import { Button, Grid, Typography } from '@mui/material';
import ExpandableCard from 'components/expandable-card/ExpandableCard';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchUserNotificationsSettings,
  modifyUserNotificationsSettings
} from 'actions/NotificationsSettingsAction';
import { t } from 'i18next';
import _ from 'lodash';
import { NotificationsTypesSettingsCard } from './NotificationsTypesSettingsCard';
import { DefaultSettingsCard } from './DefaultSettingsCard';
import { NotificationsNone } from '@mui/icons-material';

const NotificationsSettingsCard = ({ _props }) => {
  const notificationsSettings = useSelector(
    (state) => state.social.notifications.notificationsSettings
  );

  const [patchedSettings, setPatchedSettings] = useState();

  const mergedSettings = _.merge({}, notificationsSettings, patchedSettings);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserNotificationsSettings());
  }, [dispatch]);

  return (
    <ExpandableCard
      title={<Typography variant='subtitle1'>Notification Settings</Typography>}
      actions={[
        <Button
          variant='contained'
          style={{ margin: '0px 8px 8px 8px ' }}
          disabled={_.isEqual(notificationsSettings, mergedSettings)}
          onClick={() => {
            dispatch(modifyUserNotificationsSettings(mergedSettings)).then(() =>
              setPatchedSettings()
            );
          }}
          color='primary'>
          {t('commons.actions.submit')}
        </Button>
      ]}
      avatar={<NotificationsNone />}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <DefaultSettingsCard
            mergedSettings={mergedSettings}
            setPatchedSettings={setPatchedSettings}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          {!mergedSettings.emailImmediate && (
            <NotificationsTypesSettingsCard
              mergedSettings={mergedSettings}
              setPatchedSettings={setPatchedSettings}
            />
          )}
        </Grid>
      </Grid>
    </ExpandableCard>
  );
};

export default NotificationsSettingsCard;
