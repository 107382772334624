import { BlindataApi } from './ApiUtils';

class StewardshipApi extends BlindataApi {
  getRoles(params) {
    return this.get(`/api/v1/stewardship/roles`, params);
  }

  createRole(customRole) {
    return this.post(`/api/v1/stewardship/roles`, customRole);
  }

  deleteRole(uuid) {
    return this.delete(`/api/v1/stewardship/roles/${uuid}`);
  }

  putRole(role) {
    return this.put(`/api/v1/stewardship/roles/${role.uuid}`, role);
  }

  getRole(uuid) {
    return this.get(`/api/v1/stewardship/roles/${uuid}`);
  }

  getResponsibilities(params) {
    return this.get(`/api/v1/stewardship/responsibilities`, params);
  }

  createResponsibility(responsibility) {
    return this.post(`/api/v1/stewardship/responsibilities`, responsibility);
  }

  deleteResponsibility(sequenceId) {
    return this.delete(`/api/v1/stewardship/responsibilities/${sequenceId}`);
  }

  putResponsibility(responsibility) {
    return this.put(
      `/api/v1/stewardship/responsibilities/${responsibility.sequenceId}`,
      responsibility
    );
  }

  getResponsibility(sequenceId) {
    return this.get(`/api/v1/stewardship/responsibilities/${sequenceId}`);
  }
  getAssignments(params) {
    return this.get(`/api/v1/stewardship/assignments`, params);
  }
  getAssignment(sequenceId) {
    return this.get(`/api/v1/stewardship/assignments/${sequenceId}`);
  }
  createAssignment(assignment) {
    return this.post(`/api/v1/stewardship/assignments`, assignment);
  }
  putAssignment(assignment) {
    return this.put(`/api/v1/stewardship/assignments/${assignment.sequenceId}`, assignment);
  }
  deleteAssignment(sequenceId) {
    return this.delete(`/api/v1/stewardship/assignments/${sequenceId}`);
  }

  getTable(params) {
    return this.get(`/api/v1/stewardship/dashboards/table`, {
      resourceType: params.resourceType,
      systemUuid: params.systemUuid
    });
  }

  getOverallDashboard({ resourceType, ...params }) {
    return this.get(`/api/v1/stewardship/dashboards/overall/${resourceType}`, params);
  }

  getTableUsers(params) {
    return this.get(`/api/v1/stewardship/dashboards/usertable`, {
      resourceType: params.resourceType
    });
  }
}

export default StewardshipApi;
