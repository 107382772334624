import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { Delete, Edit, Refresh } from '@mui/icons-material';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  fetchCustomPropertyDefinitionsPage,
  deleteCustomPropertyDefinition,
  modifyCustomPropertyDefinition,
  refreshComputedCustomProperty
} from '../../../actions/CustomPropertiesActions';
import ConfirmIconButton from '../../../components/confirmbutton/ConfirmIconButton';
import { withLoader } from '../../../components/loader/Loader';
import CustomPropertyModal from './CustomPropertyModal';
import { useTranslation } from 'react-i18next';
import Authorize from 'components/permissions/Authorize';
import { AdminPermissionsByResourceTypes } from 'constants/ResourceTypes';

function CustomPropertiesTable({ currentPage, onDelete, onModify, onRefresh }) {
  return (
    <Card>
      <CardContent style={{ overflowX: 'auto' }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Resource Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Order</TableCell>
              <TableCell>Label</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Domain</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPage.content.map((customPropertyDefinition, index) => (
              <CustomPropertyRowItem
                key={index}
                customPropertyDefinition={customPropertyDefinition}
                onDelete={onDelete}
                onModify={onModify}
                onRefresh={onRefresh}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function CustomPropertyRowItem({ customPropertyDefinition, onDelete, onModify, onRefresh }) {
  let [modifyOpen, setModifyOpen] = useState(false);
  let { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>{t(`resourceType.${customPropertyDefinition.resourceType}`)}</TableCell>
      <TableCell>{customPropertyDefinition.name}</TableCell>
      <TableCell>{customPropertyDefinition.order}</TableCell>
      <TableCell>{customPropertyDefinition.label}</TableCell>
      <TableCell>{customPropertyDefinition.type}</TableCell>
      <TableCell>{customPropertyDefinition.domain}</TableCell>

      <TableCell>
        <Authorize
          hasPermissions={[AdminPermissionsByResourceTypes[customPropertyDefinition.resourceType]]}>
          <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
            <Grid item>
              <IconButton size='small' onClick={() => setModifyOpen(true)}>
                <Edit></Edit>
              </IconButton>
              {modifyOpen && (
                <CustomPropertyModal
                  open={modifyOpen}
                  customPropertyDefinition={customPropertyDefinition}
                  onCancel={() => setModifyOpen(false)}
                  onSubmit={(customPropertyDefinition) => {
                    onModify(customPropertyDefinition);
                    setModifyOpen(false);
                  }}
                />
              )}
            </Grid>
            <Grid item>
              <ConfirmIconButton
                size='small'
                onClick={() => onDelete(customPropertyDefinition.uuid)}>
                <Delete></Delete>
              </ConfirmIconButton>
            </Grid>

            {customPropertyDefinition.type === 'COMPUTED' && (
              <Grid item>
                <ConfirmIconButton
                  size='small'
                  message={`Do you want to refresh ${customPropertyDefinition.label}? This may take time...`}
                  onClick={() => onRefresh(customPropertyDefinition, { size: 5000 })}>
                  <Refresh></Refresh>
                </ConfirmIconButton>
              </Grid>
            )}
          </Grid>
        </Authorize>
      </TableCell>
    </TableRow>
  );
}

const mapDispatchToProps = {
  onDelete: deleteCustomPropertyDefinition,
  onModify: modifyCustomPropertyDefinition,
  fetch: fetchCustomPropertyDefinitionsPage,
  onRefresh: refreshComputedCustomProperty
};

function mapStateToProps(state) {
  return {
    currentPage: state.settings.customPropertiesDefinitions.currentPage
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetch }) => fetch()
  })
)(CustomPropertiesTable);
