import React, { useEffect, useState } from 'react';
import { fetchDashboardsUsersTable } from '../../../actions/StewardshipActions';
import FilterListIcon from '@mui/icons-material/FilterList';
import VariationTypeDecorator from '../../workflows/proposals/commons/VariationTypeDecorator';

import {
  Typography,
  Card,
  CardContent,
  Grid,
  CardHeader,
  IconButton,
  Tooltip
} from '@mui/material';
import { connect } from 'react-redux';
import { Chart } from 'react-google-charts';
import DashboardRolesCoverageFiltersModal from '../common/DashboardRolesCoverageFiltersModal';
import { useTranslation } from 'react-i18next';

function StewardshipDashboardsusersCoverage({ currentPage, fetchDashboardsUsersTable }) {
  let { t } = useTranslation();

  const [filters, setFilters] = useState({
    resourceType: 'DATA_CATEGORY'
  });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    fetchDashboardsUsersTable({ resourceType: filters.resourceType });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDashboardsUsersTable]);
  if (!currentPage) {
    return null;
  }

  const rows = [['Username', 'Workload'], ...currentPage.rows.map((r) => [r[1], r[2]])];

  return (
    <React.Fragment>
      <Card style={{ height: '300px' }}>
        <Grid container alignItems='baseline'>
          <CardHeader title={'Users Workload'}></CardHeader>
          <Grid item style={{ flexGrow: 1 }}></Grid>
          <Grid item style={{ marginRight: '1%' }}>
            <Typography color='primary' variant='caption text'>
              Resource Type:
            </Typography>
          </Grid>
          <Grid item style={{ marginRight: '1%' }}>
            <VariationTypeDecorator>
              {t(`resourceType.${filters.resourceType}`)}
            </VariationTypeDecorator>
          </Grid>
          <Grid item style={{ marginRight: '1%' }}>
            <Tooltip title={'Filters'}>
              <IconButton size='small' onClick={() => setOpen(true)}>
                <FilterListIcon></FilterListIcon>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {currentPage.rows.length > 0 ? (
          <CardContent style={{ overflowX: 'auto' }}>
            <Chart
              width={'100%'}
              height={'100%'}
              chartType='Table'
              loader={<div>Loading Chart</div>}
              data={rows}
              options={{
                showRowNumber: true,
                width: '100%',
                height: '100%'
              }}
              rootProps={{ 'data-testid': '3' }}
            />
          </CardContent>
        ) : (
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>No responsibilities defined for users</Typography>
          </div>
        )}
      </Card>
      <DashboardRolesCoverageFiltersModal
        open={open}
        setOpen={setOpen}
        usersDashboard={true}
        filters={filters}
        setFilters={setFilters}
        onFetch={fetchDashboardsUsersTable}></DashboardRolesCoverageFiltersModal>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.stewardship.dashboards.tableusers
  };
}

export default connect(mapStateToProps, { fetchDashboardsUsersTable })(
  StewardshipDashboardsusersCoverage
);
