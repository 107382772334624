import * as React from "react";
const SvgApacheCalciteLong = (props) => (
  <svg
    id="ApacheCalciteLong_svg__svg7816"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 120 60"
    width="1em"
    height="1em"
    style={{
      enableBackground: "new 0 0 120 60",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".ApacheCalciteLong_svg__st0{fill:#333}.ApacheCalciteLong_svg__st1{fill:#acf}.ApacheCalciteLong_svg__st2{fill:#2a7fff}.ApacheCalciteLong_svg__st3{fill:#59f}.ApacheCalciteLong_svg__st4{fill:#80b3ff}.ApacheCalciteLong_svg__st6{fill:#fff}"
      }
    </style>
    <g
      id="ApacheCalciteLong_svg__text8416"
      transform="translate(-141.536 6.31)"
    >
      <path
        id="ApacheCalciteLong_svg__path8481"
        className="ApacheCalciteLong_svg__st0"
        d="M176.5 32.4c-2 0-3.7-.6-5.2-1.9-1.4-1.3-2.2-2.9-2.2-4.9 0-2 .7-3.7 2.2-4.9 1.5-1.3 3.3-1.9 5.5-1.9s4 .7 5.5 2.1l-1.8 2c-1.2-1-2.5-1.5-3.7-1.5-1.3 0-2.4.4-3.3 1.2-.9.8-1.4 1.8-1.4 3s.5 2.2 1.4 3.1c.9.8 2 1.3 3.4 1.3 1.3 0 2.6-.6 3.8-1.7l1.8 1.8c-1.7 1.5-3.8 2.3-6 2.3z"
      />
      <path
        id="ApacheCalciteLong_svg__path8483"
        className="ApacheCalciteLong_svg__st0"
        d="M197.2 32.2h-2.8v-1.8c-1.2 1.3-2.8 2-4.8 2-1.5 0-2.7-.4-3.7-1.2-1-.8-1.5-1.8-1.5-3.1s.5-2.3 1.6-2.9c1-.6 2.5-1 4.2-1h3.9v-.5c0-1.8-1.1-2.6-3.2-2.6-1.3 0-2.7.4-4.2 1.3l-1.3-1.7c1.7-1.3 3.7-1.9 5.9-1.9 1.7 0 3.1.4 4.1 1.2 1.1.8 1.6 2 1.6 3.7v8.5zm-3.1-4.9v-1.1h-3.4c-2.2 0-3.3.6-3.3 1.9 0 .6.3 1.1.8 1.5.5.3 1.3.5 2.2.5 1 0 1.8-.2 2.5-.7.9-.6 1.2-1.3 1.2-2.1z"
      />
      <path
        id="ApacheCalciteLong_svg__path8485"
        className="ApacheCalciteLong_svg__st0"
        d="M204.4 32.2h-3V13.7h3v18.5z"
      />
      <path
        id="ApacheCalciteLong_svg__path8487"
        className="ApacheCalciteLong_svg__st0"
        d="M215.1 32.4c-2 0-3.7-.6-5.2-1.9-1.4-1.3-2.2-2.9-2.2-4.9 0-2 .7-3.7 2.2-4.9 1.5-1.3 3.3-1.9 5.5-1.9s4 .7 5.5 2.1l-1.8 2c-1.2-1-2.5-1.5-3.7-1.5-1.3 0-2.4.4-3.3 1.2-.9.8-1.4 1.8-1.4 3s.5 2.2 1.4 3.1c.9.8 2 1.3 3.4 1.3 1.3 0 2.6-.6 3.8-1.7l1.8 1.8c-1.7 1.5-3.7 2.3-6 2.3z"
      />
      <path
        id="ApacheCalciteLong_svg__path8489"
        className="ApacheCalciteLong_svg__st0"
        d="M227.3 32.2h-3V19h3v13.2zm-2.8-15.9c-.4-.3-.6-.8-.6-1.2s.2-.9.6-1.2c.4-.3.8-.5 1.3-.5s1 .2 1.3.5c.4.3.6.8.6 1.2s-.2.9-.6 1.2c-.4.3-.8.5-1.3.5-.5.1-.9-.1-1.3-.5z"
      />
      <path
        id="ApacheCalciteLong_svg__path8491"
        className="ApacheCalciteLong_svg__st0"
        d="M235.4 21.2v6.7c0 .6.2 1.1.5 1.5.4.4.9.5 1.5.5s1.3-.3 1.9-.9l1.2 2c-1.1.9-2.2 1.3-3.5 1.3-1.3 0-2.4-.4-3.3-1.2-.9-.8-1.3-1.9-1.3-3.3v-6.6h-1.8V19h1.8v-4.2h3V19h3.8v2.2h-3.8z"
      />
      <path
        id="ApacheCalciteLong_svg__path8493"
        className="ApacheCalciteLong_svg__st0"
        d="M256.3 26.8h-11.2c.1 1 .5 1.7 1.4 2.3.9.6 1.9.9 3 .9 1.8 0 3.2-.5 4.1-1.6l1.7 1.7c-1.5 1.5-3.6 2.2-6 2.2-2 0-3.7-.6-5.2-1.8-1.4-1.2-2.1-2.9-2.1-5s.7-3.7 2.2-5c1.4-1.2 3.1-1.8 5.1-1.8 2 0 3.6.5 5 1.6s2.1 2.6 2.1 4.5v2zm-11.2-2.2h8.2c0-1.1-.4-2-1.1-2.6-.8-.6-1.7-.9-2.8-.9-1.1 0-2.1.3-3 .9-.9.7-1.3 1.5-1.3 2.6z"
      />
    </g>
    <g
      id="ApacheCalciteLong_svg__layer1-9"
      transform="matrix(3.3437 0 0 3.3437 -435.48 -601.6)"
    >
      <g
        id="ApacheCalciteLong_svg__g2277"
        transform="matrix(.21375 .11444 -.11444 .21375 57.819 161.95)"
      >
        <path
          id="ApacheCalciteLong_svg__path1365"
          className="ApacheCalciteLong_svg__st1"
          d="m340.5-41-3.4 2-3.3.1-3.3.1-3.2-1.8-3.8-5.4 11.6-25.6 9.8 24.9-4.4 5.7z"
        />
        <path
          id="ApacheCalciteLong_svg__path1367"
          className="ApacheCalciteLong_svg__st2"
          d="m340.5-41-3.4 2-3.3.1-3.3.1-3.2-1.8 13.2-.4z"
        />
        <path
          id="ApacheCalciteLong_svg__path1308"
          className="ApacheCalciteLong_svg__st1"
          d="m340.2-41.7-3.2 1.9-3.1.1-3.1.1-3-1.7-3.6-5.1 10.9-24.1 9.2 23.4-4.1 5.4z"
        />
        <path
          id="ApacheCalciteLong_svg__path1313"
          className="ApacheCalciteLong_svg__st3"
          d="m340.2-41.7-3.1-1.6-3.1.1-3.1.1-3.2 1.8-3.6-5.1L335-70.5l9.2 23.4-4 5.4z"
        />
        <path
          id="ApacheCalciteLong_svg__path1315"
          className="ApacheCalciteLong_svg__st2"
          d="m337.4-49.8-3.1.1-3.1.1-7 3.2 10.9-24.1 9.2 23.4-6.9-2.7z"
        />
        <path
          id="ApacheCalciteLong_svg__path1319"
          className="ApacheCalciteLong_svg__st4"
          d="m337.1-43.3-6.2.2.2-6.5 3.9-20.9 2.3 20.7-.2 6.5z"
        />
        <path
          id="ApacheCalciteLong_svg__path1321"
          className="ApacheCalciteLong_svg__st3"
          d="m335.1-70.6-3.9 20.9 3.1-.1 3.1-.1-2.3-20.7z"
        />
        <path
          id="ApacheCalciteLong_svg__path1345"
          d="m337-39.8-6.2.2 3.3-1.6 2.9 1.4z"
          style={{
            fill: "#d5e5ff",
          }}
        />
        <path
          id="ApacheCalciteLong_svg__path1351"
          className="ApacheCalciteLong_svg__st6"
          d="m327.7-41.3 2.7-2.1.7-6.2.2 6 5.5-.3.5-5.9.3 6 2.5 2.1-3.1-1.6-6.2.2-3.1 1.8z"
        />
        <path
          id="ApacheCalciteLong_svg__path1353"
          className="ApacheCalciteLong_svg__st6"
          d="m324.9-46.8 6.1-3.6s4-19.4 4.1-20.1l-3.3 20.2 5.2-.3-1.7-19.2 2.6 19.4s5.8 3 6.5 3.4l-6.9-2.7-6.2.2-6.4 2.7z"
        />
        <path
          id="ApacheCalciteLong_svg__path1355"
          className="ApacheCalciteLong_svg__st3"
          d="m324.9-47.3 9.3-21.1-7.6 19.9-1.7 1.2z"
        />
        <path
          id="ApacheCalciteLong_svg__path1357"
          className="ApacheCalciteLong_svg__st4"
          d="m340.2-42.4 3.3-4.5-1.6-.8-1.7 5.3z"
        />
        <ellipse
          id="ApacheCalciteLong_svg__path1359"
          transform="rotate(73.022 330.495 -54.68)"
          className="ApacheCalciteLong_svg__st4"
          cx={330.5}
          cy={-54.7}
          rx={1}
          ry={1.1}
        />
        <ellipse
          id="ApacheCalciteLong_svg__ellipse1361"
          transform="rotate(151.836 329.625 -52.889)"
          className="ApacheCalciteLong_svg__st4"
          cx={329.6}
          cy={-52.9}
          rx={0.7}
          ry={0.6}
        />
        <path
          id="ApacheCalciteLong_svg__path2189"
          className="ApacheCalciteLong_svg__st6"
          d="m331.2-48-1.9-.8-1.3 1.7 1-1.9-1.7-1.2 1.9.8 1.3-1.7-1 1.9 1.7 1.2z"
        />
        <path
          id="ApacheCalciteLong_svg__path2193"
          className="ApacheCalciteLong_svg__st6"
          d="m329.6-47.3-.5-1.6-1.8.3 1.7-.6-.2-1.7.5 1.6 1.8-.3-1.7.6.2 1.7z"
        />
        <path
          id="ApacheCalciteLong_svg__path2219"
          className="ApacheCalciteLong_svg__st6"
          d="m337-63.3-1.2-1-1.3 1 1.1-1.2-.9-1.2 1.2 1 1.3-1-1.1 1.2.9 1.2z"
        />
      </g>
    </g>
  </svg>
);
export default SvgApacheCalciteLong;
