import { BlindataApi } from 'api/ApiUtils';

const PROBES_PROJECTS_URL = '/api/v1/data-quality/probes/projects';

export default class ProbesProjectsApi extends BlindataApi {
  getProject(uuid) {
    return this.get(`${PROBES_PROJECTS_URL}/${uuid}`);
  }

  getProjects(params) {
    return this.get(PROBES_PROJECTS_URL, params);
  }

  postProject(project) {
    return this.post(PROBES_PROJECTS_URL, project);
  }

  putProject(project) {
    return this.put(`${PROBES_PROJECTS_URL}/${project.uuid}`, project);
  }

  deleteProject(project) {
    return this.delete(`${PROBES_PROJECTS_URL}/${project.uuid}`);
  }

  exportProjects(params) {
    return this.download(`${PROBES_PROJECTS_URL}/*/export`, params)
  }
}
