import { BlindataApi } from './ApiUtils';

const QUERY_STATEMENTS_URL = '/api/v1/query-parser/statements';

export default class QueryStatementsApi extends BlindataApi {
  getAllQueryStatements(params) {
    return this.get(QUERY_STATEMENTS_URL, params);
  }
  getQueryStatement(identifier) {
    return this.get(`${QUERY_STATEMENTS_URL}/${identifier}`);
  }
  postQueryStatement(queryStatement) {
    return this.post(QUERY_STATEMENTS_URL, queryStatement);
  }
  putQueryStatement(queryStatement) {
    return this.put(`${QUERY_STATEMENTS_URL}/${queryStatement.uuid}`, queryStatement);
  }
  deleteQueryStatement(queryStatement) {
    return this.delete(`${QUERY_STATEMENTS_URL}/${queryStatement.uuid}`);
  }
  getAllDataFlowsPreview(identifier, previewOptions) {
    return this.get(`${QUERY_STATEMENTS_URL}/${identifier}/dataflows-preview`, previewOptions);
  }
  postAllDataFlowsPreview(identifier, previewOptions) {
    return this.post(`${QUERY_STATEMENTS_URL}/${identifier}/dataflows-preview`, previewOptions);
  }
  deleteAllDataFlowsPreview(identifier) {
    return this.delete(`${QUERY_STATEMENTS_URL}/${identifier}/dataflows-preview`);
  }
  getQueryStatementFullLineage(queryStatementUuid) {
    return this.get(`${QUERY_STATEMENTS_URL}/${queryStatementUuid}/full-lineage`);
  }
  exportQueryStatements(params) {
    return this.download(`${QUERY_STATEMENTS_URL}/*/export`, {
      includeProperties: 'false',
      ...params
    });
  }
  refresh(uuid) {
    return this.post(`${QUERY_STATEMENTS_URL}/${uuid}/refresh`);
  }
  getQueryStatementEndingNodes(identifier) {
    return this.get(`${QUERY_STATEMENTS_URL}/${identifier}/ending-projections`);
  }
  putQueryStatementTargetTable(identifier, connections) {
    return this.put(`${QUERY_STATEMENTS_URL}/${identifier}/target-table`, connections);
  }
  getDataFlowsInvolvedStatements(params) {
    return this.get(`${QUERY_STATEMENTS_URL}/data-flows-involved`, params);
  }
}
