import React from 'react';
import { useTranslation } from 'react-i18next';

import ResourceTypes from 'constants/ResourceTypes';

import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import CronExpressionTextField from 'components/CronExpressionTextField';
import TimeZonesMenuItem from 'components/timezone/TimeZonesMenuItem';
import SearchItemAutocomplete from 'pages/settings/search/SearchItemAutocomplete';

const PolicyConfigurationSection = ({
  policyName,
  policyType,
  policyContent,
  policyResource,
  isActive,
  setPolicyResource,
  handleChange
}) => {
  const { t } = useTranslation();

  const policyTypes = ['SINGLE_RESULT_SEMAPHORE', 'RECURRENT_RESULT_SEMAPHORE', 'SCHEDULED'];
  const updatePolicyType = (policyType) => {
    handleChange({
      policyType,
      policyContent: {
        ...policyContent,
        semaphores:
          policyType === 'SINGLE_RESULT_SEMAPHORE' || policyType === 'RECURRENT_RESULT_SEMAPHORE'
            ? ['RED']
            : [],
        semaphoresNumber: policyType === 'RECURRENT_RESULT_SEMAPHORE' ? 5 : '',
        cronExpression: null,
        timezoneId:
          policyType === 'SCHEDULED' ? Intl.DateTimeFormat().resolvedOptions().timeZone : null
      }
    });
  };

  return <>
    <TextField
      variant="standard"
      fullWidth
      margin='dense'
      label='Policy Name'
      placeholder="Enter the policy's name"
      required={true}
      value={policyName || ''}
      onChange={(event) => handleChange({ name: event.target.value })} />
    <SearchItemAutocomplete
      resourceTypes={getResourceTypesToSearch(policyType)}
      value={policyResource}
      onChange={(value) => {
        setPolicyResource(value);
        updatePolicyType(null);
      }}
    />
    <FormControl variant="standard" fullWidth margin='dense'>
      <InputLabel id='policy-type-select-label' required>
        Policy Type
      </InputLabel>
      <Select
        variant="standard"
        id='policy-type-select'
        labelId='policy-type-select-label'
        value={policyType || ''}
        onChange={(event) => updatePolicyType(event.target.value)}>
        {policyTypes
          .filter((policyType) => {
            if (
              policyResource?.resourceType !== ResourceTypes.QUALITY_CHECK &&
              (policyType === 'SINGLE_RESULT_SEMAPHORE' ||
                policyType === 'RECURRENT_RESULT_SEMAPHORE')
            ) {
              return false;
            } else {
              return true;
            }
          })
          .map((policyType) => (
            <MenuItem key={policyType} value={policyType}>
              {t(`issueMngt.issuesPolicies.types.${policyType}`)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
    {policyType === 'SINGLE_RESULT_SEMAPHORE' && (
      <SingleResultSemaphorePolicyRule
        policyContent={policyContent}
        handleChange={handleChange}
      />
    )}
    {policyType === 'RECURRENT_RESULT_SEMAPHORE' && (
      <RecurrentResultSemaphorePolicyRule
        policyContent={policyContent}
        handleChange={handleChange}
      />
    )}
    {policyType === 'SCHEDULED' && (
      <ScheduledPolicyRule policyContent={policyContent} handleChange={handleChange} />
    )}
    <FormControlLabel
      label='Active'
      control={
        <Checkbox
          checked={isActive}
          onChange={(event, checked) => handleChange({ active: checked })}
        />
      }
    />
  </>;
};

const SingleResultSemaphorePolicyRule = ({ policyContent, handleChange }) => {
  return (
    <FormControl variant="standard" fullWidth margin='dense'>
      <InputLabel shrink required>
        Policy Rule
      </InputLabel>
      <Grid container style={{ marginTop: '16px' }}>
        <Grid item>
          <Typography>Create a new issue for each</Typography>
        </Grid>
        <Grid item>
          <SemaphoreSelect
            value={policyContent.semaphores}
            setValue={(value) =>
              handleChange({ policyContent: { ...policyContent, semaphores: value } })
            }
          />
        </Grid>
        <Grid item>
          <Typography>semaphore</Typography>
        </Grid>
      </Grid>
    </FormControl>
  );
};

const RecurrentResultSemaphorePolicyRule = ({ policyContent, handleChange }) => {
  return <>
    <FormControl variant="standard" fullWidth margin='dense'>
      <InputLabel shrink required>
        Policy Rule
      </InputLabel>
      <Grid container alignItems='center' style={{ marginTop: '16px' }}>
        <Grid item>
          <Typography>Create a new issue after</Typography>
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin='dense'
            style={{ margin: '0px 0px 0px 5px', width: '75px' }}
            type='number'
            value={
              policyContent.semaphoresNumber === 0 || policyContent.semaphoresNumber
                ? policyContent.semaphoresNumber
                : ''
            }
            inputProps={{ min: 1, step: 1 }}
            onChange={(event) =>
              handleChange({
                policyContent: { ...policyContent, semaphoresNumber: event.target.value }
              })
            } />
        </Grid>
        <Grid item>
          <SemaphoreSelect
            value={policyContent.semaphores}
            setValue={(value) =>
              handleChange({ policyContent: { ...policyContent, semaphores: value } })
            }
          />
        </Grid>
        <Grid item>
          <Typography>semaphores</Typography>
        </Grid>
      </Grid>
    </FormControl>
    <FormControl variant="standard" fullWidth margin='dense'>
      <FormControlLabel
        label='Auto Close'
        control={
          <Checkbox
            checked={policyContent.autoClose || false}
            onChange={(event, checked) => {
              handleChange({ policyContent: { ...policyContent, autoClose: checked } });
            }}
          />
        }
      />
    </FormControl>
  </>;
};

const ScheduledPolicyRule = ({ policyContent, handleChange }) => {
  return (
    <FormControl variant="standard" fullWidth margin='dense'>
      <InputLabel shrink required>
        Policy Rule
      </InputLabel>
      <Grid container direction='column' style={{ marginTop: '16px' }}>
        <Grid item>
          <CronExpressionTextField
            value={policyContent.cronExpression || ''}
            onChange={(value) =>
              handleChange({ policyContent: { ...policyContent, cronExpression: value } })
            }
          />
        </Grid>
        <Grid item>
          <TimeZonesMenuItem
            value={policyContent.timezoneId}
            onChange={(tz) => handleChange({ policyContent: { ...policyContent, timezoneId: tz } })}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
};

const SemaphoreSelect = ({ value, setValue }) => {
  const { t } = useTranslation();

  const semaphores = ['GREEN', 'YELLOW', 'RED'];

  return (
    <Select
      variant="standard"
      multiple
      style={{ marginLeft: '5px', marginRight: '5px' }}
      value={value}
      renderValue={(value) =>
        value.length === 0
          ? ''
          : value.map((v) => t(`issueMngt.issuesPolicies.semaphores.${v}`)).join(' or ')
      }
      onChange={(event) => setValue(event.target.value)}>
      {semaphores.map((semaphore, index) => (
        <MenuItem key={index} value={semaphore}>
          {t(`issueMngt.issuesPolicies.semaphores.${semaphore}`)}
        </MenuItem>
      ))}
    </Select>
  );
};

function getResourceTypesToSearch(policyType) {
  switch (policyType) {
    case 'SINGLE_RESULT_SEMAPHORE':
    case 'RECURRENT_RESULT_SEMAPHORE':
      return [ResourceTypes.QUALITY_CHECK];
    default:
      return null;
  }
}

export default PolicyConfigurationSection;
