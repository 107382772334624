import React, { useCallback, useMemo, useState } from 'react';
import dagre from 'dagre';
import ReactFlow, { applyEdgeChanges, applyNodeChanges, Controls } from 'reactflow';
import 'reactflow/dist/style.css';
import TableNode from './TableNode';
import './TableDiagram.css';
import _ from 'lodash';
import { EdgeContextMenu } from './EdgeContextMenu';
import { NodeContextMenu } from './NodeContextMenu';

const nodeWidth = 400;
const nodeHeight = 52;

const getLayoutedElements = (dagreGraph, nodes, edges, direction = 'LR') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2
    };

    return node;
  });

  return { nodes, edges };
};

function TableDiagram({ initialNodes, initialEdges, fromFullLineage }) {
  const dagreGraph = useMemo(() => new dagre.graphlib.Graph(), []);
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const nodeTypes = useMemo(() => ({ tableNode: TableNode }), []);
  const { nodes: layoutedNodes, edges: layoutedEdges } = useMemo(
    () => getLayoutedElements(dagreGraph, initialNodes, initialEdges),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialNodes, initialEdges]
  );
  const [nodes, setNodes] = useState(layoutedNodes);
  const [edges, setEdges] = useState(layoutedEdges);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const [currentElement, setCurrentElement] = useState({});
  const [contextMenu, setContextMenu] = useState(null);
  const handleContextMenu = (event, element) => {
    event.preventDefault();
    if (element.edge) {
      if (_.startsWith(element.edge.id, 'REL_DATA_FLOW')) {
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6
              }
            : null
        );
        setCurrentElement({ edge: element.edge });
      }
    } else if (element.node) {
      if (
        (element.node.data.nodeType === 'SOURCE_TABLE' ||
          element.node.data.nodeType === 'TARGET_TABLE') &&
        (!('notFoundInCatalog' in element.node.data) ||
          ('notFoundInCatalog' in element.node.data && !element.node.data?.notFoundInCatalog))
      ) {
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6
              }
            : null
        );
        setCurrentElement({ node: element.node });
      }
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <>
      <ReactFlow
        onEdgeContextMenu={(event, edge) => handleContextMenu(event, { edge: edge })}
        onNodeContextMenu={(event, node) => handleContextMenu(event, { node: node })}
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView>
        <Controls />
      </ReactFlow>
      <ContextMenu element={currentElement} contextMenu={contextMenu} handleClose={handleClose} />
    </>
  );
}

const ContextMenu = ({ element, contextMenu, handleClose }) => {
  if (element.edge) {
    return (
      <EdgeContextMenu edge={element.edge} contextMenu={contextMenu} handleClose={handleClose} />
    );
  }

  if (element.node) {
    return (
      <NodeContextMenu
        data={element.node.data}
        id={element.node.id}
        contextMenu={contextMenu}
        handleClose={handleClose}
      />
    );
  }

  return <></>;
};

export default TableDiagram;
