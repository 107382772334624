import { BlindataApi } from './ApiUtils';

export default class HistoryApi extends BlindataApi {
  getHistoryRecords(params) {
    return this.get(`/api/v1/history/records/`, params);
  }

  getHistoryRecord(sequenceId) {
    return this.get(`/api/v1/history/records/${sequenceId}`);
  }

  getHistoryRecordPayload(sequenceId) {
    return this.get(`/api/v1/history/records/${sequenceId}/payload`);
  }

  exportHistoryRecords(params) {
    return this.download('/api/v1/history/exports', { ...params });
  }
}
