import React from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch, connect } from 'react-redux';
import useInterval from 'components/engagement/useInterval';
import { withRouter } from 'react-router-dom';

import { sendEngagementHits } from 'actions/EngagementActions';

import App from './App';

const AppContainer = ({ authenticated, authError }) => {
  const engagementHits = useSelector((state) => state.engagement.engagementHits.hits);
  const dispatch = useDispatch();

  useInterval(() => {
    if (engagementHits.length > 0) {
      dispatch(sendEngagementHits(engagementHits));
    }
  }, 30000);

  return <App authenticated={authenticated} />;
};

AppContainer.propTypes = {
  authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  authError: state.authentication.error
});

export default withRouter(connect(mapStateToProps, {})(AppContainer));
