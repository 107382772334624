import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ActionDelete from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle, DialogActions, ListItemIcon, IconButton } from '@mui/material';
import DataActorItemAutoComplete from '../../data_actors/utils/DataActorItemAutoComplete';
import SafeguardTypeEditor from './SafeguardTypeEditor';

const INITIAL_STATE = {
  addItemOpen: false,
  safeguardType: '',
  linkToDocs: '',
  organization: null
};

class TransferToOrganizationFormComponent extends React.Component {
  state = INITIAL_STATE;
  render() {
    const { t } = this.props;
    var addButtonDisabled = this.state.organization === null;
    return (
      <div>
        <h4>{t('processings.transferToExtraEUOrganizations.header')}</h4>
        <List>
          {(this.props.transferExtraEuOrganizations || []).map((e, index) => (
            <ListItem key={index}>
              <ListItemText primary={e.organization.name} secondary={e.safeguardType} />
              <ListItemIcon>
                <IconButton size='large'>
                  <ActionDelete onClick={() => this.props.onDelete(e)}></ActionDelete>
                </IconButton>
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
        <Button
          children={t('processings.transferToExtraEUOrganizations.actions.addItem')}
          color='primary'
          onClick={() => this.setState({ addItemOpen: true })}
        />
        <Dialog open={this.state.addItemOpen}>
          <DialogTitle>{t('processings.transferToExtraEUOrganizations.header')}</DialogTitle>
          <DialogContent>
            <DataActorItemAutoComplete
              fullWidth
              itemName={t('processings.transferToExtraEUOrganizations.fields.organization')}
              value={this.state.representative}
              onChange={(da) => this.setState({ organization: da })}
            />
            <br />
            <SafeguardTypeEditor
              country={this.state.organization && this.state.organization.country}
              safeguardType={this.state.safeguardType}
              linkToDocs={this.state.linkToDocs}
              onChange={({ safeguardType, linkToDocs }) =>
                this.setState({ safeguardType, linkToDocs })
              }></SafeguardTypeEditor>
          </DialogContent>
          <DialogActions>
            <Button
              children={t('processings.transferToExtraEUOrganizations.actions.cancel')}
              onClick={() => this.setState(INITIAL_STATE)}
            />
            <Button
              children={t('processings.transferToExtraEUOrganizations.actions.add')}
              variant='contained'
              color='primary'
              disabled={addButtonDisabled}
              onClick={() => {
                this.props.onAdd(this.state);
                this.setState(INITIAL_STATE);
              }}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

TransferToOrganizationFormComponent.propTypes = {
  transferExtraEuOrganizations: PropTypes.arrayOf(
    PropTypes.shape({
      organization: PropTypes.object.isRequired,
      safeguardType: PropTypes.string
    })
  ).isRequired,
  t: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withTranslation()(TransferToOrganizationFormComponent);
