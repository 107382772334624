import ContractsNavbar from 'pages/contracts/ContractsNavbar';
import Breadcrumbs from 'pages/sidebar/Navigation';
import React from 'react';
import ContractDefinitionAddFAB from './ContractDefinitionAddFAB';
import { ContractDefinitionsList } from './ContractDefinitionsList';
import ContractDefinitionsListToolbar from './ContractDefinitionsListToolbar';

function DefinitionsListPage({ _props }) {
  return (
    <>
      <ContractsNavbar />
      <Breadcrumbs
        elements={[
          { text: 'Contracts', to: '/contracts' },
          { text: 'Definitions', to: `/contracts/definitions` }
        ]}
      />
      <ContractDefinitionsListToolbar />
      <ContractDefinitionsList />
      <ContractDefinitionAddFAB />
    </>
  );
}

export default DefinitionsListPage;
