import React, { useEffect } from 'react';
import { fetchPoliciesPage } from 'actions/IssuesPoliciesActions';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import IssuesPoliciesTable from './IssuesPoliciesTable';
import { connect } from 'react-redux';
import IssuePolicyFAB from './IssuePolicyFAB';
import IssuePoliciesExportButton from './IssuePoliciesExportButton';

function IssuesPoliciesPage({ policies, pagination, fetchPoliciesPage }) {
  const { t } = useTranslation();
  const handleFetch = (page, size) => {
    fetchPoliciesPage({
      page,
      size
    });
  };
  useEffect(() => {
    handleFetch(pagination.page, pagination.size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!policies) {
    return null;
  }
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('navbar.issueMngt'), to: '/issue-management/issues' },
          { text: 'Issues Policies' }
        ]}></Breadcrumbs>
      <Tracking
        info={{
          pageTitle: t('issueMngt.issuesPolicies.header'),
          category: EngagementCategories.ISSUES,
          action: EngagementActions.LIST
        }}
      />
      <Grid container alignItems='center' justifyContent='flex-start'>
        <Grid item>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            {t('issueMngt.issuesPolicies.header')}
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <IssuePoliciesExportButton />
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <IssuesPoliciesTable
            policies={policies}
            pagination={pagination}
            handleFetch={handleFetch}></IssuesPoliciesTable>
        </CardContent>
      </Card>

      <IssuePolicyFAB></IssuePolicyFAB>
    </>
  );
}

const mapStateToProps = (state, props) => ({
  policies: state.campaigns.issuesPolicies.currentPage.content,
  pagination: {
    page: state.campaigns.issuesPolicies.currentPage.number,
    size: state.campaigns.issuesPolicies.currentPage.size,
    totalElements: state.campaigns.issuesPolicies.currentPage.totalElements
  }
});

export default connect(mapStateToProps, { fetchPoliciesPage })(IssuesPoliciesPage);
