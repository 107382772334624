import LogicalFieldsApi from '../api/LogicalFieldsApi';
import { buildApiCallAction } from './ActionUtils';

import {
  REQUEST_LOGICAL_FIELDS,
  CREATE_LOGICAL_FIELD,
  DELETE_LOGICAL_FIELD,
  SEARCH_LOGICAL_FIELDS,
  FETCH_LOGICAL_FIELD,
  MODIFY_LOGICAL_FIELD,
  FETCH_LOGICAL_FIELDS_PAGE,
  SET_LOGICAL_FIELDS_FILTERS,
  CLEAR_LOGICAL_FIELDS_FILTERS
} from '../constants/ActionTypes';

let logicalFieldsApi = new LogicalFieldsApi();

export const setLogicalFieldsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_LOGICAL_FIELDS_FILTERS, payload: filters });
};
export const clearLogicalFieldsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_LOGICAL_FIELDS_FILTERS });
};

export const requestLogicalFields = (dataCategory) =>
  buildApiCallAction({
    api: logicalFieldsApi,
    apiFunction: (api) =>
      api.getLogicalFields({ page: 0, size: 1000, dataCategoryUuid: dataCategory.uuid }),
    actionType: REQUEST_LOGICAL_FIELDS
  });

export const applyFilters =
  ({ page, size, ...newFilters }) =>
  (dispatch, getState) => {
    dispatch(setLogicalFieldsFilters(newFilters));
    dispatch(
      fetchLogicalFieldsPage({
        page,
        size,
        ...newFilters
      })
    );
  };
export const fetchLogicalFieldsPage = (filters) =>
  buildApiCallAction({
    api: logicalFieldsApi,
    apiFunction: (api) => {
      let apiParams = createApiParamsFromFilters(filters);
      return api.getLogicalFields(apiParams);
    },
    actionType: FETCH_LOGICAL_FIELDS_PAGE
  });

function createApiParamsFromFilters(filters) {
  let apiParams = {
    ...filters,
    dataCategoryUuid: (filters.dataCategories || []).map((dc) => dc.uuid),
    systemUuid: (filters.systems || []).map((sys) => sys.uuid)
  };
  delete apiParams.dataCategories;
  delete apiParams.systems;
  return apiParams;
}

export const fetchLogicalField = (logicalFieldUuid) =>
  buildApiCallAction({
    api: logicalFieldsApi,
    apiFunction: (api) => api.getLogicalField(logicalFieldUuid),
    actionType: FETCH_LOGICAL_FIELD
  });
export const modifyLogicalField = (logicalField) =>
  buildApiCallAction({
    api: logicalFieldsApi,
    apiFunction: (api) => api.putLogicalField(logicalField),
    actionType: MODIFY_LOGICAL_FIELD
  });

export const deleteLogicalField = (logicalField) =>
  buildApiCallAction({
    api: logicalFieldsApi,
    apiFunction: (api) => api.deleteLogicalField(logicalField.uuid),
    actionType: DELETE_LOGICAL_FIELD,
    payload: logicalField
  });

export const requestAllLogicalFields = (logicalFieldUuid) =>
  buildApiCallAction({
    api: logicalFieldsApi,
    apiFunction: (api) => api.getAllLogicalFields(logicalFieldUuid),
    actionType: REQUEST_LOGICAL_FIELDS
  });

export const createLogicalField = (logicalField) =>
  buildApiCallAction({
    api: logicalFieldsApi,
    apiFunction: (api) => api.postLogicalField(logicalField),
    actionType: CREATE_LOGICAL_FIELD
  });

export const searchLogicalFields = (search, params) =>
  buildApiCallAction({
    api: logicalFieldsApi,
    apiFunction: (api) => api.getLogicalFields({ search: searchParams(search), ...params }),
    actionType: SEARCH_LOGICAL_FIELDS
  });

const searchParams = (search) => {
  let { searchText } = search;
  return searchText || search;
};

export const fetchLogicalFieldsKPIsCompilation = () =>
  buildApiCallAction({
    api: logicalFieldsApi,
    apiFunction: (api, getState) => {
      let filters = getState().logicalfields.filters.hasFilters
        ? getState().logicalfields.filters.content
        : {};
      let apiParams = createApiParamsFromFilters(filters);
      return api.getCompilationKPIs(apiParams);
    },
    actionType: 'KPI_COMPILATION_LOGICAL_FIELDS'
  });
export const exportLogicalFields = (params) =>
  buildApiCallAction({
    api: logicalFieldsApi,
    apiFunction: (api) => api.exportLogicalFields(params),
    actionType: 'EXPORT_LOGICAL_FIELDS'
  });
