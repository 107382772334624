import React, { Component } from 'react';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import Pagination from '../../../components/pagination/Pagination';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import ExpandableCard from '../../../components/expandable-card/ExpandableCard';
import { Button } from '@mui/material';

export default class SystemLoginItem extends Component {
  state = {
    physicalEntity: null, //grant physicalEntity
    type: null //grant type
  };

  componentDidMount() {}

  onExpandChange(expanded) {
    if (expanded) {
      this.props.fetchGrants(this.props.systemLogin);
    }
  }

  grantsPaginationComponent() {
    if (this.props.grants && this.props.grants.currentPage) {
      let currentPage = this.props.grants.currentPage;
      return (
        <Pagination
          total={currentPage.totalPages}
          current={currentPage.number + 1}
          display={3}
          onChange={(number) =>
            this.props.fetchGrants(this.props.systemLogin, number - 1, currentPage.size)
          }
          styleRoot={{ margin: '8px', display: 'flex', justifyContent: 'flex-end' }}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    let currentGrantPage =
      this.props.grants && this.props.grants.currentPage
        ? this.props.grants.currentPage
        : { content: [] };
    return (
      <ExpandableCard
        onExpandChange={(expanded) => this.onExpandChange(expanded)}
        title={this.props.systemLogin.username}
        actions={[
          <Authorize hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
            <Button children='Edit' onClick={this.props.onEdit} />
          </Authorize>,
          <Authorize hasPermissions={[Permissions.SYSTEMS_ADMIN]}>
            <Button children='Delete' onClick={this.props.onDelete} />
          </Authorize>
        ]}>
        <h4>Granted physical entities:</h4>
        <ChipWrapper onRequestAdd={() => this.props.onAddGrant(this.props.systemLogin)}>
          {currentGrantPage.content.map((grt) => {
            return (
              <ChipItem
                key={grt.uuid}
                id={grt.uuid}
                label={grt.physicalEntitySchema + '.' + grt.physicalEntityName}
                tooltip={grt.type || 'No type'}
                onRequestDelete={() => this.props.deleteGrant(this.props.systemLogin, grt)}
              />
            );
          })}
        </ChipWrapper>
        {this.grantsPaginationComponent()}
      </ExpandableCard>
    );
  }
}
