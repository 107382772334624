import { combineReducers } from 'redux';
import {
  FETCH_QUALITY_ASSESSMENT_RESIDUAL_RISK_TABLE_DASHBOARD,
  FETCH_QUALITY_ASSESSMENT_RESIDUAL_RISK_COUNTER_DASHBOARD
} from 'constants/ActionTypes';

const counter = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_QUALITY_ASSESSMENT_RESIDUAL_RISK_COUNTER_DASHBOARD:
      return action.payload;
    default:
      return state;
  }
};
const table = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_QUALITY_ASSESSMENT_RESIDUAL_RISK_TABLE_DASHBOARD:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({ counter, table });
