import { buildApiCallAction } from 'actions/ActionUtils';
import AgentConnectionsApi from 'api/onagent/AgentConnectionsApi';
import {
  SEARCH_AGENT_CONNECTIONS,
  FETCH_AGENT_CONNECTIONS_PAGE,
  FETCH_AGENT_CONNECTION,
  CREATE_AGENT_CONNECTION,
  MODIFY_AGENT_CONNECTION,
  DELETE_AGENT_CONNECTION,
  TEST_AGENT_CONNECTION
} from 'constants/ActionTypes';

export const searchAgentConnections = (agent, params) =>
  buildApiCallAction({
    api: new AgentConnectionsApi(agent),
    apiFunction: (api) => api.getAgentConnectionsPage(params),
    actionType: SEARCH_AGENT_CONNECTIONS
  });

export const fetchAgentConnection = (agent, uuid) =>
  buildApiCallAction({
    api: new AgentConnectionsApi(agent),
    apiFunction: (api) => api.getAgentConnection(uuid),
    actionType: FETCH_AGENT_CONNECTION
  });

export const fetchAgentConnectionPage = (agent, params) =>
  buildApiCallAction({
    api: new AgentConnectionsApi(agent),
    apiFunction: (api) => api.getAgentConnectionsPage(params),
    actionType: FETCH_AGENT_CONNECTIONS_PAGE
  });

export const createAgentConnection = (agent, connection) =>
  buildApiCallAction({
    api: new AgentConnectionsApi(agent),
    apiFunction: (api) => api.postAgentConnection(connection),
    actionType: CREATE_AGENT_CONNECTION
  });

export const patchAgentConnection = (agent, connectionUuid, connection) =>
  buildApiCallAction({
    api: new AgentConnectionsApi(agent),
    apiFunction: (api) => api.patchAgentConnection(connectionUuid, connection),
    actionType: MODIFY_AGENT_CONNECTION
  });

export const deleteAgentConnection = (agent, connection) =>
  buildApiCallAction({
    api: new AgentConnectionsApi(agent),
    apiFunction: (api) => api.deleteAgentConnection(connection.uuid),
    actionType: DELETE_AGENT_CONNECTION,
    payload: connection
  });

export const testAgentConnection = (agent, uuid) =>
  buildApiCallAction({
    api: new AgentConnectionsApi(agent),
    apiFunction: (api) => api.testAgentConnection(uuid),
    actionType: TEST_AGENT_CONNECTION
  })
