import React from 'react';

const numberBoxStyles = {
  box: {
    padding: '8px',
    margin: '8px',
    minWidth: '150px',
    color: 'primary',
    textAlign: 'center',
    borderRadius: '16px',
    boxShadow: '3px 3px 3px 0px #ccc' /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  },
  label: {
    textAlign: 'center',
    fontWeight: '600px',
    color: 'white',
    display: 'block'
  },
  number: {
    textAlign: 'center',
    fontSize: '30px',
    color: 'white',
    display: 'block'
  }
};
const NumberBox = ({ label, number, color }) => (
  <div style={{ background: color, ...numberBoxStyles.box }}>
    <span style={numberBoxStyles.number}>{number}</span>
    <span style={numberBoxStyles.label}>{label}</span>
  </div>
);

export const numberBoxColors = {
  lightGrey: 'linear-gradient(to bottom right, rgba(113, 135, 146, 0.8), rgba(113, 135, 146, 1))',
  grey: 'linear-gradient(to bottom right, rgba(69, 90, 100, 0.8), rgba(69, 90, 100, 1))',
  darkGrey: 'linear-gradient(to bottom right, rgba(28, 49, 58, 0.8), rgba(28, 49, 58, 1))',
  lightBlue: 'linear-gradient(to bottom right, rgba(25, 170, 190, 0.8), rgba(25, 170, 190, 1))',
  blue: 'linear-gradient(to bottom right, rgba(0, 150, 180, 0.8), rgba(0, 154, 202, 1))',
  darkBlue: 'linear-gradient(to bottom right, rgba(0, 116, 153, 0.8), rgba(0, 116, 153, 1))'
};

export default NumberBox;
