import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import ProcessingView from './ProcessingView';

const ProcessingCardView = ({ processing, actions, children }) => {
  return (
    <Card>
      <CardHeader title={processing.name} subheader={processing.category} />
      <CardContent>
        <ProcessingView processing={processing}></ProcessingView>
        {children}
      </CardContent>
      {actions}
    </Card>
  );
};

export default ProcessingCardView;
