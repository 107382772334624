import React from 'react';
import { Tabs, Tab, CardContent, Card, Typography, Box, CardHeader } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserTenantPermissionsEditor from './UserTenantPermissionsEditor';
import PermissionsEditor from '../commons/PermissionsEditor';
import { HelpIcon } from '../../../../components/help';

function UserTenantPermissionsCard({ user, availableTenants }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log(`tab index ${newValue}`);
    setValue(newValue);
  };

  return (
    <Card>
      <CardHeader title='Permissions'></CardHeader>
      <CardContent sx={styles.root}>
        <Tabs
          orientation='vertical'
          indicatorColor='primary'
          variant='scrollable'
          value={value}
          onChange={handleChange}
          aria-label='Vertical tabs example'
          sx={styles.tabs}>
          <Tab label='All' {...a11yProps(0)} />
          {(availableTenants || []).map((tenant, i) => (
            <Tab label={tenant.name} {...a11yProps(i + 1)} />
          ))}
        </Tabs>
        <TabPanel value={value} index={0} sx={styles.panel}>
          {user.permissions.length > 0 ? (
            <div style={{ position: 'relative' }}>
              <PermissionsEditor permissions={user.permissions} />
              <HelpIcon
                style={{ position: 'absolute', top: 10, right: 10 }}
                tooltip='These permissions apply to all tenants. You can modify these settings by mean of the application scope.'
              />
            </div>
          ) : (
            <div>No default permissions defined</div>
          )}
        </TabPanel>
        {(availableTenants || []).map((tenant, i) => (
          <TabPanel value={value} index={i + 1} sx={styles.panel}>
            <UserTenantPermissionsEditor tenantUuid={tenant.uuid} userUuid={user.uuid} />
          </TabPanel>
        ))}
      </CardContent>
    </Card>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: (theme) => theme.palette.background.paper,
    display: 'flex',
    height: '650px'
  },
  tabs: {
    borderRight: (theme) => `1px solid ${theme.palette.divider}`,
    minWidth: '160px'
  },
  panel: {
    overflowY: 'auto',
    width: '100%'
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const mapStateToProp = (state, props) => ({
  availableTenants: state.settings.users.associatedTenants[props.user.uuid]
});

export default connect(mapStateToProp, {})(UserTenantPermissionsCard);
