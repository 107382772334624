import crudReducers from '../utils';
import { combineReducers } from 'redux';
import filtersReducer from 'reducers/utils/filters';
import ResourceTypes from 'constants/ResourceTypes';

const filtersDefault = {
  search: '',
  statuses: ['TO_DO', 'IN_PROGRESS', 'REVIEW']
};

export default combineReducers({
  ...crudReducers({ resource: 'ISSUE', keyProperty: 'uuid' }),
  filters: filtersReducer({ resource: ResourceTypes.ISSUE, filtersDefault: filtersDefault })
});
