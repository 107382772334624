import React, { useState } from 'react';
import {
  Button,
  TextField,
  FormControlLabel,
  Switch,
  Card,
  CardHeader,
  CardContent,
  CardActions
} from '@mui/material';
import SingleFileDropzone from '../../../../components/SingleFileDropzone';
import AutoComplete from '../../../../components/autocomplete/AutoComplete';
import { useTranslation } from 'react-i18next';

function ImportFileSelection(props) {
  const [file, setFile] = useState(null);
  const [delimiterGuessing, setDelimiterGuessing] = useState(true);
  const [delimiter, setDelimiter] = useState(null);
  const [encoding, setEncoding] = useState('');
  const [patchMode, setPatchMode] = useState(false);
  const [caseSensitiveMatch, setCaseSensitiveMatch] = useState(false);
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader title='Select a file'></CardHeader>
      <CardContent>
        <SingleFileDropzone file={file} accept='.csv' onChange={(file) => setFile(file)} />
        <h4>Configuration</h4>
        <FormControlLabel
          control={
            <Switch
              checked={patchMode}
              onChange={() => {
                setPatchMode(!patchMode);
              }}
              name='patchMode'
              color='primary'
            />
          }
          label='Patch Mode'
        />
        <br></br>
        <FormControlLabel
          control={
            <Switch
              checked={caseSensitiveMatch}
              onChange={() => {
                setCaseSensitiveMatch(!caseSensitiveMatch);
              }}
              name='caseSensitiveMatch'
              color='primary'
            />
          }
          label='Case Sensitive Match'
        />
        <br></br>
        <FormControlLabel
          control={
            <Switch
              checked={delimiterGuessing}
              onChange={() => {
                setDelimiterGuessing(!delimiterGuessing);
                if (delimiterGuessing) {
                  setDelimiter(null);
                }
              }}
              name='delimiterGuessing'
              color='primary'
            />
          }
          label='Automatic delimiter guessing'
        />
        <br></br>
        <TextField
          variant='standard'
          fullWidth
          disabled={delimiterGuessing}
          label={'Delimiter'}
          placeholder='Insert a delimiter'
          value={delimiter || ''}
          onChange={(event) => setDelimiter(event.target.value)}></TextField>
        <AutoComplete
          fullWidth
          label={'Encoding'}
          placeholder='Select Encoding'
          value={encoding || ''}
          dataSource={['UTF-8', 'ISO-8859-1', 'CP1251']}
          onUpdateInput={(newEncoding) => setEncoding(newEncoding)}
          onNewRequest={(newEncoding) => setEncoding(newEncoding)}
        />
      </CardContent>
      <CardActions>
        <Button onClick={() => setFile(null)}>Clear</Button>
        <Button
          children={t('commons.actions.load')}
          variant='outlined'
          color='primary'
          disabled={!file || (!delimiterGuessing && !delimiter)}
          onClick={() =>
            props.onNext({
              file,
              configuration: {
                delimiter,
                delimiterGuessing,
                encoding,
                patchMode,
                caseSensitiveMatch
              }
            })
          }></Button>
      </CardActions>
    </Card>
  );
}

export default ImportFileSelection;
