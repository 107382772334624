import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutoComplete from 'components/autocomplete/AutoComplete';
import { fetchTableTypes } from 'actions/PhysicalEntitiesActions';
const defaultTypes = [
  'BASE_TABLE',
  'VIEW',
  'SYSTEM_TABLE',
  'GLOBAL_TEMPORARY',
  'LOCAL_TEMPORARY',
  'ALIAS',
  'SYNONYM',
  'FILE',
  'DASHBOARD',
  'REPORT',
  'JOB',
  'TOPIC',
  'API'
];

const PhysicalEntityTypeSelect = ({ type, onChange, fullWidth = true, margin = 'dense' }) => {
  const [existingTypes, setExistingTypes] = useState([]);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTableTypes({ search: '' })).then(
      (response) => response && setExistingTypes(response.content)
    );
  }, [dispatch]);

  return (
    <AutoComplete
      fullWidth={fullWidth}
      margin={margin}
      label='Type'
      value={type}
      dataSource={[...new Set(defaultTypes.concat(existingTypes))]}
      onUpdateInput={onChange}
      onNewRequest={onChange}
    />
  );
};

export default PhysicalEntityTypeSelect;
