import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Permissions from '../../../constants/Permissions';
import { Button } from '@mui/material';
import ExpandableCard from '../../../components/expandable-card/ExpandableCard';
import TeamCodeDecorator from '../../settings/teams/common/TeamCodeDecorator';
import { ProposalConfirmationButton } from '../../workflows/proposals/commons/withProposalConfirmation';
import {
  ProposalResourceTypes,
  ProposalVariationTypes
} from '../../workflows/proposals/commons/ProposalConstants';
import ProcessingView from '../utils/ProcessingView';
import AuthorizeTeam from 'components/permissions/AuthorizeTeam';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';
import ResourceTypes from 'constants/ResourceTypes';

export default function ProcessingListItem({ processing, modifyProcessing, deleteProcessing }) {
  let { t } = useTranslation();
  return (
    <ExpandableCard
      title={processing.name}
      subheader={
        <div>
          <label>{t('processings.general.fields.category')}: </label>
          <b>{processing.category || t('commons.placeholders.undefined')}</b>
        </div>
      }
      decorator={
        <div>
          {processing.teamCode && (
            <TeamCodeDecorator teamCode={processing.teamCode}></TeamCodeDecorator>
          )}

          <IssueResourceListItemWidget
            resourceType={ResourceTypes.PROCESSING}
            resourceIdentifier={processing.uuid}></IssueResourceListItemWidget>
        </div>
      }
      actions={[
        processing.uuid && (
          <Button component={Link} to={`/processings/${processing.uuid}`}>
            {t('processings.actions.detail')}
          </Button>
        ),
        modifyProcessing && (
          <AuthorizeTeam
            hasAnyPermissions={[Permissions.PROCESSINGS_EDITOR, Permissions.PROPOSE]}
            teamCode={processing.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE, TeamAccessLevels.PROPOSE]}>
            <Button onClick={() => modifyProcessing(processing)}>
              {t('processings.actions.modify')}
            </Button>
          </AuthorizeTeam>
        ),
        deleteProcessing && (
          <ProposalConfirmationButton
            AuthorizeProps={{
              hasPermissions: [Permissions.PROCESSINGS_ADMIN],
              teamCode: processing.teamCode,
              teamAccessLevels: [TeamAccessLevels.WRITE, TeamAccessLevels.PROPOSE]
            }}
            message={t('processings.deleteConfirmationMessage', {
              processingName: processing.name
            })}
            ProposeButtonProps={{
              resourceName: processing.name,
              resourceType: ProposalResourceTypes.PROCESSING,
              resourceIdentifier: processing.uuid,
              variationType: ProposalVariationTypes.DELETE
            }}
            onClick={() => deleteProcessing(processing)}>
            {t('processings.actions.delete')}
          </ProposalConfirmationButton>
        ),
        processing.uuid && (
          <Button component={Link} to={`/processings/${processing.uuid}/disclaimers`}>
            Disclaimers
          </Button>
        )
      ]}>
      <ProcessingView processing={processing}></ProcessingView>
    </ExpandableCard>
  );
}

ProcessingListItem.propTypes = {
  processing: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    dataCategories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string
      })
    )
  })
};
