import React from 'react';

import { Grid, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { QueryStatementStatusIcons } from 'constants/QueryStatementStatusIcons';

function QueryStatementStatusDecorator({ queryStatement }) {
  const { t } = useTranslation();

  return (
    <>
      <Grid container justifyContent='flex-start' direction='row' alignItems='stretch' spacing={1}>
        <Grid item style={{ marginTop: '3px' }}>
          {QueryStatementStatusIcons[queryStatement?.elaborationStatus]}
        </Grid>
        <Grid item>
          <Typography variant='overline'>
            {t(`querystatements.statuses.${queryStatement?.elaborationStatus}`)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default QueryStatementStatusDecorator;
