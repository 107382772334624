import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchRoutineCatalog } from 'actions/RoutinesActions';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';

function RoutineSchemaAutoComplete({ fetchRoutineCatalog, required, catalog, onChange, ...props }) {
  const [catalogContent, setCatalogContent] = useState([]);

  useEffect(() => {
    fetchRoutineCatalog({ search: '' }).then(
      (response) =>
        response && setCatalogContent(response.content.map((catalog) => ({ catalog: catalog })))
    );
  }, [fetchRoutineCatalog, setCatalogContent]);

  return (
    <ItemAutoComplete
      {...props}
      itemName='Catalog'
      required={required}
      values={catalogContent}
      value={catalog || null}
      nameProperty={'catalog'}
      onSearch={(inputValue) => {
        fetchRoutineCatalog({ search: inputValue }).then((response) =>
          setCatalogContent(response.content.map((catalog) => ({ catalog: catalog })))
        );
        onChange({ catalog: inputValue });
      }}
      onChange={(newValue) => onChange(newValue)}
    />
  );
}

export default connect(null, { fetchRoutineCatalog })(RoutineSchemaAutoComplete);
