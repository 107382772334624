import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import {
  fetchNotificationsPage,
  patchNotification,
  markAllNotificationsAsRead,
  deleteNotification,
  deleteAllNotifications,
  fetchUnreadCount
} from '../../../actions/NotificationActions';

import { Button, List, Paper, Tab, Tabs, Typography, ButtonGroup } from '@mui/material';

import PaginatedPanel from 'components/pagination/PaginatedPanel';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import NotificationItem from 'pages/social/notifications/NotificationListItem';

const styles = {
  root: {
    width: '100%',
    maxWidth: '700px',
    margin: 'auto'
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px'
  },
  button: {
    textTransform: 'none',
    fontWeight: 'normal'
  }
};

function NotificationsTabbedPanel({
  currentPage,
  fetchNotificationsPage,
  patchNotification,
  markAllNotificationsAsRead,
  deleteNotification,
  deleteAllNotifications,
  fetchUnreadCount
}) {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const history = useHistory();

  function handleChange(event, newValue) {
    setSelectedTab(newValue);
    fetchNotificationsPage({ page: 0, viewed: newValue === 0 ? false : null });
  }

  let onPageSelection = useCallback(
    (page) => fetchNotificationsPage({ page, viewed: selectedTab === 0 ? false : null }),
    [fetchNotificationsPage, selectedTab]
  );

  useEffect(() => {
    onPageSelection(0);
  }, [onPageSelection]);

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        centered>
        <Tab label='Unread' />
        <Tab label='All' />
      </Tabs>
      {!currentPage.content || currentPage.content.length <= 0 ? (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='caption'>No notifications present</Typography>
        </div>
      ) : (
        <PaginatedPanel onPageSelection={onPageSelection} currentPage={currentPage} autoload>
          <Paper sx={styles.root}>
            <ButtonGroup variant='text' color='primary' size='small' sx={styles.buttonGroup}>
              <ConfirmButton
                sx={styles.button}
                message='Are you sure you want to delete all notifications?'
                onClick={() =>
                  deleteAllNotifications()
                    .then(fetchUnreadCount)
                    .then(() => onPageSelection(currentPage.page))
                }>
                Delete all
              </ConfirmButton>
              <Button
                sx={styles.button}
                onClick={() =>
                  markAllNotificationsAsRead()
                    .then(fetchUnreadCount)
                    .then(() => onPageSelection(currentPage.page))
                }>
                Mark all as read
              </Button>
            </ButtonGroup>
            <List>
              {currentPage.content.map((notification) => (
                <NotificationItem
                  key={notification.sequenceId}
                  notification={notification}
                  onClick={(link) => history.push(link)}
                  onDelete={() =>
                    deleteNotification(notification.sequenceId)
                      .then(fetchUnreadCount)
                      .then(() => onPageSelection(currentPage.page))
                  }
                  onMarkAsRead={() =>
                    patchNotification({ ...notification, viewed: true })
                      .then(fetchUnreadCount)
                      .then(() => onPageSelection(currentPage.page))
                  }
                />
              ))}
            </List>
          </Paper>
        </PaginatedPanel>
      )}
    </>
  );
}

const mapDispatchToProps = {
  fetchNotificationsPage,
  patchNotification,
  markAllNotificationsAsRead,
  deleteNotification,
  deleteAllNotifications,
  fetchUnreadCount
};

const mapStateToProps = (state) => ({
  currentPage: state.social.notifications.currentPage
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTabbedPanel);
