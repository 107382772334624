import React from 'react';

import { Card, CardContent } from '@mui/material';
import QualityProbesDefinitionsList from 'pages/quality/probes/definitions/list/QualityProbesDefinitionsList';

function ProjectProbesTab({ project }) {
  return (
    <Card>
      <CardContent>
        <QualityProbesDefinitionsList project={project} />
      </CardContent>
    </Card>
  );
}

export default ProjectProbesTab;
