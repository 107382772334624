import { Button, CircularProgress, Tab, Tabs } from '@mui/material';
import {
  deleteContractDefinition,
  fetchContractDefinition,
  modifyContractDefinition
} from 'actions/contracts/ContractsDefinitionsActions';

import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import HistoryButtonLink from 'pages/history/commons/HistoryButtonLink';
import HistoryTargetTypes from 'pages/history/commons/HistoryTargetTypes';
import Breadcrumbs from 'pages/sidebar/Navigation';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ContractConfigurationListPanel from './ContractConfigurationListPanel';
import ContractDefinitionModal from './ContractDefinitionModal';
import DefinitionDetailCardView from './DefinitionDetailCardView';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';
function DefinitionDetailPage({
  match: {
    params: { contractDefinitionUuid }
  }
}) {
  let contractDefinition = useSelector(
    (state) => state.contracts.definitions.content[contractDefinitionUuid]
  );

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: 'Contracts', to: '/contracts' },
          { text: 'Definitions', to: `/contracts/definitions` },
          { text: contractDefinition?.name || '' }
        ]}
      />
      <DefinitionDetailCard contractDefinitionUuid={contractDefinitionUuid} />

      <Tabs value='configurations' centered>
        <Tab value='configurations' label='Configurations' />
      </Tabs>

      <ContractConfigurationListPanel
        contractName={contractDefinition?.contractName}></ContractConfigurationListPanel>
    </>
  );
}

function DefinitionDetailCard({ contractDefinitionUuid }) {
  let history = useHistory();
  let { t } = useTranslation();
  let dispatch = useDispatch();
  let contractDefinition = useSelector(
    (state) => state.contracts.definitions.content[contractDefinitionUuid]
  );
  useEffect(() => {
    dispatch(fetchContractDefinition(contractDefinitionUuid));
  }, [contractDefinitionUuid, dispatch]);
  if (!contractDefinition) {
    return <CircularProgress />;
  }
  return (
    <DefinitionDetailCardView
      contractDefinition={contractDefinition}
      actions={[
        <HistoryButtonLink
          targetType={HistoryTargetTypes.CONTRACT}
          targetIdentifier={contractDefinitionUuid}
        />,
        <ModifyButton contractDefinition={contractDefinition} />,
        <ConfirmButton
          color='secondary'
          onClick={() =>
            dispatch(deleteContractDefinition(contractDefinition)).then(() => history.goBack())
          }>
          {t('commons.actions.delete')}
        </ConfirmButton>,
        <div style={{ marginLeft: 'auto' }}>
          <WebActionsButton resource={contractDefinition} page={TargetPages.CONTRACT_DETAIL_PAGE} />
        </div>
      ]}
    />
  );
}

function ModifyButton({ contractDefinition }) {
  let { t } = useTranslation();
  let dispatch = useDispatch();
  let [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      <ContractDefinitionModal
        open={open}
        contractDefinition={contractDefinition}
        onCancel={() => setOpen(false)}
        onSubmit={(modifiedContractDefinition) => {
          dispatch(modifyContractDefinition(modifiedContractDefinition)).then(() => setOpen(false));
        }}
      />
    </>
  );
}

export default DefinitionDetailPage;
