export const solveChainOfPromises = async (promises, flag) => {
  await promises.reduce(async (previousPromises, currentPromise) => {
    await previousPromises;
    if (!flag.stop) {
      currentPromise.update();
      return currentPromise.fun(currentPromise.args);
    } else {
      return Promise.reject('Stopped');
    }
  }, Promise.resolve());
};

export const handleMassiveAction = async ({
  action,
  onError,
  onActionsEnded,
  onUpdate,
  totalPages,
  pageSize,
  actionParams,
  flag
}) => {
  const chainOfPromises = Array.from({ length: totalPages }, (_, i) => {
    return {
      fun: (params) => action(params),
      update: () => onUpdate(i),
      args: { ...actionParams, page: i, size: pageSize }
    };
  });

  try {
    await solveChainOfPromises(chainOfPromises, flag);
    onActionsEnded();
  } catch (error) {
    onError(error);
  }
};
