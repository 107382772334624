import { connect } from 'react-redux';
import { fetchDataCategoriesKPIsCompilation } from '../../../actions/DataCategoriesActions';
import CompilationKPIsButton from '../../../components/compilation/CompilationKPIsButton';
import ResourceTypes from '../../../constants/ResourceTypes';

const mapStateToProps = (state, props) => ({
  filters: state.datacategories.filters.content,
  hasFilters: state.datacategories.filters.hasFilters,
  resourceType: ResourceTypes.DATA_CATEGORY
});

export default connect(mapStateToProps, {
  onFetchCompilationKPIs: fetchDataCategoriesKPIsCompilation
})(CompilationKPIsButton);
