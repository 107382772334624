import KeyIcon from '@mui/icons-material/Key';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import AdjustIcon from '@mui/icons-material/Adjust';
import HideSourceIcon from '@mui/icons-material/HideSource';

export const ConstraintTypes = {
  FOREIGN_KEY: 'FOREIGN KEY',
  PRIMARY_KEY: 'PRIMARY KEY',
  INDEX: 'INDEX',
  UNIQUE: 'UNIQUE',
  NOT_NULL: 'NOT NULL',
  CHECK: 'CHECK',
  DEFAULT: 'DEFAULT'
};

export const ConstraintTypesWithoutReferences = [
  'PRIMARY_KEY',
  'INDEX',
  'UNIQUE',
  'NOT_NULL',
  'CHECK',
  'DEFAULT'
];

export const ConstraintRules = ['NO ACTION', 'CASCADE', 'SET NULL', 'SET DEFAULT'];

export const ConstraintTypesIcons = {
  FOREIGN_KEY: <AccountTreeIcon color='primary' />,
  PRIMARY_KEY: <KeyIcon color='primary' />,
  INDEX: <ManageSearchIcon color='primary' />,
  UNIQUE: <AdjustIcon color='primary' />,
  NOT_NULL: <HideSourceIcon color='primary' />,
  CHECK: <SpellcheckIcon color='primary' />,
  DEFAULT: <DocumentScannerIcon color='primary' />
};
