import {
  FETCH_TEAM_POLICIES_LIST_BY_USER_UUID,
  FETCH_TEAM_POLICIES_LIST_BY_TEAM_CODE,
  FETCH_TEAM_POLICY,
  CREATE_TEAM_POLICY,
  DELETE_TEAM_POLICY,
  MODIFY_TEAM_POLICY
} from '../constants/ActionTypes';
import TeamPoliciesApi from '../api/TeamsPoliciesApi';
import { buildApiCallAction } from './ActionUtils';

const teamPoliciesApi = new TeamPoliciesApi();

export const fetchTeamPoliciesListByUserUuid = ({ page, size, userUuid }) =>
  buildApiCallAction({
    api: teamPoliciesApi,
    apiFunction: api => api.getTeamPoliciesByUserUuid({ page, size, userUuid }),
    actionType: FETCH_TEAM_POLICIES_LIST_BY_USER_UUID,
    payload: data => ({ ...data, userUuid })
  });

export const fetchTeamPoliciesListByTeamCode = ({ page, size, teamCode }) =>
  buildApiCallAction({
    api: teamPoliciesApi,
    apiFunction: api => api.getTeamPoliciesByTeamCode({ page, size, teamCode }),
    actionType: FETCH_TEAM_POLICIES_LIST_BY_TEAM_CODE,
    payload: data => ({ ...data, teamCode })
  });

export const fetchPolicy = policyUuid =>
  buildApiCallAction({
    api: teamPoliciesApi,
    apiFunction: api => api.getTeamPolicy(policyUuid),
    actionType: FETCH_TEAM_POLICY
  });

export const createTeamPolicy = teamPolicy =>
  buildApiCallAction({
    api: teamPoliciesApi,
    apiFunction: api => api.postTeamPolicy(teamPolicy),
    actionType: CREATE_TEAM_POLICY
  });
export const modifyTeamPolicy = teamPolicy =>
  buildApiCallAction({
    api: teamPoliciesApi,
    apiFunction: api => api.putTeamPolicy(teamPolicy),
    actionType: MODIFY_TEAM_POLICY
  });
export const deleteTeamPolicy = teamPolicy =>
  buildApiCallAction({
    api: teamPoliciesApi,
    apiFunction: api => api.deleteTeamPolicy(teamPolicy.uuid, teamPolicy.teamCode),
    actionType: DELETE_TEAM_POLICY,
    payload: {
      userUuid: teamPolicy.user.uuid,
      policyUuid: teamPolicy.uuid,
      teamCode: teamPolicy.teamCode
    }
  });
