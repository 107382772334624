import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  setContractConfigurationFilters,
  clearContractConfigurationsFilters
} from '../../../../actions/ContractsActions';
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import FilterList from '@mui/icons-material/FilterList';

class Filters extends React.Component {
  t = this.props.t;

  state = {
    open: false
  };

  handleApply() {
    let { onRefresh = () => {} } = this.props;
    onRefresh();
    this.setState({ open: false });
  }

  handleClear() {
    this.setState({ open: false });
    this.props.clearContractConfigurationsFilters();
    this.props.onRefresh();
  }

  render() {
    let { t } = this.props;
    let { contractName, lang, active, version } = this.props.filters;
    let hasFilters = contractName || lang || active || version;
    return (
      <>
        <IconButton
          color={hasFilters ? 'primary' : 'grey'}
          style={{ float: 'right' }}
          onClick={() => this.setState({ open: true })}
          size='large'>
          <FilterList />
        </IconButton>
        <Dialog open={this.state.open}>
          <DialogTitle>Contracts Configurations Filters</DialogTitle>
          <DialogContent>
            <TextField
              variant='standard'
              margin='normal'
              label='Contract Name'
              placeholder='Enter the contract name'
              value={contractName || ''}
              onChange={(event) =>
                this.props.setContractConfigurationFilters({
                  contractName: event.target.value
                })
              }
            />
            <br />
            <TextField
              variant='standard'
              margin='normal'
              label='Version'
              placeholder='Enter the version'
              value={version || ''}
              onChange={(event) =>
                this.props.setContractConfigurationFilters({
                  version: event.target.value
                })
              }
            />
            <br />
            <TextField
              variant='standard'
              margin='normal'
              label='Language'
              placeholder='Enter the language'
              value={lang || ''}
              onChange={(event) =>
                this.props.setContractConfigurationFilters({
                  lang: event.target.value
                })
              }
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={active || false}
                  onChange={(event) =>
                    this.props.setContractConfigurationFilters({
                      active: event.target.checked || null
                    })
                  }
                  value={'active'}
                />
              }
              label={'Only active contracts'}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClear()}>{t('commons.actions.clear')}</Button>
            <Button variant='outlined' color='primary' onClick={() => this.handleApply()}>
              {t('commons.actions.apply')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

Filters.propTypes = {
  onRefresh: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  filters: state.contracts.configurations.filters
});

export default _.flow([
  withTranslation(),
  connect(mapStateToProps, {
    setContractConfigurationFilters,
    clearContractConfigurationsFilters
  })
])(Filters);
