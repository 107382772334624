import DataProductsApi from 'api/DataProductsApi';
import AbstractImporter from './AbstractImporter';

export default class ImporterDataProduct extends AbstractImporter {
  static isSupported(object) {
    return object.dataProduct;
  }

  async importObject(object, resolveUuidOnly = false) {
    if (object.dataProduct) {
      return this.importDataProduct(object.dataProduct, resolveUuidOnly);
    } else {
      throw new Error('Object type not supported by ImporterDataProduct');
    }
  }

  async importDataProduct(dataProduct, resolveUuidOnly = false) {
    let api = new DataProductsApi();
    return this.importTemplate({
      object: dataProduct,
      objectName: 'name',
      objectIdentifier: 'uuid',
      api,
      search: (params) => api.getDataProducts(params),
      put: api.putDataProduct,
      post: api.postDataProduct,
      delet: api.deleteDataProduct,
      resolveUuidOnly
    });
  }
}
