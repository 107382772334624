import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, CardActions } from '@mui/material';
import DataActorView from './DataActorView';

function DataActorCardView({ dataActor, actions, children }) {
  if (dataActor) {
    return (
      <Card>
        <CardHeader title={dataActor.name} subheader={dataActor.businessName} />
        <CardContent>
          <DataActorView dataActor={dataActor} children={children} />
        </CardContent>
        {actions}
      </Card>
    );
  } else {
    return null;
  }
}

DataActorCardView.propTypes = {
  dataActor: PropTypes.object,
  actions: PropTypes.instanceOf(CardActions)
};

export default DataActorCardView;
