import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import useSearchParams from 'components/useSearchParams';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  fetchRiskCategories,
  fetchRiskDefinitionsPage
} from 'actions/quality/assessment/QualityAssessmentRiskDefinitionsActions';

import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

import { Card, CardContent, Grid, Divider, Typography } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import RiskDefinitionAddButton from 'pages/quality/assessment/risks/list/RiskDefinitionAddButton';
import RiskCategoriesList from 'pages/quality/assessment/risks/list/RiskCategoriesList';
import RiskDefinitionsList from 'pages/quality/assessment/risks/list/RiskDefinitionsList';
import RiskExportButton from './RiskExportButton';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const QualityAssessmentRisksListPage = ({
  categories,
  riskDefinitions,
  fetchRiskCategories,
  fetchRiskDefinitionsPage
}) => {
  const { t } = useTranslation();
  const query = useSearchParams();
  const history = useHistory();

  const currentCategory = query.get('tab');
  const selectedCategory =
    currentCategory && categories.includes(currentCategory) ? currentCategory : false;
  const handleChange = (_event, newValue) =>
    newValue && categories.includes(newValue)
      ? history.push(history.location.pathname + '?tab=' + newValue)
      : history.push(history.location.pathname);

  useEffect(() => {
    fetchRiskCategories();
  }, [fetchRiskCategories]);

  useEffect(() => {
    selectedCategory && selectedCategory !== 'All Categories'
      ? fetchRiskDefinitionsPage({ category: selectedCategory })
      : fetchRiskDefinitionsPage();
  }, [selectedCategory, fetchRiskDefinitionsPage]);

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.qualityAssessment'), to: '/data-quality/assessment/risks' },
          { text: t('navbar.qualityAssessmentRisks') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('quality.assessment.risks.listPageHeader'),
          category: EngagementCategories.DATA_QUALITY_ASSESSMENT,
          action: EngagementActions.LIST
        }}
      />
      <Grid container direction='row'>
        <Grid item>
          <Typography variant='h4'>{t('quality.assessment.risks.listPageHeader')}</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:riskdefinitions-list'}
            pageTitle={t('quality.assessment.risks.listPageHeader')}
            iconSize='small'
          />
        </Grid>
      </Grid>
      <Card style={{ height: '80vh' }}>
        <CardContent style={{ height: '100%' }}>
          {(!selectedCategory || selectedCategory === 'All Categories') &&
          riskDefinitions.length === 0 ? (
            <>
              <Grid container>
                <Grid item>
                  <RiskDefinitionAddButton />
                </Grid>
                <Grid item>
                  <RiskExportButton />
                </Grid>
              </Grid>
              <div style={{ textAlign: 'center', padding: '2%', marginTop: '48px' }}>
                <Typography>No risks defined. Add a new risk definition.</Typography>
              </div>
            </>
          ) : (
            <Grid container direction='row' spacing={3} style={{ height: '100%' }}>
              <Grid item xs={2} style={{ minWidth: '225px', height: '100%' }}>
                <RiskCategoriesList
                  categories={categories}
                  selectedCategory={selectedCategory}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <Divider orientation='vertical' />
              </Grid>
              <Grid item xs style={{ height: '100%' }}>
                <RiskDefinitionsList
                  category={selectedCategory}
                  riskDefinitions={riskDefinitions}
                />
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  categories: state.quality.assessment.riskDefinitions.categories,
  riskDefinitions: state.quality.assessment.riskDefinitions.currentPage
});

const mapDispatchToProps = {
  fetchRiskCategories,
  fetchRiskDefinitionsPage
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityAssessmentRisksListPage);
