import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  Tooltip
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React, { useState } from 'react';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { confirmQueryBucketStatements } from 'actions/QueryBucketsActions';
import { showMessage } from 'actions/BehaviourActions';
import SwitchPhysicalEntityLevel from './SwitchPhysicalEntityLevel';
import { handleMassiveAction } from '../../../../../components/massive_action_utils/MassiveActionUtils';

const flag = { stop: false };

const QueryStatementsConfirmAllButton = ({
  queryBucketUuid,
  currentPage,
  filters,
  onRefreshEnded
}) => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [previewOptions, setPreviewOptions] = useState({
    generateAtPhysicalEntityLevel: false
  });
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const dispatch = useDispatch();
  const pageSize = 1;
  const totalPages = Math.floor(currentPage.totalElements / pageSize) + 1;
  const progress = (currentPageNumber * 100) / totalPages;

  return (
    <>
      <IconButton
        size='small'
        style={{ marginRight: 8, marginLeft: 8 }}
        onClick={() => setOpenConfirm(true)}>
        <Tooltip title={t('querystatements.confirmAllButton.tooltipTitle')}>
          <CheckIcon />
        </Tooltip>
      </IconButton>
      <Dialog open={openConfirm} fullWidth={true}>
        <DialogContent>
          <DialogContentText>
            {t('querystatements.confirmAllButton.confirmMessage')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogContentText>
            <SwitchPhysicalEntityLevel
              previewOptions={previewOptions}
              setPreviewOptions={setPreviewOptions}
            />
          </DialogContentText>
          <Button
            children={'NO'}
            onClick={() => {
              setOpenConfirm(false);
            }}
          />
          <Button
            children={'YES'}
            onClick={() => {
              setOpen(true);
              setOpenConfirm(false);
              flag.stop = false;
              setCurrentPageNumber(0);
              handleMassiveAction({
                flag,
                totalPages,
                pageSize,
                action: (params) => dispatch(confirmQueryBucketStatements(params)),
                actionParams: { queryBucketUuid, ...filters, ...previewOptions },
                onError: (error) => {
                  setOpen(false);
                  dispatch(showMessage(error));
                },
                onActionsEnded: () => {
                  onRefreshEnded();
                  setOpen(false);
                },
                onUpdate: setCurrentPageNumber
              });
            }}
            variant='contained'
            color='primary'
          />
        </DialogActions>
      </Dialog>

      <Dialog open={open} maxWidth='md' fullWidth={true}>
        <DialogTitle>{t('querystatements.confirmAllButton.dialog.dialogTitle')}</DialogTitle>
        <DialogContent>
          <LinearProgress variant='determinate' value={progress} />
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.stop')}
            onClick={(event) => {
              event.stopPropagation();
              flag.stop = true;
            }}></Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QueryStatementsConfirmAllButton;
