import { BlindataApi } from './ApiUtils';

const AGENT_URL = '/api/v1/settings/agents';

export default class AgentsApi extends BlindataApi {
  getAgentsPage(params) {
    return this.get(AGENT_URL, params);
  }

  getAgent(uuid) {
    return this.get(`${AGENT_URL}/${uuid}`);
  }

  postAgent(agent) {
    return this.post(AGENT_URL, agent);
  }

  putAgent(agent) {
    return this.put(`${AGENT_URL}/${agent.uuid}`, agent);
  }

  deleteAgent(uuid) {
    return this.delete(`${AGENT_URL}/${uuid}`);
  }
}
