import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { CircularProgress, Card, CardHeader } from '@mui/material';
import { Chart } from 'react-google-charts';
import { withLoader } from '../../../components/loader/Loader';
import { fetchConsentsEventsLineChart } from '../../../actions/DashboardsActions';
import ConsentsEventsLineChartFilters from './ConsentsEventsLineChartFilters';

const ConsentsEventsLineCharts = (props) => (
  <Card style={{ margin: '8px 0 8px 0' }}>
    <CardHeader
      title='Consents Events Timeline'
      subheader={subheader(props)}
      action={<ConsentsEventsLineChartFilters />}></CardHeader>
    {props.columns.length > 0 ? (
      <Chart
        {...{
          chartType: 'LineChart',
          columns: props.columns,
          rows: props.rows.map((r) => [new Date(r[0]), r[1], r[2]]),
          options: {
            legend: true,
            hAxis: { title: 'Time' },
            vAxis: { title: 'Consents' },
            lineWidth: 4,
            colors: ['#00aeca', '#455a64']
          },
          width: '100%'
        }}
      />
    ) : (
      <CircularProgress style={{ marginLeft: '50%', marginRight: '50%' }} />
    )}
  </Card>
);

function subheader(props) {
  let sections = [subheaderTimeWindow(props), subheaderProcessings(props)];
  return sections.filter((s) => s).join(', ');
}

function subheaderTimeWindow({ startDate, endDate }) {
  if (startDate && !endDate) {
    return `Last ${computeDayDiff(startDate, endDate)} days`;
  } else if (startDate && endDate) {
    return `From ${startDate} to ${endDate}`;
  } else if (endDate) {
    return `Up to ${endDate}`;
  } else {
    return null;
  }
}

function subheaderProcessings({ processings }) {
  if (processings && processings.length > 0) {
    return `Processings: ${processings.map((p) => p.name).join(', ')}`;
  } else {
    return null;
  }
}

function computeDayDiff(startDate, endDate) {
  var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  var firstDate = new Date(startDate);
  var secondDate = new Date(endDate || new Date().toISOString());
  var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));
  return diffDays;
}

const mapStateToProps = (state) => ({
  columns: state.dashboards.consentsEvents.lineChart.columns,
  rows: state.dashboards.consentsEvents.lineChart.rows,
  processings: state.dashboards.consentsEvents.lineChartFilters.processings,
  startDate: state.dashboards.consentsEvents.lineChartFilters.startDate,
  endDate: state.dashboards.consentsEvents.lineChartFilters.endDate
});

export default _.flow([
  withLoader({
    onLoad: ({ fetchConsentsEventsLineChart, rows }) => {
      if (rows.length === 0) {
        fetchConsentsEventsLineChart();
      }
    }
  }),
  connect(mapStateToProps, { fetchConsentsEventsLineChart })
])(ConsentsEventsLineCharts);
