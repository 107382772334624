import SettingsConfigurationsApi from '../api/SettingsConfigurationsApi';
import { actionsBuilder } from './ActionUtils';

const api = new SettingsConfigurationsApi();
export default actionsBuilder({
  resource: 'SETTINGS_CONFIGURATION',
  api,
  apiMapping: {
    fetch: api.getSettingsConfiguration,
    fetchPage: api.getSettingsConfigurations,
    search: api.getSettingsConfigurations,
    create: api.postSettingsConfiguration,
    modify: api.putSettingsConfiguration,
    delete: api.deleteSettingsConfiguration
  }
});
