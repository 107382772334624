import React from 'react';
import { Route, Switch } from 'react-router';
import PhysicalModelNavbar from '../PhysicalModelNavbar';
import DataProductDetailPage from './detail/DataProductDetailPage';
import DataProductsListPage from './list/DataProductsListPage';

const DataProductsRoutes = (props) => (
  <>
    <PhysicalModelNavbar />
    <Switch>
      <Route exact path={`${props.match.url}/list`} component={DataProductsListPage} />
      <Route
        exact
        path={`${props.match.url}/detail/:dataProductUuid`}
        component={DataProductDetailPage}
      />
    </Switch>
  </>
);

export default DataProductsRoutes;
