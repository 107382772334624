import { BlindataApi } from './ApiUtils';

const RPA_ARCHIVES_URL = '/api/v1/reports/rpa/archives';

export default class RpaArchivesApi extends BlindataApi {
  getRpaArchives(params) {
    return this.get(RPA_ARCHIVES_URL, params);
  }

  getRpaArchive(uuid) {
    return this.get(`${RPA_ARCHIVES_URL}/${uuid}`);
  }

  postRpaArchive(rpaArchive) {
    return this.post(RPA_ARCHIVES_URL, rpaArchive);
  }

  putRpaArchive(rpaArchive) {
    return this.put(`${RPA_ARCHIVES_URL}/${rpaArchive.uuid}`, rpaArchive);
  }

  deleteRpaArchive(uuid) {
    return this.delete(`${RPA_ARCHIVES_URL}/${uuid}`);
  }

  postRpaArchiveRecord(rpaArchiveUuid, rpaArchiveRecord) {
    return this.post(`${RPA_ARCHIVES_URL}/${rpaArchiveUuid}/records`, rpaArchiveRecord);
  }

  getRpaArchiveRecords(rpaArchiveUuid, params) {
    return this.get(`${RPA_ARCHIVES_URL}/${rpaArchiveUuid}/records`, params);
  }

  downloadRpaArchiveRecord(uuid) {
    return this.download(`${RPA_ARCHIVES_URL}/*/records/${uuid}/download`);
  }

  deleteRpaArchiveRecord(uuid) {
    return this.delete(`${RPA_ARCHIVES_URL}/*/records/${uuid}`);
  }
}
