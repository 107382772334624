import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ReportIcon from '@mui/icons-material/Report';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';

export const QueryStatementStatusIcons = {
  ANALYZED: <SpellcheckIcon fontSize='small' style={{ color: '#00aeca' }}></SpellcheckIcon>,
  ERROR: <ReportIcon style={{ color: '#d91e48' }}></ReportIcon>,
  PARTIALLY_ANALYZED: <ReportProblemIcon style={{ color: '#e9a227' }}></ReportProblemIcon>,
  IGNORED: <BlockIcon style={{ color: '#bfbe5c' }}></BlockIcon>,
  CONFIRMED: <CheckCircleOutlineIcon style={{ color: 'green' }}></CheckCircleOutlineIcon>,
  PENDING: <PauseCircleFilledIcon style={{ color: '##7734e3' }}></PauseCircleFilledIcon>
};
