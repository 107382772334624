import React from 'react';
import { Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
import { modifyQualitySuite, deleteQualitySuite } from 'actions/QualitySuitesActions';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import ConfirmButton from '../../../../../components/confirmbutton/ConfirmButton';
import ModifyAction from '../commons/ModifyAction';
import Authorize from '../../../../../components/permissions/Authorize';
import Permissions from '../../../../../constants/Permissions';
import WatchlistIconButton from 'pages/social/watchlist/WatchlistIconButton';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import TeamCodeDecorator from 'pages/settings/teams/common/TeamCodeDecorator';

import CardDescription from 'components/carddescription/CardDescription';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

function QualitySuiteDetailCard({ qualitySuite, modifyQualitySuite, deleteQualitySuite }) {
  const cardStyle = {
    margin: '8px 0 8px 0',
    padding: 8
  };

  let history = useHistory();
  return (
    <Card style={cardStyle}>
      <CardHeader
        title={qualitySuite.name}
        subheader={qualitySuite.code}
        action={
          <Grid
            container
            justifyContent='flex-end'
            direction='row'
            spacing={2}
            alignItems='baseline'>
            <Grid item>
              {<TeamCodeDecorator teamCode={qualitySuite.teamCode}></TeamCodeDecorator>}
            </Grid>
            <Grid item>
              {!qualitySuite.published && (
                <VariationTypeDecorator>{'DRAFT'}</VariationTypeDecorator>
              )}
            </Grid>
            <Grid>
              <WatchlistIconButton
                entityIdentifier={qualitySuite.uuid}
                entityType={'QUALITY_SUITE'}></WatchlistIconButton>
            </Grid>
          </Grid>
        }
      />

      <CardContent>
        <CardDescription>{qualitySuite.description}</CardDescription>
      </CardContent>
      <CardActions>
        <Authorize hasPermissions={[Permissions.QUALITY_ADMIN]}>
          <ModifyAction qualitySuite={qualitySuite} modifyQualitySuite={modifyQualitySuite} />
        </Authorize>
        <Authorize hasPermissions={[Permissions.QUALITY_ADMIN]}>
          <ConfirmButton
            onClick={() => {
              deleteQualitySuite(qualitySuite.uuid).then(() =>
                history.push('/data-quality/kqis/suites/')
              );
            }}>
            Delete
          </ConfirmButton>
        </Authorize>
        <div style={{ marginLeft: 'auto' }}>
          <WebActionsButton resource={qualitySuite} page={TargetPages.QUALITY_SUITE_DETAIL_PAGE} />
        </div>
      </CardActions>
    </Card>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  modifyQualitySuite,
  deleteQualitySuite
};

export default connect(mapStateToProps, mapDispatchToProps)(QualitySuiteDetailCard);
