import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createTag } from 'actions/quality/probes/ProbesTagsActions';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { IconButton, Tooltip } from '@mui/material';
import QualityProbesTagModal from './QualityProbesTagModal';

function QualityProbesTagAddButton({ createTag, project }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title={'Create Tag'}>
        <IconButton onClick={() => setOpen(true)} size='medium'>
          <AddBoxIcon></AddBoxIcon>
        </IconButton>
      </Tooltip>
      <QualityProbesTagModal
        title='Create Tag'
        open={open}
        project={project}
        onClose={() => setOpen(false)}
        onSubmit={(tag) => {
          createTag(tag);
          setOpen(false);
        }}></QualityProbesTagModal>
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.PROBES_EDITOR] }),
  connect(null, { createTag })
)(QualityProbesTagAddButton);
