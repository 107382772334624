import InputIcon from '@mui/icons-material/Input';
import OutputIcon from '@mui/icons-material/Output';
import SearchIcon from '@mui/icons-material/Search';
import PreviewIcon from '@mui/icons-material/Preview';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';

export const DataProductPortEntityTypes = {
  inputPorts: 'Input Ports',
  outputPorts: 'Output Ports',
  discoveryPorts: 'Discovery Ports',
  observabilityPorts: 'Observability Ports',
  controlPorts: 'Control Ports'
};

export const DataProductPortEntityTypesIcons = {
  inputPorts: <InputIcon />,
  outputPorts: <OutputIcon />,
  discoveryPorts: <SearchIcon />,
  observabilityPorts: <PreviewIcon />,
  controlPorts: <ControlCameraIcon />
};
