import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import HelpIcon from 'components/help/HelpIcon';
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField,
  Button,
  Switch,
  FormControlLabel
} from '@mui/material';

const INITIAL_STATE = {
  code: '',
  name: '',
  description: '',
  doubleApproval: false
};

class TeamEditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = props.team ? { ...INITIAL_STATE, ...props.team } : INITIAL_STATE;
  }

  state = INITIAL_STATE;

  render() {
    let { team, open, handleCancel, title, handleSubmit } = this.props;
    return (
      <Fragment>
        <Dialog open={open} onClose={handleCancel} fullWidth>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <h4 style={{ marginTop: '8px' }}>General</h4>
            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              placeholder="Enter the Team's Code"
              label='Code'
              inputProps={{
                maxlength: 5
              }}
              helperText={`${this.state.code.length}/${5}`}
              required={true}
              value={this.state.code}
              onChange={(event) =>
                this.setState({ code: event.target.value.toUpperCase().replace(/[\W_]+/g, '') })
              }
              disabled={team}
              error={!this.state.code ? 'Required' : null}
            />
            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              placeholder="Enter the Team's Name"
              label='Name'
              required={true}
              value={this.state.name}
              onChange={(event, newValue) => this.setState({ name: event.target.value })}
            />
            <br />
            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              placeholder="Enter the Team's Description"
              label='Description'
              multiline
              rows='4'
              value={this.state.description}
              onChange={(event, newValue) => this.setState({ description: event.target.value })}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.doubleApproval}
                  color='primary'
                  onChange={(event, checked) =>
                    this.setState({ doubleApproval: event.target.checked })
                  }></Switch>
              }
              label='Double approval'
            />
            <HelpIcon tooltip='All the actions in this team will require a double approvation, one from an user that can approve in the team, and another one that can approve in the entire tenant'></HelpIcon>
            <br />

            <DialogActions>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button
                variant='contained'
                disabled={!this.state.name || !this.state.code}
                color='primary'
                onClick={() => handleSubmit(this.state)}>
                Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

TeamEditorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default TeamEditorModal;
