import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import withConfirmation from './withConfirmation';

const ConfirmMenuItem = withConfirmation(MenuItem);

ConfirmMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  message: PropTypes.string
};

export default ConfirmMenuItem;
