import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createQualityCheck } from 'actions/QualityChecksActions';
import QualityCheckModal from '../commons/QualityCheckModal';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import { IconButton, Tooltip } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

function QualityCheckAddAction({ qualitySuite, createQualityCheck }) {
  const [open, setOpen] = useState(false);

  let history = useHistory();
  return (
    <Authorize hasPermissions={[Permissions.QUALITY_EDITOR]}>
      <Tooltip title='Create check'>
        <IconButton size='large'>
          <AddBoxIcon aria-label='add quality check' onClick={() => setOpen(true)}></AddBoxIcon>
        </IconButton>
      </Tooltip>
      {open && (
        <QualityCheckModal
          open={open}
          qualitySuite={qualitySuite}
          onSubmit={(qualityCheck) => {
            qualityCheck = {
              qualitySuite: qualitySuite,
              ...qualityCheck
            };
            createQualityCheck(qualityCheck).then((qualityCheck) => {
              if (qualityCheck) {
                setOpen(false);
                history.push(`/data-quality/kqis/checks/${qualityCheck.uuid}`);
              }
            });
          }}
          onClose={() => setOpen(false)}
        />
      )}
    </Authorize>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  createQualityCheck
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityCheckAddAction);
