import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';

import { fetchSchedule } from 'actions/onagent/AgentScheduleActions';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';
import { connect } from 'react-redux';
import AgentItemAutocomplete from 'pages/settings/agents/commons/AgentItemAutocomplete';
import CrawlingScheduleDetailCard from './CrawlingScheduleDetailCard';
import CrawlingAddButton from './CrawlingAddButton';
import PaginatedPanel from 'components/pagination/PaginatedPanel';

function SystemCrawlingCard({ fetchSchedule, system, jobDefinitionsPage }) {
  const [selectedAgent, setSelectedAgent] = useState(null);
  return (
    <div>
      <Grid container alignItems='stretch' direction='row'>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item style={{ minWidth: '20%' }}>
          <AgentItemAutocomplete
            margin='dense'
            value={selectedAgent}
            onChange={(agent) => {
              setSelectedAgent(agent);
              agent && fetchSchedule(agent, { externalIdentifier: system.uuid });
            }}></AgentItemAutocomplete>
        </Grid>
      </Grid>
      {!selectedAgent && jobDefinitionsPage?.content?.length < 1 && (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='subtitle2' align='center' style={{ marginTop: '20px' }}>
            Select an agent
          </Typography>
        </div>
      )}
      {jobDefinitionsPage?.content?.length > 0 && selectedAgent ? (
        <PaginatedPanel
          currentPage={jobDefinitionsPage}
          onPageSelection={(page) =>
            selectedAgent && fetchSchedule(selectedAgent, { externalIdentifier: system.uuid, page })
          }>
          {jobDefinitionsPage.content
            .filter((job) => job.externalIdentifier === system.uuid)
            .map((job) => (
              <CrawlingScheduleDetailCard
                key={job.uuid}
                system={system}
                jobDefinition={job}
                agent={selectedAgent}
                setAgent={setSelectedAgent}
              />
            ))}
        </PaginatedPanel>
      ) : (
        selectedAgent && (
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>No Job scheduled for this project</Typography>
            <CrawlingAddButton system={system} agent={selectedAgent} />
          </div>
        )
      )}
      {jobDefinitionsPage?.content?.length > 0 && selectedAgent && (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <CrawlingAddButton system={system} agent={selectedAgent} />
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state, { system: { uuid: systemUuid } }) {
  return {
    jobDefinitionsPage: state.settings.agents.schedule.pageByExternalIdentifier[systemUuid]
  };
}
export default compose(
  withPermissions({ hasPermissions: [Permissions.AGENTS_VIEWER] }),
  connect(mapStateToProps, {
    fetchSchedule
  })
)(SystemCrawlingCard);
