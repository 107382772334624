import * as React from "react";
const SvgFirebird = (props) => (
  <svg
    height="1em"
    viewBox="0 0 353 353"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <linearGradient id="Firebird_svg__a" gradientUnits="userSpaceOnUse">
      <stop offset={0} stopColor="#f40a0b" />
      <stop offset={1} stopColor="#f5e710" />
    </linearGradient>
    <linearGradient
      id="Firebird_svg__b"
      x1={86.36}
      x2={86.36}
      xlinkHref="#Firebird_svg__a"
      y1={337.96}
      y2={7.12}
    />
    <linearGradient
      id="Firebird_svg__c"
      x1={216.11}
      x2={216.11}
      xlinkHref="#Firebird_svg__a"
      y1={348.99}
      y2={7.28}
    />
    <switch>
      <g clipRule="evenodd" fillRule="evenodd">
        <path d="M353 353H0V0h353z" fill="none" />
        <g fill="#111">
          <path d="M80.704 271.88c3.24 25.704 17.208 45.072 38.592 64.08l-7.704-3.888C50.824 305.936 8.344 245.527 8.344 175.256c0-89.28 68.904-163.512 158.04-170.136C77.248 11.744 8.344 85.976 8.344 175.256c0-89.28 68.904-163.512 158.04-170.136C64.288 25.28-33.56 143.216 80.704 271.88z" />
          <path d="M313.769 120.896C306.064 65.672 263.656 27.008 186.4 4.832c91.152 4.032 163.081 79.2 163.081 170.424 0 94.248-70.992 167.112-163.225 171.288-8.712.433-17.424-1.08-25.92-3.744V150.56c2.808-4.248 2.808-8.496 0-12.744V96.92h9.432c-3.672-2.448-6.768-4.32-9.432-5.472V79.64c49.464 18.864 72.433 21.024 68.977 6.264 4.319 12.168.576 17.712-11.232 16.488-13.608-2.088-22.032-1.152-25.344 2.88 24.408 12.096 37.44 23.832 39.096 35.208 2.304 19.152-6.768 40.248-27.216 63.36-12.888 16.057-6.192 33.12 14.615 35.855 64.583-3.168 96.121-42.839 94.537-118.799zM160.336 342.8c-19.728-6.119-37.44-18.432-46.08-27-15.264-15.84-25.632-41.184-29.448-77.76 0-.216-.072-.433-.072-.647v-12.385c2.376-29.376 21.96-50.04 58.896-61.92 8.352-4.104 13.968-8.28 16.704-12.528zm0-204.984c-1.152-1.872-2.952-3.744-5.184-5.688-2.016-2.016-6.336-4.032-12.888-5.976-14.976-.432-23.112 3.312-24.408 11.304-25.416-11.304-26.136-24.192-2.232-38.592 4.68-1.8 11.52-2.448 20.592-2.088-3.744-16.56 2.592-22.968 19.08-19.08 1.728.648 3.384 1.296 5.04 1.944v11.808c-7.56-3.528-10.512-1.728-8.928 5.472h8.928z" />
        </g>
        <path
          d="M79.704 273.88c3.24 25.704 17.208 45.072 38.592 64.08l-7.704-3.888C49.824 307.936 7.344 247.527 7.344 177.256c0-89.28 68.904-163.512 158.04-170.136C76.248 13.744 7.344 87.976 7.344 177.256c0-89.28 68.904-163.512 158.04-170.136C63.288 27.28-34.56 145.216 79.704 273.88z"
          fill="url(#Firebird_svg__b)"
        />
        <path
          d="M312.769 122.896C305.064 67.672 262.656 29.008 185.4 6.832c91.152 4.032 163.081 79.2 163.081 170.424 0 94.248-70.992 167.112-163.225 171.288-8.712.433-17.424-1.08-25.92-3.744V152.56c2.808-4.248 2.808-8.496 0-12.744V98.92h9.432c-3.672-2.448-6.768-4.32-9.432-5.472V81.64c49.464 18.864 72.433 21.024 68.977 6.264 4.319 12.168.576 17.712-11.232 16.488-13.608-2.088-22.032-1.152-25.344 2.88 24.408 12.096 37.44 23.832 39.096 35.208 2.304 19.152-6.768 40.248-27.216 63.36-12.888 16.057-6.192 33.12 14.616 35.855 64.582-3.168 96.12-42.839 94.536-118.799zM159.336 344.8c-19.728-6.119-37.44-18.432-46.08-27-15.264-15.84-25.632-41.184-29.448-77.76 0-.216-.072-.433-.072-.647v-12.385c2.376-29.376 21.96-50.04 58.896-61.92 8.352-4.104 13.968-8.28 16.704-12.528zm0-204.984c-1.152-1.872-2.952-3.744-5.184-5.688-2.016-2.016-6.336-4.032-12.888-5.976-14.976-.432-23.112 3.312-24.408 11.304-25.416-11.304-26.136-24.192-2.232-38.592 4.68-1.8 11.52-2.448 20.592-2.088-3.744-16.56 2.592-22.968 19.08-19.08 1.728.648 3.384 1.296 5.04 1.944v11.808c-7.56-3.528-10.512-1.728-8.928 5.472h8.928z"
          fill="url(#Firebird_svg__c)"
        />
      </g>
    </switch>
  </svg>
);
export default SvgFirebird;
