import * as React from "react";
const SvgMicrosoftPowerBi = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 630 630"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="MicrosoftPowerBI_svg__a"
      >
        <stop stopColor="#EBBB14" offset="0%" />
        <stop stopColor="#B25400" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="MicrosoftPowerBI_svg__d"
      >
        <stop stopColor="#F9E583" offset="0%" />
        <stop stopColor="#DE9800" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="MicrosoftPowerBI_svg__e"
      >
        <stop stopColor="#F9E68B" offset="0%" />
        <stop stopColor="#F3CD32" offset="100%" />
      </linearGradient>
      <filter
        x="-9.1%"
        y="-6.3%"
        width="136.5%"
        height="116.9%"
        filterUnits="objectBoundingBox"
        id="MicrosoftPowerBI_svg__b"
      >
        <feOffset
          dx={20}
          dy={10}
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur
          stdDeviation={10}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0530211976 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="M346 604v26H153c-14.36 0-26-11.64-26-26V183c0-14.36 11.64-26 26-26h167c14.36 0 26 11.64 26 26v421Z"
        id="MicrosoftPowerBI_svg__c"
      />
    </defs>
    <g transform="translate(77.5)" fill="none" fillRule="evenodd">
      <rect
        fill="url(#MicrosoftPowerBI_svg__a)"
        x={256}
        width={219}
        height={630}
        rx={26}
      />
      <use
        fill="#000"
        filter="url(#MicrosoftPowerBI_svg__b)"
        xlinkHref="#MicrosoftPowerBI_svg__c"
      />
      <use
        fill="url(#MicrosoftPowerBI_svg__d)"
        xlinkHref="#MicrosoftPowerBI_svg__c"
      />
      <path
        d="M219 604v26H26c-14.36 0-26-11.64-26-26V341c0-14.36 11.64-26 26-26h167c14.36 0 26 11.64 26 26v263Z"
        fill="url(#MicrosoftPowerBI_svg__e)"
      />
    </g>
  </svg>
);
export default SvgMicrosoftPowerBi;
