import React from 'react';
import _ from 'lodash';
import { Grid, TextField } from '@mui/material';
import SystemItemAutoComplete from '../../systems/commons/SystemItemAutoComplete';
import PhysicalEntityItemAutoComplete from '../../systems/physical_entities/commons/PhysicalEntityItemAutoComplete';
import { ChipWrapper, ChipItem } from '../../../components/chiplist/ChipList';
import DataCategorySearchModal from '../../datacategories/utils/DataCategorySearchModal';
import LogicalFieldSearchModal from '../../datacategories/utils/LogicalFieldSearchModal';
import { withTranslation } from 'react-i18next';
import PhysicalFieldItemSelect from '../../systems/physical_entities/commons/PhysicalFieldItemSelect';

const DataFlowEditor = ({ editor, onChange, t }) => (
  <>
    <h4>General</h4>
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      label={t('dataflows.fields.name')}
      value={editor.name || ''}
      onChange={(event) => onChange({ name: event.target.value })} />
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      label={t('dataflows.fields.scope')}
      value={editor.scope || ''}
      onChange={(event) => onChange({ scope: event.target.value })} />
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      label={t('dataflows.fields.description')}
      value={editor.description || ''}
      onChange={(event) => onChange({ description: event.target.value })} />
    <Grid container>
      <Grid item xs>
        <h4>{t('dataflows.fields.from')}</h4>
        <SystemItemAutoComplete
          key={_.get(editor, 'fromSystem.uuid')}
          value={editor.fromSystem}
          onChange={(system) =>
            onChange({
              fromSystem: system,
              fromPhysicalEntity: null,
              fromPhysicalField: null
            })
          }
        />
        <PhysicalEntityItemAutoComplete
          key={_.get(editor, 'fromPhysicalEntity.uuid')}
          value={editor.fromPhysicalEntity}
          systemUuid={_.get(editor, 'fromSystem.uuid')}
          onChange={(fromPhysicalEntity) =>
            onChange({ fromPhysicalEntity, fromPhysicalField: null })
          }
        />
        <PhysicalFieldItemSelect
          value={editor.fromPhysicalField}
          physicalEntityUuid={_.get(editor, 'fromPhysicalEntity.uuid')}
          onChange={(fromPhysicalField) => onChange({ fromPhysicalField })}
        />
      </Grid>
      <Grid item xs>
        <h4>{t('dataflows.fields.to')}</h4>
        <SystemItemAutoComplete
          key={_.get(editor, 'toSystem.uuid')}
          value={editor.toSystem}
          onChange={(toSystem) =>
            onChange({
              toSystem,
              toPhysicalEntity: null,
              toPhysicalField: null
            })
          }
        />
        <PhysicalEntityItemAutoComplete
          key={_.get(editor, 'toPhysicalEntity.uuid')}
          value={editor.toPhysicalEntity}
          systemUuid={_.get(editor, 'toSystem.uuid')}
          onChange={(toPhysicalEntity) => onChange({ toPhysicalEntity, toPhysicalField: null })}
        />
        <PhysicalFieldItemSelect
          value={editor.toPhysicalField}
          physicalEntityUuid={_.get(editor, 'toPhysicalEntity.uuid')}
          onChange={(toPhysicalField) => onChange({ toPhysicalField })}
        />
      </Grid>
    </Grid>
    <h4>{t('dataflows.fields.aboutDataCategories')}</h4>
    <ChipWrapper onRequestAdd={() => onChange({ _dcOpen: true })}>
      {(editor.dataCategories || []).map((dc) => (
        <ChipItem
          key={dc.uuid}
          id={dc.uuid}
          label={dc.name}
          tooltip={dc.description}
          onRequestDelete={() =>
            onChange({
              dataCategories: editor.dataCategories.filter((e) => e.uuid !== dc.uuid)
            })
          }
        />
      ))}
    </ChipWrapper>
    <DataCategorySearchModal
      open={editor._dcOpen || false}
      onCancel={() => onChange({ _dcOpen: false })}
      onSubmit={(dc) => {
        onChange({
          _dcOpen: false,
          dataCategories: (editor.dataCategories || []).concat([dc])
        });
      }}
    />

    <h4>{t('dataflows.fields.aboutLogicalFields')}</h4>
    <ChipWrapper onRequestAdd={() => onChange({ _lfOpen: true })}>
      {(editor.logicalFields || []).map((lf) => (
        <ChipItem
          key={lf.uuid}
          id={lf.uuid}
          label={lf.name}
          tooltip={lf.description}
          onRequestDelete={() =>
            onChange({
              logicalFields: editor.logicalFields.filter((e) => e.uuid !== lf.uuid)
            })
          }
        />
      ))}
    </ChipWrapper>
    <LogicalFieldSearchModal
      open={editor._lfOpen || false}
      onCancel={() => onChange({ _lfOpen: false })}
      onSubmit={(lf) => {
        onChange({
          _lfOpen: false,
          logicalFields: (editor.logicalFields || []).concat([lf])
        });
      }}
    />
  </>
);

export default withTranslation()(DataFlowEditor);
