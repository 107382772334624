import { AddFAB } from 'components/fab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Authorize from '../../../components/permissions/Authorize';
import DataCategoryCreateModal from '../commons/DataCategoryCreateModal';
import { createDataCategory } from 'actions/DataCategoriesActions';
import Permissions from 'constants/Permissions';

function DataCategoryCreateFAB({ createDataCategory }) {
  let [open, setOpen] = useState(false);
  let { t } = useTranslation();
  let history = useHistory();
  function handleCreateDataCategory(dc) {
    createDataCategory(dc).then((dc) => {
      if (dc) {
        history.push(`/datacategories/${dc.uuid}`);
      }
    });
  }
  return (
    <>
      <Authorize hasPermissions={[Permissions.DATA_CATEGORIES_ADMIN]}>
        <AddFAB id='datacategory-create-button' onClick={() => setOpen(true)} />
      </Authorize>

      <DataCategoryCreateModal
        title={t('data_categories.create_title')}
        open={open}
        handleSubmit={(dc) => handleCreateDataCategory(dc)}
        handleCancel={() => setOpen(false)}
      />
    </>
  );
}

export default connect(null, { createDataCategory })(DataCategoryCreateFAB);
