import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { InputAdornment, TextField, Typography, Card, CardContent } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { t } from 'i18next';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { search } from 'actions/UniversalSearchActions';
import useTrackingInfo from 'components/engagement/useTrackingInfo';
import { getRecentlyViewed } from 'actions/EngagementDashboardsActions';
import { ResourceTypesIcons } from 'constants/ResourceTypesIcons';
import { RecentsList } from './RecentsList';
import { ResourceButtonList } from './ResourceButtonList';
import { SearchResultsList } from './SearchResultsList';
import { createUrlItem } from 'pages/Routes';
import { NavigableResultsCollection } from './NavigableResultsCollection';

export default function GlobalSearch({ WrapperComponent, onExit }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userTrackingId } = useTrackingInfo();

  const [inputText, setInputText] = useState();
  const [selectedResources, setSelectedResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const searchResults = useSelector((state) => state.settings.search.content);
  const recents = useSelector((state) => state.engagement.recentlyViewed.content);

  const groupedSearchResult = _.groupBy(searchResults, 'resourceType');

  const searchResultsCollection = useMemo(
    () =>
      new NavigableResultsCollection(
        Object.values(groupedSearchResult).flat(), //maintains elements order
        setSelectedItem
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchResults, selectedResources]
  );

  const recentsCollection = useMemo(
    () => new NavigableResultsCollection(recents, setSelectedItem),
    [recents]
  );

  //Fetching search results
  useEffect(() => {
    setLoading(true);
    dispatch(search({ search: inputText, resourceTypes: selectedResources })).then(() =>
      setLoading(false)
    );
  }, [dispatch, inputText, selectedResources]);

  //Fetching recently viewed
  useEffect(() => {
    dispatch(getRecentlyViewed({ userTrackingId: userTrackingId }));
  }, [dispatch, userTrackingId]);

  //Resetting selected result after a search params change
  useEffect(() => {
    inputText
      ? setSelectedItem(searchResultsCollection.getFirst())
      : setSelectedItem(recentsCollection.getFirst());
  }, [inputText, recentsCollection, searchResultsCollection]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let onSetInputText = useCallback(_.debounce(setInputText, 300), [setInputText]);

  function handleKeyDown(event) {
    if (event.key === 'ArrowDown') {
      inputText
        ? setSelectedItem(searchResultsCollection.nextItem(selectedItem))
        : setSelectedItem(recentsCollection.nextItem(selectedItem));
    }
    if (event.key === 'ArrowUp') {
      inputText
        ? setSelectedItem(searchResultsCollection.prevItem(selectedItem))
        : setSelectedItem(recentsCollection.prevItem(selectedItem));
    }
    if (event.key === 'Enter') {
      if (selectedItem) {
        if (inputText) {
          const url = createUrlItem(selectedItem.resourceType, selectedItem.resourceIdentifier);
          history.push(url);
        } else {
          history.push(selectedItem.pagePath);
        }
        if (_.isFunction(onExit)) {
          onExit();
        }
      }
    }
  }

  return (
    <WrapperComponent>
      <Card style={{ marginBottom: '32px' }}>
        <CardContent>
          <TextField
            autoFocus
            onKeyDown={(event) => {
              handleKeyDown(event);
            }}
            style={{ paddingBottom: '16px' }}
            fullWidth
            placeholder='Search...'
            variant='outlined'
            onChange={(event) => onSetInputText(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon color='disabled' />
                </InputAdornment>
              )
            }}
          />
          <Typography variant='caption'>{t('searchWidget.resourceTip')}</Typography>
          <ResourceButtonList
            setSelectedResources={setSelectedResources}
            selectedResources={selectedResources}
            resourceTypesIcons={ResourceTypesIcons}
            pageSize={4}
          />
        </CardContent>
      </Card>

      {!inputText && recents && recents.length > 0 && (
        <RecentsList
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          options={recents}
          title={t('searchWidget.recents')}
          onItemClick={onExit}
        />
      )}
      {inputText && (
        <SearchResultsList
          filteredGroupedOptions={groupedSearchResult}
          isLoading={loading}
          title={t('searchWidget.results')}
          resourceTypesIcons={ResourceTypesIcons}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          onItemClick={onExit}
        />
      )}
    </WrapperComponent>
  );
}
