import _ from 'lodash';
import dagre from 'dagre';
import { MarkerType } from 'reactflow';
import { toPng } from 'html-to-image';

const edgeDefaultStyle = {
  strokeWidth: 1
}

const edgeDefaultMarkerEnd = {
  type: MarkerType.ArrowClosed,
  width: 15,
  height: 15
}

// Update nodes & edges format to match the one required by ReactFlow
export const computeGraph = (nodes, edges) => {

  const styledNodes = nodes.map(node => {
    return ({
      id: node.nodeId,
      data: _.clone(node),
      type: 'custom'
    })
  });

  const styledEdges = edges.map(edge => {
    return ({
      id: edge.constraintUuid,
      source: edge.sourceNodeId.split('#')[0],  // id del nodo padre
      sourceHandle: `from.${edge.sourceNodeId}`,  // id del nodo figlio
      target: edge.targetNodeId.split('#')[0],  // ide del nodo padre
      targetHandle: `to.${edge.targetNodeId}`,  // id del nodo figlio
      data: _.clone(edge),
      type: 'smoothstep',
      markerEnd: edgeDefaultMarkerEnd,
      style: edgeDefaultStyle
    })
  })

  return { nodes: styledNodes, edges: styledEdges };
}

export const computeLayout = (nodes, edges, collapsed = false) => {

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: 'LR' });
  nodes.forEach((node) => {
    const titleChars = node.data.name.length;
    const longestContentChars = Math.max(...node.data.fields?.map((field) => field.name.length + (field.type?.length || 0)))
    const nodeWidth = Math.max(titleChars * 10, longestContentChars * 10 + 24 + 50);
    const nodeHeight = collapsed ? 32 : 32 + 26 * node.data.fields?.length;
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });
  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = 'left';
    node.sourcePosition = 'right';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the ReactFlow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWithPosition.width / 2,
      y: nodeWithPosition.y - nodeWithPosition.height / 2
    };

    return node;
  });

  return { nodes, edges };
};

export const emphasizeNodes = (nodes, edge) => {
  return nodes.map(n => {
    if (n.id === edge.source || n.id === edge.target) {
      const highlightedFields = n.data.fields?.map(f => {
        if (f.nodeId === edge.data.sourceNodeId || f.nodeId === edge.data.targetNodeId) {
          return { ...f, highlighted: true }
        } else {
          return f;
        }
      });
      n = { ...n, data: { ...n.data, fields: highlightedFields } }
    }
    return n;
  });
}

export const emphasizeEdge = (edge) => {
  return {
    ...edge,
    style: { ...edge.style, stroke: '#00aeca' },
    markerEnd: { ...edge.markerEnd, color: '#00aeca' }
  }
}

export const deemphasizeNodes = (nodes, edge) => {
  return nodes.map(n => {
    if (n.id === edge.source || n.id === edge.target) {
      const resettedFields = n.data.fields?.map(f => {
        if (f.nodeId === edge.data.sourceNodeId || f.nodeId === edge.data.targetNodeId) {
          return { ...f, highlighted: false }
        } else {
          return f;
        }
      });
      n = { ...n, data: { ...n.data, fields: resettedFields } }
    }
    return n;
  });
}

export const deemphasizeEdge = (edge) => {
  return {
    ...edge,
    style: edgeDefaultStyle,
    markerEnd: edgeDefaultMarkerEnd
  }
}

export const downloadImage = () => {

  toPng(document.querySelector('.react-flow'), {
    filter: (node) => {
      // we don't want to add the minimap and the controls to the image
      return !(node.classList?.contains('react-flow__minimap') ||
        node.classList?.contains('react-flow__controls'));
    },
  }).then((dataUrl) => {
    const a = document.createElement('a');
    a.setAttribute('download', 'er_diagram.png');
    a.setAttribute('href', dataUrl);
    a.click();
  });
};