import React, { useState } from 'react';
import _ from 'lodash';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import {
  setLogicalFieldsFilters,
  clearLogicalFieldsFilters
} from '../../../actions/LogicalFieldsActions';
import FilterListIcon from '@mui/icons-material/FilterList';
import DataCategorySearchModal from '../../datacategories/utils/DataCategorySearchModal';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@mui/material';
import { connect } from 'react-redux';
import CustomPropertiesFilters from '../../../components/additional_properties/CustomPropertiesFilters';
import ResourceTypes from '../../../constants/ResourceTypes';
import SystemsSearchModal from 'pages/systems/commons/SystemsSearchModal';
function LogicalFieldsFiltersModal({
  filters,
  setLogicalFieldsFilters,
  clearLogicalFieldsFilters,
  hasFilters
}) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <Tooltip title='Filters'>
        <IconButton
          color={hasFilters ? 'primary' : 'grey'}
          size='small'
          onClick={() => setOpen(true)}>
          <FilterListIcon></FilterListIcon>
        </IconButton>
      </Tooltip>
      {open && (
        <LogicalFiltersModal
          open={open}
          filters={filters}
          onClose={() => setOpen(false)}
          onApply={(filters) => {
            setLogicalFieldsFilters(filters);
            setOpen(false);
          }}
          onClear={() => {
            clearLogicalFieldsFilters();
            setOpen(false);
          }}
          setLogicalFieldsFilters={setLogicalFieldsFilters}
          clearLogicalFieldsFilters={clearLogicalFieldsFilters}
        />
      )}
    </React.Fragment>
  );
}

function LogicalFiltersModal({ open, onFetch, filters, onClose, onApply, onClear }) {
  const [addDataCategory, setAddDataCategory] = useState(false);
  const [addSystem, setAddSystem] = useState(false);
  const [editor, setEditor] = useState(filters);

  let { dataCategories = [], nullPropertiesNames = [], systems = [] } = filters;
  let { t } = useTranslation();
  return (
    <React.Fragment>
      <Dialog open={open} fullWidth={true}>
        <DialogTitle>{'Set Filters'}</DialogTitle>
        <DialogContent>
          <CustomPropertiesFilters
            resourceType={ResourceTypes.LOGICAL_FIELD}
            properties={_.zipWith(
              filters.propertiesNames,
              filters.propertiesValues,
              (name, value) => ({
                name,
                value
              })
            )}
            onChange={(newProperties) =>
              setEditor({
                ...editor,
                propertiesNames: newProperties.map((p) => p.name),
                propertiesValues: newProperties.map((p) => p.value)
              })
            }
            nullPropertiesNames={nullPropertiesNames}
            onChangeNull={(nullNames) => setEditor({ ...editor, nullPropertiesNames: nullNames })}
          />
          <Typography variant='subtitle1'>{t('data_categories.header')}</Typography>
          <ChipWrapper onRequestAdd={() => setAddDataCategory(true)}>
            {dataCategories.map((dc) => (
              <ChipItem
                key={dc.uuid}
                id={dc.uuid}
                label={dc.name}
                tooltip={dc.description}
                onRequestDelete={() =>
                  setEditor({
                    ...editor,
                    dataCategories: dataCategories.filter((d) => d.uuid !== dc.uuid)
                  })
                }
              />
            ))}
          </ChipWrapper>
          <Typography variant='subtitle1'>{t('systems.header')}</Typography>
          <ChipWrapper onRequestAdd={() => setAddSystem(true)}>
            {systems.map((sys) => (
              <ChipItem
                key={sys.uuid}
                id={sys.uuid}
                label={sys.name}
                tooltip={sys.description}
                onRequestDelete={() =>
                  setLogicalFieldsFilters({
                    systems: dataCategories.filter((d) => d.uuid !== sys.uuid)
                  })
                }
              />
            ))}
          </ChipWrapper>
        </DialogContent>
        <DialogActions>
          <Button children={t('commons.actions.clear')} onClick={onClear}></Button>
          <Button
            variant='outlined'
            color='primary'
            children={t('commons.actions.apply')}
            onClick={() => onApply(editor)}></Button>
        </DialogActions>
      </Dialog>
      <DataCategorySearchModal
        open={addDataCategory}
        onCancel={() => setAddDataCategory(false)}
        onSubmit={(dc) => {
          setEditor({ ...editor, dataCategories: dataCategories.concat([dc]) });
          setAddDataCategory(false);
        }}
      />
      <SystemsSearchModal
        open={addSystem}
        onCancel={() => setAddSystem(false)}
        onSubmit={(sys) => {
          setEditor({ ...editor, systems: systems.concat([sys]) });
          setAddSystem(false);
        }}
      />
    </React.Fragment>
  );
}

export default connect(null, { clearLogicalFieldsFilters, setLogicalFieldsFilters })(
  LogicalFieldsFiltersModal
);
