import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import Authorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { Avatar, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import VariationTypeDecorator from '../../../workflows/proposals/commons/VariationTypeDecorator';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import { useDispatch } from 'react-redux';
import { deleteUser, patchUser } from 'actions/UsersActions';
import UserEditorModal from '../commons/UserEditorModal';

function UserItem({ user }) {
  let [open, setOpen] = useState(false);
  let dispatch = useDispatch();
  let { t } = useTranslation();
  const onDeleteConfirmed = (user) => dispatch(deleteUser(user));
  const onModifyClicked = () => setOpen(true);
  return (
    <div className='card-custom-div' style={{ margin: '8px 0 8px 0' }}>
      <ExpandableCard
        decorator={
          !user.active && (
            <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
              <Grid item>
                <VariationTypeDecorator>
                  {t(`active.${user.active.toString()}`)}
                </VariationTypeDecorator>
              </Grid>
            </Grid>
          )
        }
        zDepth={1}
        key={user.uuid}
        title={user.displayName}
        subheader={user.username}
        avatar={<Avatar>{firstLettersOf(user.username)}</Avatar>}
        actions={[
          <Button key='detail' component={Link} to={`/settings/users/${user.uuid}`}>
            Detail
          </Button>,
          <Authorize key='modify' hasPermissions={[Permissions.USERS_EDITOR]}>
            <Button children={t('commons.actions.modify')} onClick={() => onModifyClicked(user)} />
          </Authorize>,
          <Authorize key='delete' hasPermissions={[Permissions.USERS_ADMIN]}>
            <ConfirmButton
              children={t('commons.actions.delete')}
              onClick={() => onDeleteConfirmed(user)}
            />
          </Authorize>
        ]}
      />
      {open && (
        <UserEditorModal
          title='Modify User'
          isPatch
          open={open}
          user={user}
          handleCancel={() => setOpen(false)}
          handleSubmit={(user) => {
            setOpen(false);
            dispatch(patchUser(user));
          }}
        />
      )}
    </div>
  );
}

const firstLettersOf = (username) => {
  if (username) {
    let splitted = username.split('.');
    let firstLetter = splitted[0] && splitted[0][0];
    let secondLetter = splitted[1] && splitted[1][0];
    return (firstLetter || '') + (secondLetter || '');
  }
};

UserItem.propTypes = {
  onModify: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(UserItem);
