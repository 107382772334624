import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AdditionalPropertiesEditor from '../../../../components/additional_properties/AdditionalPropertiesEditor';
import LifeCycleFormSection from '../../../processings/form/LifeCycleFormSection';
import TransferToOrganizationFormComponent from '../../../processings/form/TransferToOrganizationFormComponent';
import SecurityMeasuresFormSection from '../../../processings/form/SecurityMeasuresFormSection';
import RecipientsFormSection from '../../../processings/form/RecipientsFormSection';
import PurposesFormSection from '../../../processings/form/PurposesFormSection';
import DataActorsSearchModal from '../../../data_actors/utils/DataActorsSearchModal';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
import DataActorItemAutoComplete from '../../../data_actors/utils/DataActorItemAutoComplete';
import DataCategoriesFormSection from '../../../processings/form/DataCategoriesFormSection';
import AutoComplete from '../../../../components/autocomplete/AutoComplete';
import TemplateVariablesEditor from '../../commons/TemplateVariablesEditor';
import AccountTeamSelector, {
  ACCESS_LEVELS
} from '../../../settings/profile/utils/AccountTeamSelector';
import ResourceTypes from '../../../../constants/ResourceTypes';

const TEMPLATE_DATA_INITIAL_STATE = {
  variables: [{ contextKey: 'dataActor', destination: 'controller' }]
};
function TemplateProcessingModal({
  open,
  templateProcessing = {},
  onClose,
  onSubmit,
  fullScreen = false
}) {
  const { t } = useTranslation();
  const [contentPatch, setContentPatch] = React.useState({});
  const [templateDataPatch, setTemplateDataPatch] = React.useState(
    templateProcessing.sequenceId
      ? { variables: templateProcessing.variables }
      : TEMPLATE_DATA_INITIAL_STATE
  );
  const mergedContent = { ...(templateProcessing && templateProcessing.content), ...contentPatch };
  const mergedTemplateData = { ...templateProcessing, ...templateDataPatch };

  return (
    <Dialog fullWidth fullScreen={fullScreen} open={open}>
      <DialogTitle>Processing Template</DialogTitle>
      <DialogContent>
        <h4>Template data</h4>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter the code'}
          label={'Code'}
          value={mergedTemplateData.code || ''}
          onChange={(event) =>
            setTemplateDataPatch({ ...templateDataPatch, code: event.target.value })
          }
        />
        <br></br>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter the name'}
          label={'Name'}
          value={mergedTemplateData.name || ''}
          onChange={(event) =>
            setTemplateDataPatch({ ...templateDataPatch, name: event.target.value })
          }
        />
        <br></br>
        <TemplateVariablesEditor
          variables={mergedTemplateData.variables}
          type='PROCESSING'
          onChange={(variables) => setTemplateDataPatch({ ...templateDataPatch, variables })}
        />
        <h4>General:</h4>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={t('processings.general.fields.nameHint')}
          label={t('processings.general.fields.name')}
          value={mergedContent.name || ''}
          onChange={(event) => setContentPatch({ ...contentPatch, name: event.target.value })}
        />

        <TextField
          variant='standard'
          fullWidth
          placeholder={t('processings.general.fields.categoryHint')}
          label={t('processings.general.fields.category')}
          value={mergedContent.category || ''}
          onChange={(event) => setContentPatch({ ...contentPatch, category: event.target.value })}
        />

        <AutoComplete
          fullWidth
          placeholder={t('processings.general.fields.legalBasisHint')}
          label={t('processings.general.fields.legalBasis')}
          value={mergedContent.legalBasis || ''}
          dataSource={t('suggestion:processingLegalBases', { returnObjects: true }) || []}
          onUpdateInput={(newValue) => setContentPatch({ ...contentPatch, legalBasis: newValue })}
        />
        <br />

        <TextField
          variant='standard'
          placeholder={t('processings.general.fields.descriptionHint')}
          label={t('processings.general.fields.description')}
          multiLine={true}
          fullWidth
          value={mergedContent.description || ''}
          onChange={(event) =>
            setContentPatch({ ...contentPatch, description: event.target.value })
          }
        />

        <DataCategoriesFormSection
          associatedDataCategories={mergedContent.associatedDataCategories}
          onAdd={(associatedDataCategory) =>
            setContentPatch({
              ...contentPatch,
              associatedDataCategories: (mergedContent.associatedDataCategories || []).concat([
                associatedDataCategory
              ])
            })
          }
          onRemove={(associatedDataCategory) =>
            setContentPatch({
              ...contentPatch,
              associatedDataCategories: mergedContent.associatedDataCategories.filter(
                (adc) => adc.dataCategory.uuid !== associatedDataCategory.dataCategory.uuid
              )
            })
          }
        />

        <h4>Actors:</h4>
        <DataActorItemAutoComplete
          fullWidth
          title='Add controller'
          style={{ width: '50%', minWidth: '200px' }}
          itemName='Controller'
          value={mergedContent.controller}
          onChange={(value) => setContentPatch({ ...contentPatch, controller: value })}
        />
        <br />
        <DataActorItemAutoComplete
          fullWidth
          title='Add representative'
          itemName='Representative'
          value={mergedContent.representative}
          onChange={(value) => setContentPatch({ ...contentPatch, representative: value })}
        />
        <br />
        <DataActorItemAutoComplete
          fullWidth
          title='Add Data Protection Officer'
          itemName='Data Protection Officer'
          value={mergedContent.dataProtectionOfficer}
          onChange={(value) => setContentPatch({ ...contentPatch, dataProtectionOfficer: value })}
        />
        <br />
        <div>Joint Controllers</div>
        <br></br>
        <ChipWrapper
          onRequestAdd={() => setContentPatch({ ...contentPatch, addJointControllerOpen: true })}>
          {(mergedContent.jointControllers || []).map((dc) => (
            <ChipItem
              key={dc.uuid}
              id={dc.uuid}
              label={dc.name}
              onRequestDelete={() =>
                setContentPatch({
                  ...contentPatch,
                  jointControllers: mergedContent.jointControllers.filter((d) => d.uuid !== dc.uuid)
                })
              }
            />
          ))}
        </ChipWrapper>

        <DataActorsSearchModal
          title='Add joint controller'
          open={mergedContent.addJointControllerOpen}
          onCancel={() => setContentPatch({ ...contentPatch, addJointControllerOpen: false })}
          onSubmit={(jointController) =>
            setContentPatch({
              ...contentPatch,
              jointControllers: (mergedContent.jointControllers || []).concat([jointController]),
              addJointControllerOpen: false
            })
          }
        />

        <PurposesFormSection
          purposes={mergedContent.purposes}
          onAdd={(purpose) =>
            setContentPatch({
              ...contentPatch,
              purposes: (mergedContent.purposes || []).concat([purpose])
            })
          }
          onDelete={(purpose) =>
            setContentPatch({
              ...contentPatch,
              purposes: mergedContent.purposes.filter((e) => e !== purpose)
            })
          }
          dataCategories={(mergedContent.associatedDataCategories || []).map(
            (adc) => adc.dataCategory
          )}
        />

        <RecipientsFormSection
          recipients={mergedContent.recipients}
          onAdd={(item) =>
            setContentPatch({
              ...contentPatch,
              recipients: (mergedContent.recipients || []).concat([item])
            })
          }
          onDelete={(item) =>
            setContentPatch({
              ...contentPatch,
              recipients: mergedContent.recipients.filter((e) => e !== item)
            })
          }
          dataCategories={(mergedContent.associatedDataCategories || []).map(
            (adc) => adc.dataCategory
          )}
        />

        <SecurityMeasuresFormSection
          securityMeasures={mergedContent.securityMeasures}
          onAdd={(purpose) =>
            setContentPatch({
              ...contentPatch,
              securityMeasures: (mergedContent.securityMeasures || []).concat([purpose])
            })
          }
          onDelete={(purpose) =>
            setContentPatch({
              ...contentPatch,
              securityMeasures: mergedContent.securityMeasures.filter((e) => e !== purpose)
            })
          }
        />

        <TransferToOrganizationFormComponent
          transferExtraEuOrganizations={mergedContent.transferExtraEuOrganizations || []}
          onAdd={(item) =>
            setContentPatch({
              ...contentPatch,
              transferExtraEuOrganizations: (
                mergedContent.transferExtraEuOrganizations || []
              ).concat([item])
            })
          }
          onDelete={(item) =>
            setContentPatch({
              ...contentPatch,
              transferExtraEuOrganizations: (
                mergedContent.transferExtraEuOrganizations || []
              ).filter((e) => e !== item)
            })
          }
        />

        <LifeCycleFormSection
          startDate={mergedContent.startDate && new Date(mergedContent.startDate)}
          endDate={mergedContent.endDate && new Date(mergedContent.endDate)}
          consentDuration={mergedContent.consentDuration}
          consentDurationUnit={mergedContent.consentDurationUnit}
          onChange={(newObj) => setContentPatch({ ...contentPatch, ...newObj })}
        />

        <AdditionalPropertiesEditor
          additionalProperties={mergedContent.additionalProperties}
          resourceType={ResourceTypes.PROCESSING}
          onChange={(newList) =>
            setContentPatch({ ...contentPatch, additionalProperties: newList })
          }
        />
      </DialogContent>
      <DialogActions>
        <AccountTeamSelector
          style={{ float: 'left' }}
          accessLevel={ACCESS_LEVELS.WRITE}
          accessLevel2={ACCESS_LEVELS.PROPOSE}
          selectedTeamCode={mergedContent.teamCode || ''}
          onChange={(code) => setContentPatch({ ...contentPatch, teamCode: code })}
        />
        <div style={{ flexGrow: 1 }} />
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={!mergedTemplateData.name || !mergedTemplateData.code || !mergedContent.name}
          onClick={() => {
            onSubmit({ ...mergedTemplateData, content: mergedContent }, contentPatch);
          }}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TemplateProcessingModal;
