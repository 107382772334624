import React from 'react';
import '../login/LoginPage.css';
import { Link } from 'react-router-dom';
import { Card, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function PrivacyPolicyPage() {
  return <>
    <IconButton
      style={{ position: 'absolute', top: '10px', left: '10px' }}
      component={Link}
      to='/login'
      size="large">
      <ArrowBackIcon></ArrowBackIcon>
    </IconButton>
    <Card
      style={{
        position: 'absolute',
        top: '10%',
        left: '5%',
        width: '90%',
        height: '80%',
        padding: '30px',
        overflow: 'auto'
      }}>
      <form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <h1> Privacy Policy </h1>
        </div>
        <h2 style={{ color: 'White' }}>Dati raccolti</h2>
        <p style={{ color: 'White' }}>
          Blindata S.r.l. con sede in Monza, Corso Milano n. 31 (di seguito Bindata) – quale
          Titolare del Trattamento – informa che alcuni dati personali degli utenti della
          piattaforma SaaS erogata tramite il sito https://app.blindata.io (il Servizio) saranno
          trattati al fine di permettere l’erogazione dei servizi disponibili attraverso il
          Servizio. I dati personali degli utenti del Servizio saranno in particolare costituiti
          da alcuni dati forniti direttamente da questi ultimi in fase di registrazione (i.e. i
          dati anagrafici quali nome, cognome, indirizzo e-mail), e dati raccolti nel corso
          dell’utilizzo del Servizio (i.e. utilizzo delle funzionalità della piattaforma,
          indirizzo-IP, …).
        </p>
        <h2 style={{ color: 'White' }}>
          Finalità, basi giuridiche del trattamento e periodo di conservazione dei dati.
        </h2>
        <p style={{ color: 'White' }}>
          I Dati Personali saranno trattati per le seguenti finalità:{' '}
          <ul>
            <ll>
              a) fornire accesso alla piattaforma ed erogare il Servizio. Base giuridica:
              esecuzione di un contratto di cui l'interessato è parte o all'esecuzione di misure
              precontrattuali adottate su richiesta dello stesso. Periodo di conservazione: 10
              anni a partire dal termine del rapporto contrattuale
            </ll>
            <br></br>
            <br></br>
            <ll>
              b) consentire a Blindata di svolgere le sue attività di amministrazione interna, e
              di adempiere a ogni normativa applicabile e/o ordine delle pubbliche autorità. Base
              giuridica: Necessità di assolvere gli obblighi di legge. Periodo di conservazione:
              10 anni a partire dal termine del rapporto contrattuale
            </ll>
            <br></br>
            <br></br>
            <ll>
              c) proteggere e far valere i diritti e la proprietà di Blindata e intervenire per la
              tutela del Servizio. Base giuridica: Interesse legittimo. Periodo di conservazione:
              10 anni a partire dal termine del rapporto contrattuale
            </ll>
          </ul>
        </p>
        <h2 style={{ color: 'White' }}>Comunicazione dei Dati Personali.</h2>
        <p style={{ color: 'White' }}>
          Per le finalità di gestione dei Servizi sopra indicate, Blindata potrà comunicare i Dati
          Personali ai propri consulenti e legali, nonché a terzi incaricati di specifici
          trattamenti. Questi soggetti avranno accesso ai soli dati necessari per adempiere le
          proprie obbligazioni e adempiere alle proprie funzioni (e non saranno autorizzati ad
          utilizzarli per altri fini), dovranno rispettare le istruzioni di Blindata in tema di
          trattamento dei Dati Personali, agiranno come responsabili del trattamento dei Dati
          Personali di Blindata, e saranno elencati in un elenco aggiornato disponibile presso
          Blindata. I Dati Personali non saranno altrimenti soggetti a comunicazione o a
          diffusione.
        </p>
        <h2 style={{ color: 'White' }}>Misure di sicurezza.</h2>
        <p style={{ color: 'White' }}>
          Blindata ha adottato misure di sicurezza al fine di minimizzare i rischi relativi sia
          alla perdita o al danneggiamento, anche accidentale, dei Dati Personali, sia all’accesso
          non autorizzato ai Dati Personali o al loro illegittimo trattamento.
        </p>
        <h2 style={{ color: 'White' }}>Accesso con Google.</h2>
        <p style={{ color: 'White' }}>
          Quando si accede usando Google per collegarsi alle nostre piattaforme o ai nostri
          servizi, si accetta di condividere la propria e-mail e la propria immagine di profilo
          con noi. Registreremo inoltre il fatto che la registrazione è avvenuta attraverso
          Google. La piattaforma di Google può ricevere informazioni quali indirizzo IP e URL del
          browser. Per ulteriori informazioni sui propri diritti e sui modi per proteggere la
          propria privacy, si veda l’informativa sulla privacy di Google:
          https://policies.google.com/privacy?hl=it
        </p>
        <h2 style={{ color: 'White' }}>Siti web di Terze Parti.</h2>
        <p style={{ color: 'White' }}>
          La presente Privacy Policy si applica unicamente alle informazioni raccolte tramite il
          Servizio. Il Servizio potrà contenere link diretti ad altri siti web. Blindata non è
          responsabile per le procedure privacy o per il contenuto di questi altri siti web.
        </p>
        <h2 style={{ color: 'White' }}>
          Esercizio del diritto concesso dalla legge sulla protezione dei dati.{' '}
        </h2>
        <p style={{ color: 'White' }}>
          In ogni momento l’utente potrà esercitare i diritti concessi dalla legge, ossia il
          diritto ad essere informato sull’esistenza, il contenuto, la fonte, gli scopi e i metodi
          di trattamento dei Dati Personali, e il diritto a richiedere aggiornamenti, correzioni,
          integrazioni e (quando permesso dalla legge) la cancellazione dei Dati Personali. Questi
          diritti potranno essere esercitati contattando il Titolare del trattamento dei dati
          all’indirizzo e-mail privacy@blindata.io. Il mancato consenso da parte dell’utente a
          fornire i Dati Personali può impedire a Blindata la fornitura dei Servizi. Per ogni
          richiesta o comunicazione in relazione a questa Privacy Policy o i Dati Personali di cui
          si è autorizzato il trattamento, vi preghiamo di inviare una comunicazione all’indirizzo
          e-mail privacy@blindata.io.
        </p>
      </form>
    </Card>
  </>;
}

export default PrivacyPolicyPage;
