import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@mui/material';
import AgentItemAutocomplete from 'pages/settings/agents/commons/AgentItemAutocomplete';
import { HelpIcon } from 'components/help';
import PermissionChipWrapperSelectModal from 'pages/settings/users/commons/PermissionsSelectModal';
import TargetPagesChipWrapperSelectModal from '../commons/TargetPagesChipWrapperSelectModal';
import AccountTeamSelector from 'pages/settings/profile/utils/AccountTeamSelector';
import { ACCESS_LEVELS } from 'components/permissions/AuthorizeTeam';
function WebActionsModal({ title, open, handleSubmit, handleCancel, webAction }) {
  const { t } = useTranslation();
  const [editor, setEditor] = useState({
    ...webAction,
    renderingRule: webAction?.renderingRule ? JSON.stringify(webAction?.renderingRule) : ''
  });

  return (
    <Dialog fullWidth open={open} onClick={(event) => event.stopPropagation()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <AgentItemAutocomplete
          margin='dense'
          value={editor?.agent}
          onChange={(agent) => setEditor({ ...editor, agent: agent })}
        />
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          placeholder='Enter the web action name'
          label='Name'
          required={true}
          value={editor?.name || ''}
          onChange={(event) => setEditor({ ...editor, name: event.target.value })}
        />
        <TextField
          variant='standard'
          multiline
          fullWidth
          margin='dense'
          placeholder='Enter the web action description'
          label='Description'
          value={editor?.description || ''}
          onChange={(event) => setEditor({ ...editor, description: event.target.value })}
        />
        <Grid container alignItems='center' direction='row'>
          <Grid item>
            <h4>{t('webActions.modal.permissions')}</h4>
          </Grid>
          <Grid item>
            <HelpIcon
              tooltip={'Make this web action available only for users with all defined permissions'}
            />
          </Grid>
        </Grid>
        <PermissionChipWrapperSelectModal
          title={'Select required permission'}
          permissions={editor?.permissions}
          onDelete={(p, index) =>
            setEditor({
              ...editor,
              permissions:
                editor.permissions.filter((p, idx) => idx !== index).length > 0
                  ? editor.permissions.filter((p, idx) => idx !== index)
                  : null
            })
          }
          onChange={(permission) => {
            setEditor({
              ...editor,
              permissions: (editor.permissions || [])
                .filter((p) => p !== permission)
                .concat([permission])
            });
          }}
        />
        <Grid container alignItems='center' direction='row'>
          <Grid item>
            <h4>{t('webActions.modal.pages')}</h4>
          </Grid>
          <Grid item>
            <HelpIcon tooltip={'Make this web action available for pages'} />
          </Grid>
        </Grid>
        <TargetPagesChipWrapperSelectModal
          title={'Select a Page'}
          pages={editor?.targetPages}
          onDelete={(p, index) =>
            setEditor({
              ...editor,
              targetPages:
                editor.targetPages.filter((p, idx) => idx !== index).length > 0
                  ? editor.targetPages.filter((p, idx) => idx !== index)
                  : null
            })
          }
          onChange={(page) => {
            setEditor({
              ...editor,
              targetPages: (editor.targetPages || []).filter((p) => p !== page).concat([page])
            });
          }}
        />
        <Grid container alignItems='center' direction='row'>
          <Grid item>
            <h4>{t('webActions.modal.team')}</h4>
          </Grid>
          <Grid item>
            <HelpIcon
              tooltip={'Only selected team members with a read policy can render this web action'}
            />
          </Grid>
        </Grid>
        <AccountTeamSelector
          accessLevel={ACCESS_LEVELS.READ}
          selectedTeamCode={editor?.requiredTeamCode || ''}
          onChange={(requiredTeamCode) => setEditor({ ...editor, requiredTeamCode })}
        />
        <h4>Rendering Rule</h4>
        <TextField
          variant='standard'
          multiline
          required
          fullWidth
          margin='dense'
          placeholder='Define a rule in order to make available this action'
          value={editor?.renderingRule || ''}
          onChange={(event) => setEditor({ ...editor, renderingRule: event.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.cancel')}
          onClick={() => {
            handleCancel();
            setEditor(null);
          }}
        />
        <Button
          variant='contained'
          color='primary'
          disabled={
            !editor?.name ||
            !editor.agent ||
            (editor?.renderingRule && !isJsonString(editor?.renderingRule))
          }
          children={t('commons.actions.submit')}
          onClick={() => {
            handleSubmit({
              ...editor,
              renderingRule: editor?.renderingRule ? JSON.parse(editor?.renderingRule) : null
            });
            setEditor(null);
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export default WebActionsModal;
