import React from 'react';

import AlertDialog from 'components/alertdialog/AlertDialog';
import { useSelector } from 'react-redux';

import { selectCurrentTenant } from 'reducers/settings/tenants';

const PairAgentModal = ({ selectedAgent, open, setOpen, pairAgent, fetchAgent }) => {
  const currentTenant = useSelector(selectCurrentTenant);
  const pairingRequest = {
    externalUuid: currentTenant?.uuid,
    tenantName: currentTenant?.name,
    tenantExternalSchema: currentTenant?.schemaName,
    agentExternalUuid: selectedAgent?.uuid
  };

  return (
    <AlertDialog
      open={open}
      message={
        'Do you really want to pair ' +
        (selectedAgent ? `Agent ${selectedAgent.name}` : 'this agent') +
        ' with the current tenant?'
      }
      onOk={() => {
        pairAgent(selectedAgent, pairingRequest).then(() =>
          fetchAgent(selectedAgent?.uuid)
        );
        setOpen(false);
      }}
      onCancel={() => setOpen(false)}
    />
  );
};

export default PairAgentModal;
