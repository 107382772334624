import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import DefinitionList from '../../../components/definition-list/DefinitionList';
import LogicalFieldsEditModal from '../commons/LogicalFieldsEditModal';
import Permissions from '../../../constants/Permissions';
import { Button } from '@mui/material';
import ExpandableCard from '../../../components/expandable-card/ExpandableCard';
import TeamCodeDecorator from '../../settings/teams/common/TeamCodeDecorator';
import ResourceTypes from '../../../constants/ResourceTypes';
import DataCategoryCreateModal from '../commons/DataCategoryCreateModal';
import { modifyDataCategory, deleteDataCategory } from '../../../actions/DataCategoriesActions';
import {
  requestLogicalFields,
  createLogicalField,
  deleteLogicalField
} from '../../../actions/LogicalFieldsActions';
import { connect } from 'react-redux';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import AuthorizeTeamStewardship from 'components/permissions/AuthorizeTeamStewardship';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';

function DataCategoriesListItem({
  dataCategory,
  modifyDataCategory,
  deleteDataCategory,
  logicalFields,
  requestLogicalFields,
  createLogicalField,
  deleteLogicalField
}) {
  let [modifyOpen, setModifyOpen] = useState(false);
  let [logicalFieldsOpen, setLogicalFieldsOpen] = useState(false);
  let { t } = useTranslation();

  function onShowLogicalFields() {
    requestLogicalFields(dataCategory);
  }

  function handleCloseModifyDataCategory() {
    setModifyOpen(false);
  }

  function handleModifyDataCategory(modifiedDataCategory) {
    modifyDataCategory(modifiedDataCategory);
    handleCloseModifyDataCategory();
  }

  return (
    <div className='card-custom-div' style={{ margin: '8px 0 8px 0' }}>
      <ExpandableCard
        zDepth={1}
        key={dataCategory.uuid}
        onExpandChange={onShowLogicalFields}
        title={dataCategory.name}
        subheader={
          <React.Fragment>
            <div>
              <label>{t('data_categories.fields.clearance.label')}: </label>
              <b>
                {t([
                  `data_categories.fields.clearance.values.${dataCategory.clearance}`,
                  'commons.placeholders.undefined'
                ])}
              </b>
            </div>
            <div>
              <label>{t('data_categories.fields.class')}: </label>
              <b>{dataCategory.dataClass || 'Not defined'}</b>
            </div>
          </React.Fragment>
        }
        decorator={
          <div>
            {dataCategory.teamCode && (
              <TeamCodeDecorator teamCode={dataCategory.teamCode}></TeamCodeDecorator>
            )}
            <IssueResourceListItemWidget
              resourceType={ResourceTypes.DATA_CATEGORY}
              resourceIdentifier={dataCategory.uuid}></IssueResourceListItemWidget>
          </div>
        }
        actions={[
          <Button key='link' component={Link} to={`/datacategories/${dataCategory.uuid}`}>
            {t('data_categories.actions.detail')}
          </Button>,
          <AuthorizeTeamStewardship
            resourceType={ResourceTypes.DATA_CATEGORY}
            resourceIdentifier={dataCategory.uuid}
            key='modify'
            hasPermissions={[Permissions.DATA_CATEGORIES_EDITOR]}
            teamCode={dataCategory.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE]}>
            <Button
              children={t('data_categories.actions.modify')}
              onClick={() => setModifyOpen(true)}
            />
          </AuthorizeTeamStewardship>,
          <AuthorizeTeamStewardship
            resourceType={ResourceTypes.DATA_CATEGORY}
            resourceIdentifier={dataCategory.uuid}
            key='delete'
            hasPermissions={[Permissions.DATA_CATEGORIES_ADMIN]}
            teamCode={dataCategory.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE]}>
            <ConfirmButton
              message={t('data_categories.delete_confirmation', {
                dataCategoryName: dataCategory.name
              })}
              children={t('data_categories.actions.delete')}
              onClick={() => deleteDataCategory(dataCategory)}
            />
          </AuthorizeTeamStewardship>,
          <AuthorizeTeamStewardship
            resourceType={ResourceTypes.DATA_CATEGORY}
            resourceIdentifier={dataCategory.uuid}
            key='definelf'
            hasPermissions={[Permissions.DATA_CATEGORIES_EDITOR]}
            teamCode={dataCategory.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE]}>
            <Button
              children={t('data_categories.actions.defineLogicalFields')}
              onClick={() => setLogicalFieldsOpen(true)}
            />
          </AuthorizeTeamStewardship>
        ]}>
        {dataCategory.description}
        {_.get(dataCategory, 'dataSubjectsCategories.length', 0) > 0 && (
          <>
            <h4>{t('data_categories.fields.dataSubjectsCategories.header')}</h4>
            <DefinitionList
              items={dataCategory.dataSubjectsCategories}
              labelProperty='name'
              dataProperties={['description']}
            />
          </>
        )}

        <h4>{t('data_categories.logicalFields.header')}</h4>
        <ChipWrapper>
          {logicalFields.map((field) => (
            <ChipItem
              key={field.uuid}
              id={field.uuid}
              label={field.name}
              tooltip={field.description}
              to={`/logicalfields/${field.uuid}`}
            />
          ))}
        </ChipWrapper>
        <LogicalFieldsEditModal
          open={logicalFieldsOpen}
          handleClose={() => setLogicalFieldsOpen(false)}
          logicalFields={logicalFields}
          dataCategory={dataCategory}
          createLogicalField={createLogicalField}
          deleteLogicalField={deleteLogicalField}
        />
        <DataCategoryCreateModal
          key={dataCategory.uuid}
          title={t('data_categories.modify_title')}
          open={modifyOpen}
          dataCategory={dataCategory}
          handleSubmit={(dc) => handleModifyDataCategory(dc)}
          handleCancel={() => handleCloseModifyDataCategory()}
        />
      </ExpandableCard>
    </div>
  );
}

DataCategoriesListItem.propTypes = {
  modifyDataCategory: PropTypes.func.isRequired,
  deleteDataCategory: PropTypes.func.isRequired,
  createLogicalField: PropTypes.func.isRequired,
  deleteLogicalField: PropTypes.func.isRequired,
  dataCategory: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    dataSubjectsCategories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string
      })
    ),
    clearance: PropTypes.string
  })
};
function mapStateToProps(state, { dataCategory }) {
  return {
    logicalFields: state.logicalfields.contentByDataCategory[dataCategory.uuid] || []
  };
}

const mapDispatchToProps = {
  modifyDataCategory,
  deleteDataCategory,
  createLogicalField,
  deleteLogicalField,
  requestLogicalFields
};

export default connect(mapStateToProps, mapDispatchToProps)(DataCategoriesListItem);
