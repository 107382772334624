import React from 'react';
import { Typography, Grid } from '@mui/material';
import GraphPerspectivesToolbar from './toolbar/GraphPerspectivesToolbar';
import GraphResourceFilterTollbar from './toolbar/GraphResourceFilterToolbar';
import GraphFiltersList from './list/GraphFiltersList';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const GraphFilters = (props) => (
  <div>
    <Grid container>
      <Grid item>
        <Typography variant='h4'>Graph options</Typography>
      </Grid>
      <Grid item style={{ flexGrow: 1 }} />
      <Grid>
        <FavoritesWidget itemIdentifier={'page:graph'} pageTitle={'Graph page options'} />
      </Grid>
    </Grid>
    <GraphResourceFilterTollbar />
    <GraphPerspectivesToolbar />
    <GraphFiltersList />
  </div>
);

export default GraphFilters;
