import React from 'react';
import PropTypes from 'prop-types'
import DefinitionList from '../definition-list/DefinitionList'
import { withTranslation } from "react-i18next";
import _ from 'lodash';

const AdditionalPropertiesItem = ({additionalProperties,resourceType,t})=>{
  if(additionalProperties && additionalProperties.length>0){
    return <div>
      <h4>{t('Additional Properties')}</h4>
      <DefinitionList
        items={enrichPropertyLabel(t('suggestion:additionalProperties.'+resourceType,{returnObjects:true}),additionalProperties)}
        labelProperty='name'
        dataProperties={['value']}
      />
    </div>
  } else {
    return null;
  }
}

function enrichPropertyLabel(suggestions,additionalProperties){
  return suggestions?
    additionalProperties.map(ap => ({...ap,name:labelText(suggestions,ap.name)}) )
    : additionalProperties;
}

function computeSearchTextFromSuggestions(suggestions, value){
  if (!value) {
    return ''
  } else {
    let dataSourceElement = _(suggestions).find({ 'value': value });
    let dataSourceElementText = dataSourceElement && dataSourceElement.text;
    return dataSourceElementText || value;
  }
}

function labelText(suggestions, value){
  let text = computeSearchTextFromSuggestions(suggestions, value);
  return text !== value ? `${text} (${value})` : value;
}

export default withTranslation()(AdditionalPropertiesItem)

AdditionalPropertiesItem.propTypes = {
  additionalProperties: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  })),
  resourceType: PropTypes.string
}