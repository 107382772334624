import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
function QualityResultModal({
  open,
  onSubmit,
  onCancel,
  qualityCheck,
  qualityResult,
  isPatch = false
}) {
  const INITIAL_STATE = {
    qualityCheck: qualityCheck,
    totalElements: null,
    metric: null,
    score: null,
    semaphore: null,
    startedAt: Date.now(),
    finishedAt: null,
    isManual: true,
    description: null
  };
  const [result, setResult] = useState(qualityResult || INITIAL_STATE);
  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>{isPatch ? 'Modify Result' : 'Add a Result'}</DialogTitle>
      <DialogContent>
        <TextField
          disabled={isPatch}
          variant='standard'
          fullWidth
          margin='dense'
          required
          placeholder={'Metric'}
          type='number'
          value={result.metric || null}
          label={'Metric'}
          onChange={(event) =>
            setResult({
              ...result,
              metric: event.target.value
            })
          }
        />
        {(qualityCheck.scoreStrategy === 'PERCENTAGE' ||
          qualityCheck.scoreStrategy === 'PERCENTAGE_DEVIATION') && (
          <TextField
            disabled={isPatch}
            variant='standard'
            fullWidth
            required
            margin='dense'
            placeholder={'Total Elements'}
            type='number'
            value={result.totalElements || null}
            label={'Total Elements'}
            onChange={(event) =>
              setResult({
                ...result,
                totalElements: event.target.value
              })
            }
          />
        )}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            disabled={isPatch}
            autoOk
            margin='dense'
            hideTabs
            ampm={false}
            required
            clearable
            fullWidth
            value={result.startedAt}
            inputFormat='DD/MM/YYYY HH:mm'
            onChange={(date) => setResult({ ...result, startedAt: date })}
            label='Start Date'
            renderInput={(params) => (
              <TextField
                {...{ ...params, variant: 'standard', margin: 'dense', fullWidth: true }}
              />
            )}
          />
          <DateTimePicker
            disabled={isPatch}
            autoOk
            margin='dense'
            clearable
            fullWidth
            hideTabs
            ampm={false}
            value={result.finishedAt}
            format='DD/MM/YYYY HH:mm'
            onChange={(date) => setResult({ ...result, finishedAt: date })}
            label='End Date'
            renderInput={(params) => (
              <TextField
                {...{ ...params, variant: 'standard', margin: 'dense', fullWidth: true }}
              />
            )}
          />
        </LocalizationProvider>

        <TextField
          variant='standard'
          margin='dense'
          placeholder='Add a note on this result'
          fullWidth
          multiline
          label='Notes'
          value={result.description}
          onChange={(event) => setResult({ ...result, description: event.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setResult(INITIAL_STATE);
            onCancel();
          }}>
          Cancel
        </Button>
        <Button
          disabled={
            !result.metric ||
            ((qualityCheck.scoreStrategy === 'PERCENTAGE' ||
              qualityCheck.scoreStrategy === 'PERCENTAGE_DEVIATION') &&
              !result.totalElements) ||
            !result.startedAt
          }
          onClick={() => {
            onSubmit(result);
            setResult(INITIAL_STATE);
            onCancel();
          }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QualityResultModal;
