import { buildApiCallAction } from './ActionUtils';
import {
  CREATE_DATA_ACTOR,
  MODIFY_DATA_ACTOR,
  DELETE_DATA_ACTOR,
  FETCH_DATA_ACTORS,
  FETCH_DATA_ACTOR_LOGIN_ASSIGNMENTS,
  CREATE_DATA_ACTOR_LOGIN_ASSIGNMENT,
  REMOVE_DATA_ACTOR_LOGIN_ASSIGNMENT,
  FETCH_DATA_ACTORS_PAGE,
  FETCH_DATA_ACTOR,
  SEARCH_DATA_ACTORS,
  CLEAR_DATA_ACTORS_FILTERS,
  SET_DATA_ACTORS_FILTERS,
  EXPORT_DATA_ACTORS
} from '../constants/ActionTypes';
import DataActorsApi from '../api/DataActorApi';

const defaultPageSize = 15;

const api = new DataActorsApi();

export const createDataActor = (dataActor) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postDataActor(dataActor),
    actionType: CREATE_DATA_ACTOR
  });

export const fetchDataActors = () =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAllDataActor(),
    actionType: FETCH_DATA_ACTORS
  });

export const fetchDataActorsPage = (page, size = defaultPageSize) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getDataActors(page, size),
    actionType: FETCH_DATA_ACTORS_PAGE
  });

export const fetchDataActorsPageWithFilters = (page, size = defaultPageSize) =>
  buildApiCallAction({
    api,
    apiFunction: (api, getState) =>
      api.getDataActorsFiltered(page, size, getState().data_actors.filters.content),
    actionType: FETCH_DATA_ACTORS_PAGE
  });

export const fetchDataActor = (dataActorUuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getDataActor(dataActorUuid),
    actionType: FETCH_DATA_ACTOR
  });

export const searchDataActors = (search, { page, size } = {}) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.searchDataActors(searchParams(search), { page, size }),
    actionType: SEARCH_DATA_ACTORS
  });

const searchParams = (search) => {
  let { searchText } = search;
  return searchText || search;
};

export const modifyDataActor = (dataActor) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putDataActor(dataActor),
    actionType: MODIFY_DATA_ACTOR
  });

export const deleteDataActor = (dataActor) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteDataActor(dataActor),
    actionType: DELETE_DATA_ACTOR,
    payload: dataActor
  });

export const fetchDataActorLoginAssignments = (dataActor) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAllLoginAssignments(dataActor),
    actionType: FETCH_DATA_ACTOR_LOGIN_ASSIGNMENTS
  });

export const createDataActorLoginAssignment = (dataActor, loginAssignment) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postLoginAssignment(dataActor, loginAssignment),
    actionType: CREATE_DATA_ACTOR_LOGIN_ASSIGNMENT
  });

export const removeDataActorLoginAssignment = (dataActor, loginAssignment) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteLoginAssignment(dataActor, loginAssignment),
    actionType: REMOVE_DATA_ACTOR_LOGIN_ASSIGNMENT,
    payload: loginAssignment
  });

export const setDataActorsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_DATA_ACTORS_FILTERS, payload: filters });
};

export const clearDataActorsFilters = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_DATA_ACTORS_FILTERS });
};

export const exportDataActors = (filters) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportDataActors(filters),
    actionType: EXPORT_DATA_ACTORS
  });
