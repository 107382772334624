import React, { Component } from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import DataCategoryCreateModal from './DataCategoryCreateModal';
import { withTranslation } from 'react-i18next';

class DataCategoryEditButton extends Component {
  state = {
    open: false
  };

  render() {
    let { t } = this.props;
    return (
      <span>
        <Button
          children={t('data_categories.actions.modify')}
          onClick={() => this.setState({ open: true })}
        />
        <DataCategoryCreateModal
          key={this.props.dataCategory.uuid}
          title={t('data_categories.modify_title')}
          open={this.state.open}
          dataCategory={this.props.dataCategory}
          handleSubmit={(dc) =>
            this.props.modifyDataCategory(dc).then((dc) => this.setState({ open: false }))
          }
          handleCancel={() => this.setState({ open: false })}
        />
      </span>
    );
  }
}

DataCategoryEditButton.propTypes = {
  dataCategory: PropTypes.object.isRequired,
  modifyDataCategory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};
export default withTranslation()(DataCategoryEditButton);
