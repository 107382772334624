import { Switch, Route } from 'react-router-dom';
import React from 'react';
import PhysicalModelNavbar from '../PhysicalModelNavbar';
import PhysicalConstraintDetailPage from './detail/PhysicalConstraintDetailPage';
import PhysicalConstraintsListPage from './list/PhysicalConstraintsListPage';
import ErDiagramPage from './erDiagram/ErDiagramPage';

const PhysicalConstraintsRoutes = (props) => {
  return (
    <>
      <PhysicalModelNavbar />
      <Switch>
        <Route exact path={props.match.url} component={PhysicalConstraintsListPage}></Route>
        <Route
          exact
          path={`${props.match.url}/detail/:physicalConstraintUuid`}
          component={PhysicalConstraintDetailPage}
        />
        <Route exact path={`${props.match.url}/er-diagram`} component={ErDiagramPage} />
      </Switch>
    </>
  );
};

export default PhysicalConstraintsRoutes;
