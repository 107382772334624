import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow
} from '@mui/material';
import CustomPropertiesView from 'components/additional_properties/CustomPropertiesView';
import { ChipItem } from 'components/chiplist/ChipList';
import TableCell from 'components/tables/GenericTableCell';
import ResourceTypes from 'constants/ResourceTypes';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import React from 'react';

export default function DefinitionDetailCardView({ contractDefinition, actions }) {
  return (
    <>
      <Grid container alignItems='center' justifyContent='flex-end'>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`${ResourceTypes.DATA_CATEGORY}:${contractDefinition.uuid}`}
            resourceIdentifier={contractDefinition.uuid}
            resourceName={contractDefinition.name}
            resourceType={ResourceTypes.CONTRACT}
            pageTitle={`Contract: ${contractDefinition.name}`}
          />
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.CONTRACT}
            resourceIdentifier={contractDefinition.uuid}
            resourceName={contractDefinition.name}></IssuesResourceWidget>
        </Grid>
      </Grid>

      <Card>
        <CardHeader
          title={contractDefinition.name}
          subheader={`${contractDefinition.category} - ${contractDefinition.contractName}`}></CardHeader>
        <CardContent>
          <div>{contractDefinition.description}</div>
          <CustomPropertiesView
            resourceType={ResourceTypes.CONTRACT}
            additionalProperties={contractDefinition.additionalProperties}
          />
          <h4>Associated processings</h4>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Placeholder Name</TableCell>
                <TableCell>Processing</TableCell>
                <TableCell padding='checkbox'>Consent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(contractDefinition.processings || []).map((p) => (
                <TableRow>
                  <TableCell>{p.placeholderName}</TableCell>
                  <TableCell>
                    <ChipItem to={`/processings/${p.processing.uuid}`} label={p.processing.name} />
                  </TableCell>
                  <TableCell padding='checkbox'>
                    <Checkbox checked={p.requiresConsent} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions>{actions}</CardActions>
      </Card>
    </>
  );
}
