import { BlindataApi } from 'api/ApiUtils';

const CONTRACT_TEMPLATES_URL = '/api/v1/contracts/templates';

export default class ContractTemplateAttachmentsApi extends BlindataApi {
  getContractTemplateAttachmentsPage(templateUuid, params) {
    return this.get(`${CONTRACT_TEMPLATES_URL}/${templateUuid}/attachments`, params);
  }

  getContractTemplateAttachment(templateUuid, uuid) {
    return this.get(`${CONTRACT_TEMPLATES_URL}/${templateUuid}/attachments/${uuid}`);
  }

  postContractTemplateAttachment(templateUuid, attachment) {
    return this.post(`${CONTRACT_TEMPLATES_URL}/${templateUuid}/attachments`, attachment);
  }

  putContractTemplateAttachment(templateUuid, attachment) {
    return this.put(
      `${CONTRACT_TEMPLATES_URL}/${templateUuid}/attachments/${attachment.uuid}`,
      attachment
    );
  }

  deleteContractTemplateAttachment(templateUuid, attachment) {
    return this.delete(`${CONTRACT_TEMPLATES_URL}/${templateUuid}/attachments/${attachment.uuid}`);
  }

  uploadContractTemplateAttachment(templateUuid, attachment, data) {
    return this.upload(
      `${CONTRACT_TEMPLATES_URL}/${templateUuid}/attachments/${attachment.uuid}/upload`,
      data
    );
  }

  downloadContractTemplateAttachment(templateUuid, attachment, params) {
    return this.download(
      `${CONTRACT_TEMPLATES_URL}/${templateUuid}/attachments/${attachment.uuid}/download`,
      params
    );
  }
}
