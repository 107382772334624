import { AddFAB } from 'components/fab';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import AgentJobDefinitionModal from '../../commons/AgentJobDefinitionModal/AgentJobDefinitionModal';
import { createSchedule } from 'actions/onagent/AgentScheduleActions';

function AgentJobDefinitionFAB({ createSchedule, agent }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AddFAB id='agent-job-definition-button' onClick={() => setOpen(true)} />
      {open && (
        <AgentJobDefinitionModal
          agent={agent}
          open={open}
          onCancel={() => setOpen(false)}
          onSubmit={(jobDefinition) => {
            setOpen(false);
            createSchedule(agent, jobDefinition);
          }}
        />
      )}
    </>
  );
}

export default connect(null, { createSchedule })(AgentJobDefinitionFAB);
