import React, { Component } from 'react';
import Search from '@mui/icons-material/Search';
import ProcessingSearchModal from '../utils/ProcessingSearchModal';
import { IconButton } from '@mui/material';

export default class ProcessingFinderButton extends Component {
  state = {
    open: false
  };

  render() {
    return (
      <>
        <IconButton
          size='small'
          onClick={() => this.setState({ open: true })}
          children={<Search />}
        />
        <ProcessingSearchModal
          open={this.state.open}
          onCancel={() => this.setState({ open: false })}
          onSubmit={(processing) => this.props.history.push(`/processings/${processing.uuid}`)}
        />
      </>
    );
  }
}
