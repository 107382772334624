import { Box, Tab, Tabs, Grid } from '@mui/material';
import React from 'react';
import QualityResultsDashboardTab from './QualityResultsDashboardTab';
import useSearchParams from 'components/useSearchParams';
import { useHistory } from 'react-router';

import QualityResultsListTab from './QualityResultsListTab';
import QualityResultFilters from '../commons/QualityResultFilters';
import QualityChecksTimelineTab from 'pages/quality/kqis/quality_checks/detail/QualityChecksTimelineTab';
import QualityAssessmentCheckPanel from 'pages/quality/assessment/checkEvaluations/QualityAssessmentCheckPanel';
import withAuthorize from 'components/permissions/withAuthorize';
import Permissions from 'constants/Permissions';
import QualityResultsExportButton from '../commons/QualityResultExportButton';
export function QualityResultsTabs({ qualityCheck }) {
  const query = useSearchParams();
  const history = useHistory();
  const selectedTab = query.get('tab') || 'results';
  const handleChangeTab = (_event, newValue) =>
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });
  return (
    <React.Fragment>
      <Grid container alignItems='baseline' direction='row' justifyContent='center'>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <Box display='flex' justifyContent='space-around' width='100%'>
            <Tabs value={selectedTab} onChange={handleChangeTab}>
              <Tab value='results' label='Results List' />
              <Tab value='volumes' label='Volumes' />
              <Tab value='timeline' label='Timeline' />
              <AuthorizedTab
                hasPermissions={[Permissions.QUALITY_ASSMNT_VIEWER]}
                value='checkassessment'
                label='Check Assessment'
              />
            </Tabs>
          </Box>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <QualityResultsExportButton checkUuid={qualityCheck.uuid} />
        </Grid>
        <Grid item>
          <QualityResultFilters></QualityResultFilters>
        </Grid>
      </Grid>

      {selectedTab === 'results' && <QualityResultsListTab qualityCheck={qualityCheck} />}
      {selectedTab === 'volumes' && <QualityResultsDashboardTab qualityCheck={qualityCheck} />}
      {selectedTab === 'timeline' && <QualityChecksTimelineTab qualityCheck={qualityCheck} />}
      {selectedTab === 'checkassessment' && (
        <QualityAssessmentCheckPanel qualityCheck={qualityCheck} />
      )}
    </React.Fragment>
  );
}
const AuthorizedTab = withAuthorize(Tab, 'span');

export default QualityResultsTabs;
