import React, { useEffect } from 'react';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';
import { searchQualityCheck } from '../../../../../actions/QualityChecksActions';
import { TextField } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function QualityCheckFormSection({ onChange, onSearch, /*not null*/ check, searchContent }) {
  useEffect(
    function () {
      searchQualityCheck({ name: '' });
    },
    [searchContent]
  );
  return (
    <React.Fragment>
      <ItemAutoComplete
        margin='dense'
        required
        values={searchContent}
        value={check}
        itemName='Check Name'
        placeholder='Choose a Check Name'
        keyProperty={'index'}
        nameProperty='name'
        subheaderProperty='description'
        onChange={(newCheck) => {
          onChange({
            name: newCheck && newCheck?.name,
            code: newCheck && newCheck?.code
          });
        }}
        onSearch={(searchText) => {
          onChange({ ...check, name: searchText });
          onSearch({ search: searchText });
        }}></ItemAutoComplete>
      <TextField
        variant="standard"
        required
        fullWidth
        margin='dense'
        placeholder='Choose a Check Code'
        label='Check Code'
        value={check?.code || ''}
        onChange={(event) => {
          onChange({
            ...check,
            code: event.target.value
          });
        }}></TextField>
    </React.Fragment>
  );
}

QualityCheckFormSection.propTypes = {
  check: PropTypes.shape({ name: PropTypes.string, code: PropTypes.string }).isRequired,
  onChange: PropTypes.func.isRequired,
  searchContent: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
  searchContent: Object.values(state.quality.qualityChecks.content)
});

const mapDispatchToProps = (dispatch) => ({
  onSearch: (search) => dispatch(searchQualityCheck(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(QualityCheckFormSection);
