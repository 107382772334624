import {
  LOGIN,
  LOGOUT,
  LOGIN_FAILURE,
  FETCH_ACCOUNT_RESULTING_PERMISSIONS
} from '../constants/ActionTypes';

const DEFAULT_STATE = {
  v: '1',
  authenticated: false,
  username: undefined,
  permissions: [],
  access_token: undefined,
  token_type: undefined,
  error: false
};

function loadAuthentication() {
  try {
    let savedAuthJson = sessionStorage.getItem('state.authentication');
    let savedAuth = savedAuthJson && JSON.parse(savedAuthJson);
    return savedAuth || DEFAULT_STATE;
  } catch {
    return DEFAULT_STATE;
  }
}
function saveAuthentication(auth) {
  try {
    let authJson = JSON.stringify(auth);
    sessionStorage.setItem('state.authentication', authJson);
  } catch {}
}
function clearAuthentication() {
  try {
    sessionStorage.removeItem('state.authentication');
  } catch {}
}

const initialState = loadAuthentication();

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      let newState = {
        ...state,
        authenticated: true,
        username: action.payload.username,
        access_token: action.payload.access_token,
        token_type: action.payload.token_type,
        error: false
      };
      saveAuthentication(newState);
      return newState;
    case LOGOUT:
      clearAuthentication();
      return DEFAULT_STATE;
    case LOGIN_FAILURE:
      clearAuthentication();
      return {
        ...DEFAULT_STATE,
        authenticated: false,
        error: true
      };
    case FETCH_ACCOUNT_RESULTING_PERMISSIONS:
      let newState2 = {
        ...state,
        permissions: action.payload.permissions
      };
      saveAuthentication(newState2);
      return newState2;
    default:
      return state;
  }
};

export default authentication;
