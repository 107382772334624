import React from 'react';
import _ from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DataActorChipListEditor from '../../../data_actors/commons/DataActorsChipListEditor';
import RpaTemplateItemSelect from '../../rpaTemplate/RpaTemplateSelect';

export function RpaArchiveEditorModal({ title, rpaArchive, open, onCancel, onSubmit }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [valuePatch, setValuePatch] = React.useState({});
  const mergedValue = { ...(rpaArchive || {}), ...valuePatch };
  const { t } = useTranslation();
  return (
    <Dialog open={open} fullWidth fullScreen={fullScreen}>
      <DialogTitle>{title || 'Ropa Archive Editor'}</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          required
          fullWidth
          placeholder={'Enter the name'}
          label={'Name'}
          value={mergedValue.name || ''}
          onChange={(event) => setValuePatch({ ...valuePatch, name: event.target.value })} />
        <br></br>
        <RpaTemplateItemSelect
          value={mergedValue.rpaTemplate}
          onChange={(rpaTemplate) => setValuePatch({ ...valuePatch, rpaTemplate })}
        />

        <FormControlLabel
          control={
            <Switch
              checked={mergedValue.enabled}
              onChange={(event) => setValuePatch({ ...valuePatch, enabled: event.target.checked })}
              value='enabled'
              color='primary'
            />
          }
          label='Automatic Scheduling Enabled'
        />

        <h4>Data Controllers</h4>
        <DataActorChipListEditor
          dataActors={_.get(mergedValue, 'filters.dataControllers', [])}
          onChange={(dataControllers) =>
            setValuePatch({
              ...valuePatch,
              filters: { ...(valuePatch.filters || {}), dataControllers }
            })
          }></DataActorChipListEditor>

        <h4>Data Processors</h4>
        <DataActorChipListEditor
          dataActors={_.get(mergedValue, 'filters.dataProcessors', [])}
          onChange={(dataProcessors) =>
            setValuePatch({
              ...valuePatch,
              filters: { ...(valuePatch.filters || {}), dataProcessors }
            })
          }></DataActorChipListEditor>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
        <Button onClick={() => onSubmit(mergedValue, valuePatch)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
