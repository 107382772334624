import {
  CREATE_DATA_ACTOR_TASK_ASSIGNMENT,
  FETCH_DATA_ACTOR_TASK_ASSIGNMENTS,
  REMOVE_DATA_ACTOR_TASK_ASSIGNMENT
} from '../constants/ActionTypes';
import _ from 'lodash';
import { combineReducers } from 'redux';
import crudReducers from './utils';

const defaultState = {
  content: {},
  byTask: {},
  byDataActor: {}
};

function byTask(state = defaultState.byTask, action) {
  switch (action.type) {
    case CREATE_DATA_ACTOR_TASK_ASSIGNMENT:
      return {
        ...state,
        [action.payload.taskUuid]: (state[action.payload.taskUuid] || []).concat([action.payload])
      };
    case FETCH_DATA_ACTOR_TASK_ASSIGNMENTS:
      return {
        ...state,
        ..._(action.payload.content).groupBy('taskUuid').value()
      };
    case REMOVE_DATA_ACTOR_TASK_ASSIGNMENT:
      return {
        ...state,
        [action.payload.taskUuid]: _(state[action.payload.taskUuid] || [])
          .reject({ uuid: action.payload.uuid })
          .value()
      };
    default:
      return state;
  }
}

function byDataActor(state = defaultState.byDataActor, action) {
  switch (action.type) {
    case CREATE_DATA_ACTOR_TASK_ASSIGNMENT:
      return {
        ...state,
        [action.payload.dataActorUuid]: (state[action.payload.dataActorUuid] || []).concat([
          action.payload
        ])
      };
    case FETCH_DATA_ACTOR_TASK_ASSIGNMENTS:
      return {
        ...state,
        ..._(action.payload.content).groupBy('dataActorUuid').value()
      };
    case REMOVE_DATA_ACTOR_TASK_ASSIGNMENT:
      return {
        ...state,
        [action.payload.dataActorUuid]: _(state[action.payload.dataActorUuid] || [])
          .reject({ uuid: action.payload.uuid })
          .value()
      };
    default:
      return state;
  }
}

export default combineReducers({
  ...crudReducers({ resource: 'DATA_ACTOR_TASK_ASSIGNMENT', keyProperty: 'uuid' }),
  byTask,
  byDataActor
});
