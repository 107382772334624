import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentsConfigModal from './AssignmentsConfigModal';

const styles = {
  settings: {
    position: 'relative'
  }
};

export default function AssignmentsStatusConfiguration() {
  const [openConfig, setOpenConfig] = useState(false);
  return (
    <>
      <IconButton
        aria-label='open settings'
        sx={styles.settings}
        color='default'
        size='small'
        onClick={() => setOpenConfig(true)}>
        <SettingsIcon />
      </IconButton>
      <AssignmentsConfigModal open={openConfig} onClose={() => setOpenConfig(false)} />
    </>
  );
}
