import { BlindataApi } from './ApiUtils';

const CAMPAIGNS_URL = '/api/v1/issue-management/campaigns';

export default class CampaignsApi extends BlindataApi {
  getCampaigns(params) {
    return this.get(CAMPAIGNS_URL, params);
  }

  getCampaign(uuid) {
    return this.get(`${CAMPAIGNS_URL}/${uuid}`);
  }

  postCampaign(campaign) {
    return this.post(CAMPAIGNS_URL, campaign);
  }

  putCampaign(campaign) {
    return this.put(`${CAMPAIGNS_URL}/${campaign.uuid}`, campaign);
  }

  deleteCampaign(uuid) {
    return this.delete(`${CAMPAIGNS_URL}/${uuid}`);
  }

  exportCampaigns(params) {
    return this.download(`/api/v1/issue-management/campaigns/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }
}
