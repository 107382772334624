import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ItemAutoComplete from '../../../components/itemselector/ItemAutoComplete';
import { search } from '../../../actions/UniversalSearchActions';
import { useTranslation } from 'react-i18next';

function SearchItemAutoComplete({
  onSearch,
  searchContent,
  onChange,
  value,
  label,
  resourceTypes
}) {
  useEffect(() => {
    onSearch({ search: '', resourceTypes });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearch]);

  let { t } = useTranslation();
  let translateValues = searchContent.map((e) => ({
    ...e,
    _resourceTypeTranslated: t(`resourceType.${e.resourceType}`)
  }));

  return (
    <ItemAutoComplete
      values={translateValues}
      value={value}
      showAllOptions
      itemName={label || 'Resource'}
      placeholder='Type to search for a resource'
      keyProperty={'resourceIdentifier'}
      nameProperty={'name'}
      categoryProperties={['parentName']}
      subheaderProperty={'_resourceTypeTranslated'}
      onChange={(newValue) => onChange(newValue)}
      onSearch={(inputValue) => {
        onSearch({ search: inputValue, resourceTypes });
      }}></ItemAutoComplete>
  );
}

const mapStateToProps = (state, props) => ({
  searchContent: state.settings.search.content
});
const mapDispatchToProps = {
  onSearch: search
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemAutoComplete);
