import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import { clearGraph } from '../../../actions/GraphActions';

function GraphMenuReloadGraph({ onToggle, clearGraph }) {
  let [t] = useTranslation();
  return (
    <MenuItem
      children={t('graph.menu.actions.reload')}
      onClick={() => {
        clearGraph();
        onToggle(false);
      }}
    />
  );
}

export default connect(() => ({}), { clearGraph })(GraphMenuReloadGraph);
