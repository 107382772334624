import { handleErrors, parseJson, encodeQueryData } from './ApiUtils';

export default class GraphApi {
  constructor() {
    this.token = '';
  }

  getGraphs(filters) {
    let queryString = encodeQueryData(filters);
    return fetch('/api/v1/graphs?' + queryString, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
        ...(this.selectedTenantUuid
          ? { 'X-BD-Tenant': this.selectedTenantUuid || '' }
          : {})
      }
    })
      .then(handleErrors)
      .then(parseJson);
  }

  expansion(node, queryParams = {}) {
    return fetch(
      `/api/v1/graphs/expansions/${node.labels[0]}/${node.id}?${encodeQueryData(
        queryParams
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: 'application/json',
          ...(this.selectedTenantUuid
            ? { 'X-BD-Tenant': this.selectedTenantUuid || '' }
            : {})
        }
      }
    )
      .then(handleErrors)
      .then(parseJson);
  }

  getGraphNodeDetail(node, queryParams = {}) {
    return fetch(
      `/api/v1/graphs/nodeDetail/${node.labels[0]}/${node.id}?${encodeQueryData(
        queryParams
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: 'application/json',
          ...(this.selectedTenantUuid
            ? { 'X-BD-Tenant': this.selectedTenantUuid || '' }
            : {})
        }
      }
    )
      .then(handleErrors)
      .then(parseJson);
  }
}
