import React from 'react';
import { MenuItem } from '@mui/material';
import { resetFilters } from './GraphFunctions';
import { useTranslation } from 'react-i18next';

function GraphMenuResetFilters({ graphHandler }) {
  let [t] = useTranslation();
  return (
    <MenuItem
      children={t('graph.menu.actions.reset')}
      onClick={() => onResetFilters(graphHandler)}
    />
  );
}

function onResetFilters(graphHandler) {
  let graph = graphHandler.getGraphData();
  resetFilters(graph);
  graphHandler.updateGraph(graph);
}

export default GraphMenuResetFilters;
