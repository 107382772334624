import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { t } from 'i18next';

const SwitchPhysicalEntityLevel = ({ previewOptions, setPreviewOptions }) => {
  const handleChange = () => {
    setPreviewOptions({
      ...previewOptions,
      generateAtPhysicalEntityLevel: !previewOptions.generateAtPhysicalEntityLevel
    });
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={previewOptions.generateAtPhysicalEntityLevel}
          onChange={handleChange}
          color='primary'
        />
      }
      label={t('querystatements.dataFlowsPreview.card.switchButton')}
      labelPlacement='start'
    />
  );
};

export default SwitchPhysicalEntityLevel;
