import { buildApiCallAction } from './ActionUtils';
import {
SEARCH_CONSENTS,
FETCH_CONSENT,
CONFIGURE_SEARCH_PARAMS,
CLEAR_CONSENTS_SEARCH
} from '../constants/ActionTypes';
import ConsentsApi from '../api/ConsentsApi';

const api = new ConsentsApi();

export const searchConsents = (searchParams, page = 0) => buildApiCallAction({
  api,
  apiFunction: api => api.searchConsents(searchParams,page),
  actionType: SEARCH_CONSENTS
})

export const getConsent = (uuid) => buildApiCallAction({
  api,
  apiFunction: api => api.getConsent(uuid),
  actionType: FETCH_CONSENT
})

export const configureSearchParams  = (searchParams) => (dispatch,getState) => {
  dispatch({type:CONFIGURE_SEARCH_PARAMS,payload:searchParams})
}

export const clearConsentsSearch = ()=> (dispatch,getState) => {
  dispatch({type:CLEAR_CONSENTS_SEARCH})
}