/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

export default ({ hasPermissions, hasAnyPermissions }) => WrappedComponent => {
  function WithPermissions(props) {
    let { permissions } = useSelector(mapStateToProps);
    let hostname = window.location.hostname;
    let demoHostnames = ['localhost', 'demo.blindata.io'];
    permissions = demoHostnames.includes(hostname) ? _(permissions).add('DEMO') : permissions;
    if (
      (!hasPermissions || _.every(hasPermissions, p => _.includes(permissions, p))) &&
      (!hasAnyPermissions || _.find(hasAnyPermissions, p => _.includes(permissions, p)))
    ) {
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  }

  WithPermissions.displayName = `WithPermissions(${getDisplayName(WrappedComponent)})`;
  return WithPermissions;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const mapStateToProps = state => ({
  permissions: state.authentication.permissions
});
