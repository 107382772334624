import {
  FETCH_PROCESSING,
  CREATE_PROCESSING,
  MODIFY_PROCESSING,
  DELETE_PROCESSING,
  FETCH_PROCESSING_DISCLAIMER,
  FETCH_ALL_PROCESSING_DISCLAIMERS,
  CREATE_PROCESSING_DISCLAIMER,
  FETCH_PROCESSINGS_PAGE,
  SEARCH_PROCESSINGS,
  SET_PROCESSINGS_FILTERS,
  CLEAR_PROCESSINGS_FILTERS,
  DELETE_PROCESSING_DISCLAIMER,
  FETCH_SECURITY_MEASURES
} from '../constants/ActionTypes';

import { combineReducers } from 'redux';
import _ from 'lodash';

const contentByUuid = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROCESSING:
    case CREATE_PROCESSING:
    case MODIFY_PROCESSING:
      return {
        ...state,
        [action.payload.uuid]: action.payload
      };
    case FETCH_PROCESSINGS_PAGE:
    case SEARCH_PROCESSINGS:
      return { ...state, ..._(action.payload.content).keyBy('uuid').value() };
    case DELETE_PROCESSING:
      let { [action.payload.uuid]: _clearedELem, ...stateDiff } = state;
      return stateDiff;
    default:
      return state;
  }
};

const currentPage = (state = { content: [], number: 0, totalPages: 0 }, action) => {
  switch (action.type) {
    case FETCH_PROCESSINGS_PAGE:
      return action.payload;
    case DELETE_PROCESSING:
      return { ...state, content: state.content.filter((i) => i.uuid !== action.payload.uuid) };
    case MODIFY_PROCESSING:
      return {
        ...state,
        content: state.content.map((p) => (p.uuid === action.payload.uuid ? action.payload : p))
      };
    default:
      return state;
  }
};

const filtersDefault = {
  hasFilters: false,
  dataCategories: [],
  processingsCategories: [],
  controllers: [],
  processors: [],
  teamsCodes: [],
  endDate: null,
  lifeCycleState: null,
  endDateOperator: null,
  legalBasis: null,
  sort: ['name', 'category']
};

const filters = (state = filtersDefault, action) => {
  switch (action.type) {
    case SET_PROCESSINGS_FILTERS:
      return { ...state, ...action.payload, hasFilters: true };
    case CLEAR_PROCESSINGS_FILTERS:
      return filtersDefault;
    default:
      return state;
  }
};

const disclaimers = (state = { contentByProcessingUuid: {} }, action) => {
  switch (action.type) {
    case FETCH_PROCESSING_DISCLAIMER:
      return {
        ...state,
        contentByProcessingUuid: {
          ...state.contentByProcessingUuid,
          [action.payload.processingUuid]: {
            ...state.contentByProcessingUuid[action.payload.processingUuid],
            [action.payload.uuid]: action.payload
          }
        }
      };
    case FETCH_ALL_PROCESSING_DISCLAIMERS:
      return {
        ...state,
        contentByProcessingUuid: {
          ...state.contentByProcessingUuid,
          [action.payload.processingUuid]: {
            ...state.contentByProcessingUuid[action.payload.processingUuid],
            ..._(action.payload.content).keyBy('uuid').value()
          }
        }
      };
    case CREATE_PROCESSING_DISCLAIMER:
      return {
        ...state,
        contentByProcessingUuid: {
          ...state.contentByProcessingUuid,
          [action.payload.processingUuid]: {
            ...state.contentByProcessingUuid[action.payload.processingUuid],
            [action.payload.uuid]: action.payload
          }
        }
      };
    case DELETE_PROCESSING_DISCLAIMER:
      let { [action.payload.processingDisclaimerUuid]: _removedElem, ...rest } =
        state.contentByProcessingUuid[action.payload.processingUuid];
      return {
        ...state,
        contentByProcessingUuid: {
          ...state.contentByProcessingUuid,
          [action.payload.processingUuid]: rest
        }
      };
    default:
      return state;
  }
};

const securityMeasures = (state = [], action) => {
  switch (action.type) {
    case FETCH_SECURITY_MEASURES:
      return action.payload.content;
    default:
      return state;
  }
};

export default combineReducers({
  contentByUuid,
  disclaimers,
  currentPage,
  filters,
  securityMeasures
});
