import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withLoader } from '../../../components/loader/Loader'
import { ChipWrapper, ChipItem } from '../../../components/chiplist/ChipList';
import {
  removeTaskPhysicalEntityAssociation,
  fetchTaskPhysicalEntitiesAssociations,
  createTaskPhysicalEntityAssociation
} from '../../../actions/TasksActions'
import PhysicalEntitySearchModal from '../../systems/physical_entities/commons/PhysicalEntitySearchModal';

class AssociatedPhysicalEntities extends React.Component {
  state = {
    addOpen: false
  }

  render() {
    let { 
      taskUuid,
      associatedPhysicalEntities = [],
      createTaskPhysicalEntityAssociation,
      removeTaskPhysicalEntityAssociation
    } = this.props
    return (
      <>
        <h4>Associated Physical Entities</h4>
        <ChipWrapper onRequestAdd={() => this.setState({ addOpen: true })}>
          {associatedPhysicalEntities.map(ape => (
            <ChipItem label={`${ape.systemName}: ${ape.physicalEntitySchema||''}${ape.physicalEntitySchema?'.':''}${ape.physicalEntityName}`}
              onRequestDelete={() => removeTaskPhysicalEntityAssociation(ape)}></ChipItem>
          ))}
        </ChipWrapper>
        <PhysicalEntitySearchModal open={this.state.addOpen}
          onCancel={() => this.setState({ addOpen: false })}
          onSubmit={(pe) => {
            this.setState({ addOpen: false })
            createTaskPhysicalEntityAssociation({taskUuid,physicalEntityUuid:pe.uuid})
          }}
        ></PhysicalEntitySearchModal>
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  associatedPhysicalEntities: state.tasks.associatedPhysicalEntities[props.taskUuid]
})

export default _.flow([
  withLoader({ onLoad: ({ taskUuid, fetchTaskPhysicalEntitiesAssociations }) => fetchTaskPhysicalEntitiesAssociations(taskUuid, 0, 100) }),
  connect(mapStateToProps, { removeTaskPhysicalEntityAssociation, fetchTaskPhysicalEntitiesAssociations, createTaskPhysicalEntityAssociation })
])(AssociatedPhysicalEntities)