import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ItemSelectField from './ItemSelectField';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

class ItemSelectorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  componentWillReceiveProp(props) {
    this.setState({ value: props.value });
  }

  render() {
    let { fullScreen } = this.props;

    return (
      <Dialog fullScreen={fullScreen} contentStyle={{ maxWidth: '400px' }} open={this.props.open}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <ItemSelectField
            itemName={this.props.itemName}
            keyProperty={this.props.keyProperty}
            nameProperty={this.props.nameProperty}
            value={this.state.value}
            values={this.props.values}
            categoryProperties={this.props.categoryProperties}
            onChange={(val) =>
              this.setState({
                value: val
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button children='Cancel' onClick={this.props.handleCancel} />
          <Button
            children='Submit'
            color='primary'
            disabled={this.state.value === undefined}
            onClick={() => this.props.handleSubmit(this.state.value)}
          />
          ,
        </DialogActions>
      </Dialog>
    );
  }
}

ItemSelectorModal.propTypes = {
  title: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.object,
  values: PropTypes.array.isRequired,
  keyProperty: PropTypes.string.isRequired,
  nameProperty: PropTypes.string.isRequired,
  categoryProperties: PropTypes.array,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default ItemSelectorModal;
