import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Switch, FormGroup } from '@mui/material';

const styles = {
  section: {
    marginLeft: '16px'
  }
};

function GraphMenuPhysicsLayout({
  graphHandler,
  isLayoutDagre,
  isPhysicsEnabled,
  onTogglePhysics,
  onToggleLayout
}) {
  let [t] = useTranslation();

  return (
    <div style={styles.section}>
      <FormGroup>
        <FormControlLabel
          margin='dense'
          control={
            <Switch
              value='physics'
              checked={isPhysicsEnabled}
              onChange={(evn, checked) => onTogglePhysics(checked)}
              color='primary'
            />
          }
          label={t('graph.menu.actions.physicsToggle')}
        />

        <FormControlLabel
          margin='dense'
          control={
            <Switch
              value='dagLayout'
              checked={isLayoutDagre}
              onChange={(evn, checked) => onToggleLayout(checked)}
              color='primary'
            />
          }
          label={t('graph.menu.actions.dagToggle')}
        />
      </FormGroup>
    </div>
  );
}

export default GraphMenuPhysicsLayout;
