import React from 'react';
import DataSubjectsAudienceCard from './DataSubjectsAudienceCard';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
const AudiencePage = (props) => (
  <div>
    <Breadcrumbs elements={[{ text: 'Data Subjects', to: '/datasubjects' }]} />
    <Tracking
      info={{
        pageTitle: 'Data Subjects Audience',
        category: EngagementCategories.CONSENTS,
        action: EngagementActions.LIST
      }}
    />
    <DataSubjectsAudienceCard />
  </div>
);

export default AudiencePage;
