import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tooltip } from '@mui/material';

import PropertiesTableRenderer from './PropertiesTableRenderer';
import { ChipItem } from '../chiplist/ChipList';

const ChipPropertiesTable = ({
  headers,
  propertiesPaths,
  getChipLink,
  rows,
  selectedIndex,
  onSelectionChanged,
  firstStrong
}) => (
  <PropertiesTableRenderer
    {...{
      headers,
      propertiesPaths,
      rows,
      selectedIndex,
      onSelectionChanged,
      firstStrong,
      cellContentComponent: cellContentComponent(getChipLink)
    }}
  />
);

const cellContentComponent =
  (getChipLink) =>
  ({ property, propertyPathIndex, row }) => {
    if (propertyPathIndex === 0) {
      return renderChip(property, row, getChipLink);
    } else if (_.isArray(property)) {
      return renderArray(property);
    } else {
      return renderProperty(property);
    }
  };

function renderChip(property, row, getChipLink) {
  return <ChipItem label={property} to={getChipLink ? getChipLink(row) : undefined}></ChipItem>;
}

function renderProperty(property) {
  let text = _.truncate(property || 'Not defined', { length: 100, omission: ' ...' });
  var enrichedTableCell =
    property && property.length > 100 ? (
      <Tooltip
        sx={{
          maxWidth: '800px'
        }}
        title={property}
        placement='left'>
        <p>{text}</p>
      </Tooltip>
    ) : (
      text
    );
  return enrichedTableCell;
}

function renderArray(arrayProperty) {
  return (
    <ul>
      {arrayProperty.map((p) => (
        <li>{p}</li>
      ))}
    </ul>
  );
}

ChipPropertiesTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  propertiesPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ChipPropertiesTable;
