import React, { useReducer, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Button, FormControlLabel, Checkbox } from '@mui/material';
import FilterList from '@mui/icons-material/FilterList';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import DataCategorySearchModal from '../../datacategories/utils/DataCategorySearchModal';

import {
  clearDataCategoriesFilters,
  setDataCategoriesFilters
} from '../../../actions/DataCategoriesActions';
import { IconButton, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import AccountTeamChipEditor from '../../settings/teams/common/AccountTeamChipEditor';
import CustomPropertiesFilters from '../../../components/additional_properties/CustomPropertiesFilters';
import ResourceTypes from '../../../constants/ResourceTypes';
import _ from 'lodash';
import SystemsSearchModal from 'pages/systems/commons/SystemsSearchModal';
import DataCategoryClassAutocomplete from '../detail/DataCategoryClassAutocomplete';
import DataCategoryClearanceSelect from '../detail/DataCategoryClearanceSelect';

function DataCategoriesFiltersButton({
  filters,
  hasFilters,
  clearDataCategoriesFilters,
  setDataCategoriesFilters
}) {
  let [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        size='small'
        color={hasFilters ? 'primary' : 'grey'}
        style={{ float: 'right' }}
        onClick={() => setOpen(true)}
        children={<FilterList />}
      />
      {open && (
        <DataCategoriesFiltersModal
          open={open}
          onSubmit={(filters) => {
            setOpen(false);
            setDataCategoriesFilters(filters);
          }}
          onClear={() => {
            setOpen(false);
            clearDataCategoriesFilters();
          }}
          {...{ filters, clearDataCategoriesFilters, setDataCategoriesFilters }}
        />
      )}
    </>
  );
}

function DataCategoriesFiltersModal({ open, onSubmit, onClear, filters }) {
  const [addDataCategory, setAddDataCategory] = useState(false);
  const [addSystem, setAddSystem] = useState(false);

  let [filtersEditor, setFiltersEditor] = useReducer((s, a) => ({ ...s, ...a }), filters);
  let { t } = useTranslation();
  let {
    dataClass = null,
    clearance = null,
    teamsCodes = [],
    nullTeamCode = false,
    propertiesNames = [],
    propertiesValues = [],
    nullPropertiesNames = [],
    dataCategories = [],
    systems = []
  } = filtersEditor;

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{t('filters.dataCategoryFilters')}</DialogTitle>
      <DialogContent>
        <DataCategoryClearanceSelect
          fullWidth
          value={clearance}
          onChange={(newValue) => setFiltersEditor({ clearance: newValue })}
        />
        <DataCategoryClassAutocomplete
          fullWidth
          dataClass={dataClass}
          onChange={(dataClass) => setFiltersEditor({ dataClass })}
        />
        <CustomPropertiesFilters
          resourceType={ResourceTypes.DATA_CATEGORY}
          nullPropertiesNames={nullPropertiesNames}
          properties={_.zipWith(propertiesNames, propertiesValues, (name, value) => ({
            name,
            value
          }))}
          onChange={(newProperties) =>
            setFiltersEditor({
              propertiesNames: newProperties.map((p) => p.name),
              propertiesValues: newProperties.map((p) => p.value)
            })
          }
          onChangeNull={(nullNames) =>
            setFiltersEditor({
              nullPropertiesNames: nullNames
            })
          }
        />
        <Typography variant='subtitle1'>{t('data_categories.header')}</Typography>

        <ChipWrapper onRequestAdd={() => setAddDataCategory(true)}>
          {dataCategories.map((dc) => (
            <ChipItem
              key={dc.uuid}
              id={dc.uuid}
              label={dc.name}
              tooltip={dc.description}
              onRequestDelete={() =>
                setFiltersEditor({
                  dataCategories: dataCategories.filter((d) => d.uuid !== dc.uuid)
                })
              }
            />
          ))}
        </ChipWrapper>

        <Typography variant='subtitle1'>{t('systems.header')}</Typography>
        <ChipWrapper onRequestAdd={() => setAddSystem(true)}>
          {systems.map((sys) => (
            <ChipItem
              key={sys.uuid}
              id={sys.uuid}
              label={sys.name}
              tooltip={sys.description}
              onRequestDelete={() =>
                setFiltersEditor({
                  systems: dataCategories.filter((d) => d.uuid !== sys.uuid)
                })
              }
            />
          ))}
        </ChipWrapper>

        <AccountTeamChipEditor
          teamsCodes={teamsCodes}
          onChange={(newTeamsCodes) => {
            setFiltersEditor({
              teamsCodes: newTeamsCodes
            });
          }}
        />
        <FormControlLabel
          label='Only Public'
          control={
            <Checkbox
              checked={nullTeamCode || false}
              onChange={(event, checked) =>
                setFiltersEditor({ nullTeamCode: checked || undefined })
              }
            />
          }></FormControlLabel>
        <DataCategorySearchModal
          open={addDataCategory}
          onCancel={() => setAddDataCategory(false)}
          onSubmit={(dc) => {
            setFiltersEditor({
              dataCategories: dataCategories.concat([dc])
            });
            setAddDataCategory(false);
          }}
        />
        <SystemsSearchModal
          open={addSystem}
          onCancel={() => setAddSystem(false)}
          onSubmit={(sys) => {
            setFiltersEditor({
              systems: systems.concat([sys])
            });
            setAddSystem(false);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.clear')} onClick={onClear} />
        <Button
          variant='outlined'
          color='primary'
          children={t('commons.actions.apply')}
          onClick={() => onSubmit(filtersEditor)}
        />
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  filters: state.datacategories.filters.content,
  hasFilters: state.datacategories.filters.hasFilters || false
});

export default connect(mapStateToProps, { clearDataCategoriesFilters, setDataCategoriesFilters })(
  withTranslation()(DataCategoriesFiltersButton)
);
