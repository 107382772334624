import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import {
  fetchClassificationAgentJobSettings,
  createClassificationAgentJobSettings
} from 'actions/ClassificationAgentJobSettingActions';
import AgentItemAutocomplete from 'pages/settings/agents/commons/AgentItemAutocomplete';
import Permissions from 'constants/Permissions';
import Authorize from 'components/permissions/Authorize';
import ClassificationDefaultSettingsModal from './ClassificationAgentJobSettingsModal';
import { connect } from 'react-redux';
import ResourceTypes from 'constants/ResourceTypes';
import ClassificationAgentJobSettingDetailCard from './ClassificationAgentJobSettingDetailCard';
import _ from 'lodash';

function ClassificationAgentJobSettingsCard({
  fetchClassificationAgentJobSettings,
  createClassificationAgentJobSettings,
  system,
  classificationSettings
}) {
  const [selectedAgent, setSelectedAgent] = useState(null);

  useEffect(() => {
    if (_.isEmpty(classificationSettings)) {
      fetchClassificationAgentJobSettings({
        resourceIdentifier: system.uuid,
        resourceType: ResourceTypes.SYSTEM
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchClassificationAgentJobSettings, classificationSettings]);
  return (
    <div>
      <Grid container alignItems='stretch' direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}></Grid>

        <Grid item style={{ minWidth: '20%' }}>
          <AgentItemAutocomplete
            margin='dense'
            value={selectedAgent}
            onChange={(agent) => {
              setSelectedAgent(agent);
            }}></AgentItemAutocomplete>
        </Grid>
      </Grid>

      {!_.isEmpty(classificationSettings) ? (
        <ClassificationAgentJobSettingDetailCard
          classificationAgentJobSettings={classificationSettings[0]}
          system={system}
          agent={selectedAgent}></ClassificationAgentJobSettingDetailCard>
      ) : (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='body1'>No default settings defined for this system </Typography>
          <AddButton
            system={system}
            agent={selectedAgent}
            createClassificationAgentJobSettings={createClassificationAgentJobSettings}
          />
        </div>
      )}
    </div>
  );
}

function AddButton({ system, agent, createClassificationAgentJobSettings }) {
  const [openCreate, setOpenCreate] = useState(false);

  return (
    <>
      <Authorize hasPermissions={[Permissions.CLASSIFICATION_EDITOR]}>
        <Button onClick={() => setOpenCreate(true)}>Add Default Settings</Button>
      </Authorize>
      {openCreate && (
        <ClassificationDefaultSettingsModal
          resourceIdentifier={system.uuid}
          resourceType={ResourceTypes.SYSTEM}
          agent={agent}
          open={openCreate}
          onClose={() => setOpenCreate(false)}
          onSubmit={(setting) => {
            createClassificationAgentJobSettings(setting);
            setOpenCreate(false);
          }}></ClassificationDefaultSettingsModal>
      )}
    </>
  );
}

function mapStateToProps(state, props) {
  return {
    classificationSettings:
      state.classification.agentjobsettings.contentBySystemUuid[props.system.uuid]
  };
}

export default connect(mapStateToProps, {
  fetchClassificationAgentJobSettings,
  createClassificationAgentJobSettings
})(ClassificationAgentJobSettingsCard);
