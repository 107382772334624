import React, { useState } from 'react';
import {
  setQualityResultsFilters,
  clearQualityResultsFilters
} from '../../../../../actions/QualityResultsActions';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';
import DatePicker, { hourAdjustments } from '../../../../../components/pickers/DatePicker';
import QualitySemaphore from './QualitySemaphore';
import {
  IconButton,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import { connect } from 'react-redux';

function QualityResultFilters({
  filters,
  checkUuid,
  setQualityResultsFilters,
  clearQualityResultsFilters,
  pageSize,
  hasFilters
}) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <Tooltip title='Filters'>
        <IconButton
          color={hasFilters ? 'primary' : 'grey'}
          size='small'
          onClick={() => setOpen(true)}>
          <FilterListIcon></FilterListIcon>
        </IconButton>
      </Tooltip>
      {open && (
        <QualityResultFiltersModal
          open={open}
          setOpen={setOpen}
          filters={filters}
          checkUuid={checkUuid}
          onClear={clearQualityResultsFilters}
          onSubmit={setQualityResultsFilters}
          pageSize={pageSize}></QualityResultFiltersModal>
      )}
    </React.Fragment>
  );
}

function QualityResultFiltersModal({ open, filters, onClear, onSubmit, setOpen }) {
  const INITIAL_STATE = {
    startedAtGte: null,
    startedAtLt: null,
    semaphore: null
  };

  const [filtersEditor, setFiltersEditor] = useState(filters || INITIAL_STATE);

  const semaphores = [QualitySemaphore.RED, QualitySemaphore.GREEN, QualitySemaphore.YELLOW];
  let { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth={true}>
        <DialogTitle>{'Set Filters'}</DialogTitle>
        <DialogContent>
          <DatePicker
            clearable={true}
            label={'Start Date'}
            hintText={'Start Date'}
            hourAdjustment={hourAdjustments.startOfDay}
            value={filtersEditor.startedAtGte || null}
            maxDate={filtersEditor?.startedAtLt}
            onChange={(date) => setFiltersEditor({ ...filtersEditor, startedAtGte: date })}
          />

          <DatePicker
            clearable={true}
            label={'End Date'}
            hintText={'End Date'}
            hourAdjustment={hourAdjustments.endOfDay}
            value={filtersEditor.startedAtLt || null}
            minDate={filtersEditor?.startedAtGte}
            onChange={(date) => setFiltersEditor({ ...filtersEditor, startedAtLt: date })}
          />
          <FormControl variant='standard' fullWidth={true}>
            <InputLabel id='semaphore-colour-selector-label'>Semaphore</InputLabel>
            <Select
              variant='standard'
              labelId='semaphore-colour-selector-label'
              id='select-semaphore-colour'
              value={filtersEditor.semaphores || null}
              onChange={(event) =>
                setFiltersEditor({ ...filtersEditor, semaphores: event.target.value })
              }>
              <MenuItem value={null}>
                <em>None</em>
              </MenuItem>
              {semaphores.map((semaphore) => (
                <MenuItem value={semaphore}>{semaphore}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.clear')}
            onClick={() => {
              setOpen(false);

              onClear();
            }}></Button>
          <Button
            children={t('commons.actions.apply')}
            variant='outlined'
            color='primary'
            onClick={() => {
              setOpen(false);
              onSubmit(filtersEditor);
            }}></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

function mapStateToProps(state, props) {
  return {
    filters: state.quality.qualityResults.filters.content,
    hasFilters: state.quality.qualityResults.filters.hasFilters || false
  };
}

export default connect(mapStateToProps, {
  setQualityResultsFilters,
  clearQualityResultsFilters
})(QualityResultFilters);
