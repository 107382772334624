import { buildApiCallAction } from './ActionUtils';
import ExportsApi from '../api/ExportsApi';
import {
  EXPORT_STEWARDSHIP_RESPONSIBILITY,
  EXPORT_STEWARDSHIP_ROLE,
  EXPORT_STEWARDSHIP_ASSIGNMENTS
} from '../constants/ActionTypes';

const api = new ExportsApi();

export const exportDataCategories = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) =>
      api.exportDataCategories({
        ...params,
        dataCategoriesUuid: params.dataCategories && params.dataCategories.map((dc) => dc.uuid)
      }),
    actionType: 'EXPORT_DATACATEGORIES'
  });

export const exportStewardshipResponsibilities = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.exportStewardshipResponsibilities(params),
    actionType: EXPORT_STEWARDSHIP_RESPONSIBILITY
  });
export const exportStewardshipRoles = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.exportStewardshipRoles(params),
    actionType: EXPORT_STEWARDSHIP_ROLE
  });

export const exportStewardshipAssignments = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.exportStewardshipAssignments(params),
    actionType: EXPORT_STEWARDSHIP_ASSIGNMENTS
  });
