import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchQualityChecksTimelineOverall } from 'actions/QualityDashboardsActions';

import QualityChecksTimelineCard from 'pages/quality/kqis/quality_checks/commons/QualityChecksTimelineCard';

const QualityChecksTimelineOverallCard = ({ data, filters, fetchQualityChecksTimelineOverall }) => {

  const [precision, setPrecision] = useState('day');

  useEffect(() => {
    fetchQualityChecksTimelineOverall({ ...filters, precision });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const handleSetPrecision = ({ precision }) => {
    setPrecision(precision);
    fetchQualityChecksTimelineOverall({ ...filters, precision });
  }

  return (
    <QualityChecksTimelineCard
      withDatesFilters={false}
      data={data}
      filters={{ precision }}
      setQualityChecksTimelineFilters={handleSetPrecision}
      chartWidth='100%'
    />
  );
};

const mapStateToProps = (state, props) => ({
  data: state.quality.qualityDashboards.overall.qualityChecksTimelineOverall,
  filters: state.quality.qualityDashboards.overall.filters.content
})

const mapDispatchToProps = {
  fetchQualityChecksTimelineOverall
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityChecksTimelineOverallCard);