import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchResidualRiskCounterDashbordData } from 'actions/quality/assessment/QualityAssessmentDashboardActions';

import { Card, CardHeader, CardContent, Typography } from '@mui/material';

import { Chart } from 'react-google-charts';

import {
  greenSemaphoreColor,
  yellowSemaphoreColor,
  redSemaphoreColor
} from 'pages/quality/kqis/quality_checks/commons/QualitySemaphoreColors';

const styles = {
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    minHeight: '100px',
    overflowX: 'auto'
  }
};

const ResidualRisksCounterCard = ({ data, fetchDashboardValues }) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchDashboardValues();
  }, [fetchDashboardValues]);

  const headers = ['Risk Values', ' Count', { role: 'style' }];
  const rows = data?.rows.map((row) => [row[0], row[2], getColor(row[1], data?.rows.length)]);

  return (
    <Card>
      <CardHeader title='Residual Risk' subheader='Distribution of residual risks' />
      <CardContent sx={styles.cardContent}>
        {data?.rows &&
        data?.rows.length > 0 &&
        data?.rows.reduce((acc, curr) => acc + curr[2], 0) > 0 ? (
          <Chart
            chartType='ColumnChart'
            options={{
              width: '100%',
              height: '100%',
              chartArea: { width: '90%' },
              bar: { groupWidth: '20%' },
              legend: { position: 'none' },
              vAxis: {
                textPosition: 'none',
                minValue: 0
              }
            }}
            data={[headers, ...rows]}
          />
        ) : (
          <Typography variant='overline'>{t('commons.placeholders.nothingToDisplay')}</Typography>
        )}
      </CardContent>
    </Card>
  );
};

function getColor(value, totalDomainElements) {
  if (value < totalDomainElements / 3) {
    return greenSemaphoreColor;
  }
  if (value > Math.round(totalDomainElements / 3) * 2) {
    return redSemaphoreColor;
  }
  return yellowSemaphoreColor;
}

const mapStateToProps = (state, props) => ({
  data: state.quality.assessmentDashboards.counter
});

const mapDispatchToProps = {
  fetchDashboardValues: fetchResidualRiskCounterDashbordData
};

export default connect(mapStateToProps, mapDispatchToProps)(ResidualRisksCounterCard);
