import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import FilterList from '@mui/icons-material/FilterList';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import { clearTasksFilters, setTasksFilters } from '../../../actions/TasksActions';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import ProcessingsSearchModal from '../../processings/utils/ProcessingSearchModal';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  Grid,
  MenuItem,
  InputLabel
} from '@mui/material';
import AccountTeamChipEditor from '../../settings/teams/common/AccountTeamChipEditor';
import DatePicker, { hourAdjustments } from 'components/pickers/DatePicker';

class TasksFiltersButton extends Component {
  state = {
    open: false,
    addProcessing: false
  };

  render() {
    let { t } = this.props;
    return (
      <span>
        <IconButton
          size='small'
          color={this.props.hasFilters ? 'primary' : 'grey'}
          style={{ float: 'right' }}
          onClick={() => this.setState({ open: true })}
          children={<FilterList />}
        />
        <Dialog fullWidth modal={true} open={this.state.open}>
          <DialogTitle>{t('tasks.filtersTitle')}</DialogTitle>
          <DialogContent>
            <Typography variant='subtitle1'>{t('processings.header')}</Typography>
            <ChipWrapper onRequestAdd={() => this.setState({ addProcessing: true })}>
              {this.props.processings.map((processing) => (
                <ChipItem
                  key={processing.uuid}
                  id={processing.uuid}
                  label={processing.name}
                  tooltip={processing.description}
                  onRequestDelete={() =>
                    this.props.setTasksFilters({
                      processings: this.props.processings.filter((p) => p.uuid !== processing.uuid)
                    })
                  }
                />
              ))}
            </ChipWrapper>
            <AccountTeamChipEditor
              teamsCodes={this.props.teamsCodes}
              onChange={(teamsCodes) => {
                this.props.setTasksFilters({
                  teamsCodes: teamsCodes
                });
              }}></AccountTeamChipEditor>
            <FormControlLabel
              label='Only Public'
              control={
                <Checkbox
                  checked={this.props.nullTeamCode || false}
                  onChange={(event, checked) =>
                    this.props.setTasksFilters({ nullTeamCode: checked || undefined })
                  }
                />
              }></FormControlLabel>
            <Typography variant='subtitle1'>Life Cycle</Typography>
            <Grid container direction='row' alignContent='center' alignItems='center' fullWidth>
              <Grid item xs={5}>
                <FormControl variant='standard' fullWidth>
                  <InputLabel fullWidth>State</InputLabel>
                  <Select
                    variant='standard'
                    fullWidth
                    defaultValue={this.props?.lifeCycleState}
                    onChange={(event) =>
                      this.props.setTasksFilters({ lifeCycleState: event.target.value })
                    }>
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'EXPIRED'}>Expired</MenuItem>
                    <MenuItem value={'ALIVE'}>Alive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ marginTop: '25%', marginLeft: '35%' }}>At</Typography>
              </Grid>
              <Grid item xs={5}>
                <DatePicker
                  clearable={true}
                  label={'End Date'}
                  hintText={'End Date'}
                  margin='dense'
                  disabled={!this.props.lifeCycleState}
                  hourAdjustment={hourAdjustments.endOfDay}
                  value={this.props.endDate}
                  onChange={(date) => this.props.setTasksFilters({ endDate: date })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              children={t('commons.actions.clear')}
              onClick={() => {
                this.props.clearTasksFilters();
                this.setState({ open: false });
                this.props.onRefresh();
              }}
            />
            <Button
              variant='outlined'
              color='primary'
              children={t('commons.actions.apply')}
              onClick={() => {
                this.setState({ open: false });
                this.props.onRefresh();
              }}
            />
          </DialogActions>
        </Dialog>
        <ProcessingsSearchModal
          open={this.state.addProcessing}
          onCancel={() => this.setState({ addProcessing: false })}
          onSubmit={(p) => {
            this.props.setTasksFilters({
              processings: this.props.processings.concat([p])
            });
            this.setState({ addProcessing: false });
          }}
        />
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  hasFilters: state.tasks.filters.hasFilters,
  processings: state.tasks.filters.processings || [],
  teamsCodes: state.tasks.filters.teamsCodes || [],
  nullTeamCode: state.tasks.filters.nullTeamCode,
  lifeCycleState: state.tasks.filters.lifeCycleState || null,
  endDate: state.tasks.filters.endDate || null,
  sort: state.tasks.filters.sort
});

export default connect(mapStateToProps, { clearTasksFilters, setTasksFilters })(
  withTranslation()(TasksFiltersButton)
);
