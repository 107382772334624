import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AccountTeamSelector, {
  ACCESS_LEVELS
} from '../../../settings/profile/utils/AccountTeamSelector';
import PermissionsEditor from '../../../settings/users/commons/PermissionsEditor';
import ResourceApplicability from './ResourceApplicability';

function StewardshipRoleModal({
  open,
  stewardshipRole = { resources: [], permissions: [] },
  onClose,
  onSubmit,
  fullScreen = false
}) {
  const { t } = useTranslation();
  const [contentPatch, setContentPatch] = React.useState({});
  const mergedContent = { ...stewardshipRole, ...contentPatch };
  return (
    <Dialog fullWidth fullScreen={fullScreen} open={open}>
      <DialogTitle>Role</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter the name'}
          label={'Name'}
          value={mergedContent.name || ''}
          onChange={(event) => setContentPatch({ ...contentPatch, name: event.target.value })}
        />
        <TextField
          variant='standard'
          fullWidth
          multiline
          placeholder={'Enter the description'}
          label={'Description'}
          value={mergedContent.description || ''}
          onChange={(event) =>
            setContentPatch({ ...contentPatch, description: event.target.value })
          }
        />
        <TextField
          variant='standard'
          fullWidth
          type='number'
          placeholder={'Enter the order'}
          label={'Order'}
          value={mergedContent.order || ''}
          onChange={(event) => setContentPatch({ ...contentPatch, order: event.target.value })}
        />
        <TextField
          variant='standard'
          fullWidth
          multiline
          placeholder={'Enter the Role Category'}
          label={'Role Category'}
          value={mergedContent.roleCategory || ''}
          onChange={(event) =>
            setContentPatch({ ...contentPatch, roleCategory: event.target.value })
          }
        />
        <ResourceApplicability
          resources={mergedContent.resources}
          onChange={(resources) =>
            setContentPatch({ ...contentPatch, resources })
          }></ResourceApplicability>
        <h4>Permissions</h4>
        <PermissionsEditor
          permissions={mergedContent.permissions || []}
          onChange={(permissions) =>
            setContentPatch({ ...contentPatch, permissions })
          }></PermissionsEditor>
      </DialogContent>
      <DialogActions>
        <AccountTeamSelector
          style={{ float: 'left' }}
          accessLevel={ACCESS_LEVELS.WRITE}
          selectedTeamCode={mergedContent.teamCode || ''}
          onChange={(code) => setContentPatch({ ...contentPatch, teamCode: code })}
        />
        <div style={{ flexGrow: 1 }} />
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={!mergedContent.name}
          onClick={() => {
            onSubmit({ ...mergedContent }, contentPatch);
          }}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StewardshipRoleModal;
