import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Grid
} from '@mui/material';
import { HelpIcon } from 'components/help';
import AutoComplete from 'components/autocomplete/AutoComplete';
import QualityProbesDefinitionModalJDBCParameters from './QualityProbesDefinitionModalJDBCParameters';
import QualityProbesDefinitionModalMongoParameters from './QualityProbesDefinitionModalMongoParameters';
import QualityCheckFormSection from 'pages/quality/kqis/quality_checks/commons/QualityCheckFormSection';
import QualityProbesConnectionFormSection from '../../connections/commons/QualityProbesConnectionFormSection';
import QualityCheckGroupCodeItemAutocomplete from 'pages/quality/kqis/quality_checks/commons/QualityCheckGroupCodeItemAutocomplete';
import _ from 'lodash';
import ProbeTestButton from 'pages/quality/probes/definitions/common/ProbeTestButton';
import HelpIconTable from './HelpIconTable';
import ProbeTypes from './ProbeTypes';

function QualityProbesDefinitionModal({
  title,
  probeDefinition,
  onSubmit,
  onClose,
  open,
  associatedCheck,
  passedProject
}) {
  const { t } = useTranslation();
  const [check, setCheck] = useState({
    name: associatedCheck ? associatedCheck?.name : probeDefinition?.checkName,
    code: associatedCheck ? associatedCheck?.code : probeDefinition?.checkCode
  });
  const [checkGroupCode, setCheckGroupCode] = useState(probeDefinition?.checkGroupCode || null);
  const [name, setName] = useState(probeDefinition?.name);
  const [type, setType] = useState(probeDefinition?.type);
  const [query, setQuery] = useState((probeDefinition && probeDefinition.queries[0]) || null);
  const [secondQuery, setSecondQuery] = useState(
    (probeDefinition && probeDefinition.queries[1]) || null
  );
  const project = passedProject || probeDefinition?.project;
  const handleChangeProbeType = (type) => {
    setType(type);
    if (type !== ProbeTypes.TABULAR_METRICS) {
      setCheckGroupCode(null);
    }
    if (type === ProbeTypes.TABULAR_METRICS) {
      setCheck(null);
    }
    if (
      type !== ProbeTypes.DOUBLE_METRICS ||
      type !== ProbeTypes.RECORDS_COMPARISON ||
      type !== ProbeTypes.EXPORT_ROWS
    ) {
      setSecondQuery(null);
    }
  };
  return (
    <Dialog open={open} fullWidth maxWidth='lg'>
      <DialogTitle>
        <Typography variant='h6'>{title}</Typography>
        {project && (
          <Typography color='textSecondary' variant='subtitle2'>
            You are {probeDefinition ? 'modifying' : 'creating'} a probe for project {project.name}
          </Typography>
        )}
      </DialogTitle>

      <DialogContent>
        <TextField
          variant='standard'
          margin='dense'
          required
          fullWidth
          placeholder={'Enter the probe name'}
          label={'Name'}
          value={name || ''}
          onChange={(event) => setName(event.target.value)}
        />
        <Grid container direction='row' alignItems='center'>
          <Grid item style={{ flexGrow: 1 }}>
            <AutoComplete
              required
              margin='dense'
              fullWidth
              label={'Type'}
              placeholder='Select probe type'
              value={ProbeTypes[type] || ''}
              dataSource={Object.values(ProbeTypes)}
              onUpdateInput={(newType) => handleChangeProbeType(newType)}
              onNewRequest={handleChangeProbeType}
            />
          </Grid>
          <Grid item>
            <HelpIcon tooltip={<HelpIconTable />}></HelpIcon>
          </Grid>
        </Grid>
        <h4>Quality Check</h4>
        {(type === ProbeTypes.TABULAR_METRICS || checkGroupCode) && (
          <QualityCheckGroupCodeItemAutocomplete
            checkGroupCode={checkGroupCode}
            onChange={(checkGroupCode) => {
              setCheckGroupCode(checkGroupCode);
              setCheck(null);
            }}></QualityCheckGroupCodeItemAutocomplete>
        )}
        {(type !== ProbeTypes.TABULAR_METRICS || check) && (
          <QualityCheckFormSection
            check={check}
            onChange={(check) => {
              setCheck(check);
              setCheckGroupCode(null);
            }}></QualityCheckFormSection>
        )}
        {type && (
          <QuerySection
            title={
              Object.values(ProbeTypes).includes(type)
                ? t(`quality.probes.definitions.labels.firstQuery.${type}`)
                : type
            }
            query={query}
            setQuery={setQuery}
          />
        )}
        {(type === ProbeTypes.DOUBLE_METRICS ||
          type === ProbeTypes.RECORDS_COMPARISON ||
          type === ProbeTypes.EXPORT_ROWS) && (
          <QuerySection
            title={t(`quality.probes.definitions.labels.secondQuery.${type}`)}
            query={secondQuery}
            setQuery={setSecondQuery}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <ProbeTestButton
          probe={{
            ...probeDefinition,
            name: name,
            checkCode: check && check.code,
            checkName: check && check.name,
            type: type,
            project: project,
            queries: [query, ...(secondQuery ? [secondQuery] : [])],
            checkGroupCode: checkGroupCode
          }}
          disabled={getDisabled({
            check,
            checkGroupCode,
            name,
            type,
            query,
            secondQuery
          })}></ProbeTestButton>
        <Button
          disabled={getDisabled({ check, checkGroupCode, name, type, query, secondQuery })}
          onClick={() => {
            onSubmit({
              ...probeDefinition,
              name: name,
              checkCode: check && check.code,
              checkName: check && check.name,
              type: type,
              project: project,
              queries: [query, ...(secondQuery ? [secondQuery] : [])],
              checkGroupCode: checkGroupCode
            });
          }}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const QuerySection = ({ title, query, setQuery }) => {
  return (
    <>
      <h4>{title}</h4>
      <QualityProbesConnectionFormSection
        connection={{ name: query?.connectionName, type: query?.connectionType }}
        onChange={(connection) => {
          setQuery({
            ...query,
            connectionName: connection?.name,
            connectionType: connection?.type
          });
        }}></QualityProbesConnectionFormSection>

      {query?.connectionType?.toUpperCase() === 'JDBC' && (
        <QualityProbesDefinitionModalJDBCParameters query={query} onChange={setQuery} />
      )}
      {query?.connectionType?.toUpperCase() === 'MONGO' && (
        <QualityProbesDefinitionModalMongoParameters query={query} onChange={setQuery} />
      )}
    </>
  );
};

export function getDisabled({ check, checkGroupCode, name, type, query, secondQuery }) {
  const isNameNull = _.isEmpty(name);
  const isTypeNull = _.isEmpty(type);
  const isCheckNameNull = type !== ProbeTypes.TABULAR_METRICS && _.isEmpty(check?.name);
  const isCheckCodeNull = type !== ProbeTypes.TABULAR_METRICS && _.isEmpty(check?.code);
  const isCheckGroupCodeNull = type === ProbeTypes.TABULAR_METRICS && _.isEmpty(checkGroupCode);
  const isSecondQueryValid =
    type === ProbeTypes.DOUBLE_METRICS || type === ProbeTypes.RECORDS_COMPARISON
      ? secondQuery && isQueryDefinitionValid(secondQuery)
      : true;
  return (
    isNameNull ||
    isTypeNull ||
    isCheckNameNull ||
    isCheckCodeNull ||
    isCheckGroupCodeNull ||
    !isQueryDefinitionValid(query) ||
    !isSecondQueryValid
  );
}

export function isQueryDefinitionValid(queryDefinition) {
  const isConnectionPropertiesValid =
    !_.isEmpty(queryDefinition?.connectionName) && !_.isEmpty(queryDefinition?.connectionType);

  const isQueryJDBCValid =
    queryDefinition?.connectionType?.toUpperCase() === 'JDBC' &&
    !_.isEmpty(queryDefinition?.queryBody?.text);

  const isQueryMongoValid =
    queryDefinition?.connectionType?.toUpperCase() === 'MONGO' &&
    !_.isEmpty(queryDefinition?.queryBody?.collection) &&
    queryDefinition?.queryBody?.pipeline?.length > 0;
  return isConnectionPropertiesValid && (isQueryJDBCValid || isQueryMongoValid);
}

export default QualityProbesDefinitionModal;
