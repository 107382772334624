import { buildApiCallAction } from '../../ActionUtils';
import ProbesConnectionsApi from '../../../api/quality/probes/ProbesConnectionsApi';
import {
  FETCH_PROBE_CONNECTIONS_PAGE,
  FETCH_PROBE_CONNECTION,
  CREATE_PROBE_CONNECTION,
  MODIFY_PROBE_CONNECTION,
  DELETE_PROBE_CONNECTION,
  SEARCH_PROBE_CONNECTIONS,
  EXPORT_PROBE_CONNECTIONS
} from '../../../constants/ActionTypes';

const api = new ProbesConnectionsApi();

export const searchProbeConnection = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getConnectionsPage(params),
    actionType: SEARCH_PROBE_CONNECTIONS
  });
export const fetchProbeConnection = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getConnection(uuid),
    actionType: FETCH_PROBE_CONNECTION
  });

export const fetchProbeConnectionPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getConnectionsPage(params),
    actionType: FETCH_PROBE_CONNECTIONS_PAGE
  });

export const createProbeConnection = (connection) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postProbeConnection(connection),
    actionType: CREATE_PROBE_CONNECTION
  });

export const modifyProbeConnection = (connection) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putProbeConnection(connection),
    actionType: MODIFY_PROBE_CONNECTION
  });

export const deleteProbeConnection = (connection) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteProbeConnection(connection.uuid),
    actionType: DELETE_PROBE_CONNECTION,
    payload: connection
  });

export const exportProbeConnections = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportProbeConnections(params),
    actionType: EXPORT_PROBE_CONNECTIONS
  });
