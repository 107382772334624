import React, { useState } from 'react';
import { compose } from 'redux';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  Card,
  CardContent,
  Typography,
  TablePagination
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import {
  fetchLogicalFieldsPage,
  deleteLogicalField,
  modifyLogicalField,
  setLogicalFieldsFilters
} from 'actions/LogicalFieldsActions';
import { fetchDashboardTypeCounterIfAllowedLargeRequest } from 'actions/IssueMngtDashboardsActions';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ChipWrapper, ChipItem } from '../../../components/chiplist/ChipList';
import LogicalFieldsFiltersModal from '../commons/LogicalFieldFiltersModal';
import LogicalFieldsKPIsButton from './LogicalFieldsKPIsButton';
import LogicalFieldsActions from './LogicalFieldsActions';
import { useTranslation } from 'react-i18next';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';
import LogicalFieldsExportButton from '../commons/LogicalFieldsExportButton';
import LogicalFieldsActionsModals from './LogicalFieldsActionsModals';
import TableCell from 'components/tables/GenericTableCell';
import Breadcrumbs from 'pages/sidebar/Navigation';
import ExternalHelper from 'components/help/ExternalHelper';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import ResourceTypes from 'constants/ResourceTypes';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';
import useRoutePagination from 'components/pagination/useRoutePagination';
import SearchBox from 'components/search-box/SearchBox';

function LogicalFieldListPage({
  filters,
  currentPage,
  setLogicalFieldsFilters,
  onPageSelection,
  deleteLogicalField,
  modifyLogicalField,
  hasFilters
}) {
  let { t } = useTranslation();
  const history = useHistory();

  const [selectedField, setSelectedField] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleEdit = (field) => {
    modifyLogicalField(field);
    setEditModalOpen(false);
  };
  const handleDelete = (field) => {
    deleteLogicalField(field);
    setDeleteModalOpen(false);
  };

  const { changePagination } = useRoutePagination({
    currentPage,
    onPageSelection: (page, size) => {
      onPageSelection({ page, size, ...filters });
    },
    dependencies: [filters]
  });

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.logical_model'), to: '/datacategories' },
          { text: t('navbar.logicalFields') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('logicalFields.header'),
          category: EngagementCategories.LOGICAL_MODEL,
          action: EngagementActions.LIST
        }}
      />

      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>
            {t('logicalFields.header')}
            <ExternalHelper resourceType='logicalfields'></ExternalHelper>
          </Typography>
        </Grid>
        <Grid item>
          <SearchBox
            value={filters.search}
            onChange={({ value }) => setLogicalFieldsFilters({ search: value })}
          />
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:logicalfields-list'}
            iconSize={'small'}
            pageTitle={t('logicalFields.header')}
          />
        </Grid>
        <Grid item>
          <LogicalFieldsKPIsButton />
        </Grid>
        <Grid item>
          <LogicalFieldsExportButton></LogicalFieldsExportButton>
        </Grid>
        <Grid item>
          <LogicalFieldsFiltersModal key={filters} hasFilters={hasFilters} filters={filters} />
        </Grid>
        <Grid item>
          <WebActionsButton page={TargetPages.LOGICAL_FIELD_LIST_PAGE} />
        </Grid>
      </Grid>
      <Card>
        <CardContent style={{ overflowX: 'auto' }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>{t('resourceType.DATA_CATEGORY')}</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>DataType</TableCell>
                <TableCell hiddenMd>Description</TableCell>
                <TableCell>Actions</TableCell>
                <Authorize hasPermissions={[Permissions.CAMPAIGNS_VIEWER]}>
                  <TableCell hiddenSm>Issues</TableCell>
                </Authorize>
              </TableRow>
            </TableHead>
            <TableBody>
              {(currentPage.content || []).map((logicalField) => (
                <TableRow
                  hover='true'
                  key={logicalField.uuid}
                  onClick={(event) => {
                    history.push(`/logicalfields/${logicalField.uuid}`);
                  }}
                  style={{ cursor: 'pointer' }}>
                  <TableCell align='center'>
                    <ChipWrapper>
                      <ChipItem
                        label={logicalField.dataCategory.name}
                        component={Link}
                        to={`/datacategories/${logicalField.dataCategory.uuid}`}
                        onClick={(event) => event.stopPropagation()}
                      />
                    </ChipWrapper>
                  </TableCell>
                  <TableCell>{logicalField.name}</TableCell>
                  <TableCell>{logicalField.dataType}</TableCell>
                  <TableCell breakWord hiddenMd>
                    {_.truncate(logicalField.description, { length: 125 })}
                  </TableCell>
                  <TableCell>
                    <LogicalFieldsActions
                      logicalField={logicalField}
                      onShowDetails={() => history.push(`/logicalfields/${logicalField.uuid}`)}
                      onEdit={() => {
                        setSelectedField(logicalField);
                        setEditModalOpen(true);
                      }}
                      onDelete={() => {
                        setSelectedField(logicalField);
                        setDeleteModalOpen(true);
                      }}
                    />
                  </TableCell>
                  <Authorize hasPermissions={[Permissions.CAMPAIGNS_VIEWER]}>
                    <TableCell hiddenSm>
                      <IssueResourceListItemWidget
                        resourceType={ResourceTypes.LOGICAL_FIELD}
                        resourceIdentifier={logicalField.uuid}></IssueResourceListItemWidget>
                    </TableCell>
                  </Authorize>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100, 200]}
            component='div'
            style={{ overflowX: 'hidden' }}
            count={currentPage.totalElements}
            rowsPerPage={currentPage.size}
            page={currentPage.number}
            onPageChange={(event, page) => changePagination({ page })}
            onRowsPerPageChange={(event) => changePagination({ size: event.target.value })}
          />
          <LogicalFieldsActionsModals
            selectedField={selectedField}
            editModalOpen={editModalOpen}
            setEditModalOpen={setEditModalOpen}
            onEdit={handleEdit}
            deleteModalOpen={deleteModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            onDelete={handleDelete}
          />
        </CardContent>
      </Card>
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.logicalfields.currentPage,
    filters: state.logicalfields.filters.content,
    hasFilters: state.logicalfields.filters.hasFilters
  };
}

function mapDispatchToProps(dispatch, { filters }) {
  return {
    onSearch: (search) => dispatch(fetchLogicalFieldsPage({ search: search })),
    onPageSelection: (props) =>
      dispatch(fetchLogicalFieldsPage({ ...filters, ...props })).then((response) => {
        response &&
          response.content.length > 0 &&
          dispatch(
            fetchDashboardTypeCounterIfAllowedLargeRequest(
              response.content,
              25,
              ResourceTypes.LOGICAL_FIELD
            )
          );
      }),
    modifyLogicalField: (logicalField) => dispatch(modifyLogicalField(logicalField)),
    deleteLogicalField: (logicalField) => dispatch(deleteLogicalField(logicalField)),
    setLogicalFieldsFilters: (filters) => dispatch(setLogicalFieldsFilters(filters))
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(LogicalFieldListPage);
