import React, { useEffect } from 'react';
import useSearchParams from 'components/useSearchParams';
import { useHistory } from 'react-router';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';

import { fetchSystem } from 'actions/SystemsActions';

import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import ResourceTypes from 'constants/ResourceTypes';

import { Grid, Typography, Tabs, Tab } from '@mui/material';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import SystemDetailCard from './SystemDetailCard';
import SystemMetadataMetricsPanel from './SystemMetadataMetricsPanel';
import SystemDataSubjectPanel from './SystemDataSubjectsPanel';
import DataFlowCard from '../../dataflows/utils/DataFlowCard';
import CrawlingCard from './crawling/CrawlingCard';
import Permissions from 'constants/Permissions';
import withAuthorize from 'components/permissions/withAuthorize';
import ClassificationAgentJobSettingsCard from 'pages/classification/agentsettings/ClassificationAgentJobSettingsCard';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const styles = {
  title: {
    marginBottom: '8px'
  }
};

function SystemDetail({ systemUuid, system, t, fetchSystem }) {
  useEffect(() => {
    fetchSystem(systemUuid);
  }, [systemUuid, fetchSystem]);

  const query = useSearchParams();
  const history = useHistory();
  const selectedTab = query.get('tab') || 'stats';
  const handleChangeTab = (_event, newValue) =>
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });
  if (!system) {
    return null;
  }
  return system ? (
    <div>
      <Breadcrumbs
        elements={[{ text: t('sidebar.physical_model'), to: '/systems' }, { text: system.name }]}
      />
      <Tracking
        info={{
          pageTitle: t('systems.header_detail', { systemName: system.name }),
          category: EngagementCategories.PHYSICAL_MODEL,
          action: EngagementActions.DETAIL
        }}
      />

      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography sx={styles.title} variant='h4'>
            {t('systems.header_detail', { systemName: system.name })}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`${ResourceTypes.SYSTEM}:${system.uuid}`}
            resourceIdentifier={system.uuid}
            resourceType={ResourceTypes.SYSTEM}
            resourceName={system.name}
            pageTitle={t('systems.header_detail', { systemName: system.name })}></FavoritesWidget>
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.SYSTEM}
            resourceIdentifier={system?.uuid}
            resourceName={system?.name}
          />
        </Grid>
      </Grid>
      <SystemDetailCard system={system} />
      <Tabs value={selectedTab} onChange={handleChangeTab} centered>
        <Tab value='stats' label='Stats' />
        <Tab value='dataflows' label='Dataflows' />
        <AuthorizedTab
          hasPermissions={[Permissions.AGENTS_VIEWER]}
          value='crawling'
          label='Crawling'
        />
        <AuthorizedTab
          hasPermissions={[Permissions.CLASSIFICATION_VIEWER]}
          value='classification'
          label='Classification'
        />
      </Tabs>

      {selectedTab === 'stats' && (
        <>
          <SystemMetadataMetricsPanel system={system} systemUuid={systemUuid} />
          <SystemDataSubjectPanel systemUuid={systemUuid} />
        </>
      )}
      {selectedTab === 'dataflows' && <DataFlowCard systemUuid={systemUuid} />}
      {selectedTab === 'crawling' && <CrawlingCard system={system} />}

      {selectedTab === 'classification' && <ClassificationAgentJobSettingsCard system={system} />}
    </div>
  ) : null;
}
const AuthorizedTab = withAuthorize(Tab, 'span');

const mapStateToProps = (state, props) => ({
  systemUuid: props.match.params['systemUuid'],
  system: state.systems.content[props.match.params['systemUuid']]
});

export default _.flow(withTranslation(), connect(mapStateToProps, { fetchSystem }))(SystemDetail);
