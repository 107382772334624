import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';

function AgentModal({ title, open, agent, onSubmit, onClose }) {
  const { t } = useTranslation();

  const [agentName, setAgentName] = useState(agent?.name);
  const [agentUrl, setAgentUrl] = useState(agent?.baseUrl);

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          required
          fullWidth
          margin='dense'
          placeholder={'Enter the agent name'}
          label={'Name'}
          value={agentName || ''}
          onChange={(event) => setAgentName(event.target.value)}
        />
        <TextField
          variant='standard'
          required
          fullWidth
          margin='dense'
          placeholder={'Enter the agent base URL'}
          label={'URL'}
          value={agentUrl || ''}
          onChange={(event) => setAgentUrl(event.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!(agentName && agentUrl)}
          onClick={() =>
            onSubmit({
              ...agent,
              name: agentName,
              baseUrl: agentUrl
            })
          }>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AgentModal;
