import React, { useReducer, useState } from 'react';
import FilterList from '@mui/icons-material/FilterList';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItem,
  ListItemText
} from '@mui/material';
import { connect } from 'react-redux';
import { setRoutinesFilters, clearRoutinesFilters } from 'actions/RoutinesActions';

import SystemItemAutoComplete from 'pages/systems/commons/SystemItemAutoComplete';
import { useTranslation } from 'react-i18next';
import RoutineCatalogAutoComplete from '../commons/RoutineCatalogAutoComplete';
import RoutineSchemaAutoComplete from '../commons/RoutineSchemaAutoComplete';
import DatePicker, { hourAdjustments } from 'components/pickers/DatePicker';

function RoutinesFilters({ filters, hasFilters, setRoutinesFilters, clearRoutinesFilters }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton
        size='small'
        color={hasFilters ? 'primary' : 'grey'}
        style={{ float: 'right' }}
        onClick={() => setOpen(true)}
        children={<FilterList />}></IconButton>
      {open && (
        <RoutinesFiltersDialog
          open={open}
          filters={filters}
          onClear={() => {
            setOpen(false);
            clearRoutinesFilters();
          }}
          onSubmit={(filters) => {
            setOpen(false);
            setRoutinesFilters(filters);
          }}
        />
      )}
    </>
  );
}

function RoutinesFiltersDialog({ open, onClear, onSubmit, filters }) {
  const [editor, setEditor] = useReducer((s, a) => ({ ...s, ...a }), filters);
  const { t } = useTranslation();

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{'System Routines Filters'}</DialogTitle>
      <DialogContent>
        <RoutineSchemaAutoComplete
          schema={{ schema: editor?.schema } || ''}
          onChange={(value) => setEditor({ ...editor, schema: value?.schema })}
        />
        <RoutineCatalogAutoComplete
          catalog={{ catalog: editor?.catalog } || ''}
          onChange={(value) => setEditor({ ...editor, catalog: value?.catalog })}
        />
        <SystemItemAutoComplete
          fullWidth
          margin='dense'
          value={editor?.system || null}
          onChange={(system) => {
            setEditor({ ...editor, system: system });
          }}
        />
        <FormControl fullWidth variant='standard'>
          <InputLabel>Routine Type</InputLabel>
          <Select
            value={editor?.type}
            variant='standard'
            fullWidth
            onChange={(event) => setEditor({ ...editor, type: event.target.value })}>
            <MenuItem value='PROCEDURE'>
              <ListItem dense>
                <ListItemText primary='PROCEDURE' />
              </ListItem>
            </MenuItem>
            <MenuItem value='FUNCTION'>
              <ListItem dense>
                <ListItemText primary='FUNCTION' />
              </ListItem>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth variant='standard'>
          <InputLabel>Routine Status</InputLabel>
          <Select
            value={editor?.status}
            variant='standard'
            fullWidth
            onChange={(event) => setEditor({ ...editor, status: event.target.value })}>
            <MenuItem value='PENDING'>
              <ListItem dense>
                <ListItemText primary='PENDING' />
              </ListItem>
            </MenuItem>
            <MenuItem value='ANALYZED'>
              <ListItem dense>
                <ListItemText primary='ANALYZED' />
              </ListItem>
            </MenuItem>
            <MenuItem value='ERROR'>
              <ListItem dense>
                <ListItemText primary='ERROR' />
              </ListItem>
            </MenuItem>
          </Select>
        </FormControl>
        <h4>Created</h4>
        <DatePicker
          fullWidth
          clearable={true}
          label={'From'}
          hintText={'From'}
          hourAdjustment={hourAdjustments.startOfDay}
          value={editor?.createdAtGte}
          maxDate={editor?.createdAtLt}
          onChange={(date) => setEditor({ ...editor, createdAtGte: date })}
        />
        <DatePicker
          fullWidth
          clearable={true}
          label={'To'}
          hintText={'To'}
          hourAdjustment={hourAdjustments.endOfDay}
          value={editor?.createdAtLt}
          minDate={editor?.createdAtGte}
          onChange={(date) => setEditor({ ...editor, createdAtLt: date })}
        />
        <h4>Updated</h4>
        <DatePicker
          fullWidth
          clearable={true}
          label={'From'}
          hintText={'From'}
          hourAdjustment={hourAdjustments.startOfDay}
          value={editor?.updatedAtGte}
          maxDate={editor?.updatedAtLt}
          onChange={(date) => setEditor({ ...editor, updatedAtGte: date })}
        />
        <DatePicker
          fullWidth
          clearable={true}
          label={'To'}
          hintText={'To'}
          hourAdjustment={hourAdjustments.endOfDay}
          value={editor?.updatedAtLt}
          minDate={editor?.updatedAtGte}
          onChange={(date) => setEditor({ ...editor, updatedAtLt: date })}
        />
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.clear')} onClick={() => onClear()} />
        <Button
          variant='outlined'
          color='primary'
          children={t('commons.actions.apply')}
          onClick={() => onSubmit(editor)}
        />
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  filters: state?.systemRoutines?.filters?.content,
  hasFilters: state?.systemRoutines?.filters?.hasFilters || false
});
export default connect(mapStateToProps, { setRoutinesFilters, clearRoutinesFilters })(
  RoutinesFilters
);
