import { BlindataApi } from './ApiUtils';

const QUERY_BUCKET_URL = '/api/v1/query-parser/buckets';

export default class QueryBucketsApi extends BlindataApi {
  getAllQueryBuckets(params) {
    return this.get(QUERY_BUCKET_URL, params);
  }
  getQueryBucket(identifier) {
    return this.get(`${QUERY_BUCKET_URL}/${identifier}`);
  }
  postQueryBucket(queryBucket) {
    return this.post(QUERY_BUCKET_URL, queryBucket);
  }
  putQueryBucket(queryBucket) {
    return this.put(`${QUERY_BUCKET_URL}/${queryBucket.uuid}`, queryBucket);
  }
  deleteQueryBucket(queryBucket) {
    return this.delete(`${QUERY_BUCKET_URL}/${queryBucket.uuid}`);
  }
  refreshQueryBucketStatements(queryBucketUuid, params) {
    return this.postWithQueryParameters(
      `${QUERY_BUCKET_URL}/${queryBucketUuid}/refresh`,
      null,
      params
    );
  }
  confirmQueryBucketStatements(queryBucketUuid, params) {
    return this.postWithQueryParameters(
      `${QUERY_BUCKET_URL}/${queryBucketUuid}/confirm`,
      null,
      params
    );
  }
  ignoreQueryBucketStatements(queryBucketUuid, params) {
    return this.postWithQueryParameters(
      `${QUERY_BUCKET_URL}/${queryBucketUuid}/ignore`,
      null,
      params
    );
  }
}
