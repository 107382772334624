const IssueTypes = {
  BUG: 'BUG',
  VULNERABILITY: 'VULNERABILITY',
  WARNING: 'WARNING',
  ALERT: 'ALERT',
  TASK: 'TASK',
  FEATURE_REQUEST: 'FEATURE_REQUEST',
  QUESTION: 'QUESTION'
};

export default IssueTypes;
