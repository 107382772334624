import React from 'react';

import { UNITS } from '../utils/units';

import CustomSelect from './CustomSelect';

const Months = ({ value, setValue }) => {
  return (
    <>
      <span>in</span>
      <CustomSelect unit={UNITS[3]} value={value} setValue={setValue} />
    </>
  );
};

export default Months;
