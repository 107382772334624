import React from 'react';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import ItemSelectField from '../../../../components/itemselector/ItemSelectField';
import Button from '@mui/material/Button';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import PropTypes from 'prop-types';
import ProcessingItemAutoComplete from '../../../processings/utils/ProcessingItemAutoComplete';
import ComposableTableCard, {
  addOrEditHelper
} from '../../../../components/tables/ComposableTableCard';
import ProcessingPlaceholderControlCounters from '../detail/ProcessingPlaceholderControlCounters';

const initialState = {
  open: false,
  selectedIndex: null,
  name: null,
  processing: null,
  processingDisclaimer: null
};

class ProcessingsPlaceholdersValuesEditor extends React.Component {
  state = initialState;

  render() {
    let props = this.props;
    let processingSelectedValue = this.state.processing;
    if (processingSelectedValue) {
      var processingDisclaimerValues =
        (props.disclaimers &&
          props.disclaimers[processingSelectedValue.uuid || ''] &&
          _(props.disclaimers[processingSelectedValue.uuid || ''])
            .map()
            .value()) ||
        [];
      var processingDisclaimerSelectedValue = this.state.processingDisclaimer;
    } else {
      processingDisclaimerValues = [];
      processingSelectedValue = null;
    }
    return (
      <React.Fragment>
        <ComposableTableCard
          firstStrong
          title='Processing Disclaimers Placeholders'
          rows={this.props.processingPlaceholdersValues}
          selectedIndex={this.state.selectedIndex}
          headers={[
            'Name',
            'Processing Disclaimer Uuid',
            'Disclaimer Version',
            'Disclaimer Language',
            'Title',
            'Description'
          ]}
          propertiesPaths={[
            'name',
            'processingDisclaimer.uuid',
            'processingDisclaimer.version',
            'processingDisclaimer.lang',
            'processingDisclaimer.title',
            'processingDisclaimer.description'
          ]}
          onSelectionChanged={(selectedIndex) => this.setState({ selectedIndex })}
          onAdd={() => this.setState({ ...initialState, open: true })}
          onEdit={() => {
            let processingPlaceholdersValue =
              this.props.processingPlaceholdersValues[this.state.selectedIndex];
            let name = processingPlaceholdersValue.name;
            let processingDisclaimer = processingPlaceholdersValue.processingDisclaimer;
            let processing = {
              uuid: processingPlaceholdersValue.processingDisclaimer.processingUuid
            };
            this.props.fetchAllProcessingsDisclaimers(processing.uuid);
            this.setState({ name, processing, processingDisclaimer, open: true });
          }}
          onRemove={(elem, rows, newRows) => this.props.onChange(newRows)}
          onRowsMoved={(newRows, selectedIndex) => {
            this.setState({ selectedIndex });
            this.props.onChange(newRows);
          }}
          additionalContent={
            <ProcessingPlaceholderControlCounters
              processingPlaceholdersValues={this.props.processingPlaceholdersValues || []}
            />
          }
        />

        <Dialog open={this.state.open}>
          <DialogTitle>Add Processing Disclaimer</DialogTitle>
          <DialogContent>
            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              label='Placeholder name'
              value={this.state.name || ''}
              onChange={(event) => this.setState({ name: (event.target.value || '').trim() })}
            />
            <ProcessingItemAutoComplete
              value={processingSelectedValue}
              onChange={(processing) => {
                this.setState({ processing: processing, processingDisclaimer: null });
                if (processing && processing.uuid) {
                  props.fetchAllProcessingsDisclaimers(processing.uuid);
                }
              }}
            />
            <ItemSelectField
              itemName='Disclaimer'
              value={processingDisclaimerSelectedValue}
              values={processingDisclaimerValues}
              keyProperty='uuid'
              nameProperty='version'
              categoryProperties={['lang']}
              onChange={(disclaimer) => {
                this.setState({ processingDisclaimer: disclaimer });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button children='Cancel' onClick={() => this.setState(initialState)} />
            <Button
              children='Add'
              variant='contained'
              color='primary'
              disabled={
                !this.state.name ||
                this.state.name.length === 0 ||
                !processingDisclaimerSelectedValue ||
                !processingDisclaimerSelectedValue.uuid
              }
              onClick={() => {
                props.onChange(
                  addOrEditHelper(
                    this.props.processingPlaceholdersValues,
                    {
                      name: this.state.name,
                      processingDisclaimer: this.state.processingDisclaimer
                    },
                    this.state.selectedIndex
                  )
                );
                this.setState(initialState);
              }}
            />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ProcessingsPlaceholdersValuesEditor.propTypes = {
  processings: PropTypes.object.isRequired,
  disclaimers: PropTypes.object.isRequired,
  fetchAllProcessingsDisclaimers: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired
};

export default ProcessingsPlaceholdersValuesEditor;
