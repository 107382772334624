import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchQualityChecksStats } from 'actions/QualityDashboardsActions';

import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { Chart } from 'react-google-charts';

import {
  yellowSemaphoreColor,
  yellowSemaphoreColorLight100,
  yellowSemaphoreColorLight95,
  yellowSemaphoreColorLight80,
  yellowSemaphoreColorLight65,
  redSemaphoreColor,
  redSemaphoreColorLight100,
  redSemaphoreColorLight95,
  redSemaphoreColorLight85,
  redSemaphoreColorLight65
} from 'pages/quality/kqis/quality_checks/commons/QualitySemaphoreColors';

const styles = {
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '300px'
  }
};

const QualityChecksStatsCard = ({ data, filters, fetchQualityChecksStats }) => {
  let { t } = useTranslation();

  useEffect(() => {
    fetchQualityChecksStats(filters); // eventually add pagination params (page & size)
  }, [filters, fetchQualityChecksStats]);

  return (
    <Card>
      <CardHeader title='Quality Checks Stats' />
      <CardContent sx={styles.cardContent}>
        {data?.rows && data?.rows.length > 0 ? (
          <TableChart data={data} />
        ) : (
          <Typography variant='overline'>{t('commons.placeholders.nothingToDisplay')}</Typography>
        )}
      </CardContent>
    </Card>
  );
};

const TableChart = ({ data }) => {
  const mappedRows = data?.rows.map((row) => {
    const [
      // eslint-disable-next-line no-unused-vars
      qualityCheckUuid,
      qualityCheckName,
      qualityCheckCode,
      greenCount,
      yellowCount,
      redCount,
      warningsRatio,
      alertsRatio,
      scoreAvg
    ] = row;

    return [
      `${qualityCheckName} (${qualityCheckCode})`,
      greenCount,
      yellowCount,
      redCount,
      warningsRatio * 100,
      alertsRatio * 100,
      scoreAvg
    ];
  });

  return (
    <Chart
      width={'100%'}
      chartType='Table'
      loader={<Typography variant='overline'>Loading...</Typography>}
      data={[
        [
          { label: 'Quality Check', type: 'string' },
          { label: 'Successes', type: 'number' },
          { label: 'Warnings', type: 'number' },
          { label: 'Alerts', type: 'number' },
          { label: 'Warnings Ratio', type: 'number' },
          { label: 'Alerts Ratio', type: 'number' },
          { label: 'Score Avg', type: 'number' }
        ],
        ...mappedRows
      ]}
      formatters={[
        {
          type: 'NumberFormat',
          column: 4,
          options: { fractionDigits: 2, suffix: '%' }
        },
        {
          type: 'NumberFormat',
          column: 5,
          options: { fractionDigits: 2, suffix: '%' }
        },
        {
          type: 'NumberFormat',
          column: 6,
          options: { fractionDigits: 2 }
        },
        {
          type: 'ColorFormat',
          column: 4,
          ranges: [
            [0, 20, 'black', yellowSemaphoreColorLight100],
            [20, 40, 'black', yellowSemaphoreColorLight95],
            [40, 60, 'black', yellowSemaphoreColorLight80],
            [60, 80, 'black', yellowSemaphoreColorLight65],
            [80, null, 'black', yellowSemaphoreColor]
          ]
        },
        {
          type: 'ColorFormat',
          column: 5,
          ranges: [
            [0, 20, 'black', redSemaphoreColorLight100],
            [20, 40, 'black', redSemaphoreColorLight95],
            [40, 60, 'black', redSemaphoreColorLight85],
            [60, 80, 'black', redSemaphoreColorLight65],
            [80, null, 'black', redSemaphoreColor]
          ]
        }
      ]}
      options={{
        allowHtml: true,
        width: '100%',
        height: '100%'
      }}
    />
  );
};

const mapStateToProps = (state, props) => ({
  data: state.quality.qualityDashboards.overall.qualityChecksStats,
  filters: state.quality.qualityDashboards.overall.filters.content
});

const mapDispatchToProps = {
  fetchQualityChecksStats
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityChecksStatsCard);
