import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchDataCategories } from '../../../actions/DataCategoriesActions';
import ItemAutoCompleteModal from '../../../components/itemselector/ItemAutoCompleteModal';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state, props) => ({
  values: Object.values(state.datacategories.content)
});

const mapDispatchToProps = {
  onSearch: searchDataCategories
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  title: ownProps.t('search.dataCategory'),
  itemName: ownProps.t('data_categories.label'),
  keyProperty: 'uuid',
  nameProperty: 'name',
  handleCancel: ownProps.onCancel,
  handleSubmit: ownProps.onSubmit,
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

const DataCategorySearchModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ItemAutoCompleteModal);

DataCategorySearchModal.displayName = 'DataCategorySearchModal';

DataCategorySearchModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.func
};

export default withTranslation()(DataCategorySearchModal);
