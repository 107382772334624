import { BlindataApi } from './ApiUtils';

export default class DictionaryApi extends BlindataApi {
  getAllDictionaries(params) {
    return this.get('/api/v1/classification/dictionaries', params);
  }

  getDictionary(dictionaryUuid) {
    return this.get(`/api/v1/classification/dictionaries/${dictionaryUuid}`);
  }

  deleteDictionary(dictionary) {
    return this.delete(`/api/v1/classification/dictionaries/${dictionary.uuid}`);
  }

  postDictionary(dictionary) {
    return this.post('/api/v1/classification/dictionaries', dictionary);
  }

  putDictionary(dictionary) {
    return this.put(`/api/v1/classification/dictionaries/${dictionary.uuid}`, dictionary);
  }

  exportClassificationDictionaries(params) {
    return this.download('/api/v1/classification/dictionaries/*/export', params);
  }
}
