import React, { useState } from 'react';

import { IconButton, Tooltip, Menu, MenuItem } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { connect } from 'react-redux';
import { exportQualitySuites } from 'actions/QualitySuitesActions';
import { exportQualityCheck } from 'actions/QualityChecksActions';

function QualitySuiteExportButton({ exportQualitySuites, exportQualityCheck }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title='Export CSV'>
        <IconButton
          color='default'
          aria-label='Download'
          onClick={(event) => setAnchorEl(event.currentTarget)}
          size='small'>
          <GetAppIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            exportQualitySuites();
          }}>
          {'Export Test Suites'}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            exportQualityCheck();
          }}>
          {'Export Quality Checks'}
        </MenuItem>
      </Menu>
    </>
  );
}

export default connect(null, { exportQualitySuites, exportQualityCheck })(QualitySuiteExportButton);
