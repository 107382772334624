import { BlindataAgentApi } from './BlindataAgentApi';

export default class AgentJobRunnerApi extends BlindataAgentApi {
  // eslint-disable-next-line no-useless-constructor
  constructor(agent) {
    super(agent);
  }

  runJob(job) {
    return this.post('/agent-api/v1/jobs/runner', job);
  }

  runJobByUuid(jobDefinitionUuid) {
    return this.post(`/agent-api/v1/jobs/runner/schedules/${jobDefinitionUuid}`);
  }
}
