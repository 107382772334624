import { buildApiCallAction } from 'actions/ActionUtils';
import WorkspaceApi from 'api/social/favorites/WorkspaceApi';
import {
  FETCH_WORKSPACES_PAGE,
  CREATE_WORKSPACE,
  FETCH_WORKSPACE,
  DELETE_WORKSPACE,
  MODIFY_WORKSPACE
} from 'constants/ActionTypes';

const api = new WorkspaceApi();

export const fetchWorskpacesPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getWorkspaces(params),
    actionType: FETCH_WORKSPACES_PAGE
  });

export const fetchWorkspace = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getWorkspace(uuid),
    actionType: FETCH_WORKSPACE
  });

export const deleteWorkspace = (workspace) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteWorkspace(workspace),
    actionType: DELETE_WORKSPACE,
    payload: workspace
  });
export const createWorkspace = (workspace) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postWorkspace(workspace),
    actionType: CREATE_WORKSPACE
  });

export const modifyWorkspace = (workspace) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putWorkspace(workspace),
    actionType: MODIFY_WORKSPACE,
    payload: workspace
  });
