import React from 'react';
import { Route, Switch } from 'react-router-dom';
import WebHooksListPage from './list/WebHooksListPage';
import WebHookDetailPage from './detail/WebHookDetailPage';

const WebHooksRoutes = (props) => (
  <React.Fragment>
    <Switch>
      <Route exact path={`${props.match.url}`} component={WebHooksListPage} />

      <Route exact path={`${props.match.url}/:webHookUuid`} component={WebHookDetailPage} />
    </Switch>
  </React.Fragment>
);

export default WebHooksRoutes;
