import React from 'react';
import { Typography, Card, CardHeader, CardContent, Grid } from '@mui/material';
import ImpactedAssetsTable from './ImpactedAssetsTable';
import MetricsTableSection from './MetricsTableSection';
import SystemAssetsSection from './SystemAssetsSection';

function ImpactedAssetsSection({ results }) {
  return (
    <Card>
      <CardHeader title='Impacted Assets' />
      <CardContent>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <MetricsTableSection results={results} />
          </Grid>
          <Grid item>
            <SystemAssetsSection results={results} />
          </Grid>
          <Grid item>
            {results.rows.length > 0 ? (
              <>
                <h4>Assets</h4>
                <ImpactedAssetsTable results={results} />
              </>
            ) : (
              <div style={{ textAlign: 'center', padding: '2%' }}>
                <Typography variant='overline'>No assets impacted</Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ImpactedAssetsSection;
