import React, { useEffect } from 'react';
import _ from 'lodash';
import { Grid, Typography } from '@mui/material';
import { ChipItem } from 'components/chiplist/ChipList';
import { Warning } from '@mui/icons-material';
import { useState } from 'react';
import { fetchQualityChecksPage } from 'actions/QualityChecksActions';
import { connect } from 'react-redux';

const QualityProbesDefinitionDetailSection = ({ probe, fetchQualityChecksPage }) => {
  const [associatedCheck, setAssociatedCheck] = useState(null);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (probe.checkCode && !probe.checkGroupCode) {
      setLoaded(false);
      fetchQualityChecksPage({
        code: probe.checkCode,
        page: 0,
        size: 1
      }).then((response) => {
        setAssociatedCheck(_.get(response, 'content[0]', null));
        setLoaded(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [probe]);
  return (
    <Grid container direction='row' alignItems='center' spacing={2}>
      {probe.checkGroupCode && (
        <>
          <Grid item>{<h4>Check Group</h4>}</Grid>
          <Grid item>
            <ChipItem
              label={probe.checkGroupCode}
              color={associatedCheck ? 'primary' : 'default'}
              to={associatedCheck && `/data-quality/kqis/checks/${associatedCheck?.uuid}`}
            />
          </Grid>
        </>
      )}
      {probe.checkCode && (
        <>
          <Grid item>{<h4>Associated Check</h4>}</Grid>
          <Grid item>
            <ChipItem
              label={`${probe.checkName} [${probe.checkCode}]`}
              color={associatedCheck ? 'primary' : 'default'}
              to={associatedCheck && `/data-quality/kqis/checks/${associatedCheck?.uuid}`}
            />
          </Grid>
        </>
      )}
      {loaded && !associatedCheck && !probe.checkGroupCode && (
        <Grid item>
          <Grid container direction='row' alignItems='center' spacing={1}>
            <Grid item>
              <Warning color='error' />
            </Grid>
            <Grid item>
              <Typography variant='caption'>Check not found.</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default connect(null, { fetchQualityChecksPage })(QualityProbesDefinitionDetailSection);
