import {handleErrors,parseJson} from './ApiUtils'

const defaultPageSize = 20

export default class SystemLoginApi {

  token = ''

  searchSystemLogins(searchText,systemUuid,page=0,size=defaultPageSize){
    return fetch(`/api/v1/system_logins?search=${encodeURIComponent(searchText)}${systemUuid?'&systemUuid='+systemUuid:''}&page=${page}&size=${size}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson)     
  }

  getSystemLogins(page){
    return fetch(`/api/v1/system_logins?page=${page}&size=${defaultPageSize}`,{
      method:'GET',
      headers:{
        'Accept':'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
    .then(handleErrors)
    .then(parseJson)
  }

  getSystemLoginPageBySystem(systemUuid,page,size) {
    return fetch(`/api/v1/system_logins?systemUuid=${systemUuid}&page=${page}&size=${defaultPageSize}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson)
  }

  putSystemLogin(systemLogin){
    return fetch(`/api/v1/system_logins/${systemLogin.uuid}`,{
      method:'PUT',
      headers:{
        'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(systemLogin)
    })
    .then(handleErrors)
    .then(parseJson)
  }

  postSystemLogin(systemLogin){
    return fetch('/api/v1/system_logins',{
      method:'POST',
      headers:{
        'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(systemLogin)
    })
    .then(handleErrors)
    .then(parseJson)
  }

  deleteSystemLogin(systemLogin){
    return fetch(`/api/v1/system_logins/${systemLogin.uuid}`,{
      method:'DELETE',
      headers:{
        'Accept':'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
    .then(handleErrors)
  }

  getGrants(systemLogin,page){
    return fetch(`/api/v1/system_logins/${systemLogin.uuid}/grants?page=${page?page:0}&size=${defaultPageSize}`,{
      method:'GET',
      headers:{
        'Accept':'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
    .then(handleErrors)
    .then(parseJson)
  }

  postGrant(systemLogin, physicalEntity, grant){
    return fetch(`/api/v1/system_logins/${systemLogin.uuid}/grants`,{
      method:'POST',
      headers:{
        'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify({
        ...grant,
        systemLoginUuid:systemLogin.uuid,
        physicalEntityUuid:physicalEntity.uuid
      })
    })
    .then(handleErrors)
    .then(parseJson)
  }

  putGrant(systemLogin, physicalEntity, grant){
    return fetch(`/api/v1/system_logins/${systemLogin.uuid}/grants/${grant.uuid}`,{
      method:'PUT',
      headers:{
        'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify({
        ...grant,
        systemLoginUuid:systemLogin.uuid,
        physicalEntityUuid:physicalEntity.uuid
      })
    })
    .then(handleErrors)
    .then(parseJson)
  }

  deleteGrant(systemLogin,grant){
    return fetch(`/api/v1/system_logins/${systemLogin.uuid}/grants/${grant.uuid}`,{
      method:'DELETE',
      headers:{
        'Accept':'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
    .then(handleErrors)
  }

}
