import React, { useState } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Dialog
} from '@mui/material';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import AutoComplete from '../../../components/autocomplete/AutoComplete';
import AdditionalPropertiesEditor from '../../../components/additional_properties/AdditionalPropertiesEditor';
import AliasesEditModals from './AliasesEditModal';
import { useTranslation } from 'react-i18next';
import ResourceTypes from '../../../constants/ResourceTypes';

const INITIAL_STATE = {
  name: null,
  description: null,
  dataType: null,
  additionalProperties: [],
  aliases: [],
  openAliases: false
};

function LogicalFieldEditModal({ open, title, logicalField, handleCancel, handleSubmit }) {
  const [editor, setEditor] = useState(logicalField || INITIAL_STATE);
  const [openAliases, setOpenAliases] = useState(false);
  let { t } = useTranslation();
  if (logicalField && logicalField.aliases == null) {
    logicalField.aliases = [];
  }
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <h4 style={{ marginTop: '8px' }}>General</h4>
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder="Enter the Logical field's Name"
            value={editor.name || ''}
            label='Name'
            required={true}
            onChange={(event, name) => setEditor({ ...editor, name: event.target.value })}
          />
          <TextField
            multiline
            variant='standard'
            fullWidth
            margin='dense'
            value={editor.description || ''}
            placeholder="Enter the Logical field's Description"
            label='Description'
            onChange={(event, newValue) =>
              setEditor({ ...editor, description: event.target.value })
            }
          />
          <AutoComplete
            fullWidth
            placeholder="Enter the Logical field's Data type"
            label='Data type'
            value={editor.dataType || ''}
            margin='dense'
            dataSource={t('suggestion:dataType', { returnObjects: true })}
            onUpdateInput={(event) => setEditor({ ...editor, dataType: event })}
          />

          <TextField
            variant='standard'
            fullWidth
            id='standard-textarea'
            label='Constraints'
            placeholder='Constraints'
            value={editor.constraintDescription || ''}
            multiline
            onChange={(event, newValue) =>
              setEditor({ ...editor, constraintDescription: event.target.value })
            }
          />
          <TextField
            variant='standard'
            fullWidth
            id='standard-textarea'
            label='Patterns'
            value={editor.patterns || ''}
            placeholder='Patterns'
            multiline
            onChange={(event, newValue) => setEditor({ ...editor, patterns: event.target.value })}
          />
          <TextField
            variant='standard'
            fullWidth
            id='standard-textarea'
            label='Computational Rules'
            placeholder='Rules'
            value={editor.computationalRules || ''}
            multiline
            onChange={(event, newValue) =>
              setEditor({ ...editor, computationalRules: event.target.value })
            }
          />
          <div>Aliases</div>
          {logicalField && logicalField.aliases != null && (
            <ChipWrapper onRequestAdd={() => setOpenAliases(true)}>
              {editor.aliases.map((alias, index) => (
                <ChipItem
                  key={index}
                  id={index}
                  label={`${alias}`}
                  onRequestDelete={() => {
                    setEditor({
                      ...editor,
                      aliases: editor.aliases.filter((alias, index2) => index !== index2)
                    });
                  }}
                />
              ))}
            </ChipWrapper>
          )}
          <AdditionalPropertiesEditor
            additionalProperties={editor.additionalProperties}
            resourceType={ResourceTypes.LOGICAL_FIELD}
            onChange={(newList) =>
              setEditor({
                ...editor,
                additionalProperties: newList
              })
            }
          />
          <DialogActions>
            <Button children={t('commons.actions.cancel')} onClick={handleCancel}></Button>
            <Button
              children={t('commons.actions.submit')}
              variant='contained'
              color='primary'
              disabled={!editor.name}
              onClick={() => {
                handleSubmit(editor);
                handleCancel();
              }}></Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <AliasesEditModals
        open={openAliases}
        title={'Add Alias'}
        onCancel={() => setOpenAliases(false)}
        onAdd={(alias) => {
          setEditor({
            ...editor,
            aliases: editor.aliases.concat(alias)
          });
          setOpenAliases(false);
        }}
      />
    </React.Fragment>
  );
}

export default LogicalFieldEditModal;
