import React from 'react';
import ConsentsEventsLineChart from './ConsentsEventsLineChart';
import ConsentsStatesColumnChart from './ConsentsStatesColumnChart';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
const DashboardPage = (props) => (
  <div>
    <Breadcrumbs elements={[{ text: 'Data Subjects', to: '/datasubjects' }]} />
    <Tracking
      info={{
        pageTitle: 'Consents dashboards',
        category: EngagementCategories.CONSENTS,
        action: EngagementActions.DASHBOARDS
      }}
    />
    <ConsentsEventsLineChart />
    <ConsentsStatesColumnChart />
  </div>
);

export default DashboardPage;
