import EngagementDashboardsApi from 'api/EngagementDashboardsApi';

import { buildApiCallAction } from './ActionUtils';
import {
  SET_ENGAGEMENT_TIMELINE_FILTERS,
  CLEAR_ENGAGEMENT_TIMELINE_FILTERS,
  FETCH_ENGAGEMENT_SESSION_METRICS,
  FETCH_ENGAGEMENT_SESSION_TIMELINE,
  FETCH_ENGAGEMENT_PAGE_VIEWS_METRICS,
  FETCH_ENGAGEMENT_PAGE_VIEWS_TIMELINE,
  FETCH_ENGAGEMENT_RECENTLY_VIEWED_PAGE
} from 'constants/ActionTypes';

let api = new EngagementDashboardsApi();

export const setEngagementTimelineFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_ENGAGEMENT_TIMELINE_FILTERS, payload: filters });
};

export const clearEngagementTimelineFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_ENGAGEMENT_TIMELINE_FILTERS });
};

export const fetchEngagementSessionMetrics = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getSessionCountMetrics(params),
    actionType: FETCH_ENGAGEMENT_SESSION_METRICS
  });

export const fetchEngagementSessionTimeline = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getSessionTimeline(params),
    actionType: FETCH_ENGAGEMENT_SESSION_TIMELINE
  });

export const fetchEngagementPageViewsMetrics = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getPageViewsMetrics(params),
    actionType: FETCH_ENGAGEMENT_PAGE_VIEWS_METRICS
  });

export const fetchEngagementPageViewsTimeline = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getPageViewTimeline(params),
    actionType: FETCH_ENGAGEMENT_PAGE_VIEWS_TIMELINE
  });

export const getRecentlyViewed = ({ userTrackingId, ...params }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getRecentlyViewed({ userTrackingId, ...params }),
    actionType: FETCH_ENGAGEMENT_RECENTLY_VIEWED_PAGE
  });
