import { BlindataApi } from './ApiUtils';

//const defaultPageSize = 10;

export default class TasksApi extends BlindataApi {
  getTasks(page, size, filters = {}) {
    return this.get(`/api/v1/tasks`, { page, size, ...this.validateFilters(filters) });
  }

  getEndDateOperator(lifeCycleState) {
    if (lifeCycleState === null) {
      return null;
    }
    if (lifeCycleState === 'EXPIRED') {
      return 'LTE';
    } else {
      return 'GT';
    }
  }
  getEndDateIsNull(lifeCycleState) {
    if (lifeCycleState === null) {
      return null;
    }
    return lifeCycleState === 'ALIVE';
  }

  validateFilters(filters = {}) {
    let {
      processings = [],
      teamsCodes = [],
      sort = [],
      nullTeamCode,
      templateCodes,
      lifeCycleState = null,
      endDate = null
    } = filters;
    return {
      processingsUuids: processings.map((processing) => processing.uuid),
      teamsCodes: teamsCodes,
      nullTeamCode,
      templateCodes,
      endDateOperator: lifeCycleState && this.getEndDateOperator(lifeCycleState),
      endDateIsNull: lifeCycleState && this.getEndDateIsNull(lifeCycleState),
      endDate,
      sort: sort
    };
  }

  getTask(uuid) {
    return this.get(`/api/v1/tasks/${uuid}`);
  }

  searchTasks(search, { page = 0, size } = {}) {
    return this.get(`/api/v1/tasks`, { search, page, size });
  }

  postTask(task) {
    return this.post(`/api/v1/tasks`, task);
  }

  putTask(task) {
    return this.put(`/api/v1/tasks/${task.uuid}`, task);
  }

  deleteTask(task) {
    return this.delete(`/api/v1/tasks/${task.uuid}`);
  }

  deleteTaskPhysicalEntityAssociation(uuid) {
    return this.delete(`/api/v1/tasksPhysicalEntities/${uuid}`);
  }

  getTaskPhysicalEntityAssociations(taskUuid, page, size) {
    return this.get(`/api/v1/tasksPhysicalEntities`, { taskUuid, page, size });
  }

  postTaskPhysicalEntityAssociation(taskPhysicalEntityAssociation) {
    return this.post(`/api/v1/tasksPhysicalEntities`, taskPhysicalEntityAssociation);
  }

  exportTasks(params) {
    return this.download('/api/v1/tasks/*/export', {
      ...this.validateFilters(params),
      includeProperties: 'false'
    });
  }
}
