import React from 'react';
import { connect } from 'react-redux';
import {
  createContractTemplate,
  modifyContractTemplate,
  editContractTemplate,
  clearContractTemplateEditor,
  loadContractTemplateEditor
} from '../../../../actions/ContractTemplatesActions';
import Button from '@mui/material/Button';
import Loader from '../../../../components/loader/Loader';
import ProcessingsPlaceholdersEditor from './ProcessingsPlaceholdersEditor';
import PlaceholdersEditor from './PlaceholdersEditor';
import { Grid, Typography } from '@mui/material';
import TemplateContentEditor from './TemplateContentEditor';
import Breadcrumbs from 'pages/sidebar/Navigation';
import { useTranslation } from 'react-i18next';

const ContractTemplateEditor = ({
  templateUuid,
  editor,
  editContractTemplate,
  createContractTemplate,
  modifyContractTemplate,
  processings,
  disclaimers,
  fetchAllProcessingsDisclaimers,
  fetchProcessings,
  ...otherProps
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Breadcrumbs
        elements={[
          { text: 'Contracts', to: '/contracts' },
          { text: 'Contract Template Editor' }
        ]}></Breadcrumbs>

      <Grid container>
        <Grid style={{ flexGrow: 1 }} item>
          <Typography variant='h4'>Contract Template Editor</Typography>
        </Grid>
        <Grid item>
          <Button
            children={t('commons.actions.submit')}
            variant='contained'
            color='primary'
            disabled={!editor.name}
            onClick={() => {
              (templateUuid ? modifyContractTemplate(editor) : createContractTemplate(editor)).then(
                (d) => otherProps.history.push(`/contracts/templates/${d && d.uuid}`)
              );
            }}
          />
        </Grid>
      </Grid>

      <TemplateContentEditor {...{ editor, editContractTemplate }} />

      <ProcessingsPlaceholdersEditor
        processingPlaceholders={editor.processingPlaceholders || []}
        onChange={(processingPlaceholders) => editContractTemplate({ processingPlaceholders })}
      />

      <PlaceholdersEditor
        placeholders={editor.placeholders || []}
        onChange={(placeholders) => editContractTemplate({ placeholders })}
      />
    </div>
  );
};

const LoadableTemplateEditor = (props) => (
  <Loader
    onLoad={() => {
      props.clearContractTemplateEditor();
      if (props.templateUuid) {
        props.editContractTemplate({
          uuid: null,
          contractName: null,
          language: null,
          version: null,
          processingPlaceholders: [],
          placeholders: []
        });
        props.loadContractTemplateEditor(props.templateUuid);
      }
    }}>
    <ContractTemplateEditor {...props} />
  </Loader>
);

const mapStateToProps = (state, props) => ({
  templateUuid: props.match.params['templateUuid'],
  editor: state.contracts.templates.editor,
  disclaimers: state.processings.disclaimers.contentByProcessingUuid
});

export default connect(mapStateToProps, {
  createContractTemplate,
  modifyContractTemplate,
  editContractTemplate,
  clearContractTemplateEditor,
  loadContractTemplateEditor
})(LoadableTemplateEditor);
