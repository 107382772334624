import QualityAssessmentConfigurationApi from 'api/quality/assessment/QualityAssessmentConfigurationApi';
import QualityAssessmentCheckEvaluationApi from 'api/quality/assessment/QualityAssessmentCheckEvaluationApi';
import QualityAssessmetRiskEvaluationApi from 'api/quality/assessment/QualityAssessmentRiskEvaluationApi';
import QualityAssessmentRiskDefinitionsApi from 'api/quality/assessment/QualityAssessmentRiskDefinitionsApi';
import AbstractImporter from './AbstractImporter';
import _ from 'lodash';

export default class ImporterQualityAssessment extends AbstractImporter {
  assessmentCfg = null;

  static isSupported(object) {
    return object.risk || object.riskEvaluation || object.checkEvaluation;
  }

  async getConfiguration() {
    if (!this.assessmentCfg) {
      let configApi = new QualityAssessmentConfigurationApi();
      super.authenticateApi(configApi);
      this.assessmentCfg = await configApi.getConfiguration();
    }
    return this.assessmentCfg;
  }

  async importObject(object, resolveUuidOnly = false) {
    if (object.riskEvaluation) {
      return this.importRiskEvaluation(object.riskEvaluation, resolveUuidOnly);
    } else if (object.checkEvaluation) {
      return this.importCheckEvaluation(object.checkEvaluation, resolveUuidOnly);
    } else if (object.risk) {
      return this.importRiskDefinition(object.risk, resolveUuidOnly);
    } else {
      throw new Error('Object type not supported by ImporterQualityAssesment');
    }
  }

  async importRiskDefinition(risk, resolveUuidOnly) {
    let api = new QualityAssessmentRiskDefinitionsApi();
    return await this.importTemplate({
      object: risk,
      objectName: 'code',
      objectIdentifier: 'uuid',
      api: api,
      search: () =>
        api.getRiskDefinitions({
          code: risk.code,
          size: 500
        }),
      post: api.postRiskDefinition,
      put: api.putRiskDefinition,
      delet: api.deleteRiskDefinition,
      resolveUuidOnly
    });
  }

  async importRiskEvaluation(riskEvaluation, resolveUuidOnly) {
    let reconciledRiskEvaluation = {
      ...riskEvaluation,
      ...(await this.normalizeRiskEvaluationParams(riskEvaluation)),
      risk: await super.importObject({ risk: riskEvaluation.risk }, true),
      physicalEntity: await super.importObject(
        { physicalEntity: riskEvaluation.physicalEntity },
        true
      )
    };
    let api = new QualityAssessmetRiskEvaluationApi();
    super.authenticateApi(api);
    if (reconciledRiskEvaluation._DELETE) {
      return await api.deleteRiskEvaluation(reconciledRiskEvaluation);
    } else {
      return await api.putRiskEvaluation(reconciledRiskEvaluation);
    }
  }

  async normalizeRiskEvaluationParams(riskEvaluation) {
    let configuration = await this.getConfiguration();
    let { probability, severity, inherentRisk } = riskEvaluation;
    let severityValue = severity?.value;
    let probabilityValue = probability?.value;
    if (!probabilityValue || !severityValue) {
      if (!inherentRisk) {
        throw new Error('Missing severity, probability or inherentRisk');
      }
      let inherentRiskValue = inherentRisk.value;
      if (!inherentRisk.value && inherentRisk.label) {
        const inherentRiskDomain = this.lookupDomainByLabel(
          configuration,
          'RISK_EVALUATION',
          inherentRisk.label
        );
        inherentRiskValue = inherentRiskDomain?.value;
      }
      const matrixResult = _.find(
        configuration.inherentRiskMatrix,
        (r) => r[MATRIX_OUTPUT_IDX] === inherentRiskValue
      );
      [severityValue, probabilityValue] = matrixResult;
    }
    return {
      severity: this.lookupDomainByValue(configuration, 'SEVERITY', severityValue),
      probability: this.lookupDomainByValue(configuration, 'PROBABILITY', probabilityValue)
    };
  }

  async importCheckEvaluation(checkEvaluation, resolveUuidOnly) {
    let reconciledCheckEvaluation = {
      ...checkEvaluation,
      ...(await this.normalizeCheckEvaluationParams(checkEvaluation)),
      risk: await super.importObject({ risk: checkEvaluation.risk }, true),
      qualityCheck: await super.importObject({ qualityCheck: checkEvaluation.qualityCheck }, true)
    };
    let api = new QualityAssessmentCheckEvaluationApi();
    super.authenticateApi(api);
    if (reconciledCheckEvaluation._DELETE) {
      return await api.deleteCheckEvaluation(reconciledCheckEvaluation);
    } else {
      return await api.putCheckEvaluation(reconciledCheckEvaluation);
    }
  }

  async normalizeCheckEvaluationParams(checkEvaluation) {
    let configuration = await this.getConfiguration();
    let { probabilityReduction, severityReduction, checkImpact } = checkEvaluation;
    let severityReductionValue = severityReduction?.value;
    let probabilityReductionValue = probabilityReduction?.value;
    if (!probabilityReduction || !severityReduction) {
      if (!checkImpact) {
        throw new Error('Missing severityReduction, probabilityReduction or checkImpact');
      }
      let impactDomainValue = checkImpact.value;
      if (!checkImpact.value && checkImpact.label) {
        const impactDomain = this.lookupDomainByLabel(
          configuration,
          'CHECK_IMPACT',
          checkImpact.label
        );
        impactDomainValue = impactDomain?.value;
      }
      const matrixResult = _.find(
        configuration.checkImpactMatrix,
        (r) => r[MATRIX_OUTPUT_IDX] === impactDomainValue
      );
      [severityReductionValue, probabilityReductionValue] = matrixResult;
    }
    return {
      severityReduction: this.lookupDomainByValue(
        configuration,
        'SEVERITY_REDUCTION',
        severityReductionValue
      ),
      probabilityReduction: this.lookupDomainByValue(
        configuration,
        'PROBABILITY_REDUCTION',
        probabilityReductionValue
      )
    };
  }

  lookupDomainByValue(configuration, domainType, value) {
    return this.lookupDomain(configuration, domainType, { value });
  }

  lookupDomainByLabel(configuration, domainType, label) {
    return this.lookupDomain(configuration, domainType, { label });
  }

  lookupDomain(configuration, domainType, { value, label }) {
    const valueToSearch = label || value;
    const indexToSearch = label ? DOMAINS_LABEL_IDX : DOMAINS_VALUE_IDX;
    let row = _.find(
      configuration.domains,
      (row) => row[DOMAINS_TYPE_IDX] === domainType && row[indexToSearch] === valueToSearch
    );
    if (row) {
      return {
        value: row[DOMAINS_VALUE_IDX],
        label: row[DOMAINS_LABEL_IDX]
      };
    } else {
      throw new Error(`Invalid ${domainType} lookup with ${valueToSearch}`);
    }
  }
}
const DOMAINS_TYPE_IDX = 0;
const DOMAINS_VALUE_IDX = 1;
const DOMAINS_LABEL_IDX = 2;

// eslint-disable-next-line no-unused-vars
const MATRIX_SEVERITY_IDX = 0;
// eslint-disable-next-line no-unused-vars
const MATRIX_PROBABILITY_IDX = 1;
const MATRIX_OUTPUT_IDX = 2;
