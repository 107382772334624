import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { RpaArchivesPage } from './list/RpaArchivesPage';
import RpaArchiveDetailPage from './detail/RpaArchiveDetailPage';

export default class RpaArchiveRoutes extends Component {
  render() {
    return (
      <>
        <Route exact path={`${this.props.match.url}`} component={RpaArchivesPage} />
        <Route exact path={`${this.props.match.url}/:uuid`} component={RpaArchiveDetailPage} />
      </>
    );
  }
}
