/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import ReactFlow, { Controls, useEdgesState, useNodesState } from 'reactflow';
import 'reactflow/dist/style.css';
import dagre from 'dagre';
import { DataProductNode } from './DataProductNode';

export const DataProductDependenciesGraph = ({ initialNodes, initialEdges }) => {
  const dagreGraph = useMemo(() => new dagre.graphlib.Graph(), []);
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const nodeTypes = useMemo(() => ({ custom: DataProductNode }), []);

  const { nodes: layoutedNodes, edges: layoutedEdges } = useMemo(
    () => getLayoutedElements(dagreGraph, initialNodes, initialEdges),
    [dagreGraph, initialEdges, initialNodes]
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitView>
      <Controls />
    </ReactFlow>
  );
};

const getLayoutedElements = (dagreGraph, nodes, edges) => {
  const nodeWidth = 172;
  const nodeHeight = 36;
  dagreGraph.setGraph({ rankdir: 'LR' });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = 'left';
    node.sourcePosition = 'right';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2
    };

    return node;
  });

  return { nodes, edges };
};
