import UsersApi from '../../../../api/UsersApi';
import AbstractImporter from './AbstractImporter';

export default class ImporterUsers extends AbstractImporter {
  static isSupported(object) {
    return object.user;
  }

  importObject(object, resolveUuidOnly) {
    if (object.user) {
      return this.importUser(object.user, resolveUuidOnly);
    }
    throw new Error('Object type not supported by ImporterUsers');
  }

  async importUser(user, resolvedUuidOnly) {
    let api = new UsersApi();
    return this.importTemplate({
      object: user,
      objectName: 'username',
      objectIdentifier: 'uuid',
      api,
      search: (params) => api.getUsers(params),
      put: api.patchUser,
      post: api.postUser,
      delet: api.deleteUser,
      resolveUuidOnly: true
    });
  }
}
