import React from 'react';
import { TableHead, TableRow, TableSortLabel } from '@mui/material';
import PropTypes from 'prop-types';
import TableCell from 'components/tables/GenericTableCell';

const headCells = [
  { id: 'system', numeric: false, label: 'System Name' },
  { id: 'physicalEntity', numeric: false, label: 'Physical Entity Name' },
  { id: 'physicalField', numeric: false, label: 'Physical Field Name' },
  { id: 'dataCategory', numeric: false, label: 'Data Category Name' },
  { id: 'logicalField', numeric: false, label: 'Logical Field Name' },
  { id: 'assignmentState', numeric: false, label: 'State' },
  { id: 'score', numeric: true, label: 'Score' }
];

const styles = {
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
};

export default function AssignmentsTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span style={styles.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key={'actions'} align={'center'} padding='normal'>
          {'Actions'}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

AssignmentsTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};
