import DashboardsApi from '../api/DashboardsApi';
import { buildApiCallAction } from './ActionUtils';
import {
  FETCH_DASHBOARD_CONSENTS_LINECHART,
  FETCH_DASHBOARD_CONSENTS_COLUMNCHART,
  FETCH_DASHBOARD_SYSTEM_DATA_SUBJECT_COUNT,
  FETCH_DASHBOARD_SYSTEM_METADATA_METRICS,
  SET_FILTERS_DASHBOARD_CONSENTS_LINECHART,
  CLEAR_FILTERS_DASHBOARD_CONSENTS_LINECHART,
  SET_FILTERS_DASHBOARD_CONSENTS_COLUMNCHART,
  CLEAR_FILTERS_DASHBOARD_CONSENTS_COLUMNCHART,
  FETCH_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT,
  SET_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT,
  CLEAR_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT
} from '../constants/ActionTypes';

let api = new DashboardsApi();

export const fetchConsentsEventsLineChart = (params) => buildApiCallAction({
  api: api,
  apiFunction: (api,getState) => {
    let {processings,endDate,startDate} = getState().dashboards.consentsEvents.lineChartFilters
    let defaultParams = startEndDateParams(31)
    let filterParams = {}
    if( processings.length > 0){
      filterParams.processingsUuids = processings.map(p=>p['uuid'])
    }
    if(startDate){
      filterParams.startDate = startDate
    }
    if (endDate) {
      filterParams.endDate = endDate
    }
    return api.getConsentsEventsLineChart({...defaultParams, ...filterParams, ...params})
  },
  actionType: FETCH_DASHBOARD_CONSENTS_LINECHART
});

export const setConsentsEventsLineChartFilters = (params) => (dispatch,getState)=>{
  dispatch({type: SET_FILTERS_DASHBOARD_CONSENTS_LINECHART, payload: params})
}

export const clearConsentsEventsLineChartFilters = (params) => (dispatch, getState) => {
  dispatch({ type: CLEAR_FILTERS_DASHBOARD_CONSENTS_LINECHART})
}

export const fetchConsentsStatesColumnChart = (params) => buildApiCallAction({
  api: api,
  apiFunction: (api,getState) => {
    let { processings, endDate, startDate } = getState().dashboards.consentsStates.columnChartFilters
    let filterParams = {}
    if (processings.length > 0) {
      filterParams.processingsUuids = processings.map(p => p['uuid'])
    }
    if ( startDate) {
      filterParams.startDate = startDate
    }
    if ( endDate) {
      filterParams.endDate = endDate
    }
    return api.getConsentsStatesColumnChart({...filterParams,...params})
  },
  actionType: FETCH_DASHBOARD_CONSENTS_COLUMNCHART
});

export const setConsentsStatesColumnChartFilters = (params) => (dispatch, getState) => {
  dispatch({ type: SET_FILTERS_DASHBOARD_CONSENTS_COLUMNCHART, payload: params })
}

export const clearConsentsStatesColumnChartFilters = (params) => (dispatch, getState) => {
  dispatch({ type: CLEAR_FILTERS_DASHBOARD_CONSENTS_COLUMNCHART })
}

export const fetchSystemDataSubjectCount = (params) => buildApiCallAction({
  api: api,
  apiFunction: api => api.getSystemDataSubjectCount(params),
  actionType: FETCH_DASHBOARD_SYSTEM_DATA_SUBJECT_COUNT,
  payload: data => ({...data, systemUuid: params.systemUuid})
})

export const fetchSystemMetadataMetrics = (params) => buildApiCallAction({
  api: api,
  apiFunction: api => api.getSystemMetadataMetrics(params),
  actionType: FETCH_DASHBOARD_SYSTEM_METADATA_METRICS,
  payload: data => ({ ...data, systemUuid: params.systemUuid })
})

const startEndDateParams = (daysFromNow) => {
  let _now = new Date()
  _now.setDate(_now.getDate() + 1)
  let _before = new Date()
  _before.setDate(_now.getDate() - daysFromNow);
  return {
    startDate: new Date(_before).toISOString(),
    endDate: _now.toISOString()
  }
}

export const fetchDataSubjectsAudienceCount = () => buildApiCallAction({
  api: api,
  apiFunction: (api, getState) => {
    let { selectedProcessings, targetDate, context, nullContext} = getState().dashboards.dataSubjectsAudience.filters
    let filterParams = {
      processingsUuids: selectedProcessings.map(p=>p.uuid),
      targetDate,
      context,
      nullContext
    }
    return api.getDataSubjectsAudienceCount({ ...filterParams })
  },
  actionType: FETCH_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT
})

export const editDataSubjectsAudienceFilters = (params) => (dispatch, getState) => {
  dispatch({ type: SET_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT, payload: params })
}

export const clearDataSubjectsAudienceFilters = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT})
}