import React from 'react';
import { connect } from 'react-redux';
import ProcessingFinderButton from './ProcessingFinderButton';
import ProcessingsList from './ProcessingsList';
import * as ProcessingsActions from '../../../actions/ProcessingsActions';
import { fetchDashboardTypeCounterIfAllowedLargeRequest } from 'actions/IssueMngtDashboardsActions';
import Loader from '../../../components/loader/Loader';
import PaginatedPanel from '../../../components/pagination/PaginatedPanel';
import { withRouter } from 'react-router-dom';
import ProcessingsFilters from './ProcessingsFilters';
import { Typography, Grid } from '@mui/material';
import DownloadRPAButton from './DownloadRpaButton';
import ToolbarActions from '../../sidebar/ToolbarActions';
import ProcessingExportButton from '../utils/ProcessingExportButton';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import ResourceTypes from 'constants/ResourceTypes';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';
const ProcessingsContainer = (props) => (
  <Loader
    onLoad={() => {
      props
        .fetchProcessingsPageWithFilters()
        .then(
          (response) =>
            response &&
            response.content.length > 0 &&
            props.fetchDashboardTypeCounterIfAllowedLargeRequest(
              response.content,
              25,
              ResourceTypes.PROCESSING
            )
        );
    }}>
    <div>
      <Breadcrumbs elements={[{ text: 'Processings', to: '/processings' }]} />
      <Tracking
        info={{
          pageTitle: `Processings`,
          category: EngagementCategories.PROCESSINGS,
          action: EngagementActions.LIST
        }}
      />
      <ToolbarActions>
        <DownloadRPAButton />
      </ToolbarActions>
      <Grid container>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Processings</Typography>
        </Grid>
        <Grid item>
          <ProcessingFinderButton history={props.history} />
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:processings-list'}
            pageTitle={'Processings'}
            iconSize='small'
          />
        </Grid>
        <Grid item>
          <ProcessingExportButton></ProcessingExportButton>
        </Grid>
        <Grid item>
          <ProcessingsFilters onRefresh={() => props.fetchProcessingsPageWithFilters()} />
        </Grid>
        <Grid item>
          <WebActionsButton page={TargetPages.PROCESSING_LIST_PAGE} />
        </Grid>
      </Grid>

      <PaginatedPanel
        currentPage={props.currentPage}
        onPageSelection={props.fetchProcessingsPageWithFilters}>
        <ProcessingsList {...props} />
      </PaginatedPanel>
    </div>
  </Loader>
);

const mapStateToProps = (state) => ({
  dataActors: state.data_actors.content,
  processings: state.processings.currentPage.content,
  currentPage: state.processings.currentPage
});

export default withRouter(
  connect(mapStateToProps, {
    ...ProcessingsActions,
    fetchDashboardTypeCounterIfAllowedLargeRequest
  })(ProcessingsContainer)
);
