import {
  FETCH_CHECK_EVALUATION,
  FETCH_CHECK_EVALUATIONS_BY_PE_AND_RISK,
  MODIFY_CHECK_EVALUATION,
  DELETE_CHECK_EVALUATION,
  EXPORT_CHECK_EVALUATIONS
} from 'constants/ActionTypes';
import QualityAssessmentCheckEvaluationApi from 'api/quality/assessment/QualityAssessmentCheckEvaluationApi';
import { buildApiCallAction } from 'actions/ActionUtils';

const qualityAssessmentCheckEvaluationApi = new QualityAssessmentCheckEvaluationApi();

export const fetchCheckEvaluationsMatrix = (qualityCheckUuid) =>
  buildApiCallAction({
    api: qualityAssessmentCheckEvaluationApi,
    apiFunction: (api) => api.getCheckEvaluationsMatrix(qualityCheckUuid),
    actionType: FETCH_CHECK_EVALUATION,
    payload: (response) => ({ ...response, qualityCheckUuid })
  });

export const fetchCheckEvaluationsByPhysicalEntityAndRisk = (physicalEntityUuid, riskUuid) =>
  buildApiCallAction({
    api: qualityAssessmentCheckEvaluationApi,
    apiFunction: (api) => api.getCheckEvaluationsByPhysicalEntityAndRisk(physicalEntityUuid, riskUuid),
    actionType: FETCH_CHECK_EVALUATIONS_BY_PE_AND_RISK
  });

export const modifyCheckEvaluation = (checkEvaluation) =>
  buildApiCallAction({
    api: qualityAssessmentCheckEvaluationApi,
    apiFunction: (api) => api.putCheckEvaluation(checkEvaluation),
    actionType: MODIFY_CHECK_EVALUATION
  });

export const deleteCheckEvaluation = (checkEvaluation) =>
  buildApiCallAction({
    api: qualityAssessmentCheckEvaluationApi,
    apiFunction: (api) => api.deleteCheckEvaluation(checkEvaluation),
    actionType: DELETE_CHECK_EVALUATION
  });

export const exportCheckEvaluations = () =>
  buildApiCallAction({
    api: qualityAssessmentCheckEvaluationApi,
    apiFunction: (api) => api.exportCheckEvaluations(),
    actionType: EXPORT_CHECK_EVALUATIONS
  });
