import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { searchAgents } from 'actions/AgentsActions';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';

function AgentItemAutocomplete({ onSearch, searchContent, onChange, value }) {
  useEffect(() => {
    if (!value) {
      onSearch().then((response) => {
        let defaultAgent = response?.content?.[0];
        onChange(defaultAgent);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ItemAutoComplete
      values={searchContent}
      value={value}
      showAllOptions
      itemName='Agent'
      nameProperty={'name'}
      onChange={(newValue) => onChange(newValue)}
      onSearch={(inputValue) => {
        onSearch({ search: inputValue });
      }}></ItemAutoComplete>
  );
}

const mapStateToProps = (state, props) => ({
  searchContent: Object.values(state.settings.agents.definitions.content)
});

const mapDispatchToProps = (dispatch) => ({
  onSearch: (search) => dispatch(searchAgents(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentItemAutocomplete);
