import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchQualityDashboardOverall } from 'actions/QualityDashboardsActions';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import NumberBox, { numberBoxColors } from 'components/graphics/NumberBox';

const styles = {
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px'
  }
};
function QualityChecksOverallCountsCard({ data, filters, fetchQualityDashboardOverall }) {
  let { t } = useTranslation();

  useEffect(() => {
    fetchQualityDashboardOverall(filters);
  }, [filters, fetchQualityDashboardOverall]);

  return (
    <Card>
      <CardContent sx={styles.cardContent}>
        {data && data?.rows.length > 0 ? (
          <Grid container direction='row'>
            <Grid item xs={12} sm={4}>
              <NumberBox
                label={t(`quality.dashboard.${data.columns[0].label}`)}
                number={data.rows[0][0]}
                color={numberBoxColors.lightBlue}></NumberBox>
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumberBox
                label={t(`quality.dashboard.${data.columns[1].label}`)}
                number={data.rows[0][1]}
                color={numberBoxColors.lightBlue}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumberBox
                label={t(`quality.dashboard.${data.columns[2].label}`)}
                number={data.rows[0][2]}
                color={numberBoxColors.grey}
              />
            </Grid>
          </Grid>
        ) : (
          <Typography variant='overline'>{t('commons.placeholders.nothingToDisplay')}</Typography>
        )}
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state, props) => ({
  data: state.quality.qualityDashboards.overall.qualityChecksGeneralCounts,
  filters: state.quality.qualityDashboards.overall.filters.content
});

const mapDispatchToProps = {
  fetchQualityDashboardOverall
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityChecksOverallCountsCard);
