
import { combineReducers } from 'redux'
import _ from 'lodash'
import {
  FETCH_RECORDS,
  FETCH_PROOFS  
} from '../constants/ActionTypes';

const recordsByResourceIdentifier = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RECORDS:
      return {
        ...state,
        ...(_(action.payload.content).groupBy('resourceIdentifier')).value()
      };
    default:
      return state;
  }
}

const proofsByRecordUuid = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROOFS:
      return {
        ...state,
        [action.payload.recordUuid]: action.payload.content
      };
    default:
      return state;
  }
}


export default combineReducers({ recordsByResourceIdentifier, proofsByRecordUuid })