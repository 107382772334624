import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchQualityResultsPage } from 'actions/QualityResultsActions';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Collapse,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import HeadTableCell from 'components/tables/GenericTableCell';

import { useHistory } from 'react-router';

const moment = require('moment');

const styles = {
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0
  },
  mainTableRow: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
};

const QualityResultsInnerTable = ({ data }) => {
  const orderedData = data.sort((d1, d2) => d1.executionDate > d2.executionDate);
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <HeadTableCell>Execution Date</HeadTableCell>
          <HeadTableCell>Metric</HeadTableCell>
          <HeadTableCell>Total Elements</HeadTableCell>
          <HeadTableCell>Score</HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderedData.map((d, index) => (
          <TableRow key={index}>
            <TableCell>{moment(d.executionDate).format('DD MMMM YYYY, HH:mm:ss')}</TableCell>
            <TableCell>{d.metric}</TableCell>
            <TableCell>{d.totalElements || '-'}</TableCell>
            <TableCell>{Math.round((d.score + Number.EPSILON) * 100) / 100}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const QualityResultsTableRow = ({ qualityCheckName, qualityCheckCode, results }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={styles.mainTableRow}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{qualityCheckName}</TableCell>
        <TableCell>{qualityCheckCode}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <QualityResultsInnerTable data={results} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const QualityChecksTimelineDrilldownModal = ({
  qualitySuiteUuid,
  qualityCheckUuid,
  selection,
  precision,
  size,
  open,
  onClose,
  fetchQualityResultsPage,
  isInWatchlist
}) => {
  const [tableData, setTableData] = useState([]);

  const history = useHistory();
  useEffect(() => {
    if (!selection?.semaphore) {
      return;
    }
    const page = 0;
    const params = {
      qualitySuitesUuids: qualitySuiteUuid ? [qualitySuiteUuid] : undefined,
      checkUuid: qualityCheckUuid,
      inWatchlist: isInWatchlist,
      semaphores: [selection.semaphore?.toUpperCase()],
      startedAtGte: moment(selection.date).startOf(precision).toISOString(),
      startedAtLt: moment(selection.date).endOf(precision).toISOString()
    };
    fetchQualityResultsPage({ page, size, ...params }).then((results) => {
      const data = calculateTableData(results);
      setTableData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const calculateTableData = (results) => {
    const tableData = {};
    results?.content.forEach((r) => {
      const currentData = tableData[r.qualityCheck.uuid] || {
        code: undefined,
        name: undefined,
        results: []
      };
      tableData[r.qualityCheck.uuid] = {
        uuid: currentData.uuid || r.qualityCheck.uuid,
        name: currentData.name || r.qualityCheck.name,
        code: currentData.code || r.qualityCheck.code,
        results: [
          ...currentData.results,
          {
            executionDate: r.startedAt,
            metric: r.metric,
            totalElements: r.totalElements,
            score: r.score
          }
        ].sort((date1, date2) => (date1 > date2 ? -1 : 1)) // sort results by start date desc
      };
    });
    return [...Object.values(tableData)].sort((datum1, datum2) =>
      datum1.name > datum2.name ? 1 : -1
    ); // sort quality checks alphabetically (by name)
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose} sx={styles.dialog}>
      <DialogTitle>Quality Checks Timeline Drilldown</DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Table size='small' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Quality Check Name</TableCell>
              <TableCell>Quality Check Code</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((datum) => (
              <QualityResultsTableRow
                onClick={(event) => {
                  history.push(`/results/${datum.uuid}`);
                  event.stopPropagation();
                }}
                key={datum.uuid}
                qualityCheckName={datum.name}
                qualityCheckCode={datum.code}
                results={datum.results}
              />
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default connect(null, { fetchQualityResultsPage })(QualityChecksTimelineDrilldownModal);
