import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import ResourceApplicabilityTargetTypes from './ResourceApplicabilityTargetTypes';
import { Add, Delete } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpIcon } from '../../../../components/help';

function ResourceApplicability({ resources = [], onChange }) {
  let { t } = useTranslation();
  return (
    <>
      <h4>
        Applicability{' '}
        <HelpIcon
          tooltip={
            'Specifies whether the role has a responsability over a given resource'
          }></HelpIcon>
      </h4>
      <Table size='small' padding='none'>
        <TableHead>
          <TableRow>
            <TableCell>Resource</TableCell>
            <TableCell>Can Write</TableCell>
            {onChange && (
              <TableCell>
                <ResourceApplicabilityAdder
                  resources={resources}
                  onAdd={(resourceApplicability) =>
                    onChange(resources.concat([resourceApplicability]))
                  }></ResourceApplicabilityAdder>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(resources || []).map((applicability) => (
            <TableRow>
              <TableCell padding='none'>
                {t(`resourceType.${applicability.resourceType}`)}
              </TableCell>
              <TableCell padding='none'>
                <Checkbox checked={applicability.canWrite} color='primary' disabled></Checkbox>
              </TableCell>
              {onChange && (
                <TableCell>
                  <IconButton
                    size='small'
                    onClick={() => onChange(resources.filter((r) => r !== applicability))}>
                    <Delete></Delete>
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

function ResourceApplicabilityAdder({ onAdd }) {
  let [open, setOpen] = useState(false);
  let [resourceType, setResourceType] = useState(null);
  let [canWrite, setCanWrite] = useState(false);
  let { t } = useTranslation();
  return (
    <>
      <IconButton onClick={() => setOpen(true)} size='large'>
        <Add></Add>
      </IconButton>
      <Dialog open={open}>
        <DialogTitle>Add Resource Applicability</DialogTitle>
        <DialogContent>
          <FormControl variant='standard' fullWidth>
            <InputLabel>Resource Type</InputLabel>
            <Select
              variant='standard'
              value={resourceType}
              onChange={(event) => setResourceType(event.target.value)}>
              {Object.values(ResourceApplicabilityTargetTypes).map((rt) => (
                <MenuItem value={rt}>{t(`resourceType.${rt}`)}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <br></br>

          <FormControlLabel
            style={{ paddingTop: 8 }}
            label='Can write'
            control={
              <Checkbox
                checked={canWrite}
                onChange={() => setCanWrite(!canWrite)}
                value='canWrite'
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t('commons.actions.cancel')}</Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              onAdd({ resourceType, canWrite });
              setOpen(false);
            }}>
            {t('commons.actions.add')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ResourceApplicability;
