import React, { useState } from 'react';
import { Collapse, Grid, IconButton, Paper, Typography } from '@mui/material';
import { Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ColumnNode } from './ColumnNode';

function TableNode({ id, data }) {
  let [open, setOpen] = useState(false);
  return (
    <Paper
      style={{
        borderTop: `2px solid ${handleColor(data)}`
      }}>
      <Grid container direction='column'>
        <Grid container direction='row' alignItems='center'>
          <Grid item style={{ flexGrow: 1 }}></Grid>
          <Grid item>
            <div
              style={{
                textAlign: 'center',
                fontFamily: 'sans-serif',
                padding: '8px',
                fontWeight: 500,
                margin: 'auto',
                color: 'black'
              }}>
              <Handle
                type='target'
                position={Position.Left}
                id={`in`}
                style={{ visibility: 'hidden' }}
              />
              <Handle
                type='source'
                position={Position.Right}
                id={`out`}
                style={{ visibility: 'hidden' }}
              />
              <tspan style={{ dy: '0.3em' }}> {handleName(data)}</tspan>
            </div>
          </Grid>
          {data?.columns?.length > 0 && (
            <Grid item>
              <IconButton size='small' onClick={() => setOpen(!open)}>
                {open ? (
                  <KeyboardArrowUp fontSize='small' style={{ color: handleColor(data) }} />
                ) : (
                  <KeyboardArrowDown fontSize='small' style={{ color: handleColor(data) }} />
                )}
              </IconButton>
            </Grid>
          )}
          <Grid item style={{ flexGrow: 1 }}></Grid>
        </Grid>

        {!open &&
          data.columns.map((column, index) => (
            <div key={`${column.name}_${index}`}>
              <Handle
                type='target'
                position={Position.Left}
                id={`in.${column.name}`}
                style={{ visibility: 'hidden' }}
              />
              <Handle
                type='source'
                position={Position.Right}
                id={`out.${column.name}`}
                style={{ visibility: 'hidden' }}
              />
            </div>
          ))}
        <Collapse style={{ background: 'rgb(230, 234, 240, 0.2)' }} in={open} timeout='auto'>
          {open && data.columns.map((column) => <ColumnNode column={column} nodeId={id} />)}
        </Collapse>
      </Grid>
    </Paper>
  );
}

function handleName(data) {
  switch (data.nodeType) {
    case 'SOURCE_TABLE':
      if (data.notFoundInCatalog) {
        const possibleTableNames = data.name.split('\n').filter((name) => name !== '');
        return data.schema
          ? `${data.schema}.${possibleTableNames.join(', ')}`
          : `${possibleTableNames.join(', ')}`;
      }
    // eslint-disable-next-line no-fallthrough
    case 'TARGET_TABLE':
      if (data.systemName) {
        return (
          <>
            <Typography variant='overline' style={{ lineHeight: 1 }}>
              {data?.systemName}
            </Typography>
            <br></br>
            <Typography style={{ lineHeight: 1 }}>
              {data.schema ? `${data.schema}.${data.name}` : data.name}
            </Typography>
          </>
        );
      }
      return <Typography>{data.schema ? `${data.schema}.${data.name}` : data.name}</Typography>;
    case 'PROJECTION':
      const specialProjectionTableName = getSpecialProjectionTableName(data);
      return specialProjectionTableName ? specialProjectionTableName : 'PROJECTION';
    case 'FUNCTION':
      return 'FUNCTION';
    default:
      return null;
  }
}

const specialProjectionNames = [
  'INTERSECT',
  'EXCEPT',
  'MINUS',
  'UNION',
  'MERGE_UPDATE',
  'MERGE_INSERT'
];

function getSpecialProjectionTableName(data) {
  return specialProjectionNames.some((specialProjection) => data.name.includes(specialProjection))
    ? `${data.name.split('-')[0]}`
    : null;
}

function handleColor(data) {
  switch (data.nodeType) {
    case 'SOURCE_TABLE':
    case 'TARGET_TABLE':
      if (data.notFoundInCatalog) return '#e9a227';
      if (data.isExternalTargetTable) return 'purple';
      else return '#00aeca';
    case 'PROJECTION':
      return '#e0e0e0';
    case 'FUNCTION':
      return 'black';
    default:
      return null;
  }
}

export default TableNode;
