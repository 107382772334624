import useSearchParams from 'components/useSearchParams';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { CURRENT_PAGE_INITIAL_STATE } from 'reducers/utils';

const DEFAULT_PAGE = CURRENT_PAGE_INITIAL_STATE.number;
const DEFAULT_SIZE = CURRENT_PAGE_INITIAL_STATE.size;
const MAX_SIZE = 200;

export default function useRoutePagination({ currentPage, onPageSelection, dependencies = [] }) {
  let query = useSearchParams();
  let history = useHistory();
  let page = Math.max(query.get('page') || DEFAULT_PAGE, DEFAULT_PAGE);
  let size = Math.min(query.get('size') || currentPage?.size || DEFAULT_SIZE, MAX_SIZE);
  let [transaction, setTransaction] = useState(0);
  const changePagination = ({ page: newPage = page, size: newSize = size }) => {
    query.set('page', newPage);
    query.set('size', newSize);
    setTransaction(transaction + 1);
    history.push(history.location.pathname + `?${query.toString()}`, { keepScroll: true });
  };
  useEffect(() => {
    if (transaction > 0) {
      //wait the page to be redirected by the effect on dependencies
      onPageSelection(page, size);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, transaction]);
  useEffect(() => {
    const pageToInit = transaction === 0 ? page : 0;
    changePagination({ page: pageToInit, size });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return {
    page,
    size,
    changePagination: changePagination
  };
}
