import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { connect } from 'react-redux';
import { exportStewardshipRoles } from '../../../actions/ExportsActions';

function StewardshipRolesExportbutton({ onClick }) {
  return (
    <Tooltip title='Export CSV'>
      <IconButton color='default' aria-label='Download' onClick={() => onClick()} size='small'>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    onClick: (params) => dispatch(exportStewardshipRoles(params))
  };
}

export default connect(null, mapDispatchToProps)(StewardshipRolesExportbutton);
