import { combineReducers } from 'redux';
import crudReducers from '../utils';
import {
  FETCH_NOTIFICATIONS_UNREAD_COUNT,
  FETCH_USER_NOTIFICATIONS_SETTINGS,
  MODIFY_USER_NOTIFICATIONS_SETTINGS
} from '../../constants/ActionTypes';
import _ from 'lodash';

export default combineReducers({
  stats,
  notificationsSettings,
  ...crudReducers({ resource: 'NOTIFICATION', keyProperty: 'sequenceId' })
});

function stats(state = {}, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_UNREAD_COUNT:
      return action.payload;
    default:
      return state;
  }
}

function notificationsSettings(state = {}, action) {
  switch (action.type) {
    case FETCH_USER_NOTIFICATIONS_SETTINGS:
      return action.payload;
    case MODIFY_USER_NOTIFICATIONS_SETTINGS:
      return _.assign({}, state, action.payload);
    default:
      return state;
  }
}
