import React from 'react';

import { Card, CardHeader, CardContent, CardActions, Grid } from '@mui/material';
import GeneralSection from './GeneralSection';
import LogicalFieldSection from './LogicalFieldSection';
import Actions from './Actions';
import HistoryButtonLink from '../../../../history/commons/HistoryButtonLink';
import HistoryTargetTypes from '../../../../history/commons/HistoryTargetTypes';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const PhysicalFieldDetailsCard = ({ physicalField, onEdit, onDelete }) => {
  return (
    <Card>
      <CardHeader title={physicalField.name} />
      <CardContent>
        <GeneralSection physicalField={physicalField} />
        {physicalField?.logicalFields?.length > 0 && (
          <LogicalFieldSection logicalFields={physicalField.logicalFields} />
        )}
      </CardContent>
      <CardActions>
        {onEdit && onDelete && (
          <div>
            <Grid container direction='row'>
              <Grid item>
                <Actions physicalField={physicalField} onEdit={onEdit} onDelete={onDelete} />
              </Grid>
              <Grid item>
                <HistoryButtonLink
                  targetType={HistoryTargetTypes.PHYSICAL_FIELD}
                  targetIdentifier={physicalField.uuid}></HistoryButtonLink>
              </Grid>
            </Grid>
          </div>
        )}
        <div style={{ marginLeft: 'auto' }}>
          <WebActionsButton
            resource={physicalField}
            page={TargetPages.PHYSICAL_FIELD_DETAIL_PAGE}
          />
        </div>
      </CardActions>
    </Card>
  );
};

export default PhysicalFieldDetailsCard;
