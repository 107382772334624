import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import SingleFileDropzone from 'components/SingleFileDropzone';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function AttachmentModal({ open, attachment, onCancel, onSubmit }) {
  let { t } = useTranslation();
  let [editor, setEditor] = useState(attachment || {});
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>AttachmentModal</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          label='Name'
          placeholder='Enter the name'
          value={editor.name || ''}
          onChange={(event) => setEditor({ ...editor, name: event.target.value })}></TextField>

        <SingleFileDropzone
          file={editor.file}
          onChange={(file) => setEditor({ ...editor, file: file })}></SingleFileDropzone>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
        <Button disabled={!editor.name || !editor.file} onClick={() => onSubmit(editor)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
