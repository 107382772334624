import React from 'react';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';

const HelpIcon = ({ tooltip, style }) => (
  <Tooltip title={tooltip} placement='right'>
    <HelpOutline style={{ fontSize: 18, marginLeft: 8, verticalAlign: 'text-bottom', ...style }} />
  </Tooltip>
);

export default HelpIcon;
