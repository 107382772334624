import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import GetAppIcon from '@mui/icons-material/GetApp';
import { exportQueryStatements } from 'actions/QueryStatementsActions';

const QueryStatementExportButton = ({ onClick, queryBucket, filters, systemRoutineUuid }) => {
  return (
    <>
      <Tooltip title='Export'>
        <IconButton
          size='small'
          color='default'
          aria-label='Download'
          onClick={() =>
            onClick({
              bucketUuid: queryBucket?.uuid,
              systemRoutineUuid: systemRoutineUuid,
              ...filters
            })
          }>
          <GetAppIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  filters: state.dataflows.querystatements.filters.content
});
function mapDispatchToProps(dispatch) {
  return {
    onClick: (all) => dispatch(exportQueryStatements(all))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryStatementExportButton);
