import { IconButton } from '@mui/material';
import FilterList from '@mui/icons-material/FilterList';
import React, { useState } from 'react';
import { MyIssuesFiltersModal } from './MyIssuesFiltersModal';

export const MyIssuesFiltersButton = ({ myIssuesFilters, setMyIssuesFilters }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton
        size='small'
        color={Object.keys(myIssuesFilters).length !== 0 ? 'primary' : 'grey'}
        style={{ float: 'right' }}
        onClick={() => setOpen(true)}>
        <FilterList />
      </IconButton>
      {open && (
        <MyIssuesFiltersModal
          myIssuesFilters={myIssuesFilters}
          setMyIssuesFilters={setMyIssuesFilters}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
};
