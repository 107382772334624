import React from 'react';
import { Typography } from '@mui/material';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

export function AppVersion(props) {
  try {
    const reactAppVersion = document.querySelector('meta[name="react_app_version"]')?.content;
    return (
      <Typography variant='caption' {...props} onClick={() => clearCaches(true)}>
        {reactAppVersion}
      </Typography>
    );
  } catch (e) {
    return null;
  }
}

function clearCaches(reloadAfterClear = true) {
  if ('caches' in window) {
    caches
      .keys()
      .then((names) => {
        names.forEach(async (name) => {
          await caches.delete(name);
        });
      })
      .then(() => serviceWorkerRegistration.unregister())
      .then(() => {
        if (reloadAfterClear) window.location.reload();
      });
  }
}
