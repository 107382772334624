import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  fetchRiskEvaluations,
  modifyRiskEvaluation,
  deleteRiskEvaluation
} from 'actions/quality/assessment/QualityAssessmentRiskEvaluationActions';
import { fetchCheckEvaluationsByPhysicalEntityAndRisk } from 'actions/quality/assessment/QualityAssessmentCheckEvaluationActions';

import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import TableCell from 'components/tables/GenericTableCell';
import RiskEvaluationModal from './RiskEvaluationModal';

function QualityAssessmentPhysicalPanel({ physicalEntityUuid }) {
  let dispatch = useDispatch();

  let [openRiskEval, setOpenRiskEval] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [checkDrilldownModalOpen, setCheckDrilldownModalOpen] = useState(false);

  let riskEvaluations = useSelector(
    (state) => state.quality.assessment.riskEvaluations[physicalEntityUuid]
  );

  useEffect(() => {
    if (!riskEvaluations) {
      dispatch(fetchRiskEvaluations(physicalEntityUuid));
    }
  }, [dispatch, physicalEntityUuid, riskEvaluations]);

  if (!riskEvaluations) {
    return null;
  }
  return (
    <Card>
      <CardHeader title='Quality Assessment' />
      <CardContent>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Risk Code</TableCell>
              <TableCell>Risk Category</TableCell>
              <TableCell>Risk Name</TableCell>
              <TableCell>Severity</TableCell>
              <TableCell>Probability</TableCell>
              <TableCell>Inherent Risk</TableCell>
              <TableCell>Checks Impact</TableCell>
              <TableCell>Residual Risk</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {riskEvaluations.content.map((re) => (
              <TableRow
                hover
                style={{ cursor: 'pointer' }}
                key={re?.risk?.uuid}
                onClick={() => setOpenRiskEval(re)}>
                <TableCell>{re.risk.code}</TableCell>
                <TableCell>{re.risk.category}</TableCell>
                <TableCell>{re.risk.name}</TableCell>
                <TableCell>{re.severity?.label || '-'}</TableCell>
                <TableCell>{re.probability?.label || '-'}</TableCell>
                <TableCell>{re.inherentRisk?.label || '-'}</TableCell>
                <TableCell>
                  {re.overallChecksImpact ? (
                    <>
                      <span style={{ paddingRight: '12px' }}>{re.overallChecksImpact.label}</span>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setCheckDrilldownModalOpen(true);
                          setSelectedRow(re.risk.uuid);
                        }}
                        size="large">
                        <HelpOutlineIcon fontSize='small' />
                      </IconButton>
                    </>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>{re.residualRisk?.label || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <RiskEvaluationModal
          key={openRiskEval}
          open={openRiskEval != null}
          riskEvaluation={openRiskEval}
          onCancel={() => setOpenRiskEval(null)}
          onSubmit={(riskEval) => {
            setOpenRiskEval(null);
            dispatch(modifyRiskEvaluation(riskEval)).then(() =>
              dispatch(fetchRiskEvaluations(physicalEntityUuid))
            );
          }}
          onDelete={(riskEval) => {
            setOpenRiskEval(null);
            dispatch(deleteRiskEvaluation(riskEval)).then(() =>
              dispatch(fetchRiskEvaluations(physicalEntityUuid))
            );
          }}
        />
        {checkDrilldownModalOpen && (
          <CheckDrilldownModal
            open={checkDrilldownModalOpen}
            physicalEntityUuid={physicalEntityUuid}
            riskUuid={selectedRow}
            onCancel={() => setCheckDrilldownModalOpen(false)}
          />
        )}
      </CardContent>
    </Card>
  );
}

const CheckDrilldownModal = ({ open, physicalEntityUuid, riskUuid, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    dispatch(fetchCheckEvaluationsByPhysicalEntityAndRisk(physicalEntityUuid, riskUuid)).then(
      (response) => {
        setTableData(response?.content);
      }
    );
  }, [dispatch, physicalEntityUuid, riskUuid]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Check Drilldown</DialogTitle>
      <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
        {tableData?.length > 0 ? (
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Quality Check Name</TableCell>
                <TableCell>Severity Reduction</TableCell>
                <TableCell>Probability Reduction</TableCell>
                <TableCell>Check Impact</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.sortBy(tableData, 'qualityCheck.name').map((row) => (
                <TableRow
                  hover
                  style={{ cursor: 'pointer' }}
                  key={row.sequenceId}
                  onClick={() =>
                    history.push(`/data-quality/kqis/checks/${row.qualityCheck.uuid}`)
                  }>
                  <TableCell>{row.qualityCheck.name}</TableCell>
                  <TableCell>{row.severityReduction.label}</TableCell>
                  <TableCell>{row.probabilityReduction.label}</TableCell>
                  <TableCell>{row.checkImpact.label}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant='overline'>{t('commons.placeholders.nothingToDisplay')}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default QualityAssessmentPhysicalPanel;
