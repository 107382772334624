import {
  REQUEST_CLASSIFICATION_AGENT_SETTINGS,
  CREATE_CLASSIFICATION_AGENT_SETTING,
  MODIFY_CLASSIFICATION_AGENT_SETTING,
  DELETE_CLASSIFICATION_AGENT_SETTING
} from 'constants/ActionTypes';
import { combineReducers } from 'redux';
import _ from 'lodash';

const contentBySystemUuid = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_CLASSIFICATION_AGENT_SETTINGS:
      return {
        ...state,
        ..._.groupBy(action.payload.content, (sett) => sett.resourceIdentifier)
      };
    case CREATE_CLASSIFICATION_AGENT_SETTING:
      return {
        ...state,
        [action.payload.resourceIdentifier]: (
          state[action.payload.resourceIdentifier] || []
        ).concat([action.payload])
      };
    case MODIFY_CLASSIFICATION_AGENT_SETTING:
      return {
        ...state,
        [action.payload.resourceIdentifier]: (state[action.payload.resourceIdentifier] || [])
          .filter((sett) => sett.uuid !== action.payload.uuid)
          .concat([action.payload])
      };
    case DELETE_CLASSIFICATION_AGENT_SETTING:
      return {
        ...state,
        [action.payload.resourceIdentifier]: (
          state[action.payload.resourceIdentifier] || []
        ).filter((sett) => sett.uuid !== action.payload.uuid)
      };
    default:
      return state;
  }
};
export default combineReducers({
  contentBySystemUuid
});
