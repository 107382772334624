import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchLogicalRelationType } from 'actions/LogicalRelationsActions';
import AutoComplete from 'components/autocomplete/AutoComplete';
import { useTranslation } from 'react-i18next';

const LogicalRelationTypeAutoComplete = ({
  fetchLogicalRelationType,
  fullWidth,
  margin,
  logicalRelationType,
  onChange
}) => {
  const [types, setTypes] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchLogicalRelationType({ search: '' }).then(
      (response) => response && setTypes(response.content)
    );
  }, [fetchLogicalRelationType, logicalRelationType, setTypes]);

  return (
    <AutoComplete
      fullWidth={fullWidth}
      margin={margin}
      label={t('logicalRelations.autocomplete.relationTypeLabel')}
      value={logicalRelationType}
      dataSource={types}
      onUpdateInput={onChange}
      onNewRequest={onChange}
    />
  );
};

export default connect(null, { fetchLogicalRelationType })(LogicalRelationTypeAutoComplete);
