import React from 'react';
import withCustomPropertiesDefinitons from './withCustomPropertiesDefinitons';
import { useTranslation } from 'react-i18next';
import { ChipItem, ChipWrapper } from '../chiplist/ChipList';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Checkbox,
  Grid,
  FormControlLabel
} from '@mui/material';
import CustomPropertyFieldEditor from './CustomPropertyFieldEditor';

function CustomPropertiesFilters({
  resourceType,
  customProperties = [],
  onChange,
  properties = [],
  onChangeNull,
  nullPropertiesNames = []
}) {
  return (
    <>
      {customProperties
        .filter((cdp) => cdp.searchable)
        .map((cdp) => (
          <CustomPropertyFilter
            key={cdp.uuid}
            customProperty={cdp}
            properties={properties.filter((p) => p.name === cdp.name)}
            onChange={(newProperties) =>
              onChange(properties.filter((p) => p.name !== cdp.name).concat(newProperties))
            }
            isNullChecked={nullPropertiesNames.includes(cdp.name)}
            onChangeIsNull={
              onChangeNull &&
              ((isNullChecked) => {
                if (isNullChecked) {
                  onChangeNull(nullPropertiesNames.concat(cdp.name));
                } else {
                  onChangeNull(nullPropertiesNames.filter((name) => name !== cdp.name));
                }
              })
            }
          />
        ))}
    </>
  );
}

function CustomPropertyFilter({
  customProperty,
  properties,
  onChange,
  onChangeIsNull,
  isNullChecked
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  return (
    <>
      <Grid container direction='row' alignItems='baseline'>
        <Grid item>
          <Typography variant='subtitle1'>{customProperty.label}</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          {onChangeIsNull && (
            <FormControlLabel
              label='Only not defined'
              control={
                <Checkbox
                  size='small'
                  checked={isNullChecked}
                  onChange={(event) => onChangeIsNull(event.target.checked)}
                />
              }></FormControlLabel>
          )}
        </Grid>
      </Grid>
      <ChipWrapper onRequestAdd={() => setOpen(true)}>
        {properties.map((ap, index) => (
          <ChipItem
            key={index}
            id={index}
            label={`${ap.value}`}
            onRequestDelete={() => onChange(properties.filter((ap, index2) => index !== index2))}
          />
        ))}
      </ChipWrapper>

      <Dialog
        modal={true}
        contentStyle={{ maxWidth: '600px' }}
        open={open}
        fullWidth={true}
        maxWidth='sm'>
        <DialogTitle>Add a {customProperty.label} Filter</DialogTitle>
        <DialogContent>
          <CustomPropertyFieldEditor
            customPropertyDefinition={{
              ...customProperty,
              type: customProperty.type.replace('_LIST', '')
            }}
            additionalProperties={[{ name: customProperty.name, value }]}
            onChange={(newAdditionalProperties) => setValue(newAdditionalProperties[0].value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.clear')}
            onClick={() => {
              setValue('');
              setOpen(false);
            }}
          />
          <Button
            disabled={!value}
            children={t('commons.actions.add')}
            onClick={() => {
              onChange(properties.concat([{ name: customProperty.name, value }]));
              setOpen(false);
              setValue('');
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withCustomPropertiesDefinitons(CustomPropertiesFilters);
