import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withLoader } from 'components/loader/Loader';
import { useHistory } from 'react-router';

import { fetchDataCategory } from 'actions/DataCategoriesActions';

import { Grid, Tab, Tabs, Typography } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import DataCategoryDetailSection from 'pages/datacategories/detail/DataCategoryDetailSection';
import PhysicalEntitiesSection from 'pages/datacategories/detail/PhysicalEntitiesSection';
import StewardshipResponsibilitiesPanel from 'pages/stewardship/responsibilities/common/StewardshipResponsibilitiesPanel';
import CommentsPanel from 'pages/social/comments/CommentsPanel';
import useSearchParams from 'components/useSearchParams';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import ResourceTypes from 'constants/ResourceTypes';
import Permissions from 'constants/Permissions';
import withAuthorize from 'components/permissions/withAuthorize';
import ClassificationRulesPanel from 'pages/classification/rules/commons/ClassificationRulesPanel';
import LogicalRelationsCard from '../commons/LogicalRelationsCard';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const DataCategoryDetailPage = ({ t, dataCategory, dataCategoryUuid }) => {
  const query = useSearchParams();
  const history = useHistory();

  const selectedTab = query.get('tab') || 'physicalentities';
  const handleChangeTab = (_event, newValue) =>
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });
  return (
    <div>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.logical_model'), to: '/datacategories' },
          { text: dataCategory.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('data_categories.header_detail', { dataCategoryName: dataCategory.name }),
          category: EngagementCategories.LOGICAL_MODEL,
          action: EngagementActions.DETAIL
        }}
      />

      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            {t('data_categories.header_detail', {
              dataCategoryName: dataCategory.name
            })}
          </Typography>
        </Grid>
        <Grid item>
          <Grid item>
            <FavoritesWidget
              itemIdentifier={`${ResourceTypes.DATA_CATEGORY}:${dataCategory.uuid}`}
              resourceIdentifier={dataCategory.uuid}
              resourceType={ResourceTypes.DATA_CATEGORY}
              resourceName={dataCategory.name}
              pageTitle={t('data_categories.header_detail', {
                dataCategoryName: dataCategory.name
              })}></FavoritesWidget>
          </Grid>
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.DATA_CATEGORY}
            resourceIdentifier={dataCategory?.uuid}
            resourceName={dataCategory?.name}
          />
        </Grid>
      </Grid>
      <DataCategoryDetailSection dataCategoryUuid={dataCategoryUuid} dataCategory={dataCategory} />

      <Tabs value={selectedTab} onChange={handleChangeTab} centered>
        <AuthorizedTab
          hasPermissions={[Permissions.SYSTEMS_VIEWER]}
          value='physicalentities'
          label='Physical Entities'
        />
        <Tab value='logicalrelations' label='Relations' />
        <AuthorizedTab
          value='responsibilities'
          hasPermissions={[Permissions.STEWARDSHIP_VIEWER]}
          label='Responsibilities'
        />
        <AuthorizedTab
          hasPermissions={[Permissions.CLASSIFICATION_VIEWER]}
          value='classification'
          label='Classification'></AuthorizedTab>
        <Tab value='comments' label='Comments' />
      </Tabs>
      {selectedTab === 'physicalentities' && (
        <>
          <PhysicalEntitiesSection dataCategoryUuid={dataCategoryUuid} />
        </>
      )}
      {selectedTab === 'logicalrelations' && (
        <>
          <LogicalRelationsCard dataCategory={dataCategory} />
        </>
      )}
      {selectedTab === 'responsibilities' && (
        <>
          <StewardshipResponsibilitiesPanel
            resourceIdentifier={dataCategoryUuid}
            resourceName={dataCategory.name}
            resourceType='DATA_CATEGORY'
          />
        </>
      )}
      {selectedTab === 'classification' && (
        <ClassificationRulesPanel dataCategory={dataCategory}></ClassificationRulesPanel>
      )}
      {selectedTab === 'comments' && (
        <>
          <CommentsPanel
            notExpandable
            resourceType='DATA_CATEGORY'
            resourceIdentifier={dataCategoryUuid}
            resourceName={dataCategory.name}
          />
        </>
      )}
    </div>
  );
};
const AuthorizedTab = withAuthorize(Tab, 'span');

const mapStateToProps = (state, props) => ({
  dataCategoryUuid: props.match.params['dataCategoryUuid'],
  dataCategory: state.datacategories.content[props.match.params['dataCategoryUuid']]
});

export default _.flow([
  withLoader({
    onLoad: ({ dataCategoryUuid, fetchDataCategory }) => {
      fetchDataCategory(dataCategoryUuid);
    },
    ready: ({ dataCategory }) => dataCategory
  }),
  withTranslation(),
  connect(mapStateToProps, { fetchDataCategory })
])(DataCategoryDetailPage);
