import { Grid, Typography } from '@mui/material';
import React from 'react';
import RulesExport from '../commons/RulesExport';
import RuleCreateButton from '../commons/RuleCreateButton';
import RulesTable from './RulesTable';
import RulesFilters from '../commons/RulesFilters';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import RulesSearchBox from '../commons/RulesSearchBox';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
export default function RuleListPage() {
  return (
    <>
      <Breadcrumbs
        elements={[{ text: 'Classification', to: '/classification' }, { text: 'Rules' }]}
      />
      <Tracking
        info={{
          pageTitle: `Rules`,
          category: EngagementCategories.CLASSIFICATION,
          action: EngagementActions.LIST
        }}
      />
      <Grid container>
        <Grid style={{ flexGrow: 1 }} item>
          <Typography variant='h4'>Rules</Typography>
        </Grid>
        <Grid item>
          <RulesSearchBox />
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:rules-list'}
            pageTitle={`Rules`}
            iconSize={'small'}
          />
        </Grid>
        <Grid item>
          <RuleCreateButton />
        </Grid>
        <Grid item>
          <RulesExport />
        </Grid>
        <Grid item>
          <RulesFilters />
        </Grid>
      </Grid>
      <RulesTable />
    </>
  );
}
