import React from 'react';
import { Avatar } from '@mui/material';

export default function UsernameAvatar({
  username = '?',
  backgroundColor = '#00aeca',
  randomBackgroundColor
}) {
  username = username || '?';
  if (randomBackgroundColor) {
    let randomColor = stringToRGB(username);
    backgroundColor = `#${randomColor}`;
  }
  return <Avatar style={{ backgroundColor }}>{firstLettersOf(username)}</Avatar>;
}

const firstLettersOf = (username) => {
  if (username) {
    let splitted = username.split('.');
    let firstLetter = splitted[0] && splitted[0][0];
    let secondLetter = splitted[1] && splitted[1][0];
    return (firstLetter || '') + (secondLetter || '');
  }
};

function stringToRGB(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var c = (hash & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}
