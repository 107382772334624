import React from 'react';

import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { exportAnalysis } from 'actions/dataflows/DataFlowsImpactAnalysisActions';

function ImpactedAssetsExportButton({ exportAnalysis, editor }) {
  return (
    <>
      <Button children={'Export'} onClick={() => exportAnalysis(editor)} />
    </>
  );
}

export default connect(null, { exportAnalysis })(ImpactedAssetsExportButton);
