import { buildApiCallAction } from 'actions/ActionUtils';
import ProbesDefinitionsApi from 'api/quality/probes/ProbesDefinitionsApi';
import {
  FETCH_PROBE_DEFINITIONS_PAGE,
  FETCH_PROBE_DEFINITION,
  CREATE_PROBE_DEFINITION,
  MODIFY_PROBE_DEFINITION,
  DELETE_PROBE_DEFINITION,
  FETCH_PROBE_DEFINITION_WITH_ROOT,
  EXPORT_PROBE_DEFINITIONS
} from 'constants/ActionTypes';

const api = new ProbesDefinitionsApi();

export const fetchProbeDefinition = (rootUuid, sequenceId) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getProbeDefinition(rootUuid, sequenceId),
    actionType: FETCH_PROBE_DEFINITION
  });

export const fetchProbeDefinitionByRoot = (rootUuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getProbeDefinitionByRoot(rootUuid),
    actionType: FETCH_PROBE_DEFINITION_WITH_ROOT
  });
export const fetchProbeDefinitionsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getProbesDefinitions(params),
    actionType: FETCH_PROBE_DEFINITIONS_PAGE
  });

export const createProbeDefinition = (probeDefinition) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postProbeDefinition(probeDefinition),
    actionType: CREATE_PROBE_DEFINITION
  });

export const modifyProbeDefinition = (probeDefinition) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putProbeDefinition(probeDefinition),
    actionType: MODIFY_PROBE_DEFINITION
  });

export const deleteLogicalProbeDefinition = (probeDefinition) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.logicallyDeleteProbeDefinition(probeDefinition),
    actionType: DELETE_PROBE_DEFINITION
  });

export const deletePhysicalProbeDefinition = (probeDefinition) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.physicallyDeleteProbeDefinition(probeDefinition),
    actionType: DELETE_PROBE_DEFINITION
  });

export const exportProbeDefinitionsCSV = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportProbeDefinitionsCSV(params),
    actionType: EXPORT_PROBE_DEFINITIONS
  })

export const exportProbeDefinitionsYAML = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportProbeDefinitionsYAML(params),
    actionType: EXPORT_PROBE_DEFINITIONS
  });
