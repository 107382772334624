import React, { useState } from 'react';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Link as MaterialLink
} from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { getIconShort, getTechnologyConfig } from 'constants/SystemTechnologyTypes';

function ImpactedAssetsTable({ results }) {
  const [currentPageOptions, setCurrentPageOptions] = useState({
    number: 0,
    size: 20
  });
  const currentPage = {
    content: _.chunk(results.rows, currentPageOptions.size)[currentPageOptions.number],
    totalElements: results.rows.length,
    ...currentPageOptions
  };

  return (
    <>
      <Table size='small' style={{ overflowX: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell>System</TableCell>
            <TableCell>System Type</TableCell>
            <TableCell>Schema</TableCell>
            <TableCell>Physical Entity</TableCell>
            <TableCell>Physical Field</TableCell>
            <TableCell>Depth</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPage.content
            .map((row) => _.zipObject(results.columns, row))
            .sort((a, b) => a.depth - b.depth)
            .map((asset, index) => (
              <TableRow key={index}>
                <TableCell>
                  {
                    <>
                      {asset?.systemTechnology &&
                        getIconShort(getTechnologyConfig(asset?.systemTechnology), {
                          width: '1.5em',
                          height: '1.5em'
                        })}
                      <Link
                        to={`/systems/${asset?.systemUuid}`}
                        style={{ textDecoration: 'none' }}
                        onClick={(event) => event.stopPropagation()}>
                        <MaterialLink underline='hover' style={{ color: 'black', padding: '5px' }}>
                          {asset?.systemName || ''}
                        </MaterialLink>
                      </Link>
                    </>
                  }
                </TableCell>
                <TableCell>{asset?.systemSubtype ? asset.systemSubtype : '-'}</TableCell>
                <TableCell>{asset?.schema ? asset.schema : '-'}</TableCell>
                <TableCell>
                  {
                    <Link
                      to={`/physicalentities/${asset.physicalEntityUuid}`}
                      style={{ textDecoration: 'none' }}
                      onClick={(event) => event.stopPropagation()}>
                      <MaterialLink underline='hover' style={{ color: 'black' }}>
                        {asset?.physicalEntityName || '-'}
                      </MaterialLink>
                    </Link>
                  }
                </TableCell>
                <TableCell>
                  {
                    <Link
                      to={`/systems/physical_fields/${asset?.physicalFieldUuid}`}
                      style={{ textDecoration: 'none' }}
                      onClick={(event) => event.stopPropagation()}>
                      <MaterialLink underline='hover' style={{ color: 'black' }}>
                        {asset?.physicalFieldName || '-'}
                      </MaterialLink>
                    </Link>
                  }
                </TableCell>
                <TableCell>{asset?.depth}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        style={{ overflowX: 'hidden' }}
        rowsPerPageOptions={[10, 20, 50]}
        page={currentPage.number}
        rowsPerPage={currentPage.size}
        count={currentPage.totalElements}
        onRowsPerPageChange={(event) =>
          setCurrentPageOptions({
            ...currentPageOptions,
            size: event.target.value
          })
        }
        onPageChange={(event, number) =>
          setCurrentPageOptions({
            ...currentPageOptions,
            number: number
          })
        }
      />
    </>
  );
}

export default ImpactedAssetsTable;
