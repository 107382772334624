import React, { useEffect } from 'react';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AssignmentTableHead from './AssignmentsTableHead';
import {
  fetchAssignmentsPage,
  modifyAssignment,
  patchAssignment
} from '../../../../actions/AssignmentsActions';
import AssignmentsStateButton from '../commons/AssignmentsStateButtons';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import LogicalFieldSearchModal from '../../../datacategories/utils/LogicalFieldSearchModal';
import DataCategorySearchModal from '../../../datacategories/utils/DataCategorySearchModal';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import TableCell from 'components/tables/GenericTableCell';

function descendingComparator(a, b, orderBy, orderBySubProperty) {
  if (a[orderBy][orderBySubProperty] !== undefined) {
    if (b[orderBy][orderBySubProperty] < a[orderBy][orderBySubProperty]) {
      return -1;
    }
    if (b[orderBy][orderBySubProperty] > a[orderBy][orderBySubProperty]) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy, orderBySubProperty) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, orderBySubProperty)
    : (a, b) => -descendingComparator(a, b, orderBy, orderBySubProperty);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function handleWarningMessage(t, assignmentState) {
  if (assignmentState) {
    // eslint-disable-next-line default-case
    switch (assignmentState) {
      case t('classification.assignments.state.confirmed'):
        return t('classification.assignments.noConfirmedAssignments');
      case t('classification.assignments.state.pending'):
        return t('classification.assignments.noPendingAssignments');
      case t('classification.assignments.state.refused'):
        return t('classification.assignments.noRefusedAssignmentsFound');
    }
  } else {
    return t('classification.assignments.noAssignments');
  }
}

const styles = {
  root: {
    width: '100%'
  },
  table: {
    minWidth: '750px'
  }
};

function AssignmentsTable({
  currentPage,
  page,
  rowsPerPage,
  onPageSelection,
  filters,
  patch,
  modifyAssignment
}) {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('system');
  const orderBySubProperty = 'name';

  useEffect(() => {
    onPageSelection({
      page: 0,
      size: rowsPerPage,
      filters: filters
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, onPageSelection]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    onPageSelection({
      page: newPage,
      size: rowsPerPage,
      filters: filters
    });
  };

  const handleChangeRowsPerPage = (event) => {
    onPageSelection({
      page: page,
      size: event.target.value,
      filters: filters
    });
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, currentPage.content.length);
  const rowsSorted = stableSort(
    currentPage.content,
    getComparator(order, orderBy, orderBySubProperty)
  );
  return (
    <div style={styles.root}>
      <TableContainer>
        <Table
          sx={styles.table}
          aria-labelledby='tableTitle'
          size='small'
          aria-label='assignment table'>
          <AssignmentTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          {rowsSorted.length > 0 ? (
            <TableBody>
              {rowsSorted.map((assignment, index) => {
                const labelId = `assignment-table-${index}`;
                return (
                  <TableRow hover key={assignment.sequenceId}>
                    <TableCell component='th' id={labelId} scope='row' padding='normal'>
                      {assignment.system.name}
                    </TableCell>
                    <TableCell align='left'>{assignment.physicalEntity.name}</TableCell>
                    <TableCell align='left'>
                      {assignment.physicalField ? assignment.physicalField.name : 'Not defined'}
                    </TableCell>
                    <Authorize hasPermissions={[Permissions.CLASSIFICATION_EDITOR]}>
                      <TableCell align='left'>
                        <DataCategoryAssignmentCell
                          assignment={assignment}
                          modifyAssignment={modifyAssignment}
                        />
                      </TableCell>
                    </Authorize>
                    <Authorize hasPermissions={[Permissions.CLASSIFICATION_EDITOR]}>
                      <TableCell align='left'>
                        <LogicalFieldAssignmentCell
                          assignment={assignment}
                          modifyAssignment={modifyAssignment}></LogicalFieldAssignmentCell>
                      </TableCell>
                    </Authorize>
                    <TableCell align='left'>{assignment.assignmentState}</TableCell>
                    <TableCell align='right'>{assignment.score}</TableCell>
                    <AssignmentsStateButton
                      t={t}
                      assignment={assignment}
                      patch={patch}
                      filters={filters}
                    />
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 43 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              {
                <TableRow fullWidth>
                  <TableCell colSpan={8}>
                    <Typography
                      variant='body1'
                      fullWidth
                      align='center'
                      style={{ textAlign: 'center', padding: 100 }}>
                      {handleWarningMessage(t, filters.assignmentState?.[0])}
                    </Typography>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component='div'
        count={currentPage.totalElements || 0}
        rowsPerPage={rowsPerPage || 10}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

function LogicalFieldAssignmentCell({ assignment, modifyAssignment }) {
  const [openLogicalFieldSearchModal, setOpenLogicalFieldSearchModal] = React.useState(false);
  return (
    <>
      {assignment.logicalField ? (
        assignment.logicalField.name
      ) : assignment.logicalFieldName ? (
        <Grid container alignContent='space-between' alignItems='flex-start'>
          <Grid item>
            <p>{assignment.logicalFieldName}</p>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => setOpenLogicalFieldSearchModal(true)}
              aria-label='assign logical field'
              size='large'>
              <EmojiObjectsIcon />
            </IconButton>
            <LogicalFieldSearchModal
              open={openLogicalFieldSearchModal}
              onCancel={() => setOpenLogicalFieldSearchModal(false)}
              onSubmit={(logicalField) => {
                let newRow = {
                  ...assignment,
                  logicalField,
                  dataCategory: logicalField.dataCategory
                };
                modifyAssignment(newRow);
                setOpenLogicalFieldSearchModal(false);
              }}
            />
          </Grid>
        </Grid>
      ) : (
        'Not Defined'
      )}
    </>
  );
}

function DataCategoryAssignmentCell({ assignment, modifyAssignment }) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      {assignment.dataCategory ? (
        assignment.dataCategory.name
      ) : assignment.dataCategoryName ? (
        <Grid container alignContent='space-between' alignItems='flex-start'>
          <Grid item>
            <p>{assignment.dataCategoryName}</p>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => setOpen(true)}
              aria-label='assign data category'
              size='large'>
              <EmojiObjectsIcon />
            </IconButton>
            <DataCategorySearchModal
              open={open}
              onCancel={() => setOpen(false)}
              onSubmit={(dataCategory) => {
                let newRow = {
                  ...assignment,
                  logicalField: null,
                  dataCategory
                };
                modifyAssignment(newRow);
                setOpen(false);
              }}
            />
          </Grid>
        </Grid>
      ) : (
        'Not Defined'
      )}
    </>
  );
}

function mapStateToProps(state, props) {
  return {
    currentPage: state.classification.assignments.currentPage,
    page: state.classification.assignments.currentPage.number,
    rowsPerPage: state.classification.assignments.currentPage.size,
    filters: props.filters || state.classification.assignments.filters
  };
}

const mapDispatchToProps = {
  onPageSelection: fetchAssignmentsPage,
  patch: patchAssignment,
  modifyAssignment: modifyAssignment
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsTable);
