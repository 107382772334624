import React from 'react';
import { withOpenCloseHandler } from '../../../../components/openclosehandler/OpenCloseHandler';
import PhysicalEntitiesSearchModal from './PhysicalEntitySearchModal';
import {
  ChipWrapper,
  ChipItem
} from '../../../../components/chiplist/ChipList';

const PhysicalEntitiesChipListEditor = ({
  values,
  onChange,
  onToggleOpen,
  isOpen
}) => (
  <>
    <ChipWrapper onRequestAdd={() => onToggleOpen()}>
      {(values || []).map(item => (
        <ChipItem
          key={item.uuid}
          id={item.uuid}
          label={item.name}
          tooltip={item.description}
          onRequestDelete={() =>
            onChange(values.filter(e => e.uuid !== item.uuid))
          }
        />
      ))}
    </ChipWrapper>
    <PhysicalEntitiesSearchModal
      open={isOpen}
      onCancel={() => onToggleOpen()}
      onSubmit={item => {
        onToggleOpen();
        onChange((values || []).concat([item]));
      }}
    />
  </>
);

export default withOpenCloseHandler(PhysicalEntitiesChipListEditor);
