import {
  FETCH_HISTORY_RECORD,
  FETCH_HISTORY_RECORD_PAYLOAD,
  FETCH_HISTORY_RECORD_PAGE
} from '../../constants/ActionTypes';
import { combineReducers } from 'redux';
import filtersReducer from '../utils/filters';

const filtersDefault = {
  user: null,
  firstRopaUuid: null,
  secondRopaUuid: null,
  resource: null,
  targetType: null,
  operation: null,
  startDate: null,
  endDate: null
};
const content = (state = {}, action) => {
  switch (action.type) {
    case FETCH_HISTORY_RECORD:
      return { ...state, [action.payload.sequenceId]: action.payload };
    default:
      return state;
  }
};

const payloads = (state = {}, action) => {
  switch (action.type) {
    case FETCH_HISTORY_RECORD_PAYLOAD:
      return { ...state, [action.payload.sequenceId]: action.payload };
    default:
      return state;
  }
};

const currentPage = (state = { content: [], number: 0, size: 10, totalPages: 0 }, action) => {
  switch (action.type) {
    case FETCH_HISTORY_RECORD_PAGE:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  content,
  payloads,
  currentPage,
  filters: filtersReducer({ resource: 'HISTORY', filtersDefault })
});
