import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchAgent, modifyAgent, deleteAgent } from 'actions/AgentsActions';
import { pairAgent } from 'actions/onagent/AgentAdminActions';

import { Button, Card, CardActions, CardHeader, Grid } from '@mui/material';
import Breadcrumbs from 'pages/sidebar/Navigation';

import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import AgentsTab from 'pages/settings/agents/detail/AgentTabs';
import EditAgentModal from 'pages/settings/agents/commons/EditAgentModal';
import DeleteAgentModal from 'pages/settings/agents/commons/DeleteAgentModal';
import PairAgentModal from 'pages/settings/agents/commons/PairAgentModal';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import { useTranslation } from 'react-i18next';

function AgentDetailPage({ agentUuid, agent, fetchAgent, modifyAgent, deleteAgent, pairAgent }) {
  useEffect(() => {
    fetchAgent(agentUuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAgent]);

  const [modifyOpen, setModifyOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [pairOpen, setPairOpen] = useState(false);
  const { t } = useTranslation();
  return agent ? (
    <div>
      <Breadcrumbs
        elements={[
          { text: 'Settings', to: '/settings' },
          { text: 'Agents', to: '/settings/agents' },
          { text: agent.name }
        ]}
      />
      <Grid container justifyContent='flex-end'>
        <Grid item>
          <FavoritesWidget itemIdentifier={`AGENT:${agent.uuid}`} pageTitle={agent.name} />
        </Grid>
      </Grid>
      <Card>
        <CardHeader
          title={agent.name}
          subheader={agent.baseUrl}
          action={
            <VariationTypeDecorator>{agent.pairingStatus}</VariationTypeDecorator>
          }></CardHeader>
        <Authorize hasPermissions={[Permissions.AGENTS_ADMIN]}>
          <CardActions>
            <Button
              children={t('commons.actions.modify')}
              onClick={() => setModifyOpen(true)}></Button>
            <Button children={t('commons.actions.delete')} onClick={() => setDeleteOpen(true)}>
              Delete
            </Button>
            <Button children={t('commons.actions.pair')} onClick={() => setPairOpen(true)}>
              Pair
            </Button>
          </CardActions>
        </Authorize>
      </Card>
      <AgentsTab agent={agent}></AgentsTab>
      {modifyOpen && (
        <EditAgentModal
          selectedAgent={agent}
          open={modifyOpen}
          setOpen={setModifyOpen}
          modifyAgent={modifyAgent}></EditAgentModal>
      )}
      <DeleteAgentModal
        selectedAgent={agent}
        open={deleteOpen}
        setOpen={setDeleteOpen}
        deleteAgent={deleteAgent}
      />
      <PairAgentModal
        selectedAgent={agent}
        open={pairOpen}
        setOpen={setPairOpen}
        pairAgent={pairAgent}
        fetchAgent={fetchAgent}
      />
    </div>
  ) : null;
}

const mapDispatchToProps = {
  fetchAgent,
  modifyAgent,
  deleteAgent,
  pairAgent
};

const mapStateToProps = (state, props) => ({
  agentUuid: props.match.params['agentUuid'],
  agent: state.settings.agents.definitions.content[props.match.params['agentUuid']]
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentDetailPage);
