import { combineReducers } from 'redux';

export default function filtersReducer({ resource, filtersDefault }) {
  const actionTypes = {
    setFilters: `SET_${resource}S_FILTERS`,
    clearFilters: `CLEAR_${resource}S_FILTERS`
  };
  return combineReducers({
    content: filtersContentReducer(filtersDefault, actionTypes),
    hasFilters: hasFilters(actionTypes)
  });
}

const filtersContentReducer =
  (filtersDefault, actionTypes) =>
  (state = filtersDefault, action) => {
    switch (action.type) {
      case actionTypes.setFilters:
        return {
          ...state,
          ...action.payload
        };
      case actionTypes.clearFilters:
        return filtersDefault;
      default:
        return state;
    }
  };

const hasFilters =
  (actionTypes) =>
  (state = false, action) => {
    switch (action.type) {
      case actionTypes.setFilters:
        return true;
      case actionTypes.clearFilters:
        return false;
      default:
        return state;
    }
  };
