import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ItemAutoCompleteModal from '../../../../components/itemselector/ItemAutoCompleteModal';
import { fetchProposalsPage } from '../../../../actions/ProposalsActions';

function mapStateToProps(state, props) {
  return {
    values: Object.values(state.workflows.proposals.content)
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    itemName: 'Proposal',
    keyProperty: 'uuid',
    nameProperty: 'name',
    title: 'Search Proposal'
  };
}

const mapDispatchToProps = (dispatch, { proposalStatus }) => ({
  onSearch: search => dispatch(fetchProposalsPage({ search, proposalStatus }))
});

const ProposalSearchModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ItemAutoCompleteModal);

ProposalSearchModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  proposalStatus: PropTypes.string
};

export default ProposalSearchModal;
