import { combineReducers } from 'redux';
import {
  FETCH_CUSTOM_ROLES_PAGE,
  CREATE_CUSTOM_ROLE,
  DELETE_CUSTOM_ROLE
} from '../../constants/ActionTypes';

function currentPage(state = { content: [] }, action) {
  switch (action.type) {
    case FETCH_CUSTOM_ROLES_PAGE:
      return action.payload;
    case DELETE_CUSTOM_ROLE:
      return { ...state, content: state.content.filter(cr => cr.uuid !== action.payload.uuid) };
    case CREATE_CUSTOM_ROLE:
      return { ...state, content: state.content.concat([action.payload]) };
    default:
      return state;
  }
}

export default combineReducers({ currentPage });
