import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import RaisedButton from '@mui/material/Button';
import './Reset.css';
import { resetPasswordFinish } from '../../../actions/LoginActions';
import { showMessage } from '../../../actions/BehaviourActions';
import { connect } from 'react-redux';
import logo_oriz from '../../../images/logo_oriz_white.png';

class ResetFinish extends Component {
  state = {
    password1: null,
    password2: null
  };

  handleClick(event) {
    event.preventDefault();
    this.props
      .resetPasswordFinish({
        newPassword: this.state.password1,
        key: this.props.resetKey
      })
      .then(() => {
        this.props.history.push('/');
      });
  }

  render() {
    return (
      <form id='login-div'>
        <img id='login-logo' src={logo_oriz} alt='Logo' />
        <TextField
          variant='standard'
          type='password'
          placeholder=''
          InputLabelProps={{
            shrink: true
          }}
          label='New Password'
          onChange={(event) => this.setState({ password1: event.target.value })}
          margin='normal'
        />
        <br />
        <TextField
          variant='standard'
          type='password'
          placeholder=''
          InputLabelProps={{
            shrink: true
          }}
          label='Repeat Password'
          onChange={(event) => this.setState({ password2: event.target.value })}
          margin='normal'
        />
        <br />
        <RaisedButton
          variant='contained'
          color='primary'
          children='Complete Password Reset'
          style={style}
          disabled={!this.state.password1 || this.state.password1 !== this.state.password2}
          onClick={(event) => this.handleClick(event)}></RaisedButton>
        <input type='submit' hidden onClick={(event) => this.handleClick(event)} />
      </form>
    );
  }
}

const style = {
  margin: 15
};

const mapStateToProps = (state, props) => ({
  resetKey: props.match.params.resetKey
});

export default connect(mapStateToProps, { resetPasswordFinish, showMessage })(ResetFinish);
