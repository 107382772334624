import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import RulesRoutes from './rules/RulesRoutes';
import DictionariesRoutes from './dictionaries/DictionariesRoutes';
import ClassificationNavbar from './ClassificationNavbar';
import AssignmentsRoutes from './assignments/AssignmentsRoutes';

export default function ClassificationRoutes(props) {
    return (
        <>
            <ClassificationNavbar />
            <Switch>
                <Route path={`${props.match.url}/rules`} component={RulesRoutes} />

                <Route path={`${props.match.url}/dictionaries`} component={DictionariesRoutes}/>

                <Route path={`${props.match.url}/assignments`} component={AssignmentsRoutes}/>

                <Redirect to={`${props.match.url}/rules`} />
            </Switch>
        </>
    );
}