import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DataProductsFiltersModal } from './DataProductsFiltersModal';

export default function DataProductsFiltersButton() {
  const [openModal, setOpenModal] = useState(false);
  const hasFilters = useSelector((state) => state.dataproducts.filters.hasFilters);
  return (
    <>
      <Tooltip title='Filters'>
        <IconButton onClick={() => setOpenModal(true)} color={hasFilters ? 'primary' : 'default'}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      {openModal && <DataProductsFiltersModal open={openModal} setOpen={setOpenModal} />}
    </>
  );
}
