import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import withConfirmation from './withConfirmation';

const ConfirmButton = withConfirmation(Button);

ConfirmButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  message: PropTypes.string
};

export default ConfirmButton;
