import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from './utils';
import filtersReducer from 'reducers/utils/filters';
import { combineReducers } from 'redux';

const filtersDefault = {
  search: '',
  subtype: null
};

export default combineReducers({
  ...crudReducers({ resource: ResourceTypes.SYSTEM, keyProperty: 'uuid' }),
  filters: filtersReducer({ resource: ResourceTypes.SYSTEM, filtersDefault: filtersDefault })
});
