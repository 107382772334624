import React, { useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import StewardshipTargetTypes from '../../common/StewardshipTargetTypes';
import UsersInCurrentTenantItemAutocomplete from 'pages/settings/users/commons/UsersInCurrentTenantItemAutocomplete';
import SearchItemAutocomplete from '../../../settings/search/SearchItemAutocomplete';
import RolesItemAutoComplete from '../../roles/commons/RolesItemAutoComplete';
import TeamsItemAutocomplete from 'pages/settings/teams/common/TeamsItemAutocomplete';

function ResponsibilitiesFiltersEditor({ filters, setFilters }) {
  const [open, setOpen] = useState(false);
  const [hasFilters, setHasFilters] = useState(true);
  return (
    <React.Fragment>
      <Tooltip title='Filters'>
        <IconButton
          size='small'
          color={hasFilters ? 'primary' : 'grey'}
          onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <ResponsibilitiesFiltersModal
          open={open}
          setOpen={setOpen}
          filters={filters}
          setFilters={setFilters}
          setHasFilters={setHasFilters}></ResponsibilitiesFiltersModal>
      )}
    </React.Fragment>
  );
}

function ResponsibilitiesFiltersModal({ open, filters, setFilters, setOpen, setHasFilters }) {
  const [user, setUser] = useState(filters.user);
  const [role, setRole] = useState(filters.role);
  const [team, setTeam] = useState(filters.team);
  const [resource, setResource] = useState(filters.resource);
  const [endDateIsNull, setEndDateIsNull] = useState(filters.endDateIsNull);

  let { t } = useTranslation();
  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>{'Set Filters'}</DialogTitle>
      <DialogContent>
        <UsersInCurrentTenantItemAutocomplete
          value={user}
          onChange={(user) => setUser(user)}></UsersInCurrentTenantItemAutocomplete>
        <RolesItemAutoComplete
          value={role}
          onChange={(role) => setRole(role)}></RolesItemAutoComplete>
        <TeamsItemAutocomplete
          value={team}
          onChange={(team) => setTeam(team)}></TeamsItemAutocomplete>
        <SearchItemAutocomplete
          value={resource}
          resourceType={Object.values(StewardshipTargetTypes)}
          onChange={setResource}></SearchItemAutocomplete>
        <FormControlLabel
          control={
            <Checkbox
              checked={endDateIsNull || false}
              onChange={(event) => {
                setEndDateIsNull(event.target.checked ? true : null);
              }}
              name='nullEndDate'
              color='primary'></Checkbox>
          }
          label='Only active responsibilities'></FormControlLabel>
      </DialogContent>

      <DialogActions>
        <Button
          children={t('commons.actions.clear')}
          onClick={() => {
            setFilters({
              user: null,
              resourceType: null,
              endDateIsNull: true,
              team: null,
              resourceIdentifier: null
            });
            setOpen(false);
            setHasFilters(false);
          }}></Button>
        <Button
          children={t('commons.actions.apply')}
          variant='outlined'
          color='primary'
          onClick={() => {
            setFilters({
              ...filters,
              user,
              role,
              team,
              resourceType: resource && resource.resourceType,
              resourceIdentifier: resource && resource.resourceIdentifier,
              endDateIsNull
            });
            setHasFilters(true);
            setOpen(false);
          }}></Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResponsibilitiesFiltersEditor;
