import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';

import { createAgent } from 'actions/AgentsActions';

import AddFAB from 'components/fab/AddFAB';
import AgentModal from 'pages/settings/agents/commons/AgentModal';

function AgentFAB({ createAgent }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <AddFAB onClick={() => setOpen(true)} />
      {open && (
        <AgentModal
          title='Create agent'
          open={open}
          onSubmit={(agent) => {
            createAgent(agent);
            setOpen(false);
          }}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.AGENTS_ADMIN] }),
  connect(null, { createAgent })
)(AgentFAB);
