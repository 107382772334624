import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton
} from '@mui/material';
import { SettingsOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import actions from '../../../actions/SettingsConfigurationsActions';
import ResourceApplicabilityTargetTypes from '../roles/commons/ResourceApplicabilityTargetTypes';

function StewardshipConfigButton() {
  const [open, setOpen] = React.useState(false);

  return (
    <Authorize hasPermissions={[Permissions.STEWARDSHIP_ADMIN]}>
      <IconButton size='small' onClick={() => setOpen(true)}>
        <SettingsOutlined></SettingsOutlined>
      </IconButton>
      <StewardshipSettingsDialog open={open} onClose={() => setOpen(false)} />
    </Authorize>
  );
}

/*
 * Comma separated list of ResourceTypes on which stewardship acl is enabled
 */
const STEWARDSHIP_ACL_ENABLED_PROPERTY = 'stewardship-acl-enabled';

function StewardshipSettingsDialog({ open, onClose }) {
  const { t } = useTranslation();
  const [aclEnabledResourceTypesConfig, setAclEnabledResourceTypesConfig] = useState([]);
  const dispatch = useDispatch();

  useEffect(
    function () {
      open &&
        dispatch(actions.fetch(STEWARDSHIP_ACL_ENABLED_PROPERTY)).then((property) => {
          let value = property.value || '';
          let enabledSet = Object.values(ResourceApplicabilityTargetTypes)
            .map((rt) => ({ resourceType: rt, enabled: value.includes(rt) }))
            .reduce((prev, curV) => prev.concat([curV]), []);
          setAclEnabledResourceTypesConfig(enabledSet);
        });
    },
    [open, setAclEnabledResourceTypesConfig, dispatch]
  );

  return (
    <Dialog open={open}>
      <DialogTitle>Stewardship Settings</DialogTitle>
      <DialogContent>
        <h4>Acl Enabled</h4>
        {aclEnabledResourceTypesConfig.map((rtcfg, index) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={rtcfg.enabled}
                onChange={(event) =>
                  setAclEnabledResourceTypesConfig(
                    aclEnabledResourceTypesConfig.map((rt) =>
                      rt.resourceType === rtcfg.resourceType
                        ? { ...rtcfg, enabled: event.target.checked }
                        : rt
                    )
                  )
                }
                name={`acl-on-${rtcfg.resourceType}`}
                color='primary'
              />
            }
            label={`Enable Role Based ACL on ${t(`resourceType.${rtcfg.resourceType}`)}`}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button
          onClick={() => {
            onClose();
            let aclEnabledNewPropertyValue = aclEnabledResourceTypesConfig
              .filter((rtcfg) => rtcfg.enabled)
              .map((rtcfg) => rtcfg.resourceType)
              .join(',');
            dispatch(
              actions.modify({
                name: STEWARDSHIP_ACL_ENABLED_PROPERTY,
                value: aclEnabledNewPropertyValue
              })
            );
          }}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StewardshipConfigButton;
