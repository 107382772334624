import * as React from "react";
const SvgNeo4J = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 72 81"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="1em"
    height="1em"
    {...props}
  >
    <use xlinkHref="#Neo4j_svg__a" x={0.5} y={0.5} />
    <symbol id="Neo4j_svg__a" overflow="visible">
      <g stroke="none" fillRule="nonzero">
        <path
          d="M67.287 36.308c0 18.582-15.061 33.644-33.644 33.644S0 54.89 0 36.308 15.061 2.664 33.644 2.664c18.582-.016 33.644 15.061 33.644 33.644"
          fill="#008cc1"
        />
        <path d="M14.915 25.272c0 1.484-1.206 2.69-2.689 2.69s-2.69-1.206-2.69-2.69a2.69 2.69 0 0 1 2.69-2.689 2.68 2.68 0 0 1 2.689 2.689m.456 7.384c0 1.483-1.206 2.69-2.689 2.69s-2.69-1.206-2.69-2.69a2.69 2.69 0 0 1 2.69-2.689 2.68 2.68 0 0 1 2.689 2.689m1.646 7.825c0 1.483-1.206 2.689-2.689 2.689s-2.69-1.206-2.69-2.689 1.206-2.69 2.69-2.69c1.483-.016 2.689 1.19 2.689 2.69m3.684 7.009a2.693 2.693 0 0 1-2.69 2.69 2.69 2.69 0 0 1-2.689-2.69 2.69 2.69 0 0 1 2.689-2.689c1.483-.016 2.69 1.19 2.69 2.689" />
        <use xlinkHref="#Neo4j_svg__b" />
        <use xlinkHref="#Neo4j_svg__b" x={4.89} y={-5.787} />
        <path d="M40.213 10.586c0 1.483-1.206 2.689-2.69 2.689s-2.689-1.206-2.689-2.689 1.206-2.689 2.689-2.689 2.69 1.206 2.69 2.689" />
        <use xlinkHref="#Neo4j_svg__b" x={-16.366} y={-49.178} />
        <path
          d="M52.209 10.358c9.902 0 17.93 8.028 17.93 17.93s-8.028 17.93-17.93 17.93-17.93-8.028-17.93-17.93 8.028-17.93 17.93-17.93"
          fill="#66b245"
        />
        <path d="M52.21 46.699c-10.152 0-18.411-8.259-18.411-18.41s8.259-18.41 18.411-18.41 18.41 8.259 18.41 18.41-8.259 18.41-18.41 18.41zm0-35.86c-9.622 0-17.45 7.828-17.45 17.45s7.828 17.45 17.45 17.45 17.45-7.828 17.45-17.45-7.828-17.45-17.45-17.45z" />
        <path
          d="M27.645 50.18c8.102 0 14.67 6.568 14.67 14.67s-6.568 14.67-14.67 14.67-14.67-6.568-14.67-14.67 6.568-14.67 14.67-14.67"
          fill="#66b245"
        />
        <path d="M27.645 80c-8.354 0-15.15-6.797-15.15-15.15s6.796-15.15 15.15-15.15 15.15 6.796 15.15 15.15S36 80 27.645 80zm0-29.34a14.21 14.21 0 0 0-14.19 14.19 14.21 14.21 0 0 0 14.19 14.19 14.21 14.21 0 0 0 14.19-14.19 14.21 14.21 0 0 0-14.19-14.19z" />
        <path
          d="M15.567.48c5.401 0 9.78 4.379 9.78 9.78s-4.379 9.78-9.78 9.78-9.78-4.379-9.78-9.78S10.166.48 15.567.48"
          fill="#66b245"
        />
        <path d="M15.566 20.521c-5.658 0-10.26-4.603-10.26-10.26S9.909 0 15.566 0s10.26 4.603 10.26 10.26-4.603 10.26-10.26 10.26zm0-19.56c-5.128 0-9.3 4.172-9.3 9.3s4.172 9.3 9.3 9.3 9.3-4.172 9.3-9.3-4.172-9.3-9.3-9.3z" />
      </g>
    </symbol>
    <defs>
      <path
        id="Neo4j_svg__b"
        d="M49.504 57.287c0 1.483-1.206 2.689-2.69 2.689s-2.689-1.206-2.689-2.689 1.206-2.69 2.689-2.69 2.69 1.206 2.69 2.69"
      />
    </defs>
  </svg>
);
export default SvgNeo4J;
