import React, { useReducer, useState } from 'react';
import _ from 'lodash';
import Search from '@mui/icons-material/FilterList';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
import DataCategorySearchModal from '../../../datacategories/utils/DataCategorySearchModal';
import PhysicalEntitySchemaAutoComplete from '../commons/PhysicalEntitySchemaAutoComplete';
import {
  Button,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Dialog,
  Grid,
  FormControlLabel,
  Switch
} from '@mui/material';
import DatePicker, { hourAdjustments } from '../../../../components/pickers/DatePicker';

import LogicalFieldSearchModal from '../../../datacategories/utils/LogicalFieldSearchModal';
import {
  setEntitiesFilters,
  clearEntitiesFilters
} from '../../../../actions/PhysicalEntitiesActions';
import { connect, useSelector } from 'react-redux';
import ResourceTypes from '../../../../constants/ResourceTypes';
import CustomPropertiesFilters from '../../../../components/additional_properties/CustomPropertiesFilters';
import { useTranslation } from 'react-i18next';
import DataSetAutoComplete from '../commons/DataSetAutoComplete';
import SystemItemAutoComplete from '../../commons/SystemItemAutoComplete';
import PhysicalEntityTypeSelect from 'pages/systems/physical_entities/commons/PhysicalEntityTypeSelect';

export default function PhysicalEntitiesFilters() {
  let [open, setOpen] = useState(false);
  let hasFilters = useSelector((state) => state.physicalentities.filters.hasFilters);
  return (
    <>
      <IconButton
        size='small'
        color={hasFilters ? 'primary' : 'grey'}
        onClick={() => setOpen(true)}>
        <Search />
      </IconButton>
      {open && <PhysicalEntitiesFiltersDialog open={open} setOpen={setOpen} />}
    </>
  );
}

const PhysicalEntitiesFiltersDialogView = ({
  filters,
  setEntitiesFilters,
  clearEntitiesFilters,
  open,
  setOpen
}) => {
  let [addDataCategory, setAddDataCategory] = useState(false);
  let [addLogicalFieldsUuids, setAddLogicalFieldsUuids] = useState(false);
  let [editor, setEditor] = useReducer((state, action) => ({ ...state, ...action }), filters);
  let { t } = useTranslation();

  let {
    system,
    schema,
    tableType,
    physicalFieldsCount,
    dataCategories,
    logicalFields,
    propertiesNames,
    propertiesValues,
    nullPropertiesNames,
    dataSet,
    hidden,
    startCreatedAt,
    endCreatedAt,
    startUpdatedAt,
    endUpdatedAt
  } = editor;
  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>Physical Entities Filters</DialogTitle>
      <DialogContent>
        <SystemItemAutoComplete
          fullWidth
          margin='dense'
          value={system}
          onChange={(system) => setEditor({ system: system, schema: undefined })}
        />
        <PhysicalEntitySchemaAutoComplete
          fullWidth
          margin='dense'
          systemUuid={system?.uuid}
          schema={schema || ''}
          onChange={(schema) => setEditor({ schema: schema })}
        />
        <PhysicalEntityTypeSelect
          fullWidth
          margin='dense'
          type={tableType}
          onChange={(type) => setEditor({ tableType: type })}
        />
        <DataSetAutoComplete
          fullWidth
          margin='dense'
          dataSet={dataSet || ''}
          onChange={(dataSet) => setEditor({ dataSet: dataSet })}
        />
        <TextField
          variant='standard'
          fullWidth
          margin='normal'
          label='Physical Fields Count'
          placeholder='Enter the number of physical fields'
          value={physicalFieldsCount || ''}
          onChange={(event) => {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
              setEditor({
                physicalFieldsCount: event.target.value
              });
            }
          }}
        />
        <Typography variant='subtitle1'>{t('data_categories.header')}</Typography>
        <ChipWrapper onRequestAdd={() => setAddDataCategory(true)}>
          {dataCategories.map((dc) => (
            <ChipItem
              key={dc.uuid}
              id={dc.uuid}
              label={dc.name}
              tooltip={dc.description}
              onRequestDelete={() =>
                setEditor({
                  dataCategories: dataCategories.filter((d) => d.uuid !== dc.uuid)
                })
              }
            />
          ))}
        </ChipWrapper>
        <Typography variant='subtitle1'>Logical Fields</Typography>
        <ChipWrapper onRequestAdd={() => setAddLogicalFieldsUuids(true)}>
          {logicalFields.map((lf) => (
            <ChipItem
              key={lf.uuid}
              id={lf.uuid}
              label={lf.name}
              tooltip={lf.description}
              onRequestDelete={() =>
                setEditor({
                  logicalFields: logicalFields.filter((l) => l.uuid !== lf.uuid)
                })
              }
            />
          ))}
        </ChipWrapper>

        <CustomPropertiesFilters
          resourceType={ResourceTypes.PHYSICAL_ENTITY}
          properties={_.zipWith(propertiesNames, propertiesValues, (name, value) => ({
            name,
            value
          }))}
          onChange={(newProperties) =>
            setEditor({
              propertiesNames: newProperties.map((p) => p.name),
              propertiesValues: newProperties.map((p) => p.value)
            })
          }
          nullPropertiesNames={nullPropertiesNames}
          onChangeNull={(nullNames) => {
            setEditor({ nullPropertiesNames: nullNames });
          }}
        />
        <h3>Creation date on Blindata</h3>
        <Grid container justifyContent='flex-start' spacing={2}>
          <Grid item>
            <DatePicker
              clearable={true}
              label={'Start creation date'}
              hintText={'Creation Date'}
              hourAdjustment={hourAdjustments.startOfDay}
              value={startCreatedAt || null}
              maxDate={endCreatedAt}
              onChange={(date) => setEditor({ startCreatedAt: date })}
            />
          </Grid>
          <Grid item>
            <DatePicker
              clearable={true}
              label={'End creation date'}
              hintText={'Modification Date'}
              hourAdjustment={hourAdjustments.startOfDay}
              value={endCreatedAt || null}
              minDate={startCreatedAt}
              onChange={(date) => setEditor({ endCreatedAt: date })}
            />
          </Grid>
        </Grid>
        <h3>Modification date on Blindata</h3>
        <Grid container justifyContent='flex-start' spacing={2}>
          <Grid item>
            <DatePicker
              clearable={true}
              label={'Start updated date'}
              hintText={'Creation Date'}
              hourAdjustment={hourAdjustments.startOfDay}
              value={startUpdatedAt || null}
              maxDate={endUpdatedAt}
              onChange={(date) => setEditor({ startUpdatedAt: date })}
            />
          </Grid>
          <Grid item>
            <DatePicker
              clearable={true}
              label={'End updated date'}
              hintText={'Modification Date'}
              hourAdjustment={hourAdjustments.startOfDay}
              value={endUpdatedAt || null}
              minDate={startUpdatedAt}
              onChange={(date) => setEditor({ endUpdatedAt: date })}
            />
          </Grid>
        </Grid>
        <FormControlLabel
          label='Show also hidden tables'
          control={
            <Switch
              color='primary'
              checked={hidden || false}
              onChange={(event, checked) => setEditor({ hidden: checked })}
            />
          }
          style={{ marginTop: '16px' }}
        />
        <DataCategorySearchModal
          open={addDataCategory}
          onCancel={() => setAddDataCategory(false)}
          onSubmit={(dc) => {
            setEditor({
              dataCategories: dataCategories.concat([dc])
            });
            setAddDataCategory(false);
          }}
        />
        <LogicalFieldSearchModal
          open={addLogicalFieldsUuids}
          onCancel={() => setAddLogicalFieldsUuids(false)}
          onSubmit={(lf) => {
            setEditor({
              logicalFields: (logicalFields || []).concat([lf])
            });
            setAddLogicalFieldsUuids(false);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.clear')}
          onClick={() => {
            clearEntitiesFilters();
            setOpen(false);
          }}
        />
        <Button
          variant='outlined'
          color='primary'
          children={t('commons.actions.apply')}
          onClick={() => {
            setOpen(false);
            setEntitiesFilters(editor);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, props) => ({
  filters: state.physicalentities.filters
});

const PhysicalEntitiesFiltersDialog = connect(mapStateToProps, {
  setEntitiesFilters,
  clearEntitiesFilters
})(PhysicalEntitiesFiltersDialogView);
