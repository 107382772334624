import React from 'react';
import { Route, Switch } from 'react-router';
import ProposalsListPage from './list/ProposalsListPage';
import ProposalDetailPage from './detail/ProposalDetailPage';
import ProposalEditorPage from './editor/ProposalEditorPage';

function ProposalsRoutes(props) {
  return (
    <Switch>
      <Route exact path={`${props.match.url}/editor`} component={ProposalEditorPage} />

      <Route
        exact
        path={`${props.match.url}/editor/:proposalUuid`}
        component={ProposalEditorPage}
      />
      <Route exact path={`${props.match.url}/:proposalUuid`} component={ProposalDetailPage} />
      <Route exact path={`${props.match.url}`} component={ProposalsListPage} />
    </Switch>
  );
}

export default ProposalsRoutes;
