import * as React from "react";
const SvgDatabricks = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      style={{
        fill: "#db1905",
      }}
      d="M0 24.8v13.7L41.1 60l41.1-21.5V24.8l-14.5-7.4 14.5-7.6V-3.9l-.1.1-41-21.2L.1-3.9H0V9.8l14.5 7.6L0 24.8"
      transform="translate(1.054 18.824) scale(.75294)"
    />
    <path
      transform="translate(1.054 -8.282) scale(.75294)"
      style={{
        fill: "#ff5224",
      }}
      d="m0 60.8 41.1 21.5 41.1-21.5-14.5-7.4-26.6 13.9-26.6-13.9z"
    />
    <path
      transform="translate(1.054 -8.282) scale(.75294)"
      style={{
        fill: "#ff5224",
      }}
      d="M82.2 32.2 41.1 53.7 0 32.2 41.1 11z"
    />
  </svg>
);
export default SvgDatabricks;
