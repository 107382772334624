import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import ActionAdd from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';

export function ChipItem({
  label,
  icon,
  size,
  tooltip,
  to,
  style,
  onClick,
  onRequestDelete,
  children,
  color
}) {
  return (
    <Tooltip title={tooltip || ''}>
      <Chip
        color={color}
        size={size}
        icon={icon || null}
        to={to}
        component={to ? Link : undefined}
        style={{
          ...style,
          fontWeight: 500,
          margin: 4,
          cursor: onClick || to ? 'pointer' : 'auto'
        }}
        label={label || children}
        onDelete={onRequestDelete}
        onClick={onClick}
      />
    </Tooltip>
  );
}

export function ChipWrapper({ children, onRequestAdd, justifyRigth, style }) {
  let addButton = onRequestAdd ? (
    <Chip
      avatar={
        <Avatar>
          <ActionAdd />
        </Avatar>
      }
      label={'Add'}
      onClick={() => onRequestAdd()}
      style={{ margin: 4 }}
    />
  ) : null;
  style = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: justifyRigth ? 'flex-end' : 'flex-start',
    ...style
  };
  return (
    <div style={style}>
      {children}
      {addButton}
    </div>
  );
}

ChipWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onRequestAdd: PropTypes.func
};

ChipItem.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  onRequestDelete: PropTypes.func,
  onClick: PropTypes.func
};
