import React from 'react';

import { UNITS } from '../utils/units';

import CustomSelect from './CustomSelect';

const Hours = ({ value, setValue }) => {
  return (
    <>
      <span>at</span>
      <CustomSelect unit={UNITS[1]} value={value} setValue={setValue} />
    </>
  );
};

export default Hours;
