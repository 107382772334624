import { buildApiCallAction } from './ActionUtils';
import QueryBucketsApi from 'api/QueryBucketsAPI';
import {
  FETCH_QUERY_BUCKETS_PAGE,
  FETCH_QUERY_BUCKET,
  CREATE_QUERY_BUCKET,
  MODIFY_QUERY_BUCKET,
  DELETE_QUERY_BUCKET,
  SET_QUERY_BUCKETS_FILTERS,
  CLEAR_QUERY_BUCKETS_FILTERS,
  REFRESH_QUERY_BUCKET_STATEMENTS_PAGE,
  CONFIRM_QUERY_BUCKET_STATEMENTS_PAGE,
  IGNORE_QUERY_BUCKET_STATEMENTS_PAGE
} from '../constants/ActionTypes';

const api = new QueryBucketsApi();

export const fetchQueryBucketsPage = ({ page, size = 20, ...filters }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => {
      const apiParams = createApiParams(filters);
      return api.getAllQueryBuckets({ page, size, ...apiParams });
    },
    actionType: FETCH_QUERY_BUCKETS_PAGE
  });

export const fetchQueryBucket = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getQueryBucket(uuid),
    actionType: FETCH_QUERY_BUCKET
  });
export const createQueryBucket = (queryBucket) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postQueryBucket(queryBucket),
    actionType: CREATE_QUERY_BUCKET
  });

export const modifyQueryBucket = (queryBucket) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putQueryBucket(queryBucket),
    actionType: MODIFY_QUERY_BUCKET
  });

export const deleteQueryBucket = (queryBucket) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteQueryBucket(queryBucket),
    actionType: DELETE_QUERY_BUCKET,
    payload: queryBucket
  });

export const refreshQueryBucketStatements = ({ queryBucketUuid, ...params }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.refreshQueryBucketStatements(queryBucketUuid, params),
    actionType: REFRESH_QUERY_BUCKET_STATEMENTS_PAGE
  });

export const confirmQueryBucketStatements = ({ queryBucketUuid, ...params }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.confirmQueryBucketStatements(queryBucketUuid, params),
    actionType: CONFIRM_QUERY_BUCKET_STATEMENTS_PAGE
  });

export const ignoreQueryBucketStatements = ({ queryBucketUuid, ...params }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.ignoreQueryBucketStatements(queryBucketUuid, params),
    actionType: IGNORE_QUERY_BUCKET_STATEMENTS_PAGE
  });
export const setQueryBucketsFilters = (filters) => (dispatch) => {
  dispatch({ type: SET_QUERY_BUCKETS_FILTERS, payload: filters });
};

export const clearQueryBucketsFilters = () => (dispatch) => {
  dispatch({ type: CLEAR_QUERY_BUCKETS_FILTERS });
};

function createApiParams(filters) {
  return filters.search ? { search: filters.search } : { search: '' };
}
