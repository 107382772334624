import { createUser } from 'actions/UsersActions';
import { AddFAB } from 'components/fab';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectCurrentTenant } from 'reducers/settings/tenants';
import UserEditorModal from '../commons/UserEditorModal';

function AddUserFab() {
  let history = useHistory();
  let dispatch = useDispatch();
  let [open, setOpen] = useState(false);
  let tenantName = useSelector((state) => selectCurrentTenant(state)?.name);
  return (
    <>
      <Authorize hasPermissions={[Permissions.USERS_ADMIN]}>
        <AddFAB
          onClick={() => {
            setOpen(true);
          }}
        />
      </Authorize>
      <UserEditorModal
        title={`Create User on ${tenantName}`}
        open={open}
        handleCancel={() => setOpen(false)}
        handleSubmit={(user) => {
          setOpen(false);
          dispatch(createUser(user)).then(
            (user) => user?.uuid && history.push(`/settings/users/${user.uuid}`)
          );
        }}
      />
    </>
  );
}

export default AddUserFab;
