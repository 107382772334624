import React, { useEffect } from 'react';
import { Table, TableHead, TableRow, Typography, TableBody } from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import QualityProbesTagAddButton from '../common/QualityProbesTagAddButton';
import { fetchTagsPage } from 'actions/quality/probes/ProbesTagsActions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

function QualityProbesTagsList({ currentPage, project, fetchTagsPage }) {
  const history = useHistory();

  useEffect(() => {
    if (project) fetchTagsPage({ projectUuid: project.uuid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <QualityProbesTagAddButton project={project}></QualityProbesTagAddButton>
      </div>
      {currentPage.content.length > 0 ? (
        <div>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Tag Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPage.content.map((tag) => (
                <TableRow
                  key={tag.uuid}
                  hover={true}
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(`/data-quality/probes/tags/${tag.uuid}`)}>
                  <TableCell>{tag.name}</TableCell>
                  <TableCell hiddenSm>{tag.description}</TableCell>
                  <TableCell>{formatDate(tag.createdAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='body1'>No tags defined for this project </Typography>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  currentPage: state.quality.qualityProbes.tags.currentPage
});

const mapDispatchToProps = {
  fetchTagsPage
};
function formatDate(timestamp) {
  if (timestamp) {
    let date = new Date(timestamp);
    return date.toLocaleDateString();
  } else {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityProbesTagsList);
