import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from './utils';
import { combineReducers } from 'redux';
import {
  FETCH_DATA_PRODUCT_BY_PORT_IDENTIFIER,
  FETCH_DATA_PRODUCT_MESH,
  MODIFY_DATA_PRODUCT,
  DELETE_DATA_PRODUCT
} from 'constants/ActionTypes';
import filtersReducer from './utils/filters';
import _ from 'lodash';

const filtersDefault = {
  search: ''
};

const mesh = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_PRODUCT_MESH:
      return {
        ...state,
        [action.payload.dataProductUuid]: action.payload
      };
    default:
      return state;
  }
};

const dataProductByPortIdentifier = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_PRODUCT_BY_PORT_IDENTIFIER:
      return {
        ...state,
        [action.payload.portIdentifier]: _.omit(action.payload, 'portIdentifier')
      };
    case MODIFY_DATA_PRODUCT:
    case DELETE_DATA_PRODUCT:
      return {
        ..._.omit(
          state,
          Object.keys(state).filter((key) => state[key].uuid === action.payload.uuid)
        )
      };
    default:
      return state;
  }
};

export default combineReducers({
  mesh,
  dataProductByPortIdentifier,
  ...crudReducers({ resource: ResourceTypes.DATA_PRODUCT, keyProperty: 'uuid' }),
  filters: filtersReducer({ resource: ResourceTypes.DATA_PRODUCT, filtersDefault: filtersDefault })
});
