import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  InputLabel,
  MenuItem
} from '@mui/material';

function IssueStatusAddModal({ open, onCancel, onSubmit }) {
  const [status, setStatus] = useState(null);
  let { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth>
        <DialogTitle>{'Add Status'}</DialogTitle>
        <DialogContent>
          <FormControl variant='standard' fullWidth margin='dense'>
            <InputLabel id='issue-type-select-label'>Status</InputLabel>
            <Select
              variant='standard'
              labelId='issue-type-select-label'
              id='issue-type-select'
              value={status || ''}
              onChange={(event) => setStatus(event.target.value)}>
              <MenuItem value={'TO_DO'}>To Do</MenuItem>
              <MenuItem value={'DONE'}>Done</MenuItem>
              <MenuItem value={'IN_PROGRESS'}>In Progress</MenuItem>
              <MenuItem value={'REVIEW'}>Review</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.cancel')}
            onClick={() => {
              onCancel();
              setStatus(null);
            }}></Button>
          <Button
            children={t('commons.actions.submit')}
            variant='contained'
            color='primary'
            ee
            onClick={() => {
              onSubmit(status);
              setStatus(null);
              onCancel();
            }}></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default IssueStatusAddModal;
