import React from 'react';
import { Typography, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import ToolbarActions from '../sidebar/ToolbarActions';

const DataSubjectsNavbar = ({ location: { pathname } }) => (
  <>
    <ToolbarActions>
      <Button
        color={pathname.includes('dashboards') ? 'primary' : 'grey'}
        children='Dashboards'
        component={Link}
        to='/datasubjects/dashboards'
      />
      <Button
        color={pathname.includes('audience') ? 'primary' : 'grey'}
        children='Audience'
        component={Link}
        to='/datasubjects/audience'
      />
      <Button
        color={pathname.includes('report') ? 'primary' : 'grey'}
        children='Report'
        component={Link}
        to='/datasubjects/report'
      />
    </ToolbarActions>
    <Grid container justifyContent='space-between'>
      <Grid item>
        <Typography style={{ display: 'inline' }} variant='h4'>
          Data Subjects
        </Typography>
      </Grid>
      <Grid item></Grid>
    </Grid>
  </>
);

export default withRouter(DataSubjectsNavbar);
