import * as React from "react";
const SvgDerby = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 426.161 297.456"
    overflow="visible"
    xmlSpace="preserve"
    {...props}
  >
    <switch>
      <g stroke="#000">
        <path
          fill="#FFF"
          d="M232.208 133.221S93.001 177.558 65.453 178.033c0 0-.66 12.581 7.248 39.395 8.954 3.187 110.951-22.828 122.296-28.786 45.497-23.889 30.558-35.972 140.918-74.353 0 0-2.095-9.582-10.47-23.141 0 0-40.656 27.657-93.237 42.073z"
          stroke="none"
        />
        <linearGradient
          id="Derby_svg__a"
          gradientUnits="userSpaceOnUse"
          x1={0}
          y1={239.411}
          x2={200.972}
          y2={239.411}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#575757",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#000",
            }}
          />
        </linearGradient>
        <path
          fill="url(#Derby_svg__a)"
          d="M74.042 217.032c-3.593-4.77-8.742-34.582-8.742-34.582S-7.679 214.174.646 267.094c3.909 24.865 25.083 28.246 34.136 29.277 19.604 2.232 48.078-12.758 64.306-24.064 32.637-22.742 50.212-44.686 101.884-86.885 0 .001-73.139 27.889-126.93 31.61z"
          stroke="none"
        />
        <linearGradient
          id="Derby_svg__b"
          gradientUnits="userSpaceOnUse"
          x1={59.991}
          y1={89.017}
          x2={325.445}
          y2={89.017}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#666",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#000",
            }}
          />
        </linearGradient>
        <path
          fill="url(#Derby_svg__b)"
          d="M65.453 178.033S37.155 73.442 106.656 33.636c23.207-13.293 68.307-27.798 90.743-30.773C219.838-.114 272.69-9.521 299.868 35.302c9.931 16.377 22.853 45.355 25.578 55.846-28.391 30.96-228.522 84.89-259.993 86.885z"
          stroke="none"
        />
        <linearGradient
          id="Derby_svg__c"
          gradientUnits="userSpaceOnUse"
          x1={244.438}
          y1={131.065}
          x2={425.53}
          y2={131.065}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#616161",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#000",
            }}
          />
        </linearGradient>
        <path
          fill="url(#Derby_svg__c)"
          d="M244.438 160.7s47.327-9.36 109.211-11.81c49.457-1.958 77.889-22.487 70.89-33.051-7.007-10.565-60.72-42.811-180.101 44.861z"
          stroke="none"
        />
        <path
          d="M426.127 114.642s-74.226-84.487-308.95 139.941c-52.54 41.729-102.11 49.061-114.754 18.546 10.042 29.097 38.719 28.597 74.16 16.744 46.385-15.515 110.741-76.854 118.844-82.899 63.5-47.389 172.095-123.865 229.86-85.286 0 0 2.051-3.761.84-7.046z"
          stroke="none"
        />
      </g>
    </switch>
  </svg>
);
export default SvgDerby;
