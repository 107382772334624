import React from 'react';

import AgentModal from 'pages/settings/agents/commons/AgentModal';

const EditAgentModal = ({ selectedAgent, open, setOpen, modifyAgent }) => (
  <AgentModal
    title='Modify agent'
    open={open}
    agent={selectedAgent}
    onSubmit={(agent) => {
      modifyAgent(agent);
      setOpen(false);
    }}
    onClose={() => setOpen(false)}
  />
);

export default EditAgentModal;