import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DataFlowsListPage from './list/DataFlowsListPage';
import DataFlowDetailPage from './detail/DataFlowDetailPage';
import DataFlowEditorPage from './editor/DataFlowEditorPage';
import DataFlowsQueryParserNavbar from './commons/DataFlowsQueryParserNavbar';
import QueryParserRoutes from './queryparser/QueryParserRoutes';
import DataFlowChartPage from './flowchart/DataFlowChartPage';
import ImpactAnalysisPage from './impactanalysis/ImpactAnalysisPage';

const DataFlowsRoutes = (props) => (
  <>
    <DataFlowsQueryParserNavbar />
    <Switch>
      <Route path={`${props.match.url}/queryparser`} component={QueryParserRoutes} />
      <Route exact path={`${props.match.url}/editor/create`} component={DataFlowEditorPage} />
      <Route
        exact
        path={`${props.match.url}/editor/modify/:dataFlowUuid`}
        component={DataFlowEditorPage}
      />
      <Route
        exact
        path={`${props.match.url}/detail/:dataFlowUuid`}
        component={DataFlowDetailPage}
      />
      <Route exact path={`${props.match.url}/flowchart`} component={DataFlowChartPage} />
      <Route exact path={props.match.url} component={DataFlowsListPage} />
      <Route exact path={`${props.match.url}/impactanalysis`} component={ImpactAnalysisPage} />
    </Switch>
  </>
);

export default DataFlowsRoutes;
