import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GeneralSection from './detail/generalsection/GeneralSection';
import TableSection from './detail/generalsection/TableSection';
import AnalysisButton from './detail/runbuttonsection/AnalysisButton';
import ImpactedAssetsSection from './detail/impactedassetssection/ImpactedAssetsSection';
import ImpactedAssetsExportButton from './detail/generalsection/ImpactedAssetsExportButton';
import { EndingAssetsFilterSection } from './detail/generalsection/EndingAssetsFilterSection';

const INITIAL_EDITOR_STATE = {
  maxDepth: 10,
  columnLevel: false,
  dataCategories: [],
  logicalFields: [],
  physicalEntities: [],
  physicalFields: []
};
function ImpactAnalysisPage() {
  const { t } = useTranslation();
  const [editor, setEditor] = useState(INITIAL_EDITOR_STATE);
  const [results, setResults] = useState(null);

  return (
    <>
      <Typography style={{ marginBottom: '8px' }} variant='h4'>
        {t('dataflows.impactAnalysis.header')}
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={2} alignItems='baseline'>
            <Grid item sx={{ width: '400px' }}>
              <GeneralSection editor={editor} setEditor={setEditor} />
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <TableSection editor={editor} setEditor={setEditor} />
              <EndingAssetsFilterSection editor={editor} setEditor={setEditor} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <AnalysisButton editor={editor} setResults={setResults} />
          <Button
            children={t('commons.actions.clear')}
            onClick={() => {
              setEditor(null);
              setResults(null);
            }}
          />
          {results && <ImpactedAssetsExportButton editor={editor} />}
        </CardActions>
      </Card>

      {results && <ImpactedAssetsSection results={results} />}
    </>
  );
}

export default ImpactAnalysisPage;
