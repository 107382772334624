import * as React from "react";
const SvgEdbLong = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 652 652"
    style={{
      enableBackground: "new 0 0 652 652",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M577.6 356.8c13.8 0 19.3-4.7 19.3-14 0-8.5-6.2-14.3-19.8-14.3h-30.7v28.3h31.2zm-31.2-72.1v23.7H578c10.9 0 14.7-5.4 14.7-11.6 0-6.6-4.9-12-16.5-12h-29.8zm-22.6 94.2c-1.1 0-2.2-1.2-2.2-2.3V264.9c0-1.2 1.1-2.3 2.2-2.3h55.8c24.7 0 37.8 14 37.8 32 0 9.3-3.5 16.7-9.5 21.9v.4c8.5 5.8 13.6 15.1 13.6 27.1 0 22.3-15.3 34.9-41.4 34.9h-56.3zm-100.7-94.2v72.1h23.8c23.1 0 35.3-14 35.3-36.1s-14.9-36.1-35.6-36.1h-23.5zm-22.5 94.2c-1.1 0-2.2-1.2-2.2-2.3V264.9c0-1.2 1.1-2.3 2.2-2.3h46c34.5 0 60.4 22.7 60.4 58.2 0 35.1-21.8 58.2-60 58.2h-46.4zm-87.8-48.4v26.4h64c1.1 0 2.5 1.6 2.5 2.7v16.7c0 1.2-1.5 2.7-2.5 2.7h-86.5c-1.1 0-2.2-1.2-2.2-2.3V264.9c0-1.2 1.1-2.3 2.2-2.3h85.4c1.1 0 2.5 1.6 2.5 2.7V282c0 1.2-1.5 2.7-2.5 2.7h-62.9v23.7h57.4c1.1 0 2.5 1.6 2.5 2.7v16.7c0 1.2-1.5 2.7-2.5 2.7h-57.4z" />
    <circle
      cx={134.5}
      cy={321}
      r={112.8}
      style={{
        fill: "#ff3e00",
      }}
    />
    <path
      d="M205 298.8c0-10.9-9.1-18.8-20.3-17.7l-17.1 1.7c-1.8.2-3.3 1.7-3.3 3.5v62.3c0 1.7 1.5 3 3.3 2.8l17.1-1.7c11.2-1.1 20.3-10.8 20.3-21.7 0-5.8-2.6-10.8-6.8-14 4.1-3.9 6.8-9.4 6.8-15.2m-103.7-16-17.1-1.7c-11.2-1.1-20.3 6.8-20.3 17.7 0 5.8 2.6 11.3 6.8 15.3-4.2 3.2-6.8 8.2-6.8 14 0 10.9 9.1 20.6 20.3 21.7l17.1 1.7c1.8.2 3.3-1.1 3.3-2.8v-62.3c0-1.8-1.5-3.4-3.3-3.6m33.2 17h-17.1c-1.8 0-3.3 1.4-3.3 3.1v46.8c0 1.8 1.3 3.7 3.2 3.7h17.1c-.2 3.4-5.1 6-16.5 7.2l-1.3.1c-.8.1-2.5 0-2.5 3.2v.8c0 1.7 1.4 3.2 3.2 3.2h17.1c11.2 0 20.4-8.8 20.4-19.6v-29.1c0-10.6-9.1-19.4-20.3-19.4"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default SvgEdbLong;
