import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function QualityProbesTagModal({ onClose, title, open, project, onSubmit, tag }) {
  const { t } = useTranslation();
  const [name, setName] = useState(tag?.name || undefined);
  const [description, setDescription] = useState(tag?.description || undefined);

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          required
          fullWidth
          placeholder={'Enter the tag name'}
          label={'Name'}
          value={name || ''}
          onChange={(event) => setName(event.target.value)} />
        <TextField
          variant="standard"
          fullWidth
          placeholder={'Enter the tag description'}
          label={'Description'}
          value={description || ''}
          onChange={(event) => setDescription(event.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={!name}
          onClick={() =>
            onSubmit({
              ...tag,
              name: name,
              description: description,
              project: project
            })
          }>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QualityProbesTagModal;
