import React, { useReducer } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { t } from 'i18next';
import SystemItemAutoComplete from 'pages/systems/commons/SystemItemAutoComplete';
import PhysicalEntityItemAutoComplete from 'pages/systems/physical_entities/commons/PhysicalEntityItemAutoComplete';
import PhysicalFieldItemSelect from 'pages/systems/physical_entities/commons/PhysicalFieldItemSelect';
import PhysicalEntitySchemaAutoComplete from 'pages/systems/physical_entities/commons/PhysicalEntitySchemaAutoComplete';

export const EndingAssetsFilterModal = ({ open, onCancel, onSubmit, columnLevel }) => {
  const [editor, setEditor] = useReducer((s, a) => ({ ...s, ...a }), {});

  return (
    <Dialog open={open} fullWidth maxWidth='sm'>
      <DialogTitle>Ending Asset Filter</DialogTitle>
      <DialogContent>
        <SystemItemAutoComplete
          margin='dense'
          value={editor?.system}
          onChange={(system) => setEditor({ system })}
        />
        <PhysicalEntitySchemaAutoComplete
          fullWidth
          margin='dense'
          systemUuid={editor?.system?.uuid}
          schema={editor?.schema}
          onChange={(schema) => setEditor({ schema })}
        />
        <PhysicalEntityItemAutoComplete
          margin='dense'
          systemUuid={editor?.system?.uuid}
          value={editor?.physicalEntity}
          itemName='Physical Entity'
          onChange={(physicalEntity) => setEditor({ physicalEntity })}
        />
        {columnLevel && (
          <PhysicalFieldItemSelect
            margin='dense'
            value={editor?.physicalField}
            physicalEntityUuid={editor?.physicalEntity?.uuid}
            onChange={(physicalField) => setEditor({ physicalField })}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.cancel')} onClick={onCancel} />
        <Button
          variant='outlined'
          color='primary'
          children={t('commons.actions.apply')}
          onClick={() =>
            onSubmit({
              system: editor?.system?.name,
              physicalEntity: editor?.physicalEntity?.name,
              physicalField: editor?.physicalField?.name,
              schema: editor?.schema
            })
          }
        />
      </DialogActions>
    </Dialog>
  );
};
