import { Grid, Typography } from '@mui/material';
import { fetchWebHooksPage } from 'actions/settings/webhooks/WebHooksActions';
import PaginatedPanel from 'components/pagination/PaginatedPanel';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebHookListItem from './WebHookListItem';
import { t } from 'i18next';

const WebHooksList = ({ _props }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.settings.webHooks.currentPage);

  useEffect(() => {
    dispatch(fetchWebHooksPage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container alignItems='center' direction='row'>
        <Grid item>
          <Typography variant='h5'>{t('webHooks.list.title')}</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:web-hooks-list'}
            pageTitle={t('webHooks.list.title')}
          />
        </Grid>
      </Grid>
      <PaginatedPanel
        currentPage={currentPage}
        onPageSelection={(page) => dispatch(fetchWebHooksPage({ page }))}
        autoload>
        {currentPage.content.map((webHook) => (
          <WebHookListItem key={webHook.uuid} webHook={webHook} />
        ))}
      </PaginatedPanel>
    </>
  );
};

export default WebHooksList;
