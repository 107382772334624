import crudReducers from '../utils';
import { combineReducers } from 'redux';
import filtersReducer from '../utils/filters';

const filtersDefault = {
  hasFilters: false,
  search: null,
  dataCategoryName: null,
  nullLogicalFields: null,
  logicalFieldName: null,
  nullDataCategories: null,
  types: [],
  enabled: null,
  scopes: []
};

export default combineReducers({
  ...crudReducers({ resource: 'RULE', keyProperty: 'uuid' }),
  filters: filtersReducer({ resource: 'RULE', filtersDefault })
});
