import * as React from "react";
const SvgGoogleCloudSql = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 60 81"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="1em"
    height="1em"
    {...props}
  >
    <use xlinkHref="#GoogleCloudSQL_svg__a" x={0.5} y={0.5} />
    <symbol id="GoogleCloudSQL_svg__a" overflow="visible">
      <g stroke="none">
        <path
          d="M0 33.76V45.8l29.32 15.6V49.36L0 33.76zm0 18.6V64.4L29.32 80V67.96L0 52.36z"
          fill="#aecbfa"
        />
        <path
          d="m29.32 61.4 29.32-15.6V33.76l-29.32 15.6V61.4zm0 18.6 29.32-15.6V52.36l-29.32 15.6V80z"
          fill="#669df6"
        />
        <path d="M58.64 27.64V15.6L29.32 0v12.04l29.32 15.6z" fill="#4285f4" />
        <path d="M29.32 0 0 15.6v12.04l29.32-15.6V0z" fill="#669df6" />
        <path d="M0 15.48v12.08l29.32 15.6V31.08L0 15.48z" fill="#aecbfa" />
        <path
          d="m29.32 43.16 29.32-15.6V15.48l-29.32 15.6v12.08z"
          fill="#669df6"
        />
      </g>
    </symbol>
  </svg>
);
export default SvgGoogleCloudSql;
