import React, { useMemo, useState } from 'react';
import { Grid, IconButton, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import TableCell from 'components/tables/GenericTableCell';
import { EndingAssetsFilterModal } from './EndingAssetsFilterModal';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';

export const EndingAssetsFilterSection = ({ editor, setEditor }) => {
  const mergeArrays = (a, b) => _([]).push(a, b).flatten().uniq().compact().value();
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const hasFilters = useMemo(
    () =>
      Object.keys(editor?.endingAssetsFilters || {}).flatMap(
        (key) => editor?.endingAssetsFilters[key]
      ).length > 0,
    [editor?.endingAssetsFilters]
  );

  return (
    <>
      <Grid container alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <h4>Ending Assets filters</h4>
        </Grid>
        {hasFilters && (
          <Grid item>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => setFiltersModalOpen(true)}>
                <AddBoxIcon />
              </IconButton>
            </div>
          </Grid>
        )}
      </Grid>
      {hasFilters ? (
        <>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='left'>Type</TableCell>
                <TableCell align='left'>Value</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(editor?.endingAssetsFilters).flatMap((type) =>
                editor?.endingAssetsFilters[type].map((el) => (
                  <TableRow key={el}>
                    <TableCell>{type}</TableCell>
                    <TableCell align='left'>{el}</TableCell>
                    <TableCell align='right'>
                      <IconButton
                        size='small'
                        onClick={() =>
                          setEditor({
                            ...editor,
                            endingAssetsFilters: {
                              ...editor.endingAssetsFilters,
                              [type]: editor.endingAssetsFilters[type].filter((x) => x !== el)
                            }
                          })
                        }>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </>
      ) : (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='overline'>No filter defined yet, click the button to add</Typography>
          <IconButton onClick={() => setFiltersModalOpen(true)}>
            <AddBoxIcon />
          </IconButton>
        </div>
      )}
      {filtersModalOpen && (
        <EndingAssetsFilterModal
          columnLevel={editor?.columnLevel}
          open={filtersModalOpen}
          onCancel={() => setFiltersModalOpen(false)}
          onSubmit={(endingAssetsFilters) => {
            setEditor({
              ...editor,
              endingAssetsFilters: {
                system: mergeArrays(
                  editor?.endingAssetsFilters?.system,
                  endingAssetsFilters?.system
                ),
                schema: mergeArrays(
                  editor?.endingAssetsFilters?.schema,
                  endingAssetsFilters?.schema
                ),
                physicalEntity: mergeArrays(
                  editor?.endingAssetsFilters?.physicalEntity,
                  endingAssetsFilters?.physicalEntity
                ),
                physicalField: mergeArrays(
                  editor?.endingAssetsFilters?.physicalField,
                  endingAssetsFilters?.physicalField
                )
              }
            });
            setFiltersModalOpen(false);
          }}
        />
      )}
    </>
  );
};
