import ContractsApi from '../api/ContractsApi';
import { buildApiCallAction } from './ActionUtils';
import {
  FETCH_CONTRACT_CONFIG_PAGE,
  FETCH_CONTRACT_CONFIG,
  CREATE_CONTRACT_CONFIG,
  MODIFY_CONTRACT_CONFIG,
  DELETE_CONTRACT_CONFIG,
  EDIT_CONTRACT_CONFIG_EDITOR,
  CLEAR_CONTRACT_CONFIG_EDITOR,
  SET_FILTERS_CONTRACT_CONFIGS,
  CLEAR_FILTERS_CONTRACT_CONFIGS
} from '../constants/ActionTypes';

let api = new ContractsApi();

export const fetchContractConfigurationsPage = (page, size) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api, getState) => {
      let filters = getState().contracts.configurations.filters;
      return api.getContractConfigurations(page, size, {
        includeProperties: 'false',
        ...filters
      });
    },
    actionType: FETCH_CONTRACT_CONFIG_PAGE
  });

export const fetchContractConfigurations = ({ page, size, ...others }) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => {
      return api.getContractConfigurations(page, size, {
        includeProperties: 'false',
        ...others
      });
    },
    actionType: FETCH_CONTRACT_CONFIG_PAGE
  });

export const fetchContractConfiguration = (uuid) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getContractConfiguration(uuid),
    actionType: FETCH_CONTRACT_CONFIG
  });

export const createContractConfiguration = (contractConfiguration) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.postContractConfiguration(contractConfiguration),
    actionType: CREATE_CONTRACT_CONFIG
  });

export const deleteContractConfiguration = (contractConfiguration) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.deleteContractConfiguration(contractConfiguration),
    actionType: DELETE_CONTRACT_CONFIG,
    payload: contractConfiguration
  });

export const modifyContractConfiguration = (contractConfiguration) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.putContractConfiguration(contractConfiguration),
    actionType: MODIFY_CONTRACT_CONFIG
  });

export const setContractConfigurationFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_FILTERS_CONTRACT_CONFIGS, payload: filters });
};

export const clearContractConfigurationsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_FILTERS_CONTRACT_CONFIGS });
};

export const editContractConfiguration = (item) => (dispatch, getState) => {
  dispatch({
    type: EDIT_CONTRACT_CONFIG_EDITOR,
    payload: item
  });
};

export const clearContractConfigurationEditor = () => (dispatch, getState) => {
  dispatch({
    type: CLEAR_CONTRACT_CONFIG_EDITOR
  });
};

export const loadContractConfigurationEditor = (uuid) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getContractConfiguration(uuid),
    actionType: EDIT_CONTRACT_CONFIG_EDITOR
  });

export const copyContractConfigurationEditor = (uuid) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getContractConfiguration(uuid),
    actionType: EDIT_CONTRACT_CONFIG_EDITOR,
    payload: (data) => ({ ...data, uuid: null, active: false })
  });
