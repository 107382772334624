import { sendError } from 'actions/settings/ErrorLoggingActions';
import React from 'react';
import { connect } from 'react-redux';
import './ErrorBoundary.css';

const ERROR_IN_SESSION = 'ErrorBoundary.ERROR_IN_SESSION';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    // You can also log the error to an error reporting service
    if (!window.sessionStorage.getItem(ERROR_IN_SESSION)) {
      this.props.sendError({
        url: window.location.href,
        errorInfo: error.message,
        uiVersion: document.querySelector('meta[name="react_app_version"]')?.content
      });
      window.sessionStorage.setItem(ERROR_IN_SESSION, true);
    }
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ textAlign: 'center' }}>
          <p>Something went wrong.</p>
          <p>Please refresh the page.</p>
          <i id='bug-icon' className='fa fa-bug'></i>
        </div>
      );
    }
    return this.props.children;
  }
}

export default connect(null, { sendError })(ErrorBoundary);
