import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, Grid, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';

import Breadcrumbs from 'pages/sidebar/Navigation';

import RoutineFAB from '../commons/RoutineFAB';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

import {
  fetchRoutinesPage,
  modifyRoutine,
  createRoutine,
  deleteRoutine
} from 'actions/RoutinesActions';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import RoutinesTable from './RoutinesTable';
import { useHistory } from 'react-router';
import RoutinesListToolbox from './RoutinesListToolbox';
import useRoutePagination from 'components/pagination/useRoutePagination';

function RoutinesListPage({ filters, currentPage, fetchRoutinesPage }) {
  const { changePagination } = useRoutePagination({
    currentPage,
    onPageSelection: (page, size) => {
      fetchRoutinesPage({ page, size, ...filters });
    },
    dependencies: [fetchRoutinesPage, filters]
  });
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.physical_model'), to: '/systems' },
          { text: t('navbar.routines') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('routines.header'),
          category: EngagementCategories.PHYSICAL_MODEL,
          action: EngagementActions.LIST
        }}
      />
      <RoutinesListHeaders t={t} />
      <RoutinesListPageCard currentPage={currentPage} changePagination={changePagination} />
      <Authorize hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
        <RoutineFAB system={filters?.system} />
      </Authorize>
    </>
  );
}

function RoutinesListHeaders({ t }) {
  return (
    <>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Typography variant='h4'>{t('routines.header')}</Typography>
        </Grid>
        <Grid item>
          <RoutinesListToolbox />
        </Grid>
      </Grid>
    </>
  );
}
function RoutinesListPageCard({ currentPage, changePagination }) {
  const history = useHistory();

  return (
    <Card style={{ overflowX: 'auto' }}>
      <CardContent>
        <RoutinesTable
          handleDetail={(uuid) => history.push(`/routines/${uuid}`)}
          routines={currentPage.content}></RoutinesTable>
        <TablePagination
          component='div'
          style={{ overflowX: 'hidden' }}
          rowsPerPageOptions={[25, 50, 100]}
          rowsPerPage={currentPage.size}
          page={currentPage.number}
          count={currentPage.totalElements}
          onPageChange={(event, page) => changePagination({ page })}
          onRowsPerPageChange={(event) =>
            changePagination({
              size: event.target.value
            })
          }
        />
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  currentPage: state.systemRoutines.currentPage,
  filters: state.systemRoutines.filters.content
});

export default connect(mapStateToProps, {
  fetchRoutinesPage,
  modifyRoutine,
  createRoutine,
  deleteRoutine
})(RoutinesListPage);
