import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import { Link } from 'react-router-dom';

export default function IssuePolicyLinkButton() {
  return (
    <Tooltip title={'Show Policies'}>
      <IconButton size='small' component={Link} to={`/issue-management/policies`}>
        <PolicyIcon></PolicyIcon>
      </IconButton>
    </Tooltip>
  );
}
