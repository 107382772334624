import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Tooltip,
  IconButton,
  Typography
} from '@mui/material';

import { AddBox } from '@mui/icons-material';
import RequestTemplateHeadersTable from './RequestTemplateHeadersTable';
import RequestTemplateHeadersModal from './RequestTemplateHeadersModal';

function RequestTemplateSection({ editor, setEditor }) {
  const methods = ['POST', 'PUT', 'DELETE', 'GET', 'PATCH'];
  return (
    <div>
      <h4>Request Template</h4>
      <HeadersSection editor={editor} setEditor={setEditor} />
      <FormControl variant='standard' fullWidth margin='dense' required>
        <InputLabel id='issue-type-select-label'>Method</InputLabel>
        <Select
          required
          variant='standard'
          labelId='issue-type-select-label'
          id='issue-type-select'
          value={editor?.requestTemplate?.method || ''}
          onChange={(event) =>
            setEditor({
              requestTemplate: { ...editor?.requestTemplate, method: event.target.value }
            })
          }>
          {methods.map((method) => (
            <MenuItem key={method} value={method}>
              {method}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        variant='standard'
        required
        fullWidth
        label='Url'
        margin='dense'
        placeholder='Connection Url'
        value={editor?.requestTemplate?.url || ''}
        onChange={(event) =>
          setEditor({
            requestTemplate: { ...editor.requestTemplate, url: event.target.value }
          })
        }
      />
      <TextField
        variant='standard'
        multiline
        fullWidth
        label='Body'
        margin='dense'
        placeholder='Body'
        value={editor?.requestTemplate?.body || ''}
        onChange={(event) =>
          setEditor({
            requestTemplate: { ...editor?.requestTemplate, body: event.target.value }
          })
        }
      />
    </div>
  );
}

function HeadersSection({ editor, setEditor }) {
  return (
    <Grid container alignItems='center'>
      <Typography>Headers</Typography>
      <HeaderAddButton editor={editor} setEditor={setEditor} />

      {editor?.requestTemplate?.headers &&
        Object.keys(editor?.requestTemplate?.headers).length > 0 && (
          <RequestTemplateHeadersTable editor={editor} setEditor={setEditor} />
        )}
    </Grid>
  );
}

function HeaderAddButton({ editor, setEditor }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title={'Add a header'}>
        <IconButton onClick={() => setOpen(true)} size='small'>
          <AddBox></AddBox>
        </IconButton>
      </Tooltip>
      {open && (
        <RequestTemplateHeadersModal
          title='Add an header'
          open={open}
          handleSubmit={(header) => {
            setEditor({
              requestTemplate: {
                ...editor?.requestTemplate,
                headers: {
                  ...editor?.requestTemplate?.headers,
                  [header.key]: header.value
                }
              }
            });
            setOpen(false);
          }}
          handleCancel={() => setOpen(false)}
        />
      )}
    </>
  );
}

export default RequestTemplateSection;
