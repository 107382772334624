import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Menu,
  MenuItem
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import StewardshipAuthorize from 'components/permissions/StewardshipAuthorize';
import { modifyRoutine, deleteRoutine } from 'actions/RoutinesActions';
import { useHistory } from 'react-router-dom';

import ResourceTypes from 'constants/ResourceTypes';
import Permissions from 'constants/Permissions';
import RoutineModal from '../commons/RoutineModal';
import { connect } from 'react-redux';

function RoutinesTableActions({ routine, modifyRoutine, deleteRoutine }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleShowDetails = (event) => {
    setAnchorEl(null);
    history.push(`/routines/${routine.uuid}`);
    event.stopPropagation();
  };
  const handleDelete = () => {
    setAnchorEl(null);
    deleteRoutine(routine);
  };
  return (
    <>
      <IconButton
        size='small'
        aria-haspopup='true'
        aria-controls='physical-entities-table-actions'
        onClick={(event) => handleOpen(event)}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(event) => {
          setAnchorEl(null);
          event.stopPropagation();
        }}>
        <MenuItem onClick={(event) => handleShowDetails(event)}>
          {t('commons.actions.detail')}
        </MenuItem>
        <StewardshipAuthorize
          resourceType={ResourceTypes.SYSTEM_ROUTINE}
          resourceIdentifier={routine.uuid}
          hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
          <MenuItem
            onClick={(event) => {
              setOpen(true);
              event.stopPropagation();
            }}>
            {t('commons.actions.modify')}
          </MenuItem>
        </StewardshipAuthorize>
        <StewardshipAuthorize
          resourceType={ResourceTypes.SYSTEM_ROUTINE}
          resourceIdentifier={routine.uuid}
          hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
          <MenuItem
            onClick={(event) => {
              setOpenDelete(true);
              event.stopPropagation();
            }}>
            {t('commons.actions.delete')}
          </MenuItem>
        </StewardshipAuthorize>
      </Menu>
      {
        <RoutineModal
          title='Modify Routine'
          open={open}
          key={routine}
          handleSubmit={(routine) => {
            setAnchorEl(null);
            modifyRoutine(routine);
            setOpen(false);
          }}
          handleCancel={() => setOpen(false)}
          routine={routine}
        />
      }
      {openDelete && (
        <DeleteDialog
          onSubmit={() => handleDelete()}
          onCancel={() => setOpenDelete(false)}
          open={openDelete}></DeleteDialog>
      )}
    </>
  );
}

function DeleteDialog({ onCancel, onSubmit, open }) {
  return (
    <>
      <Dialog onClick={(event) => event.stopPropagation()} open={open} onClose={onCancel}>
        <DialogContent>
          <DialogContentText>{'Do you really want to delete this routine?'}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>No</Button>
          <Button color='primary' onClick={() => onSubmit()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default connect(null, { modifyRoutine, deleteRoutine })(RoutinesTableActions);
