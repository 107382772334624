import _ from 'lodash';

export class NavigableResultsCollection {
  constructor(values) {
    this.values = values || [];
  }

  getIndexOfItem(item) {
    return _.findIndex(this.values, (el) => _.isEqual(item, el));
  }

  nextItem(selectedItem) {
    const currentIndex = this.getIndexOfItem(selectedItem);
    if (currentIndex < this.values.length - 1) {
      return this.values[currentIndex + 1];
    } else return selectedItem;
  }

  prevItem(selectedItem) {
    const currentIndex = this.getIndexOfItem(selectedItem);
    if (currentIndex > 0) {
      return this.values[currentIndex - 1];
    } else return selectedItem;
  }

  getFirst() {
    return this.values[0];
  }
}
