import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CampaignStatusType from 'pages/issueMngt/campaigns/detail/CampaignStatusType';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import DatePicker, { hourAdjustments } from 'components/pickers/DatePicker';
import UsersInCurrentTenantItemAutocomplete from 'pages/settings/users/commons/UsersInCurrentTenantItemAutocomplete';
import { useSelector } from 'react-redux';

const INITIAL_STATE = ({ currentUser }) => ({
  name: '',
  description: '',
  startDate: null,
  endDate: null,
  status: CampaignStatusType.ACTIVE,
  private: false,
  owner: currentUser,
  creator: currentUser
});

function CampaignEditModal({ open, title, campaign, handleCancel, handleSubmit }) {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.settings.profile);
  const statesValues = [CampaignStatusType.ACTIVE, CampaignStatusType.NONACTIVE];

  const [editor, setEditor] = useState(campaign || INITIAL_STATE({ currentUser }));

  return (
    <>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{title || 'Create a campaign'}</DialogTitle>
        <DialogContent>
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder="Enter the campaign's name"
            value={editor.name || ''}
            label='Name'
            required={true}
            onChange={(event, name) => setEditor({ ...editor, name: event.target.value })}
          />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            multiline
            value={editor.description || ''}
            placeholder="Enter the campaign's description"
            label='Description'
            onChange={(event, newValue) =>
              setEditor({ ...editor, description: event.target.value })
            }
          />
          <UsersInCurrentTenantItemAutocomplete
            itemName={'Owner'}
            value={editor.owner}
            onChange={(owner) => setEditor({ ...editor, owner })}
          />
          <UsersInCurrentTenantItemAutocomplete
            itemName={'Creator'}
            value={editor.creator}
            onChange={(creator) => setEditor({ ...editor, creator })}
          />
          <Grid container spacing={2}>
            <Grid item>
              <DatePicker
                maxDate={editor.endDate || undefined}
                margin='dense'
                label={'Start Date'}
                hintText={'Start Date'}
                hourAdjustment={hourAdjustments.startOfDay}
                value={editor.startDate || null}
                onChange={(date) => setEditor({ ...editor, startDate: date })}
              />
            </Grid>
            <Grid item>
              <DatePicker
                minDate={editor.startDate || undefined}
                margin='dense'
                label={'End Date'}
                hintText={'End Date'}
                hourAdjustment={hourAdjustments.endOfDay}
                value={editor.endDate || null}
                onChange={(date) => setEditor({ ...editor, endDate: date })}
              />
            </Grid>
          </Grid>

          <InputLabel shrink id='account-team-selector-label'>
            Status
          </InputLabel>
          <Select
            variant='standard'
            labelId='account-team-selector-label'
            id='account-team-selector'
            value={editor.status || ''}
            onChange={(event) => setEditor({ ...editor, status: event.target.value })}>
            {statesValues.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
          <br></br>
          <FormControlLabel
            control={
              <Checkbox
                size='small'
                checked={editor.private}
                onChange={(event) => setEditor({ ...editor, private: event.target.checked })}
                name='enabled'
                color='primary'
              />
            }
            label='Managed by owner'
          />
          <DialogActions>
            <Button children={t('commons.actions.cancel')} onClick={handleCancel}></Button>
            <Button
              children={t('commons.actions.submit')}
              variant='contained'
              color='primary'
              disabled={!editor.name || (editor.private && !editor.owner)}
              onClick={() => {
                handleSubmit(editor);
                handleCancel();
              }}></Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CampaignEditModal;
