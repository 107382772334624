import { buildApiCallAction } from './ActionUtils';
import {
  FETCH_ISSUES_PAGE,
  FETCH_ISSUE,
  CREATE_ISSUE,
  MODIFY_ISSUE,
  DELETE_ISSUE,
  SET_ISSUES_FILTERS,
  CLEAR_ISSUES_FILTERS,
  EXPORT_ISSUES
} from 'constants/ActionTypes';
import IssuesApi from 'api/IssuesApi';

const api = new IssuesApi();

export const exportIssues = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => {
      let apiParams = createApiParamsFrom(params);
      return api.exportIssues({ ...apiParams });
    },
    actionType: EXPORT_ISSUES
  });
export const fetchIssuesPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => {
      let apiParams = createApiParamsFrom(params);
      return api.getIssues({ page: params?.page, size: params?.size, ...apiParams });
    },
    actionType: FETCH_ISSUES_PAGE
  });

export const fetchMyIssues = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getMyIssues(params),
    actionType: FETCH_ISSUES_PAGE
  });

export const fetchIssue = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getIssue(uuid),
    actionType: FETCH_ISSUE
  });

export const createIssue = (issue) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postIssue(issue),
    actionType: CREATE_ISSUE
  });

export const modifyIssue = (issue) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putIssue(issue),
    actionType: MODIFY_ISSUE
  });

export const patchIssue = (issueUuid, patch) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.patchIssue(issueUuid, patch),
    actionType: MODIFY_ISSUE
  });

export const deleteIssue = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteIssue(uuid),
    actionType: DELETE_ISSUE,
    payload: { uuid }
  });

export const setIssuesFilters = (filters) => (dispatch) => {
  dispatch({
    type: SET_ISSUES_FILTERS,
    payload: filters
  });
};

export const clearIssuesFilters = () => (dispatch) => {
  dispatch({
    type: CLEAR_ISSUES_FILTERS
  });
};

function createApiParamsFrom(filters) {
  let apiParams = {
    ...filters,
    assigneeUuid: filters?.assignee && filters.assignee.map((user) => user.uuid),
    campaignUuid:
      filters.campaignUuid ||
      (filters?.campaigns && filters.campaigns.map((campaign) => campaign.uuid)),
    assigneeTeamCodes: filters?.teamCodes
  };
  delete apiParams.assignee;
  delete apiParams.campaigns;
  delete apiParams.teamCodes;
  return apiParams;
}
