import { BlindataApi } from './ApiUtils';
export default class SystemsApi extends BlindataApi {
  token = '';

  getSystems(params) {
    return this.get(`/api/v1/systems`, params);
  }

  getSystem(uuid) {
    return this.get(`/api/v1/systems/${uuid}`);
  }

  postSystem(system) {
    return this.post(`/api/v1/systems`, system);
  }

  putSystem(system) {
    return this.put(`/api/v1/systems/${system.uuid}`, system);
  }

  deleteSystem(system) {
    return this.delete(`/api/v1/systems/${system.uuid}`);
  }

  exportSystems(params) {
    return this.download(`/api/v1/systems/*/export`, { ...params, includeProperties: 'true' });
  }
}
