import { grey } from '@mui/material/colors';
import { createTheme, adaptV4Theme } from '@mui/material/styles';

export const muiThemeV1 = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#00aeca',
        contrastText: '#ffffff'
      },
      secondary: {
        main: grey[800]
      },
      grey: {
        main: grey[700],
        dark: grey[900]
      }
    },
    props: {
      MuiCheckbox: {
        color: 'primary'
      },
      MuiTabs: {
        indicatorColor: 'primary'
      },
      MuiChip: {
        color: 'primary'
      },
      MuiButton: {
        color: 'secondary'
      }
    },
    overrides: {
      MuiChip: {
        avatar: {
          color: '#ffffff',
          backgroundColor: '#00aeca'
        }
      },
      MuiCard: {
        root: {
          margin: '8px 0 8px 0'
        }
      }
    },
    typography: {
      description: {
        whiteSpace: 'pre-wrap'
      }
    }
  })
);
