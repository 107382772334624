import React, { useState } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { IconButton, Tooltip } from '@mui/material';
import AssetsAddModal from './AssetsAddModal';

function AssetsAddButton({ editor, setEditor }) {
  let [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title={'Add an asset'}>
        <IconButton onClick={() => setOpen(true)} size='small'>
          <AddBoxIcon></AddBoxIcon>
        </IconButton>
      </Tooltip>
      {open && (
        <AssetsAddModal
          open={open}
          setOpen={setOpen}
          editor={editor}
          setEditor={setEditor}></AssetsAddModal>
      )}
    </>
  );
}

export default AssetsAddButton;
