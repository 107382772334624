import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchScopes } from 'actions/DataFlowsActions';
import AutoComplete from 'components/autocomplete/AutoComplete';

function DataFlowScopeAutoComplete({ fetchScopes, fullWidth, margin, scope, onChange }) {
  const [scopes, setScopes] = useState([]);

  useEffect(() => {
    fetchScopes({ search: '' }).then((response) => response && setScopes(response.content));
  }, [fetchScopes, setScopes]);

  return (
    <AutoComplete
      fullWidth={fullWidth}
      margin={margin}
      label='Scope'
      value={scope}
      dataSource={scopes}
      onUpdateInput={onChange}
      onNewRequest={onChange}
    />
  );
}

export default connect(null, { fetchScopes })(DataFlowScopeAutoComplete);
