import React from 'react';
import { connect } from 'react-redux';

import { exportProjects } from 'actions/quality/probes/ProbesProjectsActions';

import { Tooltip, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';

const QualityProbesProjectsExportButton = ({ exportProjects }) => {
  return (
    <Tooltip title='Export CSV'>
      <IconButton onClick={() => exportProjects()} size='medium'>
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default connect(null, { exportProjects })(QualityProbesProjectsExportButton);
