import React from 'react';
import { Grid, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';

import TableCell from 'components/tables/GenericTableCell';
import ErrorIcon from '@mui/icons-material/HighlightOff';
import OkIcon from '@mui/icons-material/CheckCircleOutline';
import { Warning } from '@mui/icons-material';

function ClassificationAgentJobResultsTable({ result }) {
  let { errors, results } = result;
  let resultsRows = results?.length > 0 ? [results[0]] : [];
  return (
    <>
      <Grid container direction='column' spacing={2}>
        {errors?.length > 0 && (
          <>
            <Grid item>
              <h4 style={{ marginBottom: 0 }}>Errors</h4>
            </Grid>
            <Grid item>
              <TableContainer style={{ maxHeight: '300px' }}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Error</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {errors.map((entry) => (
                      <TableRow key={entry.uuid}>
                        <TableCell>
                          <ErrorIcon style={{ color: 'red' }} />
                        </TableCell>
                        <TableCell>{entry.error || '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
        {resultsRows?.length > 0 && (
          <>
            <Grid item>
              <h4 style={{ marginBottom: 0 }}>Results</h4>
            </Grid>
            <Grid item>
              <TableContainer style={{ maxHeight: '300px' }}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Assignments</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Updated</TableCell>
                      <TableCell>Discarded</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {results.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {!row.rowDiscarded ? (
                            <OkIcon style={{ color: 'green' }} />
                          ) : (
                            <Warning style={{ color: 'orange' }} />
                          )}
                        </TableCell>
                        <TableCell>{row.rowCreated || '-'}</TableCell>
                        <TableCell>{row.rowUpdated || '-'}</TableCell>
                        <TableCell>{row.rowDiscarded || '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default ClassificationAgentJobResultsTable;
