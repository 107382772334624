import React, { useEffect } from 'react';
import { fetchQualityResultsAreaChart } from '../../../../../actions/QualityDashboardsActions';
import { connect } from 'react-redux';
import { Card, CardHeader, Typography } from '@mui/material';
import { Chart } from 'react-google-charts';

function QualityResultsDashboardTab({
  qualityCheck,
  headers,
  values,
  filters,
  fetchQualityResultsAreaChart
}) {
  useEffect(
    function () {
      fetchQualityResultsAreaChart({ checkUuid: qualityCheck.uuid, ...filters });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, fetchQualityResultsAreaChart]
  );
  const headersLabel = headers.map((h) => h.label);
  const data = [headersLabel, ...values.map((r) => [new Date(r[0]), r[1], r[2]])];

  const chartOptions = {
    isStacked: 'false',
    legend: { position: 'right' },
    hAxis: { textPosition: 'none', title: 'Dates', titleTextStyle: { italic: false } },
    vAxis: { title: 'Volumes', minValue: 0, titleTextStyle: { italic: false } },
    series: { 0: { color: '#00AECA' }, 1: { color: '#455a64' } },
    color: ['#00AECA'],
    width: '100%'
  };

  if (data.length <= 1) {
    return (
      <Typography variant='subtitle2' align='center' style={{ marginTop: '36px' }}>
        No data
      </Typography>
    );
  } else {
    return (
      <Card style={{ margin: '8px 0 8px 0' }}>
        <CardHeader title='Check Results Over Time'></CardHeader>
        <Chart
          width='100%'
          chartType='AreaChart'
          loader={<div>Loading Chart</div>}
          data={data}
          options={chartOptions}
        />
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    headers: state.quality.qualityDashboards.metricsVolumes.columns,
    values: state.quality.qualityDashboards.metricsVolumes.rows,
    filters: state.quality.qualityResults.filters.content
  };
}

export default connect(mapStateToProps, { fetchQualityResultsAreaChart })(
  QualityResultsDashboardTab
);
