import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardActions, CardHeader, CardContent, Button, Grid } from '@mui/material';

import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import DefinitionList from '../../../components/definition-list/DefinitionList';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';
import { withLoader } from '../../../components/loader/Loader';

import { connect } from 'react-redux';
import {
  fetchProcessing,
  deleteProcessing,
  modifyProcessing
} from '../../../actions/ProcessingsActions';
import { Typography } from '@mui/material';

import Permissions from '../../../constants/Permissions';
import { withOpenCloseHandler } from '../../../components/openclosehandler/OpenCloseHandler';
import ProcessingEditorModal from '../form/ProcessingEditorModal';
import HistoryButtonLink from '../../history/commons/HistoryButtonLink';
import HistoryTargetTypes from '../../history/commons/HistoryTargetTypes';
import TeamCodeDecorator from '../../settings/teams/common/TeamCodeDecorator';
import {
  ProposalResourceTypes,
  ProposalVariationTypes
} from '../../workflows/proposals/commons/ProposalConstants';
import { ProposalConfirmationButton } from '../../workflows/proposals/commons/withProposalConfirmation';
import CommentsPanel from '../../social/comments/CommentsPanel';
import ResourceTypes from '../../../constants/ResourceTypes';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';
import AuthorizeTeam from 'components/permissions/AuthorizeTeam';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import TasksAndProcessorsPanel from 'pages/tasks/utils/panel/TasksAndProcessorsPanel';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import CardDescription from 'components/carddescription/CardDescription';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';
const ProcessingDetail = (props) => {
  if (!props.processing) return null;
  return (
    <div>
      <Breadcrumbs
        elements={[{ text: 'Processings', to: '/processings' }, { text: props.processing.name }]}
      />
      <Tracking
        info={{
          pageTitle: ` Processing: ${props.processing.name}`,
          category: EngagementCategories.PROCESSINGS,
          action: EngagementActions.DETAIL
        }}
      />

      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            Processing: {props.processing.name}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`${ResourceTypes.PROCESSING}:${props?.processing?.uuid}`}
            resourceIdentifier={props?.processing?.uuid}
            resourceType={ResourceTypes.PROCESSING}
            resourceName={props?.processing?.name}
            pageTitle={` Processing: ${props?.processing?.name}`}></FavoritesWidget>
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.PROCESSING}
            resourceIdentifier={props.processing?.uuid}
            resourceName={props.processing?.name}
          />
        </Grid>
      </Grid>
      <Card>
        <CardHeader
          title={props.processing.name}
          subheader={props.processing.category}
          action={
            props.processing && (
              <TeamCodeDecorator teamCode={props.processing.teamCode}></TeamCodeDecorator>
            )
          }
        />
        <CardContent>
          <CardDescription>{props.processing.description}</CardDescription>
          <div className='dls-wrapper'>
            <KVDefinitionList
              header='General'
              item={{
                Uuid: propValue('processing.uuid', props),
                Name: propValue('processing.name', props),
                Category: propValue('processing.category', props),
                'Legal Basis': propValue('processing.legalBasis', props),
                'Created At': propValue('processing.createdAt', props),
                'Updated At': propValue('processing.updatedAt', props)
              }}
            />
            <KVDefinitionList
              header='Actors'
              item={{
                Controller: propValue('processing.controller.name', props),
                Representative: propValue('processing.representative.name', props),
                DPO: propValue('processing.dataProtectionOfficer.name', props),
                'Joint Controllers':
                  (props.processing.jointControllers || []).map((c) => c.name).join(', ') ||
                  props.t('commons.placeholders.undefined')
              }}
            />
            {props.processing.startDate ||
            props.processing.endDate ||
            props.processing.consentDuration ? (
              <KVDefinitionList
                header='Life cycle'
                item={{
                  'Start date': props.processing.startDate,
                  'End date': props.processing.endDate,
                  'Consent Duration': `${props.processing.consentDuration || 'Not defined'} ${
                    props.processing.consentDurationUnit || ''
                  }`
                }}
              />
            ) : null}

            {optionalProperties(props.processing)}

            <CustomPropertiesView
              additionalProperties={props.processing.additionalProperties}
              resourceType={ResourceTypes.PROCESSING}
            />
          </div>

          <h4>{props.t('data_categories.header')}</h4>
          <ChipWrapper>
            {props.processing.associatedDataCategories
              .map((adc) => adc.dataCategory)
              .map((dc) => (
                <ChipItem
                  key={dc.uuid}
                  id={dc.uuid}
                  label={dc.name}
                  tooltip={dc.description}
                  onClick={() => props.history.push(`/datacategories/${dc.uuid}`)}
                />
              ))}
          </ChipWrapper>
        </CardContent>
        <CardActions>
          <AuthorizeTeam
            hasAnyPermissions={[Permissions.PROCESSINGS_EDITOR, Permissions.PROPOSE]}
            teamCode={props.processing.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE, TeamAccessLevels.PROPOSE]}>
            <ModifyButton
              processing={props.processing}
              modifyProcessing={props.modifyProcessing}
              t={props.t}
            />
          </AuthorizeTeam>
          <ProposalConfirmationButton
            AuthorizeProps={{
              hasPermissions: [Permissions.PROCESSINGS_EDITOR],
              teamCode: props.processing.teamCode,
              teamAccessLevels: [TeamAccessLevels.WRITE, TeamAccessLevels.PROPOSE]
            }}
            message={props.t('processings.deleteConfirmationMessage', {
              processingName: props.processing.name
            })}
            ProposeButtonProps={{
              resourceName: props.processing.name,
              resourceType: ProposalResourceTypes.PROCESSING,
              resourceIdentifier: props.processing.uuid,
              variationType: ProposalVariationTypes.DELETE
            }}
            onClick={() =>
              props
                .deleteProcessing(props.processing)
                .then(() => props.history.replace('/processings'))
            }>
            {props.t('processings.actions.delete')}
          </ProposalConfirmationButton>
          <Button component={Link} to={linkToDisclaimers(props.processing)}>
            Disclaimers
          </Button>
          <HistoryButtonLink
            targetType={HistoryTargetTypes.PROCESSING}
            targetIdentifier={props.processing.uuid}></HistoryButtonLink>
          <div style={{ marginLeft: 'auto' }}>
            <WebActionsButton
              resource={props.processing}
              page={TargetPages.PROCESSING_DETAIL_PAGE}
            />
          </div>
        </CardActions>
      </Card>
      <TasksAndProcessorsPanel processing={props.processing}></TasksAndProcessorsPanel>
      <CommentsPanel
        resourceType='PROCESSING'
        resourceIdentifier={props.processing.uuid}></CommentsPanel>
    </div>
  );
};

const ModifyButton = withOpenCloseHandler(
  ({ processing, isOpen, onToggleOpen, modifyProcessing, t }) => (
    <>
      <Button children={t('tasks.actions.modify')} onClick={onToggleOpen} />
      <ProcessingEditorModal
        key={processing.uuid}
        title={t('processings.modifyTitle')}
        open={isOpen}
        processing={processing}
        handleSubmit={(processing) => {
          onToggleOpen();
          modifyProcessing(processing);
        }}
        handleCancel={onToggleOpen}
      />
    </>
  )
);

function optionalProperties(processing) {
  return [
    purposesComponent(processing),
    securityMeasuresComponent(processing),
    recipientsComponent(processing),
    transferToOrganizationsComponent(processing)
  ].filter((component) => component);
}

function linkToDisclaimers(processing) {
  return {
    pathname: `/processings/${processing.uuid}/disclaimers`
  };
}

function propValue(keyChain, props) {
  let keys = keyChain.split('.');
  let value = keys.reduce((acc, key) => (acc ? acc[key] : acc), props);
  return value || 'Not defined';
}

function securityMeasuresComponent(processing) {
  let securityMeasures = processing.securityMeasures;
  if (securityMeasures && securityMeasures.length > 0) {
    return (
      <DefinitionList
        header={'Security Measures'}
        items={processing.securityMeasures}
        labelProperty={'name'}
        dataProperties={['description']}
      />
    );
  }
}

function recipientsComponent(processing) {
  let recipients = processing.recipients;
  if (recipients && recipients.length > 0) {
    return (
      <DefinitionList
        header={'Recipients'}
        items={recipients.map((r) => ({
          recipientCategory: r.recipientCategory,
          recipientDescription: r.recipientDescription,
          diffusionMode: `Diffusion mode: ${r.diffusionMode}`
        }))}
        labelProperty='recipientCategory'
        dataProperties={['recipientDescription', 'diffusionMode']}
      />
    );
  }
}

function transferToOrganizationsComponent(processing) {
  let organizations = processing.transferExtraEuOrganizations;
  if (organizations && organizations.length > 0) {
    return (
      <DefinitionList
        header={'Transfer to Extra EU Organization'}
        items={organizations.map((transfer) => ({
          label: transfer.organization.name,
          d1: `country: ${transfer.organization.country || 'Not defined'}`,
          d2: `safeguard type: ${transfer.safeguardType || 'Not defined'}`,
          d3: `link to docs: ${transfer.linkToDocs || 'Not defined'}`
        }))}
        labelProperty={'label'}
        dataProperties={['d1', 'd2', 'd3']}
      />
    );
  }
}

function purposesComponent(processing) {
  let purposes = processing.purposes;
  if (purposes && purposes.length > 0) {
    return (
      <DefinitionList
        header={'Purposes'}
        items={processing.purposes}
        labelProperty='name'
        dataProperties={['description']}
      />
    );
  }
}

function mapStateToProps(state, props) {
  return {
    processingUuid: props.match.params['processingUuid'],
    processing: state.processings.contentByUuid[props.match.params['processingUuid']]
  };
}

export default _.flow([
  withTranslation(),
  withLoader({
    onLoad: ({ fetchProcessing, processingUuid }) => {
      fetchProcessing(processingUuid);
    },
    ready: ({ processing }) => processing
  }),
  connect(mapStateToProps, { fetchProcessing, deleteProcessing, modifyProcessing })
])(ProcessingDetail);
