import React from 'react';
import { useTranslation } from 'react-i18next';

import ResourceTypes from 'constants/ResourceTypes';

import { TextField } from '@mui/material';
import ItemSelectField from 'components/itemselector/ItemSelectField';
import AdditionalPropertiesEditor from 'components/additional_properties/AdditionalPropertiesEditor';

const IssueTemplateConfigurationMoreFieldsSection = ({
  issueEstimate,
  resolutionTimePeriod,
  resolutionTimeTemporalUnit,
  additionalProperties,
  handleChange
}) => {
  const { t } = useTranslation();
  const timeUnits = ['YEARS', 'MONTHS', 'DAYS', 'HOURS'].map((tu) => ({
    val: tu,
    name: t('commons.timeunits.values.' + tu)
  }));

  return <>
    <TextField
      variant="standard"
      fullWidth
      margin='dense'
      label='Estimate'
      placeholder="Enter the issue's estimate"
      type='number'
      value={issueEstimate === 0 || issueEstimate ? issueEstimate : ''}
      inputProps={{ min: 0, step: 'any' }}
      onChange={(event) => handleChange({ estimate: event.target.value })} />
    <TextField
      variant="standard"
      fullWidth
      margin='dense'
      label='Resolution time SLA - Time period'
      placeholder={'Enter the time period (integer number)'}
      type='number'
      value={resolutionTimePeriod === 0 || resolutionTimePeriod ? resolutionTimePeriod : ''}
      inputProps={{ min: 1, step: 1 }}
      onChange={(event) => handleChange({ timePeriod: event.target.value })} />
    <ItemSelectField
      fullWidth
      margin='dense'
      itemName='Resolution time SLA - Temporal unit'
      value={timeUnits.find((e) => e.val === resolutionTimeTemporalUnit)}
      values={timeUnits}
      keyProperty='val'
      nameProperty='name'
      onChange={(obj) => handleChange({ temporalUnit: obj && obj.val })}
    />
    <AdditionalPropertiesEditor
      resourceType={ResourceTypes.ISSUE}
      additionalProperties={additionalProperties}
      onChange={(newList) => handleChange({ additionalProperties: newList })}
    />
  </>;
};

export default IssueTemplateConfigurationMoreFieldsSection;
