import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';

const HistoryRecordMetadataCardView = ({ record }) => {
  return (
    <Card>
      <CardHeader title='History Record'></CardHeader>
      <CardContent>
        <KVDefinitionList item={record}></KVDefinitionList>
      </CardContent>
    </Card>
  );
};

export default HistoryRecordMetadataCardView;
