import { Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { PhysicalEntityName, PhysicalFieldName } from './PhysicalConstraintListItemNames';

export const PhysicalConstraintTargetNames = ({ physicalConstraint, variant = 'body1' }) => {
  if (!physicalConstraint) return <></>;

  const physicalFieldsConstraintPhysicalEntity = _.head(
    physicalConstraint.physicalFieldsConstraints?.map((pfc) => pfc.targetPhysicalField)
  )?.physicalEntity;

  const physicalFields = physicalConstraint.physicalFieldsConstraints?.map(
    (pfc) => pfc.targetPhysicalField
  );

  if (!physicalFieldsConstraintPhysicalEntity) {
    return (
      <Typography variant={variant}>
        {physicalConstraint.physicalEntitiesConstraints?.map((pec, index) => (
          <>
            <PhysicalEntityName physicalEntity={pec.targetPhysicalEntity} />
            {index < physicalConstraint.physicalEntitiesConstraints.length - 1 ? `, ` : ``}
          </>
        ))}
      </Typography>
    );
  }

  return (
    <Typography variant={variant}>
      <PhysicalEntityName physicalEntity={physicalFieldsConstraintPhysicalEntity} />
      {` `}
      {`( `}
      {physicalFields?.map((pf, index) => (
        <>
          <PhysicalFieldName physicalField={pf} />
          {index < physicalFields.length - 1 ? <>{`, `}</> : <></>}
        </>
      ))}
      {` )`}
    </Typography>
  );
};
