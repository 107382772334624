import { buildApiCallAction } from './ActionUtils';
import RulesApi from '../api/RulesApi';
import {
  FETCH_RULES_PAGE,
  FETCH_RULE,
  CREATE_RULE,
  DELETE_RULE,
  MODIFY_RULE,
  SET_RULES_FILTERS,
  CLEAR_RULES_FILTERS,
  EXPORT_CLASSIFICATION_RULES
} from '../constants/ActionTypes';

const api = new RulesApi();

export const fetchRulesPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAllRules(params),
    actionType: FETCH_RULES_PAGE
  });

export const fetchRule = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getRule(uuid),
    actionType: FETCH_RULE
  });

export const createRule = (rule) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postRule(rule),
    actionType: CREATE_RULE
  });

export const modifyRule = (rule) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putRule(rule),
    actionType: MODIFY_RULE
  });

export const deleteRule = (rule) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteRule(rule),
    actionType: DELETE_RULE,
    payload: rule
  });

export const setRulesFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_RULES_FILTERS, payload: filters });
};

export const clearRulesFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_RULES_FILTERS });
};

export const exportClassificationRules = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportRules(params),
    actionType: EXPORT_CLASSIFICATION_RULES
  });
