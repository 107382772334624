import * as React from "react";
const SvgAwsElasticSearch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 69.063 85"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="1em"
    height="1em"
    {...props}
  >
    <use xlinkHref="#AWSElasticSearch_svg__a" x={2.031} y={2.5} />
    <symbol id="AWSElasticSearch_svg__a" overflow="visible">
      <g stroke="none">
        <path
          d="m0 63.468 4.16 2.175 1.683-.4.316-30.236-1.999-.097-4.16.419v28.14z"
          fill="#9d5025"
        />
        <path
          d="M11.638 63.848 4.16 65.643V34.909l7.478.361v28.578z"
          fill="#f58534"
        />
        <path
          d="m8.071 67.583 7.134 3.687 4.619-1.502V22.199l-4.619-.897-7.134 2.185v44.097z"
          fill="#9d5025"
        />
        <path
          d="m27.417 67.291-12.212 3.98V21.302l12.212 2.39v43.599z"
          fill="#f58534"
        />
        <path
          d="M54.404 22.794v34.528l8.186 1.307V21.302l-8.186 1.492z"
          fill="#9d5025"
        />
        <path
          d="m35.45 20.796 1.664-10.514 23.707 8.876v5.579l-6.293.907-19.078-4.848z"
          fill="#6b3b19"
        />
        <path
          d="m33.241 59.868 6.876 8.349 20.704-7.169v-5.696l-6.35-.907-21.23 5.423z"
          fill="#fcbf92"
        />
        <path
          d="m20.914 74.128 11.571 5.852 6.847-3.492 2.257-71.856L32.485 0 20.914 5.901v68.227z"
          fill="#9d5025"
        />
        <path
          d="M32.486.02v79.981L65 63.419V16.601zm28.335 55.333L42.23 61.049V19.108l18.6 5.647z"
          fill="#f58534"
        />
      </g>
    </symbol>
  </svg>
);
export default SvgAwsElasticSearch;
