import React from 'react';

import JsonVisualizer from 'components/syntaxhighlithter/JsonVisualizer';
function AgentScheduleConfigsView({ job }) {
  const bodyStyle = {
    border: '1px solid',
    borderColor: 'lightgrey',
    borderRadius: '0.5em',
    padding: '1em',
    overflowX: 'auto',
    minWidth: '95%',
    maxWidth: '100%'
  };
  return (
    <>
      <JsonVisualizer object={job?.jobDetails?.configs} style={bodyStyle} />
    </>
  );
}

export default AgentScheduleConfigsView;
