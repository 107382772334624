import React from 'react';
import { connect } from 'react-redux';
import { fetchTeamListPage } from 'actions/TeamsActions';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';

function TeamsItemAutocomplete({ onSearch, searchContent, onChange, value, disabled }) {
  return (
    <ItemAutoComplete
      values={searchContent}
      disabled={disabled}
      value={value}
      margin='dense'
      nameProperty={'name'}
      itemName={'Teams'}
      onChange={(newValue) => onChange(newValue)}
      onSearch={(inputValue) => {
        onSearch({ search: inputValue });
      }}></ItemAutoComplete>
  );
}

const mapStateToProps = (state, props) => ({
  searchContent: Object.values(state.settings.teams.currentPage.content)
});

const mapDispatchToProps = (dispatch) => ({
  onSearch: (search) => dispatch(fetchTeamListPage(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsItemAutocomplete);
