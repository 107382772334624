import { BlindataApi } from '../../ApiUtils';

const WEB_HOOKS_URL = '/api/v1/settings/web-hooks';

export default class WebHooksApi extends BlindataApi {
  getWebHooks(params) {
    return this.get(WEB_HOOKS_URL, params);
  }
  getWebHook(identifier) {
    return this.get(`${WEB_HOOKS_URL}/${identifier}`);
  }
  postWebHook(webHooks) {
    return this.post(WEB_HOOKS_URL, webHooks);
  }
  putWebHook(webHooks) {
    return this.put(`${WEB_HOOKS_URL}/${webHooks.uuid}`, webHooks);
  }
  deleteWebHook(identifier) {
    return this.delete(`${WEB_HOOKS_URL}/${identifier}`);
  }
}
