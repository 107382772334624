import crudReducers from '../utils';
import { combineReducers } from 'redux';
import filtersReducer from '../utils/filters';
const filtersDefault = {
  startedAtGte: null,
  startedAtLt: null,
  finishedAtGte: null,
  finishedAtLt: null,
  semaphores: null
};
export default combineReducers({
  ...crudReducers({ resource: 'QUALITY_RESULT', keyProperty: 'uuid' }),
  filters: filtersReducer({ resource: 'QUALITY_RESULT', filtersDefault })
});
