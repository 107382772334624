import React, { useEffect, useState } from 'react';
import './LoginPage.css';
import { useDispatch } from 'react-redux';
import { fetchAuthSettings } from '../../../actions/LoginActions';
import logo_oriz from '../../../images/logo_oriz_white.png';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import GoogleLoginWrapper from './GoogleLoginWrapper';
import MSAzureLoginWrapper from './MSAzureLoginWrapper';
import OIDCImplicitFlowButton from './OIDCImplicitFlowButton';
import _ from 'lodash';
import BlindataSignInButton from './BlindataSignInButton';

function LoginPage() {
  let dispatch = useDispatch();
  let [authSettingsResponse, setAuthSettingsResponse] = useState(null);

  useEffect(
    function () {
      if (!authSettingsResponse) {
        dispatch(fetchAuthSettings()).then(setAuthSettingsResponse);
      }
    },
    [dispatch, setAuthSettingsResponse, authSettingsResponse]
  );

  if (!authSettingsResponse) {
    return (
      <form id='login-div'>
        <img id='login-logo' src={logo_oriz} alt='Logo' />
        <CircularProgress></CircularProgress>
      </form>
    );
  }
  return (
    <>
      <form id='login-div'>
        <img id='login-logo' src={logo_oriz} alt='Logo' />
        <BlindataSignInButton
          setting={_.find(authSettingsResponse.settings, { provider: 'blindata' })}
        />
        {authSettingsResponse?.settings &&
          authSettingsResponse.settings.map((setting) => renderSSOBUTTON(setting))}

        <Typography
          style={{ marginTop: 16 }}
          id='forgot-link'
          variant='caption'
          component={Link}
          to='/reset/init'>
          Forgot password?
        </Typography>
      </form>
    </>
  );
}

function renderSSOBUTTON(setting) {
  if (setting.provider === 'google') {
    return <GoogleLoginWrapper key='google' setting={setting} />;
  } else if (setting.provider === 'msazure') {
    return <MSAzureLoginWrapper key='msazure' setting={setting} />;
  }
  return <OIDCImplicitFlowButton key={setting.provider} setting={setting} />;
}

export default LoginPage;
