import * as React from "react";
const SvgVertica = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 792 612"
    style={{
      enableBackground: "new 0 0 792 612",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M313.6 267h-55.8l-31.4 55.5-31.3-55.5h-14.9l46.3 81.9 38.9-68.9h48.2z" />
    <path d="M313.6 313v-13h-55.4v45.3h55.4v-13h-42.4V313zM450.7 267h13v78.3h-13zM445.6 267h-66.4v.2c4.1 3.3 7.2 7.7 9 12.8h17.7v65.3h13V280h26.7v-13zM376 345.3h13l-19.2-35.2c8.2-3.3 14-11.4 14-20.7 0-12.4-10-22.4-22.4-22.4H319v78.3h13v-33.6h25.7l18.3 33.6zM332 280h29.4c5.2 0 9.4 4.2 9.4 9.4s-4.2 9.4-9.4 9.4H332V280zM564.1 264.1l-33.4 55.6v.1c-4.7 7.8-13.2 13-22.9 13-14.8 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8c9.1 0 17.1 4.5 22 11.5l7-11.7c-7.2-7.8-17.5-12.6-29-12.6-21.9 0-39.6 17.7-39.6 39.6s17.7 39.6 39.6 39.6c14.4 0 27-7.7 34-19.2l22.3-37.2 33.8 56.3 15-.2-48.8-81.2z" />
  </svg>
);
export default SvgVertica;
