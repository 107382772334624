import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, Paper, Box } from '@mui/material';
import RouteIcon from '@mui/icons-material/Route';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { DataFlowCardTable } from './DataFlowCardTable';
import { DataFlowCardControlButtons } from './DataFlowCardControlButtons';
import { DataFlowCardQueryStatementsTable } from './DataFlowCardQueryStatementsTable';
import { t } from 'i18next';

const computeFilters = (incoming, systemUuid, physicalEntityUuid, physicalFieldUuid) => {
  const newFilters = {};
  if (incoming) {
    if (systemUuid) {
      newFilters.toSystems = [systemUuid];
      newFilters.nullToPhysicalEntities = true;
    } else if (physicalEntityUuid) {
      newFilters.toPhysicalEntities = [physicalEntityUuid];
    } else if (physicalFieldUuid) {
      newFilters.toPhysicalFields = [physicalFieldUuid];
    }
  } else {
    if (systemUuid) {
      newFilters.fromSystems = [systemUuid];
      newFilters.nullFromPhysicalEntities = true;
    } else if (physicalEntityUuid) {
      newFilters.fromPhysicalEntities = [physicalEntityUuid];
    } else if (physicalFieldUuid) {
      newFilters.fromPhysicalFields = [physicalFieldUuid];
    }
  }
  return newFilters;
};

const DataFlowCard = ({ systemUuid, physicalEntityUuid, physicalFieldUuid, name }) => {
  const [selectedTab, setSelectedTab] = useState('DATA_FLOWS');
  const [incoming, setIncoming] = useState(true);

  const filters = computeFilters(incoming, systemUuid, physicalEntityUuid, physicalFieldUuid);

  return (
    <Paper elevation={1} style={{ minHeight: 400 }} sx={{ padding: 1 }}>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          padding: '8px 8px 16px 8px'
        }}>
        <Tabs
          variant='fullWidth'
          value={selectedTab}
          onChange={(_event, newValue) => setSelectedTab(newValue)}>
          <Tab value='DATA_FLOWS' label={t('dataflows.card.dataflows.tab')} icon={<RouteIcon />} />
          <Tab
            value='STATEMENTS'
            label={t('dataflows.card.statements.tab')}
            icon={<AutoStoriesIcon />}
          />
        </Tabs>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
          <DataFlowCardControlButtons
            systemUuid={systemUuid}
            physicalEntityUuid={physicalEntityUuid}
            physicalFieldUuid={physicalFieldUuid}
            filters={filters}
            incoming={incoming}
            setIncoming={setIncoming}
            name={name}
          />
        </div>
      </div>
      <Box padding={1}>
        {selectedTab === 'DATA_FLOWS' && <DataFlowCardTable filters={filters} />}
        {selectedTab === 'STATEMENTS' && <DataFlowCardQueryStatementsTable filters={filters} />}
      </Box>
    </Paper>
  );
};

DataFlowCard.propTypes = {
  physicalEntityUuid: PropTypes.string,
  systemUuid: PropTypes.string,
  physicalFieldUuid: PropTypes.string,
  styles: PropTypes.object
};

export default DataFlowCard;
