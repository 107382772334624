import React from 'react';
import AddFAB from '../../../../components/fab/AddFAB';
import { connect } from 'react-redux';
import { createTemplateMapping } from '../../../../actions/TemplatesActions';
import TemplateMappingModal from '../commons/TemplateMappingModal';
import { useHistory } from 'react-router';
import Permissions from 'constants/Permissions';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';

function TemplateMappingFAB({ createTemplateMapping }) {
  const [open, setOpen] = React.useState(false);
  let history = useHistory();
  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      <TemplateMappingModal
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(templateMapping) =>
          createTemplateMapping(templateMapping).then((templateMapping) => {
            if (templateMapping) {
              setOpen(false);
            }
            history.push(`/templates/mappings/${templateMapping.sequenceId}`);
          })
        }></TemplateMappingModal>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  createTemplateMapping
};

export default compose(
  withPermissions({ hasPermissions: [Permissions.TASKS_ADMIN] }),
  connect(mapStateToProps, mapDispatchToProps)
)(TemplateMappingFAB);
