import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as GraphActions from '../../actions/GraphActions';
import { showSpinner, hideSpinner } from '../../actions/BehaviourActions';
import Graph from './Graph';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

class GraphContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <Tracking
          info={{
            pageTitle: 'Graph',
            category: EngagementCategories.GRAPH,
            action: EngagementActions.DASHBOARDS
          }}
        />

        <Graph
          {...this.props}
          onHideSpinner={this.props.hideSpinner}
          onShowSpinner={this.props.showSpinner}
        />
      </>
    );
  }
}

GraphContainer.propTypes = {};

const mapStateToProps = (state) => ({
  loaded: state.graph.data.loaded,
  submitted: state.graph.filters.submitted,
  uuidFilters: state.graph.filters.uuidFilters,
  propertyFilters: state.graph.filters.propertyFilters,
  logicalPerspective: state.graph.filters.logicalPerspective,
  physicalPerspective: state.graph.filters.physicalPerspective,
  businessPerspective: state.graph.filters.businessPerspective,
  loginsPerspective: state.graph.filters.loginsPerspective,
  dataFlowsPerspective: state.graph.filters.dataFlowsPerspective,
  includeLogicalRelations: state.graph.filters.includeLogicalRelations,
  graph: state.graph.data.content,
  expansionSequence: state.graph.expansions.sequence,
  expansions: state.graph.expansions.deltas
});

export default connect(mapStateToProps, { ...GraphActions, showSpinner, hideSpinner })(
  GraphContainer
);
