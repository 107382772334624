import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';
import {
  fetchRulesPage,
  setRulesFilters,
  clearRulesFilters
} from '../../../../actions/RulesActions';
import { connect } from 'react-redux';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
import ClassificationScopesModal from '../../commons/ClassificationScopesModal';
import { HelpIcon } from '../../../../components/help';

function RulesFilters({ filters, hasFilters, setRulesFilters, clearRulesFilters }) {
  const [open, setOpen] = useState(false);
  const [openAddScopes, setOpenAddScopes] = useState(false);
  const [openAddType, setOpenAddType] = useState(false);
  const { t } = useTranslation();
  const [filtersEditor, setFiltersEditor] = useReducer((s, a) => ({ ...s, ...a }), {});

  useEffect(() => {
    setFiltersEditor({
      dataCategoryName: filters.dataCategoryName,
      nullDataCategories: filters.nullDataCategories,
      logicalFieldName: filters.logicalFieldName,
      nullLogicalFields: filters.nullLogicalFields,
      enabled: filters.enabled,
      scopes: filters.scopes,
      types: filters.types
    });
  }, [filters]);
  return (
    <React.Fragment>
      <IconButton
        aria-label='filter list'
        style={{ float: 'right' }}
        color={hasFilters ? 'primary' : 'grey'}
        size={'small'}
        onClick={() => setOpen(true)}>
        <FilterListIcon />
      </IconButton>
      <Dialog fullWidth modal={'true'} open={open}>
        <DialogTitle>Rules Filters</DialogTitle>
        <DialogContent>
          <Grid container direction='column' alignContent='flex-start'>
            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              label='Data Category Name'
              placeholder='Enter rule data category name'
              value={filtersEditor.dataCategoryName || ''}
              onChange={(event) => {
                setFiltersEditor({
                  dataCategoryName: event.target.value
                });
              }}
            />
            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              label='Logical Field Name'
              placeholder='Enter rule logical field name'
              value={filtersEditor.logicalFieldName || ''}
              onChange={(event) => {
                setFiltersEditor({
                  logicalFieldName: event.target.value
                });
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filtersEditor.nullLogicalFields || false}
                  onChange={(event) => {
                    setFiltersEditor({
                      nullLogicalFields: event.target.checked
                    });
                  }}
                  name='nullLogicalFields'
                  color='primary'
                />
              }
              label='Without Logical Fields'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filtersEditor.nullDataCategories || false}
                  onChange={(event) => {
                    setFiltersEditor({
                      nullDataCategories: event.target.checked
                    });
                  }}
                  name='nullDataCategories'
                  color='primary'
                />
              }
              label='Without Data Categories'
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={filtersEditor.enabled || false}
                  onChange={(event) => {
                    setFiltersEditor({
                      enabled: event.target.checked
                    });
                  }}
                  name='enabled'
                  color='primary'
                />
              }
              label='Production'
            />
            <Typography variant='subtitle2'>Types</Typography>
            <ChipWrapper onRequestAdd={() => setOpenAddType(true)}>
              {(filtersEditor.types || []).map((p, index) => (
                <ChipItem
                  key={index}
                  id={index}
                  label={p}
                  onRequestDelete={() =>
                    setFiltersEditor({
                      types: filtersEditor.type.filter((p, idx) => idx !== index)
                    })
                  }
                />
              ))}
            </ChipWrapper>
            <Typography variant='subtitle2'>Scopes</Typography>
            <ChipWrapper onRequestAdd={() => setOpenAddScopes(true)}>
              {(filtersEditor.scopes || []).map((p, index) => (
                <ChipItem
                  key={index}
                  id={index}
                  label={p}
                  onRequestDelete={() =>
                    setFiltersEditor({
                      scopes: filtersEditor.scopes.filter((p, idx) => idx !== index)
                    })
                  }
                />
              ))}
            </ChipWrapper>
          </Grid>
          <RuleTypesModal
            t={t}
            open={openAddType}
            onCancel={() => setOpenAddType(false)}
            onSubmit={(type) => {
              setFiltersEditor({ types: (filtersEditor.types || []).concat([type]) });
              setOpenAddType(false);
            }}
          />
          <ClassificationScopesModal
            title={'Add Scope'}
            open={openAddScopes}
            onCancel={() => setOpenAddScopes(false)}
            onSubmit={(scope) => {
              setFiltersEditor({
                scopes: (filtersEditor.scopes || []).concat([scope])
              });
              setOpenAddScopes(false);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              clearRulesFilters();
              setOpen(false);
            }}>
            {t('commons.actions.clear')}
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              setRulesFilters(filtersEditor);
              setOpen(false);
            }}>
            {t('commons.actions.apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

function RuleTypesModal({ open, onCancel, onSubmit, t }) {
  const types = [
    'DICTIONARY_DATA',
    'REGEX_DATA',
    'VALUE_DATA',
    'TABLE_METADATA',
    'FIELD_METADATA',
    'TYPE_METADATA'
  ];

  const [value, setValue] = useState(null);
  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>Add Type</DialogTitle>
      <DialogContent>
        <FormControl variant='standard' fullWidth margin='dense'>
          <InputLabel>Type</InputLabel>
          <Select
            variant='standard'
            value={value || null}
            onChange={(event) => setValue(event.target.value)}>
            {types.map((type) => (
              <MenuItem key={type} value={type}>
                <ListItem dense={true}>
                  <ListItemText primary={t(`classification.rules.${type}.name`)} />
                  <ListItemIcon>
                    <HelpIcon tooltip={t(`classification.rules.${type}.definition`)} />
                  </ListItemIcon>
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
        <Button onClick={() => onSubmit(value)}>{t('commons.actions.submit')}</Button>
      </DialogActions>
    </Dialog>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    onPageSelection: (props) => dispatch(fetchRulesPage(props)),
    setRulesFilters: (props) => dispatch(setRulesFilters(props)),
    clearRulesFilters: (props) => dispatch(clearRulesFilters(props))
  };
}

function mapStateToProps(state) {
  return {
    currentPage: state.classification.rules.currentPage,
    filters: state.classification.rules.filters.content,
    hasFilters: state.classification.rules.filters.hasFilters
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RulesFilters);
