import React, { useState, useEffect } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { ResourceTypesIcons } from 'constants/ResourceTypesIcons';
import ResourceTypes from 'constants/ResourceTypes';
import { useDispatch } from 'react-redux';
import { fetchPhysicalEntitiesPageWithFilters } from 'actions/PhysicalEntitiesActions';

export const NodeContextMenu = ({ data, id, contextMenu, handleClose }) => {
  const dispatch = useDispatch();
  const [peUuid, setPeUuid] = useState(null);

  useEffect(() => {
    if (
      'notFoundInCatalog' in data &&
      !data?.notFoundInCatalog &&
      (data.nodeType === 'SOURCE_TABLE' || data.nodeType === 'TARGET_TABLE')
    ) {
      dispatch(
        fetchPhysicalEntitiesPageWithFilters(
          {
            system: { uuid: data.systemUuid },
            schema: data.schema,
            name: data.name
          },
          0,
          1
        )
      ).then((response) => response?.content.length > 0 && setPeUuid(response.content[0].uuid));
    }
  }, [
    data,
    data.name,
    data.schema,
    dispatch,
    data.systemUuid,
    data.nodeType,
    data?.notFoundInCatalog
  ]);
  const history = useHistory();
  return (
    <Menu
      autoFocus={false}
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={
        contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
      }>
      <div
        style={{
          padding: '8px 16px 16px 16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}>
        {ResourceTypesIcons[ResourceTypes.SYSTEM]}
        <Typography
          variant='body1'
          style={{
            textAlign: 'center',
            fontSize: '1.4em',
            paddingLeft: '8px'
          }}>
          {data.systemName}
        </Typography>
      </div>
      <Typography style={{ textAlign: 'center', padding: '0px 16px 8px 16px' }} variant='subtitle1'>
        Schema: {data.schema}
      </Typography>
      <MenuItem
        onClick={() => {
          handleClose();
          !peUuid
            ? history.push(`/physicalentities/${id}`)
            : history.push(`/physicalentities/${peUuid}`);
        }}>
        <ListItemIcon>{ResourceTypesIcons[ResourceTypes.PHYSICAL_ENTITY]}</ListItemIcon>
        <ListItemText>{data.name}</ListItemText>
        <OpenInNewIcon style={{ alignSelf: 'flex-end', paddingLeft: '8px' }} />
      </MenuItem>
    </Menu>
  );
};
