import React from 'react';
import moment from 'moment';
import TodayIcon from '@mui/icons-material/Today';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import QualitySemaphoreIcon from '../../quality_checks/commons/QualitySemaphoreIcon';
import _ from 'lodash';

function parseLastRun(lastRun) {
  if (!lastRun) return '-';
  var m = moment(lastRun);
  var dateCreation;
  if (!m.isValid()) dateCreation = '';
  else {
    dateCreation = m.format('DD/MM/YYYY HH:mm:ss');
  }
  return dateCreation;
}

function QualitySuitePieChartLegend({ headers, values }) {
  var greenSemaphores = 0;
  var yellowSemaphores = 0;
  var redSemaphores = 0;
  var minLastRun = '-';
  var maxLastRun = '-';

  if (values) {
    const labels = _.map(headers, (h) => h.label);
    greenSemaphores = values[_.indexOf(labels, 'GreenSemaphore')];
    yellowSemaphores = values[_.indexOf(labels, 'YellowSemaphore')];
    redSemaphores = values[_.indexOf(labels, 'RedSemaphore')];
    minLastRun = parseLastRun(values[_.indexOf(labels, 'MinLastRun')]);
    maxLastRun = parseLastRun(values[_.indexOf(labels, 'MaxLastRun')]);
  }

  const size = '20px';

  return (
    <List dense>
      <ListItem dense>
        <ListItemIcon>
          <QualitySemaphoreIcon semaphore={'green'} height={size} width={size} />
        </ListItemIcon>
        <ListItemText primary={greenSemaphores + ' Green'} />
      </ListItem>
      <ListItem dense>
        <ListItemIcon>
          <QualitySemaphoreIcon semaphore={'yellow'} height={size} width={size} />
        </ListItemIcon>
        <ListItemText primary={yellowSemaphores + ' Yellow'} />
      </ListItem>
      <ListItem dense>
        <ListItemIcon>
          <QualitySemaphoreIcon semaphore={'red'} height={size} width={size} />
        </ListItemIcon>
        <ListItemText primary={redSemaphores + ' Red'} />
      </ListItem>
      {minLastRun === maxLastRun ? (
        <ListItem dense>
          <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText primary='Last run' secondary={maxLastRun} />
        </ListItem>
      ) : (
        <>
          <ListItem alignItems='center' dense>
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>
            <ListItemText primary='Most recent run' secondary={maxLastRun} />
          </ListItem>
          <ListItem alignItems='center' dense>
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>
            <ListItemText primary='Least recent run' secondary={minLastRun} />
          </ListItem>
        </>
      )}
    </List>
  );
}

export default QualitySuitePieChartLegend;
