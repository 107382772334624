import { fetchDataProductDomains } from 'actions/DataProductsActions';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const DataProductDomainAutoComplete = ({ value, onChange, ...props }) => {
  const [domains, setDomains] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!value) {
      dispatch(fetchDataProductDomains({ search: '' })).then((response) =>
        setDomains(response?.content?.map((domain) => ({ domain: domain })))
      );
    }
  }, [dispatch, setDomains, value]);

  return (
    <ItemAutoComplete
      itemName='Domain'
      values={domains}
      value={{ domain: value }}
      nameProperty={'domain'}
      onSearch={(inputValue) => {
        dispatch(fetchDataProductDomains({ search: inputValue })).then((response) =>
          setDomains(response?.content?.map((domain) => ({ domain: domain })))
        );
        onChange({ domain: inputValue });
      }}
      onChange={(newValue) => onChange(newValue)}
      {...props}
    />
  );
};

export default DataProductDomainAutoComplete;
