import React, { useEffect } from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useSearchParams from 'components/useSearchParams';

import {
  fetchPhysicalEntity,
  modifyPhysicalEntity,
  deletePhysicalEntity,
  addDataCategoryToPhysicalEntity
} from '../../../../actions/PhysicalEntitiesActions';
import PhysicalEntityDetailsCard from './PhysicalEntityDetailsCard';
import { Grid, Typography, Tabs, Tab } from '@mui/material';

import StewardshipResponsibilitiesPanel from '../../../stewardship/responsibilities/common/StewardshipResponsibilitiesPanel';
import ResourceTypes from '../../../../constants/ResourceTypes';
import DataFlowCard from '../../../dataflows/utils/DataFlowCard';
import CommentsPanel from '../../../social/comments/CommentsPanel';
import Breadcrumbs from 'pages/sidebar/Navigation';
import QualityPhysicalPanel from 'pages/quality/kqis/quality_checks/commons/QualityPhysicalPanel';
import QualityAssessmentPhysicalPanel from 'pages/quality/assessment/riskEvaluations/QualityAssessmentPhysicalPanel';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import PhysicalFieldsSection from './PhysicalEntityDetailsCard/PhysicalFieldsSection';
import Permissions from 'constants/Permissions';
import withAuthorize from 'components/permissions/withAuthorize';
import ClassificationAssignmentsPanel from 'pages/classification/assignments/commons/ClassificationAssignmentsPanel';
import { Box } from '@mui/system';
import PhysicalConstraintsPanel from 'pages/systems/physical_constraints/tab/PhysicalConstraintsPanel';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const PhysicalEntityDetailPage = (props) => {
  let { physicalEntityUuid, physicalEntity, t } = props;
  const query = useSearchParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPhysicalEntity(physicalEntityUuid));
  }, [dispatch, physicalEntityUuid]);

  const selectedTab = query.get('tab') || 'physicalfields';
  const handleChangeTab = (_event, newValue) => {
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });
  };

  return physicalEntity ? (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.physical_model'), to: '/systems' },
          { text: physicalEntity.system?.name, to: `/systems/${physicalEntity.system?.uuid}` },
          { text: physicalEntity.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('physicalEntities.detail.header', {
            physicalEntityName: physicalEntity.name
          }),
          category: EngagementCategories.PHYSICAL_MODEL,
          action: EngagementActions.DETAIL
        }}
      />

      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>
            {t('physicalEntities.detail.header', {
              physicalEntityName: physicalEntity.name
            })}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`${ResourceTypes.PHYSICAL_ENTITY}:${physicalEntity.uuid}`}
            resourceIdentifier={physicalEntity.uuid}
            resourceType={ResourceTypes.PHYSICAL_ENTITY}
            resourceName={physicalEntity.name}
            pageTitle={t('physicalEntities.detail.header', {
              physicalEntityName: physicalEntity.name
            })}></FavoritesWidget>
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.PHYSICAL_ENTITY}
            resourceIdentifier={physicalEntity?.uuid}
            resourceName={
              physicalEntity?.schema
                ? `${physicalEntity?.schema}.${physicalEntity?.name}`
                : physicalEntity?.name
            }
          />
        </Grid>
      </Grid>

      <PhysicalEntityDetailsCard
        physicalEntity={physicalEntity}
        modifyPhysicalEntity={props.modifyPhysicalEntity}
        deletePhysicalEntity={props.deletePhysicalEntity}
        addDataCategoryToPhysicalEntity={props.addDataCategoryToPhysicalEntity}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Tabs
          value={selectedTab}
          style={{ margin: '0' }}
          onChange={handleChangeTab}
          variant='scrollable'
          scrollButtons
          allowScrollButtonsMobile>
          <Tab value='physicalfields' label='Physical Fields' />
          <Tab value='constraints' label='Constraints' />
          <Tab value='dataflows' label='Dataflows' />
          <AuthorizedTab
            hasPermissions={[Permissions.STEWARDSHIP_VIEWER]}
            value='responsibilities'
            label='Responsibilities'
          />
          <AuthorizedTab
            hasPermissions={[Permissions.QUALITY_VIEWER]}
            value='quality'
            label='Quality Checks'
          />
          <AuthorizedTab
            hasPermissions={[Permissions.QUALITY_ASSMNT_VIEWER]}
            value='quality-assessment'
            label='Quality Assessment'
          />
          <AuthorizedTab
            hasPermissions={[Permissions.CLASSIFICATION_VIEWER]}
            value='classification'
            label='Classification'
          />
          <Tab value='comments' label='Comments' />
        </Tabs>
      </Box>

      {selectedTab === 'physicalfields' && (
        <PhysicalFieldsSection physicalEntity={physicalEntity} />
      )}
      {selectedTab === 'constraints' && (
        <PhysicalConstraintsPanel physicalEntity={physicalEntity} />
      )}
      {selectedTab === 'dataflows' && (
        <DataFlowCard style={{ marginTop: 8 }} physicalEntityUuid={physicalEntityUuid} />
      )}
      {selectedTab === 'responsibilities' && (
        <StewardshipResponsibilitiesPanel
          resourceType={ResourceTypes.PHYSICAL_ENTITY}
          resourceIdentifier={physicalEntityUuid}
        />
      )}
      {selectedTab === 'quality' && (
        <QualityPhysicalPanel physicalEntityUuid={physicalEntityUuid}></QualityPhysicalPanel>
      )}
      {selectedTab === 'quality-assessment' && (
        <QualityAssessmentPhysicalPanel physicalEntityUuid={physicalEntityUuid} />
      )}
      {selectedTab === 'classification' && (
        <ClassificationAssignmentsPanel
          physicalEntity={physicalEntity}></ClassificationAssignmentsPanel>
      )}
      {selectedTab === 'comments' && (
        <CommentsPanel
          notExpandable
          resourceType={ResourceTypes.PHYSICAL_ENTITY}
          resourceIdentifier={physicalEntityUuid}
          resourceName={physicalEntity && physicalEntity.name}></CommentsPanel>
      )}
    </>
  ) : null;
};

const AuthorizedTab = withAuthorize(Tab, 'span');
const mapStateToProps = (state, props) => ({
  physicalEntityUuid: props.match.params['physicalEntityUuid'],
  physicalEntity: state.physicalentities.content[props.match.params['physicalEntityUuid']]
});

const mapDispatchToProps = {
  fetchPhysicalEntity,
  modifyPhysicalEntity,
  deletePhysicalEntity,
  addDataCategoryToPhysicalEntity
};

export default _.flow(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PhysicalEntityDetailPage);
