import React from 'react';
import { Assessment } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { IconButton } from '@mui/material';

function EngagementDashboardsLinkButton() {
  const history = useHistory();
  return (
    <IconButton size='small' onClick={() => history.push('/engagement/dashboards')}>
      <Assessment></Assessment>
    </IconButton>
  );
}

export default EngagementDashboardsLinkButton;
