import React from 'react';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import WebActionFAB from './WebActionFAB';
import WebActionsList from './WebActionsList';

function WebActionsListPage() {
  return (
    <>
      <Breadcrumbs elements={[{ text: 'Settings', to: '/settings' }, { text: 'Web Actions' }]} />
      <Tracking
        info={{
          pageTitle: 'Web Actions',
          category: EngagementCategories.SETTINGS,
          action: EngagementActions.LIST
        }}
      />
      <WebActionsList />
      <WebActionFAB />
    </>
  );
}

export default WebActionsListPage;
