import React from 'react';
import { Box } from '@mui/material';
import {
  greenSemaphoreColor,
  yellowSemaphoreColor,
  redSemaphoreColor
} from './QualitySemaphoreColors';

function getSemaphoreColor(semaphore) {
  if (!semaphore) return 'gray';
  switch (semaphore.toLowerCase()) {
    case 'green':
      return greenSemaphoreColor;
    case 'yellow':
      return yellowSemaphoreColor;
    case 'red':
      return redSemaphoreColor;
    default:
      return 'gray';
  }
}

export default function QualitySemaphoreIcon({ semaphore, width = '40px', height = '40px' }) {
  const semaphoreColor = getSemaphoreColor(semaphore);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {semaphore ? (
        <Box height={height} width={width} borderRadius='50%' bgcolor={semaphoreColor} />
      ) : (
        <Box
          height={height}
          width={width}
          borderRadius='50%'
          style={{
            color: 'white',
            borderStyle: 'solid',
            borderColor: 'black',
            borderWidth: '1px',
            justifyContent: 'center'
          }}
        />
      )}
    </div>
  );
}
