import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

import {
  fetchRpaArchive,
  modifyRpaArchive,
  deleteRpaArchive,
  createRpaArchiveRecord
} from 'actions/RpaArchiveActions';

import { Typography } from '@mui/material';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import RpaArchiveDetailCard from './RpaArchiveDetailCard';
import RpaArchiveRecordsList from './RpaArchiveRecordsList';

const RpaArchiveDetailPage = ({
  rpaArchiveUuid,
  rpaArchive,
  fetchRpaArchive,
  modifyRpaArchive,
  deleteRpaArchive,
  createRpaArchiveRecord
}) => {
  useEffect(() => {
    fetchRpaArchive(rpaArchiveUuid);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rpaArchiveUuid]);

  return rpaArchive ? (
    <>
      <Breadcrumbs
        elements={[
          { text: 'Processings', to: '/processings' },
          { text: 'RoPA Archives', to: '/reports/rpa/archives' },
          { text: rpaArchive.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: `RoPA Archive: ${rpaArchive.name}`,
          category: EngagementCategories.PROCESSINGS,
          action: EngagementActions.DETAIL
        }}
      />

      <Typography variant='h4'>RoPA Archive: {rpaArchive.name}</Typography>
      <RpaArchiveDetailCard
        rpaArchive={rpaArchive}
        modifyRpaArchive={modifyRpaArchive}
        deleteRpaArchive={deleteRpaArchive}
        createRpaArchiveRecord={createRpaArchiveRecord}
      />
      <RpaArchiveRecordsList rpaArchiveUuid={rpaArchiveUuid} />
    </>
  ) : null;
};

const mapStateToProps = (state, props) => ({
  rpaArchiveUuid: props.match.params['uuid'],
  rpaArchive: state.reports.rpa.archives.content[props.match.params['uuid']]
});

const mapDispatchToProps = {
  fetchRpaArchive,
  deleteRpaArchive,
  modifyRpaArchive,
  createRpaArchiveRecord
};

export default connect(mapStateToProps, mapDispatchToProps)(RpaArchiveDetailPage);
