import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GraphHandler from './VisGraphHandler';
import GraphMenu from './drawer/GraphMenu';
import './GraphCanvas.css';
import CtxMenu from './CtxMenu';

const defaultState = {
  expansionSequence: 0,
  lastNodeClicked: undefined,
  openDrawer: false,
  ctxMenuOpen: false,
  ctxMenuPointer: undefined,
  isLayoutDagre: true,
  isLayoutDagreFixedLevel: true,
  isPhysicsEnabled: true,
  plugins: {
    dragListener: null,
    filter: null,
    activeState: null
  }
};

class GraphCanvas extends Component {
  state = defaultState;
  graphHandler = undefined;

  componentWillReceiveProps(props) {
    if (this.graphHandler && this.state.expansionSequence < props.expansionSequence) {
      this.addExpansionsToGraph(props);
    } else if (props.loaded) {
      console.log('Initializing the Sigma instance...');
      this.initGraph();
      this.loadRealGraph(props);
      this.redrawGraph();
      console.log('Sigma instance succesfully initialized');
    } else {
      console.log('Clearing sigma instance');
      this.graphHandler.clearGraph();
    }
  }

  componentDidMount() {
    window.setTimeout(() => this.props.fetchGraph(), 500);
  }

  componentWillUnmount() {
    if (this.graphHandler) {
      this.graphHandler.destroy();
      this.graphHandler = undefined;
    }
  }

  initGraph() {
    if (this.graphHandler === undefined) {
      this.graphHandler = new GraphHandler();
      this.graphHandler.isLayoutDagre = this.state.isLayoutDagre;
      this.graphHandler.isLayoutDagreFixedLevel = this.state.isLayoutDagreFixedLevel;
      this.graphHandler.isPhysicsEnabled = this.state.isPhysicsEnabled;
      this.graphHandler.onShowSpinner = this.props.onShowSpinner;
      this.graphHandler.onHideSpinner = this.props.onHideSpinner;
      this.registerEventsListeners();
    }
  }

  addExpansionsToGraph({ expansionSequence, expansions }) {
    let lastExpansionSequence = this.state.expansionSequence;
    expansions.forEach((expansion, index) => {
      if (index + 1 > lastExpansionSequence) {
        this.graphHandler.addGraph(expansion);
        lastExpansionSequence = index + 1;
      }
    });
    this.setState({ expansionSequence: lastExpansionSequence });
  }

  /*
   * #########################################################
   * Register functions
   * #########################################################
   */

  registerEventsListeners() {
    this.registerGraphInstanceEvents();
  }

  registerGraphInstanceEvents() {
    // Possible events:
    //    clickNode, doubleClickNode, rightClickNode, overNode, outNode
    //    clickEdge, doubleClickEdge, rightClickEdge, overEdge, outEdge           * The edge events feature is not compatible with the WebGL renderer *
    //    clickStage, doubleClickStage, rightClickStage
    this.graphHandler.bind('clickNode', (event) => this.onNodeClick(event.data.node));
    this.graphHandler.bind('doubleClickNode', (event) => this.onNodeDoubleClick(event.data.node));
    this.graphHandler.bind('rightClickNode', (event) =>
      this.onNodeRightClick(event.data.node, event.pointer)
    );
    this.graphHandler.bind('clickEdge', (event) => this.onEdgeClick(event));
    this.graphHandler.bind('clickStage', (event) => this.onStageClick(event));
    this.graphHandler.bind('rightClickEdge', (event) => this.onEdgeRightClick(event));
  }

  /*
   * #########################################################
   * Callback functions
   * #########################################################
   */

  onNodeClick(node) {
    this.setState({
      lastNodeClicked: node
    });
  }

  onStageClick() {
    this.updateNodeSelection([]);
    this.setState({
      openDrawer: false,
      lastNodeClicked: undefined,
      ctxMenuOpen: false
    });
  }

  onNodeDoubleClick(node) {
    console.log('Node Double Click', node);
    this.setState({
      openDrawer: true,
      lastNodeClicked: node
    });
    this.props.closeContextualMenu();
  }

  onNodeRightClick(node, pointer) {
    console.log('Node Right Click', node, pointer);
    let canvasEl = document.getElementById('canvas-graph-panel');
    let canvasElRect = canvasEl.getBoundingClientRect();
    pointer.DOM.x += canvasElRect.left;
    pointer.DOM.y += canvasElRect.top;
    this.setState({
      openDrawer: false,
      lastNodeClicked: node
    });
    this.props.openContextualMenu({ node, pointer });
  }

  onEdgeClick({ data: { edge } }) {
    console.log('Edge Click', edge);
  }

  onEdgeRightClick({ data: { edge }, pointer }) {
    console.log('Edge right click', edge);
    let canvasEl = document.getElementById('canvas-graph-panel');
    let canvasElRect = canvasEl.getBoundingClientRect();
    pointer.DOM.x += canvasElRect.left;
    pointer.DOM.y += canvasElRect.top;
    this.setState({
      openDrawer: false,
      lastNodeClicked: null
    });
    this.props.openContextualMenu({ edge, pointer });
  }

  /*
   * #########################################################
   * Public functions
   * #########################################################
   */

  redrawGraph() {
    this.graphHandler.redrawGraph();
  }

  updateNodeSelection(selectedNodes) {
    this.graphHandler.updateNodeSelection(selectedNodes);
  }

  loadRealGraph(props) {
    var nodeIds = props.graph.nodes.map((n) => n.id);
    //TODO fix this shit
    var filteredEdges = props.graph.edges.filter(
      (e) => nodeIds.includes(e.target) && nodeIds.includes(e.source)
    );
    var graph = {
      nodes: props.graph.nodes,
      edges: filteredEdges
    };
    this.chooseOptimalLayout(graph);
    this.graphHandler.setGraphData(graph);
  }

  chooseOptimalLayout(graph) {
    if (graph.nodes.length > 50) {
      this.graphHandler.isLayoutDagre = false;
      this.setState({ isLayoutDagre: false });
    } else {
      this.graphHandler.isLayoutDagre = true;
      this.setState({ isLayoutDagre: true });
    }
    if (
      (this.props.dataFlowsPerspective &&
        !this.props.logicalPerspective &&
        !this.props.businessPerspective) ||
      (this.props.includeLogicalRelations &&
        !this.props.businessPerspective &&
        !this.props.physicalPerspective)
    ) {
      this.graphHandler.isLayoutDagreFixedLevel = false;
    }
  }

  onChangeLayout(isLayoutDagre) {
    this.graphHandler.isLayoutDagre = isLayoutDagre;
    this.graphHandler.redrawGraph();
    this.setState({ isLayoutDagre });
  }

  onTogglePhysics(enabled) {
    this.setState({ isPhysicsEnabled: enabled });
    this.graphHandler.togglePhysics(enabled);
  }

  render() {
    return (
      <div>
        <div id='canvas-graph-panel' className='graph-panel' />
        <CtxMenu {...{ graphHandler: this.graphHandler }} />
        <GraphMenu
          graphHandler={this.graphHandler}
          open={this.state.openDrawer}
          node={this.state.lastNodeClicked}
          onToggle={(openDrawer) => this.setState({ openDrawer })}
          PhysicsLayoutProps={{
            isLayoutDagre: this.state.isLayoutDagre,
            isPhysicsEnabled: this.state.isPhysicsEnabled,
            onToggleLayout: (isLayoutDagre) => this.onChangeLayout(isLayoutDagre),
            onTogglePhysics: (enabled) => this.onTogglePhysics(enabled)
          }}
        />
      </div>
    );
  }
}

GraphCanvas.propTypes = {
  graph: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired,
  expansionSequence: PropTypes.number.isRequired,
  expansions: PropTypes.array.isRequired,
  isLayoutDagre: PropTypes.bool,
  isPhysicsEnabled: PropTypes.bool
};

export default GraphCanvas;
