import React, { useReducer, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { t } from 'i18next';
import _ from 'lodash';
import ItemSelectField from 'components/itemselector/ItemSelectField';

import TableCell from 'components/tables/GenericTableCell';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { EventResourceTypes } from 'constants/EventResourceTypes';

const EVENT_TYPES = [{ key: 'DELETE' }, { key: 'PATCH' }, { key: 'POST' }, { key: 'PUT' }];

export const WebHookMatchRuleModal = ({
  title,
  onCancel,
  onSubmit,
  matchRule,
  requestTemplateParams
}) => {
  const [editor, setEditor] = useReducer((s, a) => ({ ...s, ...a }), matchRule);

  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          fullWidth
          required
          margin='dense'
          placeholder='Enter the match rule name'
          label='Name'
          value={editor?.name || ''}
          onChange={(event) => setEditor({ name: event.target.value })}
        />
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          placeholder='Enter the match rule description'
          label='Description'
          multiline
          value={editor?.description || ''}
          onChange={(event) => setEditor({ description: event.target.value })}
        />
        <ItemSelectField
          margin='dense'
          mandatory
          values={Object.keys(EventResourceTypes)
            .sort()
            .map((key) => ({ name: EventResourceTypes[key], value: key }))}
          value={
            editor?.resourceType
              ? { name: EventResourceTypes[editor?.resourceType], value: editor?.resourceType }
              : null
          }
          onChange={(target) => setEditor({ resourceType: target.value })}
          keyProperty='name'
          nameProperty='name'
          itemName='Resource Type *'
        />
        <ItemSelectField
          margin='dense'
          values={EVENT_TYPES}
          value={editor?.eventType ? { key: editor?.eventType } : null}
          onChange={(target) => setEditor({ eventType: target?.key })}
          keyProperty='key'
          nameProperty='key'
          itemName='Event Type'
        />
        <MatchAttributesSection editor={editor} setEditor={setEditor} />
        {requestTemplateParams && requestTemplateParams.length > 0 && (
          <>
            <h4 style={{ marginBottom: 0 }}>Request Template Parameters Mapping</h4>
            {requestTemplateParams.map((key) => (
              <TextField
                key={key}
                label={key}
                value={
                  editor?.requestTemplateParamsMap ? editor?.requestTemplateParamsMap[key] : ''
                }
                placeholder={`Insert mapping for ${key}`}
                fullWidth
                margin='dense'
                variant='standard'
                onChange={(event) =>
                  setEditor({
                    requestTemplateParamsMap: {
                      ...editor?.requestTemplateParamsMap,
                      [key]: event.target.value
                    }
                  })
                }
              />
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={!editor?.name || !editor?.resourceType || _.isEqual(matchRule, editor)}
          variant='contained'
          color='primary'
          onClick={() => onSubmit(editor)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const MatchAttributesSection = ({ editor, setEditor }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Grid container alignItems='center' paddingTop={2} paddingBottom={1}>
        <Typography>Match Attributes</Typography>
        <IconButton size='small' onClick={() => setOpen(true)}>
          <AddBoxIcon />
        </IconButton>
        {editor?.matchAttributes && Object.keys(editor?.matchAttributes).length > 0 && (
          <Table size='small'>
            <TableHead>
              <TableCell align='left'>Key</TableCell>
              <TableCell align='left'>Value</TableCell>
              <TableCell align='right'></TableCell>
            </TableHead>
            <TableBody>
              {Object.keys(editor?.matchAttributes).map((key) => (
                <TableRow key={key}>
                  <TableCell align='left'>{key}</TableCell>
                  <TableCell align='left'>{editor?.matchAttributes[key]}</TableCell>
                  <TableCell align='right'>
                    <IconButton
                      size='small'
                      onClick={() =>
                        setEditor({ matchAttributes: _.omit(editor?.matchAttributes, key) })
                      }>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Grid>
      {open && (
        <MatchAttributesModal
          open={open}
          onSubmit={(attribute) => {
            setEditor({
              matchAttributes: { ...editor?.matchAttributes, [attribute.key]: attribute.value }
            });

            setOpen(false);
          }}
          onCancel={() => setOpen(false)}
        />
      )}
    </>
  );
};

const MatchAttributesModal = ({ open, onCancel, onSubmit }) => {
  const [editor, setEditor] = useReducer((s, a) => ({ ...s, ...a }), {});

  return (
    <Dialog open={open}>
      <DialogTitle>Add an attribute and its value to match</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          fullWidth
          required
          margin='dense'
          label='Attribute name'
          placeholder='Enter the attribute name'
          value={editor.key || ''}
          onChange={(event) => setEditor({ key: event.target.value })}
        />
        <TextField
          variant='standard'
          fullWidth
          required
          margin='dense'
          label='Attribute vale'
          placeholder='Enter the attribute value'
          value={editor.value || ''}
          onChange={(event) => setEditor({ value: event.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={!editor.key || !editor.value}
          variant='outlined'
          color='primary'
          onClick={() => onSubmit(editor)}>
          {t('commons.actions.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
