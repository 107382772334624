import { buildApiCallAction } from 'actions/ActionUtils';

import {
  FETCH_AGENT_JOB_HISTORYS_PAGE,
  FETCH_AGENT_JOB_HISTORY,
  FETCH_AGENT_JOB_HISTORY_LOG
} from 'constants/ActionTypes';
import AgentJobHistoryApi from 'api/onagent/AgentJobHistoryApi';

export const fetchJobHistoryPage = (agent, params) =>
  buildApiCallAction({
    api: new AgentJobHistoryApi(agent),
    apiFunction: (api) => api.getJobHistoryPage(params),
    actionType: FETCH_AGENT_JOB_HISTORYS_PAGE,
    payload: (response) => ({
      ...response,
      jobDefinitionUuid: params?.jobDefinitionUuid,
      agentUuid: agent?.uuid
    })
  });

export const fetchJobHistory = (agent, sequenceId) =>
  buildApiCallAction({
    api: new AgentJobHistoryApi(agent),
    apiFunction: (api) => api.getJobHistory(sequenceId),
    actionType: FETCH_AGENT_JOB_HISTORY
  });

export const fetchJobHistoryLog = (agent, sequenceId) =>
  buildApiCallAction({
    api: new AgentJobHistoryApi(agent),
    apiFunction: (api) => api.getJobHistoryLog(sequenceId),
    actionType: FETCH_AGENT_JOB_HISTORY_LOG
  });
