import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchRole } from 'actions/StewardshipActions';

import Breadcrumbs from 'pages/sidebar/Navigation';
import StewardshipRoleCard from './StewardshipRoleCard';
import StewardshipAssignmentsCard from '../../assignments/StewardshipAssignmentsCard';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import { Grid } from '@mui/material';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

function StewardshipRoleDetailPage({ stewardshipRoleUuid, stewardshipRole, onFetch }) {
  let { t } = useTranslation();

  useEffect(() => {
    onFetch(stewardshipRoleUuid);
  }, [onFetch, stewardshipRoleUuid]);

  if (!stewardshipRole) {
    return null;
  }
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.stewardship'), to: '/stewardship/responsibilities' },
          { text: stewardshipRole?.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: stewardshipRole?.name,
          category: EngagementCategories.STEWARDSHIP,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container justifyContent='flex-end'>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`ROLE:${stewardshipRole.uuid}`}
            pageTitle={`Role: ${stewardshipRole.name}`}
          />
        </Grid>
      </Grid>
      <StewardshipRoleCard stewardshipRole={stewardshipRole} />
      <StewardshipAssignmentsCard stewardshipRoleUuid={stewardshipRoleUuid} />
    </>
  );
}

const mapStateToProps = (state, props) => ({
  stewardshipRoleUuid: props.match.params['uuid'],
  stewardshipRole: state.stewardship.roles.content[props.match.params['uuid']]
});

const mapDispatchToProps = {
  onFetch: fetchRole
};

export default connect(mapStateToProps, mapDispatchToProps)(StewardshipRoleDetailPage);
