import React from 'react';
import { Typography } from '@mui/material';
import RpaArchiveCreateFab from '../commons/RpaArchiveCreateFab';
import RpaArchivesList from './RpaArchivesList';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
export function RpaArchivesPage() {
  return (
    <>
      <Breadcrumbs
        elements={[{ text: 'Processings', to: '/processings' }, { text: 'RoPA Archives' }]}
      />
      <Tracking
        info={{
          pageTitle: `RoPA Archives`,
          category: EngagementCategories.PROCESSINGS,
          action: EngagementActions.LIST
        }}
      />
      <Typography variant='h4'>RoPA Archives</Typography>
      <RpaArchivesList />
      <Authorize hasPermissions={[Permissions.PROCESSINGS_ADMIN]}>
        <RpaArchiveCreateFab />
      </Authorize>
    </>
  );
}
