import React from 'react';
import { FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';

function GeneralSection({ editor, setEditor }) {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h4>Configuration</h4>
        <FormControlLabel
          style={{ float: 'left', marginBottom: '1%' }}
          control={
            <Switch
              style={{ marginBottom: '1%' }}
              color='primary'
              checked={editor?.columnLevel || false}
              onChange={(_event, checked) => setEditor({ ...editor, columnLevel: checked })}
            />
          }
          label='Column Level'
        />
      </Grid>
      <Grid item>
        <Typography variant='overline'>Max Depth:</Typography>
        <br></br>
        <TextField
          variant='standard'
          margin='dense'
          type='number'
          value={editor?.maxDepth}
          onChange={(event) => setEditor({ ...editor, maxDepth: event.target.value })}
        />
      </Grid>
    </Grid>
  );
}

export default GeneralSection;
