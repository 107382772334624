import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Tooltip
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useState } from 'react';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { refreshQueryBucketStatements } from 'actions/QueryBucketsActions';
import { showMessage } from 'actions/BehaviourActions';
import ConfirmIconButton from 'components/confirmbutton/ConfirmIconButton';
import { handleMassiveAction } from '../../../../../components/massive_action_utils/MassiveActionUtils';

const flag = { stop: false };

const QueryStatementsRefreshAllButton = ({
  queryBucketUuid,
  currentPage,
  filters,
  onRefreshEnded
}) => {
  const [open, setOpen] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const dispatch = useDispatch();
  const pageSize = 1;
  const totalPages = Math.floor(currentPage.totalElements / pageSize) + 1;
  const progress = (currentPageNumber * 100) / totalPages;

  return (
    <>
      <ConfirmIconButton
        size='small'
        style={{ marginRight: 8, marginLeft: 8 }}
        message={t('querystatements.refreshAllButton.confirmMessage')}
        onClick={() => {
          setOpen(true);
          flag.stop = false;
          setCurrentPageNumber(0);
          handleMassiveAction({
            flag,
            totalPages,
            pageSize,
            action: (params) => dispatch(refreshQueryBucketStatements(params)),
            actionParams: { queryBucketUuid, ...filters },
            onError: (error) => {
              setOpen(false);
              dispatch(showMessage(error));
            },
            onActionsEnded: () => {
              onRefreshEnded();
              setOpen(false);
            },
            onUpdate: setCurrentPageNumber
          });
        }}>
        <Tooltip title={t('querystatements.refreshAllButton.tooltipTitle')}>
          <RefreshIcon />
        </Tooltip>
      </ConfirmIconButton>

      <Dialog open={open} maxWidth='md' fullWidth={true}>
        <DialogTitle>{t('querystatements.refreshAllButton.dialog.dialogTitle')}</DialogTitle>
        <DialogContent>
          <LinearProgress variant='determinate' value={progress} />
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.stop')}
            onClick={(event) => {
              event.stopPropagation();
              flag.stop = true;
            }}></Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QueryStatementsRefreshAllButton;
