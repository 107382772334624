import React, { useState } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';

import AssignmentsTable from '../list/AssignmentsTable';
import ClassificationAssignmentsFiltersState from './ClassificationAssignmentsFiltersState';
import ClassificationAgentJobRunButton from 'pages/classification/agentsettings/ClassificationAgentJobRunButton';

function ClassificationAssignmentsPanel({ physicalEntity, physicalField }) {
  let requestParameters = getFiltersInitialState(physicalEntity, physicalField);
  const [filters, setFilters] = useState(requestParameters);

  return (
    <React.Fragment>
      <Card style={{ overflowX: 'auto' }}>
        <CardHeader
          title={
            <div>
              Assignments
              <ClassificationAgentJobRunButton
                onRefreshRequestParameters={filters}
                physicalField={physicalField}
                physicalEntity={physicalEntity}
                system={
                  physicalEntity.system || physicalField.physicalEntity.system
                }></ClassificationAgentJobRunButton>
            </div>
          }
          action={
            <ClassificationAssignmentsFiltersState
              filters={filters}
              onChange={(filters) => setFilters(filters)}></ClassificationAssignmentsFiltersState>
          }></CardHeader>

        <CardContent>
          <>
            <AssignmentsTable filters={filters}></AssignmentsTable>
          </>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

function getFiltersInitialState(physicalEntity, physicalField) {
  return {
    physicalEntities: [physicalEntity],
    physicalFields: physicalField && [physicalField],
    assignmentState: []
  };
}

export default ClassificationAssignmentsPanel;
