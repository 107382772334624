import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NotificationsPage from './notifications/NotificationPage';
import WorkspaceDetailPage from './favorites/workspaces/detail/WorkspaceDetailPage';

const SocialRoutes = (props) => (
  <Switch>
    <Route path={`${props.match.url}/notifications`} component={NotificationsPage} />
    <Route
      path={`${props.match.url}/favorites/workspaces/:workspaceUuid`}
      component={WorkspaceDetailPage}
    />
    <Redirect to={`${props.match.url}/notifications`} />
  </Switch>
);

export default SocialRoutes;
