import React from 'react';
import BugReportIcon from '@mui/icons-material/BugReport';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import SecurityIcon from '@mui/icons-material/Security';
import ReportIcon from '@mui/icons-material/Report';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const IssueIcons = {
  WARNING: <ReportProblemIcon style={{ color: '#e9a227' }}></ReportProblemIcon>,
  BUG: <BugReportIcon style={{ color: '#2BB673' }}></BugReportIcon>,
  FEATURE_REQUEST: <LiveHelpIcon style={{ color: '#b539e6' }}></LiveHelpIcon>,
  TASK: <AssignmentTurnedInIcon color='primary'></AssignmentTurnedInIcon>,
  QUESTION: <QuestionAnswerIcon style={{ color: '#84b6eb' }}></QuestionAnswerIcon>,
  ALERT: <ReportIcon style={{ color: '#d91e48' }}></ReportIcon>,
  VULNERABILITY: <SecurityIcon color='secondary'></SecurityIcon>
};

export default IssueIcons;
