import PhysicalConstraintsApi from 'api/PhysicalConstraintsAPI';
import AbstractImporter from './AbstractImporter';

export default class ImporterPhysicalConstraint extends AbstractImporter {
  static isSupported(object) {
    return object.physicalConstraint;
  }

  async importObject(object, resolveUuidOnly = false) {
    if (object.physicalConstraint) {
      return this.importPhysicalConstraint(object.physicalConstraint, resolveUuidOnly);
    } else {
      throw new Error('Object type not supported by ImporterPhysicalConstraint');
    }
  }

  async importPhysicalConstraint(physicalConstraint, resolveUuidOnly = false) {
    let api = new PhysicalConstraintsApi();
    let reconciledPhysicalConstraint = physicalConstraint;
    if (!resolveUuidOnly) {
      reconciledPhysicalConstraint = {
        ...physicalConstraint,
        system: physicalConstraint.system
          ? await super.importObject({ system: physicalConstraint.system }, true)
          : null,
        physicalEntitiesConstraints: physicalConstraint.physicalEntitiesConstraints
          ? await this.resolvePhysicalEntitiesConstraints(
              physicalConstraint.physicalEntitiesConstraints
            )
          : [],
        physicalFieldsConstraints: physicalConstraint.physicalFieldsConstraints
          ? await this.resolvePhysicalFieldsConstraints(
              physicalConstraint.physicalFieldsConstraints
            )
          : []
      };
    }
    return this.importTemplate({
      object: reconciledPhysicalConstraint,
      objectName: null,
      objectIdentifier: 'uuid',
      api: api,
      post: api.postPhysicalConstraint,
      put: api.putPhysicalConstraint,
      delet: api.deletePhysicalConstraint,
      resolveUuidOnly
    });
  }

  async resolvePhysicalFieldsConstraints(physicalFieldsConstraints) {
    return Promise.all(
      physicalFieldsConstraints.map(async (pfc) => {
        return {
          targetPhysicalField: pfc.targetPhysicalField
            ? await super.importObject({
                physicalField: pfc.targetPhysicalField
              })
            : null,
          referencedPhysicalField: pfc.referencedPhysicalField
            ? await super.importObject({
                physicalField: pfc.referencedPhysicalField
              })
            : null
        };
      })
    );
  }

  async resolvePhysicalEntitiesConstraints(physicalEntitiesConstraints) {
    return await Promise.all(
      physicalEntitiesConstraints.map(async (pec) => {
        return {
          targetPhysicalEntity: pec.targetPhysicalEntity
            ? await super.importObject({
                physicalEntity: pec.targetPhysicalEntity
              })
            : null,
          referencedPhysicalEntity: pec.referencedPhysicalEntity
            ? await super.importObject({
                physicalEntity: pec.referencedPhysicalEntity
              })
            : null
        };
      })
    );
  }
}
