import React from 'react';
import { connect } from 'react-redux';
import { searchProcessings } from '../../../actions/ProcessingsActions';
import ItemAutoCompleteModal from '../../../components/itemselector/ItemAutoCompleteModal';
import _ from 'lodash';

const ProcessingCategorySearchModal = ({
  open,
  contentByUuid,
  searchProcessings,
  onCancel,
  onSubmit
}) => (
  <ItemAutoCompleteModal
    title={`Search Category`}
    itemName={'Processing Category'}
    open={open}
    keyProperty={'category'}
    nameProperty={'category'}
    values={_.uniqBy(Object.values(contentByUuid), 'category')}
    onSearch={(searchText) => searchProcessings(searchText)}
    handleCancel={onCancel}
    handleSubmit={(processing) => onSubmit(processing.category)}
  />
);

const mapStateToProps = (state, props) => ({
  contentByUuid: state.processings.contentByUuid
});

export default connect(mapStateToProps, { searchProcessings })(ProcessingCategorySearchModal);
