import React from 'react';
import { Tab, Tabs } from '@mui/material';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import DashBoardIcon from '@mui/icons-material/DashboardOutlined';
import FavoritesIcon from '@mui/icons-material/StarBorder';
import Permissions from 'constants/Permissions';
import withAuthorize from 'components/permissions/withAuthorize';

export const HomePageTabs = ({ selectedTab, handleChangeTab }) => {
  const tabStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const textStyle = {
    marginBlock: 'unset'
  };

  return (
    <Tabs value={selectedTab} onChange={handleChangeTab} centered>
      <Tab
        icon={
          <div style={tabStyle}>
            <SearchIcon />
            <p style={textStyle}>search</p>
          </div>
        }
        value='search'
      />
      <Tab
        icon={
          <div style={tabStyle}>
            <FavoritesIcon />
            <p style={textStyle}>favorites</p>
          </div>
        }
        value='favorites'
      />
      <AuthorizedTab
        hasAnyPermissions={[
          Permissions.CAMPAIGNS_ADMIN,
          Permissions.CAMPAIGNS_VIEWER,
          Permissions.CAMPAIGNS_EDITOR,
          Permissions.STEWARDSHIP_ADMIN,
          Permissions.STEWARDSHIP_EDITOR,
          Permissions.STEWARDSHIP_VIEWER
        ]}
        icon={
          <div style={tabStyle}>
            <DashBoardIcon />
            <p style={textStyle}>dashboard</p>
          </div>
        }
        value='dashboard'
      />
    </Tabs>
  );
};

const AuthorizedTab = withAuthorize(Tab, 'span');
