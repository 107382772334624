import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  Button,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';
import DataActorItemAutoComplete from '../../../data_actors/utils/DataActorItemAutoComplete';
import KVDefinitionList from '../../../../components/definition-list/KVDefinitionList';
import { fetchTemplatesMappingsPage } from '../../../../actions/TemplatesActions';
import { searchDataActors } from '../../../../actions/DataActorsActions';

import { connect } from 'react-redux';
import DataActorEditorModal from '../../../data_actors/commons/DataActorEditorModal';

function Step0DataActorReconciliation({
  context: { templateEvent },
  fetchTemplatesMappingsPage,
  searchDataActors,
  onNext
}) {
  let [dataActor, setDataActor] = useState(null);
  let [mappings, setMappings] = useState([]);
  let initialSelectedMappings = _(templateEvent.mappingsCodes || [])
    .map((v) => ({ [v]: true }))
    .reduce(Object.assign, {});

  let [selectedMappings, setSelectedMappings] = useState(initialSelectedMappings);
  useEffect(
    function () {
      fetchTemplatesMappingsPage({ size: 100 })
        .then((response) => response.content)
        .then(setMappings);
      if (
        templateEvent.dataActor &&
        (templateEvent.dataActor.name || templateEvent.dataActor.codeIdentifier)
      ) {
        searchDataActors(templateEvent.dataActor.name || templateEvent.dataActor.codeIdentifier, {
          size: 1
        }).then((data) => {
          if (
            data.content &&
            data.content.length > 0 &&
            data.content[0].name === templateEvent.dataActor.name
          ) {
            setDataActor(data.content[0]);
          } else {
            setDataActor(templateEvent.dataActor);
          }
        });
      }
    },
    [fetchTemplatesMappingsPage, searchDataActors, templateEvent]
  );
  return (
    <Card>
      <CardHeader title='Select Data Actor and Mappings' />
      <CardContent>
        <Grid container justifyContent='space-between' alignItems='flex-start'>
          <Grid item style={{ minWidth: 300 }}>
            <MappingsCheckboxList
              mappings={mappings}
              selectedMappings={selectedMappings}
              onChange={(selectedMappings) => setSelectedMappings(selectedMappings)}
            />
          </Grid>
          <Grid item style={{ minWidth: 300 }}>
            <DataActorReconciliationTool
              {...{ templateEvent, dataActor, onChange: setDataActor }}
            />
          </Grid>
          <Grid item style={{ minWidth: 300 }}>
            <KVDefinitionList item={templateEvent.data} header='Event Data'></KVDefinitionList>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          disabled={!dataActor}
          onClick={() =>
            onNext({ dataActor, mappings: mappings.filter((m, index) => selectedMappings[m.code]) })
          }>
          Next
        </Button>
      </CardActions>
    </Card>
  );
}

function MappingsCheckboxList({ mappings, selectedMappings, onChange }) {
  return (
    <>
      <h4>Mappings</h4>
      {mappings.map((mapping, index) => (
        <FormControlLabel
          style={{ display: 'block' }}
          control={
            <Checkbox
              checked={selectedMappings[mapping.code] || false}
              onChange={(event, checked) =>
                onChange({ ...selectedMappings, [mapping.code]: checked })
              }
              name={mapping.code}
              color='primary'
            />
          }
          label={mapping.name}
        />
      ))}
    </>
  );
}

function DataActorReconciliationTool({ dataActor, onChange }) {
  let [open, setOpen] = React.useState(false);
  return (
    <>
      <h4>Data Actor</h4>
      <DataActorItemAutoComplete
        fullWidth
        value={dataActor}
        onChange={onChange}></DataActorItemAutoComplete>
      {!dataActor && (
        <Typography variant='caption' color='secondary'>
          Data actor not found
        </Typography>
      )}
      {dataActor && !dataActor.uuid && (
        <Typography variant='caption' color='primary'>
          Data actor will be created
        </Typography>
      )}
      {dataActor && dataActor.uuid && (
        <Typography variant='caption'>Data actor already exists</Typography>
      )}
      <Button color='primary' onClick={() => setOpen(true)}>
        Edit
      </Button>
      <DataActorEditorModal
        open={open}
        dataActor={dataActor}
        title='Edit Data Actor'
        handleCancel={() => setOpen(false)}
        handleSubmit={(da) => {
          onChange(da);
          setOpen(false);
        }}></DataActorEditorModal>
    </>
  );
}

export default connect(null, { fetchTemplatesMappingsPage, searchDataActors })(
  Step0DataActorReconciliation
);
