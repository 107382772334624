import { BlindataApi } from './ApiUtils';

const ENGAGEMENT_DASHBOARDS_URL = '/api/v1/engagement/dashboards';

export default class EngagementDashboardsApi extends BlindataApi {
  getSessionCountMetrics(params) {
    return this.get(`${ENGAGEMENT_DASHBOARDS_URL}/sessions_count`, params);
  }

  getPageViewsMetrics(params) {
    return this.get(`${ENGAGEMENT_DASHBOARDS_URL}/pageview_counters`, params);
  }

  getSessionTimeline(params) {
    return this.get(`${ENGAGEMENT_DASHBOARDS_URL}/session_timeline`, {
      ...params,
      hitType: 'PAGEVIEW',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  }

  getPageViewTimeline(params) {
    return this.get(`${ENGAGEMENT_DASHBOARDS_URL}/pageview_timeline`, {
      ...params,
      hitType: 'PAGEVIEW',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  }

  getRecentlyViewed(params) {
    return this.get(`${ENGAGEMENT_DASHBOARDS_URL}/recently_viewed`, params);
  }
}
