import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, CardActions, Button, Typography } from '@mui/material';
import { ProposalResourceTypes } from '../../../workflows/proposals/commons/ProposalConstants';
import ProposalItemView from '../../../workflows/proposals/detail/ProposalItemView';
import { useHistory } from 'react-router';
import Authorize from '../../../../components/permissions/Authorize';
import {
  clearProposalEditor,
  setProposalEditor,
  createImmediateProposal
} from '../../../../actions/ProposalsActions';
import { connect } from 'react-redux';
import WorkflowDataLoader from './WorkflowDataLoader';
import { searchProcessings } from '../../../../actions/ProcessingsActions';
import { searchTasks } from '../../../../actions/TasksActions';
import { fetchDataActorTaskAssignments } from '../../../../actions/TaskAssignmentsActions';
import { modifyTemplateEvent } from '../../../../actions/TemplatesActions';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import Permissions from 'constants/Permissions';

function Step1TemplateEvaluation({
  context: { mappings, dataActor, templateEvent },
  onNext,
  searchProcessings,
  searchTasks,
  fetchTaskAssignment,
  clearProposalEditor,
  setProposalEditor,
  createImmediateProposal,
  modifyTemplateEvent
}) {
  let [items, setItems] = useState([]);
  let history = useHistory();

  useEffect(
    function () {
      let loader = new WorkflowDataLoader({
        searchProcessings,
        searchTasks,
        fetchTaskAssignment
      });
      loader.evaluate({ templateEvent, mappings, dataActor }).then(setItems);
    },
    [searchProcessings, searchTasks, fetchTaskAssignment, templateEvent, mappings, dataActor]
  );

  function createProposal() {
    clearProposalEditor();
    setProposalEditor({
      name: templateEvent.name,
      description: templateEvent.description,
      newItems: items
    });
    modifyTemplateEvent({ ...templateEvent, eventStatus: 'COMPLETED' });
    history.push('/workflows/proposals/editor');
  }

  function sendImmediateProposal() {
    createImmediateProposal({
      items
    })
      .then((response) => {
        if (response) {
          modifyTemplateEvent({ ...templateEvent, eventStatus: 'COMPLETED' });
        }
      })
      .then(() => history.push('/templates/events'));
  }

  return (
    <Card>
      <CardHeader title='Review the involved objects' />
      <CardContent>
        <Typography>Processings</Typography>
        {items
          .filter((i) => i.resourceType === ProposalResourceTypes.PROCESSING)
          .map((p) => (
            <ProposalItemView
              item={p}
              onDelete={() => setItems(items.filter((i) => i === p))}
              onModify={(modifiedItem) =>
                setItems(items.map((old) => (old === p ? modifiedItem : old)))
              }
            />
          ))}
        <Typography>Tasks</Typography>
        {items
          .filter((i) => i.resourceType === ProposalResourceTypes.TASK)
          .map((t, index) => (
            <ProposalItemView
              item={t}
              onDelete={() => setItems(items.filter((i) => i === t))}
              onModify={(modifiedItem) => {
                let newItems = items.map((old) => (old === t ? modifiedItem : old));
                setItems(newItems);
              }}
            />
          ))}
        <Typography>Tasks Assignments</Typography>
        {items
          .filter((i) => i.resourceType === ProposalResourceTypes.TASK_ASSIGNMENT)
          .map((t, index) => (
            <ProposalItemView
              item={t}
              onDelete={() => setItems(items.filter((i) => i === t))}
              onModify={(modifiedItem) =>
                setItems(items.map((old) => (old === t ? modifiedItem : old)))
              }
            />
          ))}
      </CardContent>
      <CardActions>
        <Button onClick={createProposal}>Propose</Button>
        <Authorize
          hasPermissions={[
            Permissions.PROCESSINGS_EDITOR,
            Permissions.TASKS_EDITOR,
            Permissions.DATA_ACTORS_EDITOR
          ]}>
          <ConfirmButton onClick={sendImmediateProposal}>Immediate</ConfirmButton>
        </Authorize>
        <Button onClick={onNext}>Exit</Button>
      </CardActions>
    </Card>
  );
}

export default connect(null, {
  searchProcessings: searchProcessings,
  searchTasks: searchTasks,
  fetchTaskAssignment: fetchDataActorTaskAssignments,
  setProposalEditor,
  clearProposalEditor,
  createImmediateProposal,
  modifyTemplateEvent
})(Step1TemplateEvaluation);
