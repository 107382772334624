import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useCallback, useEffect, useState } from 'react';
import { setDataProductsFilters } from 'actions/DataProductsActions';
import _ from 'lodash';
import { connect } from 'react-redux';

function DataProductsSearchBox({ search, setDataProductsFilters }) {
  const [editor, setEditor] = useState(search || '');

  useEffect(() => {
    if (search !== editor) {
      setEditor(search || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setEditor]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let onSetFilters = useCallback(_.debounce(setDataProductsFilters, 300), [setDataProductsFilters]);

  return (
    <TextField
      value={editor}
      id='input-with-icon-textfield'
      placeholder='Search...'
      variant='outlined'
      size='small'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='disabled' />
          </InputAdornment>
        )
      }}
      onChange={(event) => {
        setEditor(event.target.value);
        onSetFilters({ search: event.target.value || null });
      }}
    />
  );
}

const mapStateToProps = (state, { search }) => ({
  search: state.dataproducts.filters.content.search
});

export default connect(mapStateToProps, { setDataProductsFilters })(DataProductsSearchBox);
