import { BlindataApi } from './ApiUtils';

const QUALITY_RESULTS_URL = '/api/v1/data-quality/results';

export default class QualityResultsApi extends BlindataApi {
  getQualityResults(params) {
    return this.get(QUALITY_RESULTS_URL, params);
  }
  postQualityResult(qualityResult) {
    return this.post(QUALITY_RESULTS_URL, qualityResult);
  }
  patchQualityResult(qualityResult) {
    return this.patch(`${QUALITY_RESULTS_URL}/${qualityResult.uuid}`, qualityResult);
  }

  getQualityResult(uuid) {
    return this.get(`${QUALITY_RESULTS_URL}/${uuid}`);
  }

  deleteQualityResult(uuid) {
    return this.delete(`${QUALITY_RESULTS_URL}/${uuid}`);
  }

  exportQualityResults(params) {
    return this.download(`${QUALITY_RESULTS_URL}/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }
}
