import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import AutoComplete from 'components/autocomplete/AutoComplete';

function QualityProbesConnectionModal({ title, open, connection, onSubmit, onClose }) {
  const { t } = useTranslation();

  const [connectionName, setConnectionName] = useState(connection?.name);
  const [connectionType, setConnectionType] = useState(connection?.type);

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          required
          fullWidth
          placeholder={'Enter the name'}
          label={'Name'}
          value={connectionName || ''}
          onChange={(event) => setConnectionName(event.target.value)} />
        <AutoComplete
          fullWidth
          label={'Type'}
          placeholder='Select Type'
          value={connectionType || ''}
          dataSource={['JDBC', 'MONGO']}
          onUpdateInput={(type) => setConnectionType(type)}
          onNewRequest={(type) => setConnectionType(type)}
        />
        <DialogActions>
          <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
          <Button
            disabled={!connectionName}
            onClick={() =>
              onSubmit({
                ...connection,
                name: connectionName,
                type: connectionType
              })
            }>
            {t('commons.actions.submit')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
export default QualityProbesConnectionModal;
