import { handleErrors, parseJson, encodeQueryData, BlindataApi } from './ApiUtils';

export default class DataFlowsApi extends BlindataApi {
  token = '';

  getDataFlows(page, size = 25, filters = {}) {
    return fetch(`/api/v1/dataflows?${encodeQueryData({ ...filters, page, size })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson);
  }
  getScopes(params) {
    return this.get(`/api/v1/dataflows/*/scopes`, params);
  }
  getDataFlow(uuid) {
    return fetch(`/api/v1/dataflows/${uuid}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson);
  }

  postDataFlow(dataflow) {
    return fetch('/api/v1/dataflows', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(dataflow)
    })
      .then(handleErrors)
      .then(parseJson);
  }

  putDataFlow(dataflow) {
    return fetch(`/api/v1/dataflows/${dataflow.uuid}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(dataflow)
    })
      .then(handleErrors)
      .then(parseJson);
  }

  deleteDataFlow(dataFlow) {
    return fetch(`/api/v1/dataflows/${dataFlow.uuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    }).then(handleErrors);
  }

  exportDataFlow(filters) {
    return this.download(`/api/v1/dataflows/*/export`, {
      ...filters,
      includeProperties: 'true'
    });
  }
}
