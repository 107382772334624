import { fetchQueryBucketsPage } from 'actions/QueryBucketsActions';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';
import React from 'react';
import { connect } from 'react-redux';

const QueryBucketItemAutoComplete = ({
  searchContent,
  onSearch,
  onChange,
  value,
  disabled,
  required
}) => {
  return (
    <ItemAutoComplete
      required={required}
      disabled={disabled}
      values={searchContent}
      value={value}
      showAllOptions
      itemName='QueryBucket'
      nameProperty={'name'}
      onChange={(newValue) => onChange(newValue)}
      onSearch={(inputValue) => {
        onSearch({ search: inputValue });
      }}></ItemAutoComplete>
  );
};

const mapStateToProps = (state, props) => ({
  searchContent: state.dataflows.querybuckets.currentPage.content
});
const mapDispatchToProps = (dispatch) => ({
  onSearch: (page) => dispatch(fetchQueryBucketsPage({ page }))
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryBucketItemAutoComplete);
