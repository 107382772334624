import { Grid } from '@mui/material';
import React from 'react';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
const {
  default: KVDefinitionList
} = require('../../../../components/definition-list/KVDefinitionList');

const styles = {
  root: {
    flexGrow: 1,
    padding: '8px'
  }
};

function RuleView({ rule }) {
  return (
    <div style={styles.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <KVDefinitionList
            header='Target'
            item={{
              'Data Category': propValue('dataCategoryName', rule),
              'Logical Field': propValue('logicalFieldName', rule)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <KVDefinitionList
            header='Content'
            item={{
              Uuid: propValue('uuid', rule),
              'Rule Content': propValue('ruleContent', rule)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <KVDefinitionList
            header='General'
            item={{
              Enabled: rule.enabled ? 'true' : 'false',
              'Created At': propValue('createdAt', rule),
              'Updated At': propValue('updatedAt', rule)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <KVDefinitionList
            header='Score'
            item={{
              Weight: propValue('weight', rule),
              Threshold: propValue('threshold', rule)
            }}
          />
        </Grid>
      </Grid>
      {rule.dictionaryUuid === null ? (
        <></>
      ) : (
        <KVDefinitionList
          header='Associated Dictionaries'
          item={{
            'Dictionary Uuid': propValue('dictionaryUuid', rule),
            Dictionary: propValue('dictionaryName', rule.dictionary)
          }}
        />
      )}
      <h4>{'Scopes'}</h4>
      {rule.scopes === null || rule.scopes.length === 0 ? (
        <div>No scopes defined</div>
      ) : (
        <ChipWrapper>
          {rule.scopes.map((scope, index) => (
            <ChipItem key={index} id={index} label={scope} />
          ))}
        </ChipWrapper>
      )}
    </div>
  );
}

function propValue(keyChain, props) {
  let keys = keyChain.split('.');
  let value = keys.reduce((acc, key) => (acc ? acc[key] : acc), props);
  return value || 'Not defined';
}

export default RuleView;
