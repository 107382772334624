import React from 'react';
import { useHistory } from 'react-router-dom';

import { TableHead, TableBody, TableRow } from '@mui/material';

import TableCell from 'components/tables/GenericTableCell';

function QualityProbesDefinitionsTable({ content }) {
  const history = useHistory();
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell>Probe Name</TableCell>
          <TableCell hiddenSm>Probe Type</TableCell>
          <TableCell hiddenMd>Quality Check </TableCell>
          <TableCell hiddenMd>Version</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {content.map((probeDefinition) => (
          <TableRow
            key={probeDefinition.sequenceId}
            hover={true}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push(
                `/data-quality/probes/definitions/${probeDefinition.rootUuid}/versions/${probeDefinition.sequenceId}`
              )
            }>
            <TableCell>{probeDefinition.name}</TableCell>
            <TableCell hiddenSm>{probeDefinition.type}</TableCell>
            <TableCell hiddenMd>
              {probeDefinition.checkGroupCode && <span> `${probeDefinition.checkGroupCode}`</span>}
              {probeDefinition.checkCode && (
                <>
                  <span>{probeDefinition.checkCode}</span> <i>[{probeDefinition.checkName}]</i>
                </>
              )}
            </TableCell>
            <TableCell hiddenMd>{`Version #${probeDefinition.version}`}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}

export default QualityProbesDefinitionsTable;
