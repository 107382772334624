import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';
import { Button } from '@mui/material';
import ProjectScheduleModal from './ProjectScheduleModal';
import { createSchedule } from 'actions/onagent/AgentScheduleActions';

function ProjectScheduleAddButton({ project, agent, createSchedule }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>Create a Schedule</Button>
      {open && (
        <ProjectScheduleModal
          title={'Schedule a job'}
          project={project}
          agent={agent}
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={(agent, schedule) => {
            createSchedule(agent, schedule);
            setOpen(false);
          }}></ProjectScheduleModal>
      )}
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.PROBES_EDITOR, Permissions.AGENTS_EDITOR] }),
  connect(null, { createSchedule })
)(ProjectScheduleAddButton);
