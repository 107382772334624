export const hoveredStyle = {
  borderColor: '#00aeca',
  borderStyle: 'solid',
  borderRadius: '1em',
  borderWidth: '2px',
  padding: '6px',
  cursor: 'pointer'
};

export const notHoveredStyle = {
  padding: '8px'
};
