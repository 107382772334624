import React from 'react';
import HomeSearchWidget from './homesearch/HomeSearchWidget';
import WelcomeWidget from './welcome/WelcomeWidget';
import './HomePage.css';
import { useHistory } from 'react-router';
import useSearchParams from 'components/useSearchParams';
import { HomePageTabs } from './HomePageTabs';
import WorkspacesWidget from 'pages/home/homefavorites/WorkspacesWidget';
import HomeDashboardPage from './home_dashboard/HomeDashboardPage';

function HomePage() {
  const history = useHistory();
  const query = useSearchParams();

  const selectedTab = query.get('tab') || localStorage.getItem('homepage-selected-tab') || 'search';

  const handleChangeTab = (_event, newValue) => {
    localStorage.setItem('homepage-selected-tab', newValue);
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });
  };

  return (
    <div id='home-container'>
      <WelcomeWidget />
      <HomePageTabs selectedTab={selectedTab} handleChangeTab={handleChangeTab} />
      {selectedTab === 'search' && <HomeSearchWidget maxWidth='1200' />}
      {selectedTab === 'favorites' && <WorkspacesWidget maxWidth='1200' />}
      {selectedTab === 'dashboard' && <HomeDashboardPage />}
    </div>
  );
}

export default HomePage;
