import {
  FETCH_TASKS_PAGE,
  FETCH_TASK,
  SEARCH_TASKS,
  CREATE_TASK,
  MODIFY_TASK,
  DELETE_TASK,
  SET_TASKS_FILTERS,
  CLEAR_TASKS_FILTERS,
  CREATE_TASK_PHYSICAL_ENTITY_ASSOCIATION,
  FETCH_TASK_PHYSICAL_ENTITY_ASSOCIATIONS,
  REMOVE_TASK_PHYSICAL_ENTITY_ASSOCIATION,
  EXPORT_TASKS
} from '../constants/ActionTypes';
import TasksApi from '../api/TasksApi';
import { buildApiCallAction } from './ActionUtils';

const api = new TasksApi();

export const fetchTasksPage = (page = 0, size, filters) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTasks(page, size || 25, filters),
    actionType: FETCH_TASKS_PAGE
  });

export const fetchTasksPageWithFilters = (page = 0, size = 25) =>
  buildApiCallAction({
    api,
    apiFunction: (api, getState) => {
      let filters = getState().tasks.filters.hasFilters ? getState().tasks.filters : undefined;
      return api.getTasks(page, size, filters);
    },
    actionType: FETCH_TASKS_PAGE
  });

export const fetchTask = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTask(uuid),
    actionType: FETCH_TASK
  });

export const searchTasks = (search) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.searchTasks(searchParams(search)),
    actionType: SEARCH_TASKS
  });

const searchParams = (search) => {
  let { searchText } = search;
  return searchText || search;
};

export const createTask = (task) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postTask(task),
    actionType: CREATE_TASK
  });

export const modifyTask = (task) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putTask(task),
    actionType: MODIFY_TASK
  });

export const deleteTask = (task) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteTask(task),
    actionType: DELETE_TASK,
    payload: task
  });

export const createTaskPhysicalEntityAssociation = ({ taskUuid, physicalEntityUuid }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postTaskPhysicalEntityAssociation({ taskUuid, physicalEntityUuid }),
    actionType: CREATE_TASK_PHYSICAL_ENTITY_ASSOCIATION
  });

export const fetchTaskPhysicalEntitiesAssociations = (taskUuid, page, size) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTaskPhysicalEntityAssociations(taskUuid, page, size),
    actionType: FETCH_TASK_PHYSICAL_ENTITY_ASSOCIATIONS
  });

export const removeTaskPhysicalEntityAssociation = (taskPhysicalEntityAssociation) =>
  buildApiCallAction({
    api,
    apiFunction: (api) =>
      api.deleteTaskPhysicalEntityAssociation(taskPhysicalEntityAssociation.uuid),
    actionType: REMOVE_TASK_PHYSICAL_ENTITY_ASSOCIATION,
    payload: taskPhysicalEntityAssociation
  });

export const setTasksFilters = (filters) => (dispatch, getState) =>
  dispatch({ type: SET_TASKS_FILTERS, payload: filters });

export const clearTasksFilters = () => (dispatch, getState) =>
  dispatch({ type: CLEAR_TASKS_FILTERS });
export const exportTasks = (filters) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportTasks(filters),
    actionType: EXPORT_TASKS
  });
