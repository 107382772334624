// utils
export const util = require('./lib/util');
export const DOMutil = require('./lib/DOMutil');

// data
export const DataSet = require('./lib/DataSet');
export const DataView = require('./lib/DataView');
export const Queue = require('./lib/Queue');

// Network
export const Network = require('./lib/network/Network');
export const network = {
  Images: require('./lib/network/Images'),
  dotparser: require('./lib/network/dotparser'),
  gephiParser: require('./lib/network/gephiParser'),
  allOptions: require('./lib/network/options')
};
network.convertDot = function (input) {
  return network.dotparser.DOTToGraph(input);
};
network.convertGephi = function (input, options) {
  return network.gephiParser.parseGephi(input, options);
};

// bundled external libraries
export const moment = require('./lib/module/moment');
export const Hammer = require('./lib/module/hammer');
export const keycharm = require('keycharm');
