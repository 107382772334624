import React from 'react';
import { useHistory } from 'react-router';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';
import { ChipWrapper, ChipItem } from '../../../components/chiplist/ChipList';
import DefinitionList from '../../../components/definition-list/DefinitionList';
import { useTranslation } from 'react-i18next';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';
import ResourceTypes from '../../../constants/ResourceTypes';

function ProcessingView({ processing }) {
  let history = useHistory();
  const [t] = useTranslation();
  return (
    <>
      <div>{processing.description}</div>
      <div className='dls-wrapper'>
        <KVDefinitionList
          header='General'
          item={{
            Uuid: propValue('uuid', processing),
            Name: propValue('name', processing),
            Category: propValue('category', processing),
            'Legal Basis': propValue('legalBasis', processing),
            'Created At': propValue('createdAt', processing),
            'Updated At': propValue('updatedAt', processing)
          }}
        />
        <KVDefinitionList
          header='Actors'
          item={{
            Controller: propValue('controller.name', processing),
            Representative: propValue('representative.name', processing),
            DPO: propValue('dataProtectionOfficer.name', processing),
            'Joint Controllers':
              (processing.jointControllers || []).map((c) => c.name).join(', ') ||
              t('commons.placeholders.undefined')
          }}
        />
        {processing.startDate || processing.endDate || processing.consentDuration ? (
          <KVDefinitionList
            header='Life cycle'
            item={{
              'Start date': processing.startDate,
              'End date': processing.endDate,
              'Consent Duration': `${processing.consentDuration || 'Not defined'} ${
                processing.consentDurationUnit || ''
              }`
            }}
          />
        ) : null}

        {optionalProperties(processing)}

        <CustomPropertiesView
          additionalProperties={processing.additionalProperties}
          resourceType={ResourceTypes.PROCESSING}
        />
      </div>

      <h4>{t('data_categories.header')}</h4>
      <ChipWrapper>
        {(processing.associatedDataCategories || [])
          .map((adc) => adc.dataCategory)
          .map((dc) => (
            <ChipItem
              key={dc.uuid}
              id={dc.uuid}
              label={dc.name}
              tooltip={dc.description}
              onClick={() => history.push(`/datacategories/${dc.uuid}`)}
            />
          ))}
      </ChipWrapper>
    </>
  );
}

function propValue(keyChain, props) {
  let keys = keyChain.split('.');
  let value = keys.reduce((acc, key) => (acc ? acc[key] : acc), props);
  return value || 'Not defined';
}

function optionalProperties(processing) {
  return [
    purposesComponent(processing),
    securityMeasuresComponent(processing),
    recipientsComponent(processing),
    transferToOrganizationsComponent(processing)
  ].filter((component) => component);
}

function securityMeasuresComponent(processing) {
  let securityMeasures = processing.securityMeasures;
  if (securityMeasures && securityMeasures.length > 0) {
    return (
      <DefinitionList
        header={'Security Measures'}
        items={processing.securityMeasures}
        labelProperty={'name'}
        dataProperties={['description']}
      />
    );
  }
}

function recipientsComponent(processing) {
  let recipients = processing.recipients;
  if (recipients && recipients.length > 0) {
    return (
      <DefinitionList
        header={'Recipients'}
        items={recipients.map((r) => ({
          recipientCategory: r.recipientCategory,
          recipientDescription: r.recipientDescription,
          diffusionMode: `Diffusion mode: ${r.diffusionMode}`
        }))}
        labelProperty='recipientCategory'
        dataProperties={['recipientDescription', 'diffusionMode']}
      />
    );
  }
}

function transferToOrganizationsComponent(processing) {
  let organizations = processing.transferExtraEuOrganizations;
  if (organizations && organizations.length > 0) {
    return (
      <DefinitionList
        header={'Transfer to Extra EU Organization'}
        items={organizations.map((transfer) => ({
          label: transfer.organization.name,
          d1: `country: ${transfer.organization.country || 'Not defined'}`,
          d2: `safeguard type: ${transfer.safeguardType || 'Not defined'}`,
          d3: `link to docs: ${transfer.linkToDocs || 'Not defined'}`
        }))}
        labelProperty={'label'}
        dataProperties={['d1', 'd2', 'd3']}
      />
    );
  }
}

function purposesComponent(processing) {
  let purposes = processing.purposes;
  if (purposes && purposes.length > 0) {
    return (
      <DefinitionList
        header={'Purposes'}
        items={processing.purposes}
        labelProperty='name'
        dataProperties={['description']}
      />
    );
  }
}

export default ProcessingView;
