import { buildApiCallAction } from './ActionUtils';
import QueryStatementsApi from 'api/QueryStatementsApi';
import {
  FETCH_QUERY_STATEMENTS_PAGE,
  FETCH_QUERY_STATEMENT,
  CREATE_QUERY_STATEMENT,
  MODIFY_QUERY_STATEMENT,
  DELETE_QUERY_STATEMENT,
  SET_QUERY_STATEMENTS_FILTERS,
  CLEAR_QUERY_STATEMENTS_FILTERS,
  FETCH_DATA_FLOW_PREVIEWS_PAGE,
  CREATE_DATA_FLOW_PREVIEWS,
  DELETE_DATA_FLOW_PREVIEWS,
  FETCH_QUERY_STATEMENT_FULL_LINEAGE,
  EXPORT_QUERY_STATEMENTS,
  PATCH_QUERY_STATEMENT,
  REFRESH_QUERY_STATEMENT,
  FETCH_QUERY_STATEMENT_ENDING_NODES,
  FETCH_DATA_FLOWS_INVOLVED_STATEMENTS
} from '../constants/ActionTypes';
import { showMessage } from './BehaviourActions';

const api = new QueryStatementsApi();

export const fetchQueryStatementsPage = ({ page, size = 20, ...filters }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => {
      const apiParams = createApiParams(filters);
      return api.getAllQueryStatements({ page, size, ...apiParams });
    },
    actionType: FETCH_QUERY_STATEMENTS_PAGE
  });

export const fetchQueryStatement = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getQueryStatement(uuid),
    actionType: FETCH_QUERY_STATEMENT
  });
export const createQueryStatement = (queryStatement) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postQueryStatement(queryStatement),
    actionType: CREATE_QUERY_STATEMENT
  });

export const createQueryStatementWithFeedback = (queryStatement) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postQueryStatement(queryStatement),
    actionType: CREATE_QUERY_STATEMENT,
    onSuccess: (dispatch, getState) => {
      showMessage('Query Statement Added')(dispatch, getState);
    }
  });

export const modifyQueryStatement = (queryStatement) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putQueryStatement(queryStatement),
    actionType: MODIFY_QUERY_STATEMENT
  });

export const patchQueryStatement = (queryStatement) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.patchQueryStatement(queryStatement),
    actionType: PATCH_QUERY_STATEMENT
  });
export const deleteQueryStatement = (queryStatement) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteQueryStatement(queryStatement),
    actionType: DELETE_QUERY_STATEMENT,
    payload: queryStatement
  });

export const fetchDataFlowPreviews = (
  identifier,
  { generateAtPhysicalEntityLevel, ...previewOptions }
) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => {
      return api.getAllDataFlowsPreview(identifier, {
        generateAtPhysicalEntityLevel,
        ...previewOptions
      });
    },
    actionType: FETCH_DATA_FLOW_PREVIEWS_PAGE
  });

export const saveDataFlowPreviews = (
  identifier,
  { generateAtPhysicalEntityLevel, ...previewOptions }
) =>
  buildApiCallAction({
    api,
    apiFunction: (api) =>
      api.postAllDataFlowsPreview(identifier, {
        generateAtPhysicalEntityLevel,
        ...previewOptions
      }),
    actionType: CREATE_DATA_FLOW_PREVIEWS
  });

export const deleteDataFlowPreviews = (identifier) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteAllDataFlowsPreview(identifier),
    actionType: DELETE_DATA_FLOW_PREVIEWS
  });

export const refreshStatement = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.refresh(uuid),
    actionType: REFRESH_QUERY_STATEMENT
  });

export const fetchQueryStatementEndingNodes = (queryStatementUuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getQueryStatementEndingNodes(queryStatementUuid),
    actionType: FETCH_QUERY_STATEMENT_ENDING_NODES,
    payload: (response) => ({ ...response, queryStatementUuid })
  });

export const connectQueryStatementToTargetTable = (queryStatementUuid, connections) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putQueryStatementTargetTable(queryStatementUuid, connections),
    actionType: MODIFY_QUERY_STATEMENT
  });

export const setQueryStatementsFilters = (filters) => (dispatch) => {
  dispatch({ type: SET_QUERY_STATEMENTS_FILTERS, payload: filters });
};

export const clearQueryStatementsFilters = () => (dispatch) => {
  dispatch({ type: CLEAR_QUERY_STATEMENTS_FILTERS });
};

function createApiParams(filters) {
  return {
    bucketUuid: filters?.bucketUuid,
    ...filters
  };
}

export const fetchQueryStatementFullLineage = (queryStatementUuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => {
      return api.getQueryStatementFullLineage(queryStatementUuid);
    },
    actionType: FETCH_QUERY_STATEMENT_FULL_LINEAGE,
    payload: (response) => ({ ...response, queryStatementUuid })
  });

export const exportQueryStatements = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportQueryStatements(params),
    actionType: EXPORT_QUERY_STATEMENTS
  });

export const fetchDataFlowsInvolvedStatements = ({ page = 0, size = 20, ...filters }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getDataFlowsInvolvedStatements({ page, size, ...filters }),
    actionType: FETCH_DATA_FLOWS_INVOLVED_STATEMENTS
  });
