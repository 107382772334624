import { BlindataApi } from 'api/ApiUtils';

const PROBES_DEFINITIONS_URL = '/api/v1/data-quality/probes/definitions';

export default class ProbesDefinitionsApi extends BlindataApi {

  getProbesDefinitions(params) {
    return this.get(PROBES_DEFINITIONS_URL, params);
  }

  getProbeDefinitionByRoot(rootUuid) {
    return this.get(`${PROBES_DEFINITIONS_URL}/${rootUuid}`);
  }

  getProbeDefinition(rootUuid, sequenceId) {
    return this.get(`${PROBES_DEFINITIONS_URL}/${rootUuid}/versions/${sequenceId}`);
  }

  postProbeDefinition(probeDefinition) {
    return this.post(PROBES_DEFINITIONS_URL, probeDefinition);
  }

  putProbeDefinition(probeDefinition) {
    return this.put(`${PROBES_DEFINITIONS_URL}/${probeDefinition.rootUuid}`, probeDefinition);
  }

  logicallyDeleteProbeDefinition(probeDefinition) {
    return this.delete(`${PROBES_DEFINITIONS_URL}/${probeDefinition.rootUuid}`);
  }

  physicallyDeleteProbeDefinition(probeDefinition) {
    return this.delete(`${PROBES_DEFINITIONS_URL}/${probeDefinition.sequenceId}`);
  }

  exportProbeDefinitionsCSV(params) {
    return this.download(`${PROBES_DEFINITIONS_URL}/*/export/csv`, {
      ...params,
      includeProperties: 'false'
    })
  }

  exportProbeDefinitionsYAML(params) {
    return this.download(`${PROBES_DEFINITIONS_URL}/*/export/yaml`, params);
  }
}