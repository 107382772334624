import React from 'react';
import { useLocation } from 'react-router';
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import ToolbarActions from 'pages/sidebar/ToolbarActions';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';

const DataFlowsQueryParserNavbar = ({ t }) => {
  const { pathname } = useLocation();
  const dataflowsPathname = '/dataflows';
  const queryparserPathname = '/dataflows/queryparser';

  return (
    <ToolbarActions>
      <Button
        color={pathname === dataflowsPathname ? 'primary' : 'grey'}
        children={t('navbar.dataflows')}
        component={Link}
        to={dataflowsPathname}
      />
      <Authorize hasPermissions={[Permissions.QUERY_PARSER_VIEWER]}>
        <Button
          color={pathname === queryparserPathname ? 'primary' : 'grey'}
          children={t('navbar.queryparser')}
          component={Link}
          to={queryparserPathname}
        />
      </Authorize>
    </ToolbarActions>
  );
};

export default withTranslation()(DataFlowsQueryParserNavbar);
