import React, { useState } from 'react';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  TextField,
  CardActions,
  Button,
  IconButton,
  Tooltip
} from '@mui/material';
import DoubleArrow from '@mui/icons-material/DoubleArrow';
import { useTranslation } from 'react-i18next';
import { ProposalStatus } from '../commons/ProposalConstants';
import ProposalItem from '../detail/ProposalItemView';
import ProposalSearchModal from '../commons/ProposalSearchModal';
import Breadcrumbs from 'pages/sidebar/Navigation';
import AccountTeamSelector, {
  ACCESS_LEVELS
} from '../../../settings/profile/utils/AccountTeamSelector';

function ProposalEditor({ editor, onChange, onCreate, onModify, onCancel, onMoveToProposal }) {
  let { t } = useTranslation();

  function onSave(proposalStatus) {
    let payload = {
      ...editor,
      proposalStatus: proposalStatus,
      items: (editor.items || []).concat(editor.newItems || [])
    };
    if (editor.uuid) {
      onModify(payload);
    } else {
      onCreate(payload);
    }
  }
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.proposals'), to: '/workflows/proposals' },
          { text: 'Proposal Editor' }
        ]}
      />
      <Typography variant='h4'>Variation Proposal Editor</Typography>
      <Card>
        <CardHeader title='Proposal Description'></CardHeader>
        <CardContent>
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            label={t('commons.fields.name')}
            placeholder={t('commons.fields.nameHint')}
            value={editor.name || ''}
            onChange={(event) => onChange({ name: event.target.value })}></TextField>
          <TextField
            variant='standard'
            margin='normal'
            multiline
            rows={2}
            fullWidth
            label={t('commons.fields.description')}
            placeholder={t('commons.fields.descriptionHint')}
            value={editor.description || ''}
            onChange={(event) => onChange({ description: event.target.value })}
          />
          <AccountTeamSelector
            accessLevel={ACCESS_LEVELS.PROPOSE}
            selectedTeamCode={editor.teamCode || ''}
            onChange={(code) => onChange({ teamCode: code })}
          />
        </CardContent>
        <CardActions>
          <Button children={t('commons.actions.cancel')} onClick={onCancel}></Button>
          <Button disabled={!editor.name} onClick={() => onSave(ProposalStatus.DRAFT)}>
            Save as Draft
          </Button>
          <Button disabled={!editor.name} onClick={() => onSave(ProposalStatus.SUBMITTED)}>
            Submit
          </Button>
        </CardActions>
      </Card>
      {editor.newItems && (
        <Typography>
          New Items <ItemsMoveButton onMoveToProposal={onMoveToProposal} />
        </Typography>
      )}
      {(editor.newItems || []).map((item, index) => (
        <ProposalItem
          t={t}
          key={index}
          item={item}
          onDelete={() => onChange({ newItems: editor.newItems.filter((i) => i !== item) })}
          onModify={(modified) =>
            onChange({ newItems: editor.newItems.map((i) => (i !== item ? i : modified)) })
          }></ProposalItem>
      ))}

      {editor.items && <Typography>Items</Typography>}
      {(editor.items || []).map((item, index) => (
        <ProposalItem
          t={t}
          key={index}
          item={item}
          onDelete={() => onChange({ items: editor.items.filter((i) => i !== item) })}
          onModify={(modified) => {
            onChange({ items: editor.items.map((i) => (i !== item ? i : modified)) });
          }}></ProposalItem>
      ))}
    </>
  );
}

function ItemsMoveButton({ onMoveToProposal }) {
  let [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title={'Move to existing proposal'}>
        <IconButton onClick={() => setOpen(true)} size='large'>
          <DoubleArrow></DoubleArrow>
        </IconButton>
      </Tooltip>
      <ProposalSearchModal
        proposalStatus={ProposalStatus.DRAFT}
        open={open}
        handleCancel={() => setOpen(false)}
        handleSubmit={(proposal) => {
          setOpen(false);
          onMoveToProposal(proposal);
        }}
      />
    </>
  );
}

export default ProposalEditor;
