import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'pages/sidebar/Navigation';
import StewardshipResponsibilitiesListTable from './StewardshipResponsibilitiesListTable';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

const StewardshipResponsibilitiesPage = (props) => {
  let { t } = useTranslation();
  let { user } = useSelector(mapStateToProps);
  const [filters, setFilters] = useState({
    user: user,
    resourceType: null,
    team: null,
    endDateIsNull: true,
    resourceIdentifier: null
  });

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.stewardship'), to: '/stewardship/responsibilities' },
          { text: t('stewardship.responsibilities.header') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('stewardship.responsibilities.header'),
          category: EngagementCategories.STEWARDSHIP,
          action: EngagementActions.LIST
        }}
      />
      <StewardshipResponsibilitiesListTable
        key={filters}
        setFilters={setFilters}
        filters={filters}></StewardshipResponsibilitiesListTable>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.settings.profile
});

export default StewardshipResponsibilitiesPage;
