import { Typography, ThemeProvider, StyledEngineProvider } from '@mui/material';
import React from 'react';
import { createTheme, adaptV4Theme } from '@mui/material/styles';

function TeamDisplayName({ team }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Typography>{team.name}</Typography>
        <Typography variant='subtitle1'>{team.code}</Typography>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
const theme = createTheme(
  adaptV4Theme({
    typography: {
      body1: {
        fontWeight: 500
      },
      subtitle1: {
        fontSize: 12
      }
    }
  })
);

export default TeamDisplayName;
