import { BlindataApi } from './ApiUtils';

const LOGICAL_RELATIONS_URL = '/api/v1/logical/relations';

export default class LogicalRelationsApi extends BlindataApi {
  getAllLogicalRelations(params) {
    return this.get(LOGICAL_RELATIONS_URL, params);
  }

  getLogicalRelation(identifier) {
    return this.get(`${LOGICAL_RELATIONS_URL}/${identifier}`);
  }

  getLogicalRelationTypes(search) {
    return this.get(`${LOGICAL_RELATIONS_URL}/*/relationTypes`, search);
  }

  postLogicalRelation(logicalRelation) {
    return this.post(LOGICAL_RELATIONS_URL, logicalRelation);
  }

  putLogicalRelation(logicalRelation) {
    return this.put(`${LOGICAL_RELATIONS_URL}/${logicalRelation.uuid}`, logicalRelation);
  }

  deleteLogicalRelation(logicalRelation) {
    return this.delete(`${LOGICAL_RELATIONS_URL}/${logicalRelation.uuid}`);
  }

  exportLogicalRelations(params) {
    return this.download(`${LOGICAL_RELATIONS_URL}/*/export`, {
      includeProperties: 'false',
      ...params
    });
  }
}
