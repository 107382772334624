import React from 'react';
import { AddFAB } from '../../../../components/fab';
import { createRpaArchive } from '../../../../actions/RpaArchiveActions';
import { RpaArchiveEditorModal } from './RpaArchiveEditorModal';
import { connect } from 'react-redux';

function RpaArchiveCreateFab({ createRpaArchive }) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      <RpaArchiveEditorModal
        open={open}
        title='Create Ropa Archive'
        onSubmit={(rpaArchive) => {
          setOpen(false);

          createRpaArchive(rpaArchive);
        }}
        onCancel={() => setOpen(false)}></RpaArchiveEditorModal>
    </>
  );
}

export default connect(null, { createRpaArchive })(RpaArchiveCreateFab);
