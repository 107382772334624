import React, { useCallback, useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import _ from 'lodash';
import { setSystemsFilters } from 'actions/SystemsActions';
import SearchIcon from '@mui/icons-material/Search';
import { connect } from 'react-redux';

function SystemsSearchBox({ search, setSystemsFilters }) {
  let [editor, setEditor] = useState(search || '');

  useEffect(() => {
    if (search !== editor) {
      setEditor(search || '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setEditor]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let onSetFilters = useCallback(_.debounce(setSystemsFilters, 100), [setSystemsFilters]);
  return (
    <TextField
      value={editor}
      id='input-with-icon-textfield'
      placeholder='Search...'
      variant='outlined'
      size='small'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='disabled' />
          </InputAdornment>
        )
      }}
      onChange={(event) => {
        setEditor(event.target.value);
        onSetFilters({ search: event.target.value || null });
      }}
    />
  );
}

export default connect(
  (state) => ({
    search: state.systems.filters.content.search
  }),
  {
    setSystemsFilters
  }
)(SystemsSearchBox);
