import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { connect } from 'react-redux';
import { exportSystems } from 'actions/SystemsActions';

function SystemsExportButton({ filters, onClick }) {
  return (
    <Tooltip title='Export CSV'>
      <IconButton
        color='default'
        aria-label='Download'
        onClick={() => onClick(filters)}
        size='small'>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}
const mapStateToProps = (state, props) => ({
  filters: state.systems.filters.content
});

function mapDispatchToProps(dispatch) {
  return {
    onClick: (filters) => dispatch(exportSystems(filters))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemsExportButton);
