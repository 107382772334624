import { IconButton, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import React from 'react';
import { exportPhysicalConstraints } from 'actions/PhysicalConstraintsActions';
import { useDispatch, useSelector } from 'react-redux';

const PhysicalConstraintsExportButton = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.physicalconstraints.filters.content);
  return (
    <Tooltip title='Export CSV'>
      <IconButton
        color='default'
        aria-label='Download'
        onClick={() => dispatch(exportPhysicalConstraints(filters))}
        size='small'>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
};

export default PhysicalConstraintsExportButton;
