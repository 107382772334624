import React from 'react';
import { useTranslation } from 'react-i18next';

import IssueTypes from 'pages/issueMngt/issues/detail/IssueTypes';

import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import CampaignItemAutoComplete from 'pages/issueMngt/campaigns/commons/CampaignItemAutoComplete';

const IssueTemplateConfigurationGeneralSection = ({
  issueName,
  issueDescription,
  issueType,
  issueCampaign,
  handleChange
}) => {
  const { t } = useTranslation();

  const types = [
    IssueTypes.BUG,
    IssueTypes.VULNERABILITY,
    IssueTypes.WARNING,
    IssueTypes.ALERT,
    IssueTypes.TASK,
    IssueTypes.FEATURE_REQUEST,
    IssueTypes.QUESTION
  ];

  return <>
    <TextField
      variant="standard"
      fullWidth
      margin='dense'
      required
      label='Issue Name'
      placeholder="Enter the issue's name"
      value={issueName || ''}
      onChange={(event) => handleChange({ name: event.target.value })} />
    <TextField
      variant="standard"
      fullWidth
      margin='dense'
      multiline
      label='Issue Description'
      placeholder="Enter the issue's description"
      value={issueDescription || ''}
      onChange={(event) => handleChange({ description: event.target.value })} />
    <FormControl variant="standard" fullWidth margin='dense'>
      <InputLabel id='issue-type-select-label' required>
        Type
      </InputLabel>
      <Select
        variant="standard"
        labelId='issue-type-select-label'
        id='issue-type-select'
        value={issueType || ''}
        onChange={(event) => handleChange({ issueType: event.target.value })}>
        {types.map((issueType) => (
          <MenuItem key={issueType} value={issueType}>
            {t(`issueMngt.issues.types.${issueType}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <CampaignItemAutoComplete
      value={issueCampaign}
      handleChange={(campaign) => handleChange({ campaign })}
    />
  </>;
};

export default IssueTemplateConfigurationGeneralSection;
