import React from 'react'

export default class OpenCloseHandler extends React.Component {
  
  state={
    open: false
  }

  render(){
    let {children, ...otherProps} = this.props;
    return React.createElement(children, {
      ...otherProps,
      onToggleOpen: () => this.setState({open: !this.state.open}),
      isOpen: this.state.open,
    });
  }

}

export function withOpenCloseHandler(component){
  return class extends React.Component {

    state = {
      open: false
    }

    render() {
      return React.createElement(component, {
        ...this.props,
        onToggleOpen: () => this.setState({ open: !this.state.open }),
        isOpen: this.state.open,
      });
    }

  }
}