import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AddFAB } from '../../../components/fab';
import TaskItem from './TasksListItem';
import TaskEditorModal from './TaskEditorModal';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';

class TasksList extends Component {
  state = {
    createOpen: false,
    editOpen: false,
    taskToEdit: undefined
  };

  handleCreate(task) {
    this.props.createTask(task).then(task => {
      this.setState({ createOpen: false });
      if (task) {
        this.props.history.push(`/tasks/${task.uuid}`);
      }
    });
  }

  handleEdit(task) {
    this.props
      .modifyTask(task)
      .then(() => this.setState({ editOpen: false, taskToEdit: undefined }));
  }

  render() {
    let { t } = this.props;
    return (
      <div>
        {this.props.tasks.map(t => (
          <TaskItem
            key={t.uuid}
            history={this.props.history}
            task={t}
            modifyTask={task => this.setState({ editOpen: true, taskToEdit: task })}
            deleteTask={this.props.deleteTask}
          />
        ))}
        <Authorize hasAnyPermissions={[Permissions.TASKS_ADMIN, Permissions.PROPOSE]}>
          <AddFAB onClick={() => this.setState({ createOpen: true })} />
        </Authorize>
        {this.state.createOpen && (
          <TaskEditorModal
            title={t('tasks.createTitle')}
            open={this.state.createOpen}
            handleSubmit={task => this.handleCreate(task)}
            handleCancel={() => this.setState({ createOpen: false })}
          />
        )}

        {this.state.editOpen && (
          <TaskEditorModal
            key={this.state.taskToEdit && this.state.taskToEdit.uuid}
            title={t('tasks.modifyTitle')}
            open={this.state.editOpen}
            task={this.state.taskToEdit}
            handleSubmit={task => this.handleEdit(task)}
            handleCancel={() => this.setState({ editOpen: false })}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(TasksList);
