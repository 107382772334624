
import { fetchDataProductServicesTypes } from 'actions/DataProductsActions';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const DataProductServicesTypeAutoComplete = ({ value, onChange, ...props }) => {
  const [servicesTypes, setServicesTypes] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!value) {
      dispatch(fetchDataProductServicesTypes({ search: '' })).then((response) =>
        setServicesTypes(response?.content?.map((servicesType) => ({ servicesType })))
      );
    }
  }, [dispatch, setServicesTypes, value]);

  return (
    <ItemAutoComplete
      placeholder='Select a Services Type'
      itemName='Services Type'
      values={servicesTypes}
      value={{ servicesType: value }}
      nameProperty={'servicesType'}
      onSearch={(inputValue) => {
        dispatch(fetchDataProductServicesTypes({ search: inputValue })).then((response) =>
          setServicesTypes(response?.content?.map((servicesType) => ({ servicesType })))
        );
        onChange({ servicesType: inputValue });
      }}
      onChange={(newValue) => onChange(newValue)}
      {...props}
    />
  );
};

export default DataProductServicesTypeAutoComplete;
