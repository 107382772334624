import React from 'react';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid
} from '@mui/material';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  fetchTemplateEvent,
  modifyTemplateEvent,
  deleteTemplateEvent
} from '../../../../actions/TemplatesActions';
import { compose } from 'redux';
import { withLoader } from '../../../../components/loader/Loader';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import KVDefinitionList from '../../../../components/definition-list/KVDefinitionList';
import DataActorCardView from '../../../data_actors/commons/DataActorCardView';
import { ChipWrapper, ChipItem } from '../../../../components/chiplist/ChipList';
import { ImmediateButton, ProposeButton } from '../workflow/WorkflowActions';
import TeamCodeDecorator from '../../../settings/teams/common/TeamCodeDecorator';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
function TemplateEventDetailPage({ templateEvent, deleteTemplateEvent, modifyTemplateEvent }) {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        elements={[{ text: 'Templates', to: '/templates' }, { text: templateEvent.name }]}
      />
      <Tracking
        info={{
          pageTitle: `Event Template: ${templateEvent.name}`,
          category: EngagementCategories.TEMPLATES,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Event Template: {templateEvent.name}</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`EVENT_TEMPLATE:${templateEvent.sequenceId}`}
            pageTitle={`Event Template: ${templateEvent.name}`}></FavoritesWidget>
        </Grid>
      </Grid>
      <Typography variant='h4'>Event Template: {templateEvent.name}</Typography>
      <Card>
        <CardHeader
          title={templateEvent.name}
          subheader={templateEvent.createdAt}
          action={
            <TeamCodeDecorator teamCode={templateEvent?.teamCode}></TeamCodeDecorator>
          }></CardHeader>
        <CardContent>
          <div>{templateEvent.description}</div>
          <h4>Mappings</h4>
          <ChipWrapper>
            {templateEvent.mappingsCodes.map((mappingCode, index) => (
              <ChipItem key={index} label={mappingCode}></ChipItem>
            ))}
          </ChipWrapper>
          <KVDefinitionList item={templateEvent.data} header='Data'></KVDefinitionList>
          <h4>Status</h4>
          <div>{templateEvent.eventStatus}</div>
        </CardContent>
        <CardActions>
          <Button
            children={t('Manage')}
            component={Link}
            to={`/templates/events/${templateEvent.sequenceId}/workflow`}
          />
          <ProposeButton templateEvent={templateEvent}></ProposeButton>
          <ImmediateButton templateEvent={templateEvent}></ImmediateButton>
          <Authorize hasPermissions={[Permissions.TASKS_ADMIN]}>
            <ConfirmButton
              onClick={() => {
                deleteTemplateEvent(templateEvent.sequenceId).then(() =>
                  history.push('/templates/mappings')
                );
              }}>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </Authorize>
        </CardActions>
      </Card>
      <DataActorCardView dataActor={templateEvent.dataActor}></DataActorCardView>
    </>
  );
}

function mapStateToProps(state, props) {
  const sequenceId = props.match.params.sequenceId;
  return {
    templateEvent: state.templates.templatesEvents.content[sequenceId],
    sequenceId: props.match.params.sequenceId
  };
}

const mapDispatchToProps = {
  fetchTemplateEvent,
  modifyTemplateEvent,
  deleteTemplateEvent
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchTemplateEvent, sequenceId }) => fetchTemplateEvent(sequenceId),
    ready: ({ templateEvent }) => templateEvent !== undefined
  })
)(TemplateEventDetailPage);
