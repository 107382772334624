import { BlindataApi } from './ApiUtils';

const NOTIFICATION_URL = '/api/v1/social/notifications';

export default class NotificationsApi extends BlindataApi {
  getNotifications(params) {
    return this.get(NOTIFICATION_URL, params);
  }

  getNotification(sequenceId) {
    return this.get(`${NOTIFICATION_URL}/${sequenceId}`);
  }

  postNotification(notification) {
    return this.post(NOTIFICATION_URL, notification);
  }

  putNotification(notification) {
    return this.put(`${NOTIFICATION_URL}/${notification.sequenceId}`, notification);
  }

  patchNotification(notification) {
    return this.patch(`${NOTIFICATION_URL}/${notification.sequenceId}`, notification);
  }

  markAllNotificationsAsRead() {
    return this.patchAll(NOTIFICATION_URL);
  }

  deleteNotification(sequenceId) {
    return this.delete(`${NOTIFICATION_URL}/${sequenceId}`);
  }

  deleteAllNotifications() {
    return this.delete(NOTIFICATION_URL)
  }

  getUnreadCount() {
    return this.get(`${NOTIFICATION_URL}/*/unread-count`);
  }
}
