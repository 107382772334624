import * as React from "react";
const SvgDenodoLong = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 734.13 197.83"
    {...props}
  >
    <defs>
      <clipPath id="DenodoLong_svg__a">
        <path d="M103 101h88v96.828h-88Zm0 0" />
      </clipPath>
      <clipPath id="DenodoLong_svg__b">
        <path d="M304 101h91v96.828h-91Zm0 0" />
      </clipPath>
      <clipPath id="DenodoLong_svg__c">
        <path d="M405 55h91v142.828h-91Zm0 0" />
      </clipPath>
      <clipPath id="DenodoLong_svg__d">
        <path d="M0 55h90v142.828H0Zm0 0" />
      </clipPath>
      <clipPath id="DenodoLong_svg__e">
        <path d="M510 100h90v97.828h-90Zm0 0" />
      </clipPath>
      <clipPath id="DenodoLong_svg__f">
        <path d="M696 78h38.129v39H696Zm0 0" />
      </clipPath>
      <clipPath id="DenodoLong_svg__g">
        <path d="M696 22h38.129v39H696Zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#DenodoLong_svg__a)">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "#231f21",
          fillOpacity: 1,
        }}
        d="M154.637 197.402c-16.39 0-28.946-4.105-37.688-12.316-8.73-8.215-13.097-19.906-13.097-35.094 0-14.855 4.074-26.715 12.21-35.574 8.141-8.863 19.528-13.297 34.168-13.297 13.297 0 23.297 3.762 29.989 11.27 6.699 7.511 10.05 18.265 10.05 32.261v10.207h-59.285c.254 4.215 1.11 7.75 2.555 10.57 1.438 2.844 3.344 5.09 5.719 6.774 2.316 1.672 5.023 2.875 8.113 3.613 3.09.715 6.457 1.079 10.125 1.079 3.149 0 6.238-.364 9.27-1.079 3.047-.738 5.832-1.699 8.355-2.882 2.207-1.032 4.266-2.141 6.14-3.329 1.891-1.18 3.415-2.238 4.602-3.156h2.864v23.426c-2.211.91-4.243 1.773-6.07 2.55-1.833.782-4.348 1.575-7.536 2.395a78.344 78.344 0 0 1-9.09 1.899c-3.11.449-6.918.683-11.394.683m8.965-59.64c-.157-6-1.485-10.559-3.977-13.7-2.508-3.132-6.332-4.707-11.484-4.707-5.246 0-9.364 1.653-12.332 4.954-2.965 3.289-4.598 7.773-4.899 13.453h32.692"
      />
    </g>
    <path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#231f21",
        fillOpacity: 1,
      }}
      d="M290.297 194.969h-27.211v-45.133c0-3.676-.14-7.336-.434-10.996-.273-3.637-.797-6.324-1.582-8.05-.918-2.052-2.261-3.524-4.011-4.419-1.754-.89-4.106-1.34-7.036-1.34-2.214 0-4.46.438-6.722 1.297-2.274.871-4.696 2.246-7.274 4.133v64.508h-27.05v-91h27.05v10.047c4.438-3.95 8.75-7.024 12.953-9.25 4.192-2.207 8.852-3.313 13.95-3.313 8.867 0 15.636 2.934 20.332 8.793 4.691 5.86 7.035 14.36 7.035 25.48v59.243"
    />
    <g clipPath="url(#DenodoLong_svg__b)">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "#231f21",
          fillOpacity: 1,
        }}
        d="M394.477 149.527c0 14.973-3.903 26.762-11.711 35.375-7.805 8.621-18.864 12.926-33.149 12.926-14.152 0-25.172-4.305-33.031-12.926-7.848-8.613-11.766-20.402-11.766-35.375 0-15.129 3.918-26.972 11.766-35.539 7.86-8.562 18.879-12.847 33.031-12.847 14.235 0 25.281 4.316 33.113 12.925 7.832 8.625 11.747 20.442 11.747 35.461m-27.762.164c0-5.402-.434-9.898-1.281-13.492-.848-3.601-2.02-6.472-3.512-8.629-1.61-2.28-3.414-3.855-5.418-4.746-2.012-.886-4.313-1.336-6.887-1.336-2.41 0-4.605.395-6.562 1.176-1.953.785-3.754 2.281-5.399 4.496-1.547 2.11-2.785 5-3.715 8.672-.925 3.676-1.39 8.29-1.39 13.86 0 5.566.437 10.062 1.312 13.492.88 3.433 2.004 6.171 3.407 8.218 1.433 2.118 3.238 3.657 5.402 4.63 2.156.972 4.555 1.452 7.176 1.452 2.168 0 4.379-.48 6.613-1.453 2.242-.972 4.035-2.43 5.367-4.375 1.61-2.32 2.817-5.125 3.649-8.39.828-3.27 1.238-7.793 1.238-13.575"
      />
    </g>
    <g clipPath="url(#DenodoLong_svg__c)">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "#231f21",
          fillOpacity: 1,
        }}
        d="M467.633 55.797v52.543c-3.875-2.094-10.153-5.192-13.555-6.098-2.957-.789-6.629-1.5-11.168-1.5-4.988 0-9.738 1.153-14.215 3.45a34.505 34.505 0 0 0-11.441 9.351 36.735 36.735 0 0 0-2.125 2.965c-6.23 8.27-9.352 19.133-9.352 32.601 0 14.957 3.922 26.75 11.786 35.375 7.859 8.618 18.875 12.926 33.05 12.926 14.278 0 25.317-4.308 33.121-12.926 7.567-8.355 11.543-19.707 11.778-34.015V55.797Zm-1.18 107.043c-.816 3.277-2.035 6.066-3.629 8.394-1.34 1.942-3.133 3.403-5.379 4.375-2.23.961-4.441 1.461-6.601 1.461-2.63 0-5.028-.5-7.188-1.46-2.168-.973-3.969-2.516-5.422-4.618-1.382-2.058-2.515-4.8-3.39-8.23-.883-3.426-1.32-7.93-1.32-13.492 0-5.567.472-10.188 1.394-13.864.926-3.672 2.176-6.558 3.71-8.672 1.645-2.207 3.458-3.718 5.415-4.5 1.957-.773 4.14-1.171 6.57-1.171 2.575 0 4.867.457 6.867 1.34 2.024.89 3.829 2.468 5.418 4.734 1.5 2.168 2.668 5.047 3.516 8.633.856 3.593 1.277 8.097 1.277 13.5 0 5.773-.41 10.304-1.238 13.57"
      />
    </g>
    <g clipPath="url(#DenodoLong_svg__d)">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "#231f21",
          fillOpacity: 1,
        }}
        d="M61.86 55.797v52.543c-3.88-2.094-10.157-5.192-13.563-6.098-2.953-.789-6.625-1.5-11.16-1.5-4.989 0-9.739 1.153-14.219 3.45a34.598 34.598 0 0 0-11.445 9.351 38.727 38.727 0 0 0-2.121 2.965C3.125 124.778 0 135.64 0 149.109c0 14.957 3.926 26.75 11.785 35.375 7.863 8.618 18.871 12.926 33.05 12.926 14.278 0 25.321-4.308 33.122-12.926 7.566-8.355 11.547-19.707 11.777-34.015V55.797ZM60.683 162.84c-.825 3.277-2.036 6.066-3.637 8.394-1.34 1.942-3.133 3.403-5.375 4.375-2.234.961-4.445 1.461-6.606 1.461-2.629 0-5.023-.5-7.183-1.46-2.168-.973-3.977-2.516-5.418-4.618-1.387-2.058-2.52-4.8-3.403-8.23-.878-3.426-1.316-7.93-1.316-13.492 0-5.567.473-10.188 1.395-13.864.925-3.672 2.168-6.558 3.714-8.672 1.649-2.207 3.458-3.718 5.415-4.5 1.953-.773 4.136-1.171 6.566-1.171 2.578 0 4.867.457 6.871 1.34 2.016.89 3.824 2.468 5.422 4.734 1.492 2.168 2.66 5.047 3.516 8.633.851 3.593 1.27 8.097 1.27 13.5 0 5.773-.411 10.304-1.231 13.57"
      />
    </g>
    <g clipPath="url(#DenodoLong_svg__e)">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "#231f21",
          fillOpacity: 1,
        }}
        d="M599.707 149.11c0 14.956-3.898 26.753-11.707 35.367-7.805 8.625-18.855 12.933-33.121 12.933-14.172 0-25.192-4.308-33.047-12.933-7.863-8.614-11.789-20.41-11.789-35.368 0-15.132 3.926-26.976 11.789-35.53 7.856-8.571 18.875-12.852 33.047-12.852 14.219 0 25.242 4.3 33.078 12.93 7.84 8.62 11.75 20.425 11.75 35.452m-27.75.153c0-5.399-.418-9.895-1.262-13.48-.86-3.598-2.035-6.477-3.53-8.637-1.599-2.27-3.411-3.844-5.419-4.739-2-.886-4.293-1.344-6.867-1.344-2.418 0-4.621.399-6.57 1.18-1.957.781-3.766 2.285-5.41 4.5-1.54 2.102-2.778 4.992-3.715 8.676-.922 3.664-1.395 8.285-1.395 13.844 0 5.566.434 10.074 1.324 13.5.871 3.437 2.004 6.172 3.399 8.226 1.437 2.106 3.242 3.657 5.414 4.621 2.16.977 4.547 1.457 7.183 1.457 2.168 0 4.371-.48 6.602-1.457 2.246-.964 4.035-2.43 5.375-4.375 1.601-2.324 2.816-5.125 3.637-8.394.82-3.262 1.234-7.793 1.234-13.578"
      />
    </g>
    <path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#f0534a",
        fillOpacity: 1,
      }}
      d="m623.488 78.648 13.953 24.141-24.168 13.945L599.32 92.57l24.168-13.922M652.77 111.625h27.914v27.914H652.77v-27.914"
    />
    <g clipPath="url(#DenodoLong_svg__f)">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "#f0534a",
          fillOpacity: 1,
        }}
        d="m696.004 102.785 13.96-24.137 24.169 13.926-13.961 24.156-24.168-13.945"
      />
    </g>
    <g clipPath="url(#DenodoLong_svg__g)">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "#f0534a",
          fillOpacity: 1,
        }}
        d="m709.965 60.926-13.957-24.168 24.16-13.957 13.965 24.172-24.168 13.953"
      />
    </g>
    <path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#231f21",
        fillOpacity: 1,
      }}
      d="M680.684 83.742h-27.91V55.82h27.91v27.922"
    />
    <path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#f0534a",
        fillOpacity: 1,
      }}
      d="M680.684 27.91h-27.91V-.004l27.91.004v27.91M637.441 36.758 623.488 60.93 599.32 46.977l13.953-24.172 24.168 13.953"
    />
  </svg>
);
export default SvgDenodoLong;
