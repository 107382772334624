import React from 'react';
import { Grid } from '@mui/material';
import NumberBox from 'components/graphics/NumberBox';

function MetricsTableSection({ results }) {
  return (
    <>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={4}>
          <NumberBox
            label={'Items found'}
            number={results.itemsFound}
            color={
              'linear-gradient(to bottom right, rgba(113, 135, 146, 0.8), rgba(113, 135, 146, 1))'
            }
          />
        </Grid>
        <Grid item xs={4}>
          <NumberBox
            label={'Max Depth'}
            number={results.maxDepth}
            color={'linear-gradient(to bottom right, rgba(0, 116, 153, 0.8), rgba(0, 116, 153, 1))'}
          />
          <Grid item xs={2} />
        </Grid>
      </Grid>
    </>
  );
}

export default MetricsTableSection;
