import { BlindataApi, fetchAll } from './ApiUtils';

const defaultPageSize = 20;

export default class DataActorsApi extends BlindataApi {
  postDataActor(dataActor) {
    return this.post('/api/v1/data_actors', dataActor);
  }

  getAllDataActor() {
    return fetchAll(this.getDataActors.bind(this));
  }

  getDataActors(page, size = 20) {
    return this.getDataActorsFiltered(page, size);
  }

  getDataActorsFiltered(page, size, filters = {}) {
    return this.get(`/api/v1/data_actors`, { page, size, ...filters });
  }

  searchDataActors(search, { page = 0, size } = {}) {
    return this.get(`/api/v1/data_actors`, { search, page, size });
  }

  getDataActor(uuid) {
    return this.get(`/api/v1/data_actors/${uuid}`);
  }

  deleteDataActor(dataActor) {
    return this.delete(`/api/v1/data_actors/${dataActor.uuid}`);
  }

  putDataActor(dataActor) {
    return this.put(`/api/v1/data_actors/${dataActor.uuid}`, dataActor);
  }

  getAllTaskAssignments(dataActor) {
    return fetchAll((page) => this.getTaskAssignments(dataActor, page));
  }

  getTaskAssignments(dataActor, page) {
    return this.get(`/api/v1/data_actors/${dataActor.uuid}/tasks`, { page, size: defaultPageSize });
  }

  postTaskAssignment(dataActor, taskAssignment) {
    return this.post(`/api/v1/data_actors/${dataActor.uuid}/tasks`, taskAssignment);
  }

  deleteTaskAssignment(dataActor, taskAssignment) {
    return this.delete(`/api/v1/data_actors/${dataActor.uuid}/tasks/${taskAssignment.uuid}`);
  }

  getAllLoginAssignments(dataActor) {
    return fetchAll((page) => this.getLoginAssignments(dataActor, page));
  }

  getLoginAssignments(dataActor, page) {
    return this.get(`/api/v1/data_actors/${dataActor.uuid}/system_logins`, {
      page,
      defaultPageSize
    });
  }

  postLoginAssignment(dataActor, loginAssignment) {
    return this.post(`/api/v1/data_actors/${dataActor.uuid}/system_logins`, loginAssignment);
  }

  deleteLoginAssignment(dataActor, loginAssignment) {
    return this.delete(
      `/api/v1/data_actors/${dataActor.uuid}/system_logins/${loginAssignment.uuid}`
    );
  }

  exportDataActors(params) {
    return this.download('/api/v1/data_actors/*/export', {
      ...params,
      includeProperties: 'false'
    });
  }
}
