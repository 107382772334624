import React from 'react';
import { Route, Redirect } from 'react-router';
import DashboardPage from './dashboards/DashboardPage';
import ReportPage from './report/ReportPage';
import AudiencePage from './audience/AudiencePage';
import DataSubjectsNavbar from './DataSubjectsNavbar';

const DataSubjectRoutes = props => (
  <div>
    <DataSubjectsNavbar />
    <Route path={props.match.url + '/dashboards'} component={DashboardPage} />
    <Route path={props.match.url + '/report'} component={ReportPage} />
    <Route path={props.match.url + '/audience'} component={AudiencePage} />
    <Route
      exact
      path={props.match.url}
      render={() => <Redirect to={props.match.url + '/report'} />}
    />
  </div>
);

export default DataSubjectRoutes;
