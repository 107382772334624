import React, { useState } from 'react';

import QualityProbesConnectionsListItem from 'pages/quality/probes/connections/list/QualityProbesConnectionsListItem';
import QualityProbesConnectionModal from 'pages/quality/probes/connections/commons/QualityProbesConnectionModal';
import AlertDialog from 'components/alertdialog/AlertDialog';
import PaginatedPanel from 'components/pagination/PaginatedPanel';

const QualityProbesConnectionsList = ({
  connections,
  modifyConnection,
  deleteConnection,
  currentPage,
  onFetch
}) => {
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [modifyModalOpen, setModifyModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <PaginatedPanel currentPage={currentPage} onPageSelection={(page) => onFetch({ page })}>
        {connections.map((conn) => (
          <QualityProbesConnectionsListItem
            key={conn.uuid}
            connection={conn}
            handleModify={() => {
              setSelectedConnection(conn);
              setModifyModalOpen(true);
            }}
            handleDelete={() => {
              setSelectedConnection(conn);
              setDeleteModalOpen(true);
            }}
          />
        ))}
      </PaginatedPanel>

      {modifyModalOpen && (
        <QualityProbesConnectionModal
          title='Modify connection'
          open={modifyModalOpen}
          connection={selectedConnection}
          onSubmit={(connection) => {
            modifyConnection(connection);
            setModifyModalOpen(false);
          }}
          onClose={() => setModifyModalOpen(false)}
        />
      )}

      <AlertDialog
        open={deleteModalOpen}
        message={
          'Do you really want to delete ' +
          (selectedConnection ? `Connection ${selectedConnection.name}` : 'this connection') +
          '?'
        }
        onOk={() => {
          deleteConnection(selectedConnection);
          setDeleteModalOpen(false);
        }}
        onCancel={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default QualityProbesConnectionsList;
