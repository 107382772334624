import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { connect } from 'react-redux';
import { exportProbeConnections } from 'actions/quality/probes/ProbesConnectionsActions';

function QualityProbesConnectionsExportButton({ onClick }) {
  return (
    <Tooltip title='Export YML'>
      <IconButton color='default' aria-label='Download' onClick={() => onClick()} size='small'>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: () => dispatch(exportProbeConnections())
  };
}

export default connect(null, mapDispatchToProps)(QualityProbesConnectionsExportButton);
