import React from 'react';
import PropTypes from 'prop-types';
import DefinitionList from '../../../components/definition-list/DefinitionList';
import { useTranslation } from 'react-i18next';
import { CardHeader, Card, CardContent, CardActions } from '@mui/material';
import ResourceTypes from '../../../constants/ResourceTypes';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';

const DataCategoryCardView = ({ dataCategory, actions, children }) => {
  const [t] = useTranslation();

  return (
    <Card>
      <CardHeader
        title={dataCategory.name}
        subheader={
          <div>
            <div>
              <label>{t('data_categories.fields.clearance.label')}: </label>
              <b>
                {t([
                  `data_categories.fields.clearance.values.${dataCategory.clearance}`,
                  'commons.placeholders.undefined'
                ])}
              </b>
            </div>
            <div>
              <label>{t('data_categories.fields.class')}: </label>
              <b>{dataCategory.dataClass || t('commons.placeholders.undefined')}</b>
            </div>
          </div>
        }
      />
      <CardContent>
        {dataCategory.description}
        <h4>{t('data_categories.fields.dataSubjectsCategories.header')}</h4>
        <DefinitionList
          items={dataCategory.dataSubjectsCategories}
          labelProperty='name'
          dataProperties={['description']}
        />
        <CustomPropertiesView
          additionalProperties={dataCategory.additionalProperties}
          resourceType={ResourceTypes.DATA_CATEGORY}
        />
        {children}
      </CardContent>
      {actions}
    </Card>
  );
};

DataCategoryCardView.propTypes = {
  dataCategory: PropTypes.object.isRequired,
  actions: PropTypes.instanceOf(CardActions)
};

export default DataCategoryCardView;
