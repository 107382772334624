import React from 'react';

import { Card, CardHeader, CardContent, CardActions } from '@mui/material';

import QualityChecksTimelineChart from 'pages/quality/kqis/quality_checks/commons/QualityChecksTimelineCard/QualityChecksTimelineChart';
import QualityChecksTimelineFilters from 'pages/quality/kqis/quality_checks/commons/QualityChecksTimelineCard/QualityChecksTimelineFilters';

const styles = {
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    minHeight: '250px',
    overflowX: 'auto'
  }
};

const QualityChecksTimelineCard = ({
  qualitySuiteUuid,
  qualityCheckUuid,
  data,
  filters,
  setQualityChecksTimelineFilters,
  sx,
  chartWidth,
  chartHeight,
  withDatesFilters = true,
  inWatchlist
}) => {
  return (
    <Card sx={sx}>
      <CardHeader
        title={!inWatchlist ? 'Quality Checks Timeline' : 'Watched Quality Checks Timeline'}
      />
      <CardContent sx={styles.cardContent}>
        <QualityChecksTimelineChart
          inWatchlist={inWatchlist}
          qualitySuiteUuid={qualitySuiteUuid}
          qualityCheckUuid={qualityCheckUuid}
          data={data}
          precision={filters.precision}
          width={chartWidth}
          height={chartHeight}
        />
      </CardContent>
      <CardActions style={{ justifyContent: 'space-evenly' }}>
        <QualityChecksTimelineFilters
          withDatesFilters={withDatesFilters}
          filters={filters}
          setQualityChecksTimelineFilters={setQualityChecksTimelineFilters}
        />
      </CardActions>
    </Card>
  );
};

export default QualityChecksTimelineCard;
