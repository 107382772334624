import { buildApiCallAction } from './ActionUtils';
import { FETCH_SECURITY_MEASURES } from '../constants/ActionTypes';
import SecurityMeasureApi from '../api/SecurityMeasureApi';

const api = new SecurityMeasureApi();

export const searchSecurityMeasures = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getSecurityMeasure(params),
    actionType: FETCH_SECURITY_MEASURES
  });

export const searchTransferExtraEuOrganizations = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTransferExtraEuOrganizations(params),
    actionType: 'getTransferExtraEuOrganizations'
  });
