import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { t } from 'i18next';
import DatePicker from 'components/pickers/DatePicker';

export const MyIssuesFiltersModal = ({ open, setOpen, myIssuesFilters, setMyIssuesFilters }) => {
  const [patchedFilters, setPatchedFilters] = useState();

  const mergedFilters = { ...myIssuesFilters, ...patchedFilters };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{t('homepage.dashboard.myIssuesPanel.filtersModal.title')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              {t('homepage.dashboard.myIssuesPanel.filtersModal.plannedStartDate')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  margin='0px'
                  label='From Date'
                  maxDate={mergedFilters?.plannedStartDateLt}
                  value={mergedFilters?.plannedStartDateGt}
                  onChange={(date) =>
                    setPatchedFilters({ ...patchedFilters, plannedStartDateGt: date })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  margin='0px'
                  label='To Date'
                  minDate={mergedFilters?.plannedStartDateGt}
                  value={mergedFilters?.plannedStartDateLt}
                  onChange={(date) =>
                    setPatchedFilters({ ...patchedFilters, plannedStartDateLt: date })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              {t('homepage.dashboard.myIssuesPanel.filtersModal.dueDate')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  margin='0px'
                  label='From Date'
                  maxDate={mergedFilters?.dueDateLt}
                  value={mergedFilters?.dueDateGt}
                  onChange={(date) => setPatchedFilters({ ...patchedFilters, dueDateGt: date })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  margin='0px'
                  label='To Date'
                  minDate={mergedFilters?.dueDateGt}
                  value={mergedFilters?.dueDateLt}
                  onChange={(date) => setPatchedFilters({ ...patchedFilters, dueDateLt: date })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.clear')}
          onClick={() => {
            setOpen(false);
            setMyIssuesFilters({});
          }}
        />
        <Button
          variant='outlined'
          color='primary'
          children={t('commons.actions.apply')}
          onClick={() => {
            setOpen(false);
            setMyIssuesFilters({ ...myIssuesFilters, ...patchedFilters });
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
