import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withLoader } from '../../../components/loader/Loader';
import { modifyDataCategory, deleteDataCategory } from '../../../actions/DataCategoriesActions';
import {
  requestLogicalFields,
  createLogicalField,
  deleteLogicalField
} from '../../../actions/LogicalFieldsActions';
import { Card, CardActions, CardHeader, CardContent } from '@mui/material';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import DefinitionList from '../../../components/definition-list/DefinitionList';
import Permissions from '../../../constants/Permissions';
import ConfirmButton from '../../../components/confirmbutton/ConfirmButton';
import LogicalFieldsEditButton from '../commons/LogicalFieldsEditButton';
import DataCategoryEditButton from '../commons/DataCategoryEditButton';
import HistoryButtonLink from '../../history/commons/HistoryButtonLink';
import HistoryTargetTypes from '../../history/commons/HistoryTargetTypes';
import TeamCodeDecorator from '../../settings/teams/common/TeamCodeDecorator';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';
import ResourceTypes from '../../../constants/ResourceTypes';
import { withRouter } from 'react-router';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import AuthorizeTeamStewardship from 'components/permissions/AuthorizeTeamStewardship';
import CardDescription from 'components/carddescription/CardDescription';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

function DataCategoryDetailSection(props) {
  let { t, dataCategory, logicalFields } = props;
  return (
    <Card key={dataCategory.uuid} style={{ margin: '8px 0 8px 0' }}>
      <CardHeader
        title={dataCategory.name}
        subheader={
          <div>
            <div>
              <label>{t('data_categories.fields.clearance.label')}: </label>
              <b>
                {t([
                  `data_categories.fields.clearance.values.${dataCategory.clearance}`,
                  'commons.placeholders.undefined'
                ])}
              </b>
            </div>
            <div>
              <label>{t('data_categories.fields.class')}: </label>
              <b>{dataCategory.dataClass || t('commons.placeholders.undefined')}</b>
            </div>
          </div>
        }
        action={<TeamCodeDecorator teamCode={dataCategory.teamCode}></TeamCodeDecorator>}
      />
      <CardContent>
        <CardDescription>{dataCategory.description}</CardDescription>
        {_.get(dataCategory, 'dataSubjectsCategories.length', 0) > 0 && (
          <>
            <h4>{t('data_categories.fields.dataSubjectsCategories.header')}</h4>
            <DefinitionList
              items={dataCategory.dataSubjectsCategories}
              labelProperty='name'
              dataProperties={['description']}
            />
          </>
        )}
        <CustomPropertiesView
          additionalProperties={dataCategory.additionalProperties}
          resourceType={ResourceTypes.DATA_CATEGORY}
        />
        <h4>{t('data_categories.logicalFields.header')}</h4>
        <ChipWrapper>
          {logicalFields.map((field) => (
            <ChipItem
              key={field.uuid}
              id={field.uuid}
              label={field.name}
              tooltip={field.description}
              to={`/logicalfields/${field.uuid}`}
            />
          ))}
        </ChipWrapper>
      </CardContent>
      <CardActions>
        <AuthorizeTeamStewardship
          resourceType={ResourceTypes.DATA_CATEGORY}
          resourceIdentifier={dataCategory.uuid}
          hasPermissions={[Permissions.DATA_CATEGORIES_EDITOR]}
          teamCode={dataCategory.teamCode}
          teamAccessLevels={[TeamAccessLevels.WRITE]}>
          <DataCategoryEditButton
            dataCategory={dataCategory}
            modifyDataCategory={props.modifyDataCategory}
          />
        </AuthorizeTeamStewardship>
        <AuthorizeTeamStewardship
          resourceType={ResourceTypes.DATA_CATEGORY}
          resourceIdentifier={props.dataCategory.uuid}
          hasPermissions={[Permissions.DATA_CATEGORIES_EDITOR]}
          teamCode={dataCategory.teamCode}
          teamAccessLevels={[TeamAccessLevels.WRITE]}>
          <LogicalFieldsEditButton
            label={t('data_categories.actions.defineLogicalFields')}
            dataCategory={dataCategory}
          />
        </AuthorizeTeamStewardship>
        <AuthorizeTeamStewardship
          resourceType={ResourceTypes.DATA_CATEGORY}
          resourceIdentifier={props.dataCategory.uuid}
          hasPermissions={[Permissions.DATA_CATEGORIES_ADMIN]}
          teamCode={dataCategory.teamCode}
          teamAccessLevels={[TeamAccessLevels.WRITE]}>
          <ConfirmButton
            children={t('data_categories.actions.delete')}
            message={t('data_categories.delete_confirmation', {
              dataCategoryName: dataCategory.name
            })}
            onClick={() => {
              props
                .deleteDataCategory(dataCategory)
                .then(() => props.history.replace('/datacategories'));
            }}
          />
        </AuthorizeTeamStewardship>
        <HistoryButtonLink
          targetType={HistoryTargetTypes.DATA_CATEGORY}
          targetIdentifier={dataCategory.uuid}></HistoryButtonLink>
        <div style={{ marginLeft: 'auto' }}>
          <WebActionsButton resource={dataCategory} page={TargetPages.DATA_CATEGORY_DETAIL_PAGE} />
        </div>
      </CardActions>
    </Card>
  );
}

DataCategoryDetailSection.propTypes = {
  dataCategoryUuid: PropTypes.string.isRequired,
  dataCategory: PropTypes.object.isRequired
};

const mapStateToProps = (state, { dataCategoryUuid }) => ({
  dataCategory: state.datacategories.content[dataCategoryUuid],
  logicalFields: state.logicalfields.contentByDataCategory[dataCategoryUuid] || []
});

export default _.flow([
  withLoader({
    onLoad: ({ dataCategory, requestLogicalFields }) => {
      if (dataCategory) requestLogicalFields(dataCategory);
    },
    dependencies: ['dataCategory', 'requestLogicalFields']
  }),
  withTranslation(),
  withRouter,
  connect(mapStateToProps, {
    modifyDataCategory,
    deleteDataCategory,
    requestLogicalFields,
    createLogicalField,
    deleteLogicalField
  })
])(DataCategoryDetailSection);
