import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material';

import { Link } from 'react-router-dom';
import { createUrlItem } from 'pages/Routes';

const styles = {
  section: {
    margin: '8px',
    width: '280px'
  },
  header: {
    userSelect: 'none'
  },
  paper: {
    width: '90%',
    margin: '8px',
    padding: '8px',
    textAlign: 'center',
    display: 'inline-block',
    backgroundColor: 'rgb(230, 230, 230)'
  },
  label: (node) => ({
    color: node ? 'white' : 'black',
    fontWeight: 600
  })
};

function GraphMenuNodeDetail({ node }) {
  let { t } = useTranslation();
  return (
    <div style={styles.section}>
      <h5 style={styles.header}>{t('graph.menu.selectedNodeHeader')}</h5>
      <Paper sx={styles.paper} style={{ backgroundColor: node && node.color }}>
        <div style={styles.label(node)}>{renderLink(node, t)}</div>
      </Paper>
    </div>
  );
}

function renderLink(node, t) {
  if (node && node.label) {
    if (createUrlItem(node.labels[0], node.data.uuid)) {
      return (
        <Link
          style={{ color: 'white', textDecoration: 'none' }}
          to={createUrlItem(node.labels[0], node.data.uuid)}>
          {node.label}
        </Link>
      );
    } else {
      return node.label;
    }
  } else {
    return t('graph.menu.doubleClickHint');
  }
}

export default GraphMenuNodeDetail;
