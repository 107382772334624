import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { TableCell, TableRow } from '@mui/material';
import QualitySemaphoreIcon from '../commons/QualitySemaphoreIcon';
import QualityTrendingIcon from '../commons/QualityTrendingIcon';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';
import ResourceTypes from 'constants/ResourceTypes';

function parseLastRun(lastRun) {
  if (!lastRun) return '-';
  var m = moment(lastRun);
  var dateCreation;
  if (!m.isValid()) dateCreation = '';
  else {
    dateCreation = m.format('DD MMM YYYY');
  }
  return `${dateCreation}`;
}

function QualityChecksTableRow({ qualityCheck, index }) {
  const history = useHistory();
  const labelId = `quality-checks-table-${index}`;

  return (
    <TableRow
      id={labelId}
      hover
      style={{ textDecoration: 'none' }}
      onClick={() => history.push(`/data-quality/kqis/checks/${qualityCheck.uuid}`)}>
      <TableCell padding='normal'>{qualityCheck.code}</TableCell>
      <TableCell padding='normal'>{qualityCheck.name}</TableCell>
      <TableCell align='center'>
        <QualitySemaphoreIcon semaphore={qualityCheck.lastSemaphore} />
      </TableCell>
      <TableCell align='center'>
        <QualityTrendingIcon trendIndicator={qualityCheck.trendIndicator} />
      </TableCell>
      <TableCell align='center'>{parseLastRun(qualityCheck.lastRun)}</TableCell>
      <TableCell align='center'>
        <IssueResourceListItemWidget
          key={qualityCheck.uuid}
          style={{ marginLeft: '15%' }}
          resourceType={ResourceTypes.QUALITY_CHECK}
          resourceIdentifier={qualityCheck.uuid}></IssueResourceListItemWidget>
      </TableCell>
      <TableCell align='center'>
        {!qualityCheck.enabled && <VariationTypeDecorator>{'DISABLED'}</VariationTypeDecorator>}
      </TableCell>
    </TableRow>
  );
}

export default QualityChecksTableRow;
