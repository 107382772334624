import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Grid, TablePagination, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchDataFlowPreviews,
  saveDataFlowPreviews,
  fetchQueryStatement
} from 'actions/QueryStatementsActions';
import DataFlowPreviewsTable from './DataFlowPreviewsTable';
import _ from 'lodash';
import SwitchPhysicalEntityLevel from '../commons/SwitchPhysicalEntityLevel';
import SaveDataFlowPreviewsButton from '../commons/SaveDataFlowPreviewsButton';
import UndoDataFlowPreviewsInsertButton from '../commons/UndoDataFlowPreviewsInsertButton';

const DataFlowPreviewsCard = ({
  content,
  queryStatement,
  fetchDataFlowPreviews,
  fetchQueryStatement,
  saveDataFlowPreviews
}) => {
  const { t } = useTranslation();
  const [currentPageOptions, setCurrentPageOptions] = useState({
    number: 0,
    size: 20
  });

  const [previewOptions, setPreviewOptions] = useState({
    generateAtPhysicalEntityLevel: false
  });

  const currentPage = {
    content: _.chunk(content, currentPageOptions.size)[currentPageOptions.number],
    totalElements: content.length,
    ...currentPageOptions
  };

  useEffect(() => {
    fetchDataFlowPreviews(queryStatement.uuid, previewOptions);
  }, [queryStatement, fetchDataFlowPreviews, previewOptions]);

  return (
    <Card>
      <CardHeader
        title={t('querystatements.dataFlowsPreview.card.title')}
        action={
          <>
            {content && content.length > 0 ? (
              <Grid container spacing={1} style={{ paddingRight: '16px' }}>
                <Grid item>
                  <SaveDataFlowPreviewsButton
                    queryStatement={queryStatement}
                    fetchQueryStatement={fetchQueryStatement}
                    saveDataFlowPreviews={saveDataFlowPreviews}
                    previewOptions={previewOptions}
                  />
                </Grid>
                <Grid item>
                  <UndoDataFlowPreviewsInsertButton queryStatement={queryStatement} />
                </Grid>
                <Grid item>
                  <SwitchPhysicalEntityLevel
                    previewOptions={previewOptions}
                    setPreviewOptions={setPreviewOptions}
                  />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </>
        }
      />
      <CardContent>
        {content && content.length > 0 ? (
          <>
            <DataFlowPreviewsTable
              dataFlowPreviews={currentPage.content}
              queryStatement={queryStatement}
              generateAtPhysicalEntityLevel={previewOptions.generateAtPhysicalEntityLevel}
            />
            <TablePagination
              component='div'
              style={{ overflowX: 'hidden' }}
              rowsPerPageOptions={[10, 20, 50]}
              page={currentPage.number}
              rowsPerPage={currentPage.size}
              count={currentPage.totalElements}
              onRowsPerPageChange={(event) =>
                setCurrentPageOptions({
                  ...currentPageOptions,
                  size: event.target.value
                })
              }
              onPageChange={(event, number) =>
                setCurrentPageOptions({
                  ...currentPageOptions,
                  number: number
                })
              }
            />
          </>
        ) : (
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>
              {t('querystatements.dataFlowsPreview.card.noPreviewPresent')}
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

function mapStateToProps(state, props) {
  return {
    content: state.dataflows.querystatements.dataflowpreviews.content
  };
}

export default connect(mapStateToProps, {
  fetchDataFlowPreviews,
  saveDataFlowPreviews,
  fetchQueryStatement
})(DataFlowPreviewsCard);
