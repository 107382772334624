import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddBoxIcon from '@mui/icons-material/AddBox';
import TableCell from 'components/tables/GenericTableCell';
import { t } from 'i18next';
import _ from 'lodash';
import SystemItemAutoComplete from 'pages/systems/commons/SystemItemAutoComplete';
import React, { useState } from 'react';
import PhysicalEntityItemAutoComplete from 'pages/systems/physical_entities/commons/PhysicalEntityItemAutoComplete';
import PhysicalFieldItemSelect from 'pages/systems/physical_entities/commons/PhysicalFieldItemSelect';
import { ConstraintTypes, ConstraintRules } from 'constants/PhysicalConstraintTypes';
import AutoComplete from 'components/autocomplete/AutoComplete';
import PhysicalConstraintSchemaAutoComplete from './PhysicalConstraintSchemaAutoComplete';

const PhysicalConstraintModal = ({ open, title, onClose, onSubmit, physicalConstraint }) => {
  const [patchedPhysicalConstraint, setPatchedPhysicalConstraint] = useState({});
  const [openPEConstraintModal, setOpenPEConstraintModal] = useState(false);
  const [openPFConstraintModal, setOpenPFConstraintModal] = useState(false);

  const mergedPhysicalConstraint = { ...physicalConstraint, ...patchedPhysicalConstraint };

  return (
    <>
      <Dialog open={open} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <SystemItemAutoComplete
            required
            value={mergedPhysicalConstraint.system}
            onChange={(system) =>
              setPatchedPhysicalConstraint({ ...patchedPhysicalConstraint, ...{ system: system } })
            }
          />
          <TextField
            required
            variant='standard'
            fullWidth
            placeholder='Name'
            label='Name'
            margin='dense'
            value={mergedPhysicalConstraint.name || ''}
            onChange={(event) =>
              setPatchedPhysicalConstraint({
                ...patchedPhysicalConstraint,
                ...{ name: event.target.value }
              })
            }
          />
          <PhysicalConstraintSchemaAutoComplete
            required
            value={mergedPhysicalConstraint.schema || ''}
            margin='dense'
            onChange={(value) =>
              setPatchedPhysicalConstraint({
                ...patchedPhysicalConstraint,
                ...{ schema: value?.schema }
              })
            }
          />
          <TextField
            variant='standard'
            fullWidth
            placeholder='Description'
            label='Description'
            multiline
            margin='dense'
            value={mergedPhysicalConstraint.description || ''}
            onChange={(event) =>
              setPatchedPhysicalConstraint({
                ...patchedPhysicalConstraint,
                ...{ description: event.target.value }
              })
            }
          />
          <AutoComplete
            required
            fullWidth
            label='Type'
            dataSource={Object.keys(ConstraintTypes)}
            value={mergedPhysicalConstraint.type || ''}
            showAllOptions
            margin='dense'
            onUpdateInput={(type) => {
              setPatchedPhysicalConstraint({ ...patchedPhysicalConstraint, ...{ type: type } });
            }}
            onChange={(type) => {
              setPatchedPhysicalConstraint({
                ...patchedPhysicalConstraint,
                ...{ type: type }
              });
            }}
          />
          <TextField
            variant='standard'
            fullWidth
            placeholder='Clause'
            label='Clause'
            multiline
            margin='dense'
            value={mergedPhysicalConstraint.clause || ''}
            onChange={(event) =>
              setPatchedPhysicalConstraint({
                ...patchedPhysicalConstraint,
                ...{ clause: event.target.value }
              })
            }
          />
          <TextField
            variant='standard'
            fullWidth
            placeholder='Access Method'
            label='Access Method'
            margin='dense'
            value={mergedPhysicalConstraint.accessMethod || ''}
            onChange={(event) =>
              setPatchedPhysicalConstraint({
                ...patchedPhysicalConstraint,
                ...{ accessMethod: event.target.value }
              })
            }
          />
          <AutoComplete
            label='Update rule'
            dataSource={ConstraintRules}
            fullWidth
            value={mergedPhysicalConstraint.updateRule || ''}
            showAllOptions
            margin='dense'
            onUpdateInput={(rule) =>
              setPatchedPhysicalConstraint({
                ...patchedPhysicalConstraint,
                ...{ updateRule: rule }
              })
            }
            onChange={(rule) =>
              setPatchedPhysicalConstraint({
                ...patchedPhysicalConstraint,
                ...{ updateRule: rule }
              })
            }
          />
          <AutoComplete
            label='Delete rule'
            dataSource={ConstraintRules}
            fullWidth
            value={mergedPhysicalConstraint.deleteRule || ''}
            showAllOptions
            margin='dense'
            onUpdateInput={(rule) =>
              setPatchedPhysicalConstraint({
                ...patchedPhysicalConstraint,
                ...{ deleteRule: rule }
              })
            }
            onChange={(rule) =>
              setPatchedPhysicalConstraint({
                ...patchedPhysicalConstraint,
                ...{ deleteRule: rule }
              })
            }
          />
          <EntitiesConstraints
            title='Physical Fields Constraints'
            onAdd={() => setOpenPFConstraintModal(true)}
            onDelete={(pfConstraint) =>
              setPatchedPhysicalConstraint({
                ...patchedPhysicalConstraint,
                physicalFieldsConstraints: _.filter(
                  mergedPhysicalConstraint.physicalFieldsConstraints,
                  (el) => !_.isEqual(pfConstraint, el)
                )
              })
            }
            entities={mergedPhysicalConstraint.physicalFieldsConstraints || []}
            extractName={({ target, referenced }) =>
              target
                ? `${target.targetPhysicalField?.physicalEntity?.name || ''}  ${
                    target.targetPhysicalField.name
                  }`
                : `${referenced.referencedPhysicalField?.physicalEntity?.name || ''}  ${
                    referenced?.referencedPhysicalField?.name || ''
                  }`
            }
          />
          <EntitiesConstraints
            title='Physical Entities Constraints'
            onAdd={() => setOpenPEConstraintModal(true)}
            onDelete={(peConstraint) =>
              setPatchedPhysicalConstraint({
                ...patchedPhysicalConstraint,
                physicalEntitiesConstraints: _.filter(
                  mergedPhysicalConstraint.physicalEntitiesConstraints,
                  (el) => !_.isEqual(peConstraint, el)
                )
              })
            }
            entities={mergedPhysicalConstraint.physicalEntitiesConstraints || []}
            extractName={({ target, referenced }) =>
              target
                ? `${target.targetPhysicalEntity.name}`
                : `${referenced?.referencedPhysicalEntity?.name || ''}`
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
              setPatchedPhysicalConstraint({});
            }}>
            {t('commons.actions.cancel')}
          </Button>
          <Button
            disabled={
              _.isEqual(physicalConstraint, mergedPhysicalConstraint) ||
              !mergedPhysicalConstraint.system ||
              !mergedPhysicalConstraint.name ||
              !mergedPhysicalConstraint.schema ||
              !mergedPhysicalConstraint.type
            }
            variant='contained'
            color='primary'
            onClick={() => {
              onSubmit(mergedPhysicalConstraint);
              onClose();
              setPatchedPhysicalConstraint({});
            }}>
            {t('commons.actions.submit')}
          </Button>
        </DialogActions>
      </Dialog>
      <AddEntityConstraintModal
        title='Physical Entity Constraint'
        open={openPEConstraintModal}
        EntityItemSelector={PhysicalEntityItemAutoComplete}
        targetTitle='Target Physical Entity'
        referencedTitle='Referenced Physical Entity'
        onClose={() => {
          setOpenPEConstraintModal(false);
        }}
        onSubmit={({ targetEntity, referencedEntity }) =>
          targetEntity &&
          setPatchedPhysicalConstraint(
            _.assign(patchedPhysicalConstraint, {
              physicalEntitiesConstraints: _.unionWith(
                mergedPhysicalConstraint.physicalEntitiesConstraints,
                [
                  {
                    targetPhysicalEntity: targetEntity,
                    referencedPhysicalEntity: referencedEntity
                  }
                ],
                _.isEqual
              )
            })
          )
        }
      />
      <AddEntityConstraintModal
        title='Physical Fields Constraint'
        open={openPFConstraintModal}
        EntityItemSelector={PhysicalEntityItemAutoComplete}
        EntityChildItemSelector={PhysicalFieldItemSelect}
        entityUuidProperty={{ key: 'physicalEntityUuid', value: 'uuid' }}
        targetTitle='Target Physical Field'
        referencedTitle='Referenced Physical Field'
        onClose={() => {
          setOpenPFConstraintModal(false);
        }}
        onSubmit={({ targetEntityChild, referencedEntityChild }) => {
          targetEntityChild &&
            setPatchedPhysicalConstraint(
              _.assign(patchedPhysicalConstraint, {
                physicalFieldsConstraints: _.unionWith(
                  mergedPhysicalConstraint.physicalFieldsConstraints,
                  [
                    {
                      targetPhysicalField: targetEntityChild,
                      referencedPhysicalField: referencedEntityChild
                    }
                  ],
                  _.isEqual
                )
              })
            );
        }}
      />
    </>
  );
};

const AddEntityConstraintModal = ({
  open,
  title,
  targetTitle,
  referencedTitle,
  onClose,
  onSubmit,
  entityUuidProperty,
  EntityItemSelector,
  EntityChildItemSelector
}) => {
  const [targetEntity, setTargetEntity] = useState(null);
  const [referencedEntity, setReferencedEntity] = useState(null);
  const [targetEntityChild, setTargetEntityChild] = useState(null);
  const [referencedEntityChild, setReferencedEntityChild] = useState(null);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <h4>{targetTitle}</h4>
        <EntityItemSelector value={targetEntity} onChange={(entity) => setTargetEntity(entity)} />
        {EntityChildItemSelector && (
          <EntityChildItemSelector
            {...{
              [entityUuidProperty.key]: targetEntity && targetEntity[entityUuidProperty.value]
            }}
            value={targetEntityChild}
            onChange={(childEntity) => setTargetEntityChild(childEntity)}
          />
        )}

        <h4>{referencedTitle}</h4>
        <EntityItemSelector
          value={referencedEntity}
          onChange={(entity) => setReferencedEntity(entity)}
        />
        {EntityChildItemSelector && (
          <EntityChildItemSelector
            {...{
              [entityUuidProperty.key]:
                referencedEntity && referencedEntity[entityUuidProperty.value]
            }}
            value={referencedEntityChild}
            onChange={(childEntity) => setReferencedEntityChild(childEntity)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}>
          {t('commons.actions.cancel')}
        </Button>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => {
            onSubmit({
              targetEntity: targetEntity,
              targetEntityChild: targetEntityChild,
              referencedEntity: referencedEntity,
              referencedEntityChild: referencedEntityChild
            });
            onClose();
          }}>
          {t('commons.actions.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EntitiesConstraints = ({ entities, title, onAdd, onDelete, extractName }) => {
  return (
    <TableContainer sx={{ paddingTop: 2, paddingBottom: 1 }}>
      <Typography variant='h6' sx={{ paddingBottom: 1, fontSize: '1em' }}>
        {title}
      </Typography>
      <Table style={{ overflowX: 'auto' }}>
        <TableHead>
          <TableCell size='small'>Target</TableCell>
          <TableCell size='small'>Referenced</TableCell>
          <TableCell align='right' sx={{ padding: 0, margin: 0 }}>
            <IconButton size='small' onClick={onAdd}>
              <AddBoxIcon />
            </IconButton>
          </TableCell>
        </TableHead>
        <TableBody>
          {entities.length > 0 ? (
            entities.map((peConstraint, index) => (
              <TableRow key={index}>
                <TableCell>{extractName({ target: peConstraint })}</TableCell>
                <TableCell>{extractName({ referenced: peConstraint })}</TableCell>
                <TableCell align='right'>
                  <IconButton onClick={() => onDelete(peConstraint)} size='small'>
                    <DeleteOutlineIcon fontSize='medium' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PhysicalConstraintModal;
