import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchPhysicalEntities } from '../../../../actions/PhysicalEntitiesActions';
import ItemAutoCompleteModal from '../../../../components/itemselector/ItemAutoCompleteModal';
import _ from 'lodash';

const PhysicalEntitySearchModal = ({
  title,
  physicalEntities,
  open,
  onSubmit,
  onCancel,
  searchPhysicalEntities
}) => (
  <ItemAutoCompleteModal
    title={title || 'Search Physical Entity'}
    itemName='Physical Entity'
    open={open}
    keyProperty='uuid'
    nameProperty='fullName'
    subheaderProperty='system.name'
    values={Object.values(physicalEntities)}
    onSearch={(searchText) => searchPhysicalEntities({ searchText })}
    handleCancel={onCancel}
    handleSubmit={onSubmit}
  />
);

PhysicalEntitySearchModal.propTypes = {
  title: PropTypes.string,
  systemUuid: PropTypes.string,
  physicalEntities: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  searchPhysicalEntities: PropTypes.func.isRequired
};

const mapStateToProps = (state, { systemUuid }) => ({
  physicalEntities: !systemUuid
    ? state.physicalentities.content
    : _.filter(
        state.physicalentities.content,
        (value) => _.get(value, 'system.uuid') === systemUuid
      )
});

export default connect(mapStateToProps, { searchPhysicalEntities })(PhysicalEntitySearchModal);
