import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import React, { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  clearAssignmentsFilters,
  setAssignmentsFilters
} from '../../../../actions/AssignmentsActions';
import { connect } from 'react-redux';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
import DataCategorySearchModal from '../../../datacategories/utils/DataCategorySearchModal';
import LogicalFieldSearchModal from '../../../datacategories/utils/LogicalFieldSearchModal';
import SystemsSearchModal from '../../../systems/commons/SystemsSearchModal';
import PhysicalEntitiesSearchModal from '../../../systems/physical_entities/commons/PhysicalEntitySearchModal';
import PhysicalFieldsSearchModal from '../../../systems/physical_entities/commons/PhysicalFieldSearchModal';
import { getIconShort, getTechnologyConfig } from 'constants/SystemTechnologyTypes';

const styles = {
  formControl: {
    margin: (theme) => theme.spacing(0),
    minWidth: '300px'
  }
};

function AssignmentsFilters({ filters, setAssignmentsFilters, clearAssignmentsFilters }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const [addDataCategory, setAddDataCategory] = useState(false);
  const [addLogicalField, setAddLogicalField] = useState(false);
  const [addSystem, setAddSystem] = useState(false);
  const [addPhysicalEntity, setAddPhysicalEntity] = useState(false);
  const [addPhysicalField, setAddPhysicalField] = useState(false);
  const [editor, setEditor] = useReducer((s, a) => ({ ...s, ...a }), filters);

  let {
    hasFilters,
    assignmentState,
    dataCategories,
    logicalFields,
    systems,
    physicalEntities,
    physicalFields
  } = editor;
  return (
    <React.Fragment>
      <IconButton
        aria-label='filter list assignments'
        style={{ float: 'right' }}
        color={hasFilters ? 'primary' : 'grey'}
        size={'small'}
        onClick={() => setOpen(true)}>
        <FilterListIcon />
      </IconButton>
      <Dialog fullWidth modal={'true'} open={open}>
        <DialogTitle>Assignments Filters</DialogTitle>
        <DialogContent>
          <FormControl variant='standard' sx={styles.formControl}>
            <InputLabel>Assignment State</InputLabel>
            <Select
              variant='standard'
              native={false}
              required
              defaultValue={assignmentState || ''}
              onChange={(event) => {
                setEditor({
                  assignmentState: [].concat([event.target.value])
                });
              }}>
              <MenuItem value={t('classification.assignments.state.confirmed')}>
                <ListItem dense={true}>
                  <ListItemText primary={t('classification.assignments.state.confirmed')} />
                </ListItem>
              </MenuItem>
              <MenuItem value={t('classification.assignments.state.pending')}>
                <ListItem dense={true}>
                  <ListItemText primary={t('classification.assignments.state.pending')} />
                </ListItem>
              </MenuItem>
              <MenuItem value={t('classification.assignments.state.refused')}>
                <ListItem dense={true}>
                  <ListItemText primary={t('classification.assignments.state.refused')} />
                </ListItem>
              </MenuItem>
            </Select>
          </FormControl>
          <br></br>
          <br></br>
          <Grid container>
            <Grid item xs>
              <Typography variant='subtitle1'>Systems</Typography>
              <ChipWrapper onRequestAdd={() => setAddSystem(true)}>
                {systems.map((system) => (
                  <ChipItem
                    icon={getIconShort(getTechnologyConfig(system.technology), {
                      width: '2em',
                      height: '2em'
                    })}
                    key={system.uuid}
                    id={system.uuid}
                    label={system.name}
                    tooltip={system.description}
                    onRequestDelete={() => {
                      setEditor({
                        systems: systems.filter((s) => s.uuid !== system.uuid)
                      });
                    }}
                  />
                ))}
              </ChipWrapper>
              <br></br>
              <Typography variant='subtitle1'>Physical Entities</Typography>
              <ChipWrapper onRequestAdd={() => setAddPhysicalEntity(true)}>
                {physicalEntities.map((physicalEntity) => (
                  <ChipItem
                    key={physicalEntity.uuid}
                    id={physicalEntity.uuid}
                    label={physicalEntity.name}
                    tooltip={physicalEntity.description}
                    onRequestDelete={() => {
                      setEditor({
                        physicalEntities: physicalEntities.filter(
                          (pe) => pe.uuid !== physicalEntity.uuid
                        )
                      });
                    }}
                  />
                ))}
              </ChipWrapper>
              <br></br>
              <Typography variant='subtitle1'>Physical Fields</Typography>
              <ChipWrapper onRequestAdd={() => setAddPhysicalField(true)}>
                {physicalFields.map((physicalField) => (
                  <ChipItem
                    key={physicalField.uuid}
                    id={physicalField.uuid}
                    label={physicalField.name}
                    onRequestDelete={() => {
                      setEditor({
                        physicalFields: physicalFields.filter(
                          (pf) => pf.uuid !== physicalField.uuid
                        )
                      });
                    }}
                  />
                ))}
              </ChipWrapper>
            </Grid>
            <Grid item xs>
              <Typography variant='subtitle1'>Data Categories</Typography>
              <ChipWrapper onRequestAdd={() => setAddDataCategory(true)}>
                {dataCategories.map((dataCategory) => (
                  <ChipItem
                    key={dataCategory.uuid}
                    id={dataCategory.uuid}
                    label={dataCategory.name}
                    tooltip={dataCategory.description}
                    onRequestDelete={() => {
                      setEditor({
                        dataCategories: dataCategories.filter((dc) => dc.uuid !== dataCategory.uuid)
                      });
                    }}
                  />
                ))}
              </ChipWrapper>
              <br></br>
              <Typography variant='subtitle1'>Logical Fields</Typography>
              <ChipWrapper onRequestAdd={() => setAddLogicalField(true)}>
                {logicalFields.map((logicalField) => (
                  <ChipItem
                    key={logicalField.uuid}
                    id={logicalField.uuid}
                    label={logicalField.name}
                    tooltip={logicalField.description}
                    onRequestDelete={() => {
                      setEditor({
                        logicalFields: logicalFields.filter((lf) => lf.uuid !== logicalField.uuid)
                      });
                    }}
                  />
                ))}
              </ChipWrapper>
              <br></br>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.clear')}
            onClick={() => {
              clearAssignmentsFilters();
              setOpen(false);
            }}>
            {t('commons.actions.clear')}
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              setAssignmentsFilters(editor);
              setOpen(false);
            }}>
            {t('commons.actions.apply')}
          </Button>
        </DialogActions>
      </Dialog>
      <DataCategorySearchModal
        open={addDataCategory}
        onCancel={() => setAddDataCategory(false)}
        onSubmit={(dataCategory) => {
          setEditor({
            dataCategories: dataCategories.concat([dataCategory])
          });
          setAddDataCategory(false);
        }}
      />
      <LogicalFieldSearchModal
        open={addLogicalField}
        onCancel={() => setAddLogicalField(false)}
        onSubmit={(logicalField) => {
          setEditor({
            logicalFields: (logicalFields || []).concat([logicalField])
          });
          setAddLogicalField(false);
        }}
      />
      <SystemsSearchModal
        open={addSystem}
        onCancel={() => setAddSystem(false)}
        onSubmit={(system) => {
          setEditor({
            systems: (systems || []).concat([system])
          });
          setAddSystem(false);
        }}
      />
      <PhysicalEntitiesSearchModal
        open={addPhysicalEntity}
        onCancel={() => setAddPhysicalEntity()}
        onSubmit={(physicalEntity) => {
          setEditor({
            physicalEntities: (physicalEntities || []).concat([physicalEntity])
          });
          setAddPhysicalEntity(false);
        }}
      />
      <PhysicalFieldsSearchModal
        open={addPhysicalField}
        onCancel={() => setAddPhysicalField()}
        onSubmit={(physicalField) => {
          setEditor({
            physicalFields: (physicalFields || []).concat([physicalField])
          });
          setAddPhysicalField(false);
        }}
      />
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    filters: state.classification.assignments.filters
  };
}

export default connect(mapStateToProps, { setAssignmentsFilters, clearAssignmentsFilters })(
  AssignmentsFilters
);
