import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withLoader } from 'components/loader/Loader';
import { useHistory } from 'react-router';
import moment from 'moment';

import Breadcrumbs from 'pages/sidebar/Navigation';
import {
  fetchQualityResult,
  deleteQualityResult,
  patchQualityResult
} from 'actions/QualityResultsActions';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import {
  CardHeader,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Button
} from '@mui/material';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import QualitySemaphoreIcon from '../commons/QualitySemaphoreIcon';
import CommentsPanel from 'pages/social/comments/CommentsPanel';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import UserChip from 'components/chiplist/UserChip';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import ResourceTypes from 'constants/ResourceTypes';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import CardDescription from 'components/carddescription/CardDescription';
import AuthorizeTeam from 'components/permissions/AuthorizeTeam';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import QualityResultModal from '../commons/QualityResultModal';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

function zeroScore(number) {
  if (number === 0) {
    return '0';
  } else if (number === null) {
    return 'Not defined for this check';
  } else {
    return number;
  }
}

function QualityResultDetailPage({ qualityResult, deleteQualityResult, patchQualityResult }) {
  const { t } = useTranslation();
  let history = useHistory();
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.kqis'), to: '/data-quality/kqis/suites' },
          {
            text: qualityResult?.qualityCheck?.qualitySuite?.name,
            to: `/data-quality/kqis/suites/${qualityResult?.qualityCheck?.qualitySuite?.uuid}`
          },
          {
            text: qualityResult?.qualityCheck?.name,
            to: `/data-quality/kqis/checks/${qualityResult?.qualityCheck?.uuid}`
          },
          { text: 'Result' }
        ]}></Breadcrumbs>
      <Tracking
        info={{
          pageTitle: 'Quality Result Detail',
          category: EngagementCategories.DATA_QUALITY,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Quality Result Detail</Typography>
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.QUALITY_RESULT}
            resourceIdentifier={qualityResult?.uuid}
            resourceName={'Result'}
          />
        </Grid>
      </Grid>

      <Card>
        <CardHeader
          action={
            <FavoritesWidget
              itemIdentifier={`${ResourceTypes.QUALITY_RESULT}:${qualityResult.uuid}`}
              resourceIdentifier={qualityResult.uuid}
              resourceType={ResourceTypes.QUALITY_RESULT}
              resourceName={'Result'}
              pageTitle={'Quality Result Detail'}></FavoritesWidget>
          }
          title={`Result of ${qualityResult.qualityCheck.name} (${qualityResult.qualityCheck.code})`}
          subheader={`Executed at ${moment(qualityResult.startedAt).format(
            'DD MMMM YYYY, HH:mm:ss'
          )}`}></CardHeader>
        <CardContent>
          <CardDescription>{qualityResult.description}</CardDescription>
          <Grid container justifyContent='flex-start'>
            <Grid item>
              <KVDefinitionList
                header='Result info'
                item={{
                  Metric: qualityResult.metric,
                  Score: zeroScore(qualityResult.score)
                }}></KVDefinitionList>
            </Grid>
            <Grid item style={{ flexGrow: 0.2 }}></Grid>
            <Grid item>
              <KVDefinitionList
                header='Score Strategy'
                item={{
                  'Score Strategy': qualityResult.qualityCheck.scoreStrategy,
                  Highest: zeroScore(qualityResult.qualityCheck.scoreRightValue),
                  Lowest: zeroScore(qualityResult.qualityCheck.scoreLeftValue),
                  Expected: zeroScore(qualityResult.qualityCheck.scoreExpectedValue)
                }}></KVDefinitionList>
            </Grid>
            <Grid item style={{ flexGrow: 0.2 }}></Grid>

            <Grid item>
              <KVDefinitionList
                header='Semaphore Thresholds'
                item={{
                  Warning: zeroScore(qualityResult.qualityCheck.warningThreshold),
                  Success: zeroScore(qualityResult.qualityCheck.successThreshold)
                }}></KVDefinitionList>
            </Grid>
            <Grid item style={{ flexGrow: 0.2 }}></Grid>
            <Grid item style={{ marginTop: '30px' }}>
              <QualitySemaphoreIcon
                semaphore={qualityResult.semaphore}
                width={'100px'}
                height={'100px'}></QualitySemaphoreIcon>
            </Grid>
          </Grid>
          {qualityResult.creator && (
            <Authorize hasPermissions={[Permissions.USERS_VIEWER]}>
              <Grid container alignItems='center' spacing={2}>
                <Grid item>
                  <h4>Created By</h4>
                </Grid>
                <Grid item>
                  <UserChip label={qualityResult.creator.displayName}></UserChip>
                </Grid>
                <Grid item>
                  <Typography variant='caption'>
                    at {moment(qualityResult.startedAt).format('DD MMMM YYYY, HH:mm:ss')}
                  </Typography>
                </Grid>
              </Grid>
            </Authorize>
          )}
        </CardContent>
        <CardActions>
          <AuthorizeTeam
            teamCode={qualityResult.qualityCheck.qualitySuite?.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE]}
            hasPermissions={[Permissions.QUALITY_EDITOR]}>
            <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
            <ConfirmButton
              onClick={() => {
                deleteQualityResult(qualityResult.uuid).then(() =>
                  history.push(`/data-quality/kqis/checks/${qualityResult.qualityCheck.uuid}`)
                );
              }}>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </AuthorizeTeam>
          <div style={{ marginLeft: 'auto' }}>
            <WebActionsButton
              resource={qualityResult}
              page={TargetPages.QUALITY_RESULT_DETAIL_PAGE}
            />
          </div>
        </CardActions>
      </Card>
      <CommentsPanel
        resourceType='QUALITY_RESULT'
        resourceIdentifier={qualityResult.uuid}
        resourceName={`Result of ${qualityResult.qualityCheck.name}`}
      />
      {open && (
        <QualityResultModal
          isPatch
          qualityResult={qualityResult}
          qualityCheck={qualityResult?.qualityCheck}
          open={open}
          onCancel={() => setOpen(false)}
          onSubmit={(resultPatch) => {
            patchQualityResult(resultPatch);
            setOpen(false);
          }}
        />
      )}
    </div>
  );
}

function mapStateToProps(state, props) {
  const uuid = props.match.params.uuid;
  return {
    qualityResult: state.quality.qualityResults.content[uuid],
    uuid: props.match.params.uuid
  };
}

const mapDispatchToProps = {
  fetchQualityResult,
  deleteQualityResult,
  patchQualityResult
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchQualityResult, uuid }) => fetchQualityResult(uuid),
    ready: ({ qualityResult }) => qualityResult !== undefined
  })
)(QualityResultDetailPage);
