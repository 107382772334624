import React from 'react';
import logo_symbol from 'images/logo_oriz.png';

export default function WelcomeWidget() {
  return (
    <div
      style={{
        maxWidth: '400px',
        textAlign: 'center',
        margin: '20px auto 20px auto'
      }}>
      <img style={{ height: 100 }} src={logo_symbol} alt={'Logo'} />
    </div>
  );
}
