import { createDataProduct } from 'actions/DataProductsActions';
import { AddFAB } from 'components/fab';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import DataProductModal from '../commons/DataProductModal';

export default function DataProductFAB() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Authorize hasPermissions={[Permissions.DATA_PRODUCTS_EDITOR]}>
      <AddFAB onClick={() => setOpen(true)} />
      {open && (
        <DataProductModal
          open={open}
          onSubmit={(dataProduct) =>
            dispatch(createDataProduct(dataProduct)).then((dataProduct) => {
              if (dataProduct) {
                setOpen(false);
                history.push(`/dataproducts/detail/${dataProduct.uuid}`);
              }
            })
          }
          title={'Create a Data Product'}
          onClose={() => setOpen(false)}
        />
      )}
    </Authorize>
  );
}
