import React from 'react';

import Button from '@mui/material/Button';

import ConfirmButton from '../../../../../components/confirmbutton/ConfirmButton';
import StewardshipAuthorize from '../../../../../components/permissions/StewardshipAuthorize';

import ResourceTypes from '../../../../../constants/ResourceTypes';
import Permissions from '../../../../../constants/Permissions';
import { withTranslation } from 'react-i18next';

const Actions = ({ physicalField, onEdit, onDelete, t }) => {
  return (
    <>
      <StewardshipAuthorize
        resourceType={ResourceTypes.PHYSICAL_ENTITY}
        resourceIdentifier={physicalField.physicalEntity.uuid}
        hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
        <Button onClick={onEdit}>{t('physicalFields.detail.actions.edit')}</Button>
      </StewardshipAuthorize>
      <StewardshipAuthorize
        resourceType={ResourceTypes.PHYSICAL_ENTITY}
        resourceIdentifier={physicalField.physicalEntity.uuid}
        hasPermissions={[Permissions.SYSTEMS_ADMIN]}>
        <ConfirmButton
          message={`Do tou want to delete: ${physicalField.name}? This will delete associated dataflows and resources.`}
          onClick={onDelete}>
          {t('physicalFields.detail.actions.delete')}
        </ConfirmButton>
      </StewardshipAuthorize>
    </>
  );
};

export default withTranslation()(Actions);
