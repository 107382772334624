import * as React from "react";
const SvgIntersystems = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150.2"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M0 .2h150v150H0V.2z" fill="none" />
    <path fill="#2d3487" d="m67.6 27.8-16-7.8v100.8l32.1 16v-17.9l-16.1-8z" />
    <path fill="#00a89e" d="m83.7 118.9 15.8 8V26.1l-31.9-16v17.7L83.7 36z" />
  </svg>
);
export default SvgIntersystems;
