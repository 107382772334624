import React, { useEffect } from 'react';
import ExpandableCard from 'components/expandable-card/ExpandableCard';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useHistory } from 'react-router';
import PaginatedPanel from 'components/pagination/PaginatedPanel';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContractDefinitionsPage } from 'actions/contracts/ContractsDefinitionsActions';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';
import ResourceTypes from 'constants/ResourceTypes';
import { fetchDashboardTypeCounterIfAllowedLargeRequest } from 'actions/IssueMngtDashboardsActions';

export function ContractDefinitionsList() {
  let { currentPage, filters } = useSelector((state) => state.contracts.definitions);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchContractDefinitionsPage({ page: 0, ...filters })).then(
      (response) =>
        response &&
        response.content.length > 0 &&
        dispatch(
          fetchDashboardTypeCounterIfAllowedLargeRequest(
            response.content,
            25,
            ResourceTypes.CONTRACT
          )
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  if (!currentPage) {
    return null;
  }

  return (
    <PaginatedPanel
      currentPage={currentPage}
      onPageSelection={(page) =>
        dispatch(fetchContractDefinitionsPage({ page, ...filters })).then(
          (response) =>
            response &&
            response.content.length > 0 &&
            dispatch(
              fetchDashboardTypeCounterIfAllowedLargeRequest(
                response.content,
                25,
                ResourceTypes.CONTRACT
              )
            )
        )
      }>
      {currentPage.content.map((cd) => (
        <ContractDefinitionsListItem key={cd.uuid} contractDefinition={cd} />
      ))}
    </PaginatedPanel>
  );
}

export function ContractDefinitionsListItem({ contractDefinition }) {
  let { t } = useTranslation();
  let history = useHistory();
  let actions = [
    <Button onClick={() => history.push(`/contracts/definitions/${contractDefinition.uuid}`)}>
      {t('commons.actions.detail')}
    </Button>
  ];
  return (
    <ExpandableCard
      decorator={
        <IssueResourceListItemWidget
          resourceType={ResourceTypes.CONTRACT}
          resourceIdentifier={contractDefinition.uuid}></IssueResourceListItemWidget>
      }
      title={contractDefinition.name}
      subheader={`${contractDefinition.category} - ${contractDefinition.contractName}`}
      children={contractDefinition.description}
      actions={actions}
    />
  );
}

export default ContractDefinitionsList;
