import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Button, Grid, LinearProgress, Typography, Hidden, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandableCard from 'components/expandable-card/ExpandableCard';
import UserChip from 'components/chiplist/UserChip';
import LockIcon from '@mui/icons-material/Lock';

import { Schedule } from '@mui/icons-material';

const CampaignsListItem = ({ campaign }) => {
  const { t } = useTranslation();

  const open = campaign.openIssues || 0;
  const closed = campaign.closedIssues || 0;
  const progress = open === 0 && closed === 0 ? 0 : (closed / (open + closed)) * 100;

  return (
    <ExpandableCard
      title={
        <Grid container alignItems='center' justifyContent='flex-start'>
          <Grid item>{campaign.name}</Grid>
          <Grid item>
            {campaign.private && (
              <Tooltip title='Private Campaign'>
                <LockIcon fontSize='small'></LockIcon>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      }
      subheader={
        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            <Schedule fontSize='small' />
          </Grid>
          <Grid item>
            <Typography variant='overline'>
              End date: {campaign.endDate ? moment(campaign.endDate).format('DD MMMM YYYY') : '-'}
            </Typography>
          </Grid>
          {campaign?.owner && (
            <Grid item>
              <Tooltip title='Owner'>
                <UserChip label={`${campaign.owner.displayName}`} fontSize='small'></UserChip>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      }
      decorator={
        <Hidden mdDown>
          <Grid
            container
            direction='column'
            style={{ paddingTop: '32px', paddingRight: '32px', width: '400px' }}>
            <Grid item>
              <LinearProgress variant='determinate' value={progress} />
            </Grid>
            <Grid item>
              <Grid container justifyContent='space-evenly'>
                <Grid item>
                  <Typography variant='overline'>{progress.toFixed(0)}% completed</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='overline'>{open} open</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='overline'>{closed} closed</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      }
      actions={[
        <Button
          key={0}
          children={t('commons.actions.detail')}
          component={Link}
          to={`/issue-management/campaigns/${campaign.uuid}`}
        />
      ]}
    />
  );
};

export default CampaignsListItem;
