import React, { useState } from 'react';
import { fetchAuthSettings, verifyOIDCToken } from 'actions/LoginActions';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';

export default function ExtractAccessToken() {
  let dispatch = useDispatch();
  let [started, setStarted] = useState(false);
  let history = useHistory();
  let params = useParams();
  let provider = params.provider;
  React.useEffect(() => {
    if (started) {
      return;
    }
    setStarted(true);
    dispatch(fetchAuthSettings()).then((data) => {
      let setting = _.find(data.settings, { provider });
      let tokenType = setting.tokenType;
      var idToken = new URLSearchParams(window.location.hash.substr(1)).get(tokenType);
      dispatch(verifyOIDCToken({ provider, idToken, history })).then(() => history.push('/')); //important to avoid verify loop
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
