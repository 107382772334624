import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { runProject } from 'actions/onagent/AgentJobRunnerActions';

import Permissions from 'constants/Permissions';

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  CircularProgress,
  Tooltip,
  IconButton
} from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import AgentItemAutocomplete from 'pages/settings/agents/commons/AgentItemAutocomplete';
import withPermissions from 'components/permissions/withPermissions';
import ProjectTestResults from './ProjectTestResults';
import { useTranslation } from 'react-i18next';

function ProjectTestButton({ projectProbes, project, runProject }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [results, setResults] = useState(null);
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title='Run All Probes'>
        <IconButton size='medium' onClick={() => setOpen(true)}>
          <PlayCircleOutlineIcon></PlayCircleOutlineIcon>
        </IconButton>
      </Tooltip>
      {open && (
        <Dialog open={open} fullWidth maxWidth='lg'>
          <DialogTitle>
            <Typography variant='h6'>Test a Project</Typography>
            {project && (
              <Typography color='textSecondary' variant='subtitle2'>
                You are testing the project "{project?.name}"
              </Typography>
            )}
          </DialogTitle>
          <DialogContent>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Typography variant='body2'>
                  Select the agent on which you want to perform the test
                </Typography>
                <AgentItemAutocomplete
                  margin='dense'
                  value={selectedAgent}
                  onChange={(agent) => {
                    setSelectedAgent(agent);
                  }}
                />
              </Grid>
              <Grid item>
                {!loading && !results && (
                  <Typography variant='body2' style={{ textAlign: 'center', marginTop: '1.5em' }}>
                    Start the test to get some results!
                  </Typography>
                )}
                {loading && (
                  <CircularProgress
                    style={{ marginLeft: 'calc(50% - 20px)', marginTop: '1.5em' }}
                  />
                )}
                {results && <ProjectTestResults results={results} />}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              children={t('commons.actions.close')}
              onClick={() => {
                setSelectedAgent(null);
                setResults(null);
                setLoading(false);
                setOpen(false);
              }}>
              Close
            </Button>
            <Button
              children={t('commons.actions.test')}
              disabled={!selectedAgent || loading || !!results}
              onClick={() => {
                setLoading(true);
                runProject(selectedAgent, projectProbes, project.name)
                  .then((response) => {
                    setLoading(false);
                    setResults(response);
                  })
                  .catch((err) => console.log(err.message));
              }}>
              Test
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.PROBES_EDITOR, Permissions.AGENTS_EDITOR] }),
  connect(null, { runProject })
)(ProjectTestButton);
