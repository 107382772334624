import { connect } from 'react-redux';
import React from 'react';
import { ChipItem } from 'components/chiplist/ChipList';

import {
  TableContainer,
  Table,
  TablePagination,
  TableRow,
  TableBody,
  TableHead,
  TableFooter
} from '@mui/material';
import TableCell from '../../../../components/tables/GenericTableCell';
import { Link } from 'react-router-dom';
import { withLoader } from '../../../../components/loader/Loader';

import { compose } from 'redux';
import { fetchDataActorTaskAssignmentsPage } from 'actions/TaskAssignmentsActions';

const styles = {
  root: {
    width: '100%'
  },
  table: {
    minWidth: '750px'
  }
};

function TaskAssignmentsTableView({
  processing,
  currentPage,
  size = 'small',
  onPageSelection,
  page,
  rowsPerPage
}) {
  return (
    <div style={styles.root}>
      <TableContainer>
        <Table
          sx={styles.table}
          aria-labelledby='associated-processors-table'
          size={size}
          aria-label='Associated processor table'>
          <TaskAssignmentsTableHead></TaskAssignmentsTableHead>
          <TableBody>
            {(currentPage.content || []).map((taskAssignment) => (
              <TableRow key={taskAssignment.uuid} hover>
                <TableCell>
                  <ChipItem
                    size='small'
                    label={taskAssignment.dataActorName}
                    component={Link}
                    to={`/data_actors/${taskAssignment.dataActorUuid}`}
                    onClick={(event) => event.stopPropagation()}
                  />
                </TableCell>
                <TableCell>
                  <ChipItem
                    size='small'
                    label={taskAssignment.taskName}
                    component={Link}
                    to={`/tasks/${taskAssignment.taskUuid}`}
                    onClick={(event) => event.stopPropagation()}
                  />
                </TableCell>
                <TableCell>{taskAssignment.role}</TableCell>
                <TableCell>{taskAssignment.notes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 25]}
                count={currentPage.totalElements || 0}
                rowsPerPage={rowsPerPage || 10}
                page={page}
                onPageChange={(event, page, processingUuid) =>
                  onPageSelection({
                    page: page,
                    size: currentPage.size,
                    processingUuid: processingUuid
                  })
                }
                onRowsPerPageChange={(event, processingUuid) =>
                  onPageSelection({
                    page: currentPage.number,
                    size: event.target.value,
                    processingUuid: processingUuid
                  })
                }></TablePagination>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

const headCells = [
  { id: 'dataProcessor', label: 'Data Processor Name' },
  { id: 'taskName', label: 'Task Name' },
  { id: 'role', label: 'Role' },
  { id: 'notes', label: 'Notes' }
];

function TaskAssignmentsTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.taskAssignments.currentPage,
    page: state.taskAssignments.currentPage.number,
    rowsPerPage: state.taskAssignments.currentPage.size
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    onPageSelection: (params) =>
      dispatch(
        fetchDataActorTaskAssignmentsPage({
          page: params.page || 0,
          size: params.size || 10,
          processingUuid: props.processing.uuid
        })
      )
  };
}

const TaskAssignmentsTable = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ onPageSelection, processing }) =>
      onPageSelection({ page: 0, size: 10, processingUuid: processing.uuid })
  })
)(TaskAssignmentsTableView);

export default TaskAssignmentsTable;
