import React, { useState } from 'react';
import { Typography, Grid, Button } from '@mui/material';
import EngagementSessionDashboards from './EngagementSessionDashboards';
import EngagementPageViewsDashboards from './EngagementPageViewsDashboards';
import { setEngagementTimelineFilters } from 'actions/EngagementDashboardsActions';
import DatePicker, { hourAdjustments } from 'components/pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
function EngagementDashboardsPage({ filters, setEngagementTimelineFilters }) {
  return (
    <React.Fragment>
      <Breadcrumbs
        elements={[
          { text: 'Settings', to: '/settings' },
          { text: 'Users', to: '/settings/users' },
          { text: 'Engagement' }
        ]}
      />
      <Tracking
        info={{
          pageTitle: `Engagement Dashboard`,
          category: EngagementCategories.SETTINGS,
          action: EngagementActions.LIST
        }}
      />
      <Grid container spacing={1} alignItems='flex-end'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>{'Engagement'}</Typography>
        </Grid>
        <Grid item>
          <TimeLinesFilters filters={filters} setFilters={setEngagementTimelineFilters} />
        </Grid>
      </Grid>
      <EngagementSessionDashboards></EngagementSessionDashboards>
      <EngagementPageViewsDashboards></EngagementPageViewsDashboards>
    </React.Fragment>
  );
}

const TimeLinesFilters = ({ filters, setFilters }) => {
  const { t } = useTranslation();

  const [editor, setEditor] = useState(filters);

  return (
    <Grid container spacing={1} alignItems='flex-end'>
      <Grid item xs={12} sm='auto'>
        <DatePicker
          clearable={true}
          label={'From'}
          hintText={'Start Date'}
          hourAdjustment={hourAdjustments.startOfDay}
          value={editor.fromHitTimestamp}
          onChange={(date) => setEditor({ ...editor, fromHitTimestamp: date })}
        />
      </Grid>
      <Grid item xs={12} sm='auto'>
        <DatePicker
          clearable={true}
          label={'To'}
          hintText={'Start Date'}
          hourAdjustment={hourAdjustments.endOfDay}
          value={editor.toHitTimestamp}
          onChange={(date) => setEditor({ ...editor, toHitTimestamp: date })}
        />
      </Grid>
      <Grid item xs={12} sm='auto'>
        <Button variant='outlined' color='primary' onClick={() => setFilters(editor)}>
          {t('commons.actions.apply')}
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, props) => ({
  filters: state.engagement.engagementDashboards.engagementTimeline.filters.content
});

export default connect(mapStateToProps, { setEngagementTimelineFilters })(EngagementDashboardsPage);
