import React from 'react';
import { List } from '@mui/material';
import CommentsListItem from './CommentsListItem';
import PaginatedPanel from '../../../components/pagination/PaginatedPanel';

function CommentsList({ onPageSelection, currentPage }) {
  return (
    <PaginatedPanel onPageSelection={onPageSelection} autoload>
      <List>
        {currentPage.content.map((comment) => (
          <CommentsListItem comment={comment} key={comment.sequenceId}></CommentsListItem>
        ))}
      </List>
    </PaginatedPanel>
  );
}

export default CommentsList;
