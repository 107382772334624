import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchRiskCategories } from 'actions/quality/assessment/QualityAssessmentRiskDefinitionsActions';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';

import AutoComplete from 'components/autocomplete/AutoComplete';

const INITIAL_STATE = ({ category }) => ({
  code: '',
  name: '',
  category: category || '',
  description: ''
});

const RiskDefinitionModal = ({
  title,
  open,
  riskDefinition,
  category,
  handleSubmit,
  handleCancel
}) => {
  const { t } = useTranslation();

  const [editor, setEditor] = useState(riskDefinition || INITIAL_STATE({ category }));

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          required
          fullWidth
          margin='dense'
          label='Risk Code'
          placeholder='Enter the risk code'
          value={editor.code}
          onChange={(event) => setEditor({ ...editor, code: event.target.value })}
        />
        <TextField
          variant='standard'
          required
          fullWidth
          margin='dense'
          label='Risk Name'
          placeholder='Enter the risk name'
          value={editor.name}
          onChange={(event) => setEditor({ ...editor, name: event.target.value })}
        />
        <RiskCategoriesAutoComplete
          required
          fullWidth
          margin='dense'
          value={editor.category}
          onChange={(newValue) => setEditor({ ...editor, category: newValue })}
        />
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          multiline
          label='Description'
          placeholder='Enter the description'
          value={editor.description}
          onChange={(event) => setEditor({ ...editor, description: event.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{t('commons.actions.cancel')}</Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!editor.code || !editor.name || !editor.category}
          onClick={() => handleSubmit(editor)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RiskCategoriesAutoComplete = connect(null, { fetchRiskCategories })(
  ({ fetchRiskCategories, value, onChange, required, fullWidth, margin }) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
      fetchRiskCategories().then((response) => response && setCategories(response.content));
    }, [fetchRiskCategories, setCategories]);

    return (
      <AutoComplete
        required={required}
        fullWidth={fullWidth}
        margin={margin}
        label='Category'
        placeholder='Select an existing category or enter a new one'
        dataSource={categories}
        value={value}
        onUpdateInput={onChange}
        onNewRequest={onChange}
      />
    );
  }
);

export default RiskDefinitionModal;
