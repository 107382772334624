/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import {Switch, Route} from 'react-router';
import DictionaryDetailPage from './detail/DictionaryDetailPage';
import DictionariesListPage from './list/DictionariesListPage';

export default function (props) {
    return (
      <Switch>
        <Route path={`${props.match.url}/:uuid`} component={DictionaryDetailPage}></Route>
  
        <Route path={`${props.match.url}`} component={DictionariesListPage}></Route>
      </Switch>
    );
  }