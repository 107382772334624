import _ from 'lodash';

export const loadSuggestions = (t) => (suggestionKey,dataCategories) => {
  if(dataCategories === undefined || dataCategories.length === 0){
    return loadAll(t,suggestionKey)
  } else {
    return loadConditioned(t,suggestionKey,dataCategories)
  }
}

const loadAll = (t,suggestionKey) => {
  let suggestions = t(suggestionKey, { returnObjects: true });
  return Array.isArray(suggestions) ? suggestions : _(suggestions).flatMap().uniqBy().value();
}

const loadConditioned = (t, suggestionKey, dataCategories) => {
  let suggestions = t(suggestionKey, { returnObjects: true });
  if(Array.isArray(suggestions)) return loadAll(t,suggestionKey);

  let dataClasses = _(dataCategories).map('dataClass').filter().value();
  let definedDataClasses = t('suggestion:dataCategoryClasses', { returnObjects: true })
  let hasPredefinedDataClasses = _(dataClasses).every(dc => definedDataClasses.includes(dc) && suggestions[dc] != null)
  if(dataClasses.length >0 && hasPredefinedDataClasses){
    return _(dataClasses).flatMap(dc => suggestions[dc]).uniqBy().value();
  } else {
    return loadAll(t,suggestionKey);
  }
}