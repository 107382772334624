import _ from 'lodash';

export default class AbstractImporter {
  constructor({ access_token, selectedTenantUuid, configuration, delegate }) {
    this.access_token = access_token;
    this.selectedTenantUuid = selectedTenantUuid;
    this.configuration = configuration;
    this.delegate = delegate;
  }

  get caseSensitiveMatch() {
    return this.configuration.caseSensitiveMatch;
  }

  get patchMode() {
    return this.configuration.patchMode;
  }

  static isSupported(object) {
    throw new Error('Importer isSupported not implemented');
  }

  async importObject(object, resolveUuidOnly = false) {
    if (this.delegate && this.delegate.importObject) {
      return this.delegate.importObject(object, resolveUuidOnly);
    }
    throw new Error('Importer importObject not implemented');
  }

  authenticateApi(api) {
    api.token = this.access_token;
    api.selectedTenantUuid = this.selectedTenantUuid;
  }

  async importTemplate({
    object,
    objectName = 'name',
    objectIdentifier = 'uuid',
    api,
    search,
    put,
    post,
    resolveUuidOnly = false,
    delet = () => null
  }) {
    if (!object) {
      return;
    }
    this.authenticateApi(api);
    let reconciledObject = object;

    //object uuid already resolved
    if (!object[objectIdentifier] && search) {
      let searchedResource = await this.searchResourceForReconciliation(
        object,
        objectName,
        search,
        api
      );

      if (searchedResource) {
        if (this.patchMode) {
          reconciledObject = this.patchObject(reconciledObject, searchedResource);
        } else {
          reconciledObject = { ...object, [objectIdentifier]: searchedResource[objectIdentifier] };
        }
      }
    }

    if (resolveUuidOnly) {
      if (reconciledObject[objectIdentifier]) {
        //return the already existing object
        return reconciledObject;
      } else {
        throw new Error(`Object with name: "${reconciledObject[objectName]}" not found`);
      }
    } else {
      //update or create the object
      if (reconciledObject[objectIdentifier]) {
        if (reconciledObject._DELETE) {
          return delet.apply(api, [reconciledObject]);
        } else {
          return put.apply(api, [reconciledObject]);
        }
      } else {
        return post.apply(api, [reconciledObject]);
      }
    }
  }

  async searchResourceForReconciliation(object, objectName, search, api) {
    let searchedResource = null;
    const nameToSearchFor = object[objectName];
    if (this.caseSensitiveMatch) {
      //search for existence by name
      let searchResponse = await search.apply(api, [
        {
          search: nameToSearchFor,
          page: 0,
          size: Math.pow(2, object[objectName]?.length || 0)
        }
      ]);
      searchedResource = _(searchResponse?.content || []).find({ [objectName]: nameToSearchFor });
    } else {
      //search for existence by name
      let searchResponse = await search.apply(api, [{ search: nameToSearchFor, page: 0, size: 1 }]);
      //assign existing uuid if there is a match
      let searchedName = _.get(searchResponse, `content[0].${objectName}`, '');
      if (searchedName.localeCompare(nameToSearchFor, undefined, { sensitivity: 'base' }) === 0) {
        searchedResource = _.get(searchResponse, 'content[0]');
      }
    }
    return searchedResource;
  }

  patchObject(object, searchedObject) {
    let patchedObject = {
      ...searchedObject,
      ...object
    };
    if (
      searchedObject.additionalProperties?.length &&
      Object.keys(object).includes('additionalProperties')
    ) {
      let apPatchByName = _.groupBy(object.additionalProperties || [], 'name');
      let apOldByName = _.groupBy(searchedObject.additionalProperties || [], 'name');
      let apNewByName = { ...apOldByName, ...apPatchByName };
      let patchedAdditionalProperties = _(apNewByName)
        .map(_.identity)
        .map((arr) => arr.filter((e) => e != null))
        .filter((arr) => arr.length > 0)
        .reduce((acc, e) => acc.concat(e), []);
      patchedObject.additionalProperties = patchedAdditionalProperties;
    }
    if (searchedObject.logicalFields?.length && object.logicalFields?.length) {
      let patchedLogicalFields = searchedObject.logicalFields.concat(object.logicalFields);
      patchedObject.logicalFields = patchedLogicalFields;
    }
    return patchedObject;
  }
}
