/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import _ from 'lodash';
import { setEntitiesFilters } from 'actions/PhysicalEntitiesActions';
import SearchIcon from '@mui/icons-material/Search';
import { connect } from 'react-redux';

function PhysicalEntitiesSearchBox({ search, setEntitiesFilters }) {
  let [editor, setEditor] = useState(search || '');
  useEffect(() => {
    if (search !== editor) {
      setEditor(search || '');
    }
  }, [search, setEditor]);
  let onSetFilters = useCallback(_.debounce(setEntitiesFilters, 300), [setEntitiesFilters]);
  return (
    <TextField
      value={editor}
      id='input-with-icon-textfield'
      placeholder='Search...'
      variant='outlined'
      size='small'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='disabled' />
          </InputAdornment>
        )
      }}
      onChange={(event) => {
        setEditor(event.target.value);
        onSetFilters({ search: event.target.value || null });
      }}
    />
  );
}

export default connect(
  (state) => ({
    search: state.physicalentities.filters.search
  }),
  {
    setEntitiesFilters
  }
)(PhysicalEntitiesSearchBox);
