import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchFavoritesPage } from 'actions/social/favorites/FavoritesActions';
import { fetchWorkspace } from 'actions/social/favorites/WorkspaceActions';
import WorkspaceDetailCard from './WorkspaceDetailCard';

function WorkspaceDetailPage({
  workspaceUuid,
  workspace,
  favorites,
  fetchFavoritesPage,
  fetchWorkspace,
  favoritesPagination
}) {
  useEffect(() => {
    if (workspaceUuid) {
      fetchWorkspace(workspaceUuid);
    }
  }, [fetchWorkspace, workspaceUuid]);

  useEffect(() => {
    fetchFavoritesPage({ workspaceUuid: workspaceUuid });
  }, [fetchFavoritesPage, workspaceUuid]);
  if (!workspace) return <></>;
  return (
    <>
      <WorkspaceDetailCard
        key={favorites}
        favoritesPagination={favoritesPagination}
        fetchFavoritesPage={fetchFavoritesPage}
        workspaceUuid={workspaceUuid}
        favorites={favorites}
        workspace={workspace}
      />
    </>
  );
}

const mapStateToProps = (state, props) => ({
  workspace: state.social.workspace.content[props.match.params['workspaceUuid']],
  workspaceUuid: props.match.params['workspaceUuid'],
  favorites: state.social.favorites.contentByWorkspace[props.match.params['workspaceUuid']],
  favoritesPagination: {
    page: state.social.favorites.currentPage.number,
    size: state.social.favorites.currentPage.size,
    totalElements: state.social.favorites.currentPage.totalElements
  }
});

export default connect(mapStateToProps, { fetchFavoritesPage, fetchWorkspace })(
  WorkspaceDetailPage
);
