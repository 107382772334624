import { combineReducers } from 'redux';
import users from './users';
import tenants from './tenants';
import profile from './profile';
import teams from './teams';
import teamsPolicies from './teamsPolicies';
import customRoles from './customRoles';
import customPropertiesDefinitions from './customPropertiesDefinitions';
import search from './search';
import configurations from './configurations';
import agents from './agents';
import webActions from './webActions';
import webHooks from './webHooks';

export default combineReducers({
  users,
  profile,
  tenants,
  teams,
  teamsPolicies,
  customRoles,
  customPropertiesDefinitions,
  search,
  configurations,
  agents,
  webActions,
  webHooks
});
