import { combineReducers } from 'redux';
import _ from 'lodash';
import {
  SET_PROPOSALS_FILTERS,
  FETCH_PROPOSALS_PAGE,
  CLEAR_PROPOSALS_FILTERS,
  DELETE_PROPOSAL,
  FETCH_PROPOSAL,
  MODIFY_PROPOSAL,
  PATCH_PROPOSAL,
  SET_PROPOSAL_EDITOR,
  CLEAR_PROPOSAL_EDITOR
} from '../../constants/ActionTypes';
import { ProposalStatus } from '../../pages/workflows/proposals/commons/ProposalConstants';

function filters(state = { proposalStates: [ProposalStatus.DRAFT] }, action) {
  switch (action.type) {
    case SET_PROPOSALS_FILTERS:
      return { ...state, ...action.payload };
    case CLEAR_PROPOSALS_FILTERS:
      return {};
    default:
      return state;
  }
}

function content(state = {}, action) {
  switch (action.type) {
    case FETCH_PROPOSALS_PAGE:
      return {
        ...state,
        ..._(action.payload.content)
          .keyBy('uuid')
          .value()
      };
    case FETCH_PROPOSAL:
    case MODIFY_PROPOSAL:
      return { ...state, [action.payload.uuid]: action.payload };
    case PATCH_PROPOSAL:
      let patched = { ...(state[action.payload.uuid] || {}), ...action.payload };
      return { ...state, [action.payload.uuid]: patched };
    case DELETE_PROPOSAL:
      return {
        ...state,
        [action.payload.uuid]: null
      };
    default:
      return state;
  }
}

const INITIAL_PROPOSALS_PAGE = { content: [], number: 0, size: 10, totalPages: 0 };

function currentPage(state = INITIAL_PROPOSALS_PAGE, action) {
  switch (action.type) {
    case FETCH_PROPOSALS_PAGE:
      return action.payload;
    case DELETE_PROPOSAL:
      return {
        ...state,
        content: state.content.filter(proposal => proposal.uuid !== action.payload.uuid)
      };
    default:
      return state;
  }
}

const INITIAL_EDITOR_STATE = {
  proposalStatus: ProposalStatus.DRAFT,
  name: null,
  description: null,
  newItems: null,
  items: null
};

function editor(state = INITIAL_EDITOR_STATE, action) {
  switch (action.type) {
    case SET_PROPOSAL_EDITOR:
      return { ...state, ...action.payload };
    case CLEAR_PROPOSAL_EDITOR:
      return INITIAL_EDITOR_STATE;
    default:
      return state;
  }
}

export default combineReducers({ filters, currentPage, content, editor });
