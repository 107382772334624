import { Badge, IconButton } from '@mui/material';
import { NotificationsNone } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { fetchUnreadCount } from '../../../actions/NotificationActions';

function NotificationIconButton({ style, unreadCount, fetchUnreadCount }) {
  useEffect(
    function () {
      fetchUnreadCount();
    },
    [fetchUnreadCount]
  );
  const history = useHistory();
  let hide = history.location.pathname.includes('/graph');
  if (hide) {
    return null;
  }
  return (
    <IconButton
      color='primary'
      style={style}
      onClick={() => history.push('/social/notifications')}
      size='large'>
      <Badge
        badgeContent={unreadCount}
        overlap='rectangular'
        max={99}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}>
        <NotificationsNone></NotificationsNone>
      </Badge>
    </IconButton>
  );
}

const mapStateToProps = (state) => ({
  unreadCount: state.social.notifications.stats.unreadCount
});

const mapDispatchToProps = {
  fetchUnreadCount
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationIconButton);
