import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import GetAppIcon from '@mui/icons-material/GetApp';
import { exportClassificationDictionaries } from '../../../../actions/DictionariesActions';

function DictionariesExport({ filter, onClick }) {
  return (
    <Tooltip title='Export'>
      <IconButton
        size='small'
        color='default'
        aria-label='Download'
        onClick={() => onClick(filter)}>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}

function mapStateToProps(state) {
  return {
    filter: state.classification.dictionaries.filter
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: (filter) => dispatch(exportClassificationDictionaries({ ...filter }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionariesExport);
