import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import HomePage from './home/HomePage';
import SettingsRoutes from './settings/SettingsRoutes';
import DataCategoriesRoutes from './datacategories/DataCategoriesRoutes';
import SystemsRoutes from './systems/SystemsRoutes';
import PhysicalEntitiesRoutes from 'pages/systems/physical_entities/PhysicalEntitiesRoutes';
import RoutinesRoutes from './systems/routines/RoutinesRoutes';
import DataFlowsRoutes from './dataflows/DataFlowsRoutes';
import ProcessingsRoutes from './processings/ProcessingsRoutes';
import TasksRoutes from './tasks/TasksRoutes';
import DataSubjectsRoutes from './datasubjects/DataSubjectsRoutes';
import DataActorsRoutes from './data_actors/DataActorsRoutes';
import TaskGrantsRoutes from './taskgrants/TaskGrantsRoutes';
import GraphPage from './graph/GraphPage';
import ConsentsPage from './consents/ConsentsRoutes';
import ContractsRoutes from './contracts/ContractsRoutes';
import ReportsRoutes from './reports/ReportsRoutes';
import HistoryRoutes from './history/HistoryRoutes';
import WorkflowsRoutes from './workflows/WorkflowsRoutes';
import TemplatesRoutes from './templates/TemplatesRoutes';
import LogicalFieldRoutes from './logicalfields/LogicalFieldsRoutes';
import SocialRoutes from './social/SocialRoutes';
import ClassificationRoutes from './classification/ClassificationRoutes';
import IssueMngtRoutes from './issueMngt/IssueMngtRoutes';
import DataQualityRoutes from './quality/DataQualityRoutes';
import StewardshipRoutes from './stewardship/StewardshipRoutes';
import EngagementDashboardsRoutes from './engagement/EngagementDashboardsRoutes';
import PhysicalConstraintsRoutes from './systems/physical_constraints/PhysicalConstraintsRoutes';
import DataProductsRoutes from './systems/dataproducts/DataProductsRoutes';

const Routes = (props) => (
  <ScrollToTop>
    <Switch>
      <Route exact path='/' component={HomePage} />
      <Route path='/datacategories' component={DataCategoriesRoutes} />
      <Route path='/tasks' component={TasksRoutes} />
      <Route path='/processings' component={ProcessingsRoutes} />
      <Route path='/data_actors' component={DataActorsRoutes} />
      <Route path='/workflows' component={WorkflowsRoutes} />
      <Route path='/datasubjects' component={DataSubjectsRoutes} />
      <Route path='/consents' component={ConsentsPage} />
      <Route path='/contracts' component={ContractsRoutes} />
      <Route path='/systems' component={SystemsRoutes} />
      <Route path='/physicalentities' component={PhysicalEntitiesRoutes} />
      <Route path='/dataflows' component={DataFlowsRoutes} />
      <Route path='/taskgrants' component={TaskGrantsRoutes} />
      <Route path='/graph' component={GraphPage} />
      <Route path='/settings' component={SettingsRoutes} />
      <Route path='/reports' component={ReportsRoutes} />
      <Route path='/history' component={HistoryRoutes} />
      <Route path='/templates' component={TemplatesRoutes} />
      <Route path='/logicalfields' component={LogicalFieldRoutes} />
      <Route path='/social' component={SocialRoutes} />
      <Route path='/classification' component={ClassificationRoutes} />
      <Route path='/issue-management' component={IssueMngtRoutes} />
      <Route path='/data-quality' component={DataQualityRoutes} />
      <Route path='/stewardship' component={StewardshipRoutes} />
      <Route path='/engagement' component={EngagementDashboardsRoutes} />
      <Route path='/routines' component={RoutinesRoutes} />
      <Route path='/physical-constraints' component={PhysicalConstraintsRoutes} />
      <Route path='/dataproducts' component={DataProductsRoutes} />
      <Redirect to='/'></Redirect>
    </Switch>
  </ScrollToTop>
);

function ScrollToTop({ children }) {
  let history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (!location?.state?.keepScroll) {
        window.scrollTo(0, 0);
        let scrollNode = document.getElementById('material-title-root')?.parentNode;
        if (scrollNode) {
          scrollNode.scrollTop = 0;
          scrollNode.scrollLeft = 0;
        }
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
}

export function createUrl(resourceType) {
  switch (resourceType) {
    case 'PROCESSING':
      return `/processings`;
    case 'DATA_CATEGORY':
      return `/datacategories`;
    case 'LOGICAL_FIELD':
      return `/logicalfields`;
    case 'TASK':
      return `/tasks`;
    case 'DATA_ACTOR':
      return `/data_actors`;
    case 'PHYSICAL_ENTITY':
      return `/physicalentities`;
    case 'SYSTEM':
      return `/systems`;
    case 'PHYSICAL_FIELD':
      return `/systems/physical_fields`;
    case 'QUALITY_SUITES':
      return `/data-quality/kqis/suites`;
    case 'QUALITY_CHECK':
      return '/data-quality/kqis/checks';
    case 'QUALITY_RESULT':
      return '/data-quality/kqis/results';
    case 'QUALITY_PROBE_DEFINITION':
      return '/data-quality/probes/definitions';
    case 'QUALITY_PROBE_PROJECT':
      return '/data-quality/probes/projects';
    case 'PROPOSAL':
      return `/workflows/proposals`;
    case 'ISSUE':
      return `/issue-management/issues`;
    case 'CAMPAIGN':
      return `/issue-management/campaigns`;
    case 'REL_DATA_FLOW':
      return `/dataflows/detail`;
    case 'QUERY_BUCKET':
      return `/dataflows/queryparser/buckets`;
    case 'DATA_PRODUCT':
      return `/dataproducts/detail`;
    default:
      return null;
  }
}

export function createUrlItem(resourceType, resourceIdentifier) {
  let baseUrl = createUrl(resourceType);
  return baseUrl ? `${baseUrl}/${resourceIdentifier}` : null;
}

export default Routes;
