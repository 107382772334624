import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { fetchConfiguration } from 'actions/quality/assessment/QualityAssessmentConfigurationActions';
import {
  fetchResidualRisksTableDashboardData,
  exportResidualRisksTable
} from 'actions/quality/assessment/QualityAssessmentDashboardActions';

import { Card, CardHeader, CardContent, Tooltip, IconButton, Typography } from '@mui/material';

import DownloadIcon from '@mui/icons-material/GetApp';

import { Chart } from 'react-google-charts';

import {
  greenSemaphoreColor,
  yellowSemaphoreColor,
  redSemaphoreColor
} from 'pages/quality/kqis/quality_checks/commons/QualitySemaphoreColors';

const styles = {
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    minHeight: '100px'
  }
};

const ResidualRisksTableCard = ({
  configuration,
  data,
  fetchConfiguration,
  fetchDashboardValues,
  exportResidualRisksTable
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!configuration) fetchConfiguration();
    fetchDashboardValues();
  }, [configuration, fetchConfiguration, fetchDashboardValues]);

  return (
    <Card>
      <CardHeader
        title='Residual Risk by Entity'
        subheader='Residual risk overview by entity and risk definition'
        action={
          <Tooltip title='Export CSV'>
            <IconButton
              color='default'
              aria-label='Download'
              onClick={() => exportResidualRisksTable()}
              size='small'>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent sx={styles.cardContent}>
        {configuration && data?.rows && data?.rows.length > 0 ? (
          <TableChart configuration={configuration} data={data} />
        ) : (
          <Typography variant='overline'>{t('commons.placeholders.nothingToDisplay')}</Typography>
        )}
      </CardContent>
    </Card>
  );
};

const TableChart = ({ configuration, data }) => {
  const history = useHistory();

  const firstColumns = 5; // entityUuid, system, schema, entity, score
  const residualRiskFieldsIndexes = _.range(firstColumns, data.columns.length);
  const domainElements = configuration.domains
    .filter((e) => e[0] === 'RISK_EVALUATION')
    .reduce((m, e) => {
      m[e[2]] = e[1];
      return m;
    }, {});
  const totalDomainElements = Object.keys(domainElements).length;
  const colorRangeLowerLimit = totalDomainElements / 3;
  const colorRangeUpperLimit = Math.round(totalDomainElements / 3) * 2 + 1;

  const mappedRows = data.rows.map((row) => {
    return row.map((element, index) => {
      if (residualRiskFieldsIndexes.includes(index)) {
        return element ? { v: domainElements[element], f: element } : null;
      } else {
        return element;
      }
    });
  });
  const formatters = residualRiskFieldsIndexes.map((i) => ({
    type: 'ColorFormat',
    column: i,
    ranges: [
      [null, colorRangeLowerLimit, 'black', greenSemaphoreColor],
      [colorRangeLowerLimit, colorRangeUpperLimit, 'black', yellowSemaphoreColor],
      [colorRangeUpperLimit, null, 'black', redSemaphoreColor]
    ]
  }));
  const columnsToShow = _.range(1, data.columns.length);

  return (
    <Chart
      chartType='Table'
      width={'100%'}
      loader={<Typography variant='overline'>Loading...</Typography>}
      data={[data.columns, ...mappedRows]}
      formatters={formatters}
      options={{
        allowHtml: true,
        width: '100%',
        height: '100%'
      }}
      chartWrapperParams={{
        view: { columns: columnsToShow }
      }}
      chartEvents={[
        {
          eventName: 'select',
          callback: ({ chartWrapper }) => {
            const selection = chartWrapper.getChart().getSelection();
            if (selection.length === 1) {
              const [selectedItem] = selection;
              const { row } = selectedItem;
              const dataTable = chartWrapper.getDataTable();
              const physicalEntityUuid = dataTable.getValue(row, 0);
              history.push(`/physicalentities/${physicalEntityUuid}?tab=quality-assessment`);
            }
          }
        }
      ]}
    />
  );
};

const mapStateToProps = (state, props) => ({
  configuration: state.quality.assessment.configuration,
  data: state.quality.assessmentDashboards.table
});

const mapDispatchToProps = {
  fetchConfiguration,
  fetchDashboardValues: fetchResidualRisksTableDashboardData,
  exportResidualRisksTable
};

export default connect(mapStateToProps, mapDispatchToProps)(ResidualRisksTableCard);
