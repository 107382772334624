import React from 'react';
import { connect } from 'react-redux';
import { searchQualitySuite } from '../../../../../actions/QualitySuitesActions';
import ItemAutoComplete from '../../../../../components/itemselector/ItemAutoComplete';

function QualitySuiteItemAutoComplete({ onSearch, searchContent, onChange, value }) {
  return (
    <ItemAutoComplete
      values={searchContent}
      value={value}
      showAllOptions
      itemName='Suites'
      nameProperty={'name'}
      onChange={(newValue) => onChange(newValue)}
      onSearch={(inputValue) => {
        onSearch({ search: inputValue });
      }}></ItemAutoComplete>
  );
}

const mapStateToProps = (state, props) => ({
  searchContent: Object.values(state.quality.qualitySuites.content)
});

const mapDispatchToProps = (dispatch) => ({
  onSearch: (search) => dispatch(searchQualitySuite(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(QualitySuiteItemAutoComplete);
