import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Card, CardContent, CardHeader, Grid, Grow, IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import { fetchMyIssues } from 'actions/IssuesActions';
import IssueStatuses from 'pages/issueMngt/issues/detail/IssueStatuses';
import { MyIssuesStatusesColumns } from './MyIssuesStatusesColumns';
import { MyIssuesFiltersButton } from './MyIssuesFiltersButton';

export const MyIssuesPanel = ({ height }) => {
  const orderedIssueStatuses = [
    IssueStatuses.TO_DO,
    IssueStatuses.IN_PROGRESS,
    IssueStatuses.REVIEW,
    IssueStatuses.DONE
  ];
  const dispatch = useDispatch();
  const history = useHistory();

  const [myIssuesFilters, setMyIssuesFilters] = useState({});

  useEffect(() => {
    dispatch(
      fetchMyIssues({
        ...myIssuesFilters,
        size: 500,
        sort: 'updatedAt,desc'
      })
    );
  }, [dispatch, myIssuesFilters]);

  const issues = useSelector((state) => state.campaigns.issues.currentPage.content);

  const issuesByStatuses = _.groupBy(issues, 'issueStatus');

  return (
    <Grow in={true} mountOnEnter unmountOnExit {...{ timeout: 800 }}>
      <Card style={{ paddingBottom: '16px' }}>
        <CardHeader
          title={t('homepage.dashboard.myIssuesPanel.title')}
          style={{ padding: '16px 16px 0px 16px' }}
          action={
            <>
              <MyIssuesFiltersButton
                myIssuesFilters={myIssuesFilters}
                setMyIssuesFilters={setMyIssuesFilters}
              />
              <IconButton size='small' onClick={() => history.push('/issue-management/my-work')}>
                <OpenInNewIcon color='primary' fontSize='small' />
              </IconButton>
            </>
          }
        />
        <CardContent style={{ padding: '0px 16px 0px 16px' }}>
          {issues && issues.length > 0 ? (
            <Grid container spacing={1}>
              {orderedIssueStatuses.map((status) => (
                <MyIssuesStatusesColumns
                  key={status}
                  orderedIssueStatuses={orderedIssueStatuses}
                  status={status}
                  height={height}
                  issuesByStatuses={issuesByStatuses}
                />
              ))}
            </Grid>
          ) : (
            <Typography variant='body1' style={{ textAlign: 'center', padding: 100 }}>
              {t('homepage.dashboard.myIssuesPanel.noIssue')}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Grow>
  );
};
