import { FETCH_DASHBOARD_SYSTEM_DATA_SUBJECT_COUNT, FETCH_DASHBOARD_SYSTEM_METADATA_METRICS } from "../../constants/ActionTypes";
import { combineReducers } from 'redux'

const dataSubjectsCount = (state = {/*systemUuid -> DashBoardDataRes*/ }, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_SYSTEM_DATA_SUBJECT_COUNT:
      return { ...state, [action.payload.systemUuid]: action.payload }
    default:
      return state;
  }
}

const metadataMetrics = ( state = {/*systemUuid->DashboardDataRes*/ }, action ) => {
  switch (action.type) {
    case FETCH_DASHBOARD_SYSTEM_METADATA_METRICS:
      return { ...state, [action.payload.systemUuid]: action.payload }
    default:
      return state;
  }
}

export default combineReducers({ dataSubjectsCount, metadataMetrics })