import { BlindataApi } from './ApiUtils';

const ISSUE_MNGT_DASHBOARDS_URL = '/api/v1/issue-management';

export default class IssueMngtDashboardsApi extends BlindataApi {
  getTemporal(uuid) {
    return this.get(`${ISSUE_MNGT_DASHBOARDS_URL}/dashboards/completion-over-time`, {
      campaignUuid: uuid
    });
  }

  getIssuesTypesCounter({ resourceIdentifier, resourceType }) {
    return this.get(`${ISSUE_MNGT_DASHBOARDS_URL}/dashboards/issues-types-counter`, {
      resourceIdentifier,
      resourceType
    });
  }
}
