/* eslint-disable no-useless-constructor */
import { BlindataAgentApi } from './BlindataAgentApi';

const AGENT_SCHEDULE_URL = '/agent-api/v1/jobs/schedules';
export default class AgentSchedulesApi extends BlindataAgentApi {
  constructor(agent) {
    super(agent);
  }

  getSchedule(params) {
    return this.get(AGENT_SCHEDULE_URL, params);
  }
  postSchedule(schedule) {
    return this.post(AGENT_SCHEDULE_URL, schedule);
  }
  putSchedule(schedule) {
    return this.put(`${AGENT_SCHEDULE_URL}/${schedule.uuid}`, schedule);
  }

  getSingleSchedule(uuid) {
    return this.get(`${AGENT_SCHEDULE_URL}/${uuid}`);
  }

  deleteSchedule(uuid) {
    return this.delete(`${AGENT_SCHEDULE_URL}/${uuid}`);
  }
}
