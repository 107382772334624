import { Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { fetchQueryBucket } from 'actions/QueryBucketsActions';
import Breadcrumbs from 'pages/sidebar/Navigation';
import QueryBucketDetailSection from './QueryBucketDetailSection';
import useSearchParams from 'components/useSearchParams';
import QueryStatementsCard from '../../statements/list/QueryStatementsCard';
import withAuthorize from 'components/permissions/withAuthorize';
import Permissions from 'constants/Permissions';
import QueryBucketSchedulesSection from './schedules/QueryBucketSchedulesSection';
import EngagementActions from 'constants/EngagementActions';
import EngagementCategories from 'constants/EngagementCategories';
import Tracking from 'components/engagement/Tracking';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import ResourceTypes from 'constants/ResourceTypes';

const QueryBucketDetailPage = ({ queryBucket, queryBucketUuid, fetchQueryBucket }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useSearchParams();
  const selectedTab = query.get('tab') || 'statements';

  const handleChangeTab = (_event, newValue) =>
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });

  useEffect(() => {
    if (queryBucketUuid) {
      fetchQueryBucket(queryBucketUuid);
    }
  }, [queryBucketUuid, fetchQueryBucket]);

  if (!queryBucket) return <></>;

  return (
    <div>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.data_flows'), to: '/dataflows' },
          { text: t('navbar.queryparser'), to: '/dataflows/queryparser' },
          { text: queryBucket?.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('querybuckets.header_detail', {
            queryBucketName: queryBucket?.name
          }),
          category: EngagementCategories.DATA_FLOW,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container alignItems='center' direction='row'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            {t('querybuckets.header_detail', {
              queryBucketName: queryBucket?.name
            })}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`${ResourceTypes.QUERY_BUCKET}:${queryBucket.uuid}`}
            resourceIdentifier={queryBucket.uuid}
            resourceType={ResourceTypes.QUERY_BUCKET}
            resourceName={queryBucket.name}
            pageTitle={t('querybuckets.header_detail', {
              queryBucketName: queryBucket?.name
            })}
          />
        </Grid>
      </Grid>
      <QueryBucketDetailSection queryBucket={queryBucket} queryBucketUuid={queryBucketUuid} />

      <Tabs value={selectedTab} onChange={handleChangeTab} centered>
        <Tab value='statements' label='Statements' />
        <AuthorizedTab
          hasPermissions={[Permissions.AGENTS_VIEWER]}
          value='schedules'
          label='Schedules'
        />
      </Tabs>
      {selectedTab === 'statements' && <QueryStatementsCard queryBucket={queryBucket} />}
      {selectedTab === 'schedules' && <QueryBucketSchedulesSection queryBucket={queryBucket} />}
    </div>
  );
};

const AuthorizedTab = withAuthorize(Tab, 'span');

const mapStateToProps = (state, props) => ({
  queryBucketUuid: props.match.params['queryBucketUuid'],
  queryBucket: state.dataflows.querybuckets.content[props.match.params['queryBucketUuid']]
});

export default connect(mapStateToProps, { fetchQueryBucket })(QueryBucketDetailPage);
