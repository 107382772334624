import React, { useEffect } from 'react';

import { fetchWorskpacesPage } from 'actions/social/favorites/WorkspaceActions';
import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { fetchFavoritesPage } from 'actions/social/favorites/FavoritesActions';
import WorkspaceItemCard from '../../social/favorites/workspaces/commons/WorkspaceItemCard';
import WorkspaceFAB from '../../social/favorites/workspaces/commons/WorkspaceFAB';

function WorkspacesWidget({ maxWidth, fetchWorskpacesPage, fetchFavoritesPage, currentPage }) {
  useEffect(() => {
    fetchWorskpacesPage();
  }, [fetchWorskpacesPage]);

  useEffect(() => {
    fetchFavoritesPage({ size: 200, page: 0 });
  }, [fetchFavoritesPage]);

  if (currentPage.content.length < 1) {
    return (
      <>
        <Typography
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '300px'
          }}>
          No workspace defined
        </Typography>
        <WorkspaceFAB />
      </>
    );
  }
  return (
    <div style={{ padding: 8, margin: '0 auto 0px auto', maxWidth: `${maxWidth}px` }}>
      <Grid container spacing={2} direction='row' alignItems='stretch' alignContent='center'>
        {(currentPage.content || []).map((workspace) => (
          <Grid item key={workspace.uuid} xs={12} md={6} xl={4}>
            <WorkspaceItemCard
              workspaces={currentPage.content}
              workspace={workspace}
              fetchFavoritesPage={fetchFavoritesPage}
              fetchWorskpacesPage={fetchWorskpacesPage}></WorkspaceItemCard>
          </Grid>
        ))}
      </Grid>
      <WorkspaceFAB />
    </div>
  );
}
const mapStateToProps = (state) => ({
  currentPage: state.social.workspace.currentPage
});

export default connect(mapStateToProps, { fetchWorskpacesPage, fetchFavoritesPage })(
  WorkspacesWidget
);
