import { combineReducers } from 'redux';
import crudReducers from './utils';
import filtersReducer from 'reducers/utils/filters';
import ResourceTypes from 'constants/ResourceTypes';

const filtersDefault = {
  dataClass: null,
  clearance: null,
  teamsCodes: [],
  dataCategoriesUuid: [],
  search: '',
  nullTeamCode: undefined,
  systems: []
};

export default combineReducers({
  ...crudReducers({ resource: ResourceTypes.DATA_CATEGORY, keyProperty: 'uuid' }),
  filters: filtersReducer({ resource: ResourceTypes.DATA_CATEGORY, filtersDefault: filtersDefault })
});
