import React from 'react';
import PropTypes from 'prop-types';
import AutoComplete from '../autocomplete/AutoComplete';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class ItemAutoComplete extends React.Component {
  state = {
    lastSearch: ''
  };
  debouncedSearch = _.debounce((searchText) => {
    this.props.onSearch(searchText);
  }, 275);

  render() {
    let props = this.props;
    let { lastSearch } = this.state;
    let preparedValues = props.values
      .filter((v) => v !== null)
      .map((e) => ({ ...e, _labelText: createLabel(props, e) }));
    return (
      <AutoComplete
        disabled={props.disabled}
        required={props.required}
        label={props.itemName}
        fullWidth
        margin={props.margin}
        showAllOptions={props.showAllOptions || false}
        placeholder={props.placeholder || `Select a ${props.itemName}`}
        value={(props.value && props.value[props.nameProperty]) || lastSearch}
        dataSource={preparedValues}
        dataSourceConfig={{
          text: '_labelText',
          subheader: props.subheaderProperty,
          value: 'uuid'
        }}
        onNewRequest={(string, dataSource, value) => {
          this.setState({ lastSearch: string });
          props.onChange(value);
        }}
        onUpdateInput={(searchText, dataSource) => {
          this.setState({ lastSearch: searchText });
          this.debouncedSearch(searchText);
          if (this.props.value) {
            props.onChange(null);
          }
        }}
      />
    );
  }
}

const createLabel = ({ nameProperty, categoryProperties, labelSeparator = ': ' }, e) => {
  return categoryProperties &&
    categoryProperties.length > 0 &&
    _.get(e, categoryProperties.join('.'))
    ? `${_.get(e, categoryProperties.join('.'))}${labelSeparator} ${e[nameProperty]}`
    : `${e[nameProperty]}`;
};

ItemAutoComplete.propTypes = {
  itemName: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  showAllOptions: PropTypes.bool,
  placeholder: PropTypes.string,
  nameProperty: PropTypes.string.isRequired,
  categoryProperties: PropTypes.array,
  labelSeparator: PropTypes.string,
  subheaderProperty: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired
};
export default withTranslation()(ItemAutoComplete);
