import React, { useState, useMemo, useEffect } from 'react';
import {
  computeGraph,
  computeLayout,
  emphasizeNodes,
  deemphasizeNodes,
  emphasizeEdge,
  deemphasizeEdge,
  downloadImage
} from './ErDiagramUtils';

import {
  ReactFlow,
  Controls,
  ControlButton,
  useNodesState,
  useEdgesState
} from 'reactflow';
import 'reactflow/dist/style.css';
import { CloseFullscreen, OpenInFull, FileDownload } from '@mui/icons-material';

import ErDiagramNode from './ErDiagramNode';
import ErDiagramNodeContextMenu from './ErDiagramNodeContextMenu';
import ErDiagramEdgeContextMenu from './ErDiagramEdgeContextMenu';

const ErDiagram = ({ rawNodes, rawEdges }) => {

  // Graph data & layout management
  const { layoutedNodes, layoutedEdges } = useMemo(
    () => {
      const { nodes: styledNodes, edges: styledEdges } = computeGraph(rawNodes, rawEdges);
      const { nodes: layoutedNodes, edges: layoutedEdges } = computeLayout(styledNodes, styledEdges);
      return { layoutedNodes, layoutedEdges };
    },
    [rawNodes, rawEdges]
  );

  // Nodes and edges management
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  useEffect(() => {
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [setNodes, setEdges, layoutedNodes, layoutedEdges])
  const onEdgeMouseEnter = (event, edge) => {
    setEdges(edges.map(e => e.id === edge.id ? emphasizeEdge(e) : e));
    setNodes(emphasizeNodes(nodes, edge));
  }
  const onEdgeMouseLeave = (event, edge) => {
    setEdges(edges.map(e => e.id === edge.id ? deemphasizeEdge(e) : e));
    setNodes(deemphasizeNodes(nodes, edge));
  }
  const nodeTypes = useMemo(() => ({ custom: ErDiagramNode }), []);
  const collapseAll = () => {
    const collapsedNodes = nodes.map(n => ({ ...n, data: { ...n.data, collapsed: true } }));
    const { nodes: layoutedNodes } = computeLayout(collapsedNodes, edges, true);
    setNodes(layoutedNodes);
  }
  const expandAll = () => {
    const expandedNodes = nodes.map(n => ({ ...n, data: { ...n.data, collapsed: false } }));
    const { nodes: layoutedNodes } = computeLayout(expandedNodes, edges, false);
    setNodes(layoutedNodes);
  }

  // Context menus management
  const [currentElement, setCurrentElement] = useState({});
  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const openContextMenu = (event, element) => {
    event.preventDefault();
    setContextMenuPosition(
      contextMenuPosition === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6
        }
        : null
    );
    setCurrentElement(element);
  };
  const closeContextMenu = () => {
    setContextMenuPosition(null);
  };

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onNodeContextMenu={(event, node) => openContextMenu(event, { node: node })}
        onEdgesChange={onEdgesChange}
        onEdgeMouseEnter={onEdgeMouseEnter}
        onEdgeMouseLeave={onEdgeMouseLeave}
        onEdgeContextMenu={(event, edge) => openContextMenu(event, { edge: edge })}
        fitView
        minZoom={0}
      >
        <Controls>
          <ControlButton
            title="collapse all nodes"
            aria-label="collapse all nodes"
            onClick={collapseAll}
          >
            <CloseFullscreen />
          </ControlButton>
          <ControlButton
            title="expand all nodes"
            aria-label="expand all nodes"
            onClick={expandAll}
          >
            <OpenInFull />
          </ControlButton>
          <ControlButton
            title="download image"
            aria-label="download image"
            onClick={downloadImage}
          >
            <FileDownload />
          </ControlButton>
        </Controls>
      </ReactFlow>
      <ContextMenu element={currentElement} position={contextMenuPosition} handleClose={closeContextMenu} />
    </>
  );
}

const ContextMenu = ({ element, position, handleClose }) => {
  if (element.edge) {
    return (
      <ErDiagramEdgeContextMenu edge={element.edge} position={position} handleClose={handleClose} />
    )
  } else if (element.node) {
    return (
      <ErDiagramNodeContextMenu node={element.node} position={position} handleClose={handleClose} />
    )
  }
  else return null;
}

export default ErDiagram;