import crudReducers from 'reducers/utils';
import { combineReducers } from 'redux';
import {
  FETCH_CONTRACT_TEMPLATE_PAGE,
  CLEAR_CONTRACT_TEMPLATES,
  FETCH_CONTRACT_TEMPLATE,
  CREATE_CONTRACT_TEMPLATE,
  MODIFY_CONTRACT_TEMPLATE,
  DELETE_CONTRACT_TEMPLATE,
  EDIT_CONTRACT_TEMPLATE_EDITOR,
  CLEAR_CONTRACT_TEMPLATE_EDITOR
} from '../../constants/ActionTypes';

const content = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONTRACT_TEMPLATE:
    case CREATE_CONTRACT_TEMPLATE:
    case MODIFY_CONTRACT_TEMPLATE:
      return {
        ...state,
        [action.payload.uuid]: action.payload
      };
    case DELETE_CONTRACT_TEMPLATE:
      let { [action.payload.uuid]: _clearedELem, ...stateDiff } = state;
      return stateDiff;
    default:
      return state;
  }
};

const currentPage = (state = { content: [], number: 0 }, action) => {
  switch (action.type) {
    case FETCH_CONTRACT_TEMPLATE_PAGE:
      return action.payload;
    case DELETE_CONTRACT_TEMPLATE:
      return { ...state, content: state.content.filter((i) => i.uuid !== action.payload.uuid) };
    case CLEAR_CONTRACT_TEMPLATES:
      return { content: [], number: 0 };
    default:
      return state;
  }
};

const DEFAULT_EDITOR = {
  contentTemplate: '<div>\n\t<h1>{{replace-me-header}}</h1>\n\t<p>{{replace-me-par}}</p>\n<div>',
  placeholders: [
    {
      name: 'replace-me-header',
      value: 'Lorem ipsum'
    },
    {
      name: 'replace-me-par',
      value:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vulputate lacus eget massa condimentum, non blandit tellus fermentum. Pellentesque elit enim, varius nec purus quis, malesuada viverra sapien. '
    }
  ]
};

const editor = (state = DEFAULT_EDITOR, action) => {
  switch (action.type) {
    case EDIT_CONTRACT_TEMPLATE_EDITOR:
      return { ...state, ...action.payload };
    case CLEAR_CONTRACT_TEMPLATE_EDITOR:
      return DEFAULT_EDITOR;
    default:
      return state;
  }
};

const attachments = combineReducers(
  crudReducers({ resource: 'CONTRACT_TEMPLATE_ATTACHMENT', keyProperty: 'uuid' })
);

export default combineReducers({ content, currentPage, editor, attachments });
