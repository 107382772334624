/* eslint-disable */
import NodeBase from '../util/NodeBase';

// Utility function
function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

const cos45 = Math.cos(degreesToRadians(45)),
  sin45 = Math.sin(degreesToRadians(45)),
  cos135 = Math.cos(degreesToRadians(135)),
  sin135 = Math.sin(degreesToRadians(135)),
  cos225 = Math.cos(degreesToRadians(225)),
  sin225 = Math.sin(degreesToRadians(225)),
  cos315 = Math.cos(degreesToRadians(315)),
  sin315 = Math.sin(degreesToRadians(315));

/**
 * Base class for constructing Node/Cluster Shapes.
 *
 * @extends NodeBase
 */
class ShapeBase extends NodeBase {
  /**
   * @param {Object} options
   * @param {Object} body
   * @param {Label} labelModule
   */
  constructor(options, body, labelModule) {
    super(options, body, labelModule);
  }

  /**
   *
   * @param {CanvasRenderingContext2D} ctx
   * @param {boolean} [selected]
   * @param {boolean} [hover]
   * @param {Object} [values={size: this.options.size}]
   */
  resize(ctx, selected = this.selected, hover = this.hover, values = { size: this.options.size }) {
    if (this.needsRefresh(selected, hover)) {
      this.labelModule.getTextSize(ctx, selected, hover);
      var size = 2 * values.size;
      this.width = size;
      this.height = size;
      this.radius = 0.5 * this.width;
    }
  }

  /**
   *
   * @param {CanvasRenderingContext2D} ctx
   * @param {string} shape
   * @param {number} sizeMultiplier - Unused! TODO: Remove next major release
   * @param {number} x
   * @param {number} y
   * @param {boolean} selected
   * @param {boolean} hover
   * @param {{toArrow: boolean, toArrowScale: (allOptions.edges.arrows.to.scaleFactor|{number}|allOptions.edges.arrows.middle.scaleFactor|allOptions.edges.arrows.from.scaleFactor|Array|number), toArrowType: *, middleArrow: boolean, middleArrowScale: (number|allOptions.edges.arrows.middle.scaleFactor|{number}|Array), middleArrowType: (allOptions.edges.arrows.middle.type|{string}|string|*), fromArrow: boolean, fromArrowScale: (allOptions.edges.arrows.to.scaleFactor|{number}|allOptions.edges.arrows.middle.scaleFactor|allOptions.edges.arrows.from.scaleFactor|Array|number), fromArrowType: *, arrowStrikethrough: (*|boolean|allOptions.edges.arrowStrikethrough|{boolean}), color: undefined, inheritsColor: (string|string|string|allOptions.edges.color.inherit|{string, boolean}|Array|*), opacity: *, hidden: *, length: *, shadow: *, shadowColor: *, shadowSize: *, shadowX: *, shadowY: *, dashes: (*|boolean|Array|allOptions.edges.dashes|{boolean, array}), width: *}} values
   * @private
   */
  _drawShape(ctx, shape, sizeMultiplier, x, y, selected, hover, values) {
    this.resize(ctx, selected, hover, values);
    this.left = x - this.width / 2;
    this.top = y - this.height / 2;

    this.initContextForDraw(ctx, values);
    ctx[shape](x, y, values.size);
    this.performFill(ctx, values);

    //Render font as icon in the node
    if (this.options.icon !== undefined) {
      if (this.options.icon.code !== undefined) {
        ctx.font =
          (selected ? 'bold ' : '') +
          this.height / 2 +
          'px ' +
          (this.options.icon.face || 'FontAwesome');
        ctx.fillStyle = this.options.icon.color || 'black';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(this.options.icon.code, x, y);
      }
    }

    if (Array.isArray(this.options.glyphs)) {
      this.options.glyphs.forEach((glyph) => this._drawGlyph(ctx, x, y, values.size, glyph));
    }

    if (this.options.label !== undefined) {
      // Need to call following here in order to ensure value for `this.labelModule.size.height`
      this.labelModule.calculateLabelSize(ctx, selected, hover, x, y, 'hanging');
      let yLabel = y + 0.5 * this.height + 0.5 * this.labelModule.size.height;
      this.labelModule.draw(ctx, x, yLabel, selected, hover, 'hanging');
    }

    this.updateBoundingBox(x, y);
  }

  _drawGlyph(ctx, x, y, size, glyph) {
    // console.log(x, y, size, radius, glyph);
    let scale = this.body.view.scale;
    let radius = size / 2.5;
    let threshold = 1.5;
    let textThreshold = 2;
    let strokeThreshold = 6;
    let fontScale = 1;

    if (x !== undefined && y !== undefined && radius * scale > threshold) {
      switch (glyph.position) {
        case 'top-right':
          x += size * cos315;
          y += size * sin315;
          break;
        case 'top-left':
          x += size * cos225;
          y += size * sin225;
          break;
        case 'bottom-left':
          x += size * cos135;
          y += size * sin135;
          break;
        case 'bottom-right':
          x += size * cos45;
          y += size * sin45;
          break;
      }

      // Glyph rendering
      ctx.fillStyle = glyph.fillColor;
      if (glyph.strokeColor !== ctx.strokeStyle) {
        ctx.strokeStyle = glyph.strokeColor;
      }
      ctx.beginPath();
      ctx.arc(x, y, radius, 2 * Math.PI, false);
      ctx.closePath();
      if (radius * scale > strokeThreshold) {
        ctx.stroke();
      }
      ctx.fill();

      // Glyph content rendering
      if (radius * scale > textThreshold) {
        var fontSize = Math.round(fontScale * radius);
        var font =
          (glyph.fontStyle || 'bold') + ' ' + fontSize + 'px ' + (glyph.font || 'Helvetica');
        if (font !== ctx.font) {
          ctx.font = font;
        }
        ctx.fillStyle = glyph.textColor;
        ctx.fillText(glyph.content, x, y);
      }
    }
  }

  /**
   *
   * @param {number} x
   * @param {number} y
   */
  updateBoundingBox(x, y) {
    this.boundingBox.top = y - this.options.size;
    this.boundingBox.left = x - this.options.size;
    this.boundingBox.right = x + this.options.size;
    this.boundingBox.bottom = y + this.options.size;

    if (this.options.label !== undefined && this.labelModule.size.width > 0) {
      this.boundingBox.left = Math.min(this.boundingBox.left, this.labelModule.size.left);
      this.boundingBox.right = Math.max(
        this.boundingBox.right,
        this.labelModule.size.left + this.labelModule.size.width
      );
      this.boundingBox.bottom = Math.max(
        this.boundingBox.bottom,
        this.boundingBox.bottom + this.labelModule.size.height
      );
    }
  }
}

export default ShapeBase;
