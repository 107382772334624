import React from 'react';
import { Typography } from '@mui/material';

function VariationTypeDecorator({ children }) {
  return children ? (
    <Typography
      variant='overline'
      sx={{
        borderStyle: 'dashed',
        borderWidth: '1px',
        borderRadius: 10,
        borderColor: (props) => props.borderColor || 'grey',
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
        padding: '4px',
        color: 'grey'
      }}>
      {children}
    </Typography>
  ) : null;
}

export default VariationTypeDecorator;
