import React from 'react';
import { withOpenCloseHandler } from '../../../components/openclosehandler/OpenCloseHandler';
import {
  AuthorizedChipItem,
  AuthorizedChipWrapper
} from '../../../components/chiplist/AuthorizedChipList';
import LogicalFieldSearchModal from 'pages/datacategories/utils/LogicalFieldSearchModal';

function LogicalFieldsChipListEditor({ values, onChange, onToggleOpen, isOpen, permissions }) {
  return (
    <>
      <AuthorizedChipWrapper
        permissionsFilters={{ onRequestAdd: permissions }}
        onRequestAdd={() => onToggleOpen()}>
        {(values || []).map((item) => (
          <AuthorizedChipItem
            permissionsFilters={{ onRequestDelete: permissions }}
            key={item.uuid}
            id={item.uuid}
            label={item.name}
            tooltip={item.description}
            onRequestDelete={() => onChange(values.filter((e) => e.uuid !== item.uuid))}
          />
        ))}
      </AuthorizedChipWrapper>
      <LogicalFieldSearchModal
        open={isOpen}
        onCancel={() => onToggleOpen()}
        onSubmit={(item) => {
          onToggleOpen();
          onChange((values || []).concat([item]));
        }}
      />
    </>
  );
}
export default withOpenCloseHandler(LogicalFieldsChipListEditor);
