import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { executeAnalysis } from 'actions/dataflows/DataFlowsImpactAnalysisActions';
import { connect } from 'react-redux';
function AnalysisButton({ editor, setResults, executeAnalysis }) {
  let [open, setOpen] = useState(false);
  return (
    <>
      <Button
        disabled={
          !(
            editor?.dataCategories?.length > 0 ||
            editor?.logicalFields?.length > 0 ||
            editor?.physicalEntities?.length > 0 ||
            editor?.physicalFields?.length > 0
          )
        }
        color='primary'
        children='Execute'
        variant='outlined'
        onClick={() => setOpen(true)}
      />
      {open && (
        <AnalysisModal
          executeAnalysis={executeAnalysis}
          open={open}
          handleCancel={() => setOpen(false)}
          editor={editor}
          setResults={setResults}
        />
      )}
    </>
  );
}

function AnalysisModal({ open, executeAnalysis, handleCancel, editor, setResults }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  return (
    <Dialog open={open}>
      <DialogTitle children='Do you want to start analysis?' />
      <DialogContent>
        {loading && (
          <CircularProgress style={{ marginLeft: 'calc(50% - 20px)', marginTop: '1.5em' }} />
        )}
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.cancel')} onClick={handleCancel}></Button>
        <Button
          children={t('commons.actions.analyze')}
          variant='contained'
          color='primary'
          onClick={() => {
            setLoading(true);
            executeAnalysis(editor).then((response) => {
              setLoading(false);
              setResults(response);
            });
            handleCancel();
          }}></Button>
      </DialogActions>
    </Dialog>
  );
}
export default connect(null, { executeAnalysis })(AnalysisButton);
