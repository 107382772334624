import Permissions from 'constants/Permissions';
import React from 'react';
import { withTranslation } from 'react-i18next';

import DataCategoriesChipListEditor from '../../../../datacategories/utils/DataCategoriesChipListEditor';

const DataCategoriesSection = ({ physicalEntity, onAddDataCategory, t }) => {
  return (
    <>
      <h4>{t('physicalEntities.detail.dataCategories.title')}</h4>

      <DataCategoriesChipListEditor
        permissions={[Permissions.SYSTEMS_EDITOR]}
        values={physicalEntity.dataCategories || []}
        onChange={(dataCategory) => {
          onAddDataCategory && onAddDataCategory(dataCategory, physicalEntity);
        }}
      />
    </>
  );
};

export default withTranslation()(DataCategoriesSection);
