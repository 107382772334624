import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';

export const AnalysisStrategySelect = ({ value, onChange }) => {
  return (
    <>
      <FormControl fullWidth variant='standard' margin='dense'>
        <InputLabel id='analysis-strategy-label'>Analysis Strategy</InputLabel>
        <Select
          labelId='analysis-strategy-label'
          id='analysis-strategy'
          value={value}
          variant='standard'
          fullWidth
          onChange={(event) => onChange(event.target.value)}>
          <MenuItem value={null}>
            <ListItem dense>
              <ListItemText primary='NONE' />
            </ListItem>
          </MenuItem>
          <MenuItem value='MARKUP'>
            <ListItem dense>
              <ListItemText primary='MARKUP' />
            </ListItem>
          </MenuItem>
          <MenuItem value='AUTOMATIC'>
            <ListItem dense>
              <ListItemText primary='AUTOMATIC' />
            </ListItem>
          </MenuItem>
        </Select>
      </FormControl>

      {value === 'MARKUP' ? (
        <Card variant='outlined'>
          <CardHeader subheader='The markup that must be modified and inserted before each routine statements. ' />
          <CardContent>
            <Typography variant='body2'>{`--SQL_LINEAGE (name: <QueryStatement name>, description: <QueryStatement description>, schema: <QueryStatement schema>)`}</Typography>
          </CardContent>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};
