import React from 'react';
import { connect } from 'react-redux';
import { MenuItem, Toolbar, Select, Button } from '@mui/material';
import grey from '@mui/material/colors/grey';
import {
  changeGraphFilterEditor,
  addGraphUuidFilter,
  requestGraph
} from '../../../../actions/GraphActions';
import ResourceSearchModals from './ResourceSearchModals';
import { withTranslation } from 'react-i18next';

const GraphResourceFilterToolbar = ({
  filterEditor,
  t,
  changeGraphFilterEditor,
  addGraphUuidFilter,
  requestGraph
}) => (
  <Toolbar
    style={{
      marginTop: '8px',
      marginBottom: '8px',
      backgroundColor: grey['300']
    }}>
    <Select
      variant='standard'
      style={{ width: 180 }}
      value={filterEditor.entityType}
      onChange={(event) => changeGraphFilterEditor({ entityType: event.target.value })}>
      {filterEditor.ENTITIES.map((e) => (
        <MenuItem key={e} value={e} children={t(`resourceType.${e}`)} />
      ))}
    </Select>
    <Button
      style={{ marginLeft: 8 }}
      children={t('commons.actions.add')}
      onClick={() => changeGraphFilterEditor({ openResourceFilterEditor: true })}
    />
    <div style={{ flexGrow: 1 }} />

    {/*<FlatButton label="+ Property filter" onClick={() => props.changeGraphFilterEditor({ openPropertyFilterEditor: true })} />*/}
    <Button children='Graph' variant='contained' color='primary' onClick={() => requestGraph()} />
    <ResourceSearchModals
      {...{
        filterEditor,
        changeGraphFilterEditor,
        addGraphUuidFilter
      }}
    />
  </Toolbar>
);

const mapStatetoProps = (state) => ({
  filterEditor: state.graph.filterEditor
});

const mapDispatchToProps = {
  changeGraphFilterEditor,
  addGraphUuidFilter,
  requestGraph
};

export default withTranslation()(
  connect(mapStatetoProps, mapDispatchToProps)(GraphResourceFilterToolbar)
);
