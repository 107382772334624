import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Table as TableMUI, TableHead, TableBody, TableRow } from '@mui/material';

import TableCell from 'components/tables/GenericTableCell';
import TableActions from 'pages/systems/physical_entities/detail/PhysicalEntityDetailsCard/PhysicalFieldsSection/TableActions';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';
import ResourceTypes from 'constants/ResourceTypes';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';

const Table = ({ fields = [], handleShowDetails, handleEdit, handleDelete }) => {
  const { t } = useTranslation();
  return (
    <TableMUI size='small' style={{ overflowX: 'auto' }}>
      <TableHead>
        <TableRow>
          <TableCell>{t('physicalFields.detail.general.name')}</TableCell>
          <TableCell hiddenMd>{t('physicalFields.detail.general.description')}</TableCell>
          <TableCell hiddenSm>{t('physicalFields.detail.general.type')}</TableCell>
          <TableCell>{t('physicalFields.detail.general.logicalField')}</TableCell>
          <Authorize hasPermissions={[Permissions.CAMPAIGNS_VIEWER]}>
            <TableCell hiddenSm>Issues</TableCell>
          </Authorize>
          <TableCell>{t('commons.fields.actions')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fields.map((field) => (
          <TableRow
            hover={true}
            style={{ cursor: 'pointer' }}
            key={field.uuid}
            onClick={() => handleShowDetails(field.uuid)}>
            <TableCell>{field.name}</TableCell>
            <TableCell breakWord hiddenMd>
              {_.truncate(field.description, { length: 125 }) || '-'}
            </TableCell>
            <TableCell hiddenSm>{field.type || '-'}</TableCell>
            <TableCell>
              {(field.logicalFields || [])
                .map((lf) => lf.dataCategory?.name + ' > ' + lf?.name)
                .filter((e) => e)
                .join(',\n') || '-'}
            </TableCell>
            <Authorize hasPermissions={[Permissions.CAMPAIGNS_VIEWER]}>
              <TableCell hiddenSm>
                <IssueResourceListItemWidget
                  resourceType={ResourceTypes.PHYSICAL_FIELD}
                  resourceIdentifier={field.uuid}></IssueResourceListItemWidget>
              </TableCell>
            </Authorize>
            <TableCell>
              <TableActions
                field={field}
                onShowDetails={() => handleShowDetails(field.uuid)}
                onEdit={() => handleEdit(field)}
                onDelete={() => handleDelete(field)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableMUI>
  );
};

export default Table;
