export const UNITS = [
  {
    type: 'minutes',
    min: 0,
    max: 59,
    total: 60,
    emptyValue: 'cronConverter.emptyValues.MINUTE'
  },
  {
    type: 'hours',
    min: 0,
    max: 23,
    total: 24,
    emptyValue: 'cronConverter.emptyValues.HOUR'
  },
  {
    type: 'month-days',
    min: 1,
    max: 31,
    total: 31,
    emptyValue: 'cronConverter.emptyValues.MONTHDAY'
  },
  {
    type: 'months',
    min: 1,
    max: 12,
    total: 12,
    emptyValue: 'cronConverter.emptyValues.MONTH',
    alt: [
      'cronConverter.months.JANUARY',
      'cronConverter.months.FEBRUARY',
      'cronConverter.months.MARCH',
      'cronConverter.months.APRIL',
      'cronConverter.months.MAY',
      'cronConverter.months.JUNE',
      'cronConverter.months.JULY',
      'cronConverter.months.AUGUST',
      'cronConverter.months.SEPTEMBER',
      'cronConverter.months.OCTOBER',
      'cronConverter.months.NOVEMBER',
      'cronConverter.months.DECEMBER',
    ]
  },
  {
    type: 'week-days',
    min: 1,
    max: 7,
    total: 7,
    emptyValue: 'cronConverter.emptyValues.WEEKDAY',
    alt: [
      'cronConverter.daysofweek.SUNDAY',
      'cronConverter.daysofweek.MONDAY',
      'cronConverter.daysofweek.TUESDAY',
      'cronConverter.daysofweek.WEDNESDAY',
      'cronConverter.daysofweek.THURSDAY',
      'cronConverter.daysofweek.FRIDAY',
      'cronConverter.daysofweek.SATURDAY',
    ]
  },
];