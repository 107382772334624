import { handleErrors, parseJson, encodeQueryData } from './ApiUtils'

export default class ConsentsApi {
  
  searchConsents(searchParam, page){
    let uriParams = encodeQueryData({...searchParam,noProps:true,page});
    return fetch(`/api/v1/consents/events?${uriParams}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
    .then(handleErrors)
    .then(parseJson)
  }

  getConsent(uuid){
    return fetch(`/api/v1/consents/events/${uuid}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson)
  }

}