import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  fetchAgentConnectionPage,
  patchAgentConnection,
  deleteAgentConnection,
  testAgentConnection
} from 'actions/onagent/AgentConnectionsActions';

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid
} from '@mui/material';
import OkIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/HighlightOff';

import PaginatedPanel from 'components/pagination/PaginatedPanel';
import AgentConnectionsListItem from 'pages/settings/agents/detail/AgentConnectionsTab/AgentConnectionsListItem';
import AgentConnectionFAB from 'pages/settings/agents/commons/AgentConnectionFAB';
import AgentConnectionModal from 'pages/settings/agents/commons/AgentConnectionModal';
import { useTranslation } from 'react-i18next';

function AgentConnectionsList({
  agent,
  currentPage,
  fetchAgentConnectionPage,
  patchAgentConnection,
  deleteAgentConnection,
  testAgentConnection
}) {
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [modifyModalOpen, setModifyModalOpen] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [testResultModalOpen, setTestResultModalOpen] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    fetchAgentConnectionPage(agent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAgentConnectionPage]);

  return (
    <>
      {currentPage.content.length === 0 ? (
        <Typography variant='body1' align='center' style={{ textAlign: 'center', padding: 100 }}>
          No connections associated to this agent yet.
        </Typography>
      ) : (
        <PaginatedPanel currentPage={currentPage}>
          {currentPage.content.map((connection) => (
            <AgentConnectionsListItem
              key={connection.uuid}
              connection={connection}
              handleTest={() => {
                testAgentConnection(agent, connection.uuid).then((response) => {
                  setTestResult(response);
                  setTestResultModalOpen(true);
                });
              }}
              handleEdit={() => {
                setSelectedConnection(connection);
                setModifyModalOpen(true);
              }}
              handleDelete={() => {
                deleteAgentConnection(agent, connection);
              }}
              onPageSelection={(page) => fetchAgentConnectionPage({ page })}
            />
          ))}
        </PaginatedPanel>
      )}

      <AgentConnectionFAB agent={agent} />

      {modifyModalOpen && (
        <EditConnectionModal
          agent={agent}
          selectedConnection={selectedConnection}
          open={modifyModalOpen}
          setOpen={setModifyModalOpen}
          patchConnection={patchAgentConnection}
        />
      )}

      {testResultModalOpen && (
        <TestResultModal
          t={t}
          result={testResult}
          open={testResultModalOpen}
          setOpen={setTestResultModalOpen}
        />
      )}
    </>
  );
}

const EditConnectionModal = ({ agent, selectedConnection, open, setOpen, patchConnection }) => (
  <AgentConnectionModal
    title='Modify connection'
    open={open}
    connection={selectedConnection}
    onSubmit={(patch) => {
      patchConnection(agent, selectedConnection.uuid, patch);
      setOpen(false);
    }}
    onClose={() => setOpen(false)}
  />
);

const TestResultModal = ({ result, open, setOpen, t }) => (
  <Dialog open={open}>
    <DialogTitle>Connection Test</DialogTitle>
    <DialogContent>
      <StyledResult status={result.status} message={result.message} />
    </DialogContent>
    <DialogActions>
      <Button children={t('commons.actions.close')} onClick={() => setOpen(false)}></Button>
    </DialogActions>
  </Dialog>
);

const StyledResult = ({ status, message }) => {
  const icon = status === 'OK' ? <OkIcon /> : <ErrorIcon />;
  const iconText = status === 'OK' ? 'OK' : 'ERROR';
  const iconColor = status === 'OK' ? 'green' : 'red';
  return (
    <Grid container direction='column'>
      <Grid
        item
        container
        alignItems='flex-end'
        justifyContent='center'
        style={{ color: iconColor }}>
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography variant='h6' style={{ marginLeft: '5px' }}>
            {iconText}
          </Typography>
        </Grid>
      </Grid>
      {message ? (
        <Grid item style={{ textAlign: 'center' }}>
          <Typography variant='caption'>{message}</Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapStateToProps = (state, props) => ({
  currentPage: state.settings.agents.connections.currentPage
});

const mapDispatchToProps = {
  fetchAgentConnectionPage,
  patchAgentConnection,
  deleteAgentConnection,
  testAgentConnection
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentConnectionsList);
