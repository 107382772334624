import { IconButton, Tooltip, MenuItem, Menu } from '@mui/material';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import GetAppIcon from '@mui/icons-material/GetApp';
import { exportLogicalRelations } from 'actions/LogicalRelationsActions';
import { useTranslation } from 'react-i18next';

const LogicalRelationExportButton = ({ dataCategory, logicalField, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (all) => {
    setAnchorEl(null);
    const searchOptions = {
      aboutDataCategoryUuid: dataCategory ? dataCategory.uuid : null,
      aboudLogicalFieldUuid: logicalField ? logicalField.uuid : null
    };
    onClick(all ? null : searchOptions);
  };

  return (
    <>
      <Tooltip title='Export'>
        <IconButton
          size='small'
          color='default'
          aria-label='Download'
          aria-haspopup='true'
          aria-controls={open ? 'logical-relations-export' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <GetAppIcon />
        </IconButton>
      </Tooltip>
      <Menu id='logical-relations-export' anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleClose(true)}>
          {t('logicalRelations.exportButton.all')}
        </MenuItem>
        <MenuItem onClick={() => handleClose(false)}>
          {t('logicalRelations.exportButton.current')}
        </MenuItem>
      </Menu>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    onClick: (searchOptions) => dispatch(exportLogicalRelations(searchOptions))
  };
}

export default connect(null, mapDispatchToProps)(LogicalRelationExportButton);
