import React from 'react';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid
} from '@mui/material';
import ProcessingCardView from '../../../processings/utils/ProcessingCardView';
import { connect } from 'react-redux';
import {
  fetchTemplateProcessing,
  deleteTemplateProcessing,
  modifyTemplateProcessing
} from '../../../../actions/TemplatesActions';
import { compose } from 'redux';
import { withLoader } from '../../../../components/loader/Loader';
import TemplateVariablesEditor from '../../commons/TemplateVariablesEditor';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import TemplateProcessingModal from '../commons/TemplateProcessingModal';
import { TemplatesProcessingsUpdateDialog } from '../../commons/TemplatesUpdateDialog';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
function TemplateProcessingDetailPage({
  templateProcessing,
  deleteTemplateProcessing,
  modifyTemplateProcessing
}) {
  let history = useHistory();
  let { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        elements={[{ text: 'Templates', to: '/templates' }, { text: templateProcessing.name }]}
      />
      <Tracking
        info={{
          pageTitle: `Processings Templates`,
          category: EngagementCategories.TEMPLATES,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Processing Template: {templateProcessing?.name}</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`PROCESSING_TEMPLATE:${templateProcessing.sequenceId}`}
            pageTitle={`Processing Template ${templateProcessing.name}`}></FavoritesWidget>
        </Grid>
      </Grid>

      <Card>
        <CardHeader
          title={templateProcessing.name}
          subheader={templateProcessing.code}></CardHeader>
        <CardContent>
          <TemplateVariablesEditor type='PROCESSING' variables={templateProcessing.variables} />
        </CardContent>
        <Authorize hasPermissions={[Permissions.TASKS_ADMIN]}>
          <CardActions>
            <ModifyAction {...{ templateProcessing, modifyTemplateProcessing }} />
            <ConfirmButton
              onClick={() =>
                deleteTemplateProcessing(templateProcessing.sequenceId).then(() =>
                  history.push('/templates/processings')
                )
              }>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </CardActions>
        </Authorize>
      </Card>
      <Typography variant='h5'>Content</Typography>
      <ProcessingCardView processing={templateProcessing.content}></ProcessingCardView>
    </>
  );
}

function ModifyAction({ templateProcessing, modifyTemplateProcessing }) {
  let { t } = useTranslation();
  let [open, setOpen] = React.useState(false);
  let [contentPatch, setContentPatch] = React.useState(null);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      {open && (
        <TemplateProcessingModal
          templateProcessing={templateProcessing}
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={(templateProcessing, contentPatch) => {
            modifyTemplateProcessing(templateProcessing).then(() => setOpen(false));
            setContentPatch(contentPatch);
          }}
        />
      )}
      {contentPatch && (
        <TemplatesProcessingsUpdateDialog
          open={contentPatch != null}
          templateCode={templateProcessing.code}
          contentPatch={contentPatch}
          onDismiss={() => setContentPatch(null)}
        />
      )}
    </>
  );
}

function mapStateToProps(state, props) {
  const sequenceId = props.match.params.sequenceId;
  return {
    templateProcessing: state.templates.templatesProcessings.content[sequenceId],
    sequenceId: props.match.params.sequenceId
  };
}

const mapDispatchToProps = {
  fetchTemplateProcessing,
  deleteTemplateProcessing,
  modifyTemplateProcessing
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchTemplateProcessing, sequenceId }) => fetchTemplateProcessing(sequenceId),
    ready: ({ templateProcessing }) => templateProcessing !== undefined
  })
)(TemplateProcessingDetailPage);
