/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { Switch, Route } from 'react-router';
import RuleDetailPage from './detail/RuleDetailPage';
import RulesListPage from './list/RulesListPage';

export default function (props) {
  return (
    <>
      <Switch>
        <Route path={`${props.match.url}/:uuid`} component={RuleDetailPage}></Route>

        <Route path={`${props.match.url}`} component={RulesListPage}></Route>
      </Switch>
    </>
  );
}