import React,{Component} from 'react';
import {connect} from 'react-redux';
import * as TaskGrantsActions from '../../actions/TaskGrantsActions'
import {searchSystemLogins} from '../../actions/SystemLoginActions'
import { fetchTasksPage} from '../../actions/TasksActions'
import TaskGrantsList from './TaskGrantsList'
import _ from 'lodash'

class TaskGrantsContainer extends Component{

    componentDidMount(){
        this.props.fetchTasksGrant()
        //this.props.fetchTasksPage()
    }

    render(){
      return (
        <TaskGrantsList {...this.props} />
      )
    }
}

const mapStateToProps= (state)=>({
  taskGrants: state.taskgrants.content,
  systemLogins: _.map(state.systemlogins.logins.content),
  tasks: state.tasks.content || []
})

export default connect(
  mapStateToProps,
  {
    ...TaskGrantsActions,
    fetchTasksPage,
    searchSystemLogins
  }
)(TaskGrantsContainer)
