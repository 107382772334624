import React from 'react';
import { useHistory } from 'react-router';

import useSearchParams from 'components/useSearchParams';

import { Box, Tab, Tabs, Grid } from '@mui/material';
import withAuthorize from 'components/permissions/withAuthorize';
import Permissions from 'constants/Permissions';
import ProjectProbesTab from 'pages/quality/probes/projects/detail/ProjectProbesTab';
import ProjectTagsTab from 'pages/quality/probes/projects/detail/ProjectTagsTab';
import ProjectScheduleTab from 'pages/quality/probes/projects/detail/ProjectScheduleTab';

function QualityProbesProjectsTabs({ project }) {
  const query = useSearchParams();
  const history = useHistory();
  const selectedTab = 1 * query.get('tab') || 0;
  const handleChange = (_event, newValue) =>
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });
  return <>
    <Grid container alignItems='baseline' direction='row' justifyContent='center'>
      <Grid item>
        <Box display='flex' justifyContent='space-around' width='100%'>
          <Tabs value={selectedTab} onChange={handleChange}>
            <Tab label='Probes' />
            <Tab label='Tags' />
            <AuthorizedTab hasPermissions={[Permissions.AGENTS_VIEWER]} label='Schedule' />
          </Tabs>
        </Box>
      </Grid>
    </Grid>

    {selectedTab === 0 && <ProjectProbesTab project={project} />}
    {selectedTab === 1 && <ProjectTagsTab project={project} />}
    {selectedTab === 2 && <ProjectScheduleTab project={project} />}
  </>;
}

const AuthorizedTab = withAuthorize(Tab, 'span');

export default QualityProbesProjectsTabs;
