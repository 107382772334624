import _ from 'lodash';
import ItemSelectField from '../../../../components/itemselector/ItemSelectField';
import { fetchPhysicalEntity } from '../../../../actions/PhysicalEntitiesActions';
import { withTranslation } from 'react-i18next';
import { withLoader } from '../../../../components/loader/Loader';
import { connect } from 'react-redux';

const mapStateToProps = (state, props) => ({
  physicalEntity: state.physicalentities.content[props.physicalEntityUuid]
});

const mapDispatchToProps = {
  fetchPhysicalEntity
};

const mergeProps = (stateProps, dispatchProps, { t, ...ownProps }) => ({
  //force reloading when physical entity uuid changes
  key: ownProps.physicalEntityUuid,
  itemName: t('physicalFields.label'),
  keyProperty: 'uuid',
  nameProperty: 'name',
  values: _.sortBy(_.get(stateProps, 'physicalEntity.physicalFields', []), 'name'),
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default _.flow([
  withLoader({
    onLoad: ({ physicalEntityUuid, physicalEntity, fetchPhysicalEntity }) => {
      if (physicalEntityUuid) {
        fetchPhysicalEntity(physicalEntityUuid);
      }
    },
    dependencies: ['physicalEntityUuid']
  }),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withTranslation()
])(ItemSelectField);
