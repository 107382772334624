import React from 'react';

import { TextField } from '@mui/material';

const JdbcConnectionProperties = ({ properties, updateProperties }) => {
  return (
    <>
      <TextField
        variant='standard'
        margin='dense'
        fullWidth
        required
        label='Endpoint'
        placeholder='Enter the JDBC connection URL'
        value={properties.endpoint || ''}
        onChange={(event) => updateProperties('endpoint', event.target.value)}
      />
      <TextField
        variant='standard'
        margin='dense'
        fullWidth
        label='Username'
        placeholder='Enter the username'
        value={properties.user || ''}
        onChange={(event) => updateProperties('user', event.target.value)}
      />
      <TextField
        variant='standard'
        margin='dense'
        fullWidth
        type='password'
        label='Password'
        placeholder='Entre the password'
        value={properties.pwd || ''}
        onChange={(event) => updateProperties('pwd', event.target.value)}
      />
      <TextField
        variant='standard'
        margin='dense'
        fullWidth
        label='Driver Class Name'
        placeholder='Enter the driver class name'
        value={properties.driver || ''}
        onChange={(event) => updateProperties('driver', event.target.value)}
      />
    </>
  );
};

export default JdbcConnectionProperties;
