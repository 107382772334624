import React from 'react';
import { ChipItem, ChipWrapper } from 'components/chiplist/ChipList';
import { Link } from 'react-router-dom';
import { getIconShort, getTechnologyConfig } from 'constants/SystemTechnologyTypes';

function SystemAssetsSection({ results }) {
  return (
    <>
      <h4>Systems involved</h4>
      <ChipWrapper>
        {results.involvedSystems.map((sys) => (
          <ChipItem
            component={Link}
            icon={getIconShort(getTechnologyConfig(sys?.systemTechnology))}
            to={`/systems/${sys.systemUuid}`}
            key={sys.systemUuid}
            id={sys.systemUuid}
            label={`${sys.systemName}`}
          />
        ))}
      </ChipWrapper>
    </>
  );
}

export default SystemAssetsSection;
