import React, { useEffect } from 'react';
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Button,
  CardActions,
  Grid
} from '@mui/material';
import QualityProbesProjectModal from '../commons/QualityProbesProjectModal';
import Breadcrumbs from 'pages/sidebar/Navigation';

import {
  modifyProbeProject,
  deleteProject,
  fetchProbeProject
} from 'actions/quality/probes/ProbesProjectsActions';
import { connect } from 'react-redux';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import QualityProbesProjectsTabs from './QualityProbesProjectsTabs';
import QualityProbesExportButton from '../commons/QualityProbesExportButton';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import { Warning } from '@mui/icons-material';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import Tracking from 'components/engagement/Tracking';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';
function QualityProbesProjectDetailPage({
  project,
  modifyProbeProject,
  deleteProject,
  fetchProbeProject,
  projectUuid
}) {
  let { t } = useTranslation();
  let history = useHistory();
  useEffect(() => {
    fetchProbeProject(projectUuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProbeProject]);
  if (!project) {
    return null;
  }
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.qualityProbes'), to: '/data-quality/probes/projects' },
          { text: project?.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('quality.probes.projects.detailPageHeader', {
            projectName: project?.name
          }),
          category: EngagementCategories.DATA_QUALITY_PROBES,
          action: EngagementActions.DETAIL
        }}
      />

      <Grid container direction='row'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            {t('quality.probes.projects.detailPageHeader', {
              projectName: project?.name
            })}
          </Typography>
        </Grid>
        <FavoritesWidget
          itemIdentifier={`PROJECT:${projectUuid}`}
          pageTitle={t('quality.probes.projects.detailPageHeader', {
            projectName: project?.name
          })}
        />
        <Grid item>
          <QualityProbesExportButton projectUuid={projectUuid} />
        </Grid>
      </Grid>

      <Card>
        <CardHeader title={project.name}></CardHeader>
        <CardContent>
          <Typography variant='body1' paragraph>
            {project.description}
          </Typography>
        </CardContent>
        <Authorize hasPermissions={[Permissions.PROBES_EDITOR]}>
          <CardActions>
            <ModifyButton project={project} modifyProbeProject={modifyProbeProject}></ModifyButton>
            <ConfirmButton
              message={
                <>
                  <Grid container direction='column' spacing={3}>
                    <Grid item>
                      <Typography variant='body1'>Do you want do delete {project.name}?</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems='center' direction='row' spacing={1}>
                        <Grid item>
                          <Warning color='secondary'></Warning>
                        </Grid>
                        <Grid item>
                          <Typography variant='caption'>
                            This action will not delete associated schedules
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
              onClick={() => deleteProject(project).then(() => history.goBack())}>
              {t('commons.actions.delete')}
            </ConfirmButton>
            <div style={{ marginLeft: 'auto' }}>
              <WebActionsButton
                resource={project}
                page={TargetPages.QUALITY_PROBE_PROJECT_DETAIL_PAGE}
              />
            </div>
          </CardActions>
        </Authorize>
      </Card>

      <QualityProbesProjectsTabs project={project}></QualityProbesProjectsTabs>
    </>
  );
}

function ModifyButton({ project, modifyProbeProject }) {
  let [open, setOpen] = React.useState(false);
  let { t } = useTranslation();
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      {open && (
        <QualityProbesProjectModal
          title='Modify Project'
          project={project}
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={(project) => {
            modifyProbeProject(project);
            setOpen(false);
          }}
        />
      )}
    </>
  );
}
function mapStateToProps(state, props) {
  return {
    project: state.quality.qualityProbes.projects.content[props.match.params['projectUuid']],
    projectUuid: props.match.params['projectUuid']
  };
}

export default connect(mapStateToProps, {
  modifyProbeProject,
  deleteProject,
  fetchProbeProject
})(QualityProbesProjectDetailPage);
