import React from 'react';
import _ from 'lodash';
import { Card, CardContent, CardActions, Button, CardHeader } from '@mui/material';
import { ChipItem } from '../../../../components/chiplist/ChipList';
import { withLoader } from '../../../../components/loader/Loader';
import { connect } from 'react-redux';
import { fetchUserDefaultTenant, changeUserDefaultTenant } from '../../../../actions/UsersActions';
import { withOpenCloseHandler } from '../../../../components/openclosehandler/OpenCloseHandler';
import TenantSelectModal from '../../tenants/commons/TenantSelectModal';

const UserDefaultTenantCard = ({
  tenant,
  userUuid,
  changeUserDefaultTenant,
  onToggleOpen,
  isOpen
}) => {
  if (!tenant) return null;
  return (
    <Card>
      <CardHeader title='Default Tenant'></CardHeader>
      <CardContent>
        <ChipItem label={tenant.name} />
        <TenantSelectModal
          open={isOpen}
          handleCancel={onToggleOpen}
          handleSubmit={(t) => {
            onToggleOpen();
            changeUserDefaultTenant(userUuid, t);
          }}
        />
      </CardContent>
      <CardActions>
        <Button onClick={onToggleOpen}>Change Default Tenant</Button>
      </CardActions>
    </Card>
  );
};

const mapStateToProps = (state, { userUuid }) => ({
  tenant: state.settings.users.defaultTenants[userUuid]
});

const mapDispatchToProps = {
  fetchUserDefaultTenant,
  changeUserDefaultTenant
};

export default _.flow([
  withLoader({
    onLoad: ({ fetchUserDefaultTenant, userUuid }) => fetchUserDefaultTenant(userUuid)
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withOpenCloseHandler
])(UserDefaultTenantCard);
