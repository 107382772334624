import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import TemplatesNavbar from './TeplatesNavbar';
import TemplatesProcessingsRoutes from './processings/TemplatesProcessingsRoutes';
import TemplatesTasksRoutes from './tasks/TemplatesTasksRoutes';
import TemplatesMappingsRoutes from './mappings/TemplatesMappingsRoutes';
import TemplatesEventsRoutes from './events/TemplatesEventsRoutes';

export default function TemplatesRoutes(props) {
  return (
    <>
      <TemplatesNavbar />
      <Switch>
        <Route path={`${props.match.url}/processings`} component={TemplatesProcessingsRoutes} />
        <Route path={`${props.match.url}/tasks`} component={TemplatesTasksRoutes} />
        <Route path={`${props.match.url}/mappings`} component={TemplatesMappingsRoutes} />
        <Route path={`${props.match.url}/events`} component={TemplatesEventsRoutes} />
        <Redirect to={`${props.match.url}/mappings`}></Redirect>
      </Switch>
    </>
  );
}
