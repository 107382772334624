import React, { useState } from 'react';
import { connect } from 'react-redux';

import { exportRiskEvaluations } from 'actions/quality/assessment/QualityAssessmentRiskEvaluationActions';
import { exportCheckEvaluations } from 'actions/quality/assessment/QualityAssessmentCheckEvaluationActions';

import { IconButton, Menu, MenuItem } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

function QualityAssessmentEvalutionsExportButton({
  exportRiskEvaluations,
  exportCheckEvaluations
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  return <>
    <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="large">
      <GetAppIcon />
    </IconButton>
    <Menu
      id='export-menu'
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}>
      <MenuItem
        onClick={() => {
          exportRiskEvaluations();
          setAnchorEl(null);
        }}>
        Export Risk Evaluation
      </MenuItem>
      <MenuItem
        onClick={() => {
          exportCheckEvaluations();
          setAnchorEl(null);
        }}>
        Export Check Evaluation
      </MenuItem>
    </Menu>
  </>;
}

const mapDispatchToProps = {
  exportRiskEvaluations,
  exportCheckEvaluations
};

export default connect(null, mapDispatchToProps)(QualityAssessmentEvalutionsExportButton);
