import React, { useState } from 'react';
import _ from 'lodash';

import { Tabs, Tab } from '@mui/material';

import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import SqlVisualizer from 'components/syntaxhighlithter/SqlVisualizer';
import JsonVisualizer from 'components/syntaxhighlithter/JsonVisualizer';
import { useTranslation } from 'react-i18next';

const QualityProbesDefinitionQuerySection = ({ probe }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = useTranslation();
  const handleChange = (_event, newValue) => setSelectedTab(newValue);
  return (
    <>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label={t(`quality.probes.definitions.labels.firstQuery.${probe.type}`)} />
        {probe.queries.length > 1 && (
          <Tab label={t(`quality.probes.definitions.labels.secondQuery.${probe.type}`)} />
        )}
      </Tabs>

      {selectedTab === 0 && <QueryTab query={probe.queries[0]} />}
      {selectedTab === 1 && <QueryTab query={probe.queries[1]} />}
    </>
  );
};

const QueryTab = ({ query }) => {
  const queryConfigs = { ...query.queryBody };

  let body;
  if (query.connectionType.toUpperCase() === 'JDBC') {
    body = queryConfigs.text;
    delete queryConfigs.text;
  }
  if (query.connectionType.toUpperCase() === 'MONGO') {
    body = queryConfigs.pipeline;
    delete queryConfigs.pipeline;
  }

  const mappedConfigs = Object.entries(queryConfigs).map(([key, value]) => [
    _.startCase(key),
    _.isArray(value) ? value.join(', ') : value
  ]);

  const bodyStyle = {
    border: '1px solid',
    borderColor: 'lightgrey',
    borderRadius: '0.5em',
    padding: '1em'
  };

  return (
    <>
      <KVDefinitionList
        header='Connection'
        item={{
          Name: query.connectionName,
          Type: query.connectionType.toUpperCase()
        }}
      />
      {mappedConfigs.length > 0 && (
        <KVDefinitionList
          header='Configuration'
          item={{
            ...Object.fromEntries(mappedConfigs)
          }}
        />
      )}
      {body && (
        <div
          style={{
            marginBlockEnd: '1em',
            marginBlockStart: '1em',
            marginRight: '8px',
            overflow: 'auto'
          }}>
          <h4>Query</h4>
          {query.connectionType.toUpperCase() === 'JDBC' && (
            <SqlVisualizer text={body} style={bodyStyle} />
          )}
          {query.connectionType.toUpperCase() === 'MONGO' && (
            <JsonVisualizer object={body} style={bodyStyle} />
          )}
        </div>
      )}
    </>
  );
};

export default QualityProbesDefinitionQuerySection;
