import _ from 'lodash';
import { useSelector } from 'react-redux';

export const useAvailableWebActions = ({ resource, page }) => {
  const availableWebActions =
    useSelector((state) => state.settings.webActions.availableWebActions) || [];
  return availableWebActions
    .filter((webAction) => webAction?.targetPages?.includes(page))
    .filter(
      (webAction) => !webAction.renderingRule || _.isMatch(resource, webAction.renderingRule)
    );
};
