import { IconButton, Tooltip } from '@mui/material';

import React from 'react';
import { connect } from 'react-redux';
import GetAppIcon from '@mui/icons-material/GetApp';
import { exportAssignments } from 'actions/AssignmentsActions';

function AssignmentsExportButton({ onClick, filters }) {
  return (
    <Tooltip title='Export'>
      <IconButton
        color='default'
        style={{ float: 'right' }}
        aria-label='Download'
        size='small'
        onClick={() => onClick(filters)}>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}

const mapStateToProps = (state, props) => ({
  filters: state.classification.assignments.filters
});
function mapDispatchToProps(dispatch) {
  return {
    onClick: (filters) => dispatch(exportAssignments(filters))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsExportButton);
