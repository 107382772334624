import { combineReducers } from 'redux';
import {
  FETCH_ENGAGEMENT_SESSION_METRICS,
  FETCH_ENGAGEMENT_PAGE_VIEWS_METRICS,
  FETCH_ENGAGEMENT_SESSION_TIMELINE,
  FETCH_ENGAGEMENT_PAGE_VIEWS_TIMELINE,
  SET_ENGAGEMENT_TIMELINE_FILTERS,
  CLEAR_ENGAGEMENT_TIMELINE_FILTERS
} from 'constants/ActionTypes';
import filtersReducer from 'reducers/utils/filters';

const moment = require('moment');

const from = new Date();
from.setDate(from.getDate() - 14);
const fromHitTimestamp = moment().subtract(14, 'days').startOf('day').toISOString();
const toHitTimestamp = moment().endOf('day').toISOString();

const timelineInitialState = {
  data: null,
  filters: {
    fromHitTimestamp,
    toHitTimestamp,
    precision: 'day',
    hitType: 'PAGEVIEW'
  }
};

const overallFiltersDefault = {
  fromHitTimestamp,
  toHitTimestamp
};

const engagementSessionMetrics = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_ENGAGEMENT_SESSION_METRICS:
      return action.payload;
    default:
      return state;
  }
};

const engagementPageViewsMetrics = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_ENGAGEMENT_PAGE_VIEWS_METRICS:
      return action.payload;
    default:
      return state;
  }
};

const engagementSessionTimeline = (state = timelineInitialState, action) => {
  switch (action.type) {
    case SET_ENGAGEMENT_TIMELINE_FILTERS:
      const timelineFilters = action.payload;
      const filters = {
        fromHitTimestamp:
          timelineFilters.fromHitTimestamp || timelineInitialState.filters.fromHitTimestamp,
        toHitTimestamp:
          timelineFilters.toHitTimestamp || timelineInitialState.filters.toHitTimestamp,
        precision: state.filters.precision
      };
      return { ...state, filters };
    case CLEAR_ENGAGEMENT_TIMELINE_FILTERS:
      return { ...state, filters: { ...timelineInitialState.filters } };
    case FETCH_ENGAGEMENT_SESSION_TIMELINE:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

const engagementPageViewTimeline = (state = timelineInitialState, action) => {
  switch (action.type) {
    case SET_ENGAGEMENT_TIMELINE_FILTERS:
      const timelineFilters = action.payload;
      const filters = {
        fromHitTimestamp:
          timelineFilters.fromHitTimestamp || timelineInitialState.filters.fromHitTimestamp,
        toHitTimestamp:
          timelineFilters.toHitTimestamp || timelineInitialState.filters.toHitTimestamp,
        precision: state.filters.precision
      };
      return { ...state, filters };
    case CLEAR_ENGAGEMENT_TIMELINE_FILTERS:
      return { ...state, filters: { ...timelineInitialState.filters } };
    case FETCH_ENGAGEMENT_PAGE_VIEWS_TIMELINE:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default combineReducers({
  engagementSessionMetrics,
  engagementPageViewsMetrics,
  engagementTimeline: combineReducers({
    filters: filtersReducer({ resource: 'ENGAGEMENT', filtersDefault: overallFiltersDefault }),
    engagementSessionTimeline,
    engagementPageViewTimeline
  })
});
