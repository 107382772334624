import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ConfigurationsPage from './configurations/list/ConfigurationsPage';
import ConfigurationDetailContainer from './configurations/detail/ConfigurationDetailPage';
import TemplatesContainer from './templates/list/TemplatesListPage';
import TemplateDetailContainer from './templates/detail/TemplateDetailPage';
import TemplateEditor from './templates/editor/TemplateEditorPage';
import ContractConfigurationEditor from './configurations/editor/ContractConfigurationEditor';
import { Switch } from 'react-router';
import DefinitionDetailPage from './definitions/detail/DefinitionDetailPage';
import DefinitionsListPage from './definitions/list/DefinitionsListPage';

export default class ContractsRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}/configurations`}
          component={ConfigurationsPage}
        />
        <Route
          exact
          path={`${this.props.match.url}/configurations/editor/create`}
          component={ContractConfigurationEditor}
        />
        <Route
          exact
          path={`${this.props.match.url}/configurations/editor/edit/:configurationUuid`}
          component={ContractConfigurationEditor}
        />
        <Route
          exact
          path={`${this.props.match.url}/configurations/editor/copy/:configurationUuid`}
          render={(props) => <ContractConfigurationEditor {...props} copy />}
        />
        <Route
          exact
          path={`${this.props.match.url}/configurations/:configurationUuid`}
          component={ConfigurationDetailContainer}
        />

        <Route exact path={`${this.props.match.url}/templates`} component={TemplatesContainer} />
        <Route
          exact
          path={`${this.props.match.url}/templates/editor/create`}
          component={TemplateEditor}
        />
        <Route
          exact
          path={`${this.props.match.url}/templates/editor/edit/:templateUuid`}
          component={TemplateEditor}
        />
        <Route
          exact
          path={`${this.props.match.url}/templates/:templateUuid`}
          component={TemplateDetailContainer}
        />

        <Route exact path={`${this.props.match.url}/definitions`} component={DefinitionsListPage} />

        <Route
          exact
          path={`${this.props.match.url}/definitions/:contractDefinitionUuid`}
          component={DefinitionDetailPage}
        />

        <Redirect to={`${this.props.match.url}/definitions`} />
      </Switch>
    );
  }
}
