import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Button,
  CardActions,
  Grid
} from '@mui/material';
import KVDefinitionList from '../../../../components/definition-list/KVDefinitionList';
import { modifyIssue, fetchIssue, deleteIssue } from '../../../../actions/IssuesActions';
import { connect, useSelector } from 'react-redux';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import IssueModal from './IssueModal';
import CommentsPanel from '../../../social/comments/CommentsPanel';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Authorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import CustomPropertiesView from 'components/additional_properties/CustomPropertiesView';
import ResourceTypes from 'constants/ResourceTypes';
import IssueIcons from './IssueIcons';
import SeverityIcons from './SeverityIcons';
import { ChipItem } from 'components/chiplist/ChipList';
import moment from 'moment';
import { createUrlItem } from '../../../Routes';
import IssueStatesManager from './IssueStatesManager';
import UserChip, { TeamChip } from 'components/chiplist/UserChip';
import CardDescription from 'components/carddescription/CardDescription';
import { hasPermissions } from 'components/permissions/permissiosSelectors';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

function IssueDetailPage({
  issue,
  issueUuid,
  fetchIssue,
  modifyIssue,
  deleteIssue,
  history,
  profile
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const isCampaignAdmin = useSelector(hasPermissions([Permissions.CAMPAIGNS_ADMIN]));
  const isCampaignEditor = useSelector(hasPermissions([Permissions.CAMPAIGNS_EDITOR]));
  useEffect(() => {
    fetchIssue(issueUuid);
  }, [issueUuid, fetchIssue]);

  if (!issue) {
    return null;
  }
  return (
    <div>
      <Breadcrumbs
        elements={[
          { text: t('navbar.issueMngt'), to: '/issue-management/my-work' },
          { text: t('navbar.issues'), to: '/issue-management/issues' },
          { text: issue.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: `Issue: ${issue.name}`,
          category: EngagementCategories.ISSUES,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            Issue: {issue.name}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`${ResourceTypes.ISSUE}:${issue.uuid}`}
            resourceIdentifier={issue.uuid}
            pageTitle={`Issue: ${issue.name}`}
            resourceType={ResourceTypes.ISSUE}
            resourceName={issue.name}
          />
        </Grid>
      </Grid>

      <Card>
        <CardHeader
          subheader={
            <Grid container direction='column'>
              <Grid item>
                <Grid
                  container
                  justifyContent='flex-start'
                  alignItems='center'
                  spacing={1}
                  direction='row'>
                  <Grid item style={{ height: '32px' }}>
                    {IssueIcons[issue.issueType]}
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle2'>{issue.issueType}</Typography>
                  </Grid>
                  <Grid item>{SeverityIcons[issue.severity]}</Grid>
                  <Grid item>
                    <Typography variant='subtitle2'>{issue.severity}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {issue.issuePolicy && (
                <Grid item>
                  <Typography variant='overline'>
                    {`Issue generated by policy ${issue.issuePolicy.name}`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          }
          title={issue.name}
          action={<IssueStatesManager issue={issue}></IssueStatesManager>}></CardHeader>
        <CardContent>
          <CardDescription>{issue.description}</CardDescription>
          <div className='dls-wrapper'>
            <KVDefinitionList
              header='General'
              item={{
                Assignee: issue.assignee?.displayName ? (
                  <UserChip label={issue.assignee?.displayName} />
                ) : (
                  '-'
                ),
                Reporter: issue.reporter?.displayName ? (
                  <UserChip label={issue.reporter?.displayName} />
                ) : (
                  '-'
                ),
                Team: issue.assigneeTeamCode ? <TeamChip label={issue.assigneeTeamCode} /> : '-',
                Campaign: issue.campaign ? (
                  <ChipItem
                    size='small'
                    key={issue.campaign.uuid}
                    id={issue.campaign.uuid}
                    label={issue.campaign.name}
                    to={`/issue-management/campaigns/${issue.campaign.uuid}`}
                  />
                ) : (
                  '-'
                ),
                Estimate: issue.estimate,
                'Priority Order': issue.priorityOrder
              }}
            />
            <KVDefinitionList
              header='Planning'
              item={{
                'Planned Start': issue.plannedStartDate
                  ? moment(issue.plannedStartDate).format('DD MMM YYYY')
                  : '-',
                ' Due Date': issue.plannedEndDate
                  ? moment(issue.plannedEndDate).format('DD MMM YYYY')
                  : '-',
                'Started On': issue.actualStartDate
                  ? moment(issue.actualStartDate).format('DD MMM YYYY')
                  : '-',
                'Completed On': issue.actualEndDate
                  ? moment(issue.actualEndDate).format('DD MMM YYYY')
                  : '-',
                'Created At': issue.createdAt ? moment(issue.createdAt).format('DD MMM YYYY') : '-',
                'Updated At': issue.updatedAt ? moment(issue.updatedAt).format('DD MMM YYYY') : '-'
              }}
            />

            <CustomPropertiesView
              additionalProperties={issue.additionalProperties}
              resourceType={ResourceTypes.ISSUE}
            />
          </div>
          {issue.resourceIdentifier && issue.resourceName && (
            <Grid container spacing={2} alignItems='center'>
              <Grid item>
                <h4>Resource</h4>
              </Grid>
              <Grid item>
                <ChipItem
                  size='small'
                  label={issue.resourceName}
                  to={createUrlItem(issue.resourceType, issue.resourceIdentifier)}></ChipItem>
              </Grid>
            </Grid>
          )}
        </CardContent>
        {!disableActions(profile, issue, isCampaignAdmin, isCampaignEditor) && (
          <CardActions>
            <Authorize hasPermissions={[Permissions.CAMPAIGNS_EDITOR]}>
              <Button children={t('commons.actions.modify')} onClick={() => setOpen(true)}></Button>
            </Authorize>
            <Authorize hasPermissions={[Permissions.CAMPAIGNS_EDITOR]}>
              <ConfirmButton
                message={`Do you want to delete: ${issue.name}?`}
                children={t('commons.actions.delete')}
                onClick={() => {
                  deleteIssue(issueUuid);
                  history.goBack();
                }}></ConfirmButton>
            </Authorize>
            <div style={{ marginLeft: 'auto' }}>
              <WebActionsButton resource={issue} page={TargetPages.ISSUE_DETAIL_PAGE} />
            </div>
          </CardActions>
        )}
      </Card>
      <CommentsPanel
        resourceType='ISSUE'
        resourceIdentifier={issueUuid}
        resourceName={issue.name}
      />
      {open && (
        <IssueModal
          open={open}
          title={`Modify Issue: ${issue.name}`}
          issueToEdit={issue}
          handleSubmit={modifyIssue}
          handleCancel={() => setOpen(false)}
        />
      )}
    </div>
  );
}

function disableActions(profile, issue, isCampaignAdmin, isCampaignEditor) {
  if (isCampaignAdmin) {
    return false;
  }
  if (issue?.campaign?.private && issue?.campaign?.owner.uuid === profile?.uuid) {
    return false;
  }
  if (!issue?.campaign?.private && isCampaignEditor) {
    return false;
  }
  return true;
}
function mapStateToProps(state, props) {
  return {
    issueUuid: props.match.params['issueUuid'],
    issue: state.campaigns.issues.content[props.match.params['issueUuid']],
    profile: state.settings.profile
  };
}

export default connect(mapStateToProps, {
  fetchIssue,
  modifyIssue,
  deleteIssue
})(IssueDetailPage);
