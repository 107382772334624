import React from 'react';
import { useTranslation } from 'react-i18next';

import IssuesSeverities from 'pages/issueMngt/issues/detail/IssuesSeverities';
import IssueStatuses from 'pages/issueMngt/issues/detail/IssueStatuses';

import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import UsersInCurrentTenantItemAutocomplete from 'pages/settings/users/commons/UsersInCurrentTenantItemAutocomplete';
import AccountTeamSelector from 'pages/settings/profile/utils/AccountTeamSelector';

const IssueTemplateConfigurationDetailsSection = ({
  issueSeverity,
  issuePriorityOrder,
  issueStatus,
  assignee,
  assigneeTeamCode,
  issueReporter,
  handleChange
}) => {
  const { t } = useTranslation();

  const severities = [
    IssuesSeverities.BLOCKER,
    IssuesSeverities.CRITICAL,
    IssuesSeverities.MAJOR,
    IssuesSeverities.MINOR,
    IssuesSeverities.INFO
  ];
  const statuses = [
    IssueStatuses.TO_DO,
    IssueStatuses.REVIEW,
    IssueStatuses.DONE,
    IssueStatuses.IN_PROGRESS
  ];

  return <>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth margin='dense' required>
          <InputLabel id='issue-severity-select-label'>Severity</InputLabel>
          <Select
            variant="standard"
            labelId='issue-severity-select-label'
            id='issue-severity-select'
            value={issueSeverity || ''}
            onChange={(event) => handleChange({ severity: event.target.value })}>
            {severities.map((severity) => (
              <MenuItem key={severity} value={severity}>
                {t(`issueMngt.issues.severities.${severity}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          fullWidth
          margin='dense'
          required
          label='Priority Order'
          placeholder="Enter the issue's priority order"
          type='number'
          value={issuePriorityOrder === 0 || issuePriorityOrder ? issuePriorityOrder : ''}
          inputProps={{ min: 0, step: 1 }}
          onChange={(event) => handleChange({ priorityOrder: event.target.value })} />
      </Grid>
    </Grid>
    <FormControl variant="standard" fullWidth margin='dense'>
      <InputLabel id='issue-status-select-label' required>
        Status
      </InputLabel>
      <Select
        variant="standard"
        labelId='issue-status-select-label'
        id='issue-status-select'
        required
        value={issueStatus || ''}
        onChange={(event) => handleChange({ issueStatus: event.target.value })}
        clearable='true'>
        {statuses.map((status) => (
          <MenuItem key={status} value={status}>
            {t(`issueMngt.issues.statuses.${status}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <UsersInCurrentTenantItemAutocomplete
      itemName={'Assignee - User'}
      value={assignee}
      onChange={(user) => handleChange({ assignee: user })}
    />
    <AccountTeamSelector
      label='Assignee - Team'
      fullWidth
      margin='dense'
      selectedTeamCode={assigneeTeamCode || ''}
      onChange={(code) => handleChange({ assigneeTeamCode: code })}
    />
    <UsersInCurrentTenantItemAutocomplete
      itemName={'Reporter'}
      value={issueReporter}
      onChange={(reporter) => handleChange({ reporter })}
    />
  </>;
};

export default IssueTemplateConfigurationDetailsSection;
