import { BlindataApi } from 'api/ApiUtils';

export default class FavoritesApi extends BlindataApi {
  getFavorites(params) {
    return this.get('/api/v1/social/favorites/items', params);
  }

  getFavorite(uuid) {
    return this.get(`/api/v1/social/favorites/items/${uuid}`);
  }

  postFavorite(favorite) {
    return this.post('/api/v1/social/favorites/items', favorite);
  }

  putFavorite(favorite) {
    return this.put(`/api/v1/social/favorites/items/${favorite?.uuid}`, favorite);
  }

  deleteFavorite(favorite) {
    return this.delete(`/api/v1/social/favorites/items/${favorite?.uuid}`);
  }
}
