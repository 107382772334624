import { BlindataApi } from './ApiUtils';

const ASSIGNMENTS_CONFIG_URL = '/api/v1/classification/assignments/configurations';

export default class AssignmentsConfigApi extends BlindataApi {

    getAllAssignmentsConfig() {
        return this.get(ASSIGNMENTS_CONFIG_URL);
    }

    putAssignmentsConfig(assignmentConfig) {
        return this.put(`${ASSIGNMENTS_CONFIG_URL}`, assignmentConfig)
    }
}