import React, { useState } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

export default function MyIssueFilters({ onChange }) {
  const options = ['Open Issues', 'All Issues', 'Closed Issues'];
  const [option, setOption] = useState('Open Issues');
  return (
    <React.Fragment>
      <FormControl variant='outlined' fullWidth margin='dense' size='small'>
        <Select
          variant='outlined'
          labelId='demo-simple-select-filled-label'
          id='demo-simple-select-filled'
          defaultValue={'Open Issues'}
          value={option}
          onChange={(event) => {
            setOption(event.target.value);
            onChange({ status: computeStatus(event.target.value) });
          }}
          onClick={(event) => event.stopPropagation()}
          clearable='true'>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
}

function computeStatus(option) {
  if (option === 'Open Issues') {
    return ['TO_DO', 'IN_PROGRESS', 'REVIEW'];
  } else if (option === 'Closed Issues') {
    return ['DONE'];
  } else {
    return [];
  }
}
