import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

function CommentEditorModal({ comment, onCancel, onSubmit, open }) {
  const { t } = useTranslation();
  const [content, setContent] = React.useState(comment.content || '');
  return (
    <Dialog open={open}>
      <DialogTitle>Edit Comment</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          multiline
          value={content}
          onChange={(event) => setContent(event.target.value)}></TextField>
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.cancel')} onClick={onCancel}></Button>
        <Button
          children={t('commons.actions.submit')}
          variant='contained'
          color='primary'
          onClick={() => onSubmit({ ...comment, content })}></Button>
      </DialogActions>
    </Dialog>
  );
}

export default CommentEditorModal;
