import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import {
  fetchWebAction,
  deleteWebAction,
  modifyWebAction
} from 'actions/settings/webactions/WebActionsActions';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import { connect } from 'react-redux';
import WebActionDetailHeader from './WebActionDetailHeader';
import WebActionDetailCardView from './WebActionDetailCardView';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import WebActionsModal from '../list/WebActionsModal';
import WebActionsTabs from './WebActionsTabs';

function WebActionDetailPage({
  webAction,
  deleteWebAction,
  modifyWebAction,
  fetchWebAction,
  webActionUuid
}) {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    fetchWebAction(webActionUuid);
  }, [fetchWebAction, webActionUuid]);
  const handleDelete = (webAction) => deleteWebAction(webAction).then(() => history.goBack());

  if (!webAction) {
    return null;
  }
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.physical_model'), to: '/settings' },
          { text: webAction?.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('webActions.detail.header', {
            name: webAction?.name
          }),
          category: EngagementCategories.SETTINGS,
          action: EngagementActions.DETAIL
        }}
      />
      <WebActionDetailHeader webAction={webAction} />
      <WebActionDetailCardView
        webAction={webAction}
        actions={
          <Authorize hasPermissions={[Permissions.AGENTS_EDITOR]}>
            <ModifyButton modifyWebAction={modifyWebAction} webAction={webAction}>
              {t('commons.actions.modify')}
            </ModifyButton>
            <ConfirmButton
              message={'Do you really want to delete this web action?'}
              onClick={() => handleDelete(webAction)}>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </Authorize>
        }
      />
      <WebActionsTabs webAction={webAction} />
    </>
  );
}

function ModifyButton({ webAction, modifyWebAction }) {
  let { t } = useTranslation();
  let [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      {open && (
        <WebActionsModal
          title='Modify Web Action'
          open={open}
          handleSubmit={(webAction) => {
            modifyWebAction(webAction);
            setOpen(false);
          }}
          handleCancel={() => setOpen(false)}
          webAction={webAction}
        />
      )}
    </>
  );
}
const mapStateToProps = (state, props) => ({
  webActionUuid: props.match.params['webActionUuid'],
  webAction: state.settings.webActions.content[props.match.params['webActionUuid']]
});

export default connect(mapStateToProps, { fetchWebAction, modifyWebAction, deleteWebAction })(
  WebActionDetailPage
);
