import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchAgentsPage, fetchAgent, modifyAgent, deleteAgent } from 'actions/AgentsActions';
import { pairAgent } from 'actions/onagent/AgentAdminActions';

import { Typography, Grid } from '@mui/material';
import Breadcrumbs from 'pages/sidebar/Navigation';

import AgentsList from 'pages/settings/agents/list/AgentsList';
import AgentFAB from 'pages/settings/agents/commons/AgentFAB';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const AgentsListPage = ({
  currentPage,
  fetchAgentsPage,
  fetchAgent,
  modifyAgent,
  deleteAgent,
  pairAgent
}) => {
  useEffect(() => {
    fetchAgentsPage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumbs
        elements={[{ text: 'Settings', to: '/settings' }, { text: 'Agents' }]}></Breadcrumbs>
      <Grid container>
        <Typography variant='h4'>Agents</Typography>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget itemIdentifier={'page:agents-list'} pageTitle={`Agents List`} />
        </Grid>
      </Grid>
      {currentPage.content.length === 0 ? (
        <Typography variant='body1' align='center' style={{ textAlign: 'center', padding: 100 }}>
          No agents has been associated to this tenant yet.
        </Typography>
      ) : (
        <AgentsList
          currentPage={currentPage}
          fetchAgentsPage={fetchAgentsPage}
          modifyAgent={modifyAgent}
          deleteAgent={deleteAgent}
          pairAgent={pairAgent}
          fetchAgent={fetchAgent}
        />
      )}
      <AgentFAB />
    </>
  );
};

const mapStateToProps = (state, props) => ({
  currentPage: state.settings.agents.definitions.currentPage
});

const mapDispatchToProps = {
  fetchAgentsPage,
  fetchAgent,
  modifyAgent,
  deleteAgent,
  pairAgent
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentsListPage);
