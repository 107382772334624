import { combineReducers } from 'redux';
import {
  FETCH_CAMPAIGN_PERCENTAGE,
  FETCH_CAMPAIGN_TEMPORAL,
  FETCH_ISSUE_TYPE_COUNTERS,
  CREATE_ISSUE,
  DELETE_ISSUE,
  MODIFY_ISSUE
} from '../../constants/ActionTypes';
import _ from 'lodash';

const percentage = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_CAMPAIGN_PERCENTAGE:
      return { ...state, [action.payload.campaignUuid]: action.payload };
    default:
      return state;
  }
};

const temporal = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_CAMPAIGN_TEMPORAL:
      return { ...state, [action.payload.campaignUuid]: action.payload };
    default:
      return state;
  }
};

const issuesTypesCounters = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ISSUE_TYPE_COUNTERS:
      let newState = _(action.payload.rows)
        .groupBy((r) => `${action.payload.resourceType}:${r[0]}`)
        .mapValues((r) =>
          _(r)
            .map((e) => ({ [e[1]]: e[2] }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {})
        )
        .value();
      return { ...state, ...newState };
    case CREATE_ISSUE:
    case DELETE_ISSUE:
    case MODIFY_ISSUE:
      return action.payload.resourceIdentifier
        ? {
            ...state,
            [`${action.payload.resourceType}:${action.payload.resourceIdentifier}`]: null,
            ...(action.payload.parentIdentifier
              ? {
                  [`${action.payload.parentType}:${action.payload.parentIdentifier}`]: null
                }
              : {})
          }
        : state;
    default:
      return state;
  }
};
export default combineReducers({ percentage, temporal, issuesTypesCounters });
