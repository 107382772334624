import React from 'react';
import {
  configureSearchParams,
  searchConsents,
  clearConsentsSearch
} from '../../../actions/ConsentsActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ProcessingItemAutoComplete from '../../processings/utils/ProcessingItemAutoComplete';
import DatePicker, { hourAdjustments } from '../../../components/pickers/DatePicker';
import { FormControlLabel, Checkbox, CardActions, Grid } from '@mui/material';
import ExpandableCard from '../../../components/expandable-card/ExpandableCard';

const SearchPanel = (props) => (
  <ExpandableCard
    title={'Search Consents'}
    subheader='Click to configure search filters'
    visibleChildren={
      <CardActions>
        <Button
          color='primary'
          variant='outlined'
          children={props.t('commons.actions.search')}
          onClick={() =>
            props.searchConsents({
              processingUuid: props.processingUuid,
              dataSubjectKeyName: props.dataSubjectKeyName,
              dataSubjectKeyValue: props.dataSubjectKeyValue,
              context: props.context,
              nullContext: props.nullContext,
              startTimestamp: props.startTimestamp,
              endTimestamp: props.endTimestamp
            })
          }
        />
        <Button
          children={props.t('commons.actions.clear')}
          onClick={() => props.clearConsentsSearch()}
        />
      </CardActions>
    }>
    <TextField
      variant='standard'
      fullWidth
      margin='dense'
      label='DataSubject Key Name'
      placeholder='Enter data subject key name'
      value={props.dataSubjectKeyName || ''}
      onChange={(event) => props.configureSearchParams({ dataSubjectKeyName: event.target.value })}
    />

    <TextField
      variant='standard'
      fullWidth
      margin='dense'
      label='DataSubject Key Value'
      placeholder='Enter data subject key value'
      value={props.dataSubjectKeyValue || ''}
      onChange={(event) => props.configureSearchParams({ dataSubjectKeyValue: event.target.value })}
    />

    <TextField
      variant='standard'
      fullWidth
      margin='dense'
      label='Context'
      placeholder='Enter the consents context'
      disabled={props.nullContext}
      value={props.context || ''}
      onChange={(event) =>
        props.configureSearchParams({
          context: event.target.value !== '' ? event.target.value : null
        })
      }
    />

    <FormControlLabel
      label='Null Context'
      control={
        <Checkbox
          checked={props.nullContext}
          onChange={() =>
            props.configureSearchParams({
              nullContext: !props.nullContext,
              context: !props.nullContext ? null : props.context
            })
          }
          value='nullContext'
        />
      }
    />

    <ProcessingItemAutoComplete
      value={props.processing}
      onChange={(processing) =>
        props.configureSearchParams({
          processingUuid: processing && processing.uuid,
          processing
        })
      }
    />
    <Grid container justifyContent='flex-start'>
      <Grid item>
        <DatePicker
          clearable={false}
          label={'From Timestamp'}
          hintText={'From Timestamp'}
          hourAdjustment={hourAdjustments.startOfDay}
          value={props.startTimestamp}
          onChange={(date) => props.configureSearchParams({ startTimestamp: date })}
        />
      </Grid>
      <Grid item>
        <DatePicker
          clearable
          label={'To Timestamp'}
          hintText={'To Timestamp'}
          hourAdjustment={hourAdjustments.endOfDay}
          value={props.endTimestamp}
          onChange={(date) => props.configureSearchParams({ endTimestamp: date })}
        />
      </Grid>
    </Grid>
  </ExpandableCard>
);

const mapStateToProps = (state) => ({
  processingUuid: state.consents.search.processingUuid,
  processing: state.consents.search.processing,
  dataSubjectKeyName: state.consents.search.dataSubjectKeyName,
  dataSubjectKeyValue: state.consents.search.dataSubjectKeyValue,
  startTimestamp: state.consents.search.startTimestamp,
  endTimestamp: state.consents.search.endTimestamp,
  context: state.consents.search.context,
  nullContext: state.consents.search.nullContext
});

export default withTranslation()(
  connect(mapStateToProps, {
    configureSearchParams,
    searchConsents,
    clearConsentsSearch
  })(SearchPanel)
);
