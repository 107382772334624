import React from 'react';
import { Card, CardHeader, CardContent, TextField, FormControlLabel, Switch } from '@mui/material';
import ItemSelectField from '../../../../components/itemselector/ItemSelectField';

const GeneralDataEditor = ({ editor, editContractConfiguration, templates }) => (
  <Card style={{ margin: '8px 0px 8px 0px' }}>
    <CardHeader
      title={`${editor.contractName || 'Contract Name'} (${editor.language || 'language'}, ${
        editor.version || 'version'
      })`}
    />
    <CardContent>
      <TextField
        variant="standard"
        fullWidth
        margin='dense'
        placeholder='Enter the contract name'
        label='Contract Name'
        value={editor.contractName || ''}
        onChange={(event) => editContractConfiguration({ contractName: event.target.value })} />
      <br />
      <TextField
        variant="standard"
        fullWidth
        margin='dense'
        placeholder='Enter the language'
        label='Language'
        value={editor.language || ''}
        onChange={(event) => editContractConfiguration({ language: event.target.value })} />
      <br />
      <TextField
        variant="standard"
        fullWidth
        margin='dense'
        placeholder='Enter the version'
        label='Version'
        value={editor.version || ''}
        onChange={(event) => editContractConfiguration({ version: event.target.value })} />
      <br />
      <ItemSelectField
        itemName='Template'
        value={editor.template}
        values={templates}
        keyProperty='uuid'
        nameProperty='name'
        onChange={(template) => {
          editContractConfiguration({ template });
        }}
      />
      <FormControlLabel
        control={
          <Switch
            children='Active version'
            checked={editor.active === true}
            color='primary'
            onChange={(evnt, checked) => editContractConfiguration({ active: checked })}
          />
        }
        label='Active version'
      />
    </CardContent>
  </Card>
);

export default GeneralDataEditor;
