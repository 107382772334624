import React from 'react';

import { Grid, Typography } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import EngagementDashboardsLinkButton from 'pages/engagement/commons/EngagementDashboardsLinkButton';
import UsersList from './UsersList';
import AddUserFab from './AddUserFAB';
import UsersSearchBox from './UsersSearchBox';
import UsersAllTenantFilter from './UsersAllTenantFilter';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const SettingsUsersPage = () => {
  return (
    <>
      <Breadcrumbs elements={[{ text: 'Settings', to: '/settings' }, { text: 'Users' }]} />
      <Tracking
        info={{
          pageTitle: 'Users',
          category: EngagementCategories.SETTINGS,
          action: EngagementActions.LIST
        }}
      />

      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Users</Typography>
        </Grid>
        <Grid item>
          <UsersSearchBox></UsersSearchBox>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:users-list'}
            iconSize={'small'}
            pageTitle={'Users'}
          />
        </Grid>
        <Grid item>
          <EngagementDashboardsLinkButton />
        </Grid>
        <Grid item>
          <UsersAllTenantFilter></UsersAllTenantFilter>
        </Grid>
      </Grid>
      <UsersList />
      <AddUserFab />
    </>
  );
};

export default SettingsUsersPage;
