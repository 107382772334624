import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {
  modifyRiskDefinition,
  deleteRiskDefinition
} from 'actions/quality/assessment/QualityAssessmentRiskDefinitionsActions';

import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';

import { Card, CardHeader, CardContent, CardActions, Button } from '@mui/material';

import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';

import RiskDefinitionModal from 'pages/quality/assessment/risks/detail/RiskDefinitionModal';

const RiskDefinitionDetailCard = ({
  riskDefinition,
  modifyRiskDefinition,
  deleteRiskDefinition
}) => {
  return (
    <Card>
      <CardHeader
        title={riskDefinition.name}
        subheader={riskDefinition.code}
        action={<VariationTypeDecorator>{riskDefinition.category}</VariationTypeDecorator>}
      />
      <CardContent>{riskDefinition.description}</CardContent>
      <CardActions>
        <ModifyButton riskDefinition={riskDefinition} modifyRiskDefinition={modifyRiskDefinition} />
        <DeleteButton riskDefinition={riskDefinition} deleteRiskDefinition={deleteRiskDefinition} />
      </CardActions>
    </Card>
  );
};

const ModifyButton = ({ riskDefinition, modifyRiskDefinition }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <Authorize hasPermissions={[Permissions.QUALITY_ASSMNT_ADMIN]}>
      <>
        <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
        {open && (
          <RiskDefinitionModal
            title='Modify the Risk Definition'
            open={open}
            riskDefinition={riskDefinition}
            handleSubmit={(definition) => {
              modifyRiskDefinition(definition);
              setOpen(false);
            }}
            handleCancel={() => setOpen(false)}
          />
        )}
      </>
    </Authorize>
  );
};

const DeleteButton = ({ riskDefinition, deleteRiskDefinition }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Authorize hasPermissions={[Permissions.QUALITY_ASSMNT_ADMIN]}>
      <ConfirmButton
        onClick={() => {
          deleteRiskDefinition(riskDefinition).then(() =>
            history.push(`/data-quality/assessment/risks`)
          );
        }}>
        {t('commons.actions.delete')}
      </ConfirmButton>
    </Authorize>
  );
};

const mapDispatchToProps = {
  modifyRiskDefinition,
  deleteRiskDefinition
};

export default connect(null, mapDispatchToProps)(RiskDefinitionDetailCard);
