import * as React from "react";
const SvgGoogleBigQuery = (props) => (
  <svg
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1.633 7.033 131.266 114.634"
    {...props}
  >
    <linearGradient
      id="GoogleBigQuery_svg__a"
      gradientUnits="userSpaceOnUse"
      x1={64}
      x2={64}
      y1={7.034}
      y2={120.789}
    >
      <stop offset={0} stopColor="#4387fd" />
      <stop offset={1} stopColor="#4683ea" />
    </linearGradient>
    <path
      d="M27.79 115.217 1.54 69.749a11.499 11.499 0 0 1 0-11.499l26.25-45.467a11.5 11.5 0 0 1 9.96-5.75h52.5a11.5 11.5 0 0 1 9.959 5.75l26.25 45.467a11.499 11.499 0 0 1 0 11.5l-26.25 45.467a11.5 11.5 0 0 1-9.959 5.749h-52.5a11.499 11.499 0 0 1-9.96-5.75z"
      fill="url(#GoogleBigQuery_svg__a)"
    />
    <path
      clipPath="url(#GoogleBigQuery_svg__b)"
      d="M119.229 86.48 80.625 47.874 64 43.425l-14.933 5.55L43.3 64l4.637 16.729 40.938 40.938 8.687-.386z"
      opacity={0.07}
    />
    <g fill="#fff">
      <path d="M64 40.804c-12.81 0-23.195 10.385-23.195 23.196 0 12.81 10.385 23.195 23.195 23.195S87.194 76.81 87.194 64c0-12.811-10.385-23.196-23.194-23.196m0 40.795c-9.72 0-17.6-7.88-17.6-17.6S54.28 46.4 64 46.4 81.6 54.28 81.6 64 73.72 81.6 64 81.6" />
      <path d="M52.99 63.104v7.21a12.794 12.794 0 0 0 4.38 4.475V63.104zm8.685-6.078v19.411c.745.137 1.507.22 2.29.22.714 0 1.41-.075 2.093-.189V57.026zm9.091 9.074v8.562a12.786 12.786 0 0 0 4.382-4.7v-3.861zm9.925 12.187-2.403 2.405a1.088 1.088 0 0 0 0 1.537l9.115 9.112a1.088 1.088 0 0 0 1.537 0l2.403-2.402a1.092 1.092 0 0 0 0-1.536l-9.116-9.116a1.09 1.09 0 0 0-1.536 0" />
    </g>
  </svg>
);
export default SvgGoogleBigQuery;
