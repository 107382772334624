import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Typography
} from '@mui/material';
import { Warning } from '@mui/icons-material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import AgentJobResourceConfiguration from './AgentJobResourceConfiguration';
import AgentJobGeneralSection from './AgentJobGeneralSection';
import AgentJobConfigurationsSettings from './AgentJobConfigurationsSettings';
import AgentGenericJobRunButton from '../AgentGenericJobRunButton';
function AgentJobDefinitionModal({ jobDefinition, open, onSubmit, onCancel, agent }) {
  let { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [editor, setEditor] = useState(jobDefinition || null);
  const [conf, setConf] = useState(JSON.stringify(editor?.jobDetails?.configs, null, 2) || '');

  const steps = [
    'Configure Resource and Type',
    'Configure job General Info',
    'Configure job details'
  ];
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      let configuration = JSON.parse(conf);
      onSubmit({
        ...editor,
        jobDetails: {
          ...editor.jobDetails,
          type: editor?.type,
          configs: {
            ...configuration
          }
        }
      });
      onCancel();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Dialog
      open={open}
      fullWidth
      PaperProps={{
        sx: {
          height: '70%'
        }
      }}>
      <DialogTitle>Job Definition</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <AgentJobResourceConfiguration editor={editor} setEditor={setEditor} />
        )}
        {activeStep === 1 && <AgentJobGeneralSection editor={editor} setEditor={setEditor} />}
        {activeStep === 2 && <AgentJobConfigurationsSettings conf={conf} setConf={setConf} />}
        {conf && !isJsonString(conf) && (
          <Grid container alignItems='center' direction='row' spacing={1}>
            <Grid item>
              <Warning style={{ color: 'red' }}></Warning>
            </Grid>
            <Grid item>
              <Typography variant='caption'>JSON not valid!</Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          style={{ float: 'left' }}
          onClick={onCancel}
          children={t('commons.actions.cancel')}
        />
        <div style={{ flexGrow: 1 }} />
        {activeStep === 2 && (
          <AgentGenericJobRunButton
            onlyTest
            jobDefinition={getCompleteConfig(editor, conf)}
            agent={agent}
            isJsonValid={isJsonString(conf)}
          />
        )}
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!isStepValid(activeStep, editor, conf)}
          onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const isStepValid = (activeStep, editor, conf) => {
  switch (activeStep) {
    case 0:
      return !_.isEmpty(editor?.type) && !_.isEmpty(editor?.externalIdentifier);
    case 1:
      return !_.isEmpty(editor?.name);
    case 2:
      return !_.isEmpty(conf) && isJsonString(conf);
    default:
      return false;
  }
};

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function getCompleteConfig(editor, conf) {
  if (isJsonString(conf)) {
    let configuration = JSON.parse(conf);
    return {
      ...editor,
      jobDetails: {
        ...editor.jobDetails,
        type: editor?.type,
        configs: {
          ...configuration
        }
      }
    };
  } else {
    return null;
  }
}
export default AgentJobDefinitionModal;
