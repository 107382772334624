import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { runJobByUuid, runJob } from 'actions/onagent/AgentJobRunnerActions';
import TableCell from 'components/tables/GenericTableCell';
import ErrorIcon from '@mui/icons-material/HighlightOff';
import OkIcon from '@mui/icons-material/CheckCircleOutline';
import { Warning } from '@mui/icons-material';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';

function QueryBucketScheduleRunButton({ agent, jobDefinition, onlyTest, runJobByUuid, runJob }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {onlyTest ? t('commons.actions.test') : t('commons.actions.run')}
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>
          <Typography variant='h6'>Running Query Extractor</Typography>
          {jobDefinition && (
            <Typography color='textSecondary' variant='subtitle2'>
              You are running "{jobDefinition?.name}"
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              {!loading && !result && (
                <Typography variant='body2' style={{ textAlign: 'center', marginTop: '1.5em' }}>
                  Start the run to get some results!
                </Typography>
              )}
              {loading && (
                <CircularProgress style={{ marginLeft: 'calc(50% - 20px)', marginTop: '1.5em' }} />
              )}
              {result && <QueryExtractorResultsTable result={result} />}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setResult(null);
            }}>
            {t('commons.actions.cancel')}
          </Button>
          <Button
            onClick={() => {
              if (loading) return;
              setLoading(true);
              setResult(null);
              let jobDetails = {
                ...jobDefinition.jobDetails,
                configs: {
                  ...jobDefinition.jobDetails.configs,
                  outputOptions: {
                    ...jobDefinition.jobDetails.configs.outputOptions,
                    dryRun: true
                  }
                }
              };
              runJob(agent, jobDetails).then((response) => {
                setResult(response);
                setLoading(false);
              });
            }}>
            {t('commons.actions.test')}
          </Button>
          {!onlyTest && (
            <ConfirmButton
              variant='contained'
              color='primary'
              disabled={loading}
              onClick={() => {
                if (loading) return;
                setLoading(true);
                setResult(null);
                runJobByUuid(agent, jobDefinition.uuid).then((response) => {
                  setResult(response);
                  setLoading(false);
                });
              }}>
              {t('commons.actions.run')}
            </ConfirmButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

function QueryExtractorResultsTable({ result }) {
  let { errors, results } = result;
  let resultsRows = results?.length > 0 ? [{ ...results[0] }] : [];
  return (
    <>
      <Grid container direction='column' spacing={2}>
        {errors?.length > 0 && (
          <>
            <Grid item>
              <h4 style={{ marginBottom: 0 }}>Errors</h4>
            </Grid>
            <Grid item>
              <TableContainer style={{ maxHeight: '300px' }}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Error</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {errors.map((entry) => (
                      <TableRow key={entry.qualityCheckCode}>
                        <TableCell>
                          <ErrorIcon style={{ color: 'red' }} />
                        </TableCell>
                        <TableCell>{entry.error || '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
        {resultsRows?.length > 0 && (
          <>
            <Grid item>
              <h4 style={{ marginBottom: 0 }}>Results</h4>
            </Grid>
            <Grid item>
              <TableContainer style={{ maxHeight: '300px' }}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Extracted</TableCell>
                      <TableCell>Uploaded</TableCell>
                      <TableCell>Discarded</TableCell>
                      <TableCell>Errors</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resultsRows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {!row.rowsDiscarded ? (
                            <OkIcon style={{ color: 'green' }} />
                          ) : (
                            <Warning style={{ color: 'orange' }} />
                          )}
                        </TableCell>
                        <TableCell>{row.extractedRows || '-'}</TableCell>
                        <TableCell>{row.uploadedRows || '-'}</TableCell>
                        <TableCell>{row.discardedRows || '-'}</TableCell>
                        <TableCell>{row.errorRows || '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.SYSTEMS_EDITOR, Permissions.AGENTS_EDITOR] }),
  connect(null, { runJobByUuid, runJob })
)(QueryBucketScheduleRunButton);
