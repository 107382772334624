import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { t } from 'i18next';
import { fetchDataFlowsInvolvedStatements } from 'actions/QueryStatementsActions';
import { QueryStatementStatusIcons } from 'constants/QueryStatementStatusIcons';
import DescriptionIcon from '@mui/icons-material/Description';
import SqlVisualizer from 'components/syntaxhighlithter/SqlVisualizer';
import { formatDate } from 'pages/history/commons/HistoryRecordTable';

export const DataFlowCardQueryStatementsTable = ({ filters }) => {
  const dispatch = useDispatch();
  const queryStatements = useSelector((state) => state.dataflows.querystatements.dataflowsinvolved);

  useEffect(() => {
    dispatch(fetchDataFlowsInvolvedStatements({ ...filters }));
  }, [dispatch, filters]);

  return (
    <div>
      {queryStatements?.content?.length > 0 ? (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>
                {t('dataflows.card.statements.tableHeaders.bucket')}
              </TableCell>
              <TableCell align='left'>{t('dataflows.card.statements.tableHeaders.name')}</TableCell>
              <TableCell align='left'>
                {t('dataflows.card.statements.tableHeaders.analysisStatus')}
              </TableCell>
              <TableCell align='left'>{t('dataflows.card.statements.tableHeaders.type')}</TableCell>
              <TableCell align='right'>
                {t('dataflows.card.statements.tableHeaders.createdAt')}
              </TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {queryStatements.content.map((queryStatement) => (
              <DataFlowsQueryStatementsTableRow
                queryStatement={queryStatement}
                key={queryStatement?.uuid}
              />
            ))}
          </TableBody>

          <TablePagination
            size='small'
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={queryStatements.totalElements}
            rowsPerPage={queryStatements.size}
            page={queryStatements.number}
            onPageChange={(_event, page) =>
              dispatch(
                fetchDataFlowsInvolvedStatements({ page, size: queryStatements.size, ...filters })
              )
            }
            onRowsPerPageChange={(event) =>
              dispatch(
                fetchDataFlowsInvolvedStatements({
                  page: queryStatements.number,
                  size: event.target.value,
                  ...filters
                })
              )
            }
          />
        </Table>
      ) : (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='body1'>
            {t('dataflows.card.statements.noStatementsFound')}
          </Typography>
        </div>
      )}
    </div>
  );
};
const DataFlowsQueryStatementsTableRow = ({ queryStatement }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        hover
        style={{ cursor: 'pointer' }}
        onClick={() =>
          history.push(
            `/dataflows/queryparser/buckets/${queryStatement.queryBucket.uuid}/statements/${queryStatement.uuid}`
          )
        }>
        <TableCell align='left'>{queryStatement?.queryBucket?.name}</TableCell>
        <TableCell align='left'>{queryStatement?.name}</TableCell>
        <TableCell align='left'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {QueryStatementStatusIcons[queryStatement?.analysisStatus]}
            <div style={{ paddingLeft: '8px' }}>{queryStatement?.analysisStatus}</div>
          </div>
        </TableCell>
        <TableCell align='left'>{queryStatement.queryType}</TableCell>
        <TableCell align='right'>{formatDate(queryStatement?.createdAt)}</TableCell>
        <TableCell align='right'>
          Query Text
          <Tooltip title='See query statement text'>
            <IconButton
              size='small'
              onClick={(event) => {
                event.stopPropagation();
                setOpen(!open);
              }}>
              <DescriptionIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <Dialog open={open} fullWidth maxWidth='xl'>
        <DialogTitle>Query Statement Text</DialogTitle>
        <DialogContent>
          <SqlVisualizer
            style={{
              margin: 0,
              border: '1px solid',
              borderColor: 'lightgrey',
              borderRadius: '0.5em',
              padding: '1em'
            }}
            text={queryStatement.queryText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t('commons.actions.close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
