export const EventResourceTypes = {
  /**
   * Logical Model
   */
  DATA_CATEGORY: 'DATA_CATEGORY',
  LOGICAL_FIELD: 'LOGICAL_FIELD',
  /**
   * Processing Registry
   * */
  TASK_ASSIGNMENT: 'TASK_ASSIGNMENT',
  TASK: 'TASK',
  /**
   * Physical Model
   */
  SYSTEM: 'SYSTEM',
  PHYSICAL_ENTITY: 'PHYSICAL_ENTITY',
  PHYSICAL_FIELD: 'PHYSICAL_FIELD',
  SYSTEM_ROUTINE: 'SYSTEM_ROUTINE',

  /**
   * Data Quality
   */

  /**
   * Issue Management
   */
  ISSUE: 'ISSUE',

  /**
   * Query Parser
   */
  QUERY_STATEMENT: 'QUERY_STATEMENT',
  QUERY_BUCKET: 'QUERY_BUCKET',

  /**
   * Consents
   */

  /**
   * Data Mesh
   */
  DATA_PRODUCT: 'DATA_PRODUCT',
  /**
   * Social
   */
  COMMENT: 'COMMENT'
};
