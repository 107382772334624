import React from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { Grid, Tooltip, IconButton } from '@mui/material';
import AccountTree from '@mui/icons-material/AccountTree';
import PhysicalConstraintsExpandableCard from '../list/PhysicalConstraintsExpandableCard';
import { AddPhysicalConstraintButton } from '../commons/AddPhysicalConstraintButton';

const PhysicalConstraintsPanel = ({ physicalEntity, physicalField }) => {
  return (
    <Grid container direction='column'>
      <Grid item>
        <Actions physicalEntity={physicalEntity} physicalField={physicalField} />
      </Grid>
      <Grid item>
        {_.map(
          {
            FOREIGN_KEY: 'FOREIGN KEY',
            PRIMARY_KEY: 'PRIMARY KEY',
            INDEX: 'INDEX',
            OTHERS: 'OTHERS'
          },
          (val, key) => (
            <PhysicalConstraintsExpandableCard
              key={key}
              type={key}
              title={val}
              physicalEntityUuid={physicalEntity?.uuid}
              physicalFieldUuid={physicalField?.uuid}
            />
          )
        )}
      </Grid>
    </Grid>
  );
};

const Actions = ({ physicalEntity, physicalField }) => {

  const history = useHistory();

  const startPhysicalEntityUuid = physicalEntity ? physicalEntity.uuid : physicalField.physicalEntity.uuid;

  return (
    <Grid container direction='row' justifyContent='flex-end'>
      <Grid item>
        <Tooltip title='Show ER Diagram'>
          <IconButton
            size='small'
            onClick={() => history.push(`/physical-constraints/er-diagram?resourceIdentifier=${startPhysicalEntityUuid}`)}
          >
            <AccountTree />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <AddPhysicalConstraintButton
          system={physicalEntity?.system || physicalField?.physicalEntity?.system}
          physicalEntity={physicalEntity}
          physicalField={physicalField}
        />
      </Grid>
    </Grid>
  )
}

export default PhysicalConstraintsPanel;
