import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { AccessTime } from '@mui/icons-material';

import CronGenerator from 'components/CronExpressionTextField/CronGenerator';

const CronExpressionTextField = ({ value, onChange }) => {
  const [generatorOpen, setGeneratorOpen] = useState(false);

  const handleClick = () => {
    setGeneratorOpen(true);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  return <>
    <FormControl variant="standard" margin='dense' fullWidth>
      <InputLabel htmlFor='cron-expression-input'>Cron Expression</InputLabel>
      <Input
        id='cron-expression-input'
        type='text'
        value={value}
        onChange={(event) => onChange(event.target.value)}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton onClick={handleClick} onMouseDown={handleMouseDown} size="large">
              <AccessTime />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
    {generatorOpen && (
      <CronExpressionGeneratorModal
        open={generatorOpen}
        setOpen={setGeneratorOpen}
        setCronExpression={onChange}
      />
    )}
  </>;
};

const CronExpressionGeneratorModal = ({ open, setOpen, setCronExpression }) => {
  const { t } = useTranslation();

  const [resultingCronExpression, setResultingCronExpression] = useState(null);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Cron Expression Generator</DialogTitle>
      <DialogContent>
        <CronGenerator apply={setResultingCronExpression} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}>
          {t('commons.actions.cancel')}
        </Button>
        <Button
          onClick={() => {
            setCronExpression(resultingCronExpression);
            setOpen(false);
          }}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CronExpressionTextField;
