import React, { useEffect, useState } from 'react';
import ItemAutoComplete from '../../../components/itemselector/ItemAutoComplete';
import { searchSecurityMeasures } from '../../../actions/SecurityMeasureActions';
import {
  DialogTitle,
  Dialog,
  Button,
  DialogContent,
  TextField,
  DialogActions,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton
} from '@mui/material';

import Delete from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const INITIAL_STATE = {
  name: '',
  description: ''
};

function SecurityMeasuresFormSection({ securityMeasures, onAdd, onDelete }) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <h4>Security Measures</h4>
      <List>
        {(securityMeasures || []).map((e, index) => (
          <ListItem key={index}>
            <ListItemText primary={e.name} secondary={e.description} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => onDelete(e)} size='large'>
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Button children={'Add Security Measure'} color='primary' onClick={() => setOpen(true)} />
      <SecurityMeasureModal
        open={open}
        onCancel={() => setOpen(false)}
        onAdd={onAdd}></SecurityMeasureModal>
    </React.Fragment>
  );
}

function _SecurityMeasureModal({
  open,
  onAdd,
  onCancel,
  securityMeasuresSuggestions,
  searchSecurityMeasures
}) {
  const [editor, setEditor] = useState(INITIAL_STATE);
  const { t } = useTranslation();
  useEffect(
    function () {
      if (open) {
        searchSecurityMeasures({ name: '' });
      }
    },
    [open, searchSecurityMeasures]
  );
  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>{'Security Measure'}</DialogTitle>
      <DialogContent>
        <ItemAutoComplete
          values={securityMeasuresSuggestions}
          value={editor}
          itemName='Security Measure'
          keyProperty={'index'}
          nameProperty='name'
          subheaderProperty='description'
          onChange={(securityMeasure) => setEditor(securityMeasure || INITIAL_STATE)}
          onSearch={(searchText) => {
            setEditor({ ...editor, name: searchText });
            searchSecurityMeasures({ name: searchText });
          }}></ItemAutoComplete>
        <TextField
          variant='standard'
          fullWidth={true}
          label={'Description'}
          value={editor.description}
          onChange={(event) => setEditor({ ...editor, description: event.target.value })}
        />
        <DialogActions>
          <Button
            children={t('commons.actions.cancel')}
            onClick={() => {
              onCancel();
              setEditor(INITIAL_STATE);
            }}></Button>
          <Button
            children={t('commons.actions.submit')}
            onClick={() => {
              onAdd(editor);
              onCancel();
            }}></Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state, props) => ({
  securityMeasuresSuggestions: state.processings.securityMeasures
});

const mapDispatchToProps = (dispatch) => ({
  searchSecurityMeasures: (params) => dispatch(searchSecurityMeasures({ size: 50, ...params }))
});

const SecurityMeasureModal = connect(mapStateToProps, mapDispatchToProps)(_SecurityMeasureModal);

export default SecurityMeasuresFormSection;
