import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress, Card, CardHeader } from '@mui/material';
import { Chart } from 'react-google-charts';
import Loader from '../../../components/loader/Loader';
import ConsentsStatesColumnChartFilters from './ConsentsStatesColumnChartFilters';
import { fetchConsentsStatesColumnChart } from '../../../actions/DashboardsActions';

const ConsentsStatesColumnChart = (props) => (
  <Loader
    onLoad={() => {
      if (props.rows.length === 0) {
        props.fetchConsentsStatesColumnChart({});
      }
    }}>
    <Card style={{ margin: '8px 0 8px 0' }}>
      <CardHeader
        title='Consents States By Processing'
        subheader={subheader(props)}
        action={<ConsentsStatesColumnChartFilters />}
      />
      {props.columns.length > 0 ? (
        <Chart
          {...{
            chartType: 'ColumnChart',
            columns: props.columns,
            rows: props.rows,
            options: {
              legend: true,
              isStacked: true,
              hAxis: { title: 'Processings' },
              vAxis: { title: 'Consents count' },
              colors: ['#00aeca', '#455a64']
            },
            width: '100%'
          }}
        />
      ) : (
        <CircularProgress style={{ marginLeft: '50%', marginRight: '50%' }} />
      )}
    </Card>
  </Loader>
);

function subheader(props) {
  let sections = [subheaderTimeWindow(props), subheaderProcessings(props)];
  return sections.filter((s) => s).join(', ');
}

function subheaderTimeWindow({ startDate, endDate }) {
  if (startDate && !endDate) {
    return `Last ${computeDayDiff(startDate, endDate)} days`;
  } else if (startDate && endDate) {
    return `From ${startDate} to ${endDate}`;
  } else if (endDate) {
    return `Up to ${endDate}`;
  } else {
    return null;
  }
}

function subheaderProcessings({ processings }) {
  if (processings && processings.length > 0) {
    return `Processings: ${processings.map((p) => p.name).join(', ')}`;
  } else {
    return null;
  }
}

function computeDayDiff(startDate, endDate) {
  var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  var firstDate = new Date(startDate);
  var secondDate = new Date(endDate || new Date().toISOString());
  var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));
  return diffDays;
}

const mapStateToProps = (state) => ({
  columns: state.dashboards.consentsStates.columnChart.columns,
  rows: state.dashboards.consentsStates.columnChart.rows,
  startDate: state.dashboards.consentsStates.columnChartFilters.startDate,
  endDate: state.dashboards.consentsStates.columnChartFilters.endDate,
  processings: state.dashboards.consentsStates.columnChartFilters.processings
});

export default connect(mapStateToProps, { fetchConsentsStatesColumnChart })(
  ConsentsStatesColumnChart
);
