import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { logout } from '../../actions/LoginActions';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { ListItemText, ListItemAvatar, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { selectCurrentTenant } from '../../reducers/settings/tenants';
import UsernameAvatar from '../../components/avatars/UsernameAvatar';
import TenantSwitcher from './TenantSwitcher';

class UserAccountMenu extends React.Component {
  state = { open: false };

  toggleOpen = () => this.setState({ open: !this.state.open });

  render() {
    let { username, tenantName, logout, displayName } = this.props;
    let { open } = this.state;
    return (
      <div style={{ paddingBottom: '12px' }}>
        <ListItem button onClick={this.toggleOpen}>
          <ListItemAvatar>
            <UsernameAvatar username={username} randomBackgroundColor />
          </ListItemAvatar>
          <ListItemText
            primary={
              _.truncate(displayName, { length: 19, omission: '...' }) ||
              _.truncate(username, { length: 19, omission: '...' })
            }
            secondary={tenantName}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <TenantSwitcher />
            <ListItem button component={Link} to='/settings'>
              <ListItemText inset primary='Settings' />
            </ListItem>
            <ListItem button onClick={logout}>
              <ListItemText inset primary='Logout' />
            </ListItem>
          </List>
        </Collapse>
      </div>
    );
  }
}

//profile is loaded with UserAccountLoader.js
const mapStateToProps = (state) => ({
  username: state.settings.profile.username,
  displayName: state.settings.profile.displayName,
  tenantName: _.get(selectCurrentTenant(state), 'name')
});

export default connect(mapStateToProps, { logout })(withTranslation()(UserAccountMenu));
