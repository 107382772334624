import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

import { fetchConfiguration } from 'actions/quality/assessment/QualityAssessmentConfigurationActions';

import { Typography, Grid, Card, CardHeader, CardContent } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import ScoringMatrix from 'pages/quality/assessment/scoring/ScoringMatrix';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const QualityAssessmentScoringPage = ({ configuration, fetchConfiguration }) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchConfiguration();
  }, [fetchConfiguration]);

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.qualityAssessment'), to: '/data-quality/assessment/risks' },
          { text: t('navbar.qualityAssessmentScoring') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('quality.assessment.scoring.pageHeader'),
          category: EngagementCategories.DATA_QUALITY_ASSESSMENT,
          action: EngagementActions.DASHBOARDS
        }}
      />
      <Grid container direction='row'>
        <Grid item>
          <Typography variant='h4'>{t('quality.assessment.scoring.pageHeader')}</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:qualityassessmentscore-lsit'}
            pageTitle={t('quality.assessment.scoring.pageHeader')}
          />
        </Grid>
      </Grid>

      {configuration && (
        <Grid container direction='column' spacing={2}>
          <Grid item container alignItems='stretch' spacing={2}>
            <Grid item lg={6} md={12} style={{ width: '100%' }}>
              <Card style={{ height: '100%' }}>
                <CardHeader title='Inherent Risk' />
                <CardContent style={{ overflowX: 'auto' }}>
                  <InherentRiskMatrix configuration={configuration} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={6} md={12} style={{ width: '100%' }}>
              <Card style={{ height: '100%' }}>
                <CardHeader title='Check Impact' />
                <CardContent style={{ overflowX: 'auto' }}>
                  <CheckImpactMatrix configuration={configuration} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Card style={{ height: '100%' }}>
              <CardHeader title='Residual Risk' />
              <CardContent style={{ overflowX: 'auto' }}>
                <ResidualRiskMatrix configuration={configuration} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const InherentRiskMatrix = ({ configuration }) => {
  return (
    <ScoringMatrix
      domains={configuration.domains}
      matrix={configuration.inherentRiskMatrix}
      columnsDomainElementType='SEVERITY'
      rowsDomainElementType='PROBABILITY'
      matrixEntriesDomainElementType='RISK_EVALUATION'
      columnsHeader='Severity'
      rowsHeader='Probability'
    />
  );
};

const CheckImpactMatrix = ({ configuration }) => {
  return (
    <ScoringMatrix
      domains={configuration.domains}
      matrix={configuration.checkImpactMatrix}
      columnsDomainElementType='SEVERITY_REDUCTION'
      rowsDomainElementType='PROBABILITY_REDUCTION'
      matrixEntriesDomainElementType='CHECK_IMPACT'
      columnsHeader='Severity Reduction'
      rowsHeader='Probability Reduction'
      mirroredColors={true}
    />
  );
};

const ResidualRiskMatrix = ({ configuration }) => {
  return (
    <ScoringMatrix
      domains={configuration.domains}
      matrix={configuration.residualRiskMatrix}
      columnsDomainElementType='RISK_EVALUATION'
      rowsDomainElementType='CHECK_IMPACT'
      matrixEntriesDomainElementType='RISK_EVALUATION'
      columnsHeader='Inherent Risk'
      rowsHeader='Check Impact'
    />
  );
};

const mapStateToProps = (state, props) => ({
  configuration: state.quality.assessment.configuration
});

const mapDispatchToProps = {
  fetchConfiguration
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityAssessmentScoringPage);
