import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';
import Permissions from '../../../constants/Permissions';
import ExpandableCard from '../../../components/expandable-card/ExpandableCard';
import TaskAssignments from '../detail/TaskAssignments';
import TeamCodeDecorator from '../../settings/teams/common/TeamCodeDecorator';
import { ProposalConfirmationButton } from '../../workflows/proposals/commons/withProposalConfirmation';
import {
  ProposalResourceTypes,
  ProposalVariationTypes
} from '../../workflows/proposals/commons/ProposalConstants';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';
import ResourceTypes from '../../../constants/ResourceTypes';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import AuthorizeTeam from 'components/permissions/AuthorizeTeam';

function TaskListItem({ task, deleteTask, modifyTask }) {
  let { t } = useTranslation();
  let history = useHistory();
  return (
    <ExpandableCard
      title={task.name}
      subheader={
        <div>
          <label>{t('tasks.fields.category')}: </label>
          <b>{task.category || t('commons.placeholders.undefined')}</b>
        </div>
      }
      decorator={<TeamCodeDecorator teamCode={task.teamCode} />}
      actions={[
        task.uuid && (
          <Button component={Link} to={`/tasks/${task.uuid}`}>
            {t('tasks.actions.detail')}
          </Button>
        ),
        modifyTask && (
          <AuthorizeTeam
            hasAnyPermissions={[Permissions.TASKS_EDITOR, Permissions.PROPOSE]}
            teamCode={task.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE, TeamAccessLevels.PROPOSE]}>
            <Button onClick={() => modifyTask(task)}>{t('tasks.actions.modify')}</Button>
          </AuthorizeTeam>
        ),

        deleteTask && (
          <ProposalConfirmationButton
            AuthorizeProps={{
              hasPermissions: [Permissions.TASKS_ADMIN],
              teamCode: task.teamCode,
              teamAccessLevels: [TeamAccessLevels.WRITE, TeamAccessLevels.PROPOSE]
            }}
            message={t('tasks.deleteConfirmationMessage', {
              processingName: task.name
            })}
            ProposeButtonProps={{
              resourceName: task.name,
              resourceType: ProposalResourceTypes.TASK,
              resourceIdentifier: task.uuid,
              variationType: ProposalVariationTypes.DELETE
            }}
            onClick={() => deleteTask(task)}>
            {t('tasks.actions.delete')}
          </ProposalConfirmationButton>
        )
      ]}>
      {task.description}
      <h4>{t('tasks.sections.businessMetadata')}</h4>
      <KVDefinitionList
        item={{
          [t('tasks.fields.category')]: task.category
        }}
      />

      <h4>{t('tasks.sections.lifecycleDefinition')}</h4>
      <KVDefinitionList
        item={{
          [t('tasks.fields.startDate')]: task.startDate,
          [t('tasks.fields.endDate')]: task.endDate
        }}
      />

      <CustomPropertiesView
        resourceType={ResourceTypes.TASK}
        additionalProperties={task.additionalProperties}
      />

      <h4>{t('tasks.sections.associatedProcessings')}</h4>
      <ChipWrapper>
        {(task.processings || []).map((p) => (
          <ChipItem
            key={p.uuid}
            id={p.uuid}
            label={p.name}
            tooltip={p.description}
            onClick={() => history.push(`/processings/${p.uuid}`)}
          />
        ))}
      </ChipWrapper>

      {task.uuid && <TaskAssignments task={task} />}
    </ExpandableCard>
  );
}

TaskListItem.propTypes = {
  task: PropTypes.shape({
    name: PropTypes.string,
    processings: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  deleteTask: PropTypes.func.isRequired,
  modifyTask: PropTypes.func.isRequired
};

export default TaskListItem;
