import {
  FETCH_HISTORY_RECORD,
  FETCH_HISTORY_RECORD_PAGE,
  FETCH_HISTORY_RECORD_PAYLOAD,
  SET_HISTORYS_FILTERS,
  CLEAR_HISTORYS_FILTERS,
  EXPORT_HISTORY_RECORD
} from '../constants/ActionTypes';
import HistoryApi from '../api/HistoryApi';
import { buildApiCallAction } from './ActionUtils';

const historyApi = new HistoryApi();

export const setHistoryFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_HISTORYS_FILTERS, payload: filters });
};

export const clearHistoryFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_HISTORYS_FILTERS });
};
export const applyFilters =
  ({ page, size, ...newFilters }) =>
  (dispatch, getState) => {
    dispatch(setHistoryFilters(newFilters));
    dispatch(
      fetchHistoryRecordPage({
        page,
        size,
        ...newFilters
      })
    );
  };

export const fetchHistoryRecordPage = (filters) =>
  buildApiCallAction({
    api: historyApi,
    apiFunction: (api) => {
      let apiParams = createApiParams(filters);
      return api.getHistoryRecords(apiParams);
    },
    actionType: FETCH_HISTORY_RECORD_PAGE
  });

function createApiParams(filters) {
  let apiParams = {
    ...filters,
    userId: filters.user && filters.user.uuid,
    targetIdentifier: filters.resource && filters.resource.resourceIdentifier
  };
  delete apiParams.user;
  delete apiParams.resource;
  return apiParams;
}
export const fetchHistoryRecord = (sequenceId) =>
  buildApiCallAction({
    api: historyApi,
    apiFunction: (api) => api.getHistoryRecord(sequenceId),
    actionType: FETCH_HISTORY_RECORD
  });

export const fetchHistoryRecordPayload = (sequenceId) =>
  buildApiCallAction({
    api: historyApi,
    apiFunction: (api) => api.getHistoryRecordPayload(sequenceId),
    actionType: FETCH_HISTORY_RECORD_PAYLOAD
  });

export const exportHistoryRecords = (filters) =>
  buildApiCallAction({
    api: historyApi,
    apiFunction: (api) => api.exportHistoryRecords(createApiParams(filters)),
    actionType: EXPORT_HISTORY_RECORD
  });
