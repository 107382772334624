import React from 'react';
import { withTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';

import GraphMenuNodeDetail from './GraphMenuNodeDetail';
import GraphMenuAddDataFlow from './GraphMenuAddDataFlow';
import GraphMenuHideUnrelated from './GraphMenuHideUnrelated';
import GraphMenuShowRelated from './GraphMenuShowRelated';
import GraphMenuResetFilters from './GraphMenuResetFilters';
import GraphMenuFocusNode from './GraphMenuFocusNode';
import GraphMenuExpandNode from './GraphMenuExpandNode';
import GraphMenuReloadGraph from './GraphMenuReloadGraph';
import GraphMenuPhysicsLayout from './GraphMenuPhysicsLayout';

const GraphMenu = ({ node, graphHandler, open, onToggle, PhysicsLayoutProps }) => (
  <div>
    <IconButton sx={styles.iconButton} onClick={() => onToggle(!open)} size='large'>
      <MenuIcon />
    </IconButton>

    <Drawer variant='persistent' anchor={'right'} open={open} onClose={() => onToggle(open)}>
      <GraphMenuDrawerControl {...{ onToggle, open }} />

      <GraphMenuNodeDetail node={node} />

      <MenuList sx={styles.container}>
        <GraphMenuHideUnrelated {...{ graphHandler, node }} />

        <GraphMenuShowRelated {...{ graphHandler, node }} />

        <GraphMenuResetFilters {...{ graphHandler, node }} />

        <Divider />

        <GraphMenuFocusNode {...{ node, onToggle }} />

        <GraphMenuExpandNode {...{ node, onToggle }} />

        <GraphMenuReloadGraph {...{ node, onToggle }} />

        <Divider />

        <GraphMenuAddDataFlow {...{ graphHandler }} />
      </MenuList>
      <div style={styles.filler} />
      <GraphMenuPhysicsLayout {...{ graphHandler, ...PhysicsLayoutProps }} />
    </Drawer>
  </div>
);

function GraphMenuDrawerControl({ onToggle, open }) {
  return (
    <>
      <div style={{ padding: 8 }}>
        <IconButton onClick={() => onToggle(!open)} disableTouchRipple={true} size='large'>
          <ChevronRightIcon />
        </IconButton>
      </div>

      <Divider />
    </>
  );
}

const styles = {
  iconButton: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    width: 'auto',
    height: 'auto',
    margin: '10px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  filler: {
    flexGrow: 1
  }
};

export default withTranslation()(GraphMenu);
