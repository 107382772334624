import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { connect } from 'react-redux';
import { exportStewardshipAssignments } from '../../../../actions/ExportsActions';

function StewardshipAssignmentExport({ filters, onClick }) {
  return (
    <Tooltip title='Export CSV'>
      <IconButton
        color='default'
        aria-label='Download'
        onClick={() => onClick(filters)}
        size='small'>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: (filters) => dispatch(exportStewardshipAssignments(filters))
  };
}

export default connect(null, mapDispatchToProps)(StewardshipAssignmentExport);
