import { combineReducers } from 'redux';
import {
  FETCH_CONTRACT_CONFIG_PAGE,
  CLEAR_CONTRACT_CONFIGS,
  FETCH_CONTRACT_CONFIG,
  CREATE_CONTRACT_CONFIG,
  MODIFY_CONTRACT_CONFIG,
  DELETE_CONTRACT_CONFIG,
  EDIT_CONTRACT_CONFIG_EDITOR,
  CLEAR_CONTRACT_CONFIG_EDITOR,
  SET_FILTERS_CONTRACT_CONFIGS,
  CLEAR_FILTERS_CONTRACT_CONFIGS
} from '../../constants/ActionTypes';

const content = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONTRACT_CONFIG:
    case CREATE_CONTRACT_CONFIG:
    case MODIFY_CONTRACT_CONFIG:
      return {
        ...state,
        [action.payload.uuid]: action.payload
      };
    case DELETE_CONTRACT_CONFIG:
      let { [action.payload.uuid]: _clearedELem, ...stateDiff } = state;
      return stateDiff;
    default:
      return state;
  }
};

const currentPage = (
  state = { content: [], number: 0, totalPages: 0, totalElements: 0 },
  action
) => {
  switch (action.type) {
    case FETCH_CONTRACT_CONFIG_PAGE:
      return action.payload;
    case DELETE_CONTRACT_CONFIG:
      return {
        ...state,
        content: state.content.filter((i) => i.uuid !== action.payload.uuid)
      };
    case CLEAR_CONTRACT_CONFIGS:
      return { content: [], number: 0, totalPages: 0 };
    default:
      return state;
  }
};

const editor = (state = {}, action) => {
  switch (action.type) {
    case EDIT_CONTRACT_CONFIG_EDITOR:
      return { ...state, ...action.payload };
    case CLEAR_CONTRACT_CONFIG_EDITOR:
      return {};
    default:
      return state;
  }
};

const filters = (
  state = { contractName: null, version: null, active: null, lang: null },
  action
) => {
  switch (action.type) {
    case SET_FILTERS_CONTRACT_CONFIGS:
      return { ...state, ...action.payload };
    case CLEAR_FILTERS_CONTRACT_CONFIGS:
      return filters(undefined, {});
    default:
      return state;
  }
};

export default combineReducers({ content, currentPage, editor, filters });
