import _ from 'lodash';
import DataCategoriesApi from '../../../../api/DataCategoriesApi';
import DataActorsApi from '../../../../api/DataActorApi';
import ProcessingsApi from '../../../../api/ProcessingsApi';
import TasksApi from '../../../../api/TasksApi';
import TaskAssignmentsApi from '../../../../api/TaskAssignmentsApi';
import LogicalFieldsApi from '../../../../api/LogicalFieldsApi';
import SystemsApi from '../../../../api/SystemsApi';
import PhysicalEntitiesApi from '../../../../api/PhysicalEntitiesApi';
import DataFlowsApi from '../../../../api/DataFlowsApi';
import PhysicalFieldsApi from '../../../../api/PhysicalFieldsApi';
import TemplatesApi from '../../../../api/TemplatesApi';
import AbstractImporter from './AbstractImporter';

export default class ImporterLegacy extends AbstractImporter {
  static isSupported(object) {
    return (
      object.task ||
      object.processing ||
      object.dataActor ||
      object.dataCategory ||
      object.taskAssignment ||
      object.logicalField ||
      object.system ||
      object.physicalEntity ||
      object.physicalField ||
      object.dataFlow ||
      object.templateEvent ||
      object.rule ||
      object.dictionary
    );
  }

  async importObject(object, resolveUuidOnly = false) {
    if (object.task) {
      return this.importTask(object.task, resolveUuidOnly);
    } else if (object.processing) {
      return this.importProcessing(object.processing, resolveUuidOnly);
    } else if (object.dataActor) {
      return this.importDataActor(object.dataActor, resolveUuidOnly);
    } else if (object.dataCategory) {
      return this.importDataCategory(object.dataCategory, resolveUuidOnly);
    } else if (object.taskAssignment) {
      return this.importTaskAssignment(object.taskAssignment, resolveUuidOnly);
    } else if (object.logicalField) {
      return this.importLogicalField(object.logicalField, resolveUuidOnly);
    } else if (object.system) {
      return this.importSystem(object.system, resolveUuidOnly);
    } else if (object.physicalEntity) {
      return this.importPhysicalEntity(object.physicalEntity, resolveUuidOnly);
    } else if (object.physicalField) {
      return this.importPhysicalField(object.physicalField, resolveUuidOnly);
    } else if (object.dataFlow) {
      return this.importDataFlow(object.dataFlow, resolveUuidOnly);
    } else if (object.templateEvent) {
      return this.importTemplateEvent(object.templateEvent, resolveUuidOnly);
    }
    throw new Error('Object type not supported by ImporterLegacy');
  }

  async importTask(task, resolveUuidOnly = false) {
    let api = new TasksApi();
    let reconciledTask = task;
    if (!resolveUuidOnly) {
      reconciledTask = await this.resolveTaskDependencies(task);
    }
    return await this.importTemplate(
      reconciledTask,
      api,
      api.searchTasks,
      api.putTask,
      api.postTask,
      resolveUuidOnly,
      api.deleteTask
    );
  }

  async resolveTaskDependencies(task) {
    let processings = await Promise.all(
      _.get(task, 'processings', []).map(
        async (processing) => await this.importProcessing(processing, true)
      )
    );
    return { ...task, processings };
  }

  async importTaskAssignment(taskAssignment) {
    let reconciledTaskAssignment = taskAssignment;
    let dataActor,
      task = null;
    if (!taskAssignment.dataActorUuid && taskAssignment.dataActorName) {
      dataActor = await this.importDataActor({ name: taskAssignment.dataActorName }, true);
      reconciledTaskAssignment.dataActorUuid = _.get(dataActor, 'uuid');
    }
    if (!taskAssignment.taskUuid && taskAssignment.taskName) {
      task = await this.importTask({ name: taskAssignment.taskName }, true);
      reconciledTaskAssignment.taskUuid = _.get(task, 'uuid');
    }
    let api = new TaskAssignmentsApi();
    this.authenticateApi(api);
    let searchRes = await api.getTaskAssignments({ dataActor, task }, 0);
    if (searchRes.content.length > 0 && searchRes.content[0].uuid) {
      reconciledTaskAssignment.uuid = searchRes.content[0].uuid;
    }
    if (reconciledTaskAssignment.uuid) {
      if (reconciledTaskAssignment._DELETE) {
        api.deleteTaskAssignment(reconciledTaskAssignment);
      } else {
        return api.putTaskAssignment(reconciledTaskAssignment);
      }
    } else {
      return api.postTaskAssignment(reconciledTaskAssignment);
    }
  }

  async importProcessing(processing, resolveUuidOnly = false) {
    let api = new ProcessingsApi();
    let reconciledProcessing = processing;
    if (!resolveUuidOnly) {
      reconciledProcessing = await this.resolveProcessingDependencies(processing);
    }
    return await this.importTemplate(
      reconciledProcessing,
      api,
      api.searchProcessings,
      api.putProcessing,
      api.postProcessing,
      resolveUuidOnly,
      api.deleteProcessing
    );
  }

  async importDataCategory(dataCategory, resolveUuidOnly = false) {
    let api = new DataCategoriesApi();
    return this.importTemplate(
      dataCategory,
      api,
      api.searchDataCategories,
      api.putDataCategory,
      api.postDataCategory,
      resolveUuidOnly,
      api.deleteDataCategory
    );
  }

  async resolveProcessingDependencies(processing) {
    let controller = await this.importDataActor(processing.controller, true);
    let dataProtectionOfficer = await this.importDataActor(processing.dataProtectionOfficer, true);
    let representative = await this.importDataActor(processing.representative, true);
    let jointControllers = await Promise.all(
      _.get(processing, 'jointControllers', []).map(
        async (jointController) => await this.importDataActor(jointController, true)
      )
    );
    let associatedDataCategories = await Promise.all(
      _.get(processing, 'associatedDataCategories', []).map(async (adc) => ({
        ...adc,
        dataCategory: await this.importDataCategory(adc.dataCategory, true)
      }))
    );
    let transferExtraEuOrganizations = await Promise.all(
      _.get(processing, 'transferExtraEuOrganizations', []).map(async (tEEO) => ({
        ...tEEO,
        organization: await this.importDataActor(tEEO.organization, true)
      }))
    );
    return {
      ...processing,
      associatedDataCategories,
      controller,
      dataProtectionOfficer,
      representative,
      jointControllers,
      transferExtraEuOrganizations
    };
  }

  async importLogicalField(logicalField, resolveUuidOnly = false) {
    if (!_.get(logicalField, 'dataCategory.name') && !_.get(logicalField, 'dataCategory.uuid')) {
      throw new Error('DataCategory not specified');
    }
    let dataCategory = await this.importDataCategory(logicalField.dataCategory, true);

    let reconciledLogicalField = { ...logicalField, dataCategory };
    let api = new LogicalFieldsApi();
    api.searchLogicalFieldsByCategory = function (search, options) {
      return this.searchLogicalFields(search, dataCategory.uuid, options);
    };
    return this.importTemplate(
      reconciledLogicalField,
      api,
      api.searchLogicalFieldsByCategory,
      api.putLogicalField,
      api.postLogicalField,
      resolveUuidOnly,
      api.deleteLogicalField
    );
  }

  async importDataActor(dataActor, resolveUuidOnly = false) {
    let api = new DataActorsApi();
    let reconciledDataActor = dataActor;
    if (!resolveUuidOnly) {
      reconciledDataActor = await this.resolveDataActorDependencies(dataActor);
    }
    return this.importTemplate(
      reconciledDataActor,
      api,
      api.searchDataActors,
      api.putDataActor,
      api.postDataActor,
      resolveUuidOnly,
      api.deleteDataActor
    );
  }

  async resolveDataActorDependencies(dataActor) {
    let refersToDataActor = dataActor.refersToDataActor;
    if (refersToDataActor) {
      refersToDataActor = await this.importDataActor(refersToDataActor, true);
    }
    return { ...dataActor, refersToDataActor };
  }

  async importSystem(system, resolveUuidOnly = false) {
    let systemsApi = new SystemsApi();
    return await this.importTemplate(
      system,
      systemsApi,
      (search, params) => systemsApi.getSystems({ search, ...params }),
      systemsApi.putSystem,
      systemsApi.postSystem,
      resolveUuidOnly,
      systemsApi.deleteSystem
    );
  }

  async importPhysicalEntity(physicalEntity, resolveUuidOnly = false) {
    let api = new PhysicalEntitiesApi();
    let system = await this.importSystem(physicalEntity.system, true);
    let dataCategories = await Promise.all(
      (physicalEntity.dataCategories || []).map(
        async (dataCategory) => await this.importDataCategory(dataCategory, true)
      )
    );
    let resolvedPhysicalEntity = { ...physicalEntity, system, dataCategories };
    return await this.importTemplate(
      resolvedPhysicalEntity,
      api,
      (name, { page, size }) =>
        api.searchPhysicalEntities(
          physicalEntity.schema ? `${physicalEntity.schema}.${name}` : name,
          system.uuid,
          page,
          size
        ),
      api.putPhysicalEntity,
      api.postPhysicalEntity,
      resolveUuidOnly,
      api.deletePhysicalEntity
    );
  }

  async importDataFlow(dataFlow, resolveUuidOnly = false) {
    let api = new DataFlowsApi();
    let resolvedDataFlow = await this.resolveDataFlowDependencies(dataFlow);
    return this.importTemplate(
      resolvedDataFlow,
      api,
      (search, { page, size }) => api.getDataFlows(page, size, { search }),
      api.putDataFlow,
      api.postDataFlow,
      resolveUuidOnly,
      api.deleteDataFlow
    );
  }

  async resolveDataFlowDependencies(dataFlow) {
    let fromSystem = dataFlow.fromSystem
      ? await this.importSystem(dataFlow.fromSystem, true)
      : null;
    let toSystem = dataFlow.toSystem ? await this.importSystem(dataFlow.toSystem, true) : null;
    let fromPhysicalEntity =
      dataFlow.fromPhysicalEntity && dataFlow.fromPhysicalEntity.name
        ? await this.importPhysicalEntity(
            {
              ...dataFlow.fromPhysicalEntity,
              system: dataFlow.fromPhysicalEntity.system || fromSystem
            },
            true
          )
        : null;
    let toPhysicalEntity =
      dataFlow.toPhysicalEntity && dataFlow.toPhysicalEntity.name
        ? await this.importPhysicalEntity(
            {
              ...dataFlow.toPhysicalEntity,
              system: dataFlow.toPhysicalEntity.system || toSystem
            },
            true
          )
        : null;
    let fromPhysicalField =
      dataFlow.fromPhysicalField && dataFlow.fromPhysicalField.name
        ? await this.importPhysicalField(
            {
              ...dataFlow.fromPhysicalField,
              physicalEntity: dataFlow.fromPhysicalField.physicalEntity || fromPhysicalEntity
            },
            true
          )
        : null;
    let toPhysicalField =
      dataFlow.toPhysicalField && dataFlow.toPhysicalField.name
        ? await this.importPhysicalField(
            {
              ...dataFlow.toPhysicalField,
              physicalEntity: dataFlow.toPhysicalField.physicalEntity || toPhysicalEntity
            },
            true
          )
        : null;
    return {
      ...dataFlow,
      fromSystem,
      toSystem,
      fromPhysicalEntity,
      toPhysicalEntity,
      fromPhysicalField,
      toPhysicalField,
      dataCategories: await Promise.all(
        (dataFlow.dataCategories || []).map((dataCategory) =>
          this.importDataCategory(dataCategory, true)
        )
      ),
      logicalFields: await Promise.all(
        (dataFlow.logicalFields || []).map((logicalField) =>
          this.importLogicalField(logicalField, true)
        )
      )
    };
  }

  async importPhysicalField(physicalField, resolveUuidOnly = false) {
    let physicalEntity = physicalField.physicalEntity
      ? await this.importPhysicalEntity(physicalField.physicalEntity, true)
      : null;
    let logicalField =
      _.get(physicalField, 'logicalField.name') || _.get(physicalField, 'logicalField.uuid')
        ? await this.importLogicalField(physicalField.logicalField, true)
        : null;
    let logicalFields = physicalField?.logicalFields?.length
      ? await Promise.all(
          physicalField.logicalFields.map((lf) => this.importLogicalField(lf, true))
        )
      : null;
    let resolvedPhysicalField = {
      ...physicalField,
      physicalEntity,
      logicalField,
      logicalFields
    };
    let api = new PhysicalFieldsApi();
    return this.importTemplate(
      resolvedPhysicalField,
      api,
      (search, { page, size }) =>
        api.getPhysicalFields({
          search,
          physicalEntityUuid: physicalEntity.uuid,
          page,
          size
        }),
      api.putPhysicalField,
      api.postPhysicalField,
      resolveUuidOnly,
      api.deletePhysicalField
    );
  }

  async importTemplateEvent(templateEvent) {
    let api = new TemplatesApi();
    this.authenticateApi(api);
    return api.postTemplateEvent(templateEvent);
  }

  async importTemplate(
    object,
    api,
    search,
    put,
    post,
    resolveUuidOnly = false,
    delet = () => null
  ) {
    return super.importTemplate({
      object,
      objectName: 'name',
      objectIdentifier: 'uuid',
      api,
      search:
        search &&
        function ({ search: searchText, ...params }) {
          return search.apply(this, [searchText, params]);
        },
      put,
      post,
      resolveUuidOnly,
      delet
    });
  }
}
