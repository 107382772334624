import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from '@mui/material';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import { fetchRule, deleteRule, modifyRule } from '../../../../actions/RulesActions';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import { HelpIcon } from '../../../../components/help';
import { withLoader } from '../../../../components/loader/Loader';
import RuleCardView from '../commons/RuleCardView';
import RulesModal from '../commons/RulesModal';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

function RuleDetailPage({ rule, deleteRule, modifyRule }) {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        elements={[{ text: 'Classification', to: '/classification' }, { text: rule.name }]}
      />
      <Tracking
        info={{
          pageTitle: `Rule: ${rule.name}`,
          category: EngagementCategories.CLASSIFICATION,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Rule: {rule.name}</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget itemIdentifier={`RULE:${rule.uuid}`} pageTitle={`Rule: ${rule.name}`} />
        </Grid>
      </Grid>
      <Card>
        <CardHeader
          title={rule.name}
          subheader={
            <Typography paragraph>
              {rule.type}
              <HelpIcon tooltip={t(`classification.rules.${rule.type}.definition`)} />
            </Typography>
          }></CardHeader>
        <CardContent>{rule.description || 'No description inserted'}</CardContent>
        <Authorize hasPermissions={[Permissions.CLASSIFICATION_EDITOR]}>
          <CardActions>
            <ModifyRule rule={rule} modifyRule={modifyRule}></ModifyRule>
            <ConfirmButton
              onClick={() => {
                deleteRule(rule).then(() => history.push('/classification/rules'));
              }}>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </CardActions>
        </Authorize>
      </Card>
      <Typography variant='h5'>Details</Typography>
      <RuleCardView rule={rule}></RuleCardView>
    </>
  );
}

function ModifyRule({ rule, modifyRule }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      <RulesModal
        rule={rule}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(modifiedRule) => {
          modifyRule(modifiedRule);
          setOpen(false);
        }}></RulesModal>
    </>
  );
}

function mapStateToProps(state, props) {
  const uuid = props.match.params.uuid;
  return {
    rule: state.classification.rules.content[uuid],
    uuid: props.match.params.uuid
  };
}

const mapDispatchToProps = {
  fetchRule,
  deleteRule,
  modifyRule
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchRule, uuid }) => fetchRule(uuid),
    ready: ({ rule }) => rule !== undefined
  })
)(RuleDetailPage);
