import React from 'react';
import ConsentSearchPanel from './ConsentSearchPanel';
import ConsentsSearchResults from './ConsentsSearchResults';
import { Grid, Typography } from '@mui/material';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const ConsentsSearchPage = (props) => (
  <div>
    <Breadcrumbs elements={[{ text: 'Consents', to: '/consents' }]} />
    <Tracking
      info={{
        pageTitle: `Consents`,
        category: EngagementCategories.CONSENTS,
        action: EngagementActions.DETAIL
      }}
    />
    <Grid container>
      <Grid item style={{ flexGrow: 1 }}>
        <Typography variant='h4'>Consents</Typography>
      </Grid>
      <Grid item>
        <FavoritesWidget itemIdentifier={'page:consents-list'} pageTitle={'Consents'} />
      </Grid>
    </Grid>
    <ConsentSearchPanel />
    <ConsentsSearchResults />
  </div>
);

export default ConsentsSearchPage;
