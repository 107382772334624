import React, { useState } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Dialog
} from '@mui/material';
import AdditionalPropertiesEditor from '../../../../components/additional_properties/AdditionalPropertiesEditor';
import { useTranslation } from 'react-i18next';
import ResourceTypes from '../../../../constants/ResourceTypes';
import LogicalFieldItemSelector from '../../../datacategories/utils/LogicalFieldItemSelector';
import Grid from '@mui/material/Grid';
import DatePicker, { hourAdjustments } from '../../../../components/pickers/DatePicker';
import { ChipItem, ChipWrapper } from 'components/chiplist/ChipList';
import LogicalFieldSearchModal from 'pages/datacategories/utils/LogicalFieldSearchModal';

const INITIAL_STATE = {
  name: '',
  description: '',
  type: '',
  ordinalPosition: null,
  logicalField: null,
  creationDate: null,
  modificationDate: null,
  additionalProperties: []
};

function PhysicalFieldEditModal({ open, title, physicalField, handleCancel, handleSubmit }) {
  const [editor, setEditor] = useState(physicalField || INITIAL_STATE);
  const [logicalFieldOpen, setLogicalFieldOpen] = useState(false);
  let { t } = useTranslation();
  return (
    <>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <h4 style={{ marginTop: '8px' }}>General</h4>
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder="Enter the Physical field's Name"
            value={editor.name || ''}
            label='Name'
            required={true}
            onChange={(event, name) => setEditor({ ...editor, name: event.target.value })}
          />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            value={editor.description || ''}
            placeholder="Enter the Physical field's Description"
            label='Description'
            onChange={(event, newValue) =>
              setEditor({ ...editor, description: event.target.value })
            }
          />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            value={editor.type || ''}
            placeholder="Enter the Physical field's type"
            label='Type'
            onChange={(event, newValue) => setEditor({ ...editor, type: event.target.value })}
          />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            type='number'
            placeholder={'Enter the ordinal position'}
            label={'Ordinal Position'}
            value={editor.ordinalPosition || null}
            onChange={(event) => setEditor({ ...editor, ordinalPosition: event.target.value })}
          />
          {physicalField?.logicalField && (
            <LogicalFieldItemSelector
              value={editor.logicalField}
              onChange={(value) => setEditor({ ...editor, logicalField: value })}
            />
          )}
          <h4>{t('physicalFields.detail.logicalFields.title')}</h4>
          <ChipWrapper onRequestAdd={() => setLogicalFieldOpen(true)}>
            {(editor.logicalFields || []).map((lf) => (
              <ChipItem
                key={lf.uuid}
                id={lf.uuid}
                label={lf.name}
                tooltip={lf.description}
                onRequestDelete={() =>
                  setEditor({
                    ...editor,
                    logicalFields: editor.logicalFields.filter((e) => e.uuid !== lf.uuid)
                  })
                }
              />
            ))}
          </ChipWrapper>
          <LogicalFieldSearchModal
            open={logicalFieldOpen}
            onCancel={() => setLogicalFieldOpen(false)}
            onSubmit={(lf) => {
              setLogicalFieldOpen(false);
              setEditor({
                ...editor,
                logicalFields: (editor.logicalFields || []).concat([lf])
              });
            }}
          />
          <Grid container justifyContent='flex-start' spacing={2}>
            <Grid item>
              <DatePicker
                fullWidth
                margin={'dense'}
                clearable={true}
                label={'Created on system at'}
                hintText={'Creation Date'}
                hourAdjustment={hourAdjustments.startOfDay}
                value={editor.creationDate || null}
                onChange={(date) => setEditor({ ...editor, creationDate: date })}
              />
            </Grid>
            <Grid item>
              <DatePicker
                fullWidth
                margin={'dense'}
                clearable={true}
                label={'Updated on system at'}
                hintText={'Modification Date'}
                hourAdjustment={hourAdjustments.startOfDay}
                value={editor.modificationDate || null}
                onChange={(date) => setEditor({ ...editor, modificationDate: date })}
              />
            </Grid>
          </Grid>
          <AdditionalPropertiesEditor
            additionalProperties={editor.additionalProperties}
            resourceType={ResourceTypes.PHYSICAL_FIELD}
            onChange={(newList) =>
              setEditor({
                ...editor,
                additionalProperties: newList
              })
            }
          />
          <DialogActions>
            <Button children={t('commons.actions.cancel')} onClick={handleCancel} />
            <Button
              children={t('commons.actions.submit')}
              variant='contained'
              color='primary'
              disabled={!editor.name}
              onClick={() => {
                handleSubmit(editor);
                handleCancel();
              }}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PhysicalFieldEditModal;
