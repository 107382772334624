import React from 'react';
import { createRule } from '../../../../actions/RulesActions';
import { useHistory } from 'react-router';
import RulesModal from './RulesModal';
import { connect } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';

import AddBoxIcon from '@mui/icons-material/AddBox';
import _ from 'lodash';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';

function RuleCreateButton({ createRule, classes }) {
  const [open, setOpen] = React.useState(false);
  let history = useHistory();

  return (
    <>
      <Tooltip title={'Create rule'}>
        <IconButton
          aria-label='create rules'
          color='default'
          size={'small'}
          onClick={() => setOpen(true)}>
          <AddBoxIcon />
        </IconButton>
      </Tooltip>
      <RulesModal
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(rule) => {
          createRule(rule).then((rule) => {
            if (rule) {
              setOpen(false);
            }
            history.push(`/classification/rules/${rule.uuid}`);
          });
        }}></RulesModal>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  createRule
};

export default _.flow([
  withPermissions({ hasPermissions: [Permissions.CLASSIFICATION_EDITOR] }),
  connect(mapStateToProps, mapDispatchToProps)
])(RuleCreateButton);
