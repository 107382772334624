import React, { useState } from 'react';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid
} from '@mui/material';
import { connect } from 'react-redux';
import {
  fetchTemplateMapping,
  modifyTemplateMapping,
  deleteTemplateMapping
} from '../../../../actions/TemplatesActions';
import { compose } from 'redux';
import { withLoader } from '../../../../components/loader/Loader';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import TemplateMappingModal from '../commons/TemplateMappingModal';
import TeamCodeDecorator from '../../../settings/teams/common/TeamCodeDecorator';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

function TemplateMappingDetailPage({
  templateMapping,
  deleteTemplateMapping,
  modifyTemplateMapping
}) {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        elements={[{ text: 'Templates', to: '/templates' }, { text: templateMapping.name }]}
      />
      <Tracking
        info={{
          pageTitle: `Mapping Template: ${templateMapping.name}`,
          category: EngagementCategories.TEMPLATES,
          action: EngagementActions.LIST
        }}
      />
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Mapping Template: {templateMapping.name}</Typography>{' '}
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`MAPPING_TEMPLATE:${templateMapping.sequenceId}`}
            pageTitle={`Mapping Template: ${templateMapping.name}`}></FavoritesWidget>
        </Grid>
      </Grid>

      <Card>
        <CardHeader
          title={templateMapping.name}
          subheader={templateMapping.code}
          action={
            <TeamCodeDecorator teamCode={templateMapping.teamCode}></TeamCodeDecorator>
          }></CardHeader>
        <CardContent>
          {templateMapping.description}
          <h4>Associated processings templates</h4>
          <ChipWrapper>
            {(templateMapping.templatesTasks || []).map((p, index) => (
              <ChipItem
                key={index}
                id={index}
                label={p.name}
                to={`/templates/tasks/${p.sequenceId}`}
              />
            ))}
          </ChipWrapper>
        </CardContent>
        <Authorize hasPermissions={[Permissions.TASKS_ADMIN]}>
          <CardActions>
            <ModifyAction
              templateMapping={templateMapping}
              modifyTemplateMapping={modifyTemplateMapping}></ModifyAction>
            <ConfirmButton
              onClick={() => {
                deleteTemplateMapping(templateMapping.sequenceId).then(() =>
                  history.push('/templates/mappings')
                );
              }}>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </CardActions>
        </Authorize>
      </Card>
    </>
  );
}

function ModifyAction({ templateMapping, modifyTemplateMapping }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      <TemplateMappingModal
        templateMapping={templateMapping}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(modifiedTemplateMapping) => {
          modifyTemplateMapping(modifiedTemplateMapping);
          setOpen(false);
        }}></TemplateMappingModal>
    </>
  );
}

function mapStateToProps(state, props) {
  const sequenceId = props.match.params.sequenceId;
  return {
    templateMapping: state.templates.templatesMappings.content[sequenceId],
    sequenceId: props.match.params.sequenceId
  };
}

const mapDispatchToProps = {
  fetchTemplateMapping,
  modifyTemplateMapping,
  deleteTemplateMapping
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchTemplateMapping, sequenceId }) => fetchTemplateMapping(sequenceId),
    ready: ({ templateMapping }) => templateMapping !== undefined
  })
)(TemplateMappingDetailPage);
