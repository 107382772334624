import { BlindataApi } from './ApiUtils';

const COMMENTS_URL = '/api/v1/social/comments';

export default class CommentsApi extends BlindataApi {
  getComments(params) {
    return this.get(COMMENTS_URL, params);
  }

  getComment(sequenceId) {
    return this.get(`${COMMENTS_URL}/${sequenceId}`);
  }

  postComment(comment) {
    return this.post(COMMENTS_URL, comment);
  }

  putComment(comment) {
    return this.put(`${COMMENTS_URL}/${comment.sequenceId}`, comment);
  }

  deleteComment(sequenceId) {
    return this.delete(`${COMMENTS_URL}/${sequenceId}`);
  }
}
