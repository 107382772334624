import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useState } from 'react';
import _ from 'lodash';
import { t } from 'i18next';
import { Check } from '@mui/icons-material';

const NOTIFICATION_TYPES = [
  'PROPOSAL',
  'CAMPAIGN_ACTIVITY',
  'ISSUE',
  'DATA_CATEGORY',
  'LOGICAL_FIELD',
  'DATA_ACTOR',
  'PROCESSING',
  'TASK',
  'PHYSICAL_FIELD',
  'SYSTEM',
  'PHYSICAL_ENTITY',
  'QUALITY_SUITES',
  'QUALITY_CHECK',
  'CAMPAIGN'
];

export const NotificationsTypesSettingsCard = ({ mergedSettings, setPatchedSettings }) => {
  const [open, setOpen] = useState(false);

  const applyChanges = (notificationsType, emailImmediate) =>
    setPatchedSettings(
      _.merge({}, mergedSettings, {
        notificationsTypesSettings: _.unionBy(
          [
            {
              notificationsType: notificationsType,
              emailImmediate: emailImmediate
            }
          ],
          mergedSettings.notificationsTypesSettings,
          'notificationsType'
        )
      })
    );

  return (
    <>
      <Card variant='outlined' style={{ height: '100%' }}>
        <CardHeader
          subheader='Custom Immediate Settings'
          action={
            <Tooltip title={'Create settings'}>
              <IconButton onClick={() => setOpen(true)}>
                <AddBoxIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <CardContent>
          {mergedSettings.notificationsTypesSettings && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Resource Type</TableCell>
                  <TableCell></TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mergedSettings.notificationsTypesSettings
                  .filter((e) => e.emailImmediate)
                  .map((notificationsTypeSetting, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {notificationsTypeSetting.notificationsType.replace('_', ' ')}
                      </TableCell>
                      <TableCell>
                        <Check color='primary'></Check>
                        <Typography variant='caption'>Send email immediately</Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <IconButton
                          size='small'
                          onClick={() =>
                            applyChanges(notificationsTypeSetting.notificationsType, false)
                          }>
                          <ClearIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
      <ImmediateSettingsModal
        title={'Add new notification type'}
        open={open}
        onCancel={() => setOpen(false)}
        enums={NOTIFICATION_TYPES}
        onSubmit={(notificationsType) => applyChanges(notificationsType, true)}
      />
    </>
  );
};

function ImmediateSettingsModal({ open, enums, title, onCancel, onSubmit }) {
  const [element, setStatus] = useState(null);
  const elements = enums ? enums : [];
  return (
    <>
      <Dialog open={open} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <FormControl variant='standard' fullWidth margin='dense'>
            <InputLabel>Type</InputLabel>
            <Select
              variant='standard'
              value={element || ''}
              onChange={(event) => setStatus(event.target.value)}>
              {elements.map((element) => (
                <MenuItem key={element} value={element}>
                  {element.replace('_', ' ')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.cancel')}
            onClick={() => {
              onCancel();
              setStatus(null);
            }}></Button>
          <Button
            children={t('commons.actions.submit')}
            color='primary'
            ee
            onClick={() => {
              onSubmit(element);
              setStatus(null);
              onCancel();
            }}></Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
