import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Tooltip,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions
} from '@mui/material';
import CommentsAdder from './CommentsAdder';
import CommentsList from './CommentsList';
import ExpandableCard from '../../../components/expandable-card/ExpandableCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectCounter } from '../../../reducers/social/comments';
import { fetchCommentsPage } from '../../../actions/CommentsActions';
import { Sort } from '@mui/icons-material';
import WatchlistIconButton from '../watchlist/WatchlistIconButton';

function CommentsPanel({ resourceType, resourceIdentifier, resourceName, notExpandable }) {
  const counter = useSelector(selectCounter(resourceType, resourceIdentifier));
  const currentPage = useSelector((state) => state.social.comments.currentPage);
  const dispatch = useDispatch();
  const [isSortAsc, setIsSortAsc] = useState(false);
  useEffect(
    function () {
      dispatch(
        fetchCommentsPage({
          resourceType,
          resourceIdentifier,
          sort: computeSortStringParam(isSortAsc)
        })
      );
    },
    [resourceType, resourceIdentifier, isSortAsc, dispatch]
  );
  function onToggleSort() {
    const newIsSortAsc = !isSortAsc;
    setIsSortAsc(newIsSortAsc);
  }
  return !notExpandable ? (
    <ExpandableCard
      title={`Comments`}
      titleTypographyProps={{}}
      subheader={counter > 0 ? `${counter} comments` : ''}
      decorator={
        <Grid container>
          <Grid item>
            <WatchlistIconButton
              entityIdentifier={resourceIdentifier}
              entityType={resourceType}></WatchlistIconButton>
          </Grid>
          <Grid item>
            <Tooltip title={isSortAsc ? 'Oldest first' : 'Newest first'}>
              <IconButton
                onClick={(event) => {
                  onToggleSort();
                  event.stopPropagation();
                }}
                size='large'>
                <Sort
                  style={{
                    transitionDuration: '1s',
                    transitionProperty: 'transform',
                    transform: !isSortAsc && 'rotateX(180deg)'
                  }}></Sort>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      }
      actions={
        <CommentsAdder
          {...{
            resourceType,
            resourceName,
            resourceIdentifier
          }}
        />
      }>
      <CommentsList
        {...{
          resourceType,
          resourceIdentifier,
          currentPage,
          onPageSelection: (page) => {
            dispatch(
              fetchCommentsPage({
                resourceType,
                resourceIdentifier,
                page,
                sort: computeSortStringParam(isSortAsc)
              })
            );
          }
        }}></CommentsList>
    </ExpandableCard>
  ) : (
    <Card titleTypographyProps={{}} subheader={counter > 0 ? `${counter} comments` : ''}>
      <CardHeader
        title={`Comments`}
        action={
          <Grid container>
            <Grid item>
              <WatchlistIconButton
                entityIdentifier={resourceIdentifier}
                entityType={resourceType}></WatchlistIconButton>
            </Grid>
            <Grid item>
              <Tooltip title={isSortAsc ? 'Oldest first' : 'Newest first'}>
                <IconButton
                  onClick={(event) => {
                    onToggleSort();
                    event.stopPropagation();
                  }}
                  size='large'>
                  <Sort
                    style={{
                      transitionDuration: '1s',
                      transitionProperty: 'transform',
                      transform: !isSortAsc && 'rotateX(180deg)'
                    }}></Sort>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        }></CardHeader>
      <CardContent>
        <CommentsList
          {...{
            resourceType,
            resourceIdentifier,
            currentPage,
            onPageSelection: (page) => {
              dispatch(
                fetchCommentsPage({
                  resourceType,
                  resourceIdentifier,
                  page,
                  sort: computeSortStringParam(isSortAsc)
                })
              );
            }
          }}></CommentsList>
      </CardContent>
      <CardActions>
        {' '}
        <CommentsAdder
          {...{
            resourceType,
            resourceName,
            resourceIdentifier
          }}
        />
      </CardActions>
    </Card>
  );

  function computeSortStringParam(isAscending) {
    return isAscending ? 'createdAt,asc' : 'createdAt,desc';
  }
}

CommentsPanel.propTypes = {
  resourceIdentifier: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired
};

export default CommentsPanel;
