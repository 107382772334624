import React from 'react';
import { useTranslation } from 'react-i18next';

import Permissions from 'constants/Permissions';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import { setIssuesFilters } from 'actions/IssuesActions';
import { Typography, Grid } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import Authorize from 'components/permissions/Authorize';
import MyIssuesList from 'pages/issueMngt/myWork/MyIssuesList';
import MyIssueFilters from './MyIssueFilters';
import { connect } from 'react-redux';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const MyWorkPage = ({ filters, setIssuesFilters }) => {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('navbar.issueMngt'), to: '/issue-management/my-work' },
          { text: t('navbar.myWork') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('issueMngt.myWork.header'),
          category: EngagementCategories.ISSUES,
          action: EngagementActions.LIST
        }}
      />
      <Grid container justifyContent='flex-start' alignItems='center'>
        <Grid item>
          <Typography variant='h4'>{t('issueMngt.myWork.header')}</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:mywork-list'}
            iconSize={'small'}
            pageTitle={t('issueMngt.myWork.header')}
          />
        </Grid>
        <Grid item>
          <MyIssueFilters
            filters={filters}
            onChange={(filters) => {
              setIssuesFilters({ statuses: filters.status });
            }}
          />
        </Grid>
      </Grid>
      <Authorize hasPermissions={[Permissions.CAMPAIGNS_VIEWER]}>
        <MyIssuesList />
      </Authorize>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  filters: state.campaigns.issues.filters.content
});

export default connect(mapStateToProps, { setIssuesFilters })(MyWorkPage);
