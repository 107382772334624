import { handleErrors, parseJson, BlindataApi } from './ApiUtils';

export default class UsersApi extends BlindataApi {
  token = '';

  getUsers(params) {
    return this.get('/api/v1/users', params);
  }

  getUser(uuid) {
    return fetch(`/api/v1/users/${uuid}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson);
  }

  patchUser(user) {
    return fetch(`/api/v1/users/${user.uuid}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(user)
    })
      .then(handleErrors)
      .then(parseJson);
  }

  deleteUser(user) {
    return fetch(`/api/v1/users/${user.uuid}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    }).then(handleErrors);
  }

  postUser(user) {
    return fetch(`/api/v1/users`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(user)
    })
      .then(handleErrors)
      .then(parseJson);
  }

  getUserTenants(uuid, params) {
    return this.get(`/api/v1/users/${uuid}/tenants`, params);
  }

  putUserToTenant(userUuid, tenantUuid) {
    return fetch(`/api/v1/users/${userUuid}/tenants/${tenantUuid}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    }).then(handleErrors);
  }

  deleteUserFromTenant(userUuid, tenantUuid) {
    return fetch(`/api/v1/users/${userUuid}/tenants/${tenantUuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    }).then(handleErrors);
  }

  getUserDefaultTenant(userUuid) {
    return fetch(`/api/v1/users/${userUuid}/defaultTenant`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson);
  }

  putUserDefaultTenant(userUuid, tenantUuid) {
    return fetch(`/api/v1/users/${userUuid}/defaultTenant/${tenantUuid}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    }).then(handleErrors);
  }

  getTenantPermissions({ userUuid, tenantUuid }) {
    return this.get(`/api/v1/users/${userUuid}/tenants/${tenantUuid}/permissions`);
  }

  putTenantPermissions({ userUuid, tenantUuid, permissions }) {
    return this.put(`/api/v1/users/${userUuid}/tenants/${tenantUuid}/permissions`, {
      userUuid,
      tenantUuid,
      permissions
    });
  }

  getTeamSettings(userUuid) {
    return this.get(`/api/v1/users/${userUuid}/team-settings`);
  }

  putTeamSettings(userUuid, teamSettings) {
    return this.put(`/api/v1/users/${userUuid}/team-settings`, teamSettings);
  }

  getUsersInCurrentTenant(search) {
    return this.get(`/api/v1/users/*/list`, search);
  }
}
