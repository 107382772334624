import React from 'react';
import { connect } from 'react-redux';

import { searchCampaigns } from 'actions/CampaignActions';

import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';

const CampaignItemAutoComplete = ({ values, value, searchCampaigns, handleChange }) => {
  return (
    <ItemAutoComplete
      itemName='Campaign'
      values={values}
      value={value}
      nameProperty={'name'}
      onSearch={(inputValue) => searchCampaigns({ search: inputValue })}
      onChange={(newValue) => handleChange(newValue)}
    />
  );
};

const mapStateToProps = (state, props) => ({
  values: Object.values(state.campaigns.campaigns.content)
});

const mapDispatchToProps = {
  searchCampaigns
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignItemAutoComplete);
