import React from 'react';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import SupervisedUserCircleSharpIcon from '@mui/icons-material/SupervisedUserCircleSharp';
import { Chip } from '@mui/material';

export default function UserChip({ label, size }) {
  return createChip(label, AccountCircleSharpIcon, size);
}

function createChip(label, Icon, size) {
  return (
    <Chip
      style={{ margin: 2 }}
      size={size || 'small'}
      color='default'
      icon={<Icon />}
      label={<div style={{ color: 'black' }}>{label}</div>}></Chip>
  );
}

export function TeamChip({ label, size }) {
  return createChip(label, SupervisedUserCircleSharpIcon, size);
}
