export const ResourceTypes = {
  /**
   * Logical Model
   */
  DATA_CATEGORY: 'DATA_CATEGORY',
  LOGICAL_FIELD: 'LOGICAL_FIELD',
  LOGICAL_RELATION: 'LOGICAL_RELATION',
  /**
   * Processing Registry
   * */
  DATA_ACTOR: 'DATA_ACTOR',
  PROCESSING: 'PROCESSING',
  TASK_ASSIGNMENT: 'TASK_ASSIGNMENT',
  TASK: 'TASK',
  /**
   * Physical Model
   */
  SYSTEM: 'SYSTEM',
  PHYSICAL_ENTITY: 'PHYSICAL_ENTITY',
  PHYSICAL_FIELD: 'PHYSICAL_FIELD',
  SYSTEM_ROUTINE: 'SYSTEM_ROUTINE',
  PHYSICAL_CONSTRAINT: 'PHYSICAL_CONSTRAINT',

  /**
   * Data Quality
   */
  QUALITY_CHECK: 'QUALITY_CHECK',
  QUALITY_SUITE: 'QUALITY_SUITE',
  QUALITY_RESULT: 'QUALITY_RESULT',
  QUALITY_PROBE_DEFINITION: 'QUALITY_PROBE_DEFINITION',
  QUALITY_PROBE_PROJECT: 'QUALITY_PROBE_PROJECT',

  /**
   * Issue Management
   */
  ISSUE: 'ISSUE',

  /**
   * Query Parser
   */
  QUERY_STATEMENT: 'QUERY_STATEMENT',
  QUERY_BUCKET: 'QUERY_BUCKET',

  /**
   * Consents
   */
  CONTRACT: 'CONTRACT',

  /**
   * Data Mesh
   */
  DATA_PRODUCT: 'DATA_PRODUCT',
  DATA_PRODUCT_PORT: 'DATA_PRODUCT_PORT'
};

export const AdminPermissionsByResourceTypes = {
  DATA_CATEGORY: 'DATA_CATEGORIES_ADMIN',
  LOGICAL_FIELD: 'DATA_CATEGORIES_ADMIN',
  LOGICAL_RELATION: 'DATA_CATEGORIES_ADMIN',
  DATA_ACTOR: 'DATA_ACTORS_ADMIN',
  PROCESSING: 'PROCESSINGS_ADMIN',
  TASK_ASSIGNMENT: 'TASKS_ADMIN',
  TASK: 'TASKS_ADMIN',
  SYSTEM: 'SYSTEMS_ADMIN',
  PHYSICAL_ENTITY: 'SYSTEMS_ADMIN',
  PHYSICAL_FIELD: 'SYSTEMS_ADMIN',
  SYSTEM_ROUTINE: 'SYSTEMS_ADMIN',
  PHYSICAL_CONSTRAINT: 'SYSTEMS_ADMIN',
  QUALITY_CHECK: 'QUALITY_ADMIN',
  QUALITY_SUITE: 'QUALITY_ADMIN',
  QUALITY_RESULT: 'QUALITY_ADMIN',
  QUALITY_PROBE_DEFINITION: 'QUALITY_ADMIN',
  QUALITY_PROBE_PROJECT: 'QUALITY_ADMIN',
  ISSUE: 'CAMPAIGNS_ADMIN',
  QUERY_STATEMENT: 'QUERY_PARSER_ADMIN',
  QUERY_BUCKET: 'QUERY_PARSER_ADMIN',
  CONTRACT: 'CONTRACTS_ADMIN',
  DATA_PRODUCT: 'DATA_PRODUCTS_ADMIN',
  DATA_PRODUCT_PORT: 'DATA_PRODUCTS_ADMIN'
};

export const ViewerPermissionsByResourceTypes = {
  DATA_CATEGORY: 'DATA_CATEGORIES_VIEWER',
  LOGICAL_FIELD: 'DATA_CATEGORIES_VIEWER',
  LOGICAL_RELATION: 'DATA_CATEGORIES_VIEWER',
  DATA_ACTOR: 'DATA_ACTORS_VIEWER',
  PROCESSING: 'PROCESSINGS_VIEWER',
  TASK_ASSIGNMENT: 'TASKS_VIEWER',
  TASK: 'TASKS_VIEWER',
  SYSTEM: 'SYSTEMS_VIEWER',
  PHYSICAL_ENTITY: 'SYSTEMS_VIEWER',
  PHYSICAL_FIELD: 'SYSTEMS_VIEWER',
  SYSTEM_ROUTINE: 'SYSTEMS_VIEWER',
  PHYSICAL_CONSTRAINT: 'SYSTEMS_VIEWER',
  QUALITY_CHECK: 'QUALITY_VIEWER',
  QUALITY_SUITE: 'QUALITY_VIEWER',
  QUALITY_RESULT: 'QUALITY_VIEWER',
  QUALITY_PROBE_DEFINITION: 'QUALITY_VIEWER',
  QUALITY_PROBE_PROJECT: 'QUALITY_VIEWER',
  ISSUE: 'CAMPAIGNS_VIEWER',
  QUERY_STATEMENT: 'QUERY_PARSER_VIEWER',
  QUERY_BUCKET: 'QUERY_PARSER_VIEWER',
  CONTRACT: 'CONTRACTS_VIEWER',
  DATA_PRODUCT: 'DATA_PRODUCTS_VIEWER',
  DATA_PRODUCT_PORT: 'DATA_PRODUCTS_VIEWER'
};

export default ResourceTypes;
