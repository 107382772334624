import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import TextFieldsListEditor from '../../../components/list_editors/TextFieldsListEditor';
import _ from 'lodash';
import { Typography, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import AdditionalPropertiesEditor from '../../../components/additional_properties/AdditionalPropertiesEditor';
import AccountTeamSelector, {
  ACCESS_LEVELS
} from '../../settings/profile/utils/AccountTeamSelector';
import ResourceTypes from '../../../constants/ResourceTypes';
import DataCategoryClassAutocomplete from '../detail/DataCategoryClassAutocomplete';
import DataCategoryClearanceSelect from '../detail/DataCategoryClearanceSelect';

const initialState = {
  uuid: undefined,
  name: undefined,
  description: undefined,
  clearance: 'COMMON',
  dataClass: '',
  dataSubjectsCategories: [],
  teamCode: null
};

class DataCategoryCreateModal extends Component {
  constructor(props) {
    super(props);
    let dataCategory = props.dataCategory;
    this.state = dataCategory ? { ...dataCategory } : initialState;
    const { t } = this.props;
    this.dataClassSuggestions = t('suggestion:dataCategoryClasses', {
      returnObjects: true
    });
    this.dataSubjectsCategoriesSuggestions = t('suggestion:interestedDataSubjectsCategories', {
      returnObjects: true
    });
    this.dataSubjectsCategoriesSuggestions.all = _(this.dataSubjectsCategoriesSuggestions)
      .flatMap()
      .uniqBy()
      .value();
  }

  handleChangeClearance(clearance) {
    this.setState({ clearance });
  }

  render() {
    const { t, fullScreen } = this.props;

    return (
      <Dialog title={this.props.title} open={this.props.open} fullWidth fullScreen={fullScreen}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1'>{t('data_categories.general_info_header')}</Typography>

          <TextField
            variant='standard'
            id='datacategory-name-textfield'
            fullWidth
            margin='dense'
            placeholder={t('data_categories.fields.nameHint')}
            label={t('data_categories.fields.name')}
            required
            value={this.state.name || ''}
            onChange={(event) => this.setState({ name: event.target.value })}
          />
          <br />

          <DataCategoryClassAutocomplete
            fullWidth
            margin='dense'
            dataClass={this.state.dataClass}
            onChange={(newValue) => this.setState({ dataClass: newValue })}
          />

          <TextField
            variant='standard'
            multiline
            fullWidth
            margin='dense'
            placeholder={t('data_categories.fields.descriptionHint')}
            label={t('data_categories.fields.description')}
            value={this.state.description || ''}
            onChange={(event) => this.setState({ description: event.target.value || null })}
          />
          <br />
          <DataCategoryClearanceSelect
            value={this.state.clearance}
            onChange={(newClearance) => this.handleChangeClearance(newClearance)}
          />
          <TextFieldsListEditor
            headerTitle={t('data_categories.fields.dataSubjectsCategories.header')}
            addLabel={t('data_categories.actions.addDataSubjectCategory')}
            items={this.state.dataSubjectsCategories}
            onAdd={(dsc) =>
              this.setState({
                dataSubjectsCategories: this.state.dataSubjectsCategories.concat([dsc])
              })
            }
            onDelete={(dsc) =>
              this.setState({
                dataSubjectsCategories: this.state.dataSubjectsCategories.filter((e) => e !== dsc)
              })
            }
            textFields={[
              {
                hintText: t('data_categories.fields.dataSubjectsCategories.fields.nameHint'),
                floatingLabelText: t('data_categories.fields.dataSubjectsCategories.fields.name'),
                property: 'name',
                suggestions:
                  this.dataSubjectsCategoriesSuggestions[this.state.dataClass] ||
                  this.dataSubjectsCategoriesSuggestions.all ||
                  [],
                enabler: true
              },
              {
                hintText: t('data_categories.fields.dataSubjectsCategories.fields.descriptionHint'),
                floatingLabelText: t(
                  'data_categories.fields.dataSubjectsCategories.fields.description'
                ),
                property: 'description'
              }
            ]}
          />
          <AdditionalPropertiesEditor
            additionalProperties={this.state.additionalProperties || []}
            resourceType={ResourceTypes.DATA_CATEGORY}
            onChange={(additionalProperties) => this.setState({ additionalProperties })}
          />
        </DialogContent>
        <DialogActions>
          <AccountTeamSelector
            accessLevel={ACCESS_LEVELS.WRITE}
            accessLevel2={ACCESS_LEVELS.PROPOSE}
            selectedTeamCode={this.state.teamCode || ''}
            onChange={(code) => this.setState({ teamCode: code })}
          />
          <div style={{ flexGrow: 1 }} />
          <Button
            children={t('data_categories.actions.cancel')}
            onClick={this.props.handleCancel}
          />
          <Button
            id='datacategory-save-button'
            children={t('data_categories.actions.submit')}
            variant='contained'
            color='primary'
            disabled={this.state.name === undefined}
            onClick={() => this.props.handleSubmit(this.state)}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DataCategoryCreateModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  clearance: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default _.flow([withTranslation()])(DataCategoryCreateModal);
