import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { AddFAB } from '../../../../components/fab';
import Loader from '../../../../components/loader/Loader';
import {
  fetchContractConfigurationsPage,
  deleteContractConfiguration
} from '../../../../actions/ContractsActions';
import Authorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';
import { ConfigurationsList } from './ConfigurationsList';
import ContractsNavbar from '../../ContractsNavbar';
import { Grid, Typography } from '@mui/material';
import Filters from './Filters';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
const ConfigurationsPage = ({
  page,
  fetchContractConfigurationsPage,
  deleteContractConfiguration,
  history,
  t
}) => (
  <Loader onLoad={() => fetchContractConfigurationsPage(0)}>
    <React.Fragment>
      <Breadcrumbs
        elements={[
          { text: 'Contracts', to: '/contracts' },
          { text: 'Configurations', to: '/contracts/configurations' }
        ]}
      />
      <Tracking
        info={{
          pageTitle: `Contract Configurations`,
          category: EngagementCategories.CONSENTS,
          action: EngagementActions.LIST
        }}
      />

      <ContractsNavbar />
      <Grid container justifyContent='flex-start' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Contracts Configurations</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:configurations-list'}
            pageTitle={`Contract Configurations`}
          />
        </Grid>
        <Grid item>
          <Filters onRefresh={() => fetchContractConfigurationsPage(0)} />
        </Grid>
      </Grid>
      <ConfigurationsList
        {...{
          page,
          fetchContractConfigurationsPage,
          deleteContractConfiguration,
          t
        }}
      />
      <Authorize hasPermissions={[Permissions.CONTRACTS_ADMIN]}>
        <AddFAB onClick={() => history.push('/contracts/configurations/editor/create')} />
      </Authorize>
    </React.Fragment>
  </Loader>
);

const mapStateToProps = (state, props) => ({
  page: state.contracts.configurations.currentPage
});

export default _.flow([
  withTranslation(),
  connect(mapStateToProps, { fetchContractConfigurationsPage, deleteContractConfiguration })
])(ConfigurationsPage);
