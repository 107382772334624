import React from 'react';
import PaginatedPanel from '../../../../components/pagination/PaginatedPanel';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchRolesPage } from '../../../../actions/StewardshipActions';
import TeamCodeDecorator from '../../../settings/teams/common/TeamCodeDecorator';
function StewardshipRolesList({ currentPage, onPageSelection }) {
  return (
    <PaginatedPanel autoload onPageSelection={onPageSelection} currentPage={currentPage}>
      <>
        {currentPage.content.map((item) => (
          <StewardshipRoleListItem stewardshipRole={item} />
        ))}
      </>
    </PaginatedPanel>
  );
}

function StewardshipRoleListItem({ stewardshipRole }) {
  const { t } = useTranslation();
  return (
    <ExpandableCard
      title={stewardshipRole.name}
      subheader={stewardshipRole.code}
      actions={[
        <Button
          children={t('commons.actions.detail')}
          component={Link}
          to={`/stewardship/roles/${stewardshipRole.uuid}`}
        />
      ]}
      decorator={
        stewardshipRole && (
          <TeamCodeDecorator teamCode={stewardshipRole.teamCode}></TeamCodeDecorator>
        )
      }>
      {stewardshipRole.description}
    </ExpandableCard>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.stewardship.roles.currentPage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onPageSelection: (page) => dispatch(fetchRolesPage({ page }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StewardshipRolesList);
