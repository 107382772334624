import React from 'react';

import Typography from '@mui/material/Typography';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const styles = {
  decorator: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: 3,
    borderColor: 'grey',
    boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
    padding: '4px',
    color: 'grey'
  }
};

export const VisibilityDecorator = () => {
  return <VisibilityOffIcon style={{ color: 'grey' }} />;
};

export const DataSetDecorator = ({ dataSet }) => {
  return (
    <Typography variant='overline' sx={styles.decorator}>
      {dataSet}
    </Typography>
  );
};
