import { buildApiCallAction } from '../ActionUtils';
import { EXECUTE_IMPACT_ANALYSIS, EXPORT_IMPACT_ANALYSIS } from 'constants/ActionTypes';
import DataFlowsImpactAnalysisApi from 'api/DataFlowsImpactAnalysisApi';

const api = new DataFlowsImpactAnalysisApi();

export const executeAnalysis = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postImpactAnalysis(generateApiParams(params)),
    actionType: EXECUTE_IMPACT_ANALYSIS
  });

export const exportAnalysis = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportImpactAnalysis(generateApiParams(params)),
    actionType: EXPORT_IMPACT_ANALYSIS
  });

function generateApiParams(params) {
  return {
    logicalFieldUuids: params?.logicalFields && params?.logicalFields.map((lf) => lf.uuid),
    dataCategoryUuids: params?.dataCategories && params?.dataCategories.map((dc) => dc.uuid),
    physicalEntityUuids: params?.physicalEntities && params?.physicalEntities.map((pe) => pe.uuid),
    physicalFieldUuids: params?.physicalFields && params?.physicalFields.map((pf) => pf.uuid),
    endingAssetsFiltersSystemNames: params?.endingAssetsFilters?.system,
    endingAssetsFiltersSchemaNames: params?.endingAssetsFilters?.schema,
    endingAssetsFiltersPhysicalEntityNames: params?.endingAssetsFilters?.physicalEntity,
    endingAssetsFiltersPhysicalFieldNames: params?.endingAssetsFilters?.physicalField,
    maxDepth: params?.maxDepth,
    columnLevel: params?.columnLevel
  };
}
