import { buildApiCallAction } from './ActionUtils';
import AssignmentsApi from '../api/AssignmentsApi';
import {
  CLEAR_ASSIGNMENTS_FILTERS,
  CREATE_ASSIGNMENT,
  DELETE_ASSIGNMENT,
  FETCH_ASSIGNMENT,
  FETCH_ASSIGNMENTS_PAGE,
  MODIFY_ASSIGNMENT,
  SET_ASSIGNMENTS_FILTERS,
  EXPORT_CLASSIFICATION_ASSIGNMENTS
} from '../constants/ActionTypes';

const api = new AssignmentsApi();

export const fetchAssignmentsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAllAssignments(params),
    actionType: FETCH_ASSIGNMENTS_PAGE
  });

export const fetchAssignment = (sequenceId) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAssignment(sequenceId),
    actionType: FETCH_ASSIGNMENT
  });

export const createAssignment = (assignment) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postAssignment(assignment),
    actionType: CREATE_ASSIGNMENT
  });

export const modifyAssignment = (assignment) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putAssignment(assignment),
    actionType: MODIFY_ASSIGNMENT
  });

export const deleteAssignment = (assignment) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteAssignment(assignment),
    actionType: DELETE_ASSIGNMENT
  });

export const setAssignmentsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_ASSIGNMENTS_FILTERS, payload: filters });
};

export const clearAssignmentsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_ASSIGNMENTS_FILTERS });
};

export const patchAssignment = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.patchAssignment(params),
    actionType: MODIFY_ASSIGNMENT
  });

export const exportAssignments = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportAssignments(params),
    actionType: EXPORT_CLASSIFICATION_ASSIGNMENTS
  });
