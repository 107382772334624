import React from 'react';
import { Tabs, Tab } from '@mui/material';

import { ProposalStatus } from '../commons/ProposalConstants';

function ProposalStatusSwitcher({ filters = {}, onChange }) {
  let approverPerspective = filters.approverPerspective;
  let proposalStates = filters.proposalStates || [];
  let switcherValue = -1;
  if (proposalStates.includes(ProposalStatus.DRAFT)) {
    switcherValue = 0;
  } else if (proposalStates.includes(ProposalStatus.SUBMITTED)) {
    switcherValue = 1;
  } else if (proposalStates.includes(ProposalStatus.APPROVED)) {
    switcherValue = 2;
  }

  return (
    <Tabs
      variant='fullWidth'
      indicatorColor='primary'
      sx={{
        flexGrow: 1,
        justifyContent: ''
      }}
      value={switcherValue}
      onChange={(event, newValue) =>
        onChange({
          proposalStates: tabsConfigStates[newValue]
        })
      }>
      {!approverPerspective && <Tab value={0} label='DRAFT' {...a11yProps(0)} />}
      <Tab value={1} label='PENDING' {...a11yProps(1)} />
      <Tab value={2} label='COMPLETED' {...a11yProps(2)} />
    </Tabs>
  );
}

const tabsConfigStates = {
  0: [ProposalStatus.DRAFT],
  1: [ProposalStatus.SUBMITTED, ProposalStatus.ACKNOWLEDGED],
  2: [
    ProposalStatus.APPROVED,
    ProposalStatus.CANCELED,
    ProposalStatus.REFUSED,
    ProposalStatus.ERROR
  ]
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default ProposalStatusSwitcher;
