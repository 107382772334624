import React from 'react';
import { IconButton, Grid, Tooltip } from '@mui/material';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function ClassificationAssignmentsFiltersState({ filters, onChange }) {
  let confirmedIsClicked = filters.assignmentState.includes('CONFIRMED');
  let pendingIsClicked = filters.assignmentState.includes('PENDING');
  let refusedIsClicked = filters.assignmentState.includes('REFUSED');

  return (
    <>
      <Grid container direction='row' alignItems='center'>
        <Grid item>
          <Tooltip title='Show confirmed'>
            <IconButton
              aria-label='confirmed'
              size='medium'
              color={confirmedIsClicked ? 'primary' : 'grey'}
              onClick={() => {
                onChange({
                  ...filters,
                  assignmentState: !confirmedIsClicked
                    ? filters.assignmentState.concat(['CONFIRMED'])
                    : filters.assignmentState.filter((state) => state !== 'CONFIRMED')
                });
              }}>
              <ThumbUpAltIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title='Show pending'>
            <IconButton
              aria-label='pending'
              size='medium'
              color={pendingIsClicked ? 'primary' : 'grey'}
              onClick={() => {
                onChange({
                  ...filters,
                  assignmentState: !pendingIsClicked
                    ? filters.assignmentState.concat(['PENDING'])
                    : filters.assignmentState.filter((state) => state !== 'PENDING')
                });
              }}>
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title='Show refused'>
            <IconButton
              aria-label='refused'
              size='medium'
              color={refusedIsClicked ? 'primary' : 'grey'}
              onClick={() => {
                onChange({
                  ...filters,
                  assignmentState: !refusedIsClicked
                    ? filters.assignmentState.concat(['REFUSED'])
                    : filters.assignmentState.filter((state) => state !== 'REFUSED')
                });
              }}>
              <ThumbDownAltIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}

export default ClassificationAssignmentsFiltersState;
