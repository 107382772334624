import React from 'react';
import { Tooltip } from '@mui/material';
import ConfirmIconButton from 'components/confirmbutton/ConfirmIconButton';
import UndoIcon from '@mui/icons-material/Undo';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { deleteDataFlowPreviews, fetchQueryStatement } from 'actions/QueryStatementsActions';

const UndoDataFlowPreviewsInsertButton = ({ queryStatement }) => {
  const dispatch = useDispatch();
  return (
    <ConfirmIconButton
      size='small'
      onClick={() => {
        dispatch(deleteDataFlowPreviews(queryStatement.uuid)).then(() =>
          dispatch(fetchQueryStatement(queryStatement.uuid))
        );
      }}
      message={t('querystatements.dataFlowsPreview.card.confirmMessageDeletePreviews')}>
      <Tooltip title={t('querystatements.dataFlowsPreview.card.deleteButton')}>
        <UndoIcon />
      </Tooltip>
    </ConfirmIconButton>
  );
};

export default UndoDataFlowPreviewsInsertButton;
