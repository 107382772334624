import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from '@mui/material';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import React, { useState } from 'react';
import {
  deleteQueryStatement,
  modifyQueryStatement,
  refreshStatement,
  fetchQueryStatementEndingNodes
} from 'actions/QueryStatementsActions';
import { connect } from 'react-redux';
import QueryStatementModal from '../commons/QueryStatementModal';
import { useHistory } from 'react-router';
import SqlVisualizer from 'components/syntaxhighlithter/SqlVisualizer';
import CardDescription from 'components/carddescription/CardDescription';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import QueryStatementStatusDecorator from '../commons/QueryStatementStatusDecorator';
import QueryStatementTargetTableModal from '../commons/QueryStatementTargetTableModal';
import { t } from 'i18next';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import { HelpIcon } from 'components/help';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const bodyStyle = {
  border: '1px solid',
  borderColor: 'lightgrey',
  borderRadius: '0.5em',
  padding: '1em'
};

const QueryStatementDetailSection = ({
  queryStatement,
  deleteQueryStatement,
  modifyQueryStatement,
  onRefresh,
  refreshStatement,
  fetchQueryStatementEndingNodes,
  endingNodes
}) => {
  const [openQueryStatementModal, setOpenQueryStatementModal] = useState(false);
  const [openTargetTableModal, setOpenTargetTableModal] = useState(false);
  const history = useHistory();

  const handleOpenTargetTableModal = () => {
    setOpenTargetTableModal(true);
    fetchQueryStatementEndingNodes(queryStatement.uuid);
  };

  if (!queryStatement) return null;
  return (
    <Card key={queryStatement.uuid} style={{ margin: '8px 0 8px 0' }}>
      <CardHeader
        action={<QueryStatementStatusDecorator queryStatement={queryStatement} />}
        title={queryStatement?.name}
        subheader={getErrorMessage(queryStatement, t)}
      />

      <CardContent>
        <CardDescription>{queryStatement?.description}</CardDescription>
        <KVDefinitionList
          header='General'
          item={{
            Type: queryStatement?.queryType,
            Schema: queryStatement?.schema,
            Analysis: queryStatement?.analysisStatus || queryStatement?.elaborationStatus,
            'Created At': formatDate(queryStatement?.createdAt),
            'Updated At': formatDate(queryStatement?.updatedAt)
          }}></KVDefinitionList>
        <TargetTableWarning
          queryStatement={queryStatement}
          handleOpenTargetTableModal={handleOpenTargetTableModal}
        />
        <h3>
          {t('querystatements.datail.query')}
          <GoToAssociatedRoutine
            history={history}
            systemRoutineUuid={queryStatement.systemRoutineUuid}
          />
        </h3>
        <SqlVisualizer text={queryStatement.queryText} style={bodyStyle} />
      </CardContent>
      <CardActions>
        <Authorize hasPermissions={[Permissions.QUERY_PARSER_EDITOR]}>
          <Button onClick={() => setOpenQueryStatementModal(true)}>
            {t('querystatements.actions.modify')}
          </Button>
          <ConfirmButton
            message={t('querystatements.delete_confirmation', {
              querySatementName: queryStatement.name
            })}
            children={t('querystatements.actions.delete')}
            onClick={() => {
              deleteQueryStatement(queryStatement);
              history.goBack();
            }}
          />
          <ConfirmButton
            message={'Do you want to ignore this statement?'}
            children={'Ignore'}
            onClick={() =>
              modifyQueryStatement({ ...queryStatement, elaborationStatus: 'IGNORED' })
            }></ConfirmButton>
          <ConfirmButton
            message={
              'Do you want to refresh this statement? This will reset data flows and connected target tables.'
            }
            onClick={() => refreshStatement(queryStatement.uuid).then(() => onRefresh())}>
            Refresh
          </ConfirmButton>
        </Authorize>
        <QueryStatementModal
          title={t('querystatements.modify_title')}
          open={openQueryStatementModal}
          queryStatement={queryStatement}
          onClose={() => {
            setOpenQueryStatementModal(false);
          }}
          onSubmit={(mergedQueryStatementData) => {
            setOpenQueryStatementModal(false);
            modifyQueryStatement(mergedQueryStatementData);
          }}
        />
        <QueryStatementTargetTableModal
          title={t('querystatements.targetTableModal.title')}
          key={endingNodes}
          queryStatement={queryStatement}
          endingNodes={endingNodes}
          open={openTargetTableModal}
          onClose={() => {
            setOpenTargetTableModal(false);
          }}
          onSubmit={() => {
            setOpenTargetTableModal(false);
          }}
        />
      </CardActions>
    </Card>
  );
};

function GoToAssociatedRoutine({ systemRoutineUuid, history }) {
  if (!systemRoutineUuid) return <></>;
  return (
    <Button
      variant='outlined'
      style={{ marginLeft: '16px' }}
      endIcon={<OpenInNewIcon />}
      onClick={() => history.push(`/routines/${systemRoutineUuid}`)}>
      Routine
    </Button>
  );
}
function getErrorMessage(queryStatement, t) {
  return queryStatement.elaborationStatus === 'ERROR' ? (
    <>
      <br></br>
      {t('querystatements.datail.errorMessage')}
      <b>
        {queryStatement?.displayErrorMessage}
        <HelpIcon tooltip={queryStatement?.errorMessage} />
      </b>
      <br></br>
      {t('querystatements.datail.errorType')}
      <b>{queryStatement?.errorType}</b>
    </>
  ) : (
    <></>
  );
}

function formatDate(timestamp) {
  if (timestamp) {
    let date = new Date(timestamp);
    return date.toLocaleString();
  } else {
    return null;
  }
}

const TargetTableWarning = ({ queryStatement, handleOpenTargetTableModal }) => {
  if (
    (queryStatement.queryType !== 'SELECT' && queryStatement.queryType !== 'SELECT_INTO') ||
    queryStatement.connectedToTarget
  )
    return null;
  return (
    <Grid
      container
      md={6}
      style={{
        border: '1px solid',
        borderColor: '#e9a227',
        borderRadius: '0.5em',
        padding: '1em'
      }}>
      <Grid
        item
        md={11}
        style={{
          padding: '0.5em'
        }}>
        <Typography>
          {t('querystatements.datail.targetTableWarning', { queryType: queryStatement.queryType })}
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Button
          onClick={() => handleOpenTargetTableModal()}
          size='small'
          style={{
            padding: '0.5em'
          }}>
          Add
        </Button>
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state, props) {
  return {
    endingNodes: state.dataflows.querystatements.endingNodes[props.queryStatement.uuid]
  };
}

export default connect(mapStateToProps, {
  modifyQueryStatement,
  deleteQueryStatement,
  refreshStatement,
  fetchQueryStatementEndingNodes
})(QueryStatementDetailSection);
