import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';
import { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

function TenantSelectModal({
  open,
  handleCancel,
  handleSubmit,
  associatedTenants,
  selectedTenant
}) {
  const [value, setValue] = useState(selectedTenant || null);
  const { t } = useTranslation();

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle children={'Select Tenant'}></DialogTitle>
      <DialogContent>
        <ItemAutoComplete
          itemName='Tenant'
          values={associatedTenants}
          value={value}
          showAllOptions
          nameProperty={'name'}
          onSearch={(inputValue) => associatedTenants.filter((ten) => ten.name.match(inputValue))}
          onChange={(newValue) => setValue(newValue)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} children={t('commons.actions.cancel')} />
        <Button
          children={t('commons.actions.submit')}
          onClick={() => {
            handleSubmit(value);
            setValue(null);
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state, props) => ({
  associatedTenants: _.get(state, 'settings.profile.associatedTenants')
});

export default connect(mapStateToProps, null)(TenantSelectModal);
