import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChipWrapper, ChipItem } from '../../../../components/chiplist/ChipList';
import TaskAssignmentModal from '../../../tasks/detail/TaskAssignmentModal';

export default function TemplateTaskAssignmentEditor({
  onChange,
  taskAssignments = [],
  templateTask
}) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const onToggleOpen = () => setOpen(!open);

  return (
    <>
      <h4>{t('tasks.sections.associatedDataProcessors')}</h4>
      <ChipWrapper onRequestAdd={onChange ? onToggleOpen : undefined}>
        {(taskAssignments || []).map((taskAssignment, index) => (
          <ChipItem
            key={index}
            id={index}
            label={taskAssignment.content.dataActorName}
            tooltip={
              'role: ' + taskAssignment.content.role + ' notes: ' + taskAssignment.content.notes
            }
            onRequestDelete={
              onChange && (() => onChange(taskAssignments.filter((e) => e !== taskAssignment)))
            }
          />
        ))}
      </ChipWrapper>
      {onChange && (
        <TaskAssignmentModal
          open={open}
          task={templateTask.content}
          handleCancel={onToggleOpen}
          handleSubmit={(taskAssignment) => {
            onToggleOpen();
            onChange(
              taskAssignments.concat([
                {
                  content: taskAssignment
                }
              ])
            );
          }}
        />
      )}
    </>
  );
}
