import { BlindataApi } from './ApiUtils';

const POLICIES_URL = '/api/v1/issue-management/policies';

export default class PoliciesApi extends BlindataApi {
  getPolicies(params) {
    return this.get(POLICIES_URL, params);
  }

  getPolicy(uuid) {
    return this.get(`${POLICIES_URL}/${uuid}`);
  }

  postPolicy(policy) {
    return this.post(POLICIES_URL, policy);
  }

  putPolicy(policy) {
    return this.put(`${POLICIES_URL}/${policy.uuid}`, policy);
  }

  deletePolicy(uuid) {
    return this.delete(`${POLICIES_URL}/${uuid}`);
  }

  exportPolicies(params) {
    return this.download(`${POLICIES_URL}/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }
}
