import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import React, { useState } from 'react';
import { Button } from '@mui/material';
import TasksTable from './TasksTable';
import TaskAssignmentsTable from './TaskAssignmentsTable';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import withPermissions from 'components/permissions/withPermissions';

function TasksAndProcessorsPanel({ processing }) {
  const [tasks, setTasks] = useState(true);
  return (
    <ExpandableCard
      title={'Tasks & Processors'}
      titleTypographyProps={{}}
      actions={
        <>
          <Authorize hasPermissions={[Permissions.TASKS_VIEWER]}>
            <Button color={tasks ? 'primary' : 'grey'} onClick={() => setTasks(true)}>
              Tasks
            </Button>{' '}
          </Authorize>
          <Authorize hasPermissions={[Permissions.DATA_ACTORS_VIEWER, Permissions.TASKS_VIEWER]}>
            <Button color={!tasks ? 'primary' : 'grey'} onClick={() => setTasks(false)}>
              Processors
            </Button>
          </Authorize>
        </>
      }>
      {tasks ? (
        <Authorize hasPermissions={[Permissions.TASKS_VIEWER]}>
          <TasksTable processing={processing}></TasksTable>
        </Authorize>
      ) : (
        <Authorize hasPermissions={[Permissions.DATA_ACTORS_VIEWER, Permissions.TASKS_VIEWER]}>
          <TaskAssignmentsTable processing={processing}></TaskAssignmentsTable>
        </Authorize>
      )}
    </ExpandableCard>
  );
}

export default withPermissions({
  hasAnyPermissions: [Permissions.TASKS_VIEWER, Permissions.DATA_ACTORS_VIEWER]
})(TasksAndProcessorsPanel);
