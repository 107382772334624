import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';

import { testProbe } from 'actions/onagent/AgentJobRunnerActions';

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  CircularProgress
} from '@mui/material';

import AgentItemAutocomplete from 'pages/settings/agents/commons/AgentItemAutocomplete';
import ProjectTestResults from '../list/ProjectTestResults';
import { useTranslation } from 'react-i18next';

function ProbeTestButton({ probe, testProbe, disabled = false }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [results, setResults] = useState(null);
  const { t } = useTranslation();
  return (
    <>
      <Button disabled={disabled} onClick={() => setOpen(true)}>
        Test
      </Button>
      {open && (
        <Dialog open={open} fullWidth maxWidth='lg'>
          <DialogTitle>
            <Typography variant='h6'>Test a Probe Definition</Typography>
            {probe && (
              <Typography color='textSecondary' variant='subtitle2'>
                You are testing the probe "{probe?.name}"
              </Typography>
            )}
          </DialogTitle>
          <DialogContent>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Typography variant='body2'>
                  Select the agent on which you want to perform the test
                </Typography>
                <AgentItemAutocomplete
                  margin='dense'
                  value={selectedAgent}
                  onChange={(agent) => setSelectedAgent(agent)}
                />
              </Grid>

              <Grid item>
                {!loading && !results && (
                  <Typography variant='body2' style={{ textAlign: 'center' }}>
                    Start the test to get some results!
                  </Typography>
                )}
                {loading && <CircularProgress style={{ marginLeft: 'calc(50% - 20px)' }} />}
                {results && <ProjectTestResults results={results}></ProjectTestResults>}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              children={t('commons.actions.close')}
              onClick={() => {
                setSelectedAgent(null);
                setResults(null);
                setLoading(false);
                setOpen(false);
              }}>
              Close
            </Button>
            <Button
              children={t('commons.actions.test')}
              disabled={!selectedAgent || loading || !!results}
              onClick={() => {
                setLoading(true);
                testProbe(selectedAgent, probe)
                  .then((response) => {
                    setLoading(false);
                    setResults(response);
                  })
                  .catch((err) => console.log(err.message));
              }}>
              Test
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.PROBES_EDITOR, Permissions.AGENTS_EDITOR] }),
  connect(null, { testProbe })
)(ProbeTestButton);
