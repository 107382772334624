import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import ProcessingsListPage from './list/ProcessingsListPage'
import ProcessingDisclaimersPage from './disclaimers/ProcessingDisclaimersPage'
import ProcessingDetail from './detail/ProcessingDetailPage'
export default class ProcessingsPage extends Component {

  render() {
    return (
      <React.Fragment>
        <Route exact path={this.props.match.url} component={ProcessingsListPage} />
        <Route exact path={`${this.props.match.url}/:processingUuid/disclaimers`} component={ProcessingDisclaimersPage} />
        <Route exact path={`${this.props.match.url}/:processingUuid`} component={ProcessingDetail} />
      </React.Fragment>
    );
  }
}
