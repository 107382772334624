import { BlindataApi } from '../../ApiUtils';

const PROBES_CONNECTION_URL = '/api/v1/data-quality/probes/connections';

export default class ProbesConnectionsApi extends BlindataApi {
  getConnection(uuid) {
    return this.get(`${PROBES_CONNECTION_URL}/${uuid}`);
  }

  getConnectionsPage(params) {
    return this.get(PROBES_CONNECTION_URL, params);
  }

  postProbeConnection(connection) {
    return this.post(PROBES_CONNECTION_URL, connection);
  }

  putProbeConnection(connection) {
    return this.put(`${PROBES_CONNECTION_URL}/${connection.uuid}`, connection);
  }

  deleteProbeConnection(uuid) {
    return this.delete(`${PROBES_CONNECTION_URL}/${uuid}`);
  }

  exportProbeConnections(params) {
    return this.download(`${PROBES_CONNECTION_URL}/*/export`, params);
  }
}
