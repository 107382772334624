import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton
} from '@mui/material';
import Authorize from '../../../../components/permissions/Authorize';
import ProposeButton from './ProposeButton';
import { ChipItem } from '../../../../components/chiplist/ChipList';
import Permissions from '../../../../constants/Permissions';
import AuthorizeTeam from 'components/permissions/AuthorizeTeam';

function withProposalConfirmation(component, confirmOnProp = 'onClick') {
  return function ({ ProposeButtonProps, message, AuthorizeProps, children, ...otherProps }) {
    let [open, setOpen] = useState(false);
    return (
      <AuthorizeTeam
        hasAnyPermissions={[
          Permissions.PROPOSE,
          ...((AuthorizeProps &&
            (AuthorizeProps.hasPermissions || AuthorizeProps.hasAnyPermissions)) ||
            [])
        ]}
        teamCode={AuthorizeProps && AuthorizeProps.teamCode}
        teamAccessLevels={AuthorizeProps && AuthorizeProps.teamAccessLevels}>
        {React.createElement(
          component,
          {
            ...otherProps,
            [confirmOnProp]: () => setOpen(true)
          },
          children
        )}
        <Dialog open={open}>
          <DialogContent>
            <DialogContentText>{message || 'Are you sure?'}</DialogContentText>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <ProposeButton {...ProposeButtonProps}></ProposeButton>
              <Authorize {...AuthorizeProps}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    otherProps[confirmOnProp]();
                    setOpen(false);
                  }}>
                  Ok
                </Button>
              </Authorize>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </AuthorizeTeam>
    );
  };
}

export const ProposalConfirmationButton = withProposalConfirmation(Button);

export const ProposalConfirmationIconButton = withProposalConfirmation(IconButton);

export const ChipItemWithProposalConfirmation = withProposalConfirmation(
  ChipItem,
  'onRequestDelete'
);
export default withProposalConfirmation;
