import { buildApiCallAction } from './ActionUtils';
import RpaUtilsApi from '../api/RpaUtilsApi';
import { EDIT_RPA_TEMPLATE } from '../constants/ActionTypes';
import { fetchRpaTemplate, editRpaTemplate } from './RpaTemplateActions';
import { validateFilters } from './ProcessingsActions';

const api = new RpaUtilsApi();

export const testDownloadRpaTemplate = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.testDownloadRpa(params),
    actionType: 'RPA_TEST_DOWNLOAD'
  });

export const downloadRpa = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.downloadRpa(validateFilters(params)),
    actionType: 'RPA_DOWNLOAD'
  });

export const loadEditorWithTemplateSample = () =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.fetchTemplateSample(),
    actionType: EDIT_RPA_TEMPLATE
  });

export const loadEditorWithTemplate = (uuid) => (dispatch, getState) => {
  return fetchRpaTemplate(uuid)(dispatch, getState).then((data) =>
    editRpaTemplate(data)(dispatch, getState)
  );
};
