import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createWorkspace } from 'actions/social/favorites/WorkspaceActions';
import WorkspaceModal from './WorkspaceModal';
import { AddFAB } from 'components/fab';

function WorkspaceFAB({ createWorkspace }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      {open && (
        <WorkspaceModal
          setOpen={setOpen}
          title='Create Worskpace'
          open={open}
          onSubmit={(workspace) => {
            createWorkspace(workspace);
            setOpen(false);
          }}
          onCancel={() => setOpen(false)}></WorkspaceModal>
      )}
    </>
  );
}

export default connect(null, { createWorkspace })(WorkspaceFAB);
