import * as React from "react";
const SvgCubridLong = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    viewBox="0 0 111.054 33.977"
    {...props}
  >
    <defs>
      <clipPath clipPathUnits="userSpaceOnUse" id="CubridLong_svg__a">
        <path d="M0 6508.35h419.53V0H0v6508.35Z" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#CubridLong_svg__a)"
      transform="matrix(1.25 0 0 -1.25 -376.802 8085.564)"
    >
      <path
        d="m0 0 5.111-5.114V0H0Z"
        style={{
          fill: "#5086cd",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(307.685 6451.498)"
      />
      <path
        d="M0 0v-5.115h5.113V0H-.002 0Z"
        style={{
          fill: "#f1511b",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(313.607 6462.53)"
      />
      <path
        d="M0 0h5.113v5.115L0 .002V0Z"
        style={{
          fill: "#ee2639",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(313.606 6463.336)"
      />
      <path
        d="M0 0h10.227L0 10.229V0Z"
        style={{
          fill: "#f9a50d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(319.53 6452.302)"
      />
      <path
        d="M0 0v10.229l-5.115-5.113L0 0Z"
        style={{
          fill: "#b2289a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(312.797 6452.302)"
      />
      <path
        d="M0 0h-5.115l-5.113 5.113h5.115L0 0Z"
        style={{
          fill: "#293780",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(311.67 6452.302)"
      />
      <path
        d="m0 0 10.229 10.229H0V0Z"
        style={{
          fill: "#4dbd38",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(313.606 6441.27)"
      />
      <path
        d="M0 0c-3.252 0-5.117 1.785-5.117 4.897V6.04c0 2.975 2.099 4.897 5.35 4.897 1.06 0 2.228-.166 2.951-.414V7.555H1.178v1.311l-.105.027c-.186.046-.827.106-1.073.106-1.755 0-2.761-1.014-2.761-2.843V4.897c0-1.819 1.083-2.959 2.761-2.959h.688c.789 0 1.945.245 2.496.437V.465C2.772.307 1.545 0 .688 0H0Z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(338.738 6449.401)"
      />
      <path
        d="M0 0c-1.974 0-4.326.687-4.326 3.96v6.859h2.238V4.001c0-1.384.741-2.03 2.088-2.03 1.35 0 2.086.646 2.086 2.03v6.818h2.238V3.975C4.324 1.486 2.707 0 0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(348.147 6449.401)"
      />
      <path
        d="M0 0h1.736c.8 0 1.296.444 1.296 1.27 0 .811-.446 1.238-1.296 1.238H0V0Zm0-4.418h1.736c1.09 0 1.517.392 1.517 1.398 0 1.076-.607 1.297-1.517 1.297H0v-2.695zm-2.238-1.888V4.396h4.326c2.182 0 3.287-.953 3.287-2.832 0-.992-.396-1.66-1.242-2.102l-.276-.144.289-.111c.93-.353 1.463-1.187 1.463-2.285 0-2.172-1.152-3.228-3.521-3.228h-4.326z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(356.781 6455.825)"
      />
      <path
        d="M374.041 6460.221h2.238v-10.702h-2.238v10.702z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M0 0h1.736c1.739 0 2.776 1.064 2.776 3.022v.909c0 1.837-1.012 2.787-2.776 2.787H0V0Zm-2.238-1.991V8.71h3.974c3.262 0 5.131-1.657 5.131-4.545V2.789c0-3.084-1.822-4.78-5.131-4.78h-3.974z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(380.66 6451.51)"
      />
      <path
        d="M0 0h-1.305v3.546H0c1.322 0 2.088-.217 2.088-1.681C2.088.448 1.502 0 0 0m4.73-5.198L2.816-1.226a4 4 0 0 1 .487.357c.758.671 1.14 1.631 1.14 2.851 0 2.468-1.187 3.521-3.974 3.521h-4.012V-5.198h2.238v3.265H.512c.066 0 .138.005.208.01l1.54-3.275h2.47z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(367.746 6454.717)"
      />
      <path
        d="M0 0v-1.054h-.291V0h-.402v.269H.402V0H0Z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(388.336 6459.804)"
      />
      <path
        d="M0 0v.864L-.372.298h-.009l-.368.559V0h-.284v1.323h.313l.348-.56.347.56h.314V0H0Z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(389.996 6458.75)"
      />
    </g>
  </svg>
);
export default SvgCubridLong;
