import crudReducers from '../utils';
import { combineReducers } from 'redux';
import _ from 'lodash';
export default combineReducers({
  ...crudReducers({ resource: 'SETTINGS_CONFIGURATION', keyProperty: 'name' })
});

export const extractConfigurationsMap = (state) =>
  _.mapValues(state.settings.configurations.content, 'value');

export const extractConfiguration = (name) => (state) =>
  _(state.settings.configurations.content).find({ name });
