import React from 'react';
import { Switch, Route } from 'react-router';
import TemplateMappingDetailPage from './detail/TemplateMappingDetailPage';
import TemplatesMappingsListPage from './list/TemplatesMappingListPage';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  return (
    <Switch>
      <Route path={`${props.match.url}/:sequenceId`} component={TemplateMappingDetailPage}></Route>

      <Route path={`${props.match.url}`} component={TemplatesMappingsListPage}></Route>
    </Switch>
  );
}
