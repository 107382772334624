import { fetchDataProductsPage } from 'actions/DataProductsActions';
import PaginatedPanel from 'components/pagination/PaginatedPanel';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataProductListItem } from './DataProductListItem';
import { fetchDashboardTypeCounterIfAllowedLargeRequest } from 'actions/IssueMngtDashboardsActions';
import ResourceTypes from 'constants/ResourceTypes';

const DataProductsList = ({ _props }) => {
  const dispatch = useDispatch();
  const { currentPage, filters } = useSelector((state) => state.dataproducts);

  useEffect(() => {
    dispatch(
      fetchDataProductsPage({
        page: 0,
        size: 200,
        ...filters?.content,
        includeProperties: false
      })
    ).then(
      (response) =>
        response &&
        response.content.length > 0 &&
        dispatch(
          fetchDashboardTypeCounterIfAllowedLargeRequest(
            response.content,
            25,
            ResourceTypes.DATA_PRODUCT
          )
        )
    );
  }, [dispatch, filters?.content]);

  if (!currentPage.content) return <></>;

  return (
    <PaginatedPanel
      currentPage={currentPage}
      onPageSelection={(page) =>
        dispatch(
          fetchDataProductsPage({ page, ...filters.content, includeProperties: false })
        ).then(
          (response) =>
            response &&
            response.content.length > 0 &&
            dispatch(
              fetchDashboardTypeCounterIfAllowedLargeRequest(
                response.content,
                25,
                ResourceTypes.DATA_PRODUCT
              )
            )
        )
      }>
      {currentPage.content.map((dp) => (
        <DataProductListItem dataProduct={dp} />
      ))}
    </PaginatedPanel>
  );
};

export default DataProductsList;
