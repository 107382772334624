import {
  Collapse,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { DataProductPortNode } from './DataProductPortNode';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ResourceTypesIcons } from 'constants/ResourceTypesIcons';
import ResourceTypes from 'constants/ResourceTypes';
import { useHistory } from 'react-router';
import _ from 'lodash';

export const DataProductNode = ({ id, data }) => {
  const randomColor = stringToRGB(id);
  const [open, setOpen] = useState(false);

  const [contextMenu, setContextMenu] = useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <Paper
      style={{
        borderTop: `2px solid #${randomColor}`,
        cursor: 'context-menu'
      }}>
      <ContextMenu data={data} handleClose={handleClose} contextMenu={contextMenu} />
      <Grid container direction='column' onContextMenu={handleContextMenu}>
        <Grid container direction='row' alignItems='center'>
          <Grid item style={{ flexGrow: 1 }}></Grid>
          <Grid item>
            <div
              style={{
                textAlign: 'center',
                fontFamily: 'sans-serif',
                padding: '8px',
                fontWeight: 500,
                margin: 'auto',
                color: 'black'
              }}>
              <Handle
                type='target'
                position={Position.Left}
                id={'to'}
                style={{ visibility: 'hidden' }}
              />
              <Handle
                type='source'
                position={Position.Right}
                id={'from'}
                style={{ visibility: 'hidden' }}
              />
              <tspan style={{ dy: '0.3em' }}> {data.displayName}</tspan>
            </div>
          </Grid>
          {data?.ports?.length > 0 && (
            <Grid item>
              <IconButton
                size='small'
                onClick={(event) => {
                  setOpen(!open);
                  event.stopPropagation();
                }}>
                {open ? (
                  <KeyboardArrowUp fontSize='small' />
                ) : (
                  <KeyboardArrowDown fontSize='small' />
                )}
              </IconButton>
            </Grid>
          )}
          <Grid item style={{ flexGrow: 1 }}></Grid>
        </Grid>

        {!open &&
          data.ports.map((port, index) => (
            <div key={index}>
              <Handle
                type='target'
                position={Position.Left}
                id={`to.${port.identifier}`}
                style={{ visibility: 'hidden' }}
              />
              <Handle
                type='source'
                position={Position.Right}
                id={`from.${port.identifier}`}
                style={{ visibility: 'hidden' }}
              />
            </div>
          ))}
        <Collapse style={{ background: 'rgb(230, 234, 240, 0.2)' }} in={open} timeout='auto'>
          {open && data.ports.map((port) => <DataProductPortNode dataProduct={data} port={port} />)}
        </Collapse>
      </Grid>
    </Paper>
  );
};

const ContextMenu = ({ data, contextMenu, handleClose }) => {
  const history = useHistory();

  return (
    <Menu
      autoFocus={false}
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={
        contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
      }>
      <div style={{ padding: '8px 16px 16px 16px' }}>
        <Typography
          variant='body1'
          style={{
            textAlign: 'center',
            fontSize: '1.4em'
          }}>
          Data Product
        </Typography>
        <Typography variant='description'>
          {_.truncate(data.description?.replace(/(.{50})/g, '$1\n'), { length: 100 })}
        </Typography>
      </div>
      <MenuItem
        onClick={() => {
          handleClose();
          history.push(`/dataproducts/detail/${data.uuid}`);
        }}>
        <ListItemIcon>{ResourceTypesIcons[ResourceTypes.DATA_PRODUCT]}</ListItemIcon>
        <ListItemText>{data.displayName || data.name || data.identifier}</ListItemText>
        <OpenInNewIcon fontSize='small' style={{ alignSelf: 'flex-end' }} />
      </MenuItem>
    </Menu>
  );
};

function stringToRGB(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var c = (hash & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}
