import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ResourceTypes from 'constants/ResourceTypes';
import DataCategoryItemAutoComplete from 'pages/datacategories/utils/DataCategoryItemAutoComplete';
import LogicalFieldItemAutoComplete from 'pages/logicalfields/commons/LogicalFieldItemAutoComplete';
import PhysicalEntityItemAutoComplete from 'pages/systems/physical_entities/commons/PhysicalEntityItemAutoComplete';
import PhysicalFieldItemSelect from 'pages/systems/physical_entities/commons/PhysicalFieldItemSelect';

function AssetsAddModal({ open, setOpen, editor, setEditor }) {
  const { t } = useTranslation();
  const filteredResourceTypes = [
    ResourceTypes.DATA_CATEGORY,
    ResourceTypes.PHYSICAL_ENTITY,
    ResourceTypes.PHYSICAL_FIELD,
    ResourceTypes.LOGICAL_FIELD
  ];
  const [asset, setAsset] = useState({ resourceType: ResourceTypes.PHYSICAL_ENTITY });
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add an assets</DialogTitle>
      <DialogContent>
        <InputLabel title='Choose a resource type'></InputLabel>
        <Select
          fullWidth
          variant='standard'
          defaultValue={ResourceTypes.PHYSICAL_ENTITY}
          value={asset?.resourceType}
          onChange={(event) => setAsset({ resourceType: event.target.value })}>
          {filteredResourceTypes.map((type) => (
            <MenuItem key={type} value={type} children={type} />
          ))}
        </Select>
        {(asset?.resourceType === ResourceTypes.DATA_CATEGORY ||
          asset?.resourceType === ResourceTypes.LOGICAL_FIELD) && (
          <DataCategoryItemAutoComplete
            onChange={(dc) => setAsset({ ...asset, dataCategory: dc })}
          />
        )}
        {asset?.resourceType === ResourceTypes.LOGICAL_FIELD && asset.dataCategory && (
          <LogicalFieldItemAutoComplete
            value={asset.logicalField || null}
            dataCategoryUuid={asset.dataCategory?.uuid}
            onChange={(lf) => setAsset({ ...asset, logicalField: lf })}
          />
        )}
        {(asset?.resourceType === ResourceTypes.PHYSICAL_ENTITY ||
          asset?.resourceType === ResourceTypes.PHYSICAL_FIELD) && (
          <PhysicalEntityItemAutoComplete
            onChange={(dc) => setAsset({ ...asset, physicalEntity: dc })}
          />
        )}
        {asset?.resourceType === ResourceTypes.PHYSICAL_FIELD && asset.physicalEntity && (
          <PhysicalFieldItemSelect
            value={asset.physicalField || null}
            physicalEntityUuid={asset?.physicalEntity?.uuid}
            onChange={(pf) =>
              setAsset({
                ...asset,
                physicalField: { ...pf, physicalEntity: asset?.physicalEntity }
              })
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.cancel')} onClick={() => setOpen(false)}></Button>
        <Button
          disabled={getDisabled(asset)}
          children={t('commons.actions.submit')}
          variant='contained'
          color='primary'
          onClick={() => {
            setEditor(concatAssetToArray(editor, asset));
            setOpen(false);
          }}></Button>
      </DialogActions>
    </Dialog>
  );
}

function concatAssetToArray(editor, asset) {
  switch (asset.resourceType) {
    case ResourceTypes.PHYSICAL_ENTITY:
      return {
        ...editor,
        physicalEntities: (editor?.physicalEntities || [])
          .filter((pe) => pe.uuid !== asset?.physicalEntity?.uuid)
          .concat([asset.physicalEntity])
      };
    case ResourceTypes.DATA_CATEGORY:
      return {
        ...editor,
        dataCategories: (editor?.dataCategories || [])
          .filter((dc) => dc?.uuid !== asset?.dataCategory?.uuid)
          .concat([asset.dataCategory])
      };

    case ResourceTypes.LOGICAL_FIELD:
      return {
        ...editor,
        logicalFields: (editor?.logicalFields || [])
          .filter((lf) => lf.uuid !== asset?.logicalField?.uuid)
          .concat([asset.logicalField])
      };

    case ResourceTypes.PHYSICAL_FIELD:
      return {
        ...editor,
        physicalFields: (editor?.physicalFields || [])
          .filter((pf) => pf.uuid !== asset?.physicalField?.uuid)
          .concat([asset.physicalField])
      };
    default:
      return editor;
  }
}
function getDisabled(asset) {
  switch (asset.resourceType) {
    case ResourceTypes.PHYSICAL_ENTITY:
      return !asset.physicalEntity;
    case ResourceTypes.DATA_CATEGORY:
      return !asset.dataCategory;
    case ResourceTypes.LOGICAL_FIELD:
      return !asset.logicalField;
    case ResourceTypes.PHYSICAL_FIELD:
      return !asset.physicalField;
    default:
      return true;
  }
}
export default AssetsAddModal;
