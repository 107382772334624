import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid
} from '@mui/material';
import React, { useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChipItem, ChipWrapper } from 'components/chiplist/ChipList';

import AgentConnectionItemAutoComplete from 'pages/settings/agents/commons/AgentConnectionItemAutoComplete';
import ClassificationScopesModal from 'pages/classification/commons/ClassificationScopesModal';
import { HelpIcon } from 'components/help';

const defaultEditorState = (resourceIdentifier, resourceType) => ({
  type: 'CLASSIFICATION',

  resourceType: resourceType,
  resourceIdentifier: resourceIdentifier,
  configs: {
    strategy: 'SQL',
    sampling: {
      connectionName: null,
      extractionQueryTemplate: `select * from {table_schema}.{table_name} limit 100`,
      minSampleSizeThreshold: 100,
      extractionQueryColumnarTemplate:
        'select {column_name} from {table_schema}.{table_name} where {column_name} is not null limit 100'
    },
    rules: { scopes: [] },
    dictionaries: {
      connectionName: null,
      scopes: []
    },
    outputOptions: {
      dryRun: false,
      targetConnectionName: null
    }
  }
});

function ClassificationAgentJobSettingsModal({
  open,
  onClose,
  onSubmit,
  agent,
  defaultSettings,
  resourceIdentifier,
  resourceType
}) {
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultState = useMemo(() => defaultEditorState(resourceIdentifier, resourceType));
  const [editor, setEditor] = useReducer(
    (state, action) => ({ ...state, ...action }),
    defaultSettings || defaultState
  );
  return (
    <Dialog open={open} fullWidth style={{ minWidth: 800 }}>
      <DialogTitle>Classification Default Settings</DialogTitle>
      <DialogContent>
        <ClassificationJobConfigsEditor
          agent={agent}
          configs={editor.configs}
          editor={editor}
          setEditor={setEditor}
          onChange={(configs) => setEditor({ configs: configs })}></ClassificationJobConfigsEditor>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button onClick={() => onSubmit(editor)}>{t('commons.actions.submit')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export function ClassificationJobConfigsEditor({ configs, agent, onChange }) {
  return (
    <>
      <Typography variant='subtitle1'>Settings</Typography>
      <AgentConnectionItemAutoComplete
        fullWidth
        margin='dense'
        value={configs?.sampling.connectionName || ''}
        onChange={(connection) => {
          onChange({
            ...configs,
            sampling: { ...configs.sampling, connectionName: connection }
          });
        }}
        agent={agent}
      />
      <SamplingEditor configs={configs} onChange={onChange} />
      <DictionariesEditor agent={agent} configs={configs} onChange={onChange} />
      <RulesEditor configs={configs} onChange={onChange} />
      <OutputOptionsEditor
        agent={agent}
        outputOptions={configs.outputOptions}
        onChange={(outputOptions) => onChange({ ...configs, outputOptions })}
      />
    </>
  );
}

function SamplingEditor({ configs, onChange }) {
  return <>
    <TextField
      variant="standard"
      fullWidth
      margin='dense'
      multiline
      maxRows={4}
      label='Tables Query'
      value={configs.sampling.extractionQueryTemplate || ''}
      onChange={(event) => {
        onChange({
          ...configs,
          sampling: { ...configs.sampling, extractionQueryTemplate: event.target.value }
        });
      }} />
    <TextField
      variant="standard"
      fullWidth
      margin='dense'
      multiline
      maxRows={4}
      label='Columns Query'
      value={configs.sampling.extractionQueryColumnarTemplate || ''}
      onChange={(event) =>
        onChange({
          ...configs,
          sampling: { ...configs.sampling, extractionQueryColumnarTemplate: event.target.value }
        })
      } />
    <Grid container direction='row' alignItems='center'>
      <Grid item style={{ flexGrow: 0.7 }}>
        <TextField
          variant="standard"
          fullWidth
          margin='dense'
          type='number'
          label={'Columnar sample threshold'}
          placeholder={'Enter the sample threshold'}
          value={configs.sampling.minSampleSizeThreshold}
          onChange={(event) =>
            onChange({
              ...configs,
              sampling: { ...configs.sampling, minSampleSizeThreshold: event.target.value }
            })
          } />
      </Grid>
      <Grid>
        <HelpIcon tooltip='Minimum number of samples not null to not trigger columnar extraction. It affects execution time '></HelpIcon>
      </Grid>
    </Grid>
  </>;
}
function DictionariesEditor({ agent, configs, onChange }) {
  const [openDictionary, setOpenDictionary] = useState(false);
  return (
    <>
      <h4>Dictionary</h4>

      <AgentConnectionItemAutoComplete
        fullWidth
        margin='dense'
        value={configs?.dictionaries.connectionName || ''}
        onChange={(connection) => {
          onChange({
            ...configs,
            dictionaries: { ...configs.dictionaries, connectionName: connection }
          });
        }}
        agent={agent}
      />
      <br></br>
      <Typography variant='subtitle1'>Scopes</Typography>
      <ChipWrapper onRequestAdd={() => setOpenDictionary(true)}>
        {(configs.dictionaries.scopes || []).map((p, index) => (
          <ChipItem
            key={index}
            id={index}
            label={p}
            onRequestDelete={() =>
              onChange({
                ...configs,
                dictionaries: {
                  ...configs.dictionaries,
                  scopes: configs.dictionaries.scopes.filter((p, idx) => idx !== index)
                }
              })
            }
          />
        ))}
      </ChipWrapper>
      {openDictionary && (
        <ClassificationScopesModal
          title={'Add a scope for dictionaries'}
          open={openDictionary}
          onCancel={() => setOpenDictionary(false)}
          onSubmit={(scope) => {
            onChange({
              ...configs,
              dictionaries: {
                ...configs.dictionaries,
                scopes: (configs.dictionaries.scopes || []).concat([scope])
              }
            });
            setOpenDictionary(false);
          }}></ClassificationScopesModal>
      )}
    </>
  );
}
function RulesEditor({ configs, onChange }) {
  const [openRule, setOpenRule] = useState(false);

  return (
    <>
      <h4>Rules Scopes</h4>

      <ChipWrapper onRequestAdd={() => setOpenRule(true)}>
        {(configs.rules.scopes || []).map((p, index) => (
          <ChipItem
            key={index}
            id={index}
            label={p}
            onRequestDelete={() =>
              onChange({
                ...configs,
                rules: { scopes: configs.rules.scopes.filter((p, idx) => idx !== index) }
              })
            }
          />
        ))}
      </ChipWrapper>
      {openRule && (
        <ClassificationScopesModal
          title={'Add a scope for rules'}
          open={openRule}
          onCancel={() => setOpenRule(false)}
          onSubmit={(scope) => {
            onChange({
              ...configs,
              rules: {
                ...configs.rules,
                scopes: (configs.rules.scopes || []).concat([scope])
              }
            });
            setOpenRule(false);
          }}></ClassificationScopesModal>
      )}
    </>
  );
}
function OutputOptionsEditor({ outputOptions, agent, onChange }) {
  return (
    <>
      <h4>Output</h4>
      <AgentConnectionItemAutoComplete
        fullWidth
        margin='dense'
        connectionTypes={['BLINDATA']}
        value={outputOptions?.targetConnectionName || ''}
        onChange={(value) => onChange({ ...outputOptions, targetConnectionName: value })}
        agent={agent}
      />
    </>
  );
}

export default ClassificationAgentJobSettingsModal;
