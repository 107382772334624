import { combineReducers } from 'redux';
import crudReducers from 'reducers/utils';
import {
  CREATE_AGENT_SCHEDULE,
  DELETE_AGENT_SCHEDULE,
  FETCH_AGENT_SCHEDULES_PAGE,
  MODIFY_AGENT_SCHEDULE
} from 'constants/ActionTypes';

function pageByExternalIdentifier(state = {}, action) {
  switch (action.type) {
    case FETCH_AGENT_SCHEDULES_PAGE:
      return action?.payload?.externalIdentifier
        ? { ...state, [action.payload.externalIdentifier]: action.payload }
        : state;
    case CREATE_AGENT_SCHEDULE:
      if (action.payload.externalIdentifier && state[action.payload.externalIdentifier]) {
        return {
          ...state,
          [action.payload.externalIdentifier]: {
            ...state[action.payload.externalIdentifier],
            content: state[action.payload.externalIdentifier].content.concat([action.payload])
          }
        };
      }
      return state;
    case MODIFY_AGENT_SCHEDULE:
      if (action.payload.externalIdentifier && state[action.payload.externalIdentifier]) {
        return {
          ...state,
          [action.payload.externalIdentifier]: {
            ...state[action.payload.externalIdentifier],
            content: state[action.payload.externalIdentifier].content.map((e) =>
              e.uuid === action.payload.uuid ? action.payload : e
            )
          }
        };
      }
      return state;
    case DELETE_AGENT_SCHEDULE:
      if (action.payload.externalIdentifier && state[action.payload.externalIdentifier]) {
        return {
          ...state,
          [action.payload.externalIdentifier]: {
            ...state[action.payload.externalIdentifier],
            content: state[action.payload.externalIdentifier].content.filter(
              (e) => e.uuid !== action.payload.uuid
            )
          }
        };
      }
      return state;
    default:
      return state;
  }
}

export default combineReducers({
  ...crudReducers({ resource: 'AGENT_SCHEDULE', keyProperty: 'uuid' }),
  pageByExternalIdentifier
});
