import React from 'react';

import GlobalSearch from './GlobalSearch';

export default function HomeSearchWidget({ maxWidth }) {
  return (
    <GlobalSearch WrapperComponent={(props) => <CardComponent maxWidth={maxWidth} {...props} />} />
  );
}

const CardComponent = ({ maxWidth, ...props }) => (
  <div style={{ padding: 8, margin: '0 auto 0px auto', maxWidth: `${maxWidth}px` }}>
    {props.children}
  </div>
);
