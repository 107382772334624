import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { connect } from 'react-redux';
import { exportHistoryRecords } from '../../../actions/HistoryActions';

function HistoryExportButton({ filters, onClick }) {
  return (
    <Tooltip title='Export CSV'>
      <IconButton
        color='default'
        aria-label='Download'
        onClick={() => onClick(filters)}
        size='small'>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: (filters) => dispatch(exportHistoryRecords(filters))
  };
}
function mapStateToProps(state) {
  return {
    filters: state.history.filters.content
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(HistoryExportButton);
