import React from 'react';
import { Route } from 'react-router-dom'
import DataActorsListPage from './list/DataActorsListPage'
import DataActorDetail from './detail/DataActorDetail'

export default class DataActorsPage extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Route exact path={this.props.match.url} component={DataActorsListPage} />
        <Route exact path={`${this.props.match.url}/:dataActorUuid`} component={DataActorDetail} />
      </React.Fragment>
    );
  }

}