import { BlindataApi } from './ApiUtils';

const ASSIGNMENTS_URL = '/api/v1/classification/assignments';

export default class AssignmentsApi extends BlindataApi {
  getAllAssignments(params) {
    const { page, size = 20, filters = {} } = params;
    return this.get(ASSIGNMENTS_URL, { page, size, ...this.validateFilters(filters) });
  }

  validateFilters(filters = {}) {
    let {
      search,
      assignmentState = [],
      dataCategories = [],
      logicalFields = [],
      systems = [],
      physicalEntities = [],
      physicalFields = []
    } = filters;
    return {
      dataCategoriesUuids: dataCategories.map((dataCategory) => dataCategory.uuid),
      logicalFieldsUuids: logicalFields.map((logicalField) => logicalField.uuid),
      systemsUuids: systems.map((system) => system.uuid),
      physicalEntitiesUuids: physicalEntities.map((physicalEntity) => physicalEntity.uuid),
      physicalFieldsUuids: physicalFields.map((physicalField) => physicalField.uuid),
      search: search,
      assignmentState: assignmentState
    };
  }

  getAssignment(identifier) {
    return this.get(`${ASSIGNMENTS_URL}/${identifier}`);
  }

  postAssignment(assignment) {
    return this.post(ASSIGNMENTS_URL, assignment);
  }

  putAssignment(assignment) {
    return this.put(`${ASSIGNMENTS_URL}/${assignment.sequenceId}`, assignment);
  }

  deleteAssignment(assignment) {
    return this.delete(`${ASSIGNMENTS_URL}/${assignment.sequenceId}`);
  }

  patchAssignment(params) {
    const { sequenceId, assignmentState } = params;
    return this.patch(`${ASSIGNMENTS_URL}/${sequenceId}`, assignmentState);
  }
  exportAssignments(params) {
    return this.download(`${ASSIGNMENTS_URL}/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }
}
