import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import { fetchIssuesPage, createIssue, setIssuesFilters } from 'actions/IssuesActions';

import Permissions from 'constants/Permissions';

import { Card, CardHeader, CardContent, Grid, Tooltip, IconButton } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

import Authorize from 'components/permissions/Authorize';
import IssuesTable from 'pages/issueMngt/issues/list/IssuesTable';
import IssueModal from 'pages/issueMngt/issues/detail/IssueModal';
import IssuesFiltersModal from 'pages/issueMngt/issues/list/IssuesFiltersModal';
import IssueExportButton from 'pages/issueMngt/issues/list/IssueExportButton';
import { hasPermissions } from 'components/permissions/permissiosSelectors';
import { Lock } from '@mui/icons-material';

const IssuesListCard = ({
  campaign,
  issues,
  pagination,
  currentUser,
  fetchIssuesPage,
  createIssue,
  filters
}) => {
  useEffect(() => {
    fetchIssuesPage({
      page: pagination.page,
      size: pagination.size,
      ...filters,
      campaigns: [campaign]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleFetch = (page, size) => {
    fetchIssuesPage({ page, size, ...filters, campaigns: [campaign] });
  };

  return (
    <Card>
      <CardHeader
        title='Issues'
        action={
          <Grid container direction='row' alignItems='center'>
            <Grid item>
              <AddButton campaign={campaign} currentUser={currentUser} createIssue={createIssue} />
            </Grid>
            <Grid item>
              <IssuesFiltersModal hideCampaigns></IssuesFiltersModal>
            </Grid>
            <Grid item>
              <IssueExportButton></IssueExportButton>
            </Grid>
          </Grid>
        }></CardHeader>
      <CardContent>
        <IssuesTable
          campaign={campaign}
          issues={issues}
          pagination={pagination}
          handleFetch={handleFetch}
        />
      </CardContent>
    </Card>
  );
};

const AddButton = ({ campaign, currentUser, createIssue }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const isCampaignAdmin = useSelector(hasPermissions([Permissions.CAMPAIGNS_ADMIN]));
  const currentUserUuid = currentUser?.uuid;
  return (
    <>
      {canAddIssue(campaign, currentUserUuid, isCampaignAdmin) ? (
        <Authorize hasPermissions={[Permissions.CAMPAIGNS_EDITOR]}>
          <Tooltip title='Create issue'>
            <IconButton size='small' onClick={() => setModalOpen(true)}>
              <AddBoxIcon />
            </IconButton>
          </Tooltip>
        </Authorize>
      ) : (
        <Tooltip title='Only Admin or Owner can add issues'>
          <IconButton size='small'>
            <Lock />
          </IconButton>
        </Tooltip>
      )}
      {modalOpen && (
        <IssueModal
          open={modalOpen}
          title={'Create an Issue'}
          campaign={campaign}
          reporter={currentUser}
          handleSubmit={createIssue}
          handleCancel={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state, props) => ({
  issues: state.campaigns.issues.currentPage.content,
  filters: state.campaigns.issues.filters.content,
  pagination: {
    page: state.campaigns.issues.currentPage.number,
    size: state.campaigns.issues.currentPage.size,
    totalElements: state.campaigns.issues.currentPage.totalElements
  },
  currentUser: state.settings.profile
});

function canAddIssue(campaign, currentUserUuid, isCampaignAdmin) {
  if (isCampaignAdmin) {
    return true;
  }
  if (!campaign.private || campaign?.owner?.uuid === currentUserUuid) {
    return true;
  }
  return false;
}

const mapDispatchToProps = {
  fetchIssuesPage,
  createIssue,
  setIssuesFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(IssuesListCard);
