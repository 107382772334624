import { combineReducers } from 'redux';
import crudReducers from 'reducers/utils';

const connections = combineReducers({
  ...crudReducers({ resource: 'PROBE_CONNECTION', keyProperty: 'uuid' })
});

const projects = combineReducers({
  ...crudReducers({ resource: 'PROBE_PROJECT', keyProperty: 'uuid' })
});

const currentProbeDefinitionReducer = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_PROBE_DEFINITION_WITH_ROOT':
      return action.payload;
    case 'FETCH_PROBE_DEFINITION':
      return action.payload;
    case 'MODIFY_PROBE_DEFINITION':
      return action.payload;
    default:
      return state;
  }
};

const definitions = combineReducers({
  ...crudReducers({ resource: 'PROBE_DEFINITION', keyProperty: 'sequenceId' }),
  currentProbeDefinition: currentProbeDefinitionReducer
});

const tags = combineReducers({
  ...crudReducers({ resource: 'QUALITY_TAG', keyProperty: 'uuid' })
});

export default combineReducers({
  connections,
  projects,
  definitions,
  tags
});
