import React from 'react';
import VariationTypeDecorator from '../../workflows/proposals/commons/VariationTypeDecorator';
import _ from 'lodash';
import { Typography, Card, CardContent, Grid, CardHeader } from '@mui/material';
import NumberBox, { numberBoxColors } from 'components/graphics/NumberBox';

function DashboardOverallView({ rows, columns, percentLabel, resourceType }) {
  return (
    <Card style={{ height: '90%' }}>
      <CardHeader
        action={<VariationTypeDecorator>{resourceType}</VariationTypeDecorator>}></CardHeader>
      <CardContent direction='center' style={{ minHeight: '200px', justifyContent: 'center' }}>
        {rows && rows.length > 0 ? (
          <Grid container direction='row' alignItems='center'>
            <Grid item xs={4}>
              <div>
                <Typography variant='h2' style={{ textAlign: 'center' }}>
                  {rows && rows[0] && rows[0][0]}%
                </Typography>
                <Typography align='center' variant='subtitle1' style={{ textAlign: 'center' }}>
                  {percentLabel}
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <Grid container direction='column' alignItems='center'>
                <Grid container direction='row'>
                  <Grid item xs={4}>
                    <NumberBox
                      label={columns[1] && columns[1].label}
                      number={rows[0] && rows[0][1]}
                      color={numberBoxColors.lightBlue}></NumberBox>
                  </Grid>
                  <Grid item style={{ flexGrow: 0.8 }}></Grid>
                  <Grid item xs={4}>
                    <NumberBox
                      label={columns[4] && columns[4].label}
                      number={rows[0] && _.round(rows[0][4], 1)}
                      color={numberBoxColors.grey}></NumberBox>
                  </Grid>
                </Grid>
                <Grid container direction='row'>
                  <Grid item xs={4}>
                    <NumberBox
                      label={columns[2] && columns[2].label}
                      number={rows[0] && rows[0][2]}
                      color={numberBoxColors.lightGrey}></NumberBox>
                  </Grid>
                  <Grid item style={{ flexGrow: 0.8 }}></Grid>
                  <Grid item xs={4}>
                    <NumberBox
                      label={columns[3] && columns[3].label}
                      number={rows[0] && rows[0][3]}
                      color={numberBoxColors.darkGrey}></NumberBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Typography style={{ textAlign: 'center', padding: '2%' }} variant='body1'>
            No informations about {resourceType}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default DashboardOverallView;
