import { buildApiCallAction } from './ActionUtils';
import ProposalsApi from '../api/ProposalsApi';
import {
  FETCH_PROPOSALS_PAGE,
  FETCH_PROPOSAL,
  MODIFY_PROPOSAL,
  DELETE_PROPOSAL,
  CREATE_PROPOSAL,
  PATCH_PROPOSAL,
  SET_PROPOSAL_EDITOR,
  CLEAR_PROPOSAL_EDITOR,
  SET_PROPOSALS_FILTERS,
  CLEAR_PROPOSALS_FILTERS
} from '../constants/ActionTypes';

const api = new ProposalsApi();

export const fetchProposalsPageWithFilters = (params) => (dispatch, getState) => {
  let filters = getState().workflows.proposals.filters;
  let { approverPerspective, proposalStates } = filters;

  return dispatch(
    fetchProposalsPage({
      proposalStates,
      proposerUuids: !approverPerspective ? 'me' : [],
      ...params
    })
  );
};

export const fetchProposalsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getProposals(params),
    actionType: FETCH_PROPOSALS_PAGE
  });

export const fetchProposal = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getProposal(uuid),
    actionType: FETCH_PROPOSAL
  });

export const createProposal = (proposal) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postProposal(proposal),
    actionType: CREATE_PROPOSAL
  });

export const modifyProposal = (proposal) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putProposal(proposal),
    actionType: MODIFY_PROPOSAL
  });

export const deleteProposal = (proposal) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteProposal(proposal.uuid),
    actionType: DELETE_PROPOSAL,
    payload: { uuid: proposal.uuid }
  });

export const patchProposal = (patch) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.patchProposal(patch),
    actionType: PATCH_PROPOSAL
  });

export const setProposalEditor = (editor) => (dispatch, getState) => {
  dispatch({
    type: SET_PROPOSAL_EDITOR,
    payload: editor
  });
};

export const clearProposalEditor = () => (dispatch) => {
  dispatch({
    type: CLEAR_PROPOSAL_EDITOR
  });
};

export const setProposalsFilters = (filters) => (dispatch) => {
  dispatch({
    type: SET_PROPOSALS_FILTERS,
    payload: filters
  });
};

export const clearProposalsFilters = () => (dispatch) => {
  dispatch({
    type: CLEAR_PROPOSALS_FILTERS
  });
};

export const createImmediateProposal = (proposal) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postImmediateProposal(proposal),
    actionType: 'CREATE_IMMEDIATE_PROPOSAL'
  });
