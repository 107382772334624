import React from 'react';

import { Icon, Tooltip } from '@mui/material';
const commonsStyles = {
  textAlign: 'center',
  verticalAlign: 'bottom'
};
const SeverityIcons = {
  BLOCKER: (
    <Tooltip title={'Blocker'}>
      <Icon style={{ color: '#d91e48', ...commonsStyles }} className=' fa fa-ban'></Icon>
    </Tooltip>
  ),
  CRITICAL: (
    <Tooltip title={'Critical'}>
      <Icon className='fa fa-angle-double-up' style={{ color: '#e9a227', ...commonsStyles }}></Icon>
    </Tooltip>
  ),
  MAJOR: (
    <Tooltip title={'Major'}>
      <Icon className='fa fa-angle-up' style={{ color: '#9753d4', ...commonsStyles }}></Icon>
    </Tooltip>
  ),
  MINOR: (
    <Tooltip title={'Minor'}>
      <Icon
        className='fa fa-angle-double-down'
        style={{ color: '#84b6eb', ...commonsStyles }}></Icon>
    </Tooltip>
  ),
  INFO: (
    <Tooltip title={'Info'}>
      <Icon className='fa fa-info' style={{ color: '#0514ed', ...commonsStyles }}></Icon>
    </Tooltip>
  )
};

export default SeverityIcons;
