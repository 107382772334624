import ResourceTypes from 'constants/ResourceTypes';

export const CustomPropertiesResources = [
  ResourceTypes.DATA_CATEGORY,
  ResourceTypes.LOGICAL_FIELD,
  ResourceTypes.PROCESSING,
  ResourceTypes.TASK,
  ResourceTypes.SYSTEM,
  ResourceTypes.PHYSICAL_ENTITY,
  ResourceTypes.PHYSICAL_FIELD,
  ResourceTypes.QUALITY_CHECK,
  ResourceTypes.ISSUE,
  ResourceTypes.CONTRACT,
  ResourceTypes.DATA_PRODUCT
];
