import { v4 as uuidv4 } from 'uuid';

const useTrackingInfo = () => {

  const getTrackingInfo = (storage, key) => {
    let value = storage.getItem(key);
    if (value === null) {
      value = uuidv4();
      storage.setItem(key, value);
    }
    return value;
  }

  const userTrackingId = getTrackingInfo(localStorage, 'state.engagement.userTrackingId');
  const sessionTrackingId = getTrackingInfo(sessionStorage, 'state.engagement.sessionTrackingId');
  const hitTimestamp = Date.now();

  return { userTrackingId, sessionTrackingId, hitTimestamp };
}

export default useTrackingInfo;