import React from 'react';
import { Route, Switch } from 'react-router';
import ProposalsRoutes from './proposals/ProposalsRoutes';

function WorkflowsRoutes(props) {
  return (
    <Switch>
      <Route path={`${props.match.url}/proposals`} component={ProposalsRoutes} />
    </Switch>
  );
}

export default WorkflowsRoutes;
