import React from 'react';
import { Tooltip } from '@mui/material';
import ConfirmIconButton from 'components/confirmbutton/ConfirmIconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { t } from 'i18next';

const SaveDataFlowPreviewsButton = ({
  queryStatement,
  saveDataFlowPreviews,
  fetchQueryStatement,
  previewOptions
}) => {
  return (
    <ConfirmIconButton
      size='small'
      onClick={() =>
        saveDataFlowPreviews(queryStatement.uuid, previewOptions).then(() =>
          fetchQueryStatement(queryStatement.uuid)
        )
      }
      message={t('querystatements.dataFlowsPreview.card.confirmMessageSavePreviews')}>
      <Tooltip title={t('querystatements.dataFlowsPreview.card.saveButton')}>
        <AddBoxIcon />
      </Tooltip>
    </ConfirmIconButton>
  );
};

export default SaveDataFlowPreviewsButton;
