import React, { useState } from 'react';
import TeamPolicyEditorModal from './TeamPolicyEditorModal';
import {
  fetchTeamPoliciesListByUserUuid,
  fetchTeamPoliciesListByTeamCode,
  modifyTeamPolicy,
  createTeamPolicy,
  deleteTeamPolicy
} from '../../../../actions/TeamsPoliciesActions';
import {
  AddCircle as AddCircleIcon,
  Delete as DeleteIcon,
  Create as CreateIcon
} from '@mui/icons-material';
import { compose } from 'redux';
import { withLoader } from '../../../../components/loader/Loader';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Checkbox,
  TablePagination
} from '@mui/material';
import { ChipItem } from '../../../../components/chiplist/ChipList';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';

function TeamPoliciesCard({
  teamPoliciesPage,
  byTeamCode,
  byUserUuid,
  onPageSelection,
  deleteTeamPolicy,
  modifyTeamPolicy,
  createTeamPolicy
}) {
  const [createOpen, setCreateOpen] = useState(false);
  const [editPolicy, setEditPolicy] = useState(null);

  return (
    <Card>
      <CardHeader
        title='Team Policies'
        action={<AddAction onClick={() => setCreateOpen(true)}></AddAction>}
      />
      <TeamPolicyEditorModal
        fixedUserUuid={byUserUuid}
        fixedTeamCode={byTeamCode}
        open={createOpen}
        handleCancel={() => setCreateOpen(false)}
        handleSubmit={(teamPolicy) =>
          createTeamPolicy(teamPolicy).then((data) => data && setCreateOpen(false))
        }
        title='Create Team Policy'></TeamPolicyEditorModal>
      <TeamPolicyEditorModal
        teamPolicyToEdit={editPolicy}
        key={editPolicy && editPolicy.uuid}
        open={editPolicy != null}
        handleCancel={() => setEditPolicy(null)}
        handleSubmit={(teamPolicy) => {
          modifyTeamPolicy(teamPolicy).then((data) => data && setEditPolicy(null));
        }}
        title='Edit Team Policy'
      />
      <CardContent>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {byUserUuid ? <TableCell>Team</TableCell> : <TableCell>User</TableCell>}
              <TableCell>Read</TableCell>
              <TableCell>Write</TableCell>
              <TableCell>Propose</TableCell>
              <TableCell>Approve</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamPoliciesPage.content.map((teamPolicy) => (
              <TableRow key={teamPolicy.uuid}>
                <TableCell>
                  {byUserUuid ? (
                    <ChipItem
                      to={`/settings/teams/${teamPolicy.teamCode}`}
                      label={teamPolicy.teamCode}
                    />
                  ) : (
                    <ChipItem
                      to={`/settings/users/${teamPolicy.user.uuid}`}
                      label={teamPolicy.user.username}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Checkbox checked={teamPolicy.canRead} disabled />
                </TableCell>
                <TableCell>
                  <Checkbox checked={teamPolicy.canWrite} disabled />
                </TableCell>
                <TableCell>
                  <Checkbox checked={teamPolicy.canPropose} disabled />
                </TableCell>
                <TableCell>
                  <Checkbox checked={teamPolicy.canApprove} disabled />
                </TableCell>
                <TableCell>
                  <Authorize hasPermissions={[Permissions.USERS_EDITOR]}>
                    <IconButton onClick={() => setEditPolicy(teamPolicy)} size="large">
                      <CreateIcon></CreateIcon>
                    </IconButton>
                    <IconButton onClick={() => deleteTeamPolicy(teamPolicy)} size="large">
                      <DeleteIcon></DeleteIcon>
                    </IconButton>
                  </Authorize>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={teamPoliciesPage.totalElements}
          rowsPerPage={teamPoliciesPage.size}
          page={teamPoliciesPage.number}
          onPageChange={(event, page) =>
            onPageSelection({ page, size: teamPoliciesPage.size, byUserUuid, byTeamCode })
          }
          onRowsPerPageChange={(event) =>
            onPageSelection({
              page: teamPoliciesPage.number,
              size: event.target.value,
              byUserUuid,
              byTeamCode
            })
          }
        />
      </CardContent>
    </Card>
  );
}
const AddAction = ({ onClick }) => (
  <Authorize hasPermissions={[Permissions.USERS_EDITOR]}>
    <IconButton style={{ marginLeft: 32 }} onClick={onClick} size="large">
      <AddCircleIcon />
    </IconButton>
  </Authorize>
);

const mapDispatchToProps = {
  onPageSelection:
    ({ page, size, byUserUuid, byTeamCode }) =>
    (dispatch, getState) => {
      if (byUserUuid) {
        return dispatch(fetchTeamPoliciesListByUserUuid({ userUuid: byUserUuid, page, size }));
      } else {
        return dispatch(fetchTeamPoliciesListByTeamCode({ teamCode: byTeamCode, page, size }));
      }
    },
  modifyTeamPolicy,
  createTeamPolicy,
  deleteTeamPolicy
};
function mapStateToProps(state, { byUserUuid, byTeamCode }) {
  return {
    teamPoliciesPage: byUserUuid
      ? state.settings.teamsPolicies.currentPageByUserUuid[byUserUuid]
      : state.settings.teamsPolicies.currentPageByTeamCode[byTeamCode],
    user: state.settings.users.content[byUserUuid]
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ onPageSelection, byUserUuid, byTeamCode }) => {
      onPageSelection({ byUserUuid, byTeamCode, page: 0, size: 10 });
    },
    ready: ({ teamPoliciesPage }) => teamPoliciesPage !== undefined
  })
)(TeamPoliciesCard);
