import React, { useState } from 'react';
import './TeamEditorModal';
import { AddFAB } from '../../../../components/fab';
import TeamEditorModal from './TeamEditorModal';
import { connect } from 'react-redux';
import { createTeam } from '../../../../actions/TeamsActions';
import { useHistory } from 'react-router-dom';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';

function TeamCreateFab({ createTeam }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  return (
    <Authorize hasPermissions={[Permissions.USERS_EDITOR]}>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      <TeamEditorModal
        open={open}
        handleCancel={() => setOpen(false)}
        handleSubmit={(team) =>
          createTeam(team).then((data) => data && history.push(`/settings/teams/${data.code}`))
        }></TeamEditorModal>
    </Authorize>
  );
}
const mapDispatchToProps = {
  createTeam
};

export default connect(null, mapDispatchToProps)(TeamCreateFab);
