import { BlindataApi } from './ApiUtils';
const AGENT_JOB_SETTINGS = '/api/v1/settings/agents/job_settings';

export default class AgentJobSettingsApi extends BlindataApi {
  getAgentJobSettings(params) {
    return this.get(AGENT_JOB_SETTINGS, params);
  }

  postAgentJobSetting(jobSetting) {
    return this.post(AGENT_JOB_SETTINGS, jobSetting);
  }

  putAgentJobSetting(jobSetting) {
    return this.put(`${AGENT_JOB_SETTINGS}/${jobSetting.uuid}`, jobSetting);
  }

  deleteAgentJobSetting(jobSetting) {
    return this.delete(`${AGENT_JOB_SETTINGS}/${jobSetting.uuid}`);
  }
}
