import React from 'react';
import SqlVisualizer from 'components/syntaxhighlithter/SqlVisualizer';
import { Button, Grid, Menu, Typography } from '@mui/material';

export const RoutineManualExtractionContextMenu = ({
  contextMenu,
  selectedText,
  onClose,
  onSubmit
}) => {
  return (
    <Menu
      open={contextMenu !== null}
      anchorReference='anchorPosition'
      anchorPosition={
        contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
      }>
      <Grid
        container
        flexDirection='column'
        alignItems='center'
        style={{ padding: '8px 16px 8px 16px' }}>
        <Grid item lg={12} xs={12} alignSelf='flex-start'>
          <Typography style={{ fontSize: '1.4em' }}>Extracted Statement</Typography>
        </Grid>
        <Grid item lg={12} xs={12}>
          <SqlVisualizer
            text={selectedText}
            style={{
              maxWidth: `${window.innerWidth - 32}px`,
              maxHeight: `${window.innerHeight / 2}px`,
              overflow: 'auto',
              fontSize: '0.6em'
            }}
          />
        </Grid>
        <Grid item lg={12} xs={12} alignSelf='flex-end'>
          <Button
            style={{ marginRight: '16px' }}
            onClick={() => {
              onClose();
            }}>
            CANCEL
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              onClose();
              onSubmit();
            }}>
            ADD
          </Button>
        </Grid>
      </Grid>
    </Menu>
  );
};
