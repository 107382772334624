import { handleErrors, parseJson, encodeQueryData } from './ApiUtils';

export default class ReportApi {
  token = '';
  selectedTenantUuid = '';

  getDataSubjectReport(dataSubjectKey) {
    return fetch(`/api/v1/reports/datasubject_report_data?${encodeQueryData(dataSubjectKey)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson);
  }

  downloadDataSubjectReport({ name, value }) {
    var f = document.createElement('form');
    f.method = 'POST';
    f.action = `/api/v1/reports/datasubject_report?name=${encodeURIComponent(
      name
    )}&value=${encodeURIComponent(value)}`;
    f.target = '_blank';
    var element1 = document.createElement('INPUT');
    element1.name = 'access_token';
    element1.value = this.token;
    element1.type = 'hidden';
    f.appendChild(element1);

    if (this.selectedTenantUuid) {
      var element2 = document.createElement('INPUT');
      element2.name = 'x_bd_tenant';
      element2.value = this.selectedTenantUuid;
      element2.type = 'hidden';
      f.appendChild(element2);
    }
    //and some more input elements here

    //and dont forget to add a submit button
    document.body.appendChild(f);
    f.submit();
    return new Promise((resolve) => resolve());
  }

  downloadAudienceExport(selectedProcessings, targetDate) {
    var f = document.createElement('form');
    f.method = 'POST';
    f.action = '/api/v1/reports/datasubjects_audience';
    f.target = '_blank';
    var access_token_elem = document.createElement('INPUT');
    access_token_elem.name = 'access_token';
    access_token_elem.value = this.token;
    access_token_elem.type = 'hidden';
    f.appendChild(access_token_elem);

    if (this.selectedTenantUuid) {
      var element2 = document.createElement('INPUT');
      element2.name = 'x_bd_tenant';
      element2.value = this.selectedTenantUuid;
      element2.type = 'hidden';
      f.appendChild(element2);
    }

    var targetDateElem = document.createElement('INPUT');
    targetDateElem.name = 'targetDate';
    targetDateElem.value = targetDate;
    targetDateElem.type = 'hidden';
    f.appendChild(targetDateElem);

    var processingsUuids = document.createElement('INPUT');
    processingsUuids.name = 'processingsUuids';
    processingsUuids.value = selectedProcessings.map((p) => p.uuid);
    processingsUuids.type = 'hidden';
    f.appendChild(processingsUuids);

    document.body.appendChild(f);
    f.submit();
    return new Promise((resolve) => resolve());
  }

  downloadSystemDataSubjectsExport({ systemUuid }) {
    var f = document.createElement('form');
    f.method = 'POST';
    f.action = '/api/v1/reports/system_datasubjects_extraction';
    f.target = '_blank';
    var access_token_elem = document.createElement('INPUT');
    access_token_elem.name = 'access_token';
    access_token_elem.value = this.token;
    access_token_elem.type = 'hidden';
    f.appendChild(access_token_elem);

    if (this.selectedTenantUuid) {
      var element2 = document.createElement('INPUT');
      element2.name = 'x_bd_tenant';
      element2.value = this.selectedTenantUuid;
      element2.type = 'hidden';
      f.appendChild(element2);
    }

    var systemUuidElem = document.createElement('INPUT');
    systemUuidElem.name = 'systemUuid';
    systemUuidElem.value = systemUuid;
    systemUuidElem.type = 'hidden';
    f.appendChild(systemUuidElem);

    document.body.appendChild(f);
    f.submit();
    return new Promise((resolve) => resolve());
  }
}
