import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SystemItem from './SystemItem';
import { fetchDashboardTypeCounterIfAllowedLargeRequest } from 'actions/IssueMngtDashboardsActions';
import ResourceTypes from 'constants/ResourceTypes';
import PaginatedPanel from 'components/pagination/PaginatedPanel';
import { fetchSystemsPage } from 'actions/SystemsActions';

function SystemsList({
  filters,
  fetchSystemsPage,
  currentPage,
  fetchDashboardTypeCounterIfAllowedLargeRequest
}) {
  useEffect(
    function () {
      fetchSystemsPage({ page: 0, ...filters }).then(
        (response) =>
          response &&
          response.content.length > 0 &&
          fetchDashboardTypeCounterIfAllowedLargeRequest(response.content, 25, ResourceTypes.SYSTEM)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, fetchSystemsPage]
  );

  return (
    <div>
      <PaginatedPanel
        currentPage={currentPage}
        onPageSelection={(page) => fetchSystemsPage({ page, ...filters })}>
        {currentPage.content.map((sys) => {
          return <SystemItem key={sys.uuid} system={sys} />;
        })}
      </PaginatedPanel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentPage: state.systems.currentPage,
  filters: state.systems.filters.content
});

export default connect(mapStateToProps, {
  fetchSystemsPage,
  fetchDashboardTypeCounterIfAllowedLargeRequest
})(SystemsList);
