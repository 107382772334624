import { buildApiCallAction } from './ActionUtils';
import TaskGrantsApi from '../api/TaskGrantsApi';
import {
  FETCH_TASK_GRANTS,
  CREATE_TASK_GRANT,
  DELETE_TASK_GRANT
} from '../constants/ActionTypes';

const api = new TaskGrantsApi()

export const fetchTasksGrant = () => buildApiCallAction({
  api:api,
  apiFunction: (api)=>api.getAllTaskGrants(),
  actionType: FETCH_TASK_GRANTS
})

export const createTaskGrant = (taskGrant) => buildApiCallAction({
  api: api,
  apiFunction: api => api.postTaskGrant(taskGrant),
  actionType: CREATE_TASK_GRANT
})

export const deleteTaskGrant = (taskGrant) => buildApiCallAction({
  api: api,
  apiFunction: api => api.deleteTaskGrant(taskGrant),
  actionType: DELETE_TASK_GRANT,
  payload: taskGrant
})
