import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DataCategoryItemAutoComplete from '../utils/DataCategoryItemAutoComplete';
import LogicalRelationTypeAutoComplete from './LogicalRelationTypeAutoComplete';
import LogicalFieldItemAutoComplete from 'pages/logicalfields/commons/LogicalFieldItemAutoComplete';

const LogicalRelationModal = ({ open, onClose, onSubmit, logicalRelation }) => {
  const [logicalRelationDataPatch, setLogicalRelationDataPatch] = React.useState({});
  const { t } = useTranslation();
  const mergedLogicalRelationData = {
    ...logicalRelation,
    fromDataCategory:
      logicalRelation?.fromDataCategory || logicalRelation?.fromLogicalField?.dataCategory,
    toDataCategory:
      logicalRelation?.toDataCategory || logicalRelation?.toLogicalField?.dataCategory,
    ...logicalRelationDataPatch
  };
  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{t('logicalRelations.modal.dialog.title')}</DialogTitle>
      <DialogContent>
        <h4>{t('logicalRelations.modal.dialog.content')}</h4>
        <TextField
          variant='standard'
          fullWidth
          placeholder={t('logicalRelations.modal.fields.nameHint')}
          label={t('logicalRelations.modal.fields.name')}
          value={mergedLogicalRelationData.name || ''}
          onChange={(event) =>
            setLogicalRelationDataPatch({ ...logicalRelationDataPatch, name: event.target.value })
          }
        />
        <TextField
          variant='standard'
          fullWidth
          multiline
          placeholder={t('logicalRelations.modal.fields.descriptionHint')}
          label={t('logicalRelations.modal.fields.description')}
          value={mergedLogicalRelationData.description || ''}
          onChange={(event) =>
            setLogicalRelationDataPatch({
              ...logicalRelationDataPatch,
              description: event.target.value
            })
          }
        />
        <br></br>
        <LogicalRelationTypeAutoComplete
          logicalRelationType={mergedLogicalRelationData.relationType}
          fullWidth
          onChange={(relationType) => {
            setLogicalRelationDataPatch({
              ...logicalRelationDataPatch,
              relationType: relationType
            });
          }}
        />
        <br></br>
        <DataCategoryItemAutoComplete
          itemName={t('logicalRelations.modal.fields.toDataCategory')}
          placeholder={t('logicalRelations.modal.fields.toDataCategoryHint')}
          value={mergedLogicalRelationData.toDataCategory}
          onChange={(dataCategory) => {
            setLogicalRelationDataPatch({
              ...logicalRelationDataPatch,
              toDataCategory: dataCategory,
              toLogicalField: null
            });
          }}
        />
        <br></br>
        <LogicalFieldItemAutoComplete
          itemName={t('logicalRelations.modal.fields.toLogicalField')}
          placeholder={t('logicalRelations.modal.fields.toLogicalFieldHint')}
          value={mergedLogicalRelationData.toLogicalField}
          dataCategoryUuid={mergedLogicalRelationData.toDataCategory?.uuid}
          onChange={(logicalField) => {
            setLogicalRelationDataPatch({
              ...logicalRelationDataPatch,
              toLogicalField: logicalField,
              toDataCategory: logicalField
                ? logicalField?.dataCategory
                : mergedLogicalRelationData.toDataCategory
            });
          }}
        />
        <br></br>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => onSubmit(cleanUp(mergedLogicalRelationData), logicalRelationDataPatch)}
          disabled={
            (!mergedLogicalRelationData?.fromDataCategory &&
              !mergedLogicalRelationData?.fromLogicalField) ||
            (!mergedLogicalRelationData?.toDataCategory &&
              !mergedLogicalRelationData?.toLogicalField)
          }>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const cleanUp = (logicalRelation) => {
  let cleanedResource = { ...logicalRelation };
  if (cleanedResource.toLogicalField) {
    delete cleanedResource.toDataCategory;
  }
  if (cleanedResource.fromLogicalField) {
    delete cleanedResource.fromDataCategory;
  }
  return cleanedResource;
};

export default LogicalRelationModal;
