import React, { useEffect, useRef, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Grid, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataFlow } from 'actions/DataFlowsActions';
import SqlVisualizer from 'components/syntaxhighlithter/SqlVisualizer';
import { QueryStatementStatusIcons } from 'constants/QueryStatementStatusIcons';
import { EngagementCategoriesIcons } from 'constants/EngagementCategoriesIcons';
import EngagementCategories from 'constants/EngagementCategories';

export const EdgeContextMenu = ({ contextMenu, handleClose, edge }) => {
  const dataFlow = useSelector((state) => state.dataflows.content[edge.data.uuid]);
  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    if (!dataFlow) {
      dispatch(fetchDataFlow(edge?.data?.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, edge]);

  return (
    <Menu
      ref={ref}
      autoFocus={false}
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={
        contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
      }
      sx={{ maxHeight: '80%', maxWidth: '80%' }}>
      <div
        style={{
          padding: '8px 16px 16px 16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}>
        {EngagementCategoriesIcons[EngagementCategories.DATA_FLOW]}
        <Typography
          variant='body1'
          style={{
            textAlign: 'center',
            fontSize: '1.4em',
            paddingLeft: '8px'
          }}>
          Data Flow
        </Typography>
      </div>
      <EdgeContextMenuContent handleClose={handleClose} dataFlow={dataFlow} parentReference={ref} />
    </Menu>
  );
};

const EdgeContextMenuContent = ({ dataFlow, handleClose, parentReference }) => {
  const history = useHistory();
  const [ref, setRef] = useState();

  useEffect(() => {
    setRef(parentReference);
  }, [parentReference]);

  if (!ref?.current) {
    return null;
  }

  const sqlVisualizerStyle = {
    border: '1px solid',
    borderColor: 'lightgrey',
    borderRadius: '0.5em',
    padding: '1em',
    margin: '8px 16px 8px 16px',
    maxWidth: `${ref?.current?.clientWidth}px`,
    maxHeight: `${ref?.current?.clientHeight * 0.7}px`
  };

  if (dataFlow?.queryStatement) {
    return (
      <>
        <MenuItem
          onClick={() => {
            handleClose();
            history.push(`/dataflows/detail/${dataFlow?.uuid}`);
          }}>
          <Grid container>
            <Typography sx={{ paddingRight: 1 }} color={'text.secondary'}>{`Detail:`}</Typography>
            {dataFlow?.name}
            <OpenInNewIcon style={{ paddingLeft: '8px' }} />
          </Grid>
        </MenuItem>
        {dataFlow?.queryStatement?.systemRoutineUuid && (
          <MenuItem
            onClick={() => {
              handleClose();
              history.push(`/routines/${dataFlow?.queryStatement?.systemRoutineUuid}`);
            }}>
            <Grid container>
              <Typography color={'text.secondary'}>{`System Routine`}</Typography>
              <OpenInNewIcon style={{ paddingLeft: '8px' }} />
            </Grid>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            history.push(
              `/dataflows/queryparser/buckets/*/statements/${dataFlow?.queryStatement?.uuid}`
            );
          }}>
          <Grid container>
            <Typography
              sx={{ paddingRight: 1 }}
              color={'text.secondary'}>{`Query Statement:`}</Typography>
            {dataFlow?.queryStatement?.name}
            {QueryStatementStatusIcons[dataFlow?.queryStatement?.analysisStatus]}
            <OpenInNewIcon style={{ paddingLeft: '8px' }} />
          </Grid>
        </MenuItem>
        <SqlVisualizer style={sqlVisualizerStyle} text={dataFlow?.queryStatement?.queryText} />
      </>
    );
  }
  return (
    <MenuItem
      onClick={() => {
        handleClose();
        history.push(`/dataflows/detail/${dataFlow?.uuid}`);
      }}>
      <ListItemText>{dataFlow?.name}</ListItemText>
      <OpenInNewIcon style={{ alignSelf: 'flex-end', paddingLeft: '8px' }} />
    </MenuItem>
  );
};
