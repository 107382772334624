import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function WorkspaceModal({ onSubmit, open, onCancel, workspace, title, setOpen }) {
  const [editor, setEditor] = useState(workspace || {});
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onClose={() =>
        // Set 'open' to false, however you would do that with your particular code.
        setOpen(false)
      }>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          fullWidth
          label='Name'
          value={editor.name || ''}
          onChange={(event) => setEditor({ ...editor, name: event.target.value })}
        />
        <TextField
          fullWidth
          variant='standard'
          label='Description'
          value={editor.description || ''}
          onChange={(event) => setEditor({ ...editor, description: event.target.value })}
        />
        <FormControlLabel
          label='Default Workspace'
          control={
            <Checkbox
              checked={editor.defaultWorkspace || false}
              onChange={(event, checked) =>
                setEditor({ ...editor, defaultWorkspace: checked || undefined })
              }
            />
          }></FormControlLabel>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
            setEditor({});
          }}>
          {t('commons.actions.cancel')}
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onSubmit(editor);
            setEditor({});
          }}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WorkspaceModal;
