import { BlindataApi } from 'api/ApiUtils';

const RISK_DEFINITIONS_URL = '/api/v1/data-quality/assessment/risks';

export default class QualityAssessmentRiskDefinitionsApi extends BlindataApi {
  getRiskDefinitions(params) {
    return this.get(RISK_DEFINITIONS_URL, params);
  }

  getRiskDefinition(uuid) {
    return this.get(`${RISK_DEFINITIONS_URL}/${uuid}`);
  }

  postRiskDefinition(riskDefinition) {
    return this.post(RISK_DEFINITIONS_URL, riskDefinition);
  }

  putRiskDefinition(riskDefinition) {
    return this.put(`${RISK_DEFINITIONS_URL}/${riskDefinition.uuid}`, riskDefinition);
  }

  deleteRiskDefinition(uuid) {
    return this.delete(`${RISK_DEFINITIONS_URL}/${uuid}`);
  }

  getRiskCategories() {
    return this.get(`${RISK_DEFINITIONS_URL}/*/categories`);
  }

  exportRisks(params) {
    return this.download(`${RISK_DEFINITIONS_URL}/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }
}
