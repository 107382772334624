import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { searchTasks } from '../../../actions/TasksActions';
import ItemAutoCompleteModal from '../../../components/itemselector/ItemAutoCompleteModal';

const TaskSearchModal = ({
  title,
  searchTasks,
  tasks,
  open,
  onCancel,
  onSubmit,
  t
}) => (
  <ItemAutoCompleteModal
    title={title || t('tasks.searchTitle')}
    itemName={t('tasks.label')}
    open={open}
    keyProperty={'uuid'}
    nameProperty={'name'}
    values={Object.values(tasks)}
    onSearch={searchText => searchTasks(searchText)}
    handleCancel={onCancel}
    handleSubmit={onSubmit}
  />
);

const mapStateToProps = (state, props) => ({
  tasks: state.tasks.contentByUuid
});

TaskSearchModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default connect(
  mapStateToProps,
  { searchTasks }
)(withTranslation()(TaskSearchModal));
