import React, { useState } from 'react';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import LogicalRelationsCardActions from './LogicalRelationsCardActions';
import { ChipItem } from 'components/chiplist/ChipList';
import _ from 'lodash';
import CardDescription from 'components/carddescription/CardDescription';

const LogicalRelationsCardTable = ({ logicalRelations, dataCategory, logicalField }) => {
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [descriptionModalContent, setDescriptionModalContent] = useState();

  const redirect = (dataCategory, logicalField) =>
    dataCategory
      ? `/datacategories/${dataCategory?.uuid}?tab=logicalrelations`
      : `/logicalfields/${logicalField?.uuid}?tab=logicalrelations`;

  const pickColor = (dc, lf) => {
    if (dc) return dc?.uuid === dataCategory?.uuid ? 'default' : 'primary';
    if (lf) return lf?.uuid === logicalField?.uuid ? 'default' : 'primary';
  };
  return (
    <>
      <Table size='small' style={{ overflowX: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell>Relation</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell width='100%' align='right' hiddenMd hiddenSm>
              Description
            </TableCell>
            <TableCell width='100%' align='right'>
              Type
            </TableCell>
            <TableCell align='right' style={{ width: 100 }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logicalRelations.map((logicalRelation) => (
            <TableRow hover={true} style={{ cursor: 'pointer' }} key={logicalRelation.uuid}>
              <TableCell>
                <ChipItem
                  color={pickColor(
                    logicalRelation.fromDataCategory,
                    logicalRelation.fromLogicalField
                  )}
                  key={`${logicalRelation.uuid}1`}
                  size='small'
                  to={redirect(logicalRelation.fromDataCategory, logicalRelation.fromLogicalField)}>
                  {logicalRelation.fromDataCategory?.name ||
                    `${logicalRelation.fromLogicalField?.dataCategory.name} : ${logicalRelation.fromLogicalField?.name}` ||
                    ''}
                </ChipItem>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>{logicalRelation.name || '-'}</TableCell>
              <TableCell>
                <ChipItem
                  color={pickColor(logicalRelation.toDataCategory, logicalRelation.toLogicalField)}
                  size='small'
                  key={`${logicalRelation.uuid}2`}
                  to={redirect(logicalRelation.toDataCategory, logicalRelation.toLogicalField)}>
                  {logicalRelation.toDataCategory?.name ||
                    `${logicalRelation.toLogicalField?.dataCategory.name} : ${logicalRelation.toLogicalField?.name}` ||
                    '-'}
                </ChipItem>
              </TableCell>
              <TableCell width='100%' align='right' hiddenMd hiddenSm>
                {logicalRelation.description ? (
                  <>
                    {_.truncate(logicalRelation.description.replace(/\n/g, ' '), { length: '50' })}
                    <Tooltip title='Click to see the full description'>
                      <IconButton
                        size='small'
                        onClick={() => {
                          setDescriptionModalContent(logicalRelation);
                          setDescriptionModalOpen(true);
                        }}>
                        <DescriptionIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <></>
                )}
              </TableCell>
              <TableCell width='100%' align='right'>
                <Typography variant='caption'>{logicalRelation.relationType || '-'}</Typography>
              </TableCell>
              <TableCell align='right'>
                <LogicalRelationsCardActions
                  logicalRelation={logicalRelation}></LogicalRelationsCardActions>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DescriptionModal
        descriptionModalOpen={descriptionModalOpen}
        content={descriptionModalContent}
        setDescriptionModalOpen={setDescriptionModalOpen}
      />
    </>
  );
};
const DescriptionModal = ({ descriptionModalOpen, content, setDescriptionModalOpen }) => {
  if (!content) return <></>;
  return (
    <Dialog open={descriptionModalOpen}>
      <DialogTitle>
        <Typography variant='h5'>Description</Typography>
        <Typography variant='subtitle1' color='textSecondary'>
          {content.fromDataCategory?.name ||
            `${content.fromLogicalField?.dataCategory?.name} : ${content.fromLogicalField?.name}` ||
            '-'}{' '}
          {content.name}{' '}
          {content.toDataCategory?.name ||
            `${content.toLogicalField?.dataCategory?.name} : ${content.toLogicalField?.name}` ||
            '-'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CardDescription>{content?.description}</CardDescription>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setDescriptionModalOpen(false)} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default LogicalRelationsCardTable;
