import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@mui/material';
import Permissions, {
  permissionsEntities,
  PermissionsRoles
} from '../../../../constants/Permissions';
import { ChipItem } from '../../../../components/chiplist/ChipList';
import CustomRolesActions from './CustomRolesButtons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hasPermissions } from 'components/permissions/permissiosSelectors';
import _ from 'lodash';

function PermissionsEditor({ permissions = [], onChange }) {
  let currentUserPermissions = useSelector((state) => state.authentication.permissions);

  let allowedPermissionsEntities = Object.values(permissionsEntities).filter((entity) =>
    _.find(currentUserPermissions, (p) => p.startsWith(entity))
  );
  let allowedPermissionsSingle = [
    Permissions.APPROVE,
    Permissions.PROPOSE,
    Permissions.OPERATOR
  ].filter((p) => currentUserPermissions.indexOf(p) > -1);
  return (
    <>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>ENTITY</TableCell>
            <TableCell>{PermissionsRoles.VIEWER}</TableCell>
            <TableCell>{PermissionsRoles.EDITOR}</TableCell>
            <TableCell>{PermissionsRoles.ADMIN}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {allowedPermissionsEntities.map((entity) => (
            <PermissionEntityRow entity={entity} permissions={permissions} onChange={onChange} />
          ))}
          {allowedPermissionsSingle.map((p) => (
            <SinglePermissionRow
              onChange={onChange}
              permissions={permissions}
              targetPermission={p}></SinglePermissionRow>
          ))}
        </TableBody>
      </Table>
      {onChange && (
        <CustomRolesActions
          permissions={permissions}
          onApplyPermissions={(permissions) => onChange(permissions)}></CustomRolesActions>
      )}
    </>
  );
}

function PermissionEntityRow({ permissions, onChange, entity }) {
  const READ_PERMISSION = `${entity}_${PermissionsRoles.VIEWER}`,
    EDIT_PERMISSION = `${entity}_${PermissionsRoles.EDITOR}`,
    ADMIN_PERMISSION = `${entity}_${PermissionsRoles.ADMIN}`;
  let { t } = useTranslation();

  return (
    <TableRow style={{ height: 15 }}>
      <TableCell padding='none'>
        <ChipItem>{t(`permissions.${entity}`)}</ChipItem>
      </TableCell>
      <PermissionCheckboxCell
        permissions={permissions}
        targetPermission={READ_PERMISSION}
        onChange={onChange}
      />
      <PermissionCheckboxCell
        permissions={permissions}
        targetPermission={EDIT_PERMISSION}
        onChange={onChange}
      />
      <PermissionCheckboxCell
        permissions={permissions}
        targetPermission={ADMIN_PERMISSION}
        onChange={onChange}
      />
    </TableRow>
  );
}

function PermissionCheckboxCell({ permissions, targetPermission, onChange }) {
  let hasPermission = useSelector(hasPermissions([targetPermission]));
  return (
    <TableCell padding='none'>
      <Checkbox
        disabled={onChange == null || !hasPermission}
        checked={(permissions || []).includes(targetPermission)}
        onChange={(event, checked) =>
          onChange(changePermissions(checked, permissions, targetPermission))
        }></Checkbox>
    </TableCell>
  );
}

function SinglePermissionRow({ permissions, targetPermission, onChange }) {
  let { t } = useTranslation();

  return (
    <TableRow>
      <TableCell padding='none'>
        <ChipItem>{t(`permissions.${targetPermission}`)}</ChipItem>
      </TableCell>
      <PermissionCheckboxCell
        permissions={permissions}
        targetPermission={targetPermission}
        onChange={onChange}
      />
      <TableCell></TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
}

function changePermissions(checked, permissions, targetPermission) {
  if (checked) {
    return permissions.concat([targetPermission]);
  } else {
    return permissions.filter((p) => p !== targetPermission);
  }
}

export default PermissionsEditor;
