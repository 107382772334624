import { buildApiCallAction } from './ActionUtils';
import DataProductsApi from 'api/DataProductsApi';
import {
  FETCH_DATA_PRODUCTS_PAGE,
  FETCH_DATA_PRODUCT,
  MODIFY_DATA_PRODUCT,
  DELETE_DATA_PRODUCT,
  CREATE_DATA_PRODUCT,
  FETCH_DATA_PRODUCT_MESH,
  SET_DATA_PRODUCTS_FILTERS,
  CLEAR_DATA_PRODUCTS_FILTERS,
  EXPORT_DATA_PRODUCTS,
  FETCH_DATA_PRODUCT_DOMAINS,
  FETCH_DATA_PRODUCT_SERVICES_TYPES,
  FETCH_DATA_PRODUCTS_BY_NAME,
  FETCH_DATA_PRODUCT_BY_PORT_IDENTIFIER
} from '../constants/ActionTypes';

const api = new DataProductsApi();

export const fetchDataProductsPage = ({ page, size = 20, ...filters }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getDataProducts({ page, size, ...filters }),
    actionType: FETCH_DATA_PRODUCTS_PAGE
  });

export const fetchDataProductsByName = ({ page = 0, size = 30, search }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getDataProducts({ page, size, search }),
    actionType: FETCH_DATA_PRODUCTS_BY_NAME
  });

export const fetchDataProductByPortIdentifier = ({ page = 0, size = 1, portIdentifier }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getDataProducts({ page, size, portIdentifier: portIdentifier }),
    actionType: FETCH_DATA_PRODUCT_BY_PORT_IDENTIFIER,
    payload: (response) => ({
      ...(response?.content ? response.content[0] : null),
      portIdentifier: portIdentifier
    })
  });

export const fetchDataProduct = (dataProductUuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getDataProduct(dataProductUuid),
    actionType: FETCH_DATA_PRODUCT
  });

export const createDataProduct = (dataProduct) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postDataProduct(dataProduct),
    actionType: CREATE_DATA_PRODUCT
  });

export const modifyDataProduct = (dataProduct) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putDataProduct(dataProduct),
    actionType: MODIFY_DATA_PRODUCT
  });

export const deleteDataProduct = (dataProduct) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteDataProduct(dataProduct),
    actionType: DELETE_DATA_PRODUCT,
    payload: dataProduct
  });

export const fetchDataProductMesh = (dataProductUuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getDataProductMesh(dataProductUuid),
    actionType: FETCH_DATA_PRODUCT_MESH,
    payload: (response) => ({ ...response, dataProductUuid })
  });

export const setDataProductsFilters = (filters) => (dispatch) => {
  dispatch({ type: SET_DATA_PRODUCTS_FILTERS, payload: filters });
};

export const clearDataProductsFilters = (dispatch) => {
  dispatch({ type: CLEAR_DATA_PRODUCTS_FILTERS });
};

export const exportDataProducts = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportDataProducts(params),
    actionType: EXPORT_DATA_PRODUCTS
  });

export const fetchDataProductDomains = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getDataProductDomains(params),
    actionType: FETCH_DATA_PRODUCT_DOMAINS
  });

export const fetchDataProductServicesTypes = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getDataProductServicesTypes(params),
    actionType: FETCH_DATA_PRODUCT_SERVICES_TYPES
  });
