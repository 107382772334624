import React, { useState } from 'react';
import _ from 'lodash';
import {
  fetchDataActorTaskAssignments,
  removeDataActorTaskAssignment,
  createDataActorTaskAssignment
} from '../../../actions/TaskAssignmentsActions';
import { withTranslation } from 'react-i18next';
import { withLoader } from '../../../components/loader/Loader';
import { connect } from 'react-redux';
import { ChipItem } from '../../../components/chiplist/ChipList';
import TaskAssignmentModal from './TaskAssignmentModal';
import { withOpenCloseHandler } from '../../../components/openclosehandler/OpenCloseHandler';
import { ProposalConfirmationIconButton } from '../../workflows/proposals/commons/withProposalConfirmation';
import {
  ProposalResourceTypes,
  ProposalVariationTypes
} from '../../workflows/proposals/commons/ProposalConstants';
import {
  IconButton,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import { Add, DeleteOutlined } from '@mui/icons-material';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import withPermissions from '../../../components/permissions/withPermissions';
import TaskAssignmentsExportButton from '../utils/TaskAssignmentsExportButton';

function TaskAssignments({
  task,
  dataActor,
  taskAssignments,
  createDataActorTaskAssignment,
  removeDataActorTaskAssignment,
  t,
  isOpen,
  onToggleOpen
}) {
  let [page, setPage] = useState(0);
  let [size, setSize] = useState(20);
  return <>
    <h4>{task ? t('tasks.sections.associatedDataProcessors') : 'Assigned tasks'}</h4>
    <Table size='small'>
      <TableHead>
        <TableCell padding='checkbox'>
          <Authorize
            hasAnyPermissions={[
              Permissions.PROPOSE,
              Permissions.TASKS_EDITOR,
              Permissions.DATA_ACTORS_EDITOR
            ]}>
            <IconButton size='small' onClick={onToggleOpen}>
              <Add></Add>
            </IconButton>
          </Authorize>
        </TableCell>
        {dataActor && <TableCell>Task</TableCell>}
        {task && <TableCell>DataActor</TableCell>}
        <TableCell>Role</TableCell>
        <TableCell>Notes</TableCell>
      </TableHead>
      <TableBody>
        {taskAssignments.slice(page * size, page * size + size).map((taskAssignment) => (
          <TableRow key={taskAssignment.uuid} hover>
            <TableCell padding='checkbox'>
              <ProposalConfirmationIconButton
                size='small'
                AuthorizeProps={{
                  hasAnyPermissions: [Permissions.TASKS_EDITOR, Permissions.DATA_ACTORS_EDITOR]
                }}
                ProposeButtonProps={{
                  resourceType: ProposalResourceTypes.TASK_ASSIGNMENT,
                  resourceIdentifier: taskAssignment.uuid,
                  resourceName: `${taskAssignment.dataActorName} -> ${taskAssignment.taskName}`,
                  variationType: ProposalVariationTypes.DELETE
                }}
                onClick={() => removeDataActorTaskAssignment(taskAssignment)}>
                <DeleteOutlined />
              </ProposalConfirmationIconButton>
            </TableCell>
            {dataActor && (
              <TableCell>
                <ChipItem size='small' to={`/tasks/${taskAssignment.taskUuid}`}>
                  {taskAssignment.taskName}
                </ChipItem>
              </TableCell>
            )}
            {task && (
              <TableCell>
                <ChipItem size='small' to={`/data_actors/${taskAssignment.dataActorUuid}`}>
                  {taskAssignment.dataActorName}
                </ChipItem>
              </TableCell>
            )}
            <TableCell>{taskAssignment.role}</TableCell>
            <TableCell breakWord>{taskAssignment.notes}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell>
            <TaskAssignmentsExportButton
              size={taskAssignments.length}
              dataActorUuid={dataActor?.uuid}
              taskUuid={task?.uuid}
            />
          </TableCell>
          <TablePagination
            size='small'
            rowsPerPageOptions={[20, 50, 100]}
            page={page}
            rowsPerPage={size}
            count={taskAssignments.length}
            onPageChange={(event, page) => setPage(page)}
            onRowsPerPageChange={(event) => setSize(event.target.value)}
          />
        </TableRow>
      </TableFooter>
    </Table>
    <TaskAssignmentModal
      open={isOpen}
      task={task}
      dataActor={dataActor}
      handleCancel={onToggleOpen}
      handleSubmit={(taskAssignment) => {
        onToggleOpen();
        createDataActorTaskAssignment(taskAssignment);
      }}
    />
  </>;
}

const mapStateToProps = (state, props) => ({
  taskAssignments:
    (props.task && state.taskAssignments.byTask[props.task.uuid]) ||
    (props.dataActor && state.taskAssignments.byDataActor[props.dataActor.uuid]) ||
    []
});

export default _.flow([
  withPermissions({ hasPermissions: [Permissions.TASKS_VIEWER, Permissions.DATA_ACTORS_VIEWER] }),
  withOpenCloseHandler,
  withLoader({
    onLoad: ({ task, dataActor, fetchDataActorTaskAssignments }) =>
      fetchDataActorTaskAssignments({ task, dataActor })
  }),
  connect(mapStateToProps, {
    fetchDataActorTaskAssignments,
    removeDataActorTaskAssignment,
    createDataActorTaskAssignment
  }),
  withTranslation()
])(TaskAssignments);
