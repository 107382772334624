/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { Switch, Route } from 'react-router';
import AssignmentsListPage from './list/AssignmentsListPage';

export default function (props) {
    return (
        <>
            <Switch>
                <Route path={`${props.match.url}`} component={AssignmentsListPage}></Route>
            </Switch>
        </>
    );
}