import { buildApiCallAction } from './ActionUtils';
import CampaignsApi from 'api/CampaignsApi';
import {
  SEARCH_CAMPAIGNS,
  FETCH_CAMPAIGNS_PAGE,
  FETCH_CAMPAIGN,
  CREATE_CAMPAIGN,
  MODIFY_CAMPAIGN,
  DELETE_CAMPAIGN,
  EXPORT_CAMPAIGNS
} from 'constants/ActionTypes';

const api = new CampaignsApi();

export const searchCampaigns = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getCampaigns(params),
    actionType: SEARCH_CAMPAIGNS
  });

export const fetchCampaignsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getCampaigns(params),
    actionType: FETCH_CAMPAIGNS_PAGE
  });

export const fetchCampaign = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getCampaign(uuid),
    actionType: FETCH_CAMPAIGN
  });

export const createCampaign = (campaign) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postCampaign(campaign),
    actionType: CREATE_CAMPAIGN
  });

export const modifyCampaign = (campaign) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putCampaign(campaign),
    actionType: MODIFY_CAMPAIGN
  });

export const deleteCampaign = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteCampaign(uuid),
    actionType: DELETE_CAMPAIGN,
    payload: { uuid }
  });

export const exportCampaigns = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportCampaigns(params),
    actionType: EXPORT_CAMPAIGNS
  });
