import React from 'react';
import TableCellMUI from '@mui/material/TableCell';

const TableCell = ({ sx, hiddenSm, hiddenMd, breakWord, children, ...otherProps }) => {
  return (
    <TableCellMUI
      sx={[
        {
          display: {
            xs: hiddenSm ? 'none' : 'table-cell',
            sm: hiddenSm ? 'none' : 'table-cell',
            md: hiddenMd ? 'none' : 'table-cell',
            lg: 'table-cell'
          },
          breakWord: breakWord && 'break-word',
          '&.MuiTableCell-head': {
            backgroundColor: 'rgb(245, 245, 245) !important'
          },
          '&.MuiTableCell-sizeSmall': {
            fontSize: '0.785rem',
            whiteSpace: 'pre-wrap'
          }
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...otherProps}>
      {children}
    </TableCellMUI>
  );
};

export default TableCell;
