import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Tooltip } from '@mui/material';
import StewarshipRoleCreateFAB from './StewarshipRoleCreateFAB';
import StewardshipRolesList from './StewardshipRolesList';
import PreAuthorize from '../../../../components/permissions/Authorize';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Permissions from '../../../../constants/Permissions';
import StewardshipExportRoles from '../../common/StewardshipExportRoles';
import StewardshipConfigButton from '../../config/StewardshipConfigButton';
import ExternalHelper from 'components/help/ExternalHelper';
import Breadcrumbs from 'pages/sidebar/Navigation';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

export default function StewardshipRolesListPage() {
  let { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.stewardship'), to: '/stewardship/responsibilities' },
          { text: t('stewardship.roles.header') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('stewardship.roles.header'),
          category: EngagementCategories.STEWARDSHIP,
          action: EngagementActions.LIST
        }}
      />
      <Grid container alignItems='center'>
        <Grid item>
          <Typography variant='h4'>
            Roles
            <ExternalHelper resourceType='stewardshipRoles' />
          </Typography>
        </Grid>
        <Grid item>
          <StewardshipConfigButton />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:roles-list'}
            iconSize={'small'}
            pageTitle={t('stewardship.roles.header')}
          />
        </Grid>
        <Grid item>
          <StewardshipExportRoles></StewardshipExportRoles>
        </Grid>

        <Grid item>
          <Tooltip title='User Assignments'>
            <IconButton size='small' component={Link} to={`/stewardship/assignments`}>
              <AccountCircleIcon></AccountCircleIcon>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <StewardshipRolesList />
      <PreAuthorize hasPermissions={[Permissions.STEWARDSHIP_ADMIN]}>
        <StewarshipRoleCreateFAB />
      </PreAuthorize>
    </>
  );
}
