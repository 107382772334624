import crudReducers from './utils';
import { combineReducers } from 'redux';
import filtersReducer from './utils/filters';

const filtersDefault = {
  schema: '',
  system: undefined,
  search: '',
  catalog: '',
  type: '',
  status: undefined,
  createdAtGte: undefined,
  updatedAtGte: undefined,
  createdAtLt: undefined,
  updatedAtLt: undefined
};

export default combineReducers({
  ...crudReducers({ resource: 'SYSTEM_ROUTINE', keyProperty: 'uuid' }),
  filters: filtersReducer({ resource: 'SYSTEM_ROUTINE', filtersDefault })
});
