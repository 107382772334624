import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DialogTitle, DialogActions, DialogContent, Typography, Grid } from '@mui/material';
import DataActorItemAutoComplete from '../../data_actors/utils/DataActorItemAutoComplete';
import {
  ProposalResourceTypes,
  ProposalVariationTypes
} from '../../workflows/proposals/commons/ProposalConstants';
import ProposeButton from '../../workflows/proposals/commons/ProposeButton';
import TaskItemAutoComplete from '../utils/TaskItemAutoComplete';
import { ProposalItemAddButton } from '../../workflows/proposals/commons/ProposalItemSelector';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import TaskAssignmentRoleAutocomplete from './TaskAssignmentRoleAutocomplete';
import { Warning } from '@mui/icons-material';
import TransferExtraEuPatchModal from './TransferExtraEuPatchModal';

function TaskAssignmentModal({ ...props }) {
  let initialState = {
    task: props.task,
    dataActor: props.dataActor,
    role: undefined,
    notes: undefined
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  function submitTaskAssignment() {
    let taskAssignment = createTaskAssignment(state);
    props.handleSubmit(taskAssignment);
  }

  function createTaskAssignment(state) {
    return {
      dataActorUuid: state.dataActor && state.dataActor.uuid,
      dataActorName: state.dataActor && state.dataActor.name,
      taskUuid: state.task && state.task.uuid,
      taskName: state.task && state.task.name,
      role: state.role,
      notes: state.notes
    };
  }

  const taskAssignment = createTaskAssignment(state);
  return (
    <Dialog fullScreen={props.fullScreen} fullWidth open={props.open}>
      <DialogTitle>Task Assignment</DialogTitle>
      <DialogContent>
        {!props.dataActor && (
          <span>
            <DataActorItemAutoComplete
              value={state.dataActor}
              onChange={(dataActor) => dispatch({ dataActor })}
            />
            <ProposalItemAddButton
              proposal={state.proposal}
              resourceType={ProposalResourceTypes.DATA_ACTOR}
              onSubmit={(dataActor) => dispatch({ dataActor })}
            />
          </span>
        )}
        <TransferExtraEuWarning
          dataActor={state.dataActor}
          task={state.task}
          onFixed={(updatedTask) => dispatch({ task: updatedTask })}
        />

        {!props.task && (
          <span>
            <TaskItemAutoComplete value={state.task} onChange={(task) => dispatch({ task })} />
            <ProposalItemAddButton
              proposal={state.proposal}
              resourceType={ProposalResourceTypes.TASK}
              onSubmit={(task) => dispatch({ task })}
            />
          </span>
        )}

        <TaskAssignmentRoleAutocomplete
          value={state.role || ''}
          onChange={(role) => dispatch({ role })}
        />

        <TextField
          variant='standard'
          placeholder='Enter the notes'
          margin='normal'
          label='Notes'
          multiline
          fullWidth
          value={state.notes || ''}
          onChange={(event) => dispatch({ notes: event.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button children='Cancel' onClick={props.handleCancel} />
        <ProposeButton
          disabled={!state.dataActor || !state.task}
          resourceIdentifier={state.uuid}
          resourceName={`${taskAssignment.dataActorName} -> ${taskAssignment.taskName}`}
          resourceType={ProposalResourceTypes.TASK_ASSIGNMENT}
          resourcePayload={taskAssignment}
          variationType={state.uuid ? ProposalVariationTypes.PUT : ProposalVariationTypes.POST}
          onProposalChange={(proposal) => dispatch({ proposal })}
        />
        <Authorize hasPermissions={[Permissions.DATA_ACTORS_EDITOR, Permissions.TASKS_EDITOR]}>
          <Button
            children='Submit'
            variant='contained'
            color='primary'
            disabled={!state.dataActor || !state.task || state.proposal}
            onClick={() => submitTaskAssignment()}
          />
        </Authorize>
      </DialogActions>
    </Dialog>
  );
}

function TransferExtraEuWarning({ dataActor, task, onFixed }) {
  let [patchOpen, setPatchOpen] = React.useState(false);
  if (!dataActor || !task) return null;
  if (isExtraEu(dataActor) && isRegisteredInProcessingTransfer(dataActor, task)) {
    return (
      <Grid container alignItems='center'>
        <Grid item>
          <Warning color='secondary' />
        </Grid>
        <Grid item>
          <Typography variant='caption'>
            Extra EU Data Actor not registered in task's processing(s)!
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <Authorize hasPermissions={[Permissions.PROCESSINGS_EDITOR]}>
            <Button size='small' onClick={() => setPatchOpen(true)}>
              Fix this
            </Button>
          </Authorize>
          <TransferExtraEuPatchModal
            open={patchOpen}
            task={task}
            dataActor={dataActor}
            processingsToPatch={uncompliantProcessings(dataActor, task)}
            onCancel={() => setPatchOpen(false)}
            onSubmit={(updatedTask) => {
              setPatchOpen(false);
              onFixed(updatedTask);
            }}></TransferExtraEuPatchModal>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

function isExtraEu(dataActor) {
  let country = _.get(dataActor, 'country', null);
  let warning = country && !europeanCountryCodes.includes(country);
  return warning;
}

function isRegisteredInProcessingTransfer(dataActor, task) {
  return uncompliantProcessings(dataActor, task).length > 0;
}

function uncompliantProcessings(dataActor, task) {
  let dataActorUuid = dataActor.uuid;
  let processings = task.processings || [];
  let uncompliantProcessings = [];
  for (let p of processings) {
    const includeDataActor = _(p.transferExtraEuOrganizations || [])
      .map((t) => t.organization.uuid)
      .includes(dataActorUuid);
    if (!includeDataActor) {
      uncompliantProcessings.push(p);
    }
  }
  return uncompliantProcessings;
}

function reducer(state, action) {
  return { ...state, ...action };
}

TaskAssignmentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  task: PropTypes.object,
  dataActor: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default _.flow([])(TaskAssignmentModal);

const europeanCountryCodes = [
  'AT',
  'IT',
  'BE',
  'LV',
  'GB',
  'LT',
  'HR',
  'LU',
  'CY',
  'MT',
  'CZ',
  'NL',
  'DK',
  'PL',
  'EE',
  'PT',
  'FI',
  'RO',
  'FR',
  'SK',
  'DE',
  'SI',
  'GR',
  'ES',
  'HU',
  'SE',
  'IE',
  'NO',
  'IS',
  'LI'
];
