import { BlindataApi } from './ApiUtils';

const ISSUES_URL = '/api/v1/issue-management/issues';

export default class IssuesApi extends BlindataApi {
  getIssues(params) {
    return this.get(ISSUES_URL, params);
  }

  getMyIssues(params) {
    return this.get(`${ISSUES_URL}/*/my-issues`, params);
  }

  getIssue(uuid) {
    return this.get(`${ISSUES_URL}/${uuid}`);
  }

  postIssue(issue) {
    return this.post(ISSUES_URL, issue);
  }

  putIssue(issue) {
    return this.put(`${ISSUES_URL}/${issue.uuid}`, issue);
  }

  patchIssue(issueUuid, patch) {
    return this.patch(`${ISSUES_URL}/${issueUuid}`, patch);
  }

  deleteIssue(uuid) {
    return this.delete(`${ISSUES_URL}/${uuid}`);
  }
  exportIssues(params) {
    return this.download(`${ISSUES_URL}/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }
}
