import React from 'react';
import { connect } from 'react-redux';
import DataFlowEditor from './DataFlowEditor';
import _ from 'lodash';
import Authorize from '../../../components/permissions/Authorize';

import {
  editDataFlow,
  createDataFlow,
  modifyDataFlow,
  fetchDataFlow,
  clearDataFlowEditor
} from '../../../actions/DataFlowsActions';
import { Typography, Button, Paper } from '@mui/material';
import { withLoader } from '../../../components/loader/Loader';
import { withTranslation } from 'react-i18next';
import Permissions from '../../../constants/Permissions';
import Breadcrumbs from 'pages/sidebar/Navigation';

const DataFlowEditorPage = ({
  dataFlowUuid,
  editor,
  editDataFlow,
  clearDataFlowEditor,
  createDataFlow,
  modifyDataFlow,
  t,
  history
}) => (
  <>
    {dataFlowUuid ? (
      <Breadcrumbs
        elements={[
          { text: t('sidebar.data_flows'), to: '/dataflows' },
          { text: editor?.name, to: `/dataflows/detail/${dataFlowUuid}` },
          { text: 'Modify', to: `/dataflows/editor/modify/${dataFlowUuid}` }
        ]}
      />
    ) : (
      <Breadcrumbs
        elements={[
          { text: t('sidebar.data_flows'), to: '/dataflows' },
          { text: 'Create', to: `/dataflows/editor/create` }
        ]}
      />
    )}
    <Typography variant='h4'>
      {dataFlowUuid
        ? t('dataflows.modifyDataFlowTitle', { dataFlowName: editor.name })
        : t('dataflows.createDataFlowTitle')}
      <Authorize hasAnyPermissions={[Permissions.SYSTEMS_ADMIN, Permissions.SYSTEMS_EDITOR]}>
        <Button
          style={{ float: 'right' }}
          color='primary'
          variant='contained'
          disabled={isNotCompleteEditor(editor)}
          onClick={() => {
            if (dataFlowUuid) {
              modifyDataFlow(editor).then((df) => {
                history.push(`/dataflows/detail/${df.uuid}`);
                clearDataFlowEditor();
              });
            } else {
              createDataFlow(editor).then((df) => {
                history.push(`/dataflows/detail/${df.uuid}`);
                clearDataFlowEditor();
              });
            }
          }}>
          {dataFlowUuid ? t('commons.actions.modify') : t('commons.actions.create')}
        </Button>
      </Authorize>
    </Typography>
    <Paper style={{ padding: 16, margin: '8px 0 8px 0' }}>
      <DataFlowEditor editor={editor} onChange={editDataFlow} />
    </Paper>
  </>
);

function isNotCompleteEditor(editor) {
  return !(
    (editor.fromSystem || editor.fromPhysicalEntity || editor.fromPhysicalField) &&
    (editor.toSystem || editor.toPhysicalEntity || editor.toPhysicalField) &&
    editor.name
  );
}

const mapStateToProps = (state, props) => ({
  editor: state.dataflows.editor,
  dataFlowUuid: props.match.params['dataFlowUuid']
});

export default _.flow([
  withTranslation(),
  withLoader({
    onLoad: ({ dataFlowUuid, fetchDataFlow, editDataFlow, clearDataFlowEditor }) => {
      clearDataFlowEditor();
      if (dataFlowUuid) {
        fetchDataFlow(dataFlowUuid).then((df) => editDataFlow(df));
      }
    }
  }),
  connect(mapStateToProps, {
    editDataFlow,
    createDataFlow,
    fetchDataFlow,
    modifyDataFlow,
    clearDataFlowEditor
  })
])(DataFlowEditorPage);
