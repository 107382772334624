import { buildApiCallAction } from './ActionUtils';
import {
  FETCH_DATA_ACTOR_TASK_ASSIGNMENTS,
  CREATE_DATA_ACTOR_TASK_ASSIGNMENT,
  REMOVE_DATA_ACTOR_TASK_ASSIGNMENT,
  FETCH_DATA_ACTOR_TASK_ASSIGNMENTS_PAGE
} from '../constants/ActionTypes';
import TaskAssignmentsApi from '../api/TaskAssignmentsApi';

const api = new TaskAssignmentsApi();

export const fetchDataActorTaskAssignmentsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTaskAssignmentsPage(params),
    actionType: FETCH_DATA_ACTOR_TASK_ASSIGNMENTS_PAGE
  });

export const fetchDataActorTaskAssignments = ({ dataActor, task, processing }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAllTaskAssignments({ dataActor, task, processing }),
    actionType: FETCH_DATA_ACTOR_TASK_ASSIGNMENTS
  });

export const createDataActorTaskAssignment = (taskAssignment) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postTaskAssignment(taskAssignment),
    actionType: CREATE_DATA_ACTOR_TASK_ASSIGNMENT
  });

export const removeDataActorTaskAssignment = (taskAssignment) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteTaskAssignment(taskAssignment),
    actionType: REMOVE_DATA_ACTOR_TASK_ASSIGNMENT,
    payload: taskAssignment
  });

export const fetchTaskAssignmentsRoles = () =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTaskAssignmentsRoles(),
    actionType: 'FETCH_TASK_ASSIGNMENTS_ROLES'
  });

export const exportTaskAssignments = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportTaskAssignments(params),
    actionType: 'TASK_ASSIGNMENTS_EXPORT'
  });
