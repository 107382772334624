import React, { Component } from 'react';
import Button from '@mui/material/Button';
import LogicalFieldsEditModal from './LogicalFieldsEditModal';
import {
  requestLogicalFields,
  createLogicalField,
  deleteLogicalField
} from '../../../actions/LogicalFieldsActions';
import { connect } from 'react-redux';

class LogicalFieldsEditButton extends Component {
  state = {
    open: false
  };

  render() {
    return (
      <span>
        <Button children={this.props.label} onClick={() => this.setState({ open: true })} />
        <LogicalFieldsEditModal
          dataCategory={this.props.dataCategory}
          logicalFields={this.props.logicalFields}
          open={this.state.open}
          handleClose={() => this.setState({ open: false })}
          createLogicalField={this.props.createLogicalField}
          deleteLogicalField={this.props.deleteLogicalField}
        />
      </span>
    );
  }
}

const mapStateToProps = (state, props) => ({
  logicalFields: state.logicalfields.contentByDataCategory[props.dataCategory.uuid] || []
});

export default connect(mapStateToProps, {
  requestLogicalFields,
  createLogicalField,
  deleteLogicalField
})(LogicalFieldsEditButton);
