import { buildApiCallAction } from '../ActionUtils';
import { SEND_ERROR } from '../../constants/ActionTypes';
import ErrorLoggingApi from '../../api/ErrorLoggingApi';

const api = new ErrorLoggingApi();

export const sendError = (error) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postError(error),
    actionType: SEND_ERROR
  });
