import { buildApiCallAction } from 'actions/ActionUtils';
import ProbesTagsApi from 'api/quality/probes/ProbesTagsApi';
import {
  FETCH_QUALITY_TAG,
  FETCH_QUALITY_TAGS_PAGE,
  CREATE_QUALITY_TAG,
  MODIFY_QUALITY_TAG,
  DELETE_QUALITY_TAG,
  RESTORE_QUALITY_TAG,
  SEARCH_QUALITY_TAGS
} from 'constants/ActionTypes';

const api = new ProbesTagsApi();

export const fetchTag = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTag(uuid),
    actionType: FETCH_QUALITY_TAG
  });

export const fetchTagsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTagsPage(params),
    actionType: FETCH_QUALITY_TAGS_PAGE
  });

export const restoreProbesFromTag = (tagUuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.restoreProbesFromTag(tagUuid),
    actionType: RESTORE_QUALITY_TAG
  });

export const createTag = (tag) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postTag(tag),
    actionType: CREATE_QUALITY_TAG
  });

export const deleteTag = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteTag(uuid),
    actionType: DELETE_QUALITY_TAG
  });

export const searchTags = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTagsPage(params),
    actionType: SEARCH_QUALITY_TAGS
  });

export const patchTag = (tag) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.patchTag(tag),
    actionType: MODIFY_QUALITY_TAG
  });
