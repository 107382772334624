import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchRoutineSchemas } from 'actions/RoutinesActions';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';

function RoutineSchemaAutoComplete({ fetchRoutineSchemas, schema, onChange, required, ...props }) {
  const [schemas, setSchemas] = useState([]);
  useEffect(() => {
    fetchRoutineSchemas({ search: '' }).then((response) =>
      setSchemas(response.content.map((schema) => ({ schema: schema })))
    );
  }, [fetchRoutineSchemas, setSchemas]);

  return (
    <ItemAutoComplete
      {...props}
      required={required}
      itemName='Schema'
      values={schemas}
      value={schema || null}
      nameProperty={'schema'}
      onSearch={(inputValue) => {
        fetchRoutineSchemas({ search: inputValue }).then((response) =>
          setSchemas(response.content.map((schema) => ({ schema: schema })))
        );
        onChange({ schema: inputValue });
      }}
      onChange={(newValue) => onChange(newValue)}></ItemAutoComplete>
  );
}

export default connect(null, { fetchRoutineSchemas })(RoutineSchemaAutoComplete);
