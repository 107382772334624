import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Authorize from '../../../components/permissions/Authorize';

import { fetchDataFlow, deleteDataFlow } from '../../../actions/DataFlowsActions';
import { createUrlItem } from 'pages/Routes';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Grid
} from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import Breadcrumbs from 'pages/sidebar/Navigation';
import { withLoader } from '../../../components/loader/Loader';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import ConfirmButton from '../../../components/confirmbutton/ConfirmButton';
import Permissions from '../../../constants/Permissions';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const DataFlowDetail = ({ dataFlow, deleteDataFlow, t }) => (
  <>
    <Breadcrumbs
      elements={[
        { text: t('sidebar.data_flows'), to: '/dataflows' },
        { text: dataFlow?.name, to: `/dataflows/detail/${dataFlow?.uuid}` }
      ]}
    />
    <Tracking
      info={{
        pageTitle: t('dataflows.titleDetailPage', { dataFlowName: dataFlow.name }),
        category: EngagementCategories.DATA_FLOW,
        action: EngagementActions.DETAIL
      }}
    />
    <Grid container direction='row' alignContent='center'>
      <Grid item style={{ flexGrow: 1 }}>
        <Typography variant='h4'>
          {t('dataflows.titleDetailPage', { dataFlowName: dataFlow.name })}
        </Typography>
      </Grid>
      <Grid item>
        <FavoritesWidget
          itemIdentifier={`DATAFLOW:${dataFlow.uuid}`}
          pageTitle={t('dataflows.titleDetailPage', {
            dataFlowName: dataFlow.name
          })}></FavoritesWidget>
      </Grid>
    </Grid>

    <Card style={{ margin: '8px 0 8px 0' }}>
      <CardHeader title={dataFlow.name} subheader={dataFlow.scope} />
      <CardContent>
        {dataFlow.queryStatement && (
          <>
            <Typography variant='body1'>{t('dataflows.queryStatementHeader')}</Typography>
            <ChipItem
              label={dataFlow.queryStatement.name}
              tooltip={t('dataflows.queryStatementHeaderTooltip')}
              to={`/dataflows/queryparser/buckets/${dataFlow.queryStatement.queryBucket}/statements/${dataFlow.queryStatement.uuid}`}
            />
          </>
        )}
        {dataFlow.description && (
          <>
            <Typography variant='body1'>{t('dataflows.descriptionHeader')}</Typography>
            <p>{dataFlow.description}</p>
          </>
        )}
        <Typography variant='body1'>{t('dataflows.fromToHeader')}</Typography>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 200 }} padding='normal' />
              <TableCell>{t('dataflows.fields.from')}</TableCell>
              <TableCell>{t('dataflows.fields.to')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>{t('systems.header')}</strong>
              </TableCell>
              <TableCell>{renderCell(dataFlow.fromSystem, systemLink, t)}</TableCell>
              <TableCell>{renderCell(dataFlow.toSystem, systemLink, t)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t('physicalEntities.header')}</strong>
              </TableCell>
              <TableCell>
                {renderCell(dataFlow.fromPhysicalEntity, physicalEntityLink, t)}
              </TableCell>
              <TableCell>{renderCell(dataFlow.toPhysicalEntity, physicalEntityLink, t)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t('physicalFields.header')}</strong>
              </TableCell>
              <TableCell>{renderCell(dataFlow.fromPhysicalField, physicalFieldLink, t)}</TableCell>
              <TableCell>{renderCell(dataFlow.toPhysicalField, physicalFieldLink, t)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {(dataFlow.dataCategories || []).length > 0 && (
          <>
            <Typography style={{ marginTop: 16 }} variant='body1'>
              {t('dataflows.aboutDataCategoriesHeader')}
            </Typography>
            <ChipWrapper>
              {(dataFlow.dataCategories || []).map((dc) => (
                <ChipItem
                  key={dc.uuid}
                  id={dc.uuid}
                  label={dc.name}
                  tooltip={dc.description}
                  to={`/datacategories/${dc.uuid}`}
                />
              ))}
            </ChipWrapper>
          </>
        )}
        {(dataFlow.logicalFields || []).length > 0 && (
          <>
            <Typography style={{ marginTop: 16 }} variant='body1'>
              {t('dataflows.aboutLogicalFieldsHeader')}
            </Typography>
            <ChipWrapper>
              {(dataFlow.logicalFields || []).map((lf) => (
                <ChipItem
                  key={lf.uuid}
                  id={lf.uuid}
                  label={lf.name}
                  tooltip={lf.description}
                  to={createUrlItem('LOGICAL_FIELD', lf.uuid)}
                />
              ))}
            </ChipWrapper>
          </>
        )}
      </CardContent>

      <CardActions>
        <Authorize hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
          <Button to={`/dataflows/editor/modify/${dataFlow.uuid}`} component={Link}>
            {t('commons.actions.modify')}
          </Button>
        </Authorize>
        <Authorize hasPermissions={[Permissions.SYSTEMS_ADMIN]}>
          <ConfirmButton
            message={t('dataflows.deleteConfirmationMessage', {
              dataFlowName: dataFlow.name || t('commons.placeholder.undefined')
            })}
            onClick={() => deleteDataFlow(dataFlow)}>
            {t('commons.actions.delete')}
          </ConfirmButton>
        </Authorize>
      </CardActions>
    </Card>
  </>
);

function renderCell(elem, linkFn, t = () => null) {
  if (elem) {
    return <ChipItem label={_.get(elem, 'name')} to={linkFn(elem)} />;
  } else {
    return t('commons.placeholders.undefined');
  }
}
const systemLink = (sys) => `/systems/${sys.uuid}`;
const physicalEntityLink = (pe) => `/physicalentities/${pe.uuid}`;
const physicalFieldLink = (pf) => `/systems/physical_fields/${pf.uuid}`;

const mapStateToProps = (state, props) => ({
  dataFlow: state.dataflows.content[props.match.params['dataFlowUuid']],
  dataFlowUuid: props.match.params['dataFlowUuid']
});

export default _.flow([
  withTranslation(),
  withLoader({
    onLoad: ({ fetchDataFlow, dataFlowUuid }) => {
      fetchDataFlow(dataFlowUuid);
    },
    ready: ({ dataFlow }) => dataFlow
  }),
  connect(mapStateToProps, { fetchDataFlow, deleteDataFlow })
])(DataFlowDetail);
