import { BlindataApi } from './ApiUtils';

const PHYSICAL_CONSTRAINTS_URL = '/api/v1/physical-constraints';

export default class PhysicalConstraintsApi extends BlindataApi {
  getPhysicalConstraintsPage(params) {
    return this.get(PHYSICAL_CONSTRAINTS_URL, params);
  }
  getPhysicalConstraint(identifier) {
    return this.get(`${PHYSICAL_CONSTRAINTS_URL}/${identifier}`);
  }
  postPhysicalConstraint(physicalConstraint) {
    return this.post(PHYSICAL_CONSTRAINTS_URL, physicalConstraint);
  }
  putPhysicalConstraint(physicalConstraint) {
    return this.put(`${PHYSICAL_CONSTRAINTS_URL}/${physicalConstraint.uuid}`, physicalConstraint);
  }
  deletePhysicalConstraint(identifier) {
    return this.delete(`${PHYSICAL_CONSTRAINTS_URL}/${identifier}`);
  }
  exportPhysicalConstraints(params) {
    return this.download(`${PHYSICAL_CONSTRAINTS_URL}/*/export`, {
      ...params
    });
  }
  getSchemas(params) {
    return this.get(`${PHYSICAL_CONSTRAINTS_URL}/*/schemas`, params);
  }
}
