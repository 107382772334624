import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchConfiguration } from 'actions/quality/assessment/QualityAssessmentConfigurationActions';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery
} from '@mui/material';

import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import ItemSelectField from 'components/itemselector/ItemSelectField';

export default function RiskEvaluationModal({
  open,
  riskEvaluation,
  onCancel,
  onSubmit,
  onDelete
}) {
  let { t } = useTranslation();
  let dispatch = useDispatch();
  let [editor, setState] = useState(riskEvaluation || {});
  let configuration = useSelector((state) => state.quality.assessment.configuration);
  useEffect(() => {
    if (!configuration) {
      dispatch(fetchConfiguration());
    }
  }, [dispatch, configuration]);
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  if (!configuration) {
    return null;
  }
  const domainElements = configuration.domains || [];
  const severityValues = domainElements
    .filter((row) => row[0] === 'SEVERITY')
    .map((row) => ({ label: row[2], value: row[1] }));
  const probabilityValues = domainElements
    .filter((row) => row[0] === 'PROBABILITY')
    .map((row) => ({ label: row[2], value: row[1] }));

  return (
    <Dialog open={open} onClose={onCancel} fullWidth fullScreen={fullScreen}>
      <DialogTitle>Risk Evaluation</DialogTitle>
      <DialogContent>
        <DialogContentText>Evaluation for risk "{riskEvaluation?.risk?.name}"</DialogContentText>
        <ItemSelectField
          fullWidth
          margin='dense'
          itemName='Severity'
          keyProperty='value'
          nameProperty='label'
          value={editor.severity}
          values={severityValues}
          onChange={(severity) => setState({ ...editor, severity })}
        />
        <ItemSelectField
          fullWidth
          margin='dense'
          itemName='Probability'
          keyProperty='value'
          nameProperty='label'
          value={editor.probability}
          values={probabilityValues}
          onChange={(probability) => setState({ ...editor, probability })}
        />
      </DialogContent>
      <DialogActions>
        <ConfirmButton
          disabled={!riskEvaluation?.probability || !riskEvaluation?.severity}
          color='secondary'
          onClick={() => onDelete(editor)}>
          {t('commons.actions.delete')}
        </ConfirmButton>
        <div style={{ flexGrow: 1 }} />
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
        <Button
          color='primary'
          disabled={!editor.probability || !editor.severity}
          onClick={() => onSubmit(editor)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
