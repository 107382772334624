import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import DatePicker, { hourAdjustments } from 'components/pickers/DatePicker';

const QualityChecksTimelineFilters = ({
  withDatesFilters,
  filters,
  setQualityChecksTimelineFilters
}) => {
  let { t } = useTranslation();

  return (
    <>
      {withDatesFilters && (
        <>
          <DatePicker
            fullWidth={false}
            margin='none'
            clearable={false}
            label={'From'}
            hintText={'Start Date'}
            hourAdjustment={hourAdjustments.startOfDay}
            value={filters.startedAtGte}
            maxDate={filters?.startedAtLt}
            onChange={(date) => setQualityChecksTimelineFilters({ startedAtGte: date })}
          />
          <DatePicker
            fullWidth={false}
            margin='none'
            clearable={false}
            label={'To'}
            hintText={'End Date'}
            hourAdjustment={hourAdjustments.endOfDay}
            value={filters.startedAtLt}
            minDate={filters?.startedAtGte}
            onChange={(date) => setQualityChecksTimelineFilters({ startedAtLt: date })}
          />
        </>
      )}
      <FormControl variant='standard'>
        <InputLabel id='quality-suite-results-kpi-precision-selector-label'>Precision</InputLabel>
        <Select
          variant='standard'
          labelId='quality-suite-results-kpi-precision-selector-label'
          id='quality-suite-results-kpi-precision-selector'
          value={filters.precision}
          onChange={(event) => setQualityChecksTimelineFilters({ precision: event.target.value })}>
          <MenuItem value='month'>{t('commons.timeunits.values.MONTHS')}</MenuItem>
          <MenuItem value='day'>{t('commons.timeunits.values.DAYS')}</MenuItem>
          <MenuItem value='hour'>{t('commons.timeunits.values.HOURS')}</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default QualityChecksTimelineFilters;
