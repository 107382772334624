import { Button } from '@mui/material';
import ExpandableCard from 'components/expandable-card/ExpandableCard';
import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';

const WebHookListItem = ({ webHook }) => {
  return (
    <div className='card-custom-div' style={{ margin: '8px 8px 0' }}>
      <ExpandableCard
        zDepth={1}
        key={webHook.uuid}
        title={webHook.name}
        actions={[
          <Button
            children={t('commons.actions.detail')}
            key='detail'
            component={Link}
            to={`/settings/web-hooks/${webHook.uuid}`}></Button>
        ]}
        subheader={webHook?.description}
      />
    </div>
  );
};

export default WebHookListItem;
