import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { t } from 'i18next';
import { Box } from '@mui/system';
import hljsStyle from 'react-syntax-highlighter/dist/cjs/styles/hljs/idea';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import { HttpHeaders } from './HttpHeaders';

export const WebActionConfigurationExecutionResult = ({
  executionResult,
  setIsNewResult,
  onResultVisualized
}) => {
  return (
    <>
      <DialogTitle>{t('webActions.executionModal.result.title')}</DialogTitle>
      <DialogContent>
        <h4>{t('webActions.executionModal.result.status')}</h4>
        <Typography>{executionResult.status}</Typography>
        <HttpHeaders headers={executionResult?.headers} />
        <h4 style={{ marginTop: '8px' }}>{t('webActions.executionModal.result.body')}</h4>
        <Box
          style={{
            border: '1px solid',
            borderColor: 'lightgrey',
            borderRadius: '0.5em',
            padding: '16px'
          }}>
          <SyntaxHighlighter style={hljsStyle} customStyle={{ whiteSpace: 'pre-wrap', margin: 0 }}>
            {executionResult.body}
          </SyntaxHighlighter>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsNewResult(false);
            onResultVisualized();
          }}>
          {t('commons.actions.close')}
        </Button>
      </DialogActions>
    </>
  );
};
