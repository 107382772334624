import React, { useState } from 'react';
import {
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TeamSelector from '../../teams/common/TeamSelector';

function UserTeamSettingEditor({ teamSettings, open, onCancel, onSubmit }) {
  const { t } = useTranslation();
  const [editor, setEditor] = useState(teamSettings);
  return (
    <Dialog open={open}>
      <DialogTitle>Edit User Team Settings</DialogTitle>
      <DialogContent>
        <FormGroup>
          <TeamSelector
            value={editor.defaultTeam}
            onChange={(team) => setEditor({ ...editor, defaultTeam: team })}></TeamSelector>

          <FormControlLabel
            control={
              <Checkbox
                checked={editor.canReadAll}
                onChange={(e) => setEditor({ ...editor, canReadAll: e.target.checked })}></Checkbox>
            }
            label='Read All'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editor.canWriteAll}
                onChange={(e) =>
                  setEditor({ ...editor, canWriteAll: e.target.checked })
                }></Checkbox>
            }
            label='Write All'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editor.canProposeAll}
                onChange={(e) =>
                  setEditor({ ...editor, canProposeAll: e.target.checked })
                }></Checkbox>
            }
            label='Propose All'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editor.canApproveAll}
                onChange={(e) =>
                  setEditor({ ...editor, canApproveAll: e.target.checked })
                }></Checkbox>
            }
            label='Approve All'
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>{t('commons.actions.cancel')}</Button>
        <Button variant='contained' color='primary' onClick={() => onSubmit(editor)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserTeamSettingEditor;
