import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination
} from '@mui/material';
import { useHistory } from 'react-router';

const QualityChecksPieChartDrillDownModal = ({
  data,
  open,
  onClose,
  onRowsChange,
  onPageChange
}) => {
  const history = useHistory();
  if (!data) {
    return null;
  }
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Quality Checks Pie Chart Drilldown</DialogTitle>
      <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <TableContainer>
          <Table size='small' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Quality Check Name</TableCell>
                <TableCell>Quality Check Code</TableCell>
                <TableCell>Suite Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data?.content || []).map((checkKPI) => (
                <TableRow
                  hover={true}
                  key={checkKPI.uuid}
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(`/data-quality/kqis/checks/${checkKPI.uuid}`)}>
                  <TableCell>{checkKPI.name}</TableCell>
                  <TableCell>{checkKPI.code}</TableCell>
                  <TableCell>{checkKPI.qualitySuite.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component='div'
          rowsPerPage={data.size}
          count={data.totalElements}
          page={data.number}
          onPageChange={(event, page) => onPageChange(page)}
          onRowsPerPageChange={(event) => onRowsChange(event.target.value)}></TablePagination>
      </DialogContent>
    </Dialog>
  );
};

export default QualityChecksPieChartDrillDownModal;
