import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import RpaTemplateEditorPage from './rpaTemplate/RpaTemplateEditorPage';
import RpaArchiveRoutes from './rpaArchives/RpaArchiveRoutes';
export default class ReportsRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path={`${this.props.match.url}/rpa/templates/edit/:rpaTemplateUuid`}
          render={(props) => (
            <RpaTemplateEditorPage {...{ ...props, mode: RpaTemplateEditorPage.EDIT_MODE }} />
          )}
        />
        <Route
          exact
          path={`${this.props.match.url}/rpa/templates/create`}
          render={(props) => (
            <RpaTemplateEditorPage {...{ ...props, mode: RpaTemplateEditorPage.CREATE_MODE }} />
          )}
        />
        <Route path={`${this.props.match.url}/rpa/archives`} component={RpaArchiveRoutes}></Route>
      </React.Fragment>
    );
  }
}
