import { combineReducers } from 'redux';
import {
  FETCH_TEAMS_LIST,
  FETCH_TEAM,
  CREATE_TEAM,
  DELETE_TEAM,
  MODIFY_TEAM
} from '../../constants/ActionTypes';

import _ from 'lodash';

const TEAM_INITIAL_STATE = {
  content: {},
  currentPage: {
    content: [],
    number: 0,
    size: 20,
    totalPages: 0
  }
};

const content = (state = TEAM_INITIAL_STATE.content, action) => {
  switch (action.type) {
    case FETCH_TEAMS_LIST:
      return {
        ...state,
        ..._(action.payload.content)
          .keyBy('code')
          .value()
      };
    case CREATE_TEAM:
    case MODIFY_TEAM:
    case FETCH_TEAM:
      return {
        ...state,
        [action.payload.code]: action.payload
      };
    case DELETE_TEAM:
      return {
        ...state,
        [action.payload.code]: undefined
      };
    default:
      return state;
  }
};
const currentPage = (state = TEAM_INITIAL_STATE.currentPage, action) => {
  switch (action.type) {
    case FETCH_TEAMS_LIST:
      return action.payload;
    case CREATE_TEAM:
      return {
        ...state,
        content: _(state.content)
          .keyBy('code')
          .assign({ [action.payload.code]: action.payload })
          .map()
          .value()
      };
    default:
      return state;
  }
};

export default combineReducers({ content, currentPage });
