import { BlindataApi } from './ApiUtils';

export default class DataCategoriesApi extends BlindataApi {
  getDataCategories(params) {
    return this.get(`/api/v1/datacategories`, params);
  }

  getDataCategory(uuid) {
    return this.get(`/api/v1/datacategories/${uuid}`);
  }

  searchDataCategories(search, { page = 0, size } = {}) {
    return this.get(`/api/v1/datacategories`, { search, page, size });
  }

  putDataCategory(dataCategory) {
    return this.put(`/api/v1/datacategories/${dataCategory.uuid}`, dataCategory);
  }

  postDataCategory(dataCategory) {
    return this.post('/api/v1/datacategories', dataCategory);
  }

  deleteDataCategory(dataCategory) {
    return this.delete(`/api/v1/datacategories/${dataCategory.uuid}`);
  }

  getDataCategoriesCompilationKPIs(params) {
    return this.get(`/api/v1/datacategories/*/kpis/compilation`, params);
  }

  getDataClasses(params) {
    return this.get('/api/v1/datacategories/*/dataClasses', params);
  }
}
