import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/display/fullscreen.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import './RpaTemplateEditor.css';
import { fetchJobHistory, fetchJobHistoryLog } from 'actions/onagent/AgentJobhistoryActions';
import { fetchAgent } from 'actions/AgentsActions';
import { connect } from 'react-redux';
import moment from 'moment';

import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import JobHistoryLogSection from './JobHistoryLogSection';

function JobHistoryDetailPage({
  fetchJobHistory,
  sequenceId,
  fetchAgent,
  agentUuid,
  jobHistory,
  fetchJobHistoryLog,
  agent
}) {
  const [log, setLog] = useState(null);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!agent) {
      fetchAgent(agentUuid);
    }
    if (agent) {
      fetchJobHistory(agent, sequenceId);
      fetchJobHistoryLog(agent, sequenceId).then((response) => {
        setLog(response);
        setLoaded(true);
      });
    }
  }, [fetchAgent, agent, agentUuid, fetchJobHistory, sequenceId, fetchJobHistoryLog]);
  return (
    loaded && (
      <React.Fragment>
        <Card>
          <CardHeader
            title={jobHistory?.name}
            action={
              <VariationTypeDecorator>{jobHistory?.runStatus}</VariationTypeDecorator>
            }></CardHeader>
          <CardContent>
            <KVDefinitionList
              item={{
                'Started At':
                  jobHistory?.startedAt &&
                  moment(jobHistory?.startedAt).format('DD MMMM YYYY, HH:mm:ss'),
                'Finished At':
                  jobHistory?.finishedAt &&
                  moment(jobHistory?.finishedAt).format('DD MMMM YYYY, HH:mm:ss'),
                Type: jobHistory?.type
              }}></KVDefinitionList>
          </CardContent>
        </Card>
        <JobHistoryLogSection logContent={log?.logContent}></JobHistoryLogSection>
      </React.Fragment>
    )
  );
}

function mapStateToProps(state, props) {
  return {
    sequenceId: props.match.params['sequenceId'],
    agentUuid: props.match.params['agentUuid'],
    jobHistory: state.settings.agents.jobhistory.content[props.match.params['sequenceId']],
    agent: state.settings.agents.definitions.content[props.match.params['agentUuid']]
  };
}

export default connect(mapStateToProps, { fetchJobHistory, fetchAgent, fetchJobHistoryLog })(
  JobHistoryDetailPage
);
