import React from 'react';
import { Grid, Typography } from '@mui/material';
import AssetsAddButton from './AssetsAddButton';
import AssetsTable from './AssetsTable';

function TableSection({ editor, setEditor }) {
  return (
    <>
      {editor?.dataCategories?.length > 0 ||
      editor?.logicalFields?.length > 0 ||
      editor?.physicalEntities?.length > 0 ||
      editor?.physicalFields?.length > 0 ? (
        <>
          <Grid container alignItems='center'>
            <Grid item style={{ flexGrow: 1 }}>
              <h4>Starting Assets of interest</h4>
            </Grid>
            <Grid item>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <AssetsAddButton editor={editor} setEditor={setEditor} />
              </div>
            </Grid>
          </Grid>
          <AssetsTable setEditor={setEditor} editor={editor} />
        </>
      ) : (
        <>
          <h4>Starting Assets of interest</h4>
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='overline'>
              No asset defined yet, click the button to add
            </Typography>
            <AssetsAddButton editor={editor} setEditor={setEditor} />
          </div>
        </>
      )}
    </>
  );
}

export default TableSection;
