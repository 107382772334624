import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import QualityAssessmentNavbar from 'pages/quality/assessment/QualityAssessmentNavbar';
import RiskDefinitionsListPage from 'pages/quality/assessment/risks/list/RiskDefinitionsListPage';
import RiskDefinitionDetailPage from 'pages/quality/assessment/risks/detail/RiskDefinitionDetailPage';
import QualityAssessmentScoringPage from 'pages/quality/assessment/scoring/QualityAssessmentScoringPage';
import QualityAssessmentDashboardsPage from 'pages/quality/assessment/dashboards/QualityAssessmentDashboardsPage';

const QualityAssessmentRoutes = (props) => {
  return (
    <>
      <QualityAssessmentNavbar />
      <Switch>
        <Route
          exact
          path={`${props.match.url}/risks`}
          component={RiskDefinitionsListPage}
        />
        <Route
          path={`${props.match.url}/risks/:uuid`}
          component={RiskDefinitionDetailPage}
        />
        <Route
          exact
          path={`${props.match.url}/scoring`}
          component={QualityAssessmentScoringPage}
        />
        <Route
          exact
          path={`${props.match.url}/dashboards`}
          component={QualityAssessmentDashboardsPage}
        />
        <Redirect to={`${props.match.url}/risks`} />
      </Switch>
    </>
  )
}

export default QualityAssessmentRoutes;