import { ADD_ENGAGEMENT_HIT, DELETE_ENGAGEMENT_HITS } from 'constants/ActionTypes';

const initialState = { hits: [] };

const hits = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ENGAGEMENT_HIT:
      return { ...state, hits: [...state.hits, action.payload] };
    case DELETE_ENGAGEMENT_HITS:
      const hitsToBeRemoved = action.payload.map((hit) => hit.hitUuid);
      const purgedHits = state.hits.filter((hit) => !hitsToBeRemoved.includes(hit.hitUuid));
      return { ...state, hits: purgedHits };
    default:
      return state;
  }
};

export default hits;
