import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withLoader } from '../../../../../components/loader/Loader';
import Breadcrumbs from 'pages/sidebar/Navigation';
import { fetchQualityCheck } from '../../../../../actions/QualityChecksActions';
import QualityCheckDetailCard from './QualityCheckDetailCard';

import QualityResultsTabs from './QualityResultsTabs';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import { Grid, Typography } from '@mui/material';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import ResourceTypes from 'constants/ResourceTypes';
import _ from 'lodash';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

function QualityCheckDetailPage({ qualityCheck }) {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.kqis'), to: '/data-quality/kqis/suites' },
          {
            text: qualityCheck.qualitySuite?.name,
            to: `/data-quality/kqis/suites/${qualityCheck.qualitySuite.uuid}`
          },
          { text: qualityCheck.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: qualityCheck?.name,
          category: EngagementCategories.DATA_QUALITY,
          action: EngagementActions.DETAIL
        }}
      />

      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Quality Check: {_.truncate(qualityCheck?.name)}</Typography>
        </Grid>
        <Grid item>
          <Grid item>
            <FavoritesWidget
              itemIdentifier={`${ResourceTypes.QUALITY_CHECK}:${qualityCheck.uuid}`}
              resourceIdentifier={qualityCheck.uuid}
              resourceType={ResourceTypes.QUALITY_CHECK}
              resourceName={qualityCheck.name}
              pageTitle={qualityCheck.name}></FavoritesWidget>
          </Grid>
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.QUALITY_CHECK}
            resourceIdentifier={qualityCheck?.uuid}
            parentIdentifier={qualityCheck?.uuid}
            resourceName={qualityCheck?.name}
          />
        </Grid>
      </Grid>

      <QualityCheckDetailCard qualityCheck={qualityCheck} />
      <QualityResultsTabs qualityCheck={qualityCheck} />
    </>
  );
}

function mapStateToProps(state, props) {
  const uuid = props.match.params.uuid;
  return {
    qualityCheck: state.quality.qualityChecks.content[uuid],
    uuid: props.match.params.uuid
  };
}

const mapDispatchToProps = {
  fetchQualityCheck
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchQualityCheck, uuid }) => fetchQualityCheck(uuid),
    ready: ({ qualityCheck }) => qualityCheck !== undefined
  })
)(QualityCheckDetailPage);
