import { buildApiCallAction } from './ActionUtils';
import {
  FETCH_SYSTEM_ROUTINES_PAGE,
  FETCH_SYSTEM_ROUTINE,
  CREATE_SYSTEM_ROUTINE,
  DELETE_SYSTEM_ROUTINE,
  MODIFY_SYSTEM_ROUTINE,
  SET_SYSTEM_ROUTINES_FILTERS,
  CLEAR_SYSTEM_ROUTINES_FILTERS,
  EXPORT_SYSTEM_ROUTINES,
  FETCH_SYSTEM_ROUTINE_SCHEMAS,
  FETCH_SYSTEM_ROUTINE_CATALOG
} from '../constants/ActionTypes';
import RoutinesApi from 'api/RoutinesApi';
import { showMessage } from './BehaviourActions';

const api = new RoutinesApi();
export const fetchRoutinesPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getRoutinesPage({ ...params, systemUuid: params?.system?.uuid }),
    actionType: FETCH_SYSTEM_ROUTINES_PAGE
  });

export const fetchRoutine = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getRoutine(uuid),
    actionType: FETCH_SYSTEM_ROUTINE
  });
export const createRoutine = (routine) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postRoutine(routine),
    actionType: CREATE_SYSTEM_ROUTINE
  });

export const modifyRoutine = (routine) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putRoutine(routine),
    actionType: MODIFY_SYSTEM_ROUTINE
  });

export const deleteRoutine = (routine) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteRoutine(routine),
    actionType: DELETE_SYSTEM_ROUTINE,
    payload: routine
  });

export const exportRoutines = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => {
      return api.exportRoutines({ ...params, systemUuid: params?.system?.uuid });
    },
    actionType: EXPORT_SYSTEM_ROUTINES
  });

export const fetchRoutineSchemas = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getSchemas(params),
    actionType: FETCH_SYSTEM_ROUTINE_SCHEMAS
  });

export const fetchRoutineCatalog = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getCatalog(params),
    actionType: FETCH_SYSTEM_ROUTINE_CATALOG
  });

export const setRoutinesFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_SYSTEM_ROUTINES_FILTERS, payload: filters });
};

export const clearRoutinesFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_SYSTEM_ROUTINES_FILTERS });
};

export const analyzeSystemRoutine = (routineUuid, analysisOptions) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.analyzeRoutine(routineUuid, analysisOptions),
    actionType: MODIFY_SYSTEM_ROUTINE,
    onSuccess: (dispatch, getState) => {
      showMessage('Routine Analyzed')(dispatch, getState);
    }
  });

export const resetSystemRoutine = (routineUuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.resetRoutine(routineUuid),
    actionType: MODIFY_SYSTEM_ROUTINE
  });

export const confirmSystemRoutine = (routineUuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.confirmRoutine(routineUuid),
    actionType: MODIFY_SYSTEM_ROUTINE
  });
