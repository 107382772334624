import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withLoader } from '../../../../components/loader/Loader';
import { fetchTeamConfiguration } from '../../../../actions/LoginActions';

function AccountTeamSelectorView({
  availableTeams,
  onChange,
  selectedTeamCode,
  fullWidth,
  margin,
  label = 'Team'
}) {
  return (
    <FormControl variant='standard' fullWidth={fullWidth} margin={margin}>
      <InputLabel shrink id='account-team-selector-label'>
        {label}
      </InputLabel>
      <Select
        variant='standard'
        labelId='account-team-selector-label'
        id='account-team-selector'
        value={selectedTeamCode || ''}
        displayEmpty
        onChange={(event) => onChange(event.target.value || null)}
        disabled={availableTeams.length === 0}>
        <MenuItem value=''>
          <em>Public</em>
        </MenuItem>
        {(addSelectTeamCode(availableTeams, selectedTeamCode) || []).map((team) => (
          <MenuItem key={team.code} value={team.code}>
            {team.name || team.code}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function addSelectTeamCode(availableTeams, selectedTeamCode) {
  return !availableTeams.map((team) => team.code).includes(selectedTeamCode) &&
    !isEmpty(selectedTeamCode)
    ? availableTeams.concat([{ code: selectedTeamCode, name: null }])
    : availableTeams;
}
function isEmpty(str) {
  return !str || str.length === 0;
}
const mapStateToProps = (state, { accessLevel = ACCESS_LEVELS.READ, accessLevel2 }) => ({
  availableTeams: state.account.teamConfig.teamPolicies
    .filter((tp) => tp[accessLevel.description] || (accessLevel2 && tp[accessLevel2.description]))
    .map((tp) => tp.team)
});

export const ACCESS_LEVELS = Object.freeze({
  READ: Symbol('canRead'),
  WRITE: Symbol('canWrite'),
  PROPOSE: Symbol('canPropose'),
  APPROVE: Symbol('canApprove')
});

const AccountTeamSelector = compose(
  connect(mapStateToProps, { fetchTeamConfiguration }),
  withLoader({ onLoad: ({ fetchTeamConfiguration }) => fetchTeamConfiguration() })
)(AccountTeamSelectorView);

AccountTeamSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedTeamCode: PropTypes.string.isRequired,
  accessLevel: PropTypes.oneOf(Object.values(ACCESS_LEVELS)),
  accessLevel2: PropTypes.oneOf(Object.values(ACCESS_LEVELS))
};
export default AccountTeamSelector;
