import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  CardActions,
  Grid,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import { connect } from 'react-redux';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import ClassificationAgentJobSettingsModal from './ClassificationAgentJobSettingsModal';
import { ChipWrapper, ChipItem } from 'components/chiplist/ChipList';
import {
  modifyClassificationAgentJobSettings,
  deleteClassificationAgentJobSettings
} from 'actions/ClassificationAgentJobSettingActions';
import { formatDate } from 'pages/history/commons/HistoryRecordTable';
import ResourceTypes from 'constants/ResourceTypes';
import SqlVisualizer from 'components/syntaxhighlithter/SqlVisualizer';

const bodyStyle = {
  border: '1px solid',
  borderColor: 'lightgrey',
  borderRadius: '0.5em',
  padding: '1em'
};

function ClassificationAgentJobSettingDetailCard({
  classificationAgentJobSettings,
  system,
  agent,
  modifyClassificationAgentJobSettings,
  deleteClassificationAgentJobSettings
}) {
  const [editModal, setEditModal] = useState(false);
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader title={'Classification Default Settings'}></CardHeader>
      <CardContent>
        <Grid container direction='row'>
          <Grid item>
            <GeneralSection classificationAgentJobSettings={classificationAgentJobSettings} />
            <DictionariesSection
              dictionaries={classificationAgentJobSettings?.configs?.dictionaries}
            />
            <RulesSection rules={classificationAgentJobSettings?.configs?.rules} />
          </Grid>
          <Grid item style={{ flexGrow: 0.6 }}></Grid>
          <Grid item>
            <OutputSection outputOptions={classificationAgentJobSettings?.configs?.outputOptions} />
            <SamplingSection sampling={classificationAgentJobSettings?.configs?.sampling} />
          </Grid>
        </Grid>
      </CardContent>
      {classificationAgentJobSettings && (
        <CardActions>
          <ActionSections
            t={t}
            classificationAgentJobSettings={classificationAgentJobSettings}
            setEditModal={setEditModal}
            deleteClassificationAgentJobSettings={deleteClassificationAgentJobSettings}
          />
        </CardActions>
      )}
      {editModal && (
        <ClassificationAgentJobSettingsModal
          open={editModal}
          agent={agent}
          defaultSettings={classificationAgentJobSettings}
          resourceIdentifier={system.uuid}
          resourceType={ResourceTypes.SYSTEM}
          title={`Modify Settings`}
          onClose={() => setEditModal(false)}
          onSubmit={(jobSetting) => {
            modifyClassificationAgentJobSettings(jobSetting);
            setEditModal(false);
          }}
        />
      )}
    </Card>
  );
}

function GeneralSection({ classificationAgentJobSettings }) {
  return (
    <KVDefinitionList
      header='General'
      item={{
        Type: classificationAgentJobSettings?.type,
        Strategy: classificationAgentJobSettings?.configs.strategy,
        'Created in': formatDate(classificationAgentJobSettings?.createdAt),
        'Updated in': classificationAgentJobSettings?.updatedAt
          ? formatDate(classificationAgentJobSettings?.updatedAt)
          : '-'
      }}></KVDefinitionList>
  );
}

function DictionariesSection({ dictionaries }) {
  return (
    <>
      <KVDefinitionList
        header='Dictionaries'
        item={{
          Connection: dictionaries?.connectionName || 'No connection defined',
          Scopes: ''
        }}></KVDefinitionList>
      {dictionaries?.scopes.length > 0 ? (
        <ChipWrapper>
          {dictionaries.scopes.map((scope) => (
            <ChipItem label={scope} />
          ))}
        </ChipWrapper>
      ) : (
        <Typography style={{ marginLeft: '10px' }} variant='body1'>
          All scopes for dictionaries
        </Typography>
      )}
    </>
  );
}
function RulesSection({ rules }) {
  return (
    <>
      <KVDefinitionList
        header='Rules'
        item={{
          Scopes: ''
        }}></KVDefinitionList>
      {rules?.scopes?.length > 0 ? (
        <ChipWrapper style={{ marginLeft: '10px' }}>
          {rules.scopes.map((scope) => (
            <ChipItem label={scope} />
          ))}
        </ChipWrapper>
      ) : (
        <Typography style={{ marginLeft: '10px' }} variant='body1'>
          All scopes for rules
        </Typography>
      )}
    </>
  );
}
function OutputSection({ outputOptions }) {
  return (
    <KVDefinitionList
      header='Output'
      item={{
        Credentials: outputOptions?.targetConnectionName,
        'Dry Run': outputOptions?.dryRun ? 'true' : 'false'
      }}
    />
  );
}
function SamplingSection({ sampling }) {
  return (
    <>
      <KVDefinitionList header='Sampling' />
      <dt
        style={{
          clear: 'left',
          textAlign: 'left',
          fontWeight: 400,
          color: '#00aeca'
        }}>
        Min Samples for Columnar Extraction:
      </dt>
      <div
        style={{
          marginBlockEnd: '2em',
          marginBlockStart: '1em',
          marginLeft: '18px',
          overflow: 'auto'
        }}>
        <Typography variant='body1'>{sampling?.minSampleSizeThreshold}</Typography>
      </div>
      <dt
        style={{
          clear: 'left',
          textAlign: 'left',
          fontWeight: 400,
          color: '#00aeca'
        }}>
        Extraction Query Template:
      </dt>
      <div
        style={{
          marginBlockEnd: '2em',
          marginBlockStart: '1em',
          marginLeft: '18px',
          overflow: 'auto'
        }}>
        <SqlVisualizer style={bodyStyle} text={sampling?.extractionQueryTemplate}></SqlVisualizer>
      </div>
      <dt
        style={{
          clear: 'left',
          textAlign: 'left',
          fontWeight: 400,
          color: '#00aeca'
        }}>
        Extraction Columnar Query Template:
      </dt>
      <div
        style={{
          marginBlockEnd: '2em',
          marginBlockStart: '1em',
          marginLeft: '18px',
          overflow: 'auto'
        }}>
        <SqlVisualizer
          style={bodyStyle}
          text={sampling?.extractionQueryColumnarTemplate}></SqlVisualizer>
      </div>
    </>
  );
}

function ActionSections({
  setEditModal,
  classificationAgentJobSettings,
  deleteClassificationAgentJobSettings,
  t
}) {
  return (
    <>
      <Authorize hasPermissions={[Permissions.SYSTEMS_EDITOR, Permissions.CLASSIFICATION_EDITOR]}>
        <Button onClick={() => setEditModal(true)}>{t('commons.actions.modify')}</Button>
      </Authorize>
      <Authorize hasPermissions={[Permissions.SYSTEMS_EDITOR, Permissions.CLASSIFICATION_EDITOR]}>
        <ConfirmButton
          onClick={() => {
            deleteClassificationAgentJobSettings(classificationAgentJobSettings);
          }}>
          {t('commons.actions.delete')}
        </ConfirmButton>
      </Authorize>
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.AGENTS_VIEWER] }),
  connect(null, {
    modifyClassificationAgentJobSettings,
    deleteClassificationAgentJobSettings
  })
)(ClassificationAgentJobSettingDetailCard);
