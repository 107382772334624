import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Paper,
  Grid,
  Typography
} from '@mui/material';
import { deleteWebHook, modifyWebHook } from 'actions/settings/webhooks/WebHooksActions';
import CardDescription from 'components/carddescription/CardDescription';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import Authorize from 'components/permissions/Authorize';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import WebHookModal from '../list/WebHookModal';
import Permissions from 'constants/Permissions';
import { useHistory } from 'react-router';
import JsonVisualizer from 'components/syntaxhighlithter/JsonVisualizer';
import moment from 'moment';

export const WebHookDetailSection = ({ webHook }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={webHook?.name}
        subheader={
          <Grid container direction='column' alignItems='flex-start'>
            <Grid item>
              <Typography
                variant='caption'
                children={`Created on ${moment(webHook?.createdAt).format(
                  'DD MMMM YYYY, HH:mm:ss'
                )} by ${webHook?.createdByUserDisplayName} `}
              />
            </Grid>
            {webHook?.updatedByUserDisplayName && (
              <Grid item>
                <Typography
                  variant='caption'
                  children={`Updated on ${moment(webHook?.updatedAt).format(
                    'DD MMMM YYYY, HH:mm:ss'
                  )} by ${webHook?.updatedByUserDisplayName} `}
                />
              </Grid>
            )}
          </Grid>
        }
      />
      <CardContent>
        <CardDescription>{webHook?.description}</CardDescription>

        <h4>{t('webHooks.detail.requestTemplate')}</h4>
        <Paper variant='outlined'>
          <JsonVisualizer object={webHook?.requestTemplate}></JsonVisualizer>
        </Paper>
      </CardContent>
      <CardActions>
        <Authorize hasPermissions={[Permissions.AGENTS_EDITOR]}>
          <ModifyButton webHook={webHook} onSubmit={(webHook) => dispatch(modifyWebHook(webHook))}>
            {t('commons.actions.modify')}
          </ModifyButton>
        </Authorize>
        <Authorize hasPermissions={[Permissions.AGENTS_EDITOR]}>
          <ConfirmButton
            message={'Do you really want to delete this web hook?'}
            onClick={() => {
              dispatch(deleteWebHook(webHook)).then(() => history.push('/settings/web-hooks'));
            }}>
            {t('commons.actions.delete')}
          </ConfirmButton>
        </Authorize>
      </CardActions>
    </Card>
  );
};
const ModifyButton = ({ webHook, onSubmit }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      {open && (
        <WebHookModal
          title={t('webHooks.modal.title', { action: 'Modify' })}
          open={open}
          onSubmit={(webHook) => {
            onSubmit(webHook);
            setOpen(false);
          }}
          onCancel={() => setOpen(false)}
          webHook={webHook}
        />
      )}
    </>
  );
};
