import { actionsBuilder, buildApiCallAction } from './ActionUtils';

import PhysicalConstraintsApi from 'api/PhysicalConstraintsAPI';
import {
  CLEAR_PHYSICAL_CONSTRAINTS_FILTERS,
  EXPORT_PHYSICAL_CONSTRAINTS,
  FETCH_PHYSICAL_CONSTRAINTS_PAGE_BY_TYPE,
  FETCH_PHYSICAL_CONSTRAINT_SCHEMAS,
  SET_PHYSICAL_CONSTRAINTS_FILTERS
} from 'constants/ActionTypes';

const api = new PhysicalConstraintsApi();

export const fetchPhysicalConstraintsPageByType = ({ constraintType, ...params }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getPhysicalConstraintsPage({ ...params }),
    actionType: FETCH_PHYSICAL_CONSTRAINTS_PAGE_BY_TYPE,
    payload: (response) => ({
      ...response,
      constraintType: constraintType
    })
  });

export default actionsBuilder({
  resource: 'PHYSICAL_CONSTRAINT',
  api,
  apiMapping: {
    fetch: api.getPhysicalConstraint,
    fetchPage: api.getPhysicalConstraintsPage,
    create: api.postPhysicalConstraint,
    modify: api.putPhysicalConstraint,
    delete: api.deletePhysicalConstraint
  }
});

export const setPhysicalConstraintsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_PHYSICAL_CONSTRAINTS_FILTERS, payload: filters });
};

export const clearPhysicalConstraintsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_PHYSICAL_CONSTRAINTS_FILTERS, payload: filters });
};

export const exportPhysicalConstraints = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) =>
      api.exportPhysicalConstraints({ ...params, systemUuid: params?.system?.uuid }),
    actionType: EXPORT_PHYSICAL_CONSTRAINTS
  });

export const fetchPhysicalConstraintSchemas = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getSchemas(params),
    actionType: FETCH_PHYSICAL_CONSTRAINT_SCHEMAS
  });
