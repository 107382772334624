import React from 'react';
import TasksContainer from './list/TasksListPage'
import TaskDetail from './detail/TaskDetail'
import {Route} from 'react-router-dom';

export default class TasksPage extends React.Component {

  linkToDisclaimers = (task) =>  {
    return {
      pathname: `${this.props.match.url}/${task.uuid}/disclaimers`
    }
  }

  render() {
    return (
        <React.Fragment>
          <Route
            exact path={this.props.match.url}
            component={TasksContainer} />     
          <Route
            exact path={`${this.props.match.url}/:taskUuid`}
            component={TaskDetail} />
        </React.Fragment>
    );
  }

}

export const linkToDisclaimers = (task) =>  {
    return {
      pathname: `/tasks/${task.uuid}/disclaimers`
    }
}
