import { AddFAB } from 'components/fab';
import React from 'react';
import { useState } from 'react';
import ContractDefinitionModal from 'pages/contracts/definitions/detail/ContractDefinitionModal';
import { useDispatch } from 'react-redux';
import { createContractDefinition } from 'actions/contracts/ContractsDefinitionsActions';
import { useHistory } from 'react-router';

function ContractDefinitionAddFAB() {
  let [open, setOpen] = useState(false);
  let dispacth = useDispatch();
  let history = useHistory();
  return (
    <div>
      <ContractDefinitionModal
        open={open}
        onCancel={() => setOpen(false)}
        onSubmit={(cd) => {
          dispacth(createContractDefinition(cd)).then(
            (cd) => cd && history.push(`/contracts/definitions/${cd.uuid}`)
          );
        }}
      />
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
    </div>
  );
}

export default ContractDefinitionAddFAB;
