import { getSystemName } from 'constants/SystemTechnologyTypes';
import React from 'react';
import { withTranslation } from 'react-i18next';

const Subheader = ({ system, type, t }) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label style={{ paddingRight: '4px' }}>
          {t('physicalEntities.detail.subheader.system')}:
        </label>
        <b>{getSystemName(system)}</b>
      </div>
      {type && (
        <div>
          <label>{t('physicalEntities.detail.subheader.type')}: </label>
          <b>{type}</b>
        </div>
      )}
    </>
  );
};

export default withTranslation()(Subheader);
