import { buildApiCallAction } from './ActionUtils';
import {
  REQUEST_CLASSIFICATION_AGENT_SETTINGS,
  CREATE_CLASSIFICATION_AGENT_SETTING,
  MODIFY_CLASSIFICATION_AGENT_SETTING,
  DELETE_CLASSIFICATION_AGENT_SETTING
} from 'constants/ActionTypes';
import AgentJobSettingsApi from 'api/AgentJobSettingsApi';

const api = new AgentJobSettingsApi();

export const fetchClassificationAgentJobSettings = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAgentJobSettings(params),
    actionType: REQUEST_CLASSIFICATION_AGENT_SETTINGS
  });

export const createClassificationAgentJobSettings = (classificationAgentJobSettings) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postAgentJobSetting(classificationAgentJobSettings),
    actionType: CREATE_CLASSIFICATION_AGENT_SETTING
  });

export const modifyClassificationAgentJobSettings = (classificationAgentJobSettings) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putAgentJobSetting(classificationAgentJobSettings),
    actionType: MODIFY_CLASSIFICATION_AGENT_SETTING
  });

export const deleteClassificationAgentJobSettings = (classificationAgentJobSettings) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteAgentJobSetting(classificationAgentJobSettings),
    actionType: DELETE_CLASSIFICATION_AGENT_SETTING,
    payload: classificationAgentJobSettings
  });
