import React from 'react';
import TeamDetailCard from './TeamDetailCard';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withLoader } from '../../../../components/loader/Loader';
import { fetchTeam, modifyTeam, deleteTeam } from '../../../../actions/TeamsActions';
import TeamPoliciesCard from './TeamPoliciesCard';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
const TeamDetailPage = ({ team, teamCode, modifyTeam, deleteTeam }) => (
  <>
    <Breadcrumbs
      elements={[
        { text: 'Settings', to: '/settings' },
        { text: 'Teams', to: '/settings/teams' },
        { text: team.name }
      ]}
    />
    <Tracking
      info={{
        pageTitle: `Team: ${team.name}`,
        category: EngagementCategories.SETTINGS,
        action: EngagementActions.DETAIL
      }}
    />
    <TeamDetailCard onModify={modifyTeam} onDelete={deleteTeam} team={team}></TeamDetailCard>
    <TeamPoliciesCard byTeamCode={teamCode} />
  </>
);

const mapStateToProps = (state, props) => ({
  team: state.settings.teams.content[props.match.params.teamCode],
  teamCode: props.match.params.teamCode
});

const mapDispatchToProps = {
  fetchTeam,
  modifyTeam,
  deleteTeam
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchTeam, teamCode }) => fetchTeam(teamCode),
    ready: ({ team }) => team !== undefined
  })
)(TeamDetailPage);
