import { buildApiCallAction } from '../../ActionUtils';
import {
  FETCH_WEB_ACTIONS_PAGE,
  FETCH_WEB_ACTION,
  CREATE_WEB_ACTION,
  DELETE_WEB_ACTION,
  MODIFY_WEB_ACTION,
  SET_WEB_ACTIONS_FILTERS,
  CLEAR_WEB_ACTIONS_FILTERS,
  EXPORT_WEB_ACTIONS,
  FETCH_AVAILABLE_WEB_ACTIONS
} from 'constants/ActionTypes';
import WebActionsApi from 'api/settings/webactions/WebActionsApi';

const api = new WebActionsApi();
export const fetchWebActionsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getWebActionsPage(params),
    actionType: FETCH_WEB_ACTIONS_PAGE
  });

export const fetchWebAction = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getWebAction(uuid),
    actionType: FETCH_WEB_ACTION
  });
export const createWebAction = (webAction) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postWebAction(webAction),
    actionType: CREATE_WEB_ACTION
  });

export const modifyWebAction = (webAction) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putWebAction(webAction),
    actionType: MODIFY_WEB_ACTION
  });

export const deleteWebAction = (webAction) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteWebAction(webAction),
    actionType: DELETE_WEB_ACTION,
    payload: webAction
  });

export const exportWebActions = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => {
      return api.exportWebActions(params);
    },
    actionType: EXPORT_WEB_ACTIONS
  });

export const setWebActionsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_WEB_ACTIONS_FILTERS, payload: filters });
};

export const clearWebActionsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_WEB_ACTIONS_FILTERS });
};

export const fetchAvailableWebActions = () =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAvailableWebActions(),
    actionType: FETCH_AVAILABLE_WEB_ACTIONS
  });
