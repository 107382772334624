import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AdditionalPropertiesEditor from 'components/additional_properties/AdditionalPropertiesEditor';
import { DialogContent, DialogTitle, DialogActions } from '@mui/material';
import ItemSelectField from 'components/itemselector/ItemSelectField';
import ResourceTypes from 'constants/ResourceTypes';
import SystemTechnologyItemSelect from './SystemTechnologyItemSelect';

const systemSubtypes = [
  { key: 'APPLICATION' },
  { key: 'SERVICE' },
  { key: 'DATABASE' },
  { key: 'ARCHIVE' },
  { key: 'QUEUE' },
  { key: 'JOB' }
];
const initialState = {
  uuid: undefined,
  name: undefined,
  description: undefined,
  additionalProperties: []
};
function SystemEditorModal({ system, onSubmit, open, title, onClose, fullScreen }) {
  const [editor, setEditor] = useState(system || initialState);
  return (
    <Dialog fullScreen={fullScreen} open={open} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          fullWidth
          required
          margin='dense'
          placeholder='Enter the system name'
          label='Name'
          value={editor.name || ''}
          onChange={(event) => setEditor({ ...editor, name: event.target.value })}
        />
        <SystemTechnologyItemSelect
          value={editor?.technology || ''}
          onChange={(technology) => setEditor({ ...editor, technology })}
        />
        <ItemSelectField
          itemName='Subtype'
          value={editor.subtype ? { key: editor.subtype } : null}
          values={systemSubtypes}
          keyProperty={'key'}
          nameProperty={'key'}
          onChange={(subtype) => setEditor({ ...editor, subtype: _.get(subtype, 'key') })}
        />
        <TextField
          variant='standard'
          fullWidth
          multiline
          margin='dense'
          placeholder='Enter the system description'
          label='Description'
          value={editor.description || ''}
          onChange={(event) => setEditor({ ...editor, description: event.target.value })}
        />
        <AdditionalPropertiesEditor
          resourceType={ResourceTypes.SYSTEM}
          additionalProperties={editor.additionalProperties || []}
          onChange={(additionalProperties) =>
            setEditor({
              ...editor,
              additionalProperties
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button children='Cancel' onClick={onClose} />
        <Button
          disabled={!editor.name}
          children='Submit'
          variant='contained'
          color='primary'
          onClick={() => onSubmit(editor)}
        />
      </DialogActions>
    </Dialog>
  );
}
SystemEditorModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
export default _.flow([])(SystemEditorModal);
