import _ from 'lodash';

export const hideUnrelatedNodes = (graph, node) => {
  let nodesByIdMap = nodesById(graph);
  let edgesBySourceMap = edgesBySource(graph);
  let edgesByTargetMap = edgesByTarget(graph);
  graph.nodes.forEach(n => n.hidden = true)
  let firstNode = nodesByIdMap[node.id];
  navigateGraph(graph, firstNode, showNodeIfNotAlreadyHiddenByFilter, nodesByIdMap, edgesBySourceMap, 'target');
  navigateGraph(graph, firstNode, showNodeIfNotAlreadyHiddenByFilter, nodesByIdMap, edgesByTargetMap, 'source');
  graph.nodes.forEach(commitHiddenNodes);
  //console.log('Filtered nodes count: '+graph.nodes.reduce((sum,n)=>n.filtered===true?sum+1:sum,0))
}

export const showRelatedNodes = (graph, node) => {
  let nodesByIdMap = nodesById(graph);
  let edgesBySourceMap = edgesBySource(graph);
  let edgesByTargetMap = edgesByTarget(graph);
  let firstNode = nodesByIdMap[node.id];
  navigateGraph(graph, firstNode, showNode, nodesByIdMap, edgesBySourceMap, 'target');
  navigateGraph(graph, firstNode, showNode, nodesByIdMap, edgesByTargetMap, 'source');
  //console.log('Filtered nodes count: ' + graph.nodes.reduce((sum, n) => n.filtered === true ? sum + 1 : sum, 0))
}

export const resetFilters = (graph) => {
  graph.nodes.forEach(n => n.filtered = n.hidden = false)
}

const navigateGraph = (graph, firstNode, apply, nodesById, edgesMap, edgesDirectionProperty) => {
  var currentNodes = [firstNode];
  var loopGuard = 0;
  while (currentNodes.length > 0 && loopGuard < 10) {
    currentNodes.forEach(apply);
    let traverseEdges = _.flatMap(currentNodes, n => edgesMap[n.id] || []);
    currentNodes = _.map(traverseEdges, e => nodesById[e[edgesDirectionProperty]]);
    loopGuard = loopGuard + 1;
  }
}

const nodesById = (graph) => _.keyBy(graph.nodes, n => n.id);

const edgesBy = (property) => (graph) => _.groupBy(graph.edges, e => e[property]);

const edgesBySource = edgesBy('source');

const edgesByTarget = edgesBy('target');

function showNode(n) {
  n.hidden = false;
  n.filtered = false;
}

function showNodeIfNotAlreadyHiddenByFilter(n) {
  if (!n.filtered && n.hidden) {
    n.hidden = n.filtered = false;
  }
}

function commitHiddenNodes(n) {
  n.filtered = n.hidden;
}