import {
  RECEIVE_SEARCH_SYSTEM_LOGINS,
  FETCH_SYSTEM_LOGINS_PAGE,
  FETCH_GRANTS_PAGE,
  CREATE_SYSTEM_LOGIN,
  CREATE_GRANT,
  DELETE_SYSTEM_LOGIN,
  DELETE_GRANT,
  MODIFY_SYSTEM_LOGIN
} from '../constants/ActionTypes'
import { combineReducers } from 'redux'
import  _ from 'lodash'

const loginDefaultState = {
  content:{},
  currentPage:{
    content:[],
    number:0,
    size:20,
    totalPages:0
  },
  pages:{  }
}

const logins = (state = loginDefaultState, action) => {
  switch(action.type){
    case RECEIVE_SEARCH_SYSTEM_LOGINS:
      return {
        ...state,
        content: {
          ...state.content,
          ..._(action.payload.content).keyBy('uuid').value()
        }
      };
    case FETCH_SYSTEM_LOGINS_PAGE:
      return {
        ...state,
        content: {
          ...state.content,
          ..._(action.payload.content).keyBy('uuid').value()
        },
        currentPage: action.payload,
        pages: {
          ...state.pages,
          [pageKey(action.payload)]: action.payload
        }       
      }
    case CREATE_SYSTEM_LOGIN:
    case MODIFY_SYSTEM_LOGIN:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.uuid]: action.payload
        },
        currentPage: {
          ...state.currentPage,
          content: _(state.currentPage.content)
              .keyBy('uuid')
              .assign({ [action.payload.uuid]: action.payload })
              .map().value()
        }
    }
    case DELETE_SYSTEM_LOGIN:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.uuid]: undefined
        }
      }
    default :
      return state;
  }
}

const pageKey = ({ systemUuid, number, size }) => `${systemUuid}|${number}|${size}`

export const readCurrentPage = reducer => (systemUuid) => {
  if(reducer.currentPage.systemUuid!==systemUuid){
    return loginDefaultState.currentPage
  }
  let values = reducer.currentPage.content || []
  let ids = _.map(values, 'uuid');
  return {
    ...reducer.currentPage,
    content: ids.map(id => reducer.content[id]).filter(e => e !== undefined)
  }
}

const grants = (state = {content:{}}, action)=>{
  switch(action.type){
    case FETCH_GRANTS_PAGE:
    case DELETE_GRANT:
    case CREATE_GRANT:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.systemLoginUuid]: _grantsBySystem(state.content[action.payload.systemLoginUuid], action)
        }
      };
    default :
      return state;
  }
}

const _grantsBySystem = (state = { content: {} }, action) => {
  switch (action.type) {
    case FETCH_GRANTS_PAGE:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.uuid]:action.payload
        },
        currentPage: action.payload,
        pages:{
          ...state.pages,
          [`${action.payload.number}|${action.payload.size}`]: action.payload
        }
      };
    case DELETE_GRANT:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.uuid]: undefined
        },
        currentPage:{
          ...state.currentPage,
          content: state.currentPage.content.filter(grt=>grt.uuid !== action.payload.uuid)
        }
      }
    case CREATE_GRANT:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.uuid]: action.payload
        },
        currentPage:{
          ...state.currentPage,
          content: _(state.currentPage.content)
            .keyBy('uuid')
            .assign({ [action.payload.uuid]: action.payload })
            .map().value()
        }
      }
    default:
      return state;
  }
}

export default combineReducers({logins,grants})
