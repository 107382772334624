import { BlindataApi } from 'api/ApiUtils';

export class BlindataAgentApi extends BlindataApi {

  agent = null;

  constructor(agent) {
    super();
    this.agent = agent;
  }

  buildUrl(url) {
    if (!this.agent) throw new Error('Agent not defined in BlindataAgentApi');
    return `${this.agent.baseUrl || ''}${url}`;
  }

  get(url, params) {
    return super.get(this.buildUrl(url), params);
  }

  delete(url) {
    return super.delete(this.buildUrl(url));
  }

  put(url, body) {
    return super.put(this.buildUrl(url), body);
  }

  post(url, body) {
    return super.post(this.buildUrl(url), body);
  }

  patch(url, body) {
    return super.patch(this.buildUrl(url), body);
  }

  download(url, params) {
    return super.download(this.buildUrl(url), params);
  }
}
