import React from 'react';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import { Controlled as CodeMirror } from 'react-codemirror2-react-17';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/display/fullscreen.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import './RpaTemplateEditor.css';

function JobHistoryLogSection({ logContent }) {
  return (
    <Card>
      <CardHeader title='Log Content'></CardHeader>
      <CardContent>
        {logContent ? (
          <CodeMirror
            style={{ heigth: 'fill-parent' }}
            value={logContent}
            options={{
              mode: 'htmlmixed',
              lineNumbers: true
            }}></CodeMirror>
        ) : (
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>No logs define for this record</Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default JobHistoryLogSection;
