import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchQualitySuite } from '../../../../../actions/QualitySuitesActions';
import { withLoader } from '../../../../../components/loader/Loader';

import { Typography, Grid } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import QualitySuiteDetailCard from 'pages/quality/kqis/quality_suites/detail/QualitySuiteDetailCard';
import QualitySuiteChecksLastRunChart from 'pages/quality/kqis/quality_suites/detail/QualitySuiteChecksLastRunChart';
import QualitySuiteChecksTimelineChart from 'pages/quality/kqis/quality_suites/detail/QualitySuiteChecksTimelineChart';
import QualityChecksCard from 'pages/quality/kqis/quality_checks/list/QualityChecksList';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import ResourceTypes from 'constants/ResourceTypes';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

function QualitySuiteDetailPage({ qualitySuite }) {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.kqis'), to: '/data-quality/kqis/suites' },
          { text: qualitySuite.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('quality.testSuites.detailPageHeader', {
            testSuiteName: qualitySuite?.name
          }),
          category: EngagementCategories.DATA_QUALITY,
          action: EngagementActions.DETAIL
        }}
      />

      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            {t('quality.testSuites.detailPageHeader', {
              testSuiteName: qualitySuite.name
            })}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`${ResourceTypes.QUALITY_SUITE}:${qualitySuite.uuid}`}
            resourceIdentifier={qualitySuite.uuid}
            resourceType={ResourceTypes.QUALITY_SUITE}
            resourceName={qualitySuite.name}
            pageTitle={t('quality.testSuites.detailPageHeader', {
              testSuiteName: qualitySuite?.name
            })}></FavoritesWidget>
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.QUALITY_SUITE}
            resourceIdentifier={qualitySuite?.uuid}
            resourceName={qualitySuite?.name}
          />
        </Grid>
      </Grid>

      <QualitySuiteDetailCard qualitySuite={qualitySuite} />
      <Grid container direction='row' spacing={2} alignItems='stretch' justifyContent='center'>
        <Grid item lg={6} md={12} style={{ width: '100%' }}>
          <QualitySuiteChecksLastRunChart qualitySuite={qualitySuite} />
        </Grid>
        <Grid item lg={6} md={12} style={{ width: '100%' }}>
          <QualitySuiteChecksTimelineChart inWatchlist={false} qualitySuite={qualitySuite} />
        </Grid>
      </Grid>
      <QualityChecksCard qualitySuite={qualitySuite} />
    </>
  );
}

function mapStateToProps(state, props) {
  const uuid = props.match.params.uuid;
  return {
    qualitySuite: state.quality.qualitySuites.content[uuid],
    uuid: props.match.params.uuid
  };
}

const mapDispatchToProps = {
  fetchQualitySuite
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchQualitySuite, uuid }) => fetchQualitySuite(uuid),
    ready: ({ qualitySuite }) => qualitySuite !== undefined
  })
)(QualitySuiteDetailPage);
