import {
  CLEAR_DATASUBJECT_REPORT,
  EDIT_DATASUBJECT_REPORT_SELECTION,
  FETCH_DATASUBJECT_REPORT
} from '../constants/ActionTypes';
import { buildApiCallAction } from './ActionUtils';
import ReportsApi from '../api/ReportsApi';

const reportsApi = new ReportsApi();

export const fetchDataSubjectReport = (dataSubjectKey) =>
  buildApiCallAction({
    api: reportsApi,
    apiFunction: (api) => api.getDataSubjectReport(dataSubjectKey),
    actionType: FETCH_DATASUBJECT_REPORT
  });

export const editDataSubjectReportSelection = (dataSubjectKey) => (dispatch) => {
  dispatch({
    type: EDIT_DATASUBJECT_REPORT_SELECTION,
    payload: dataSubjectKey
  });
};

export const clearDataSubjectReport = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATASUBJECT_REPORT
  });
};

export const downloadDataSubjectReport = (dataSubjectKey) =>
  buildApiCallAction({
    api: reportsApi,
    apiFunction: (api) => api.downloadDataSubjectReport(dataSubjectKey),
    actionType: 'DOWNLOAD_DATA_SUBJECT_REPORT'
  });

export const downloadAudienceExport = (selectedProcessings, targetDate) =>
  buildApiCallAction({
    api: reportsApi,
    apiFunction: (api) => api.downloadAudienceExport(selectedProcessings, targetDate),
    actionType: 'DOWNLOAD_AUDIENCE_EXPORT'
  });

export const downloadSystemDataSubjectsExport = ({ systemUuid }) =>
  buildApiCallAction({
    api: reportsApi,
    apiFunction: (api) => api.downloadSystemDataSubjectsExport({ systemUuid }),
    actionType: 'DOWNLOAD_SYSTEM_DATASUBJECT_EXPORT'
  });
