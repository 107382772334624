import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import { modifyRoutine, deleteRoutine, fetchRoutine } from 'actions/RoutinesActions';
import StewardshipAuthorize from 'components/permissions/StewardshipAuthorize';
import Permissions from 'constants/Permissions';
import { connect } from 'react-redux';
import RoutineModal from '../commons/RoutineModal';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import Tracking from 'components/engagement/Tracking';
import RoutineTabs from './RoutineTabs';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import ResourceTypes from 'constants/ResourceTypes';
import HistoryButtonLink from 'pages/history/commons/HistoryButtonLink';
import HistoryTargetTypes from 'pages/history/commons/HistoryTargetTypes';
import Breadcrumbs from 'pages/sidebar/Navigation';
import RoutineDetailCardView from './RoutineDetailCardView';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

function RoutineDetailPage({ routine, deleteRoutine, modifyRoutine, routineUuid, fetchRoutine }) {
  useEffect(() => {
    fetchRoutine(routineUuid);
  }, [fetchRoutine, routineUuid]);

  const { t } = useTranslation();
  const history = useHistory();

  const handleDelete = (routine) => deleteRoutine(routine).then(() => history.goBack());

  return routine ? (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.physical_model'), to: '/systems' },
          { text: routine?.system?.name, to: `/systems/${routine?.system?.uuid}` },
          { text: routine?.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('routines.detail.header', {
            name: routine?.name
          }),
          category: EngagementCategories.PHYSICAL_MODEL,
          action: EngagementActions.DETAIL
        }}
      />
      <RoutineDetailHeader name={routine.name} routine={routine} />
      <RoutineDetailCardView
        routine={routine}
        actions={[
          <StewardshipAuthorize
            resourceType={'Routine'}
            resourceIdentifier={routine?.uuid}
            hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
            <ModifyButton modifyRoutine={modifyRoutine} routine={routine}>
              {t('commons.actions.modify')}
            </ModifyButton>
            <ConfirmButton
              message={'Do you really want to delete this routine?'}
              onClick={() => handleDelete(routine)}>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </StewardshipAuthorize>,
          <HistoryButtonLink
            targetType={HistoryTargetTypes.SYSTEM_ROUTINE}
            targetIdentifier={routine?.uuid}
          />,
          <div style={{ marginLeft: 'auto' }}>
            <WebActionsButton resource={routine} page={TargetPages.SYSTEM_ROUTINE_DETAIL_PAGE} />
          </div>
        ]}
      />
      <RoutineTabs routine={routine} />
    </>
  ) : null;
}

function RoutineDetailHeader({ routine }) {
  const { t } = useTranslation();
  return (
    <>
      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>
            {t('routines.detail.header', {
              name: routine.name
            })}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`${'Routine'}:${routine?.uuid}`}
            resourceIdentifier={routine?.uuid}
            resourceType={ResourceTypes.SYSTEM_ROUTINE}
            resourceName={routine?.name}
            pageTitle={`Routine: ${routine?.name}`}></FavoritesWidget>
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.SYSTEM_ROUTINE}
            resourceIdentifier={routine?.uuid}
            resourceName={routine?.schema ? `${routine?.schema}.${routine?.name}` : routine?.name}
          />
        </Grid>
      </Grid>
    </>
  );
}
function ModifyButton({ routine, modifyRoutine }) {
  let { t } = useTranslation();
  let [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      {open && (
        <RoutineModal
          title='Modify Routine'
          open={open}
          key={routine}
          handleSubmit={(routine) => {
            modifyRoutine(routine);
            setOpen(false);
          }}
          handleCancel={() => setOpen(false)}
          routine={routine}
        />
      )}
    </>
  );
}

const mapStateToProps = (state, props) => ({
  routineUuid: props.match.params['routineUuid'],
  routine: state.systemRoutines.content[props.match.params['routineUuid']]
});

export default connect(mapStateToProps, { fetchRoutine, modifyRoutine, deleteRoutine })(
  RoutineDetailPage
);
