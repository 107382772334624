import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Select, MenuItem } from '@mui/material';

const CustomSelect = ({ unit, value, setValue }) => {
  const { t } = useTranslation();

  return (
    <Select
      variant="standard"
      multiple
      displayEmpty
      style={{ marginLeft: '5px', marginRight: '5px' }}
      value={value}
      renderValue={(value) => {
        if (value.length === 0) {
          return t(unit.emptyValue);
        } else {
          if (unit.alt) {
            return value
              .map((v) => (unit.min === 0 ? unit.alt[v] : unit.alt[v - 1]))
              .map((alt) => t(alt))
              .join(', ');
          } else {
            return value.join(', ');
          }
        }
      }}
      onChange={(event) => setValue(event.target.value)}>
      {_.range(unit.total).map((number) => {
        const itemValue = unit.min === 0 ? number : number + 1;
        const itemLabel = unit.alt ? t(unit.alt[number]) : itemValue;
        return (
          <MenuItem key={number} value={itemValue}>
            {itemLabel}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default CustomSelect;
