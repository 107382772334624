import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';
import NotaryRegistry from '../../notary/NotaryRegistry';
import AdditionalPropertiesItem from '../../../components/additional_properties/AdditionalPropertiesItem';

const ConsentDetailItem = ({ consent }) => (
  <Card>
    <CardHeader
      title={`${consent.consentEvent} by ${consent.dataSubjectKey.name}:${consent.dataSubjectKey.value}`}
      subheader={`processing: ${consent.processingDisclaimer.processing.name}, disclaimer: ${consent.processingDisclaimer.lang}, version: ${consent.processingDisclaimer.version}`}
    />
    <CardContent>
      <div className='dls-wrapper'>
        <KVDefinitionList
          header={'Identifiers'}
          item={{
            Uuid: consent.uuid,
            'Key Name': consent.dataSubjectKey.name,
            'Key Value': consent.dataSubjectKey.value,
            'Disclaimer Uuid': consent.processingDisclaimerUuid,
            Context: consent.context,
            Event: consent.consentEvent
          }}
        />
        <KVDefinitionList
          header={'Lifecyle'}
          item={{
            Timestamp: consent.timestamp,
            'Expires At': consent.expirationDate,
            'Created At': consent.createdAt,
            'Updated At': consent.updatedAt
          }}
        />
      </div>
      <div className='dls-wrapper'>
        <KVDefinitionList
          header={'Processing'}
          item={{
            Name: consent.processingDisclaimer.processing.name,
            Description: consent.processingDisclaimer.processing.description
          }}
        />
        <KVDefinitionList
          header={'Disclaimer'}
          item={{
            Language: consent.processingDisclaimer.lang,
            Version: consent.processingDisclaimer.version
          }}
        />
      </div>
      <div>
        <h4>Disclaimer description</h4>
        {consent.processingDisclaimer.description}
      </div>
      <AdditionalPropertiesItem additionalProperties={consent.additionalProperties} />
      <NotaryRegistry resourceType='consents' resourceIdentifier={consent.uuid} />
    </CardContent>
  </Card>
);

export default ConsentDetailItem;
