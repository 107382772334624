import React from 'react';

import SearchItemAutoComplete from 'pages/settings/search/SearchItemAutocomplete';
import { FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import moment from 'moment';

import ResourceTypes from 'constants/ResourceTypes';
function AgentJobResourceConfiguration({ editor, setEditor }) {
  const types = ['QUALITY_PROBES', 'CLASSIFICATION', 'CRAWLING', 'QUERY_EXTRACTOR', 'POWER_BI'];
  return (
    <>
      <h4>Resource*</h4>
      <SearchItemAutoComplete
        resourceTypes={[
          ResourceTypes.SYSTEM,
          ResourceTypes.QUALITY_CHECK,
          ResourceTypes.QUALITY_PROBE_DEFINITION,
          ResourceTypes.QUERY_STATEMENT,
          ResourceTypes.QUERY_BUCKET
        ]}
        value={{
          resourceIdentifier: editor?.externalIdentifier,
          name: editor?.resourceName || editor?.externalIdentifier
        }}
        onChange={(resource) =>
          setEditor({
            ...editor,
            externalIdentifier: resource && resource.resourceIdentifier,
            resourceType: resource && resource.resourceType,
            resourceName: resource && resource.name,
            name: editor?.name
              ? editor?.name
              : `${resource?.name} Job ${moment(new Date()).format('DD MMM YYYY')}`
          })
        }></SearchItemAutoComplete>
      <h4>Type*</h4>
      <FormControl variant='standard' fullWidth margin='dense'>
        <InputLabel id='issue-type-select-label'>Job Type</InputLabel>
        <Select
          variant='standard'
          labelId='issue-type-select-label'
          id='issue-type-select'
          value={editor?.type}
          onChange={(event) => setEditor({ ...editor, type: event.target.value })}>
          {types.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default AgentJobResourceConfiguration;
