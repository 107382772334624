import React from 'react';

import { Card, CardContent, CardHeader } from '@mui/material';
import JobHistoryTable from 'pages/quality/probes/projects/commons/JobHistoryTable';

function AgentJobsList({ agent }) {
  return (
    <Card>
      <CardHeader title={'Job History'}></CardHeader>
      <CardContent>
        <JobHistoryTable agent={agent}></JobHistoryTable>
      </CardContent>
    </Card>
  );
}

export default AgentJobsList;
