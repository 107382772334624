import React, { useEffect, useState } from 'react';
import { Grid, Card, Typography, Button, CardActions, Tabs, Tab } from '@mui/material';
import {
  fetchLogicalField,
  deleteLogicalField,
  modifyLogicalField
} from '../../../actions/LogicalFieldsActions';
import HistoryButtonLink from '../../history/commons/HistoryButtonLink';

import HistoryTargetTypes from '../../history/commons/HistoryTargetTypes';
import { connect } from 'react-redux';
import ConfirmButton from '../../../components/confirmbutton/ConfirmButton';
import LogicalFieldEditModal from '../commons/LogicalFieldEditModal';
import CommentsPanel from '../../social/comments/CommentsPanel';
import ResourceTypes from '../../../constants/ResourceTypes';
import Permissions from '../../../constants/Permissions';
import { useTranslation } from 'react-i18next';
import PhysicalFieldsSection from 'pages/logicalfields/detail/PhysicalFieldsSection';
import Breadcrumbs from 'pages/sidebar/Navigation';
import LogicalFieldCardView from '../commons/LogicalFieldCardView';
import AuthorizeTeamStewardship from 'components/permissions/AuthorizeTeamStewardship';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import useSearchParams from 'components/useSearchParams';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import withAuthorize from 'components/permissions/withAuthorize';
import ClassificationRulesPanel from 'pages/classification/rules/commons/ClassificationRulesPanel';
import LogicalRelationsCard from 'pages/datacategories/commons/LogicalRelationsCard';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

function LogicalFieldDetailPage({
  logicalFieldUuid,
  logicalField,
  modifyLogicalField,
  fetchLogicalField,
  history,
  deleteLogicalField
}) {
  const [open, setOpen] = useState(false);
  let { t } = useTranslation();

  useEffect(() => {
    fetchLogicalField(logicalFieldUuid);
  }, [logicalFieldUuid, fetchLogicalField]);
  const query = useSearchParams();

  const selectedTab = query.get('tab') || 'physicalfields';
  const handleChangeTab = (_event, newValue) =>
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });
  if (!logicalField) {
    return null;
  }
  return (
    <div>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.logical_model'), to: '/datacategories' },
          {
            text: logicalField.dataCategory.name,
            to: `/datacategories/${logicalField.dataCategory.uuid}`
          },
          { text: logicalField.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('logicalFields.header_detail', { logicalFieldName: logicalField.name }),
          category: EngagementCategories.LOGICAL_MODEL,
          action: EngagementActions.DETAIL
        }}
      />

      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            {t('logicalFields.header_detail', { logicalFieldName: logicalField.name })}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`${ResourceTypes.LOGICAL_FIELD}:${logicalField.uuid}`}
            resourceIdentifier={logicalField.uuid}
            resourceType={ResourceTypes.LOGICAL_FIELD}
            resourceName={logicalField.name}
            pageTitle={t('logicalFields.header_detail', {
              logicalFieldName: logicalField.name
            })}></FavoritesWidget>
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.LOGICAL_FIELD}
            resourceIdentifier={logicalField?.uuid}
            resourceName={logicalField?.name}
          />
        </Grid>
      </Grid>

      <Card>
        <LogicalFieldCardView logicalField={logicalField}></LogicalFieldCardView>
        <CardActions>
          <AuthorizeTeamStewardship
            resourceType={ResourceTypes.DATA_CATEGORY}
            resourceIdentifier={logicalField.dataCategory.uuid}
            hasPermissions={[Permissions.DATA_CATEGORIES_EDITOR]}
            teamCode={logicalField.dataCategory.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE]}>
            <Button onClick={() => setOpen(true)}>Modify</Button>
          </AuthorizeTeamStewardship>
          <AuthorizeTeamStewardship
            resourceType={ResourceTypes.DATA_CATEGORY}
            resourceIdentifier={logicalField.dataCategory.uuid}
            hasPermissions={[Permissions.DATA_CATEGORIES_ADMIN]}
            teamCode={logicalField.dataCategory.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE]}>
            <ConfirmButton
              message={`Do tou want to delete: ${logicalField.name}?`}
              onClick={() => {
                deleteLogicalField(logicalField);
                history.goBack();
              }}>
              Delete
            </ConfirmButton>
          </AuthorizeTeamStewardship>
          <HistoryButtonLink
            targetType={HistoryTargetTypes.LOGICAL_FIELD}
            targetIdentifier={logicalField.uuid}></HistoryButtonLink>
          <div style={{ marginLeft: 'auto' }}>
            <WebActionsButton
              resource={logicalField}
              page={TargetPages.LOGICAL_FIELD_DETAIL_PAGE}
            />
          </div>
        </CardActions>
      </Card>
      <Tabs value={selectedTab} onChange={handleChangeTab} centered>
        <AuthorizedTab
          value='physicalfields'
          hasPermissions={[Permissions.SYSTEMS_VIEWER]}
          label='Physical Fields'
        />
        <Tab value='logicalrelations' label='Relations' />
        <AuthorizedTab
          value='classification'
          hasPermissions={[Permissions.CLASSIFICATION_VIEWER]}
          label='Classification'
        />
        <Tab value='comments' label='Comments' />
      </Tabs>
      <LogicalFieldEditModal
        title={'Edit:' + logicalField.name}
        logicalField={logicalField}
        open={open}
        handleSubmit={modifyLogicalField}
        handleCancel={() => setOpen(false)}
      />
      {selectedTab === 'physicalfields' && (
        <PhysicalFieldsSection logicalFieldUuid={logicalFieldUuid} />
      )}
      {selectedTab === 'logicalrelations' && <LogicalRelationsCard logicalField={logicalField} />}
      {selectedTab === 'classification' && (
        <ClassificationRulesPanel logicalField={logicalField}></ClassificationRulesPanel>
      )}
      {selectedTab === 'comments' && (
        <CommentsPanel
          notExpandable
          resourceType='LOGICAL_FIELD'
          resourceIdentifier={logicalFieldUuid}></CommentsPanel>
      )}
    </div>
  );
}

const AuthorizedTab = withAuthorize(Tab, 'span');
function mapStateToProps(state, props) {
  return {
    logicalFieldUuid: props.match.params['logicalFieldUuid'],
    logicalField: state.logicalfields.content[props.match.params['logicalFieldUuid']]
  };
}
export default connect(mapStateToProps, {
  modifyLogicalField,
  fetchLogicalField,
  deleteLogicalField
})(LogicalFieldDetailPage);
