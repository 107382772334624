import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

export default function withAuthorize(WrappedComponent, FallBackComponent = null) {
  function _WithAuthorize(props) {
    let { hasPermissions, hasAnyPermissions, ...otherProps } = props;
    let { permissions } = useSelector(mapStateToProps);
    if (
      (!hasPermissions || _.every(hasPermissions, (p) => _.includes(permissions, p))) &&
      (!hasAnyPermissions || _.find(hasAnyPermissions, (p) => _.includes(permissions, p)))
    ) {
      return <WrappedComponent {...otherProps} />;
    } else {
      return (FallBackComponent && <FallBackComponent />) || null;
    }
  }

  _WithAuthorize.displayName = `WithAuthorize(${getDisplayName(WrappedComponent)})`;
  return _WithAuthorize;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const mapStateToProps = (state) => ({
  permissions: state.authentication.permissions
});
