import {
  FETCH_PROCESSING,
  CREATE_PROCESSING,
  MODIFY_PROCESSING,
  DELETE_PROCESSING,
  FETCH_ALL_PROCESSING_DISCLAIMERS,
  CREATE_PROCESSING_DISCLAIMER,
  DELETE_PROCESSING_DISCLAIMER,
  FETCH_PROCESSINGS_PAGE,
  SEARCH_PROCESSINGS,
  SET_PROCESSINGS_FILTERS,
  CLEAR_PROCESSINGS_FILTERS,
  EXPORT_PROCESSINGS,
  FETCH_PROCESSING_LEGAL_BASIS
} from '../constants/ActionTypes';
import ProcessingsApi from '../api/ProcessingsApi';
import { buildApiCallAction } from './ActionUtils';
const processingApi = new ProcessingsApi();

export const fetchProcessingsPage = (page = 0, size, filters) =>
  buildApiCallAction({
    api: processingApi,
    apiFunction: (api) => api.getProcessings(page, size, filters),
    actionType: FETCH_PROCESSINGS_PAGE
  });

export const fetchProcessingLegalBasis = (params) =>
  buildApiCallAction({
    api: processingApi,
    apiFunction: (api) => api.getLegalBasis(params),
    actionType: FETCH_PROCESSING_LEGAL_BASIS
  });

export const fetchProcessingsPageWithFilters = (page = 0, size) =>
  buildApiCallAction({
    api: processingApi,
    apiFunction: (api, getState) => {
      let filters = getState().processings.filters.hasFilters
        ? validateFilters(getState().processings.filters)
        : undefined;
      return api.getProcessings(page, size, filters);
    },
    actionType: FETCH_PROCESSINGS_PAGE
  });

export const searchProcessings = (search) =>
  buildApiCallAction({
    api: processingApi,
    apiFunction: (api) => api.searchProcessings(searchParams(search)),
    actionType: SEARCH_PROCESSINGS
  });

const searchParams = (search) => {
  let { searchText } = search;
  return searchText || search;
};

export const fetchProcessing = (processingUuid) =>
  buildApiCallAction({
    api: processingApi,
    apiFunction: (api) => api.getProcessing(processingUuid),
    actionType: FETCH_PROCESSING
  });

export const createProcessing = (processing) =>
  buildApiCallAction({
    api: processingApi,
    apiFunction: (api) => api.postProcessing(processing),
    actionType: CREATE_PROCESSING
  });

export const modifyProcessing = (processing) =>
  buildApiCallAction({
    api: processingApi,
    apiFunction: (api) => api.putProcessing(processing),
    actionType: MODIFY_PROCESSING
  });

export const deleteProcessing = (processing) =>
  buildApiCallAction({
    api: processingApi,
    apiFunction: (api) => api.deleteProcessing(processing),
    actionType: DELETE_PROCESSING,
    payload: processing
  });

export const fetchAllProcessingsDisclaimers = (processingUuid, page, size) =>
  buildApiCallAction({
    api: processingApi,
    apiFunction: (api) => api.getAllProcessingDisclaimers(processingUuid, page, size),
    actionType: FETCH_ALL_PROCESSING_DISCLAIMERS,
    payload: (data) => ({ ...data, processingUuid })
  });

export const createProcessingDisclaimer = (processingUuid, disclaimer) =>
  buildApiCallAction({
    api: processingApi,
    apiFunction: (api) => api.postProcessingDisclaimer(processingUuid, disclaimer),
    actionType: CREATE_PROCESSING_DISCLAIMER
  });

export const deleteProcessingDisclaimer = (processing, disclaimer) =>
  buildApiCallAction({
    api: processingApi,
    apiFunction: (api) => api.deleteProcessingDisclaimer(processing.uuid, disclaimer.uuid),
    actionType: DELETE_PROCESSING_DISCLAIMER,
    payload: (data) => ({
      processingUuid: processing.uuid,
      processingDisclaimerUuid: disclaimer.uuid
    })
  });

export const setProcessingsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_PROCESSINGS_FILTERS, payload: filters });
};

export const clearProcessingsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_PROCESSINGS_FILTERS });
};

export const exportProcessings = (filters) =>
  buildApiCallAction({
    api: processingApi,
    apiFunction: (api) => api.exportProcessings(validateFilters(filters)),
    actionType: EXPORT_PROCESSINGS
  });

export function validateFilters(filters = {}) {
  let {
    dataCategories = [],
    controllers = [],
    processors = [],
    nullTeamCode,
    lifeCycleState = null,
    ...otherFilters
  } = filters;
  let cleanedFilters = {
    ...otherFilters,
    dataCategoriesUuids: dataCategories.map((dc) => dc.uuid),
    endDateOperator: lifeCycleState && getEndDateOperator(lifeCycleState),
    endDateIsNull: lifeCycleState && getEndDateIsNull(lifeCycleState),
    controllersUuids: controllers.map((controller) => controller.uuid),
    processorsUuids: processors.map((processing) => processing.uuid)
  };
  delete cleanedFilters.hasFilters;
  delete cleanedFilters.lifeCycleState;
  delete cleanedFilters.dataCategories;
  delete cleanedFilters.processors;
  delete cleanedFilters.controllers;
  return cleanedFilters;
}

function getEndDateIsNull(lifeCycleState) {
  if (lifeCycleState === null) {
    return null;
  }
  return lifeCycleState === 'ALIVE';
}

function getEndDateOperator(lifeCycleState) {
  if (lifeCycleState === null) {
    return null;
  }
  if (lifeCycleState === 'EXPIRED') {
    return 'LTE';
  } else {
    return 'GT';
  }
}
