import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DatePicker from '../../../components/pickers/DatePicker';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import AdditionalPropertiesEditor from '../../../components/additional_properties/AdditionalPropertiesEditor';
import { DialogContent, DialogTitle, DialogActions } from '@mui/material';
import ProcessingSearchModal from '../../processings/utils/ProcessingSearchModal';
import AccountTeamSelector, {
  ACCESS_LEVELS
} from '../../settings/profile/utils/AccountTeamSelector';
import ProposeButton from '../../workflows/proposals/commons/ProposeButton';
import {
  ProposalResourceTypes,
  ProposalVariationTypes
} from '../../workflows/proposals/commons/ProposalConstants';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import { ProposalItemAddButton } from '../../workflows/proposals/commons/ProposalItemSelector';
import ResourceTypes from '../../../constants/ResourceTypes';

const defaultState = {
  uuid: undefined,
  name: undefined,
  description: undefined,
  category: undefined,
  startDate: undefined,
  endDate: undefined,
  processings: [],
  additionalProperties: [],
  addProcessingOpen: false,
  teamCode: null,
  templateCode: null
};

class TaskEditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = props.task ? { ...defaultState, ...props.task } : defaultState;
  }

  state = this.defaultState;

  onSubmit() {
    let task = {
      ...this.state
    };
    this.props.handleSubmit(task);
  }

  render() {
    const t = this.props.t;

    return (
      <Dialog fullWidth fullScreen={this.props.fullScreen} open={this.props.open}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <h4>{t('tasks.sections.commonMetadata')}</h4>
          <TextField
            variant='standard'
            margin='normal'
            required
            placeholder={t('tasks.fields.nameHint')}
            label={t('tasks.fields.name')}
            fullWidth
            value={this.state.name || ''}
            onChange={(event) => this.setState({ name: event.target.value })}
          />

          <TextField
            variant='standard'
            margin='normal'
            placeholder={t('tasks.fields.descriptionHint')}
            label={t('tasks.fields.description')}
            multiLine={true}
            fullWidth
            value={this.state.description || ''}
            onChange={(event) => this.setState({ description: event.target.value })}
          />

          <TextField
            variant='standard'
            fullWidth
            placeholder={t('tasks.fields.templateCodeHint')}
            label={t('tasks.fields.templateCode')}
            value={this.state.templateCode || ''}
            onChange={(event) => this.setState({ templateCode: event.target.value })}
          />

          <h4>{t('tasks.sections.associatedProcessings')}</h4>
          <ChipWrapper onRequestAdd={() => this.setState({ addProcessingOpen: true })}>
            {this.state.processings.map((p, index) => (
              <ChipItem
                key={index}
                id={index}
                label={p.name}
                onRequestDelete={() =>
                  this.setState({
                    processings: this.state.processings.filter((p, idx) => idx !== index)
                  })
                }
              />
            ))}
          </ChipWrapper>
          <ProposalItemAddButton
            proposal={this.state.proposal}
            resourceType={ProposalResourceTypes.PROCESSING}
            onSubmit={(item) => this.setState({ processings: this.state.processings.concat(item) })}
          />
          <ProcessingSearchModal
            title={t('tasks.addProcessingTitle')}
            open={this.state.addProcessingOpen}
            onCancel={() => this.setState({ addProcessingOpen: false })}
            onSubmit={(processing) =>
              this.setState({
                processings: this.state.processings.concat([processing]),
                addProcessingOpen: false
              })
            }
          />

          <h4>{t('tasks.sections.businessMetadata')}</h4>
          <TextField
            variant='standard'
            margin='normal'
            placeholder={t('tasks.fields.categoryHint')}
            label={t('tasks.fields.category')}
            fullWidth
            value={this.state.category || ''}
            onChange={(event, newValue) => this.setState({ category: event.target.value })}
          />

          <h4>{t('tasks.sections.lifecycleDefinition')}</h4>

          <DatePicker
            label={t('tasks.fields.startDate')}
            hintText={t('tasks.fields.startDate')}
            value={this.state.startDate}
            onChange={(date) => this.setState({ startDate: date })}
          />

          <DatePicker
            label={t('tasks.fields.endDate')}
            hintText={t('tasks.fields.endDateHint')}
            value={this.state.endDate}
            onChange={(date) => this.setState({ endDate: date })}
          />

          <AdditionalPropertiesEditor
            resourceType={ResourceTypes.TASK}
            additionalProperties={this.state.additionalProperties}
            onChange={(newList) =>
              this.setState({
                additionalProperties: newList
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <AccountTeamSelector
            accessLevel={ACCESS_LEVELS.WRITE}
            accessLevel2={ACCESS_LEVELS.PROPOSE}
            style={{ float: 'left' }}
            selectedTeamCode={this.state.teamCode || ''}
            onChange={(code) => this.setState({ teamCode: code })}
          />
          <div style={{ flexGrow: 1 }} />
          <Button children={t('commons.actions.cancel')} onClick={this.props.handleCancel} />

          <ProposeButton
            disabled={this.state.name === undefined || this.state.processings.length === 0}
            resourceIdentifier={this.state.uuid}
            resourceName={this.state.name}
            resourceType={ProposalResourceTypes.TASK}
            resourcePayload={this.state}
            variationType={
              this.state.uuid ? ProposalVariationTypes.PUT : ProposalVariationTypes.POST
            }
            onProposalChange={(proposal) => this.setState({ proposal })}
          />
          <Authorize hasAnyPermissions={[Permissions.TASKS_EDITOR, Permissions.TASKS_ADMIN]}>
            <Button
              children={t('commons.actions.submit')}
              variant='contained'
              color='primary'
              disabled={
                this.state.name === undefined ||
                this.state.processings.length === 0 ||
                this.state.proposal
              }
              onClick={() => this.onSubmit()}
            />
          </Authorize>
        </DialogActions>
      </Dialog>
    );
  }
}

export default _.flow([withTranslation()])(TaskEditorModal);

TaskEditorModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string,
  task: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};
