import React, { useEffect } from 'react';
import { Chart } from 'react-google-charts';
import _ from 'lodash';

export default function CompilationKPIsChart({ onFetch }) {
  const [data, setData] = React.useState(null);
  useEffect(
    function () {
      onFetch().then((response) => {
        if (response) {
          setData(prepareData(response));
        }
      });
    },
    [setData, onFetch]
  );
  return (
    <Chart
      width={'100%'}
      // Note here we use Bar instead of BarChart to load the material design version
      chartType='BarChart'
      loader={<div>Loading Chart</div>}
      data={data ? data : [[]]}
      options={{
        colors: ['#00AECA', '#455a64'],
        isStacked: 'percent',
        annotations: { alwaysOutside: true },
        hAxis: {
          title: 'Fillness percentage'
        },
        vAxis: {
          title: 'Properties'
        },
        bars: 'horizontal',
        axes: {
          y: {
            0: { side: 'right' }
          }
        },
        legend: { position: 'none' }
      }}
      // For tests
      rootProps={{ 'data-testid': '5' }}
    />
  );
}

function prepareData(response) {
  let processedData = [];
  processedData.push(['Properties', 'Filled Count', { role: 'annotation' }, 'Unfilled Count']);
  let totalCount = response.defaultProperties.totalCount;
  for (let key in response.defaultProperties) {
    if (key === 'totalCount') continue;
    const counterValue = response.defaultProperties[key];
    const percentage = (counterValue / totalCount) * 100;
    const percentageString = percentage.toFixed(0) + '%';
    processedData.push([key, counterValue, percentageString, totalCount - counterValue]);
  }
  for (let key in response.additionalProperties) {
    if (key === 'totalCount') continue;
    const counterValue = response.additionalProperties[key];
    const percentage = (counterValue / totalCount) * 100;
    const percentageString = percentage.toFixed(0) + '%';
    processedData.push([key, counterValue, percentageString, totalCount - counterValue]);
  }
  return _.sortBy(processedData, (r) => r[3]);
}
