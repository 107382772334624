import React from 'react';

import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import RoutinesSearchBox from './RoutinesSearchBox';
import RoutinesExportButton from './RoutinesExportButton';
import RoutinesFilters from './RoutinesFilters';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const RoutinesListToolbox = ({ systemUUID }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems='center'>
      <Grid item>
        <RoutinesSearchBox />
      </Grid>
      <Grid item>
        <FavoritesWidget
          itemIdentifier={'page:systemroutines-list'}
          iconSize={'small'}
          pageTitle={t('routines.header')}></FavoritesWidget>
      </Grid>
      <Grid item>
        <RoutinesExportButton />
      </Grid>
      <Grid item>
        <RoutinesFilters />
      </Grid>
      <Grid item>
        <WebActionsButton page={TargetPages.SYSTEM_ROUTINE_LIST_PAGE} />
      </Grid>
    </Grid>
  );
};

export default RoutinesListToolbox;
