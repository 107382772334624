import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <Grid
      style={{ position: 'absolute', bottom: '10px', maxWidth: '400px', left: '35%' }}
      spacing={1}
      container
      direction='row'
      justifyContent='center'
      alignItems='center'>
      <Grid item>
        <Typography
          style={{ marginTop: 16, color: 'white' }}
          variant='caption'
          component={Link}
          to={{ pathname: 'https://blindata.io/' }}
          target='_blank'>
          blindata.io
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='caption' style={{ marginTop: 16, color: 'white' }}>
          |
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{ marginTop: 16, color: 'white' }}
          variant='caption'
          component={Link}
          to='/terms-and-conditions'>
          {'Terms & Conditions'}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='caption' style={{ marginTop: 16, color: 'white' }}>
          |
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{ marginTop: 16, color: 'white' }}
          variant='caption'
          component={Link}
          to='/privacy-policy'>
          Privacy Policy
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='caption' style={{ marginTop: 16, color: 'white' }}>
          |
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{ marginTop: 16, color: 'white' }}
          variant='caption'
          component={Link}
          to='/docs'>
          API Docs
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Footer;
