import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchTemporal } from 'actions/IssueMngtDashboardsActions';
import { Chart } from 'react-google-charts';

const CompletionLineChart = ({ campaignUuid, values, fetchTemporal }) => {
  useEffect(() => {
    fetchTemporal(campaignUuid);
  }, [campaignUuid, fetchTemporal]);

  const rows = values
    ? [['Date', 'Total', 'Completed'], ...values.rows.map((r) => [new Date(r[0]), r[1], r[2]])]
    : [];

  return values ? (
    <Chart
      width='100%'
      height='350px'
      chartType='AreaChart'
      loader={<div>Loading Chart</div>}
      data={rows}
      options={{
        title: 'Issues Completion Over Time',
        hAxis: { title: 'Dates' },
        vAxis: { title: '# Issues', minValue: 0 },
        series: { 0: { color: '#455a64' }, 1: { color: '#00AECA' } },
        pointSize: 5
      }}
    />
  ) : null;
};
function mapStateToProps(state, props) {
  return {
    values: state.campaigns.dashboards.temporal[props.campaignUuid]
  };
}

export default connect(mapStateToProps, {
  fetchTemporal
})(CompletionLineChart);
