import React from 'react';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TeamCodeDecorator from '../../../settings/teams/common/TeamCodeDecorator';
import { ProposalDetailsView } from '../detail/ProposalDetailPage';

function ProposalListsItem({ proposal }) {
  const { t } = useTranslation();
  return (
    <ExpandableCard
      decorator={<TeamCodeDecorator teamCode={proposal.teamCode} />}
      title={proposal.name}
      subheader={`${proposal.proposalStatus}`}
      actions={[
        <Button to={`/workflows/proposals/${proposal.uuid}`} component={Link}>
          {t('commons.actions.detail')}
        </Button>
      ]}>
      <ProposalDetailsView proposal={proposal} />
    </ExpandableCard>
  );
}

export default ProposalListsItem;
