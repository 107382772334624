import { combineReducers } from 'redux';
import querybuckets from './querybuckets';
import querystatements from './querystatements';

import {
  FETCH_DATA_FLOWS_PAGE,
  DELETE_DATA_FLOW,
  CLEAR_DATA_FLOWS_PAGE,
  FETCH_DATA_FLOW,
  CREATE_DATA_FLOW,
  MODIFY_DATA_FLOW,
  EDIT_DATA_FLOW_EDITOR,
  CLEAR_DATA_FLOW_EDITOR,
  EDIT_DATA_FLOW_FILTERS,
  CLEAR_DATA_FLOW_FILTERS
} from '../../constants/ActionTypes';

const content = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_FLOW:
    case CREATE_DATA_FLOW:
    case MODIFY_DATA_FLOW:
      return { ...state, [action.payload.uuid]: action.payload };
    case DELETE_DATA_FLOW:
      let { [action.payload.uuid]: _clearedElem, ...stateDiff } = state;
      return stateDiff;
    default:
      return state;
  }
};

const defaultCurrentPage = { content: [], number: 0, size: 25, totalPages: 0 };

const currentPage = (state = defaultCurrentPage, action) => {
  switch (action.type) {
    case FETCH_DATA_FLOWS_PAGE:
      return action.payload;
    case DELETE_DATA_FLOW:
      return {
        ...state,
        content: state.content.filter((i) => i.uuid !== action.payload.uuid)
      };
    case CLEAR_DATA_FLOWS_PAGE:
      return defaultCurrentPage;
    default:
      return state;
  }
};

const editor = (state = {}, action) => {
  switch (action.type) {
    case EDIT_DATA_FLOW_EDITOR:
      return { ...state, ...action.payload };
    case CLEAR_DATA_FLOW_EDITOR:
      return {};
    default:
      return state;
  }
};

const defaultFilters = {
  scopes: null,
  fromSystems: [],
  fromPhysicalEntities: [],
  nullFromPhysicalEntities: false,
  fromPhysicalFields: [],
  toSystems: [],
  toPhysicalEntities: [],
  nullToPhysicalEntites: false,
  toPhysicalFields: [],
  aboutLogicalFields: [],
  aboutDataCategories: []
};

const filters = (state = defaultFilters, action) => {
  switch (action.type) {
    case EDIT_DATA_FLOW_FILTERS:
      return { ...state, ...action.payload };
    case CLEAR_DATA_FLOW_FILTERS:
      return defaultFilters;
    default:
      return state;
  }
};

export default combineReducers({
  currentPage,
  content,
  editor,
  filters,
  querybuckets,
  querystatements
});
