import React from 'react';
import _ from 'lodash';
import { Menu } from '@mui/material';
import { connect } from 'react-redux';
import { closeContextualMenu } from '../../../actions/GraphActions';
import { withRouter } from 'react-router';
import ContextualMenuEdgeContent from './ContextualMenuEdgeContent';
import ContextualMenuNodeContent from './ContextualMenuNodeContent';

function ContextualMenu({
  open,
  edge,
  node,
  nodeDetail,
  pointer = { DOM: { x: 0, y: 0 } },
  graphHandler,
  history,
  onClose
}) {
  return (
    <Menu
      anchorReference='anchorPosition'
      anchorPosition={{
        left: pointer.DOM.x,
        top: pointer.DOM.y
      }}
      autoFocus={false}
      disableAutoFocusItem={true}
      open={open && (node || edge)}
      onClose={onClose}
      onContextMenu={(event) => {
        event.preventDefault();
        onClose();
      }}>
      {node ? (
        <ContextualMenuNodeContent {...{ node, nodeDetail, history }} />
      ) : (
        <ContextualMenuEdgeContent {...{ edge, onClose, history, graphHandler }} />
      )}
    </Menu>
  );
}

function mapStateToProps(state) {
  let node = state.graph.contextualMenu.node;
  let nodeDetail = node ? state.graph.nodeDetails[state.graph.contextualMenu.node.id] : null;
  return {
    open: state.graph.contextualMenu.open,
    pointer: state.graph.contextualMenu.pointer,
    edge: state.graph.contextualMenu.edge,
    node,
    nodeDetail
  };
}

export default _.flow([connect(mapStateToProps, { onClose: closeContextualMenu }), withRouter])(
  ContextualMenu
);
