import React from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'pages/sidebar/Navigation';
import StewardshipAssignmentCard from './StewardshipAssignmentsCard';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

function StewardshipAssignmentsPage() {

  let { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.stewardship'), to: '/stewardship/responsibilities' },
          { text: t('stewardship.assignments.header') }
        ]}
      />
      <Tracking info={{
        pageTitle: t('stewardship.assignments.header'),
        category: EngagementCategories.STEWARDSHIP,
        action: EngagementActions.LIST
      }} />
      <StewardshipAssignmentCard></StewardshipAssignmentCard>
    </>
  );
}

export default StewardshipAssignmentsPage;
