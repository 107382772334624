import React from 'react';
import { Grid } from '@mui/material';
import ResourceTypes from 'constants/ResourceTypes';
import { MyIssuesPanel } from './MyIssuesPanel';
import { ResponsibilitiesCard } from './ResponsibilitiesCard';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import QualitySuiteChecksTimelineChart from 'pages/quality/kqis/quality_suites/detail/QualitySuiteChecksTimelineChart';
import HomeQualityChecksLastRunChart from './HomeQualityChecksLastRunChart';

const HomeDashboardPage = ({ _props }) => {
  return (
    <Grid container style={{ padding: 8, margin: '0 auto 0px auto' }}>
      <Authorize hasPermissions={[Permissions.CAMPAIGNS_VIEWER]}>
        <Grid item xs={12} md={12} lg={12}>
          <MyIssuesPanel height={400} />
        </Grid>
      </Authorize>
      <Grid container item spacing={1}>
        <Authorize hasPermissions={[Permissions.QUALITY_VIEWER]}>
          <Grid item lg={6} md={12} style={{ width: '100%' }}>
            <HomeQualityChecksLastRunChart />
          </Grid>
        </Authorize>
        <Authorize hasPermissions={[Permissions.QUALITY_VIEWER]}>
          <Grid item lg={6} md={12} style={{ width: '100%' }}>
            <QualitySuiteChecksTimelineChart inWatchlist={true} />
          </Grid>
        </Authorize>
      </Grid>
      <Authorize hasPermissions={[Permissions.STEWARDSHIP_VIEWER]}>
        <Grid container item spacing={1}>
          <Grid item xs={12} md={12} lg={6}>
            <ResponsibilitiesCard height={200} resourceType={ResourceTypes.DATA_CATEGORY} />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <ResponsibilitiesCard height={200} resourceType={ResourceTypes.PHYSICAL_ENTITY} />
          </Grid>
        </Grid>
      </Authorize>
    </Grid>
  );
};

export default HomeDashboardPage;
