import React from 'react';
import PaginatedPanel from '../../../components/pagination/PaginatedPanel';
import { connect } from 'react-redux';
import _ from 'lodash';
import { fetchDataFlowsPageWithFilters, deleteDataFlow } from '../../../actions/DataFlowsActions';
import { Card, CardContent, Typography } from '@mui/material';
import PropertiesTable from '../../../components/tables/PropertiesTable';
import { withTranslation } from 'react-i18next';
import DataFlowListToolbar from './DataFlowListToolbar';

class DataFlowsList extends React.Component {
  state = {
    selectedIndexes: new Set()
  };

  onSelectionChanged(selectedIndexes) {
    this.setState({ selectedIndexes });
  }

  render() {
    let { t, currentPage, fetchDataFlowsPageWithFilters, deleteDataFlow } = this.props;
    let { selectedIndexes } = this.state;
    let selectedDataFlows = [...selectedIndexes].map((i) => currentPage.content[i]);
    return (
      <>
        <DataFlowListToolbar
          selectedDataFlows={selectedDataFlows}
          onDeleteDataFlow={(dataFlows) => {
            dataFlows
              .reduce(
                (prevResult, df) => prevResult.then(() => deleteDataFlow(df)),
                Promise.resolve()
              )
              .then(() => fetchDataFlowsPageWithFilters(0));

            this.setState({ selectedIndexes: new Set() });
          }}
          onRefreshData={() => {
            fetchDataFlowsPageWithFilters(0);
          }}
        />
        <Card>
          <CardContent>
            <PaginatedPanel
              autoload
              currentPage={currentPage}
              onPageSelection={(page) => {
                this.setState({ selectedIndexes: new Set() });
                fetchDataFlowsPageWithFilters(page);
              }}>
              {currentPage.content.length > 0 ? (
                <PropertiesTable
                  headers={[
                    t('dataflows.fields.name'),
                    t('dataflows.fields.scope'),
                    t('dataflows.fields.from'),
                    t('dataflows.fields.to')
                  ]}
                  propertiesPaths={['name', 'scope', 'from', 'to']}
                  selectedIndexes={this.state.selectedIndexes}
                  onSelectionChanged={this.onSelectionChanged.bind(this)}
                  rows={currentPage.content.map((df) => ({
                    name: df.name,
                    scope: df.scope,
                    from: [
                      _.get(df, 'fromSystem.name'),
                      _.get(df, 'fromPhysicalEntity.name'),
                      _.get(df, 'fromPhysicalField.name')
                    ].join(',\n'),
                    to: [
                      _.get(df, 'toSystem.name'),
                      _.get(df, 'toPhysicalEntity.name'),
                      _.get(df, 'toPhysicalField.name')
                    ].join(',\n')
                  }))}
                />
              ) : (
                <Typography variant='body1' style={{ textAlign: 'center', padding: 100 }}>
                  {t('dataflows.noDataFlowsFound')}
                </Typography>
              )}
            </PaginatedPanel>
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: state.dataflows.currentPage
});

export default _.flow([
  withTranslation(),
  connect(mapStateToProps, { fetchDataFlowsPageWithFilters, deleteDataFlow })
])(DataFlowsList);
