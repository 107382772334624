import React from 'react';
import { Switch, Route } from 'react-router';
import TemplatesTasksListPage from './list/TemplatesTasksListPage';
import TemplateTaskDetailPage from './detail/TemplateTaskDetailPage';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  return (
    <Switch>
      <Route path={`${props.match.url}/:sequenceId`} component={TemplateTaskDetailPage}></Route>

      <Route path={`${props.match.url}`} component={TemplatesTasksListPage}></Route>
    </Switch>
  );
}
