import { buildApiCallAction } from './ActionUtils';
import { FETCH_UNIVERSAL_SEARCH } from '../constants/ActionTypes';
import UniversalSearchApi from '../api/UniversalSearchApi';

const api = new UniversalSearchApi();

export const search = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getSearch(params),
    actionType: FETCH_UNIVERSAL_SEARCH
  });
