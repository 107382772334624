import {
  Autocomplete,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField
} from '@mui/material';
import {
  SystemTechnologyTypes,
  getIconShort,
  getTechnologyConfig
} from 'constants/SystemTechnologyTypes';
import React from 'react';

const SystemTechnologyItemSelect = ({ value, onChange, ...props }) => {
  return (
    <Autocomplete
      {...props}
      fullWidth
      options={SystemTechnologyTypes}
      autoHighlight
      freeSolo
      getOptionLabel={(option) => option.label}
      renderOption={(renderProps, option) => (
        <MenuItem {...renderProps}>
          <ListItemIcon>{getIconShort(option, { width: '1.5em', height: '1.5em' })}</ListItemIcon>
          <ListItemText>{option.label}</ListItemText>
        </MenuItem>
      )}
      inputValue={getTechnologyConfig(value) ? getTechnologyConfig(value).label : value}
      onInputChange={(_event, input) => {
        onChange(getTechnologyConfig(input)?.key || input);
      }}
      renderInput={(params) => (
        <TextField
          margin='dense'
          variant='standard'
          {...params}
          placeholder='Choose a Technology'
          label='System Technology'
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                {getIconShort(getTechnologyConfig(value), { width: '1.5em', height: '1.5em' })}
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};

export default SystemTechnologyItemSelect;
