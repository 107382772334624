import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { fetchContractConfigurations } from 'actions/ContractsActions';
import { ChipItem } from 'components/chiplist/ChipList';
import TableCell from 'components/tables/GenericTableCell';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

const BASE_PARAMS = { sort: 'createdAt,DESC' };

function ContractConfigurationListPanel({ contractName, currentPage, onFetch }) {
  let history = useHistory();
  useEffect(() => {
    if (contractName) onFetch({ contractName, size: 10, ...BASE_PARAMS });
  }, [contractName, onFetch]);
  if (!contractName) return null;
  return (
    <Card>
      <CardHeader
        title='Configurations'
        action={
          <IconButton
            size='small'
            onClick={() => history.push(`/contracts/configurations/editor/create`)}>
            <Add />
          </IconButton>
        }
      />
      <CardContent>
        {!currentPage?.content?.length ? (
          <Typography variant='body2' align='center' style={{ textAlign: 'center', padding: 4 }}>
            No configuration defined for this contract definition.
          </Typography>
        ) : (
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Version</TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPage.content.map((cc) => (
                <TableRow
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(`/contracts/configurations/${cc.uuid}`)}>
                  <TableCell>{cc.contractName}</TableCell>
                  <TableCell>{cc.version}</TableCell>
                  <TableCell>{cc.language}</TableCell>
                  <TableCell>{new Date(cc.createdAt).toLocaleString()}</TableCell>
                  <TableCell style={{ flexGrow: 1 }}>
                    {cc.active && <ChipItem size='small'>Active</ChipItem>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TablePagination
              component='div'
              style={{ overflowX: 'hidden' }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              page={currentPage.number}
              rowsPerPage={currentPage.size}
              count={currentPage.totalElements}
              onPageChange={(event, page) =>
                onFetch({ page, size: currentPage.size, contractName, ...BASE_PARAMS })
              }
              onRowsPerPageChange={(event) =>
                onFetch({
                  page: currentPage.number,
                  size: event.target.value,
                  contractName,
                  ...BASE_PARAMS
                })
              }
            />
          </Table>
        )}
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  currentPage: state.contracts.configurations.currentPage
});

const mapDispatchToProps = {
  onFetch: fetchContractConfigurations
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractConfigurationListPanel);
