import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import { ListItem, ListItemText } from '@mui/material';
import _ from 'lodash';

const MAX_SUGGESTIONS = 50;
function getSuggestions(dataSource, dataSourceConfig, inputValue, showAllOptions = false) {
  let count = 0;
  return dataSource.filter((item) => {
    let inputString = _.isObject(inputValue) ? getLabel(inputValue, dataSourceConfig) : inputValue;
    const keep =
      (!inputString ||
        (getLabel(item, dataSourceConfig) || '')
          .toLowerCase()
          .indexOf(inputString.toLowerCase()) !== -1) &&
      count < MAX_SUGGESTIONS;
    if (keep) {
      count += 1;
    }
    return keep;
  });
}

function getLabel(suggestion, dataSourceConfig) {
  return dataSourceConfig ? suggestion[dataSourceConfig.text] : suggestion;
}
function getSubheader(suggestion, dataSourceConfig) {
  return dataSourceConfig && dataSourceConfig.subheader
    ? _.get(suggestion, dataSourceConfig.subheader)
    : null;
}

class AutoCompleteMui extends Component {
  constructor(props) {
    super(props);
    this.id = Math.random() + '';
  }
  render() {
    let props = this.props;
    const {
      dataSource = [],
      dataSourceConfig,
      showAllOptions,
      onUpdateInput,
      onNewRequest
    } = props;
    const { label, placeholder, value, fullWidth, helperText, margin, disabled, required } = props;
    return (
      <AutoComplete
        freeSolo
        disabled={disabled}
        value={value}
        getOptionLabel={(inputOption) =>
          _.isString(inputOption) ? inputOption : getLabel(inputOption, dataSourceConfig)
        }
        options={getSuggestions(dataSource, dataSourceConfig, value, showAllOptions)}
        renderOption={(props, option) => {
          return (
            <ListItem {...props}>
              <ListItemText
                primary={getLabel(option, dataSourceConfig)}
                secondary={getSubheader(option, dataSourceConfig)}
              />
            </ListItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            variant='standard'
            {...params}
            required={required}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            onChange={(event) => {
              onUpdateInput(event.target.value, dataSource);
            }}
            margin={margin}
            fullWidth={fullWidth}
          />
        )}
        onChange={(event, value) => {
          onNewRequest
            ? onNewRequest(value && getLabel(value, dataSourceConfig), dataSource, value)
            : onUpdateInput(value && getLabel(value, dataSourceConfig), dataSource, value);
        }}
      />
    );
  }
}
/**
 * DataSource config:
 * - text: specifies the property containing the label
 * - subheader: specifies the property containing the resource subheader
 * - showAll: specifies wheter to filter or not
 */
AutoCompleteMui.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  helperText: PropTypes.string,
  dataSource: PropTypes.array.isRequired,
  dataSourceConfig: PropTypes.object,
  showAllOptions: PropTypes.bool,
  onUpdateInput: PropTypes.func,
  onNewRequest: PropTypes.func
};
export default AutoCompleteMui;
