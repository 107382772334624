import React from 'react';
import '../login/LoginPage.css';
import { Link } from 'react-router-dom';
import { Card, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function TermsAndConditionsPage() {
  return (
    <>
      <IconButton
        style={{ position: 'absolute', top: '10px', left: '10px' }}
        component={Link}
        to='/login'
        size='large'>
        <ArrowBackIcon></ArrowBackIcon>
      </IconButton>
      <Card
        style={{
          position: 'absolute',
          top: '10%',
          left: '5%',
          width: '90%',
          height: '80%',
          padding: '30px',
          overflow: 'auto'
        }}>
        <form>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <h1>Condizioni generali di fornitura del servizio licenza software SAAS </h1>
          </div>

          <p style={{ color: 'White' }}>
            Con le presenti condizioni generali (<b>“Condizioni Generali”</b> o, più in generale, il
            <b>“Contratto”</b>) sono definiti i termini e condizioni conformemente alle quali
            Blindata metterà a disposizione al soggetto, di volta in volta individuato nell’Ordine (
            <b>“Cliente”</b>), (singolarmente la <b>“Parte”</b> e, collettivamente, le{' '}
            <b>“Parti”</b>) il diritto di usufruire della piattaforma in modalità “Software as a
            Service” (“SAAS”) per la gestione dei processi di “Data Management”, in conformità alla
            normativa privacy (<b>“Piattaforma”</b>), nonché le ulteriori funzionalità o servizi
            connessi (nel complesso, il
            <b>“Servizio”</b>), a seconda il Modulo Software indicato nell’Ordine.<br></br>
            <br></br> Il Contratto è costituito dall’Ordine e dai rispettivi allegati, di cui le
            presenti Condizioni Generali fanno parte. Eventuali deroghe al presente Contratto
            saranno valide ed efficaci solo se contenute nei documenti accettati e formalizzati per
            iscritto da entrambe le Parti.
          </p>
          <h2 style={{ color: 'White' }}>1 Perfezionamento del contratto</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                1.1) Il Contratto si intende validamente stipulato tra le Parti con la ricezione
                della accettazione dell’Ordine relativo al Servizio, debitamente sottoscritto, dal
                Cliente. Il perfezionamento del Contratto implica l’automatica integrale
                accettazione delle presenti Condizioni Generali da parte del Cliente.
              </ll>
              <br></br>
              <br></br>
              <ll>
                1.2) Il Cliente ha la facoltà di attivare diversi tipi di Moduli Software legati al
                Servizio. Blindata procederà all’attivazione del Servizio entro 10 giorni dalla
                stipula del Contratto, dandone avviso al Cliente.
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>2 Corrispettivi e pagamenti</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                2.1) Il Cliente è tenuto al pagamento del corrispettivo, secondo le modalità e le
                tempistiche previste nel modulo d’ordine (“Corrispettivo”). Blindata si riserva la
                facoltà di apportare modifiche al Corrispettivo fermo restando che il nuovo
                corrispettivo sarà applicato dopo la scadenza del Contratto, e fermo restando la
                facoltà del Cliente di recedere dal Contratto entro il termine di 30 (trenta) giorni
                dal ricevimento della comunicazione di modifica di Blindata.
              </ll>
              <br></br>
              <br></br>
              <ll>
                2.2)Il Corrispettivo varia ed è calcolato sulla base del/i Modulo/i Software
                prescelto/i e dei Parametri applicati. Il Corrispettivo non comprende eventuali
                costi di assistenza e avviamento. Ogni prestazione aggiuntiva sarà pertanto oggetto
                di accordo separato.
              </ll>
              <br></br>
              <br></br>
              <ll>
                2.3) Blindata si riserva la facoltà di sospendere anche senza preavviso il Servizio
                in caso di mancato o ritardato pagamento del Corrispettivo. Trascorsi inutilmente 30
                (trenta) giorni dalla sospensione del Servizio, il Contratto si risolverà di diritto
                per inadempimento, ferma restando il diritto di Blindata a ritenere il Corrispettivo
                pagato sino ad allora a titolo di penale, salvo il risarcimento del danno ulteriore.
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>3 Uso del servizio e della piattaforma</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                3.1) Il Cliente è tenuto al pagamento del corrispettivo, secondo le modalità e le
                tempistiche previste nel modulo d’ordine (“Corrispettivo”). Blindata si riserva la
                facoltà di apportare modifiche al Corrispettivo fermo restando che il nuovo
                corrispettivo sarà applicato dopo la scadenza del Contratto, e fermo restando la
                facoltà del Cliente di recedere dal Contratto entro il termine di 30 (trenta) giorni
                dal ricevimento della comunicazione di modifica di Blindata.
              </ll>
              <br></br>
              <br></br>
              <ll>
                3.2)Il Corrispettivo varia ed è calcolato sulla base del/i Modulo/i Software
                prescelto/i e dei Parametri applicati. Il Corrispettivo non comprende eventuali
                costi di assistenza e avviamento. Ogni prestazione aggiuntiva sarà pertanto oggetto
                di accordo separato.
              </ll>
            </ul>
            <p>
              I Parametri potranno essere quelli di seguito definiti:<br></br>
            </p>
            <ul>
              <ll>
                <b>API call</b>: indica e definisce le chiamate effettuate per registrare eventi,
                tipicamente legati al consenso;
              </ll>
              <br></br>
              <br></br>
              <ll>
                <b>Tenant</b>: indica e definisce la realtà aziendale o la persona giuridica per la
                quale vengono definiti i registri di trattamento. Tipicamente riveste il ruolo di
                Titolare oppure di Responsabile del trattamento;
              </ll>
              <br></br>
              <br></br>
              <ll>
                <b>Utenti</b>: indica e definisce il numero di utenze nominali attivate, una volta
                create le credenziali per l’accesso alla Piattaforma;
              </ll>
              <br></br>
              <br></br>
              <ll>
                <b>Processing</b>: indica e definisce i trattamenti di dati, sia che riguardino i
                dati personali che dati di natura e contenuto diverso;
              </ll>
              <br></br>
              <br></br>
              <ll>
                <b>System</b>: indica e definisce le applicazioni, le basi di dati, le cartelle su
                “File System” mappate all'interno della Piattaforma.
              </ll>
              <br></br>
              <br></br>
              <ll>
                <b>Tabelle</b>: indica e definisce il numero di tabelle e le viste oggetto di
                applicazione delle regole definite nel modulo di Data Classification
              </ll>
              <br></br>
              <br></br>
              <ll>
                <b>Quality Check</b>: indica e definisce il numero di controlli di qualità creati
                all’interno della Piattaforma.
              </ll>
            </ul>
          </p>
          <ul>
            <ll>
              3.3) Il Cliente si impegna a mantenere Blindata indenne da ogni pretesa che possa
              essere avanzata nei confronti di quest’ultimo a qualsiasi titolo o in qualsiasi modo
              collegate all’inidoneità dei sistemi hardware e/o di rete e/o di software del Cliente
              atti a consentire la corretta fruizione del Servizio.
            </ll>
            <br></br>
            <br></br>
            <ll>
              3.4) Il Cliente si impegna ad utilizzare il Servizio in conformità al presente
              Contratto ed alle norme di legge. È espressamente vietato al Cliente:
              <br></br>
              <br></br>
              <ul>
                <ll>
                  - Decodificare, decompilare o disassemblare la Piattaforma o eseguire ingegneria
                  inversa sullo stesso;
                </ll>
                <br></br>
                <br></br>
                <ll>
                  - Generare in alcuna maniera e con alcun mezzo, tramite operazioni di
                  personalizzazione, la convinzione che la Piattaforma sia di sua proprietà e / o
                  creazione;
                </ll>
                <br></br>
                <br></br>
                <ll>
                  - Pubblicare la Piattaforma e/o parti di essa, per consentirne la duplicazione o
                  qualsiasi altro utilizzo da parte di altri;
                </ll>
                <br></br>
                <br></br>
                <ll>
                  - Consentire l’accesso alle informazioni raccolte, memorizzate e trattate tramite
                  il Servizio ad altri utenti secondo modalità diverse da quelle espressamente
                  previste dal presente Contratto.
                </ll>
                <br></br>
              </ul>
            </ll>
            <br></br>
            <br></br>
            <ll>
              3.5) Il Cliente acconsente a fornire a Blindata, direttamente e/o tramite un proprio
              delegato, le informazioni necessarie per verificare le condizioni di utilizzo
              effettivo del Servizio e la conseguente conformità delle stesse al Contratto; tali
              informazioni potranno anche essere acquisite da Blindata e/o chi per esso tramite
              procedura telematica.
            </ll>
            <br></br>
            <br></br>
            <ll>
              3.6) Il Cliente prende atto ed accetta che l’utilizzo della Piattaforma non
              garantisce, di per sé, la conformità al Regolamento EU 2016/679 del 27 aprile 2016 e
              al D.Lgs. n. 196/2003 “Codice in materia di protezione dei dati personali”, come
              modificato dal D.Lgs n. 101/2018, restando dunque in capo al Cliente la responsabilità
              relativa al rispetto di tale normativa, anche mediante l’adozione di adeguati processi
              interni.
            </ll>
          </ul>
          <h2 style={{ color: 'White' }}>4 Sospensione temporanea o interruzione del servizio</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                4.1) Il Cliente prende atto ed accetta che Blindata potrà sospendere e/o
                interrompere il Servizio per garantire gli interventi di manutenzione ordinaria o
                straordinaria che si rendano opportuni e/o necessari sia ai locali che ospitano
                l’Infrastruttura che ai server e/o apparecchiature ivi contenute. In tali casi,
                Blindata si impegna a ripristinare il Servizio nel minor tempo possibile secondo le
                circostanze.
              </ll>
              <br></br>
              <br></br>
              <ll>
                4.2) Blindata, inoltre, si riserva la facoltà di sospendere e/o interrompere la
                fornitura del Servizio nei seguenti casi:
                <ll>
                  <br></br>
                  <br></br>
                  <ul>
                    <ll>
                      - Uso improprio o violazioni della Piattaforma o del Servizio da parte del
                      Cliente;
                    </ll>
                    <br></br>
                    <br></br>
                    <ll>
                      - Guasti e/o malfunzionamenti alla rete e agli apparati di fornitura del
                      Servizio dipendenti da caso fortuito o forza maggiore o che comportino
                      pericolo per la rete internet, e/o manutenzioni non programmabili e/o
                      prevedibili e tecnicamente indispensabili;
                    </ll>
                    <br></br>
                    <br></br>
                    <ll>
                      - Qualora ricorrano motivate ragioni di sicurezza e/o garanzia di
                      riservatezza;
                    </ll>
                    <br></br>
                    <br></br>
                    <ll>
                      - Errato o non conforme utilizzo del Servizio da parte dal Cliente o comunque
                      mancati adempimenti del Cliente ad obblighi di legge in materia di utilizzo
                      del Servizio;
                    </ll>
                    <br></br>
                    <br></br>
                    <ll>
                      - Problemi di Servizio che non siano rimediabili senza sospendere o
                      interrompere lo stesso.
                    </ll>
                    <br></br>
                    <br></br>
                  </ul>
                </ll>
                <ll>
                  4.3) Nel caso in cui l'interruzione del Servizio sia pianificabile con tale
                  anticipo e l'interruzione prevista ecceda le 4 (quattro) ore, Blindata darà un
                  preavviso di almeno 24 (ventiquattro) ore, salvo che l’interruzione sia collocata
                  nell'orario notturno o festivo e sia dovuto ad esigenze di manutenzione.
                </ll>
                <ll>
                  4.4) In caso di perdita o furto delle Credenziali, Blindata si riserva la facoltà
                  di interrompere il Servizio. Inoltre, il Cliente dovrà immediatamente darne
                  comunicazione a Blindata a mezzo raccomandata con ricevuta di ritorno o a mezzo di
                  posta elettronica certificata, anticipata tramite e-mail, allegando la denuncia
                  effettuata all'autorità competente.
                </ll>
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>5 Livello di servizio</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                5.1) Blindata e il Cliente si danno reciprocamente atto che l’accesso al Servizio
                sarà erogato Blindata con la garanzia di un livello di disponibilità dello stesso
                pari al 90% (novanta percento) del tempo, 24 ore al giorno 7 giorni su 7, per tutto
                l’anno (<b>“Standard di Servizi”</b> o <b>“SLA”</b>). In caso di impossibilità di
                accedere al Servizio inferiore allo SLA, che sia direttamente imputabile a Blindata,
                la stessa risponderà nei limiti dal presente articolo 6, restando espressamente
                escluso, ora per allora, qualsiasi altro indennizzo o risarcimento al Cliente per
                danni diretti o indiretti di qualsiasi natura e specie. L’osservanza dello SLA verrà
                calcolata su base annuale. Sono espressamente escluse le interruzioni del Servizio o
                la indisponibilità dovuta ad interventi tecnici o di manutenzione programmata di
                parte di Blindata, o suoi aventi causa.
              </ll>
              <br></br>
              <br></br>
              <ll>
                5.2) In caso di guasti o malfunzionamenti, il Cliente si impegna a fornire tutte le
                specifiche e le informazioni eventualmente richieste da Blindata, tramite il
                servizio di assistenza di cui all’indirizzo di posta assistenza@blindata.io.
                Eventuali irregolarità o disfunzioni del Servizio dovranno essere comunicate a
                Blindata entro 24 (ventiquattro) ore dalla data in cui sono venute a conoscenza del
                Cliente, a pena di decadenza. Blindata, ricevuta la comunicazione, si attiverà nel
                più breve tempo possibile per prendere in carico la segnalazione del Cliente,
                considerando che gli interventi potranno essere effettuati dal lunedì al venerdì,
                dalle ore 9.00 alle 18.00, festivi esclusi. Eventuali danni causati da una
                comunicazione non tempestiva del Cliente non saranno imputabili a Blindata. In
                nessun caso qualsiasi contestazione e/o eccezione darà facoltà al Cliente di
                sospendere, ritardare o non effettuare il pagamento del Corrispettivo.
              </ll>
              <br></br>
              <br></br>
              <ll>
                5.3) Blindata non rilascia dichiarazioni e garanzie espresse o implicite in
                relazione alla soddisfazione delle esigenze del Cliente da parte del Servizio o
                all’assenza di errori dello stesso.
              </ll>
              <br></br>
              <br></br>
              <ll>
                5.4) Il Cliente accetta e prende atto che, in nessun caso, Blindata potrà essere
                ritenuta responsabile per i danni, di qualsiasi genere, che il Cliente stesso possa
                subire da ritardi, sospensioni, interruzioni, difetti o malfunzionamenti della
                Piattaforma e/o del Servizio, siano essi derivanti da qualsiasi causa non imputabile
                o riconducibile a Blindata, comprese in via interamente esemplificativa le seguenti:
                <ll>
                  <br></br>
                  <br></br>
                  <ul>
                    <ll>
                      - Malfunzionamenti della Piattaforma e/o del Servizio causati, in generale,
                      da: (i) comportamenti negligenti, colposi o dolosi del Cliente; (ii) guasti
                      e/o malfunzionamenti delle apparecchiature, impianti e/o dei sistemi, di
                      qualsiasi tipo, in uso presso la sede del Cliente;
                    </ll>
                    <br></br>
                    <br></br>
                    <ll>
                      - Sospensione e/o interruzione della connettività internet da parte del
                      gestore;
                    </ll>
                    <br></br>
                    <br></br>
                    <ll>
                      - Guasti alla rete e/o ai server dovuti a casi fortuiti o forza maggiore;
                    </ll>
                    <br></br>
                    <br></br>
                    <ll>
                      - Indisponibilità del Servizio e/o della Piattaforma per comprovati eventi
                      sistematici non controllabili da Blindata.
                    </ll>
                    <br></br>
                    <br></br>
                  </ul>
                </ll>
              </ll>

              <ll>
                5.5) Eventuali reclami circa la fornitura del Servizio dovranno essere indirizzati
                dal Cliente a Blindata all’indirizzo email di cui all’art. 5.2 che precede. Blindata
                esaminerà il reclamo e fornirà risposta scritta entro 10 (dieci) giorni dal
                ricevimento del medesimo, salvo diverso SLA convenuto.
              </ll>
              <br></br>
              <br></br>
              <ll>
                5.6) A partire dall’attivazione del Servizio, il Cliente è ritenuto unico ed
                esclusivo responsabile per l’utilizzo del Servizio, e di conseguenza per tutti i
                dati e contenuti immessi, presenti, transitati e/o conservati sulla Piattaforma. Il
                Cliente si obbliga a manlevare e tenere indenne Blindata da qualsiasi costo, onere
                spesa o danno che allo stesso possa essere cagionato a seguito di azioni di terzi,
                ivi incluse pubbliche autorità, conseguenti a violazione degli impegni di cui al
                presente articolo.
              </ll>
              <br></br>
              <br></br>
              <ll>
                5.7) In caso di danno derivante da fatto o colpa di Blindata, la responsabilità di
                quest’ultima non potrà mai eccedere l’ammontare massimo pari al Corrispettivo
                versato negli ultimi 3 (tre) mesi dal Cliente.
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>6 Proprietà intellettuale</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                6.1) Tutti i diritti sui marchi, loghi, nomi, e altri segni distintivi comunque
                associati al Servizio, e la Piattaforma, inclusi i codici sorgente, eventuali
                sviluppi e migliorie apportati da Blindata, la relativa documentazione, e qualsiasi
                materiale messo a disposizione del Cliente tramite il Servizio nonché tutti i
                diritti di utilizzazione economica sugli stessi, sono di titolarità esclusiva di
                Blindata.
              </ll>
              <br></br>
              <br></br>
              <ll>
                6.2) Il presente Contratto non concede al Cliente alcun diritto relativamente ai
                marchi di Blindata e viceversa.
              </ll>
              <br></br>
              <br></br>
              <ll>
                6.3) La Piattaforma costituisce il risultato, l’insieme delle conoscenze tecniche e
                dell’esperienza necessarie alla realizzazione, al funzionamento, al settaggio,
                all'implementazione, allo sviluppo ed alla manutenzione del Servizio (
                <b>“Know How”</b>). Pertanto, il Cliente si impegna espressamente a non divulgare e
                diffondere presso terzi le informazioni tecniche ricevute in esecuzione del presente
                Contratto e relative al Know How come sopra definito, le quali sono destinate a
                rimanere segrete anche ai sensi dell’art. 98 del D.lgs. n. 30 del 2005 (Codice della
                proprietà industriale ed intellettuale).
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>7 Proprietà intellettuale</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                7.1) Indipendentemente dalla data di sottoscrizione dell’Ordine, il presente
                Contratto entrerà in vigore dalla data di attivazione del Servizio per un periodo
                pari alla durata dell’abbonamento sottoscritto, come indicato all’art. 5
                dell’Ordine.
              </ll>
              <br></br>
              <br></br>
              <ll>
                7.2) Alla scadenza, il Contratto si rinnoverà automaticamente, salvo disdetta
                inviata per iscritto 60 giorni prima della relativa scadenza conformemente
                all’articolo 13 che segue.
              </ll>
              <br></br>
              <br></br>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>8 Risoluzione</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                8.1) Qualora una Parte non adempia alle obbligazioni di cui al presente Contratto,
                l’altra Parte avrà facoltà di contestare l'avvenuto inadempimento, per iscritto e a
                mezzo lettera raccomandata a.r. o PEC, invitando la Parte inadempiente a porvi
                rimedio entro un termine non inferiore a 15 (quindici) giorni. Decorso inutilmente
                detto termine la Parte interessata potrà risolvere il presente Contratto, inviando
                una comunicazione scritta alla Parte inadempiente a mezzo raccomandata a.r. o PEC
              </ll>
              <br></br>
              <br></br>
              <ll>
                8.2) Blindata potrà risolvere di diritto, ex art. 1456 cod. civ. il presente
                Contratto nei casi di seguito indicati:
                <ll>
                  <br></br>
                  <br></br>
                  <ul>
                    <ll>- Art. 3: Uso del Servizio e della Piattaforma;</ll>
                    <br></br>
                    <br></br>
                    <ll>- Art. 6: Proprietà intellettuale;</ll>
                    <br></br>
                    <br></br>
                    <ll>- Art. 9: Riservatezza;</ll>
                    <br></br>
                    <br></br>
                    <ll>- Art. 10: Trattamento dei Dati Personali</ll>
                    <br></br>
                    <br></br>
                    <ll>- Art. 11: Cessione del Contratto e subappalto</ll>
                  </ul>
                </ll>
              </ll>
              <br></br>
              <br></br>
              <ll>
                8.3) È fatto salvo, in caso di risoluzione del Contratto, il risarcimento dei danni.
              </ll>
              <br></br>
              <br></br>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>9 Riservatezza</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                9.1) Ciascuna Parte si impegna a mantenere e a far mantenere ai propri dipendenti,
                consulenti, collaboratori ed amministratori la più assoluta riservatezza in
                relazione all’esistenza ed al contenuto del presente Contratto e a tutte le
                informazioni sensibili di natura commerciale e tecnica concernenti l’attività svolta
                dall’altra Parte e di cui la stessa sia venuta a conoscenza. Ciascuna Parte si
                impegna, inoltre, a non comunicare tali informazioni a terzi e ad utilizzare tali
                informazioni esclusivamente al fine di adempiere ai rispettivi obblighi derivanti
                dal presente Contratto. Tale obbligo di riservatezza sarà valido ed efficace per i 3
                (tre) anni successivi all’estinzione, perdita di efficacia, declaratoria di
                inefficacia o invalidità del presente Contratto, a qualsiasi titolo intervenute.
              </ll>
              <br></br>
              <br></br>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>10 Trattamento dei dati personali</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                10.1) Ai sensi e per gli effetti del D.Lgs. n. 196/2003 “Codice in materia di
                protezione dei dati personali”, come modificato dal D.Lgs n. 101/2018, e del
                Regolamento EU 2016/679 del 27 aprile 2016 (<b>“GDPR”</b>), le Parti sono tenute
                all’assolvimento di tutti gli obblighi previsti dalla normativa vigente, ivi
                compresi gli adempimenti previsti in materia di misure di sicurezza di cui al GDPR.
              </ll>
              <br></br>
              <br></br>
              <ll>
                10.2) Blindata è tenuta a conservare e mantenere riservati i dati personali che
                riceverà dal Cliente i quali potranno essere trattati esclusivamente per le finalità
                correlate all’adempimento del presente Contratto.
              </ll>
              <br></br>
              <br></br>
              <ll>
                10.3) Il Cliente è titolare del trattamento dei dati personali degli utenti,
                determinando le finalità e i mezzi del trattamento stesso.
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>11 Cessione del contratto e subappalto</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                11.1) Il Cliente non potrà cedere o trasferire a terzi il presente Contratto, nonché
                i diritti, le facoltà e gli obblighi dal medesimo scaturenti, sotto pena della sua
                risoluzione, e del risarcimento di eventuali danni a favore di Blindata, salvo il
                preventivo consenso scritto di quest’ultima.
              </ll>
              <br></br>
              <br></br>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>12 Norme generali</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                12.1) Con la sottoscrizione del Contratto le Parti non intendono costituire alcuna
                associazione o joint venture, né intendono instaurare un rapporto di agenzia.
              </ll>
              <br></br>
              <br></br>
              <ll>
                12.2) Qualsiasi ritardo nell’esercizio di un diritto non costituirà una rinuncia a
                tale diritto.
              </ll>
              <br></br>
              <br></br>
              <ll>
                12.3) Qualsiasi modifica al Contratto sarà valida e vincolante solo se risultante da
                atto scritto e sottoscritto da un rappresentante autorizzato di ciascuna Parte.
              </ll>
              <br></br>
              <br></br>
              <ll>
                12.4) Ciascuna Parte si impegna a non fare, o autorizzare, dichiarazioni o
                comunicazioni pubbliche o private in relazione alle condizioni economiche ed alle
                condizioni particolari contenute nell’Ordine senza il preventivo consenso scritto
                della Parte interessata, salvo che tale dichiarazione o comunicazione si sia resa
                necessaria a seguito della richiesta di una Autorità.
              </ll>
              <br></br>
              <br></br>
              <ll>
                12.5) L’eventuale invalidità o inefficacia di una o più previsioni del Contratto non
                comporterà l'invalidità o inefficacia delle rimanenti disposizioni del Contratto.
              </ll>
              <br></br>
              <br></br>
              <ll>
                12.6) Resta inteso che qualsiasi difformità tra quanto contenuto nel Contratto e
                nell’Ordine sarà risolta dando la prevalenza alle disposizioni dell’Ordine.
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>13 Comunicazioni</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                13.1) Tutte le comunicazioni tra le Parti inerenti il Contratto dovranno avvenire
                per iscritto, a mezzo PEC o lettera raccomandata a/r agli indirizzi indicati
                nell’Ordine.
              </ll>
              <br></br>
              <br></br>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>14 Legge applicabile, controversie e foro competente</h2>
          <p style={{ color: 'White' }}>
            <ul>
              <ll>
                14.1) Il presente Contratto è regolato, in via esclusiva, dalla legge italiana.
              </ll>
              <br></br>
              <br></br>
              <ll>
                14.2) Qualunque controversia inerente all’interpretazione, alla conclusione,
                all’esecuzione, all’attuazione, all’estinzione del presente Contratto, è di
                competenza esclusiva del Foro di Milano.
              </ll>
              <br></br>
              <br></br>
            </ul>
          </p>
        </form>
      </Card>
    </>
  );
}

export default TermsAndConditionsPage;
