import React, { useEffect } from 'react';
import _ from 'lodash';
import { fetchDataFlowsPage } from '../../../actions/DataFlowsActions';
import { Typography, Table, TableHead, TableBody, TableRow, TablePagination } from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { t } from 'i18next';

export const DataFlowCardTable = ({ filters }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentPage = useSelector((state) => state.dataflows.currentPage);

  useEffect(() => {
    if (filters) {
      dispatch(fetchDataFlowsPage(0, 25, filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters]);

  return (
    <>
      {currentPage?.content?.length > 0 ? (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>{t('dataflows.fields.scope')}</TableCell>
              <TableCell>{t('dataflows.fields.name')}</TableCell>
              <TableCell>{t('dataflows.fields.from')}</TableCell>
              <TableCell>{t('dataflows.fields.to')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPage.content.map((df) => (
              <TableRow
                key={df.uuid}
                hover
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/dataflows/detail/${df.uuid}`)}>
                <TableCell>{df.scope || t('commons.placeholders.undefined')}</TableCell>
                <TableCell>{df.name || t('commons.placeholders.undefined')}</TableCell>
                <TableCell>
                  {[
                    _.get(df, 'fromSystem.name'),
                    _.get(df, 'fromPhysicalEntity.name'),
                    _.get(df, 'fromPhysicalField.name')
                  ]
                    .filter((elem) => elem)
                    .join(',\n')}
                </TableCell>
                <TableCell>
                  {[
                    _.get(df, 'toSystem.name'),
                    _.get(df, 'toPhysicalEntity.name'),
                    _.get(df, 'toPhysicalField.name')
                  ]
                    .filter((elem) => elem)
                    .join(',\n')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TablePagination
            size='small'
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={currentPage.totalElements}
            rowsPerPage={currentPage.size}
            page={currentPage.number}
            onPageChange={(_event, page) =>
              dispatch(fetchDataFlowsPage(page, currentPage.size, filters))
            }
            onRowsPerPageChange={(event) =>
              dispatch(fetchDataFlowsPage(currentPage.number, event.target.value, filters))
            }
          />
        </Table>
      ) : (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='body1'>{t('dataflows.noDataFlowsFound')}</Typography>
        </div>
      )}
    </>
  );
};
