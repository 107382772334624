import React from 'react';
import TemplatesEventsLists from './TemplatesEventsList';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
export default function TemplatesEventsListPage() {
  return (
    <>
      <Breadcrumbs elements={[{ text: 'Templates', to: '/templates' }, { text: 'Events' }]} />
      <Tracking
        info={{
          pageTitle: `Events`,
          category: EngagementCategories.TEMPLATES,
          action: EngagementActions.LIST
        }}
      />
      <TemplatesEventsLists />
    </>
  );
}
