import React from 'react';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { downloadRpa } from '../../../actions/RpaUtilsActions';
import { ButtonGroup, Grid } from '@mui/material';
import RpaTemplatesMenu from './RpaTemplateMenu';
import { RpaArchiveButton } from './RpaArchiveButton';

function DownloadRpaButtonGroup(props) {
  const [rpaTemplate, setRpaTemplate] = React.useState(null);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  return (
    <Grid container alignItems='center'>
      <Grid item>
        <Typography variant='caption' style={{ display: 'inline' }}>
          ROPA:
        </Typography>
      </Grid>
      <Grid item>
        <ButtonGroup size='small' ref={anchorRef} aria-label='small outlined button group'>
          <Tooltip title='Download pdf'>
            <IconButton
              variant='contained'
              color='primary'
              onClick={() =>
                props.downloadRpa({
                  ...props.filters,
                  type: 'PDF',
                  templateUuid: rpaTemplate && rpaTemplate.uuid
                })
              }
              size='large'>
              <PictureAsPdf />
            </IconButton>
          </Tooltip>
          <Tooltip title='Download doc'>
            <IconButton
              variant='contained'
              color='primary'
              onClick={() =>
                props.downloadRpa({
                  ...props.filters,
                  type: 'DOC',
                  templateUuid: rpaTemplate && rpaTemplate.uuid
                })
              }
              size='large'>
              <LibraryBooks />
            </IconButton>
          </Tooltip>
          <IconButton
            color={rpaTemplate ? 'primary' : 'grey'}
            onClick={() => setSettingsOpen(true)}
            size='large'>
            <ArrowDropDownIcon />
          </IconButton>
          <RpaTemplatesMenu
            anchorRef={anchorRef}
            open={settingsOpen}
            onSelect={setRpaTemplate}
            selectedRpaTemplate={rpaTemplate}
            onClose={() => setSettingsOpen(false)}></RpaTemplatesMenu>
        </ButtonGroup>
      </Grid>
      <Grid item>
        <RpaArchiveButton />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  filters: state.processings.filters
});

export default connect(mapStateToProps, {
  downloadRpa
})(DownloadRpaButtonGroup);
