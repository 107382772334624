import React from 'react';
import { TextField } from '@mui/material';

function ConfigurationGeneralModalSection({ editor, setEditor }) {
  return (
    <>
      <h4>General</h4>
      <TextField
        variant='standard'
        required
        fullWidth
        margin='dense'
        label='Name'
        placeholder='Enter the configuration name'
        value={editor?.name}
        onChange={(event) => setEditor({ name: event.target.value })}
      />
      <TextField
        variant='standard'
        fullWidth
        margin='dense'
        label='Description'
        multiline
        placeholder='Enter the configuration description'
        value={editor?.description}
        onChange={(event) => setEditor({ description: event.target.value })}
      />
    </>
  );
}

export default ConfigurationGeneralModalSection;
