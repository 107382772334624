import React from 'react';
import _ from 'lodash';
import { Card, CardContent, CardHeader } from '@mui/material';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
import {
  fetchUserTenants,
  addUserToTenant,
  removeUserFromTenant
} from '../../../../actions/UsersActions';
import { withLoader } from '../../../../components/loader/Loader';
import { connect } from 'react-redux';
import { withOpenCloseHandler } from '../../../../components/openclosehandler/OpenCloseHandler';
import TenantSelectModal from '../../tenants/commons/TenantSelectModal';

const UserAssociatedTenantsCard = ({
  userUuid,
  associatedTenants,
  addUserToTenant,
  removeUserFromTenant,
  onToggleOpen,
  isOpen
}) => {
  if (!associatedTenants) return null;
  return (
    <Card>
      <CardHeader title='Associated Tenants'></CardHeader>
      <CardContent>
        <ChipWrapper onRequestAdd={() => onToggleOpen()}>
          {_.sortBy(associatedTenants, 'name').map((t) => (
            <ChipItem
              key={t.uuid}
              label={t.name}
              onRequestDelete={() => removeUserFromTenant(userUuid, t)}
            />
          ))}
        </ChipWrapper>
        {isOpen && (
          <TenantSelectModal
            open={isOpen}
            handleCancel={() => onToggleOpen()}
            handleSubmit={(t) => {
              addUserToTenant(userUuid, t);
              onToggleOpen();
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state, { userUuid }) => ({
  associatedTenants: state.settings.users.associatedTenants[userUuid]
});

const mapDispatchToProps = {
  fetchUserTenants,
  addUserToTenant,
  removeUserFromTenant
};

export default _.flow([
  withLoader({
    onLoad: ({ userUuid, fetchUserTenants }) => fetchUserTenants(userUuid, { size: 1000 })
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withOpenCloseHandler
])(UserAssociatedTenantsCard);
