import { BlindataApi } from './ApiUtils';

export default class SecurityMeasureApi extends BlindataApi {
  getSecurityMeasure(params) {
    return this.get(`/api/v1/processings/*/securityMeasures`, params);
  }

  getTransferExtraEuOrganizations(params) {
    return this.get(`/api/v1/processings/*/transferExtraEuOrganizations/*/safeguardTypes`, params);
  }
}
