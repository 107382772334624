import { Grid, Tab, Tabs, Typography } from '@mui/material';
import { fetchWebHook } from 'actions/settings/webhooks/WebHooksActions';
import Tracking from 'components/engagement/Tracking';
import EngagementActions from 'constants/EngagementActions';
import EngagementCategories from 'constants/EngagementCategories';
import { t } from 'i18next';
import Breadcrumbs from 'pages/sidebar/Navigation';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSearchParams from 'components/useSearchParams';
import { useHistory } from 'react-router';
import { WebHookDetailSection } from './WebHookDetailSection';
import WebHookMatchRulesPanel from './WebHookMatchRulesPanel';

const WebHookDetailPage = ({ ...props }) => {
  const webHookUuid = props.match.params['webHookUuid'];
  const webHook = useSelector((state) => state.settings.webHooks.content[webHookUuid]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWebHook(webHookUuid));
  }, [webHookUuid, dispatch]);

  const query = useSearchParams();
  const history = useHistory();
  const selectedTab = query.get('tab') || 'matchRules';
  const handleChangeTab = (_event, newValue) =>
    history.push(`${history.location.pathname}?tab=${newValue}`, { keepScroll: true });

  if (!webHook) {
    return null;
  }

  return (
    <>
      <HeaderSection webHook={webHook} />
      <WebHookDetailSection webHook={webHook} />
      <Tabs value={selectedTab} onChange={handleChangeTab} centered>
        <Tab value='matchRules' label='Match Rules' />
      </Tabs>

      {selectedTab === 'matchRules' && <WebHookMatchRulesPanel webHook={webHook} />}
    </>
  );
};

const HeaderSection = ({ webHook }) => {
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('navbar.settings'), to: '/settings' },
          { text: t('navbar.webHooks'), to: '/settings/web-hooks' },
          { text: webHook?.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('webHooks.detail.header', {
            name: webHook?.name
          }),
          category: EngagementCategories.SETTINGS,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container alignItems='center'>
        <Typography variant='h4' flexGrow={1}>
          {t('webHooks.detail.header', {
            name: webHook.name
          })}
        </Typography>
        <FavoritesWidget
          itemIdentifier={`WEB_HOOK:${webHook?.uuid}`}
          pageTitle={`Web Hook: ${webHook?.name}`}
        />
      </Grid>
    </>
  );
};

export default WebHookDetailPage;
