import React from 'react';
import { useRouteMatch, useLocation } from 'react-router';
import { clearIssuesFilters } from 'actions/IssuesActions';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ToolbarActions from 'pages/sidebar/ToolbarActions';
import { connect } from 'react-redux';

const IssueMngtNavabar = ({ clearIssuesFilters }) => {
  let { url } = useRouteMatch();
  let { pathname } = useLocation();

  const myWorkUrl = `${url}/my-work`;
  const issuesUrl = `${url}/issues`;
  const campaignsUrl = `${url}/campaigns`;

  return (
    <ToolbarActions>
      <Button
        color={pathname === myWorkUrl ? 'primary' : 'grey'}
        children='My Work'
        component={Link}
        to={myWorkUrl}
        onClick={() => clearIssuesFilters()}
      />
      <Button
        color={pathname === issuesUrl ? 'primary' : 'grey'}
        children='Issues'
        component={Link}
        to={issuesUrl}
        onClick={() => clearIssuesFilters()}
      />
      <Button
        color={pathname === campaignsUrl ? 'primary' : 'grey'}
        children='Campaigns'
        component={Link}
        to={campaignsUrl}
        onClick={() => clearIssuesFilters()}
      />
    </ToolbarActions>
  );
};

export default connect(null, { clearIssuesFilters })(IssueMngtNavabar);
