import QualityDashboardsApi from '../api/QualityDashboardsApi';
import { buildApiCallAction } from './ActionUtils';
import {
  FETCH_QUALITY_CHECKS_SEMAPHORES_PIE_CHART,
  FETCH_QUALITY_CHECKS_TIMELINE_BY_TEST_SUITE,
  SET_QUALITY_CHECKS_TIMELINE_BY_TEST_SUITE_FILTERS,
  FETCH_QUALITY_RESULTS_AREA_CHART,
  FETCH_QUALITY_CHECKS_TIMELINE_BY_CHECK,
  FETCH_QUALITY_DASHBOARDS_OVERALL,
  FETCH_QUALITY_CHECKS_TIMELINE_OVERALL,
  FETCH_QUALITY_CHECKS_STATS,
  SET_QUALITY_CHECKS_TIMELINE_BY_CHECK_FILTERS,
  SET_QUALITY_DASHBOARDS_FILTERS,
  CLEAR_QUALITY_DASHBOARDS_FILTERS,
  FETCH_HOME_QUALITY_CHECKS_SEMAPHORES_PIE_CHART,
  FETCH_HOME_QUALITY_CHECKS_SEMAPHORES_DRILLDOWN,
  FETCH_QUALITY_CHECKS_SEMAPHORES_DRILLDOWN
} from '../constants/ActionTypes';

let api = new QualityDashboardsApi();

export const fetchQualityChecksSemaphoresPieChart = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => {
      return api.getChecksLastRunSemaphores(params);
    },
    actionType: FETCH_QUALITY_CHECKS_SEMAPHORES_PIE_CHART,
    payload: (data) => ({ ...data, qualitySuitesUuids: params.qualitySuitesUuids })
  });

export const fetchHomeQualityChecksSemaphoresPieChart = (params = {}) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => {
      return api.getChecksLastRunSemaphores({ ...params, inWatchlist: true });
    },
    actionType: FETCH_HOME_QUALITY_CHECKS_SEMAPHORES_PIE_CHART
  });

export const fetchQualityChecksSemaphoresPieChartDrilldown = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => {
      return api.getChecksDrilldown(params);
    },
    actionType: FETCH_QUALITY_CHECKS_SEMAPHORES_DRILLDOWN
  });

export const fetchHomeQualityChecksSemaphoresPieChartDrilldown = (params = {}) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => {
      return api.getChecksDrilldown({ ...params, inWatchlist: true });
    },
    actionType: FETCH_HOME_QUALITY_CHECKS_SEMAPHORES_DRILLDOWN
  });

export const fetchQualityChecksTimelineByTestSuite = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => {
      return api.getSemaphoresOverTime(params);
    },
    actionType: FETCH_QUALITY_CHECKS_TIMELINE_BY_TEST_SUITE
  });

export const setQualityChecksTimelineByTestSuiteFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_QUALITY_CHECKS_TIMELINE_BY_TEST_SUITE_FILTERS, payload: filters });
};

export const fetchQualityResultsAreaChart = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => {
      return api.getResultsAreaChart(params);
    },
    actionType: FETCH_QUALITY_RESULTS_AREA_CHART
  });

export const fetchQualityChecksTimelineByCheck = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => {
      return api.getSemaphoresOverTime(params);
    },
    actionType: FETCH_QUALITY_CHECKS_TIMELINE_BY_CHECK
  });

export const fetchQualityDashboardOverall = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getOverall(params),
    actionType: FETCH_QUALITY_DASHBOARDS_OVERALL
  });

export const fetchQualityChecksTimelineOverall = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getSemaphoresOverTime(params),
    actionType: FETCH_QUALITY_CHECKS_TIMELINE_OVERALL
  });

export const fetchQualityChecksStats = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => {
      return api.getQualityChecksStats(params);
    },
    actionType: FETCH_QUALITY_CHECKS_STATS
  });

export const setQualityChecksTimelineByCheckFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_QUALITY_CHECKS_TIMELINE_BY_CHECK_FILTERS, payload: filters });
};

export const setQualityDashboardsOverallFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_QUALITY_DASHBOARDS_FILTERS, payload: filters });
};
export const clearQualityDashboardsOverallFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_QUALITY_DASHBOARDS_FILTERS });
};
