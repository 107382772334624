import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';

import { createAgentConnection } from 'actions/onagent/AgentConnectionsActions';

import AddFAB from 'components/fab/AddFAB';
import AgentConnectionModal from 'pages/settings/agents/commons/AgentConnectionModal';

const AgentConnectionFAB = ({ agent, createAgentConnection }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AddFAB onClick={() => setOpen(true)} />
      {open && (
        <AgentConnectionModal
          title='Create connection'
          open={open}
          onSubmit={(connection) => {
            createAgentConnection(agent, connection);
            setOpen(false);
          }}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default compose(
  withPermissions({ hasPermissions: [Permissions.AGENTS_ADMIN] }),
  connect(null, { createAgentConnection })
)(AgentConnectionFAB);
