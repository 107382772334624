import React from 'react';
import { MenuItem, Typography, Divider, ListSubheader, ListItemIcon } from '@mui/material';
import _ from 'lodash';
import { OpenInNew, OpenInBrowser } from '@mui/icons-material';
import { createUrlItem } from 'pages/Routes';

function ContextualMenuNodeContent({ node, nodeDetail, history }) {
  return (
    <>
      <ListSubheader variant='caption'>{_.get(node, 'data.name')}</ListSubheader>
      <Divider />
      {createDescription(nodeDetail)}
      {createGraphAlertList(nodeDetail)}
      {createDetailLink(node, history)}
      {createExternalLinkItem(nodeDetail)}
    </>
  );
}
function createDetailLink(node, history) {
  if (!node) return null;
  let url = createUrlItem(node.labels[0], node.data.uuid);
  if (!url) return null;
  return (
    <MenuItem dense onClick={() => history.push(url)}>
      <ListItemIcon>
        <OpenInBrowser />
      </ListItemIcon>
      Detail
    </MenuItem>
  );
}

function createExternalLinkItem(nodeDetail) {
  if (!nodeDetail || !nodeDetail.data) return null;
  let externalUrlProperty = _.find(
    nodeDetail.data.additionalProperties || [],
    ({ name }) => name === 'external_url'
  );
  if (!externalUrlProperty) return null;
  let url = externalUrlProperty.value;
  return (
    <MenuItem dense onClick={() => window.open(url, '_blank')}>
      <ListItemIcon>
        <OpenInNew />
      </ListItemIcon>
      External Url
    </MenuItem>
  );
}

function createDescription(nodeDetail) {
  let description = _.get(nodeDetail, 'data.description');
  if (description) {
    return (
      <>
        <MenuItem dense button={false}>
          <Typography
            variant='body2'
            component='div'
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              hyphens: 'auto'
            }}>
            {description}
          </Typography>
        </MenuItem>
        <Divider />
      </>
    );
  }
}

function createGraphAlertList(nodeDetail) {
  if (
    nodeDetail &&
    nodeDetail.data &&
    nodeDetail.data.additionalProperties &&
    _.find(nodeDetail.data.additionalProperties, { name: 'graph_alert' })
  ) {
    return (
      <>
        <MenuItem dense button={false} style={{ display: 'block' }}>
          <ul>
            {nodeDetail.data.additionalProperties
              .filter((ap) => ap.name === 'graph_alert')
              .map((ap) => (
                <li>
                  <Typography variant='body2'>{ap.value}</Typography>
                </li>
              ))}
          </ul>
        </MenuItem>
        <Divider />
      </>
    );
  } else {
    return null;
  }
}

export default ContextualMenuNodeContent;
