/* eslint-disable import/no-anonymous-default-export */
export default {
  DATA_CATEGORY: 'DATA_CATEGORY',
  PROCESSING: 'PROCESSING',
  TASK: 'TASK',
  DATA_ACTOR: 'DATA_ACTOR',
  TASK_ASSIGNMENT: 'TASK_ASSIGNMENT',
  LOGICAL_FIELD: 'LOGICAL_FIELD',
  PHYSICAL_FIELD: 'PHYSICAL_FIELD',
  SYSTEM: 'SYSTEM',
  PHYSICAL_ENTITY: 'PHYSICAL_ENTITY',
  CONTRACT: 'CONTRACT',
  SYSTEM_ROUTINE:'SYSTEM_ROUTINE'
};
