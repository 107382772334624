import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  fetchQualityChecksTimelineByTestSuite,
  setQualityChecksTimelineByTestSuiteFilters
} from 'actions/QualityDashboardsActions';

import QualityChecksTimelineCard from 'pages/quality/kqis/quality_checks/commons/QualityChecksTimelineCard';

const styles = {
  card: {
    height: 'calc(100% - 16px)',
    display: 'flex',
    flexDirection: 'column'
  }
};

const QualitySuiteChecksTimelineChart = ({
  qualitySuite,
  data,
  filters,
  fetchQualityChecksTimelineByTestSuite,
  setQualityChecksTimelineByTestSuiteFilters,
  inWatchlist
}) => {
  useEffect(() => {
    fetchQualityChecksTimelineByTestSuite({
      qualitySuitesUuids: [qualitySuite?.uuid],
      inWatchlist: inWatchlist,
      ...filters
    });
  }, [qualitySuite, filters, fetchQualityChecksTimelineByTestSuite, inWatchlist]);

  return (
    <QualityChecksTimelineCard
      inWatchlist={inWatchlist}
      qualitySuiteUuid={qualitySuite?.uuid}
      data={data}
      filters={filters}
      setQualityChecksTimelineFilters={setQualityChecksTimelineByTestSuiteFilters}
      sx={styles.card}
      chartWidth='675px'
      chartHeight='300px'
    />
  );
};

const mapStateToProps = (state, props) => ({
  data: state.quality.qualityDashboards.qualityChecksTimelineByTestSuite.data,
  filters: state.quality.qualityDashboards.qualityChecksTimelineByTestSuite.filters
});

const mapDispatchToProps = {
  fetchQualityChecksTimelineByTestSuite,
  setQualityChecksTimelineByTestSuiteFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(QualitySuiteChecksTimelineChart);
