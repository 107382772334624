import React from 'react';
import { Switch, Route } from 'react-router';
import StewardshipRolesListPage from './list/StewardshipRolesListPage';
import StewardshipRoleCard from './detail/StewardshipRoleDetailPage';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  return (
    <Switch>
      <Route path={`${props.match.url}/:uuid`} component={StewardshipRoleCard}></Route>

      <Route path={`${props.match.url}`} component={StewardshipRolesListPage}></Route>
    </Switch>
  );
}
