import React from 'react';
import { useTranslation } from 'react-i18next';

import Permissions from 'constants/Permissions';

import { Button } from '@mui/material';

import ExpandableCard from 'components/expandable-card/ExpandableCard';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import Authorize from 'components/permissions/Authorize';
import { Link } from 'react-router-dom';

const AgentsListItem = ({ agent, handleEdit, handleDelete, handlePairing }) => {
  const { t } = useTranslation();

  return (
    <ExpandableCard
      title={agent.name}
      subheader={agent.baseUrl}
      decorator={<VariationTypeDecorator>{agent.pairingStatus}</VariationTypeDecorator>}
      actions={
        <>
          <Authorize hasPermissions={[Permissions.AGENTS_VIEWER]}>
            <Button key='link' component={Link} to={`/settings/agents/${agent.uuid}`}>
              {t('commons.actions.detail')}
            </Button>
          </Authorize>
          <Authorize hasPermissions={[Permissions.AGENTS_ADMIN]}>
            <Button onClick={handleEdit}>{t('commons.actions.modify')}</Button>
          </Authorize>
          <Authorize hasPermissions={[Permissions.AGENTS_ADMIN]}>
            <Button onClick={handleDelete}>{t('commons.actions.delete')}</Button>
          </Authorize>
          <Authorize hasPermissions={[Permissions.AGENTS_ADMIN]}>
            <Button onClick={handlePairing}>{t('commons.actions.pair')}</Button>
          </Authorize>
        </>
      }></ExpandableCard>
  );
};

export default AgentsListItem;
