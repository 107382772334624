import { buildApiCallAction } from './ActionUtils';
import QualitySuitesApi from '../api/QualitySuitesApi';
import {
  FETCH_QUALITY_SUITE,
  FETCH_QUALITY_SUITES_PAGE,
  CREATE_QUALITY_SUITE,
  MODIFY_QUALITY_SUITE,
  DELETE_QUALITY_SUITE,
  SEARCH_QUALITY_SUITES,
  EXPORT_QUALITY_SUITES
} from '../constants/ActionTypes';

const api = new QualitySuitesApi();

export const searchQualitySuite = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getQualitySuites(params),
    actionType: SEARCH_QUALITY_SUITES
  });
export const fetchQualitySuite = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getQualitySuite(uuid),
    actionType: FETCH_QUALITY_SUITE
  });

export const fetchQualitySuitesPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getQualitySuites(params),
    actionType: FETCH_QUALITY_SUITES_PAGE
  });

export const createQualitySuite = (qualitySuite) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postQualitySuite(qualitySuite),
    actionType: CREATE_QUALITY_SUITE
  });

export const modifyQualitySuite = (qualitySuite) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putQualitySuite(qualitySuite),
    actionType: MODIFY_QUALITY_SUITE
  });

export const deleteQualitySuite = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteQualitySuite(uuid),
    actionType: DELETE_QUALITY_SUITE
  });

export const exportQualitySuites = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportQualitySuites(params),
    actionType: EXPORT_QUALITY_SUITES
  });
