import React, { useState, useReducer } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@mui/material';
import DatePicker, { hourAdjustments } from 'components/pickers/DatePicker';
import {
  setQueryStatementsFilters,
  clearQueryStatementsFilters
} from 'actions/QueryStatementsActions';
import { connect } from 'react-redux';
import { ChipItem, ChipWrapper } from 'components/chiplist/ChipList';
import PhysicalEntitySchemaChipListEditor from 'pages/dataflows/list/PhysicalEntitySchemaChipListEditor';

function QueryStatementFiltersModal({
  filters,
  setQueryStatementsFilters,
  clearQueryStatementsFilters,
  hasFilters
}) {
  const [open, setOpen] = useState(false);
  let { t } = useTranslation();

  return (
    <React.Fragment>
      <Tooltip title='Filters'>
        <IconButton
          color={hasFilters ? 'primary' : 'grey'}
          size='small'
          onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <QueryStatementFilters
          t={t}
          open={open}
          setOpen={setOpen}
          filters={filters}
          onClear={() => {
            clearQueryStatementsFilters();
            setOpen(false);
          }}
          onApply={(newFilters) => {
            setQueryStatementsFilters(newFilters);
            setOpen(false);
          }}></QueryStatementFilters>
      )}
    </React.Fragment>
  );
}

function QueryStatementFilters({ onApply, onClear, open, filters, t }) {
  const [filtersEditor, setFiltersEditor] = useReducer((s, a) => ({ ...s, ...a }), filters);
  const [addElaborationStatuses, setAddElaborationStatuses] = useState(false);
  const [addAnalysisStatuses, setAddAnalysisStatuses] = useState(false);
  const [addQueryTypes, setAddQueryTypes] = useState(false);
  let {
    elaborationStatuses = [],
    analysisStatuses = [],
    schemas = [],
    queryTypes = [],
    creationDateGt = null,
    creationDateLt = null,
    modificationDateGt = null,
    modificationDateLt = null
  } = filtersEditor;
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Set Filters</DialogTitle>
      <DialogContent>
        <h4>Elaboration Statuses</h4>
        <ChipWrapper onRequestAdd={() => setAddElaborationStatuses(true)}>
          {elaborationStatuses.map((sts) => (
            <ChipItem
              label={sts}
              onRequestDelete={() =>
                setFiltersEditor({
                  elaborationStatuses: elaborationStatuses.filter((a) => a !== sts)
                })
              }></ChipItem>
          ))}
        </ChipWrapper>
        <h4>Analysis Statuses</h4>
        <ChipWrapper onRequestAdd={() => setAddAnalysisStatuses(true)}>
          {analysisStatuses.map((sts) => (
            <ChipItem
              label={sts}
              onRequestDelete={() =>
                setFiltersEditor({
                  analysisStatuses: elaborationStatuses.filter((a) => a !== sts)
                })
              }></ChipItem>
          ))}
        </ChipWrapper>
        <h4>Query Type</h4>
        <ChipWrapper onRequestAdd={() => setAddQueryTypes(true)}>
          {queryTypes.map((sts) => (
            <ChipItem
              label={sts}
              onRequestDelete={() =>
                setFiltersEditor({
                  queryTypes: queryTypes.filter((a) => a !== sts)
                })
              }></ChipItem>
          ))}
        </ChipWrapper>
        <h4>Schema</h4>
        <PhysicalEntitySchemaChipListEditor
          values={schemas}
          onChange={(values) => setFiltersEditor({ schemas: values })}
        />
        <h4 style={{ marginBottom: '0px' }}>Creation Date</h4>
        <DatePicker
          clearable={true}
          label={'Start Date'}
          hintText={'Start Date'}
          hourAdjustment={hourAdjustments.startOfDay}
          value={creationDateGt}
          maxDate={creationDateLt}
          onChange={(date) => setFiltersEditor({ creationDateGt: date })}
        />
        <DatePicker
          clearable={true}
          label={'End Date'}
          hintText={'End Date'}
          hourAdjustment={hourAdjustments.endOfDay}
          value={creationDateLt}
          minDate={creationDateGt}
          onChange={(date) => setFiltersEditor({ creationDateLt: date })}
        />
        <br></br>
        <h4 style={{ marginBottom: '0px' }}>Modification Date</h4>
        <DatePicker
          clearable={true}
          label={'Start Date'}
          hintText={'Start Date'}
          hourAdjustment={hourAdjustments.startOfDay}
          value={modificationDateGt}
          maxDate={modificationDateLt}
          onChange={(date) => setFiltersEditor({ modificationDateGt: date })}
        />
        <DatePicker
          clearable={true}
          label={'End Date'}
          hintText={'End Date'}
          hourAdjustment={hourAdjustments.endOfDay}
          value={modificationDateLt}
          minDate={modificationDateGt}
          onChange={(date) => setFiltersEditor({ modificationDateLt: date })}
        />
      </DialogContent>

      {addElaborationStatuses && (
        <StatementEnumModal
          title={'Add Elaboration Status'}
          enums={['PARTIALLY_ANALYZED', 'ANALYZED', 'PENDING', 'CONFIRMED', 'IGNORED', 'ERROR']}
          onSubmit={(status) => {
            setFiltersEditor({
              elaborationStatuses: elaborationStatuses.filter((s) => s !== status).concat([status])
            });
            setAddElaborationStatuses(false);
          }}
          onCancel={() => setAddElaborationStatuses(false)}
          open={addElaborationStatuses}
        />
      )}

      {addAnalysisStatuses && (
        <StatementEnumModal
          title={'Add Analysis Status'}
          enums={['PARTIALLY_ANALYZED', 'ANALYZED', 'PENDING', 'CONFIRMED', 'IGNORED', 'ERROR']}
          onSubmit={(status) => {
            setFiltersEditor({
              analysisStatuses: analysisStatuses.filter((s) => s !== status).concat([status])
            });
            setAddAnalysisStatuses(false);
          }}
          onCancel={() => setAddAnalysisStatuses(false)}
          open={addAnalysisStatuses}
        />
      )}

      {addQueryTypes && (
        <StatementEnumModal
          title={'Add Query Statement Type'}
          enums={[
            'CREATE_TABLE',
            'CREATE_VIEW',
            'INSERT',
            'SELECT',
            'MERGE',
            'UPDATE',
            'SELECT_INTO'
          ]}
          onSubmit={(type) => {
            setFiltersEditor({
              queryTypes: queryTypes.concat([type])
            });
            setAddQueryTypes(false);
          }}
          onCancel={() => setAddQueryTypes(false)}
          open={addQueryTypes}
        />
      )}

      <DialogActions>
        <Button children={t('commons.actions.clear')} onClick={() => onClear()} />
        <Button
          variant='outlined'
          color='primary'
          children={t('commons.actions.apply')}
          onClick={() => onApply(filtersEditor)}
        />
      </DialogActions>
    </Dialog>
  );

  function StatementEnumModal({ open, enums, title, onCancel, onSubmit }) {
    const [element, setStatus] = useState(null);
    const elements = enums ? enums : [];
    return (
      <React.Fragment>
        <Dialog open={open} fullWidth>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <FormControl variant='standard' fullWidth margin='dense'>
              <InputLabel>Type</InputLabel>
              <Select
                variant='standard'
                value={element || ''}
                onChange={(event) => setStatus(event.target.value)}>
                {elements.map((element) => (
                  <MenuItem key={element} value={element}>
                    {element}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              children={t('commons.actions.cancel')}
              onClick={() => {
                onCancel();
                setStatus(null);
              }}></Button>
            <Button
              children={t('commons.actions.submit')}
              color='primary'
              ee
              onClick={() => {
                onSubmit(element);
                setStatus(null);
                onCancel();
              }}></Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.dataflows.querystatements.filters.content,
  hasFilters: state.dataflows.querystatements.filters.hasFilters
});
export default connect(mapStateToProps, { setQueryStatementsFilters, clearQueryStatementsFilters })(
  QueryStatementFiltersModal
);
