import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import {
  executeAgentWebActionConfiguration,
  fetchAgentWebActionConfigurationsPage
} from 'actions/onagent/webactions/AgentWebActionConfigurationsActions';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t } from 'i18next';
import ItemSelectField from 'components/itemselector/ItemSelectField';
import _ from 'lodash';

export const WebActionConfigurationExecutionParams = ({
  onCancel,
  setSpinner,
  webAction,
  payload,
  configuration,
  setConfiguration,
  setIsNewResult
}) => {
  const [manuallyFilledParams, setManuallyFilledParams] = useState({});

  const dispatch = useDispatch();
  const webActionConfigurations = useSelector(
    (state) => state.settings.agents.webActionConfigurations?.currentPage?.content
  );

  useEffect(() => {
    dispatch(
      fetchAgentWebActionConfigurationsPage(webAction?.agent, { webActionUuid: webAction?.uuid })
    );
  }, [dispatch, webAction]);

  //Automatically set the configuration if only one is present
  useEffect(() => {
    if (webActionConfigurations?.length === 1) {
      setConfiguration(webActionConfigurations[0]);
    }
  }, [setConfiguration, webActionConfigurations]);

  const templateParams = configuration?.templateParams?.params;
  const automaticParams = Object.keys(templateParams || {}).filter(
    (key) => templateParams[key]?.mode === 'AUTOMATIC'
  );
  const manualParams = Object.keys(templateParams || {}).filter(
    (key) => templateParams[key]?.mode === 'MANUAL'
  );

  const automaticallyFilledParams = Object.keys(templateParams || {})
    .filter((key) => templateParams[key]?.mode === 'AUTOMATIC')
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: _.isObject(_.get(payload, `${templateParams[key]?.defaultValue}`))
          ? JSON.stringify(_.get(payload, `${templateParams[key]?.defaultValue}`))
          : _.get(payload, `${templateParams[key]?.defaultValue}`)
      }),
      {}
    );

  const mergedFilledParams = { ...automaticallyFilledParams, ...manuallyFilledParams };

  return (
    <>
      <DialogTitle>{t('webActions.executionModal.params.title')}</DialogTitle>
      <DialogContent>
        <ItemSelectField
          placeholder={'Select a configuration'}
          value={configuration}
          values={webActionConfigurations}
          nameProperty='name'
          keyProperty='uuid'
          itemName='configuration'
          onChange={(newConfig) => setConfiguration(newConfig)}
        />
        {configuration ? (
          <>
            <h4 style={{ marginBottom: '0px' }}>{t('webActions.executionModal.params.url')}</h4>
            <TextField
              disabled
              fullWidth
              variant='standard'
              value={
                configuration
                  ? `${configuration?.requestTemplate?.method} ${configuration?.requestTemplate?.url}`
                  : ''
              }
            />
            <h4 style={{ marginBottom: '0px' }}>{t('webActions.executionModal.params.params')}</h4>
            {templateParams && Object.keys(templateParams).length > 0 ? (
              [
                ...manualParams.map((key) => (
                  <TextField
                    multiline
                    margin='dense'
                    fullWidth
                    key={key}
                    label={key}
                    variant='standard'
                    value={manuallyFilledParams[key] || ''}
                    required={templateParams[key]?.mandatory}
                    onChange={(event) =>
                      setManuallyFilledParams({
                        ...manuallyFilledParams,
                        [key]: event.target.value
                      })
                    }
                  />
                )),
                ...automaticParams.map((key) => (
                  <TextField
                    multiline
                    margin='dense'
                    fullWidth
                    key={key}
                    label={key}
                    variant='standard'
                    value={automaticallyFilledParams[key] || ''}
                    required={templateParams[key]?.mandatory}
                    disabled
                  />
                ))
              ]
            ) : (
              <div style={{ textAlign: 'center', padding: '2%' }}>
                <Typography variant='body1'>
                  {t('webActions.executionModal.params.noParams')}
                </Typography>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>{t('commons.actions.cancel')}</Button>
        <Button
          variant='contained'
          color='primary'
          disabled={
            !configuration ||
            manualParams.reduce(
              (acc, param) =>
                acc || (templateParams[param]?.mandatory && !manuallyFilledParams[param]),
              false
            )
          }
          onClick={() => {
            setSpinner(true);
            dispatch(
              executeAgentWebActionConfiguration(webAction?.agent, configuration.uuid, {
                params: mergedFilledParams
              })
            ).then((res) => {
              setSpinner(false);
              if (res) {
                setIsNewResult(true);
              }
            });
          }}>
          {t('commons.actions.run')}
        </Button>
      </DialogActions>
    </>
  );
};
