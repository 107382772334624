import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import TableCell from '../../../../../components/tables/GenericTableCell';
const headCells = [
  { id: 'code', numeric: false, label: 'Code' },
  { id: 'checkName', numeric: false, label: 'Name' },
  { id: 'lastSemaphore', numeric: false, label: 'Last Run Semaphore' },
  { id: 'trend', numeric: false, label: 'Trend Indicator' },
  { id: 'lastRun', numeric: false, label: 'Last Run Date' },
  { id: 'issues', numeric: false, label: 'Issues' },
  { label: '' }
];

export default function QualityChecksTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell key={headCell.id} align={index < 2 ? 'left' : 'center'} padding='normal'>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
