import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SystemsListPage from './list/SystemsListPage';
import SystemDetailPage from './detail/SystemDetailPage';

import SystemLoginsContainer from './logins/SystemLoginsListPage';
import PhysicalFieldDetailPage from './physicalfields/detail/PhysicalFieldDetailPage';
import PhysicalModelNavbar from 'pages/systems/PhysicalModelNavbar';

const SystemsRoutes = (props) => (
  <>
    <PhysicalModelNavbar />
    <Switch>
      <Route exact path={props.match.url} component={SystemsListPage} />
      <Route exact path={`${props.match.url}/:systemUuid`} component={SystemDetailPage} />
      <Route
        exact
        path={`${props.match.url}/:systemUuid/logins`}
        component={SystemLoginsContainer}
      />
      <Route
        exact
        path={`${props.match.url}/physical_fields/:physicalFieldUuid`}
        component={PhysicalFieldDetailPage}
      />
    </Switch>
  </>
);

export default SystemsRoutes;
