import React, { useEffect } from 'react';
import { fetchOverallDashboard } from '../../../actions/StewardshipActions';
import { Grid } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import DashboardOverallView from '../common/DashboardOverallView';
import { useTranslation } from 'react-i18next';
import ResourceApplicabilityTargetTypes from '../roles/commons/ResourceApplicabilityTargetTypes';
import { filterViewableResourceTypes } from 'components/permissions/permissiosSelectors';

const STEWARDSHIP_RESOURCE_TYPES = Object.values(ResourceApplicabilityTargetTypes);

function StewardshipDashboardsOverall({ overall, fetchOverallDashboard }) {
  let viewableResourceTypes = useSelector(filterViewableResourceTypes(STEWARDSHIP_RESOURCE_TYPES));
  useEffect(() => {
    viewableResourceTypes.forEach((resourceType) => fetchOverallDashboard({ resourceType }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOverallDashboard]);

  let { t } = useTranslation();

  return (
    <Grid container spacing={1} direction='row' alignItems='stretch' justifyContent='center'>
      {viewableResourceTypes.map((resourceType) => (
        <Grid item lg={6} md={12} style={{ maxWidth: '100%' }}>
          <DashboardOverallView
            resourceType={t(`resourceType.${resourceType}`)}
            rows={overall[resourceType]?.rows || []}
            columns={overall[resourceType]?.columns || []}
            percentLabel={'Responsibilities Coverage'}></DashboardOverallView>
        </Grid>
      ))}
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    overall: state.stewardship.dashboards.overall
  };
}

export default connect(mapStateToProps, { fetchOverallDashboard })(StewardshipDashboardsOverall);
