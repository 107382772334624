import React from 'react';
import { useRouteMatch, useLocation } from 'react-router';
import ToolbarActions from 'pages/sidebar/ToolbarActions';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const QualityAssessmentNavbar = () => {
  let { url } = useRouteMatch();
  let { pathname } = useLocation();

  const risksUrl = `${url}/risks`;
  const scoringUrl = `${url}/scoring`;
  const assessmentDashboardsUrl = `${url}/dashboards`;

  return (
    <ToolbarActions>
      <Button
        color={pathname === assessmentDashboardsUrl ? 'primary' : 'grey'}
        children='Dashboards'
        component={Link}
        to={assessmentDashboardsUrl}
      />
      <Button
        color={pathname === scoringUrl ? 'primary' : 'grey'}
        children='Scoring'
        component={Link}
        to={scoringUrl}
      />
      <Button
        color={pathname === risksUrl ? 'primary' : 'grey'}
        children='Risks'
        component={Link}
        to={risksUrl}
      />
    </ToolbarActions>
  );
};

export default QualityAssessmentNavbar;
