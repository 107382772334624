import React from 'react';
import ToolbarActions from '../sidebar/ToolbarActions';
import { Button } from '@mui/material';
import { useRouteMatch, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

export default function TemplatesNavbar(props) {
  let { url } = useRouteMatch();
  let { pathname } = useLocation();
  const stewardshipRolesUrl = `${url}/roles`;
  const stewardshipResponsibilitiesUrl = `${url}/responsibilities`;
  const stewardshipDashboardUrlTable = `${url}/dashboards/table`;
  return (
    <ToolbarActions>
      <Button
        color={pathname === stewardshipDashboardUrlTable ? 'primary' : 'grey'}
        children='Dashboard'
        component={Link}
        to={stewardshipDashboardUrlTable}
      />
      <Button
        color={pathname === stewardshipRolesUrl ? 'primary' : 'grey'}
        children='Roles'
        component={Link}
        to={stewardshipRolesUrl}
      />
      <Button
        color={pathname === stewardshipResponsibilitiesUrl ? 'primary' : 'grey'}
        children='Responsibilities'
        component={Link}
        to={stewardshipResponsibilitiesUrl}
      />
    </ToolbarActions>
  );
}
