import React, { useState } from 'react';
import { ChipWrapper, ChipItem } from '../../../../components/chiplist/ChipList';
import ItemSelectorModal from '../../../../components/itemselector/ItemSelectorModal';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withLoader } from '../../../../components/loader/Loader';
import { fetchTeamConfiguration } from '../../../../actions/LoginActions';
import { Typography } from '@mui/material';

function AccountTeamChipEditor(props) {
  let [addTeamCode, setAddTeamCode] = useState(false);
  let { availableTeamsCodes = [], teamsCodes = [], onChange } = props;
  return (
    <>
      <Typography variant='subtitle1'>Teams</Typography>
      <ChipWrapper onRequestAdd={() => setAddTeamCode(true)}>
        {teamsCodes.map((teamCode) => (
          <ChipItem
            key={teamCode}
            id={teamCode}
            label={teamCode}
            tooltip={teamCode}
            onRequestDelete={() => onChange(teamsCodes.filter((t) => t !== teamCode))}></ChipItem>
        ))}
      </ChipWrapper>
      <ItemSelectorModal
        title='Select a Team'
        itemName='Team'
        open={addTeamCode}
        values={availableTeamsCodes.map((t) => ({ name: t, value: t }))}
        keyProperty={'value'}
        nameProperty={'name'}
        handleSubmit={({ name }) => {
          onChange(teamsCodes.concat([name]));
          setAddTeamCode(false);
        }}
        handleCancel={() => setAddTeamCode(false)}></ItemSelectorModal>
    </>
  );
}
export const ACCESS_LEVELS = Object.freeze({
  READ: Symbol('canRead'),
  WRITE: Symbol('canWrite'),
  PROPOSE: Symbol('canPropose'),
  APPROVE: Symbol('canApprove')
});

const mapStateToProps = (state, { accessLevel = ACCESS_LEVELS.READ }) => ({
  availableTeamsCodes: state.account.teamConfig.teamPolicies
    .filter((tp) => tp[accessLevel.description])
    .map((tp) => tp.team.code)
});
export default compose(
  connect(mapStateToProps, { fetchTeamConfiguration }),
  withLoader({ onLoad: ({ fetchTeamConfiguration }) => fetchTeamConfiguration() })
)(AccountTeamChipEditor);
