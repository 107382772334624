import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ErrorBoundary from '../errors/ErrorBoundary';
import './HtmlEditor.css';
import { Grid } from '@mui/material';
import { Controlled as CodeMirror } from 'react-codemirror2-react-17';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/display/fullscreen.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import { html_beautify } from 'js-beautify';
export default class HtmlEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlEditor: props.defaultHtmlEnabled || false
    };
  }

  handleSwitch = () => {
    if (!this.state.htmlEditor) {
      this.props.handleChange(this.props.text.replace('\n', ''));
    }
    this.props.handleChange(html_beautify(this.props.text));
    this.setState({ htmlEditor: !this.state.htmlEditor });
  };

  render() {
    return (
      <div>
        <Grid container>
          <Grid style={{ flexGrow: 1 }} item>
            <h4>{this.props.label}</h4>
          </Grid>
          <Grid item>
            <FormControlLabel
              style={{ paddingLeft: '12px' }}
              control={
                <Switch
                  checked={this.state.htmlEditor}
                  onChange={() => this.handleSwitch()}
                  value='htmlEditor'
                  color='primary'
                />
              }
              label='Html Editor'
            />
          </Grid>
        </Grid>

        {!this.state.htmlEditor ? (
          <>
            <CodeMirror
              style={{ maxHeight: 400, overflow: 'auto' }}
              value={this.props.text || ''}
              onBeforeChange={(editor, data, newValue) => {
                this.props.handleChange(newValue);
              }}
              options={{
                mode: 'htmlmixed',
                lineNumbers: true
              }}
            />
          </>
        ) : (
          <ErrorBoundary>
            <ReactQuill
              value={this.props.text}
              modules={{
                toolbar: toolbarOptions,
                clipboard: {
                  matchVisual: false
                }
              }}
              onChange={(text) => this.props.handleChange(text)}
            />
          </ErrorBoundary>
        )}
      </div>
    );
  }
}

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ['clean'], // remove formatting button
  ['link', 'image', 'video']
];

// function format(html) {
//   var tab = '\t';
//   var result = '';
//   var indent = '';

//   html.split(/>\s*</).forEach(function (element) {
//     if (element.match(/^\/\w/)) {
//       indent = indent.substring(tab.length);
//     }

//     result += indent + '<' + element + '>\r\n';

//     if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith('input')) {
//       indent += tab;
//     }
//   });

//   return result.substring(1, result.length - 3);
// }

HtmlEditor.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};
