import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import { expandNode } from '../../../actions/GraphActions';

function GraphMenuExpandNode({ node, onToggle, expandNode }) {
  let [t] = useTranslation();
  return (
    <MenuItem
      children={t('graph.menu.actions.expand')}
      disabled={node === undefined}
      onClick={() => {
        expandNode(node);
        onToggle(false);
      }}
    />
  );
}

export default connect(() => ({}), { expandNode })(GraphMenuExpandNode);
