import React from 'react';

import { Grid } from '@mui/material';
import {
  VisibilityDecorator,
  DataSetDecorator
} from 'pages/systems/physical_entities/commons/Decorators';

const Decorators = ({ dataset, hidden }) => {
  return (
    <Grid container spacing={2} alignItems={'center'}>
      {hidden ? (
        <Grid item>
          <VisibilityDecorator />
        </Grid>
      ) : null}
      {dataset ? (
        <Grid item>
          <DataSetDecorator dataSet={dataset} />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Decorators;
