import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TablePagination,
  Tooltip,
  Typography
} from '@mui/material';
import { useHistory } from 'react-router';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchQueryStatementsPage, createQueryStatement } from 'actions/QueryStatementsActions';
import QueryStatementModal from '../commons/QueryStatementModal';
import QueryStatementsCardTable from './QueryStatementsCardTable';
import QueryStatementExportButton from '../commons/QueryStatementExportButton';
import QueryStatementSearchBox from '../commons/QueryStatementSearchBox';
import QueryStatementFiltersModal from '../commons/QueryStatementFiltersModal';
import { fetchQueryBucket } from 'actions/QueryBucketsActions';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import QueryStatementsRefreshAllButton from '../commons/QueryStatementsRefreshAllButton';
import QueryStatementsConfirmAllButton from '../commons/QueryStatementsConfirmAllButton';
import useRoutePagination from 'components/pagination/useRoutePagination';
import QueryStatementsIgnoreAllButton from '../commons/QueryStatementsIgnoreAllButton';

const QueryStatementsCard = ({
  fetchQueryStatementsPage,
  createQueryStatement,
  fetchQueryBucket,
  currentPage,
  queryBucket,
  filters
}) => {
  const { t } = useTranslation();
  const queryBucketUuid = queryBucket.uuid;
  const { changePagination } = useRoutePagination({
    currentPage,
    onPageSelection: (page, size) => {
      fetchQueryStatementsPage({
        page: page,
        ...getFetchParameters({ uuid: queryBucketUuid }, filters)
      });
    },
    dependencies: [queryBucketUuid, fetchQueryStatementsPage, filters]
  });
  return (
    <Card>
      <CardHeader
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>{t('querystatements.card.title')}</Typography>
            <Authorize hasPermissions={[Permissions.QUERY_PARSER_EDITOR]}>
              <QueryStatementsRefreshAllButton
                filters={filters}
                onRefreshEnded={() =>
                  fetchQueryBucket(queryBucket.uuid).then(() =>
                    fetchQueryStatementsPage({
                      page: 0,
                      size: currentPage.size,
                      ...getFetchParameters(queryBucket, filters)
                    })
                  )
                }
                queryBucketUuid={queryBucket.uuid}
                currentPage={currentPage}
              />
            </Authorize>
            <Authorize hasPermissions={[Permissions.QUERY_PARSER_EDITOR]}>
              <QueryStatementsConfirmAllButton
                filters={filters}
                onRefreshEnded={() =>
                  fetchQueryBucket(queryBucket.uuid).then(() =>
                    fetchQueryStatementsPage({
                      page: 0,
                      size: currentPage.size,
                      ...getFetchParameters(queryBucket, filters)
                    })
                  )
                }
                queryBucketUuid={queryBucket.uuid}
                currentPage={currentPage}
              />
            </Authorize>
            <Authorize hasPermissions={[Permissions.QUERY_PARSER_EDITOR]}>
              <QueryStatementsIgnoreAllButton
                filters={filters}
                onRefreshEnded={() =>
                  fetchQueryBucket(queryBucket.uuid).then(() =>
                    fetchQueryStatementsPage({
                      page: 0,
                      size: currentPage.size,
                      ...getFetchParameters(queryBucket, filters)
                    })
                  )
                }
                queryBucketUuid={queryBucket.uuid}
                currentPage={currentPage}
              />
            </Authorize>
          </div>
        }
        action={
          <Grid container direction='row' alignItems='center'>
            <Grid item>
              <QueryStatementSearchBox bucketUuid={queryBucket.uuid} />
            </Grid>
            <Authorize hasPermissions={[Permissions.QUERY_PARSER_EDITOR]}>
              <Grid item>
                <AddButton createQueryStatement={createQueryStatement} queryBucket={queryBucket} />
              </Grid>
            </Authorize>
            <Grid item>
              <QueryStatementExportButton queryBucket={queryBucket} />
            </Grid>
            <Grid item>
              <QueryStatementFiltersModal />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {currentPage.content && currentPage.content.length > 0 ? (
          <>
            <QueryStatementsCardTable
              queryStatements={currentPage.content}
              queryBucket={queryBucket}
              onRefresh={() => changePagination({ page: currentPage.number })}
            />
            <TablePagination
              component='div'
              style={{ overflowX: 'hidden' }}
              rowsPerPageOptions={[10, 20, 50]}
              page={currentPage.number}
              rowsPerPage={currentPage.size}
              count={currentPage.totalElements}
              onRowsPerPageChange={(event) =>
                changePagination({
                  page: currentPage.number,
                  size: event.target.value
                })
              }
              onPageChange={(_event, page) =>
                changePagination({
                  page: page,
                  size: currentPage.size
                })
              }
            />
          </>
        ) : (
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>No query statement associated to this resource</Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const AddButton = ({ queryBucket, createQueryStatement }) => {
  const [openCreate, setOpenCreate] = useState(false);
  const history = useHistory();

  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t('querystatements.card.addButton')}>
        <IconButton size='small' onClick={() => setOpenCreate(true)}>
          <AddBoxIcon />
        </IconButton>
      </Tooltip>
      {openCreate && (
        <QueryStatementModal
          open={openCreate}
          queryBucket={queryBucket}
          onClose={() => {
            setOpenCreate(false);
          }}
          onSubmit={(queryStatement) => {
            setOpenCreate(false);
            createQueryStatement(queryStatement).then((statement) => {
              if (statement) {
                history.push(
                  `/dataflows/queryparser/buckets/${statement.queryBucket.uuid}/statements/${statement.uuid}`
                );
              }
            });
          }}
        />
      )}
    </>
  );
};

const getFetchParameters = (queryBucket, filters) => {
  return {
    ...filters,
    bucketUuid: queryBucket?.uuid
  };
};

const mapStateToProps = (state, _props) => {
  return {
    currentPage: state.dataflows.querystatements.currentPage,
    filters: state.dataflows.querystatements.filters.content
  };
};

export default connect(mapStateToProps, {
  fetchQueryStatementsPage,
  createQueryStatement,
  fetchQueryBucket
})(QueryStatementsCard);
