import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchCheckGroupCodes } from 'actions/QualityChecksActions';
import Autocomplete from 'components/autocomplete/AutoComplete';

function QualityCheckGroupCodeItemAutocomplete({ onChange, checkGroupCode, fetchCheckGroupCodes }) {

  const [qualityCheckCodes, setQualityCheckCodes] = useState([]);

  useEffect(() => {
    fetchCheckGroupCodes({ search: '' }).then(
      (response) => response && setQualityCheckCodes(response.content)
    );
  }, [fetchCheckGroupCodes, setQualityCheckCodes]);

  return (
    <Autocomplete
      fullWidth
      margin='dense'
      label='Check Group Code'
      value={checkGroupCode}
      dataSource={qualityCheckCodes}
      onUpdateInput={onChange}
      onNewRequest={onChange}
    />
  );
}

export default connect(null, { fetchCheckGroupCodes })(QualityCheckGroupCodeItemAutocomplete);
