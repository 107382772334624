import React from 'react';
import { withTranslation } from 'react-i18next';
import DataFlowsList from './DataFlowsList';
import AddFab from '../../../components/fab/AddFAB';
import { Link } from 'react-router-dom';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import Breadcrumbs from 'pages/sidebar/Navigation';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

const DataFlowsListPage = ({ t }) => (
  <div>
    <Breadcrumbs elements={[{ text: t('sidebar.data_flows'), to: '/dataflows' }]} />
    <Tracking info={{
      pageTitle: t('dataflows.titleListPage'),
      category: EngagementCategories.DATA_FLOW,
      action: EngagementActions.LIST
    }} />
    <DataFlowsList />
    <Authorize hasPermissions={[Permissions.SYSTEMS_ADMIN]}>
      <AddFab to={'/dataflows/editor/create'} component={Link} />
    </Authorize>
  </div>
);

export default withTranslation()(DataFlowsListPage);
