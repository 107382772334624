import React, { useState } from 'react';
import { connect } from 'react-redux';

import { createPolicy } from 'actions/IssuesPoliciesActions';
import withPermissions from 'components/permissions/withPermissions';

import AddFAB from 'components/fab/AddFAB';
import { compose } from 'redux';
import Permissions from 'constants/Permissions';
import IssuePolicyModal from 'pages/issueMngt/issuesPolicies/detail/IssuePolicyModal';

function IssuePolicyFAB({ createPolicy }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      {open && (
        <IssuePolicyModal
          open={open}
          title='Create an Issue Policy'
          handleCancel={() => setOpen(false)}
          handleSubmit={(policy) => createPolicy({ ...policy })}
        />
      )}
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.CAMPAIGNS_EDITOR] }),
  connect(null, { createPolicy })
)(IssuePolicyFAB);
