import React from 'react';

import PhysicalEntitySearchModal from 'pages/systems/physical_entities/commons/PhysicalEntitySearchModal';
import PhysicalEntityCreateModal from 'pages/systems/physical_entities/commons/PhysicalEntityCreateModal';
import AlertDialog from 'components/alertdialog/AlertDialog';

const PhysicalEntitiesActionsModals = ({
  selectedPhysicalEntity,
  searchModalOpen,
  setSearchModalOpen,
  onSearch,
  appliedFilters,
  createModalOpen,
  setCreateModalOpen,
  onCreate,
  editModalOpen,
  setEditModalOpen,
  onEdit,
  deleteModalOpen,
  setDeleteModalOpen,
  onDelete
}) => {
  return (
    <>
      {/* Search modal */}
      <PhysicalEntitySearchModal
        open={searchModalOpen}
        onSubmit={(entity) => onSearch(entity)}
        onCancel={() => setSearchModalOpen(false)}
      />

      {/* Create modal */}
      <PhysicalEntityCreateModal
        title='Create Physical Entity'
        open={createModalOpen}
        key={appliedFilters.system?.uuid} // Needed to update the state after props change
        physicalEntity={{
          system: appliedFilters.system
        }}
        handleSubmit={(entity) => onCreate(entity)}
        handleCancel={() => setCreateModalOpen(false)}
      />

      {/* Edit modal */}
      <PhysicalEntityCreateModal
        title='Modify Physical Entity'
        open={editModalOpen}
        key={selectedPhysicalEntity && selectedPhysicalEntity.uuid}
        physicalEntity={selectedPhysicalEntity}
        handleSubmit={(entity) => onEdit(entity)}
        handleCancel={() => setEditModalOpen(false)}
      />

      {/* Delete modal */}
      <AlertDialog
        message={
          'Do you really want to delete this physical entity? This will delete associated dataflows and resources.'
        }
        open={deleteModalOpen}
        onOk={() => onDelete(selectedPhysicalEntity)}
        onCancel={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default PhysicalEntitiesActionsModals;
