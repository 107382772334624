import React from 'react';
import { Route } from 'react-router-dom';
import ConsentDetailContainer from './detail/ConsentDetailPage'
import ConsentsSearchPage from './search/ConsentsSearchPage'

export default class ConsentsPage extends React.Component {

  render() {
    return (
      <div>
        <Route exact path={this.props.match.url}
          component={ConsentsSearchPage} />
        <Route path={`${this.props.match.url}/:consentUuid`} component={ConsentDetailContainer} />
      </div>
    );
  }

}
