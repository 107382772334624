import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { searchAgentConnections } from 'actions/onagent/AgentConnectionsActions';
import AutoComplete from 'components/autocomplete/AutoComplete';

function AgentConnectionItemAutoComplete({
  label,
  fullWidth,
  margin,
  agent,
  searchContent,
  value,
  onSearch,
  onChange,
  ...rest
}) {
  const [lastSearch, setLastSerach] = useState('');
  const debouncedSearch = _.debounce((searchText) => {
    onSearch(agent, { search: searchText });
  }, 275);

  return (
    <AutoComplete
      fullWidth={fullWidth}
      margin={margin}
      label={label || 'Connection'}
      placeholder='Select a Connection'
      value={value || lastSearch}
      dataSource={searchContent}
      onNewRequest={(string, dataSource, value) => {
        setLastSerach(string);
        onChange(value);
      }}
      onUpdateInput={(searchText, dataSource) => {
        setLastSerach(searchText);
        debouncedSearch(searchText);
        if (value) {
          onChange(null);
        }
      }}
      {...rest}
    />
  );
}

const mapStateToProps = (state, props) => ({
  searchContent: Object.values(state.settings.agents.connections.content)
    .filter((conn) => !props.connectionTypes || props.connectionTypes.includes(conn.type))
    .map((conn) => conn.name)
});

const mapDispatchToProps = (dispatch) => ({
  onSearch: (agent, search) => dispatch(searchAgentConnections(agent, search))
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentConnectionItemAutoComplete);
