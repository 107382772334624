import { combineReducers } from 'redux';
import comments from './comments';
import notifications from './notifications';
import watchlist from './watchlist';
import workspace from './workspace';
import favorites from './favorites';

export default combineReducers({
  comments,
  notifications,
  watchlist,
  workspace,
  favorites
});
