import * as React from "react";
const SvgExasol = (props) => (
  <svg
    id="Exasol_svg__layer"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 652 652"
    style={{
      enableBackground: "new 0 0 652 652",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{".Exasol_svg__st0{fill:#002c40}"}</style>
    <path
      className="Exasol_svg__st0"
      d="M21.2 249.9h84.4v22H46.8v36.9h47.5v22.1H46.8v37.9h62v22H21.2zM288.4 371.5c16.3 0 30.9-11.4 30.9-31.1 0-19.8-15.9-30.5-30.9-30.5-16.7 0-30.7 12-30.7 30.5 0 18.4 13.9 31.1 30.7 31.1m31.1 4.9c-6.7 11-21.3 16.5-33.7 16.5-27 0-50.9-20.4-50.9-52.4s23.8-52.2 50.9-52.2c12 0 26.8 4.9 33.7 16.3v-14.2h21.9v100.2h-21.9v-14.2zM372.6 360.6s11.1 11.1 27.6 11.1c7.1 0 13.3-3.2 13.3-9.1 0-15.1-49.2-13.1-49.2-45.7 0-18.9 16.9-29.8 38.7-29.8 22.8 0 33.9 11.5 33.9 11.5l-9.3 18.7s-9.3-8.7-24.8-8.7c-6.6 0-12.9 2.8-12.9 9.1 0 14.3 49.2 12.5 49.2 45.1 0 16.9-14.7 30.4-38.7 30.4-25.2 0-39.3-14.9-39.3-14.9l11.5-17.7zM507.6 371.8c16.3 0 30-12.7 30-31.6 0-18.7-13.7-31.6-30-31.6-16.5 0-30.2 12.9-30.2 31.6.1 18.8 13.8 31.6 30.2 31.6m-.2-84.6c31 0 55.8 22 55.8 53 0 31.2-24.8 53-55.6 53-31 0-55.8-21.8-55.8-53 .1-31 24.9-53 55.6-53M582.1 249.9h25v105.6c0 11.1 3.8 13.9 10.3 13.9 2 0 3.8-.2 3.8-.2v22s-3.8.6-7.9.6c-13.9 0-31.2-3.6-31.2-32.6V249.9z"
    />
    <path
      style={{
        fill: "#45b035",
      }}
      d="m122.3 390.8 38.5-50.3-38.4-50h29.7l38.4 50-38.6 50.3z"
    />
    <path
      className="Exasol_svg__st0"
      d="m180.9 315.6 14.8 19.3 33.9-44.5H200zM180.9 365.6l14.8-19.3 33.9 44.5H200z"
    />
  </svg>
);
export default SvgExasol;
