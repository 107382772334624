import {
  REQUEST_LOGICAL_FIELDS,
  CREATE_LOGICAL_FIELD,
  DELETE_LOGICAL_FIELD
} from '../constants/ActionTypes';
import _ from 'lodash';
import crudReducers from './utils';
import filtersReducer from './utils/filters';

import { combineReducers } from 'redux';
const filtersDefault = {
  hasFilters: false,
  search: '',
  dataCategories: [],
  systems: []
};

//hashmap categoryUuid -> logicalfields[]
const contentByDataCategory = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_LOGICAL_FIELDS:
      return {
        ...state,
        ..._.groupBy(action.payload.content, (lf) => lf.dataCategory.uuid)
      };

    case CREATE_LOGICAL_FIELD:
      return {
        ...state,
        [action.payload.dataCategory.uuid]: (state[action.payload.dataCategory.uuid] || []).concat([
          action.payload
        ])
      };
    case DELETE_LOGICAL_FIELD:
      return {
        ...state,
        [action.payload.dataCategory.uuid]: (state[action.payload.dataCategory.uuid] || []).filter(
          (lf) => lf.uuid !== action.payload.uuid
        )
      };

    default:
      return state;
  }
};

export default combineReducers({
  ...crudReducers({ resource: 'LOGICAL_FIELD', keyProperty: 'uuid' }),
  contentByDataCategory,
  filters: filtersReducer({ resource: 'LOGICAL_FIELD', filtersDefault })
});
