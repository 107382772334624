import React from 'react';
import { Link } from 'react-router-dom';
import KVDefinitionList from '../../../../components/definition-list/KVDefinitionList';
import { Card, CardHeader, CardContent, CardActions, Grid } from '@mui/material';
import { withLoader } from '../../../../components/loader/Loader';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import Authorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';
import {
  fetchContractConfiguration,
  deleteContractConfiguration
} from '../../../../actions/ContractsActions';
import { connect } from 'react-redux';
import { Typography, Button } from '@mui/material';

import _ from 'lodash';
import ComposableTableCard from '../../../../components/tables/ComposableTableCard';
import ProcessingPlaceholderControlCounters from './ProcessingPlaceholderControlCounters';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import { ChipItem } from 'components/chiplist/ChipList';
import { useTranslation } from 'react-i18next';

const ConfigurationDetailPage = ({ configuration, deleteContractConfiguration, history }) => {
  const { t } = useTranslation();
  return !configuration ? null : (
    <React.Fragment>
      <Breadcrumbs
        elements={[
          { text: 'Contracts', to: '/contracts' },
          { text: 'Configurations', to: '/contracts/configurations' },
          { text: configuration.contractName }
        ]}
      />
      <Tracking
        info={{
          pageTitle: `Contract Configuration:${configuration.contractName}`,
          category: EngagementCategories.CONSENTS,
          action: EngagementActions.DETAIL
        }}
      />

      <Typography variant='h4'>Contract Configuration</Typography>

      <ConfigurationDetailGeneralData
        {...{ configuration, deleteContractConfiguration, history, t }}
      />

      <ConfigurationDetailProcessingPlaceholders {...{ configuration }} />

      <ConfigurationDetailPlaceholders {...{ configuration }} />
    </React.Fragment>
  );
};

const ConfigurationDetailGeneralData = ({
  configuration,
  deleteContractConfiguration,
  history,
  t
}) => (
  <Card sx={styles.card}>
    <CardHeader
      title={`${configuration.contractName}`}
      subheader={`(${configuration.language}, ${configuration.version})`}
    />
    <CardContent>
      <Grid container spacing={2}>
        <Grid item>
          <KVDefinitionList
            item={{
              Name: configuration.contractName,
              Language: configuration.language,
              Version: configuration.version,
              Active: (configuration.active || false).toString()
            }}
          />
        </Grid>
        <Grid item>
          <KVDefinitionList
            item={{
              'Date created':
                configuration.createdAt && new Date(configuration.createdAt).toLocaleString(),
              'Date modified':
                configuration.updatedAt && new Date(configuration.updatedAt).toLocaleString()
            }}
          />
        </Grid>
      </Grid>
      {configuration.template && (
        <Grid direction='row' alignItems='center' spacing={2} container>
          <Grid item>
            <h4>Template:</h4>
          </Grid>
          <Grid item>
            <ChipItem to={`/contracts/templates/${configuration?.template?.uuid}`} size='small'>
              {_.get(configuration, 'template.name')}
            </ChipItem>
          </Grid>
        </Grid>
      )}
    </CardContent>
    <CardActions>
      <Authorize hasPermissions={[Permissions.CONTRACTS_EDITOR]}>
        <Button
          children={t('commons.actions.modify')}
          component={Link}
          to={`/contracts/configurations/editor/edit/${configuration.uuid}`}
        />
      </Authorize>
      <Authorize hasPermissions={[Permissions.CONTRACTS_ADMIN]}>
        <Button
          children={t('commons.actions.copy')}
          component={Link}
          to={`/contracts/configurations/editor/copy/${configuration.uuid}`}
        />
      </Authorize>
      <Authorize hasPermissions={[Permissions.CONTRACTS_ADMIN]}>
        <ConfirmButton
          children={t('commons.actions.delete')}
          onClick={() => {
            deleteContractConfiguration(configuration).then(() =>
              history.replace('/contracts/configurations')
            );
          }}
        />
      </Authorize>
    </CardActions>
  </Card>
);

const ConfigurationDetailProcessingPlaceholders = ({ configuration }) => (
  <ComposableTableCard
    firstStrong
    title='Processing Placeholders Values'
    headers={[
      'Name',
      'Processing Disclaimer Uuid',
      'Disclaimer Version',
      'Disclaimer Language',
      'Title',
      'Description'
    ]}
    propertiesPaths={[
      'name',
      'processingDisclaimer.uuid',
      'processingDisclaimer.version',
      'processingDisclaimer.lang',
      'processingDisclaimer.title',
      'processingDisclaimer.description'
    ]}
    rows={configuration.processingPlaceholdersValues || []}
    additionalContent={
      <ProcessingPlaceholderControlCounters
        processingPlaceholdersValues={configuration.processingPlaceholdersValues}
      />
    }
  />
);

const ConfigurationDetailPlaceholders = ({ configuration }) => (
  <ComposableTableCard
    firstStrong
    title='Placeholders Values'
    headers={['Name', 'Value']}
    propertiesPaths={['name', 'value']}
    rows={configuration.placeholdersValues || []}
  />
);

const styles = {
  card: {
    margin: '8px 0px 8px 0px'
  }
};

const mapStateToProps = (state, props) => ({
  configuration: state.contracts.configurations.content[props.match.params['configurationUuid']],
  configurationUuid: props.match.params['configurationUuid']
});

export default _.flow([
  withLoader({
    onLoad: (props) => props.fetchContractConfiguration(props.configurationUuid)
  }),
  connect(mapStateToProps, { fetchContractConfiguration, deleteContractConfiguration })
])(ConfigurationDetailPage);
