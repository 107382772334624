import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import _ from 'lodash';

import { fetchErDiagramData } from 'actions/physical/ErDiagramActions';

import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  ButtonGroup,
  Button
} from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import ErDiagram from './ErDiagram';

const ErDiagramPage = (props) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { resourceIdentifier } = queryString.parse(
    props.location.search
  );

  const [diagramDepth, setDiagramDepth] = useState(1);
  const [data, setData] = useState(null);
  const [currentEntity, setCurrentEntity] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const data = await dispatch(
        fetchErDiagramData({
          startPhysicalEntityUuid: resourceIdentifier,
          depth: diagramDepth
        })
      );
      setData(data);
      setCurrentEntity(_.find(data.nodes, { 'physicalEntityUuid': resourceIdentifier }));
    }
    fetchData();
  }, [resourceIdentifier, diagramDepth, dispatch])

  return data && currentEntity ? (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.physical_model'), to: '/systems' },
          { text: currentEntity.systemName, to: `/systems/${currentEntity.systemUuid}` },
          { text: currentEntity.name, to: `/physicalentities/${currentEntity.physicalEntityUuid}` },
          { text: 'ER Diagram' },
        ]}
      />
      <Card>
        <CardHeader
          title={`ER Diagram - ${currentEntity.name}`}
          action={<DepthControls diagramDepth={diagramDepth} setDiagramDepth={setDiagramDepth} />}
        />
        <CardContent sx={{ height: 750, width: '100%' }}>
          <ErDiagram rawNodes={data.nodes} rawEdges={data.edges} />
        </CardContent>
      </Card>
    </>
  ) : null;

}

const DepthControls = ({ diagramDepth, setDiagramDepth }) => {

  const MIN_DEPTH = 1;
  const MAX_DEPTH = 10;

  const handleIncrement = () => setDiagramDepth(diagramDepth + 1)
  const handleDecrement = () => setDiagramDepth(diagramDepth - 1)

  const styles = {
    leftButton: (isDisabled) => isDisabled ? { borderRight: '1px solid rgba(0, 0, 0, 0.2)' } : { borderRight: '1px solid rgba(0, 174, 202, 0.5)' },
    centerButton: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
      borderTopColor: 'rgba(0, 0, 0, 0.2)',
      borderRight: 0,
      borderBottomColor: 'rgba(0, 0, 0, 0.2)',
      borderLeft: 0
    }
  }

  return (
    <Grid container>
      <Grid item>
        <Typography variant='overline' sx={{ padding: '8px' }}>Depth</Typography>
      </Grid>
      <Grid item>
        <ButtonGroup variant="outlined" size='small'>
          <Button style={styles.leftButton(diagramDepth <= MIN_DEPTH)} disabled={diagramDepth <= MIN_DEPTH} onClick={handleDecrement}>-</Button>
          <Button style={styles.centerButton} disabled>{diagramDepth}</Button>
          <Button disabled={diagramDepth >= MAX_DEPTH} onClick={handleIncrement}>+</Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  )
}

export default ErDiagramPage;