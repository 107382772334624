import { combineReducers } from 'redux';
import campaigns from './campaigns';
import issues from './issues';
import issuesPolicies from './issuesPolicies';
import dashboards from './dashboards';

export default combineReducers({
  campaigns,
  issues,
  issuesPolicies,
  dashboards
});
