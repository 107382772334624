import React from 'react';
import ImportFileSelection from './ImportFileSelection';
import ImportCsvPreview from './ImportCsvPreview';
import ImportJob from './ImportJob';
import { csvDataToObject } from '../utils/converter';
import { Typography, Grid } from '@mui/material';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const ImportPage = (props) => (
  <React.Fragment>
    <Breadcrumbs elements={[{ text: 'Settings', to: '/settings' }, { text: 'Import' }]} />
    <Tracking
      info={{
        pageTitle: 'Import Page',
        category: EngagementCategories.SETTINGS,
        action: EngagementActions.DETAIL
      }}
    />
    <Grid container>
      <Grid item>
        <Typography variant='h4'>Import</Typography>
      </Grid>
      <Grid item style={{ flexGrow: 1 }} />
      <Grid item>
        <FavoritesWidget itemIdentifier={'page:import-utils'} pageTitle={'Import Page'} />
      </Grid>
    </Grid>
    <ImportWorkflow />
  </React.Fragment>
);

class ImportWorkflow extends React.Component {
  state = {
    step: 1,
    configuration: null,
    file: null,
    header: null,
    rows: null,
    objects: null
  };

  render() {
    switch (this.state.step) {
      case 1:
        return (
          <ImportFileSelection
            onNext={({ configuration, file }) => this.setState({ step: 2, configuration, file })}
          />
        );
      case 2:
        return (
          <ImportCsvPreview
            file={this.state.file}
            configuration={this.state.configuration}
            onBack={() => this.setState({ step: 1 })}
            onNext={({ header, rows }) =>
              this.setState({
                step: 3,
                header,
                rows,
                objects: csvDataToObject(header, rows, this.state.configuration)
              })
            }
          />
        );
      case 3:
        return (
          <ImportJob
            configuration={this.state.configuration}
            objects={this.state.objects}
            onBack={() => this.setState({ step: 1 })}
          />
        );
      default:
        return null;
    }
  }
}

export default ImportPage;
