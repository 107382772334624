import { ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';
import { useHistory } from 'react-router';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import _ from 'lodash';

export const DataProductPortNode = ({ dataProduct, port }) => {
  const [contextMenu, setContextMenu] = useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end'
      }}
      onContextMenu={handleContextMenu}>
      <ContextMenu
        dataProduct={dataProduct}
        port={port}
        handleClose={handleClose}
        contextMenu={contextMenu}
      />
      <Handle
        type='target'
        position={Position.Left}
        id={`to.${port.identifier}`}
        style={{ position: 'relative', visibility: 'hidden' }}
      />
      <span style={{ flexGrow: 1 }}>{port.displayName}</span>
      <Handle
        type='source'
        position={Position.Right}
        id={`from.${port.identifier}`}
        style={{ position: 'relative', visibility: 'hidden' }}
      />
    </div>
  );
};

const ContextMenu = ({ dataProduct, port, contextMenu, handleClose }) => {
  const history = useHistory();

  return (
    <Menu
      autoFocus={false}
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={
        contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
      }>
      <div style={{ padding: '8px 16px 16px 16px' }}>
        <Typography
          variant='body1'
          style={{
            textAlign: 'center',
            fontSize: '1.4em',
            paddingLeft: '16px',
            paddingRight: '16px'
          }}>
          {dataProduct.displayName || dataProduct.name || dataProduct.identifier}'s Port
        </Typography>
        <Typography variant='description'>
          {_.truncate(port.description?.replace(/(.{50})/g, '$1\n'), { length: 100 })}
        </Typography>
      </div>
      <MenuItem
        onClick={() => {
          handleClose();
          history.push(`/dataproducts/detail/${dataProduct.uuid}?port=${port.uuid}`);
        }}>
        <ListItemText>{port.displayName || port.name || port.identifier}</ListItemText>
        <OpenInNewIcon fontSize='small' style={{ alignSelf: 'flex-end' }} />
      </MenuItem>
    </Menu>
  );
};
