import { createTheme, adaptV4Theme } from '@mui/material/styles';

export const muiThemeV1 = createTheme(
  adaptV4Theme({
    palette: {
      type: 'dark',
      primary: {
        main: '#00aeca',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#BEBEBE'
      }
    },
    overrides: {
      MuiListItem: {
        root: {
          color: '#ffffff'
        }
      }
    }
  })
);
