import React from 'react';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import QualityChecksTableRow from './QualityChecksTableRow';

function getEmptyRowPadding(emptyRows) {
  return 53 * emptyRows;
}

function QualityChecksTableEmptyRow({ cols }) {
  return (
    <TableRow>
      <TableCell colSpan={cols}>
        <Typography variant='body1' align='center' style={{ textAlign: 'center', padding: 100 }}>
          No quality checks has been created for this test suite yet.
        </Typography>
      </TableCell>
    </TableRow>
  );
}

function QualityChecksTableBody({ rows, emptyRows, fillEmptyRows }) {
  const cols = 4;

  if (rows.length === 0) {
    // return empty table
    return (
      <TableBody>
        <QualityChecksTableEmptyRow cols={cols} />
      </TableBody>
    );
  } else {
    return (
      <TableBody>
        {rows.map((row, index) => (
          <QualityChecksTableRow key={row.uuid} qualityCheck={row} index={index} />
        ))}
        {emptyRows > 0 && fillEmptyRows && (
          <TableRow style={{ height: getEmptyRowPadding(emptyRows) }}>
            <TableCell colSpan={cols} />
          </TableRow>
        )}
      </TableBody>
    );
  }
}

export default QualityChecksTableBody;
