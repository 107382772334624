import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { AdminPermissionsByResourceTypes } from 'constants/ResourceTypes';
import { CustomPropertiesResources } from './CustomPropertiesResources';
import withAuthorize from 'components/permissions/withAuthorize';

function CustomPropertyModal({
  open,
  customPropertyDefinition = { type: 'STRING' },
  onCancel,
  onSubmit
}) {
  const { t } = useTranslation();
  const [patch, setPatch] = React.useState(customPropertyDefinition);

  const data = { ...customPropertyDefinition, ...patch };
  return (
    <Dialog open={open}>
      <DialogTitle title='Custom Property Definition'>Custom Property Definition</DialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth>
          <InputLabel required id='resource-type-select-label'>
            Resource Type
          </InputLabel>
          <Select
            variant="standard"
            fullWidth
            labelId='resource-type-select-label'
            id='resource-type-select'
            value={data.resourceType}
            onChange={(event) => setPatch({ ...patch, resourceType: event.target.value })}>
            {CustomPropertiesResources.map((res) => (
              <AuthorizedMenuItem
                hasPermissions={[AdminPermissionsByResourceTypes[res]]}
                value={res}>
                {t(`resourceType.${res}`)}
              </AuthorizedMenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="standard"
          required
          fullWidth
          placeholder={'Enter the name'}
          label={'Name'}
          value={data.name || ''}
          onChange={(event) => setPatch({ ...patch, name: event.target.value })} />
        <TextField
          variant="standard"
          fullWidth
          placeholder={'Enter the label'}
          label={'Label'}
          value={data.label || ''}
          onChange={(event) => setPatch({ ...patch, label: event.target.value })} />
        <TextField
          variant="standard"
          fullWidth
          type='number'
          placeholder={'Enter the order'}
          label={'Order'}
          value={data.order || ''}
          onChange={(event) => setPatch({ ...patch, order: event.target.value })} />
        <FormControl variant="standard" fullWidth>
          <InputLabel id='type-select-label'>Type</InputLabel>
          <Select
            variant="standard"
            fullWidth
            labelId='type-select-label'
            id='type-select'
            value={data.type}
            onChange={(event) => setPatch({ ...patch, type: event.target.value, domain: null })}>
            {TYPES.map((rt) => (
              <MenuItem value={rt}>{rt}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <DomainEditor
          customProperty={data}
          onChange={(event) => setPatch({ ...patch, domain: event.target.value || null })}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={data.searchable || false}
              onChange={(event) => {
                setPatch({ ...patch, searchable: event.target.checked });
              }}
              name='searchable'
              color='primary'
            />
          }
          label='Render as a search filter'
        />
        <br></br>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
        <Button disabled={isInvalid(data)} onClick={() => onSubmit(data)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function isInvalid(customProperty) {
  return (
    !customProperty.name ||
    !customProperty.resourceType ||
    (['ENUMERATION', 'ENUMERATION_LIST'].includes(customProperty.type) &&
      !isJSONArray(customProperty.domain))
  );
}

function DomainEditor({ customProperty, onChange }) {
  if (customProperty.type === 'COMPUTED') {
    return (
      <TextField
        variant="standard"
        fullWidth
        multiline
        error={!customProperty.domain}
        placeholder={
          (customProperty.type === 'ENUMERATION' || customProperty.type === 'ENUMERATION_LIST') &&
          "e.g. (resource.name != null? 0 : 50) + (resource.additionalProperties.?[name == 'mandatory'].size()>0? 50 : 0)"
        }
        helperText={'Enter the domain as a SpEL expression'}
        label={'Domain'}
        value={customProperty.domain || ''}
        onChange={onChange} />
    );
  } else if (customProperty.type === 'ENUMERATION' || customProperty.type === 'ENUMERATION_LIST') {
    return (
      <TextField
        variant="standard"
        fullWidth
        multiline
        error={customProperty.domain && !isJSONArray(customProperty.domain)}
        placeholder={'e.g. ["value1","value2","value3"]'}
        helperText={'Enter the domain as a JSON array of values'}
        label={'Domain'}
        value={customProperty.domain || ''}
        onChange={onChange} />
    );
  } else {
    return (
      <TextField
        variant="standard"
        fullWidth
        disabled={true}
        helperText={'Domain definition not yet supported'}
        label={'Domain'}
        value={customProperty.domain || ''}
        onChange={onChange} />
    );
  }
}

function isJSONArray(string) {
  try {
    return _.isArray(JSON.parse(string));
  } catch (e) {
    return false;
  }
}

const AuthorizedMenuItem = withAuthorize(MenuItem);

const TYPES = [
  'STRING',
  'NUMERIC',
  'INTEGER',
  'ENUMERATION',
  'COMPUTED',
  'STRING_LIST',
  'ENUMERATION_LIST',
  'AUTOCOMPLETE',
  'AUTOCOMPLETE_LIST'
];

export default CustomPropertyModal;
