import React from 'react';

import { Link } from 'react-router-dom';
import _ from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import DetailsIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip, IconButton, Typography, Grid } from '@mui/material';
import { withTranslation } from 'react-i18next';
import withConfirmation from '../../../components/confirmbutton/withConfirmation';
import { withOpenCloseHandler } from '../../../components/openclosehandler/OpenCloseHandler';
import DataFlowsFiltersDialog from './DataFlowsFiltersDialog';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import DataFlowExportButton from '../utils/DataFlowExportButton';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
const DataFlowListToolbar = ({ selectedDataFlows = [], onDeleteDataFlow, onRefreshData, t }) => (
  <Grid container justifyContent='flex-end' alignItems='center'>
    <Grid style={{ flexGrow: 1 }} item>
      <Grid container>
        <Grid item>
          <Typography variant='h4'>{t('dataflows.titleListPage')}</Typography>
        </Grid>
        <Grid item>
          <Tooltip title='Impact Analysis'>
            <IconButton children='Analysis' component={Link} to={`/dataflows/impactanalysis`}>
              <QueryStatsIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      {selectedDataFlows.length === 1 && (
        <>
          <Authorize hasAnyPermissions={[Permissions.SYSTEMS_VIEWER]}>
            <IconButton
              size='small'
              to={`/dataflows/detail/${selectedDataFlows[0].uuid}`}
              component={Link}
              aria-label={t('commons.actions.detail')}>
              <Tooltip title={t('commons.actions.detail')}>
                <DetailsIcon />
              </Tooltip>
            </IconButton>
          </Authorize>
          <Authorize hasAnyPermissions={[Permissions.SYSTEMS_EDITOR]}>
            <IconButton
              size='small'
              to={`/dataflows/editor/modify/${selectedDataFlows[0].uuid}`}
              component={Link}
              aria-label={t('commons.actions.modify')}>
              <Tooltip title={t('commons.actions.modify')}>
                <EditIcon />
              </Tooltip>
            </IconButton>
          </Authorize>
        </>
      )}

      {selectedDataFlows.length > 0 && (
        <Authorize hasAnyPermissions={[Permissions.SYSTEMS_ADMIN]}>
          <IconButtonWithConfirmation
            size='small'
            aria-label={t('commons.actions.delete')}
            onClick={() => {
              onDeleteDataFlow(selectedDataFlows);
            }}>
            <Tooltip title={t('commons.actions.delete')}>
              <DeleteIcon />
            </Tooltip>
          </IconButtonWithConfirmation>
        </Authorize>
      )}
    </Grid>
    <FavoritesWidget
      itemIdentifier={`page:dataflow-list`}
      pageTitle={t('dataflows.titleListPage')}
      iconSize={'small'}
    />
    <DataFlowExportButton></DataFlowExportButton>
    <DataFlowsFiltersDialog
      onCancel={() => {
        onRefreshData();
      }}
      onSubmit={() => {
        onRefreshData();
      }}
    />
  </Grid>
);

const IconButtonWithConfirmation = withConfirmation(IconButton);

export default _.flow([withTranslation(), withOpenCloseHandler])(DataFlowListToolbar);
