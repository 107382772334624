import { Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import { t } from 'i18next';
import React from 'react';
import {
  PhysicalConstraintName,
  PhysicalEntityName,
  PhysicalFieldName
} from './PhysicalConstraintListItemNames';
import { PhysicalConstraintTargetNames } from './PhysicalConstraintTargetNames';

const PhysicalConstraintsTable = ({ physicalConstraints, hasReferences, isReferenced }) => {
  if (hasReferences) {
    return (
      <Table style={{ overflowX: 'auto' }}>
        <TableHead>
          <TableCell size='small'>{t('physicalConstraints.tableByType.constraintName')}</TableCell>
          <TableCell size='small'>{t('physicalConstraints.tableByType.targetEntities')}</TableCell>
          <TableCell size='small'>
            {t('physicalConstraints.tableByType.referencedEntities')}
          </TableCell>
        </TableHead>
        <TableBody>
          {physicalConstraints.map((pc) => {
            return (
              <>
                <TableRow>
                  <TableCell
                    rowSpan={
                      pc?.physicalEntitiesConstraints?.length +
                      pc?.physicalFieldsConstraints?.length +
                      1
                    }>
                    <PhysicalConstraintName physicalConstraint={pc} />
                  </TableCell>
                </TableRow>

                {pc.physicalEntitiesConstraints?.map((pec) => (
                  <TableRow>
                    <TableCell>
                      <Typography variant='body1'>
                        <PhysicalEntityName physicalEntity={pec.targetPhysicalEntity} />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>
                        <PhysicalEntityName physicalEntity={pec.referencedPhysicalEntity} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                {pc.physicalFieldsConstraints?.map((pfc) => (
                  <TableRow>
                    <TableCell>
                      <Typography variant='body1'>
                        {isReferenced && pfc.targetPhysicalField?.physicalEntity ? (
                          <>
                            <PhysicalEntityName
                              physicalEntity={pfc.targetPhysicalField?.physicalEntity}
                            />
                            {` : `}
                          </>
                        ) : (
                          <></>
                        )}
                        <PhysicalFieldName physicalField={pfc.targetPhysicalField} />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>
                        {!isReferenced && pfc.referencedPhysicalField?.physicalEntity ? (
                          <>
                            <PhysicalEntityName
                              physicalEntity={pfc.referencedPhysicalField?.physicalEntity}
                            />
                            {` : `}
                          </>
                        ) : (
                          <></>
                        )}
                        <PhysicalFieldName physicalField={pfc.referencedPhysicalField} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  return (
    <Table style={{ overflowX: 'auto' }}>
      <TableHead>
        <TableCell size='small'>{t('physicalConstraints.tableByType.constraintName')}</TableCell>
        <TableCell size='small'>{t('physicalConstraints.tableByType.targetEntities')}</TableCell>
      </TableHead>
      <TableBody>
        {physicalConstraints.map((pc) => {
          return (
            <>
              <TableRow>
                <TableCell>
                  <PhysicalConstraintName physicalConstraint={pc} />
                </TableCell>
                <TableCell>
                  <PhysicalConstraintTargetNames physicalConstraint={pc} />
                </TableCell>
              </TableRow>
            </>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default PhysicalConstraintsTable;
