import React from 'react';
import _ from 'lodash';
import withCustomPropertiesDefinitons from './withCustomPropertiesDefinitons';
import CustomPropertyFieldEditor from './CustomPropertyFieldEditor';

function CustomPropertiesEditor({
  resourceType,
  customProperties,
  additionalProperties,
  onChange,
  resource
}) {
  if (!customProperties || customProperties.length <= 0) {
    return null;
  }
  const additionalPropertiesByName = _.groupBy(additionalProperties, 'name');
  return (
    <>
      {customProperties.map((cpd) => (
        <CustomPropertyFieldEditor
          customPropertyDefinition={cpd}
          additionalProperties={additionalPropertiesByName[cpd.name]}
          onChange={(newCustomProperties) => {
            const newAdditionalProperties = _.flatMap({
              ...additionalPropertiesByName,
              [cpd.name]: newCustomProperties
            });
            onChange(newAdditionalProperties);
          }}
        />
      ))}
    </>
  );
}

export default withCustomPropertiesDefinitons(CustomPropertiesEditor);
