import React from 'react';
import AlertDialog from '../alertdialog/AlertDialog';

function withConfirmation(component) {
  return class extends React.Component {
    state = {
      open: false
    };

    render() {
      return (
        <>
          {React.createElement(component, {
            ...this.props,
            onClick: () => this.setState({ open: !this.state.open })
          })}
          <AlertDialog
            open={this.state.open}
            onCancel={() => this.setState({ open: false })}
            onOk={() => {
              this.props.onClick();
              this.setState({ open: false });
            }}
            message={this.props.message || 'Are you sure?'}
          />
        </>
      );
    }
  };
}

export default withConfirmation;
