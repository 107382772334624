import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { modifyPolicy, deletePolicy } from 'actions/IssuesPoliciesActions';
import IconButton from '@mui/material/IconButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Menu,
  MenuItem
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Permissions from 'constants/Permissions';
import Authorize from 'components/permissions/Authorize';
import { connect } from 'react-redux';
import IssuePolicyModal from 'pages/issueMngt/issuesPolicies/detail/IssuePolicyModal';

const IssuesPoliciesTableActions = ({ modifyPolicy, deletePolicy, policy }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [edit, setEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  let { t } = useTranslation();
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  let isOpen = Boolean(anchorEl);
  return (
    <>
      <IconButton
        size='small'
        aria-haspopup='true'
        aria-controls='physical-entities-table-actions'
        onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='physical-entities-table-actions'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {/* <MenuItem
          onClick={(event) => {
            history.push(`/policies/${policy?.uuid}`);
            event.stopPropagation();
          }}>
          {t('commons.actions.detail')}
        </MenuItem> */}
        {isOpen && (
          <Authorize hasPermissions={[Permissions.CAMPAIGNS_EDITOR]}>
            <MenuItem
              onClick={(event) => {
                setEdit(true);
                handleClose(event);
              }}>
              {t('commons.actions.modify')}
            </MenuItem>
          </Authorize>
        )}
        {isOpen && (
          <Authorize hasPermissions={[Permissions.CAMPAIGNS_EDITOR]}>
            <MenuItem onClick={() => setOpenDelete(true)}>{t('commons.actions.delete')}</MenuItem>
          </Authorize>
        )}
      </Menu>
      {edit && (
        <IssuePolicyModal
          open={edit}
          title={`Modify Issue Policy: ${policy.name}`}
          policyToEdit={policy}
          handleSubmit={(policy) => {
            modifyPolicy(policy);
          }}
          handleCancel={() => setEdit(false)}
        />
      )}
      {openDelete && (
        <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
          <DialogContent>
            <DialogContentText>{'Do you want to delete this policy?'}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDelete(false)}>No</Button>
            <Button color='primary' onClick={() => deletePolicy(policy.uuid)}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default connect(null, { modifyPolicy, deletePolicy })(IssuesPoliciesTableActions);
