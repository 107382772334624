/* eslint-disable no-useless-constructor */
import { BlindataAgentApi } from '../BlindataAgentApi';

const AGENT_WEB_ACTION_CONFIGURATIONS_URL = '/agent-api/v1/web-actions/configurations';

export default class AgentWebActionConfigurationsApi extends BlindataAgentApi {
  constructor(agent) {
    super(agent);
  }

  getAgentWebActionConfigurationsPage(params) {
    return this.get(AGENT_WEB_ACTION_CONFIGURATIONS_URL, params);
  }

  getAgentWebActionConfiguration(uuid) {
    return this.get(`${AGENT_WEB_ACTION_CONFIGURATIONS_URL}/${uuid}`);
  }

  postAgentWebActionConfiguration(configuration) {
    return this.post(AGENT_WEB_ACTION_CONFIGURATIONS_URL, configuration);
  }

  putAgentWebActionConfiguration(uuid, configuration) {
    return this.put(`${AGENT_WEB_ACTION_CONFIGURATIONS_URL}/${uuid}`, configuration);
  }

  deleteAgentWebActionConfiguration(uuid) {
    return this.delete(`${AGENT_WEB_ACTION_CONFIGURATIONS_URL}/${uuid}`);
  }

  executeAgentWebActionConfiguration(uuid, executionParams) {
    return this.post(`${AGENT_WEB_ACTION_CONFIGURATIONS_URL}/${uuid}/execute`, executionParams);
  }
}
