import React from 'react';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';

function TaskAssignmentView({ taskAssignment }) {
  return (
    <>
      <KVDefinitionList
        item={{
          'Data Actor': taskAssignment.dataActorName,
          Task: taskAssignment.taskName,
          Role: taskAssignment.role,
          Notes: taskAssignment.notes
        }}
      />
    </>
  );
}

export default TaskAssignmentView;
