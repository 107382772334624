import React from 'react';
import Linkify from 'linkify-react';

/*
 * Linkify Options:
 * rel noreferrer - avoid blindata to be tracked by linked website analitycs
 * rel noopener - avoid reverse tabnabbing
 * target _blank - opens the link in another tab
 */
export default function BdLinkify({ children }) {
  return <Linkify options={{ target: '_blank', rel: 'noopener noreferrer' }}>{children}</Linkify>;
}
