import React from 'react';
import { Typography, Grid, Chip } from '@mui/material';
import {
  greenSemaphoreColor,
  yellowSemaphoreColor,
  redSemaphoreColor
} from 'pages/quality/kqis/quality_checks/commons/QualitySemaphoreColors';

const DataQualityHighlights = ({ greens, yellows, reds }) => {
  const noChecks = greens === 0 && yellows === 0 && reds === 0;
  return (
    <Grid container spacing={1}>
      <Grid item>
        {noChecks ? (
          <ChipPlaceholder />
        ) : greens > 0 ? (
          <GreenChip label={greens} />
        ) : (
          <GreyChip label={greens} />
        )}
      </Grid>
      <Grid item>
        {noChecks ? (
          <ChipPlaceholder label='-' />
        ) : yellows > 0 ? (
          <YellowChip label={yellows} />
        ) : (
          <GreyChip label={yellows} />
        )}
      </Grid>
      <Grid item>
        {noChecks ? (
          <ChipPlaceholder />
        ) : reds > 0 ? (
          <RedChip label={reds} />
        ) : (
          <GreyChip label={reds} />
        )}
      </Grid>
    </Grid>
  );
};

const width = '30px';

const rootStyles = {
  width,
  fontWeight: 'bold',
  color: 'white'
};

const ChipPlaceholder = ({ label }) => (
  <div style={{ width }}>{label && <Typography align='center'>-</Typography>}</div>
);

const GreenChip = ({ label }) => (
  <Chip
    size='small'
    style={{ ...rootStyles, backgroundColor: greenSemaphoreColor }}
    label={label}
  />
);

const YellowChip = ({ label }) => (
  <Chip
    size='small'
    style={{ ...rootStyles, backgroundColor: yellowSemaphoreColor }}
    label={label}
  />
);

const RedChip = ({ label }) => (
  <Chip size='small' style={{ ...rootStyles, backgroundColor: redSemaphoreColor }} label={label} />
);

const GreyChip = ({ label }) => (
  <Chip size='small' style={{ ...rootStyles, backgroundColor: 'lightgrey' }} label={label} />
);

export default DataQualityHighlights;
