import { combineReducers } from 'redux';
import {
  FETCH_DASHBOARD_CONSENTS_LINECHART,
  CLEAR_FILTERS_DASHBOARD_CONSENTS_LINECHART,
  SET_FILTERS_DASHBOARD_CONSENTS_LINECHART
} from '../../constants/ActionTypes';
import { startEndDateParams } from './utils';

const lineChart = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_CONSENTS_LINECHART:
      return action.payload;
    default:
      return state;
  }
};

const _lineChartFiltersInit = {
  processings: [],
  ...startEndDateParams(7),
  hasFilters: false
};

const lineChartFilters = (state = _lineChartFiltersInit, action) => {
  switch (action.type) {
    case SET_FILTERS_DASHBOARD_CONSENTS_LINECHART:
      return { ...state, ...action.payload, hasFilters: true };
    case CLEAR_FILTERS_DASHBOARD_CONSENTS_LINECHART:
      return _lineChartFiltersInit;
    default:
      return state;
  }
};

export default combineReducers({ lineChart, lineChartFilters });
