import React, { useState } from 'react';
import {
  Button,
  Dialog,
  TextField,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material';

function ConfirmButtonWithComment({ message, children, onClick, ...otherProps }) {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');
  return <>
    <Button onClick={() => setOpen(true)} {...otherProps}>
      {children}
    </Button>
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <TextField
          variant="standard"
          helperText='Write your comment here'
          label='Comment'
          value={comment}
          onChange={(event) => setComment(event.target.value)}></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          onClick={() => {
            setOpen(false);
            onClick({ comment: comment || null });
          }}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  </>;
}

export default ConfirmButtonWithComment;
