import { ChipItem } from 'components/chiplist/ChipList';
import { Link as MaterialLink } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { ConstraintTypesIcons } from 'constants/PhysicalConstraintTypes';

export const PhysicalEntityName = ({ physicalEntity }) => {
  return physicalEntity ? (
    <Link
      to={`/physicalentities/${physicalEntity.uuid}`}
      style={{ textDecoration: 'none' }}
      onClick={(event) => event.stopPropagation()}>
      <MaterialLink underline='hover' style={{ color: 'black' }}>
        {physicalEntity.name || ''}
      </MaterialLink>
    </Link>
  ) : (
    <></>
  );
};

export const PhysicalFieldName = ({ physicalField }) => {
  return physicalField ? (
    <Link
      to={`/systems/physical_fields/${physicalField.uuid}`}
      style={{ textDecoration: 'none' }}
      onClick={(event) => event.stopPropagation()}>
      <MaterialLink underline='hover' style={{ color: 'black' }}>
        {physicalField.name || ''}
      </MaterialLink>
    </Link>
  ) : (
    <></>
  );
};
export const PhysicalConstraintName = ({ physicalConstraint }) => {
  return physicalConstraint ? (
    <ChipItem
      icon={ConstraintTypesIcons[physicalConstraint.type] || <></>}
      label={`${physicalConstraint.name || ''}`}
      to={`/physical-constraints/detail/${physicalConstraint.uuid}`}
    />
  ) : (
    <></>
  );
};
