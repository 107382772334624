import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withLoader } from '../../../components/loader/Loader';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Grid
} from '@mui/material';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';
import { downloadRpa } from '../../../actions/RpaUtilsActions';
import { fetchTask, deleteTask, modifyTask } from '../../../actions/TasksActions';
import AssociatedPhysicalEntities from './AssociatedPhysicalEntities';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import TaskEditorModal from '../list/TaskEditorModal';
import TaskAssignments from './TaskAssignments';
import HistoryButtonLink from '../../history/commons/HistoryButtonLink';
import HistoryTargetTypes from '../../history/commons/HistoryTargetTypes';
import TeamCodeDecorator from '../../settings/teams/common/TeamCodeDecorator';
import { ProposalConfirmationButton } from '../../workflows/proposals/commons/withProposalConfirmation';
import AuthorizeTeam from 'components/permissions/AuthorizeTeam';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

import {
  ProposalResourceTypes,
  ProposalVariationTypes
} from '../../workflows/proposals/commons/ProposalConstants';
import CommentsPanel from '../../social/comments/CommentsPanel';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';
import ResourceTypes from '../../../constants/ResourceTypes';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import CardDescription from 'components/carddescription/CardDescription';

const TaskDetail = ({
  task,
  taskAssignments,
  taskUuid,
  downloadRpa,
  modifyTask,
  deleteTask,
  t,
  ...props
}) => {
  const [modifyOpen, setModifyOpen] = React.useState(false);
  return (
    <React.Fragment>
      <Breadcrumbs elements={[{ text: 'Tasks', to: '/tasks' }, { text: task.name }]} />
      <Tracking
        info={{
          pageTitle: `Task: ${task.name}`,
          category: EngagementCategories.TASKS,
          action: EngagementActions.DETAIL
        }}
      />

      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            {t('tasks.headerDetail', { taskName: task.name })}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`${ResourceTypes.TASK}:${task.uuid}`}
            resourceIdentifier={task.uuid}
            resourceType={ResourceTypes.TASK}
            resourceName={task.name}
            pageTitle={`Task: ${task.name}`}></FavoritesWidget>
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.TASK}
            resourceIdentifier={props.processing?.uuid}
            resourceName={props.processing?.name}
          />
        </Grid>
      </Grid>

      <Card>
        <CardHeader
          title={task.name}
          subheader={
            <div>
              <label>{t('tasks.fields.category')}: </label>
              <b>{task.category || t('commons.placeholders.undefined')}</b>
            </div>
          }
          action={<TeamCodeDecorator teamCode={task.teamCode}></TeamCodeDecorator>}
        />
        <CardContent>
          <CardDescription>{task.description}</CardDescription>
          <h4>{t('tasks.sections.businessMetadata')}</h4>
          <KVDefinitionList
            item={{
              [t('tasks.fields.category')]: task.category
            }}
          />

          <h4>{t('tasks.sections.lifecycleDefinition')}</h4>
          <KVDefinitionList
            item={{
              [t('tasks.fields.startDate')]: task.startDate,
              [t('tasks.fields.endDate')]: task.endDate
            }}
          />

          <CustomPropertiesView
            resourceType={ResourceTypes.TASK}
            additionalProperties={task.additionalProperties}
          />
          <Authorize hasPermissions={[Permissions.PROCESSINGS_VIEWER]}>
            <h4>{t('tasks.sections.associatedProcessings')}</h4>
            <ChipWrapper>
              {task.processings.map((p) => (
                <ChipItem
                  key={p.uuid}
                  id={p.uuid}
                  label={p.name}
                  tooltip={p.description}
                  onClick={() => props.history.push(`/processings/${p.uuid}`)}
                />
              ))}
            </ChipWrapper>
          </Authorize>
          <Authorize hasPermissions={[Permissions.TASKS_VIEWER]}>
            <TaskAssignments task={task} />
          </Authorize>
          <Authorize hasPermissions={[Permissions.SYSTEMS_VIEWER]}>
            <AssociatedPhysicalEntities taskUuid={taskUuid} />
          </Authorize>
        </CardContent>
        <CardActions>
          <AuthorizeTeam
            hasAnyPermissions={[Permissions.TASKS_EDITOR, Permissions.PROPOSE]}
            teamCode={task.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE, TeamAccessLevels.PROPOSE]}>
            <>
              <Button children={t('tasks.actions.modify')} onClick={() => setModifyOpen(true)} />
              <TaskEditorModal
                key={task.uuid}
                title={t('tasks.modifyTitle')}
                open={modifyOpen}
                task={task}
                handleSubmit={(task) => {
                  setModifyOpen(false);
                  modifyTask(task);
                }}
                handleCancel={() => setModifyOpen(false)}
              />
            </>
          </AuthorizeTeam>
          <ProposalConfirmationButton
            AuthorizeProps={{
              hasPermissions: [Permissions.TASKS_EDITOR],
              teamCode: task.teamCode,
              teamAccessLevels: [TeamAccessLevels.WRITE, TeamAccessLevels.PROPOSE]
            }}
            message={t('tasks.deleteConfirmationMessage', {
              taskName: task.name
            })}
            ProposeButtonProps={{
              resourceName: task.name,
              resourceType: ProposalResourceTypes.TASK,
              resourceIdentifier: task.uuid,
              variationType: ProposalVariationTypes.DELETE
            }}
            onClick={() => deleteTask(task).then(() => props.history.replace('/tasks'))}>
            {t('tasks.actions.delete')}
          </ProposalConfirmationButton>
          <Button
            onClick={() =>
              downloadRpa({
                uuids: (task.processings || []).map((p) => p.uuid),
                type: 'PDF',
                hasFilters: true
              })
            }>
            {t('tasks.actions.downloadrpa')}
          </Button>
          <HistoryButtonLink
            targetType={[HistoryTargetTypes.TASK, HistoryTargetTypes.TASK_ASSIGNMENT]}
            targetIdentifier={task.uuid}
          />
        </CardActions>
      </Card>
      <CommentsPanel resourceType='TASK' resourceIdentifier={task.uuid}></CommentsPanel>
    </React.Fragment>
  );
};

TaskDetail.propTypes = {
  task: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
  task: state.tasks.contentByUuid[props.match.params['taskUuid']],
  taskUuid: props.match.params['taskUuid'],
  taskAssignments: state.taskAssignments.byTask[props.match.params['taskUuid']] || []
});

export default _.flow([
  withTranslation(),
  withLoader({
    onLoad: ({ taskUuid, fetchTask }) => {
      fetchTask(taskUuid);
    },
    ready: ({ task }) => task
  }),
  connect(mapStateToProps, { fetchTask, modifyTask, deleteTask, downloadRpa })
])(TaskDetail);
