import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { searchConsents } from '../../../actions/ConsentsActions';
import { Typography, Button } from '@mui/material';
import ExpandableCard from '../../../components/expandable-card/ExpandableCard';
import PaginatedPanel from '../../../components/pagination/PaginatedPanel';

const ConsentsSearchResults = (props) => (
  <div>
    {props.currentPage ? (
      <Typography variant='title'>
        Results{' '}
        {props.searchParams.startTimestamp
          ? `from ${dateToString(props.searchParams.startTimestamp)}`
          : ''}{' '}
        {props.searchParams.endTimestamp
          ? `to ${dateToString(props.searchParams.endTimestamp)}`
          : ''}
      </Typography>
    ) : null}
    <PaginatedPanel
      currentPage={props.currentPage}
      onPageSelection={(page) => props.searchConsents(props.searchParams, page)}>
      {props.currentPage && props.currentPage.content.length === 0 ? (
        <Paper style={{ padding: '32px', textAlign: 'center' }}>No results found</Paper>
      ) : null}
      {props.currentPage &&
        props.currentPage.content.map((consent) => (
          <ConsentListItem key={consent.uuid} consent={consent} t={props.t} />
        ))}
    </PaginatedPanel>
  </div>
);

function dateToString(string) {
  return new Date(string).toLocaleDateString();
}

const ConsentListItem = ({ consent, t }) => (
  <ExpandableCard
    key={consent.uuid}
    title={`${consent.consentEvent} by ${consent.dataSubjectKey.name}: ${consent.dataSubjectKey.value}`}
    subheader={`processing: ${consent.processingDisclaimer.processing.name}, disclaimer: ${consent.processingDisclaimer.lang}, version: ${consent.processingDisclaimer.version}, timestamp: ${consent.timestamp}`}
    actions={[
      <Button
        children={t('commons.actions.detail')}
        component={Link}
        to={`/consents/${consent.uuid}`}
      />
    ]}></ExpandableCard>
);

const mapStateToProps = (state) => ({
  currentPage: state.consents.currentPage,
  searchParams: state.consents.search
});

export default withTranslation()(
  connect(mapStateToProps, {
    searchConsents
  })(ConsentsSearchResults)
);
