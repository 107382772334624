import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';
import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { runJobByUuid, runJob } from 'actions/onagent/AgentJobRunnerActions';
import { fetchClassificationAgentJobSettings } from 'actions/ClassificationAgentJobSettingActions';

import ResourceTypes from 'constants/ResourceTypes';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { ClassificationJobConfigsEditor } from './ClassificationAgentJobSettingsModal';
import { withLoader } from 'components/loader/Loader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { fetchAssignmentsPage } from 'actions/AssignmentsActions';
import ClassificationAgentJobResultsTable from '../../settings/agents/commons/ClassificationAgentJobResultsTable';
import AgentItemAutocomplete from 'pages/settings/agents/commons/AgentItemAutocomplete';
function ClassificationAgentJobRunButton({
  onRefreshRequestParameters,
  defaultSettingsList,
  runJob,
  physicalEntity,
  physicalField,
  fetchAssignmentsPage
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [agent, setAgent] = useState(null);
  const [editor, setEditor] = useState(defaultSettingsList[0] || null);

  let resourceIdentifierForSampling = getResourceIdentifierForSampling(
    physicalField,
    physicalEntity
  );
  const handleRun = () => {
    // eslint-disable-next-line no-lone-blocks
    {
      if (loading) return;
      setLoading(true);
      setResult(null);
      let jobDetails = {
        type: editor.type,
        configs: {
          ...editor.configs,

          crawling: { ...resourceIdentifierForSampling },
          outputOptions: {
            ...editor.configs.outputOptions,
            dryRun: false
          }
        }
      };
      runJob(agent, jobDetails).then((response) => {
        setResult(response);
        setLoading(false);
        fetchAssignmentsPage({
          page: 0,
          filters: onRefreshRequestParameters
        });
      });
    }
  };
  return (
    <>
      <Tooltip title='Run classification analysis'>
        <IconButton onClick={() => setOpen(true)} size='large'>
          <PlayCircleOutlineIcon></PlayCircleOutlineIcon>
        </IconButton>
      </Tooltip>
      <Dialog open={open} fullWidth>
        <DialogTitle>
          <Typography variant='h6'>Running Classification</Typography>
        </DialogTitle>
        <DialogContent>
          <AgentItemAutocomplete
            margin='dense'
            value={agent}
            onChange={(agent) => {
              setAgent(agent);
            }}></AgentItemAutocomplete>
          <br></br>
          <SettingsEditor editor={editor} setEditor={setEditor} agent={agent} />
          <ResultLoader loading={loading} result={result} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setResult(null);
            }}>
            {t('commons.actions.cancel')}
          </Button>
          <Button onClick={() => handleRun()}>{t('commons.actions.run')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function SettingsEditor({ editor, setEditor, agent }) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  return (
    <>
      <Grid container direction='row' alignItems='center'>
        <Grid item>
          <Typography variant='body2'>Click to see and modify classification settings</Typography>
        </Grid>
        <Grid item>
          <IconButton size='small' onClick={() => setCollapseOpen(!collapseOpen)}>
            {collapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse
        in={collapseOpen}
        timeout='auto'
        children={
          <ClassificationJobConfigsEditor
            configs={editor.configs}
            agent={agent}
            editor={editor}
            setEditor={setEditor}
            onChange={(configs) =>
              setEditor({ ...editor, configs: configs })
            }></ClassificationJobConfigsEditor>
        }></Collapse>
    </>
  );
}

function ResultLoader({ result, loading }) {
  return (
    <>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          {loading && (
            <CircularProgress style={{ marginLeft: 'calc(50% - 20px)', marginTop: '1.5em' }} />
          )}
          {result && <ClassificationAgentJobResultsTable result={result} />}
        </Grid>
      </Grid>
    </>
  );
}

function getResourceIdentifierForSampling(physicalField, physicalEntity) {
  return physicalField
    ? { physicalEntityUuid: physicalEntity.uuid, physicalFieldUuid: physicalField.uuid }
    : { physicalEntityUuid: physicalEntity.uuid };
}

function mapStateToProps(state, props) {
  return {
    defaultSettingsList:
      state.classification.agentjobsettings.contentBySystemUuid[props.system.uuid]
  };
}
const mapDispatchToProps = {
  fetchAssignmentsPage,
  runJobByUuid,
  runJob,
  fetchClassificationAgentJobSettings
};

export default compose(
  withPermissions({
    hasPermissions: [Permissions.CLASSIFICATION_EDITOR, Permissions.AGENTS_EDITOR]
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchClassificationAgentJobSettings, system }) =>
      fetchClassificationAgentJobSettings({
        resourceIdentifier: system.uuid,
        resourceType: ResourceTypes.SYSTEM
      }),
    ready: ({ defaultSettingsList }) => defaultSettingsList
  })
)(ClassificationAgentJobRunButton);
