import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmIconButton from 'components/confirmbutton/ConfirmIconButton';
import { useDispatch } from 'react-redux';
import { resetSystemRoutine } from 'actions/RoutinesActions';

const RoutineExtractedStatementsDeleteButton = ({ systemRoutineUuid }) => {
  const dispatch = useDispatch();
  return (
    <ConfirmIconButton
      size='small'
      message='Are you sure to delete all the associated statements?'
      onClick={() => {
        dispatch(resetSystemRoutine(systemRoutineUuid));
      }}>
      <DeleteIcon />
    </ConfirmIconButton>
  );
};

export default RoutineExtractedStatementsDeleteButton;
