export const greenSemaphoreColor = "#2BB673"; // green

export const yellowSemaphoreColor = "#e9a227"; //yellow
export const yellowSemaphoreColorLight100 = '#ffffff';
export const yellowSemaphoreColorLight95 = '#fdf5e8';
export const yellowSemaphoreColorLight80 = '#f6d7a2';
export const yellowSemaphoreColorLight65 = '#efb95d';

export const redSemaphoreColor = "#d91e48"; //red
export const redSemaphoreColorLight100 = '#ffffff';
export const redSemaphoreColorLight95 = '#fce9ed';
export const redSemaphoreColorLight85 = '#f6bcc8';
export const redSemaphoreColorLight65 = '#ea627f';