import { FETCH_UNIVERSAL_SEARCH } from '../../constants/ActionTypes';
import { combineReducers } from 'redux';

const content = (state = [], action) => {
  switch (action.type) {
    case FETCH_UNIVERSAL_SEARCH:
      return action.payload.content;
    default:
      return state;
  }
};

export default combineReducers({ content });
