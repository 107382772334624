import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { fetchIssuesPage, createIssue } from 'actions/IssuesActions';
import { fetchPoliciesPage, createPolicy } from 'actions/IssuesPoliciesActions';
import { fetchDashboardTypeCounterIfAllowed } from 'actions/IssueMngtDashboardsActions';
import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IssueIcons from '../detail/IssueIcons';
import IssuesTable from '../list/IssuesTable';
import IssueTypes from '../detail/IssueTypes';
import IssueModal from '../detail/IssueModal';
import withPermissions from 'components/permissions/withPermissions';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import IssuesPoliciesTable from 'pages/issueMngt/issuesPolicies/list/IssuesPoliciesTable';
import IssuePolicyModal from 'pages/issueMngt/issuesPolicies/detail/IssuePolicyModal';

function IssuesResourceWidget({ resourceType, resourceIdentifier, resourceName }) {
  let [open, setOpen] = useState(false);
  if (!resourceIdentifier || !resourceType || !resourceName) {
    return null;
  }
  return (
    <>
      <IssueCounterButton
        onClick={() => setOpen(true)}
        {...{ resourceType, resourceIdentifier, resourceName }}
      />
      <IssuesResourceDialog
        open={open}
        onClose={() => setOpen(false)}
        {...{
          resourceType,
          resourceIdentifier,
          resourceName
        }}></IssuesResourceDialog>
    </>
  );
}

function IssueCounterButton({ onClick, resourceType, resourceIdentifier }) {
  let rdxData = useSelector(
    (state) =>
      state.campaigns.dashboards.issuesTypesCounters[`${resourceType}:${resourceIdentifier}`]
  );

  let dispatch = useDispatch();
  useEffect(
    function () {
      if (!rdxData) {
        dispatch(fetchDashboardTypeCounterIfAllowed({ resourceType, resourceIdentifier }));
      }
    },
    [dispatch, resourceType, resourceIdentifier, rdxData]
  );
  let data = rdxData || {};
  return (
    <Button onClick={onClick}>
      {_.sum(Object.values(data)) > 0 ? (
        <Grid container spacing={2}>
          {issuesOrder.map((type) =>
            data[type] > 0 ? (
              <Grid item>
                <BadgeIssueIcon badgeContent={data[type]} issueType={type}></BadgeIssueIcon>
              </Grid>
            ) : null
          )}
        </Grid>
      ) : (
        <Typography variant='caption'>NO ISSUES</Typography>
      )}
    </Button>
  );
}

function IssuesResourceDialog({ open, onClose, resourceType, resourceIdentifier, resourceName }) {
  let currentPage = useSelector((state) => state.campaigns.issues.currentPage);
  let currentPagePolicies = useSelector((state) => state.campaigns.issuesPolicies.currentPage);
  let currentUser = useSelector((state) => ({
    uuid: state.settings.profile.uuid,
    username: state.settings.profile.username,
    displayName: state.settings.profile.displayName
  }));
  let [loaded, setLoaded] = useState(false);
  let [openAdd, setOpenAdd] = useState(false);
  let [openPolicy, setOpenPolicy] = useState(false);
  let dispatch = useDispatch();
  let handleFetch = useCallback(
    (page, size) =>
      dispatch(
        fetchIssuesPage({
          resourceType,
          resourceIdentifier,
          page,
          size,
          statuses: ['TO_DO', 'IN_PROGRESS', 'REVIEW']
        })
      ),
    [resourceType, resourceIdentifier, dispatch]
  );
  let handlePoliciesFetch = useCallback(
    (page, size) =>
      dispatch(
        fetchPoliciesPage({
          resourceType,
          resourceIdentifier,
          page,
          size
        })
      ),
    [resourceIdentifier, resourceType, dispatch]
  );
  useEffect(
    function () {
      if (open) {
        handleFetch(0, 10);
        handlePoliciesFetch(0, 10).then(() => setLoaded(true));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourceType, resourceIdentifier, open, handleFetch]
  );
  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
      <DialogTitle variant='h4'>Issues</DialogTitle>
      <DialogContent>
        {loaded && (
          <div>
            <IssuesTable
              issues={currentPage.content}
              pagination={{
                page: currentPage.number,
                totalElements: currentPage.totalElements,
                size: currentPage.size
              }}
              handleFetch={handleFetch}
            />
            {currentPagePolicies.content.length > 0 && (
              <div>
                <h2>Policies</h2>
                <IssuesPoliciesTable
                  policies={currentPagePolicies.content}
                  pagination={{
                    page: currentPagePolicies.number,
                    totalElements: currentPagePolicies.totalElements,
                    size: currentPagePolicies.size
                  }}
                  handleFetch={handlePoliciesFetch}></IssuesPoliciesTable>
              </div>
            )}
          </div>
        )}
        {openAdd && (
          <IssueModal
            open={openAdd}
            title='Create an Issue'
            resource={{ resourceIdentifier, name: resourceName, resourceType }}
            reporter={currentUser}
            handleSubmit={(issue) =>
              dispatch(
                createIssue({
                  ...issue,
                  resourceType,
                  resourceIdentifier,
                  resourceName
                })
              )
            }
            handleCancel={() => setOpenAdd(false)}
          />
        )}
        {openPolicy && (
          <IssuePolicyModal
            open={openPolicy}
            title='Create an Issue Policy'
            resource={{ resourceName, resourceIdentifier, resourceType }}
            handleSubmit={(policy) =>
              dispatch(
                createPolicy({
                  resourceType,
                  resourceIdentifier,
                  resourceName,
                  ...policy
                })
              )
            }
            handleCancel={() => setOpenPolicy(false)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Authorize hasPermissions={[Permissions.CAMPAIGNS_EDITOR]}>
          <Button variant='contained' color='primary' onClick={() => setOpenPolicy(true)}>
            Add Policy
          </Button>
        </Authorize>
        <Authorize hasPermissions={[Permissions.CAMPAIGNS_EDITOR]}>
          <Button variant='contained' color='primary' onClick={() => setOpenAdd(true)}>
            Add Issue
          </Button>
        </Authorize>
      </DialogActions>
    </Dialog>
  );
}

const issuesOrder = [
  IssueTypes.ALERT,
  IssueTypes.WARNING,
  IssueTypes.BUG,
  IssueTypes.VULNERABILITY,
  IssueTypes.TASK,
  IssueTypes.FEATURE_REQUEST,
  IssueTypes.QUESTION
];

function BadgeIssueIcon({ badgeContent, issueType }) {
  return (
    <Badge
      badgeContent={badgeContent}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}>
      {IssueIcons[issueType]}
    </Badge>
  );
}

IssuesResourceWidget.propTypes = {
  resourceType: PropTypes.string.isRequired,
  resourceIdentifier: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired
};

export default withPermissions({ hasPermissions: [Permissions.CAMPAIGNS_VIEWER] })(
  IssuesResourceWidget
);
