import _ from 'lodash';
import { UNITS } from './units';

export function getCronStringFromValues(
  period,     // year, month, week, hour, minute
  months,     // number[]
  monthDays,  // number[]
  weekDays,   // number[]
  hours,      // number[]
  minutes     // number[]
) {
  const newMonths = period === 'year' && months ? months : []
  const newMonthDays = (period === 'year' || period === 'month') && monthDays ? monthDays : []
  const newWeekDays = period === 'week' && weekDays ? weekDays : []
  const newHours = period !== 'minute' && period !== 'hour' && hours ? hours : []
  const newMinutes = period !== 'minute' && minutes ? minutes : []

  const secondsPart = '0';
  const minutesPart = parseMinutes(newMinutes);
  const hoursPart = parseHours(newHours);
  const monthDaysPart = parseMonthDays(newMonthDays, newWeekDays);
  const monthsPart = parseMonths(newMonths)
  const weekDaysPart = parseWeekDays(newWeekDays);

  return [secondsPart, minutesPart, hoursPart, monthDaysPart, monthsPart, weekDaysPart].join(' ');
}

const parseMinutes = (values) => {
  const unit = UNITS[0];
  const parsedArray = parsePartArray(values, unit);
  return partToString(parsedArray, unit);
}

const parseHours = (values) => {
  const unit = UNITS[1];
  const parsedArray = parsePartArray(values, unit);
  return partToString(parsedArray, unit);
}

const parseMonthDays = (monthDays, weekDays) => {
  const parsedMonthDays = parsePartArray(monthDays, UNITS[2]);
  const parsedWeekDays = parsePartArray(weekDays, UNITS[4]);
  if (parsedMonthDays.length === 0 && parsedWeekDays.length > 0) {
    return '?';
  }
  return partToString(parsedMonthDays, UNITS[2]);
}

const parseMonths = (values) => {
  const unit = UNITS[3];
  const parsedArray = parsePartArray(values, unit);
  return partToString(parsedArray, unit);
}

const parseWeekDays = (weekDays) => {
  const unit = UNITS[4];
  const parsedWeekDays = parsePartArray(weekDays, unit);
  if (parsedWeekDays.length === 0) {
    return '?';
  }
  return partToString(parsedWeekDays, unit);
}

function parsePartArray(arr, unit) {
  const values = _.uniq(arr).sort((a, b) => a - b);

  if (values.length === 0) {
    return values
  }

  const value = outOfRange(values, unit)

  if (typeof value !== 'undefined') {
    throw new Error(`Value "${value}" out of range for ${unit.type}`)
  }

  return values
}

/**
 * Finds an element from values that is outside of the range of unit
 */
function outOfRange(values, unit) {
  const first = values[0]
  const last = values[values.length - 1]

  if (first < unit.min) {
    return first
  } else if (last > unit.max) {
    return last
  }

  return
}

/**
 * Returns the cron part array as a string.
 */
export function partToString(
  cronPart,
  unit,
) {
  let retval = ''

  if (isFull(cronPart, unit) || cronPart.length === 0) {
    retval = '*'
  } else {
    const step = getStep(cronPart)

    if (step && isInterval(cronPart, step)) {
      if (isFullInterval(cronPart, unit, step)) {
        retval = `*/${step}`
      } else {
        retval = `${getMin(cronPart)}-${getMax(cronPart)}/${step}`
      }
    } else {
      retval = toRanges(cronPart)
        .map(range => {
          if (Array.isArray(range)) {
            return `${range[0]}-${range[1]}`
          }

          return range
        })
        .join(',')
    }
  }
  return retval
}

/**
 * Returns true if range has all the values of the unit
 */
function isFull(values, unit) {
  return values.length === unit.max - unit.min + 1
}

/**
 * Returns the difference between first and second elements in the range
 */
function getStep(values) {
  if (values.length > 2) {
    const step = values[1] - values[0]

    if (step > 1) {
      return step
    }
  }
}

/**
 * Returns true if the range can be represented as an interval
 */
function isInterval(values, step) {
  for (let i = 1; i < values.length; i++) {
    const prev = values[i - 1]
    const value = values[i]

    if (value - prev !== step) {
      return false
    }
  }

  return true
}

/**
 * Returns true if the range contains all the interval values
 */
function isFullInterval(values, unit, step) {
  const min = getMin(values)
  const max = getMax(values)
  const haveAllValues = values.length === (max - min) / step + 1

  if (min === unit.min && max + step > unit.max && haveAllValues) {
    return true
  }

  return false
}

/**
 * Returns the smallest value in the range
 */
function getMin(values) {
  return values[0]
}

/**
 * Returns the largest value in the range
 */
function getMax(values) {
  return values[values.length - 1]
}

/**
 * Returns the range as an array of ranges
 * defined as arrays of positive integers
 */
function toRanges(values) {
  const retval = []
  let startPart = null

  values.forEach((value, index, self) => {
    if (value !== self[index + 1] - 1) {
      if (startPart !== null) {
        retval.push([startPart, value])
        startPart = null
      } else {
        retval.push(value)
      }
    } else if (startPart === null) {
      startPart = value
    }
  })

  return retval
}
