import React, { useReducer, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';

import { connect } from 'react-redux';
import { t } from 'i18next';
import _ from 'lodash';
import { connectQueryStatementToTargetTable } from 'actions/QueryStatementsActions';
import PhysicalEntitySearchModal from 'pages/systems/physical_entities/commons/PhysicalEntitySearchModal';

import { NodesToConnectTable } from '../../../commons/NodesToConnectTable';
import { TargetNodesTable } from '../../../commons/TargetNodesTable';

const QueryStatementTargetTableModal = ({
  title,
  open,
  onClose,
  queryStatement,
  connectQueryStatementToTargetTable,
  endingNodes
}) => {
  const [targetTable, setTargetTable] = useState(null);
  const [searchModalOpen, setSearchModalOpen] = useState(false);

  const disableSaveButton = (table) => {
    if (!table.links) return true;
    return !table.links.reduce((prev, curr) => prev || curr.physicalField, false);
  };

  const initialState = {
    matchStrategy: 'none',
    links: endingNodes
      ? endingNodes.map((endingNode) => {
          return { node: endingNode, physicalField: null };
        })
      : []
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'sortByPositionIndex':
        return {
          ...state,
          matchStrategy: 'sortByPositionIndex',
          links: state.links.map((link) => ({
            node: link.node,
            physicalField: targetTable
              ? _.findLast(
                  targetTable.physicalFields,
                  (pf) => pf.ordinalPosition === link.node.positionIndex
                ) || null
              : null
          }))
        };
      case 'sortByColumnName':
        return {
          ...state,
          matchStrategy: 'sortByColumnName',
          links: state.links.map((link) => ({
            node: link.node,
            physicalField: targetTable
              ? _.findLast(
                  targetTable.physicalFields,
                  (pf) => pf.name.toUpperCase() === link.node.columnName.toUpperCase() || null
                )
              : null
          }))
        };
      case 'addLink':
        let modifiedLinks = state.links;
        modifiedLinks[action.payload.index] = {
          node: modifiedLinks[action.payload.index].node,
          physicalField: action.payload.physicalField
        };
        return {
          ...state,
          matchStrategy: 'none',
          links: modifiedLinks
        };
      default:
        return state;
    }
  }

  const [table, dispatchTableAction] = useReducer(reducer, initialState);

  if (!endingNodes) return <></>;

  return (
    <>
      <Dialog fullWidth open={open} maxWidth={'lg'} onClick={(event) => event.stopPropagation()}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <h4>{t('querystatements.targetTableModal.matchStrategyTitle')}</h4>
          <MatchingStrategySelector
            key={table.matchStrategy}
            matchStrategy={table.matchStrategy}
            dispatchTableAction={dispatchTableAction}
          />
          <br></br>
          <Grid container>
            <Grid
              item
              md={6}
              style={{ borderRight: '1px solid', borderColor: 'lightgray', textAlign: 'center' }}>
              <h2>{t('querystatements.targetTableModal.endingNodesTableTitle')}</h2>
              <NodesToConnectTable
                title={t('querystatements.targetTableModal.endingPointName')}
                nodes={table.links.map((link) => {
                  return { name: link.node.columnName };
                })}
              />
            </Grid>
            <Grid item md={6} style={{ textAlign: 'center' }}>
              <h2>{t('querystatements.targetTableModal.targetTableTitle')}</h2>
              <TargetNodesTable
                targetTable={targetTable}
                physicalFields={table.links.map((link) => link.physicalField)}
                dispatchTableAction={dispatchTableAction}
                setSearchModalOpen={setSearchModalOpen}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              onClose();
              setTargetTable(null);
            }}>
            {t('commons.actions.cancel')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={disableSaveButton(table)}
            onClick={(event) => {
              event.stopPropagation();
              connectQueryStatementToTargetTable(queryStatement.uuid, {
                content: table.links
              });
              onClose();
              setTargetTable(null);
            }}>
            {t('commons.actions.submit')}
          </Button>
        </DialogActions>
      </Dialog>
      <PhysicalEntitySearchModal
        open={searchModalOpen}
        onSubmit={(entity) => {
          setTargetTable(entity);
          setSearchModalOpen(false);
        }}
        onCancel={() => {
          setSearchModalOpen(false);
        }}
      />
    </>
  );
};

const MatchingStrategySelector = ({ dispatchTableAction, matchStrategy }) => (
  <FormControl variant='standard'>
    <RadioGroup
      row
      aria-labelledby='radio-buttons-match-strategy'
      defaultValue={matchStrategy}
      name='radio-buttons-group'>
      <FormControlLabel
        value='sortByPositionIndex'
        control={
          <Radio
            color='primary'
            onChange={() => dispatchTableAction({ type: 'sortByPositionIndex' })}
          />
        }
        label={t('querystatements.targetTableModal.positionIndex')}
      />
      <FormControlLabel
        value='sortByColumnName'
        control={
          <Radio
            color='primary'
            onChange={() => dispatchTableAction({ type: 'sortByColumnName' })}
          />
        }
        label={t('querystatements.targetTableModal.columnName')}
      />
    </RadioGroup>
  </FormControl>
);

export default connect(null, { connectQueryStatementToTargetTable })(
  QueryStatementTargetTableModal
);
