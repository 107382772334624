import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import {
  createCustomRole,
  fetchCustomRolesPage,
  deleteCustomRole
} from '../../../../actions/CustomRolesActions';
import { compose } from 'redux';
import { withLoader } from '../../../../components/loader/Loader';
import ConfirmIconButton from '../../../../components/confirmbutton/ConfirmIconButton';

function CustomRolesActions({
  customRoles,
  permissions,
  createCustomRole,
  deleteCustomRole,
  onApplyPermissions
}) {
  const { t } = useTranslation();
  const [createOpen, setCreateOpen] = useState(false);
  const [applyOpen, setApplyOpen] = useState(false);
  const [customRoleName, setCustomRoleName] = useState('');

  return (
    <>
      <Grid justifyContent='flex-end' container>
        <Grid item>
          <Button
            disabled={!permissions || permissions.length === 0}
            onClick={() => setCreateOpen(true)}>
            Save as role
          </Button>
        </Grid>

        <Grid item>
          <Button onClick={() => setApplyOpen(true)}>Apply a role</Button>
        </Grid>
      </Grid>
      <Dialog open={createOpen}>
        <DialogTitle>Save as role</DialogTitle>
        <DialogContent>
          <TextField
            variant='standard'
            label={t('commons.fields.name')}
            helperText={t('commons.fields.nameHint')}
            value={customRoleName}
            onChange={(event) => setCustomRoleName(event.target.value)}></TextField>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCreateOpen(false);
              setCustomRoleName('');
            }}>
            {t('commons.actions.cancel')}
          </Button>
          <Button
            disabled={!customRoleName}
            onClick={() => {
              createCustomRole({ name: customRoleName, permissions });
              setCreateOpen(false);
            }}>
            {t('commons.actions.submit')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={applyOpen}>
        <DialogTitle>Apply a Role</DialogTitle>
        <DialogContent>
          <List>
            {customRoles.map((cr) => (
              <ListItem
                button
                onClick={() => {
                  onApplyPermissions(cr.permissions);
                  setApplyOpen(false);
                }}>
                <ListItemText
                  primary={cr.name}
                  secondary={`${cr.permissions.length} permissions`}></ListItemText>
                <ListItemSecondaryAction>
                  <ConfirmIconButton
                    onClick={() => deleteCustomRole(cr.uuid)}
                    edge='end'
                    aria-label='Delete'>
                    <DeleteIcon />
                  </ConfirmIconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setApplyOpen(false)}>{t('commons.actions.cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function mapStateToProps(state) {
  return {
    customRoles: state.settings.customRoles.currentPage.content
  };
}

export default compose(
  connect(mapStateToProps, {
    createCustomRole,
    deleteCustomRole,
    fetchCustomRolesPage
  }),
  withLoader({ onLoad: ({ fetchCustomRolesPage }) => fetchCustomRolesPage() })
)(CustomRolesActions);
