import React, { Component } from 'react';
import SystemLoginEditorModal from './SystemLoginEditorModal';
import SystemLoginItem from './SystemLoginsListItem';
import GrantEditorModal from './GrantEditorModal';
import AlertDialog from '../../../components/alertdialog/AlertDialog';
import Pagination from '../../../components/pagination/Pagination';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import { Typography } from '@mui/material';
import { AddFAB } from '../../../components/fab';

export default class SystemLoginsList extends Component {
  state = {
    createOpen: false,
    grantEditorOpen: false,
    grantEditorSystemLogin: undefined,
    deleteOpen: false,
    loginToDelete: undefined,
    editOpen: false,
    loginToEdit: undefined
  };

  componentDidMount() {
    this.props.fetchSystemLoginsPageBySystem(
      this.props.systemUuid,
      this.props.page.number,
      this.props.page.size
    );
    //this.props.requestPhysicalEntities();
  }

  onCreateSystemLogin(systemLogin) {
    systemLogin = { ...systemLogin, system: { uuid: this.props.systemUuid } };
    this.props.createSystemLogin(systemLogin).then(() => this.setState({ createOpen: false }));
  }

  resetDelete() {
    this.setState({ deleteOpen: false, loginToDelete: undefined });
  }

  handleDelete() {
    this.props.deleteSystemLogin(this.state.loginToDelete).then(() => this.resetDelete());
  }

  handleEdit(systemLogin) {
    this.props.modifySystemLogin(systemLogin).then(() => this.resetEdit());
  }

  resetEdit() {
    this.setState({ editOpen: false, loginToEdit: undefined });
  }

  paginationComponent() {
    let { totalPages } = this.props.page;
    if (totalPages <= 1) {
      return null;
    }
    return (
      <Pagination
        total={this.props.page.totalPages}
        current={this.props.page.number + 1}
        display={7}
        onChange={(number) =>
          this.props.fetchSystemLoginsPageBySystem(
            this.props.systemUuid,
            number - 1,
            this.props.page.size
          )
        }
        styleRoot={{ margin: '8px', display: 'flex', justifyContent: 'space-around' }}
      />
    );
  }

  render() {
    let systemName = this.props.system ? this.props.system.name : undefined;
    return (
      <div>
        <Typography variant='h4'>{systemName} System Logins</Typography>

        {this.paginationComponent()}
        {this.props.page.content.map((sl) => (
          <SystemLoginItem
            key={sl.uuid}
            systemLogin={sl}
            grants={this.props.grants[sl.uuid]}
            fetchGrants={this.props.fetchGrants}
            physicalEntities={this.props.physicalEntities}
            onAddGrant={() => this.setState({ grantEditorOpen: true, grantEditorSystemLogin: sl })}
            deleteGrant={this.props.deleteGrant}
            onDelete={() => this.setState({ deleteOpen: true, loginToDelete: sl })}
            onEdit={() => this.setState({ editOpen: true, loginToEdit: sl })}
          />
        ))}
        {this.paginationComponent()}
        <Authorize hasPermissions={[Permissions.SYSTEMS_ADMIN]}>
          <AddFAB onClick={() => this.setState({ createOpen: true })} />
        </Authorize>
        <SystemLoginEditorModal
          title='Create system login'
          open={this.state.createOpen}
          handleSubmit={(dc) => this.onCreateSystemLogin(dc)}
          handleCancel={() => this.setState({ createOpen: false })}
        />
        <SystemLoginEditorModal
          title='Create system login'
          open={this.state.editOpen}
          systemLogin={this.state.loginToEdit}
          handleSubmit={(sl) => this.handleEdit(sl)}
          handleCancel={() => this.resetEdit()}
        />
        <GrantEditorModal
          title={'Create Grant'}
          onSearchPhysicalEntities={(searchText) =>
            this.props.searchPhysicalEntities({ searchText, systemUuid: this.props.systemUuid })
          }
          physicalEntities={this.props.physicalEntities}
          systemLogin={this.state.grantEditorSystemLogin}
          open={this.state.grantEditorOpen}
          handleSubmit={(sl, pe, grt) =>
            this.props
              .createGrant(sl, pe, grt)
              .then(() => this.setState({ grantEditorOpen: false }))
          }
          handleCancel={() => this.setState({ grantEditorOpen: false })}
        />
        <AlertDialog
          open={this.state.deleteOpen}
          message={
            'Do you really want to delete SystemLogin: ' +
            (this.state.loginToDelete ? this.state.loginToDelete.username : '') +
            '?'
          }
          onCancel={() => this.resetDelete()}
          onOk={() => {
            this.handleDelete();
          }}
        />
      </div>
    );
  }
}
