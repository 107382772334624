import { Button } from '@mui/material';
import React, { useState } from 'react';
import { createQueryBucket } from 'actions/QueryBucketsActions';
import { connect } from 'react-redux';
import QueryBucketModal from '../commons/QueryBucketModal';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';
import { useTranslation } from 'react-i18next';

function QueryBucketCloneButton({ queryBucket, createQueryBucket }) {
  const [openClone, setOpenClone] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <Button onClick={() => setOpenClone(true)} children={t('commons.actions.clone')}></Button>
      {openClone && (
        <QueryBucketModal
          title={'Clone Bucket'}
          open={openClone}
          queryBucket={{ ...queryBucket, name: '' }}
          onClose={() => setOpenClone(false)}
          onSubmit={(queryBucket) => {
            createQueryBucket(queryBucket).then((res) => {
              if (res) {
                history.push(`/dataflows/queryparser/buckets/${res.uuid}`);
              }
            });
            setOpenClone(false);
          }}
        />
      )}
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.QUERY_PARSER_EDITOR] }),
  connect(null, { createQueryBucket })
)(QueryBucketCloneButton);
