import { combineReducers } from 'redux'
import {EDIT_DATASUBJECT_REPORT_SELECTION,FETCH_DATASUBJECT_REPORT, CLEAR_DATASUBJECT_REPORT} from '../../constants/ActionTypes'

function selection( state = { name: null, value: null }, action ) {
  switch (action.type) {
    case EDIT_DATASUBJECT_REPORT_SELECTION:
      return { ...state, ...action.payload }
    case CLEAR_DATASUBJECT_REPORT:
      return {name:null,value:null}
    default:
      return state
  }
}

function data( state = null, action ) {
  switch (action.type) {
    case FETCH_DATASUBJECT_REPORT:
      return action.payload;
    case CLEAR_DATASUBJECT_REPORT:
      return null;
    default:
      return state;
  }
}

export default combineReducers({selection,data});