import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import {
  createFavorite,
  modifyFavorite,
  deleteFavorite,
  fetchFavoritesPage
} from 'actions/social/favorites/FavoritesActions';
import { fetchWorskpacesPage, createWorkspace } from 'actions/social/favorites/WorkspaceActions';
import { useRouteMatch } from 'react-router';
import FavoriteWidgetModal from './FavoritesWidgetModal';

function FavoritesWidget({
  createFavorite,
  modifyFavorite,
  deleteFavorite,
  fetchFavoritesPage,
  resourceIdentifier,
  createWorkspace,
  fetchWorskpacesPage,
  resourceType,
  resourceName,
  pageTitle,
  favorite,
  workspaces,
  iconSize,
  itemIdentifier
}) {
  const match = useRouteMatch();

  useEffect(() => {
    fetchWorskpacesPage({});
  }, [fetchWorskpacesPage]);

  useEffect(() => {
    fetchFavoritesPage({ itemIdentifier: itemIdentifier });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFavoritesPage]);

  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [createdFavorite, setCreatedFavorite] = useState();
  const [oldWorkspace, setOldWorkspace] = useState(favorite?.workspace || null);
  const handleClose = () => {
    setOpen(false);
    setCreatedFavorite(null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        size={iconSize || 'medium'}
        aria-controls={'favorite-open-popover'}
        aria-haspopup='true'
        color={favorite ? 'primary' : 'grey'}
        onClick={(event) => {
          !favorite &&
            createFavorite({
              resourceType: resourceType || null,
              resourceName: resourceName || null,
              resourceIdentifier: resourceIdentifier || null,
              pageTitle: pageTitle,
              pagePath: match.url,
              itemIdentifier: itemIdentifier
            }).then((response) => {
              setOldWorkspace(response.workspace);
              setCreatedFavorite(response);
            });
          setOpen(true);
        }}>
        <StarIcon />
      </IconButton>
      {open && (favorite || createFavorite) && (
        <FavoriteWidgetModal
          anchorEl={anchorRef.current}
          handleDelete={() => {
            favorite && deleteFavorite(favorite);
            handleClose();
          }}
          favorite={createdFavorite || favorite}
          id={'favorite-popover'}
          onSubmit={(item) => {
            modifyFavorite(item, favorite?.workspace?.uuid || oldWorkspace.uuid);
            handleClose();
          }}
          createWorkspace={createWorkspace}
          handleClose={handleClose}
          open={open}
          workspaces={workspaces}
          title={createdFavorite ? 'Favorite Added' : 'Edit Favorite'}></FavoriteWidgetModal>
      )}
    </>
  );
}

function mapStateToProps(state, props) {
  return {
    favorite:
      state.social.favorites.contentByItemIdentifier[props.itemIdentifier] &&
      Object.values(state.social.favorites.contentByItemIdentifier[props.itemIdentifier])[0],
    workspaces: state.social.workspace.currentPage.content
  };
}

const mapDispatchToProps = {
  createFavorite,
  deleteFavorite,
  modifyFavorite,
  fetchFavoritesPage,
  fetchWorskpacesPage,
  createWorkspace
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesWidget);
