import React from 'react';
import IssueStatuses from './IssueStatuses';
import { patchIssue } from 'actions/IssuesActions';
import { FormControl, Select, MenuItem } from '@mui/material';
import { connect, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import Permissions from 'constants/Permissions';
import { hasPermissions } from 'components/permissions/permissiosSelectors';

const styles = {
  formControl: {
    margin: (theme) => theme.spacing(1),
    width: '250px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center'
  }
};

function IssueStatesManager({ issue, patchIssue, profile }) {
  const isCampaignAdmin = useSelector(hasPermissions([Permissions.CAMPAIGNS_ADMIN]));
  const isCampaignEditor = useSelector(hasPermissions([Permissions.CAMPAIGNS_EDITOR]));
  const statuses = [
    IssueStatuses.TO_DO,
    IssueStatuses.REVIEW,
    IssueStatuses.DONE,
    IssueStatuses.IN_PROGRESS
  ];

  let { t } = useTranslation();
  return (
    <FormControl variant='outlined' sx={styles.formControl} fullWidth margin='dense' size='small'>
      <Select
        disabled={isDisabled(profile, issue, isCampaignAdmin, isCampaignEditor)}
        variant='outlined'
        labelId='demo-simple-select-filled-label'
        id='demo-simple-select-filled'
        required
        value={issue.issueStatus}
        onChange={(event) => {
          patchIssue(issue.uuid, { issueStatus: event.target.value });
          event.stopPropagation();
        }}
        onClick={(event) => event.stopPropagation()}
        clearable='true'>
        {statuses.map((status, index) => (
          <MenuItem key={index} value={status}>
            {t(`issueMngt.issues.statuses.${status}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function isDisabled(profile, issue, isAdmin, isEditor) {
  if (isAdmin) {
    return false;
  }
  if (!issue?.campaign?.private && isEditor) {
    return false;
  }
  if (profile?.uuid === issue?.assignee?.uuid) {
    return false;
  }
  if (issue?.campaign?.private && issue?.campaign?.owner.uuid === profile?.uuid) {
    return false;
  }
  return true;
}

const mapStateToProps = (state, props) => ({
  profile: state.settings.profile
});

export default connect(mapStateToProps, { patchIssue })(IssueStatesManager);
