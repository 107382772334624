import React, { useCallback, useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import _ from 'lodash';
import { setRoutinesFilters } from 'actions/RoutinesActions';
import { connect } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';

function RoutinesSearchBox({ search, setRoutinesFilters }) {
  const [editor, setEditor] = useState(search || '');

  useEffect(() => {
    if (search !== editor) {
      setEditor(editor || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setEditor]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let onSetFilters = useCallback(_.debounce(setRoutinesFilters, 300), [setRoutinesFilters]);
  return (
    <TextField
      value={editor}
      id='input-with-icon-textfield'
      placeholder='Search...'
      variant='outlined'
      size='small'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='disabled' />
          </InputAdornment>
        )
      }}
      onChange={(event) => {
        setEditor(event.target.value);
        onSetFilters({ search: event.target.value || null });
      }}
    />
  );
}

function mapStateToProps(state) {
  return {
    search: state.systemRoutines.filters.search
  };
}

export default connect(mapStateToProps, { setRoutinesFilters })(RoutinesSearchBox);
