import React from 'react';
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  IconButton,
  ListItemAvatar,
  Icon
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import WorkIcon from '@mui/icons-material/Work';
import GavelIcon from '@mui/icons-material/Gavel';
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ExtensionIcon from '@mui/icons-material/Extension';
import { withTranslation } from 'react-i18next';

const GraphUuidFilter = ({ filter, onDelete, t }) => (
  <ListItem button disableRipple>
    <ListItemAvatar>
      <Avatar style={{ backgroundColor: colors[filter.entityType] }}>
        {icons[filter.entityType]}
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary={filter.name} secondary={t(`resourceType.${filter.entityType}`)} />
    <ListItemSecondaryAction>
      <IconButton aria-label='Delete' onClick={() => onDelete(filter)} size="large">
        <DeleteIcon />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
);

const icons = {
  DATA_ACTOR: <PersonIcon />,
  SYSTEM_LOGIN: <VpnKeyIcon />,
  PHYSICAL_ENTITY: <Icon className='fa fa-table' />,
  TASK: <WorkIcon />,
  PROCESSING: <GavelIcon />,
  DATA_CATEGORY: <FolderIcon />,
  LOGICAL_FIELD: <ExtensionIcon />,
  SYSTEM: <Icon className='fa fa-database' />,
  PHYSICAL_FIELD: <FolderIcon />
};

const colors = {
  DATA_ACTOR: '#FF00CC',
  SYSTEM_LOGIN: '#006d2c',
  PHYSICAL_ENTITY: '#383838',
  TASK: '#ff8e38',
  PROCESSING: '#ffbc38',
  DATA_CATEGORY: '#4248f4',
  LOGICAL_FIELD: '#4a9cad',
  SYSTEM: '#6b6b6b',
  PHYSICAL_FIELD: '#000000'
};

export default withTranslation()(GraphUuidFilter);
