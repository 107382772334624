export const nodeLabels = {
  SYSTEM: 'SYSTEM',
  PHYSICAL_ENTITY: 'PHYSICAL_ENTITY',
  PHYSICAL_FIELD: 'PHYSICAL_FIELD',

  DATA_CATEGORY: 'DATA_CATEGORY',
  LOGICAL_FIELD: 'LOGICAL_FIELD',

  PROCESSING: 'PROCESSING',
  TASK: 'TASK',
  DATA_ACTOR: 'DATA_ACTOR'
};
