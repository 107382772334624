import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

export const _Authorize = ({ permissions, hasPermissions, hasAnyPermissions, children }) => {
  let hostname = window.location.hostname;
  let demoHostnames = ['localhost', 'demo.blindata.io'];
  permissions = demoHostnames.includes(hostname) ? _(permissions).add('DEMO') : permissions;
  const hasPermissionsCheck =
    !hasPermissions || _.every(hasPermissions, (p) => _.includes(permissions, p));
  const hasAnyPermissionsCheck =
    !hasAnyPermissions || _.find(hasAnyPermissions, (p) => _.includes(permissions, p));
  if (hasPermissionsCheck && hasAnyPermissionsCheck) {
    return children;
  } else {
    return null;
  }
};

_Authorize.propTypes = {
  hasAnyPermissions: PropTypes.arrayOf(PropTypes.string),
  hasPermissions: PropTypes.arrayOf(PropTypes.string),
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.element.isRequired
};

const mapStateToProps = (state) => ({
  permissions: state.authentication.permissions
});

export default connect(mapStateToProps)(_Authorize);
