import _ from 'lodash';
import QualityChecksApi from '../../../../api/QualityChecksApi';
import QualitySuitesApi from '../../../../api/QualitySuitesApi';
import QualityResultsApi from 'api/QualityResultsApi';
import AbstractImporter from './AbstractImporter';

export default class ImporterQuality extends AbstractImporter {
  static isSupported(object) {
    return object.qualityCheck || object.qualitySuite || object.qualityResult;
  }

  async importObject(object, resolveUuidOnly = false) {
    if (object.qualityCheck) {
      return this.importQualityCheck(object.qualityCheck, resolveUuidOnly);
    } else if (object.qualitySuite) {
      return this.importQualitySuite(object.qualitySuite, resolveUuidOnly);
    } else if (object.qualityResult) {
      return this.importQualityResult(object.qualityResult, resolveUuidOnly);
    } else {
      throw new Error('Object type not supported by ImporterQuality');
    }
  }

  async importQualityCheck(qualityCheck, resolveUuidOnly = false) {
    let api = new QualityChecksApi();
    let reconciledQualityCheck = qualityCheck;
    if (!resolveUuidOnly) {
      reconciledQualityCheck = {
        ...qualityCheck,
        qualitySuite: await this.importQualitySuite(qualityCheck.qualitySuite, true),
        physicalEntities: qualityCheck?.physicalEntities?.length
          ? await Promise.all(
              qualityCheck.physicalEntities.map((pe) =>
                super.importObject({ physicalEntity: pe }, true)
              )
            )
          : null,
        physicalFields: qualityCheck?.physicalFields?.length
          ? await Promise.all(
              qualityCheck.physicalFields.map((pf) =>
                super.importObject({ physicalField: pf }, true)
              )
            )
          : null
      };
    }
    return this.importTemplate({
      object: reconciledQualityCheck,
      objectName: 'code',
      objectIdentifier: 'uuid',
      api: api,
      search: (params) =>
        api.getQualityChecks({
          ...params,
          suiteUuid: reconciledQualityCheck.qualitySuite && reconciledQualityCheck.qualitySuite.uuid
        }),
      post: api.postQualityCheck,
      put: api.putQualityCheck,
      delet: api.deleteQualityCheck,
      resolveUuidOnly
    });
  }

  async importQualitySuite(qualitySuite, resolveUuidOnly = false) {
    let api = new QualitySuitesApi();
    let reconciledQualitySuite = qualitySuite;
    if (!resolveUuidOnly) {
      reconciledQualitySuite = {
        ...qualitySuite,
        system: qualitySuite.system
          ? await super.importObject({ system: qualitySuite.system }, true)
          : null
      };
    }
    return this.importTemplate({
      object: reconciledQualitySuite,
      objectName: 'name',
      objectIdentifier: 'uuid',
      api,
      search: (params) => api.getQualitySuites(params),
      post: api.postQualitySuite,
      put: api.putQualitySuite,
      delet: api.deleteQualitySuite,
      resolveUuidOnly
    });
  }

  async importQualityResult(qualityResult, resolveUuidOnly) {
    const api = new QualityResultsApi();
    if (resolveUuidOnly) {
      throw new Error('Quality Result update not supported');
    }
    let reconciledQualityResult = qualityResult;
    //this is to suport same headers as massive ingestion api
    if (qualityResult.qualityCheckCode) {
      _.set(reconciledQualityResult, 'qualityCheck.code', qualityResult.qualityCheckCode);
    }
    reconciledQualityResult.qualityCheck = await this.importQualityCheck(
      reconciledQualityResult.qualityCheck,
      true
    );
    return this.importTemplate({
      object: reconciledQualityResult,
      objectName: null,
      objectIdentifier: 'uuid',
      api,
      search: null,
      post: api.postQualityResult,
      put: null,
      delet: api.deleteQualityResult,
      resolveUuidOnly
    });
  }
}
