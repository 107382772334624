import React from 'react';
import PaginatedPanel from 'components/pagination/PaginatedPanel';
import { fetchWebActionsPage } from 'actions/settings/webactions/WebActionsActions';
import { Grid, Typography } from '@mui/material';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import { connect } from 'react-redux';
import WebActionListItem from './WebActionListItem';

function WebActionsList({ fetchWebActionsPage, currentPage }) {
  return (
    <>
      <Grid container alignItems='center' direction='row'>
        <Grid item>
          <Typography variant='h5'>Web Actions</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
        <Grid item>
          <FavoritesWidget itemIdentifier={'page:web-actions-list'} pageTitle={'Web Actions'} />
        </Grid>
      </Grid>
      <PaginatedPanel
        currentPage={currentPage}
        onPageSelection={(page) => fetchWebActionsPage({ page })}
        autoload>
        {currentPage.content.map((webAction) => (
          <WebActionListItem key={webAction.uuid} webAction={webAction} />
        ))}
      </PaginatedPanel>
    </>
  );
}

function mapStateToProps(state, props) {
  return {
    currentPage: state.settings.webActions.currentPage
  };
}

export default connect(mapStateToProps, { fetchWebActionsPage })(WebActionsList);
