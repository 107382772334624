import PropTypes from 'prop-types';
import { fetchTeamListPage } from '../../../../actions/TeamsActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withLoader } from '../../../../components/loader/Loader';
import ItemSelectField from '../../../../components/itemselector/ItemSelectField';

const mapStateToProps = (state, props) => ({
  itemName: 'Team',
  values: Object.values(state.settings.teams.content),
  //value: props.value,
  keyProperty: 'code',
  nameProperty: 'name',
  //onChange: (PropTypes.func.isRequired),
  onSearch: () => null
});

const mapDispatchToProps = {
  fetchTeamListPage
};

const TeamSelector = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({ onLoad: ({ fetchTeamListPage }) => fetchTeamListPage({ size: 50 }) })
)(ItemSelectField);

TeamSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

export default TeamSelector;
