import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import QualityProbesNavbar from 'pages/quality/probes/QualityProbesNavbar';
import QualityProbesConnectionPage from 'pages/quality/probes/connections/list/QualityProbesConnectionsListPage';
import QualityProbesProjectsPage from 'pages/quality/probes/projects/QualityProbesProjectsPage';
import QualityProbesProjectsDetailPage from 'pages/quality/probes/projects/detail/QualityProbesProjectDetailPage';
import QualityProbesDefinitionDetailPage from 'pages/quality/probes/definitions/detail/QualityProbesDefinitionDetailPage';
import QualityProbesTagDetailPage from 'pages/quality/probes/tags/detail/QualityProbesTagDetailPage';

export default function QualityProbeRoutes(props) {
  return (
    <>
      <QualityProbesNavbar />
      <Switch>
        <Route
          exact
          path={`${props.match.url}/connections`}
          component={QualityProbesConnectionPage}
        />
        <Route exact path={`${props.match.url}/projects`} component={QualityProbesProjectsPage} />
        <Route
          exact
          path={`${props.match.url}/projects/:projectUuid`}
          component={QualityProbesProjectsDetailPage}
        />
        <Route
          exact
          path={`${props.match.url}/definitions/:probeRootUuid`}
          component={QualityProbesDefinitionDetailPage}
        />
        <Route
          exact
          path={`${props.match.url}/definitions/:probeRootUuid/versions/:sequenceId`}
          component={QualityProbesDefinitionDetailPage}
        />
        <Route
          exact
          path={`${props.match.url}/tags/:tagUuid`}
          component={QualityProbesTagDetailPage}
        />
        <Redirect to={`${props.match.url}/projects`} />
      </Switch>
    </>
  );
}
