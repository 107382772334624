import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';

import RuleTableHead from './RuleTableHead';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { fetchRulesPage, deleteRule, modifyRule } from '../../../../actions/RulesActions';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RulesModal from '../commons/RulesModal';
import { compose } from 'redux';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import TableCell from 'components/tables/GenericTableCell';
import ConfirmIconButton from 'components/confirmbutton/ConfirmIconButton';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const styles = {
  root: {
    width: '100%'
  },
  table: {
    minWidth: '750px',
    overflowX: 'auto'
  }
};

function RulesTable({
  currentPage,
  page,
  rowsPerPage,
  deleteRule,
  modifyRule,
  fetchRulesPage,
  filters
}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');

  useEffect(() => {
    fetchRulesPage({ page: 0, ...filters });
  }, [filters, fetchRulesPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, currentPage.content.length);
  const rowsSorted = stableSort(currentPage.content, getComparator(order, orderBy));
  return (
    <div style={styles.root}>
      <Card style={{ position: 'relative' }}>
        <CardContent>
          <TableContainer>
            <Table
              sx={styles.table}
              aria-labelledby='tableTitle'
              size={'small'}
              aria-label='enhanced table'>
              <RuleTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {rowsSorted.map((row, index) => {
                  const labelId = `rule-table-checkbox-${index}`;
                  return (
                    <TableRow hover tabIndex={-1} key={row.name}>
                      <TableCell component='th' id={labelId} scope='row' padding='normal'>
                        {row.name}
                      </TableCell>
                      <TableCell align='left'>{row.dataCategoryName || 'Not defined'}</TableCell>
                      <TableCell align='left'>{row.logicalFieldName || 'Not defined'}</TableCell>
                      <TableCell align='left'>{row.type}</TableCell>
                      <TableCell align='right'>{row.weight}</TableCell>
                      <TableCell align='center'>
                        <RulesActionButtons {...{ row, modifyRule, deleteRule }} />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 43 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component='div'
            count={currentPage.totalElements}
            rowsPerPage={rowsPerPage || 10}
            page={page}
            onPageChange={(event, page) =>
              fetchRulesPage({ page: page, size: currentPage.size, ...filters })
            }
            onRowsPerPageChange={(event) =>
              fetchRulesPage({ page: 0, size: event.target.value, ...filters })
            }
          />
        </CardContent>
      </Card>
    </div>
  );
}

function RulesActionButtons({ row, modifyRule, deleteRule }) {
  const [openModal, setOpenModal] = useState(false);
  let history = useHistory();
  let { t } = useTranslation();
  return (
    <>
      <IconButton
        size='small'
        aria-label='detail'
        children={t('commons.actions.detail')}
        component={Link}
        to={`/classification/rules/${row.uuid}`}>
        <InfoIcon />
      </IconButton>
      <Authorize hasPermissions={[Permissions.CLASSIFICATION_EDITOR]}>
        <IconButton size='small' aria-label='edit' onClick={() => setOpenModal(true)}>
          <EditIcon />
        </IconButton>
      </Authorize>
      <RulesModal
        key={row.uuid}
        rule={{ ...row }}
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={(modifiedRule) => {
          modifyRule(modifiedRule).then(() => setOpenModal(false));
        }}></RulesModal>
      <Authorize hasPermissions={[Permissions.CLASSIFICATION_EDITOR]}>
        <ConfirmIconButton
          size='small'
          aria-label='delete'
          onClick={() => {
            deleteRule(row).then(() => history.push('/classification/rules'));
          }}>
          <DeleteIcon />
        </ConfirmIconButton>
      </Authorize>
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.classification.rules.currentPage,
    page: state.classification.rules.currentPage.number,
    rowsPerPage: state.classification.rules.currentPage.size,
    filters: state.classification.rules.filters.content
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRulesPage: (props) => dispatch(fetchRulesPage(props)),
    deleteRule: (rule) => dispatch(deleteRule(rule)),
    modifyRule: (rule) => dispatch(modifyRule(rule))
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(RulesTable);
