import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid, CardHeader, CardActions } from '@mui/material';

import { connect } from 'react-redux';
import { withLoader } from '../../../components/loader/Loader';
import { fetchSystemMetadataMetrics } from '../../../actions/DashboardsActions';
import _ from 'lodash';
import PhysicalFieldsExportButton from '../../../components/exports/PhysicalFieldsExportButton';
import { withTranslation } from 'react-i18next';
import PhysicalEntitiesExportButton from '../physical_entities/utils/PhysicalEntitiesExportButton';

const styles = {
  card: {
    padding: '8px',
    margin: '8px 0 8px 0'
  }
};

const SystemMetadataMetricsPanel = ({ system, systemUuid, metadataMetrics, t }) => (
  <Card sx={styles.card}>
    <CardHeader title='Metadata Metrics' />
    <CardContent>
      <Grid container direction='row' justifyContent='space-around' alignItems='stretch'>
        {metadataMetrics
          ? _.zipWith(metadataMetrics.columns, metadataMetrics.rows, (column, row) => ({
              column,
              row
            })).map(({ column, row }, index) => (
              <Grid item xs={4}>
                <NumberBox
                  label={t(column.label)}
                  number={row[0]}
                  color={numberBoxColors[column.label]}
                />
              </Grid>
            ))
          : null}
      </Grid>
    </CardContent>
    <CardActions>
      <PhysicalEntitiesExportButton system={system}></PhysicalEntitiesExportButton>
      <PhysicalFieldsExportButton system={system}></PhysicalFieldsExportButton>
    </CardActions>
  </Card>
);

const numberBoxColors = {
  'metadataMetrics.logins':
    'linear-gradient(to bottom right, rgba(113, 135, 146, 0.8), rgba(113, 135, 146, 1))',
  'metadataMetrics.physicalEntities':
    'linear-gradient(to bottom right, rgba(69, 90, 100, 0.8), rgba(69, 90, 100, 1))',
  'metadataMetrics.physicalFields':
    'linear-gradient(to bottom right, rgba(28, 49, 58, 0.8), rgba(28, 49, 58, 1))',
  'metadataMetrics.dataActors':
    'linear-gradient(to bottom right, rgba(25, 170, 190, 0.8), rgba(25, 170, 190, 1))',
  'metadataMetrics.dataCategories':
    'linear-gradient(to bottom right, rgba(0, 150, 180, 0.8), rgba(0, 154, 202, 1))',
  'metadataMetrics.logicalFields':
    'linear-gradient(to bottom right, rgba(0, 116, 153, 0.8), rgba(0, 116, 153, 1))'
};

const numberBoxStyles = {
  box: {
    padding: '8px',
    margin: '8px',
    maxWidth: '200px',
    textAlign: 'center',
    borderRadius: '16px',
    boxShadow: '3px 3px 3px 0px #ccc' /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  },
  label: {
    textAlign: 'center',
    fontWeight: '600px',
    color: 'white',
    display: 'block'
  },
  number: {
    textAlign: 'center',
    fontSize: '30px',
    color: 'white',
    display: 'block'
  }
};

const NumberBox = ({ label, number, color }) => (
  <div style={{ ...numberBoxStyles.box, background: color }}>
    <span style={numberBoxStyles.number}>{number}</span>
    <span style={numberBoxStyles.label}>{label}</span>
  </div>
);

const mapStateToProps = (state, props) => ({
  metadataMetrics: state.dashboards.systems.metadataMetrics[props.systemUuid]
});

const mapDispatchToProps = {
  fetchSystemMetadataMetrics
};

SystemMetadataMetricsPanel.propTypes = {
  systemUuid: PropTypes.string.isRequired
};

export default _.flow([
  withTranslation(),
  withLoader({
    onLoad: ({ systemUuid, fetchSystemMetadataMetrics }) =>
      fetchSystemMetadataMetrics({ systemUuid })
  }),
  connect(mapStateToProps, mapDispatchToProps)
])(SystemMetadataMetricsPanel);
