import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { _fetchGraphActually } from 'actions/GraphActions';
import { useDispatch } from 'react-redux';
import { Card, CardContent, CardHeader, CircularProgress } from '@mui/material';
import TableDiagram from 'components/flow/TableDiagram';
import _ from 'lodash';

export default function DataFlowChartPage(props) {
  let [data, setData] = useState(null);
  let dispatch = useDispatch();
  let { resourceType, resourceIdentifier, dataFlowsDepth } = queryString.parse(
    props.location.search
  );
  const parentRef = useRef(null);
  let [size, setSize] = useState(null);
  // useEffect will run on parentRef value assignment
  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (parentRef.current) {
      let height = parentRef.current.offsetHeight;
      let width = parentRef.current.offsetWidth;
      setSize({ height, width });
    }
  }, [parentRef, setSize]);
  useEffect(() => {
    async function fetchData() {
      let graph = await dispatch(
        _fetchGraphActually({
          entityTypes: ['PHYSICAL_ENTITY', 'PHYSICAL_FIELD', 'SYSTEM'],
          physicalEntities: resourceType === 'PHYSICAL_ENTITY' ? [resourceIdentifier] : null,
          physicalFields: resourceType === 'PHYSICAL_FIELD' ? [resourceIdentifier] : null,
          includeDataFlows: true,
          dataFlowsDepth: dataFlowsDepth,
          dataFlowsDepthHierarchy: true
        })
      );
      let styledData = computeStyledGraph({ nodes: graph.nodes, edges: graph.edges });
      setData(styledData);
    }
    fetchData();
  }, [resourceIdentifier, resourceType, dataFlowsDepth, dispatch]);

  return (
    <Card ref={parentRef}>
      <CardHeader title='Data Flow Chart'></CardHeader>
      {!size || !data ? (
        <CircularProgress></CircularProgress>
      ) : (
        <CardContent style={{ overflowX: 'auto', height: 800, width: size.width }} ref={parentRef}>
          <TableDiagram initialNodes={data.nodes} initialEdges={data.edges}></TableDiagram>
        </CardContent>
      )}
    </Card>
  );
}

function computeStyledGraph({ nodes, edges }) {
  const nodeIdGenerator = (node) => {
    if (!node) {
      return;
    }
    if (node.labels.includes('PHYSICAL_ENTITY')) return node.id;
    else if (node.labels.includes('PHYSICAL_FIELD'))
      return _.find(edges, { target: node.id, type: 'REL_PHYSICAL_ENTITY_FIELD' }).source;
    else {
      return node.id;
    }
  };

  let fieldsNodes = nodes.filter((n) => n.labels.includes('PHYSICAL_FIELD'));
  let entityNodes = nodes.filter((n) => n.labels.includes('PHYSICAL_ENTITY'));
  let systemNodes = nodes.filter((n) => n.labels.includes('SYSTEM'));

  let fieldsNodesById = _.keyBy(fieldsNodes, 'id');
  let entityNodesById = _.keyBy(entityNodes, 'id');
  let systemNodesById = _.keyBy(systemNodes, 'id');
  let styledNodes = entityNodes.map((entityNode) => ({
    id: entityNode.id,
    type: 'tableNode',
    data: {
      name: entityNode.data.name,
      schema: entityNode.data.schema,
      ...edges
        .filter((e) => e.type === 'REL_PHYSICAL_ENTITY_SYSTEM' && e.target === entityNode.id)
        .map((e) => systemNodesById[e.source])
        .map((system) => ({ systemUuid: system?.data.uuid, systemName: system?.data.name }))?.[0],
      nodeType: 'SOURCE_TABLE',
      columns: edges
        .filter((e) => e.type === 'REL_PHYSICAL_ENTITY_FIELD' && e.source === entityNode.id)
        .map((e) => fieldsNodesById[e.target])
        .sort((a, b) => a.data.positionIndex - b.data.positionIndex)
        .map((n) => ({ name: n.data.name }))
    }
  }));
  let styledEdges = edges
    .filter((e) => e.type === 'REL_DATA_FLOW')
    .map((e) => ({
      id: e.id,
      source: nodeIdGenerator(
        fieldsNodesById[e.source] || entityNodesById[e.source] || systemNodesById[e.source]
      ),
      sourceHandle: fieldsNodesById[e.source]
        ? `out.${fieldsNodesById[e.source].data.name}`
        : 'out',
      target: nodeIdGenerator(
        fieldsNodesById[e.target] || entityNodesById[e.target] || systemNodesById[e.target]
      ),
      targetHandle: fieldsNodesById[e.target] ? `in.${fieldsNodesById[e.target].data.name}` : 'in',
      animated: true,
      data: { ...e.data }
    }));

  if (systemNodes.length > 0) {
    let styledSystemsNodes = systemNodes
      .filter((n) =>
        _.find(edges, (e) => e.type === 'REL_DATA_FLOW' && (e.source === n.id || e.target === n.id))
      )
      .map((n) => ({
        id: n.id,
        type: 'tableNode',
        data: {
          name: n.data.name,
          systemUuid: n.id,
          nodeType: 'SOURCE_TABLE',
          columns: []
        }
      }));
    styledNodes = styledNodes.concat(styledSystemsNodes);
    let systemEdges = edges
      .filter(
        (e) =>
          e.type === 'REL_PHYSICAL_ENTITY_SYSTEM' && _.find(styledSystemsNodes, { id: e.source })
      )
      .map((e) => ({
        id: e.id,
        type: 'straight',
        source: nodeIdGenerator(
          fieldsNodesById[e.source] || entityNodesById[e.source] || systemNodesById[e.source]
        ),
        sourceHandle: fieldsNodesById[e.source]
          ? `out.${fieldsNodesById[e.source].data.name}`
          : 'out',
        target: nodeIdGenerator(
          fieldsNodesById[e.target] || entityNodesById[e.target] || systemNodesById[e.source]
        ),
        targetHandle: fieldsNodesById[e.target]
          ? `in.${fieldsNodesById[e.target].data.name}`
          : 'in',
        animated: false
      }));
    styledEdges = styledEdges.concat(systemEdges);
  }
  return { nodes: styledNodes, edges: styledEdges };
}
