import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchProcessingLegalBasis } from 'actions/ProcessingsActions';
import AutoComplete from 'components/autocomplete/AutoComplete';

function ProcessingLegalBasisAutoComplete({ fetchProcessingLegalBasis, lb, onChange }) {
  const [legalBasis, setLegalBasis] = useState([]);
  useEffect(() => {
    fetchProcessingLegalBasis({ search: '' }).then((response) => {
      response && setLegalBasis(response.content);
    });
  }, [fetchProcessingLegalBasis, setLegalBasis]);
  return (
    <AutoComplete
      fullWidth
      margin='dense'
      dataSource={legalBasis}
      value={lb}
      label='Legal Basis'
      onUpdateInput={onChange}
      onNewRequest={onChange}></AutoComplete>
  );
}

export default connect(null, { fetchProcessingLegalBasis })(ProcessingLegalBasisAutoComplete);
