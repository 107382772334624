import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QualityCheckModal from './QualityCheckModal';

export default function ModifyAction({ qualityCheck, modifyQualityCheck }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Button children={t('commons.actions.modify')} onClick={() => setOpen(true)}></Button>
      {open && (
        <QualityCheckModal
          open={open}
          qualitySuite={qualityCheck.qualitySuite}
          qualityCheck={qualityCheck}
          onSubmit={(modifiedQualityCheck) => {
            modifyQualityCheck(modifiedQualityCheck);
            setOpen(false);
          }}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
}
