import * as React from "react";
const SvgApachePhoenixLong = (props) => (
  <svg
    viewBox="0 0 562.5 146.7"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <clipPath id="ApachePhoenixLong_svg__a">
        <path d="M0 0h1440v388H0z" />
      </clipPath>
      <clipPath id="ApachePhoenixLong_svg__b">
        <path d="M579 32h54v252h-54z" />
      </clipPath>
      <clipPath id="ApachePhoenixLong_svg__c">
        <path d="M584.1 144c4.297 11.68 3.676 13.37 17.5 35.3 14.54 23.06 26.86 50.93 24.1 104.3 0 0 16.25-45.88-3.199-103-14.26-41.89-31.54-48.24-.859-148.5 0 0-59.54 52.18-37.54 111.9" />
      </clipPath>
      <clipPath id="ApachePhoenixLong_svg__d">
        <path d="M0 0h1440v388H0z" />
      </clipPath>
      <clipPath id="ApachePhoenixLong_svg__f">
        <path d="M552 62h71v230h-71z" />
      </clipPath>
      <clipPath id="ApachePhoenixLong_svg__g">
        <path d="M572.1 199.6s5.95 6.488 11.21 12.41c2.601 2.933 42.2 56.26 38.36 79.2.953-5.555 7.422-52.72-35.08-116.8-17.85-26.91-25.65-72.75-6.109-111.7 0 0-57.71 81.87-8.376 136.9m49.48 92.05c.031-.144.055-.289.082-.437-.035.199-.063.348-.082.437m-.02.114.02-.114c-.008.036-.012.075-.02.114" />
      </clipPath>
      <clipPath id="ApachePhoenixLong_svg__h">
        <path d="M0 0h1440v388H0z" />
      </clipPath>
      <clipPath id="ApachePhoenixLong_svg__j">
        <path d="M538 135h82v158h-82z" />
      </clipPath>
      <clipPath id="ApachePhoenixLong_svg__k">
        <path d="M564.2 236.9c30.06 20.16 47.58 35.67 55.21 55.98 0 0-.664-5.855-4.547-15.48-5.812-14.43-22.01-47.04-45.44-68.08-33.15-29.79-26.61-74.28-26.61-74.28-12.74 74.28 7.453 92.51 21.38 101.9" />
      </clipPath>
      <clipPath id="ApachePhoenixLong_svg__l">
        <path d="M0 0h1440v388H0z" />
      </clipPath>
      <clipPath id="ApachePhoenixLong_svg__n">
        <path d="M443 14h243v365H443z" />
      </clipPath>
      <clipPath id="ApachePhoenixLong_svg__o">
        <path d="M625.9 171.7c26.74 75.7 19.46 118.7-5.586 142.9-9.808 9.496-27.89 22.51-56.19 19.08-28.59-3.461-45.53-18.19-57.4-34.02-18.32-24.41-24.73-56.95-.796-106.2 14-31.41 23-58.39 22.91-58.37 1.734-4.73 3.207-9.453 4.152-14 11.57 2.188 15.68-7.254 15.68-7.254-4.848 1.883-6.469-2.054-6.469-2.054 9.887-4.797 7.254-13.11 7.254-13.11-5.195 3.203-11.11 2.211-11.11 2.211-.27-.203-11.7-.461-12.22-.75-21.54-15.97-35.93-12.26-44.27-6.18-2.106 1.418-3.664 2.871-4.809 4.34-2.879 3.192-4.258 5.953-4.258 5.953s-21.3 3.422-27.98 16.84c0 0 13.98-9.058 27.46-5.316 1.457 2.515 7.937 6.32 13.4 19.48 2.672 6.43-.109 21.5-3.398 27.64-17.93 33.45-39.15 56.73-39.15 90.72 0 30.25 10.78 57.98 28.69 79.58 36.77 45.17 95.6 45.17 96.07 45.17 68.9 0 117.3-55.85 117.3-124.8 0-14.36-2.41-32.14-4.449-40.96-15.66-59.53-51.72-109.5-.965-197.9 0 0-97.62 33.1-53.89 156.9" />
      </clipPath>
      <clipPath id="ApachePhoenixLong_svg__p">
        <path d="M0 0h1440v388H0z" />
      </clipPath>
      <linearGradient
        id="ApachePhoenixLong_svg__e"
        x2={1}
        gradientTransform="matrix(-47.61 2.111 2.111 47.61 628.7 157.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          style={{
            stopColor: "#f7931d",
          }}
          offset={0}
        />
        <stop
          style={{
            stopColor: "#ed1c24",
          }}
          offset={0.559}
        />
        <stop
          style={{
            stopColor: "#ed1c24",
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        id="ApachePhoenixLong_svg__i"
        x2={1}
        gradientTransform="matrix(-64.63 2.866 2.866 64.63 618.1 176.4)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          style={{
            stopColor: "#f7931d",
          }}
          offset={0}
        />
        <stop
          style={{
            stopColor: "#ed1c24",
          }}
          offset={0.559}
        />
        <stop
          style={{
            stopColor: "#ed1c24",
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        id="ApachePhoenixLong_svg__m"
        x2={1}
        gradientTransform="matrix(-75.5 3.348 3.348 75.5 615.8 212.4)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          style={{
            stopColor: "#f7931d",
          }}
          offset={0}
        />
        <stop
          style={{
            stopColor: "#ed1c24",
          }}
          offset={0.559}
        />
        <stop
          style={{
            stopColor: "#ed1c24",
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        id="ApachePhoenixLong_svg__q"
        x2={1}
        gradientTransform="translate(443.1 196.6) scale(242.1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          style={{
            stopColor: "#f7931d",
          }}
          offset={0}
        />
        <stop
          style={{
            stopColor: "#ed1c24",
          }}
          offset={0.559}
        />
        <stop
          style={{
            stopColor: "#ed1c24",
          }}
          offset={1}
        />
      </linearGradient>
    </defs>
    <g
      clipPath="url(#ApachePhoenixLong_svg__a)"
      transform="matrix(.3987 0 0 .386 -4.566 -2.124)"
    >
      <path
        d="M108.2 270c39.39 0 65.2-21.05 65.2-52.29v-.683c0-33.95-25.47-51.61-63.84-51.61h-60.1v104.6zM22.63 140.6h88.96c53.65 0 88.96 28.52 88.96 75.38v.676c0 51.28-42.78 77.76-93.38 77.76H49.44v83.88H22.62zM222.3 140.7h26.82v105.6h137.2V140.7h26.82v237.7h-26.82v-107h-137.2v107H222.3zM717.4 140.7h171.8v24.45h-145v81.16h129.7v24.44H744.2v83.2h146.7v24.45H717.4zM917.8 140.7h25.13l149.7 190.5V140.7h26.14v237.7h-21.39l-153.5-194.9v194.9h-26.14zM1148 140.7h26.82v237.7H1148zM1413 140.7l-87.6 116.5 91 121.2h-31.92l-76.06-102.5-76.06 102.5h-30.9l91-121.2-87.6-116.5h31.58l72.66 97.79 72.67-97.79zM70.52 78.84 55.81 45.9 41 78.84zM52.48 37.38h6.848l29.52 65.25h-7.864l-7.589-17.12h-35.27l-7.676 17.12h-7.504zM124.9 73.1c10.74 0 17.77-5.739 17.77-14.25v-.184c0-9.262-6.942-14.07-17.41-14.07h-16.38v28.51zm-23.32-35.26h24.24c14.63 0 24.25 7.777 24.25 20.54v.191c0 13.97-11.66 21.19-25.45 21.19h-15.73v22.86h-7.309zM199 78.84 184.29 45.9l-14.81 32.94zm-18.05-41.46h6.855l29.52 65.25h-7.871l-7.586-17.12h-35.26l-7.684 17.12h-7.492zM223.4 70.41v-.184c0-18.32 13.7-33.51 32.77-33.51 11.76 0 18.78 4.164 25.26 10.28l-4.988 5.367c-5.465-5.179-11.57-8.887-20.37-8.887-14.34 0-25.08 11.66-25.08 26.57v.183c0 15 10.83 26.75 25.08 26.75 8.887 0 14.72-3.426 20.91-9.344l4.813 4.719c-6.75 6.851-14.16 11.39-25.91 11.39-18.69 0-32.49-14.72-32.49-33.33M294.6 37.84h7.309v28.78h37.39V37.84h7.321v64.78h-7.321V73.48h-37.39v29.14H294.6zM364.9 37.84h46.83v6.664h-39.52v22.12h35.36v6.668h-35.36v22.67h39.99v6.66h-47.3z"
        style={{
          fill: "#231f20",
        }}
      />
    </g>
    <g
      clipPath="url(#ApachePhoenixLong_svg__b)"
      transform="matrix(.3987 0 0 .386 -4.566 -2.124)"
    >
      <g clipPath="url(#ApachePhoenixLong_svg__c)">
        <g clipPath="url(#ApachePhoenixLong_svg__d)">
          <path
            d="M584.1 144c4.297 11.68 3.676 13.37 17.5 35.3 14.54 23.06 26.86 50.93 24.1 104.3 0 0 16.25-45.88-3.199-103-14.26-41.89-31.54-48.24-.859-148.5 0 0-59.54 52.18-37.54 111.9"
            style={{
              fill: "url(#ApachePhoenixLong_svg__e)",
            }}
          />
        </g>
      </g>
    </g>
    <g
      clipPath="url(#ApachePhoenixLong_svg__f)"
      transform="matrix(.3987 0 0 .386 -4.566 -2.124)"
    >
      <g clipPath="url(#ApachePhoenixLong_svg__g)">
        <g clipPath="url(#ApachePhoenixLong_svg__h)">
          <path
            d="M572.1 199.6s5.95 6.488 11.21 12.41c2.601 2.933 42.2 56.26 38.36 79.2.953-5.555 7.422-52.72-35.08-116.8-17.85-26.91-25.65-72.75-6.109-111.7 0 0-57.71 81.87-8.376 136.9m49.48 92.05c.031-.144.055-.289.082-.437-.035.199-.063.348-.082.437m-.02.114.02-.114c-.008.036-.012.075-.02.114"
            style={{
              fill: "url(#ApachePhoenixLong_svg__i)",
            }}
          />
        </g>
      </g>
    </g>
    <g
      clipPath="url(#ApachePhoenixLong_svg__j)"
      transform="matrix(.3987 0 0 .386 -4.566 -2.124)"
    >
      <g clipPath="url(#ApachePhoenixLong_svg__k)">
        <g clipPath="url(#ApachePhoenixLong_svg__l)">
          <path
            d="M564.2 236.9c30.06 20.16 47.58 35.67 55.21 55.98 0 0-.664-5.855-4.547-15.48-5.812-14.43-22.01-47.04-45.44-68.08-33.15-29.79-26.61-74.28-26.61-74.28-12.74 74.28 7.453 92.51 21.38 101.9"
            style={{
              fill: "url(#ApachePhoenixLong_svg__m)",
            }}
          />
        </g>
      </g>
    </g>
    <g
      clipPath="url(#ApachePhoenixLong_svg__n)"
      transform="matrix(.3987 0 0 .386 -4.566 -2.124)"
    >
      <g clipPath="url(#ApachePhoenixLong_svg__o)">
        <g clipPath="url(#ApachePhoenixLong_svg__p)">
          <path
            d="M625.9 171.7c26.74 75.7 19.46 118.7-5.586 142.9-9.808 9.496-27.89 22.51-56.19 19.08-28.59-3.461-45.53-18.19-57.4-34.02-18.32-24.41-24.73-56.95-.796-106.2 14-31.41 23-58.39 22.91-58.37 1.734-4.73 3.207-9.453 4.152-14 11.57 2.188 15.68-7.254 15.68-7.254-4.848 1.883-6.469-2.054-6.469-2.054 9.887-4.797 7.254-13.11 7.254-13.11-5.195 3.203-11.11 2.211-11.11 2.211-.27-.203-11.7-.461-12.22-.75-21.54-15.97-35.93-12.26-44.27-6.18-2.106 1.418-3.664 2.871-4.809 4.34-2.879 3.192-4.258 5.953-4.258 5.953s-21.3 3.422-27.98 16.84c0 0 13.98-9.058 27.46-5.316 1.457 2.515 7.937 6.32 13.4 19.48 2.672 6.43-.109 21.5-3.398 27.64-17.93 33.45-39.15 56.73-39.15 90.72 0 30.25 10.78 57.98 28.69 79.58 36.77 45.17 95.6 45.17 96.07 45.17 68.9 0 117.3-55.85 117.3-124.8 0-14.36-2.41-32.14-4.449-40.96-15.66-59.53-51.72-109.5-.965-197.9 0 0-97.62 33.1-53.89 156.9"
            style={{
              fill: "url(#ApachePhoenixLong_svg__q)",
            }}
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgApachePhoenixLong;
