import React from 'react';
import { ListSubheader, MenuItem, Typography, Divider, ListItemIcon } from '@mui/material';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import withConfirmation from '../../../components/confirmbutton/withConfirmation';
import { deleteDataFlow } from '../../../actions/DataFlowsActions';
import { removeEdge } from '../../../actions/GraphActions';
import { useTranslation } from 'react-i18next';
import { OpenInBrowser, DeleteOutlineOutlined } from '@mui/icons-material';
import { createUrlItem } from 'pages/Routes';

function ContextualMenuEdgeContent({ edge, history, onClose, graphHandler }) {
  return (
    <>
      <ListSubheader variant='caption'>
        {_.get(edge, 'data.name') || _.get(edge, 'type')}
      </ListSubheader>
      {createDescription(edge)}
      {createDetailLink(edge, history)}
      {_.get(edge, 'type') === 'REL_DATA_FLOW' ? (
        <DeleteDataFlow {...{ edge, graphHandler, onClose }} />
      ) : null}
    </>
  );
}

function createDescription(edge) {
  let description = _.get(edge, 'data.description');
  if (!description) return null;
  return (
    <>
      <MenuItem dense button={false}>
        <Typography
          variant='body2'
          style={{
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            hyphens: 'auto'
          }}>
          {description}
        </Typography>
      </MenuItem>
      <Divider />
    </>
  );
}

function createDetailLink(edge, history) {
  if (!edge) return null;
  let url = createUrlItem(edge.type, edge.data.uuid);
  if (!url) return null;
  return (
    <MenuItem dense onClick={() => history.push(url)}>
      <ListItemIcon>
        <OpenInBrowser />
      </ListItemIcon>
      Detail
    </MenuItem>
  );
}

function DeleteDataFlow({ edge, graphHandler, onClose }) {
  let [t] = useTranslation();
  let dispatch = useDispatch();
  if (!_.get(edge, 'data.uuid')) return null;
  return (
    <MenuItemWithConfirmation
      onClick={() => {
        dispatch(deleteDataFlow(edge.data))
          .then(onClose)
          .then(dispatch(removeEdge(edge, graphHandler)));
      }}>
      <ListItemIcon>
        <DeleteOutlineOutlined />
      </ListItemIcon>
      {t('commons.actions.delete')}
    </MenuItemWithConfirmation>
  );
}

const MenuItemWithConfirmation = withConfirmation(MenuItem);

export default ContextualMenuEdgeContent;
