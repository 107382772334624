import { buildApiCallAction } from './ActionUtils';
import {
  FETCH_POLICYS_PAGE,
  CREATE_POLICY,
  MODIFY_POLICY,
  DELETE_POLICY,
  EXPORT_POLICIES
} from 'constants/ActionTypes';
import IssuesPoliciesApi from 'api/IssuesPoliciesApi';

const api = new IssuesPoliciesApi();

export const fetchPoliciesPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getPolicies(params),
    actionType: FETCH_POLICYS_PAGE
  });

export const createPolicy = (policy) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postPolicy(policy),
    actionType: CREATE_POLICY
  });

export const modifyPolicy = (policy) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putPolicy(policy),
    actionType: MODIFY_POLICY
  });

export const deletePolicy = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deletePolicy(uuid),
    actionType: DELETE_POLICY,
    payload: { uuid }
  });

export const exportPolicies = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportPolicies(params),
    actionType: EXPORT_POLICIES
  });
