import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ItemSelectField from '../../components/itemselector/ItemSelectField';
import ItemAutoComplete from '../../components/itemselector/ItemAutoComplete';
import { DialogContent, DialogTitle, DialogActions } from '@mui/material';

export default class TaskGrantEditorModal extends Component {
  defaultState = {
    uuid: undefined,
    task: undefined,
    systemLogin: undefined
  };

  state = this.defaultState;

  onSubmit() {
    let taskGrant = {
      uuid: this.state.uuid,
      task: this.state.task,
      systemLogin: this.state.systemLogin
    };
    this.props.handleSubmit(taskGrant);
  }

  render() {
    const actions = [
      <Button children='Cancel' onClick={this.props.handleCancel} />,
      <Button
        children='Submit'
        variant='contained'
        color='primary'
        disabled={this.state.task === undefined || this.state.systemLogin === undefined}
        onClick={() => this.onSubmit()}
      />
    ];
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>{this.props.title}</DialogTitle>

        <DialogContent>
          <ItemSelectField
            itemName='Task'
            keyProperty='uuid'
            nameProperty='name'
            value={this.state.task}
            values={this.props.tasks}
            onChange={(task) =>
              this.setState({
                task: task
              })
            }
          />

          <ItemAutoComplete
            title='Assign login'
            itemName='Login'
            keyProperty='uuid'
            nameProperty='username'
            categoryProperties={['system', 'name']}
            labelSeparator={' > '}
            value={this.state.systemLogin}
            values={this.props.systemLogins}
            onSearch={(searchText) => this.props.searchSystemLogins({ searchText })}
            onChange={(systemLogin) =>
              this.setState({
                systemLogin: systemLogin
              })
            }
          />
        </DialogContent>

        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

TaskGrantEditorModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  tasks: PropTypes.array.isRequired,
  systemLogins: PropTypes.array.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  searchSystemLogins: PropTypes.func.isRequired
};
