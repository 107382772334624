import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchCampaignsPage } from 'actions/CampaignActions';

import Permissions from 'constants/Permissions';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

import { Grid, Typography, Tabs, Tab } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import Authorize from 'components/permissions/Authorize';

import CampaignsList from 'pages/issueMngt/campaigns/list/CampaignsList';
import CampaignFAB from 'pages/issueMngt/campaigns/list/CampaignFAB';
import CampaignExportButton from './CampaignExportButton';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const CampaignsListPage = ({ currentPage, fetchCampaignsPage }) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    fetchCampaignsPage({ page: 0, status: newValue === 0 ? 'ACTIVE' : 'NONACTIVE' });
  };
  const handlePageChange = (page) => {
    fetchCampaignsPage({ page, status: selectedTab === 0 ? 'ACTIVE' : 'NONACTIVE' });
  };

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('navbar.issueMngt'), to: '/issue-management/my-work' },
          { text: t('navbar.campaigns') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('issueMngt.campaigns.header'),
          category: EngagementCategories.CAMPAIGNS,
          action: EngagementActions.LIST
        }}
      />

      <Grid container justifyContent='flex-start' alignItems='center'>
        <Grid item>
          <Typography variant='h4'>{t('issueMngt.campaigns.header')}</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:campaign-list'}
            iconSize={'small'}
            pageTitle={t('issueMngt.campaigns.header')}
          />
        </Grid>
        <Grid item>
          <CampaignExportButton></CampaignExportButton>
        </Grid>
        <Grid item>
          <Tabs value={selectedTab} onChange={handleTabChange} textColor='primary'>
            <Tab label={t('issueMngt.campaigns.tabs.active')} />
            <Tab label={t('issueMngt.campaigns.tabs.nonactive')} />
          </Tabs>
        </Grid>
      </Grid>
      <CampaignsList currentPage={currentPage} handlePageChange={handlePageChange} />
      <Authorize hasPermissions={[Permissions.CAMPAIGNS_ADMIN]}>
        <CampaignFAB />
      </Authorize>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentPage: state.campaigns.campaigns.currentPage
});

const mapDispatchToProps = {
  fetchCampaignsPage
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsListPage);
