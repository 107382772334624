import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchCustomPropertyDefinitionsPage } from '../../actions/CustomPropertiesActions';
import { withLoader } from '../loader/Loader';

const mapStateToProps = (state, { resourceType }) => ({
  customProperties: state.settings.customPropertiesDefinitions.currentPage.content.filter(
    (cdp) => resourceType === cdp.resourceType
  ),
  loaded: state.settings.customPropertiesDefinitions.loaded
});

const mapDispatchToProps = { fetch: fetchCustomPropertyDefinitionsPage };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetch }) => fetch(),
    ready: ({ loaded }) => loaded
  })
);
