import RoutinesApi from 'api/RoutinesApi';
import AbstractImporter from './AbstractImporter';

export default class ImporterRoutine extends AbstractImporter {
  static isSupported(object) {
    return object.systemRoutine;
  }

  async importObject(object, resolveUuidOnly = false) {
    if (object.systemRoutine) {
      return this.importRoutine(object.systemRoutine, resolveUuidOnly);
    } else {
      throw new Error('Object type not supported by ImporterSystemRoutine');
    }
  }

  async importRoutine(systemRoutine, resolveUuidOnly = false) {
    let api = new RoutinesApi();
    let reconciledSystemRoutine = systemRoutine;
    if (!resolveUuidOnly) {
      reconciledSystemRoutine = {
        ...systemRoutine,
        system: systemRoutine.system
          ? await super.importObject({ system: systemRoutine.system }, true)
          : null
      };
    }
    return this.importTemplate({
      object: reconciledSystemRoutine,
      objectName: null,
      objectIdentifier: 'uuid',
      api: api,
      post: api.postRoutine,
      put: api.putRoutine,
      delet: api.deleteRoutine,
      resolveUuidOnly
    });
  }
}
