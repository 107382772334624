import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import React, { useReducer, useState } from 'react';
import FilterList from '@mui/icons-material/FilterList';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  clearPhysicalConstraintsFilters,
  setPhysicalConstraintsFilters
} from 'actions/PhysicalConstraintsActions';
import { t } from 'i18next';
import AutoComplete from 'components/autocomplete/AutoComplete';
import SystemItemAutoComplete from 'pages/systems/commons/SystemItemAutoComplete';
import { ConstraintTypes } from 'constants/PhysicalConstraintTypes';
import _ from 'lodash';
import PhysicalConstraintSchemaAutoComplete from '../commons/PhysicalConstraintSchemaAutoComplete';

const PhysicalConstraintsFilters = () => {
  const [open, setOpen] = useState(false);

  const filters = useSelector((state) => state.physicalconstraints.filters.content);
  const hasFilters = useSelector((state) => state.physicalconstraints.filters.hasFilters);

  const dispatch = useDispatch();

  return (
    <>
      <IconButton
        size='small'
        color={hasFilters ? 'primary' : 'grey'}
        style={{ float: 'right' }}
        onClick={() => setOpen(true)}
        children={<FilterList />}></IconButton>
      {open && (
        <PhysicalConstraintsFiltersModal
          open={open}
          filters={filters}
          onClear={() => {
            setOpen(false);
            dispatch(clearPhysicalConstraintsFilters());
          }}
          onSubmit={(filters) => {
            setOpen(false);
            dispatch(setPhysicalConstraintsFilters(filters));
          }}
        />
      )}
    </>
  );
};

const PhysicalConstraintsFiltersModal = ({ open, onSubmit, onClear, filters }) => {
  const [editor, setEditor] = useReducer((s, a) => ({ ...s, ...a }), filters);

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>Physical Constraints Filters</DialogTitle>
      <DialogContent>
        <SystemItemAutoComplete
          value={editor.system}
          onChange={(system) => setEditor({ ...editor, system: system })}
        />
        <PhysicalConstraintSchemaAutoComplete
          value={editor.schema || ''}
          onChange={(value) => setEditor({ ...editor, schema: value?.schema })}
        />
        <AutoComplete
          fullWidth
          label='Type'
          dataSource={Object.keys(ConstraintTypes)}
          value={_.head(editor.constraintTypes) || _.head(editor.constraintTypesNot) || ''}
          showAllOptions
          onUpdateInput={(type) =>
            setEditor({
              ...editor,
              ...(type === 'OTHERS'
                ? {
                    constraintTypesNot: Object.keys(ConstraintTypes).filter((e) => e !== 'OTHERS'),
                    constraintTypes: []
                  }
                : { constraintTypesNot: [], constraintTypes: [type] })
            })
          }
          onChange={(type) => setEditor({ ...editor, ...{ type: type } })}
        />
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.clear')} onClick={() => onClear()} />
        <Button
          variant='outlined'
          color='primary'
          children={t('commons.actions.apply')}
          onClick={() => {
            onSubmit(editor);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default PhysicalConstraintsFilters;
