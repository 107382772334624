import React from 'react';

import { UNITS } from '../utils/units';

import CustomSelect from './CustomSelect';

const Minutes = ({ value, setValue, selectedPeriod }) => {
  return (
    <>
      {selectedPeriod === 'hour' ? <span>at</span> : <span>:</span>}
      <CustomSelect unit={UNITS[0]} value={value} setValue={setValue} />
      {selectedPeriod === 'hour' && <span>minute(s)</span>}
    </>
  );
};

export default Minutes;
