import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import TextFieldsListEditor from '../../../components/list_editors/TextFieldsListEditor'
import {loadSuggestions} from '../../../components/suggestions/SuggestionsUtils'

const PurposesFormSection = ({ t, purposes, dataCategories, onAdd, onDelete }) => (
  <TextFieldsListEditor
    headerTitle={t('processings.purposes.header')}
    addLabel={t('processings.purposes.actions.add')}
    items={purposes}
    onAdd={onAdd}
    onDelete={onDelete}
    textFields={[{
      hintText: t('processings.purposes.fields.nameHint'),
      floatingLabelText: t('processings.purposes.fields.name'),
      property: 'name',
      suggestions: loadSuggestions(t)('suggestion:purposes',dataCategories),
      enabler: true
    }, {
      hintText: t('processings.purposes.fields.descriptionHint'),
      floatingLabelText: t('processings.purposes.fields.description'),
      property: 'description'
    }]}
  />
)


PurposesFormSection.propTypes = {
  purposes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string
  })).isRequired
}

export default withTranslation()(PurposesFormSection)