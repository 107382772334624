import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import withConfirmation from './withConfirmation';

const ConfirmIconButton = withConfirmation(IconButton);

ConfirmIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  message: PropTypes.string
};

export default ConfirmIconButton;
