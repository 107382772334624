import { combineReducers } from 'redux';
import {
  FETCH_DASHBOARD_CONSENTS_COLUMNCHART,
  SET_FILTERS_DASHBOARD_CONSENTS_COLUMNCHART,
  CLEAR_FILTERS_DASHBOARD_CONSENTS_COLUMNCHART
} from '../../constants/ActionTypes';
import { startEndDateParams } from './utils';

const columnChart = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_CONSENTS_COLUMNCHART:
      return action.payload;
    default:
      return state;
  }
};

const _columnChartFiltersInit = {
  processings: [],
  ...startEndDateParams(7),
  hasFilters: false
};

const columnChartFilters = (state = _columnChartFiltersInit, action) => {
  switch (action.type) {
    case SET_FILTERS_DASHBOARD_CONSENTS_COLUMNCHART:
      return { ...state, ...action.payload, hasFilters: true };
    case CLEAR_FILTERS_DASHBOARD_CONSENTS_COLUMNCHART:
      return _columnChartFiltersInit;
    default:
      return state;
  }
};
export default combineReducers({ columnChart, columnChartFilters });
