import React from 'react';
import GraphCanvas from './GraphCanvas';
import GraphFilters from './filters/GraphFilters';

const Graph = props => {
  if (props.submitted) {
    return <GraphCanvas {...props} />;
  } else {
    return <GraphFilters {...props} />;
  }
};

export default Graph;
