import React from 'react';
import { showRelatedNodes } from './GraphFunctions';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';

function GraphMenuShowRelated({ node, graphHandler }) {
  let [t] = useTranslation();
  return (
    <MenuItem
      children={t('graph.menu.actions.showRelated')}
      disabled={node === undefined}
      onClick={() => onShowRelatedNodes(node, graphHandler)}
    />
  );
}

function onShowRelatedNodes(node, graphHandler) {
  let graph = graphHandler.getGraphData();
  showRelatedNodes(graph, node);
  graphHandler.updateGraph(graph);
}

export default GraphMenuShowRelated;
