import React from 'react';

import { TextField } from '@mui/material';

const JdbcConnectionProperties = ({ properties, updateProperties }) => {
  return <>
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      required
      label='Endpoint'
      placeholder='Enter the connection URL'
      value={properties.endpoint || ''}
      onChange={(event) => updateProperties('endpoint', event.target.value)} />
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      required
      label='Username'
      placeholder='Enter the username'
      value={properties.user || ''}
      onChange={(event) => updateProperties('user', event.target.value)} />
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      required
      type='password'
      label='Password'
      placeholder='Entre the password'
      value={properties.pwd || ''}
      onChange={(event) => updateProperties('pwd', event.target.value)} />
  </>;
};

export default JdbcConnectionProperties;
