import PropTypes from 'prop-types';
import ItemAutoComplete from '../../../../components/itemselector/ItemAutoComplete';
import { connect } from 'react-redux';
import { fetchDictionaryListPage } from '../../../../actions/DictionariesActions';

function mapStateToProps(state, props) {
  return {
    values: Object.values(state.classification.dictionaries.content)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSearch: (search) => dispatch(fetchDictionaryListPage({ search }))
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    itemName: 'Dictionary',
    keyProperty: 'uuid',
    nameProperty: 'dictionaryName',
    ...ownProps,
    ...stateProps,
    ...dispatchProps
  };
}

const DictionariesAutoComplete = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ItemAutoComplete);

DictionariesAutoComplete.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default DictionariesAutoComplete;
