import React from 'react';
import { connect } from 'react-redux';

import { Card, CardContent, CardHeader, Typography } from '@mui/material';

import { deleteWorkspace, modifyWorkspace } from 'actions/social/favorites/WorkspaceActions';

import WorkspaceItemCardActions from './WorkspaceItemCardActions';
import WorkspaceFavoriteTable from './WorkspaceFavoriteTable';
import { fetchFavoritesPage } from 'actions/social/favorites/FavoritesActions';

const CARD_HEIGHT = '355px';
const TABLE_MAX_HEIGHT = '200px';

function WorkspaceItemCard({
  workspace,
  favoriteItems,
  deleteWorkspace,
  modifyWorkspace,
  workspaces,
  fetchFavoritesPage,
  favoritesPagination
}) {
  return (
    <Card variant='outlined' style={{ height: CARD_HEIGHT }}>
      <CardHeader
        action={
          <WorkspaceItemCardActions
            workspace={workspace}
            deleteWorkspace={deleteWorkspace}
            modifyWorkspace={modifyWorkspace}></WorkspaceItemCardActions>
        }
        title={workspace.name}
        subheader={
          workspace.defaultWorkspace && (
            <Typography variant='overline'>Default workspace</Typography>
          )
        }></CardHeader>
      <CardContent>
        <WorkspaceFavoriteTable
          fromWidget
          fetchFavoritesPage={fetchFavoritesPage}
          pagination={favoritesPagination}
          maxHeight={TABLE_MAX_HEIGHT}
          workspaces={workspaces}
          favoriteItems={favoriteItems}
        />
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state, { workspace }) => ({
  favoriteItems: state.social.favorites.contentByWorkspace[workspace?.uuid] || [],
  favoritesPagination: {
    page: state.social.favorites.currentPage.number,
    size: state.social.favorites.currentPage.size,
    totalElements: state.social.favorites.currentPage.totalElements
  }
});

export default connect(mapStateToProps, { deleteWorkspace, modifyWorkspace, fetchFavoritesPage })(
  WorkspaceItemCard
);
