import { AddFAB } from 'components/fab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createQueryBucket } from 'actions/QueryBucketsActions';
import QueryBucketModal from '../commons/QueryBucketModal';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';

const QueryBucketCreateFAB = ({ createQueryBucket }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const handleCreateQueryBucket = (queryBucket) => {
    createQueryBucket(queryBucket).then((res) => {
      if (res) {
        history.push(`/dataflows/queryparser/buckets/${res.uuid}`);
      }
    });
  };

  return (
    <>
      <AddFAB id='querybucket-create-button' onClick={() => setOpen(true)} />
      <QueryBucketModal
        title={t('querybuckets.create_title')}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSubmit={(queryBucket) => {
          setOpen(false);
          handleCreateQueryBucket(queryBucket);
        }}
      />
    </>
  );
};

export default compose(
  withPermissions({ hasPermissions: [Permissions.QUERY_PARSER_EDITOR] }),
  connect(null, { createQueryBucket })
)(QueryBucketCreateFAB);
