import React from 'react';

import { Tab, Tabs } from '@mui/material';

import { useHistory } from 'react-router';
import useSearchParams from 'components/useSearchParams';
import AgentWebActionConfigurationSection from './agentconfigurationtab/list/AgentWebActionConfigurationSection';

function WebActionsTabs({ webAction }) {
  const query = useSearchParams();
  const history = useHistory();
  const selectedTab = query.get('tab') || 'configurations';
  const handleChangeTab = (_event, newValue) =>
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });
  return (
    <>
      <Tabs value={selectedTab} onChange={handleChangeTab} centered>
        <Tab value='configurations' label='Configurations' />
      </Tabs>
      {selectedTab === 'configurations' && (
        <AgentWebActionConfigurationSection agent={webAction?.agent} webAction={webAction} />
      )}
    </>
  );
}

export default WebActionsTabs;
