import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  InputLabel,
  MenuItem
} from '@mui/material';

function IssueSeveritiesAddModal({ open, onCancel, onSubmit }) {
  let { t } = useTranslation();
  const [severity, setSeverity] = useState(null);
  const severityTypes = ['BLOCKER', 'CRITICAL', 'MAJOR', 'MINOR', 'INFO'];

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth>
        <DialogTitle>{'Add Severity Level'}</DialogTitle>
        <DialogContent>
          <FormControl variant='standard' fullWidth margin='dense'>
            <InputLabel id='issue-type-select-label'>Severity</InputLabel>
            <Select
              variant='standard'
              labelId='issue-type-select-label'
              id='issue-type-select'
              value={severity || ''}
              onChange={(event) => setSeverity(event.target.value)}>
              {severityTypes.map((severityLevel) => (
                <MenuItem key={severityLevel} value={severityLevel}>
                  {t(`issueMngt.issues.severities.${severityLevel}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.cancel')}
            onClick={() => {
              onCancel();
              setSeverity(null);
            }}></Button>
          <Button
            children={t('commons.actions.submit')}
            variant='contained'
            color='primary'
            ee
            onClick={() => {
              onSubmit(severity);
              setSeverity(null);
              onCancel();
            }}></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default IssueSeveritiesAddModal;
