import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import UsersInCurrentTenantItemAutocomplete from './UsersInCurrentTenantItemAutocomplete';
function UserFiltersSearchModal({ open, onCancel, onSubmit }) {
  const [user, setUser] = useState(null);
  let { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth>
        <DialogTitle>{'Search User'}</DialogTitle>
        <DialogContent>
          <UsersInCurrentTenantItemAutocomplete
            value={user}
            onChange={(user) => setUser(user)}></UsersInCurrentTenantItemAutocomplete>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.cancel')}
            onClick={() => {
              onCancel();
              setUser(null);
            }}></Button>
          <Button
            children={t('commons.actions.submit')}
            variant='contained'
            color='primary'
            ee
            onClick={() => {
              onSubmit(user);
              setUser(null);
              onCancel();
            }}></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default UserFiltersSearchModal;
