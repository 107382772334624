import React, { Component } from 'react';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchProfile } from '../../../actions/LoginActions';
import { CardActions, CardContent, CardHeader, Card, Typography } from '@mui/material';
import HistoryButtonLink from '../../history/commons/HistoryButtonLink';
import ChangePassword from './ChangePassword';
import PermissionsEditor from '../users/commons/PermissionsEditor';
import ExpandableCard from '../../../components/expandable-card/ExpandableCard';
import NotificationsSettingsCard from 'pages/social/notifications/NotificationsSettingsCard';
import { Security } from '@mui/icons-material';
import { selectCurrentTenant } from 'reducers/settings/tenants';

const ProfileContent = ({
  Uuid,
  Username,
  Email,
  applicationScope,
  permissions,
  fullName,
  tenantName
}) => (
  <>
    <Card style={{ marginTop: '8px' }}>
      <CardHeader title={'Your Profile'} />
      <CardContent>
        <KVDefinitionList
          item={{
            Uuid,
            Username,
            Email,
            'Full Name': fullName,
            Role: applicationScope
          }}
        />
      </CardContent>
      <CardActions>
        <ChangePassword />
        <HistoryButtonLink userId={Uuid} />
      </CardActions>
    </Card>
    <NotificationsSettingsCard />
    <ExpandableCard
      title={<Typography variant='subtitle1'>Permissions on {tenantName}</Typography>}
      avatar={<Security></Security>}>
      <PermissionsEditor permissions={permissions}></PermissionsEditor>
    </ExpandableCard>
  </>
);

class ProfileMounter extends Component {
  componentDidMount = () => this.props.fetchProfile();
  render = () => <ProfileContent {...this.props} />;
}

const mapStateToProps = (state, props) => ({
  Uuid: state.settings.profile.uuid,
  fullName: state.settings.profile.fullName,
  Username: state.settings.profile.username,
  Email: state.settings.profile.email,
  applicationScope: state.settings.profile.applicationScope,
  permissions: state.authentication.permissions,
  tenantName: selectCurrentTenant(state)?.name
});

export default connect(mapStateToProps, { fetchProfile })(withTranslation()(ProfileMounter));
