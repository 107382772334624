import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createQualitySuite } from '../../../../../actions/QualitySuitesActions';
import AddFAB from '../../../../../components/fab/AddFAB';
import QualitySuiteModal from '../commons/QualitySuiteModal';

function QualitySuiteFAB({ createQualitySuite, defaultPublished }) {
  const [open, setOpen] = React.useState(false);

  let history = useHistory();

  return (
    <React.Fragment>
      <AddFAB onClick={() => setOpen(true)} />
      <QualitySuiteModal
        defaultPublished={defaultPublished}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(qualitySuite) =>
          createQualitySuite(qualitySuite).then((qualitySuite) => {
            if (qualitySuite) {
              setOpen(false);
              history.push(`/data-quality/kqis/suites/${qualitySuite.uuid}`);
            }
          })
        }
      />
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  createQualitySuite
};

export default connect(mapStateToProps, mapDispatchToProps)(QualitySuiteFAB);
