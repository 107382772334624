import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, CardContent, CardActions } from '@mui/material';
import { ChipWrapper, ChipItem } from '../../../components/chiplist/ChipList';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';
import ResourceTypes from '../../../constants/ResourceTypes';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';
import { useTranslation } from 'react-i18next';
import CardDescription from 'components/carddescription/CardDescription';

function LogicalFieldCardView({ logicalField, actions, children }) {
  let { t } = useTranslation();
  if (logicalField) {
    return (
      <div>
        <CardHeader
          title={logicalField.name}
          subheader={
            <div>
              <label>{t('resourceType.DATA_CATEGORY')}: </label>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>
                {
                  <ChipItem
                    key={logicalField.uuid}
                    id={logicalField.uuid}
                    label={logicalField.dataCategory.name}
                    tooltip={logicalField.dataCategory.description}
                    to={`/datacategories/${logicalField.dataCategory.uuid}`}
                  />
                }
              </a>
            </div>
          }></CardHeader>
        <CardContent>
          <CardDescription>{logicalField.description}</CardDescription>
          <KVDefinitionList
            header='General'
            item={{
              Uuid: logicalField.uuid,
              Name: logicalField.name,
              [t('resourceType.DATA_CATEGORY')]: logicalField.dataCategory.name,
              Datatype: logicalField.dataType
            }}
          />
          {logicalField.constraintDescription != null && (
            <div>
              <h4>Constraints</h4>
              <div>{logicalField.constraintDescription}</div>
            </div>
          )}

          {logicalField.aliases != null && logicalField.aliases.length > 0 && (
            <div>
              <h4>Aliases</h4>
              <ChipWrapper>
                {logicalField.aliases.map((alias, index) => (
                  <ChipItem key={index} id={index} label={`${alias}`} />
                ))}
              </ChipWrapper>
            </div>
          )}

          {logicalField.patterns != null && (
            <div>
              <h4>Patterns</h4>
              <div>{logicalField.patterns}</div>
            </div>
          )}

          {logicalField.computationalRules != null && (
            <div>
              <h4>Computational Rules</h4>
              <div>{logicalField.computationalRules}</div>
            </div>
          )}

          <div className='dls-wrapper'>
            <CustomPropertiesView
              resourceType={ResourceTypes.LOGICAL_FIELD}
              additionalProperties={logicalField.additionalProperties}
            />
          </div>
        </CardContent>
      </div>
    );
  } else {
    return null;
  }
}

LogicalFieldCardView.propTypes = {
  logicalField: PropTypes.object,
  actions: PropTypes.instanceOf(CardActions)
};

export default LogicalFieldCardView;
