import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

function CompilationFiltersLogicalFieldKPIsView({ filters }) {
  let { t } = useTranslation();
  return (
    <>
      <div>
        <label style={{ color: '#00AECA' }}>{<b>Active Filters</b>}: </label>
      </div>
      {filters.nullPropertiesNames && (
        <div>
          <Typography variant='overline'>Not Defined Properties: </Typography>
          {filters.nullPropertiesNames.join(', ')}
        </div>
      )}
      {filters.search && (
        <div>
          <Typography variant='overline'>Search: </Typography>
          <b>{filters.search}</b>
        </div>
      )}
      {filters.dataCategories && filters.dataCategories.length > 0 && (
        <div>
          <Typography variant='overline'>{t('data_categories.header') + ': '}</Typography>
          {filters.dataCategories.map((dc) => dc.name).join(', ')}
        </div>
      )}
      {filters.propertiesNames && filters.propertiesNames.length > 0 && (
        <div>
          <Typography variant='overline'>Properties Names: </Typography>
          {filters.propertiesNames.join(', ')}
        </div>
      )}
      {filters.propertiesValues && filters.propertiesValues.length > 0 && (
        <div>
          <Typography variant='overline'>Properties Values: </Typography>
          {filters.propertiesValues.join(', ')}
        </div>
      )}
      {filters.systems && filters.systems.length > 0 && (
        <div>
          <Typography variant='overline'>Systems: </Typography>
          {filters.systems.map((s) => s.name).join(', ')}
        </div>
      )}
    </>
  );
}

export default withTranslation()(CompilationFiltersLogicalFieldKPIsView);
