import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SafeguardTypeEditor from '../../processings/form/SafeguardTypeEditor';
import { modifyProcessing } from '../../../actions/ProcessingsActions';
import { fetchTask } from '../../../actions/TasksActions';
import { useDispatch } from 'react-redux';

function TransferExtraEuPatchModal({
  open,
  dataActor,
  task,
  processingsToPatch,
  onCancel,
  onSubmit
}) {
  let { t } = useTranslation();
  let [safeguardType, setSafeguardType] = useState(null);
  let [linkToDocs, setLinkToDocs] = useState(null);
  let dispatch = useDispatch();

  function handlePatch() {
    let newTransfer = { organization: dataActor, safeguardType, linkToDocs };
    let modifiedProcessings = processingsToPatch.map((p) => ({
      ...p,
      transferExtraEuOrganizations: [...(p.transferExtraEuOrganizations || []), newTransfer]
    }));
    return Promise.all(modifiedProcessings.map((p) => dispatch(modifyProcessing(p))))
      .then(() => dispatch(fetchTask(task.uuid)))
      .then(onSubmit);
  }

  return (
    <Dialog open={open}>
      <DialogContent>
        <h4>Transfer to Extra Eu Oganizations</h4>
        <SafeguardTypeEditor
          country={dataActor && dataActor.country}
          safeguardType={safeguardType}
          linkToDocs={linkToDocs}
          onChange={({ safeguardType, linkToDocs }) => {
            setSafeguardType(safeguardType);
            setLinkToDocs(linkToDocs);
          }}
        />

        <h4>Processings to fix</h4>
        <ul>
          {processingsToPatch.map((p) => (
            <li key={p.uuid}>{p.name}</li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
        <Button color='primary' onClick={handlePatch}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TransferExtraEuPatchModal;
