import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import StewardshipNavbar from './StewardshipNavbar';
import StewardshipRolesRoutes from './roles/StewardshipRolesRoutes';
import StewardshipResponsibilitiesRoutes from './responsibilities/StewardshipResponsibilitiesRoutes';
import StewardshipDashboardsRoutes from './dashboards/StewardshipDashboardsRoutes';
import StewardshipAssignmentsRoutes from './assignments/StewardshipAssignmentsRoutes';

export default function TemplatesRoutes(props) {
  return (
    <>
      <StewardshipNavbar />
      <Switch>
        <Route path={`${props.match.url}/roles`} component={StewardshipRolesRoutes} />
        <Route
          path={`${props.match.url}/assignments`}
          component={StewardshipAssignmentsRoutes}></Route>
        <Route
          path={`${props.match.url}/responsibilities`}
          component={StewardshipResponsibilitiesRoutes}
        />
        <Route
          path={`${props.match.url}/dashboards`}
          component={StewardshipDashboardsRoutes}></Route>
        <Redirect to={`${props.match.url}/responsibilities`}></Redirect>
      </Switch>
    </>
  );
}
