import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CampaignItemAutoComplete from './CampaignItemAutoComplete';

function CampaignSearchModal({ open, onCancel, onSubmit }) {
  const [campaign, setCampaign] = useState(null);
  let { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth>
        <DialogTitle>{'Search Campaigns'}</DialogTitle>
        <DialogContent>
          <CampaignItemAutoComplete
            value={campaign && campaign.uuid && { uuid: campaign.uuid }}
            handleChange={(campaign) => setCampaign(campaign)}></CampaignItemAutoComplete>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.cancel')}
            onClick={() => {
              onCancel();
              setCampaign(null);
            }}></Button>
          <Button
            children={t('commons.actions.submit')}
            variant='contained'
            color='primary'
            ee
            onClick={() => {
              onSubmit(campaign);
              setCampaign(null);
              onCancel();
            }}></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default CampaignSearchModal;
