import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { ChipWrapper, ChipItem } from '../../../components/chiplist/ChipList';
import ProcessingSearchModal from '../../processings/utils/ProcessingSearchModal';
import { connect } from 'react-redux';
import {
  fetchDataSubjectsAudienceCount,
  editDataSubjectsAudienceFilters,
  clearDataSubjectsAudienceFilters
} from '../../../actions/DashboardsActions';
import { downloadAudienceExport } from '../../../actions/ReportsActions';
import DatePicker, { hourAdjustments } from '../../../components/pickers/DatePicker';
import { useTranslation } from 'react-i18next';

const DataSubjectAudienceCard = ({
  selectedProcessings,
  targetDate,
  context,
  nullContext,
  fetchDataSubjectsAudienceCount,
  editDataSubjectsAudienceFilters,
  clearDataSubjectsAudienceFilters,
  openAddProcessing,
  audienceCount,
  downloadAudienceExport
}) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader title='Data Subjects Audience' />
      <CardContent>
        <p>
          Count and extract the Data Subjects that gave the consent to the specified processings and
          context:
        </p>
        <ol>
          <li>Choose the processings that requires the consents</li>
          <li>Select a target date to filter expired consents</li>
          <li>Select a context to filter unrelated consents</li>
        </ol>
        <Grid container spacing={32}>
          <Grid item style={{ minWidth: 256 }}>
            <h4>Processings</h4>
            <ChipWrapper
              onRequestAdd={() => editDataSubjectsAudienceFilters({ openAddProcessing: true })}>
              {(selectedProcessings || []).map((p) => (
                <ChipItem
                  label={p.name}
                  onRequestDelete={() =>
                    editDataSubjectsAudienceFilters({
                      selectedProcessings: selectedProcessings.filter((e) => e !== p)
                    })
                  }
                />
              ))}
            </ChipWrapper>
          </Grid>
          <Grid item style={{ minWidth: 256 }}>
            <h4>Target Date</h4>
            <DatePicker
              hourAdjustment={hourAdjustments.endOfDay}
              id='date'
              label='Target Date'
              type='date'
              value={targetDate}
              onChange={(date) =>
                editDataSubjectsAudienceFilters({
                  targetDate: date
                })
              }
              InputLabelProps={{
                shrink: true
              }}
              clearable
            />
          </Grid>
          <Grid item style={{ minWidth: 256 }}>
            <h4>Context</h4>
            <TextField
              variant='standard'
              disabled={nullContext}
              placeholder='Enter the context'
              label='Context'
              value={context || ''}
              onChange={(event) => editDataSubjectsAudienceFilters({ context: event.target.value })}
              InputLabelProps={{
                shrink: true
              }}
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={nullContext}
                  onChange={() =>
                    editDataSubjectsAudienceFilters({
                      nullContext: !nullContext,
                      context: !nullContext ? null : context
                    })
                  }
                  value='nullContext'
                />
              }
              label='Null Context'
            />
          </Grid>
          <Grid item style={{ minWidth: 256 }}>
            <div>
              <h4>Data Subjects Count</h4>
              <Typography style={{ marginLeft: 8, display: 'inline' }} variant='h4'>
                {extractCountNumber(audienceCount)}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <ProcessingSearchModal
          open={openAddProcessing}
          onSubmit={(p) =>
            editDataSubjectsAudienceFilters({
              selectedProcessings: selectedProcessings.concat([p]),
              openAddProcessing: false
            })
          }
          onCancel={() => editDataSubjectsAudienceFilters({ openAddProcessing: false })}
        />
      </CardContent>
      <CardActions>
        <Button
          children={t('commons.actions.count')}
          disabled={!selectedProcessings || selectedProcessings.length <= 0 || !targetDate}
          onClick={() => fetchDataSubjectsAudienceCount()}></Button>
        <Button
          children={t('commons.actions.download')}
          disabled={!selectedProcessings || selectedProcessings.length <= 0 || !targetDate}
          onClick={() => downloadAudienceExport(selectedProcessings, targetDate)}></Button>
        <Button
          children={t('commons.actions.clear')}
          onClick={() => clearDataSubjectsAudienceFilters()}></Button>
      </CardActions>
    </Card>
  );
};

function extractCountNumber(count) {
  if (count) {
    try {
      let {
        rows: [[countNumber]]
      } = count;
      return countNumber;
    } catch (e) {
      return 'error';
    }
  } else {
    return '?';
  }
}

const mapStateToProps = (state) => ({
  selectedProcessings: state.dashboards.dataSubjectsAudience.filters.selectedProcessings,
  targetDate: state.dashboards.dataSubjectsAudience.filters.targetDate,
  context: state.dashboards.dataSubjectsAudience.filters.context,
  nullContext: state.dashboards.dataSubjectsAudience.filters.nullContext,
  openAddProcessing: state.dashboards.dataSubjectsAudience.filters.openAddProcessing,
  audienceCount: state.dashboards.dataSubjectsAudience.audienceCount,
  access_token: state.authentication.access_token
});

const DataSubjectAudienceCardContainer = connect(mapStateToProps, {
  editDataSubjectsAudienceFilters,
  fetchDataSubjectsAudienceCount,
  clearDataSubjectsAudienceFilters,
  downloadAudienceExport
})(DataSubjectAudienceCard);

export default DataSubjectAudienceCardContainer;
