import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import configActions from '../../actions/SettingsConfigurationsActions';
import { fetchStewardshipAcl } from '../../actions/StewardshipActions';
import { extractConfiguration } from '../../reducers/settings/configurations';
import { extractStewardshipAcl } from '../../reducers/stewardship/responsibilities';

const withResponsibilities = (WrappedComponent) => {
  function WithResponsibilities(props) {
    let { resourceIdentifier, resourceType, ...otherProps } = props;
    let dispatch = useDispatch();
    let hasResponsibilities = useSelector(
      extractStewardshipAcl({ resourceType, resourceIdentifier })
    );
    let stewardshipAclEnabledProperty = useSelector(
      extractConfiguration('stewardship-acl-enabled')
    );
    let aclActiveOnResource =
      stewardshipAclEnabledProperty == null ||
      (stewardshipAclEnabledProperty.value || '').includes(resourceType);
    useEffect(
      function () {
        if (!stewardshipAclEnabledProperty) {
          dispatch(configActions.fetch('stewardship-acl-enabled'));
        } else if (aclActiveOnResource) {
          dispatch(fetchStewardshipAcl({ resourceType, resourceIdentifier }));
        }
      },
      [
        stewardshipAclEnabledProperty,
        aclActiveOnResource,
        resourceIdentifier,
        resourceType,
        dispatch
      ]
    );
    if (!aclActiveOnResource || hasResponsibilities) {
      return <WrappedComponent {...otherProps} />;
    } else {
      return null;
    }
  }

  WithResponsibilities.displayName = `WithResponsibilities(${getDisplayName(WrappedComponent)})`;
  return WithResponsibilities;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withResponsibilities;
