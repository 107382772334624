import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchContractDefinitionCategories } from 'actions/contracts/ContractsDefinitionsActions';
import AutoComplete from 'components/autocomplete/AutoComplete';

function ContractDefinitionCategoriesAutoComplete({
  fetchContractDefinitionCategories,
  category,
  margin,
  fullWidth,
  onChange
}) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchContractDefinitionCategories({ search: '' }).then(
      (response) => response && setCategories(response.content)
    );
  }, [fetchContractDefinitionCategories, setCategories]);

  return (
    <AutoComplete
      fullWidth={fullWidth}
      margin={margin}
      label='Contract Categories'
      value={category}
      dataSource={categories}
      onUpdateInput={onChange}
      onNewRequest={onChange}
    />
  );
}
export default connect(null, { fetchContractDefinitionCategories })(
  ContractDefinitionCategoriesAutoComplete
);
