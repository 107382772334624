import React from 'react';

import { UNITS } from '../utils/units';

import CustomSelect from './CustomSelect';

const WeekDays = ({ value, setValue, selectedPeriod }) => {

  return (
    <>
      {selectedPeriod === 'week' ?
        <span>on</span>
        :
        <span>and</span>
      }
      <CustomSelect unit={UNITS[4]} value={value} setValue={setValue} />
      {selectedPeriod === 'hour' && <span>minute(s)</span>}
    </>
  )
};

export default WeekDays;