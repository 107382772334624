import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Tooltip,
  IconButton,
  DialogContent,
  Grid,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import { clearDataFlowFilters, editDataFlowFilters } from '../../../actions/DataFlowsActions';
import FilterListIcon from '@mui/icons-material/FilterList';
import SystemsChipListEditor from '../../systems/commons/SystemsChipListEditor';
import PhysicalEntitiesChipListEditor from '../../systems/physical_entities/commons/PhysicalEntitiesChipListEditor';
import DataFlowScopeAutoComplete from '../utils/DataFlowScopeAutoComplete';
import Permissions from 'constants/Permissions';
import DataCategoriesChipListEditor from 'pages/datacategories/utils/DataCategoriesChipListEditor';
import LogicalFieldsChipListEditor from 'pages/logicalfields/commons/LogicalFieldsChipListEditor';
import Authorize from 'components/permissions/Authorize';
import PhysicalEntitySchemaChipListEditor from './PhysicalEntitySchemaChipListEditor';

class DataFlowsFiltersDialog extends React.Component {
  state = {
    filterOpen: false
  };

  render() {
    let { filters, onCancel, onSubmit, editDataFlowFilters, clearDataFlowFilters, t } = this.props;
    return (
      <>
        <Tooltip title={t('commons.actions.filters')} enterDelay={2 * 1000}>
          <IconButton
            size='small'
            color={this.state.hasFilters ? 'primary' : 'grey'}
            onClick={() => this.setState({ filterOpen: true })}
            aria-label={t('commons.actions.filters')}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <MobileDialog open={this.state.filterOpen} fullWidth>
          <DialogTitle>{t('dataflows.header') + ' ' + t('commons.actions.filters')}</DialogTitle>
          <DialogContent>
            <h4>{t('dataflows.scope')}</h4>
            <DataFlowScopeAutoComplete
              fullWidth
              margin='dense'
              scope={filters.scopes || ''}
              onChange={(newScopes) => editDataFlowFilters({ scopes: newScopes })}
            />
            <Grid container direction='row' spacing={2}>
              <Grid item xs>
                <h4>{t('dataflows.fields.from')}</h4>
                <h5>Systems</h5>
                <SystemsChipListEditor
                  values={filters.fromSystems}
                  onChange={(values) => editDataFlowFilters({ fromSystems: values })}
                />
                <h5>Schema</h5>
                <PhysicalEntitySchemaChipListEditor
                  values={filters.fromPhysicalEntitySchemas}
                  onChange={(values) => editDataFlowFilters({ fromPhysicalEntitySchemas: values })}
                />
                <h5>Physical entities</h5>
                <PhysicalEntitiesChipListEditor
                  values={filters.fromPhysicalEntities}
                  onChange={(values) => editDataFlowFilters({ fromPhysicalEntities: values })}
                />
                <FormControlLabel
                  style={{ marginTop: '5%', marginLeft: '0%' }}
                  label='Only Null'
                  labelPlacement='start'
                  control={
                    <Checkbox
                      checked={filters.nullFromPhysicalEntities}
                      onChange={(event, checked) =>
                        editDataFlowFilters({
                          nullFromPhysicalEntities: checked
                        })
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs>
                <h4>{t('dataflows.fields.to')}</h4>
                <h5>Systems</h5>
                <SystemsChipListEditor
                  values={filters.toSystems}
                  onChange={(values) => editDataFlowFilters({ toSystems: values })}
                />
                <h5>Schema</h5>
                <PhysicalEntitySchemaChipListEditor
                  values={filters.toPhysicalEntitySchemas}
                  onChange={(values) => editDataFlowFilters({ toPhysicalEntitySchemas: values })}
                />
                <h5>Physical entities</h5>
                <PhysicalEntitiesChipListEditor
                  values={filters.toPhysicalEntities}
                  onChange={(values) => editDataFlowFilters({ toPhysicalEntities: values })}
                />
                <FormControlLabel
                  style={{ marginTop: '5%', marginLeft: '0%' }}
                  label='Only Null'
                  labelPlacement='start'
                  control={
                    <Checkbox
                      checked={filters.nullToPhysicalEntities}
                      onChange={(event, checked) =>
                        editDataFlowFilters({
                          nullToPhysicalEntities: checked
                        })
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
            <Authorize hasPermissions={[Permissions.DATA_CATEGORIES_VIEWER]}>
              <h4>{t('dataflows.fields.aboutDataCategories')}</h4>
              <DataCategoriesChipListEditor
                permissions={[Permissions.DATA_CATEGORIES_VIEWER]}
                values={filters.aboutDataCategories}
                onChange={(values) =>
                  editDataFlowFilters({ aboutDataCategories: values })
                }></DataCategoriesChipListEditor>
              <h4>{t('dataflows.fields.aboutLogicalFields')}</h4>
              <LogicalFieldsChipListEditor
                permissions={[Permissions.DATA_CATEGORIES_VIEWER]}
                values={filters.aboutLogicalFields}
                onChange={(values) =>
                  editDataFlowFilters({ aboutLogicalFields: values })
                }></LogicalFieldsChipListEditor>
            </Authorize>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                clearDataFlowFilters();
                this.setState({ filterOpen: false, hasFilters: false });
                onCancel();
              }}>
              {t('commons.actions.clear')}
            </Button>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                this.setState({ filterOpen: false, hasFilters: true });
                onSubmit();
              }}>
              {t('commons.actions.apply')}
            </Button>
          </DialogActions>
        </MobileDialog>
      </>
    );
  }
}
const MobileDialog = Dialog;

const mapStateToProps = (state) => ({
  filters: state.dataflows.filters
});

export default _.flow([
  withTranslation(),
  connect(mapStateToProps, { editDataFlowFilters, clearDataFlowFilters })
])(DataFlowsFiltersDialog);
