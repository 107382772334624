import { handleErrors, parseJson, encodeQueryData, BlindataApi } from './ApiUtils';

export default class PhysicalEntitiesApi extends BlindataApi {
  token = '';

  getPhysicalEntitiesPage(page, size = 25, filters = {}) {
    return this.get(`/api/v1/physical_entities`, { page, size, ...this.validateFilters(filters) });
  }

  exportPhysicalEntities(params) {
    return this.download(`/api/v1/physical_entities/*/export`, {
      ...this.validateFilters(params),
      includeProperties: 'false'
    });
  }

  validateFilters(filters = {}) {
    return {
      search: filters.search,
      name: filters.name,
      systemUuid: filters.system?.uuid,
      schemas: filters.schema ? [filters.schema] : [],
      tableType: filters.tableType,
      physicalFieldsCount: filters.physicalFieldsCount,
      dataCategoriesUuids: filters.dataCategories?.map((dc) => dc.uuid),
      logicalFieldsUuids: filters.logicalFields?.map((lf) => lf.uuid),
      propertiesNames: filters.propertiesNames,
      propertiesValues: filters.propertiesValues,
      nullPropertiesNames: filters.nullPropertiesNames,
      dataSet: filters.dataSet,
      hidden: filters.hidden || false,
      view: filters.view,
      startCreatedAt: filters.startCreatedAt,
      endCreatedAt: filters.endCreatedAt,
      startUpdatedAt: filters.startUpdatedAt,
      endUpdatedAt: filters.endUpdatedAt,
      includeProperties: filters.includeProperties
    };
  }

  getPhysicalEntitiesBySystem(systemUuid, page, size) {
    return fetch(`/api/v1/physical_entities?systemUuid=${systemUuid}&page=${page}&size=${size}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson);
  }

  getPhysicalEntity(physicalEntityUuid) {
    return fetch(`/api/v1/physical_entities/${physicalEntityUuid}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson);
  }

  searchPhysicalEntities(search, systemUuid, page = 0, size = 10) {
    return fetch(
      `/api/v1/physical_entities?${encodeQueryData({
        search,
        systemUuid,
        page,
        size
      })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${this.token}`,
          ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
        }
      }
    )
      .then(handleErrors)
      .then(parseJson);
  }

  postPhysicalEntity(physicalEntity) {
    return fetch(`/api/v1/physical_entities`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(physicalEntity)
    })
      .then(handleErrors)
      .then(parseJson);
  }

  putPhysicalEntity(physicalEntity) {
    return fetch(`/api/v1/physical_entities/${physicalEntity.uuid}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(physicalEntity)
    })
      .then(handleErrors)
      .then(parseJson);
  }

  deletePhysicalEntity(physicalEntity) {
    return fetch(`/api/v1/physical_entities/${physicalEntity.uuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    }).then(handleErrors);
  }

  getSchemas(params) {
    return this.get(`/api/v1/physical_entities/*/schemas`, params);
  }
  getDataSet(params) {
    return this.get(`/api/v1/physical_entities/*/dataSet`, params);
  }
  getTableTypes(params) {
    return this.get(`/api/v1/physical_entities/*/tableTypes`, params);
  }
}
