import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchProbeProjectsPage } from 'actions/quality/probes/ProbesProjectsActions';

import { Link } from 'react-router-dom';
import {
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
  Typography
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import QualityProbesProjectsExportButton from './QualityProbesProjectsExportButton';
import QualityProbesProjectsFAB from '../commons/QualityProbesProjectFAB';

function QualityProbesProjectsList({
  currentPage,
  selectedProject,
  setSelectedProject,
  fetchProbeProjectsPage
}) {
  useEffect(() => {
    fetchProbeProjectsPage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    <Grid container justifyContent='flex-end'>
      <Grid item>
        <QualityProbesProjectsExportButton />
      </Grid>
      <Grid item>
        <QualityProbesProjectsFAB />
      </Grid>
    </Grid>
    {currentPage.content.length > 0 ? (
      <div style={{ height: '100%', overflowY: 'auto' }}>
        <Grid container direction='column' style={{ minHeight: '100%' }}>
          <Grid item>
            <Table>
              <TableBody>
                {currentPage.content.map((project) => (
                  <TableRow
                    key={project.uuid}
                    hover={true}
                    style={{ cursor: 'pointer' }}
                    selected={selectedProject?.uuid === project.uuid}
                    onClick={() => {
                      setSelectedProject(project);
                    }}>
                    <TableCell style={{ width: '56px' }} padding='none'>
                      <FolderIcon color='primary' />
                    </TableCell>
                    <TableCell>{project.name}</TableCell>
                    <TableCell align='right' padding='none'>
                      <IconButton
                        component={Link}
                        to={`/data-quality/probes/projects/${project.uuid}`}
                        size="large">
                        <OpenInNewIcon fontSize='small' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item style={{ flexGrow: 1 }} />
          <Grid item>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 500, 1000]}
              component='div'
              style={{ overflowX: 'hidden' }}
              count={currentPage.totalElements}
              rowsPerPage={currentPage.size}
              page={currentPage.number}
              onPageChange={(event, page) =>
                fetchProbeProjectsPage({ page: page, size: currentPage.size })
              }
              onRowsPerPageChange={(event) =>
                fetchProbeProjectsPage({ page: currentPage.number, size: event.target.value })
              }
            />
          </Grid>
        </Grid>
      </div>
    ) : (
      <div style={{ textAlign: 'center', padding: '2%' }}>
        <Typography variant='body1'>No projects defined</Typography>
      </div>
    )}
  </>;
}

const mapStateToProps = (state) => ({
  currentPage: state.quality.qualityProbes.projects.currentPage
});

const mapDispatchToProps = {
  fetchProbeProjectsPage
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityProbesProjectsList);
