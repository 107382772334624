import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import ExpandableCard from '../../../../../components/expandable-card/ExpandableCard';
import { ChipItem } from '../../../../../components/chiplist/ChipList';

import moment from 'moment';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';
import ResourceTypes from 'constants/ResourceTypes';
import TeamCodeDecorator from 'pages/settings/teams/common/TeamCodeDecorator';
import { useTranslation } from 'react-i18next';
import { getIconShort, getTechnologyConfig } from 'constants/SystemTechnologyTypes';

function getSubheader(qualitySuite) {
  let code = qualitySuite.code;
  var m = moment(qualitySuite.createdAt);
  var dateCreation;
  if (!m.isValid()) dateCreation = '';
  else {
    dateCreation = m.format('DD MMM YYYY');
  }
  return `${code} - ${dateCreation}`;
}

export default function QualitySuitesListItem({ qualitySuite }) {
  const { t } = useTranslation();
  return (
    <ExpandableCard
      title={qualitySuite.name}
      subheader={getSubheader(qualitySuite)}
      actions={[
        <Button
          key={qualitySuite.uuid}
          children={t('commons.actions.detail')}
          component={Link}
          to={`/data-quality/kqis/suites/${qualitySuite.uuid}`}
        />
      ]}
      decorator={
        <div>
          {qualitySuite.teamCode && <TeamCodeDecorator teamCode={qualitySuite.teamCode} />}
          {qualitySuite.system && (
            <ChipItem
              icon={getIconShort(getTechnologyConfig(qualitySuite?.system?.technology), {
                width: '2em',
                height: '2em'
              })}
              label={qualitySuite.system.name}
              tooltip={'Go to the system definition'}
              to={`/systems/${qualitySuite.system.uuid}`}
            />
          )}
          {!qualitySuite.published && <VariationTypeDecorator>{'DRAFT'}</VariationTypeDecorator>}
          <IssueResourceListItemWidget
            resourceType={ResourceTypes.QUALITY_SUITE}
            resourceIdentifier={qualitySuite.uuid}></IssueResourceListItemWidget>
        </div>
      }
    />
  );
}
