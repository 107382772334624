import * as React from "react";
const SvgApacheCalcite = (props) => (
  <svg
    id="ApacheCalcite_svg__svg7816"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 64 64"
    width="1em"
    height="1em"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".ApacheCalcite_svg__st0{fill:#acf}.ApacheCalcite_svg__st1{fill:#2a7fff}.ApacheCalcite_svg__st2{fill:#59f}.ApacheCalcite_svg__st3{fill:#80b3ff}.ApacheCalcite_svg__st5{fill:#fff}"
      }
    </style>
    <g
      id="ApacheCalcite_svg__g2277"
      transform="matrix(.21375 .11444 -.11444 .21375 57.819 161.95)"
    >
      <path
        id="ApacheCalcite_svg__path1365"
        className="ApacheCalcite_svg__st0"
        d="m-273.6-313.9-29.9 18.1-29.3 1-29.3 1L-391-310l-34-48.2 103.1-227.1 86.8 220.9-38.5 50.5z"
      />
      <path
        id="ApacheCalcite_svg__path1367"
        className="ApacheCalcite_svg__st1"
        d="m-273.6-313.9-29.9 18.1-29.3 1-29.3 1L-391-310l117.4-3.9z"
      />
      <path
        id="ApacheCalcite_svg__path1308"
        className="ApacheCalcite_svg__st0"
        d="m-276.8-320.5-28.2 17.1-27.6.9-27.6.9-27.1-15.3-32-45.4 97-213.7 81.7 207.9-36.2 47.6z"
      />
      <path
        id="ApacheCalcite_svg__path1313"
        className="ApacheCalcite_svg__st2"
        d="m-276.8-320.5-27.1-13.9-27.6.9-27.6.9-28.1 15.7-32-45.4 97-213.7 81.7 207.9-36.3 47.6z"
      />
      <path
        id="ApacheCalcite_svg__path1315"
        className="ApacheCalcite_svg__st1"
        d="m-301.9-392.3-27.6.9-27.6.9-62.1 28.3 97-213.7 81.7 207.9-61.4-24.3z"
      />
      <path
        id="ApacheCalcite_svg__path1319"
        className="ApacheCalcite_svg__st3"
        d="m-303.9-334.4-55.2 1.8 2-57.9 34.9-185.4 20.3 183.6-2 57.9z"
      />
      <path
        id="ApacheCalcite_svg__path1321"
        className="ApacheCalcite_svg__st2"
        d="m-322.2-576-34.9 185.4 27.6-.9 27.6-.9-20.3-183.6z"
      />
      <path
        id="ApacheCalcite_svg__path1345"
        d="m-305-303.4-55.2 1.8 28.9-14.3 26.3 12.5z"
        style={{
          fill: "#d5e5ff",
        }}
      />
      <path
        id="ApacheCalcite_svg__path1351"
        className="ApacheCalcite_svg__st5"
        d="m-387.2-316.8 23.6-19 6.6-54.7 1.4 53.2 49.1-2.4 4.7-52.7 3 52.9 22 19-27.1-13.9-55.2 1.8-28.1 15.8z"
      />
      <path
        id="ApacheCalcite_svg__path1353"
        className="ApacheCalcite_svg__st5"
        d="m-412.8-365.5 54-31.8s35.3-172.2 36.6-178.6l-29.5 178.7 45.9-2.9-15.5-170.2 23.2 171.9s51.4 26.8 57.6 30.2l-61.4-24.3-55.2 1.8-55.7 25.2z"
      />
      <path
        id="ApacheCalcite_svg__path1355"
        className="ApacheCalcite_svg__st2"
        d="m-412.2-369.6 82.6-186.8-67.1 176.5-15.5 10.3z"
      />
      <path
        id="ApacheCalcite_svg__path1357"
        className="ApacheCalcite_svg__st3"
        d="m-276.3-326.7 29.2-39.7-13.8-6.9-15.4 46.6z"
      />
      <ellipse
        id="ApacheCalcite_svg__path1359"
        transform="rotate(73.022 -362.885 -435.222)"
        className="ApacheCalcite_svg__st3"
        cx={-362.9}
        cy={-435.2}
        rx={8.7}
        ry={9.8}
      />
      <ellipse
        id="ApacheCalcite_svg__ellipse1361"
        transform="rotate(151.836 -370.473 -419.299)"
        className="ApacheCalcite_svg__st3"
        cx={-370.5}
        cy={-419.3}
        rx={5.9}
        ry={5.5}
      />
      <path
        id="ApacheCalcite_svg__path2189"
        className="ApacheCalcite_svg__st5"
        d="m-356.7-375.9-16.9-7.2-11.8 15 8.7-16.7-14.9-10.3 16.9 7.2 11.8-15-8.7 16.7 14.9 10.3z"
      />
      <path
        id="ApacheCalcite_svg__path2193"
        className="ApacheCalcite_svg__st5"
        d="m-370.6-369.7-4.8-13.9-15.6 2.7 15-5.5-1.8-14.8 4.8 13.9 15.6-2.7-15 5.5 1.8 14.8z"
      />
      <path
        id="ApacheCalcite_svg__path2219"
        className="ApacheCalcite_svg__st5"
        d="m-304.8-511.5-10.6-8.9-11.9 8.8 10.1-10.7-8.4-10.8 10.6 8.9 11.9-8.8-10.1 10.7 8.4 10.8z"
      />
    </g>
  </svg>
);
export default SvgApacheCalcite;
