import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Menu,
  MenuItem
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { deleteFavorite, modifyFavorite } from 'actions/social/favorites/FavoritesActions';

import { connect } from 'react-redux';
import FavoriteItemModal from './FavoriteItemModal';
import { useHistory } from 'react-router-dom';

function FavoritesTableActions({ deleteFavorite, modifyFavorite, item, workspaces }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const oldWorkspaceUuid = item.workspace.uuid;
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const history = useHistory();
  const handleShowDetails = (event) => {
    setAnchorEl(null);
    history.push(`${item.pagePath}`);
    event.stopPropagation();
  };
  const handleDelete = () => {
    setAnchorEl(null);
    deleteFavorite(item);
  };
  return (
    <>
      <IconButton
        size='small'
        aria-haspopup='true'
        aria-controls='physical-entities-table-actions'
        onClick={(event) => handleOpen(event)}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='physical-entities-table-actions'
        anchorEl={anchorEl}
        keepMounted
        onClose={(event) => {
          setAnchorEl(null);
          event.stopPropagation();
        }}
        open={Boolean(anchorEl)}>
        <MenuItem onClick={(event) => handleShowDetails(event)}>Detail</MenuItem>
        <MenuItem
          onClick={(event) => {
            setOpen(true);
            event.stopPropagation();
          }}>
          Modify
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            setOpenDelete(true);
            event.stopPropagation();
          }}>
          Delete
        </MenuItem>
      </Menu>
      {open && (
        <FavoriteItemModal
          workspaces={workspaces}
          item={item}
          open={open}
          onCancel={() => setOpen(false)}
          onSubmit={(item) => {
            setAnchorEl(null);
            modifyFavorite(item, oldWorkspaceUuid);
            setOpen(false);
          }}></FavoriteItemModal>
      )}
      {openDelete && (
        <DeleteDialog
          onSubmit={(event) => handleDelete(event)}
          onCancel={() => setOpenDelete(false)}
          open={openDelete}></DeleteDialog>
      )}
    </>
  );
}

function DeleteDialog({ onCancel, onSubmit, item, open }) {
  return (
    <>
      <Dialog onClick={(event) => event.stopPropagation()} open={open} onClose={onCancel}>
        <DialogContent>
          <DialogContentText>{'Do you really want to delete this item?'}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>No</Button>
          <Button color='primary' onClick={() => onSubmit()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default connect(null, { modifyFavorite, deleteFavorite })(FavoritesTableActions);
