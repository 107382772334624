import React from 'react';
import { Typography } from '@mui/material';
import BdLinkify from 'components/linkify/BdLinkify';

const CardDescription = ({ children }) => (
  <BdLinkify>
    <Typography variant='description'>{children}</Typography>
  </BdLinkify>
);

export default CardDescription;
