import { IconButton } from '@mui/material';
import { Assessment } from '@mui/icons-material';
import React from 'react';
import CompilationKPIsModal from './CompilationKPIsModal';

export default function CompilationKPIsButton({
  onFetchCompilationKPIs,
  filters,
  hasFilters,
  resourceType
}) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <IconButton size='small' onClick={() => setOpen(true)}>
        <Assessment></Assessment>
      </IconButton>
      <CompilationKPIsModal
        resourceType={resourceType}
        hasFilters={hasFilters}
        filters={filters}
        onFetchCompilationKPIs={onFetchCompilationKPIs}
        open={open}
        onClose={() => setOpen(false)}></CompilationKPIsModal>
    </>
  );
}
