import { IconButton, Table, TableBody, TableHead, TableRow } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/AddBox';
import React, { useState } from 'react';
import _ from 'lodash';
import TableCell from 'components/tables/GenericTableCell';
import PhysicalEntitySearchModal from 'pages/systems/physical_entities/commons/PhysicalEntitySearchModal';
import { useHistory } from 'react-router';

export const DataProductPortPhysicalEntitiesList = ({
  dataProductPort,
  deletePhysicalEntityFromList,
  updatePhysicalEntitiesList
}) => {
  const [physicalEntitiesSearchModalOpen, setPhysicalEntitiesSearchModalOpen] = useState(false);
  const history = useHistory();

  const editMode = deletePhysicalEntityFromList || updatePhysicalEntitiesList;
  const physicalEntitiesPresent =
    dataProductPort.physicalEntities && dataProductPort.physicalEntities.length > 0;

  return (
    <>
      {editMode || physicalEntitiesPresent ? (
        <h4>
          Physical Entities
          {editMode ? (
            <IconButton onClick={() => setPhysicalEntitiesSearchModalOpen(true)}>
              <AddIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </h4>
      ) : (
        <></>
      )}
      {physicalEntitiesPresent ? (
        <>
          <Table size='small' style={{ overflow: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell>System</TableCell>
                <TableCell>Schema</TableCell>
                <TableCell>Name</TableCell>
                {editMode ? <TableCell align='right'></TableCell> : <></>}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataProductPort.physicalEntities.map((pe, index) => (
                <TableRow
                  key={index}
                  hover={!editMode}
                  style={{ cursor: !editMode ? 'pointer' : 'inherit' }}
                  onClick={() => {
                    if (!editMode) {
                      history.push(`/physicalentities/${pe.uuid}`);
                    }
                  }}>
                  <TableCell>{_.truncate(pe.system.name, { length: 30 })}</TableCell>
                  <TableCell>{_.truncate(pe.schema, { length: 20 })}</TableCell>
                  <TableCell>{_.truncate(pe.name, { length: 40 })}</TableCell>
                  {editMode ? (
                    <TableCell align='right'>
                      <IconButton
                        size='small'
                        onClick={(event) => {
                          deletePhysicalEntityFromList(pe);
                          event.stopPropagation();
                        }}>
                        <ClearIcon />
                      </IconButton>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <></>
      )}
      <PhysicalEntitySearchModal
        open={physicalEntitiesSearchModalOpen}
        onCancel={() => setPhysicalEntitiesSearchModalOpen(false)}
        onSubmit={({ uuid, schema, name, system }) => {
          updatePhysicalEntitiesList({ uuid, schema, name, system });
          setPhysicalEntitiesSearchModalOpen(false);
        }}
      />
    </>
  );
};
