import { BlindataApi } from './ApiUtils';

const EXPORT_URL = '/api/v1/exports';

export default class ExportsApi extends BlindataApi {
  exportPhysicalFields(params) {
    return this.download(`${EXPORT_URL}/physicalFields`, { ...params, includeProperties: 'false' });
  }

  exportDataCategories(params) {
    return this.download('/api/v1/datacategories/*/export', {
      ...params,
      includeProperties: 'false'
    });
  }

  exportLogicalFields(params) {
    return this.download('/api/v1/logicalfields/*/export', {
      ...params,
      includeProperties: 'false'
    });
  }

  exportStewardshipResponsibilities(params) {
    return this.download(`${EXPORT_URL}/responsibilities`, {
      ...getParamsExportResponsibilities(params),
      includeProperties: 'false'
    });
  }

  exportStewardshipRoles(params) {
    return this.download(`${EXPORT_URL}/roles`, { ...params, includeProperties: 'false' });
  }
  exportStewardshipAssignments(params) {
    return this.download(`${EXPORT_URL}/assignments`, {
      stewardshipRoleUuid: params.stewardshipRole && params.stewardshipRole.uuid,
      userUuid: params.user && params.user.uuid
    });
  }
}

function getParamsExportResponsibilities(params) {
  return {
    page: params.page,
    size: params.size,
    userUuid: params.user && params.user.uuid,
    stewardshipRoleUuid: params.role && params.role.uuid,
    resourceType: params.resourceType,
    resourceIdentifier: params.resourceIdentifier,
    endDateIsNull: params.endDateIsNull
  };
}
