import templatesTasks from './templatesTasks';
import templatesProcessings from './templatesProcessings';
import templatesMappings from './templatesMappings';
import templatesEvents from './templatesEvents';
import { combineReducers } from 'redux';

export default combineReducers({
  templatesTasks,
  templatesProcessings,
  templatesMappings,
  templatesEvents
});
