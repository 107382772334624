import { showMessage } from 'actions/BehaviourActions';
import { verifyOIDCToken } from 'actions/LoginActions';
import React from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

function GoogleLoginWrapper({ setting }) {
  let dispatch = useDispatch();
  let history = useHistory();
  function onGoogleLoginSuccess(credentialResponse) {
    dispatch(
      verifyOIDCToken({ provider: 'google', idToken: credentialResponse.credential, history })
    );
  }
  function onGoogleLoginFailure({ error, description }) {
    console.error(error);
    dispatch(showMessage(description));
  }
  return (
    <div style={{ margin: 8, maxWidth: 214 }}>
      <GoogleOAuthProvider clientId={setting.clientId}>
        <GoogleLogin
          style={{ height: 42 }}
          onSuccess={onGoogleLoginSuccess}
          onFailure={onGoogleLoginFailure}
        />
      </GoogleOAuthProvider>
    </div>
  );
}

export default GoogleLoginWrapper;
