import React from 'react';
import { IconButton } from '@mui/material';

import GetAppIcon from '@mui/icons-material/GetApp';
import { exportClassificationRules } from '../../../../actions/RulesActions';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    filters: state.classification.rules.filters
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: (filters) => dispatch(exportClassificationRules({ ...filters }))
  };
}

function RulesExport({ filters, onClick, classes }) {
  return (
    <IconButton color='default' aria-label='Download' onClick={() => onClick(filters)} size='small'>
      <GetAppIcon />
    </IconButton>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RulesExport);
