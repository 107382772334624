import React from 'react';
import { Grid, Typography } from '@mui/material';
import DictionariesList from './DictionariesList';
import DictionariesFAB from './DictionariesFAB';
import DictionariesFilter from './DictionariesFilter';
import DictionariesExport from '../list/DictionariesExport';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

export default function DictionariesListPage() {
  return (
    <>
      <Breadcrumbs
        elements={[{ text: 'Classification', to: '/classification' }, { text: 'Dictionaries' }]}
      />
      <Tracking
        info={{
          pageTitle: `Dictionaries`,
          category: EngagementCategories.CLASSIFICATION,
          action: EngagementActions.LIST
        }}
      />
      <Grid container>
        <Grid style={{ flexGrow: 1 }} item>
          <Typography variant='h4'>Dictionaries</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:dictionaries-list'}
            iconSize={'small'}
            pageTitle={'Dictionaries'}
          />
        </Grid>
        <Grid item>
          <DictionariesExport />
        </Grid>
        <Grid item>
          <DictionariesFilter />
        </Grid>
      </Grid>
      <DictionariesList />
      <DictionariesFAB />
    </>
  );
}
