import { buildApiCallAction } from 'actions/ActionUtils';
import ProbesProjectsApi from 'api/quality/probes/ProbesProjectsApi';
import {
  FETCH_PROBE_PROJECTS_PAGE,
  FETCH_PROBE_PROJECT,
  CREATE_PROBE_PROJECT,
  MODIFY_PROBE_PROJECT,
  DELETE_PROBE_PROJECT,
  EXPORT_PROBE_PROJECTS
} from 'constants/ActionTypes';

const api = new ProbesProjectsApi();

export const fetchProbeProject = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getProject(uuid),
    actionType: FETCH_PROBE_PROJECT
  });

export const fetchProbeProjectsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getProjects(params),
    actionType: FETCH_PROBE_PROJECTS_PAGE
  });

export const createProbeProject = (project) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postProject(project),
    actionType: CREATE_PROBE_PROJECT
  });

export const modifyProbeProject = (project) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putProject(project),
    actionType: MODIFY_PROBE_PROJECT
  });

export const deleteProject = (project) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteProject(project),
    actionType: DELETE_PROBE_PROJECT
  });

export const exportProjects = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportProjects(params),
    actionType: EXPORT_PROBE_PROJECTS
  })
