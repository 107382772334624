import { fetchAll, handleErrors, parseJson, BlindataApi } from './ApiUtils';
import _ from 'lodash';
const defaultPageSize = 20;

export default class TaskAssignmentsApi extends BlindataApi {
  token = '';

  getTaskAssignmentsPage(params) {
    return this.get('/api/v1/taskAssignments', params);
  }

  getAllTaskAssignments({ dataActor, task, processing }) {
    return fetchAll((page) =>
      this.getTaskAssignments({ dataActor, task, processing, size: 1000 }, page)
    );
  }

  getTaskAssignments({ dataActor, task, processing, size = defaultPageSize }, page = 0) {
    let params = _({ dataActor, task, processing })
      .pickBy((val, key) => val)
      .map((val, key) => (val ? `${key + 'Uuid'}=${val.uuid}` : null))
      .compact()
      .join('&');

    return fetch(`/api/v1/taskAssignments?page=${page}&size=${size}&` + params, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson);
  }

  postTaskAssignment(taskAssignment) {
    return this.post(`/api/v1/taskAssignments`, taskAssignment);
  }

  putTaskAssignment(taskAssignment) {
    return this.put(`/api/v1/taskAssignments/${taskAssignment.uuid}`, taskAssignment);
  }

  deleteTaskAssignment(taskAssignment) {
    return this.delete(`/api/v1/taskAssignments/${taskAssignment.uuid}`);
  }

  getTaskAssignmentsRoles(params = {}) {
    return this.get(`/api/v1/taskAssignments/*/roles`, params);
  }

  exportTaskAssignments(params) {
    return this.download(`/api/v1/taskAssignments/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }
}
