import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router';
import { muiThemeV1 } from '../sidebar/SideBarMenuTheme';
import { ThemeProvider } from '@mui/material/styles';
import BehaviouralContainer from '../behavioural/BehaviouralContainer';
import DarkGradientBackground from './commons/DarkGradientBackground';
import LoginPage from './login/LoginPage';
import Reset from './reset/ResetFinish';
import ResetStart from './reset/ResetStart';
import ExtractAccessToken from './authorize/ExtractAccessToken';
import { AppVersion } from 'components/app_version/AppVersion';
import Footer from 'pages/public/commons/Footer';
import TermsAndConditionsPage from './terms_and_conditions/TermsAndConditionsPage';
import PrivacyPolicyPage from './privacy_policy/PrivacyPolicyPage';
import ApiDocsPage from 'pages/public/docs/ApiDocsPage';

export default function PublicRoutes(props) {
  let location = useLocation();
  return (
    <ThemeProvider theme={muiThemeV1}>
      <BehaviouralContainer>
        <DarkGradientBackground>
          <>
            <Footer />
            <AppVersion
              style={{ position: 'absolute', bottom: '10px', right: '10px', color: 'white' }}
            />
            <Switch>
              <Route exact path='/reset/finish/:resetKey' component={Reset}></Route>
              <Route path='/reset/init' component={ResetStart}></Route>
              <Route exact path='/terms-and-conditions' component={TermsAndConditionsPage}></Route>
              <Route exact path='/privacy-policy' component={PrivacyPolicyPage}></Route>
              <Route path='/login' component={LoginPage} />
              <Route path='/authorize/:provider' component={ExtractAccessToken} />
              <Route exact path='/docs' component={ApiDocsPage}></Route>
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location }
                }}></Redirect>
            </Switch>
          </>
        </DarkGradientBackground>
      </BehaviouralContainer>
    </ThemeProvider>
  );
}
