import { BlindataApi } from './ApiUtils';

export default class TeamsApi extends BlindataApi {
  getTeams(params) {
    return this.get('/api/v1/teams', params);
  }

  getTeam(code) {
    return this.get(`/api/v1/teams/${code}`);
  }

  postTeam(team) {
    return this.post('/api/v1/teams/', team);
  }
  putTeam(team) {
    return this.put(`/api/v1/teams/${team.code}`, team);
  }

  deleteTeam(code) {
    return this.delete(`/api/v1/teams/${code}`);
  }
}
