import { BlindataApi } from './ApiUtils';

const QUALITY_SUITES_URL = '/api/v1/data-quality/suites';

export default class QualitySuitesApi extends BlindataApi {
  getQualitySuites(params) {
    return this.get(QUALITY_SUITES_URL, params);
  }

  getQualitySuite(uuid) {
    return this.get(`${QUALITY_SUITES_URL}/${uuid}`);
  }

  postQualitySuite(qualitySuite) {
    return this.post(QUALITY_SUITES_URL, qualitySuite);
  }

  putQualitySuite(qualitySuite) {
    return this.put(`${QUALITY_SUITES_URL}/${qualitySuite.uuid}`, qualitySuite);
  }

  deleteQualitySuite(uuid) {
    return this.delete(`${QUALITY_SUITES_URL}/${uuid}`);
  }

  exportQualitySuites(params) {
    return this.download(`${QUALITY_SUITES_URL}/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }
}
