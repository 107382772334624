import React from 'react';
import { useTranslation } from 'react-i18next';

import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

import { Card, CardContent, Grid, Typography } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import IssueList from './IssueList';
import IssueFAB from './IssueFAB';
import IssueSearchBox from './IssueSearchBox';
import IssuesFiltersModal from './IssuesFiltersModal';
import IssueExportButton from './IssueExportButton';
import IssuePolicyLinkButton from 'pages/issueMngt/issuesPolicies/list/IssuePolicyLinkButton';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const IssuesListPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('navbar.issueMngt'), to: '/issue-management/my-work' },
          { text: t('navbar.issues') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('issueMngt.issues.header'),
          category: EngagementCategories.ISSUES,
          action: EngagementActions.LIST
        }}
      />
      <Grid container spacing={2} justifyContent='flex-start' alignItems='center'>
        <Grid item>
          <Typography variant='h4'>Issues</Typography>
        </Grid>
        <Grid item>
          <IssuePolicyLinkButton />
        </Grid>

        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <IssueSearchBox></IssueSearchBox>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:issue-list'}
            pageTitle={t('issueMngt.issues.header')}
            iconSize='small'
          />
        </Grid>
        <Grid item>
          <IssueExportButton></IssueExportButton>
        </Grid>
        <Grid item>
          <IssuesFiltersModal />
        </Grid>
        <Grid item>
          <WebActionsButton page={TargetPages.ISSUE_LIST_PAGE} />
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <IssueList></IssueList>
        </CardContent>
      </Card>

      <IssueFAB></IssueFAB>
    </>
  );
};

export default IssuesListPage;
