import React from 'react';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';
import ConfirmButton from '../../../components/confirmbutton/ConfirmButton';
import { Button, CardActions } from '@mui/material';
import ExpandableCard from '../../../components/expandable-card/ExpandableCard';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';

const ProcessingDisclaimerItem = ({ processing, disclaimer, onDelete, onCopy }) => (
  <ExpandableCard
    title={`Language: ${disclaimer.lang}, Version: ${disclaimer.version}`}
    subheader={`created at ${new Date(disclaimer.createdAt)}`}
    visibleChildren={
      <CardActions>
        <Authorize hasPermissions={[Permissions.PROCESSINGS_ADMIN]}>
          <Button onClick={() => onCopy(disclaimer)}>Copy</Button>
        </Authorize>

        <Authorize hasPermissions={[Permissions.PROCESSINGS_ADMIN]}>
          <ConfirmButton
            color='secondary'
            children={'Delete'}
            message={`Do you want to delete the disclaimer with title: '${disclaimer.title}', lang: '${disclaimer.lang}', version: '${disclaimer.version}'?`}
            onClick={onDelete}
          />
        </Authorize>
      </CardActions>
    }
    actions={[]}>
    <KVDefinitionList
      item={{
        Identifier: disclaimer.uuid,
        Title: disclaimer.title,
        Description: disclaimer.description
      }}
    />
  </ExpandableCard>
);

export default ProcessingDisclaimerItem;
