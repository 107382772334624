import React from 'react';
import { withOpenCloseHandler } from '../../../../components/openclosehandler/OpenCloseHandler';
import ResponsibilityModal from './ResponsibilityModal';
import {
  AuthorizedChipItem,
  AuthorizedChipWrapper
} from '../../../../components/chiplist/AuthorizedChipList';
import Permissions from '../../../../constants/Permissions';
import { Typography } from '@mui/material';

function ResponsibilityChipListEditor({
  values,
  onToggleOpen,
  isOpen,
  onDelete,
  onSubmit,
  role,
  resourceType,
  resourceIdentifier
}) {
  const responsibilityByChipEditor = {
    user: null,
    team: null,
    stewardshipRole: role,
    resourceType: resourceType,
    resourceIdentifier: resourceIdentifier,
    startDate: Date.now(),
    endDate: null
  };
  return (
    <React.Fragment>
      <AuthorizedChipWrapper
        permissionsFilters={{ onRequestAdd: [Permissions.STEWARDSHIP_EDITOR] }}
        onRequestAdd={() => onToggleOpen()}>
        {(values || []).map((item) =>
          item.user ? (
            <AuthorizedChipItem
              tooltip={
                <React.Fragment>
                  <Typography variant='subtitle1'>{item.user.username}</Typography>
                  {item.stewardshipRole.description}
                </React.Fragment>
              }
              permissionsFilters={{ onRequestDelete: [Permissions.STEWARDSHIP_EDITOR] }}
              key={item.user.uuid}
              id={item.user.uuid}
              label={item.user.displayName}
              onRequestDelete={() => {
                onDelete({ ...item, endDate: Date.now() });
              }}></AuthorizedChipItem>
          ) : (
            <AuthorizedChipItem
              tooltip={
                <React.Fragment>
                  <Typography variant='subtitle1'>{`Team:${item.assigneeTeam.code}`}</Typography>
                  {item.stewardshipRole.description}
                </React.Fragment>
              }
              permissionsFilters={{ onRequestDelete: [Permissions.STEWARDSHIP_EDITOR] }}
              key={item.assigneeTeam.sequenceId}
              id={item.assigneeTeam.sequenceId}
              label={item.assigneeTeam.name}
              onRequestDelete={() => {
                onDelete({ ...item, endDate: Date.now() });
              }}></AuthorizedChipItem>
          )
        )}
      </AuthorizedChipWrapper>
      {isOpen && (
        <ResponsibilityModal
          isChipEditor={true}
          responsibility={responsibilityByChipEditor}
          open={isOpen}
          setOpen={() => onToggleOpen()}
          onSubmit={(item) => {
            onSubmit(item);
            onToggleOpen();
          }}></ResponsibilityModal>
      )}
    </React.Fragment>
  );
}

export default withOpenCloseHandler(ResponsibilityChipListEditor);
