import React from 'react';
import { useLocation } from 'react-router';
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import ToolbarActions from 'pages/sidebar/ToolbarActions';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';

const PhysicalModelNavbar = ({ t }) => {
  const { pathname } = useLocation();
  const systemsPathname = '/systems';
  const physicaleEntitiesPathname = '/physicalentities';
  const dataProductsPathname = '/dataproducts/list';

  return (
    <ToolbarActions>
      <Button
        color={pathname === systemsPathname ? 'primary' : 'grey'}
        children={t('navbar.systems')}
        component={Link}
        to={systemsPathname}
      />
      <Button
        color={pathname === physicaleEntitiesPathname ? 'primary' : 'grey'}
        children={t('navbar.physicalEntities')}
        component={Link}
        to={physicaleEntitiesPathname}
      />
      <Authorize hasPermissions={[Permissions.DATA_PRODUCTS_VIEWER]}>
        <Button
          color={pathname === dataProductsPathname ? 'primary' : 'grey'}
          children={t('navbar.dataProducts')}
          component={Link}
          to={dataProductsPathname}
        />
      </Authorize>
    </ToolbarActions>
  );
};

export default withTranslation()(PhysicalModelNavbar);
