import ClassificationDictionariesApi from 'api/DictionaryApi';
import AbstractImporter from './AbstractImporter';
import RulesApi from 'api/RulesApi';
import AssignmentsApi from 'api/AssignmentsApi';

export default class ImporterClassification extends AbstractImporter {
  static isSupported(object) {
    return (
      object.classificationRule ||
      object.classificationDictionary ||
      object.classificationAssignmentPatch
    );
  }

  async importObject(object, resolveUuidOnly = false) {
    if (object.classificationDictionary) {
      return this.importDictionary(object.classificationDictionary, resolveUuidOnly);
    } else if (object.classificationRule) {
      return this.importRule(object.classificationRule, resolveUuidOnly);
    } else if (object.classificationAssignmentPatch) {
      return this.patchAssignment(object.classificationAssignmentPatch, resolveUuidOnly);
    } else {
      throw new Error('Object type not supported by ImporterClassification');
    }
  }

  async importDictionary(dictionary, resolveUuidOnly = false) {
    let api = new ClassificationDictionariesApi();
    return this.importTemplate({
      object: dictionary,
      objectName: 'name',
      objectIdentifier: 'uuid',
      api,
      search: api.getAllDictionaries,
      post: api.postDictionary,
      put: api.putDictionary,
      delet: api.deleteDictionary,
      resolveUuidOnly
    });
  }

  async importRule(rule, resolveUuidOnly = false) {
    let api = new RulesApi();
    let reconciledRule = rule;
    if (!resolveUuidOnly) {
      reconciledRule = {
        ...rule,
        dictionary: await this.importDictionary(rule.dictionary, true)
      };
    }
    return this.importTemplate({
      object: reconciledRule,
      objectName: 'name',
      objectIdentifier: 'uuid',
      api,
      search: (params) => api.getAllRules({ ...params }),
      post: api.postRule,
      put: api.putRule,
      delet: api.deleteRule,
      resolveUuidOnly
    });
  }

  async patchAssignment(assignment, resolveUuidOnly = false) {
    let api = new AssignmentsApi();
    let reconciledAssignment = assignment;
    return this.importTemplate({
      object: reconciledAssignment,
      objectName: null,
      objectIdentifier: 'sequenceId',
      api,
      put: api.patchAssignment
    });
  }
}
