import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Paper,
  Tooltip,
  Typography,
  Grid
} from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ForwardRoundedIcon from '@mui/icons-material/ForwardRounded';
import { fetchQualityResultsPage } from '../../../../../actions/QualityResultsActions';
import PaginatedPanel from '../../../../../components/pagination/PaginatedPanel';
import QualitySemaphoreIcon from '../commons/QualitySemaphoreIcon';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';
import ResourceTypes from 'constants/ResourceTypes';
import { fetchDashboardTypeCounterIfAllowedLargeRequest } from 'actions/IssueMngtDashboardsActions';

function QualityResultsListTab({
  qualityCheck,
  currentPage,
  fetchQualityResultsPage,
  filters,
  fetchDashboardTypeCounterIfAllowedLargeRequest
}) {
  useEffect(
    function () {
      fetchQualityResultsPage({ checkUuid: qualityCheck.uuid, page: 0, ...filters }).then(
        (response) =>
          response &&
          response.content.length > 0 &&
          fetchDashboardTypeCounterIfAllowedLargeRequest(
            response.content,
            25,
            ResourceTypes.QUALITY_RESULT
          )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, fetchQualityResultsPage]
  );
  return (
    <List>
      <PaginatedPanel
        currentPage={currentPage}
        onPageSelection={(page) =>
          fetchQualityResultsPage({ page: page, checkUuid: qualityCheck.uuid, ...filters }).then(
            (response) =>
              response &&
              response.content.length > 0 &&
              fetchDashboardTypeCounterIfAllowedLargeRequest(
                response.content,
                25,
                ResourceTypes.QUALITY_RESULT
              )
          )
        }>
        {currentPage?.content.length === 0 ? (
          <Typography variant='subtitle2' align='center' style={{ marginTop: '20px' }}>
            No data
          </Typography>
        ) : (
          currentPage?.content.map((result) => QualityResultPaper(result))
        )}
      </PaginatedPanel>
    </List>
  );

  function QualityResultPaper(result) {
    let history = useHistory();

    return (
      <Paper key={result.uuid} style={{ marginTop: '10px' }}>
        <Typography color='textSecondary' style={{ margin: '1em' }} variant='caption'>
          Execution date: {moment(result.startedAt).format('DD MMMM YYYY, HH:mm:ss')}
        </Typography>
        <ListItem key={result.uuid}>
          <ListItemAvatar>
            <QualitySemaphoreIcon semaphore={result.semaphore} />
          </ListItemAvatar>
          <Box display='flex' justifyContent='space-around' width='100%'>
            <Typography variant='subtitle2'>Metric: {result.metric}</Typography>
            {result.totalElements && (
              <Typography variant='subtitle2'>Total elements: {result.totalElements}</Typography>
            )}
            {!result.totalElements && (
              <Typography variant='subtitle2'>Total elements: -</Typography>
            )}
            <Typography variant='subtitle2'>
              Quality score: {getRoundedQualityScore(result.score)}
            </Typography>
            <div>
              <Grid container alignItems='center'>
                <Grid item>
                  <IssueResourceListItemWidget
                    resourceIdentifier={result.uuid}
                    resourceType={ResourceTypes.QUALITY_RESULT}></IssueResourceListItemWidget>
                </Grid>
              </Grid>
            </div>
          </Box>

          <Tooltip title={'Detail'}>
            <IconButton
              color='primary'
              onClick={() => history.push(`/data-quality/kqis/results/${result.uuid}`)}
              size="large">
              <ForwardRoundedIcon style={{ color: 'gray' }}></ForwardRoundedIcon>
            </IconButton>
          </Tooltip>
        </ListItem>
      </Paper>
    );
  }
}

function getRoundedQualityScore(score) {
  return Math.round((score + Number.EPSILON) * 100) / 100;
}

function mapStateToProps(state) {
  return {
    currentPage: state.quality.qualityResults.currentPage,
    filters: state.quality.qualityResults.filters.content
  };
}

export default connect(mapStateToProps, {
  fetchQualityResultsPage,
  fetchDashboardTypeCounterIfAllowedLargeRequest
})(QualityResultsListTab);
