import * as React from "react";
const SvgExcel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2289.75 2130"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="#185C37"
      d="M1437.75 1011.75 532.5 852v1180.393c0 53.907 43.7 97.607 97.607 97.607h1562.036c53.907 0 97.607-43.7 97.607-97.607V1597.5l-852-585.75z"
    />
    <path
      fill="#21A366"
      d="M1437.75 0H630.107C576.2 0 532.5 43.7 532.5 97.607V532.5l905.25 532.5L1917 1224.75 2289.75 1065V532.5L1437.75 0z"
    />
    <path fill="#107C41" d="M532.5 532.5h905.25V1065H532.5V532.5z" />
    <path
      opacity={0.1}
      d="M1180.393 426H532.5v1331.25h647.893c53.834-.175 97.432-43.773 97.607-97.607V523.607c-.175-53.834-43.773-97.432-97.607-97.607z"
    />
    <path
      opacity={0.2}
      d="M1127.143 479.25H532.5V1810.5h594.643c53.834-.175 97.432-43.773 97.607-97.607V576.857c-.175-53.834-43.773-97.432-97.607-97.607z"
    />
    <path
      opacity={0.2}
      d="M1127.143 479.25H532.5V1704h594.643c53.834-.175 97.432-43.773 97.607-97.607V576.857c-.175-53.834-43.773-97.432-97.607-97.607z"
    />
    <path
      opacity={0.2}
      d="M1073.893 479.25H532.5V1704h541.393c53.834-.175 97.432-43.773 97.607-97.607V576.857c-.175-53.834-43.773-97.432-97.607-97.607z"
    />
    <linearGradient
      id="Excel_svg__a"
      gradientUnits="userSpaceOnUse"
      x1={203.513}
      y1={1729.018}
      x2={967.987}
      y2={404.982}
      gradientTransform="matrix(1 0 0 -1 0 2132)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#18884f",
        }}
      />
      <stop
        offset={0.5}
        style={{
          stopColor: "#117e43",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#0b6631",
        }}
      />
    </linearGradient>
    <path
      fill="url(#Excel_svg__a)"
      d="M97.607 479.25h976.285c53.907 0 97.607 43.7 97.607 97.607v976.285c0 53.907-43.7 97.607-97.607 97.607H97.607C43.7 1650.75 0 1607.05 0 1553.143V576.857c0-53.907 43.7-97.607 97.607-97.607z"
    />
    <path
      fill="#FFF"
      d="m302.3 1382.264 205.332-318.169L319.5 747.683h151.336l102.666 202.35c9.479 19.223 15.975 33.494 19.49 42.919h1.331a798.667 798.667 0 0 1 21.3-44.677L725.371 747.79H864.3l-192.925 314.548L869.2 1382.263H721.378L602.79 1160.158a186.298 186.298 0 0 1-14.164-29.66h-1.757a140.458 140.458 0 0 1-13.739 28.755l-122.102 223.011H302.3z"
    />
    <path
      fill="#33C481"
      d="M2192.143 0H1437.75v532.5h852V97.607C2289.75 43.7 2246.05 0 2192.143 0z"
    />
    <path fill="#107C41" d="M1437.75 1065h852v532.5h-852V1065z" />
  </svg>
);
export default SvgExcel;
