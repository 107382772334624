import React from 'react';
import ProposalEditor from './ProposalEditor';
import {
  setProposalEditor,
  createProposal,
  modifyProposal,
  fetchProposal,
  clearProposalEditor
} from '../../../../actions/ProposalsActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { withLoader } from '../../../../components/loader/Loader';

function ProposalEditorPage({
  editor,
  setProposalEditor,
  clearProposalEditor,
  createProposal,
  modifyProposal,
  fetchProposal
}) {
  let history = useHistory();
  const handleSubmit = func => proposal =>
    func(proposal).then(proposal => {
      if (proposal) {
        clearProposalEditor();
        history.push(`/workflows/proposals/${proposal.uuid}`);
      }
    });

  const handleCancel = () => {
    clearProposalEditor();
    history.goBack();
  };

  return (
    <ProposalEditor
      editor={editor}
      onCancel={handleCancel}
      onChange={setProposalEditor}
      onCreate={handleSubmit(createProposal)}
      onModify={handleSubmit(modifyProposal)}
      onMoveToProposal={proposal => fetchProposal(proposal.uuid).then(setProposalEditor)}
    />
  );
}

const mapStateToProps = (state, props) => ({
  editor: state.workflows.proposals.editor,
  proposalUuid: props.match.params.proposalUuid
});

const mapDispatchToProps = {
  setProposalEditor,
  clearProposalEditor,
  createProposal,
  modifyProposal,
  fetchProposal
};

function onLoad({ proposalUuid, fetchProposal, setProposalEditor }) {
  return proposalUuid && fetchProposal(proposalUuid).then(setProposalEditor);
}

function ready({ editor, proposalUuid }) {
  return proposalUuid ? editor.uuid === proposalUuid : true;
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({ onLoad, ready })
)(ProposalEditorPage);
