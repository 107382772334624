import { Grid, Typography } from '@mui/material';
import Tracking from 'components/engagement/Tracking';
import EngagementActions from 'constants/EngagementActions';
import EngagementCategories from 'constants/EngagementCategories';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import { Breadcrumbs } from 'pages/sidebar/Navigation';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QueryBucketCreateFAB from './QueryBucketCreateFAB';
import QueryBucketList from './QueryBucketsList';
import QueryBucketsSearchBox from './QueryBucketsSearchBox';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const QueryBucketListPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.data_flows'), to: '/dataflows' },
          { text: t('navbar.queryparser') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('querybuckets.header'),
          category: EngagementCategories.QUERY_PARSER,
          action: EngagementActions.LIST
        }}
      />
      <Grid justifyContent='flex-end' container alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>{t('querybuckets.header')}</Typography>
        </Grid>
        <Grid item>
          <QueryBucketsSearchBox />
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:querybuckets-list'}
            iconSize={'small'}
            pageTitle={t('querybuckets.header')}
          />
        </Grid>
        <Grid item>
          <WebActionsButton page={TargetPages.QUERY_BUCKET_LIST_PAGE} />
        </Grid>
      </Grid>
      <QueryBucketList />
      <QueryBucketCreateFAB />
    </>
  );
};

export default QueryBucketListPage;
