import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import QualityKqisNavbar from 'pages/quality/kqis/QualityKqisNavbar';
import QualitySuitesListPage from 'pages/quality/kqis/quality_suites/list/QualitySuitesListPage';
import QualitySuiteDetailPage from 'pages/quality/kqis/quality_suites/detail/QualitySuiteDetailPage';
import QualityCheckDetailPage from 'pages/quality/kqis/quality_checks/detail/QualityCheckDetailPage';
import DataQualityDasboardsPage from 'pages/quality/kqis/dashboards/DataQualityDashboardsPage';
import QualityResultDetailPage from './quality_checks/detail/QualityResultDetailPage';

export default function DataQualityRoutes(props) {
  return (
    <>
      <QualityKqisNavbar />
      <Switch>
        <Route exact path={`${props.match.url}/suites`} component={QualitySuitesListPage} />
        <Route path={`${props.match.url}/suites/:uuid`} component={QualitySuiteDetailPage} />
        <Route path={`${props.match.url}/checks/:uuid`} component={QualityCheckDetailPage} />
        <Route path={`${props.match.url}/results/:uuid`} component={QualityResultDetailPage} />

        <Route path={`${props.match.url}/dashboards`} component={DataQualityDasboardsPage} />
        <Redirect to={`${props.match.url}/suites`} />
      </Switch>
    </>
  );
}
