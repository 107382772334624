import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ChipItem } from 'components/chiplist/ChipList';

import { fetchProbeDefinitionsPage } from 'actions/quality/probes/ProbesDefinitionsActions';
import { Typography } from '@mui/material';

function QualityProbesDefinitionAssociatedCheck({
  checkCode,
  checkGroupCode,
  currentPage,
  rootUuid,
  fetchProbeDefinitionsPage
}) {
  useEffect(() => {
    if (checkCode || checkGroupCode) {
      fetchProbeDefinitionsPage({ checkCode: checkCode, checkGroupCode: checkGroupCode });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProbeDefinitionsPage]);
  if (!currentPage) {
    return null;
  }
  return (
    <>
      <h4 style={{ flexGrow: 1 }}>Associated Probes</h4>
      {currentPage.content.length > 0 ? (
        <React.Fragment>
          {currentPage.content.map((probe) => (
            <ChipItem
              size={'small'}
              label={probe.name}
              component={Link}
              to={`/data-quality/probes/definitions/${rootUuid}/versions/${probe.sequenceId}`}
            />
          ))}
        </React.Fragment>
      ) : (
        <Typography variant='body2' align='center' style={{ textAlign: 'center', padding: 4 }}>
          No probes associated for this check.
        </Typography>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  currentPage: state.quality.qualityProbes.definitions.currentPage
});

const mapDispatchToProps = {
  fetchProbeDefinitionsPage
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityProbesDefinitionAssociatedCheck);
