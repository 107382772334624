import React from 'react';

import Typography from '@mui/material/Typography';

export const Decorator = ({ text }) => {
  return (
    <Typography
      variant='overline'
      sx={{
        borderStyle: 'dashed',
        borderWidth: '1px',
        borderRadius: 2,
        borderColor: (props) => props.borderColor || 'grey',
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
        padding: 1,
        margin: 1,
        color: 'grey',
        lineHeight: 1
      }}>
      {text}
    </Typography>
  );
};
