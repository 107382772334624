import React from 'react';
import hljsStyle from 'react-syntax-highlighter/dist/cjs/styles/hljs/idea';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/cjs/languages/hljs/json';

SyntaxHighlighter.registerLanguage('sql', json);

function JsonVisualizer({ object, style }) {
  return (
    <SyntaxHighlighter
      language='json'
      style={hljsStyle}
      customStyle={{ whiteSpace: 'pre-wrap', ...style }}>
      {JSON.stringify(object, null, 2)}
    </SyntaxHighlighter>
  );
}

export default JsonVisualizer;
