import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { runJobByUuid, runJob } from 'actions/onagent/AgentJobRunnerActions';
import { connect } from 'react-redux';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import JsonVisualizer from 'components/syntaxhighlithter/JsonVisualizer';

const bodyStyle = {
  border: '1px solid',
  borderColor: 'lightgrey',
  borderRadius: '0.5em',
  padding: '1em',
  overflowX: 'auto',
  minWidth: '95%',
  maxWidth: '100%'
};

function AgentGenericJobRunButton({
  jobDefinition,
  onlyTest,
  agent,
  runJobByUuid,
  runJob,
  isJsonValid
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const { t } = useTranslation();
  return (
    <>
      <Button onClick={() => setOpen(true)} disabled={!jobDefinition?.jobDetails?.configs}>
        {onlyTest ? t('commons.actions.test') : t('commons.actions.run')}
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>
          <Typography variant='h6'>Running Job</Typography>
          {jobDefinition && (
            <Typography color='textSecondary' variant='subtitle2'>
              You are running "{jobDefinition?.name}"
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              {!loading && !result && (
                <Typography variant='body2' style={{ textAlign: 'center', marginTop: '1.5em' }}>
                  Start the run to get some results!
                </Typography>
              )}
              {loading && (
                <CircularProgress style={{ marginLeft: 'calc(50% - 20px)', marginTop: '1.5em' }} />
              )}
              {result && <ResultVisualizer result={result} />}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setResult(null);
            }}>
            {t('commons.actions.cancel')}
          </Button>
          <Button
            disabled={!isJsonValid}
            onClick={() => {
              if (loading) return;
              setLoading(true);
              setResult(null);
              let jobDetails = {
                ...jobDefinition.jobDetails,
                configs: {
                  ...jobDefinition.jobDetails.configs,
                  outputOptions: {
                    ...jobDefinition.jobDetails.configs.outputOptions,
                    dryRun: true
                  }
                }
              };
              runJob(agent, jobDetails).then((response) => {
                setResult(response);
                setLoading(false);
              });
            }}>
            {t('commons.actions.test')}
          </Button>
          {!onlyTest && (
            <ConfirmButton
              variant='contained'
              color='primary'
              disabled={loading}
              onClick={() => {
                if (loading) return;
                setLoading(true);
                setResult(null);
                runJobByUuid(agent, jobDefinition.uuid).then((response) => {
                  setResult(response);
                  setLoading(false);
                });
              }}>
              {t('commons.actions.run')}
            </ConfirmButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

function ResultVisualizer({ result }) {
  return (
    <>
      {result ? (
        <>
          <h4>Result</h4>
          <JsonVisualizer object={result} style={bodyStyle} />
        </>
      ) : (
        <Typography variant='overline'>No results extracted</Typography>
      )}
    </>
  );
}
export default connect(null, { runJobByUuid, runJob })(AgentGenericJobRunButton);
