import React from 'react';
import ActionProcessing from '@mui/icons-material/GavelTwoTone';
import SystemIcon from '@mui/icons-material/StorageRounded';
import PhysicalEntityIcon from '@mui/icons-material/TableChartOutlined';
import PhysicalFieldIcon from '@mui/icons-material/NoteSharp';
import ActionUsers from '@mui/icons-material/SupervisorAccountTwoTone';
import ActionWork from '@mui/icons-material/WorkOutlineTwoTone';
import ActionDataCategories from '@mui/icons-material/FolderOpenTwoTone';
import ActionLogicalField from '@mui/icons-material/Extension';
import ActionQualitySuite from '@mui/icons-material/NetworkCheckTwoTone';
import ActionQualityCheck from '@mui/icons-material/VerifiedUser';
import ActionQueryBucket from '@mui/icons-material/DynamicFeedOutlined';
import ActionQualityProbesDefinition from '@mui/icons-material/SettingsInputComponent';
import ActionQualityProbesProject from '@mui/icons-material/Ballot';
import ActionDataProduct from '@mui/icons-material/Category';
import ResourceTypes from 'constants/ResourceTypes';

export const ResourceTypesIcons = {
  [ResourceTypes.DATA_CATEGORY]: <ActionDataCategories style={{ color: '#4248f4' }} />,
  [ResourceTypes.LOGICAL_FIELD]: <ActionLogicalField style={{ color: '#4a9cad' }} />,
  [ResourceTypes.SYSTEM]: <SystemIcon style={{ color: '6b6b6b' }} />,
  [ResourceTypes.PHYSICAL_ENTITY]: <PhysicalEntityIcon style={{ color: '#383838' }} />,
  [ResourceTypes.PHYSICAL_FIELD]: <PhysicalFieldIcon style={{ color: '#4a3c05' }} />,
  [ResourceTypes.PROCESSING]: <ActionProcessing style={{ color: '#ffbc38' }} />,
  [ResourceTypes.DATA_ACTOR]: <ActionUsers style={{ color: '#FF00CC' }} />,
  [ResourceTypes.TASK]: <ActionWork style={{ color: '#ff8e38' }} />,
  [ResourceTypes.QUALITY_SUITE]: <ActionQualitySuite style={{ color: '#acd079' }} />,
  [ResourceTypes.QUALITY_CHECK]: <ActionQualityCheck style={{ color: '#7ac215' }} />,
  [ResourceTypes.QUERY_BUCKET]: <ActionQueryBucket />,
  [ResourceTypes.QUALITY_PROBE_DEFINITION]: (
    <ActionQualityProbesDefinition style={{ color: '#4884ff' }} />
  ),
  [ResourceTypes.QUALITY_PROBE_PROJECT]: (
    <ActionQualityProbesProject style={{ color: '#91b6ff' }} />
  ),
  [ResourceTypes.DATA_PRODUCT]: <ActionDataProduct />
};
