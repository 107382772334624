import { BlindataApi } from './ApiUtils';

class CustomRolesApi extends BlindataApi {
  getCustomRoles({ page, size }) {
    return this.get(`/api/v1/settings/custom_roles`, { page, size });
  }

  createCustomRole(customRole) {
    return this.post(`/api/v1/settings/custom_roles`, customRole);
  }

  deleteCustomRole(uuid) {
    return this.delete(`/api/v1/settings/custom_roles/${uuid}`);
  }
}

export default CustomRolesApi;
