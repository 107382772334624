import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { modifyLogicalRelation, deleteLogicalRelation } from 'actions/LogicalRelationsActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ConfirmMenuItem from 'components/confirmbutton/ConfirmMenuItem';
import LogicalRelationModal from './LogicalRelationModal';
import AuthorizeTeamStewardship from 'components/permissions/AuthorizeTeamStewardship';
import ResourceTypes from 'constants/ResourceTypes';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import Permissions from 'constants/Permissions';

const LogicalRelationsCardActions = ({
  logicalRelation,
  t,
  modifyLogicalRelation,
  deleteLogicalRelation
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  let isOpen = Boolean(anchorEl);
  let { fromDataCategory: dataCategory, fromLogicalField: logicalField } = logicalRelation;
  return (
    <AuthorizeTeamStewardship
      resourceType={dataCategory ? ResourceTypes.DATA_CATEGORY : ResourceTypes.LOGICAL_FIELD}
      resourceIdentifier={dataCategory?.uuid || logicalField?.uuid}
      hasPermissions={[Permissions.DATA_CATEGORIES_EDITOR]}
      teamCode={dataCategory?.teamCode || logicalField?.dataCategory?.teamCode}
      teamAccessLevels={[TeamAccessLevels.WRITE]}>
      <IconButton
        size='small'
        aria-haspopup='true'
        aria-controls='logical-relations-card-actions'
        onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='logical-relations-table-actions'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {isOpen && (
          //   <Authorize hasPermissions={[Permissions.CLASSIFICATION_EDITOR]}>
          <MenuItem
            onClick={(event) => {
              setOpenEdit(true);
              setAnchorEl(null);
              event.stopPropagation();
            }}>
            {t('commons.actions.modify')}
          </MenuItem>
          //   </Authorize>
        )}
        {isOpen && (
          //   <Authorize hasPermissions={[Permissions.CLASSIFICATION_EDITOR]}>
          <ConfirmMenuItem
            message={'Do you really want to delete this logical relation?'}
            onClick={() => {
              setAnchorEl(null);
              deleteLogicalRelation(logicalRelation);
            }}
            onClose={() => setAnchorEl(null)}>
            {t('commons.actions.delete')}
          </ConfirmMenuItem>
          //   </Authorize>
        )}
      </Menu>

      <LogicalRelationModal
        logicalRelation={logicalRelation}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        onSubmit={(logicalRelation) => {
          setOpenEdit(false);
          modifyLogicalRelation(logicalRelation);
        }}></LogicalRelationModal>
    </AuthorizeTeamStewardship>
  );
};

export default compose(
  withTranslation(),
  connect(null, { modifyLogicalRelation, deleteLogicalRelation })
)(LogicalRelationsCardActions);
