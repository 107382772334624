import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import UserItem from './UserItem';
import { fetchUsersPage } from 'actions/UsersActions';
import { connect } from 'react-redux';
import PaginatedPanel from 'components/pagination/PaginatedPanel';
import { Typography } from '@mui/material';

function UsersList({ page, filters, fetchUsersPage }) {
  const onFetch = useCallback(
    (page, size = 10) => fetchUsersPage(page, size, filters),
    [fetchUsersPage, filters]
  );
  return (
    <PaginatedPanel currentPage={page} onPageSelection={onFetch} dependencies={[filters]} autoload>
      {page.content.length === 0 && (
        <div style={{ textAlign: 'center', padding: '5%' }}>
          <Typography variant='body1'>No users found</Typography>
        </div>
      )}
      {page.content.map((user) => (
        <UserItem key={user.uuid} user={user} />
      ))}
    </PaginatedPanel>
  );
}

UsersList.propTypes = {
  page: PropTypes.object,
  fetchUsersPage: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
  page: state.settings.users.currentPage,
  filters: state.settings.users.filters.content
});

export default connect(mapStateToProps, {
  fetchUsersPage
})(UsersList);
