import React from 'react';
import { withTranslation } from 'react-i18next';

import KVDefinitionList from 'components/definition-list/KVDefinitionList';

function GeneralSection({ routine, t }) {
  if (!routine) {
    return null;
  }
  return (
    <>
      <div className='dls-wrapper'>
        <KVDefinitionList
          header={t('routines.detail.general.title')}
          item={{
            ...{
              [t('routines.detail.general.name')]: routine?.name,
              [t('routines.detail.general.system')]: routine?.system.name,
              [t('routines.detail.general.strategy')]: routine?.analysisStrategy,
              [t('routines.detail.general.type')]: routine?.type
            },
            ...(routine?.createdAt && {
              [t('routines.detail.general.createdAt')]: new Date(
                routine?.createdAt
              ).toLocaleDateString()
            }),
            ...(routine?.updatedAt && {
              [t('routines.detail.general.updatedAt')]: new Date(
                routine?.updatedAt
              ).toLocaleDateString()
            })
          }}
        />
      </div>
    </>
  );
}

export default withTranslation()(GeneralSection);
