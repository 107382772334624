import React from 'react';
import ToolbarActions from '../sidebar/ToolbarActions';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useRouteMatch, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

export default function ClassificationNavbar(props) {
  let { url } = useRouteMatch();
  let { t } = useTranslation();
  let { pathname } = useLocation();
  const rulesUrl = `${url}/rules`;
  const dictionariesUrl = `${url}/dictionaries`;
  const assignmentsUrl = `${url}/assignments`;
  return (
    <ToolbarActions>
      <Button
        color={pathname === rulesUrl ? 'primary' : 'grey'}
        children={t('navbar.rules')}
        component={Link}
        to={rulesUrl}
      />
      <Button
        color={pathname === dictionariesUrl ? 'primary' : 'grey'}
        children={t('navbar.dictionaries')}
        component={Link}
        to={dictionariesUrl}
      />
      <Button
        color={pathname === assignmentsUrl ? 'primary' : 'grey'}
        children={t('navbar.assignments')}
        component={Link}
        to={assignmentsUrl}
      />
    </ToolbarActions>
  );
}
