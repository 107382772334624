import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import { formatDate } from 'pages/history/commons/HistoryRecordTable';
import React from 'react';
import BuildIcon from '@mui/icons-material/Build';
import { DataProductPortPhysicalEntitiesList } from '../list/DataProductPortPhysicalEntitiesList';
import CustomPropertiesView from 'components/additional_properties/CustomPropertiesView';
import { DataProductPortDependency } from './DataProductPortDependency';
import { DataProductPortEntityTypesIcons } from 'constants/DataProductPortEntityTypes';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const DataProductPortModal = ({ open, handleClose, port }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg'>
      <DialogTitle sx={{ display: 'flex', alignContent: 'center' }}>
        {DataProductPortEntityTypesIcons[port.entityType] || <BuildIcon />}
        <div style={{ paddingLeft: '8px' }}>{port.displayName.replace(/(.{52})/g, '$1\n')}</div>
      </DialogTitle>
      <DialogContent>
        <Typography variant='description'>{port.description}</Typography>
        <Grid container>
          <Grid item lg={6} md={12} xs={12}>
            <KVDefinitionList
              header='General'
              item={{
                'Entity Type': port?.entityType,
                Name: port?.name,
                Display: port?.displayName,
                Identifier: port?.identifier,
                'Services Type': port?.servicesType
              }}
            />
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <KVDefinitionList
              header='Versioning'
              item={{
                Version: port?.version,
                'Created At': formatDate(port?.createdAt),
                'Updated At': formatDate(port?.updatedAt)
              }}
            />
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            {port.additionalProperties && (
              <CustomPropertiesView additionalProperties={port.additionalProperties} />
            )}
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            {port.dependsOnIdentifier ? (
              <DataProductPortDependency
                portIdentifier={port.dependsOnIdentifier}
                onClick={() => handleClose()}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <DataProductPortPhysicalEntitiesList dataProductPort={port} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Close</Button>
        <div style={{ marginLeft: 'auto' }}>
          <WebActionsButton resource={port} page={TargetPages.DATA_PRODUCT_DETAIL_PAGE} />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DataProductPortModal;
