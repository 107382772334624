import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import _ from 'lodash';

import { Add, Delete } from '@mui/icons-material';
import TableCell from 'components/tables/GenericTableCell';
import SystemItemAutoComplete from 'pages/systems/commons/SystemItemAutoComplete';
import PhysicalEntityItemAutoComplete from 'pages/systems/physical_entities/commons/PhysicalEntityItemAutoComplete';
import PhysicalFieldItemSelect from 'pages/systems/physical_entities/commons/PhysicalFieldItemSelect';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

function QualityCheckPhysicalAssociationTable({ qualityCheck, onChange }) {
  let history = useHistory();
  let physicalEntities = qualityCheck.physicalEntities || [];
  let physicalFields = qualityCheck.physicalFields || [];
  return (
    <>
      <Toolbar style={{ padding: 0 }}>
        <h4 style={{ flexGrow: 1 }}>Physical associations</h4>
        {onChange && (
          <AddAction
            onAddPhysicalEntity={(pe) =>
              onChange({ ...qualityCheck, physicalEntities: physicalEntities.concat([pe]) })
            }
            onAddPhysicalField={(pf) =>
              onChange({ ...qualityCheck, physicalFields: physicalFields.concat([pf]) })
            }
          />
        )}
      </Toolbar>
      {physicalEntities.length === 0 && physicalFields.length === 0 && (
        <Typography variant='body2' align='center' style={{ textAlign: 'center', padding: 4 }}>
          No physical associations defined for this check.
        </Typography>
      )}
      {(physicalEntities.length > 0 || physicalFields.length > 0) && (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>System</TableCell>
              <TableCell>Entity Schema</TableCell>
              <TableCell>Entity Name</TableCell>
              <TableCell>Field</TableCell>
              {onChange && <TableCell padding='checkbox'></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {physicalEntities.map((pe) => (
              <TableRow
                hover={!onChange}
                style={!onChange ? { cursor: 'pointer' } : {}}
                key={pe.uuid}
                onClick={!onChange ? () => history.push(`/physicalentities/${pe.uuid}`) : null}>
                <TableCell>{pe.system.name}</TableCell>
                <TableCell>{pe.schema || '-'}</TableCell>
                <TableCell>{pe.name}</TableCell>
                <TableCell>-</TableCell>
                {onChange && (
                  <TableCell padding='checkbox'>
                    <IconButton
                      size='small'
                      onClick={() =>
                        onChange({
                          ...qualityCheck,
                          physicalEntities: physicalEntities.filter((e) => e.uuid !== pe.uuid)
                        })
                      }>
                      <Delete></Delete>
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {physicalFields.map((pf) => (
              <TableRow
                key={pf.uuid}
                hover={!onChange}
                style={!onChange ? { cursor: 'pointer' } : {}}
                onClick={
                  !onChange ? () => history.push(`/systems/physical_fields/${pf.uuid}`) : null
                }>
                <TableCell>{pf.physicalEntity.system.name}</TableCell>
                <TableCell>{pf.physicalEntity.schema || '-'}</TableCell>
                <TableCell>{pf.physicalEntity.name}</TableCell>
                <TableCell>{pf.name}</TableCell>
                {onChange && (
                  <TableCell>
                    <IconButton
                      size='small'
                      onClick={() =>
                        onChange({
                          ...qualityCheck,
                          physicalFields: physicalFields.filter((f) => f.uuid !== pf.uuid)
                        })
                      }>
                      <Delete></Delete>
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

function AddAction({ onAddPhysicalField, onAddPhysicalEntity }) {
  let { t } = useTranslation();
  let [open, setOpen] = useState(false);
  let [system, setSystem] = useState(null);
  let [physicalEntity, setPhysicalEntity] = useState(null);
  let [physicalField, setPhysicalField] = useState(null);

  return (
    <>
      <IconButton size='small' onClick={() => setOpen(true)}>
        <Add></Add>
      </IconButton>
      <Dialog open={open} fullWidth>
        <DialogTitle title='Add a physical association'></DialogTitle>
        <DialogContent>
          <SystemItemAutoComplete
            value={system}
            onChange={(sys) => setSystem(sys)}></SystemItemAutoComplete>
          <PhysicalEntityItemAutoComplete
            key={_.get(physicalEntity, 'uuid')}
            categoryProperties={['system', 'name']}
            labelSeparator={' > '}
            systemUuid={system && system.uuid}
            value={physicalEntity}
            nameProperty='fullName'
            onChange={(pe) => setPhysicalEntity(pe)}
          />

          <PhysicalFieldItemSelect
            value={physicalField}
            physicalEntityUuid={physicalEntity && physicalEntity.uuid}
            onChange={(pf) => setPhysicalField(pf)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t('commons.actions.cancel')}</Button>
          <Button
            variant='contained'
            color='primary'
            disabled={!physicalEntity && !physicalField}
            onClick={() => {
              if (physicalField) {
                onAddPhysicalField({ ...physicalField, physicalEntity });
              } else if (physicalEntity) {
                onAddPhysicalEntity(physicalEntity);
              }
              setOpen(false);
            }}>
            {t('commons.actions.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QualityCheckPhysicalAssociationTable;
