import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import NumberBox, { numberBoxColors } from 'components/graphics/NumberBox';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Chart } from 'react-google-charts';
import {
  fetchEngagementSessionMetrics,
  fetchEngagementSessionTimeline,
  setEngagementTimelineFilters
} from 'actions/EngagementDashboardsActions';

function EngagementSessionDashboards({
  sessionCount,
  sessionTimeline,
  filters,
  fetchEngagementSessionMetrics,
  fetchEngagementSessionTimeline
}) {
  const [precision, setPrecision] = useState('day');
  let { t } = useTranslation();
  const [loadedMetrics, setLoadedMetrics] = useState(false);
  const [loadedTimeline, setLoadedTimeline] = useState(false);

  const handleSetPrecision = ({ precision }) => {
    setPrecision(precision);
    fetchEngagementSessionTimeline({ ...filters, precision });
  };

  useEffect(() => {
    fetchEngagementSessionMetrics({ ...filters }).then(() => setLoadedMetrics(true));
  }, [filters, fetchEngagementSessionMetrics]);

  useEffect(() => {
    fetchEngagementSessionTimeline({ ...filters, precision }).then(() => setLoadedTimeline(true));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, fetchEngagementSessionTimeline]);

  const rows = loadedTimeline && [
    sessionTimeline.columns,
    ...sessionTimeline.rows.map((r) => [new Date(r[0]), r[1], r[2]])
  ];

  return (
    <Card>
      <CardHeader
        action={
          <FormControl variant="standard">
            <InputLabel id='quality-suite-results-kpi-precision-selector-label'>
              Precision
            </InputLabel>
            <Select
              variant="standard"
              labelId='quality-suite-results-kpi-precision-selector-label'
              id='quality-suite-results-kpi-precision-selector'
              value={precision}
              onChange={(event) => handleSetPrecision({ precision: event.target.value })}>
              <MenuItem value='month'>{t('commons.timeunits.values.MONTHS')}</MenuItem>
              <MenuItem value='day'>{t('commons.timeunits.values.DAYS')}</MenuItem>
              <MenuItem value='hour'>{t('commons.timeunits.values.HOURS')}</MenuItem>
            </Select>
          </FormControl>
        }
        title={'Session Dashboards'}></CardHeader>
      <CardContent>
        <Grid container alignContent='center' spacing={2}>
          <Grid item>
            <Grid container direction='column' spacing={1} alignContent='center'>
              <Grid item>
                <NumberBox
                  label={loadedMetrics && sessionCount.columns[0].label}
                  number={loadedMetrics && sessionCount.rows[0][0]}
                  color={numberBoxColors.lightBlue}></NumberBox>
              </Grid>
              <Grid item>
                <NumberBox
                  label={loadedMetrics && sessionCount.columns[1].label}
                  number={loadedMetrics && sessionCount.rows[0][1]}
                  color={numberBoxColors.darkGrey}></NumberBox>
              </Grid>
            </Grid>
          </Grid>
          {rows.length > 1 ? (
            <Grid item style={{ flexGrow: 1 }}>
              <Chart
                chartType='ColumnChart'
                loader={<div>Loading Chart</div>}
                data={loadedTimeline && rows}
                options={{
                  colors: ['#00AECA', '#455a64']
                }}
              />
            </Grid>
          ) : (
            <Grid item>
              <Typography variant='overline'>
                {t('commons.placeholders.nothingToDisplay')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state, props) => ({
  sessionCount: state.engagement.engagementDashboards.engagementSessionMetrics,
  filters:
    state.engagement.engagementDashboards.engagementTimeline.engagementSessionTimeline.filters,
  sessionTimeline:
    state.engagement.engagementDashboards.engagementTimeline.engagementSessionTimeline.data
});

const mapDispatchToProps = {
  fetchEngagementSessionMetrics,
  fetchEngagementSessionTimeline,
  setEngagementTimelineFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(EngagementSessionDashboards);
