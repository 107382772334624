import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';

import {
  fetchQualityChecksSemaphoresPieChart,
  fetchQualityChecksSemaphoresPieChartDrilldown
} from 'actions/QualityDashboardsActions';
import QualitySuitePieChartLegend from 'pages/quality/kqis/quality_suites/detail/QualitySuitePieChartLegend';
import QualitySuitePieChart from 'pages/quality/kqis/quality_suites/detail/QualitySuitePieChart';
const styles = {
  card: {
    height: 'calc(100% - 16px)',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    minHeight: '250px'
  }
};

const QualitySuiteChecksLastRunChart = ({
  pieChartData,
  fetchQualityChecksSemaphoresPieChartDrilldown,
  fetchQualityChecksSemaphoresPieChart,
  qualitySuite
}) => {
  useEffect(() => {
    fetchQualityChecksSemaphoresPieChart({ qualitySuitesUuids: [qualitySuite.uuid] });
  }, [qualitySuite, fetchQualityChecksSemaphoresPieChart]);

  const [drillDownData, setDrillDownData] = useState(null);
  let { t } = useTranslation();
  const headers = pieChartData?.columns;
  const values = pieChartData?.row;
  return (
    <Card sx={styles.card}>
      <CardHeader title='Quality Checks Last Run' />
      <CardContent sx={styles.cardContent}>
        {pieChartData ? (
          <>
            <QualitySuitePieChart
              onRequestDrillDownData={(params) =>
                fetchQualityChecksSemaphoresPieChartDrilldown({
                  qualitySuitesUuids: [qualitySuite.uuid],
                  ...params
                }).then((response) => {
                  setDrillDownData(null);
                  setDrillDownData(response);
                })
              }
              drillDownData={drillDownData}
              headers={headers}
              values={values}
            />
            <QualitySuitePieChartLegend headers={headers} values={values} />
          </>
        ) : (
          <Typography variant='overline'>{t('commons.placeholders.nothingToDisplay')}</Typography>
        )}
      </CardContent>
    </Card>
  );
};
const mapStateToProps = (state, props) => ({
  pieChartData: state.quality.qualityDashboards.qualityChecksLastRun[props.qualitySuite.uuid]
});

export default connect(mapStateToProps, {
  fetchQualityChecksSemaphoresPieChart,
  fetchQualityChecksSemaphoresPieChartDrilldown
})(QualitySuiteChecksLastRunChart);
