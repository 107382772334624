import React from 'react';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';
import { searchProbeConnection } from 'actions/quality/probes/ProbesConnectionsActions';
import { connect } from 'react-redux';
import AutoComplete from 'components/autocomplete/AutoComplete';

function QualityProbesConnectionFormSection({ onChange, onSearch, connection, searchContent }) {
  return (
    <React.Fragment>
      <ItemAutoComplete
        required
        margin='dense'
        values={searchContent}
        value={connection}
        showAllOptions
        onClose
        itemName='Connection Name'
        nameProperty={'name'}
        onChange={(connectionItem) => {
          connectionItem &&
            onChange({
              name: connectionItem?.name,
              type: connectionItem?.type
            });
        }}
        onSearch={(inputValue) => {
          onSearch({ search: inputValue });
          onChange({ ...connection, name: inputValue });
        }}></ItemAutoComplete>
      <AutoComplete
        required
        fullWidth
        margin='dense'
        label={'Connection Type'}
        placeholder='Select Connection Type'
        value={connection?.type || ''}
        dataSource={['JDBC', 'MONGO']}
        onUpdateInput={(type) =>
          onChange({
            ...connection,
            type: type
          })
        }
        onNewRequest={(type) =>
          onChange({
            ...connection,
            type: type
          })
        }
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  searchContent: Object.values(state.quality.qualityProbes.connections.content)
});

const mapDispatchToProps = (dispatch) => ({
  onSearch: (search) => dispatch(searchProbeConnection(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(QualityProbesConnectionFormSection);
