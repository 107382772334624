import { buildApiCallAction } from 'actions/ActionUtils';
import { showMessage } from 'actions/BehaviourActions';
import AgentWebActionConfigurationsApi from 'api/onagent/webactions/AgentWebActionConfigurationsApi';
import {
  FETCH_AGENT_WEB_ACTION_CONFIGURATIONS_PAGE,
  FETCH_AGENT_WEB_ACTION_CONFIGURATION,
  CREATE_AGENT_WEB_ACTION_CONFIGURATION,
  DELETE_AGENT_WEB_ACTION_CONFIGURATION,
  MODIFY_AGENT_WEB_ACTION_CONFIGURATION,
  EXECUTE_AGENT_WEB_ACTION_CONFIGURATION
} from 'constants/ActionTypes';

export const fetchAgentWebActionConfigurationsPage = (agent, params) =>
  buildApiCallAction({
    api: new AgentWebActionConfigurationsApi(agent),
    apiFunction: (api) => api.getAgentWebActionConfigurationsPage(params),
    actionType: FETCH_AGENT_WEB_ACTION_CONFIGURATIONS_PAGE
  });

export const fetchAgentWebActionConfiguration = (agent, uuid) =>
  buildApiCallAction({
    api: new AgentWebActionConfigurationsApi(agent),
    apiFunction: (api) => api.getAgentWebActionConfiguration(uuid),
    actionType: FETCH_AGENT_WEB_ACTION_CONFIGURATION
  });

export const createAgentWebActionConfiguration = (agent, configuration) =>
  buildApiCallAction({
    api: new AgentWebActionConfigurationsApi(agent),
    apiFunction: (api) => api.postAgentWebActionConfiguration(configuration),
    actionType: CREATE_AGENT_WEB_ACTION_CONFIGURATION
  });

export const modifyAgentWebActionConfiguration = (agent, configurationUuid, configuration) =>
  buildApiCallAction({
    api: new AgentWebActionConfigurationsApi(agent),
    apiFunction: (api) => api.putAgentWebActionConfiguration(configurationUuid, configuration),
    actionType: MODIFY_AGENT_WEB_ACTION_CONFIGURATION
  });

export const deleteAgentWebActionConfiguration = (agent, connection) =>
  buildApiCallAction({
    api: new AgentWebActionConfigurationsApi(agent),
    apiFunction: (api) => api.deleteAgentWebActionConfiguration(connection.uuid),
    actionType: DELETE_AGENT_WEB_ACTION_CONFIGURATION,
    payload: connection
  });

export const executeAgentWebActionConfiguration = (agent, configurationUuid, executionParams) =>
  buildApiCallAction({
    api: new AgentWebActionConfigurationsApi(agent),
    apiFunction: (api) =>
      api.executeAgentWebActionConfiguration(configurationUuid, executionParams),
    actionType: EXECUTE_AGENT_WEB_ACTION_CONFIGURATION,
    payload: (data) => ({ ...data, configurationUuid }),
    onError: () => showMessage('Failed to execute the request')
  });
