import React from 'react';
import { Typography, Grid } from '@mui/material';
import CustomPropertiesFAB from './CustomPropertiesFAB';
import CustomPropertiesTable from './CustomPropertiesTable';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
function CustomPropertiesPage() {
  return (
    <div>
      <Breadcrumbs
        elements={[
          { text: 'Settings', to: '/settings' },
          { text: 'Custom Properties' }
        ]}></Breadcrumbs>
      <Tracking
        info={{
          pageTitle: 'Custom Properties',
          category: EngagementCategories.SETTINGS,
          action: EngagementActions.LIST
        }}
      />
      <Grid container>
        <Grid item>
          <Typography variant='h4'>Custom Properties</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:customproperties-list'}
            pageTitle={'Custom Properties'}
          />
        </Grid>
      </Grid>
      <CustomPropertiesTable></CustomPropertiesTable>
      <CustomPropertiesFAB></CustomPropertiesFAB>
    </div>
  );
}

export default CustomPropertiesPage;
