import { BlindataApi } from './ApiUtils';

export default class TeamPoliciesApi extends BlindataApi {
  getTeamPoliciesByUserUuid({ page, size, userUuid }) {
    return this.get(`/api/v1/users/${userUuid}/team-policies`, { page, size });
  }
  getTeamPoliciesByTeamCode({ page, size, teamCode }) {
    return this.get(`/api/v1/teams/${teamCode}/policies`, { page, size });
  }
  getTeamPolicy(policyUuid, teamCode) {
    return this.get(`/api/v1/teams/${teamCode}/policies/${policyUuid}`);
  }

  postTeamPolicy(teamPolicy, teamCode) {
    return this.post(`/api/v1/teams/${teamCode}/policies`, teamPolicy);
  }

  putTeamPolicy(teamPolicy) {
    return this.put(`/api/v1/teams/${teamPolicy.teamCode}/policies/${teamPolicy.uuid}`, teamPolicy);
  }

  deleteTeamPolicy(policyUuid, teamCode) {
    return this.delete(`/api/v1/teams/${teamCode}/policies/${policyUuid}`);
  }
}
