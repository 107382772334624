import React from 'react';
import { Table, TableContainer, TablePagination } from '@mui/material';
import QualityChecksTableHead from './QualityChecksTableHead';
import QualityChecksTableBody from './QualityChecksTableBody';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withLoader } from '../../../../../components/loader/Loader';
import { fetchQualityChecksPage } from '../../../../../actions/QualityChecksActions';
import { fetchDashboardTypeCounterIfAllowedLargeRequest } from 'actions/IssueMngtDashboardsActions';
import ResourceTypes from 'constants/ResourceTypes';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const styles = {
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: (theme) => theme.spacing(2)
  },
  table: {
    minWidth: '750px'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
};

export function QualityChecksTableView({
  qualitySuite,
  currentPage,
  page,
  rowsPerPage,
  onPageSelection,
  fillEmptyRows = true,
  size = 'medium'
}) {
  const order = 'desc';
  const orderBy = 'checkName';

  const handleChangePage = (event, newPage) => {
    onPageSelection({
      page: newPage,
      size: rowsPerPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    onPageSelection({
      page: page,
      size: event.target.value
    });
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, currentPage.content.length);
  const rowsSorted = stableSort(currentPage.content, getComparator(order, orderBy));

  return (
    <div style={styles.root}>
      <TableContainer>
        <Table
          sx={styles.table}
          aria-labelledby='tableTitle'
          size={size}
          aria-label='quality checks table'>
          <QualityChecksTableHead sx={styles} order={order} orderBy={orderBy} />
          <QualityChecksTableBody
            fillEmptyRows={fillEmptyRows}
            rows={rowsSorted}
            emptyRows={emptyRows}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component='div'
        count={currentPage.totalElements || 0}
        rowsPerPage={rowsPerPage || 10}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.quality.qualityChecks.currentPage,
    page: state.quality.qualityChecks.currentPage.number,
    rowsPerPage: state.quality.qualityChecks.currentPage.size
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    onPageSelection: (params) =>
      dispatch(
        fetchQualityChecksPage({
          suiteUuid: props.qualitySuite && props.qualitySuite.uuid,
          page: params.page,
          size: params.size
        })
      ).then(
        (response) =>
          response &&
          response.content.length > 0 &&
          dispatch(
            fetchDashboardTypeCounterIfAllowedLargeRequest(
              response.content,
              25,
              ResourceTypes.QUALITY_CHECK
            )
          )
      )
  };
}

const QualityCheckTable = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ onPageSelection }) =>
      onPageSelection({
        page: 0,
        size: 20
      })
  })
)(QualityChecksTableView);

export default QualityCheckTable;
