import * as React from "react";
const SvgDremio = (props) => (
  <svg
    id="Dremio_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 64 64"
    width="1em"
    height="1em"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".Dremio_svg__st0,.Dremio_svg__st1,.Dremio_svg__st2,.Dremio_svg__st3{fill-rule:evenodd;clip-rule:evenodd;fill:#393c42}.Dremio_svg__st1,.Dremio_svg__st2,.Dremio_svg__st3{fill:#55bfce}.Dremio_svg__st2,.Dremio_svg__st3{fill:#fff}.Dremio_svg__st3{fill:#25abad}"
      }
    </style>
    <path
      className="Dremio_svg__st0"
      d="M57.8 190.7c-.5.6-1.8.6-3.5-.2l-20.8-10.6-.1.1-1.1 1.1c-.6.5-1.7 1.2-2.4 1.5-4.5 2.3-9.8 2.1-14.5.7-5.3-1.6-10.3-4.8-14.1-8.8-1.7-1.8-3.2-3.9-4.4-6.1-1.5-2.8-2.4-5.8-2.7-8.9-.5-6.1 1.2-14 5.4-18.6 1-1.2 2.1-2.2 3.2-3.3.8-.8 1.7-1.6 2.5-2.5 1.5-1.8 2.3-3.6 3-5.9l.1-.3.3.2c4.3 3.1 5.9 7.2 2.8 11.8 1.8 1.5 2.7 3.6 2.7 5.9 0 2.2-.9 4.6-2.8 5.9.8.3 2 .9 2.8 1.2 0-.7-.6-1.8-.1-2.1 1-.6 4.8 1 6.1 4.4 1.2.4 1.9.7 3.2 1.2 4.9 2 10.3 4.8 13.3 8.3 1 1.2 3.1 4.7.8 5.6.8.9 1.3 2.4 1 3.4l17.8 13c1.3.8 2.1 2.3 1.5 3zM3.1 146.4c-.4 2.2 3.4 4.1 5.4 5-.5-1.6-1.5-3.2-2.7-4.4-.6-.5-2.1-1.9-2.7-.6z"
      transform="matrix(1 0 0 -1 6 192)"
    />
    <path
      className="Dremio_svg__st1"
      d="M36.5 168.3c1.3 1.7.4 2.7-.5 1.7-1.8-1.9-4.6-2.5-7.1-2.1-3.7.6-3.8 1.2-3.2 1.7 4.3-1.6 9.3.5 11.4 3.9.9 1.3-2.1 2.3-3.9 4.7-8.5 11.5-35.3 1.5-37.8-18.7-.8-6.1.9-12.8 5.1-17.5 3.1-3.5 6.5-5 8.4-10.6 3.7 3.2 3.2 6.8.4 10 4.7 2.4 4 8.7.7 10.7-1.5-5.6-7-9.8-8.3-5.8-.8 4.1 5.9 6.8 16.6 10.8 9 3.5 14.8 6.3 18.2 11.2z"
      transform="matrix(1 0 0 -1 6 192)"
    />
    <path
      className="Dremio_svg__st2"
      d="M36.5 168.3c1.3 1.7.4 2.7-.5 1.7-1.6-1.6-3.9-2.3-6.1-2.2-3.7.3-6.6 1-9.3.7-5.3-.5-10.5-3.9-13.3-6.7-4.7-4.8-9.8-14.8-4.6-16.7-.4.2-.7.7-.9 1.3-.8 4.1 5.9 6.8 16.6 10.8 8.9 3.4 14.7 6.2 18.1 11.1z"
      transform="matrix(1 0 0 -1 6 192)"
    />
    <path
      className="Dremio_svg__st1"
      d="M13.5 160.5c2.8-1.3 2.2-6.1 1.8-7.3 5.2 1.5 3.7 9.2 6.1 11.6-3 .1-8.7-2.9-7.9-4.3z"
      transform="matrix(1 0 0 -1 6 192)"
    />
    <path
      className="Dremio_svg__st0"
      d="M22.4 174.5c.7.4 1.7.1 2.1-.6.4-.7.1-1.6-.6-2.1-.7-.4-1.7-.1-2.1.6-.4.8-.1 1.7.6 2.1z"
      transform="matrix(1 0 0 -1 6 192)"
    />
    <path
      className="Dremio_svg__st1"
      d="m35.1 173.9 21 14.6c.6.4 1.4 1.3 1 1.8-.3.4-1.2.2-2-.2l-22.7-12.3c-.7-1.5.8-4.7 2.7-3.9z"
      transform="matrix(1 0 0 -1 6 192)"
    />
    <path
      className="Dremio_svg__st2"
      d="m33.3 176.8 21.9 12.9c1 .6 1.3.6 1.9.6-.3.4-1.2.1-2-.2l-22.7-12.3c-.6-1.2.2-3.3 1.4-3.9-.4 1-.7 2-.5 2.9z"
      transform="matrix(1 0 0 -1 6 192)"
    />
    <path
      className="Dremio_svg__st3"
      d="M.9 171c.7.1 1.3-.4 1.3-1.1 0-.7-.6-1.4-1.3-1.5-.7-.1-1.3.4-1.2 1.1-.1.7.5 1.4 1.2 1.5zM5.2 175.7c.6.1 1.1-.3 1.1-1s-.5-1.3-1.2-1.4c-.6-.1-1.1.3-1.1 1s.6 1.3 1.2 1.4zM18.1 181.9c.6 0 1-.6.9-1.2-.2-.6-.8-1.1-1.4-1.1-.6 0-1 .6-.9 1.2.1.7.7 1.2 1.4 1.1zM22 182.6c.5-.1.8-.6.6-1.1-.2-.5-.8-.8-1.3-.7-.5.1-.8.6-.6 1.1.2.5.8.8 1.3.7zM5.2 172.8c.5.1.8-.3.8-.7 0-.5-.4-.9-.9-1-.5-.1-.8.2-.8.7.1.4.5.9.9 1zM-1.4 167.3c.5.1.9-.3.9-.8s-.4-1-.9-1.1c-.5-.1-.9.3-.9.7 0 .6.4 1.1.9 1.2zM37.9 179.5c-.1.5-.1.9-.1 1.3l-1-.5c0-.4.1-.8.3-1.2.4-1.1 1.2-2.1 1.8-2.3-.4.7-.8 1.7-1 2.7zm5.2 3.1c0 .4-.1.7 0 1l-.8-.5c0-.3.1-.6.2-.9.4-1 1.1-1.9 1.5-1.9-.4.4-.7 1.4-.9 2.3zm5.2 3c0 .3-.1.5 0 .8l-.7-.4c0-.2 0-.5.1-.7.2-.7.6-1.4 1.2-1.5-.2.4-.5 1-.6 1.8zm5 2.9c0 .2-.1.4 0 .5l-.4-.2-.2-.1c0-.1.1-.3.2-.5.2-.5.6-1.1.9-1.3-.2.4-.4 1-.5 1.6z"
      transform="matrix(1 0 0 -1 6 192)"
    />
  </svg>
);
export default SvgDremio;
