import React, { useEffect } from 'react';
import DataCategoryItem from './DataCategoriesListItem';
import { fetchDataCategoriesPage } from '../../../actions/DataCategoriesActions';
import { connect } from 'react-redux';
import PaginatedPanel from 'components/pagination/PaginatedPanel';
import { fetchDashboardTypeCounterIfAllowedLargeRequest } from 'actions/IssueMngtDashboardsActions';
import ResourceTypes from 'constants/ResourceTypes';

function DataCategoriesList({
  filters,
  currentPage,
  fetchDataCategoriesPage,
  fetchDashboardTypeCounterIfAllowedLargeRequest
}) {
  useEffect(
    function () {
      fetchDataCategoriesPage({ page: 0, ...filters }).then(
        (response) =>
          response &&
          response.content.length > 0 &&
          fetchDashboardTypeCounterIfAllowedLargeRequest(
            response.content,
            25,
            ResourceTypes.DATA_CATEGORY
          )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, fetchDataCategoriesPage]
  );

  return (
    <PaginatedPanel
      currentPage={currentPage}
      onPageSelection={(page) => fetchDataCategoriesPage({ page, ...filters })}>
      {currentPage.content.map((dataCategory) => (
        <DataCategoryItem key={dataCategory.uuid} dataCategory={dataCategory} />
      ))}
    </PaginatedPanel>
  );
}

const mapStateToProps = (state) => ({
  currentPage: state.datacategories.currentPage,
  filters: state.datacategories.filters.content
});

export default connect(mapStateToProps, {
  fetchDataCategoriesPage,
  fetchDashboardTypeCounterIfAllowedLargeRequest
})(DataCategoriesList);
