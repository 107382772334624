import React from 'react';
import { connect } from 'react-redux';
import { fetchUsersInCurrentTenant } from 'actions/UsersActions';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';

function UsersInCurrentTenantItemAutoComplete({
  onSearch,
  searchContent,
  onChange,
  value,
  itemName,
  disabled
}) {
  return (
    <ItemAutoComplete
      disabled={disabled}
      values={searchContent}
      value={value}
      margin='dense'
      nameProperty={'displayName'}
      subheaderProperty={'username'}
      itemName={itemName || 'User'}
      onChange={(newValue) => onChange(newValue)}
      onSearch={(inputValue) => {
        onSearch({ search: inputValue });
      }}></ItemAutoComplete>
  );
}

const mapStateTopProps = (state, props) => ({
  searchContent: Object.values(state.settings.users.currentTenantContent)
});

const mapDispatchToProps = (dispatch) => ({
  onSearch: (search) => dispatch(fetchUsersInCurrentTenant(search))
});
export default connect(mapStateTopProps, mapDispatchToProps)(UsersInCurrentTenantItemAutoComplete);
