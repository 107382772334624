import _ from 'lodash';
import React, { useState } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import 'reactflow/dist/style.css';

export const ColumnNode = ({ column, nodeId }) => {
  const reactFlowInstance = useReactFlow();
  const [highlighted, setHighlighted] = useState(false);

  const handleClick = () => {
    const edges = reactFlowInstance.getEdges();
    let highlightedEdges = [];
    //navigate node to right
    let currentEdges = _(edges)
      .filter((edge) => edge.source === nodeId && edge.sourceHandle === `out.${column.name}`)
      .uniqBy('id')
      .value();

    let cycleCounter = 0;
    while (currentEdges && currentEdges.length > 0 && cycleCounter < 200) {
      cycleCounter++;
      highlightedEdges = _.unionBy(currentEdges, highlightedEdges, 'id');
      currentEdges = _(currentEdges)
        .flatMap((currentEdge) =>
          edges.filter(
            (edge) =>
              edge.source === currentEdge.target &&
              edge.sourceHandle.split('.')[1] === currentEdge.targetHandle.split('.')[1]
          )
        )
        .uniqBy('id')
        .value();
    }
    //navigate node to left
    currentEdges = _(edges)
      .filter((edge) => edge.target === nodeId && edge.targetHandle === `in.${column.name}`)
      .uniqBy('id')
      .value();
    cycleCounter = 0;
    while (currentEdges && currentEdges.length > 0 && cycleCounter < 200) {
      cycleCounter++;
      highlightedEdges = _.unionBy(currentEdges, highlightedEdges, 'id');
      currentEdges = _(currentEdges)
        .flatMap((currentEdge) =>
          edges.filter(
            (edge) =>
              edge.target === currentEdge.source &&
              edge.targetHandle.split('.')[1] === currentEdge.sourceHandle.split('.')[1]
          )
        )
        .uniqBy('id')
        .value();
    }
    //Updates edges with colors
    reactFlowInstance.setEdges(
      edges.map((edge) => {
        if (_.find(highlightedEdges, (e) => e.id === edge.id)) {
          return { ...edge, style: { stroke: '#00aeca' }, animated: false };
        } else {
          return { ...edge, style: {}, animated: true };
        }
      })
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: highlighted ? 'rgb(230, 234, 240, 0.8)' : 'rgb(230, 234, 240, 0.1)'
      }}
      onMouseOver={() => setHighlighted(true)}
      onMouseOut={() => setHighlighted(false)}>
      <Handle
        type='target'
        position={Position.Left}
        id={`in.${column.name}`}
        style={{ position: 'relative', visibility: 'hidden' }}
      />
      <span onClick={() => handleClick()} style={{ flexGrow: 1 }}>
        {column.name}
      </span>
      <Handle
        type='source'
        position={Position.Right}
        id={`out.${column.name}`}
        style={{ position: 'relative', visibility: 'hidden' }}
      />
    </div>
  );
};
