import React from 'react';
import ToolbarActions from '../sidebar/ToolbarActions';
import { Button } from '@mui/material';
import { useRouteMatch, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

export default function TemplatesNavbar(props) {
  let { url } = useRouteMatch();
  let { pathname } = useLocation();
  const templateMappingsUrl = `${url}/mappings`;
  const templateTasksUrl = `${url}/tasks`;
  const templateProcessingsUrl = `${url}/processings`;
  const templateEventsUrl = `${url}/events`;
  return (
    <ToolbarActions>
      <Button
        color={pathname === templateMappingsUrl ? 'primary' : 'grey'}
        children='Mappings'
        component={Link}
        to={templateMappingsUrl}
      />
      <Button
        color={pathname === templateTasksUrl ? 'primary' : 'grey'}
        children='Tasks'
        component={Link}
        to={templateTasksUrl}
      />
      <Button
        color={pathname === templateProcessingsUrl ? 'primary' : 'grey'}
        children='Processings'
        component={Link}
        to={templateProcessingsUrl}
      />
      <Button
        color={pathname === templateEventsUrl ? 'primary' : 'grey'}
        children='Events'
        component={Link}
        to={templateEventsUrl}
      />
    </ToolbarActions>
  );
}
