import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { t } from 'i18next';
import _ from 'lodash';
import RequestTemplateSection from 'pages/settings/webactions/commons/RequestTemplateSection';
import React, { useReducer } from 'react';
import { WebHookModalRequestTemplateParams } from './WebHookModalRequestTemplateParams';

const WebHookModal = ({ open, onCancel, onSubmit, webHook, title }) => {
  const [editor, setEditor] = useReducer((s, a) => ({ ...s, ...a }), webHook);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          fullWidth
          required
          margin='dense'
          placeholder={t('webHooks.modal.name.placeholder')}
          label={t('webHooks.modal.name.label')}
          value={editor?.name || ''}
          onChange={(event) => setEditor({ name: event.target.value })}
        />
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          placeholder={t('webHooks.modal.description.placeholder')}
          multiline
          label={t('webHooks.modal.description.label')}
          value={editor?.description || ''}
          onChange={(event) => setEditor({ description: event.target.value })}
        />
        <RequestTemplateSection editor={editor} setEditor={setEditor} />
        <WebHookModalRequestTemplateParams editor={editor} setEditor={setEditor} />
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.cancel')}
          onClick={() => {
            onCancel();
          }}
        />
        <Button
          disabled={
            !editor?.name ||
            !editor?.requestTemplate?.method ||
            !editor?.requestTemplate?.url ||
            _.isEqual(webHook, editor)
          }
          variant='contained'
          color='primary'
          children={t('commons.actions.submit')}
          onClick={() => {
            onSubmit(editor);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default WebHookModal;
