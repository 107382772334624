import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import { Table, TableHead, TableBody, TableRow } from '@mui/material';

import TableCell from 'components/tables/GenericTableCell';
import PhysicalEntitiesTableActions from 'pages/systems/physical_entities/list/PhysicalEntitiesTableActions';
import {
  VisibilityDecorator,
  DataSetDecorator
} from 'pages/systems/physical_entities/commons/Decorators';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';
import ResourceTypes from 'constants/ResourceTypes';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import { getSystemName } from 'constants/SystemTechnologyTypes';

const PhysicalEntitiesTable = ({
  physicalEntities,
  handleShowDetails,
  handleEdit,
  handleDelete,
  t
}) => {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>{t('physicalEntities.detail.general.system')}</TableCell>
          <TableCell>{t('physicalEntities.detail.general.schema')}</TableCell>
          <TableCell>{t('physicalEntities.detail.general.name')}</TableCell>
          <TableCell hiddenMd>{t('physicalEntities.detail.general.type')}</TableCell>
          <TableCell hiddenMd>{t('physicalEntities.detail.general.description')}</TableCell>
          <TableCell hiddenSm />
          <TableCell hiddenSm>Data Set</TableCell>
          <Authorize hasPermissions={[Permissions.CAMPAIGNS_VIEWER]}>
            <TableCell hiddenSm>Issues</TableCell>
          </Authorize>
          <TableCell>{t('commons.fields.actions')}</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {physicalEntities.map((entity) => (
          <TableRow
            hover={true}
            style={{ cursor: 'pointer' }}
            key={entity.uuid}
            onClick={() => handleShowDetails(entity.uuid)}>
            <TableCell>{getSystemName(entity?.system)}</TableCell>
            <TableCell>{entity.schema || '-'}</TableCell>
            <TableCell>{entity.name}</TableCell>
            <TableCell hiddenMd>{entity.tableType || '-'}</TableCell>
            <TableCell breakWord hiddenMd>
              {_.truncate(entity.description, { length: 125 }) || '-'}
            </TableCell>
            <TableCell hiddenSm>{entity.hidden && <VisibilityDecorator />}</TableCell>
            <TableCell hiddenSm>
              {entity.dataSet && <DataSetDecorator dataSet={entity.dataSet} />}
            </TableCell>
            <Authorize hasPermissions={[Permissions.CAMPAIGNS_VIEWER]}>
              <TableCell hiddenSm>
                <IssueResourceListItemWidget
                  resourceType={ResourceTypes.PHYSICAL_ENTITY}
                  resourceIdentifier={entity.uuid}></IssueResourceListItemWidget>
              </TableCell>
            </Authorize>
            <TableCell>
              <PhysicalEntitiesTableActions
                entityUUID={entity.uuid}
                onShowDetails={() => handleShowDetails(entity.uuid)}
                onEdit={() => handleEdit(entity)}
                onDelete={() => handleDelete(entity)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default withTranslation()(PhysicalEntitiesTable);
