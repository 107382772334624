import React from 'react';
import { connect } from 'react-redux';
import {
  deleteQualitySuite,
  fetchQualitySuitesPage,
  modifyQualitySuite
} from 'actions/QualitySuitesActions';
import PaginatedPanel from 'components/pagination/PaginatedPanel';
import QualitySuitesListItem from './QualitySuitesListItem';
import ResourceTypes from 'constants/ResourceTypes';
import { fetchDashboardTypeCounterIfAllowedLargeRequest } from 'actions/IssueMngtDashboardsActions';

function QualitySuitesList({
  currentPage,
  onPageSelection,
  modifyQualitySuite,
  deleteQualitySuite
}) {
  return (
    <PaginatedPanel currentPage={currentPage} onPageSelection={onPageSelection} autoload>
      {currentPage.content.map((item) => (
        <QualitySuitesListItem
          key={item.uuid}
          qualitySuite={item}
          modifyQualitySuite={modifyQualitySuite}
          deleteQualitySuite={deleteQualitySuite}
        />
      ))}
    </PaginatedPanel>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.quality.qualitySuites.currentPage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onPageSelection: (page) =>
      dispatch(fetchQualitySuitesPage({ page, published: true })).then(
        (response) =>
          response &&
          response.content.length > 0 &&
          dispatch(
            fetchDashboardTypeCounterIfAllowedLargeRequest(
              response.content,
              25,
              ResourceTypes.QUALITY_SUITE
            )
          )
      ),
    modifyQualitySuite: (qualitySuite) => dispatch(modifyQualitySuite(qualitySuite)),
    deleteQualitySuite: (uuid) => dispatch(deleteQualitySuite(uuid)),
    fetchDashboardTypeCounterIfAllowedLargeRequest: (props) =>
      dispatch(fetchDashboardTypeCounterIfAllowedLargeRequest({ ...props }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QualitySuitesList);
