import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import HistoryDetailPage from './detail/HistoryDetailPage';
import HistoryListPage from './list/HistoryListPage';

export default class ProcessingsPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path={`${this.props.match.url}/records`} component={HistoryListPage} />
        <Route
          exact
          path={`${this.props.match.url}/records/:sequenceId`}
          component={HistoryDetailPage}
        />
      </React.Fragment>
    );
  }
}
