import React, { useState } from 'react';
import moment from 'moment';

import {
  TableHead,
  TableBody,
  TableRow,
  Table,
  TableContainer,
  Grid,
  TablePagination
} from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import OkIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/HighlightOff';

function ProjectTestResults({ results }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [errorPage, setErrorPage] = useState(0);
  const [errorsPerPage, setErrorsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeErrorPage = (event, newPage) => {
    setErrorPage(newPage);
  };

  const handleChangeErrorsRowsPerPage = (event) => {
    setErrorsPerPage(+event.target.value);
    setErrorPage(0);
  };
  return (
    <Grid container direction='column' spacing={2}>
      {results.errors?.length > 0 && (
        <>
          <Grid item>
            <h4 style={{ marginBottom: 0 }}>Errors</h4>
          </Grid>
          <Grid item>
            <TableContainer style={{ maxHeight: '300px' }}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Check Code</TableCell>
                    <TableCell>Error</TableCell>
                    <TableCell>Start Time</TableCell>
                    <TableCell>End Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.errors
                    .slice(errorPage * errorsPerPage, errorPage * errorsPerPage + errorsPerPage)
                    .map((entry) => (
                      <TableRow key={entry.qualityCheckCode}>
                        <TableCell>
                          <ErrorIcon style={{ color: 'red' }} />
                        </TableCell>
                        <TableCell>{entry.qualityCheckCode}</TableCell>
                        <TableCell>{entry.error || '-'}</TableCell>
                        <TableCell>
                          {entry.startedAt &&
                            moment(entry.startedAt).format('DD MMMM YYYY, HH:mm:ss')}
                        </TableCell>
                        <TableCell>
                          {entry.finishedAt &&
                            moment(entry.finishedAt).format('DD MMMM YYYY, HH:mm:ss')}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component='div'
              count={results.errors.length}
              rowsPerPage={errorsPerPage}
              page={errorPage}
              onPageChange={handleChangeErrorPage}
              onRowsPerPageChange={handleChangeErrorsRowsPerPage}
            />
          </Grid>
        </>
      )}
      {results.results?.length > 0 && (
        <>
          <Grid item>
            <h4 style={{ marginBottom: 0 }}>Results</h4>
          </Grid>
          <Grid item>
            <TableContainer style={{ maxHeight: '300px' }}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Check Code</TableCell>
                    <TableCell>Metric</TableCell>
                    <TableCell>Total Elements</TableCell>
                    <TableCell>Start Time</TableCell>
                    <TableCell>End Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.results
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((entry) => (
                      <TableRow key={entry.qualityCheckCode}>
                        <TableCell>
                          <OkIcon style={{ color: 'green' }} />
                        </TableCell>
                        <TableCell>{entry.qualityCheckCode}</TableCell>
                        <TableCell>
                          {entry.metric !== null && entry.metric !== undefined ? entry.metric : '-'}
                        </TableCell>
                        <TableCell>{entry.totalElements || '-'}</TableCell>
                        <TableCell>
                          {entry.startedAt &&
                            moment(entry.startedAt).format('DD MMMM YYYY, HH:mm:ss')}
                        </TableCell>
                        <TableCell>
                          {entry.finishedAt &&
                            moment(entry.finishedAt).format('DD MMMM YYYY, HH:mm:ss')}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component='div'
              count={results.results.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default ProjectTestResults;
