import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { fetchHistoryRecordPayload } from '../../../actions/HistoryActions';
import { compose } from 'redux';
import { withLoader } from '../../../components/loader/Loader';
import { Card, CardHeader, CardContent, Paper, Tabs, Tab } from '@mui/material';
import HistoryTargetTypes from '../commons/HistoryTargetTypes';
import DataCategoryCardView from '../../datacategories/commons/DataCategoryCardView';
import ProcessingCardView from '../../processings/utils/ProcessingCardView';
import TaskCardView from '../../tasks/utils/TaskCardView';
import LogicalFieldCardView from '../../logicalfields/commons/LogicalFieldCardView';
import PhysicalFieldCardView from '../../systems/physical_entities/commons/PhysicalFieldCardView';
import DataActorCardView from '../../data_actors/commons/DataActorCardView';
import PaperErrorBoundary from '../../../components/errors/PaperErrorBoundary';
import JsonVisualizer from '../../../components/syntaxhighlithter/JsonVisualizer';
import PhysicalEntityCardView from '../../systems/physical_entities/commons/PhysicalEntityCardView';
import DefinitionDetailCardView from 'pages/contracts/definitions/detail/DefinitionDetailCardView';
import RoutineDetailCardView from 'pages/systems/routines/detail/RoutineDetailCardView';

const HistoryRecordPayloadCardView = ({ recordPayload, targetType }) => {
  const [contentStyle, setContentStyle] = React.useState(0);
  return (
    <>
      <ContentTypeSelector
        value={contentStyle}
        handleChange={(event, newValue) => setContentStyle(newValue)}
      />
      {contentStyle === 0 ? (
        <PaperErrorBoundary message='Error rendering styled view'>
          <TypedContentCard {...{ recordPayload, targetType }}></TypedContentCard>
        </PaperErrorBoundary>
      ) : (
        <RawContentCard {...{ recordPayload }}></RawContentCard>
      )}
    </>
  );
};

const ContentTypeSelector = ({ value, handleChange }) => {
  return (
    <Tabs
      variant='fullWidth'
      indicatorColor='primary'
      value={value}
      onChange={handleChange}
      centered>
      <Tab label='Styled Version' />
      <Tab label='Raw Version' />
    </Tabs>
  );
};

const TypedContentCard = ({ recordPayload, targetType }) => {
  let factory = typedContentFactoryMap[targetType] || defaultFactory;
  return factory({ recordPayload });
};

const typedContentFactoryMap = {
  [HistoryTargetTypes.DATA_CATEGORY]: ({ recordPayload }) => (
    <DataCategoryCardView dataCategory={recordPayload.payload}></DataCategoryCardView>
  ),
  [HistoryTargetTypes.PROCESSING]: ({ recordPayload }) => (
    <ProcessingCardView processing={recordPayload.payload}></ProcessingCardView>
  ),
  [HistoryTargetTypes.TASK]: ({ recordPayload }) => (
    <TaskCardView task={recordPayload.payload}></TaskCardView>
  ),
  [HistoryTargetTypes.DATA_ACTOR]: ({ recordPayload }) => (
    <DataActorCardView dataActor={recordPayload.payload}></DataActorCardView>
  ),
  [HistoryTargetTypes.LOGICAL_FIELD]: ({ recordPayload }) => (
    <Card>
      <LogicalFieldCardView logicalField={recordPayload.payload}></LogicalFieldCardView>
    </Card>
  ),
  [HistoryTargetTypes.PHYSICAL_ENTITY]: ({ recordPayload }) => (
    <PhysicalEntityCardView physicalEntity={recordPayload.payload}></PhysicalEntityCardView>
  ),
  [HistoryTargetTypes.PHYSICAL_FIELD]: ({ recordPayload }) => (
    <PhysicalFieldCardView physicalField={recordPayload.payload}></PhysicalFieldCardView>
  ),
  [HistoryTargetTypes.CONTRACT]: ({ recordPayload }) => (
    <DefinitionDetailCardView contractDefinition={recordPayload.payload} />
  ),
  [HistoryTargetTypes.SYSTEM_ROUTINE]: ({ recordPayload }) => (
    <RoutineDetailCardView routine={recordPayload.payload} />
  )
};

const defaultFactory = () => {
  return <Paper style={{ padding: 30, textAlign: 'center' }}>Not available</Paper>;
};

const RawContentCard = ({ recordPayload }) => {
  return (
    <Card>
      <CardHeader title='Raw Content'></CardHeader>
      <CardContent>
        <JsonVisualizer object={recordPayload.payload} />
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state, { sequenceId }) => ({
  recordPayload: state.history.payloads[sequenceId],
  targetType: _.get(state.history.content[sequenceId], 'targetType')
});

const mapDispatchToProps = {
  fetchHistoryRecordPayload
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchHistoryRecordPayload, sequenceId }) => fetchHistoryRecordPayload(sequenceId),
    ready: ({ recordPayload }) => recordPayload !== undefined
  })
)(HistoryRecordPayloadCardView);
