import React from 'react';
import { Table, TableHead, TableBody, TableRow, Link as MaterialLink } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import TableCell from 'components/tables/GenericTableCell';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const DataFlowPreviewsTable = ({
  dataFlowPreviews,
  queryStatement,
  generateAtPhysicalEntityLevel
}) => {
  const history = useHistory();

  const handleRowClick = (dataFlowPreview) => {
    if (dataFlowPreview.dataFlowUuid) {
      history.push(`/dataflows/detail/${dataFlowPreview.dataFlowUuid}`);
    }
  };

  return (
    <Table size='small' style={{ overflowX: 'auto' }}>
      <TableHead>
        <TableRow>
          <TableCell>From</TableCell>
          <TableCell>To</TableCell>
          <TableCell align='center' size='small'>
            Generated
          </TableCell>
          <TableCell align='center' size='small'>
            Already Present
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dataFlowPreviews.map((dataFlowPreview, index) => (
          <TableRow
            hover={dataFlowPreview.dataFlowUuid}
            style={{ cursor: dataFlowPreview.dataFlowUuid ? 'pointer' : 'default' }}
            key={index}
            onClick={() => handleRowClick(dataFlowPreview)}>
            <TableCell>
              <SystemName system={dataFlowPreview.dataFlow?.fromSystem} /> : <span />
              <PhysicalEntityName physicalEntity={dataFlowPreview.dataFlow?.fromPhysicalEntity} />
              <PhysicalFieldName
                physicalField={dataFlowPreview.dataFlow.fromPhysicalField}
                display={generateAtPhysicalEntityLevel}
              />
            </TableCell>
            <TableCell>
              <SystemName system={dataFlowPreview.dataFlow?.toSystem} /> : <span />
              <PhysicalEntityName physicalEntity={dataFlowPreview.dataFlow?.toPhysicalEntity} />
              <PhysicalFieldName
                physicalField={dataFlowPreview.dataFlow.toPhysicalField}
                display={generateAtPhysicalEntityLevel}
              />
            </TableCell>
            <TableCell align='center' size='small'>
              {dataFlowPreview.dataFlowUuid &&
              dataFlowPreview.dataFlow.name === queryStatement.uuid ? (
                <DoneIcon color='primary' />
              ) : (
                <></>
              )}
            </TableCell>
            <TableCell align='center' size='small'>
              {dataFlowPreview.dataFlowUuid &&
              dataFlowPreview.dataFlow.name !== queryStatement.uuid ? (
                <DoneIcon color='primary' />
              ) : (
                <></>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const PhysicalEntityName = ({ physicalEntity }) => {
  return (
    <Link
      to={`/physicalentities/${physicalEntity.uuid}`}
      style={{ textDecoration: 'none' }}
      onClick={(event) => event.stopPropagation()}>
      <MaterialLink underline='hover' style={{ color: 'black' }}>
        {physicalEntity.name || ''}
      </MaterialLink>
    </Link>
  );
};

const SystemName = ({ system }) => {
  return (
    <Link
      to={`/systems/${system.uuid}`}
      style={{ textDecoration: 'none' }}
      onClick={(event) => event.stopPropagation()}>
      <MaterialLink underline='hover' style={{ color: 'black' }}>
        {system.name || ''}
      </MaterialLink>
    </Link>
  );
};

const PhysicalFieldName = ({ physicalField, display }) => {
  return (
    !display &&
    physicalField && (
      <>
        : <span />
        <Link
          to={`/systems/physical_fields/${physicalField.uuid}`}
          style={{ textDecoration: 'none' }}
          onClick={(event) => event.stopPropagation()}>
          <MaterialLink underline='hover' style={{ color: 'black' }}>
            {physicalField.name || ''}
          </MaterialLink>
        </Link>
      </>
    )
  );
};

export default DataFlowPreviewsTable;
