import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import QueryBucketItemAutoComplete from '../../buckets/commons/QueryBucketItemAutoComplete';
import _ from 'lodash';

const QueryStatementModal = ({ title, open, onClose, onSubmit, queryBucket, queryStatement }) => {
  const [queryStatementDataPatch, setQueryStatementDataPatch] = useState();
  const { t } = useTranslation();

  const mergedQueryStatementData = _.assign(
    {},
    { queryBucket: queryBucket },
    queryStatement,
    queryStatementDataPatch
  );

  return (
    <Dialog fullWidth open={open} onClick={(event) => event.stopPropagation()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <h4>{t('querystatements.modal.dialog.content')}</h4>
        <TextField
          variant='standard'
          fullWidth
          placeholder={t('querystatements.modal.fields.nameHint')}
          label={t('querystatements.modal.fields.name')}
          value={mergedQueryStatementData.name || ''}
          onChange={(event) =>
            setQueryStatementDataPatch({ ...queryStatementDataPatch, name: event.target.value })
          }
        />
        <br></br>
        <TextField
          variant='standard'
          fullWidth
          multiline
          placeholder={t('querystatements.modal.fields.descriptionHint')}
          label={t('querystatements.modal.fields.description')}
          value={mergedQueryStatementData.description || ''}
          onChange={(event) =>
            setQueryStatementDataPatch({
              ...queryStatementDataPatch,
              description: event.target.value
            })
          }
        />
        <br></br>
        <TextField
          variant='standard'
          fullWidth
          required
          placeholder={t('querystatements.modal.fields.schemaHint')}
          label={t('querystatements.modal.fields.schema')}
          value={mergedQueryStatementData.schema || ''}
          onChange={(event) =>
            setQueryStatementDataPatch({ ...queryStatementDataPatch, schema: event.target.value })
          }
        />
        <br></br>
        <QueryBucketItemAutoComplete
          required
          value={mergedQueryStatementData.queryBucket}
          disabled={queryBucket}
          onChange={(queryBucket) =>
            setQueryStatementDataPatch(
              _.assign({}, queryStatementDataPatch, { queryBucket: queryBucket })
            )
          }
        />
        <br></br>
        <TextField
          variant='standard'
          fullWidth
          multiline
          required
          placeholder={t('querystatements.modal.fields.textHint')}
          label={t('querystatements.modal.fields.text')}
          value={mergedQueryStatementData.queryText || ''}
          onChange={(event) =>
            setQueryStatementDataPatch({
              ...queryStatementDataPatch,
              queryText: event.target.value
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            onClose();
            setQueryStatementDataPatch({});
          }}>
          {t('commons.actions.cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={(event) => {
            event.stopPropagation();
            onSubmit(mergedQueryStatementData);
            setQueryStatementDataPatch({});
          }}
          disabled={
            !mergedQueryStatementData.schema ||
            !mergedQueryStatementData.queryText ||
            !mergedQueryStatementData.queryBucket
          }>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QueryStatementModal;
