import { GRAPH_STYLES, GRAPH_SUBTYPES_STYLES } from './VisGraphStyles';
import _ from 'lodash';
import vis from '../../components/vis';

export const EVENTS = {
  doubleClickNode: 'doubleClickNode',
  clickStage: 'clickStage',
  clickNode: 'clickNode',
  clickEdge: 'clickEdge',
  rightClickNode: 'rightClickNode',
  rightClickEdge: 'rightClickEdge'
};

export default class VisGraphHandler {
  options = {
    nodes: {
      shape: 'dot',
      level: 0
    },
    edges: {
      arrows: 'to',
      color: {
        color: 'grey',
        inherit: false
      }
    }
  };

  callbacks = {};

  constructor() {
    this._randomSeed = Math.random() * 100;
    this._network = null;
    this._graph = null;
    this.isLayoutDagre = false;
    this.isLayoutDagreFixedLevel = true;
    this.isPhysicsEnabled = true;
    this._initGraphInstance();
    this.onShowSpinner = () => null;
    this.onHideSpinner = () => null;
  }

  /* =====================================================
   * Public functions
   * ==================================================== */

  getGraphInstance() {
    return this._network;
  }

  getGraphData() {
    var nodes = this._graph.nodes.get();
    var edges = this._graph.edges.get();
    var graph = {
      nodes: nodes,
      edges: edges
    };
    return graph;
  }

  setGraphData(graph) {
    if (!this.isLayoutDagre) this.onShowSpinner();
    this._network.setOptions({
      nodes: { level: this.isLayoutDagreFixedLevel ? 0 : undefined }
    });
    this._refreshNodesStyles(graph.nodes);
    this._refreshEdgesStyles(graph.edges);
    this._graph = {
      nodes: new vis.DataSet(graph.nodes),
      edges: new vis.DataSet(graph.edges.map((e) => ({ from: e.source, to: e.target, ...e })))
    };
    if (graph.nodes.length > 200) {
      console.log('large network mode');
      this._network.setOptions({
        layout: { improvedLayout: false },
        edges: { smooth: false }
      });
    }
    this._network.setData(this._graph);
    this._network.once('stabilizationIterationsDone', () => {
      if (graph.nodes.length > 200) {
        this._network.setOptions({ physics: false });
      }
      this.onHideSpinner();
    });
  }

  addGraph(graph) {
    let addedGraph = {
      nodes: this._refreshNodesStyles(
        graph.nodes.filter((n) => !_.find(this._graph.nodes.get(), { id: n.id }))
      ),
      edges: this._refreshEdgesStyles(
        graph.edges
          .filter((e) => !_.find(this._graph.edges.get(), { id: e.id }))
          .map((e) => ({ from: e.source, to: e.target, ...e }))
      )
    };
    this._graph.nodes.add(addedGraph.nodes);
    this._graph.edges.add(addedGraph.edges);
  }

  removeGraph(graph) {
    if (graph.edges && graph.edges.length > 0) {
      graph.edges.forEach((e) => this._graph.edges.remove(e.id));
    }
    if (graph.nodes && graph.nodes.length > 0) {
      graph.nodes.forEach((n) => this._graph.nodes.remove(n.id));
    }
  }

  updateGraph(graph) {
    this._graph.nodes.update(graph.nodes);
    this._graph.edges.update(graph.edges);
  }

  togglePhysics(enabled) {
    this.isPhysicsEnabled = enabled;
    this._network.setOptions({ physics: this.isPhysicsEnabled });
  }

  redrawGraph() {
    this._refreshGraphLayout();
    this._network.redraw();
  }

  refreshGraph = function () {
    this._network.redraw();
  };

  clearGraph() {
    this._graph.edges.clear();
    this._graph.nodes.clear();
  }

  destroy() {
    this._network.destroy();
  }

  updateNodeSelection(selectedNodes) {
    /*if(selectedNodes === undefined || selectedNodes.length == 0) {
      this.plugins.activeState.dropNodes();
    } else {
      this.plugins.activeState.dropNodes();
      for(var i = 0; i < selectedNodes.length; i++) {
          this.plugins.activeState.addNodes(selectedNodes[i].id);
      }
    }
    this.refreshGraph();*/
  }
  toggleAddEdge(enabled, externalCallback) {
    if (enabled) {
      this._enableAddEdge(externalCallback);
    } else {
      this._disableAddEdge();
    }
  }

  _enableAddEdge(externalCallback) {
    let addEdge = (data, visResultCallback) => {
      let from = this._graph.nodes.get(data.from);
      let to = this._graph.nodes.get(data.to);
      externalCallback({ from, to }, visResultCallback);
    };
    this._network.setOptions({ manipulation: { addEdge } });
    this._network.addEdgeMode();
  }

  _disableAddEdge() {
    this._network.setOptions({ manipulation: { addEdge: null } });
    this._network.disableEditMode();
  }

  bind(event, callback) {
    this.callbacks[event] = callback;
  }

  /* =====================================================
   * Private functions
   * ==================================================== */

  _initGraphInstance() {
    // create a network
    var container = document.getElementById('canvas-graph-panel');

    // provide the data in the vis format
    var data = {
      nodes: [],
      edges: []
    };

    // initialize your network!
    this._network = new vis.Network(container, data, this.options);
    this._initEventsCallbacks();
    this._refreshGraphLayout();
  }

  _initEventsCallbacks() {
    this.callbacks = {};
    this._network.on('click', ({ nodes, edges }) => {
      if ((!nodes || !nodes[0]) && (!edges || !edges[0])) {
        let onClickStage = this.callbacks[EVENTS.clickStage] || _.identity;
        onClickStage();
      } else if (nodes && nodes.length > 0) {
        let nodeId = nodes[0];
        let node = this._graph.nodes.get(nodeId);
        let onClickNode = this.callbacks[EVENTS.clickNode] || _.identity;
        onClickNode({ data: { node } });
      } else if (edges && edges.length > 0) {
        let edgeId = edges[0];
        let edge = this._graph.edges.get(edgeId);
        let onClickEdge = this.callbacks[EVENTS.clickEdge] || _.identity;
        onClickEdge({ data: { edge } });
      }
    });
    this._network.on('doubleClick', ({ nodes }) => {
      if (!nodes || !nodes[0]) return;
      let nodeId = nodes[0];
      let node = this._graph.nodes.get(nodeId);
      let onDoubleClickNode = this.callbacks[EVENTS.doubleClickNode] || _.identity;
      onDoubleClickNode({ data: { node } });
    });
    this._network.on('oncontext', ({ event, pointer }) => {
      event.preventDefault();
      let nodeId = this._network.getNodeAt(pointer.DOM);
      if (nodeId) {
        let node = _.find(this._graph.nodes.get(), { id: nodeId });
        let onRightClickNode = this.callbacks[EVENTS.rightClickNode] || _.identity;
        onRightClickNode({ data: { node }, pointer });
      } else {
        let edgeId = this._network.getEdgeAt(pointer.DOM);
        if (edgeId) {
          let edge = this._graph.edges.get(edgeId);
          let onRightClickEdge = this.callbacks[EVENTS.rightClickEdge] || _.identity;
          onRightClickEdge({ data: { edge }, pointer });
        }
      }
    });
  }

  _refreshGraphLayout() {
    if (this.isLayoutDagre) {
      this._refreshDagreLayout();
    } else {
      this._refreshForceLayout();
    }
    this._network.redraw();
  }

  _refreshForceLayout() {
    this._network.setOptions({
      layout: {
        randomSeed: this._randomSeed,
        hierarchical: false
      },
      physics: {
        enabled: this.isPhysicsEnabled
      }
    });
  }

  _refreshDagreLayout() {
    this._network.setOptions({
      layout: {
        randomSeed: this._randomSeed,
        hierarchical: {
          direction: 'LR',
          sortMethod: 'custom',
          blockShifting: false,
          edgeMinimization: false,
          parentCentralization: false
        }
      },
      physics: {
        enabled: this.isPhysicsEnabled
      }
    });
    this._onDagreStop();
  }

  _onDagreStop() {
    this._divideCluster('DATA_CATEGORY', 'PHYSICAL_FIELD');
    this._divideCluster('TASK', 'SYSTEM_LOGIN');
    this._divideCluster('PROCESSING', 'PHYSICAL_ENTITY');
    this._divideCluster('DATA_ACTOR', 'SYSTEM');
    this._alignVertically();
  }

  _divideCluster(label1, label2) {
    var nodes = this._graph.nodes
      .get()
      .filter((n) => n.labels.includes(label1) || n.labels.includes(label2))
      .map((node) => ({
        ...node,
        ...this._network.getPositions(node.id)[node.id]
      }))
      .sort((a, b) => a.y - b.y);
    var positions = nodes.map((n) => {
      return { x: n.x, y: n.y };
    });
    var cluster1 = nodes.filter((n) => n.labels.includes(label1));
    var cluster2 = nodes.filter((n) => n.labels.includes(label2));
    var clusterOrdered = cluster1.concat(cluster2);
    for (var i = 0; i < positions.length; i++) {
      this._network.moveNode(clusterOrdered[i].id, positions[i].x, positions[i].y);
    }
  }

  _alignVertically() {
    var nodes = this._graph.nodes
      .get()
      .map((n) => ({ ...n, ...this._network.getPositions(n.id)[n.id] }));
    let xs = nodes
      .map((n) => n.x)
      .reduce((acc, value) => {
        acc[value] = value;
        return acc;
      }, {});
    for (let column in xs) {
      let ns = nodes.filter((n) => n.x === xs[column]);
      let ys = ns.map((n) => n.y);
      let ymax = Math.max(...ys);
      let ymin = Math.min(...ys);
      let size = ns.length;
      let delta = (ymax - ymin) / size;
      ns.sort((a, b) => a.y - b.y).reduce((newY, node) => {
        this._network.moveNode(node.id, node.x, newY);
        return newY + delta;
      }, ymin);
    }
  }

  _findMainLabel(n) {
    var mainLabel = '';
    for (var i = 0; i < n.labels.length; i++) {
      for (var l in GRAPH_STYLES.nodes) {
        if (l === n.labels[i]) {
          mainLabel = l;
          break;
        }
      }
    }
    return mainLabel;
  }

  _refreshNodesStyles(nodes) {
    nodes.forEach((n) => {
      var mainLabel = this._findMainLabel(n);
      var style = GRAPH_STYLES.nodes[mainLabel];
      if (n.labels.length > 1) {
        let subtype = n.labels[1];
        let subtypeStyling = _.get(GRAPH_SUBTYPES_STYLES, `nodes.${subtype}.${mainLabel}`);
        if (subtypeStyling) {
          style = _.merge({}, style, subtypeStyling);
        }
      }
      if (style) {
        n.color = style.color;
        n.size = style.size ? style.size * 4 : 1;
        n.label = n.data[style.label];
        n.level = this.isLayoutDagreFixedLevel ? style.level : undefined;
        n.shape = 'dot';
        n.icon = {
          face: style.icon.font,
          size: style.size * 10,
          code: style.icon.content,
          color: 'white'
        };
      } else {
        // TODO create  a default node style in graphStyles.js
        n.color = 'black';
        n.label = n.data['nominativo'];
      }

      n.glyphs = [];
      if (n.data._visibilityAlertCount && parseInt(n.data._visibilityAlertCount) !== 0) {
        n.glyphs.push({
          position: 'top-right',
          color: 'red',
          textColor: 'white',
          strokeColor: 'white',
          fillColor: 'red',
          content: n.data._visibilityAlertCount
        });
      }
      if (n.data._noConsentFilterAlertCount && parseInt(n.data._noConsentFilterAlertCount) !== 0) {
        n.glyphs.push({
          position: 'top-left',
          fillColor: 'orange',
          textColor: 'white',
          strokeColor: 'white',
          content: n.data._noConsentFilterAlertCount
        });
      }
      if (
        n.data.additionalProperties != null &&
        _.isArray(n.data.additionalProperties) &&
        _.find(n.data.additionalProperties, { name: 'graph_alert' })
      ) {
        n.glyphs.push({
          position: 'bottom-left',
          fillColor: '#00aeca',
          textColor: 'white',
          strokeColor: 'white',
          content: '!'
        });
      }
    });
    return nodes;
  }

  _refreshEdgesStyles(edges) {
    for (var e of edges) {
      if (e.type === 'REL_DATA_FLOW') {
        Object.assign(e, GRAPH_STYLES.edges.REL_DATA_FLOW);
      } else if (e.type === 'REL_LOGICAL_RELATION') {
        Object.assign(e, GRAPH_STYLES.edges.REL_LOGICAL_RELATION);
        e.label = e.data?.name;
        e.data.description = e.data?.relationType;
      } else {
        Object.assign(e, GRAPH_STYLES.edges.REL_DEFAULT);
      }
    }
    return edges;
  }
}
