import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import QualityKqisRoutes from 'pages/quality/kqis/QualityKqisRoutes';
import QualityProbesRoutes from 'pages/quality/probes/QualityProbesRoutes';
import QualityAssessmentRoutes from 'pages/quality/assessment/QualityAssessmentRoutes';

export default function DataQualityRoutes(props) {
  return (
    <Switch>
      <Route path={`${props.match.url}/kqis`} component={QualityKqisRoutes} />
      <Route path={`${props.match.url}/probes`} component={QualityProbesRoutes} />
      <Route path={`${props.match.url}/assessment`} component={QualityAssessmentRoutes} />
      <Redirect to={`${props.match.url}/kqis`} />
    </Switch>
  );
}
