import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Card, CardHeader, CardContent, CardActions, Button } from '@mui/material';

import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import DataActorChipListEditor from '../../../data_actors/commons/DataActorsChipListEditor';
import { RpaArchiveEditorModal } from '../commons/RpaArchiveEditorModal';

function RpaArchiveDetailCard({
  rpaArchive,
  deleteRpaArchive,
  modifyRpaArchive,
  createRpaArchiveRecord
}) {
  let history = useHistory();
  let { t } = useTranslation();
  return (
    <Card>
      <CardHeader title={rpaArchive.name} subheader={`enabled: ${rpaArchive.enabled}`}></CardHeader>
      <CardContent>
        <h4>Data Controllers</h4>
        <DataActorChipListEditor
          dataActors={_.get(rpaArchive, 'filters.dataControllers', [])}></DataActorChipListEditor>
        <h4>Data Processors</h4>
        <DataActorChipListEditor
          dataActors={_.get(rpaArchive, 'filters.dataProcessors', [])}></DataActorChipListEditor>
      </CardContent>
      <Authorize hasPermissions={[Permissions.PROCESSINGS_ADMIN]}>
        <CardActions>
          <ModifyAction {...{ rpaArchive, modifyRpaArchive }} />
          <ConfirmButton
            onClick={() =>
              deleteRpaArchive(rpaArchive.uuid).then(() => history.push('/reports/rpa/archives'))
            }>
            {t('commons.actions.delete')}
          </ConfirmButton>
          <CreateVersionAction
            rpaArchive={rpaArchive}
            onClick={() => createRpaArchiveRecord(rpaArchive.uuid)}></CreateVersionAction>
        </CardActions>
      </Authorize>
    </Card>
  );
}

function ModifyAction({ rpaArchive, modifyRpaArchive }) {
  let { t } = useTranslation();
  let [open, setOpen] = React.useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      <RpaArchiveEditorModal
        rpaArchive={rpaArchive}
        open={open}
        onCancel={() => setOpen(false)}
        onSubmit={(rpaArchive, contentPatch) => {
          modifyRpaArchive(rpaArchive).then(() => setOpen(false));
        }}
      />
    </>
  );
}

function CreateVersionAction({ onClick }) {
  return (
    <ConfirmButton message='Do you want to create a new record?' onClick={onClick}>
      Create Record
    </ConfirmButton>
  );
}

export default RpaArchiveDetailCard;
