import { BlindataApi } from './ApiUtils';

export default class RpaTemplatesApi extends BlindataApi {
  getRpaTemplates(page = 0, size = 10) {
    let params = { page, size };
    return this.get('/api/v1/reports/rpa/templates', params);
  }

  getRpaTemplate(uuid) {
    return this.get(`/api/v1/reports/rpa/templates/${uuid}`);
  }

  postRpaTemplate(rpaTemplate) {
    return this.post(`/api/v1/reports/rpa/templates`, rpaTemplate);
  }

  putRpaTemplate(rpaTemplate) {
    return this.put(`/api/v1/reports/rpa/templates/${rpaTemplate.uuid}`, rpaTemplate);
  }

  deleteRpaTemplate(uuid) {
    return this.delete(`/api/v1/reports/rpa/templates/${uuid}`);
  }
}
