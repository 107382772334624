import engagementDashboards from './engagementDashboards';
import engagementHits from './engagementHits';
import recentlyViewed from './recentlyViewed';
import { combineReducers } from 'redux';

export default combineReducers({
  engagementDashboards,
  engagementHits,
  recentlyViewed
});
