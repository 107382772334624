import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { Warning } from '@mui/icons-material';
import UsersInCurrentTenantItemAutocomplete from 'pages/settings/users/commons/UsersInCurrentTenantItemAutocomplete';
import _ from 'lodash';

import DatePicker, { hourAdjustments } from '../../../../components/pickers/DatePicker';
import StewardshipTargetTypes from '../../common/StewardshipTargetTypes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RolesItemAutoComplete from '../../roles/commons/RolesItemAutoComplete';
import SearchItemAutoComplete from '../../../settings/search/SearchItemAutocomplete';
import TeamsItemAutocomplete from 'pages/settings/teams/common/TeamsItemAutocomplete';

function ResponsibilityModal({ open, setOpen, responsibility, onSubmit, isChipEditor }) {
  const { t } = useTranslation();
  const INITIAL_STATE = {
    user: null,
    assigneeTeam: null,
    stewardshipRole: null,
    resourceType: '',
    resourceIdentifier: '',
    resourceName: '',
    description: '',
    startDate: Date.now(),
    endDate: null
  };
  const [editor, setEditor] = useState(responsibility || { ...INITIAL_STATE });
  const [warn, setWarn] = useState(false);

  return (
    <Dialog fullWidth={true} open={open}>
      <DialogTitle>Create Responsibility</DialogTitle>
      <DialogContent>
        <UsersInCurrentTenantItemAutocomplete
          value={editor.user}
          disabled={editor.assigneeTeam}
          onChange={(user) =>
            setEditor({ ...editor, user: user, assigneeTeam: null })
          }></UsersInCurrentTenantItemAutocomplete>

        <TeamsItemAutocomplete
          value={editor.team}
          disabled={editor.user}
          onChange={(team) =>
            setEditor({ ...editor, assigneeTeam: team, user: null })
          }></TeamsItemAutocomplete>
        <TextField
          variant="standard"
          fullWidth
          label='Description'
          placeholder='Enter the description'
          value={editor.description}
          onChange={(event) => setEditor({ ...editor, description: event.target.value })} />
        {!isChipEditor && (
          <SearchItemAutoComplete
            resourceTypes={Object.values(StewardshipTargetTypes)}
            value={{
              resourceIdentifier: editor?.resourceIdentifier,
              resourceName: editor?.resourceName,
              resourceType: editor?.resourceType
            }}
            onChange={(resource) =>
              setEditor({
                ...editor,
                resourceIdentifier: resource && resource.resourceIdentifier,
                resourceName: resource && resource.name,
                resourceType: resource && resource.resourceType
              })
            }></SearchItemAutoComplete>
        )}
        {!isChipEditor && (
          <RolesItemAutoComplete
            resourceTypes={Object.values(StewardshipTargetTypes)}
            onChange={(role) =>
              setEditor({ ...editor, stewardshipRole: role })
            }></RolesItemAutoComplete>
        )}
        <RoleWarning
          setWarn={setWarn}
          resourceType={editor.resourceType}
          role={editor.stewardshipRole}></RoleWarning>
        <DatePicker
          clearable={true}
          disabled={editor.startActivityTime}
          label={'Start Date'}
          hintText={'Start Date'}
          hourAdjustment={hourAdjustments.startOfDay}
          value={editor.startDate || Date.now()}
          onChange={(date) => setEditor({ ...editor, startDate: date })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={warn || getDisabled(editor)}
          onClick={() => {
            onSubmit(editor);
            setOpen(false);
          }}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RoleWarning({ resourceType, role, setWarn }) {
  if (!resourceType || !role) return null;
  if (!role.resources.filter((type) => type.resourceType === resourceType).length > 0) {
    setWarn(true);
    return (
      <Grid container alignItems='center'>
        <Grid item>
          <Warning color='secondary' />
        </Grid>
        <Grid item>
          <Typography variant='caption'>Role not allowed for this type of resource</Typography>
        </Grid>
      </Grid>
    );
  } else {
    setWarn(false);
    return null;
  }
}

function getDisabled(editor) {
  return (
    _.isEmpty(editor.resourceIdentifier) ||
    _.isEmpty(editor.stewardshipRole) ||
    (!editor.user && !editor.assigneeTeam) ||
    (editor.user && editor.assigneeTeam)
  );
}
export default ResponsibilityModal;
