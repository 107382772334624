import React from 'react';
import PaginatedPanel from '../../../../components/pagination/PaginatedPanel';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchTemplatesProcessingsPage } from '../../../../actions/TemplatesActions';

function TemplatesProcessingsList({ currentPage, onPageSelection }) {
  return (
    <PaginatedPanel autoload onPageSelection={onPageSelection} currentPage={currentPage}>
      <>
        {currentPage.content.map((item) => (
          <TemplatesProcessingsListItem templateProcessing={item}></TemplatesProcessingsListItem>
        ))}
      </>
    </PaginatedPanel>
  );
}

function TemplatesProcessingsListItem({ templateProcessing }) {
  const { t } = useTranslation();
  return (
    <ExpandableCard
      title={templateProcessing.name}
      subheader={templateProcessing.code}
      actions={[
        <Button
          children={t('commons.actions.detail')}
          component={Link}
          to={`/templates/processings/${templateProcessing.sequenceId}`}
        />
      ]}></ExpandableCard>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.templates.templatesProcessings.currentPage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onPageSelection: (page) => dispatch(fetchTemplatesProcessingsPage({ page }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesProcessingsList);
