import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { BubbleChart } from '@mui/icons-material';
import {
  addGraphUuidFilter,
  requestGraph,
  changePerspective,
  clearGraphFilters
} from '../../../actions/GraphActions';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

const defaultPerspectiveOptions = {
  logicalPerspective: false,
  businessPerspective: false,
  loginsPerspective: false,
  physicalPerspective: false,
  dataFlowsPerspective: false,
  dataFlowsDepth: 0,
  dataFlowsDepthHierarchy: false
};

function GraphLinkButton({
  graphUuidFilter,
  perspectiveOptions,
  addGraphUuidFilter,
  requestGraph,
  changePerspective,
  clearGraphFilters,
  component,
  size,
  ...otherProps
}) {
  let history = useHistory();
  function handleClick() {
    if (!graphUuidFilter) {
      console.warn('GraphLinkButton: No graph uuid filter defined');
      return;
    }
    clearGraphFilters();
    changePerspective({ ...defaultPerspectiveOptions, ...perspectiveOptions });
    addGraphUuidFilter(graphUuidFilter);
    requestGraph();
    history.push('/graph');
  }
  return (
    <Tooltip title='Show graph'>
      {component ? (
        React.createElement(component, { ...otherProps, onClick: handleClick })
      ) : (
        <IconButton size={size || 'medium'} onClick={handleClick}>
          <BubbleChart />
        </IconButton>
      )}
    </Tooltip>
  );
}

const mapDispatchToProps = {
  addGraphUuidFilter,
  requestGraph,
  changePerspective,
  clearGraphFilters
};

export default connect(null, mapDispatchToProps)(GraphLinkButton);
