import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Card, CardHeader, CardContent, CardActions } from '@mui/material';
import Button from '@mui/material/Button';

import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import PhysicalEntityCreateModal from 'pages/systems/physical_entities/commons/PhysicalEntityCreateModal';
import Subheader from 'pages/systems/physical_entities/detail/PhysicalEntityDetailsCard/header/Subheader';
import Decorators from 'pages/systems/physical_entities/detail/PhysicalEntityDetailsCard/header/Decorators';
import GeneralSection from 'pages/systems/physical_entities/detail/PhysicalEntityDetailsCard/GeneralSection';
import DataCategoriesSection from 'pages/systems/physical_entities/detail/PhysicalEntityDetailsCard/DataCategoriesSection';
import HistoryButtonLink from '../../../../history/commons/HistoryButtonLink';
import HistoryTargetTypes from '../../../../history/commons/HistoryTargetTypes';

import StewardshipAuthorize from 'components/permissions/StewardshipAuthorize';

import ResourceTypes from 'constants/ResourceTypes';
import Permissions from 'constants/Permissions';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const PhysicalEntityDetail = ({
  physicalEntity,
  modifyPhysicalEntity,
  deletePhysicalEntity,
  addDataCategoryToPhysicalEntity,
  t
}) => {
  const history = useHistory();
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleEdit = (entity) => modifyPhysicalEntity(entity).then(() => setEditModalOpen(false));
  const handleDelete = (entity) => deletePhysicalEntity(entity).then(() => history.goBack());

  return (
    <Card>
      <CardHeader
        title={
          physicalEntity.schema
            ? `${physicalEntity.schema}.${physicalEntity.name}`
            : physicalEntity.name
        }
        subheader={<Subheader system={physicalEntity.system} type={physicalEntity.tableType} />}
        action={<Decorators dataset={physicalEntity.dataSet} hidden={physicalEntity.hidden} />}
      />
      <CardContent>
        <GeneralSection physicalEntity={physicalEntity} />
        <DataCategoriesSection
          physicalEntity={physicalEntity}
          onAddDataCategory={addDataCategoryToPhysicalEntity}
        />
      </CardContent>
      <CardActions>
        <StewardshipAuthorize
          resourceType={ResourceTypes.PHYSICAL_ENTITY}
          resourceIdentifier={physicalEntity.uuid}
          hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
          <Button onClick={() => setEditModalOpen(true)}>{t('commons.actions.modify')}</Button>
        </StewardshipAuthorize>
        <StewardshipAuthorize
          resourceType={ResourceTypes.PHYSICAL_ENTITY}
          resourceIdentifier={physicalEntity.uuid}
          hasPermissions={[Permissions.SYSTEMS_ADMIN]}>
          <ConfirmButton
            message={
              'Do you really want to delete this physical entity? This will delete associated dataflows and resources.'
            }
            onClick={() => handleDelete(physicalEntity)}>
            {t('commons.actions.delete')}
          </ConfirmButton>
          <HistoryButtonLink
            targetType={HistoryTargetTypes.PHYSICAL_ENTITY}
            targetIdentifier={physicalEntity.uuid}></HistoryButtonLink>
          <div style={{ marginLeft: 'auto' }}>
            <WebActionsButton
              page={TargetPages.PHYSICAL_ENTITY_DETAIL_PAGE}
              resource={physicalEntity}
            />
          </div>
        </StewardshipAuthorize>
      </CardActions>
      {editModalOpen && (
        <PhysicalEntityCreateModal
          title='Modify Physical Entity'
          open={editModalOpen}
          key={physicalEntity}
          physicalEntity={physicalEntity}
          handleSubmit={handleEdit}
          handleCancel={() => setEditModalOpen(false)}
        />
      )}
    </Card>
  );
};

PhysicalEntityDetail.propTypes = {
  physicalEntity: PropTypes.object.isRequired,
  modifyPhysicalEntity: PropTypes.func.isRequired,
  deletePhysicalEntity: PropTypes.func.isRequired,
  addDataCategoryToPhysicalEntity: PropTypes.func.isRequired
};

export default withTranslation()(PhysicalEntityDetail);
