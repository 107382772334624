import React from 'react';
import { Switch, Route } from 'react-router';
import StewardshipResponsibilitiesPage from './StewardshipResponsibilitiesPage';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  return (
    <Switch>
      <Route path={`${props.match.url}/:uuid`} component={StewardshipResponsibilitiesPage}></Route>

      <Route path={`${props.match.url}`} component={StewardshipResponsibilitiesPage}></Route>
    </Switch>
  );
}
