import React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdditionalPropertiesEditor from 'components/additional_properties/AdditionalPropertiesEditor';
import ResourceTypes from 'constants/ResourceTypes';
import TableCell from 'components/tables/GenericTableCell';
import { ChipItem } from 'components/chiplist/ChipList';
import { Add, Delete } from '@mui/icons-material';
import ProcessingItemAutoComplete from 'pages/processings/utils/ProcessingItemAutoComplete';

function ContractDefinitionModal({ open, contractDefinition, onCancel, onSubmit }) {
  let { t } = useTranslation();
  let [editor, setEditor] = useState(contractDefinition || {});
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        {contractDefinition ? `Modify ${contractDefinition.name}` : 'Create a new contract'}
      </DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          required
          fullWidth
          label='Name'
          placeholder='Enter the name'
          value={editor.name || ''}
          onChange={({ target: { value: name } }) => setEditor({ ...editor, name })}
        />
        <TextField
          variant='standard'
          required
          fullWidth
          label='Contract Name'
          placeholder='Enter the contract name'
          value={editor.contractName || ''}
          onChange={({ target: { value: contractName } }) => setEditor({ ...editor, contractName })}
        />
        <TextField
          variant='standard'
          fullWidth
          label='Category'
          placeholder='Enter the category'
          value={editor.category || ''}
          onChange={({ target: { value: category } }) => setEditor({ ...editor, category })}
        />
        <TextField
          variant='standard'
          fullWidth
          multiline
          label='Description'
          placeholder='Enter the description'
          value={editor.description || ''}
          onChange={({ target: { value: description } }) => setEditor({ ...editor, description })}
        />
        <ContractDefinitionProcessingEditor
          processings={editor.processings}
          onChange={(processings) => setEditor({ ...editor, processings })}
        />
        <AdditionalPropertiesEditor
          additionalProperties={editor.additionalProperties}
          resourceType={ResourceTypes.CONTRACT}
          onChange={(newList) => setEditor({ ...editor, additionalProperties: newList })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
        <Button color='primary' onClick={() => onSubmit(editor)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ContractDefinitionProcessingEditor({ processings, onChange }) {
  return (
    <>
      <Toolbar style={{ padding: 0 }}>
        <h4 style={{ flexGrow: 1 }}>Associated processings</h4>
        {onChange && <AddAction onAdd={(p) => onChange((processings || []).concat([p]))} />}
      </Toolbar>
      {!processings?.length ? (
        <Typography variant='body2' align='center' style={{ textAlign: 'center', padding: 4 }}>
          No processings associated to this contract definition.
        </Typography>
      ) : (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'></TableCell>
              <TableCell>Placeholder Name</TableCell>
              <TableCell>Processing</TableCell>
              <TableCell padding='checkbox'>Consent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(processings || []).map((p) => (
              <TableRow>
                <TableCell>
                  <IconButton
                    size='small'
                    onClick={() => onChange(processings.filter((e) => e !== p))}>
                    <Delete />
                  </IconButton>
                </TableCell>
                <TableCell>{p.placeholderName}</TableCell>
                <TableCell>
                  <ChipItem to={`/processings/${p.processing.uuid}`} label={p.processing.name} />
                </TableCell>
                <TableCell padding='checkbox'>
                  <Checkbox checked={p.requiresConsent} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

function AddAction({ onAdd }) {
  let { t } = useTranslation();
  let [open, setOpen] = useState(false);
  let [processing, setProcessing] = useState(null);
  let [requiresConsent, setRequiresConsent] = useState(false);
  let [placeholderName, setPlaceHolderName] = useState('');

  return (
    <>
      <IconButton size='small' onClick={() => setOpen(true)}>
        <Add></Add>
      </IconButton>
      <Dialog open={open} fullWidth>
        <DialogTitle title='Add a physical association'></DialogTitle>
        <DialogContent>
          <ProcessingItemAutoComplete value={processing} onChange={(sys) => setProcessing(sys)} />
          <TextField
            variant='standard'
            fullWidth
            label='Placeholder Name'
            value={placeholderName}
            onChange={({ target: { value: placeholderName } }) =>
              setPlaceHolderName(placeholderName)
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={requiresConsent}
                onChange={(event) => {
                  setRequiresConsent(event.target.checked);
                }}
                name='enabled'
                color='primary'
              />
            }
            label='Requires consent'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t('commons.actions.cancel')}</Button>
          <Button
            variant='contained'
            color='primary'
            disabled={!placeholderName || !processing}
            onClick={() => {
              onAdd({ processing, placeholderName, requiresConsent });
              setOpen(false);
            }}>
            {t('commons.actions.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ContractDefinitionModal;
