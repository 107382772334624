import React from 'react';

import Grid from '@mui/material/Grid';
import PhysicalEntitiesExportButton from '../utils/PhysicalEntitiesExportButton';
import PhysicalEntitiesFilters from 'pages/systems/physical_entities/list/PhysicalEntitiesFilters';
import PhysicalEntitiesSearchBox from './PhysicalEntitiesSearchBox';
import { useTranslation } from 'react-i18next';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const PhysicalEntitiesListToolbox = ({ systemUUID }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems='center'>
      <Grid item>
        <PhysicalEntitiesSearchBox />
      </Grid>
      <Grid item>
        <FavoritesWidget
          itemIdentifier={'page:physicalentities-list'}
          iconSize={'small'}
          pageTitle={t('physicalEntities.header')}></FavoritesWidget>
      </Grid>
      <Grid item>
        <PhysicalEntitiesExportButton></PhysicalEntitiesExportButton>
      </Grid>
      <Grid item>
        <PhysicalEntitiesFilters systemUuid={systemUUID} />
      </Grid>
      <Grid item>
        <WebActionsButton page={TargetPages.PHYSICAL_ENTITY_LIST_PAGE} />
      </Grid>
    </Grid>
  );
};

export default PhysicalEntitiesListToolbox;
