import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import DatePicker from '../../../components/pickers/DatePicker';
import ItemSelectField from '../../../components/itemselector/ItemSelectField';

const LifeCycleFormSection = ({
  t,
  onChange,
  endDate,
  startDate,
  consentDuration,
  consentDurationUnit
}) => {
  const timeUnits = ['YEARS', 'MONTHS', 'DAYS', 'HOURS'].map((tu) => ({
    val: tu,
    name: t('commons.timeunits.values.' + tu)
  }));

  return (
    <div>
      <h4>{t('processings.lifecycle.header')}</h4>

      <DatePicker
        fullWidth
        label={t('processings.lifecycle.fields.startDate')}
        hintText={t('processings.lifecycle.fields.startDateHint')}
        value={startDate}
        clearable
        onChange={(date) => onChange({ startDate: date })}
      />
      <br></br>

      <DatePicker
        fullWidth
        label={t('processings.lifecycle.fields.endDate')}
        hintText={t('processings.lifecycle.fields.endDateHint')}
        value={endDate}
        clearable
        onChange={(date) => onChange({ endDate: date })}
      />
      <br></br>

      <div>
        <TextField
          variant="standard"
          fullWidth
          placeholder={t('processings.lifecycle.fields.consentDurationHint')}
          label={t('processings.lifecycle.fields.consentDuration')}
          multiLine={true}
          value={consentDuration || ''}
          type='number'
          onChange={(event) =>
            onChange({ consentDuration: parseInt(event.target.value.replace(/\D/g, ''), 10) })
          } />
      </div>

      <ItemSelectField
        itemName={t('processings.lifecycle.fields.consentDurationUnit')}
        keyProperty={'val'}
        nameProperty={'name'}
        value={timeUnits.find((e) => e.val === consentDurationUnit)}
        values={timeUnits}
        onChange={(obj) => onChange({ consentDurationUnit: obj && obj.val })}
      />
    </div>
  );
};

LifeCycleFormSection.propTypes = {
  endDate: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  consentDuration: PropTypes.number,
  consentDurationUnit: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(LifeCycleFormSection);
