import React from 'react';
import ToolbarActions from '../../sidebar/ToolbarActions';
import { Button } from '@mui/material';
import { useRouteMatch, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

export default function QualityProbesNavbar(props) {
  let { url } = useRouteMatch();
  let { pathname } = useLocation();
  const connectionsUrl = `${url}/connections`;
  const projectsUrl = `${url}/projects`;
  return (
    <ToolbarActions>
      <Button
        color={pathname === connectionsUrl ? 'primary' : 'grey'}
        children='Connections'
        component={Link}
        to={connectionsUrl}></Button>
      <Button
        color={pathname === projectsUrl ? 'primary' : 'grey'}
        children='Projects'
        component={Link}
        to={projectsUrl}></Button>
    </ToolbarActions>
  );
}
