import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Breadcrumbs from 'pages/sidebar/Navigation';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionsBuilder from 'actions/PhysicalConstraintsActions';
import { t } from 'i18next';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import PhysicalConstraintModal from '../commons/PhysicalConstraintModal';
import { useHistory } from 'react-router';
import {
  ConstraintTypesIcons,
  ConstraintTypesWithoutReferences
} from 'constants/PhysicalConstraintTypes';
import TableCell from 'components/tables/GenericTableCell';
import { PhysicalEntityName, PhysicalFieldName } from '../list/PhysicalConstraintListItemNames';
import { PhysicalConstraintTargetNames } from '../list/PhysicalConstraintTargetNames';
import SqlVisualizer from 'components/syntaxhighlithter/SqlVisualizer';

const bodyStyle = {
  border: '1px solid',
  borderColor: 'lightgrey',
  borderRadius: '0.5em',
  padding: '1em'
};

const PhysicalConstraintDetailPage = ({ ...props }) => {
  const [physicalConstraintModalOpen, setPhysicalConstraintModalOpen] = useState(false);

  const physicalConstraintUuid = props.match.params['physicalConstraintUuid'];
  const physicalConstraint = useSelector(
    (state) => state.physicalconstraints.content[physicalConstraintUuid]
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!physicalConstraint) {
      dispatch(ActionsBuilder.fetch(physicalConstraintUuid));
    }
  }, [dispatch, physicalConstraint, physicalConstraintUuid]);

  if (!physicalConstraint) {
    return <></>;
  }

  const hasReferences = !ConstraintTypesWithoutReferences.includes(physicalConstraint.type);
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.physicalConstraints'), to: '/physical-constraints' },
          {
            text: physicalConstraint?.name,
            to: `/physical-constraints/detail/${physicalConstraint.uuid}`
          }
        ]}
      />
      <Typography variant='h4'>
        {t('physicalConstraints.detail.header', {
          physicalConstraintName: physicalConstraint.name
        })}
      </Typography>
      <Card style={{ margin: '8px 0 8px 0' }}>
        <CardHeader
          sx={{ paddingBottom: 0 }}
          title={
            <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
              {ConstraintTypesIcons[physicalConstraint.type] || <></>}
              <div style={{ paddingLeft: '8px' }}>
                {physicalConstraint?.schema
                  ? `${physicalConstraint?.schema}.${physicalConstraint?.name}`
                  : physicalConstraint?.name}
              </div>
            </div>
          }
          subheader={<>System: {physicalConstraint.system.name}</>}
        />
        <CardContent sx={{ paddingTop: 0 }}>
          {physicalConstraint.description ? (
            <>
              <Typography variant='body1'>
                {t('physicalConstraints.detail.descriptionHeader')}
              </Typography>
              <p>{physicalConstraint.description}</p>
            </>
          ) : (
            <></>
          )}
          <GeneralDefinitionList physicalConstraint={physicalConstraint} />

          {hasReferences ? (
            <PhysicalConstraintTable physicalConstraint={physicalConstraint} />
          ) : (
            <>
              <h4>Targets</h4>
              <Box
                sx={{
                  p: 2,
                  border: '1px solid lightGrey',
                  borderRadius: 2,
                  textAlign: 'center',
                  overflowX: 'auto'
                }}>
                <PhysicalConstraintTargetNames
                  physicalConstraint={physicalConstraint}
                  variant='h5'
                />
              </Box>
            </>
          )}
          {physicalConstraint.clause ? (
            <>
              <h4>Clause</h4>
              <SqlVisualizer text={physicalConstraint.clause} style={bodyStyle} />
            </>
          ) : (
            <></>
          )}
        </CardContent>
        <CardActions>
          <Button onClick={() => setPhysicalConstraintModalOpen(true)}>
            {t('commons.actions.modify')}
          </Button>
          <ConfirmButton
            message={t('physicalConstraints.detail.deleteConfirmMessage')}
            onClick={() =>
              dispatch(ActionsBuilder.delete(physicalConstraint.uuid)).then((res) =>
                history.goBack()
              )
            }>
            {t('commons.actions.delete')}
          </ConfirmButton>
        </CardActions>
      </Card>
      {physicalConstraintModalOpen && (
        <PhysicalConstraintModal
          open={physicalConstraintModalOpen}
          onClose={() => setPhysicalConstraintModalOpen(false)}
          title={t('physicalConstraints.modal.modifyTitle')}
          physicalConstraint={physicalConstraint}
          onSubmit={(modifiedConstraint) => dispatch(ActionsBuilder.modify(modifiedConstraint))}
        />
      )}
    </>
  );
};

const GeneralDefinitionList = ({ physicalConstraint }) => {
  return (
    <div className='dls-wrapper'>
      <KVDefinitionList
        header={t('physicalConstraints.detail.general.title')}
        item={{
          [t('physicalConstraints.detail.general.type')]: physicalConstraint.type,
          ...(physicalConstraint?.accessMethod && {
            [t('physicalConstraints.detail.general.accessMethod')]: physicalConstraint?.accessMethod
          }),
          ...(physicalConstraint?.updateRule && {
            [t('physicalConstraints.detail.general.updateRule')]: physicalConstraint?.updateRule
          }),
          ...(physicalConstraint?.deleteRule && {
            [t('physicalConstraints.detail.general.deleteRule')]: physicalConstraint?.deleteRule
          }),
          ...(physicalConstraint?.createdAt && {
            [t('physicalConstraints.detail.general.createdAt')]: new Date(
              physicalConstraint?.createdAt
            ).toLocaleDateString()
          }),
          ...(physicalConstraint?.updatedAt && {
            [t('physicalConstraints.detail.general.updatedAt')]: new Date(
              physicalConstraint?.updatedAt
            ).toLocaleDateString()
          })
        }}
      />
    </div>
  );
};

const PhysicalConstraintTable = ({ physicalConstraint }) => {
  return (
    <Table style={{ overflowX: 'auto' }}>
      <TableHead>
        <TableCell size='small'>{t('physicalConstraints.tableByType.targetEntities')}</TableCell>
        <TableCell size='small'>
          {t('physicalConstraints.tableByType.referencedEntities')}
        </TableCell>
      </TableHead>
      <TableBody>
        {physicalConstraint.physicalEntitiesConstraints?.map((pec) => (
          <TableRow>
            <TableCell>
              <Typography variant='body1'>
                <PhysicalEntityName physicalEntity={pec.targetPhysicalEntity} />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='body1'>
                <PhysicalEntityName physicalEntity={pec.referencedPhysicalEntity} />
              </Typography>
            </TableCell>
          </TableRow>
        ))}
        {physicalConstraint.physicalFieldsConstraints?.map((pfc) => (
          <TableRow>
            <TableCell>
              <Typography variant='body1'>
                {pfc.targetPhysicalField?.physicalEntity ? (
                  <>
                    <PhysicalEntityName physicalEntity={pfc.targetPhysicalField?.physicalEntity} />
                    {` : `}
                  </>
                ) : (
                  <></>
                )}
                <PhysicalFieldName physicalField={pfc.targetPhysicalField} />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='body1'>
                {pfc.referencedPhysicalField?.physicalEntity ? (
                  <>
                    <PhysicalEntityName
                      physicalEntity={pfc.referencedPhysicalField?.physicalEntity}
                    />
                    {` : `}
                  </>
                ) : (
                  <></>
                )}
                <PhysicalFieldName physicalField={pfc.referencedPhysicalField} />
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PhysicalConstraintDetailPage;
