import { combineReducers } from 'redux';
import {
  CONFIGURE_SEARCH_PARAMS,
  SEARCH_CONSENTS,
  FETCH_CONSENT,
  CLEAR_CONSENTS_SEARCH
} from '../constants/ActionTypes';

var moment = require('moment');

const searchDefault = {
  processingUuid: null,
  processing: null,
  dataSubjectKeyValue: null,
  dataSubjectKeyName: null,
  context: null,
  nullContext: false,
  startTimestamp: moment()
    .startOf('day')
    .toISOString(),
  endTimestamp: null
};

const search = (state = searchDefault, action) => {
  switch (action.type) {
    case CONFIGURE_SEARCH_PARAMS:
      return { ...state, ...action.payload };
    case CLEAR_CONSENTS_SEARCH:
      return searchDefault;
    default:
      return state;
  }
};

const content = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONSENT:
      return {
        ...state,
        [action.payload.uuid]: action.payload
      };
    default:
      return state;
  }
};

const currentPage = (state = null, action) => {
  switch (action.type) {
    case SEARCH_CONSENTS:
      return action.payload;
    case CLEAR_CONSENTS_SEARCH:
    case CONFIGURE_SEARCH_PARAMS:
      return null;
    default:
      return state;
  }
};

export default combineReducers({ search, content, currentPage });
