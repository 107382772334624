import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UsersInCurrentTenantItemAutocomplete from 'pages/settings/users/commons/UsersInCurrentTenantItemAutocomplete';
import RolesItemAutoComplete from '../../roles/commons/RolesItemAutoComplete';

function StewardshipAssingmentsModal({ open, setOpen, title, onSubmit, user, role }) {
  const { t } = useTranslation();
  const [editor, setEditor] = useState({ user: user, stewardshipRole: role });

  return (
    <Dialog fullWidth={true} open={open}>
      <DialogTitle children={title}></DialogTitle>
      <DialogContent>
        <UsersInCurrentTenantItemAutocomplete
          value={editor.user || null}
          onChange={(user) => {
            setEditor({ ...editor, user });
          }}></UsersInCurrentTenantItemAutocomplete>
        {!role && (
          <RolesItemAutoComplete
            value={editor.stewardshipRole}
            onChange={(role) =>
              setEditor({ ...editor, stewardshipRole: role })
            }></RolesItemAutoComplete>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            setEditor({
              user: user,
              stewardshipRole: role
            });
          }}>
          {t('commons.actions.cancel')}
        </Button>
        <Button
          onClick={() => {
            onSubmit(editor);
            setEditor({
              user: user,
              stewardshipRole: role
            });
            setOpen(false);
          }}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StewardshipAssingmentsModal;
