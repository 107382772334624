import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import IssueTypes from '../detail/IssueTypes';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  InputLabel,
  MenuItem
} from '@mui/material';

function IssueTypesAddModal({ open, onCancel, onSubmit }) {
  let { t } = useTranslation();
  const [type, setType] = useState(null);
  const types = [
    IssueTypes.BUG,
    IssueTypes.VULNERABILITY,
    IssueTypes.WARNING,
    IssueTypes.ALERT,
    IssueTypes.TASK,
    IssueTypes.FEATURE_REQUEST,
    IssueTypes.QUESTION
  ];
  return (
    <React.Fragment>
      <Dialog open={open} fullWidth>
        <DialogTitle>{'Add Type'}</DialogTitle>
        <DialogContent>
          <FormControl variant='standard' fullWidth margin='dense'>
            <InputLabel id='issue-type-select-label'>Type</InputLabel>
            <Select
              variant='standard'
              labelId='issue-type-select-label'
              id='issue-type-select'
              value={type || ''}
              onChange={(event) => setType(event.target.value)}>
              {types.map((issueType) => (
                <MenuItem key={issueType} value={issueType}>
                  {t(`issueMngt.issues.types.${issueType}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.cancel')}
            onClick={() => {
              onCancel();
              setType(null);
            }}></Button>
          <Button
            children={t('commons.actions.submit')}
            variant='contained'
            color='primary'
            onClick={() => {
              onSubmit(type);
              setType(null);
              onCancel();
            }}></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default IssueTypesAddModal;
