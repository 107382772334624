import dictionaries from './dictionaries';
import rules from './rules';
import assignments from './assignments';
import assignmentsconfig from './assignmentsconfig';
import agentjobsettings from './agentjobsettings';
import { combineReducers } from 'redux';

export default combineReducers({
  dictionaries,
  assignments,
  rules,
  assignmentsconfig,
  agentjobsettings
});
