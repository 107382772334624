import React from 'react';
import { connect } from 'react-redux';
import ItemAutoComplete from '../../../../components/itemselector/ItemAutoComplete';
import { fetchRolesPage } from '../../../../actions/StewardshipActions';
function RolesItemAutoComplete({ searchContent, onSearch, onChange, value }) {
  return (
    <ItemAutoComplete
      values={searchContent}
      value={value}
      showAllOptions
      itemName='Role'
      nameProperty={'name'}
      onChange={(newValue) => onChange(newValue)}
      onSearch={(inputValue) => {
        onSearch({ search: inputValue });
      }}></ItemAutoComplete>
  );
}

const mapStateToProps = (state, props) => ({
  searchContent: state.stewardship.roles.currentPage.content
});
const mapDispatchToProps = (dispatch) => ({
  onSearch: (page) => dispatch(fetchRolesPage({ page }))
});
export default connect(mapStateToProps, mapDispatchToProps)(RolesItemAutoComplete);
