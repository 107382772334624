import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Table, TableRow, TableBody, TableHead, Checkbox } from '@mui/material';
import TableCell from './GenericTableCell';

const styles = {
  propertiesTableWrapper: {
    overflowX: 'auto'
  },
  tooltip: {
    maxWidth: '800px'
  },
  propertiesTable: {
    tableLayout: 'auto'
  },
  textTableCell: {
    whiteSpace: 'normal',
    wordWrap: 'break-word'
  },
  checkboxTableCell: {
    width: '80px'
  }
};

const PropertiesTableRenderer = ({
  cellContentComponent,
  headers = [],
  propertiesPaths = [],
  keyPath = '',
  rows = [],
  selectedIndex,
  selectedIndexes,
  onSelectionChanged,
  firstStrong
}) => (
  <div style={styles.propertiesTableWrapper}>
    <Table sx={styles.propertiesTable} size='small'>
      <TableHead>
        <TableRow>
          {onSelectionChanged ? (
            <TableCell sx={styles.checkboxTableCell} padding='checkbox'>
              {selectedIndexes && (
                <Checkbox
                  checked={selectedIndexes?.size === rows.length}
                  onClick={() => {
                    if (selectedIndexes?.size >= rows.length) {
                      onSelectionChanged(new Set(), null, null);
                    } else {
                      const newSelectedIndexes = new Set([...Array(rows.length).keys()]);
                      onSelectionChanged(newSelectedIndexes, null, null);
                    }
                  }}
                />
              )}
            </TableCell>
          ) : null}
          {headers.map((h) => (
            <TableCell>{h}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow
            key={_.get(row, keyPath)}
            hover={true}
            selected={(selectedIndexes && selectedIndexes.has(index)) || index === selectedIndex}
            onClick={() => {
              if (!onSelectionChanged) return;
              if (selectedIndexes) {
                let newSelectedIndexes = new Set(selectedIndexes);
                if (newSelectedIndexes.has(index)) {
                  newSelectedIndexes.delete(index);
                } else {
                  newSelectedIndexes.add(index);
                }
                onSelectionChanged(newSelectedIndexes, row, index);
              } else {
                onSelectionChanged(index === selectedIndex ? null : index, row, index);
              }
            }}>
            {onSelectionChanged ? (
              <TableCell padding='checkbox'>
                <Checkbox
                  checked={
                    (selectedIndexes && selectedIndexes.has(index)) || index === selectedIndex
                  }
                />
              </TableCell>
            ) : null}
            {propertiesPaths.map((propertyPath, propertyPathIndex) => (
              <TableCell key={Math.random()}>
                {React.createElement(cellContentComponent, {
                  property: _.get(row, propertyPath),
                  propertyPath,
                  propertyPathIndex,
                  row
                })}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

PropertiesTableRenderer.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  propertiesPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default PropertiesTableRenderer;
