import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import SystemItemAutoComplete from '../../../../systems/commons/SystemItemAutoComplete';
import _ from 'lodash';
import AccountTeamSelector from 'pages/settings/profile/utils/AccountTeamSelector';
import { ACCESS_LEVELS } from 'components/permissions/AuthorizeTeam';
import { useTranslation } from 'react-i18next';

function QualitySuiteModal({
  open,
  qualitySuite = {},
  onClose,
  onSubmit,
  fullScreen = false,
  defaultPublished = true
}) {
  const [qualitySuitePatch, setQualitySuitePatch] = useState({});
  const mergedQualitySuiteData = {
    published: defaultPublished,
    ...qualitySuite,
    ...qualitySuitePatch
  };
  const { t } = useTranslation();
  return (
    <Dialog fullWidth fullScreen={fullScreen} open={open}>
      <DialogTitle>Test Suite</DialogTitle>
      <DialogContent>
        <h4>Test Suite definition</h4>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter the code'}
          label={'Code'}
          value={mergedQualitySuiteData.code || ''}
          onChange={(event) =>
            setQualitySuitePatch({ ...qualitySuitePatch, code: event.target.value })
          }
        />
        <br></br>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter the name of the test suite'}
          label={'Name'}
          value={mergedQualitySuiteData.name || ''}
          onChange={(event) =>
            setQualitySuitePatch({ ...qualitySuitePatch, name: event.target.value })
          }
        />
        <br></br>
        <TextField
          variant='standard'
          fullWidth
          multiline={true}
          placeholder={'Enter the description of the test suite'}
          label={'Description'}
          value={mergedQualitySuiteData.description || ''}
          onChange={(event) =>
            setQualitySuitePatch({ ...qualitySuitePatch, description: event.target.value })
          }
        />
        <br></br>
        <SystemItemAutoComplete
          key={_.get(mergedQualitySuiteData, 'system.uuid')}
          value={mergedQualitySuiteData.system}
          onChange={(system) => {
            setQualitySuitePatch({ ...qualitySuitePatch, system: system });
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              size='small'
              checked={mergedQualitySuiteData.published}
              onChange={(event, checked) => {
                setQualitySuitePatch({
                  ...qualitySuitePatch,
                  published: checked
                });
              }}
              name='enabled'
              color='primary'
            />
          }
          label='Published'
        />
      </DialogContent>
      <DialogActions>
        <AccountTeamSelector
          accessLevel={ACCESS_LEVELS.WRITE}
          selectedTeamCode={mergedQualitySuiteData.teamCode || ''}
          onChange={(code) => setQualitySuitePatch({ ...qualitySuitePatch, teamCode: code })}
        />
        <div style={{ flexGrow: 1 }} />
        <Button children={t('commons.actions.cancel')} onClick={onClose}></Button>
        <Button
          children={t('commons.actions.submit')}
          disabled={!mergedQualitySuiteData.name || !mergedQualitySuiteData.code}
          onClick={() => onSubmit(mergedQualitySuiteData, qualitySuitePatch)}></Button>
      </DialogActions>
    </Dialog>
  );
}

export default QualitySuiteModal;
