import React from 'react';
const { default: KVDefinitionList } = require("../../../../components/definition-list/KVDefinitionList");

function DictionaryView({dictionary}){
    return(
        <>
            <KVDefinitionList
                header='General'
                item={{
                    Uuid: propValue('uuid', dictionary),
                    Name: propValue('dictionaryName', dictionary),
                    Entity: propValue('entityName', dictionary),
                    Query : propValue('query',dictionary),
                    'Created At': propValue('createdAt', dictionary),
                    'Updated At': propValue('updatedAt', dictionary)
                }}
            />
            <KVDefinitionList
                header='Scopes'
                item={{
                    Scopes: propValue('scopes', dictionary)
                }}
            />
        </>
    )
}

function propValue(keyChain, props) {
    let keys = keyChain.split('.');
    let value = keys.reduce((acc, key) => (acc ? acc[key] : acc), props);
    return value+" " || 'Not defined';
  }


  export default DictionaryView; 