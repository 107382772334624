import React from 'react';

import { Grid, Table, TablePagination, Typography } from '@mui/material';

import RiskDefinitionAddButton from 'pages/quality/assessment/risks/list/RiskDefinitionAddButton';
import RiskDefinitionsTable from 'pages/quality/assessment/risks/list/RiskDefinitionsTable';
import RiskExportButton from './RiskExportButton';

const RiskDefinitionsList = ({ category, riskDefinitions, fetchRiskDefinitionsPage }) => {
  return <>
    <Grid container justifyContent='flex-end'>
      <Grid item>
        <RiskDefinitionAddButton selectedCategory={category} />
      </Grid>
      <Grid item>
        <RiskExportButton />
      </Grid>
    </Grid>
    {riskDefinitions.content.length > 0 ? (
      <div style={{ height: '100%', overflowY: 'auto' }}>
        <Grid container direction='column' style={{ minHeight: '100%' }}>
          <Grid item style={{ flexGrow: 1 }}>
            <Table size='small'>
              <RiskDefinitionsTable content={riskDefinitions.content} />
            </Table>
          </Grid>
          <Grid item>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component='div'
              style={{ overflowX: 'hidden' }}
              count={riskDefinitions.totalElements}
              rowsPerPage={riskDefinitions.size}
              page={riskDefinitions.number}
              onPageChange={(event, page) =>
                fetchRiskDefinitionsPage({
                  page: page,
                  size: riskDefinitions.size,
                  category
                })
              }
              onRowsPerPageChange={(event) =>
                fetchRiskDefinitionsPage({
                  page: riskDefinitions.number,
                  size: event.target.value,
                  category
                })
              }
            />
          </Grid>
        </Grid>
      </div>
    ) : (
      <div style={{ textAlign: 'center', padding: '25px', marginTop: '48px', overflowY: 'auto' }}>
        <Typography variant='body1'>No risks defined in this category</Typography>
      </div>
    )}
  </>;
};

export default RiskDefinitionsList;
