import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';

/**
 * Alerts are urgent interruptions, requiring acknowledgement, that inform the user about a situation.
 */
const AlertDialog = ({ open, onCancel, message, onOk }) => (
  <div>
    <Dialog open={open} onClose={onCancel}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>No</Button>
        <Button variant='contained' color='primary' onClick={onOk}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

export default AlertDialog;
