import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { setHistoryFilters } from '../../../actions/HistoryActions';

import { Grid } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import HistoryExport from '../commons/HistoryExport';
import HistoryFiltersModal from '../commons/HistoryFiltersModal';
import HistoryRecordTable from '../commons/HistoryRecordTable';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

const HistoryListPage = (props) => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    let {
      userId,
      targetIdentifier,
      targetType,
      startDate,
      endDate,
      operation,
      firstRopaUuid,
      secondRopaUuid
    } = queryString.parse(props.location.search);
    props.setHistoryFilters({
      user: userId ? { uuid: userId } : null,
      resource: targetIdentifier ? { resourceIdentifier: targetIdentifier } : null,
      targetType,
      startDate,
      endDate,
      operation,
      firstRopaUuid,
      secondRopaUuid
    });
    setReady(true);
  }, [props]);
  if (!ready) return null;
  return <>
    <Breadcrumbs elements={[{ text: 'History', to: '/history/records' }, { text: 'Records' }]} />
    <Tracking
      info={{
        pageTitle: 'History',
        category: EngagementCategories.SETTINGS,
        action: EngagementActions.LIST
      }}
    />

    <Grid container justifyContent='flex-end'>
      <Grid item>
        <HistoryExport></HistoryExport>
      </Grid>
      <Grid item>
        <HistoryFiltersModal></HistoryFiltersModal>
      </Grid>
    </Grid>

    <HistoryRecordTable />
  </>;
};
const mapDispatchToProps = { setHistoryFilters };

export default connect(null, mapDispatchToProps)(HistoryListPage);
