import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchDataSet } from '../../../../actions/PhysicalEntitiesActions';
import AutoComplete from '../../../../components/autocomplete/AutoComplete';

function DataSetAutoComplete({ fetchDataSet, dataSet, onChange, fullWidth, margin }) {
  const [dataSets, setDatasets] = useState([]);
  useEffect(() => {
    fetchDataSet({ search: '' }).then((response) => response && setDatasets(response.content));
  }, [fetchDataSet, setDatasets]);
  return (
    <AutoComplete
      fullWidth={fullWidth}
      margin={margin}
      label='Data Set'
      value={dataSet}
      dataSource={dataSets}
      onUpdateInput={onChange}
      onNewRequest={onChange}
    />
  );
}

export default connect(null, { fetchDataSet })(DataSetAutoComplete);
