import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function DataCategoryClearanceSelect({ value, onChange, fullWidth = true }) {
  const { t } = useTranslation();
  return (
    <FormControl variant="standard" fullWidth={fullWidth}>
      <InputLabel id='select-clearance-label'>
        {t('data_categories.fields.clearance.label')}
      </InputLabel>
      <Select
        variant="standard"
        labelId='select-clearance-label'
        id='select-clearance'
        value={value || ''}
        onChange={(event) => onChange(event.target.value || null)}>
        <MenuItem value=''>
          <em>Not defined</em>
        </MenuItem>
        <MenuItem value={'COMMON'} children={t('data_categories.fields.clearance.values.COMMON')} />
        <MenuItem
          value={'SENSITIVE'}
          children={t('data_categories.fields.clearance.values.SENSITIVE')}
        />
        <MenuItem
          value={'CLASSIFIED'}
          children={t('data_categories.fields.clearance.values.CLASSIFIED')}
        />
      </Select>
    </FormControl>
  );
}
