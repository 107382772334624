import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { fetchQualityChecksPanelPage } from '../../../../../actions/QualityChecksActions';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { QualityChecksTableView } from '../list/QualityChecksTable';
import Permissions from 'constants/Permissions';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';
import { selectPanel } from 'reducers/quality/qualityChecks';

function QualityPhisicalPanel({
  //a string or array
  physicalEntityUuid,
  // a string or array
  physicalFieldUuid,
  fetchQualityChecksPanelPage,
  currentPage,
  title
}) {
  useEffect(() => {
    if (physicalEntityUuid?.length > 0 || physicalFieldUuid?.length > 0) {
      fetchQualityChecksPanelPage({
        physicalEntityUuid: physicalEntityUuid,
        physicalFieldUuid: physicalFieldUuid,
        page: 0,
        size: 10
      });
    }
  }, [physicalEntityUuid, physicalFieldUuid, fetchQualityChecksPanelPage]);
  return (
    <Card titleTypographyProps={{}}>
      <CardHeader title={title || 'Quality Checks'}></CardHeader>
      <CardContent>
        {currentPage.content.length > 0 ? (
          <QualityChecksTableView
            page={currentPage.number}
            rowsPerPage={currentPage.size}
            currentPage={currentPage}
            fillEmptyRows={false}
            size={'small'}
            onPageSelection={({ page, size }) =>
              fetchQualityChecksPanelPage({
                physicalEntityUuid: physicalEntityUuid,
                physicalFieldUuid: physicalFieldUuid,
                page,
                size
              })
            }></QualityChecksTableView>
        ) : (
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>No checks defined for this resource</Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

function mapStateToProps(state, { physicalEntityUuid, physicalFieldUuid }) {
  return {
    currentPage: selectPanel({ physicalEntityUuid, physicalFieldUuid })(state)
  };
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.QUALITY_VIEWER] }),
  connect(mapStateToProps, { fetchQualityChecksPanelPage })
)(QualityPhisicalPanel);
