import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import React, { useReducer, useState } from 'react';
import _ from 'lodash';
import ResourceTypes from 'constants/ResourceTypes';
import CustomPropertiesFilters from 'components/additional_properties/CustomPropertiesFilters';
import {
  setContractDefinitionFilters,
  clearContractDefinitionFilters
} from 'actions/contracts/ContractsDefinitionsActions';
import { connect } from 'react-redux';
import FilterList from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';
import ContractDefinitionCategoriesAutoComplete from './ContractDefinitionCategoriesAutoComplete';
import { ChipItem, ChipWrapper } from 'components/chiplist/ChipList';
import ProcessingSearchModal from 'pages/processings/utils/ProcessingSearchModal';

function ContractDefinitionFiltersModal({
  filters,
  hasFilters,
  setContractDefinitionFilters,
  clearContractDefinitionFilters
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton
        size='small'
        color={hasFilters ? 'primary' : 'grey'}
        children={<FilterList />}
        onClick={() => setOpen(true)}></IconButton>
      {open && (
        <ContractFiltersDialog
          open={open}
          filters={filters}
          onClear={() => {
            setOpen(false);
            clearContractDefinitionFilters();
          }}
          onSubmit={(filters) => {
            setOpen(false);
            setContractDefinitionFilters({ ...filters, filters });
          }}></ContractFiltersDialog>
      )}
    </>
  );
}

function ContractFiltersDialog({ open, onClear, onSubmit, filters }) {
  const [filtersEditor, setFiltersEditor] = useReducer((s, a) => ({ ...s, ...a }), filters);
  const [addProcessings, setAddProcessings] = useState(false);

  let { t } = useTranslation();

  let {
    category = null,
    processingUuids = [],
    propertiesNames = [],
    propertiesValues = [],
    nullPropertiesNames = []
  } = filtersEditor;

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{'Contracts Filters'}</DialogTitle>
      <DialogContent>
        <h4>Category</h4>
        <ContractDefinitionCategoriesAutoComplete
          fullWidth
          margin='dense'
          category={category || ''}
          onChange={(category) =>
            setFiltersEditor({ ...filtersEditor, category: category })
          }></ContractDefinitionCategoriesAutoComplete>
        <h4>Processings</h4>
        <ChipWrapper onRequestAdd={() => setAddProcessings(true)}>
          {processingUuids.map((proc) => (
            <ChipItem
              key={proc.uuid}
              id={proc.uuid}
              label={proc.name}
              tooltip={proc.description}
              onRequestDelete={() =>
                setFiltersEditor({
                  processingUuids: processingUuids.filter((p) => p.uuid !== proc.uuid)
                })
              }
            />
          ))}
        </ChipWrapper>
        <CustomPropertiesFilters
          resourceType={ResourceTypes.CONTRACT}
          nullPropertiesNames={nullPropertiesNames}
          properties={_.zipWith(propertiesNames, propertiesValues, (name, value) => ({
            name,
            value
          }))}
          onChange={(newProperties) =>
            setFiltersEditor({
              propertiesNames: newProperties.map((p) => p.name),
              propertiesValues: newProperties.map((p) => p.value)
            })
          }
          onChangeNull={(nullNames) =>
            setFiltersEditor({
              nullPropertiesNames: nullNames
            })
          }
        />
        <ProcessingSearchModal
          title={t('tasks.addProcessingTitle')}
          open={addProcessings}
          onCancel={() => setAddProcessings(false)}
          onSubmit={(processing) => {
            setFiltersEditor({
              ...filtersEditor,
              processingUuids: (processingUuids || []).concat([processing])
            });
            setAddProcessings(false);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.clear')} onClick={() => onClear()} />
        <Button
          variant='outlined'
          color='primary'
          children={t('commons.actions.apply')}
          onClick={() => onSubmit(filtersEditor)}
        />
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  filters: state.contracts.definitions.filters.content,
  hasFilters: state.contracts.definitions.filters.hasFilters || false
});

export default connect(mapStateToProps, {
  setContractDefinitionFilters,
  clearContractDefinitionFilters
})(ContractDefinitionFiltersModal);
