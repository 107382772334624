import React from 'react';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import FilterList from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import ClassificationScopesModal from '../../commons/ClassificationScopesModal';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
import { connect } from 'react-redux';
import {
  setScopesFilter,
  clearScopesFilter,
  fetchDictionaryListPage
} from '../../../../actions/DictionariesActions';

function DictionariesFilter({ filter, setScopesFilter, clearScopesFilter, onPageSelection }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [filterSelection, setFilterSelection] = React.useState(false);
  let { scopes } = filter;

  return (
    <>
      <Tooltip title='Filter'>
        <IconButton
          size='small'
          color={'default'}
          onClick={() => setOpen(true)}
          children={<FilterList />}
        />
      </Tooltip>
      <Dialog fullWidth fullScreen={false} open={open}>
        <DialogTitle>Dictionary filter</DialogTitle>
        <DialogContent>
          <Typography variant='h6'>Scopes</Typography>
          <ChipWrapper onRequestAdd={() => setFilterSelection(true)}>
            {(scopes || []).map((p, index) => (
              <ChipItem
                key={index}
                id={index}
                label={p}
                onRequestDelete={() =>
                  setScopesFilter({
                    scopes: filter.scopes.filter((p, idx) => idx !== index)
                  })
                }
              />
            ))}
          </ChipWrapper>
          <ClassificationScopesModal
            title={'Add a scope'}
            open={filterSelection}
            onCancel={() => setFilterSelection(false)}
            onSubmit={(scope) => {
              setScopesFilter({
                scopes: (filter.scopes || []).concat([scope])
              });
              setFilterSelection(false);
            }}
          />
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1 }} />
          <Button
            onClick={() => {
              setOpen(false);
              clearScopesFilter();
              onPageSelection({
                page: 0,
                size: 20
              });
            }}>
            {t('commons.actions.clear')}
          </Button>
          <Button
            variant='outlined'
            color='primary'
            disabled={filter.length === 0}
            onClick={() => {
              setOpen(false);
              onPageSelection({
                page: 0,
                size: 20,
                ...filter
              });
            }}>
            {t('commons.actions.apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function mapStateToProps(state) {
  return {
    filter: state.classification.dictionaries.filter || []
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setScopesFilter: (props) => dispatch(setScopesFilter(props)),
    clearScopesFilter: (props) => dispatch(clearScopesFilter(props)),
    onPageSelection: (props) => dispatch(fetchDictionaryListPage(props))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionariesFilter);
