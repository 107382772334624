import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import DataActorAssignedLogins from '../commons/DataActorAssignedLogins';
import ExpandableCard from '../../../components/expandable-card/ExpandableCard';
import { Typography, Hidden, Button, Grid } from '@mui/material';

import TeamCodeDecorator from '../../settings/teams/common/TeamCodeDecorator';
import ResourceTypes from '../../../constants/ResourceTypes';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';
import AuthorizeTeam from 'components/permissions/AuthorizeTeam';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import TaskAssignments from 'pages/tasks/detail/TaskAssignments';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';

class DataActorsListItem extends Component {
  state = {
    expanded: false,
    editOpen: false
  };

  onExpandChange() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    return (
      <ExpandableCard
        decorator={
          <Fragment>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
              <Hidden mdDown>
                <Grid item>
                  <IssueResourceListItemWidget
                    resourceType={ResourceTypes.DATA_ACTOR}
                    resourceIdentifier={this.props.dataActor.uuid}></IssueResourceListItemWidget>
                </Grid>
                <Grid item>
                  <ChipWrapper>
                    {this.props.dataActor.roles.map((role) => {
                      return <ChipItem key={role} id={role} label={role} />;
                    })}
                  </ChipWrapper>
                </Grid>
                <Grid item>
                  <TeamCodeDecorator teamCode={this.props.dataActor.teamCode} />
                </Grid>
              </Hidden>
            </Grid>
          </Fragment>
        }
        key={this.props.dataActor.uuid}
        onExpandChange={this.onExpandChange.bind(this)}
        title={`${this.props.dataActor.name || ''}`}
        subheader={this.props.dataActor.businessName}
        actions={[
          <AuthorizeTeam hasPermissions={[Permissions.DATA_ACTORS_VIEWER]}>
            <Button
              children='Detail'
              onClick={() => this.props.history.push(`/data_actors/${this.props.dataActor.uuid}`)}
            />
          </AuthorizeTeam>,
          <AuthorizeTeam
            hasPermissions={[Permissions.DATA_ACTORS_EDITOR]}
            teamCode={this.props.dataActor.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE, TeamAccessLevels.PROPOSE]}>
            <Button
              children='Modify'
              onClick={() => this.props.modifyDataActor(this.props.dataActor)}
            />
          </AuthorizeTeam>,
          <AuthorizeTeam
            hasPermissions={[Permissions.DATA_ACTORS_EDITOR]}
            teamCode={this.props.dataActor.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE]}>
            <ConfirmButton
              children='Delete'
              onClick={() => this.props.deleteDataActor(this.props.dataActor)}
            />
          </AuthorizeTeam>
        ]}>
        <Typography paragraph>{this.props.dataActor.notes}</Typography>
        <div className='dls-wrapper'>
          <KVDefinitionList
            header='Description'
            item={{
              Code: this.props.dataActor.identifierCode,
              'First Name': this.props.dataActor.firstName,
              'Last Name': this.props.dataActor.lastName,
              'Business Name': this.props.dataActor.businessName,
              'Subject Type': this.props.dataActor.subjectType,
              'Subject Description': this.props.dataActor.subjectDescription,
              Country: this.props.dataActor.country
            }}
          />

          <KVDefinitionList
            header='Contacts'
            item={{
              Email: this.props.dataActor.email,
              Phone: this.props.dataActor.phone,
              Fax: this.props.dataActor.fax
            }}
          />

          <KVDefinitionList
            header={'Address'}
            item={{
              State: this.props.dataActor.addressState,
              City: this.props.dataActor.addressCity,
              'Street Address': this.props.dataActor.addressStreet,
              'Postal Code': this.props.dataActor.addressPostalCode,
              'Building Number': this.props.dataActor.addressBuildingNumber
            }}
          />
          {this.state.expanded ? (
            <CustomPropertiesView
              additionalProperties={this.props.dataActor.additionalProperties}
              resourceType={ResourceTypes.DATA_ACTOR}
            />
          ) : null}
        </div>
        <Authorize hasPermissions={[Permissions.SYSTEMS_VIEWER]}>
          {this.state.expanded ? (
            <DataActorAssignedLogins dataActor={this.props.dataActor} />
          ) : null}
        </Authorize>
        <Authorize hasPermissions={[Permissions.TASKS_VIEWER]}>
          {this.state.expanded ? <TaskAssignments dataActor={this.props.dataActor} /> : null}
        </Authorize>
      </ExpandableCard>
    );
  }
}

DataActorsListItem.propTypes = {
  dataActor: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    clearance: PropTypes.string
  }),
  assignedTasks: PropTypes.array,
  deleteDataActor: PropTypes.func.isRequired,
  modifyDataActor: PropTypes.func.isRequired
};

export default withRouter(DataActorsListItem);
