import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import { focusNode } from '../../../actions/GraphActions';

function GraphMenuFocusNode({ node, onToggle, focusNode }) {
  let [t] = useTranslation();
  return (
    <MenuItem
      children={t('graph.menu.actions.focus')}
      disabled={node === undefined}
      onClick={() => {
        focusNode(node);
        onToggle(false);
      }}
    />
  );
}

export default connect(() => ({}), { focusNode })(GraphMenuFocusNode);
