import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Button, CardActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import { modifySchedule, deleteSchedule } from 'actions/onagent/AgentScheduleActions';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import { connect } from 'react-redux';
import ProjectScheduleModal from './ProjectScheduleModal';
import JobHistoryTable from '../../commons/JobHistoryTable';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import ProjectScheduleRunButton from './ProjectScheduleRunButton';

function ProjectScheduleDetailCard({
  jobDefinition,
  project,
  agent,
  modifySchedule,
  deleteSchedule
}) {
  const [editModal, setEditModal] = useState(false);
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={'Job Definition'}
        action={
          jobDefinition.scheduled ? (
            <VariationTypeDecorator>{'SCHEDULED'}</VariationTypeDecorator>
          ) : (
            <VariationTypeDecorator>{'NOT SCHEDULED'}</VariationTypeDecorator>
          )
        }></CardHeader>
      <CardContent>
        <KVDefinitionList
          header='General'
          item={{
            Target: jobDefinition.name,
            Type: jobDefinition.type,
            'Cron Expression': jobDefinition.cronExpression,
            Timezone: jobDefinition.timezoneId
          }}></KVDefinitionList>
        <KVDefinitionList
          header='Output'
          item={{
            Connection: jobDefinition.jobDetails.configs.outputOptions?.targetConnectionName
          }}
        />
        <div style={{ marginBlockStart: '1em', marginBlockEnd: '1em', overflow: 'auto' }}>
          <h4>Runs History</h4>
          <JobHistoryTable agent={agent} jobUuid={jobDefinition.uuid} />
        </div>
      </CardContent>
      {jobDefinition && agent && (
        <CardActions>
          <Authorize hasPermissions={[Permissions.PROBES_EDITOR, Permissions.AGENTS_EDITOR]}>
            <Button onClick={() => setEditModal(true)}>{t('commons.actions.modify')}</Button>
          </Authorize>
          <ProjectScheduleRunButton agent={agent} jobDefinition={jobDefinition} onlyTest={false} />
          <Authorize hasPermissions={[Permissions.PROBES_EDITOR, Permissions.AGENTS_EDITOR]}>
            <ConfirmButton onClick={() => deleteSchedule(agent, jobDefinition)}>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </Authorize>
        </CardActions>
      )}
      {editModal && (
        <ProjectScheduleModal
          open={editModal}
          agent={agent}
          schedule={jobDefinition}
          project={project}
          title={`Modify ${jobDefinition.name}`}
          onClose={() => setEditModal(false)}
          onSubmit={(agent, schedule) => {
            modifySchedule(agent, schedule);
            setEditModal(false);
          }}></ProjectScheduleModal>
      )}
    </Card>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.AGENTS_VIEWER] }),
  connect(null, {
    modifySchedule,
    deleteSchedule
  })
)(ProjectScheduleDetailCard);
