import { buildApiCallAction } from './ActionUtils';
import StewardshipApi from '../api/StewardshipApi';
import {
  CREATE_STEWARDSHIP_ROLE,
  FETCH_STEWARDSHIP_ROLES_PAGE,
  DELETE_STEWARDSHIP_ROLE,
  FETCH_STEWARDSHIP_ROLE,
  MODIFY_STEWARDSHIP_ROLE,
  CREATE_STEWARDSHIP_RESPONSIBILITY,
  FETCH_STEWARDSHIP_RESPONSIBILITYS_PAGE,
  DELETE_STEWARDSHIP_RESPONSIBILITY,
  FETCH_STEWARDSHIP_RESPONSIBILITY,
  MODIFY_STEWARDSHIP_RESPONSIBILITY,
  FETCH_ACL_STEWARDSHIP_RESPONSIBILITIES,
  FETCH_STEWARDSHIP_ASSIGNMENTS_PAGE,
  FETCH_STEWARDSHIP_ASSIGNMENT,
  CREATE_STEWARDSHIP_ASSIGNMENT,
  MODIFY_STEWARDSHIP_ASSIGNMENT,
  DELETE_STEWARDSHIP_ASSIGNMENT,
  FETCH_STEWARDSHIP_DASHBOARD_TABLE,
  FETCH_STEWARDSHIP_DASHBOARD_USERS_TABLE,
  FETCH_STEWARDSHIP_DASHBOARD_OVERALL,
  FETCH_STEWARDSHIP_RESPONSIBILITYS_PAGE_BY_RESOURCE_TYPE
} from '../constants/ActionTypes';
import { extractUserUuid } from '../reducers/settings/profile';

const api = new StewardshipApi();

export const searchRoles = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getRoles(params),
    actionType: FETCH_STEWARDSHIP_ROLES_PAGE
  });
export const fetchRolesPage = ({ page = 0, size = 100 } = {}) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getRoles({ page, size }),
    actionType: FETCH_STEWARDSHIP_ROLES_PAGE
  });

export const fetchRole = (uuid) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getRole(uuid),
    actionType: FETCH_STEWARDSHIP_ROLE
  });

export const createRole = (role) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.createRole(role),
    actionType: CREATE_STEWARDSHIP_ROLE
  });

export const modifyRole = (role) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.putRole(role),
    actionType: MODIFY_STEWARDSHIP_ROLE
  });

export const deleteRole = (role) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.deleteRole(role.uuid),
    actionType: DELETE_STEWARDSHIP_ROLE,
    payload: role
  });

export const fetchResponsibilitiesPage = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getResponsibilities(cleanParams(params)),
    actionType: FETCH_STEWARDSHIP_RESPONSIBILITYS_PAGE
  });

export const fetchResponsibilitiesPageByResourceType = ({ resourceType, ...params }) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) =>
      api.getResponsibilities(cleanParams({ ...params, resourceType: resourceType })),
    actionType: FETCH_STEWARDSHIP_RESPONSIBILITYS_PAGE_BY_RESOURCE_TYPE,
    payload: (response) => ({
      ...response,
      resourceType: resourceType
    })
  });

function cleanParams({ user, role, team, ...params }) {
  if (user?.uuid) {
    params.userUuid = user.uuid;
  }
  if (role?.uuid) {
    params.stewardshipRoleUuid = role.uuid;
  }
  if (team?.code) {
    params.teamCode = team.code;
  }
  return params;
}

export const fetchStewardshipAcl =
  ({ resourceType, resourceIdentifier }) =>
  (dispatch, getState) => {
    let userUuid = extractUserUuid(getState());
    return dispatch(
      buildApiCallAction({
        api: api,
        apiFunction: (api) =>
          api.getResponsibilities({
            resourceType,
            resourceIdentifier,
            userUuid,
            includeUserTeams: true,
            roleCanWrite: true,
            endDateIsNull: true
          }),
        actionType: FETCH_ACL_STEWARDSHIP_RESPONSIBILITIES,
        payload: (response) => ({ ...response, resourceIdentifier, resourceType, userUuid })
      })
    );
  };
export const fetchResponsibility = (uuid) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getResponsibility(uuid),
    actionType: FETCH_STEWARDSHIP_RESPONSIBILITY
  });

export const createResponsibility = (responsibility) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.createResponsibility(responsibility),
    actionType: CREATE_STEWARDSHIP_RESPONSIBILITY
  });

export const modifyResponsibility = (responsibility) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.putResponsibility(responsibility),
    actionType: MODIFY_STEWARDSHIP_RESPONSIBILITY
  });

export const deleteResponsibility = (sequenceId) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.deleteResponsibility(sequenceId),
    actionType: DELETE_STEWARDSHIP_RESPONSIBILITY,
    payload: { sequenceId }
  });
export const fetchAssignmentsPage = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getAssignments(params),
    actionType: FETCH_STEWARDSHIP_ASSIGNMENTS_PAGE
  });

export const fetchAssignment = (sequenceId) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getAssignment(sequenceId),
    actionType: FETCH_STEWARDSHIP_ASSIGNMENT
  });

export const createAssignment = (assignment) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.createAssignment(assignment),
    actionType: CREATE_STEWARDSHIP_ASSIGNMENT
  });

export const modifyAssignment = (assignment) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.putAssignment(assignment),
    actionType: MODIFY_STEWARDSHIP_ASSIGNMENT
  });

export const deleteAssignment = (sequenceId) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.deleteAssignment(sequenceId),
    actionType: DELETE_STEWARDSHIP_ASSIGNMENT,
    payload: { sequenceId }
  });

export const fetchDashboardsTable = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getTable(params),
    actionType: FETCH_STEWARDSHIP_DASHBOARD_TABLE
  });

export const fetchDashboardsUsersTable = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getTableUsers(params),
    actionType: FETCH_STEWARDSHIP_DASHBOARD_USERS_TABLE
  });

export const fetchOverallDashboard = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getOverallDashboard(params),
    actionType: FETCH_STEWARDSHIP_DASHBOARD_OVERALL,
    payload: (response) => ({ ...params, ...response })
  });
