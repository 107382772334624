import React from 'react';
import sql from 'react-syntax-highlighter/dist/cjs/languages/hljs/sql';
import hljsStyle from 'react-syntax-highlighter/dist/cjs/styles/hljs/idea';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';

SyntaxHighlighter.registerLanguage('sql', sql);

function SqlVisualizer({ text, style, rowNumber = true }) {
  return (
    <SyntaxHighlighter
      language='sql'
      style={hljsStyle}
      showLineNumbers={rowNumber}
      lineNumberStyle={{
        color: 'grey',
        marginRight: 16,
        borderRight: '2px solid #00aeca'
      }}
      customStyle={{ whiteSpace: 'pre-wrap', ...style }}>
      {text}
    </SyntaxHighlighter>
  );
}

export default SqlVisualizer;
