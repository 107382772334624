import React, { useState } from 'react';
import { selectTenant } from 'actions/LoginActions';
import {
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  List,
  TextField,
  InputAdornment
} from '@mui/material';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useHistory, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';

function TenantSwitcher({ associatedTenants, selectTenant }) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(null);
  const [editor, setEditor] = useState(associatedTenants || null);
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <ListItem button onClick={() => setOpen(true)}>
        <ListItemText inset primary='Tenants' />
      </ListItem>
      {open && (
        <Dialog
          onClose={() => {
            setEditor(associatedTenants);
            setSearch(null);
            setOpen(false);
          }}
          fullWidth
          open={open}>
          <DialogTitle children={'Search Tenant'} />
          <DialogContent sx={{ overflow: 'hidden', height: '280px' }}>
            <TextField
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon color='disabled' />
                  </InputAdornment>
                )
              }}
              sx={{ marginTop: '2%' }}
              variant='outlined'
              fullWidth
              placeholder={'Search Tenant'}
              label={'Tenant'}
              value={search || ''}
              onChange={(event) => {
                setSearch(event.target.value);
                setEditor(
                  associatedTenants.filter((ten) =>
                    ten.name.toUpperCase().match(event.target.value.toUpperCase())
                  )
                );
              }}></TextField>
            <List sx={{ overflow: 'auto', maxHeight: '200px', marginTop: '2%' }}>
              {_.sortBy(editor, 'name').map((tenant) => (
                <ListItem
                  key={tenant.uuid}
                  button
                  onClick={() => {
                    setOpen(false);
                    selectTenant(tenant);
                    setSearch(null);
                    setEditor(associatedTenants);
                    history.push('/');
                  }}>
                  <ListItemText primary={tenant.name} />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              children={t('commons.actions.cancel')}
              onClick={() => {
                setEditor(associatedTenants);
                setSearch(null);
                setOpen(false);
              }}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  associatedTenants: _.get(state, 'settings.profile.associatedTenants'),
  selectedTenant: state.settings.tenants.selectedTenant
});

export default connect(mapStateToProps, { selectTenant })(withRouter(TenantSwitcher));
