import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import StewardshipAuthorize from 'components/permissions/StewardshipAuthorize';

import ResourceTypes from 'constants/ResourceTypes';
import Permissions from 'constants/Permissions';

const ShowDetailsAction = ({ handleShowDetails }) => {
  const { t } = useTranslation();

  return (
    <MenuItem onClick={(event) => handleShowDetails(event)}>{t('commons.actions.detail')}</MenuItem>
  );
};

const EditAction = ({ field, handleEdit }) => {
  const { t } = useTranslation();

  return (
    <>
      <StewardshipAuthorize
        resourceType={ResourceTypes.PHYSICAL_ENTITY}
        resourceIdentifier={field.physicalEntity?.uuid}
        hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
        <MenuItem onClick={(event) => handleEdit(event)}>{t('commons.actions.modify')}</MenuItem>
      </StewardshipAuthorize>
    </>
  );
};

const DeleteAction = ({ field, handleDelete }) => {
  const { t } = useTranslation();

  return (
    <StewardshipAuthorize
      resourceType={ResourceTypes.PHYSICAL_ENTITY}
      resourceIdentifier={field.physicalEntity?.uuid}
      hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
      <MenuItem onClick={(event) => handleDelete(event)}>{t('commons.actions.delete')}</MenuItem>
    </StewardshipAuthorize>
  );
};

const TableActions = ({ field, onShowDetails, onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleShowDetails = (event) => {
    setAnchorEl(null);
    onShowDetails();
    event.stopPropagation();
  };
  const handleEdit = (event) => {
    setAnchorEl(null);
    onEdit();
    event.stopPropagation();
  };
  const handleDelete = (event) => {
    setAnchorEl(null);
    onDelete();
    event.stopPropagation();
  };

  return (
    <>
      <IconButton
        size='small'
        aria-haspopup='true'
        aria-controls='pe-physical-entities-table-actions'
        onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='pe-physical-entities-table-actions'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <ShowDetailsAction handleShowDetails={handleShowDetails} />
        <EditAction field={field} handleEdit={handleEdit} />
        <DeleteAction field={field} handleDelete={handleDelete} />
      </Menu>
    </>
  );
};

export default TableActions;
