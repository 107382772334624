import React, { useEffect } from 'react';
import moment from 'moment';
import { fetchJobHistoryPage } from 'actions/onagent/AgentJobhistoryActions';
import { useHistory } from 'react-router-dom';

import {
  TableHead,
  TableBody,
  TableRow,
  Table,
  TableContainer,
  Typography,
  TablePagination
} from '@mui/material';

import TableCell from 'components/tables/GenericTableCell';
import { connect } from 'react-redux';
function JobHistoryTable({ fetchJobHistoryPage, jobUuid, agent, currentPage }) {
  useEffect(() => {
    fetchJobHistoryPage(agent, { jobDefinitionUuid: jobUuid });
  }, [fetchJobHistoryPage, agent, jobUuid]);

  const history = useHistory();

  return (
    <div>
      {currentPage?.content?.length > 0 ? (
        <div>
          <TableContainer>
            <Table size='small' style={{ overflowX: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Started At</TableCell>
                  <TableCell hiddenSm>Name</TableCell>
                  <TableCell hiddenMd>User </TableCell>
                  <TableCell hiddenMd>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPage?.content.map((run) => (
                  <TableRow
                    key={run.sequenceId}
                    hover={true}
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      history.push(`/settings/agents/${agent.uuid}/runs-history/${run.sequenceId}`)
                    }>
                    <TableCell>
                      {run.startedAt && moment(run.startedAt).format('DD MMMM YYYY, HH:mm:ss')}
                    </TableCell>
                    <TableCell hiddenSm>{run.name}</TableCell>
                    <TableCell hiddenMd>{run.userUuid} </TableCell>
                    <TableCell hiddenMd>{run.runStatus}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={currentPage.totalElements}
            rowsPerPage={currentPage.size}
            page={currentPage.number}
            onPageChange={(event, page) =>
              fetchJobHistoryPage(agent, {
                page: page,
                size: currentPage.size,
                jobDefinitionUuid: jobUuid
              })
            }
            onRowsPerPageChange={(event) =>
              fetchJobHistoryPage(agent, {
                page: currentPage.number,
                size: event.target.value,
                jobDefinitionUuid: jobUuid
              })
            }
          />
        </div>
      ) : (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='body1'>No history available</Typography>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state, { jobUuid }) {
  return {
    currentPage: jobUuid
      ? state.settings.agents.jobhistory.pageByJobDefinitionUuid[jobUuid]
      : state.settings.agents.jobhistory.currentPage
  };
}

export default connect(mapStateToProps, { fetchJobHistoryPage })(JobHistoryTable);
