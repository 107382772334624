import crudReducers from 'reducers/utils';
import filtersReducer from 'reducers/utils/filters';
import { combineReducers } from 'redux';

const filtersDefault = {
  search: '',
  category: null,
  processingUuids: [],
  propertiesValues: [],
  propertiesNames: [],
  nullPropertiesNames: []
};

export default combineReducers({
  ...crudReducers({ resource: 'CONTRACT_DEFINITION', keyProperty: 'uuid' }),
  filters: filtersReducer({ resource: 'CONTRACT_DEFINITION', filtersDefault: filtersDefault })
});
