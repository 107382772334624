import React, { useState } from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Typography, CardContent, CardActions, Card, Button, Grid } from '@mui/material';
import { withLoader } from '../../../../components/loader/Loader';
import { connect } from 'react-redux';
import { fetchUser, patchUser } from '../../../../actions/UsersActions';
import KVDefinitionList from '../../../../components/definition-list/KVDefinitionList';
import Authorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';
import UserDefaultTenantCard from './UserDefaultTenantCard';
import UserAssociatedTenantsCard from './UserAssociatedTenantsCard';
import HistoryButtonLink from '../../../history/commons/HistoryButtonLink';
import UserTenantPermissionsCard from './UserTenantPermissionsCard';
import UserEditorModal from '../commons/UserEditorModal';
import { Link } from 'react-router-dom';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
const UserDetailPage = ({ t, user, patchUser }) => {
  let [modifyOpen, setModifyOpen] = useState(false);
  if (!user) return null;
  return (
    <React.Fragment>
      <Breadcrumbs
        elements={[
          { text: 'Settings', to: '/settings' },
          { text: 'Users', to: '/settings/users' },
          { text: user.displayName }
        ]}
      />
      <Tracking
        info={{
          pageTitle: `User Detail`,
          category: EngagementCategories.SETTINGS,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>User: {user.username}</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`USER:${user.username}_${user.uuid}`}
            pageTitle={`User: ${user.username}`}
          />
        </Grid>
      </Grid>

      <Card>
        <CardContent>
          <Typography variant='h5'>Info</Typography>
          <KVDefinitionList
            item={{
              Uuid: user.uuid,
              Username: user.username,
              Email: user.email,
              'Full Name': user.displayName,
              Role: user.applicationScope,
              Active: user.active.toString(),
              'Last Login': user.lastLogin
                ? new Date(user.lastLogin).toLocaleString()
                : t('commons.placeholders.undefined')
            }}
          />
        </CardContent>
        <CardActions>
          <Authorize key='modify' hasPermissions={[Permissions.USERS_EDITOR]}>
            <Button children={t('commons.actions.modify')} onClick={() => setModifyOpen(true)} />
          </Authorize>
          <Button
            key='team-buttom'
            component={Link}
            to={`/settings/users/${user.uuid}/teams-settings`}>
            Teams
          </Button>
          <Authorize key='history' hasPermissions={[Permissions.USERS_ADMIN]}>
            <HistoryButtonLink userId={user.uuid} />
          </Authorize>
        </CardActions>
      </Card>

      <UserEditorModal
        open={modifyOpen}
        handleCancel={() => setModifyOpen(false)}
        handleSubmit={(user) => {
          setModifyOpen(false);
          patchUser(user);
        }}
        user={user}
        title={t('commons.actions.modify')}
        isPatch={true}
      />
      <UserDefaultTenantCard userUuid={user.uuid} />
      <UserAssociatedTenantsCard userUuid={user.uuid} />
      <UserTenantPermissionsCard user={user}></UserTenantPermissionsCard>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  user: state.settings.users.content[props.match.params.userUuid],
  userUuid: props.match.params.userUuid
});

const mapDispatchToProps = {
  fetchUser,
  patchUser
};

export default _.flow([
  withLoader({
    onLoad: ({ fetchUser, userUuid }) => fetchUser(userUuid)
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
])(UserDetailPage);
