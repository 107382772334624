import * as React from "react";
const SvgElasticSearch = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 286"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="M14.344 80.173H203.55c20.851 0 39.47-9.56 51.963-24.487C229.453 21.836 188.553 0 142.53 0 86.178 0 37.476 32.711 14.344 80.173"
      fill="#F0BF1A"
    />
    <path
      d="M187.515 102.444H5.755A142.508 142.508 0 0 0 0 142.53c0 13.924 2.033 27.367 5.755 40.087h181.76c21.825 0 40.087-17.816 40.087-40.087 0-22.27-17.816-40.086-40.087-40.086"
      fill="#07A5DE"
    />
    <path
      d="M256 228.755c-12.414-14.591-30.883-23.868-51.56-23.868H14.346C37.477 252.35 86.179 285.06 142.53 285.06c46.306 0 87.436-22.097 113.47-56.305"
      fill="#3EBEB0"
    />
    <path
      d="M5.755 102.444a142.472 142.472 0 0 0 0 80.173h118.959c3.118-12.026 4.899-25.388 4.899-40.087 0-14.698-1.781-28.06-4.9-40.086H5.757Z"
      fill="#231F20"
    />
    <path
      d="M70.82 19.153c-24.053 14.253-44.096 35.632-56.567 61.02h105.116c-10.69-24.497-27.615-44.985-48.55-61.02"
      fill="#D7A229"
    />
    <path
      d="M75.274 268.135c20.488-16.48 36.968-38.305 47.213-63.248H14.253c13.362 26.725 34.742 48.995 61.021 63.248"
      fill="#019B8F"
    />
  </svg>
);
export default SvgElasticSearch;
