import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAvailableWebActions } from './useAvailableWebActions';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AvailableWebActionsModal } from './AvailableWebActionsModal';
import WebActionExecutionModal from './WebActionExecutionModal';
import { connect } from 'react-redux';

const WebActionsButton = ({ resource, sx, page, currentUser }) => {
  const [availableWebActionsModalOpen, setAvailableWebActionsModalOpen] = useState(false);
  const [webActionExecutionModalOpen, setWebActionExecutionModalOpen] = useState(false);
  const [currentWebAction, setCurrentWebAction] = useState();
  const payload = { resource, user: currentUser };

  const availableWebActions = useAvailableWebActions({ resource, page });
  if (availableWebActions && availableWebActions.length > 0) {
    return (
      <>
        <IconButton sx={sx} onClick={() => setAvailableWebActionsModalOpen(true)}>
          <MoreVertIcon />
        </IconButton>
        {availableWebActionsModalOpen && (
          <AvailableWebActionsModal
            open={availableWebActionsModalOpen}
            setOpen={setAvailableWebActionsModalOpen}
            availableWebActions={availableWebActions}
            setWebAction={setCurrentWebAction}
            setWebActionExecutionOpen={setWebActionExecutionModalOpen}
          />
        )}
        {webActionExecutionModalOpen && (
          <WebActionExecutionModal
            open={webActionExecutionModalOpen}
            webAction={currentWebAction}
            onResultVisualized={() => {
              setCurrentWebAction();
              setWebActionExecutionModalOpen(false);
              setAvailableWebActionsModalOpen(false);
            }}
            onCancel={() => {
              setCurrentWebAction();
              setWebActionExecutionModalOpen(false);
            }}
            payload={payload}
            page={page}
          />
        )}
      </>
    );
  } else {
    return null;
  }
};

WebActionsButton.propTypes = {
  resource: PropTypes.object,
  page: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => ({
  currentUser: {
    uuid: state.settings.profile.uuid,
    username: state.settings.profile.username,
    displayName: state.settings.profile.displayName
  }
});

export default connect(mapStateToProps, null)(WebActionsButton);
