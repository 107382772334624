import { Button } from '@mui/material';
import CardDescription from 'components/carddescription/CardDescription';
import ExpandableCard from 'components/expandable-card/ExpandableCard';
import { t } from 'i18next';
import React from 'react';
import { useHistory } from 'react-router';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';
import ResourceTypes from 'constants/ResourceTypes';

export const DataProductListItem = ({ dataProduct }) => {
  const history = useHistory();
  return (
    <ExpandableCard
      decorator={
        <IssueResourceListItemWidget
          resourceType={ResourceTypes.DATA_PRODUCT}
          resourceIdentifier={dataProduct.uuid}></IssueResourceListItemWidget>
      }
      title={dataProduct.displayName}
      subheader={`${dataProduct.domain}`}
      actions={[
        <Button onClick={() => history.push(`/dataproducts/detail/${dataProduct.uuid}`)}>
          {t('commons.actions.detail')}
        </Button>
      ]}>
      <CardDescription>{dataProduct.description}</CardDescription>
    </ExpandableCard>
  );
};
