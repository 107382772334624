import React from 'react';
import PropTypes from 'prop-types';
import DataCategoryItemAutoComplete from './DataCategoryItemAutoComplete';
import ItemSelectField from '../../../components/itemselector/ItemSelectField';
import { connect } from 'react-redux';
import { requestLogicalFields } from '../../../actions/LogicalFieldsActions';
import { fetchDataCategory } from '../../../actions/DataCategoriesActions';

class LogicalFieldItemSelector extends React.Component {
  state = {
    dataCategory: null
  };

  constructor(props) {
    super(props);
    this.state = {
      dataCategory: props.value && props.value.dataCategory
    };
  }

  componentDidMount() {
    if (this.state.dataCategory && this.state.dataCategory.uuid) {
      this.props.fetchDataCategory(this.state.dataCategory.uuid);
      this.props.requestLogicalFields(this.state.dataCategory);
    }
  }

  handleChangeDataCategory(dataCategory) {
    this.props.onChange(null);
    this.setState({ dataCategory });
    if (dataCategory) {
      this.props.requestLogicalFields(dataCategory);
    }
  }

  handleChangeLogicalField(logicalField) {
    this.props.onChange(logicalField);
  }

  render() {
    let { logicalFields } = this.props;
    return (
      <React.Fragment>
        <DataCategoryItemAutoComplete
          fullWidth
          margin='dense'
          value={this.state.dataCategory}
          onChange={this.handleChangeDataCategory.bind(this)}
        />
        <ItemSelectField
          fullWidth
          margin='dense'
          itemName='LogicalField'
          keyProperty='uuid'
          nameProperty='name'
          value={this.props.value}
          values={(this.state.dataCategory && logicalFields[this.state.dataCategory.uuid]) || []}
          onChange={this.handleChangeLogicalField.bind(this)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  logicalFields: state.logicalfields.contentByDataCategory
});

LogicalFieldItemSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

export default connect(mapStateToProps, { requestLogicalFields, fetchDataCategory })(
  LogicalFieldItemSelector
);
