import * as React from "react";
const SvgSap = (props) => (
  <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 455 255" {...props}>
    <linearGradient
      id="Sap_svg__a"
      x1={45.483}
      x2={45.483}
      y2={45.001}
      gradientTransform="scale(5)"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#00AEEF" offset={0} />
      <stop stopColor="#0097DC" offset={0.212} />
      <stop stopColor="#007CC5" offset={0.519} />
      <stop stopColor="#006CB8" offset={0.792} />
      <stop stopColor="#0066B3" offset={1} />
    </linearGradient>
    <path
      d="M0 225h229.87L454.83 0H0v225"
      clipRule="evenodd"
      fill="url(#Sap_svg__a)"
      fillRule="evenodd"
    />
    <path
      d="M269.92 45H225l.15 105.65-39.115-105.69H147.25l-33.39 88.265c-3.55-22.46-26.77-30.21-45.04-36.015-12.065-3.875-24.87-9.575-24.74-15.875.1-5.17 6.85-9.965 20.265-9.25 9 .485 16.95 1.21 32.765 8.85l15.55-27.1c-14.42-7.34-34.36-11.975-50.71-11.99h-.1c-19.065 0-34.94 6.175-44.78 16.35-6.86 7.1-10.56 16.13-10.71 26.115-.25 13.74 4.785 23.48 15.365 31.265 8.94 6.55 20.375 10.8 30.45 13.92 12.425 3.85 22.575 7.2 22.45 14.33-.1 2.6-1.08 5.03-2.95 6.99-3.1 3.2-7.85 4.4-14.425 4.53-12.685.27-22.085-1.725-37.065-10.58L6.35 172.215c14.965 8.51 30.85 12.78 48.9 12.78l4.06-.03c15.71-.285 28.46-4.05 38.59-12.2.58-.465 1.1-.935 1.64-1.41l-1.7 8.76 37.9-.12 6.8-17.41c7.15 2.44 15.28 3.79 23.91 3.79 8.41 0 16.32-1.28 23.335-3.58l4.74 17.2 68 .065.165-39.69h14.47c34.975 0 55.65-17.8 55.65-47.65-.011-33.245-20.11-47.725-62.89-47.725zm-103.47 90.31c-5.225 0-10.125-.91-14.34-2.51l14.18-44.775h.275l13.95 44.9c-4.2 1.5-8.985 2.385-14.07 2.385zm106.1-25.725h-9.87V73.5h9.875c13.15 0 23.65 4.38 23.65 17.81-.011 13.9-10.5 18.275-23.65 18.275"
      clipRule="evenodd"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSap;
