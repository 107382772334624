import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { connect } from 'react-redux';
import { exportCampaigns } from 'actions/CampaignActions';

function CampaignExportButton({ fitlers, exportCampaigns }) {
  return (
    <Tooltip title='Export CSV'>
      <IconButton
        color='default'
        aria-label='Download'
        onClick={() => exportCampaigns()}
        size='small'>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}

export default connect(null, { exportCampaigns })(CampaignExportButton);
