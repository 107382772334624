import React from 'react';
import { Card, CardHeader, CardContent, CardActions, Typography, Grid } from '@mui/material';
import CardDescription from 'components/carddescription/CardDescription';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import JsonVisualizer from 'components/syntaxhighlithter/JsonVisualizer';
import { ChipItem } from 'components/chiplist/ChipList';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function WebActionDetailCardView({ webAction, actions }) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={webAction?.name}
        action={
          webAction?.requiredTeamCode && (
            <VariationTypeDecorator>{webAction?.requiredTeamCode}</VariationTypeDecorator>
          )
        }
        subheader={
          <Grid container direction='column' alignItems='flex-start'>
            <Grid item>
              <Typography
                variant='caption'
                children={`Created on ${moment(webAction.createdAt).format(
                  'DD MMMM YYYY, HH:mm:ss'
                )} by ${webAction.createdByUserDisplayName} `}
              />
            </Grid>
            {webAction?.updatedByUserDisplayName && (
              <Grid item>
                <Typography
                  variant='caption'
                  children={`Updated on ${moment(webAction.updatedAt).format(
                    'DD MMMM YYYY, HH:mm:ss'
                  )} by ${webAction.updatedByUserDisplayName} `}
                />
              </Grid>
            )}
          </Grid>
        }
      />
      <CardContent>
        <CardDescription>{webAction?.description}</CardDescription>
        <h4>{t('webActions.detail.agent')}</h4>
        <ChipItem
          label={webAction?.agent?.name}
          to={`/settings/agents/${webAction?.agent?.uuid}`}
        />
        <h4>{t('webActions.detail.pages')}</h4>
        {webAction?.targetPages?.length > 0 ? (
          <>
            {webAction?.targetPages?.map((page) => (
              <ChipItem label={t(`webActions.targetPages.${page}`)}></ChipItem>
            ))}
          </>
        ) : (
          <Typography variant='outlined'>No pages defined for this web action</Typography>
        )}

        <h4>{t('webActions.detail.permissions')}</h4>
        {webAction?.permissions?.length > 0 ? (
          <>
            {webAction?.permissions?.map((perm) => (
              <ChipItem label={perm}></ChipItem>
            ))}
          </>
        ) : (
          <Typography variant='outlined'>No permissions defined for this web action</Typography>
        )}
        <h4>{t('webActions.detail.rule')}</h4>
        {webAction?.renderingRule ? (
          <JsonVisualizer object={webAction?.renderingRule}></JsonVisualizer>
        ) : (
          <Typography variant='outlined'>No rule defined for this web action</Typography>
        )}
      </CardContent>
      <CardActions>{actions}</CardActions>
    </Card>
  );
}

export default WebActionDetailCardView;
