import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Chart } from 'react-google-charts';

import Typography from '@mui/material/Typography';

import QualityChecksTimelineDrilldownModal from 'pages/quality/kqis/quality_checks/commons/QualityChecksTimelineCard/QualityChecksTimelineDrilldownModal';
import {
  greenSemaphoreColor,
  yellowSemaphoreColor,
  redSemaphoreColor
} from 'pages/quality/kqis/quality_checks/commons/QualitySemaphoreColors';

const QualityChecksTimelineChart = ({
  qualitySuiteUuid,
  qualityCheckUuid,
  data,
  precision,
  width,
  height,
  inWatchlist
}) => {
  let { t } = useTranslation();

  const [selection, setSelection] = useState({});
  const [open, setOpen] = useState(false);

  const mappedRows = data?.rows?.map((row) => {
    row[0] = new Date(row[0]);
    return row;
  });

  const handleSectionSelection = (chartWrapper) => {
    const chartSelection = chartWrapper.getChart().getSelection();
    if (chartSelection.length === 1) {
      const [selectedItem] = chartSelection;
      const { row, column } = selectedItem;
      const dataTable = chartWrapper.getDataTable();
      setSelection({
        date: dataTable.getValue(row, 0),
        semaphore: dataTable.getColumnLabel(column),
        value: dataTable.getValue(row, column)
      });
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {data?.rows && data?.rows.length > 0 ? (
        <>
          <Chart
            width={width}
            height={height}
            chartType='ColumnChart'
            loader={<Typography variant='overline'>Loading...</Typography>}
            data={[
              data.columns, // ['Date', 'Green', 'Yellow', 'Red'],
              ...mappedRows
            ]}
            options={{
              chartArea: { width: '80%' },
              isStacked: true,
              colors: [greenSemaphoreColor, yellowSemaphoreColor, redSemaphoreColor],
              hAxis: {
                title: 'Date'
              },
              vAxis: {
                title: 'Count',
                minValue: 0
              },
              legend: { position: 'none' }
            }}
            chartEvents={[
              {
                eventName: 'select',
                callback: ({ chartWrapper }) => handleSectionSelection(chartWrapper)
              }
            ]}
          />
          <QualityChecksTimelineDrilldownModal
            isInWatchlist={inWatchlist}
            qualitySuiteUuid={qualitySuiteUuid}
            qualityCheckUuid={qualityCheckUuid}
            selection={selection}
            precision={precision}
            size={selection.value}
            open={open}
            onClose={handleClose}
          />
        </>
      ) : (
        <Typography variant='overline'>{t('commons.placeholders.nothingToDisplay')}</Typography>
      )}
    </>
  );
};

export default QualityChecksTimelineChart;
