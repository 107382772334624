import { fetchAll, handleErrors, parseJson } from './ApiUtils';

export default class TaskGrantsApi {
  constructor() {
    this.token = '';
  }

  getAllTaskGrants() {
    return fetchAll(page => this.getTaskGrants(page));
  }

  getTaskGrants(page) {
    return fetch(`/api/v1/taskgrants?page=${page}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
        ...(this.selectedTenantUuid
          ? { 'X-BD-Tenant': this.selectedTenantUuid || '' }
          : {})
      }
    })
      .then(handleErrors)
      .then(parseJson);
  }

  postTaskGrant(tasksGrant) {
    return fetch(`/api/v1/taskgrants`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(this.selectedTenantUuid
          ? { 'X-BD-Tenant': this.selectedTenantUuid || '' }
          : {})
      },
      body: JSON.stringify(tasksGrant)
    })
      .then(handleErrors)
      .then(parseJson);
  }

  deleteTaskGrant(tasksGrant) {
    return fetch(`/api/v1/taskgrants/${tasksGrant.uuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid
          ? { 'X-BD-Tenant': this.selectedTenantUuid || '' }
          : {})
      },
      body: JSON.stringify(tasksGrant)
    }).then(handleErrors);
  }
}
