import {
  SHOW_PROGRESS, HIDE_PROGRESS,
  SHOW_MESSAGE, HIDE_MESSAGE,
  SHOW_SPINNER, HIDE_SPINNER
} from '../constants/ActionTypes'

export const hideMessage = () => (dispatch,getState) => {
  return dispatch({
    type: HIDE_MESSAGE
  })
}

export const showMessage = (text) => (dispatch,getState) => {
  return dispatch({
    type: SHOW_MESSAGE,
    payload: {text}
  })
}

export const showProgress = (text) => (dispatch,getState) => {
  return dispatch({
    type: SHOW_PROGRESS,
    payload:{text}
  })
}

export const hideProgress = ()=> (dispatch,getState)=>{
  return dispatch({
    type:HIDE_PROGRESS
  })
}

export const showSpinner = () => (dispatch,getState) => {
  return dispatch({
    type: SHOW_SPINNER,
  })
}

export const hideSpinner = ()=> (dispatch,getState)=>{
  return dispatch({
    type:HIDE_SPINNER
  })
}
