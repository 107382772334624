import { BlindataApi } from '../../ApiUtils';

const WEB_ACTIONS_URL = '/api/v1/settings/web-actions';

export default class WebActionsApi extends BlindataApi {
  getWebActionsPage(params) {
    return this.get(WEB_ACTIONS_URL, params);
  }
  getWebAction(identifier) {
    return this.get(`${WEB_ACTIONS_URL}/${identifier}`);
  }
  postWebAction(webAction) {
    return this.post(WEB_ACTIONS_URL, webAction);
  }
  putWebAction(webAction) {
    return this.put(`${WEB_ACTIONS_URL}/${webAction.uuid}`, webAction);
  }
  deleteWebAction(webAction) {
    return this.delete(`${WEB_ACTIONS_URL}/${webAction.uuid}`);
  }
  exportWebActions(params) {
    return this.download(`${WEB_ACTIONS_URL}/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }
  getAvailableWebActions() {
    return this.get(`${WEB_ACTIONS_URL}/*/available-actions`);
  }
}
