import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardContent,
  Typography,
  Button,
  DialogActions,
  Grid,
  LinearProgress,
  Box
} from '@mui/material';
import { fetchProcessingsPage, modifyProcessing } from '../../../actions/ProcessingsActions';
import { fetchTasksPage, modifyTask } from '../../../actions/TasksActions';

import { connect } from 'react-redux';
import JsonVisualizer from '../../../components/syntaxhighlithter/JsonVisualizer';
import ProcessingView from '../../processings/utils/ProcessingView';
import ExpandableCard from '../../../components/expandable-card/ExpandableCard';
import TaskView from '../../tasks/utils/TaskView';
import { Warning } from '@mui/icons-material';
import PaginatedPanel from 'components/pagination/PaginatedPanel';

const BATCH_PAGE_SIZE = 20;

function TemplatesUpdateDialog({
  type,
  open,
  contentPatch,
  modifyObject,
  fetchContent,
  templateCode,
  onDismiss
}) {
  let [message, setMessage] = React.useState('');
  let [loaded, setLoaded] = React.useState(false);
  let [modifiedObjectsPage, setModifiedObjectsPage] = React.useState([]);
  let [progress, setProgress] = useState({ current: 0, total: 0 });

  const fetchModifiedObjectsPage = useCallback(
    ({ page = 0 }) =>
      fetchContent({ templateCode, page }).then((data) => ({
        ...data,
        content: data.content.map((obj) => ({ ...obj, ...contentPatch }))
      })),
    [templateCode, contentPatch, fetchContent]
  );

  React.useEffect(() => {
    if (Object.keys(contentPatch || {}).length === 0) {
      setMessage('No massive updates to apply');
    }
    if (JSON.stringify(contentPatch || {}).includes('{{')) {
      setMessage('Massive updates involve variables');
    }

    if (!templateCode) return;
    fetchModifiedObjectsPage({ page: 0 })
      .then((data) => setModifiedObjectsPage(data))
      .then((data) => {
        setLoaded(true);
      });
  }, [fetchModifiedObjectsPage, contentPatch, templateCode, setLoaded]);

  async function executeMassiveUpate() {
    if (isInProgress(progress)) {
      return;
    }
    const totalElements = modifiedObjectsPage.totalElements;
    const pageSize = modifiedObjectsPage.size;
    setProgress({ current: 0, total: totalElements });
    const totalPages = Math.floor(totalElements / pageSize) + 1;
    let modifiedPages = [];
    for (const page of Array.from(Array(totalPages).keys())) {
      const newPage = await fetchModifiedObjectsPage({ page });
      modifiedPages.push(newPage);
    }
    for (let modifiedPage of modifiedPages) {
      for (let modifiedObject of modifiedPage.content) {
        await modifyObject(modifiedObject);
        setProgress({ current: progress.current + 1, total: totalElements });
      }
    }
    setProgress({ current: 0, total: 0 });
    onDismiss();
  }

  function renderModifiedObject(modifiedObject) {
    if (type === 'PROCESSING') {
      return (
        <ExpandableCard
          key={modifiedObject.uuid}
          title={modifiedObject.name}
          subheader={modifiedObject.category}>
          <ProcessingView processing={modifiedObject} />
        </ExpandableCard>
      );
    } else if (type === 'TASK') {
      return (
        <ExpandableCard
          key={modifiedObject.uuid}
          title={modifiedObject.name}
          subheader={modifiedObject.category}>
          <TaskView task={modifiedObject} />
        </ExpandableCard>
      );
    }
  }

  return (
    <Dialog fullScreen open={open}>
      <DialogTitle>
        Massive update
        {isInProgress(progress) && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              variant='determinate'
              value={(progress.current / progress.total) * 100}
            />
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <Card>
          <CardContent>
            <Typography variant='h5'>Variations to apply</Typography>
            <JsonVisualizer object={contentPatch} />
          </CardContent>
        </Card>
        <Typography variant='h5'>Content</Typography>

        {loaded && (
          <PaginatedPanel
            currentPage={modifiedObjectsPage}
            onPageSelection={(page) =>
              fetchModifiedObjectsPage({ page }).then(setModifiedObjectsPage)
            }>
            {modifiedObjectsPage.content.map((modifiedObject) =>
              renderModifiedObject(modifiedObject)
            )}
          </PaginatedPanel>
        )}
      </DialogContent>
      <DialogActions>
        {message && (
          <Grid container alignItems='center'>
            <Grid item>
              <Warning color='secondary' />
            </Grid>
            <Grid item>
              <Typography variant='caption'>{message}</Typography>
            </Grid>
          </Grid>
        )}

        <Button disabled={isInProgress(progress)} onClick={onDismiss}>
          Cancel
        </Button>
        <Button disabled={message || isInProgress(progress)} onClick={executeMassiveUpate}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function isInProgress(progress) {
  return progress.total > 0 && progress.current < progress.total;
}

function mapStateToPropsForProcessings(state, props) {
  return {
    type: 'PROCESSING'
  };
}

function mapDisatchToPropsForProcessings(dispatch, props) {
  return {
    modifyObject: (obj) => dispatch(modifyProcessing(obj)),
    fetchContent: ({ templateCode, page = 0, size = BATCH_PAGE_SIZE }) =>
      dispatch(fetchProcessingsPage(page, size, { templateCodes: [templateCode] }))
  };
}

function mapStateToPropsForTasks(state, props) {
  return {
    type: 'TASK'
  };
}

function mapDisatchToPropsForTasks(dispatch, props) {
  return {
    modifyObject: (obj) => dispatch(modifyTask(obj)),
    fetchContent: ({ templateCode, page = 0, size = BATCH_PAGE_SIZE }) =>
      dispatch(fetchTasksPage(page, size, { templateCodes: [templateCode] }))
  };
}

export const TemplatesTasksUpdateDialog = connect(
  mapStateToPropsForTasks,
  mapDisatchToPropsForTasks
)(TemplatesUpdateDialog);

export const TemplatesProcessingsUpdateDialog = connect(
  mapStateToPropsForProcessings,
  mapDisatchToPropsForProcessings
)(TemplatesUpdateDialog);
