import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ClassificationScopesModal({ title, open, onCancel, onSubmit }) {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ minWidth: 350 }}>
        <TextField
          variant='standard'
          fullWidth
          placeholder={'Define a new scope'}
          label={'Scope'}
          value={value || ''}
          onChange={(event) => setValue(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
        <Button disabled={!value} onClick={() => onSubmit(value)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ClassificationScopesModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ClassificationScopesModal;
