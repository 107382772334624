import React from 'react';
import _ from 'lodash';

import { useSelector } from 'react-redux';

export const withPermissionsFilteredProps = (WrappedComponent) => {
  function WithPermissions({
    permissionsFilters,

    ...props
  }) {
    let { permissions } = useSelector(mapStateToProps);

    let filteredProps = _.mapValues(props, (value, key) => {
      let requiredPermissions = permissionsFilters[key];
      if (!requiredPermissions || hasPermissions(requiredPermissions, permissions)) {
        return value;
      } else {
        return null;
      }
    });

    return <WrappedComponent {...filteredProps} />;
  }
  WithPermissions.displayName = `WithPermissions(${getDisplayName(WrappedComponent)})`;
  return WithPermissions;
};
function hasPermissions(requiredPermissions, permissions) {
  return _(requiredPermissions).every((p) => permissions.includes(p));
}
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
const mapStateToProps = (state) => ({
  permissions: state.authentication.permissions
});
