import React, { useCallback, useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import _ from 'lodash';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchBox({ value, onChange }) {
  let [editor, setEditor] = useState(value || '');
  useEffect(() => {
    if (value !== editor) {
      setEditor(value || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, setEditor]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let onSetFilters = useCallback(_.debounce(onChange, 300), [onChange]);
  return (
    <TextField
      value={editor}
      id='input-with-icon-textfield'
      placeholder='Search...'
      variant='outlined'
      size='small'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='disabled' />
          </InputAdornment>
        )
      }}
      onChange={(event) => {
        setEditor(event.target.value);
        onSetFilters({ value: event.target.value || null });
      }}
    />
  );
}
