import React from 'react';
import { useTranslation } from 'react-i18next';

import Permissions from 'constants/Permissions';

import { Button } from '@mui/material';

import Authorize from 'components/permissions/Authorize';
import ExpandableCard from 'components/expandable-card/ExpandableCard';

const QualityProbesConnectionsListItem = ({ connection, handleModify, handleDelete }) => {
  const { t } = useTranslation();

  return (
    <ExpandableCard
      title={connection.name}
      subheader={connection.type}
      actions={
        <>
          <Authorize hasPermissions={[Permissions.PROBES_EDITOR]}>
            <Button onClick={() => handleModify(connection)}>{t('commons.actions.modify')}</Button>
          </Authorize>
          <Authorize hasPermissions={[Permissions.PROBES_EDITOR]}>
            <Button onClick={() => handleDelete(connection)}>{t('commons.actions.delete')}</Button>
          </Authorize>
        </>
      }></ExpandableCard>
  );
};

export default QualityProbesConnectionsListItem;
