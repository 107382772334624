import { combineReducers } from 'redux';
import crudReducers from 'reducers/utils';
import { FETCH_AGENT_JOB_HISTORYS_PAGE } from 'constants/ActionTypes';

function pageByJobDefinitionUuid(state = {}, action) {
  if (action.type === FETCH_AGENT_JOB_HISTORYS_PAGE && action?.payload?.jobDefinitionUuid) {
    return { ...state, [action.payload.jobDefinitionUuid]: action.payload };
  } else {
    return state;
  }
}
export default combineReducers({
  ...crudReducers({ resource: 'AGENT_JOB_HISTORY', keyProperty: 'sequenceId' }),
  pageByJobDefinitionUuid
});
