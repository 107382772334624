import React from 'react';
import PropTypes from 'prop-types';
import ProcessingView from '../../../processings/utils/ProcessingView';
import TaskView from '../../../tasks/utils/TaskView';
import { ProposalResourceTypes, ProposalVariationTypes } from '../commons/ProposalConstants';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { useTranslation } from 'react-i18next';
import TaskAssignmentView from '../../../tasks/utils/TaskAssignmentView';
import VariationTypeDecorator from '../commons/VariationTypeDecorator';
import TeamCodeDecorator from '../../../settings/teams/common/TeamCodeDecorator';
import { Grid, Button } from '@mui/material';
import ProcessingEditorModal from '../../../processings/form/ProcessingEditorModal';
import TaskEditorModal from '../../../tasks/list/TaskEditorModal';
import DataActorView from 'pages/data_actors/commons/DataActorView';

function ProposalItemView({ item, onDelete, onModify }) {
  let { t } = useTranslation();
  let [open, setOpen] = React.useState(false);
  let actions = [];
  if (onDelete) {
    actions.push(
      <Button key='del' onClick={onDelete}>
        {t('commons.actions.delete')}
      </Button>
    );
  }
  if (
    onModify &&
    item.variationType !== ProposalVariationTypes.DELETE &&
    [ProposalResourceTypes.PROCESSING, ProposalResourceTypes.TASK].includes(item.resourceType)
  ) {
    actions.push(
      <Button key='mod' onClick={() => setOpen(true)}>
        {t('commons.actions.modify')}
      </Button>
    );
  }
  return <>
    <ExpandableCard
      title={item.resourceName}
      subheader={item.resourceType}
      actions={actions}
      decorator={
        <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
          <Grid item>
            <VariationTypeDecorator>
              {t(`proposals.variationTypes.${item.variationType}`, item.variationType)}
            </VariationTypeDecorator>
          </Grid>
          <Grid item>
            <TeamCodeDecorator teamCode={item.resourcePayload && item.resourcePayload.teamCode} />
          </Grid>
        </Grid>
      }>
      {renderResourceTypeView(item)}
    </ExpandableCard>
    {renderResourceTypeModal(item, onModify, open, setOpen)}
  </>;
}

function renderResourceTypeView(item) {
  if (!item.resourcePayload) {
    return null;
  }
  switch (item.resourceType) {
    case ProposalResourceTypes.DATA_ACTOR:
      return <DataActorView dataActor={item.resourcePayload}></DataActorView>;
    case ProposalResourceTypes.PROCESSING:
      return <ProcessingView processing={item.resourcePayload}></ProcessingView>;
    case ProposalResourceTypes.TASK:
      return <TaskView task={item.resourcePayload}></TaskView>;
    case ProposalResourceTypes.TASK_ASSIGNMENT:
      return <TaskAssignmentView taskAssignment={item.resourcePayload}></TaskAssignmentView>;
    default:
      return JSON.stringify(item);
  }
}

function renderResourceTypeModal(item, onModify, open, setOpen) {
  if (!item.resourcePayload) {
    return null;
  }
  switch (item.resourceType) {
    case ProposalResourceTypes.PROCESSING:
      return (
        <ProcessingEditorModal
          open={open}
          processing={item.resourcePayload}
          handleSubmit={(processing) => {
            onModify({ ...item, resourceName: processing?.name, resourcePayload: processing });
            setOpen(false);
          }}
          handleCancel={() => setOpen(false)}></ProcessingEditorModal>
      );
    case ProposalResourceTypes.TASK:
      return (
        <TaskEditorModal
          open={open}
          task={item.resourcePayload}
          handleSubmit={(task) => {
            onModify({ ...item, resourceName: task?.name, resourcePayload: task });
            setOpen(false);
          }}
          handleCancel={() => setOpen(false)}
        />
      );
    default:
      return null;
  }
}

ProposalItemView.propTypes = {
  item: PropTypes.object.isRequired
};

export default ProposalItemView;
