import React from 'react';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';

export const NodesToConnectTable = ({ title, nodes }) => {
  return (
    <Table size='small' style={{ overflowX: 'auto' }}>
      <TableHead>
        <TableRow>
          <TableCell>{title}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {nodes.map((node, index) => (
          <TableRow hover={false} key={index}>
            <TableCell sx={[{ height: '80px' }, { 'padding-top': 0, 'padding-bottom': 0 }]}>
              {node.name}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
