import React from 'react';
import { useTranslation } from 'react-i18next';
import { hideUnrelatedNodes } from './GraphFunctions';
import { MenuItem } from '@mui/material';

function onHideUnrelatedNodes(node, graphHandler) {
  let graph = graphHandler.getGraphData();
  hideUnrelatedNodes(graph, node);
  graphHandler.updateGraph(graph);
}

function GraphMenuHideUnrelated({ node, graphHandler }) {
  let [t] = useTranslation();
  return (
    <MenuItem
      children={t('graph.menu.actions.hideUnrelated')}
      disabled={node === undefined}
      onClick={() => onHideUnrelatedNodes(node, graphHandler)}
    />
  );
}

export default GraphMenuHideUnrelated;
