import React from 'react';
import { Typography } from '@mui/material';

const styles = {
  root: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: 3,
    borderColor: (theme) => theme.palette.primary.main || 'grey',
    boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
    padding: '4px'
  }
};

function TeamCodeDecorator({ teamCode }) {
  if (!teamCode) return null;
  return (
    <Typography variant='overline' color='primary' sx={styles.root}>
      {teamCode}
    </Typography>
  );
}

export default TeamCodeDecorator;
