import { Tabs, Tab } from '@mui/material';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import withPermissions from 'components/permissions/withPermissions';
import { fetchQualitySuitesPage } from 'actions/QualitySuitesActions';
import { compose } from 'redux';
import Permissions from 'constants/Permissions';
function QualitySuitPageFiltersSwitch({ fetchQualitySuitesPage, setdefaultPublished }) {
  const [selectedTab, setSelectedTab] = useState(0);

  function handleChange(event, newValue) {
    setSelectedTab(newValue);
    setdefaultPublished(newValue === 0 ? true : false);
    fetchQualitySuitesPage({ page: 0, published: newValue === 0 ? true : false });
  }

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        centered>
        <Tab label='Published'></Tab>
        <Tab label='Draft'></Tab>
      </Tabs>
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.QUALITY_EDITOR] }),
  connect(null, { fetchQualitySuitesPage })
)(QualitySuitPageFiltersSwitch);
