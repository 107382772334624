import crudReducers from '../utils';
import { combineReducers } from 'redux';
import { FETCH_COMMENTS_PAGE } from '../../constants/ActionTypes';

export default combineReducers({
  ...crudReducers({ resource: 'COMMENT', keyProperty: 'sequenceId' }),
  counters
});

function counters(state = {}, action) {
  switch (action.type) {
    case FETCH_COMMENTS_PAGE:
      return {
        ...state,
        [`${action.payload.resourceType}:${action.payload.resourceIdentifier}`]: action.payload
          .totalElements
      };
    default:
      return state;
  }
}

export const selectCounter = (resourceType, resourceIdentifier) => (state) =>
  state.social.comments.counters[`${resourceType}:${resourceIdentifier}`];
