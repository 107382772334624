import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  Card,
  CardHeader,
  CardContent
} from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchHistoryRecordPage } from '../../../actions/HistoryActions';
import { TablePagination } from '@mui/material';
import { compose } from 'redux';

const HistoryRecordTableView = ({ currentPage, fetchHistoryRecordPage, filters }) => {
  let { t } = useTranslation();
  useEffect(() => {
    fetchHistoryRecordPage({ page: 0, size: 10, ...filters });
  }, [filters, fetchHistoryRecordPage]);

  return (
    <Card>
      <CardHeader title='History Records'></CardHeader>
      <CardContent style={{ overflowX: 'auto' }}>
        <Table size='small' style={{ overflowX: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Operation</TableCell>
              <TableCell>Target Type</TableCell>
              <TableCell>Target Name</TableCell>
              <TableCell>Proposer</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(currentPage.content || []).map((historyRecord) => (
              <TableRow key={historyRecord.sequenceId} hover>
                <TableCell>{formatDate(historyRecord.timestamp)}</TableCell>
                <TableCell>{historyRecord.username}</TableCell>
                <TableCell>{historyRecord.operation}</TableCell>
                <TableCell>{t(`history.record.targetType.${historyRecord.targetType}`)}</TableCell>
                <TableCell>{historyRecord.targetName}</TableCell>
                <TableCell>{historyRecord.proposerUsername}</TableCell>
                <TableCell>
                  <Link to={`/history/records/${historyRecord.sequenceId}`}>Open version</Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={currentPage.totalElements || 0}
          rowsPerPage={currentPage.size}
          page={currentPage.number}
          onPageChange={(event, page) =>
            fetchHistoryRecordPage({ page: page, size: currentPage.size, ...filters })
          }
          onRowsPerPageChange={(event) =>
            fetchHistoryRecordPage({ page: 0, size: event.target.value, ...filters })
          }
        />
      </CardContent>
    </Card>
  );
};

export function formatDate(timestamp) {
  if (timestamp) {
    let date = new Date(timestamp);
    return date.toLocaleString();
  } else {
    return null;
  }
}

const mapStateToProps = (state, props) => ({
  currentPage: state.history.currentPage,
  filters: state.history.filters.content
});

const mapDispatchToProps = {
  fetchHistoryRecordPage
};

const HistoryRecordTable = compose(connect(mapStateToProps, mapDispatchToProps))(
  HistoryRecordTableView
);
export default HistoryRecordTable;
