import { BlindataApi } from 'api/ApiUtils';

const DASHBOARDS_URL = '/api/v1/data-quality/assessment/dashboards';

export default class QualityAssessmentDashboardsApi extends BlindataApi {
  getResidualRiskCounter() {
    return this.get(`${DASHBOARDS_URL}/residual-risks-counter`);
  }

  getResidualRiskTable() {
    return this.get(`${DASHBOARDS_URL}/residual-risks-table`);
  }

  exportResidualRisksTable() {
    return this.download(`${DASHBOARDS_URL}/residual-risks-table/export`);
  }
}
