import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withPermissions from 'components/permissions/withPermissions';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import Permissions from 'constants/Permissions';
import { fetchPhysicalEntitiesWithQualityHighlights } from 'actions/QualityHighlightsActions';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Typography,
  Card,
  CardHeader,
  CardContent
} from '@mui/material';

import TableCell from 'components/tables/GenericTableCell';
import DataQualityHighlights from 'components/DataQualityHighlights';
import { useEffect } from 'react';
import GraphDataFlowLinkButton from 'pages/graph/utils/GraphDataFlowLinkButton';

const PhysicalEntitiesSection = ({ dataCategoryUuid, fetchPhysicalEntities, t }) => {
  const initialPage = 0,
    initialSize = 10;

  const history = useHistory();
  const [entities, setEntities] = useState([]);
  const [pagination, setPagination] = useState({
    page: initialPage,
    size: initialSize,
    totalElements: 0
  });

  const handleFetch = (page, size) => {
    fetchPhysicalEntities({ dataCategoriesUuids: [dataCategoryUuid], page, size }).then(
      (response) => {
        setEntities(response?.content);
        setPagination({
          page: response?.number || initialPage,
          size: response?.size || initialSize,
          totalElements: response?.totalElements || 0
        });
      }
    );
  };

  useEffect(() => {
    handleFetch(pagination.page, pagination.size);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card titleTypographyProps={{}} style={{ overflowX: 'auto' }}>
      <CardHeader
        title='Physical Entities'
        action={
          <GraphDataFlowLinkButton
            perspectiveOptions={{
              physicalPerspective: true,
              dataFlowsPerspective: true
            }}
            graphUuidFilter={{
              entityType: 'DATA_CATEGORY',
              name: '',
              object: { uuid: dataCategoryUuid }
            }}
          />
        }></CardHeader>
      <CardContent>
        {entities && entities.length > 0 ? (
          <>
            <Table size='small' style={{ overflowX: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('physicalEntities.detail.general.system')}</TableCell>
                  <TableCell>{t('physicalEntities.detail.general.schema')}</TableCell>
                  <TableCell>{t('physicalEntities.detail.general.name')}</TableCell>
                  <TableCell hiddenMd>{t('physicalEntities.detail.general.description')}</TableCell>
                  <TableCell style={{ width: '150px' }}>
                    {t('physicalEntities.detail.qualityChecks.title')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entities.map((entity) => (
                  <TableRow
                    hover={true}
                    style={{ cursor: 'pointer' }}
                    key={entity.physicalEntityUuid}
                    onClick={() => history.push(`/physicalentities/${entity.physicalEntityUuid}`)}>
                    <TableCell>{entity.systemName}</TableCell>
                    <TableCell>{entity.schema || '-'}</TableCell>
                    <TableCell>{entity.physicalEntityName}</TableCell>
                    <TableCell breakWord hiddenMd>
                      {_.truncate(entity.physicalEntityDescription, { length: 125 }) || '-'}
                    </TableCell>
                    <TableCell>
                      <DataQualityHighlights
                        greens={entity.greens}
                        yellows={entity.yellows}
                        reds={entity.reds}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component='div'
              style={{ overflowX: 'hidden' }}
              rowsPerPageOptions={[10, 25, 50]}
              page={pagination.page}
              rowsPerPage={pagination.size}
              count={pagination.totalElements}
              onPageChange={(event, page) => handleFetch(page, pagination.size)}
              onRowsPerPageChange={(event) => handleFetch(pagination.page, event.target.value)}
            />
          </>
        ) : (
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>
              No physical entities associated to this data category
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default compose(
  withPermissions({ hasPermissions: [Permissions.SYSTEMS_VIEWER] }),
  withTranslation(),
  connect(null, {
    fetchPhysicalEntities: fetchPhysicalEntitiesWithQualityHighlights
  })
)(PhysicalEntitiesSection);
