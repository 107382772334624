import PropTypes from 'prop-types';
import _ from 'lodash';
import ItemAutoComplete from '../../../components/itemselector/ItemAutoComplete';
import { connect } from 'react-redux';
import { searchLogicalFields } from '../../../actions/LogicalFieldsActions';

const mapStateToProps = (state, props) => ({
  values: Object.values(state.logicalfields.searchContent)
});

const mapDispatchToProps = (dispatch, { dataCategoryUuid }) => ({
  onSearch: (search) => dispatch(searchLogicalFields(search, { dataCategoryUuid }))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  itemName: 'LogicalField',
  keyProperty: 'uuid',
  nameProperty: 'name',
  subheaderProperty: 'dataCategory.name',
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  value: extractOriginalValue(ownProps, stateProps)
});

const extractOriginalValue = (ownProps, stateProps) => {
  if (ownProps.value && !ownProps.value.name) {
    let originalValue = _(stateProps.values).find({ uuid: ownProps.value.uuid });
    return originalValue || ownProps.value;
  } else {
    return ownProps.value;
  }
};

const LogicalFieldItemAutoComplete = _.flow([
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
])(ItemAutoComplete);

LogicalFieldItemAutoComplete.displayName = 'LogicalFieldItemAutoComplete';

LogicalFieldItemAutoComplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  dataCategoryUuid: PropTypes.string
};

export default LogicalFieldItemAutoComplete;
