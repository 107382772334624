import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, MenuItem, Typography } from '@mui/material';

import Months from './fields/Months';
import MonthDays from './fields/MonthDays';
import WeekDays from './fields/WeekDays';
import Hours from './fields/Hours';
import Minutes from './fields/Minutes';

import { getCronStringFromValues } from './utils/converter';

const CronGenerator = ({ apply }) => {
  const { t } = useTranslation();

  const [expression, setExpression] = useState(null);
  const [period, setPeriod] = useState('day');
  const [months, setMonths] = useState([1]);
  const [monthDays, setMonthDays] = useState([1]);
  const [weekDays, setWeekDays] = useState([2]);
  const [hours, setHours] = useState([0]);
  const [minutes, setMinutes] = useState([0]);
  const options = [
    {
      value: 'year',
      label: 'cronConverter.timeunits.YEAR'
    },
    {
      value: 'month',
      label: 'cronConverter.timeunits.MONTH'
    },
    {
      value: 'week',
      label: 'cronConverter.timeunits.WEEK'
    },
    {
      value: 'day',
      label: 'cronConverter.timeunits.DAY'
    },
    {
      value: 'hour',
      label: 'cronConverter.timeunits.HOUR'
    },
    {
      value: 'minute',
      label: 'cronConverter.timeunits.MINUTE'
    }
  ];

  useEffect(() => {
    const resultingExpression = getCronStringFromValues(
      period,
      months,
      monthDays,
      weekDays,
      hours,
      minutes
    );
    setExpression(resultingExpression);
    apply(resultingExpression);
  }, [period, months, monthDays, weekDays, hours, minutes, apply]);

  return <>
    <div>
      <span>Every</span>
      <Select
        variant="standard"
        style={{ marginLeft: '5px' }}
        value={period}
        onChange={(event) => setPeriod(event.target.value)}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {t(option.label)}
          </MenuItem>
        ))}
      </Select>
      {period === 'year' && <Months value={months} setValue={setMonths} />}
      {(period === 'year' || period === 'month') && (
        <MonthDays value={monthDays} setValue={setMonthDays} />
      )}
      {period === 'week' && (
        <WeekDays value={weekDays} setValue={setWeekDays} selectedPeriod={period} />
      )}
      {period !== 'minute' && period !== 'hour' && <Hours value={hours} setValue={setHours} />}
      {period !== 'minute' && (
        <Minutes value={minutes} setValue={setMinutes} selectedPeriod={period} />
      )}
    </div>
    <br />
    <Typography variant='caption'>{`Resulting Cron Expression: ${expression}`}</Typography>
  </>;
};

export default CronGenerator;
