import React from 'react';
import { connect } from 'react-redux';
import { fetchAllProcessingsDisclaimers } from '../../../../actions/ProcessingsActions';
import {
  createContractConfiguration,
  modifyContractConfiguration,
  editContractConfiguration,
  clearContractConfigurationEditor,
  loadContractConfigurationEditor,
  copyContractConfigurationEditor
} from '../../../../actions/ContractsActions';
import { fetchContractTemplatesPage } from '../../../../actions/ContractTemplatesActions';
import Loader from '../../../../components/loader/Loader';
import ProcessingsPlaceholdersValuesEditor from './ProcessingsPlaceholdersValuesEditor';
import PlaceholdersValuesEditor from './PlaceholdersValuesEditor';
import { Typography, Button, Grid } from '@mui/material';
import GeneralDataEditor from './GeneralDataEditor';
import Breadcrumbs from 'pages/sidebar/Navigation';
import { useTranslation } from 'react-i18next';

const ContractConfigurationEditor = ({
  configurationUuid,
  editor,
  templates,
  editContractConfiguration,
  createContractConfiguration,
  modifyContractConfiguration,
  disclaimers,
  fetchAllProcessingsDisclaimers,
  ...otherProps
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Breadcrumbs
        elements={[
          { text: 'Contracts', to: '/contracts' },
          { text: 'Configurations', to: '/contracts/configurations' },
          { text: 'Contract Configuration Editor' }
        ]}></Breadcrumbs>

      <Grid container>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Contract Configuration Editor</Typography>
        </Grid>
        <Grid item>
          <Button
            children={t('commons.actions.submit')}
            variant='contained'
            color='primary'
            onClick={() => {
              (editor.uuid
                ? modifyContractConfiguration(editor)
                : createContractConfiguration(editor)
              ).then((c) =>
                c ? otherProps.history.push(`/contracts/configurations/${c.uuid}`) : null
              );
            }}
            style={{ position: 'absolute', top: '10px', right: '10px' }}
            disabled={!editor.contractName || !editor.version || !editor.language}>
            {editor.uuid ? 'Save' : 'Create'}
          </Button>
        </Grid>
      </Grid>

      <GeneralDataEditor {...{ editor, editContractConfiguration, templates }} />

      <ProcessingsPlaceholdersValuesEditor
        processingPlaceholdersValues={editor.processingPlaceholdersValues || []}
        disclaimers={disclaimers}
        fetchAllProcessingsDisclaimers={fetchAllProcessingsDisclaimers}
        onChange={(processingPlaceholdersValues) =>
          editContractConfiguration({ processingPlaceholdersValues })
        }
      />

      <PlaceholdersValuesEditor
        placeholdersValues={editor.placeholdersValues || []}
        onChange={(placeholdersValues) => editContractConfiguration({ placeholdersValues })}
      />
    </div>
  );
};

const LoadableConfigurationEditor = ({
  copy,
  copyContractConfigurationEditor,
  loadContractConfigurationEditor,
  ...props
}) => (
  <Loader
    onLoad={() => {
      props.fetchContractTemplatesPage(0, 1000);
      props.clearContractConfigurationEditor({ uuid: null, version: null });
      if (props.configurationUuid) {
        props.editContractConfiguration({
          uuid: null,
          contractName: null,
          language: null,
          version: null,
          processingPlaceholdersValues: [],
          placeholdersValues: []
        });
        if (copy) {
          copyContractConfigurationEditor(props.configurationUuid);
        } else {
          loadContractConfigurationEditor(props.configurationUuid);
        }
      }
    }}>
    <ContractConfigurationEditor {...props} />
  </Loader>
);

const mapStateToProps = (state, props) => ({
  configurationUuid: props.match.params['configurationUuid'],
  editor: state.contracts.configurations.editor,
  templates: state.contracts.templates.currentPage.content,
  disclaimers: state.processings.disclaimers.contentByProcessingUuid
});

export default connect(mapStateToProps, {
  createContractConfiguration,
  modifyContractConfiguration,
  editContractConfiguration,
  clearContractConfigurationEditor,
  loadContractConfigurationEditor,
  copyContractConfigurationEditor,
  fetchContractTemplatesPage,
  fetchAllProcessingsDisclaimers
})(LoadableConfigurationEditor);
