import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  ListItemSecondaryAction
} from '@mui/material';
import UsernameAvatar from '../../../components/avatars/UsernameAvatar';
import moment from 'moment';

import CommentsActionsMenu from './CommentsActionsMenu';
import BdLinkify from 'components/linkify/BdLinkify';

function CommentsListItem({ comment }) {
  return (
    <ListItem alignItems='flex-start' dense>
      <ListItemAvatar>
        <UsernameAvatar username={comment.authorUsername} randomBackgroundColor></UsernameAvatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography component='span' display='inline' variant='body2'>
              <bold>{comment.authorUsername}</bold>
            </Typography>
            <Typography component='span' variant='caption' color='textSecondary'>
              {' - '} {comment.createdAt && moment(comment.createdAt).fromNow()}
              {comment.updatedAt &&
                moment(comment.updatedAt).isAfter(comment.createdAt) &&
                ' (edited)'}
            </Typography>
          </>
        }
        secondary={<BdLinkify>{comment.content}</BdLinkify>}
        secondaryTypographyProps={{ style: { whiteSpace: 'pre-line' } }}
      />
      <ListItemSecondaryAction>
        <CommentsActionsMenu comment={comment}></CommentsActionsMenu>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default CommentsListItem;
