import { combineReducers } from 'redux';
import filtersReducer from 'reducers/utils/filters';
import {
  FETCH_USERS_PAGE,
  CREATE_USER,
  PATCH_USER,
  DELETE_USER,
  FETCH_USER,
  ADD_USER_TENANT,
  SEARCH_USER,
  REMOVE_USER_TENANT,
  FETCH_USER_TENANTS,
  CHANGE_DEFAULT_TENANT,
  FETCH_DEFAULT_TENANT,
  FETCH_USER_TENANT_PERMISSIONS,
  MODIFY_USER_TENANT_PERMISSIONS,
  FETCH_TEAM_USER_SETTINGS,
  FETCH_USERS_IN_CURRENT_TENANT,
  MODIFY_TEAM_USER_SETTINGS
} from '../../constants/ActionTypes';
import _ from 'lodash';

const USERS_INITIAL_STATE = {
  content: {},
  currentPage: {
    content: [],
    number: 0,
    size: 10,
    totalPages: 0
  },
  filters: {}
};

const content = (state = USERS_INITIAL_STATE.content, action) => {
  switch (action.type) {
    case FETCH_USERS_PAGE:
    case SEARCH_USER:
      return {
        ...state,
        ..._(action.payload.content).keyBy('uuid').value()
      };
    case CREATE_USER:
    case FETCH_USER:
    case PATCH_USER:
      return {
        ...state,
        [action.payload.uuid]: action.payload
      };
    case DELETE_USER:
      return {
        ...state,
        [action.payload.uuid]: undefined
      };
    default:
      return state;
  }
};

const currentTenantContent = (state = USERS_INITIAL_STATE.content, action) => {
  switch (action.type) {
    case FETCH_USERS_IN_CURRENT_TENANT:
      return {
        ...state,
        ..._(action.payload.content).keyBy('uuid').value()
      };
    default:
      return state;
  }
};

const currentPage = (state = USERS_INITIAL_STATE.currentPage, action) => {
  switch (action.type) {
    case FETCH_USERS_PAGE:
      return action.payload;
    case CREATE_USER:
    case FETCH_USER:
    case PATCH_USER:
      return {
        ...state,
        content: _(state.content)
          .keyBy('uuid')
          .assign({ [action.payload.uuid]: action.payload })
          .map()
          .value()
      };
    case DELETE_USER:
      return {
        ...state,
        content: state.content.filter((u) => u.uuid !== action.payload.uuid)
      };
    default:
      return state;
  }
};

const associatedTenants = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_TENANTS:
      return {
        ...state,
        [action.payload.userUuid]: _.unionBy(
          state[action.payload.userUuid] || [],
          action.payload.content || [],
          'uuid'
        )
      };
    case REMOVE_USER_TENANT:
      return {
        ...state,
        [action.payload.userUuid]: _.filter(
          state[action.payload.userUuid],
          (e) => e.uuid !== action.payload.tenantUuid
        )
      };
    case ADD_USER_TENANT:
    case CHANGE_DEFAULT_TENANT:
      return {
        ...state,
        [action.payload.userUuid]: _.unionBy(
          state[action.payload.userUuid] || [],
          [action.payload.tenant],
          'uuid'
        )
      };
    default:
      return state;
  }
};

const defaultTenants = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_DEFAULT_TENANT:
    case FETCH_DEFAULT_TENANT:
      return {
        ...state,
        [action.payload.userUuid]: action.payload.tenant
      };
    default:
      return state;
  }
};

const teamSettings = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TEAM_USER_SETTINGS:
    case MODIFY_TEAM_USER_SETTINGS:
      return { ...state, [action.payload.user.uuid]: action.payload };
    default:
      return state;
  }
};

const tenantPermissions = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_TENANT_PERMISSIONS:
    case MODIFY_USER_TENANT_PERMISSIONS:
      return {
        ...state,
        [tenantPermissionsKey(action.payload.userUuid, action.payload.tenantUuid)]: action.payload
      };
    default:
      return state;
  }
};

const tenantPermissionsKey = (userUuid, tenantUuid) => `${userUuid}@${tenantUuid}`;

export function selectTenantPermissions(tenantPermissions, { userUuid, tenantUuid }) {
  const key = tenantPermissionsKey(userUuid, tenantUuid);
  const tenantPermissionsRes = tenantPermissions[key];
  const tenantPermissionsArray = tenantPermissionsRes && tenantPermissionsRes.permissions;
  return tenantPermissionsArray;
}

export default combineReducers({
  content,
  currentPage,
  filters: filtersReducer({ resource: 'USER', filtersDefault: { onlyCurrentTenant: true } }),
  associatedTenants,
  defaultTenants,
  tenantPermissions,
  currentTenantContent,
  teamSettings
});
