import { AddFAB } from 'components/fab';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Authorize from '../../../components/permissions/Authorize';
import { createSystem } from 'actions/SystemsActions';
import Permissions from 'constants/Permissions';
import SystemEditorModal from './SystemEditorModal';
function SystemsFAB({ createSystem }) {
  let [open, setOpen] = useState(false);
  let history = useHistory();
  function handleCreateSystem(sys) {
    createSystem(sys).then((sys) => {
      if (sys) {
        history.push(`/systems/${sys.uuid}`);
      }
    });
  }

  return (
    <>
      <Authorize hasPermissions={[Permissions.SYSTEMS_ADMIN]}>
        <AddFAB id='system-create-button' onClick={() => setOpen(true)} />
      </Authorize>

      {open && (
        <SystemEditorModal
          title='Create a system'
          open={open}
          onSubmit={(sys) => handleCreateSystem(sys)}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
}

export default connect(null, { createSystem })(SystemsFAB);
