import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Button, CardActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import { modifySchedule, deleteSchedule } from 'actions/onagent/AgentScheduleActions';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import { connect } from 'react-redux';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import CrawlingModal from './CrawlingModal';
import JobHistoryTable from 'pages/quality/probes/projects/commons/JobHistoryTable';
import CrawlingRunButton from './CrawlingRunButton';

function CrawlingScheduleDetailCard({
  jobDefinition,
  system,
  agent,
  modifySchedule,
  deleteSchedule
}) {
  const [editModal, setEditModal] = useState(false);
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={'Job Definition'}
        action={
          jobDefinition.scheduled ? (
            <VariationTypeDecorator>{'SCHEDULED'}</VariationTypeDecorator>
          ) : (
            <VariationTypeDecorator>{'NOT SCHEDULED'}</VariationTypeDecorator>
          )
        }></CardHeader>
      <CardContent>
        <KVDefinitionList
          header='General'
          item={{
            Name: jobDefinition.name,
            Type: jobDefinition.type,
            Cron: jobDefinition.cronExpression,
            Timezone: jobDefinition.timezoneId,
            Target: jobDefinition?.jobDetails?.configs?.connectionName
          }}></KVDefinitionList>
        <KVDefinitionList
          header='Output'
          item={{
            Credentials: jobDefinition.jobDetails.configs.outputOptions?.targetConnectionName,
            'Default Hidden':
              jobDefinition.jobDetails.configs.outputOptions?.defaultHidden?.toString()
          }}
        />
        <div style={{ marginBlockStart: '1em', marginBlockEnd: '1em', overflow: 'auto' }}>
          <h4>Runs History</h4>
          <JobHistoryTable agent={agent} jobUuid={jobDefinition.uuid} />
        </div>
      </CardContent>
      {jobDefinition && agent && (
        <CardActions>
          <Authorize hasPermissions={[Permissions.SYSTEMS_EDITOR, Permissions.AGENTS_EDITOR]}>
            <Button onClick={() => setEditModal(true)}>{t('commons.actions.modify')}</Button>
          </Authorize>
          <CrawlingRunButton agent={agent} jobDefinition={jobDefinition} />
          <Authorize hasPermissions={[Permissions.SYSTEMS_EDITOR, Permissions.AGENTS_EDITOR]}>
            <ConfirmButton onClick={() => deleteSchedule(agent, jobDefinition)}>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </Authorize>
        </CardActions>
      )}
      {editModal && (
        <CrawlingModal
          open={editModal}
          agent={agent}
          schedule={jobDefinition}
          system={system}
          title={`Modify ${jobDefinition.name}`}
          onCancel={() => setEditModal(false)}
          onSubmit={(schedule) => {
            modifySchedule(agent, schedule);
            setEditModal(false);
          }}
        />
      )}
    </Card>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.AGENTS_VIEWER] }),
  connect(null, {
    modifySchedule,
    deleteSchedule
  })
)(CrawlingScheduleDetailCard);
