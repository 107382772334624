import SystemsApi from '../api/SystemsApi';
import { buildApiCallAction } from './ActionUtils';

import {
  SEARCH_SYSTEMS,
  FETCH_SYSTEM,
  FETCH_SYSTEMS_PAGE,
  DELETE_SYSTEM,
  MODIFY_SYSTEM,
  CREATE_SYSTEM,
  SET_SYSTEMS_FILTERS,
  CLEAR_SYSTEMS_FILTERS,
  EXPORT_SYSTEMS
} from '../constants/ActionTypes';

let api = new SystemsApi();

export const fetchSystemsPage = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getSystems(params),
    actionType: FETCH_SYSTEMS_PAGE
  });

export const fetchSystem = (systemUuid) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getSystem(systemUuid),
    actionType: FETCH_SYSTEM
  });

export const createSystem = (system) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.postSystem(system),
    actionType: CREATE_SYSTEM
  });

export const modifySystem = (system) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.putSystem(system),
    actionType: MODIFY_SYSTEM
  });

export const deleteSystem = (system) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.deleteSystem(system),
    actionType: DELETE_SYSTEM,
    payload: system
  });

export const setSystemsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_SYSTEMS_FILTERS, payload: filters });
};

export const clearSystemsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_SYSTEMS_FILTERS });
};

export const exportSystems = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportSystems(params),
    actionType: EXPORT_SYSTEMS
  });

/**
 * Search for systems according to given the parameters·
 *
 * @param {{search: string}} search
 */
export const searchSystems = ({ search }) =>
  buildApiCallAction({
    api: api,
    apiFunction: () => api.getSystems({ search }),
    actionType: SEARCH_SYSTEMS
  });
