import React, { useEffect } from 'react';
import { searchTags } from 'actions/quality/probes/ProbesTagsActions';
import { connect } from 'react-redux';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';

function QualityProbesProjectTagsItemAutocomplete({
  onSearch,
  searchContent,
  onChange,
  value,
  projectUuid
}) {
  useEffect(() => {
    onSearch({ projectUuid: projectUuid, search: '' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearch]);
  return (
    <ItemAutoComplete
      values={searchContent}
      value={value}
      showAllOptions
      categoryProperties={['project', 'name']}
      itemName='Tags'
      nameProperty={'name'}
      onChange={(newValue) => onChange(newValue)}
      onSearch={(inputValue) => {
        onSearch({ projectUuid: projectUuid, search: inputValue });
      }}></ItemAutoComplete>
  );
}

const mapStateToProps = (state, props) => ({
  searchContent: Object.values(state.quality.qualityProbes.tags.content).filter(
    (tag) => tag.project.uuid === props.projectUuid
  )
});

const mapDispatchToProps = (dispatch) => ({
  onSearch: (search) => dispatch(searchTags(search))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualityProbesProjectTagsItemAutocomplete);
