import _ from 'lodash';

export function csvDataToObject(header, csvData, configuration) {
  let results = [];
  for (let flattenObject of csvData) {
    let unflattenObject = _(flattenObject).reduce(function (acc, value, key) {
      return (value !== '' && value !== null) || configuration?.patchMode
        ? _.set(acc, key, value)
        : acc;
    }, {});
    results.push(unflattenObject);
  }
  return results;
}
