import * as React from "react";
const SvgDbtLabsDbt = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="M245.121 10.647c6.018 5.787 9.953 13.425 10.879 21.758 0 3.472-.926 5.787-3.01 10.185-2.082 4.397-27.775 48.839-35.413 61.106-4.398 7.176-6.713 15.74-6.713 24.072 0 8.565 2.315 16.898 6.713 24.073 7.638 12.268 33.33 56.94 35.414 61.338 2.083 4.398 3.009 6.48 3.009 9.953-.926 8.333-4.63 15.971-10.647 21.526-5.787 6.018-13.425 9.953-21.527 10.648-3.471 0-5.786-.926-9.953-3.01-4.166-2.083-49.533-27.08-61.8-34.719-.927-.463-1.852-1.157-3.01-1.62L88.42 180.08c1.388 11.573 6.48 22.683 14.813 30.784 1.62 1.62 3.24 3.01 5.093 4.398-1.39.694-3.01 1.39-4.398 2.315-12.268 7.638-56.94 33.33-61.338 35.414-4.398 2.083-6.481 3.009-10.185 3.009-8.333-.926-15.971-4.63-21.526-10.647C4.86 239.566.926 231.928 0 223.595c.231-3.472 1.157-6.944 3.01-9.953 2.082-4.398 27.775-49.07 35.413-61.338 4.398-7.176 6.713-15.508 6.713-24.072 0-8.565-2.315-16.898-6.713-24.073-7.638-12.73-33.562-57.403-35.414-61.801C1.157 39.348.231 35.877 0 32.405c.926-8.333 4.63-15.971 10.647-21.758C16.434 4.63 24.072.926 32.405 0c3.472.231 6.944 1.157 10.185 3.01 3.703 1.62 36.34 20.6 53.7 30.784l3.934 2.315c1.389.925 2.546 1.62 3.472 2.083l1.852 1.157 61.8 36.571c-1.388-13.888-8.563-26.618-19.674-35.182 1.39-.694 3.01-1.39 4.398-2.315 12.268-7.638 56.94-33.562 61.338-35.414C216.42 1.157 219.891.231 223.595 0a35.434 35.434 0 0 1 21.526 10.647Zm-113.88 133.787 13.193-13.194c1.852-1.851 1.852-4.629 0-6.48l-13.194-13.194c-1.851-1.852-4.629-1.852-6.48 0l-13.194 13.194c-1.852 1.851-1.852 4.629 0 6.48l13.194 13.194c1.62 1.62 4.629 1.62 6.48 0Z"
      fill="#FF694A"
    />
  </svg>
);
export default SvgDbtLabsDbt;
