import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import TableCell from 'components/tables/GenericTableCell';
import React, { useState } from 'react';
import DataProductPortModal from '../commons/DataProductPortModal';
import DataProductPortEditModal from '../commons/DataProductPortEditModal';
import _ from 'lodash';

export function DataProductPortList({ content, title, deletePortFromList, updatePortFromList }) {
  const [dataProductPortModalOpen, setDataProductPortModalOpen] = useState(false);
  const [dataProductPortEditModalOpen, setDataProductPortEditModalOpen] = useState(false);
  const [currentPort, setCurrentPort] = useState({});

  return (
    <>
      <Card variant='outlined' style={{ height: '100%' }}>
        <CardHeader title={title} />
        <CardContent>
          {content && content.length > 0 ? (
            <Table size='small' style={{ overflow: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Type</TableCell>
                  {deletePortFromList ? <TableCell align='right'></TableCell> : <></>}
                </TableRow>
              </TableHead>
              <TableBody>
                {content.map((port, index) => (
                  <TableRow
                    key={index}
                    hover={true}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setCurrentPort(port);
                      setDataProductPortModalOpen(true);
                    }}>
                    <TableCell>{_.truncate(port?.displayName, { length: 30 })}</TableCell>
                    <TableCell>{_.truncate(port?.version, { length: 30 })}</TableCell>
                    <TableCell>{_.truncate(port?.servicesType, { length: 30 })}</TableCell>
                    {updatePortFromList || deletePortFromList ? (
                      <TableCell align='right'>
                        {updatePortFromList ? (
                          <IconButton
                            size='small'
                            onClick={(event) => {
                              setCurrentPort(port);
                              setDataProductPortEditModalOpen(true);
                              event.stopPropagation();
                            }}>
                            <ModeEditIcon />
                          </IconButton>
                        ) : (
                          <></>
                        )}
                        {deletePortFromList ? (
                          <IconButton
                            size='small'
                            onClick={(event) => {
                              deletePortFromList(port);
                              event.stopPropagation();
                            }}>
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography variant='body1' style={{ textAlign: 'center', padding: 5 }}>
              No Ports Defined
            </Typography>
          )}
        </CardContent>
      </Card>
      {dataProductPortModalOpen && (
        <DataProductPortModal
          open={dataProductPortModalOpen}
          port={currentPort}
          handleClose={() => {
            setCurrentPort({});
            setDataProductPortModalOpen(false);
          }}
        />
      )}
      {dataProductPortEditModalOpen && (
        <DataProductPortEditModal
          open={dataProductPortEditModalOpen}
          dataProductPort={currentPort}
          title={'Modify Data Product Port'}
          handleClose={() => {
            setCurrentPort({});
            setDataProductPortEditModalOpen(false);
          }}
          onSubmit={(newPort) => updatePortFromList(currentPort, newPort)}
        />
      )}
    </>
  );
}
