import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
import TemplatesTasksSearchModal from '../../tasks/commons/TemplateTasksSearchModal';
import AccountTeamSelector, {
  ACCESS_LEVELS
} from '../../../settings/profile/utils/AccountTeamSelector';

function TemplateMappingModal({
  open,
  templateMapping = {},
  onClose,
  onSubmit,
  fullScreen = false
}) {
  const { t } = useTranslation();
  const [templateDataPatch, setTemplateDataPatch] = React.useState({});
  const [addTemplateTaskOpen, setAddTemplateTaskOpen] = React.useState(false);
  const mergedTemplateData = { ...templateMapping, ...templateDataPatch };
  return (
    <Dialog fullWidth fullScreen={fullScreen} open={open}>
      <DialogTitle>Mapping</DialogTitle>
      <DialogContent>
        <h4>Mapping definition</h4>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter the code'}
          label={'Code'}
          value={mergedTemplateData.code || ''}
          onChange={(event) =>
            setTemplateDataPatch({ ...templateDataPatch, code: event.target.value })
          }
        />
        <br></br>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={t('commons.fields.nameHint')}
          label={t('commons.fields.name')}
          value={mergedTemplateData.name || ''}
          onChange={(event) =>
            setTemplateDataPatch({ ...templateDataPatch, name: event.target.value })
          }
        />
        <TextField
          variant='standard'
          fullWidth
          placeholder={t('commons.fields.descriptionHint')}
          label={t('commons.fields.description')}
          value={mergedTemplateData.description || ''}
          onChange={(event) =>
            setTemplateDataPatch({ ...templateDataPatch, description: event.target.value })
          }
        />
        <h4>Associated tasks templates</h4>
        <ChipWrapper onRequestAdd={() => setAddTemplateTaskOpen(true)}>
          {(mergedTemplateData.templatesTasks || []).map((p, index) => (
            <ChipItem
              key={index}
              id={index}
              label={p.name}
              onRequestDelete={() =>
                setTemplateDataPatch({
                  ...templateDataPatch,
                  templateTasks: mergedTemplateData.templatesTasks.filter((p, idx) => idx !== index)
                })
              }
            />
          ))}
        </ChipWrapper>

        <TemplatesTasksSearchModal
          title={'Add Task Template'}
          open={addTemplateTaskOpen}
          onCancel={() => setAddTemplateTaskOpen(false)}
          onSubmit={(templateTask) => {
            setTemplateDataPatch({
              ...templateDataPatch,
              templatesTasks: (mergedTemplateData.templatesTasks || []).concat([templateTask])
            });
            setAddTemplateTaskOpen(false);
          }}
        />
      </DialogContent>
      <DialogActions>
        <AccountTeamSelector
          style={{ float: 'left' }}
          accessLevel={ACCESS_LEVELS.WRITE}
          accessLevel2={ACCESS_LEVELS.PROPOSE}
          selectedTeamCode={mergedTemplateData.teamCode || ''}
          onChange={(code) => setTemplateDataPatch({ ...templateDataPatch, teamCode: code })}
        />
        <div style={{ flexGrow: 1 }} />
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={!mergedTemplateData.name || !mergedTemplateData.code}
          onClick={() => onSubmit(mergedTemplateData, templateDataPatch)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TemplateMappingModal;
