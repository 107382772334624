import React from 'react';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Delete as DeleteIcon } from '@mui/icons-material';
import AutoComplete from '../../../components/autocomplete/AutoComplete';
import _ from 'lodash';

export default function TemplateVariablesEditor({ variables, type, onChange }) {
  const [addVariableOpen, setAddVariableOpen] = React.useState(false);
  return (
    <>
      <h4>Template variables</h4>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {onChange && <TableCell></TableCell>}
            <TableCell>Input</TableCell>
            <TableCell>Destination</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(variables || []).map((v) => (
            <TableRow>
              {onChange && (
                <TableCell>
                  <IconButton
                    size='small'
                    onClick={() => onChange(variables.filter((i) => v !== i))}>
                    <DeleteIcon></DeleteIcon>
                  </IconButton>
                </TableCell>
              )}
              <TableCell>
                {cellLabelFromValue(CONTEXT_KEY_DATASOURCES[type], v.contextKey)}
              </TableCell>
              <TableCell>
                {cellLabelFromValue(DESTINATION_DATASOURCES[type], v.destination)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {onChange && (
        <Button color='primary' onClick={() => setAddVariableOpen(true)}>
          Add Variable
        </Button>
      )}
      {onChange && (
        <AddVariableDialog
          type={type}
          open={addVariableOpen}
          onCancel={() => setAddVariableOpen(false)}
          onSubmit={(variable) => {
            setAddVariableOpen(false);
            onChange((variables || []).concat([variable]));
          }}
        />
      )}
    </>
  );
}

function AddVariableDialog({ open, onCancel, onSubmit, type }) {
  let { t } = useTranslation();
  let [contextKey, setContextKey] = React.useState('');
  let [destination, setDestination] = React.useState('');
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add Variable</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        <AutoComplete
          required
          fullWidth
          placeholder={'Enter the input'}
          label={'Input'}
          dataSource={CONTEXT_KEY_DATASOURCES[type] || {}}
          dataSourceConfig={{ text: 'text' }}
          value={labelFromValue(CONTEXT_KEY_DATASOURCES[type], contextKey)}
          onUpdateInput={(newValue, dataSource) => {
            let dataSourceElement = _(dataSource).find({ text: newValue });
            let dataSourceValue = dataSourceElement && dataSourceElement.value;
            setContextKey(dataSourceValue || newValue);
          }}
        />
        <AutoComplete
          required
          fullWidth
          placeholder={'Enter the destination'}
          label={'Destination'}
          value={labelFromValue(DESTINATION_DATASOURCES[type], destination)}
          dataSource={DESTINATION_DATASOURCES[type] || {}}
          dataSourceConfig={{ text: 'text' }}
          onUpdateInput={(newValue, dataSource) => {
            let dataSourceElement = _(dataSource).find({ text: newValue });
            let dataSourceValue = dataSourceElement && dataSourceElement.value;
            setDestination(dataSourceValue || newValue);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={!contextKey || !destination}
          onClick={() => onSubmit({ contextKey, destination })}>
          {t('commons.actions.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function cellLabelFromValue(dataSource, value) {
  let elem = _(dataSource).find({ value });
  return elem ? `${elem.text} (${value})` : value;
}

function labelFromValue(dataSource, value) {
  let elem = _(dataSource).find({ value });
  return elem ? elem.text : value;
}

const CONTEXT_KEY_DATASOURCES = {
  PROCESSING: [{ text: 'Incoming Data Actor', value: 'dataActor' }],
  TASK: [{ text: 'Incoming Data Actor', value: 'dataActor' }]
};

const DESTINATION_DATASOURCES = {
  PROCESSING: [
    { text: 'Data Controller', value: 'controller' },
    { text: 'Start Date', value: 'startDate' },
    { text: 'End Date', value: 'endDate' }
  ],
  TASK: [
    { text: 'Data Processor', value: 'dataProcessor' },
    { text: 'Start Date', value: 'startDate' },
    { text: 'End Date', value: 'endDate' }
  ]
};
