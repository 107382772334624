import { combineReducers } from 'redux';
import {
  FETCH_QUALITY_CHECKS_SEMAPHORES_PIE_CHART,
  FETCH_QUALITY_CHECKS_TIMELINE_BY_TEST_SUITE,
  SET_QUALITY_CHECKS_TIMELINE_BY_TEST_SUITE_FILTERS,
  FETCH_QUALITY_RESULTS_AREA_CHART,
  FETCH_QUALITY_CHECKS_TIMELINE_BY_CHECK,
  FETCH_QUALITY_CHECKS_STATS,
  FETCH_QUALITY_CHECKS_TIMELINE_OVERALL,
  SET_QUALITY_CHECKS_TIMELINE_BY_CHECK_FILTERS,
  SET_QUALITY_RESULTS_FILTERS,
  CLEAR_QUALITY_RESULTS_FILTERS,
  FETCH_QUALITY_DASHBOARDS_OVERALL,
  FETCH_HOME_QUALITY_CHECKS_SEMAPHORES_PIE_CHART
} from 'constants/ActionTypes';
import filtersReducer from 'reducers/utils/filters';
import _ from 'lodash';

const moment = require('moment');

const from = new Date();
from.setDate(from.getDate() - 10);
const startedAtGte = moment().subtract(10, 'days').startOf('day').toISOString();
const startedAtLt = moment().endOf('day').toISOString();

const timelineInitialState = {
  data: null,
  filters: {
    startedAtGte,
    startedAtLt,
    precision: 'day'
  }
};

const overallFiltersDefault = {
  startedAtGte,
  startedAtLt
};

const qualityChecksLastRun = (state = {}, action) => {
  switch (action.type) {
    case FETCH_QUALITY_CHECKS_SEMAPHORES_PIE_CHART:
      const uuids = action.payload.qualitySuitesUuids;
      const statePatchEntry = _(uuids).map((uuid, index) => ({
        [uuid]: {
          columns: action.payload.columns,
          row: action.payload.rows[index]
        }
      }));
      const statePatch = statePatchEntry.reduce((o, acc) => ({ ...acc, ...o }), {});
      return { ...state, ...statePatch };
    default:
      return state;
  }
};

const homeChecksLastRun = (state = {}, action) => {
  switch (action.type) {
    case FETCH_HOME_QUALITY_CHECKS_SEMAPHORES_PIE_CHART:
      return {
        ...action.payload,
        row: action.payload.rows.reduce(
          (prev, cur) => [
            null,
            null,
            prev[2] + cur[2],
            prev[3] + cur[3],
            prev[4] + cur[4],
            prev[5] > cur[5] ? prev[5] : cur[5],
            prev[6] < cur[6] ? prev[6] : cur[6]
          ],
          [null, null, 0, 0, 0, action.payload.rows?.[5], action.payload.rows?.[6]]
        )
      };
    default:
      return state;
  }
};

const qualityChecksTimelineByTestSuite = (state = timelineInitialState, action) => {
  switch (action.type) {
    case FETCH_QUALITY_CHECKS_TIMELINE_BY_TEST_SUITE:
      return { ...state, data: action.payload };
    case SET_QUALITY_CHECKS_TIMELINE_BY_TEST_SUITE_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    default:
      return state;
  }
};

const metricsVolumes = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_QUALITY_RESULTS_AREA_CHART:
      return action.payload;
    default:
      return state;
  }
};

const qualityChecksTimelineByCheck = (state = timelineInitialState, action) => {
  switch (action.type) {
    case FETCH_QUALITY_CHECKS_TIMELINE_BY_CHECK:
      return { ...state, data: action.payload };
    case SET_QUALITY_CHECKS_TIMELINE_BY_CHECK_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case SET_QUALITY_RESULTS_FILTERS:
      const qualityResultsFilters = action.payload;
      const filters = {
        startedAtGte:
          qualityResultsFilters.startedAtGte || timelineInitialState.filters.startedAtGte,
        startedAtLt: qualityResultsFilters.startedAtLt || timelineInitialState.filters.startedAtLt,
        precision: state.filters.precision
      };
      return { ...state, filters };
    case CLEAR_QUALITY_RESULTS_FILTERS:
      return { ...state, filters: { ...timelineInitialState.filters } };
    default:
      return state;
  }
};

const qualityChecksGeneralCounts = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_QUALITY_DASHBOARDS_OVERALL:
      return action.payload;
    default:
      return state;
  }
};

const qualityChecksStats = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_QUALITY_CHECKS_STATS:
      return action.payload;
    default:
      return state;
  }
};

const qualityChecksTimelineOverall = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_QUALITY_CHECKS_TIMELINE_OVERALL:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  qualityChecksLastRun,
  qualityChecksTimelineByTestSuite,
  metricsVolumes,
  qualityChecksTimelineByCheck,
  homeChecksLastRun,
  overall: combineReducers({
    filters: filtersReducer({
      resource: 'QUALITY_DASHBOARD',
      filtersDefault: overallFiltersDefault
    }),
    qualityChecksGeneralCounts,
    qualityChecksStats,
    qualityChecksTimelineOverall
  })
});
