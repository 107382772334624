import { fetchAll, BlindataApi } from './ApiUtils';

export default class LogicalFieldsApi extends BlindataApi {
  getAllLogicalFields() {
    return fetchAll(this.getLogicalFields.bind(this));
  }

  getLogicalFields(params) {
    return this.get(`/api/v1/logicalfields`, params);
  }

  getLogicalField(logicalFieldUuid) {
    return this.get(`/api/v1/logicalfields/${logicalFieldUuid}`);
  }

  deleteLogicalField(logicalFieldUuid) {
    return this.delete(`/api/v1/logicalfields/${logicalFieldUuid}`);
  }

  searchLogicalFields(search, dataCategoryUuid, { page = 0, size } = {}) {
    return this.get(`/api/v1/logicalfields`, {
      search,
      page,
      size,
      dataCategoryUuid
    });
  }

  postLogicalField(logicalField) {
    return this.post(`/api/v1/logicalfields`, logicalField);
  }

  putLogicalField(logicalField) {
    return this.put(`/api/v1/logicalfields/${logicalField.uuid}`, logicalField);
  }

  getCompilationKPIs(params) {
    return this.get(`/api/v1/logicalfields/*/kpis/compilation`, params);
  }

  exportLogicalFields(params) {
    return this.download('/api/v1/logicalfields/*/export', {
      dataCategoryUuid: params.dataCategories.map((dc) => dc.uuid),
      includeProperties: 'false'
    });
  }
}
