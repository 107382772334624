import { connect } from 'react-redux';
import { fetchTasksPage } from 'actions/TasksActions';
import { withLoader } from '../../../../components/loader/Loader';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import React from 'react';
import {
  TableContainer,
  Table,
  TablePagination,
  TableRow,
  TableBody,
  TableHead,
  TableFooter
} from '@mui/material';
import TableCell from '../../../../components/tables/GenericTableCell';
import { compose } from 'redux';
import { ChipItem } from 'components/chiplist/ChipList';
import { useTranslation } from 'react-i18next';

const styles = {
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: (theme) => theme.spacing(2)
  },
  table: {
    minWidth: '750px'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
};

function TasksTableView({
  processing,
  currentPage,
  size = 'small',
  onPageSelection,
  page,
  rowsPerPage
}) {
  
  const { t } = useTranslation();

  return (
    <div style={styles.root}>
      <TableContainer>
        <Table
          sx={styles.table}
          aria-labelledby='tableTitle'
          size={size}
          aria-label='associated tasks table'>
          <TasksTableHead></TasksTableHead>
          <TableBody>
            {(currentPage.content || []).map((task) => (
              <TableRow key={task.uuid} hover>
                <TableCell>
                  <ChipItem
                    size='small'
                    label={task.name}
                    component={Link}
                    to={`/tasks/${task.uuid}`}
                    onClick={(event) => event.stopPropagation()}
                  />
                </TableCell>
                <TableCell>{_.truncate(task.description, { length: 125 })}</TableCell>
                <TableCell>
                  {(task.startDate && new Date(task.startDate).toLocaleDateString()) ||
                    t('commons.placeholders.undefined')}
                </TableCell>
                <TableCell>
                  {(task.endDate && new Date(task.endDate).toLocaleDateString()) ||
                    t('commons.placeholders.undefined')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 25]}
                style={{ overflowX: 'hidden' }}
                count={currentPage.totalElements || 0}
                rowsPerPage={rowsPerPage || 10}
                page={page}
                onPageChange={(event, page, processing) =>
                  onPageSelection({ page: page, size: currentPage.size, processing: [processing] })
                }
                onRowsPerPageChange={(event) => {
                  onPageSelection({
                    page: currentPage.number,
                    size: event.target.value,
                    processing: [processing]
                  });
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

const headCells = [
  { id: 'taskName', label: 'Task Name' },
  { id: 'description', label: 'Description' },
  { id: 'startDate', label: 'Start Date' },
  { id: 'endDate', label: 'End Date' }
];

function TasksTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.tasks.currentPage,
    page: state.tasks.currentPage.number,
    rowsPerPage: state.tasks.currentPage.size
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    onPageSelection: (params) => {
      dispatch(
        fetchTasksPage(params.page || 0, params.size || 10, { processings: [props.processing] })
      );
    }
  };
}

const TasksTable = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ onPageSelection, processing }) =>
      onPageSelection(0, 10, { processings: [processing] })
  })
)(TasksTableView);

export default TasksTable;
