import { buildApiCallAction } from 'actions/ActionUtils';
import { DELETE_ENGAGEMENT_HITS } from 'constants/ActionTypes';
import EngagementApi from 'api/EngagementApi';

const api = new EngagementApi();

export const sendEngagementHits = (engagementHits) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.sendEngagementHits(engagementHits),
    actionType: DELETE_ENGAGEMENT_HITS,
    payload: engagementHits
  });
