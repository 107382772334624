import { fetchDataProductsByName } from 'actions/DataProductsActions';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const DataProductItemAutoComplete = ({ value, onChange, ...props }) => {
  const [dataProducts, setDataProducts] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!value) {
      dispatch(fetchDataProductsByName({ search: '' })).then((response) =>
        setDataProducts(response?.content)
      );
    }
  }, [dispatch, setDataProducts, value]);

  return (
    <ItemAutoComplete
      itemName='Data Product'
      values={dataProducts}
      value={value}
      nameProperty={'displayName'}
      onSearch={(inputValue) => {
        dispatch(fetchDataProductsByName({ search: inputValue })).then((response) =>
          setDataProducts(response?.content)
        );
      }}
      onChange={(newValue) => onChange(newValue)}
      {...props}
    />
  );
};

export default DataProductItemAutoComplete;
