import React, { useState } from 'react';
import { connect } from 'react-redux';

import { createIssue } from 'actions/IssuesActions';
import withPermissions from 'components/permissions/withPermissions';

import AddFAB from 'components/fab/AddFAB';
import { compose } from 'redux';
import Permissions from 'constants/Permissions';
import IssueModal from '../detail/IssueModal';

function IssueFAB({ currentUser, createIssue }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      {open && (
        <IssueModal
          open={open}
          title={'Create an Issue'}
          reporter={currentUser}
          handleSubmit={createIssue}
          handleCancel={() => setOpen(false)}
        />
      )}
    </>
  );
}

const mapStateToProps = (state, props) => ({
  currentUser: {
    uuid: state.settings.profile.uuid,
    username: state.settings.profile.username,
    displayName: state.settings.profile.displayName
  }
});

export default compose(
  withPermissions({ hasPermissions: [Permissions.CAMPAIGNS_EDITOR] }),
  connect(mapStateToProps, { createIssue })
)(IssueFAB);
