import React, { useMemo, useReducer } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AgentConnectionItemAutoComplete from 'pages/settings/agents/commons/AgentConnectionItemAutoComplete';
import TimeZonesMenuItem from 'components/timezone/TimeZonesMenuItem';
import CronExpressionTextField from 'components/CronExpressionTextField';
import CrawlingRunButton from './CrawlingRunButton';
import _ from 'lodash';

const defaultsConfigsByStrategy = (system) => ({
  JDBC_DRIVER: {
    strategy: 'JDBC_DRIVER',
    //connectionName: null,
    system: system.name,
    systemUuid: system.uuid,
    queryAllTables:
      "select table_schema, table_name, comment as description, null as data_set, table_type, false as is_hidden \nfrom jdbc_metadata_tables \nwhere table_type in ('TABLE','VIEW')",
    queryAllColumns:
      'select table_schema, table_name, column_name, data_type, ordinal_position \nfrom jdbc_metadata_columns',
    queryAllPhysicalConstraints: `select 
      catalog , 
      name , 
      schema , 
      type , 
      clause , 
      update_rule , 
      delete_rule , 
      access_method , 
      list_order , 
      target_physical_entity_schema , 
      target_physical_entity_name , 
      referenced_physical_entity_schema , 
      referenced_physical_entity_name , 
      target_physical_field_name , 
      referenced_physical_field_name
    from jdbc_metadata_constraints;`,
    outputOptions: {
      dryRun: false,
      //targetConnectionName: null,
      defaultHidden: false
    }
  },
  SQL: {
    strategy: 'SQL',
    //connectionName: null,
    system: system.name,
    systemUuid: system.uuid,
    queryAllTables:
      "select table_schema, table_name, table_type \nfrom information_schema.tables \nwhere table_schema not in ('INFORMATION_SCHEMA')",
    queryAllColumns:
      "select table_schema, table_name, column_name, data_type, ordinal_position \nfrom information_schema.columns \nwhere table_schema not in ('INFORMATION_SCHEMA')",
    queryAllRoutines: 'select * from information_schema.routines;',
    queryAllDataFlows: null,
    queryAllPhysicalConstraints: `select  distinct
    KCU.constraint_name as name,
    KCU.constraint_schema as schema,
    'PRIMARY_KEY' as type,
    KCU.table_schema as target_physical_entity_schema,
    KCU.table_name as target_physical_entity_name,
    KCU.column_name as target_physical_field_name,
    KCU.ordinal_position as list_order,
    NULL as referenced_physical_entity_schema,
    NULL as referenced_physical_entity_name,
    NULL as referenced_physical_field_name,
    NULL as clause,
    null as delete_rule,
    null as update_rule,
    null as access_method
  from information_schema.table_constraints AS TC
  inner join information_schema.key_column_usage AS KCU
  on KCU.constraint_catalog = TC.constraint_catalog
  and KCU.constraint_schema = TC.constraint_schema
  and KCU.table_name = TC.table_name
  and KCU.constraint_name = TC.constraint_name
  where TC.constraint_type = 'PRIMARY KEY'
  union
  select  distinct
    tc.constraint_name as name,
    tc.constraint_schema as schema,
    'FOREIGN_KEY' as type,
    tc.table_schema as target_physical_entity_schema,
    tc.table_name as target_physical_entity_name,
    kcu.column_name as target_physical_field_name,
    kcu.ordinal_position as list_order,
    ccu.table_schema as referenced_physical_entity_schema,
    ccu.table_name as referenced_physical_entity_name,
    ccu.column_name as referenced_physical_field_name,
    NULL as clause,
    rc.delete_rule as delete_rule,
    rc.update_rule as update_rule,
    null as access_method
  from information_schema.table_constraints as tc 
  join information_schema.key_column_usage as kcu
  on tc.constraint_name = kcu.constraint_name
  and tc.table_schema = kcu.table_schema
  join information_schema.constraint_column_usage as ccu
  on ccu.constraint_name = tc.constraint_name
  and ccu.table_schema = tc.table_schema
  join information_schema.referential_constraints rc
  on rc.constraint_name = kcu.constraint_name
  where tc.constraint_type = 'FOREIGN KEY'
  union
  select  distinct
    KCU.constraint_name as name,
    KCU.constraint_schema as schema,
    'UNIQUE' as type,
    KCU.table_schema as target_physical_entity_schema,
    KCU.table_name as target_physical_entity_name,
    KCU.column_name as target_physical_field_name,
    KCU.ordinal_position as list_order,
    NULL as referenced_physical_entity_schema,
    NULL as referenced_physical_entity_name,
    NULL as referenced_physical_field_name,
    NULL as clause,
    null as delete_rule,
    null as update_rule,
    null as access_method
  from information_schema.table_constraints AS TC
  inner join information_schema.key_column_usage AS KCU
  on KCU.constraint_catalog = TC.constraint_catalog
  and KCU.constraint_schema = TC.constraint_schema
  and KCU.table_name = TC.table_name
  and KCU.constraint_name = TC.constraint_name
  where TC.constraint_type = 'UNIQUE'
  union
  select  distinct
    i.relname as name,
    ixs.schemaname  as schema,
    'INDEX' as type,
    ixs.schemaname  as target_physical_entity_schema,
    t.relname as target_physical_entity_name,
    a.attname as target_physical_field_name,
    a.attnum  as list_order,
    NULL as referenced_physical_entity_schema,
    NULL as referenced_physical_entity_name,
    NULL as referenced_physical_field_name,
    ixs.indexdef as clause,
    null as delete_rule,
    null as update_rule,
    null as access_method
  from
    pg_class t,
    pg_class i,
    pg_index ix,
    pg_indexes ixs,
    pg_attribute a
  where	i.relname = ixs.indexname
  and t.oid = ix.indrelid
  and i.oid = ix.indexrelid
  and a.attrelid = t.oid
  and a.attnum = ANY(ix.indkey)
  ;`,
    outputOptions: {
      dryRun: false,
      //targetConnectionName: null,
      defaultHidden: false
    }
  }
});

const defaultEditorState = (system) => ({
  name: `${system.name} - crawling`,
  externalIdentifier: system.uuid,
  timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  cronExpression: null,
  scheduled: false,
  type: 'CRAWLING',
  jobDetails: {
    type: 'CRAWLING',
    configs: defaultsConfigsByStrategy(system)['JDBC_DRIVER']
  }
});

function CrawlingModal({ schedule, system, agent, open, onSubmit, onCancel }) {
  let { t } = useTranslation();
  const defaultState = useMemo(() => defaultEditorState(system), [system]);
  const [editor, setEditor] = useReducer(
    (state, action) => ({ ...state, ...action }),
    schedule ? alignSchedule(schedule, system) : defaultState
  );
  return (
    <Dialog open={open}>
      <DialogTitle>Crawling Modal</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          label='Schedule Name'
          value={editor.name || ''}
          onChange={(event) => setEditor({ name: event.target.value })}
        />
        <CronExpressionTextField
          value={editor.cronExpression || ''}
          onChange={(cronExpression) => setEditor({ cronExpression })}
        />
        <TimeZonesMenuItem
          value={editor.timezoneId}
          onChange={(timezoneId) => setEditor({ timezoneId })}
        />

        <FormControlLabel
          control={
            <Checkbox
              size='small'
              checked={editor.scheduled}
              onChange={(event) => setEditor({ ...editor, scheduled: event.target.checked })}
              name='enabled'
              color='primary'
            />
          }
          label='Job Scheduled'
        />
        <CrawlingJobConfigsEditor
          agent={agent}
          system={system}
          configs={editor.jobDetails.configs}
          onChange={(configs) => setEditor({ jobDetails: { ...editor.jobDetails, configs } })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
        <CrawlingRunButton jobDefinition={editor} agent={agent} onlyTest />
        <Button onClick={() => onSubmit(editor)}>{t('commons.actions.submit')}</Button>
      </DialogActions>
    </Dialog>
  );
}

/* This should align properties that where not required in previous versions.
 * system uuid is for performance and decoupling from name.
 * system name is for retrocompatibility and when name changes in old agent versions
 */
function alignSchedule(schedule, system) {
  const newSchedule = { ...schedule };
  _.set(newSchedule, 'jobDetails.configs.system', system.name);
  _.set(newSchedule, 'jobDetails.configs.systemUuid', system.uuid);
  return newSchedule;
}

function CrawlingJobConfigsEditor({ configs, agent, onChange, system }) {
  return (
    <>
      <Typography variant='subtitle1'>Settings</Typography>
      <Select
        variant='standard'
        value={configs.strategy || 'JDBC_DRIVER'}
        onChange={(event) =>
          onChange({ ...configs, ...defaultsConfigsByStrategy(system)[event.target.value] })
        }>
        <MenuItem value={'JDBC_DRIVER'} children={'JDBC_DRIVER'} />
        <MenuItem value={'SQL'} children={'SQL'} />
      </Select>
      <AgentConnectionItemAutoComplete
        fullWidth
        margin='dense'
        value={configs?.connectionName || ''}
        onChange={(value) => onChange({ ...configs, connectionName: value })}
        agent={agent}
      />
      <TextField
        variant='standard'
        fullWidth
        margin='dense'
        multiline
        maxRows={4}
        label='Tables Query'
        value={configs.queryAllTables || ''}
        onChange={(event) => onChange({ ...configs, queryAllTables: event.target.value })}
      />
      <TextField
        variant='standard'
        fullWidth
        margin='dense'
        multiline
        maxRows={4}
        label='Columns Query'
        value={configs.queryAllColumns || ''}
        onChange={(event) => onChange({ ...configs, queryAllColumns: event.target.value })}
      />
      <TextField
        variant='standard'
        fullWidth
        margin='dense'
        multiline
        maxRows={4}
        label='Physical Constraints Query'
        value={configs.queryAllPhysicalConstraints || ''}
        onChange={(event) =>
          onChange({ ...configs, queryAllPhysicalConstraints: event.target.value })
        }
      />
      {configs.strategy && configs.strategy === 'SQL' && (
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          multiline
          maxRows={4}
          label='Routines Query'
          value={configs.queryAllRoutines || ''}
          onChange={(event) => onChange({ ...configs, queryAllRoutines: event.target.value })}
        />
      )}
      {configs.strategy && configs.strategy === 'SQL' && (
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          multiline
          maxRows={4}
          label='Data Flows Query'
          value={configs.queryAllDataFlows || ''}
          onChange={(event) => onChange({ ...configs, queryAllDataFlows: event.target.value })}
        />
      )}
      <OutputOptionsEditor
        {...{
          agent,
          outputOptions: configs.outputOptions,
          onChange: (outputOptions) => onChange({ ...configs, outputOptions })
        }}></OutputOptionsEditor>
    </>
  );
}

function OutputOptionsEditor({ outputOptions, agent, onChange }) {
  return (
    <>
      <Typography variant='subtitle1'>Output</Typography>
      <AgentConnectionItemAutoComplete
        fullWidth
        margin='dense'
        connectionTypes={['BLINDATA']}
        value={outputOptions?.targetConnectionName || ''}
        onChange={(value) => onChange({ ...outputOptions, targetConnectionName: value })}
        agent={agent}
      />
      <FormControlLabel
        control={
          <Checkbox
            size='small'
            checked={outputOptions.defaultHidden}
            onChange={(event) =>
              onChange({ ...outputOptions, defaultHidden: event.target.checked })
            }
            name='enabled'
            color='primary'
          />
        }
        label='Default Hidden'
      />
    </>
  );
}

export default CrawlingModal;
