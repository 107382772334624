import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { MenuItem } from '@mui/material';
import { nodeLabels } from '../../../constants/GraphLabels';
import DataFlowEditorDialog from '../../dataflows/editor/DataFlowEditorDialog';

const physicalModelLabels = [
  nodeLabels.SYSTEM,
  nodeLabels.PHYSICAL_ENTITY,
  nodeLabels.PHYSICAL_FIELD
];

var graphCallback = null;

function GraphMenuAddDataFlow({ graphHandler }) {
  const dataFlowsPerspective = useSelector(selectDataFlowsPerspective());
  const [enabled, setEnabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  function addEdgeCallBack({ from, to }, callback) {
    let edgeTypes = [_.get(from, 'labels[0]'), _.get(to, 'labels[0]')];
    if (_(edgeTypes).every((type) => physicalModelLabels.includes(type))) {
      graphCallback = callback;
      setSelectedData({ from, to });
      setOpen(true);
    }
  }

  function toogleAddEdgeMode() {
    setEnabled(!enabled);
    graphCallback = null;
    setSelectedData(null);
    graphHandler.toggleAddEdge(!enabled, addEdgeCallBack);
  }

  if (!dataFlowsPerspective) return null;

  return (
    <>
      <MenuItem onClick={toogleAddEdgeMode}>
        {enabled ? 'Disable Add DataFlow' : 'Enable Add DataFlow'}
      </MenuItem>
      <DataFlowEditorDialog
        open={open}
        editor={createDataFlowEditorFromSelectedData(selectedData)}
        onClose={(result) => {
          if (result) {
            graphCallback({
              from: selectedData.from.id,
              to: selectedData.to.id,
              type: 'REL_DATA_FLOW',
              dashes: true,
              data: result
            });
            setEnabled(false);
          }
          setOpen(false);
          setSelectedData(null);
          graphCallback = null;
        }}
      />
    </>
  );
}

function createDataFlowEditorFromSelectedData(selectedData) {
  if (!selectedData || !selectedData.from || !selectedData.to) return;
  let editor = {};
  let fromLabel = _.get(selectedData.from, 'labels[0]');
  editor[`from${editorCreationConfig[fromLabel]}`] = {
    uuid: selectedData.from.id
  };
  let toLabel = _.get(selectedData.to, 'labels[0]');
  editor[`to${editorCreationConfig[toLabel]}`] = { uuid: selectedData.to.id };
  return editor;
}

const editorCreationConfig = {
  [nodeLabels.SYSTEM]: 'System',
  [nodeLabels.PHYSICAL_ENTITY]: 'PhysicalEntity',
  [nodeLabels.PHYSICAL_FIELD]: 'PhysicalField'
};

function selectDataFlowsPerspective() {
  return (state) => _.get(state, 'graph.filters.dataFlowsPerspective');
}

export default GraphMenuAddDataFlow;
