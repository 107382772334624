import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';

import { createSchedule } from 'actions/onagent/AgentScheduleActions';

import { Button } from '@mui/material';

import QueryBucketScheduleModal from './QueryBucketScheduleModal';

function QueryBucketScheduleAddButton({ agent, queryBucket, createSchedule }) {
  let [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>Add schedule</Button>
      <QueryBucketScheduleModal
        open={open}
        agent={agent}
        queryBucket={queryBucket}
        onCancel={() => setOpen(false)}
        onSubmit={(schedule) => {
          setOpen(false);
          createSchedule(agent, schedule);
        }}
      />
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.QUERY_PARSER_EDITOR, Permissions.AGENTS_EDITOR] }),
  connect(null, { createSchedule })
)(QueryBucketScheduleAddButton);
