import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
import CardDescription from 'components/carddescription/CardDescription';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { deleteDataProduct, modifyDataProduct } from 'actions/DataProductsActions';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import { t } from 'i18next';
import { DataProductPortList } from '../list/DataProductPortList';
import DataProductModal from '../commons/DataProductModal';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import { formatDate } from 'pages/history/commons/HistoryRecordTable';
import CustomPropertiesView from 'components/additional_properties/CustomPropertiesView';
import ResourceTypes from 'constants/ResourceTypes';
import AuthorizeTeamStewardship from 'components/permissions/AuthorizeTeamStewardship';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import Permissions from 'constants/Permissions';

import _ from 'lodash';
import { DataProductPortEntityTypes } from 'constants/DataProductPortEntityTypes';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

export const DataProductDetailCard = ({ dataProduct }) => {
  const [dataProductModalOpen, setDataProductModalOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const entityTypes = _.union(
    ['inputPorts', 'outputPorts'],
    Object.keys(_.keyBy(dataProduct?.ports, 'entityType'))
  );
  return (
    <>
      <Card>
        <CardHeader title={dataProduct.displayName} subheader={dataProduct.domain} />
        <CardContent>
          <CardDescription>{dataProduct.description}</CardDescription>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12} xs={12}>
              <KVDefinitionList
                header='General'
                item={{
                  Domain: dataProduct?.domain,
                  Display: dataProduct?.displayName,
                  Name: dataProduct?.name,
                  Identifier: dataProduct?.identifier
                }}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <KVDefinitionList
                header='Versioning'
                item={{
                  Version: dataProduct?.version,
                  'Created At': formatDate(dataProduct?.createdAt),
                  'Updated At': formatDate(dataProduct?.updatedAt)
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <CustomPropertiesView
                additionalProperties={dataProduct.additionalProperties}
                resourceType={ResourceTypes.DATA_PRODUCT}
              />
            </Grid>
            {entityTypes.map((key) => (
              <Grid key={key} item lg={6} md={12} xs={12}>
                <DataProductPortList
                  title={DataProductPortEntityTypes[key] || key}
                  content={(dataProduct.ports || []).filter((port) => port.entityType === key)}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
        <CardActions>
          <AuthorizeTeamStewardship
            resourceType={ResourceTypes.DATA_PRODUCT}
            resourceIdentifier={dataProduct.uuid}
            hasPermissions={[Permissions.DATA_PRODUCTS_EDITOR]}
            teamCode={dataProduct.teamCode}
            teamAccessLevels={[TeamAccessLevels.WRITE]}>
            <Button onClick={() => setDataProductModalOpen(true)}>
              {t('commons.actions.modify')}
            </Button>
            <ConfirmButton
              message={`Do you want to delete this data product?`}
              onClick={() =>
                dispatch(deleteDataProduct(dataProduct)).then((_res) =>
                  history.push('/dataproducts/list')
                )
              }>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </AuthorizeTeamStewardship>
          <div style={{ marginLeft: 'auto' }}>
            <WebActionsButton resource={dataProduct} page={TargetPages.DATA_PRODUCT_DETAIL_PAGE} />
          </div>
        </CardActions>
      </Card>
      {dataProductModalOpen && (
        <DataProductModal
          open={dataProductModalOpen}
          onSubmit={(dataProduct) =>
            dispatch(modifyDataProduct(dataProduct)).then(
              (response) => response && setDataProductModalOpen(false)
            )
          }
          title={'Modify a Data Product'}
          dataProduct={dataProduct}
          onClose={() => setDataProductModalOpen(false)}
        />
      )}
    </>
  );
};
