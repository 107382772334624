import React, { useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import { DialogTitle, DialogActions, DialogContent } from '@mui/material';

function LogicalFieldsEditModal({
  t,
  fullScreen,
  open,
  dataCategory,
  logicalFields,
  createLogicalField,
  deleteLogicalField,
  handleClose
}) {
  let [state, setState] = useReducer((state, action) => ({ ...state, ...action }), {
    name: null,
    description: null
  });
  const nameTextInputRef = useRef(null);

  function handleAdd(event) {
    event.preventDefault();
    event.stopPropagation();
    createLogicalField({
      name: state.name,
      description: state.description,
      dataCategory: dataCategory
    }).then(() =>
      setState({
        name: null,
        description: null
      })
    );
    nameTextInputRef.current.focus();
  }

  return (
    <Dialog fullScreen={fullScreen} open={open} fullWidth>
      <DialogTitle>{t('data_categories.logicalFields.editorHeader')}</DialogTitle>
      <DialogContent>
        <ChipWrapper>
          {logicalFields.map((field) => (
            <ChipItem
              key={field.uuid}
              id={field.uuid}
              label={field.name}
              tooltip={field.description}
              onRequestDelete={() => deleteLogicalField(field)}
            />
          ))}
        </ChipWrapper>
        <form style={{ paddingTop: 8 }}>
          <TextField
            variant='standard'
            fullWidth
            inputRef={nameTextInputRef}
            placeholder={t('data_categories.logicalFields.fields.nameHint')}
            label={t('data_categories.logicalFields.fields.name')}
            value={state.name || ''}
            onChange={(event) => setState({ name: event.target.value })}
          />
          <TextField
            variant='standard'
            fullWidth
            multiline
            placeholder={t('data_categories.logicalFields.fields.descriptionHint')}
            label={t('data_categories.logicalFields.fields.description')}
            value={state.description || ''}
            onChange={(event) => setState({ description: event.target.value })}
          />
          <input type='submit' hidden onClick={handleAdd} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.done')} onClick={handleClose} />
        <Button
          children={t('commons.actions.add')}
          variant='contained'
          color='primary'
          onClick={handleAdd}
        />
      </DialogActions>
    </Dialog>
  );
}

LogicalFieldsEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  logicalFields: PropTypes.array.isRequired,
  dataCategory: PropTypes.object.isRequired,
  createLogicalField: PropTypes.func.isRequired,
  deleteLogicalField: PropTypes.func.isRequired
};

export default _.flow([withTranslation()])(LogicalFieldsEditModal);
