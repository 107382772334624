import { buildApiCallAction } from './ActionUtils';
import {
  FETCH_NOTIFICATIONS_PAGE,
  FETCH_NOTIFICATION,
  CREATE_NOTIFICATION,
  MODIFY_NOTIFICATION,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_UNREAD_COUNT
} from '../constants/ActionTypes';
import NotificationsApi from '../api/NotificationsApi';

const notificationsApi = new NotificationsApi();

export const fetchNotificationsPage = (params) =>
  buildApiCallAction({
    api: notificationsApi,
    apiFunction: (api) => api.getNotifications(params),
    actionType: FETCH_NOTIFICATIONS_PAGE,
    payload: (response) => ({
      ...response,
      resourceType: params.resourceType,
      resourceIdentifier: params.resourceIdentifier
    })
  });

export const fetchNotification = (sequenceId) =>
  buildApiCallAction({
    api: notificationsApi,
    apiFunction: (api) => api.getNotification(sequenceId),
    actionType: FETCH_NOTIFICATION
  });

export const createNotification = (templateProcessing) =>
  buildApiCallAction({
    api: notificationsApi,
    apiFunction: (api) => api.postNotification(templateProcessing),
    actionType: CREATE_NOTIFICATION
  });

export const patchNotification = (templateProcessing) =>
  buildApiCallAction({
    api: notificationsApi,
    apiFunction: (api) => api.patchNotification(templateProcessing),
    actionType: MODIFY_NOTIFICATION
  });

export const markAllNotificationsAsRead = () =>
  buildApiCallAction({
    api: notificationsApi,
    apiFunction: (api) => api.markAllNotificationsAsRead(),
    actionType: MARK_ALL_NOTIFICATIONS_AS_READ
  });

export const deleteNotification = (sequenceId) =>
  buildApiCallAction({
    api: notificationsApi,
    apiFunction: (api) => api.deleteNotification(sequenceId),
    actionType: DELETE_NOTIFICATION,
    payload: { sequenceId }
  });

export const deleteAllNotifications = () =>
  buildApiCallAction({
    api: notificationsApi,
    apiFunction: (api) => api.deleteAllNotifications(),
    actionType: DELETE_ALL_NOTIFICATIONS
  })

export const fetchUnreadCount = () =>
  buildApiCallAction({
    api: notificationsApi,
    apiFunction: (api) => api.getUnreadCount(),
    actionType: FETCH_NOTIFICATIONS_UNREAD_COUNT
  });
