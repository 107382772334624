import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import { Grid, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ConfirmMenuItem from 'components/confirmbutton/ConfirmMenuItem';
import WorkspaceModal from './WorkspaceModal';
import LaunchIcon from '@mui/icons-material/Launch';
import { useHistory } from 'react-router';
function WorkspaceItemCardActions({ workspace, deleteWorkspace, modifyWorkspace, t }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const history = useHistory();
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  let isOpen = Boolean(anchorEl);

  return (
    <>
      <Grid container direction='row' alignItems='center'>
        <Grid item>
          <IconButton
            size='small'
            color='primary'
            aria-haspopup='true'
            onClick={() => history.push(`/social/favorites/workspaces/${workspace.uuid}`)}>
            <LaunchIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton size='small' aria-haspopup='true' onClick={handleOpen}>
            <MoreHorizIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Menu
        id='physical-entities-table-actions'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {isOpen && (
          <MenuItem onClick={() => setOpenEdit(true)}>{t('commons.actions.modify')}</MenuItem>
        )}
        {isOpen && (
          <ConfirmMenuItem
            message={'Do you really want to delete this workspace?'}
            onClose={handleClose}
            onClick={() => {
              deleteWorkspace(workspace);
              setAnchorEl(null);
            }}>
            {t('commons.actions.delete')}
          </ConfirmMenuItem>
        )}
      </Menu>
      {openEdit && (
        <WorkspaceModal
          title='Edit Worskpace'
          workspace={workspace}
          open={openEdit}
          onSubmit={(workspace) => {
            modifyWorkspace(workspace);
            setOpenEdit(false);
            setAnchorEl(null);
          }}
          onCancel={() => setOpenEdit(false)}></WorkspaceModal>
      )}
    </>
  );
}

export default withTranslation()(WorkspaceItemCardActions);
