import { BlindataApi } from './ApiUtils';

export default class NotaryApi extends BlindataApi {
  getRecords({ resourceType, resourceIdentifier }, page) {
    return this.get(`/api/v1/notary/records`, { resourceType, resourceIdentifier, page });
  }

  getProofs(recordUuid) {
    return this.get(`/api/v1/notary/records/${recordUuid}/proofs`);
  }
}
