import React from 'react';
import { connect } from 'react-redux';
import * as DataActorsActions from '../../../actions/DataActorsActions';
import { fetchDashboardTypeCounterIfAllowedLargeRequest } from 'actions/IssueMngtDashboardsActions';
import DataActorsList from './DataActorsList';
import PaginatedPanel from '../../../components/pagination/PaginatedPanel';
import DataActorsFinderButton from './DataActorsFinderButton';
import { Typography, Grid } from '@mui/material';
import DataActorsFiltersButton from './DataActorsFiltersButton';
import DataActorExportButton from '../commons/DataActorExportButton';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import ResourceTypes from 'constants/ResourceTypes';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const DataActorsContainer = (props) => (
  <React.Fragment>
    <Breadcrumbs elements={[{ text: 'Data Actors', to: '/data_actors' }]} />
    <Tracking
      info={{
        pageTitle: `Data Actors`,
        category: EngagementCategories.DATA_ACTOR,
        action: EngagementActions.LIST
      }}
    />

    <Grid container justifyContent='flex-start'>
      <Grid item>
        <Typography variant='h4'>Data Actors</Typography>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}></Grid>
      <Grid item>
        <DataActorsFinderButton history={props.history} />
      </Grid>
      <Grid item>
        <FavoritesWidget
          itemIdentifier={'page:dataactors-list'}
          pageTitle={'Data Actors'}
          iconSize='small'
        />
      </Grid>
      <Grid item>
        <DataActorExportButton></DataActorExportButton>
      </Grid>
      <Grid item>
        <DataActorsFiltersButton onRefresh={() => props.fetchDataActorsPageWithFilters(0)} />
      </Grid>
      <Grid item>
        <WebActionsButton page={TargetPages.DATA_ACTOR_LIST_PAGE} />
      </Grid>
    </Grid>

    <PaginatedPanel
      currentPage={props.currentPage}
      onPageSelection={(page) =>
        props
          .fetchDataActorsPageWithFilters(page)
          .then(
            (response) =>
              response &&
              response.content.length > 0 &&
              props.fetchDashboardTypeCounterIfAllowedLargeRequest(
                response.content,
                25,
                ResourceTypes.DATA_ACTOR
              )
          )
      }
      autoload>
      <DataActorsList {...props} />
    </PaginatedPanel>
  </React.Fragment>
);

const mapStateToProps = (state) => ({
  currentPage: state.data_actors.currentPage,
  dataActors: state.data_actors.currentPage.content
});

export default connect(mapStateToProps, {
  ...DataActorsActions,
  fetchDashboardTypeCounterIfAllowedLargeRequest
})(DataActorsContainer);
