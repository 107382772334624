import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchDataClasses } from 'actions/DataCategoriesActions';
import AutoComplete from 'components/autocomplete/AutoComplete';
import _ from 'lodash';

function DataCategoryClassAutocomplete({ fullWidth, margin, dataClass, onChange }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataClasses, setDataClasses] = useState([]);
  const dataClassSuggestions = useMemo(
    () =>
      t('suggestion:dataCategoryClasses', {
        returnObjects: true
      }),
    [t]
  );

  useEffect(() => {
    dispatch(fetchDataClasses({ search: '' })).then(
      (response) => response && setDataClasses(response.content)
    );
  }, [dispatch]);

  return (
    <AutoComplete
      fullWidth={fullWidth}
      margin={margin}
      label={t('data_categories.fields.class')}
      placeholder={t('data_categories.fields.classHint')}
      value={dataClass || ''}
      dataSource={_.sortBy([...new Set(dataClassSuggestions.concat(dataClasses))])}
      onUpdateInput={onChange}
      onNewRequest={onChange}
    />
  );
}

export default DataCategoryClassAutocomplete;
