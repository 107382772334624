import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, CardActions } from '@mui/material';
import Subheader from 'pages/systems/physical_entities/detail/PhysicalEntityDetailsCard/header/Subheader';
import Decorators from 'pages/systems/physical_entities/detail/PhysicalEntityDetailsCard/header/Decorators';

import GeneralSection from '../detail/PhysicalEntityDetailsCard/GeneralSection';

function PhysicalEntityCardView({ physicalEntity }) {
  if (physicalEntity) {
    return (
      <Card>
        <CardHeader
          title={
            physicalEntity.schema
              ? `${physicalEntity.schema}.${physicalEntity.name}`
              : physicalEntity.name
          }
          subheader={<Subheader system={physicalEntity?.system} type={physicalEntity.tableType} />}
          action={<Decorators dataset={physicalEntity.dataSet} hidden={physicalEntity.hidden} />}
        />
        <CardContent>
          <GeneralSection physicalEntity={physicalEntity}></GeneralSection>
        </CardContent>
      </Card>
    );
  } else {
    return null;
  }
}

PhysicalEntityCardView.propTypes = {
  logicalField: PropTypes.object,
  actions: PropTypes.instanceOf(CardActions)
};

export default PhysicalEntityCardView;
