import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import SettingsNavbar from 'pages/settings/SettingsNavbar';
import ProfilePage from './profile/ProfilePage';
import UsersRoutes from './users/UsersRoutes';
import TeamsRoutes from './teams/TeamsRoutes';
import AgentsRoutes from 'pages/settings/agents/AgentsRoutes';
import ImportPage from './importexport/import/ImportPage';
import CustomPropertiesPage from './custom_properties/CustomPropertiesPage';
import WebActionsRoutes from './webactions/WebActionsRoutes';
import WebHooksRoutes from './webhooks/WebHooksRoutes';

const SettingsRoutes = (props) => (
  <>
    <SettingsNavbar />
    <Switch>
      <Route exact path={`${props.match.url}/profile`} component={ProfilePage} />
      <Route path={`${props.match.url}/users`} component={UsersRoutes} />
      <Route path={`${props.match.url}/teams`} component={TeamsRoutes} />
      <Route path={`${props.match.url}/agents`} component={AgentsRoutes} />
      <Route exact path={`${props.match.url}/import`} component={ImportPage} />
      <Route
        exact
        path={`${props.match.url}/custom_properties_definitions`}
        component={CustomPropertiesPage}
      />
      <Route path={`${props.match.url}/web-actions`} component={WebActionsRoutes} />
      <Route path={`${props.match.url}/web-hooks`} component={WebHooksRoutes} />
      <Redirect to={`${props.match.url}/profile`} />
    </Switch>
  </>
);

export default SettingsRoutes;
