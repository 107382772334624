import { buildApiCallAction } from 'actions/ActionUtils';
import ContractTemplateAttachmentsApi from 'api/contracts/ContractTemplateAttachmentsApi';
import {
  FETCH_CONTRACT_TEMPLATE_ATTACHMENTS_PAGE,
  FETCH_CONTRACT_TEMPLATE_ATTACHMENT,
  CREATE_CONTRACT_TEMPLATE_ATTACHMENT,
  MODIFY_CONTRACT_TEMPLATE_ATTACHMENT,
  DELETE_CONTRACT_TEMPLATE_ATTACHMENT
} from 'constants/ActionTypes';

const api = new ContractTemplateAttachmentsApi();

export const uploadContractTemplateAttachment = (templateUuid, attachment, params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.uploadContractTemplateAttachment(templateUuid, attachment, params),
    actionType: MODIFY_CONTRACT_TEMPLATE_ATTACHMENT
  });
export const downloadContractTemplateAttachment = (templateUuid, attachment) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.downloadContractTemplateAttachment(templateUuid, attachment),
    actionType: 'DOWNLOAD_CONTRACT_TEMPLATE_ATTACHMENTS'
  });

export const fetchContractTemplateAttachment = (templateUuid, uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getContractTemplateAttachment(uuid),
    actionType: FETCH_CONTRACT_TEMPLATE_ATTACHMENT
  });

export const fetchContractTemplateAttachmentsPage = (templateUuid, params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getContractTemplateAttachmentsPage(templateUuid, params),
    actionType: FETCH_CONTRACT_TEMPLATE_ATTACHMENTS_PAGE
  });

export const createContractTemplateAttachment = (templateUuid, attachment) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postContractTemplateAttachment(templateUuid, attachment),
    actionType: CREATE_CONTRACT_TEMPLATE_ATTACHMENT
  });

export const modifyContractTemplateAttachment = (templateUuid, attachment) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putContractTemplateAttachment(templateUuid, attachment),
    actionType: MODIFY_CONTRACT_TEMPLATE_ATTACHMENT
  });

export const deleteContractTemplateAttachment = (templateUuid, attachment) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteContractTemplateAttachment(templateUuid, attachment),
    actionType: DELETE_CONTRACT_TEMPLATE_ATTACHMENT,
    payload: attachment
  });
