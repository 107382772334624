import * as TechnologyIcons from 'icons/systemTechnologies/index';

export const SystemTechnologyTypes = [
  {
    key: 'ApacheCalcite',
    label: 'Apache Calcite',
    iconShort: TechnologyIcons.ApacheCalcite,
    iconLong: TechnologyIcons.ApacheCalciteLong,
    iconDefault: TechnologyIcons.ApacheCalcite
  },
  {
    key: 'ApacheDrill',
    label: 'Apache Drill',
    iconShort: TechnologyIcons.ApacheDrill,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.ApacheDrill
  },
  {
    key: 'ApacheFeather',
    label: 'Apache Feather',
    iconShort: TechnologyIcons.ApacheFeather,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.ApacheFeather
  },
  {
    key: 'ApacheHive',
    label: 'Apache Hive',
    iconShort: TechnologyIcons.ApacheHive,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.ApacheHive
  },
  {
    key: 'ApacheIgnite',
    label: 'Apache Ignite',
    iconShort: (props) => null,
    iconLong: TechnologyIcons.ApacheIgniteLong,
    iconDefault: TechnologyIcons.ApacheIgniteLong
  },
  {
    key: 'ApacheImpala',
    label: 'Apache Impala',
    iconShort: TechnologyIcons.ApacheImpala,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.ApacheImpala
  },
  {
    key: 'ApacheKyuubi',
    label: 'Apache Kyuubi',
    iconShort: (props) => null,
    iconLong: TechnologyIcons.ApacheKyuubiLong,
    iconDefault: TechnologyIcons.ApacheKyuubiLong
  },
  {
    key: 'ApachePhoenix',
    label: 'Apache Phoenix',
    iconShort: (props) => null,
    iconLong: TechnologyIcons.ApachePhoenixLong,
    iconDefault: TechnologyIcons.ApachePhoenixLong
  },
  {
    key: 'AWSAthena',
    label: 'AWS Athena',
    iconShort: TechnologyIcons.AwsAthena,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.AwsAthena
  },
  {
    key: 'AWSElasticSearch',
    label: 'AWS Elasticsearch',
    iconShort: TechnologyIcons.AwsElasticSearch,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.AwsElasticSearch
  },
  {
    key: 'AWSRedshift',
    label: 'AWS Redshift',
    iconShort: TechnologyIcons.AwsRedshift,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.AwsRedshift
  },
  {
    key: 'AWS',
    label: 'Amazon Web Service',
    iconShort: TechnologyIcons.Aws,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Aws
  },
  {
    key: 'Cockroach',
    label: 'Cockroach',
    iconShort: TechnologyIcons.Cockroach,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Cockroach
  },
  {
    key: 'CSVFile',
    label: 'CSV File',
    iconShort: TechnologyIcons.CsvFile,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.CsvFile
  },
  {
    key: 'Cubrid',
    label: 'Cubrid',
    iconShort: (props) => null,
    iconLong: TechnologyIcons.CubridLong,
    iconDefault: TechnologyIcons.CubridLong
  },
  {
    key: 'Databricks',
    label: 'Databricks',
    iconShort: TechnologyIcons.Databricks,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Databricks
  },
  {
    key: 'Denodo',
    label: 'Denodo',
    iconShort: (props) => null,
    iconLong: TechnologyIcons.DenodoLong,
    iconDefault: TechnologyIcons.DenodoLong
  },
  {
    key: 'Derby',
    label: 'Derby',
    iconShort: TechnologyIcons.Derby,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Derby
  },
  {
    key: 'Dremio',
    label: 'Dremio',
    iconShort: TechnologyIcons.Dremio,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Dremio
  },
  {
    key: 'EDB',
    label: 'EDB',
    iconShort: (props) => null,
    iconLong: TechnologyIcons.EdbLong,
    iconDefault: TechnologyIcons.EdbLong
  },
  {
    key: 'ElasticSearch',
    label: 'Elasticsearch',
    iconShort: TechnologyIcons.ElasticSearch,
    iconLong: TechnologyIcons.ElasticSearchLong,
    iconDefault: TechnologyIcons.ElasticSearch
  },
  {
    key: 'Exasol',
    label: 'Exasol',
    iconShort: TechnologyIcons.Exasol,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Exasol
  },
  {
    key: 'Excel',
    label: 'Excel',
    iconShort: TechnologyIcons.Excel,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Excel
  },
  {
    key: 'Firebird',
    label: 'Firebird',
    iconShort: TechnologyIcons.Firebird,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Firebird
  },
  {
    key: 'GoogleBigQuery',
    label: 'Google BigQuery',
    iconShort: TechnologyIcons.GoogleBigQuery,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.GoogleBigQuery
  },
  {
    key: 'GoogleCloudSQL',
    label: 'Google Cloud SQL',
    iconShort: TechnologyIcons.GoogleCloudSql,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.GoogleCloudSql
  },
  {
    key: 'Greenplum',
    label: 'Greenplum',
    iconShort: TechnologyIcons.Greenplum,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Greenplum
  },
  {
    key: 'Hadoop',
    label: 'Hadhoop',
    iconShort: TechnologyIcons.Hadoop,
    iconLong: TechnologyIcons.HadoopLong,
    iconDefault: TechnologyIcons.Hadoop
  },
  {
    key: 'Ingress',
    label: 'Ingress',
    iconShort: TechnologyIcons.Ingress,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Ingress
  },
  {
    key: 'Intersystems',
    label: 'Intersystems',
    iconShort: TechnologyIcons.Intersystems,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Intersystems
  },
  {
    key: 'MariaDB',
    label: 'MariaDB',
    iconShort: TechnologyIcons.MariaDb,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.MariaDb
  },
  {
    key: 'MicrosoftAccess',
    label: 'Microsoft Access',
    iconShort: TechnologyIcons.MicrosoftAccess,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.MicrosoftAccess
  },
  {
    key: 'MicrosoftAzureSynapse',
    label: 'Microsoft Azure Synapse',
    iconShort: TechnologyIcons.MicrosoftAzureSynapse,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.MicrosoftAzureSynapse
  },
  {
    key: 'MicrosoftAzureAnalysisServices',
    label: 'Microsoft Azure Analysis Services',
    iconShort: TechnologyIcons.MicrosoftAzureAnalysisServices,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.MicrosoftAzureAnalysisServices
  },
  {
    key: 'MicrosoftAzure',
    label: 'Microsoft Azure',
    iconShort: TechnologyIcons.MicrosoftAzure,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.MicrosoftAzure
  },
  {
    key: 'MicrosoftPowerBI',
    label: 'Microsoft PowerBI',
    iconShort: TechnologyIcons.MicrosoftPowerBi,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.MicrosoftPowerBi
  },
  {
    key: 'MicrosoftSQLServer',
    label: 'Microsoft SQL Server',
    iconShort: TechnologyIcons.MicrosoftSqlServer,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.MicrosoftSqlServer
  },
  {
    key: 'MySQL',
    label: 'MySQL',
    iconShort: TechnologyIcons.MySql,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.MySql
  },
  {
    key: 'Neo4j',
    label: 'Neo4j',
    iconShort: TechnologyIcons.Neo4J,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Neo4J
  },
  {
    key: 'Nuodb',
    label: 'Nuodb',
    iconShort: TechnologyIcons.Nuodb,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Nuodb
  },
  {
    key: 'Oracle',
    label: 'Oracle',
    iconShort: TechnologyIcons.Oracle,
    iconLong: TechnologyIcons.OracleLong,
    iconDefault: TechnologyIcons.Oracle
  },
  {
    key: 'PostgreSQL',
    label: 'PostgreSQL',
    iconShort: TechnologyIcons.PostgreSql,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.PostgreSql
  },
  {
    key: 'Presto',
    label: 'Presto',
    iconShort: TechnologyIcons.Presto,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Presto
  },
  {
    key: 'Salesforce',
    label: 'Salesforce',
    iconShort: TechnologyIcons.Salesforce,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Salesforce
  },
  {
    key: 'Sap',
    label: 'Sap',
    iconShort: TechnologyIcons.Sap,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Sap
  },
  {
    key: 'Snowflake',
    label: 'Snowflake',
    iconShort: TechnologyIcons.Snowflake,
    iconLong: TechnologyIcons.SnowflakeLong,
    iconDefault: TechnologyIcons.Snowflake
  },
  {
    key: 'Vertica',
    label: 'Vertica',
    iconShort: TechnologyIcons.Vertica,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.Vertica
  },
  {
    key: 'DBT',
    label: 'DBT',
    iconShort: TechnologyIcons.DbtLabsDbt,
    iconLong: (props) => null,
    iconDefault: TechnologyIcons.DbtLabsDbt
  }
];

export const getIconShort = (obj, props) =>
  obj?.iconShort(props) || obj?.iconDefault(props) || <></>; //todo get def icon

export const getIconLong = (obj, props) => obj?.iconLong(props) || obj?.iconDefault(props) || <></>; //todo get def icon

export const getTechnologyConfig = (value) =>
  SystemTechnologyTypes.find((type) => type.key === value || type.label === value);

export const getSystemName = (system, { ...props }) => (
  <div style={{ display: 'flex', alignItems: 'center' }} {...props}>
    {getIconShort(getTechnologyConfig(system?.technology))}
    <div style={{ paddingLeft: '4px' }}>{system?.name}</div>
  </div>
);
