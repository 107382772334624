import { Grid, Tab, Tabs, Typography } from '@mui/material';
import withAuthorize from 'components/permissions/withAuthorize';
import useSearchParams from 'components/useSearchParams';
import Permissions from 'constants/Permissions';
import ResourceTypes from 'constants/ResourceTypes';
import CommentsPanel from 'pages/social/comments/CommentsPanel';
import StewardshipResponsibilitiesPanel from 'pages/stewardship/responsibilities/common/StewardshipResponsibilitiesPanel';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataProduct } from 'actions/DataProductsActions';
import { DataProductDetailCard } from './DataProductDetailCard';
import Breadcrumbs from 'pages/sidebar/Navigation';
import { t } from 'i18next';
import DataProductDependenciesPanel from '../dependenciesGraph/DataProductDependenciesPanel';
import DataProductPortModal from '../commons/DataProductPortModal';
import _ from 'lodash';
import DataProductQualityPanel from './DataProductQualityPanel';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';

export const DataProductDetailPage = ({ match }) => {
  const [dataProductPortModalOpen, setDataProductPortModalOpen] = useState(false);
  const [currentPort, setCurrentPort] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useSearchParams();

  const selectedTab = query.get('tab') || 'dependencies';
  const handleChangeTab = (_event, newValue) => {
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });
  };

  const selectedPort = query.get('port');

  const dataProduct = useSelector(
    (state) => state.dataproducts.content[match.params['dataProductUuid']]
  );

  useEffect(() => {
    dispatch(fetchDataProduct(match.params['dataProductUuid']));
  }, [dispatch, match.params]);

  useEffect(() => {
    if (selectedPort && dataProduct) {
      const port = _.find(dataProduct.ports, { uuid: selectedPort });
      if (port) {
        setCurrentPort(port);
        setDataProductPortModalOpen(true);
      }
    }
  }, [dataProduct, selectedPort]);

  return dataProduct ? (
    <>
      <Breadcrumbs
        elements={[
          { text: t('navbar.physical_model'), to: '/systems' },
          { text: t('navbar.dataProducts'), to: '/dataproducts/list' },
          { text: dataProduct?.displayName }
        ]}
      />
      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            {t('dataProducts.detail.header', {
              dataProductName: dataProduct?.displayName
            })}
          </Typography>
        </Grid>
        <Grid item>
          {dataProduct && (
            <FavoritesWidget
              itemIdentifier={`${ResourceTypes.DATA_PRODUCT}:${dataProduct.uuid}`}
              pageTitle={t('dataProducts.detail.header', {
                dataProductName: dataProduct?.displayName
              })}
              resourceType={ResourceTypes.DATA_PRODUCT}
              resourceIdentifier={dataProduct?.uuid}
              resourceName={dataProduct?.displayName}
            />
          )}
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.DATA_PRODUCT}
            resourceIdentifier={dataProduct?.uuid}
            resourceName={dataProduct?.displayName}
          />
        </Grid>
      </Grid>
      <DataProductDetailCard dataProduct={dataProduct} />
      <Tabs value={selectedTab} onChange={handleChangeTab} centered>
        <Tab value='dependencies' label='Dependencies' />
        <AuthorizedTab
          hasPermissions={[Permissions.QUALITY_VIEWER]}
          value='quality'
          label='Quality'
        />
        <AuthorizedTab
          hasPermissions={[Permissions.STEWARDSHIP_VIEWER]}
          value='responsibilities'
          label='Responsibilities'
        />
        <Tab value='comments' label='Comments' />
      </Tabs>
      {selectedTab === 'dependencies' && <DataProductDependenciesPanel dataProduct={dataProduct} />}
      {selectedTab === 'responsibilities' && (
        <StewardshipResponsibilitiesPanel
          resourceType={ResourceTypes.DATA_PRODUCT}
          resourceIdentifier={dataProduct.uuid}
        />
      )}
      {selectedTab === 'quality' && <DataProductQualityPanel dataProduct={dataProduct} />}

      {selectedTab === 'comments' && (
        <CommentsPanel
          notExpandable
          resourceType={ResourceTypes.DATA_PRODUCT}
          resourceIdentifier={dataProduct.uuid}
          resourceName={dataProduct.name}></CommentsPanel>
      )}
      {dataProductPortModalOpen && (
        <DataProductPortModal
          open={dataProductPortModalOpen}
          port={currentPort}
          handleClose={() => {
            setDataProductPortModalOpen(false);
          }}
        />
      )}
    </>
  ) : null;
};

const AuthorizedTab = withAuthorize(Tab, 'span');

export default DataProductDetailPage;
