import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import HistoryTargetTypes from './HistoryTargetTypes';

const HistoryButtonLink = ({
  userId,
  targetType,
  targetIdentifier,
  firstRopaUuid,
  secondRopaUuid,
  ...otherProps
}) => {
  let q = queryString.stringify({
    userId,
    targetIdentifier,
    targetType,
    firstRopaUuid,
    secondRopaUuid
  });

  let url = `/history/records/?${q}`;
  return (
    <Button to={url} {...otherProps} component={Link}>
      History
    </Button>
  );
};

HistoryButtonLink.propTypes = {
  userId: PropTypes.string,
  firstRopaUuid: PropTypes.string,
  secondRopaUuid: PropTypes.string,
  targetIdentifier: PropTypes.string,
  targetType: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(HistoryTargetTypes)),
    PropTypes.arrayOf(PropTypes.oneOf(Object.values(HistoryTargetTypes)))
  ])
};

export default HistoryButtonLink;
