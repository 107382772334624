import { buildApiCallAction } from '../../ActionUtils';
import FavoritesApi from 'api/social/favorites/FavoritesApi';
import {
  FETCH_FAVORITES_PAGE,
  CREATE_FAVORITE,
  FETCH_FAVORITE,
  DELETE_FAVORITE,
  MODIFY_FAVORITE
} from '../../../constants/ActionTypes';

const api = new FavoritesApi();

export const fetchFavoritesPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getFavorites(params),
    actionType: FETCH_FAVORITES_PAGE
  });

export const fetchFavorite = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getFavorite(uuid),
    actionType: FETCH_FAVORITE
  });

export const deleteFavorite = (favorite) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteFavorite(favorite),
    actionType: DELETE_FAVORITE,
    payload: favorite
  });
export const createFavorite = (favorite) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postFavorite(favorite),
    actionType: CREATE_FAVORITE
  });

export const modifyFavorite = (favorite, oldUuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putFavorite(favorite),
    actionType: MODIFY_FAVORITE,
    payload: { favorite: favorite, oldWorkspaceUuid: oldUuid }
  });
