import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from "react-redux";
import SystemLoginSearchModal from '../../systems/logins/SystemLoginSearchModal';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import { removeDataActorLoginAssignment, fetchDataActorLoginAssignments, createDataActorLoginAssignment } from '../../../actions/DataActorsActions'
import { withLoader } from '../../../components/loader/Loader';
import { withOpenCloseHandler } from '../../../components/openclosehandler/OpenCloseHandler';

const DataActorAssignedLogins = ({ dataActor, assignedLogins, createDataActorLoginAssignment, removeDataActorLoginAssignment, onToggleOpen, isOpen }) => (
  <React.Fragment>
    <h4>Assigned logins</h4>
    <ChipWrapper onRequestAdd={onToggleOpen}>
      {assignedLogins.map(assignedLogin => {
        var systemPrefix = ''
        try {
          systemPrefix = assignedLogin.systemName + ': '
        } catch (e) { }
        return (
          <ChipItem key={assignedLogin.uuid}
            id={assignedLogin.uuid}
            label={systemPrefix + assignedLogin.systemLoginUsername}
            onRequestDelete={() => removeDataActorLoginAssignment(dataActor,assignedLogin)} />
        );
      })}
    </ChipWrapper>
    <SystemLoginSearchModal title="Assign login"
      itemName="Login"
      open={isOpen}
      onCancel={onToggleOpen}
      onSubmit={(systemLogin) => createDataActorLoginAssignment(dataActor, {
        dataActorUuid: dataActor.uuid, systemLoginUuid: systemLogin.uuid
      }).then(onToggleOpen)} />
  </React.Fragment>
)

DataActorAssignedLogins.propTypes = {
  createDataActorLoginAssignment: PropTypes.func.isRequired,
  removeDataActorLoginAssignment: PropTypes.func.isRequired,
  dataActor: PropTypes.object.isRequired,
  assignedLogins: PropTypes.array.isRequired
}

const mapStateToProps = (state,props)=>({
  assignedLogins: state.data_actors.assignedLogins[props.dataActor.uuid]||[],
})

export default _.flow([
  withOpenCloseHandler,
  withLoader({ onLoad: ({dataActor, fetchDataActorLoginAssignments}) => fetchDataActorLoginAssignments(dataActor) }),
  connect(mapStateToProps, {
    fetchDataActorLoginAssignments,removeDataActorLoginAssignment, createDataActorLoginAssignment
  })
])(DataActorAssignedLogins)