import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ExpandableCard from '../../components/expandable-card/ExpandableCard';

const TaskGrantItem = (props) => (
  <div className='item-container'>
    <ExpandableCard
      title={`${props.taskGrant.systemLogin.system.name}: ${props.taskGrant.systemLogin.username} => ${props.taskGrant.task.name}`}
      subtitle={`created at ${new Date(props.taskGrant.createdAt)}`}
      actions={[
        <Button children='Delete' onClick={() => props.deleteTaskGrant(props.taskGrant)} />
      ]}
    />
  </div>
);

TaskGrantItem.propTypes = {
  taskGrant: PropTypes.object.isRequired,
  deleteTaskGrant: PropTypes.func.isRequired
};

export default TaskGrantItem;
