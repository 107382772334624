import crudReducers from '../utils';
import { combineReducers } from 'redux';
import { CLEAR_ASSIGNMENTS_FILTERS, SET_ASSIGNMENTS_FILTERS } from '../../constants/ActionTypes';

function filters(state = initialAssignmentsFilters, action) {
  switch (action.type) {
    case SET_ASSIGNMENTS_FILTERS:
      return {
        ...state,
        ...action.payload,
        hasFilters: true
      };
    case CLEAR_ASSIGNMENTS_FILTERS:
      return defaultAssignmentsFilters;
    default:
      return state;
  }
}

const defaultAssignmentsFilters = {
  hasFilters: false,
  assignmentState: [],
  dataCategories: [],
  logicalFields: [],
  systems: [],
  physicalEntities: [],
  physicalFields: []
};

const initialAssignmentsFilters = {
  ...defaultAssignmentsFilters,
  assignmentState: ['PENDING'],
  hasFilters: true
};

export default combineReducers({
  ...crudReducers({ resource: 'ASSIGNMENT', keyProperty: 'sequenceId' }),
  filters
});
