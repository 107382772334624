import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchTenantPermissions, modifyTenantPermissions } from '../../../../actions/UsersActions';
import { selectTenantPermissions } from '../../../../reducers/settings/users';
import PermissionsEditor from '../commons/PermissionsEditor';

function UserTenantPermissionsEditor({
  tenantUuid,
  userUuid,
  onChangeTenantPermissions,
  fetchTenantPermissions,
  tenantPermissions = []
}) {
  useEffect(() => {
    fetchTenantPermissions({ userUuid, tenantUuid });
  }, [tenantUuid, userUuid, fetchTenantPermissions]);

  return (
    <PermissionsEditor
      permissions={tenantPermissions}
      onChange={newPermissions => onChangeTenantPermissions(newPermissions)}
    />
  );
}

function mapStateToProps(state, { userUuid, tenantUuid }) {
  return {
    tenantPermissions: selectTenantPermissions(state.settings.users.tenantPermissions, {
      userUuid,
      tenantUuid
    })
  };
}

function mapDispatchToProps(dispatch, { userUuid, tenantUuid }) {
  return {
    onChangeTenantPermissions: permissions =>
      dispatch(modifyTenantPermissions({ userUuid, tenantUuid, permissions })),
    fetchTenantPermissions: params => dispatch(fetchTenantPermissions(params))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTenantPermissionsEditor);
