import { combineReducers } from 'redux';
import { FETCH_WATCHLIST_PAGE,DELETE_WATCHLIST_RECORD, CREATE_WATCHLIST_RECORD } from '../../constants/ActionTypes';
import _ from 'lodash';

export default combineReducers({
  contentByEntity
});

function contentByEntity(state = {}, action) {
  switch (action.type) {
    case FETCH_WATCHLIST_PAGE:
      return {
        ...state,
        ..._(action.payload.content)
          .keyBy((e) => `${e.entityType}:${e.entityIdentifier}`)
          .value()
      };
    case CREATE_WATCHLIST_RECORD:
      return{
      ...state,
      [`${action.payload.entityType}:${action.payload.entityIdentifier}`]: action.payload
      }
    case DELETE_WATCHLIST_RECORD:
      return{
        ...state,
        [`${action.payload.entityType}:${action.payload.entityIdentifier}`]: undefined
      }
    default:
      return state;
  }
}
