import React from 'react';

import { connect } from 'react-redux';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';
import { fetchSystemsPage } from 'actions/SystemsActions';

function SystemItemAutoComplete({ onSearch, onChange, value, searchContent, ...props }) {
  return (
    <ItemAutoComplete
      values={searchContent}
      value={value}
      margin='dense'
      showAllOptions
      itemName='System'
      nameProperty={'name'}
      onChange={(newValue) => onChange(newValue)}
      onSearch={(inputValue) => {
        onSearch({ search: inputValue });
      }}
      {...props}
    />
  );
}

const mapStateToProps = (state, props) => ({
  searchContent: Object.values(state.systems.content)
});

const mapDispatchToProps = (dispatch) => ({
  onSearch: (params) => dispatch(fetchSystemsPage(params))
});
export default connect(mapStateToProps, mapDispatchToProps)(SystemItemAutoComplete);
