import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import Permissions from 'constants/Permissions';
import Authorize from 'components/permissions/Authorize';

const ClassificationPanelActions = ({ rule, t, onEdit, onDelete, onShowDetails }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleEdit = (event) => {
    setAnchorEl(null);
    onEdit();
    event.stopPropagation();
  };

  const handleDelete = (event) => {
    setAnchorEl(null);
    onDelete();
    event.stopPropagation();
  };

  const handleShowDetails = (event) => {
    setAnchorEl(null);
    onShowDetails();
    event.stopPropagation();
  };

  let isOpen = Boolean(anchorEl);

  return (
    <>
      <IconButton
        size='small'
        aria-haspopup='true'
        aria-controls='classification-panel-actions'
        onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='classification-panel-actions'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleShowDetails}>{t('commons.actions.detail')}</MenuItem>

        {isOpen && (
          <Authorize hasPermissions={[Permissions.CLASSIFICATION_EDITOR]}>
            <MenuItem onClick={handleEdit}>{t('commons.actions.modify')}</MenuItem>
          </Authorize>
        )}
        {isOpen && (
          <Authorize hasPermissions={[Permissions.CLASSIFICATION_EDITOR]}>
            <MenuItem onClick={handleDelete}>{t('commons.actions.delete')}</MenuItem>
          </Authorize>
        )}
      </Menu>
    </>
  );
};

export default withTranslation()(ClassificationPanelActions);
