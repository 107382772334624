import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import ResourceTypes from 'constants/ResourceTypes';
import IssueTypes from 'pages/issueMngt/issues/detail/IssueTypes';
import IssuesSeverities from 'pages/issueMngt/issues/detail/IssuesSeverities';
import IssueStatuses from 'pages/issueMngt/issues/detail/IssueStatuses';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid
} from '@mui/material';
import DatePicker, { hourAdjustments } from 'components/pickers/DatePicker';

import CampaignItemAutoComplete from 'pages/issueMngt/campaigns/commons/CampaignItemAutoComplete';
import SearchItemAutocomplete from 'pages/settings/search/SearchItemAutocomplete';
import UsersInCurrentTenantItemAutocomplete from 'pages/settings/users/commons/UsersInCurrentTenantItemAutocomplete';
import AccountTeamSelector from 'pages/settings/profile/utils/AccountTeamSelector';
import AdditionalPropertiesEditor from 'components/additional_properties/AdditionalPropertiesEditor';

const IssueModal = ({
  open,
  title,
  campaign,
  resource,
  reporter,
  issueToEdit,
  handleSubmit,
  handleCancel
}) => {
  const { t } = useTranslation();

  const [issue, setIssue] = useState(
    issueToEdit || {
      campaign,
      resourceIdentifier: resource?.resourceIdentifier,
      resourceName: resource?.name,
      resourceType: resource?.resourceType,
      issueType: IssueTypes.TASK,
      severity: IssuesSeverities.INFO,
      priorityOrder: 1000,
      issueStatus: IssueStatuses.TO_DO,
      reporter
    }
  );

  const handleChange = (change) => setIssue({ ...issue, ...change });

  return (
    <>
      <Dialog open={open} onClose={handleCancel} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <GeneralSection
            issueName={issue.name}
            issueDescription={issue.description}
            issueCampaign={issue.campaign}
            issueResource={
              issue.resourceIdentifier
                ? {
                    resourceIdentifier: issue.resourceIdentifier,
                    name: issue.resourceName,
                    resourceType: issue.resourceType
                  }
                : undefined
            }
            issueType={issue.issueType}
            handleChange={handleChange}
          />
          <DetailsSection
            issueSeverity={issue.severity}
            issuePriorityOrder={issue.priorityOrder}
            issueStatus={issue.issueStatus}
            issueAssignee={issue.assignee}
            issueAssigneeTeamCode={issue.assigneeTeamCode}
            issueReporter={issue.reporter}
            handleChange={handleChange}
          />
          <MoreFieldsSection
            issueEstimate={issue.estimate}
            issuePlannedStartDate={issue.plannedStartDate}
            issuePlannedEndDate={issue.plannedEndDate}
            additionalProperties={issue.additionalProperties}
            handleChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.cancel')}
            onClick={() => {
              handleCancel();
            }}
          />
          <Button
            children={t('commons.actions.submit')}
            variant='contained'
            color='primary'
            disabled={disabled(issue)}
            onClick={() => {
              handleSubmit(issue);
              handleCancel();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const GeneralSection = ({
  issueName,
  issueDescription,
  issueCampaign,
  issueResource,
  issueType,
  handleChange
}) => {
  const { t } = useTranslation();
  const types = [
    IssueTypes.BUG,
    IssueTypes.VULNERABILITY,
    IssueTypes.WARNING,
    IssueTypes.ALERT,
    IssueTypes.TASK,
    IssueTypes.FEATURE_REQUEST,
    IssueTypes.QUESTION
  ];

  return (
    <>
      <h4>General</h4>
      <TextField
        variant='standard'
        fullWidth
        margin='dense'
        label='Name'
        placeholder="Enter the issue's name"
        required
        value={issueName || ''}
        onChange={(event) => handleChange({ name: event.target.value })}
      />
      <TextField
        variant='standard'
        fullWidth
        margin='dense'
        multiline
        label='Description'
        placeholder="Enter the issue's description"
        value={issueDescription || ''}
        onChange={(event) => handleChange({ description: event.target.value })}
      />
      <FormControl variant='standard' fullWidth margin='dense'>
        <InputLabel id='issue-type-select-label' required>
          Type
        </InputLabel>
        <Select
          variant='standard'
          labelId='issue-type-select-label'
          id='issue-type-select'
          value={issueType || ''}
          onChange={(event) => handleChange({ issueType: event.target.value })}>
          {types.map((issueType) => (
            <MenuItem key={issueType} value={issueType}>
              {t(`issueMngt.issues.types.${issueType}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <CampaignItemAutoComplete
        value={issueCampaign}
        handleChange={(campaign) => handleChange({ campaign })}
      />
      <SearchItemAutocomplete
        value={issueResource}
        onChange={(resource) =>
          handleChange({
            resourceIdentifier: resource?.resourceIdentifier,
            resourceName: resource?.name,
            resourceType: resource?.resourceType
          })
        }
      />
    </>
  );
};

const DetailsSection = ({
  issueSeverity,
  issuePriorityOrder,
  issueStatus,
  issueAssignee,
  issueAssigneeTeamCode,
  issueReporter,
  handleChange
}) => {
  const { t } = useTranslation();

  const severities = [
    IssuesSeverities.BLOCKER,
    IssuesSeverities.CRITICAL,
    IssuesSeverities.MAJOR,
    IssuesSeverities.MINOR,
    IssuesSeverities.INFO
  ];
  const statuses = [
    IssueStatuses.TO_DO,
    IssueStatuses.REVIEW,
    IssueStatuses.DONE,
    IssueStatuses.IN_PROGRESS
  ];

  return (
    <>
      <h4>Details</h4>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl variant='standard' fullWidth margin='dense'>
            <InputLabel id='issue-severity-select-label' required>
              Severity
            </InputLabel>
            <Select
              variant='standard'
              labelId='issue-severity-select-label'
              id='issue-severity-select'
              value={issueSeverity || ''}
              onChange={(event) => handleChange({ severity: event.target.value })}>
              {severities.map((severity) => (
                <MenuItem key={severity} value={severity}>
                  {t(`issueMngt.issues.severities.${severity}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            label='Priority Order'
            placeholder="Enter the issue's priority order (positive integer number)"
            required
            type='number'
            inputProps={{ min: 0 }}
            value={
              issuePriorityOrder !== null && issuePriorityOrder !== undefined
                ? issuePriorityOrder
                : ''
            }
            onChange={(event) => handleChange({ priorityOrder: event.target.value })}
          />
        </Grid>
      </Grid>
      <FormControl variant='standard' fullWidth margin='dense'>
        <InputLabel id='issue-status-select-label' required>
          Status
        </InputLabel>
        <Select
          variant='standard'
          labelId='issue-status-select-label'
          id='issue-status-select'
          required
          value={issueStatus || ''}
          onChange={(event) => handleChange({ issueStatus: event.target.value })}
          clearable='true'>
          {statuses.map((status) => (
            <MenuItem key={status} value={status}>
              {t(`issueMngt.issues.statuses.${status}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <UsersInCurrentTenantItemAutocomplete
        itemName={'Assignee - User'}
        value={issueAssignee}
        onChange={(assignee) => handleChange({ assignee })}
      />
      <AccountTeamSelector
        label='Assignee - Team'
        fullWidth
        margin='dense'
        selectedTeamCode={issueAssigneeTeamCode || ''}
        onChange={(code) => handleChange({ assigneeTeamCode: code })}
      />
      <UsersInCurrentTenantItemAutocomplete
        itemName={'Reporter'}
        value={issueReporter}
        onChange={(reporter) => handleChange({ reporter })}
      />
    </>
  );
};

const MoreFieldsSection = ({
  issueEstimate,
  issuePlannedStartDate,
  issuePlannedEndDate,
  additionalProperties,
  handleChange
}) => {
  return (
    <>
      <h4>More Fields</h4>
      <TextField
        variant='standard'
        fullWidth
        margin='dense'
        label='Estimate'
        placeholder="Enter the issue's estimate (positive number)"
        type='number'
        inputProps={{ min: 0, step: 'any' }}
        value={issueEstimate !== null && issueEstimate !== undefined ? issueEstimate : ''}
        onChange={(event) => handleChange({ estimate: event.target.value })}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            maxDate={issuePlannedEndDate || undefined}
            fullWidth
            margin='dense'
            label='Planned Start'
            hintText='Planned Start'
            value={issuePlannedStartDate}
            onChange={(date) => handleChange({ plannedStartDate: date })}
            clearable={true}
            hourAdjustment={hourAdjustments.startOfDay}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            minDate={issuePlannedStartDate || undefined}
            fullWidth
            margin='dense'
            label='Due Date'
            hintText='Due Date'
            value={issuePlannedEndDate}
            onChange={(date) => handleChange({ plannedEndDate: date })}
            clearable={true}
            hourAdjustment={hourAdjustments.endOfDay}
          />
        </Grid>
      </Grid>
      <AdditionalPropertiesEditor
        resourceType={ResourceTypes.ISSUE}
        additionalProperties={additionalProperties}
        onChange={(newList) => handleChange({ additionalProperties: newList })}
      />
    </>
  );
};

const disabled = (issue) => {
  const isNumberInputEmpty = (value) => {
    return value === undefined || value === null || value === '';
  };

  const isPositiveNumber = (value) => {
    if (isNumberInputEmpty(value)) return false;
    const numericValue = Number(value);
    return numericValue >= 0;
  };

  const isPositiveInteger = (value) => {
    if (isNumberInputEmpty(value)) return false;
    const numericValue = Number(value);
    return Number.isInteger(numericValue) && numericValue >= 0;
  };

  return (
    !issue.name ||
    !issue.issueType ||
    !issue.severity ||
    !issue.priorityOrder ||
    !issue.issueStatus ||
    !isPositiveInteger(issue.priorityOrder) ||
    (!_.isEmpty(issue.estimate) && !isPositiveNumber(issue.estimate))
  );
};

export default IssueModal;
