import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchRiskDefinition } from 'actions/quality/assessment/QualityAssessmentRiskDefinitionsActions';

import { Grid, Typography } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

import RiskDefinitionDetailCard from 'pages/quality/assessment/risks/detail/RiskDefinitionDetailCard';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const RiskDefinitionDetailPage = ({ uuid, riskDefinition, fetchRiskDefinition }) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchRiskDefinition(uuid);
  }, [uuid, fetchRiskDefinition]);

  if (!riskDefinition) {
    return null;
  }
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.qualityAssessment'), to: '/data-quality/assessment/risks' },
          { text: t('navbar.qualityAssessmentRisks'), to: '/data-quality/assessment/risks' },
          { text: riskDefinition.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('quality.assessment.risks.detailPageHeader', { name: riskDefinition.name }),
          category: EngagementCategories.DATA_QUALITY_ASSESSMENT,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            {t('quality.assessment.risks.detailPageHeader', { name: riskDefinition.name })}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`RISK:${riskDefinition.uuid}`}
            pageTitle={`Risk: ${riskDefinition.name}`}
          />
        </Grid>
      </Grid>

      <RiskDefinitionDetailCard riskDefinition={riskDefinition} />
    </>
  );
};

function mapStateToProps(state, props) {
  return {
    uuid: props.match.params.uuid,
    riskDefinition: state.quality.assessment.riskDefinitions.content[props.match.params.uuid]
  };
}

const mapDispatchToProps = {
  fetchRiskDefinition
};

export default connect(mapStateToProps, mapDispatchToProps)(RiskDefinitionDetailPage);
