import { FETCH_ENGAGEMENT_RECENTLY_VIEWED_PAGE } from 'constants/ActionTypes';

const initialState = [];

const recentlyViewed = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ENGAGEMENT_RECENTLY_VIEWED_PAGE:
      return { ...action.payload };
    default:
      return state;
  }
};

export default recentlyViewed;
