import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import CompilationKPIsChart from './CompilationKPIsChart';
import CompilationFiltersLogicalFieldKPIsView from './CompilationFiltersLogicalFieldKPIsView';
import CompilationFiltersDataCategoryKPIsView from './CompilationFiltersDataCategoryKPIsView';
import ResourceTypes from '../../constants/ResourceTypes';
import { useTranslation } from 'react-i18next';
export default function CompilationKPIsModal({
  onFetchCompilationKPIs,
  open,
  onClose,
  filters,
  hasFilters,
  resourceType
}) {
  const { t } = useTranslation();
  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>Completeness KPIs</DialogTitle>
      <DialogContent>
        {hasFilters && resourceType === ResourceTypes.LOGICAL_FIELD && (
          <CompilationFiltersLogicalFieldKPIsView
            filters={filters}></CompilationFiltersLogicalFieldKPIsView>
        )}
        {hasFilters && resourceType === ResourceTypes.DATA_CATEGORY && (
          <CompilationFiltersDataCategoryKPIsView
            filters={filters}></CompilationFiltersDataCategoryKPIsView>
        )}
        <CompilationKPIsChart onFetch={onFetchCompilationKPIs}></CompilationKPIsChart>
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.close')} onClick={onClose}></Button>
      </DialogActions>
    </Dialog>
  );
}
