import { buildApiCallAction } from './ActionUtils';
import {
  CREATE_CUSTOM_PROPERTY_DEFINITION,
  FETCH_CUSTOM_PROPERTY_DEFINITIONS_PAGE,
  MODIFY_CUSTOM_PROPERTY_DEFINITION,
  DELETE_CUSTOM_PROPERTY_DEFINITION,
  FETCH_CUSTOM_PROPERTY_DEFINITIONS_VALUES_PAGE
} from '../constants/ActionTypes';
import CustomPropertiesApi from '../api/CustomPropertiesApi';

const api = new CustomPropertiesApi();

export const fetchCustomPropertyDefinitionsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getCustomPropertyDefinitions(params),
    actionType: FETCH_CUSTOM_PROPERTY_DEFINITIONS_PAGE
  });

export const fetchCustomPropertyDefinition = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getCustomPropertyDefinition(uuid),
    actionType: FETCH_CUSTOM_PROPERTY_DEFINITIONS_PAGE
  });

export const createCustomPropertyDefinition = (customPropertyDefinition) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postCustomPropertyDefinition(customPropertyDefinition),
    actionType: CREATE_CUSTOM_PROPERTY_DEFINITION
  });

export const modifyCustomPropertyDefinition = (customPropertyDefinition) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putCustomPropertyDefinition(customPropertyDefinition),
    actionType: MODIFY_CUSTOM_PROPERTY_DEFINITION
  });

export const deleteCustomPropertyDefinition = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteCustomPropertyDefinition(uuid),
    actionType: DELETE_CUSTOM_PROPERTY_DEFINITION,
    payload: { uuid }
  });

export const fetchCustomPropertyDefinitionValues = (uuid, params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getCustomPropertyDefinitionValues(uuid, params),
    actionType: FETCH_CUSTOM_PROPERTY_DEFINITIONS_VALUES_PAGE,
    payload: (response) => ({ ...response, uuid })
  });

export const refreshComputedCustomProperty = (customPropertyDefinition, params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) =>
      api.postCustomPropertyDefinitionRefresh(customPropertyDefinition.uuid, params),
    actionType: 'refreshComputedCustomProperty'
  });
