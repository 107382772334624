import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SystemItemAutoComplete from '../../systems/commons/SystemItemAutoComplete';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  FormControl,
  MenuItem
} from '@mui/material';
import { useSelector } from 'react-redux';
import { filterViewableResourceTypes } from 'components/permissions/permissiosSelectors';
import ResourceApplicabilityTargetTypes from '../roles/commons/ResourceApplicabilityTargetTypes';

const STEWARDSHIP_RESOURCE_TYPES = Object.values(ResourceApplicabilityTargetTypes);

function DashboardRolesCoverageFiltersModal({
  open,
  setOpen,
  filters,
  setFilters,
  onFetch,
  usersDashboard
}) {
  let viewableResourceTypes = useSelector(filterViewableResourceTypes(STEWARDSHIP_RESOURCE_TYPES));

  const [resourceType, setResourceType] = useState(filters.resourceType);
  const [system, setSystem] = useState(null);

  let { t } = useTranslation();
  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>{'Set Coverage Filters'}</DialogTitle>
      <DialogContent>
        <FormControl variant='standard' fullWidth={true}>
          <InputLabel id='demo-simple-select-label'>Resource Type</InputLabel>
          <Select
            variant='standard'
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={resourceType}
            onChange={(event) => {
              setResourceType(event.target.value);
              setFilters({ ...filters, resourceType: event.target.value });
            }}>
            {viewableResourceTypes.map((rt) => (
              <MenuItem value={rt}>{t(`resourceType.${rt}`)}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {resourceType === 'PHYSICAL_ENTITY' && !usersDashboard && (
          <div>
            <h4>Do you want to filter for a System?</h4>
            <SystemItemAutoComplete
              value={system || null}
              onChange={(sys) => {
                setSystem(sys);
                setFilters({ ...filters, systemUuid: sys && sys.uuid });
              }}></SystemItemAutoComplete>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.clear')}
          onClick={() => {
            setSystem(null);
            setFilters({
              resourceType: 'DATA_CATEGORY',
              systemUuid: null
            });
            setOpen(false);
          }}></Button>
        <Button
          children={t('commons.actions.apply')}
          variant='outlined'
          color='primary'
          onClick={() => {
            onFetch(filters);
            setOpen(false);
          }}></Button>
      </DialogActions>
    </Dialog>
  );
}

export default DashboardRolesCoverageFiltersModal;
