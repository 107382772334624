import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { searchProcessings } from '../../../actions/ProcessingsActions';
import ItemAutoCompleteModal from '../../../components/itemselector/ItemAutoCompleteModal';

const ProcessingSearchModal = ({
  t,
  title,
  open,
  contentByUuid,
  searchProcessings,
  onCancel,
  onSubmit
}) => (
  <ItemAutoCompleteModal
    title={title || `Search Processings`}
    itemName={'Processing'}
    open={open}
    keyProperty={'uuid'}
    nameProperty={'name'}
    values={Object.values(contentByUuid)}
    onSearch={(searchText) => searchProcessings({ searchText })}
    handleCancel={onCancel}
    handleSubmit={onSubmit}
  />
);

ProcessingSearchModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  contentByUuid: state.processings.contentByUuid
});

export default _.flow([withTranslation(), connect(mapStateToProps, { searchProcessings })])(
  ProcessingSearchModal
);
