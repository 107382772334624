import React from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReportIcon from '@mui/icons-material/Report';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';

export const RoutineStatusDecorator = ({ routineAnalysisStatus, routineErrorMessage }) => {
  const statusIcons = {
    ANALYZED: <SpellcheckIcon fontSize='small' style={{ color: '#00aeca' }}></SpellcheckIcon>,
    ERROR: <ReportIcon style={{ color: '#d91e48' }}></ReportIcon>,
    PENDING: <PauseCircleFilledIcon style={{ color: '##7734e3' }}></PauseCircleFilledIcon>
  };
  return (
    <>
      <Grid container justifyContent='flex-start' direction='row' alignItems='stretch' spacing={1}>
        <Grid item style={{ marginTop: '3px' }}>
          {statusIcons[routineAnalysisStatus]}
        </Grid>
        <Grid item>
          <Typography variant='overline'>{routineAnalysisStatus}</Typography>
          {routineErrorMessage ? (
            <Tooltip title={routineErrorMessage}>
              <HelpOutlineIcon />
            </Tooltip>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
};
