import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withPermissions from 'components/permissions/withPermissions';
import { withTranslation } from 'react-i18next';

import Permissions from 'constants/Permissions';

import {
  TablePagination,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
  Grid
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { fetchRulesPage, createRule, modifyRule, deleteRule } from 'actions/RulesActions';
import ClassificationRulesPanelTable from './ClassificationRulesPanelTable';
import Authorize from 'components/permissions/Authorize';
import RulesModal from './RulesModal';
import { useHistory } from 'react-router-dom';
import ClassificationRulesActionsModals from './ClassificationRulesActionsModals';

function ClassificationRulesPanel({
  dataCategory,
  logicalField,
  fetchRulesPage,
  createRule,
  modifyRule,
  deleteRule,
  currentPage
}) {
  const [includeFields, setIncludeFields] = useState(true);
  const [selectedRule, setSelectedRule] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const history = useHistory();

  const handleEdit = (rule) => {
    modifyRule(rule);
    setEditModalOpen(false);
  };

  const handleDelete = (rule) => {
    deleteRule(rule);
    setDeleteModalOpen(false);
  };

  let fetchParameters = getFetchParameters(logicalField, dataCategory, includeFields);
  useEffect(() => {
    fetchRulesPage(fetchParameters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCategory, logicalField, fetchRulesPage, includeFields]);

  return <>
    <Card titleTypographyProps={{}} style={{ overflowX: 'auto' }}>
      <CardHeader
        title='Rules'
        action={[
          <Grid container direction='row' alignItems='center'>
            {!logicalField && (
              <Grid item>
                <FormControlLabel
                  style={{ float: 'left' }}
                  control={
                    <Switch
                      color='primary'
                      disabled={logicalField}
                      checked={includeFields}
                      onChange={(event, checked) => {
                        setIncludeFields(checked);
                      }}></Switch>
                  }
                  label='Include Fields'
                />
              </Grid>
            )}
            <Grid item>
              <AddButton
                rule={{
                  logicalFieldName: logicalField?.name,
                  dataCategoryName: dataCategory?.name || logicalField?.dataCategory?.name
                }}
                createRule={createRule}></AddButton>
            </Grid>
          </Grid>
        ]}></CardHeader>
      <CardContent>
        {currentPage.content && currentPage.content.length > 0 ? (
          <>
            <ClassificationRulesPanelTable
              handleEdit={(rule) => {
                setSelectedRule(rule);
                setEditModalOpen(true);
              }}
              handleDelete={(rule) => {
                setSelectedRule(rule);
                setDeleteModalOpen(true);
              }}
              handleShowDetails={(uuid) => {
                history.push(`/classification/rules/${uuid}`);
              }}
              rules={currentPage.content}></ClassificationRulesPanelTable>
            <TablePagination
              component='div'
              style={{ overflowX: 'hidden' }}
              rowsPerPageOptions={[10, 20, 50]}
              page={currentPage.number}
              rowsPerPage={currentPage.size}
              count={currentPage.totalElements}
              onRowsPerPageChange={(event) =>
                fetchRulesPage({
                  ...fetchParameters,
                  page: currentPage.number,
                  size: event.target.value
                })
              }
              onPageChange={(event, page) =>
                fetchRulesPage({
                  ...fetchParameters,
                  page: page,
                  size: currentPage.size
                })
              }
            />
          </>
        ) : (
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>No rules associated to this resource</Typography>
          </div>
        )}
      </CardContent>
    </Card>
    <ClassificationRulesActionsModals
      selectedRule={selectedRule}
      editModalOpen={editModalOpen}
      setEditModalOpen={setEditModalOpen}
      deleteModalOpen={deleteModalOpen}
      setDeleteModalOpen={setDeleteModalOpen}
      onDelete={handleDelete}
      onEdit={handleEdit}></ClassificationRulesActionsModals>
  </>;
}
function getFetchParameters(logicalField, dataCategory, includeFields) {
  if (logicalField) {
    return {
      relatedLogicalFieldUuid: logicalField.uuid
    };
  } else {
    return { relatedDataCategoryUuid: dataCategory.uuid, includeFields: includeFields };
  }
}

function AddButton({ rule, createRule }) {
  const [openCreate, setOpenCreate] = useState(false);

  return (
    <>
      <Authorize hasPermissions={[Permissions.CLASSIFICATION_EDITOR]}>
        <Tooltip title='Add new issue'>
          <IconButton size='small' onClick={() => setOpenCreate(true)}>
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </Authorize>
      {openCreate && (
        <RulesModal
          open={openCreate}
          rule={rule}
          onClose={() => setOpenCreate(false)}
          onSubmit={(rule) => {
            createRule(rule);
            setOpenCreate(false);
          }}></RulesModal>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.classification.rules.currentPage
  };
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.CLASSIFICATION_VIEWER] }),
  withTranslation(),
  connect(mapStateToProps, { fetchRulesPage, createRule, modifyRule, deleteRule })
)(ClassificationRulesPanel);
