import React from 'react';
import { Link } from 'react-router-dom';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import { ChipWrapper, ChipItem } from '../../../../components/chiplist/ChipList';
import Authorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';
import { Button } from '@mui/material';

export const ConfigurationsListItem = ({ configuration, deleteContractConfiguration, t }) => (
  <ExpandableCard
    title={`${configuration.contractName}`}
    subheader={`version: ${configuration.version} language: ${configuration.language}`}
    actions={[
      <Button
        children={t('commons.actions.detail')}
        component={Link}
        to={`/contracts/configurations/${configuration.uuid}`}
      />,
      <Authorize hasPermissions={[Permissions.CONTRACTS_EDITOR]}>
        <Button
          children={t('commons.actions.modify')}
          component={Link}
          to={`/contracts/configurations/editor/edit/${configuration.uuid}`}
        />
      </Authorize>,
      <Authorize hasPermissions={[Permissions.CONTRACTS_ADMIN]}>
        <Button
          children={t('commons.actions.copy')}
          component={Link}
          to={`/contracts/configurations/editor/copy/${configuration.uuid}`}
        />
      </Authorize>,
      <Authorize hasPermissions={[Permissions.CONTRACTS_ADMIN]}>
        <ConfirmButton
          children={t('commons.actions.delete')}
          onClick={() => deleteContractConfiguration(configuration)}
        />
      </Authorize>
    ]}
    visibleChildren={
      configuration.active ? (
        <ChipWrapper
          justifyRigth
          style={{
            position: 'absolute',
            clear: 'both',
            float: 'right',
            right: '45px',
            top: '18px',
            display: 'flex'
          }}>
          <ChipItem key={configuration.uuid} id={configuration.uuid} label={'Active'} />
        </ChipWrapper>
      ) : null
    }
  />
);
