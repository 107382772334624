import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeamConfiguration } from 'actions/LoginActions';
// eslint-disable-next-line no-unused-vars
import TeamAccessLevels from 'constants/TeamAccessLevels';

// eslint-disable-next-line import/no-anonymous-default-export
export default (WrappedComponent) => {
  function WithTeamPolicy(props) {
    let { teamCode, teamAccessLevels = [], ...otherProps } = props;
    let teamPolicies = useSelector((state) => state.account.teamConfig.teamPolicies);
    let teamSettings = useSelector((state) => state.account.teamConfig.teamSettings);
    let teamConfigLoaded = useSelector((state) => state.account.teamConfig.loaded);
    let availableTeamsCodes = teamPolicies
      .filter((tp) =>
        teamAccessLevels.map((tal) => tp[tal.description]).reduce((prev, cur) => prev || cur, false)
      )
      .map((tp) => tp.team.code);
    let allTeamSettings = teamAccessLevels
      .map((tal) => teamSettings[tal.description + 'All'])
      .reduce((prev, cur) => prev || cur, false);

    let dispatch = useDispatch();

    useEffect(
      function () {
        if (!teamConfigLoaded) {
          dispatch(fetchTeamConfiguration());
        }
      },
      [dispatch, teamConfigLoaded]
    );
    let hasTeamPolicy = !teamCode || allTeamSettings || availableTeamsCodes.includes(teamCode);

    if (hasTeamPolicy) {
      return <WrappedComponent {...otherProps} />;
    } else {
      return null;
    }
  }

  WithTeamPolicy.displayName = `WithTeamPolicy(${getDisplayName(WrappedComponent)})`;
  return WithTeamPolicy;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
