import React, { useReducer, useState } from 'react';
import FilterList from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Grid
} from '@mui/material';
import { connect } from 'react-redux';
import { ChipItem, ChipWrapper } from 'components/chiplist/ChipList';
import { setIssuesFilters, clearIssuesFilters } from 'actions/IssuesActions';
import CampaignSearchModal from 'pages/issueMngt/campaigns/commons/CampaignSearchModal';
import UserFiltersSearchModal from 'pages/settings/users/commons/UserFiltersSearchModal';
import DatePicker, { hourAdjustments } from 'components/pickers/DatePicker';
import AccountTeamChipEditor from 'pages/settings/teams/common/AccountTeamChipEditor';
import IssueStatusAddModal from './IssueStatusAddModal';
import IssueTypesAddModal from './IssueTypesAddModal';
import IssueSeveritiesAddModal from './IssueSeveritiesAddModal';
import CustomPropertiesFilters from 'components/additional_properties/CustomPropertiesFilters';
import ResourceTypes from 'constants/ResourceTypes';

function IssueFiltersModal({
  filters,
  hasFilters,
  setIssuesFilters,
  clearIssuesFilters,
  hideCampaigns = false
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        size='small'
        color={hasFilters ? 'primary' : 'grey'}
        style={{ float: 'right' }}
        onClick={() => setOpen(true)}
        children={<FilterList />}></IconButton>
      {open && (
        <IssueFiltersDialog
          open={open}
          filters={filters}
          onClear={() => {
            setOpen(false);
            clearIssuesFilters();
          }}
          onSubmit={(filters) => {
            setOpen(false);
            setIssuesFilters(filters);
          }}
          hideCampaigns={hideCampaigns}
        />
      )}
    </>
  );
}

function IssueFiltersDialog({ open, onClear, onSubmit, filters, hideCampaigns }) {
  const [addCampaing, setAddCampaign] = useState(false);
  const [addAssignee, setAddAssignee] = useState(false);
  const [addStatus, setAddStatus] = useState(false);
  const [addType, setAddType] = useState(false);
  const [addSeverity, setAddSeverity] = useState(false);
  const [filtersEditor, setFiltersEditor] = useReducer((s, a) => ({ ...s, ...a }), filters);

  let { t } = useTranslation();
  let {
    campaigns = [],
    issueTypes = [],
    severities = [],
    dueDateGt = null,
    dueDateLt = null,
    teamCodes = [],
    assignee = [],
    statuses = [],
    propertiesNames = [],
    propertiesValues = [],
    nullPropertiesNames = []
  } = filtersEditor;
  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{'Issues Filters'}</DialogTitle>
      <DialogContent>
        {!hideCampaigns && (
          <>
            <Typography variant='subtitle1'>Campaigns</Typography>
            <ChipWrapper onRequestAdd={() => setAddCampaign(true)}>
              {campaigns.map((cmp) => (
                <ChipItem
                  key={cmp?.uuid}
                  id={cmp?.uuid}
                  label={cmp?.name}
                  tooltip={cmp?.description}
                  onRequestDelete={() =>
                    setFiltersEditor({
                      campaigns: campaigns.filter((camp) => camp?.uuid !== cmp?.uuid)
                    })
                  }></ChipItem>
              ))}
            </ChipWrapper>
          </>
        )}
        <Typography variant='subtitle1'>Assignees</Typography>
        <ChipWrapper onRequestAdd={() => setAddAssignee(true)}>
          {assignee.map((user) => (
            <ChipItem
              key={user?.uuid}
              id={user?.uuid}
              label={user?.displayName}
              onRequestDelete={() =>
                setFiltersEditor({
                  assignee: assignee.filter((a) => a?.uuid !== user?.uuid)
                })
              }></ChipItem>
          ))}
        </ChipWrapper>
        <AccountTeamChipEditor
          teamsCodes={teamCodes}
          onChange={(code) => setFiltersEditor({ teamCodes: code })}></AccountTeamChipEditor>
        <Typography variant='subtitle1'>Issue Type</Typography>
        <ChipWrapper onRequestAdd={() => setAddType(true)}>
          {issueTypes.map((type) => (
            <ChipItem
              key={type}
              id={type}
              label={t(`issueMngt.issues.types.${type}`)}
              onRequestDelete={() =>
                setFiltersEditor({
                  issueTypes: issueTypes.filter((a) => a !== type)
                })
              }></ChipItem>
          ))}
        </ChipWrapper>
        <Typography variant='subtitle1'>Severity</Typography>

        <ChipWrapper onRequestAdd={() => setAddSeverity(true)}>
          {severities.map((severity) => (
            <ChipItem
              key={severity}
              id={severity}
              label={t(`issueMngt.issues.severities.${severity}`)}
              onRequestDelete={() =>
                setFiltersEditor({
                  severities: severities.filter((a) => a !== severity)
                })
              }></ChipItem>
          ))}
        </ChipWrapper>
        <Typography variant='subtitle1'>Status</Typography>
        <ChipWrapper onRequestAdd={() => setAddStatus(true)}>
          {statuses.map((sts) => (
            <ChipItem
              label={t(`issueMngt.issues.statuses.${sts}`)}
              onRequestDelete={() =>
                setFiltersEditor({
                  statuses: statuses.filter((a) => a !== sts)
                })
              }></ChipItem>
          ))}
        </ChipWrapper>
        <Typography variant='subtitle1'>Due Date</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              fullWidth
              margin='dense'
              label='From Date'
              hintText='From Date'
              value={dueDateGt}
              maxDate={dueDateLt}
              onChange={(date) => setFiltersEditor({ dueDateGt: date })}
              clearable={true}
              hourAdjustment={hourAdjustments.startOfDay}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              fullWidth
              margin='dense'
              label='To Date'
              hintText='To Date'
              value={dueDateLt}
              minDate={dueDateGt}
              onChange={(date) => setFiltersEditor({ dueDateLt: date })}
              clearable={true}
              hourAdjustment={hourAdjustments.endOfDay}
            />
          </Grid>
        </Grid>
        <CustomPropertiesFilters
          resourceType={ResourceTypes.ISSUE}
          nullPropertiesNames={nullPropertiesNames}
          properties={_.zipWith(propertiesNames, propertiesValues, (name, value) => ({
            name,
            value
          }))}
          onChange={(newProperties) =>
            setFiltersEditor({
              propertiesNames: newProperties.map((p) => p.name),
              propertiesValues: newProperties.map((p) => p.value)
            })
          }
          onChangeNull={(nullNames) =>
            setFiltersEditor({
              nullPropertiesNames: nullNames
            })
          }
        />
        {addCampaing && (
          <CampaignSearchModal
            onSubmit={(campaign) => {
              setFiltersEditor({
                campaigns: campaigns.concat([campaign])
              });
              setAddCampaign(false);
            }}
            onCancel={() => setAddCampaign(false)}
            open={addCampaing}
          />
        )}
        {addAssignee && (
          <UserFiltersSearchModal
            onSubmit={(user) => {
              setFiltersEditor({
                assignee: assignee.concat([user])
              });
              setAddAssignee(false);
            }}
            onCancel={() => setAddAssignee(false)}
            open={addAssignee}
          />
        )}
        {addStatus && (
          <IssueStatusAddModal
            onSubmit={(status) => {
              setFiltersEditor({
                statuses: statuses.concat([status])
              });
              setAddStatus(false);
            }}
            onCancel={() => setAddStatus(false)}
            open={addStatus}
          />
        )}
        {addType && (
          <IssueTypesAddModal
            open={addType}
            onCancel={() => setAddType(false)}
            onSubmit={(type) => {
              setFiltersEditor({
                issueTypes: issueTypes.concat([type])
              });
              setAddType(false);
            }}
          />
        )}
        {addSeverity && (
          <IssueSeveritiesAddModal
            open={addSeverity}
            onCancel={() => setAddSeverity(false)}
            onSubmit={(severity) => {
              setFiltersEditor({
                severities: severities.concat([severity])
              });
              setAddSeverity(false);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.clear')} onClick={() => onClear()} />
        <Button
          variant='outlined'
          color='primary'
          children={t('commons.actions.apply')}
          onClick={() => onSubmit(filtersEditor)}
        />
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  filters: state.campaigns.issues.filters.content,
  hasFilters: state.campaigns.issues.filters.hasFilters || false
});

export default connect(mapStateToProps, { clearIssuesFilters, setIssuesFilters })(
  IssueFiltersModal
);
