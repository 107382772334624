import { combineReducers } from 'redux';
import definitions from './definitions';
import connections from './connections';
import schedule from './schedule';
import jobhistory from './jobHistory';
import webActionConfigurations from './webActionConfigurations';
export default combineReducers({
  connections,
  definitions,
  schedule,
  jobhistory,
  webActionConfigurations
});
