import { BlindataApi } from './ApiUtils';

export default class ProposalsApi extends BlindataApi {
  getProposals(params) {
    return this.get('/api/v1/workflows/proposals', params);
  }

  getProposal(uuid) {
    return this.get(`/api/v1/workflows/proposals/${uuid}`);
  }

  postProposal(proposal) {
    return this.post('/api/v1/workflows/proposals', proposal);
  }

  putProposal(proposal) {
    return this.put(`/api/v1/workflows/proposals/${proposal.uuid}`, proposal);
  }

  patchProposal(proposal) {
    return this.patch(`/api/v1/workflows/proposals/${proposal.uuid}`, proposal);
  }

  deleteProposal(uuid) {
    return this.delete(`/api/v1/workflows/proposals/${uuid}`);
  }

  postImmediateProposal(proposal) {
    return this.post('/api/v1/workflows/immediate-proposals', proposal);
  }
}
