import RpaTemplatesApi from '../api/RpaTemplates';
import { buildApiCallAction } from './ActionUtils';

import {
  DELETE_RPA_TEMPLATE,
  MODIFY_RPA_TEMPLATE,
  CREATE_RPA_TEMPLATE,
  FETCH_RPA_TEMPLATE,
  FETCH_RPA_TEMPLATES,
  EDIT_RPA_TEMPLATE
} from '../constants/ActionTypes';

let api = new RpaTemplatesApi();

export const fetchRpaTemplates = (page, size) =>
  buildApiCallAction({
    api: api,
    apiFunction: () => api.getRpaTemplates(page, size),
    actionType: FETCH_RPA_TEMPLATES
  });

export const fetchRpaTemplate = uuid =>
  buildApiCallAction({
    api: api,
    apiFunction: api => api.getRpaTemplate(uuid),
    actionType: FETCH_RPA_TEMPLATE
  });

export const createRpaTemplate = system =>
  buildApiCallAction({
    api: api,
    apiFunction: api => api.postRpaTemplate(system),
    actionType: CREATE_RPA_TEMPLATE
  });

export const modifyRpaTemplate = system =>
  buildApiCallAction({
    api: api,
    apiFunction: api => api.putRpaTemplate(system),
    actionType: MODIFY_RPA_TEMPLATE
  });

export const deleteRpaTemplate = rpaTemplate =>
  buildApiCallAction({
    api: api,
    apiFunction: api => api.deleteRpaTemplate(rpaTemplate.uuid),
    actionType: DELETE_RPA_TEMPLATE,
    payload: rpaTemplate
  });

export const editRpaTemplate = editValues => dispatch => {
  dispatch({
    type: EDIT_RPA_TEMPLATE,
    payload: editValues
  });
};
