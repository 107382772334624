import React from 'react';
import { connect } from 'react-redux'
import { searchDataActors } from '../../../actions/DataActorsActions'
import _ from 'lodash'
import ItemAutoCompleteModal from '../../../components/itemselector/ItemAutoCompleteModal';

const BusinessNameAutoCompleteModal = ({ open, contentByUuid, searchDataActors, onCancel, onSubmit }) => (
  <ItemAutoCompleteModal title='Search Business Names'
    itemName={'Business Name'}
    open={open}
    keyProperty={'businessName'}
    nameProperty={'businessName'}
    values={_.uniqBy(Object.values(contentByUuid), 'businessName')}
    onSearch={(searchText) => searchDataActors(searchText)}
    handleCancel={onCancel}
    handleSubmit={onSubmit} />
);

const mapStateToProps = (state, props) => ({
  contentByUuid: state.data_actors.contentByUuid
});

export default connect(mapStateToProps, { searchDataActors })(BusinessNameAutoCompleteModal);