import React from 'react';
import { Table, TableHead, TableRow, TableBody, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from 'components/tables/GenericTableCell';

function RequestTemplateHeadersTable({ editor, setEditor }) {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell align='left'>Name</TableCell>
          <TableCell>Value</TableCell>
          <TableCell align='right'></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(editor?.requestTemplate?.headers).map((headerKey) => (
          <TableRow key={headerKey}>
            <TableCell align='left'>{headerKey}</TableCell>
            <TableCell>{editor?.requestTemplate?.headers[headerKey]}</TableCell>
            <TableCell align='right'>
              <IconButton
                size='small'
                onClick={() =>
                  setEditor({
                    requestTemplate: {
                      ...editor?.requestTemplate,
                      headers: delete editor?.requestTemplate?.headers[headerKey] && {
                        ...editor?.requestTemplate?.headers
                      }
                    }
                  })
                }>
                <DeleteIcon></DeleteIcon>
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default RequestTemplateHeadersTable;
