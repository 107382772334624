import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import Breadcrumbs from 'pages/sidebar/Navigation';
import QualitySuitesList from '../list/QualitySuitesList';
import QualitySuiteFAB from './QualitySuiteFAB';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import QualitySuitPageFiltersSwitch from '../commons/QualitySuitePageFiltersSwitch';
import QualitySuiteExportButton from '../commons/QualitySuiteExportButton';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const QualitySuitesListPage = () => {
  const { t } = useTranslation();
  const [defaultPublished, setdefaultPublished] = useState(true);
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.kqis'), to: '/data-quality/kqis/suites' },
          { text: t('navbar.testSuites') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('quality.testSuites.header'),
          category: EngagementCategories.DATA_QUALITY,
          action: EngagementActions.LIST
        }}
      />

      <Grid container direction='row' alignItems='center' spacing={2}>
        <Grid item>
          <Typography variant='h4'>{t('quality.testSuites.header')}</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:qualitysuites-list'}
            iconSize={'small'}
            pageTitle={t('quality.testSuites.header')}
          />
        </Grid>
        <Grid item>
          <QualitySuiteExportButton></QualitySuiteExportButton>
        </Grid>
        <Grid item>
          <QualitySuitPageFiltersSwitch
            setdefaultPublished={setdefaultPublished}></QualitySuitPageFiltersSwitch>
        </Grid>
        <Grid item>
          <WebActionsButton page={TargetPages.QUALITY_SUITE_LIST_PAGE} />
        </Grid>
      </Grid>
      <QualitySuitesList />
      <Authorize hasPermissions={[Permissions.QUALITY_ADMIN]}>
        <QualitySuiteFAB defaultPublished={defaultPublished} />
      </Authorize>
    </>
  );
};

export default QualitySuitesListPage;
