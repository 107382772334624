import React from 'react';
import {connect} from 'react-redux';
import * as SystemLoginActions from '../../../actions/SystemLoginActions'
import {searchPhysicalEntities} from '../../../actions/PhysicalEntitiesActions'
import SystemLoginsList from './SystemLoginsList'
import {readCurrentPage} from '../../../reducers/systemlogins'
import _ from 'lodash'

const SystemLoginsContainer = (props)=>(
  <SystemLoginsList
    {...props}
  />
)

const mapStateToProps = (state,props) => ({
  systemUuid: props.match.params['systemUuid'],
  system: state.systems.content[props.match.params['systemUuid']],
  page: readCurrentPage(state.systemlogins.logins)(props.match.params['systemUuid']),
  grants: state.systemlogins.grants.content,
  physicalEntities: _(state.physicalentities.content)
      .map()
      .filter(pe => pe.system.uuid === props.match.params['systemUuid'])
      .value()
})

export default connect(
  mapStateToProps,
  {
    ...SystemLoginActions,
    searchPhysicalEntities
  }
)(SystemLoginsContainer)
