import { Button, Hidden } from '@mui/material';
import ExpandableCard from 'components/expandable-card/ExpandableCard';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { modifyQueryBucket, deleteQueryBucket } from 'actions/QueryBucketsActions';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import QueryBucketModal from '../commons/QueryBucketModal';
import { Link } from 'react-router-dom';
import { ChipItem } from 'components/chiplist/ChipList';
import TeamCodeDecorator from 'pages/settings/teams/common/TeamCodeDecorator';
import QueryBucketStats from '../commons/QueryBucketStats';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import { getIconShort, getTechnologyConfig } from 'constants/SystemTechnologyTypes';

const QueryBucketsListItem = ({ queryBucket, modifyQueryBucket, deleteQueryBucket }) => {
  const [open, setOpen] = useState(false);
  let { t } = useTranslation();
  return (
    <div className='card-custom-div' style={{ margin: '8px 0 8px 0' }}>
      <ExpandableCard
        zDepth={1}
        key={queryBucket.uuid}
        title={queryBucket.name}
        subheader={queryBucket.description}
        decorator={
          <>
            <Hidden mdDown>
              <QueryBucketStats queryBucket={queryBucket} />
            </Hidden>
            <TeamCodeDecorator teamCode={queryBucket.teamCode} />
          </>
        }
        children={
          <>
            <h4>{t('querybuckets.list.systems')}</h4>
            {(queryBucket?.systems || []).map((system) => (
              <ChipItem
                icon={getIconShort(getTechnologyConfig(system.technology), {
                  width: '2em',
                  height: '2em'
                })}
                key={system.uuid}
                id={system.uuid}
                label={system.name}
                to={`/systems/${system.uuid}`}
              />
            ))}
          </>
        }
        actions={[
          <Button
            key='link'
            component={Link}
            to={`/dataflows/queryparser/buckets/${queryBucket.uuid}`}>
            {t('querybuckets.actions.detail')}
          </Button>,
          <Authorize hasPermissions={[Permissions.QUERY_PARSER_EDITOR]}>
            <Button onClick={() => setOpen(true)}>{t('querybuckets.actions.modify')}</Button>
            <ConfirmButton
              message={t('querybuckets.delete_confirmation', {
                queryBucketName: queryBucket.name
              })}
              children={t('querybuckets.actions.delete')}
              onClick={() => deleteQueryBucket(queryBucket)}
            />
          </Authorize>
        ]}></ExpandableCard>
      <QueryBucketModal
        title={t('querybuckets.modify_title')}
        open={open}
        queryBucket={queryBucket}
        onClose={() => {
          setOpen(false);
        }}
        onSubmit={(queryBucket) => {
          setOpen(false);
          modifyQueryBucket(queryBucket);
        }}
      />
    </div>
  );
};

const mapDispatchToProps = {
  modifyQueryBucket,
  deleteQueryBucket
};

export default connect(null, mapDispatchToProps)(QueryBucketsListItem);
