import * as React from "react";
const SvgApacheDrill = (props) => (
  <svg
    id="ApacheDrill_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 252.5 124.5"
    style={{
      enableBackground: "new 0 0 252.5 124.5",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {".ApacheDrill_svg__st0{fill:#717073}.ApacheDrill_svg__st1{fill:#00467f}"}
    </style>
    <path
      className="ApacheDrill_svg__st0"
      d="M89.9 47.8h-3.4l-2.1-4.2H71.9l-2.1 4.2h-3.4l9.8-18.6H80l9.9 18.6zm-7-7-4.7-9-4.8 9h9.5zM108.1 29.2c1.9 0 3.4.5 4.5 1.6s1.7 2.5 1.7 4.2c0 1.8-.6 3.3-1.7 4.2-1.2 1-2.8 1.5-5 1.5H96.4v7.1h-3.1V29.2h14.8zm-.6 8.7c2.4 0 3.6-1 3.6-2.9 0-2.1-1.3-3.1-3.8-3.1H96.4v5.9h11.1zM137.1 47.8h-3.4l-2.1-4.2H119l-2.1 4.2h-3.4l9.8-18.6h3.9l9.9 18.6zm-7-7-4.7-9-4.8 9h9.5zM160.7 33.4l-2.8 1c-1.9-2-4.5-3-7.9-3-2.6 0-4.7.7-6.3 2-1.6 1.3-2.4 3-2.4 5 0 2.1.8 3.8 2.4 5.1 1.6 1.3 3.8 1.9 6.5 1.9 3.3 0 5.9-1 7.7-3.1l2.6 1.5c-1.3 1.6-2.8 2.7-4.5 3.4-1.7.7-3.7 1-6 1-3.3 0-6-.8-8.2-2.5-2.5-1.9-3.7-4.3-3.7-7.4 0-3 1.3-5.4 3.8-7.3 2.2-1.7 4.9-2.5 8.1-2.5 4.6.1 8.2 1.7 10.7 4.9zM186.4 47.8h-3.1v-8.3h-14.9v8.3h-3.1V29.2h3.1v7.5h14.9v-7.5h3.1v18.6zM211 47.8h-19.4V29.2h18.7V32h-15.6v4.8h10.9v2.8h-10.9V45H211v2.8z"
    />
    <path
      className="ApacheDrill_svg__st1"
      d="M97.1 55.6c5 0 9 1.3 11.9 4 2.9 2.7 4.4 6.2 4.4 10.7 0 4.1-1.6 7.5-4.7 10.3-2.9 2.5-6.5 3.8-10.7 3.8H78.8V55.6h18.3zm1.3 24.5c2.9 0 5.2-.9 7.1-2.8 1.8-1.8 2.8-4.2 2.8-7.1 0-3.1-1-5.6-3-7.5-2-1.9-4.6-2.8-7.8-2.8H83.6v20.2h14.8zM153 84.4h-5.6l-7.8-11h-14.5v11h-4.8V55.6H143c3 0 5.4.8 7.1 2.4 1.7 1.6 2.6 3.8 2.6 6.6 0 2.4-.7 4.4-2.1 5.9-1.3 1.4-3.1 2.3-5.4 2.6l7.8 11.3zm-10.6-15.3c3.7 0 5.5-1.5 5.5-4.5 0-3.2-2-4.8-6-4.8h-16.8V69h17.3zM165.2 84.4h-4.8V55.6h4.8v28.8zM201.9 84.4h-27V55.6h4.8v24.5h22.2v4.3zM234.5 84.4h-27V55.6h4.8v24.5h22.2v4.3z"
    />
    <linearGradient
      id="ApacheDrill_svg__SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={33.233}
      y1={68.463}
      x2={56.9}
      y2={59.13}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#c2c2c4",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#717073",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#ApacheDrill_svg__SVGID_1_)",
      }}
      d="m14.2 18.5 2.4 5.9 9.6-6.2-7.9 10.4-2.1 8.5 5.7.2 1.4 3.4 9.5-6.3L25 44.8l-2.3 8.1 5.7.2 1.3 3.2 9.4-6.2-7.6 10.7-2.3 8 5.6.2 1.4 3.3 9.8-6.4v.1l-7.8 10.5-1.2 7h6.5l2.2 3L51 83l-2.9 7.1-1.3 6.3 6.1-.1 1.8 2.4 4.1-2.7-2.3 5.1 9.1 12.4-1.3-8.7 2.3-5.8-3.1-.1L62 87.8l3.1-9.2-4.4-.1L59.2 68l2.1-7.8-5-.2-3.1-7.7 2.2-8.2-5.7-.2-2.9-7.2 2.1-8.4-5.6-.2-3-7.3 2.1-8.4-5.6-.2-1.1-2.5z"
    />
  </svg>
);
export default SvgApacheDrill;
