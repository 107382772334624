import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { DialogTitle, DialogActions, DialogContent } from '@mui/material';
import ItemAutoComplete from './ItemAutoComplete';

class ItemAutoCompleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  componentWillReceiveProp(props) {
    this.setState({ value: props.value });
  }

  render() {
    return (
      <Dialog
        fullScreen={this.props.fullScreen}
        open={this.props.open}
        fullWidth={true}
        maxWidth='sm'>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent style={{ minWidth: 350 }}>
          <ItemAutoComplete {...this.props} onChange={(val) => this.setState({ value: val })} />
        </DialogContent>
        <DialogActions>
          <Button children='Cancel' onClick={this.props.handleCancel} />
          <Button
            children='Submit'
            variant='contained'
            color='primary'
            disabled={this.state.value === undefined}
            onClick={() => this.props.handleSubmit(this.state.value)}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

ItemAutoCompleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.object,
  values: PropTypes.array.isRequired,
  keyProperty: PropTypes.string.isRequired,
  nameProperty: PropTypes.string.isRequired,
  categoryProperties: PropTypes.array,
  labelSeparator: PropTypes.string,
  onSearch: PropTypes.func,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default ItemAutoCompleteModal;
