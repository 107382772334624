import { buildApiCallAction } from './ActionUtils';
import { showMessage } from './BehaviourActions';
import {
  REQUEST_GRAPH,
  RECEIVE_GRAPH,
  CLEAR_GRAPH,
  CLEAR_GRAPH_FILTERS,
  ADD_GRAPH_UUID_FILTER,
  REMOVE_GRAPH_UUID_FILTER,
  ADD_GRAPH_PROPERTY_FILTER,
  REMOVE_GRAPH_PROPERTY_FILTER,
  CHANGE_GRAPH_FILTER_EDITOR,
  CHANGE_GRAPH_PERSPECTIVE_FILTER,
  GRAPH_NODE_EXPANSION,
  OPEN_GRAPH_CONTEXTUAL_MENU,
  FETCH_GRAPH_NODE_DETAIL,
  CLOSE_GRAPH_CONTEXTUAL_MENU,
  GRAPH_REMOVE_ELEMS
} from '../constants/ActionTypes';
import GraphApi from '../api/GraphApi';
import _ from 'lodash';

export const fetchGraph = () => (dispatch, getState) => {
  const filters = getState().graph.filters;
  let uuidFilters = _(filters.uuidFilters)
    .map((e) => ({ entityType: e.entityType, uuid: e.object.uuid }))
    .groupBy('entityType')
    .mapValues((uuids) => _.map(uuids, 'uuid'))
    .mapKeys((v, k) => filtersSetting[k])
    .value();
  let propsFilters = _(filters.propertyFilters)
    .map((e) => ({ entityType: e.entityType, prop: e.name }))
    .groupBy('entityType')
    .mapValues((names) => _.map(names, 'prop'))
    .mapKeys((v, k) => filtersSetting[k] + 'Props')
    .value();
  let entityTypes = getEntityTypesFilters(filters);
  let includeDataFlows = filters.dataFlowsPerspective;
  let dataFlowsDepth = filters.dataFlowsDepth;
  let dataFlowsDepthHierarchy = filters.dataFlowsDepthHierarchy;
  let includeLogicalRelations = filters.includeLogicalRelations;
  _fetchGraphActually({
    ...uuidFilters,
    ...propsFilters,
    entityTypes,
    includeDataFlows,
    dataFlowsDepth,
    dataFlowsDepthHierarchy,
    includeLogicalRelations
  })(dispatch, getState);
};

const perspectiveConfig = {
  logicalPerspective: ['DATA_CATEGORY', 'LOGICAL_FIELD'],
  businessPerspective: ['DATA_ACTOR', 'TASK', 'PROCESSING'],
  loginsPerspective: ['DATA_ACTOR', 'SYSTEM_LOGIN', 'SYSTEM'],
  physicalPerspective: ['SYSTEM', 'PHYSICAL_ENTITY', 'PHYSICAL_FIELD']
};

const filtersSetting = {
  PHYSICAL_ENTITY: 'physicalEntities',
  SYSTEM_LOGIN: 'systemLogins',
  DATA_ACTOR: 'dataActors',
  TASK: 'tasks',
  PROCESSING: 'processings',
  DATA_CATEGORY: 'dataCategories',
  SYSTEM: 'systems',
  LOGICAL_FIELD: 'logicalFields',
  PHYSICAL_FIELD: 'physicalFields'
};

export const _fetchGraphActually = (params) =>
  buildApiCallAction({
    api: new GraphApi(),
    apiFunction: (api) => api.getGraphs(params),
    actionType: RECEIVE_GRAPH,
    onError: clearGraph()
  });

export const clearGraph = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_GRAPH });
};

export const clearGraphFilters = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_GRAPH_FILTERS });
};

export const requestGraph = () => (dispatch, getState) => {
  dispatch({ type: REQUEST_GRAPH });
};
//{ entityType, openResourceFilterEditor,openPropertyFilterEditor}
export const changeGraphFilterEditor = (stateDiff) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_GRAPH_FILTER_EDITOR,
    payload: stateDiff
  });
};

export const addGraphUuidFilter =
  ({ entityType, name, object }) =>
  (dispatch, getState) => {
    dispatch({
      type: ADD_GRAPH_UUID_FILTER,
      payload: { entityType, name, object }
    });
  };

export const removeGraphUuidFilter = (filter) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_GRAPH_UUID_FILTER,
    payload: filter
  });
};

export const addGraphPropertyFilter = (filter) => (dispatch, getState) => {
  dispatch({
    type: ADD_GRAPH_PROPERTY_FILTER,
    payload: filter
  });
};

export const removeGraphPropertyFilter = (filter) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_GRAPH_PROPERTY_FILTER,
    payload: filter
  });
};

export const changePerspective = (perspectives) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_GRAPH_PERSPECTIVE_FILTER,
    payload: perspectives
  });
};

export const focusNode = (node) => (dispatch, getState) => {
  if (!node) {
    showMessage('Error')(dispatch, getState);
    return;
  }
  let entityType = node.labels[0];
  if (!nodeNames[entityType]) {
    showMessage('Node entity type not supported')(dispatch, getState);
    return;
  }
  getState().graph.filters.propertyFilters.forEach((pf) => {
    if (pf.entityType === entityType) {
      removeGraphPropertyFilter(pf)(dispatch, getState);
    }
  });
  addGraphUuidFilter({
    name: node.data[nodeNames[entityType].name],
    entityType,
    object: node.data
  })(dispatch, getState);
  clearGraph()(dispatch, getState);
  requestGraph()(dispatch, getState);
  fetchGraph()(dispatch, getState);
};

export const expandNode = (node) => (dispatch, getState) => {
  let queryParams = {
    includeDataFlows: getState().graph.filters.dataFlowsPerspective,
    includeLogicalRelations: getState().graph.filters.includeLogicalRelations,
    entityTypes: getEntityTypesFilters(getState().graph.filters)
  };
  buildApiCallAction({
    api: new GraphApi(),
    apiFunction: (api) => api.expansion(node, queryParams),
    actionType: GRAPH_NODE_EXPANSION
  })(dispatch, getState);
};
const nodeNames = {
  DATA_ACTOR: {
    name: 'name'
  },
  SYSTEM_LOGIN: {
    name: 'username'
  },
  PHYSICAL_ENTITY: {
    name: '_fullName'
  },
  TASK: {
    name: 'name'
  },
  PROCESSING: {
    name: 'name'
  },
  DATA_CATEGORY: {
    name: 'name'
  },
  LOGICAL_FIELD: {
    name: 'name'
  },
  SYSTEM: {
    name: 'name'
  },
  PHYSICAL_FIELD: {
    name: 'name'
  }
};

export const fetchGraphNodeDetail = (node) =>
  buildApiCallAction({
    api: new GraphApi(),
    apiFunction: (api) => api.getGraphNodeDetail(node),
    actionType: FETCH_GRAPH_NODE_DETAIL
  });

export const openContextualMenu =
  ({ node, edge, pointer }) =>
  (dispatch, getState) => {
    if (node && !getState().graph.nodeDetails[node.id]) {
      fetchGraphNodeDetail(node)(dispatch, getState);
    }
    dispatch({
      type: OPEN_GRAPH_CONTEXTUAL_MENU,
      payload: { node, edge, pointer }
    });
  };

export const closeContextualMenu = () => (dispatch) => {
  dispatch({
    type: CLOSE_GRAPH_CONTEXTUAL_MENU
  });
};

export const removeEdge = (edge, graphHandler) => (dispatch) => {
  graphHandler.removeGraph({ edges: [edge] });
  dispatch({
    type: GRAPH_REMOVE_ELEMS
  });
};

function getEntityTypesFilters(filters) {
  let entityTypes = [];
  if (filters.logicalPerspective)
    entityTypes = entityTypes.concat(perspectiveConfig.logicalPerspective);
  if (filters.businessPerspective)
    entityTypes = entityTypes.concat(perspectiveConfig.businessPerspective);
  if (filters.loginsPerspective)
    entityTypes = entityTypes.concat(perspectiveConfig.loginsPerspective);
  if (filters.physicalPerspective)
    entityTypes = entityTypes.concat(perspectiveConfig.physicalPerspective);
  return entityTypes;
}
