import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import React from 'react';

export const DefaultSettingsCard = ({ mergedSettings, setPatchedSettings }) => {
  return (
    <Card variant='outlined' style={{ height: '100%' }}>
      <CardHeader subheader='Default Settings' />
      <CardContent>
        <Grid container alignItems='center' spacing={8}>
          <Grid item xs={4}>
            <Typography variant='body1'>Email Notifications</Typography>
          </Grid>
          <Grid item xs={8}>
            <Tooltip
              title='Receive a daily summary of unread notifications'
              placement='right'
              arrow>
              <FormControlLabel
                label='Daily Summary'
                control={
                  <Checkbox
                    checked={mergedSettings.emailSummary}
                    onClick={() =>
                      setPatchedSettings({
                        ...mergedSettings,
                        emailSummary: !mergedSettings.emailSummary
                      })
                    }
                  />
                }
              />
            </Tooltip>
            <br></br>
            <Tooltip
              placement='right'
              title='Receive an email as soon as a notification is created'
              arrow>
              <FormControlLabel
                label='Send Immediately'
                control={
                  <Checkbox
                    checked={mergedSettings.emailImmediate}
                    onClick={() =>
                      setPatchedSettings({
                        ...mergedSettings,
                        emailImmediate: !mergedSettings.emailImmediate
                      })
                    }
                  />
                }
              />
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
