import crudReducers from '../utils';
import { combineReducers } from 'redux';
import {
  FETCH_CUSTOM_PROPERTY_DEFINITIONS_PAGE,
  FETCH_CUSTOM_PROPERTY_DEFINITIONS_VALUES_PAGE
} from '../../constants/ActionTypes';

function loaded(state = false, action) {
  if (action.type === FETCH_CUSTOM_PROPERTY_DEFINITIONS_PAGE) {
    return true;
  } else {
    return state;
  }
}

function values(state = {}, action) {
  if (action.type === FETCH_CUSTOM_PROPERTY_DEFINITIONS_VALUES_PAGE) {
    return { ...state, [action.payload.uuid]: action.payload.content.map((e) => e.value) };
  } else {
    return state;
  }
}

export default combineReducers({
  ...crudReducers({ resource: 'CUSTOM_PROPERTY_DEFINITION', keyProperty: 'uuid' }),
  loaded,
  values
});
