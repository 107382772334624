import { buildApiCallAction } from './ActionUtils';
import QualityChecksApi from '../api/QualityChecksApi';
import {
  CREATE_QUALITY_CHECK,
  FETCH_QUALITY_CHECK,
  FETCH_QUALITY_CHECKS_PAGE,
  FETCH_QUALITY_CHECK_GROUP_CODES,
  MODIFY_QUALITY_CHECK,
  DELETE_QUALITY_CHECK,
  SEARCH_QUALITY_CHECKS,
  UPDATE_CHECKS_OPTIONS_ENABLED,
  EXPORT_QUALITY_CHECK,
  FETCH_QUALITY_CHECKS_PANEL_PAGE
} from '../constants/ActionTypes';

const api = new QualityChecksApi();

export const searchQualityCheck = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getQualityChecks(params),
    actionType: SEARCH_QUALITY_CHECKS
  });

export const fetchQualityCheck = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getQualityCheck(uuid),
    actionType: FETCH_QUALITY_CHECK
  });

export const fetchQualityChecksPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getQualityChecks(params),
    actionType: FETCH_QUALITY_CHECKS_PAGE
  });

export const fetchQualityChecksPanelPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getQualityChecks({ ...params, enabled: true }),
    actionType: FETCH_QUALITY_CHECKS_PANEL_PAGE,
    payload: (response) => ({
      ...response,
      physicalEntityUuid: params.physicalEntityUuid,
      physicalFieldUuid: params.physicalFieldUuid
    })
  });

export const createQualityCheck = (qualityCheck) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postQualityCheck(qualityCheck),
    actionType: CREATE_QUALITY_CHECK
  });

export const modifyQualityCheck = (qualityCheck) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putQualityCheck(qualityCheck),
    actionType: MODIFY_QUALITY_CHECK
  });

export const deleteQualityCheck = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteQualityCheck(uuid),
    actionType: DELETE_QUALITY_CHECK
  });

export const updateChecksOptionsEnabled = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.updateChecksOptionsEnabled(params),
    actionType: UPDATE_CHECKS_OPTIONS_ENABLED
  });

export const exportQualityCheck = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportQualityCheck(params),
    actionType: EXPORT_QUALITY_CHECK
  });

export const fetchCheckGroupCodes = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getCheckGroupCode(params),
    actionType: FETCH_QUALITY_CHECK_GROUP_CODES
  });
