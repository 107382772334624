import { buildApiCallAction } from 'actions/ActionUtils';

import {
  CREATE_AGENT_SCHEDULE,
  FETCH_AGENT_SCHEDULES_PAGE,
  FETCH_AGENT_SCHEDULE,
  MODIFY_AGENT_SCHEDULE,
  DELETE_AGENT_SCHEDULE
} from 'constants/ActionTypes';
import AgentSchedulesApi from 'api/onagent/AgentSchedulesApi';

export const fetchSchedule = (agent, params) =>
  buildApiCallAction({
    api: new AgentSchedulesApi(agent),
    apiFunction: (api) => api.getSchedule(params),
    actionType: FETCH_AGENT_SCHEDULES_PAGE,
    payload: (response) => ({
      ...response,
      externalIdentifier: params?.externalIdentifier,
      agentUuid: agent?.uuid
    })
  });

export const fetchSingleSchedule = (agent, uuid) =>
  buildApiCallAction({
    api: new AgentSchedulesApi(agent),
    apiFunction: (api) => api.getSingleSchedule(uuid),
    actionType: FETCH_AGENT_SCHEDULE
  });

export const createSchedule = (agent, schedule) =>
  buildApiCallAction({
    api: new AgentSchedulesApi(agent),
    apiFunction: (api) => api.postSchedule(schedule),
    actionType: CREATE_AGENT_SCHEDULE
  });

export const modifySchedule = (agent, schedule) =>
  buildApiCallAction({
    api: new AgentSchedulesApi(agent),
    apiFunction: (api) => api.putSchedule(schedule),
    actionType: MODIFY_AGENT_SCHEDULE
  });

export const deleteSchedule = (agent, schedule) =>
  buildApiCallAction({
    api: new AgentSchedulesApi(agent),
    apiFunction: (api) => api.deleteSchedule(schedule.uuid),
    actionType: DELETE_AGENT_SCHEDULE,
    payload: schedule
  });
