import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

function TemplateParamsModal({ title, open, handleCancel, handleSubmit }) {
  const [parameter, setParameter] = useState(null);
  const { t } = useTranslation();
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          required
          fullWidth
          margin='dense'
          label='Name'
          placeholder='Enter the parameter name'
          value={parameter?.name}
          onChange={(event) => setParameter({ ...parameter, name: event.target.value })}
        />
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          label='Default Value'
          placeholder='Enter the default value'
          value={parameter?.defaultValue}
          onChange={(event) => setParameter({ ...parameter, defaultValue: event.target.value })}
        />
        <FormControl variant='standard' fullWidth margin='dense'>
          <InputLabel>Mode</InputLabel>
          <Select
            variant='standard'
            value={parameter?.mode}
            onChange={(event) => setParameter({ ...parameter, mode: event.target.value })}>
            <MenuItem key={'Automatic'} value={'AUTOMATIC'}>
              {'AUTOMATIC'}
            </MenuItem>
            <MenuItem key={'Manual'} value={'MANUAL'}>
              {'MANUAL'}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant='standard' fullWidth margin='dense'>
          <InputLabel>Mandatory</InputLabel>
          <Select
            variant='standard'
            value={parameter?.mandatory}
            onChange={(event) =>
              setParameter({
                ...parameter,
                mandatory: event.target.value
              })
            }>
            <MenuItem key={'True'} value={true}>
              {'True'}
            </MenuItem>
            <MenuItem key={'False'} value={false}>
              {'False'}
            </MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={!parameter || !parameter.name}
          variant='contained'
          color='primary'
          onClick={() => handleSubmit(parameter)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TemplateParamsModal;
