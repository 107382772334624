import React from 'react';
import { connect } from 'react-redux';
import ConsentDetailItem from './ConsentDetailItem';
import { getConsent } from '../../../actions/ConsentsActions';
import { Typography } from '@mui/material';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

class ConsentsDetailContainer extends React.Component {
  componentDidMount() {
    this.props.getConsent(this.props.consentUuid);
  }

  render = () => (
    <div>
      <Breadcrumbs elements={[{ text: 'Consents', to: '/consents' }, { text: 'Detail' }]} />
      <Tracking
        info={{
          pageTitle: `Consent Detail`,
          category: EngagementCategories.CONSENTS,
          action: EngagementActions.DETAIL
        }}
      />
      <Typography variant='h4'>Consent Detail</Typography>
      {this.props.consent ? <ConsentDetailItem consent={this.props.consent} /> : null}
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  consentUuid: props.match.params['consentUuid'],
  consent: state.consents.content[props.match.params['consentUuid']]
});

export default connect(mapStateToProps, { getConsent })(ConsentsDetailContainer);
