import { handleErrors, parseJson, encodeQueryData } from './ApiUtils'

const pageSize = '20';

export default class ContractsApi {

  token = ''

  /*
   * Contracts Configurations endpoints 
   */

  getContractConfigurations(page, size = pageSize, filters = {} ) {
    return fetch(`/api/v1/contracts/configurations?${encodeQueryData({ ...filters, page, size })}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson)
  }

  getContractConfiguration(uuid) {
    return fetch(`/api/v1/contracts/configurations/${uuid}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson)
  }

  postContractConfiguration(contractTemplate) {
    return fetch(`/api/v1/contracts/configurations`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(contractTemplate)
    })
      .then(handleErrors)
      .then(parseJson)
  }

  putContractConfiguration(contractConfiguration) {
    return fetch(`/api/v1/contracts/configurations/${contractConfiguration.uuid}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(contractConfiguration)
    })
      .then(handleErrors)
      .then(parseJson)
  }

  deleteContractConfiguration(contractConfiguration) {
    return fetch(`/api/v1/contracts/configurations/${contractConfiguration.uuid}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
  }


  /*
   * Contracts Templates endpoints 
   */

  getContractTemplates(page, size = pageSize) {
    return fetch(`/api/v1/contracts/templates?page=${page}&size=${size}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson)
  }

  getContractTemplate(uuid) {
    return fetch(`/api/v1/contracts/templates/${uuid}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson)
  }

  postContractTemplate(contractTemplate) {
    return fetch(`/api/v1/contracts/templates`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(contractTemplate)
    })
      .then(handleErrors)
      .then(parseJson)
  }

  putContractTemplate(contractTemplate) {
    return fetch(`/api/v1/contracts/templates/${contractTemplate.uuid}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(contractTemplate)
    })
      .then(handleErrors)
      .then(parseJson)
  }

  deleteContractTemplate(contractTemplate) {
    return fetch(`/api/v1/contracts/templates/${contractTemplate.uuid}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
  }

}