import { FETCH_PHYSICAL_CONSTRAINTS_PAGE_BY_TYPE } from 'constants/ActionTypes';
import ResourceTypes from 'constants/ResourceTypes';
import { combineReducers } from 'redux';
import crudReducers from './utils';
import filtersReducer from './utils/filters';

const contentByType = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PHYSICAL_CONSTRAINTS_PAGE_BY_TYPE:
      return {
        ...state,
        [action.payload.constraintType]: action.payload
      };
    default:
      return state;
  }
};

const filtersDefault = {};

export default combineReducers({
  contentByType,
  filters: filtersReducer({ resource: ResourceTypes.PHYSICAL_CONSTRAINT, filtersDefault }),
  ...crudReducers({ resource: ResourceTypes.PHYSICAL_CONSTRAINT, keyProperty: 'uuid' })
});
