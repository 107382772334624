import React from 'react';
import ToolbarActions from '../../sidebar/ToolbarActions';
import { Button } from '@mui/material';
import { useRouteMatch, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

export default function QualityKqisNavbar(props) {
  let { url } = useRouteMatch();
  let { pathname } = useLocation();
  const suitesUrl = `${url}/suites`;
  const qualityDashboardUrl = `${url}/dashboards`;
  return (
    <ToolbarActions>
      <Button
        color={pathname === qualityDashboardUrl ? 'primary' : 'grey'}
        children='Dashboards'
        component={Link}
        to={qualityDashboardUrl}></Button>
      <Button
        color={pathname === suitesUrl ? 'primary' : 'grey'}
        children='Suites'
        component={Link}
        to={suitesUrl}></Button>
    </ToolbarActions>
  );
}
