import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { connect } from 'react-redux';
import { exportResults } from 'actions/QualityResultsActions';

function QualityResultExportButton({ filters, onClick, checkUuid, fromDashboard, size }) {
  return (
    <Tooltip title='Export CSV'>
      <IconButton
        size={size || 'small'}
        color='default'
        aria-label='Download'
        onClick={() => onClick({ ...filters, checkUuid })}>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}
const mapStateToProps = (state, props) => ({
  filters: !props.fromDashboard
    ? state.quality.qualityResults.filters.content
    : state.quality.qualityDashboards.overall.filters.content
});

function mapDispatchToProps(dispatch) {
  return {
    onClick: (filters) => dispatch(exportResults(filters))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityResultExportButton);
