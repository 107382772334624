import React from 'react';
import { TextField } from '@mui/material';

function AgentjobConfigurationsSettings({ setConf, conf }) {
  return (
    <>
      <h4>Configuration</h4>
      <TextField
        required
        minRows={5}
        variant='standard'
        multiline
        fullWidth
        margin='dense'
        label='Raw Configuration'
        placeholder='Insert a valid JSON to configure your job'
        value={conf}
        onChange={(event) => setConf(event.target.value)}
      />
    </>
  );
}

export default AgentjobConfigurationsSettings;
