import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ItemAutoComplete from '../../../components/itemselector/ItemAutoComplete';
import { searchTransferExtraEuOrganizations } from '../../../actions/SecurityMeasureActions';
function SafeguardTypeEditor({
  searchTransferExtraEuOrganizations,
  onChange,
  country,
  safeguardType,
  linkToDocs
}) {
  let { t } = useTranslation();
  let [samples, setSamples] = useState([]);
  let [countryBasedSuggestions, setCountryBasedSuggestions] = useState(true);
  useEffect(() => {
    searchTransferExtraEuOrganizations({
      country: countryBasedSuggestions ? country : null
    }).then((response) => setSamples(response.content));
  }, [setSamples, country, countryBasedSuggestions, searchTransferExtraEuOrganizations]);
  return (
    <>
      <ItemAutoComplete
        itemName={t('processings.transferToExtraEUOrganizations.fields.safeguardType')}
        placeholder={t('processings.transferToExtraEUOrganizations.fields.safeguardTypeHint')}
        value={{ safeguardType, linkToDocs }}
        values={samples}
        nameProperty='safeguardType'
        subheaderProperty='linkToDocs'
        keyProperty='null'
        onChange={(newValue) =>
          onChange({ safeguardType: newValue?.safeguardType, linkToDocs: newValue?.linkToDocs })
        }
        onSearch={(text) => onChange({ safeguardType: text, linkToDocs })}
      />
      <FormControlLabel
        label='Country based suggestions'
        control={
          <Checkbox
            size='small'
            checked={countryBasedSuggestions}
            onChange={() => setCountryBasedSuggestions(!countryBasedSuggestions)}
            value='countryBasedSuggestions'
          />
        }
      />
      <TextField
        variant='standard'
        fullWidth
        placeholder={t('processings.transferToExtraEUOrganizations.fields.linkToDocsHint')}
        label={t('processings.transferToExtraEUOrganizations.fields.linkToDocs')}
        margin='normal'
        value={linkToDocs || ''}
        onChange={(event) => onChange({ safeguardType, linkToDocs: event.target.value })}
      />
    </>
  );
}

const mapDispatchToProps = {
  searchTransferExtraEuOrganizations
};

export default connect(null, mapDispatchToProps)(SafeguardTypeEditor);
