import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as TasksActions from '../../../actions/TasksActions';
import TasksList from './TasksList';
import PaginatedPanel from '../../../components/pagination/PaginatedPanel';
import TaskFinderButton from './TaskFinderButton';
import TasksFiltersButton from './TasksFiltersButton';
import { Typography, Grid } from '@mui/material';
import TaskExportButton from '../utils/TaskExportButton';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';
const TasksContainer = (props) => (
  <React.Fragment>
    <Breadcrumbs elements={[{ text: 'Tasks', to: '/tasks' }]} />
    <Tracking
      info={{
        pageTitle: props.t('tasks.header'),
        category: EngagementCategories.TASKS,
        action: EngagementActions.LIST
      }}
    />
    <Grid container justifyContent='flex-start'>
      <Grid item>
        <Typography variant='h4'> {props.t('tasks.header')}</Typography>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}></Grid>
      <Grid item>
        <TaskFinderButton history={props.history} />
      </Grid>
      <Grid item>
        <FavoritesWidget
          itemIdentifier={'page:tasks-list'}
          pageTitle={props.t('tasks.header')}
          iconSize='small'
        />
      </Grid>
      <Grid item>
        <TaskExportButton></TaskExportButton>
      </Grid>
      <Grid item>
        <TasksFiltersButton onRefresh={props.fetchTasksPageWithFilters} />
      </Grid>
      <Grid item>
        <WebActionsButton page={TargetPages.TASK_LIST_PAGE} />
      </Grid>
    </Grid>
    <PaginatedPanel
      currentPage={props.currentPage}
      onPageSelection={props.fetchTasksPageWithFilters}
      autoload>
      <TasksList {...props} />
    </PaginatedPanel>
  </React.Fragment>
);

const mapStateToProps = (state, props) => ({
  currentPage: state.tasks.currentPage,
  tasks: state.tasks.currentPage.content || []
});

export default connect(mapStateToProps, {
  ...TasksActions
})(withTranslation()(TasksContainer));
