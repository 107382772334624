import React from 'react';
import { TextField } from '@mui/material';
import TimeZonesMenuItem from 'components/timezone/TimeZonesMenuItem';
import CronExpressionTextField from 'components/CronExpressionTextField';
import moment from 'moment';

function AgentJobGeneralSection({ editor, setEditor }) {
  return (
    <>
      <TextField
        required
        variant='standard'
        fullWidth
        margin='dense'
        label='Job Name'
        value={
          editor?.name || `${editor?.resourceName} Job ${moment(new Date()).format('DD MMM YYYY')}`
        }
        onChange={(event) => setEditor({ ...editor, name: event.target.value })}
      />
      <CronExpressionTextField
        value={editor?.cronExpression || ''}
        onChange={(cronExpression) => setEditor({ ...editor, cronExpression: cronExpression })}
      />
      <TimeZonesMenuItem
        value={editor?.timezoneId}
        onChange={(timezoneId) => setEditor({ ...editor, timezoneId: timezoneId })}
      />
    </>
  );
}

export default AgentJobGeneralSection;
