import React from 'react';
import { connect } from 'react-redux';
import { AddFAB } from '../../../../components/fab';
import Loader from '../../../../components/loader/Loader';
import {
  deleteContractTemplate,
  fetchContractTemplatesPage
} from '../../../../actions/ContractTemplatesActions';
import Authorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';
import { TemplatesList } from './TemplatesList';
import ContractsNavbar from '../../ContractsNavbar';
import { Grid, Typography } from '@mui/material';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
const TemplatesPage = ({ page, fetchContractTemplatesPage, deleteContractTemplate, history }) => (
  <Loader onLoad={() => fetchContractTemplatesPage(0)}>
    <div>
      <Breadcrumbs elements={[{ text: 'Contracts', to: '/contracts' }]} />
      <Tracking
        info={{
          pageTitle: `Contracts Templates`,
          category: EngagementCategories.CONSENTS,
          action: EngagementActions.LIST
        }}
      />

      <ContractsNavbar />
      <Grid container>
        <Grid
          item
          style={{
            flexGrow: 1
          }}>
          <Typography variant='h4'>Contracts Templates</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:template-list'}
            pageTitle={'Contracts Templates'}></FavoritesWidget>
        </Grid>
      </Grid>
      <TemplatesList {...{ page, fetchContractTemplatesPage, deleteContractTemplate }} />
      <Authorize hasPermissions={[Permissions.CONTRACTS_ADMIN]}>
        <AddFAB onClick={() => history.push('/contracts/templates/editor/create')} />
      </Authorize>
    </div>
  </Loader>
);

const mapStateToProps = (state, props) => ({
  page: state.contracts.templates.currentPage
});

export default connect(mapStateToProps, {
  fetchContractTemplatesPage,
  deleteContractTemplate
})(TemplatesPage);
