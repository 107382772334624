import { buildApiCallAction } from './ActionUtils';
import AssignmentsConfigApi from '../api/AssignmentsConfigApi';
import { FETCH_ASSIGNMENTS_CONFIG, MODIFY_ASSIGNMENTS_CONFIG } from '../constants/ActionTypes';

const api = new AssignmentsConfigApi();

export const fetchAssignmentsConfig = () => 
    buildApiCallAction({
        api, 
        apiFunction: (api) => api.getAllAssignmentsConfig(),
        actionType: FETCH_ASSIGNMENTS_CONFIG
    })

export const modifyAssignmentsConfig = (assignmentsConfig) => 
    buildApiCallAction({
        api,
        apiFunction: (api) => api.putAssignmentsConfig(assignmentsConfig),
        actionType: MODIFY_ASSIGNMENTS_CONFIG
    })