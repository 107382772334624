import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import Typography from '@mui/material/Typography';
import {
  greenSemaphoreColor,
  yellowSemaphoreColor,
  redSemaphoreColor
} from '../../quality_checks/commons/QualitySemaphoreColors';
import _ from 'lodash';
import QualityChecksPieChartDrillDownModal from './QualityChecksPieChartDrillDownModal';

function QualitySuitePieChart({ headers, values, onRequestDrillDownData, drillDownData }) {
  let greenSemaphores,
    yellowSemaphores,
    redSemaphores = 0;
  let isDataAvailable = false;
  const [drillDownOpen, setDrillDownOpen] = useState(false);
  const [selectedSemaphore, setSelectedSemaphore] = useState(null);
  if (values) {
    const labels = _.map(headers, (h) => h.label);
    greenSemaphores = values[_.indexOf(labels, 'GreenSemaphore')];
    yellowSemaphores = values[_.indexOf(labels, 'YellowSemaphore')];
    redSemaphores = values[_.indexOf(labels, 'RedSemaphore')];
    isDataAvailable = greenSemaphores + yellowSemaphores + redSemaphores > 0;
  }
  const handleClose = () => {
    setDrillDownOpen(false);
    setSelectedSemaphore(null);
  };

  return (
    <>
      <Chart
        width='250px'
        height='250px'
        chartType='PieChart'
        loader={<Typography variant='overline'>Loading...</Typography>}
        data={[
          ['Semaphore', 'Checks'],
          ['Green', greenSemaphores],
          ['Yellow', yellowSemaphores],
          ['Red', redSemaphores],
          ['Not Available', isDataAvailable ? 0 : 1]
        ]}
        options={{
          slices: [
            { color: greenSemaphoreColor },
            { color: yellowSemaphoreColor },
            { color: redSemaphoreColor },
            { color: 'gray' }
          ],
          legend: {
            position: 'none'
          },
          pieSliceText: isDataAvailable ? 'percentage' : 'label'
        }}
        rootProps={{ 'data-testid': '1' }}
        chartEvents={[
          {
            eventName: 'select',
            callback: ({ chartWrapper }) => {
              const rowNumber = chartWrapper?.getChart()?.getSelection()?.[0]?.row;
              if (rowNumber > 2 || rowNumber < 0) return;
              let semaphore = { 0: 'GREEN', 1: 'YELLOW', 2: 'RED' }[rowNumber];
              setSelectedSemaphore(semaphore);
              onRequestDrillDownData({ semaphore: semaphore });
              setDrillDownOpen(true);
            }
          }
        ]}
      />
      {drillDownOpen && (
        <QualityChecksPieChartDrillDownModal
          onPageChange={(page) => {
            onRequestDrillDownData({
              semaphore: selectedSemaphore,
              size: drillDownData.size,
              page: page
            });
          }}
          onRowsChange={(size) => {
            onRequestDrillDownData({
              semaphore: selectedSemaphore,
              size: size,
              page: 0
            });
          }}
          data={drillDownData}
          open={drillDownOpen}
          onClose={handleClose}
        />
      )}
    </>
  );
}

export default QualitySuitePieChart;
