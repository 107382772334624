import React from 'react';
import {
  Grow,
  Popper,
  Paper,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Divider,
  ListItemSecondaryAction,
  IconButton,
  ListItemText
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withLoader } from '../../../components/loader/Loader';
import { compose } from 'redux';
import { fetchRpaTemplates } from '../../../actions/RpaTemplateActions';
import { Link } from 'react-router-dom';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';

function RpaTemplatesMenu({
  anchorRef,
  open,
  rpaTemplates = [],
  selectedRpaTemplate,
  onSelect,
  onClose
}) {
  return (
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
          }}>
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList id='split-button-menu'>
                <MenuItem
                  disabled={!selectedRpaTemplate}
                  selected={!selectedRpaTemplate}
                  onClick={() => {
                    onClose();
                    onSelect(null);
                  }}>
                  <ListItemText>Default</ListItemText>
                </MenuItem>
                {rpaTemplates.map((rpaTemplate, index) => (
                  <MenuItem
                    key={rpaTemplate.uuid || 'default'}
                    disabled={rpaTemplate.uuid === _.get(selectedRpaTemplate, 'uuid')}
                    selected={rpaTemplate.uuid === _.get(selectedRpaTemplate, 'uuid')}
                    onClick={() => {
                      onClose();
                      onSelect(rpaTemplate);
                    }}>
                    <ListItemText style={{ paddingRight: 16 }}>{rpaTemplate.name}</ListItemText>
                    <ListItemSecondaryAction>
                      <Authorize hasPermissions={[Permissions.PROCESSINGS_ADMIN]}>
                        <IconButton
                          component={Link}
                          size='small'
                          color='default'
                          to={`/reports/rpa/templates/edit/${rpaTemplate.uuid}`}>
                          <Edit></Edit>
                        </IconButton>
                      </Authorize>
                    </ListItemSecondaryAction>
                  </MenuItem>
                ))}

                <Authorize hasPermissions={[Permissions.PROCESSINGS_ADMIN]}>
                  <Divider></Divider>
                  <MenuItem component={Link} to={'/reports/rpa/templates/create'}>
                    <ListItemText>Create template</ListItemText>
                  </MenuItem>
                </Authorize>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

const mapStateToProps = (state) => ({
  rpaTemplates: state.reports.rpa.templates.currentPage.content
});

export default compose(
  connect(mapStateToProps, { fetchRpaTemplates }),
  withLoader({ onLoad: ({ fetchRpaTemplates }) => fetchRpaTemplates(0, 20) })
)(RpaTemplatesMenu);
