import React, { useState, useEffect } from 'react';
import PaginatedPanel from '../../../../components/pagination/PaginatedPanel';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { Button, Switch, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VariationTypeDecorator from '../../../workflows/proposals/commons/VariationTypeDecorator';
import { connect } from 'react-redux';
import {
  fetchTemplatesEventsPage,
  modifyTemplateEvent
} from '../../../../actions/TemplatesActions';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import { ImmediateButton, ProposeButton } from '../workflow/WorkflowActions';
import TeamCodeDecorator from '../../../settings/teams/common/TeamCodeDecorator';
import TemplateEventsMassiveActions from './TemplateEventsMassiveActions';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import ImportLinkButton from 'pages/settings/importexport/commons/ImportLinkButton';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const INITIAL_STATUS = {
  statuses: ['PENDING']
};

function TemplatesEventsList({ currentPage, fetchTemplatesEventsPage, modifyTemplateEvent }) {
  const [checked, setChecked] = useState(false);
  const [statuses, setStatuses] = useState(INITIAL_STATUS.statuses);
  useEffect(() => {
    fetchTemplatesEventsPage({ statuses });
  }, [fetchTemplatesEventsPage, statuses]);
  return (
    <>
      <Grid container alignItems='center' spacing={1}>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Events</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:templateevent-list'}
            iconSize={'small'}
            pageTitle={'Events'}
          />
        </Grid>

        <Grid item>
          <TemplateEventsMassiveActions
            onCompleted={() => fetchTemplatesEventsPage({ statuses })}
          />
        </Grid>
        <Grid item>
          <ImportLinkButton />
        </Grid>
        <Grid item justifyContent='flex-end'>
          <FormGroup>
            <FormControlLabel
              style={{ float: 'right' }}
              control={
                <Switch
                  value={checked}
                  color='primary'
                  onChange={(event, checked) => {
                    setChecked(checked);
                    if (checked) {
                      setStatuses(['PENDING', 'COMPLETED', 'DELETED']);
                    } else {
                      setStatuses(['PENDING']);
                    }
                  }}></Switch>
              }
              label='All events'></FormControlLabel>
          </FormGroup>
        </Grid>
      </Grid>
      <PaginatedPanel
        autoload
        onPageSelection={(page) => fetchTemplatesEventsPage({ page, statuses })}
        currentPage={currentPage}>
        <>
          {currentPage.content
            .filter((item) => statuses.includes(item.eventStatus))
            .map((item) => (
              <TemplatesEventsListItem
                templateEvent={item}
                fetchTemplatesEventsPage={fetchTemplatesEventsPage}
                modifyTemplateEvent={modifyTemplateEvent}></TemplatesEventsListItem>
            ))}
        </>
      </PaginatedPanel>
    </>
  );
}

function TemplatesEventsListItem({ templateEvent, modifyTemplateEvent }) {
  const { t } = useTranslation();

  return (
    <ExpandableCard
      title={templateEvent.name}
      subheader={templateEvent.code}
      decorator={
        <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
          <Grid item>
            <VariationTypeDecorator>
              {t(`templates.status.${templateEvent.eventStatus}`, templateEvent.eventStatus)}
            </VariationTypeDecorator>
          </Grid>
          {templateEvent.teamCode && (
            <Grid item>
              <TeamCodeDecorator teamCode={templateEvent.teamCode}></TeamCodeDecorator>
            </Grid>
          )}
        </Grid>
      }
      actions={[
        <Button
          children={t('commons.actions.detail')}
          component={Link}
          to={`/templates/events/${templateEvent.sequenceId}`}
        />,
        <Button
          children={t('Manage')}
          component={Link}
          to={`/templates/events/${templateEvent.sequenceId}/workflow`}
        />,

        <ProposeButton templateEvent={templateEvent}></ProposeButton>,
        <ImmediateButton templateEvent={templateEvent}></ImmediateButton>,
        <Authorize
          hasAnyPermissions={[
            Permissions.DATA_ACTORS_EDITOR,
            Permissions.PROCESSINGS_EDITOR,
            Permissions.TASKS_EDITOR,
            Permissions.PROPOSE
          ]}>
          <ConfirmButton
            color='secondary'
            children={t('Ignore')}
            onClick={() => {
              modifyTemplateEvent({ ...templateEvent, eventStatus: 'DELETED' });
            }}></ConfirmButton>
        </Authorize>
      ]}>
      {templateEvent.description}
    </ExpandableCard>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.templates.templatesEvents.currentPage
  };
}

const mapDispatchToProps = { fetchTemplatesEventsPage, modifyTemplateEvent };

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesEventsList);
