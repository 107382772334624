import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { fetchCustomPropertyDefinitionValues } from '../../actions/CustomPropertiesActions';
import { connect } from 'react-redux';
import AutoComplete from '../../components/autocomplete/AutoComplete';
import { useTranslation } from 'react-i18next';

export default function CustomPropertyFieldEditor({
  customPropertyDefinition,
  additionalProperties = [],
  onChange
}) {
  let additionalProperty = {
    name: customPropertyDefinition.name,
    value: isList(customPropertyDefinition)
      ? additionalProperties.map((ap) => ap.value)
      : _.get(additionalProperties, '[0].value', null)
  };
  let onChangeInner = (adp) => {
    const onChangeValue = isList(customPropertyDefinition)
      ? adp.value.map((ap) => ({ name: customPropertyDefinition.name, value: ap }))
      : [adp];
    onChange(onChangeValue);
  };
  switch (customPropertyDefinition.type) {
    case 'ENUMERATION':
      return (
        <CustomPropertyEnumerationEditor
          {...{ additionalProperty, customPropertyDefinition, onChange: onChangeInner }}
        />
      );
    case 'AUTOCOMPLETE':
      return (
        <CustomPropertyAutocompleteEditor
          {...{ additionalProperty, customPropertyDefinition, onChange: onChangeInner }}
        />
      );
    case 'STRING_LIST':
    case 'ENUMERATION_LIST':
    case 'AUTOCOMPLETE_LIST':
      return (
        <CustomPropertyListEditor
          {...{ additionalProperty, customPropertyDefinition, onChange: onChangeInner }}
        />
      );
    case 'COMPUTED':
      return (
        <CustomPropertyComputedEditor
          {...{
            additionalProperty,
            customPropertyDefinition,
            onChange: onChangeInner
          }}></CustomPropertyComputedEditor>
      );
    case 'INTEGER':
    case 'NUMERIC':
    default:
      return (
        <CustomPropertyStringEditor
          {...{ additionalProperty, customPropertyDefinition, onChange: onChangeInner }}
        />
      );
  }
}

function isList(customPropertyDefinition) {
  return customPropertyDefinition.type && customPropertyDefinition.type.includes('LIST');
}

function CustomPropertyComputedEditor({ customPropertyDefinition, additionalProperty, onChange }) {
  const value = _.get(additionalProperty, 'value', '');
  const fieldProps = {
    label: customPropertyDefinition.label,
    placeholder: customPropertyDefinition.label,
    value,
    disabled: true,
    helperText: 'This property will be calculated after saving',
    onChange: (event) => null
  };
  return <TextField variant='standard' fullWidth {...fieldProps} />;
}

function CustomPropertyStringEditor({ customPropertyDefinition, additionalProperty, onChange }) {
  const value = _.get(additionalProperty, 'value', '');
  const fieldProps = {
    label: customPropertyDefinition.label,
    placeholder: customPropertyDefinition.label,
    value,
    onChange: (event) =>
      onChange({ name: customPropertyDefinition.name, value: event.target.value || null })
  };
  return <TextField variant='standard' fullWidth {...fieldProps} />;
}

function CustomPropertyListEditor({ customPropertyDefinition, additionalProperty, onChange }) {
  const { t } = useTranslation();
  const values = _.get(additionalProperty, 'value') || [];
  const [editor, setEditor] = useState('');
  const [open, setOpen] = useState(false);
  let inputPicker = null;
  switch (customPropertyDefinition.type) {
    case 'ENUMERATION_LIST':
      inputPicker = (
        <CustomPropertyEnumerationEditor
          additionalProperty={{ value: editor }}
          onChange={({ value }) => setEditor(value)}
          customPropertyDefinition={customPropertyDefinition}
        />
      );
      break;
    case 'AUTOCOMPLETE_LIST':
      inputPicker = (
        <CustomPropertyAutocompleteEditor
          additionalProperty={{ value: editor }}
          onChange={({ value }) => setEditor(value)}
          customPropertyDefinition={customPropertyDefinition}
        />
      );
      break;
    case 'STRING_LIST':
    default:
      inputPicker = (
        <TextField
          variant='standard'
          fullWidth
          label={customPropertyDefinition.label}
          value={editor || ''}
          onChange={(event) => setEditor(event.target.value || null)}></TextField>
      );
  }
  return (
    <>
      <List dense disablePadding>
        <h4>{customPropertyDefinition.label}</h4>
        {values.map((v) => (
          <ListItem key={v} disableGutters>
            <ListItemText primary={v}></ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                size='small'
                onClick={() => {
                  onChange({
                    name: [customPropertyDefinition.name],
                    value: values.filter((e) => e !== v)
                  });
                }}>
                <Delete></Delete>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <Button color='primary' onClick={() => setOpen(true)}>
        Add {customPropertyDefinition.label}
      </Button>

      <Dialog open={open}>
        <DialogTitle>Add {customPropertyDefinition.label}</DialogTitle>
        <DialogContent style={{ minWidth: 300 }}>{inputPicker}</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            color='primary'
            children={t('commons.actions.add')}
            disabled={!editor}
            onClick={() => {
              onChange({
                name: customPropertyDefinition.name,
                value: values.concat(editor)
              });
              setOpen(false);
              setEditor(null);
            }}></Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function CustomPropertyEnumerationEditor({
  customPropertyDefinition,
  additionalProperty,
  onChange
}) {
  const value = _.get(additionalProperty, 'value', '');
  const fieldProps = {
    label: customPropertyDefinition.label,
    placeholder: customPropertyDefinition.label,
    value,
    onChange: (event) =>
      onChange({ name: customPropertyDefinition.name, value: event.target.value || null })
  };
  let listOfValues = JSON.parse(_.get(customPropertyDefinition, 'domain', '[]'));
  listOfValues = _.isArray(listOfValues) ? listOfValues : [];
  return (
    <FormControl variant='standard' fullWidth>
      <InputLabel id={`${customPropertyDefinition.name}-select-label`}>
        {fieldProps.label}
      </InputLabel>
      <Select
        variant='standard'
        fullWidth
        labelId={`${customPropertyDefinition.name}-select-label`}
        id={`${customPropertyDefinition.name}-select`}
        value={fieldProps.value || ''}
        onChange={fieldProps.onChange}>
        <MenuItem value={''}>
          <em>Not defined</em>
        </MenuItem>
        {listOfValues.map((elem) => (
          <MenuItem value={elem}>{elem}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function CustomPropertyAutocompleteEditorView({
  customPropertyDefinition,
  additionalProperty,
  fetchValues,
  values,
  onChange
}) {
  useEffect(function () {
    fetchValues(customPropertyDefinition.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(
    _.debounce((searchValue) => {
      fetchValues(customPropertyDefinition.uuid, { searchValue });
    }, 275),
    [fetchValues]
  );

  return (
    <AutoComplete
      fullWidth
      label={customPropertyDefinition.label}
      value={_.get(additionalProperty, 'value', '')}
      dataSource={values}
      onUpdateInput={(value) => {
        onChange({ name: customPropertyDefinition.name, value: value || null });
        onSearch(value);
      }}
      onNewRequest={(value) =>
        onChange({ name: customPropertyDefinition.name, value: value || null })
      }
    />
  );
}

const CustomPropertyAutocompleteEditor = connect(
  (state, { customPropertyDefinition: { uuid } }) => ({
    values: state.settings.customPropertiesDefinitions.values[uuid]
  }),
  {
    fetchValues: fetchCustomPropertyDefinitionValues
  }
)(CustomPropertyAutocompleteEditorView);
