import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  fetchProbeConnectionPage,
  modifyProbeConnection,
  deleteProbeConnection
} from 'actions/quality/probes/ProbesConnectionsActions';

import { Typography, Grid } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import QualityProbesConnectionsList from 'pages/quality/probes/connections/list/QualityProbesConnectionsList';
import QualityProbesConnectionFAB from 'pages/quality/probes/connections/commons/QualityProbesConnectionFAB';
import QualityProbesConnectionsExportButton from '../commons/QualityProbesConnectionsExportButton';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const QualityProbesConnectionsPage = ({
  currentPage,
  fetchConnectionsPage,
  modifyConnection,
  deleteConnection
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchConnectionsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.qualityProbes'), to: '/data-quality/probes/projects' },
          { text: t('navbar.qualityProbesConnection') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: 'Probes Connections',
          category: EngagementCategories.DATA_QUALITY_PROBES,
          action: EngagementActions.LIST
        }}
      />

      <Grid container direction='row'>
        <Grid item>
          <Typography variant='h4'>Probes Connections</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:qualityprobesconnections-list'}
            pageTitle={'Probes Connections'}
            iconSize='small'
          />
        </Grid>
        <Grid>
          <QualityProbesConnectionsExportButton></QualityProbesConnectionsExportButton>
        </Grid>
      </Grid>
      <QualityProbesConnectionsList
        currentPage={currentPage}
        onFetch={fetchConnectionsPage}
        connections={currentPage.content}
        modifyConnection={modifyConnection}
        deleteConnection={deleteConnection}
      />
      <QualityProbesConnectionFAB />
    </>
  );
};

const mapStateToProps = (state, props) => ({
  currentPage: state.quality.qualityProbes.connections.currentPage
});

const mapDispatchToProps = {
  fetchConnectionsPage: fetchProbeConnectionPage,
  modifyConnection: modifyProbeConnection,
  deleteConnection: deleteProbeConnection
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityProbesConnectionsPage);
