import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { clearUsersFilters, setUsersFilters } from 'actions/UsersActions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function UsersAllTenantFilter() {
  let dispatch = useDispatch();
  let [open, setOpen] = useState(false);
  let filters = useSelector((state) => state.settings.users.filters.content);
  let [editor, setEditor] = useState(filters);
  const { t } = useTranslation();
  return (
    <>
      <IconButton size='small' onClick={() => setOpen(true)}>
        <FilterList />
      </IconButton>
      <Dialog open={open} fullWidth>
        <DialogTitle>Users Filters</DialogTitle>

        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={editor.onlyCurrentTenant}
                onChange={(event) => {
                  setEditor({
                    ...editor,
                    onlyCurrentTenant: event.target.checked
                  });
                }}
                name='only-current-tenant'
                color='primary'
              />
            }
            label='Only current tenant'
          />
        </DialogContent>
        <DialogActions>
          <Button children={t('commons.actions.cancel')} onClick={() => setOpen(false)}></Button>

          <Button
            children={t('commons.actions.clear')}
            onClick={() => {
              dispatch(clearUsersFilters());
              setOpen(false);
            }}></Button>

          <Button
            children={t('commons.actions.apply')}
            variant='contained'
            color='primary'
            onClick={() => {
              dispatch(setUsersFilters({ ...filters, ...editor }));
              setOpen(false);
            }}></Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
