import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Tooltip } from '@mui/material';
import GlobalSearchModal from './GlobalSearchModal';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory } from 'react-router';

const GlobalSearchButton = (_props) => {
  const [open, setOpen] = useState(false);

  useHotkeys('ctrl+k', () => setOpen(true), {
    filter: (event) => {
      setOpen(true);
      event.preventDefault();
    }
  });
  const history = useHistory();
  let hide = history.location.pathname.includes('/graph');

  return (
    <>
      {!hide && (
        <IconButton color='primary' onClick={() => setOpen(true)} size='large'>
          <Tooltip title='Ctrl + K'>
            <SearchIcon />
          </Tooltip>
        </IconButton>
      )}
      {open ? <GlobalSearchModal open={open} onClose={() => setOpen(false)} /> : <></>}
    </>
  );
};

export default GlobalSearchButton;
