import React, { useState } from 'react';
import { ChipWrapper, ChipItem } from 'components/chiplist/ChipList';
import { withOpenCloseHandler } from 'components/openclosehandler/OpenCloseHandler';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';
import PhysicalEntitySchemaAutoComplete from 'pages/systems/physical_entities/commons/PhysicalEntitySchemaAutoComplete';
import { useTranslation } from 'react-i18next';
import SystemItemAutoComplete from 'pages/systems/commons/SystemItemAutoComplete';

function PhysicalEntitySchemaChipListEditor({ onChange, values, onToggleOpen, isOpen }) {
  return (
    <>
      <ChipWrapper onRequestAdd={() => onToggleOpen()}>
        {(values || []).map((item) => (
          <ChipItem
            key={item}
            label={item}
            onRequestDelete={() => onChange(values.filter((e) => e !== item))}
          />
        ))}
      </ChipWrapper>
      {isOpen && (
        <PhysicalEntitySchemaSearchModal
          open={isOpen}
          onCancel={() => onToggleOpen()}
          onSubmit={(item) => {
            onToggleOpen();
            onChange((values || []).filter((schema) => schema !== item).concat([item]));
          }}
        />
      )}
    </>
  );
}

function PhysicalEntitySchemaSearchModal({ open, onCancel, onSubmit }) {
  const { t } = useTranslation();
  const [schema, setSchema] = useState(null);
  const [system, setSystem] = useState(null);
  return (
    <>
      <Dialog open={open} fullWidth>
        <DialogTitle children='Search for a schema' />
        <DialogContent>
          <SystemItemAutoComplete value={system} onChange={(sys) => setSystem(sys)} />
          <PhysicalEntitySchemaAutoComplete
            systemUuid={system?.uuid || null}
            fullWidth
            value={schema || null}
            margin='dense'
            onChange={(schema) => setSchema(schema)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
          <Button disabled={!schema} onClick={() => onSubmit(schema)}>
            {t('commons.actions.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withOpenCloseHandler(PhysicalEntitySchemaChipListEditor);
