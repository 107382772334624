import React, { Component } from 'react';
import Search from '@mui/icons-material/Search';
import DataActorsSearchModal from '../utils/DataActorsSearchModal';
import { IconButton } from '@mui/material';

export default class DataActorsFinderButton extends Component {
  state = {
    open: false
  };

  render() {
    return (
      <span>
        <IconButton
          size='small'
          style={{ float: 'right' }}
          onClick={() => this.setState({ open: true })}
          children={<Search />}
        />
        <DataActorsSearchModal
          open={this.state.open}
          onCancel={() => this.setState({ open: false })}
          onSubmit={(dataActor) => this.props.history.push(`/data_actors/${dataActor.uuid}`)}
        />
      </span>
    );
  }
}
