import React from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { WarningOutlined } from '@mui/icons-material';

export default function ProcessingPlaceholderControlCounters({ processingPlaceholdersValues }) {
  const numOfPlaceholders = _(processingPlaceholdersValues || [])
    .map((pp) => pp.name)
    .uniq()
    .size();
  const numOfProcessings = _(processingPlaceholdersValues || [])
    .map((pp) => pp.processingDisclaimer.processingUuid)
    .uniq()
    .size();
  const numOfDisclaimers = _(processingPlaceholdersValues || [])
    .map((pp) => pp.processingDisclaimer.uuid)
    .uniq()
    .size();
  return (
    <Grid alignItems='flex-end' direction='row' justifyContent='flex-end' spacing={4} container>
      <Grid item>
        {(numOfDisclaimers !== numOfProcessings ||
          numOfPlaceholders !== numOfProcessings ||
          numOfDisclaimers !== numOfPlaceholders) && (
          <WarningOutlined color='secondary'></WarningOutlined>
        )}
      </Grid>
      <Grid item>
        <Typography variant='overline'>Placeholders: </Typography>
        {numOfPlaceholders}
      </Grid>
      <Grid item>
        <Typography variant='overline'>Processings: </Typography>
        {numOfProcessings}
      </Grid>
      <Grid item>
        <Typography variant='overline'>Disclaimers: </Typography>
        {numOfDisclaimers}
      </Grid>
    </Grid>
  );
}
