import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchProposal, deleteProposal } from '../../../../actions/ProposalsActions';
import { withLoader } from '../../../../components/loader/Loader';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  CardActions,
  Button
} from '@mui/material';
import KVDefinitionList from '../../../../components/definition-list/KVDefinitionList';
import TeamCodeDecorator from '../../../settings/teams/common/TeamCodeDecorator';
import ProposalItemView from './ProposalItemView';
import { useHistory } from 'react-router';
import { ProposalStatus } from '../commons/ProposalConstants';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import { useTranslation } from 'react-i18next';
import ProposalManageButtons from './ProposalManagementButtons';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

function ProposalDetailPage({ proposal, deleteProposal }) {
  let history = useHistory();
  let { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.proposals'), to: '/workflows/proposals' },
          { text: proposal.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: `Variation Proposal ${proposal.name}`,
          category: EngagementCategories.PROPOSALS,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Variation Proposal</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`PROPOSAL:${proposal.uuid}`}
            pageTitle={`Variation Proposal ${proposal.name}`}
          />
        </Grid>
      </Grid>

      <Card>
        <CardHeader
          title={proposal.name}
          action={<TeamCodeDecorator teamCode={proposal.teamCode} />}></CardHeader>
        <CardContent>
          <ProposalDetailsView proposal={proposal} />
        </CardContent>
        <CardActions>
          <ProposalManageButtons proposal={proposal}></ProposalManageButtons>

          {proposal.proposalStatus === ProposalStatus.DRAFT && (
            <Button
              disabled={proposal.proposalStatus !== ProposalStatus.DRAFT}
              onClick={() => {
                history.push(`/workflows/proposals/editor/${proposal.uuid}`);
              }}>
              {t('commons.actions.modify')}
            </Button>
          )}

          {proposal.proposalStatus === ProposalStatus.DRAFT && (
            <ConfirmButton
              onClick={() =>
                deleteProposal(proposal).then(() => history.push('/workflows/proposals'))
              }>
              {t('commons.actions.delete')}
            </ConfirmButton>
          )}
        </CardActions>
      </Card>
      <Typography variant='h4'>Items</Typography>
      {(proposal.items || []).map((item) => (
        <ProposalItemView item={item}></ProposalItemView>
      ))}
    </>
  );
}

export function ProposalDetailsView({ proposal }) {
  return (
    <>
      {proposal.description}
      <Grid container spacing={2}>
        <Grid item>
          <KVDefinitionList
            header='General'
            item={{
              Proposer: proposal.proposerUsername,
              'Created At': proposal.createdAt && new Date(proposal.createdAt).toLocaleString(),
              'Updated At': proposal.updatedAt && new Date(proposal.updatedAt).toLocaleString()
            }}></KVDefinitionList>
        </Grid>
        <Grid item>
          <KVDefinitionList
            header='Status'
            item={{
              Status: proposal.proposalStatus,
              'Submitted At':
                proposal.submissionTimestamp &&
                new Date(proposal.submissionTimestamp).toLocaleString(),
              Comment: proposal.comment
            }}></KVDefinitionList>
        </Grid>

        <Grid item>
          <KVDefinitionList
            header='Tenant Approval'
            item={{
              Approver: proposal.tenantApproverUsername,
              'Approved At':
                proposal.tenantApprovalTimestamp &&
                new Date(proposal.tenantApprovalTimestamp).toLocaleString()
            }}></KVDefinitionList>
        </Grid>
        <Grid item>
          <KVDefinitionList
            header='Team Approval'
            item={{
              Approver: proposal.teamApproverUsername,
              'Approved At':
                proposal.teamApprovalTimestamp &&
                new Date(proposal.teamApprovalTimestamp).toLocaleString()
            }}></KVDefinitionList>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state, props) => ({
  proposal: state.workflows.proposals.content[props.match.params.proposalUuid],
  proposalUuid: props.match.params.proposalUuid
});

const mapDispatchToProps = {
  fetchProposal,
  deleteProposal
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchProposal, proposalUuid }) => fetchProposal(proposalUuid),
    ready: ({ proposal }) => proposal
  })
)(ProposalDetailPage);
