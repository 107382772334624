import { buildApiCallAction } from './ActionUtils';
import {
  FETCH_RECORDS,
  FETCH_PROOFS
} from '../constants/ActionTypes';
import NotaryApi from '../api/NotaryApi';

const api = new NotaryApi();

export const fetchRecords = ({resourceType,resourceIdentifier}, page = 0) => buildApiCallAction({
  api,
  apiFunction: api => api.getRecords({resourceIdentifier,resourceType}, page),
  actionType: FETCH_RECORDS
})

export const fetchProofs = (record, page = 0) => buildApiCallAction({
  api,
  apiFunction: api => api.getProofs(record.uuid, page),
  actionType: FETCH_PROOFS,
  payload: (data)=>({...data,recordUuid: record.uuid})
})