import DataCategoriesApi from '../api/DataCategoriesApi';
import { buildApiCallAction } from './ActionUtils';
import {
  CREATE_DATACATEGORY,
  MODIFY_DATACATEGORY,
  DELETE_DATACATEGORY,
  SEARCH_DATACATEGORIES,
  FETCH_DATACATEGORIES_PAGE,
  FETCH_DATACATEGORY,
  SET_DATACATEGORIES_FILTER,
  CLEAR_DATACATEGORIES_FILTER
} from '../constants/ActionTypes';

let api = new DataCategoriesApi();

export const fetchDataCategoriesPage = ({ page, size = 20, ...filters }) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => {
      let apiParams = createApiParamsFrom(filters);
      return api.getDataCategories({ page, size, ...apiParams });
    },
    actionType: FETCH_DATACATEGORIES_PAGE
  });

export const fetchDataCategory = (uuid) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getDataCategory(uuid),
    actionType: FETCH_DATACATEGORY
  });

export const createDataCategory = (dataCategory) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.postDataCategory(dataCategory),
    actionType: CREATE_DATACATEGORY
  });

export const modifyDataCategory = (dataCategory) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.putDataCategory(dataCategory),
    actionType: MODIFY_DATACATEGORY
  });

export const deleteDataCategory = (dataCategory) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.deleteDataCategory(dataCategory),
    actionType: DELETE_DATACATEGORY,
    payload: dataCategory
  });

export const searchDataCategories = (search) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.searchDataCategories(searchParams(search)),
    actionType: SEARCH_DATACATEGORIES
  });

export const setDataCategoriesFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_DATACATEGORIES_FILTER, payload: filters });
};

export const clearDataCategoriesFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_DATACATEGORIES_FILTER });
};

const searchParams = (search) => {
  let { searchText } = search;
  return searchText || search;
};

export const fetchDataCategoriesKPIsCompilation = () =>
  buildApiCallAction({
    api: api,
    apiFunction: (api, getState) => {
      let filters = getState().datacategories.filters.hasFilters
        ? getState().datacategories.filters.content
        : {};
      let apiParams = createApiParamsFrom(filters);
      return api.getDataCategoriesCompilationKPIs(apiParams);
    },
    actionType: 'KPI_COMPILATION_DATACATEGORIES'
  });

function createApiParamsFrom(filters) {
  let apiParams = {
    ...filters,
    dataCategoryUuid: filters.dataCategories && filters.dataCategories.map((dc) => dc.uuid),
    systemUuid: filters.systems?.map((sys) => sys.uuid)
  };
  delete apiParams.dataCategories;
  delete apiParams.systems;
  return apiParams;
}

export const fetchDataClasses = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getDataClasses(params),
    actionType: 'FETCH_DATA_CATEGORIES_DATA_CLASSES'
  });
