import PropTypes from 'prop-types';
import { fetchRpaTemplates } from '../../../actions/RpaTemplateActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withLoader } from '../../../components/loader/Loader';
import ItemSelectField from '../../../components/itemselector/ItemSelectField';

const mapStateToProps = (state, props) => ({
  itemName: 'Template',
  values: Object.values(state.reports.rpa.templates.currentPage.content),
  value: props.value,
  keyProperty: 'uuid',
  nameProperty: 'name'
});

const mapDispatchToProps = {
  fetchRpaTemplates
};

const RpaTemplateItemSelect = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({ onLoad: ({ fetchRpaTemplates }) => fetchRpaTemplates(0, 50) })
)(ItemSelectField);

RpaTemplateItemSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

export default RpaTemplateItemSelect;
