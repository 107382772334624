import { Grid, Typography } from '@mui/material';
import Tracking from 'components/engagement/Tracking';
import ExternalHelper from 'components/help/ExternalHelper';
import EngagementActions from 'constants/EngagementActions';
import EngagementCategories from 'constants/EngagementCategories';
import Breadcrumbs from 'pages/sidebar/Navigation';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DataProductFAB from './DataProductFAB';
import DataProductsExportButton from './DataProductsExportButton';
import DataProductsFiltersButton from './DataProductsFiltersButton';
import DataProductsList from './DataProductsList';
import DataProductsSearchBox from './DataProductsSearchBox';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';

export default function DataProductsListPage() {
  let { t } = useTranslation();

  return (
    <div>
      <Breadcrumbs
        elements={[
          { text: t('navbar.physical_model'), to: '/systems' },
          { text: t('navbar.dataProducts') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('dataProducts.header'),
          category: EngagementCategories.PHYSICAL_MODEL,
          action: EngagementActions.LIST
        }}
      />
      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>
            {t('dataProducts.header')}
            <ExternalHelper resourceType='dataProducts'></ExternalHelper>
          </Typography>
        </Grid>
        <Grid item>
          <DataProductsSearchBox />
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:dataproducts-list'}
            iconSize={'small'}
            pageTitle={t('dataProducts.header')}
          />
        </Grid>
        <Grid item>
          <DataProductsExportButton />
        </Grid>
        <Grid item>
          <DataProductsFiltersButton />
        </Grid>
        <Grid item>
          <WebActionsButton page={TargetPages.DATA_PRODUCT_LIST_PAGE} />
        </Grid>
      </Grid>
      <DataProductsList></DataProductsList>
      <DataProductFAB />
    </div>
  );
}
