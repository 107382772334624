import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { clearDataProductsFilters, setDataProductsFilters } from 'actions/DataProductsActions';
import CustomPropertiesFilters from 'components/additional_properties/CustomPropertiesFilters';
import ResourceTypes from 'constants/ResourceTypes';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataProductDomainAutoComplete from '../commons/DataProductDomainAutoComplete';

export const DataProductsFiltersModal = ({ open, setOpen }) => {
  const [patchedFilters, setPatchedFilters] = useState();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.dataproducts.filters.content);

  const mergedFilters = { ...filters, ...patchedFilters };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{t('dataProducts.filters.title')}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          fullWidth
          placeholder='Data Product Identifier'
          label='Data Product Identifier'
          value={mergedFilters.identifier || ''}
          onChange={(event) =>
            setPatchedFilters({ ...patchedFilters, identifier: event.target.value })
          }
        />
        <DataProductDomainAutoComplete
          value={mergedFilters.domain || ''}
          margin='dense'
          onChange={(value) => setPatchedFilters({ ...patchedFilters, domain: value?.domain })}
        />

        <CustomPropertiesFilters
          resourceType={ResourceTypes.DATA_PRODUCT}
          nullPropertiesNames={mergedFilters.nullPropertiesNames}
          properties={_.zipWith(
            mergedFilters.propertiesNames,
            mergedFilters.propertiesValues,
            (name, value) => ({
              name,
              value
            })
          )}
          onChange={(newProperties) =>
            setPatchedFilters({
              ...patchedFilters,
              propertiesNames: newProperties.map((p) => p.name),
              propertiesValues: newProperties.map((p) => p.value)
            })
          }
          onChangeNull={(nullNames) =>
            setPatchedFilters({
              ...patchedFilters,
              nullPropertiesNames: nullNames
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.clear')}
          onClick={() => {
            clearDataProductsFilters(dispatch);
            setOpen(false);
          }}
        />
        <Button
          variant='outlined'
          color='primary'
          children={t('commons.actions.apply')}
          onClick={() => {
            setDataProductsFilters(mergedFilters)(dispatch);
            setOpen(false);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
