import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Grid, Card, CardContent, Divider } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import QualityProbesProjectsList from './list/QualityProbesProjectsList';
import QualityProbesDefinitionsList from '../definitions/list/QualityProbesDefinitionsList';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const QualityProbesProjectsPage = () => {
  const { t } = useTranslation();

  const [selectedProject, setSelectedProject] = useState(undefined);

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.qualityProbes'), to: '/data-quality/probes/projects' },
          { text: t('navbar.qualityProbesProjects') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: 'Projects',
          category: EngagementCategories.DATA_QUALITY_PROBES,
          action: EngagementActions.LIST
        }}
      />
      <Grid container alignItems='center' justifyContent='flex-start'>
        <Grid item>
          <Typography variant='h4'>Projects</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
        <Grid item>
          <FavoritesWidget itemIdentifier={'page:qualityprojects-list'} pageTitle={'Projects'} />
        </Grid>
        <Grid item>
          <WebActionsButton page={TargetPages.QUALITY_PROBE_PROJECT_LIST_PAGE} />
        </Grid>
      </Grid>

      <Card style={{ height: '80vh' }}>
        <CardContent style={{ height: '100%' }}>
          <Grid container direction='row' spacing={3} style={{ height: '100%' }}>
            <Grid item xs={4} style={{ minWidth: '425px', height: '100%' }}>
              <QualityProbesProjectsList
                selectedProject={selectedProject}
                setSelectedProject={(project) => setSelectedProject(project)}
              />
            </Grid>
            <Grid item>
              <Divider orientation='vertical' />
            </Grid>
            <Grid item xs style={{ height: '100%' }}>
              <QualityProbesDefinitionsList project={selectedProject} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default QualityProbesProjectsPage;
