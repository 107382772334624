import React from 'react';
import { exportPhysicalEntities } from 'actions/PhysicalEntitiesActions';
import { IconButton, Tooltip, Button } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { connect } from 'react-redux';

function PhysicalEntitiesExportButton({ filters, onClick, system }) {
  return (
    <>
      {system && <Button onClick={() => onClick({ system: system })}>Export Entities</Button>}
      {!system && (
        <Tooltip title='Export CSV'>
          <IconButton
            size='small'
            color='default'
            aria-label='Download'
            onClick={() => onClick(filters)}>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
const mapStateToProps = (state, props) => ({
  filters: state.physicalentities.filters
});

function mapDispatchToProps(dispatch) {
  return {
    onClick: (filters) => dispatch(exportPhysicalEntities(filters))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalEntitiesExportButton);
