import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import HtmlEditor from '../../../components/html_editor/HtmlEditor';
import { DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';

const defaultState = {
  uuid: undefined,
  lang: undefined,
  title: undefined,
  description: undefined,
  version: undefined,
  format: 'text'
};

export default class ProcessingDisclaimerCreateModal extends Component {
  constructor(props) {
    super(props);
    this.state = props.disclaimer ? { ...defaultState, ...props.disclaimer } : defaultState;
    this.handleChange = this.handleChange.bind(this);
  }

  onSubmit() {
    let disclaimer = {
      ...this.state
    };
    this.props.handleSubmit(disclaimer);
  }

  handleChange(value) {
    this.setState({ description: value });
  }

  render() {
    const actions = [
      <Button children='Cancel' onClick={this.props.handleCancel} />,
      <Button
        children='Create'
        variant='contained'
        color='primary'
        disabled={this.state.lang === undefined || this.state.version === undefined}
        onClick={() => this.onSubmit()}
      />
    ];
    return (
      <Dialog
        title={this.props.title}
        actions={actions}
        modal={true}
        open={this.props.open}
        fullScreen
        aria-labelledby='processing-disclaimer-create-dialog-title'>
        <DialogTitle id='processing-disclaimer-create-dialog-title'>{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='processing-disclaimer-create-dialog-description'>
            Here you can create a processing disclaimer by specifying its version and language. The
            content's format can be a plain text or an embeddable html snippet. Once created, it can
            not be modified: you need to edit a new version.
          </DialogContentText>
          <TextField
            variant='standard'
            placeholder='Enter the language'
            label='Language'
            required
            fullWidth
            margin='dense'
            value={this.state.lang || ''}
            onChange={(event) => this.setState({ lang: event.target.value })}
          />

          <TextField
            variant='standard'
            placeholder='Enter the version'
            label='Version'
            required
            fullWidth
            margin='dense'
            value={this.state.version || ''}
            onChange={(event) => this.setState({ version: event.target.value })}
          />

          <TextField
            variant='standard'
            placeholder='Enter the title'
            label='Title'
            fullWidth
            margin='dense'
            value={this.state.title || ''}
            onChange={(event) => this.setState({ title: event.target.value })}
          />

          <HtmlEditor
            label='Description'
            placeholder='Enter the processing disclaimer. Use the switch button to enable the html editor.'
            text={this.state.description}
            handleChange={(text) => this.setState({ description: text })}
          />
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

ProcessingDisclaimerCreateModal.propTypes = {
  processing: PropTypes.object.isRequired,
  disclaimer: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};
