import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';

function RequestTemplateHeadersModal({ title, open, handleCancel, handleSubmit }) {
  const [header, setHeader] = useState(null);
  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          required
          fullWidth
          margin='dense'
          label='Name'
          placeholder='Enter the header name'
          value={header?.key}
          onChange={(event) => setHeader({ ...header, key: event.target.value })}
        />
        <TextField
          variant='standard'
          required
          fullWidth
          margin='dense'
          label='Value'
          placeholder='Enter the header value'
          value={header?.value}
          onChange={(event) => setHeader({ ...header, value: event.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{t('commons.actions.cancel')}</Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={!header?.key || !header?.value}
          onClick={() => handleSubmit(header)}>
          {t('commons.actions.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RequestTemplateHeadersModal;
