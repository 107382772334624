import {
  CREATE_DATA_ACTOR,
  MODIFY_DATA_ACTOR,
  DELETE_DATA_ACTOR,
  FETCH_DATA_ACTOR,
  FETCH_DATA_ACTORS,
  FETCH_DATA_ACTORS_PAGE,
  FETCH_DATA_ACTOR_TASK_ASSIGNMENTS,
  CREATE_DATA_ACTOR_TASK_ASSIGNMENT,
  REMOVE_DATA_ACTOR_TASK_ASSIGNMENT,
  FETCH_DATA_ACTOR_LOGIN_ASSIGNMENTS,
  CREATE_DATA_ACTOR_LOGIN_ASSIGNMENT,
  REMOVE_DATA_ACTOR_LOGIN_ASSIGNMENT,
  SEARCH_DATA_ACTORS
} from '../constants/ActionTypes';
import _ from 'lodash';
import { combineReducers } from 'redux';
import filtersReducer from './utils/filters';

const filtersDefault = {
  businessNames: [],
  roles: [],
  teamCodes: [],
  nullTeamCode: undefined
};

const content = (state = [], action) => {
  switch (action.type) {
    case FETCH_DATA_ACTORS:
      return action.payload.content;
    case CREATE_DATA_ACTOR:
      return state.concat([action.payload]);
    case MODIFY_DATA_ACTOR:
      return state.map((dp) => (dp.uuid === action.payload.uuid ? action.payload : dp));
    case DELETE_DATA_ACTOR:
      return state.filter((dp) => dp.uuid !== action.payload.uuid);
    default:
      return state;
  }
};

const contentByUuid = (
  state = {
    /*uuid->:dataActor*/
  },
  action
) => {
  switch (action.type) {
    case FETCH_DATA_ACTORS:
    case FETCH_DATA_ACTORS_PAGE:
    case SEARCH_DATA_ACTORS:
      return { ...state, ..._(action.payload.content).keyBy('uuid').value() };
    case CREATE_DATA_ACTOR:
    case MODIFY_DATA_ACTOR:
    case FETCH_DATA_ACTOR:
      return { ...state, [action.payload.uuid]: action.payload };
    case DELETE_DATA_ACTOR:
      let { [action.payload.uuid]: _clearedELem, ...stateDiff } = state;
      return stateDiff;
    default:
      return state;
  }
};

const currentPage = (state = { content: [], number: 0, totalPages: 0 }, action) => {
  switch (action.type) {
    case FETCH_DATA_ACTORS_PAGE:
      return action.payload;
    case DELETE_DATA_ACTOR:
      return { ...state, content: state.content.filter((i) => i.uuid !== action.payload.uuid) };
    case MODIFY_DATA_ACTOR:
      return {
        ...state,
        content: state.content.map((p) => (p.uuid === action.payload.uuid ? action.payload : p))
      };
    default:
      return state;
  }
};

const assignedTasks = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_ACTOR_TASK_ASSIGNMENTS:
      return {
        ...state,
        ..._.groupBy(action.payload.content, 'dataActorUuid')
      };
    case CREATE_DATA_ACTOR_TASK_ASSIGNMENT:
      return {
        ...state,
        [action.payload.dataActorUuid]: (state[action.payload.dataActorUuid] || []).concat([
          action.payload
        ])
      };
    case REMOVE_DATA_ACTOR_TASK_ASSIGNMENT:
      return {
        ...state,
        [action.payload.dataActorUuid]: state[action.payload.dataActorUuid].filter(
          (al) => al.uuid !== action.payload.uuid
        )
      };
    default:
      return state;
  }
};

const assignedLogins = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_ACTOR_LOGIN_ASSIGNMENTS:
      return {
        ...state,
        ..._(action.payload.content).groupBy('dataActorUuid').value()
      };
    case CREATE_DATA_ACTOR_LOGIN_ASSIGNMENT:
      return {
        ...state,
        [action.payload.dataActorUuid]: (state[action.payload.dataActorUuid] || []).concat([
          action.payload
        ])
      };
    case REMOVE_DATA_ACTOR_LOGIN_ASSIGNMENT:
      return {
        ...state,
        [action.payload.dataActorUuid]: state[action.payload.dataActorUuid].filter(
          (al) => al.uuid !== action.payload.uuid
        )
      };
    default:
      return state;
  }
};

export default combineReducers({
  content,
  contentByUuid,
  currentPage,
  assignedLogins,
  assignedTasks,
  filters: filtersReducer({ resource: 'DATA_ACTOR', filtersDefault })
});
