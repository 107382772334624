import React from 'react';
import { useHistory } from 'react-router-dom';

import { TableHead, TableBody, TableRow } from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';

const RiskDefinitionsTable = ({ content }) => {
  const history = useHistory();

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell>Code</TableCell>
          <TableCell>Name</TableCell>
          <TableCell hiddenMd>Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {content.map((riskDefinition) => (
          <TableRow
            key={riskDefinition.uuid}
            hover={true}
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(`/data-quality/assessment/risks/${riskDefinition.uuid}`)}>
            <TableCell>{riskDefinition.code}</TableCell>
            <TableCell>{riskDefinition.name}</TableCell>
            <TableCell hiddenMd>{riskDefinition.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
};

export default RiskDefinitionsTable;
