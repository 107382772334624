import consentsEvents from './consentsEvents'
import consentsStates from './consentsState'
import systems from './systems'
import dataSubjectsAudience from './dataSubjectsAudience'
import { combineReducers } from 'redux'

export default combineReducers({ 
  consentsEvents, 
  consentsStates, 
  dataSubjectsAudience, 
  systems 
})