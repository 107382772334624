import React from 'react';
import { Route } from 'react-router-dom';
import DataCategoriesListPage from './list/DataCategoriesListPage';
import DataCategoryDetailPage from './detail/DataCategoryDetailPage';
import DataCategoryLogicalFieldsNavbar from './commons/DataCategoryLogicalFieldsNavbar';

const DataCategoriesRoutes = (props) => {
  return (
    <React.Fragment>
      <DataCategoryLogicalFieldsNavbar></DataCategoryLogicalFieldsNavbar>
      <Route exact path={props.match.url} component={DataCategoriesListPage} />
      <Route
        exact
        path={`${props.match.url}/:dataCategoryUuid`}
        component={DataCategoryDetailPage}
      />
    </React.Fragment>
  );
};

export default DataCategoriesRoutes;
