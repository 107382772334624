import React, { useState } from 'react';
import { Card, CardHeader, CardContent, CardActions, IconButton, Collapse } from '@mui/material';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ExpandableCard({
  title,
  titleTypographyProps,
  style,
  subheader,
  actions,
  avatar,
  decorator,
  children,
  visibleChildren,
  onExpandChange
}) {
  let [isOpen, setIsOpen] = useState(false);
  let onToggleOpen = () => setIsOpen(!isOpen);
  return (
    <Card style={{ ...style, position: 'relative' }}>
      <CardHeader
        title={title}
        titleTypographyProps={
          titleTypographyProps || { variant: 'body1', style: { fontSize: '0.925rem' } }
        }
        subheader={subheader}
        subheaderTypographyProps={{ variant: 'body1', style: { lineHeight: '1.1em' } }}
        avatar={avatar}
        action={
          <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
            <Grid item>{decorator}</Grid>
            <Grid item>
              <IconButton size='large'>
                <ExpandMoreIconAnimated expanded={isOpen} />
              </IconButton>
            </Grid>
          </Grid>
        }
        onClick={() => {
          onToggleOpen();
          if (onExpandChange) {
            onExpandChange(!isOpen);
          }
        }}></CardHeader>
      {visibleChildren}
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        {children ? <CardContent>{children}</CardContent> : null}
        {actions ? <CardActions>{actions}</CardActions> : null}
      </Collapse>
    </Card>
  );
}

const ExpandMoreIconAnimated = ({ expanded }) => (
  <ExpandMoreIcon
    sx={{
      transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
      transition: (theme) =>
        theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest
        })
    }}
  />
);

export default ExpandableCard;
