import { FETCH_ACCOUNT_TEAMS_CONFIGURATION, SELECT_TENANT } from '../../constants/ActionTypes';

const INITIAL_STATE = {
  loaded: false,
  teamSettings: {
    defaultTeam: null,
    canReadAll: false,
    canWriteAll: false,
    canProposeAll: false,
    canApproveAll: false
  },
  teamPolicies: []
};

const teamConfig = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_TEAMS_CONFIGURATION:
      return { loaded: true, ...action.payload };
    case SELECT_TENANT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default teamConfig;
