const ProbeTypes = {
  SINGLE_METRIC: 'SINGLE_METRIC',
  DOUBLE_METRICS: 'DOUBLE_METRICS',
  RECORDS_COMPARISON: 'RECORDS_COMPARISON',
  TABULAR_METRICS: 'TABULAR_METRICS',
  COPY_ROWS: 'COPY_ROWS',
  EXPORT_ROWS: 'EXPORT_ROWS'
};

export default ProbeTypes;
