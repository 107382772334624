import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchSystemLogins } from '../../../actions/SystemLoginActions';
import ItemAutoCompleteModal from '../../../components/itemselector/ItemAutoCompleteModal';

const SystemLoginSearchModal = ({
  title,
  systemLogins,
  open,
  onSubmit,
  onCancel,
  searchSystemLogins
}) => (
  <ItemAutoCompleteModal
    title={title || 'Search login'}
    itemName='System Login'
    open={open}
    keyProperty='uuid'
    nameProperty='username'
    categoryProperties={['system', 'name']}
    labelSeparator={' > '}
    values={Object.values(systemLogins)}
    onSearch={searchText => searchSystemLogins({ searchText })}
    handleCancel={onCancel}
    handleSubmit={onSubmit}
  />
);

SystemLoginSearchModal.propTypes = {
  title: PropTypes.string,
  systemLogins: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  searchSystemLogins: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  systemLogins: state.systemlogins.logins.content
});

export default connect(
  mapStateToProps,
  { searchSystemLogins }
)(SystemLoginSearchModal);
