import React, { useReducer, useState } from 'react';
import FilterList from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';
import { clearSystemsFilters, setSystemsFilters } from 'actions/SystemsActions';
import _ from 'lodash';

import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button
} from '@mui/material';
import ItemSelectField from 'components/itemselector/ItemSelectField';

import { connect } from 'react-redux';
import CustomPropertiesFilters from 'components/additional_properties/CustomPropertiesFilters';
import ResourceTypes from 'constants/ResourceTypes';

function SystemsFilters({ hasFilters, filters, setSystemsFilters, clearSystemsFilters }) {
  let [open, setOpen] = useState(false);

  return (
    <span>
      <IconButton
        size='small'
        color={hasFilters ? 'primary' : 'grey'}
        style={{ float: 'right' }}
        onClick={() => setOpen(true)}
        children={<FilterList />}
      />
      {open && (
        <SystemsFiltersDialog
          open={open}
          filters={filters}
          onClear={() => {
            setOpen(false);
            clearSystemsFilters();
          }}
          onSubmit={(filters) => {
            setOpen(false);
            setSystemsFilters(filters);
          }}></SystemsFiltersDialog>
      )}
    </span>
  );
}

function SystemsFiltersDialog({ open, onClear, onSubmit, filters }) {
  const [filtersEditor, setFiltersEditor] = useReducer((s, a) => ({ ...s, ...a }), filters);
  let { t } = useTranslation();
  const systemSubtypes = [
    { key: 'APPLICATION' },
    { key: 'SERVICE' },
    { key: 'DATABASE' },
    { key: 'ARCHIVE' },
    { key: 'QUEUE' },
    { key: 'JOB' }
  ];
  let { propertiesNames = [], propertiesValues = [], nullPropertiesNames = [] } = filtersEditor;

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>Systems Filters</DialogTitle>
      <DialogContent>
        <ItemSelectField
          itemName='Subtype'
          value={filtersEditor.subtype ? { key: filtersEditor.subtype } : null}
          values={systemSubtypes}
          keyProperty={'key'}
          nameProperty={'key'}
          onChange={(subtype) =>
            setFiltersEditor({ ...filtersEditor, subtype: _.get(subtype, 'key') })
          }
        />
        <CustomPropertiesFilters
          resourceType={ResourceTypes.SYSTEM}
          nullPropertiesNames={nullPropertiesNames}
          properties={_.zipWith(propertiesNames, propertiesValues, (name, value) => ({
            name,
            value
          }))}
          onChange={(newProperties) =>
            setFiltersEditor({
              propertiesNames: newProperties.map((p) => p.name),
              propertiesValues: newProperties.map((p) => p.value)
            })
          }
          onChangeNull={(nullNames) =>
            setFiltersEditor({
              nullPropertiesNames: nullNames
            })
          }></CustomPropertiesFilters>
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.clear')} onClick={() => onClear()} />
        <Button
          children={t('commons.actions.apply')}
          color='primary'
          variant='outlined'
          onClick={() => onSubmit(filtersEditor)}
        />
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  filters: state.systems.filters.content,
  hasFilters: state.systems.filters.hasFilters || false
});

export default connect(mapStateToProps, { clearSystemsFilters, setSystemsFilters })(SystemsFilters);
