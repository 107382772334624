import React from 'react';
import { useTranslation } from 'react-i18next';

import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

import { Grid, Typography } from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import QualityAssessmentResidualRisksCounterCard from './ResidualRisksCounterCard';
import QualityAssessmentResidualRisksTableCard from './ResidualRisksTableCard';
import QualityAssessmentExportMenu from './QualityAssessmentEvalutionsExportButton';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const QualityAssessmentDashboardsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.qualityAssessment'), to: '/data-quality/assessment/risks' },
          { text: t('navbar.dashboard') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('quality.assessment.dashboards.pageHeader'),
          category: EngagementCategories.DATA_QUALITY_ASSESSMENT,
          action: EngagementActions.DASHBOARDS
        }}
      />

      <Grid container>
        <Grid item>
          <Typography variant='h4'>{t('quality.assessment.dashboards.pageHeader')}</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:qualityassessment-dashboard'}
            pageTitle={t('quality.assessment.dashboards.pageHeader')}
          />
        </Grid>
        <Grid item>
          <QualityAssessmentExportMenu />
        </Grid>
      </Grid>

      <QualityAssessmentResidualRisksCounterCard />
      <QualityAssessmentResidualRisksTableCard />
    </>
  );
};

export default QualityAssessmentDashboardsPage;
