import React, { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';
import { ConstraintTypesIcons } from 'constants/PhysicalConstraintTypes';
import ErDiagramFieldNodeContextMenu from './ErDiagramFieldNodeContextMenu';

const styles = {
  field: {
    display: 'flex',
    alignItems: 'baseline',
    margin: '3px 0'
  },
  primaryKey: (field) => field.primaryKey ? { fontWeight: 'bold' } : {},
  highlighted: (highlighted) => highlighted ? { backgroundColor: 'rgba(230, 234, 240, 0.8)' } : {}
}

const ErDiagramFieldNode = ({ entity, field }) => {

  const [highlighted, setHighlighted] = useState(field.highlighted);
  useEffect(() => {
    setHighlighted(field.highlighted);
  }, [field])

  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const openContextMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenuPosition({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6
    });
  };
  const closeContextMenu = () => {
    setContextMenuPosition(null);
  };

  return (
    <>
      <div
        style={{ ...styles.field, ...styles.highlighted(highlighted) }}
        onMouseOver={() => setHighlighted(true)}
        onMouseOut={() => setHighlighted(false)}
        onContextMenu={openContextMenu}
      >
        <Handle
          type='target'
          position={Position.Left}
          id={`to.${field.nodeId}`}
          style={{ position: 'relative', visibility: 'hidden' }}
        />
        <Grid container alignItems='center'>
          <Grid item>
            <Typography sx={styles.primaryKey(field)}>
              {field.name}
            </Typography>
          </Grid>
          {field.primaryKey ?
            <Grid item sx={{ paddingLeft: '3px', height: '24px' }}>
              {ConstraintTypesIcons['PRIMARY_KEY']}
            </Grid> : null
          }
          <Grid item style={{ flex: 1 }} />
          <Grid item>
            <Typography sx={{ ...styles.primaryKey(field), paddingLeft: '50px', color: '#696969' }}>
              {field.type}
            </Typography>
          </Grid>
        </Grid>
        <Handle
          type='source'
          position={Position.Right}
          id={`from.${field.nodeId}`}
          style={{ position: 'relative', visibility: 'hidden' }}
        />
      </div>
      <ErDiagramFieldNodeContextMenu entity={entity} field={field} position={contextMenuPosition} handleClose={closeContextMenu} />
    </>
  );
};

export default ErDiagramFieldNode;