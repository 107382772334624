import { buildApiCallAction } from './ActionUtils';
import NotificationsSettingsApi from 'api/NotificationsSettingsApi';
import {
  FETCH_USER_NOTIFICATIONS_SETTINGS,
  MODIFY_USER_NOTIFICATIONS_SETTINGS
} from 'constants/ActionTypes';

const notificationsSettingsApi = new NotificationsSettingsApi();

export const fetchUserNotificationsSettings = () =>
  buildApiCallAction({
    api: notificationsSettingsApi,
    apiFunction: (api) => api.getUserNotificationsSettings(),
    actionType: FETCH_USER_NOTIFICATIONS_SETTINGS
  });

export const modifyUserNotificationsSettings = (notificationsSettings) =>
  buildApiCallAction({
    api: notificationsSettingsApi,
    apiFunction: (api) => api.putUserNotificationsSettings(notificationsSettings),
    actionType: MODIFY_USER_NOTIFICATIONS_SETTINGS
  });
