import React from 'react';
import { connect, useSelector } from 'react-redux';
import { AddFAB } from '../../../components/fab';
import CustomPropertyModal from './CustomPropertyModal';
import { createCustomPropertyDefinition } from '../../../actions/CustomPropertiesActions';
import { hasAnyPermissionsMatching } from 'components/permissions/permissiosSelectors';

function CustomPropertiesFAB({ create }) {
  const [open, setOpen] = React.useState(false);
  const hasAnyAdminPermissions = useSelector(
    hasAnyPermissionsMatching((permission) => permission.includes('ADMIN'))
  );
  if (!hasAnyAdminPermissions) {
    return null;
  }

  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      <CustomPropertyModal
        open={open}
        onCancel={() => setOpen(false)}
        onSubmit={(data) => {
          create(data);
          setOpen(false);
        }}></CustomPropertyModal>
    </>
  );
}

export default connect(null, { create: createCustomPropertyDefinition })(CustomPropertiesFAB);
