import { connect } from 'react-redux';
import { fetchLogicalFieldsKPIsCompilation } from '../../../actions/LogicalFieldsActions';
import CompilationKPIsButton from '../../../components/compilation/CompilationKPIsButton';
import ResourceTypes from '../../../constants/ResourceTypes';

const mapStateToProps = (state, props) => ({
  filters: state.logicalfields.filters.content,
  hasFilters: state.logicalfields.filters.hasFilters,
  resourceType: ResourceTypes.LOGICAL_FIELD
});

export default connect(mapStateToProps, {
  onFetchCompilationKPIs: fetchLogicalFieldsKPIsCompilation
})(CompilationKPIsButton);
