import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PhysicalModelNavbar from 'pages/systems/PhysicalModelNavbar';
import PhysicalEntitiesListPage from 'pages/systems/physical_entities/list/PhysicalEntitiesListPage';
import PhysicalEntityDetailPage from 'pages/systems/physical_entities/detail/PhysicalEntityDetailPage';

const PhysicalEntitiesRoutes = (props) => (
  <>
    <PhysicalModelNavbar />
    <Switch>
      <Route exact path={props.match.url} component={PhysicalEntitiesListPage}></Route>
      <Route
        exact
        path={`${props.match.url}/:physicalEntityUuid`}
        component={PhysicalEntityDetailPage}
      />
    </Switch>
  </>
);

export default PhysicalEntitiesRoutes;
