var util = require('./lib/util');

// Graph3d
//util.extend(exports, require('./index-graph3d'));

// Timeline & Graph2d
//util.extend(exports, require('./index-timeline-graph2d'));

// Network
util.extend(exports, require('./index-network'));
