import React from 'react';
import PaginatedPanel from '../../../../components/pagination/PaginatedPanel';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchDictionaryListPage } from '../../../../actions/DictionariesActions';
import { ChipWrapper, ChipItem } from '../../../../components/chiplist/ChipList';

function DictionariesList({ currentPage, onPageSelection }) {
  return (
    <PaginatedPanel autoload onPageSelection={onPageSelection} currentPage={currentPage}>
      <>
        {currentPage.content.map((item) => (
          <DictionaryListItem dictionary={item}></DictionaryListItem>
        ))}
      </>
    </PaginatedPanel>
  );
}

function DictionaryListItem({ dictionary }) {
  const { t } = useTranslation();
  return (
    <ExpandableCard
      title={dictionary.dictionaryName}
      subheader={dictionary.query}
      visibleChildren={<ScopesChip dictionary={dictionary} />}
      actions={[
        <Button
          children={t('commons.actions.detail')}
          component={Link}
          to={`/classification/dictionaries/${dictionary.uuid}`}
        />
      ]}></ExpandableCard>
  );
}

function ScopesChip({ dictionary }) {
  if (!dictionary || !dictionary.scopes) {
    return null;
  }
  return (
    <ChipWrapper
      justifyRigth
      style={{
        position: 'absolute',
        clear: 'both',
        float: 'right',
        display: 'flex',
        right: '60px',
        top: '18px'
      }}>
      {(dictionary.scopes || []).map((s, index) => (
        <ChipItem key={index} id={index} label={s} />
      ))}
    </ChipWrapper>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.classification.dictionaries.currentPage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onPageSelection: (page) => dispatch(fetchDictionaryListPage({ page }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionariesList);
