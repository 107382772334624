import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import { Table, TableHead, TableBody, TableRow, Typography } from '@mui/material';

import TableCell from 'components/tables/GenericTableCell';

import ResourceTypes from 'constants/ResourceTypes';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';
import RoutinesTableActions from './RoutinesTableActions';
import { RoutineStatusDecorator } from '../detail/RoutineStatusDecorator';

function RoutinesTable({ routines, handleDetail, t }) {
  return routines.length > 0 ? (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>{t('routines.detail.tableHeaders.schema')}</TableCell>
          <TableCell>{t('routines.detail.tableHeaders.name')}</TableCell>
          <TableCell hiddenMd>{t('routines.detail.tableHeaders.type')}</TableCell>
          <TableCell hiddenMd>{t('routines.detail.tableHeaders.status')}</TableCell>
          <TableCell hiddenMd>{t('routines.detail.general.description')}</TableCell>
          <Authorize hasPermissions={[Permissions.CAMPAIGNS_VIEWER]}>
            <TableCell hiddenSm>Issues</TableCell>
          </Authorize>
          <TableCell>{t('commons.fields.actions')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {routines.map((routine) => (
          <TableRow
            hover={true}
            style={{ cursor: 'pointer' }}
            key={routine.uuid}
            onClick={() => handleDetail(routine.uuid)}>
            <TableCell>{routine.schema || '-'}</TableCell>
            <TableCell>{routine.name}</TableCell>
            <TableCell hiddenMd>{routine.type || '-'}</TableCell>
            <TableCell hiddenMd>
              <RoutineStatusDecorator
                routineAnalysisStatus={routine?.analysisStatus}
                routineErrorMessage={routine?.analysisErrorMessage}
              />
            </TableCell>
            <TableCell breakWord hiddenMd>
              {_.truncate(routine.description, { length: 125 }) || '-'}
            </TableCell>
            <TableCell hiddenSm>
              <IssueResourceListItemWidget
                resourceType={ResourceTypes.SYSTEM_ROUTINE}
                resourceIdentifier={routine.uuid}></IssueResourceListItemWidget>
            </TableCell>
            <TableCell>
              <RoutinesTableActions routine={routine} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <Typography variant='body1' style={{ textAlign: 'center', padding: 100 }}>
      No routines defined
    </Typography>
  );
}

export default withTranslation()(RoutinesTable);
