import React from 'react';
import Pagination from './Pagination';
import PropTypes from 'prop-types';
import useRoutePagination from './useRoutePagination';

function PaginatedPanel({ currentPage, onPageSelection, dependencies, autoload, style, children }) {
  let { changePagination } = useRoutePagination({
    currentPage,
    onPageSelection: (page, size) => {
      if (autoload) {
        onPageSelection(page);
      }
    },
    dependencies
  });

  return (
    <div style={{ ...style }}>
      {pageNumbers({
        currentPage,
        onPageSelection: (page) => (autoload ? changePagination({ page }) : onPageSelection(page))
      })}
      {children}
      {pageNumbers({
        currentPage,
        onPageSelection: (page) => (autoload ? changePagination({ page }) : onPageSelection(page))
      })}
    </div>
  );
}

const pageNumbers = ({ currentPage, onPageSelection }) => {
  if (currentPage && currentPage.totalPages > 1)
    return (
      <Pagination
        total={currentPage.totalPages}
        current={currentPage.number + 1}
        display={7}
        onChange={(number) => onPageSelection(number - 1)}
        styleRoot={{
          margin: '8px',
          display: 'flex',
          justifyContent: 'space-around'
        }}
      />
    );
  else {
    return <div style={{ height: '8px' }} />;
  }
};

PaginatedPanel.propTypes = {
  currentPage: PropTypes.shape({
    totalPages: PropTypes.number,
    number: PropTypes.number
  }).isRequired,
  onPageSelection: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  autoload: PropTypes.bool
};

export default PaginatedPanel;
