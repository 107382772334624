import React from 'react';

import { Tabs, Tab, Typography } from '@mui/material';

const RiskCategoriesList = ({ categories, selectedCategory, onChange }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '48px'
        }}>
        <Typography variant='subtitle2'>Risk Categories</Typography>
      </div>
      {categories.length > 0 ? (
        <div style={{ height: '100%', overflowY: 'auto' }}>
          <Tabs
            orientation='vertical'
            variant='scrollable'
            textColor='primary'
            value={selectedCategory || 'All Categories'}
            onChange={onChange}>
            <Tab key={0} value='All Categories' label='All Categories' />
            {categories.map((category, index) => (
              <Tab key={index + 1} value={category} label={category} />
            ))}
          </Tabs>
        </div>
      ) : (
        <div style={{ textAlign: 'center', padding: '25px', marginTop: '48px', overflowY: 'auto' }}>
          <Typography variant='body1'>No risks defined</Typography>
        </div>
      )}
    </>
  );
};

export default RiskCategoriesList;
