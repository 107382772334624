import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PhysicalModelNavbar from 'pages/systems/PhysicalModelNavbar';
import RoutinesListPage from './list/RoutinesListPage';
import RoutineDetailPage from './detail/RoutineDetailPage';

const RoutinesRoutes = (props) => (
  <>
    <PhysicalModelNavbar />
    <Switch>
      <Route exact path={props.match.url} component={RoutinesListPage}></Route>
      <Route exact path={`${props.match.url}/:routineUuid`} component={RoutineDetailPage} />
    </Switch>
  </>
);

export default RoutinesRoutes;
