import React from 'react';
import PaginatedPanel from '../../../../components/pagination/PaginatedPanel';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { Button, Typography } from '@mui/material';
import { connect } from 'react-redux';
import {
  fetchRpaArchiveRecordsPage,
  downloadRpaArchiveRecord,
  deleteRpaArchiveRecord
} from '../../../../actions/RpaArchiveActions';
import RpaDifferencesModal from '../commons/RpaDifferencesModal';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import Authorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';
import { useTranslation } from 'react-i18next';

function RpaArchiveRecordList({
  rpaArchiveUuid,
  currentPage,
  fetchRpaArchiveRecordsPage,
  downloadRpaArchiveRecord,
  deleteRpaArchiveRecord
}) {
  return (
    <>
      <Typography variant='h4'>Records</Typography>
      <PaginatedPanel
        currentPage={currentPage}
        onPageSelection={(page) => fetchRpaArchiveRecordsPage(rpaArchiveUuid, { page })}
        autoload>
        {((currentPage && currentPage.content) || []).map((record) => (
          <RpaArchiveRecordListItem
            rpaArchiveRecords={currentPage.content}
            rpaArchiveUuid={rpaArchiveUuid}
            deleteRpaArchiveRecord={deleteRpaArchiveRecord}
            rpaArchiveRecord={record}
            downloadRpaArchiveRecord={downloadRpaArchiveRecord}></RpaArchiveRecordListItem>
        ))}
      </PaginatedPanel>
    </>
  );
}

function RpaArchiveRecordListItem({
  rpaArchiveRecords,
  rpaArchiveUuid,
  rpaArchiveRecord,
  downloadRpaArchiveRecord,
  deleteRpaArchiveRecord
}) {
  let { t } = useTranslation();
  return (
    <ExpandableCard
      title={rpaArchiveRecord.fileName}
      subheader={rpaArchiveRecord.createdAt}
      actions={[
        <Button onClick={() => downloadRpaArchiveRecord(rpaArchiveRecord.uuid)}>Download</Button>,
        <Authorize hasPermissions={[Permissions.PROCESSINGS_ADMIN]}>
          <ConfirmButton
            color='secondary'
            onClick={() => deleteRpaArchiveRecord(rpaArchiveUuid, rpaArchiveRecord.uuid)}>
            {t('commons.actions.delete')}
          </ConfirmButton>
          <RpaDifferencesModal
            rpaArchiveRecords={rpaArchiveRecords}
            rpaArchiveRecordUuid={rpaArchiveRecord.uuid}></RpaDifferencesModal>
        </Authorize>
      ]}
    />
  );
}

const mapStateToProps = (state, props) => ({
  currentPage: state.reports.rpa.archives.records[props.rpaArchiveUuid]
});

const mapDispatchToProps = {
  fetchRpaArchiveRecordsPage,
  downloadRpaArchiveRecord,
  deleteRpaArchiveRecord
};

export default connect(mapStateToProps, mapDispatchToProps)(RpaArchiveRecordList);
