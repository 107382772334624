import { BlindataApi } from './ApiUtils';

const QUALITY_DASHBOARDS_URL = '/api/v1/data-quality/dashboards';

export default class QualitySuitesApi extends BlindataApi {
  getChecksLastRunSemaphores(params) {
    return this.get(`${QUALITY_DASHBOARDS_URL}/checks/semaphores`, params);
  }

  getChecksDrilldown(params) {
    return this.get(`${QUALITY_DASHBOARDS_URL}/checks/semaphores/drill-down`, params);
  }

  getSemaphoresOverTime(params) {
    return this.get(`${QUALITY_DASHBOARDS_URL}/checks/timeline`, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...params
    });
  }

  getResultsAreaChart(params) {
    return this.get(`${QUALITY_DASHBOARDS_URL}/results/totalElements`, params);
  }

  getOverall(params) {
    return this.get(`${QUALITY_DASHBOARDS_URL}/overall`, params);
  }
  getQualityChecksStats(params) {
    return this.get(`${QUALITY_DASHBOARDS_URL}/checks/stats`, params);
  }
}
