import crudReducers from '../utils';
import { combineReducers } from 'redux';
import filtersReducer from '../utils/filters';
import {
  CREATE_WEB_ACTION,
  DELETE_WEB_ACTION,
  FETCH_AVAILABLE_WEB_ACTIONS,
  MODIFY_WEB_ACTION
} from 'constants/ActionTypes';

const filtersDefault = {
  search: ''
};

const availableWebActions = (state = [], action) => {
  switch (action.type) {
    case FETCH_AVAILABLE_WEB_ACTIONS:
      return action.payload.availableWebActions;
    case MODIFY_WEB_ACTION:
      return [
        ...state.filter((webAction) => webAction.uuid !== action.payload.uuid),
        action.payload
      ];
    case CREATE_WEB_ACTION:
      return [...state, action.payload];
    case DELETE_WEB_ACTION:
      return state.filter((webAction) => webAction.uuid !== action.payload.uuid);
    default:
      return state;
  }
};

export default combineReducers({
  availableWebActions,
  ...crudReducers({ resource: 'WEB_ACTION', keyProperty: 'uuid' }),
  filters: filtersReducer({ resource: 'WEB_ACTION', filtersDefault })
});
