import React, { useState } from 'react';
import { updateChecksOptionsEnabled, fetchQualityChecksPage } from 'actions/QualityChecksActions';
import { connect } from 'react-redux';
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  DialogTitle,
  Typography,
  Grid,
  Switch
} from '@mui/material';
import { compose } from 'redux';
import Permissions from 'constants/Permissions';
import SettingsIcon from '@mui/icons-material/Settings';
import withPermissions from 'components/permissions/withPermissions';
import { useTranslation } from 'react-i18next';
function UpdateChecksEnabledButton({
  updateChecksOptionsEnabled,
  suiteUuid,
  fetchQualityChecksPage,
  page,
  size
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [enable, setEnable] = useState(true);
  return (
    <>
      <Tooltip title='Manage Checks'>
        <IconButton size='small' onClick={() => setOpenDialog(true)}>
          <SettingsIcon></SettingsIcon>
        </IconButton>
      </Tooltip>
      <StatusDialog
        page={page}
        size={size}
        enable={enable}
        setEnable={setEnable}
        openDialog={openDialog}
        onRefresh={fetchQualityChecksPage}
        setOpenDialog={setOpenDialog}
        suiteUuid={suiteUuid}
        updateChecksOptionsEnabled={updateChecksOptionsEnabled}></StatusDialog>
    </>
  );
}

function StatusDialog({
  updateChecksOptionsEnabled,
  openDialog,
  setOpenDialog,
  suiteUuid,
  onRefresh,
  enable,
  setEnable,
  page,
  size
}) {
  const { t } = useTranslation();
  return (
    <Dialog open={openDialog} fullWidth>
      <DialogTitle>Checks Settings</DialogTitle>
      <DialogContent>
        <Typography variant='overline'>Disable/Enable all checks</Typography>
        <br></br>
        <Grid component='label' container alignItems='center' spacing={1}>
          <Grid item>Disable All</Grid>
          <Grid item>
            <Switch
              checked={enable}
              onChange={(event) => setEnable(event.target.checked)}
              value={enable}
              color='primary'
            />
          </Grid>
          <Grid item>Enable All</Grid>
        </Grid>
        <br></br>
        {enable === true ? (
          <Typography variant='body1'>
            With this option, all the checks will be taken into account for data quality dashboards.
          </Typography>
        ) : (
          <Typography variant='body1'>
            With this option, all the checks will be excluded from data quality dashboards.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.cancel')}
          onClick={() => {
            setOpenDialog(false);
            setEnable(true);
          }}></Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            updateChecksOptionsEnabled({
              suiteUuid: suiteUuid,
              enabled: enable
            }).then(() =>
              onRefresh({
                suiteUuid: suiteUuid,
                page: page,
                size: size
              })
            );
            setOpenDialog(false);
          }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
function mapStateToProps(state) {
  return {
    page: state.quality.qualityChecks.currentPage.number,
    size: state.quality.qualityChecks.currentPage.size
  };
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.QUALITY_EDITOR] }),
  connect(mapStateToProps, { updateChecksOptionsEnabled, fetchQualityChecksPage })
)(UpdateChecksEnabledButton);
