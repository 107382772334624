export const TargetPages = {
  DATA_CATEGORY_DETAIL_PAGE: 'DATA_CATEGORY_DETAIL_PAGE',
  LOGICAL_FIELD_DETAIL_PAGE: 'LOGICAL_FIELD_DETAIL_PAGE',
  DATA_ACTOR_DETAIL_PAGE: 'DATA_ACTOR_DETAIL_PAGE',
  PROCESSING_DETAIL_PAGE: 'PROCESSING_DETAIL_PAGE',
  TASK_DETAIL_PAGE: 'TASK_DETAIL_PAGE',
  SYSTEM_DETAIL_PAGE: 'SYSTEM_DETAIL_PAGE',
  PHYSICAL_ENTITY_DETAIL_PAGE: 'PHYSICAL_ENTITY_DETAIL_PAGE',
  PHYSICAL_FIELD_DETAIL_PAGE: 'PHYSICAL_FIELD_DETAIL_PAGE',
  SYSTEM_ROUTINE_DETAIL_PAGE: 'SYSTEM_ROUTINE_DETAIL_PAGE',
  QUALITY_CHECK_DETAIL_PAGE: 'QUALITY_CHECK_DETAIL_PAGE',
  QUALITY_SUITE_DETAIL_PAGE: 'QUALITY_SUITE_DETAIL_PAGE',
  QUALITY_RESULT_DETAIL_PAGE: 'QUALITY_RESULT_DETAIL_PAGE',
  QUALITY_PROBE_DEFINITION_DETAIL_PAGE: 'QUALITY_PROBE_DEFINITION_DETAIL_PAGE',
  QUALITY_PROBE_PROJECT_DETAIL_PAGE: 'QUALITY_PROBE_PROJECT_DETAIL_PAGE',
  ISSUE_DETAIL_PAGE: 'ISSUE_DETAIL_PAGE',
  QUERY_STATEMENT_DETAIL_PAGE: 'QUERY_STATEMENT_DETAIL_PAGE',
  QUERY_BUCKET_DETAIL_PAGE: 'QUERY_BUCKET_DETAIL_PAGE',
  CONTRACT_DETAIL_PAGE: 'CONTRACT_DETAIL_PAGE',
  DATA_PRODUCT_DETAIL_PAGE: 'DATA_PRODUCT_DETAIL_PAGE',
  DATA_CATEGORY_LIST_PAGE: 'DATA_CATEGORY_LIST_PAGE',
  LOGICAL_FIELD_LIST_PAGE: 'LOGICAL_FIELD_LIST_PAGE',
  DATA_ACTOR_LIST_PAGE: 'DATA_ACTOR_LIST_PAGE',
  PROCESSING_LIST_PAGE: 'PROCESSING_LIST_PAGE',
  TASK_ASSIGNMENT_LIST_PAGE: 'TASK_ASSIGNMENT_LIST_PAGE',
  TASK_LIST_PAGE: 'TASK_LIST_PAGE',
  SYSTEM_LIST_PAGE: 'SYSTEM_LIST_PAGE',
  PHYSICAL_ENTITY_LIST_PAGE: 'PHYSICAL_ENTITY_LIST_PAGE',
  SYSTEM_ROUTINE_LIST_PAGE: 'SYSTEM_ROUTINE_LIST_PAGE',
  QUALITY_CHECK_LIST_PAGE: 'QUALITY_CHECK_LIST_PAGE',
  QUALITY_SUITE_LIST_PAGE: 'QUALITY_SUITE_LIST_PAGE',
  QUALITY_PROBE_DEFINITION_LIST_PAGE: 'QUALITY_PROBE_DEFINITION_LIST_PAGE',
  QUALITY_PROBE_PROJECT_LIST_PAGE: 'QUALITY_PROBE_PROJECT_LIST_PAGE',
  ISSUE_LIST_PAGE: 'ISSUE_LIST_PAGE',
  QUERY_STATEMENT_LIST_PAGE: 'QUERY_STATEMENT_LIST_PAGE',
  QUERY_BUCKET_LIST_PAGE: 'QUERY_BUCKET_LIST_PAGE',
  CONTRACT_LIST_PAGE: 'CONTRACT_LIST_PAGE',
  DATA_PRODUCT_LIST_PAGE: 'DATA_PRODUCT_LIST_PAGE'
};

export default TargetPages;
