import React from 'react'
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {fetchProcessing, fetchAllProcessingsDisclaimers, createProcessingDisclaimer, deleteProcessingDisclaimer} from '../../../actions/ProcessingsActions'
import ProcessingDisclaimersList from './ProcessingDisclaimersList'

const ProcessingDisclaimersContainer = (props)=>(
  <ProcessingDisclaimersList {...props}/>
)

const mapStateToProps = (state,props) =>({
  processingUuid: props.match.params['processingUuid'],
  processing: state.processings.contentByUuid[props.match.params['processingUuid']],
  processingDisclaimers: state.processings.disclaimers.contentByProcessingUuid[props.match.params['processingUuid']]
})

export default withRouter(connect(
  mapStateToProps,
  {
    fetchProcessing,
    fetchAllProcessingsDisclaimers,
    createProcessingDisclaimer,
    deleteProcessingDisclaimer
  }
)(ProcessingDisclaimersContainer))