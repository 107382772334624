import { buildApiCallAction } from './ActionUtils';
import {
  FETCH_RPA_ARCHIVES_PAGE,
  FETCH_RPA_ARCHIVE,
  CREATE_RPA_ARCHIVE,
  MODIFY_RPA_ARCHIVE,
  DELETE_RPA_ARCHIVE,
  CREATE_RPA_ARCHIVE_RECORD,
  FETCH_RPA_ARCHIVE_RECORDS_PAGE,
  DELETE_RPA_ARCHIVE_RECORD
} from '../constants/ActionTypes';
import RpaArchivesApi from '../api/RpaArchivesApi';

const rpaArchiveApi = new RpaArchivesApi();

export const fetchRpaArchivesPage = (params) =>
  buildApiCallAction({
    api: rpaArchiveApi,
    apiFunction: (api) => api.getRpaArchives(params),
    actionType: FETCH_RPA_ARCHIVES_PAGE
  });

export const fetchRpaArchive = (uuid) =>
  buildApiCallAction({
    api: rpaArchiveApi,
    apiFunction: (api) => api.getRpaArchive(uuid),
    actionType: FETCH_RPA_ARCHIVE
  });

export const createRpaArchive = (rpaArchive) =>
  buildApiCallAction({
    api: rpaArchiveApi,
    apiFunction: (api) => api.postRpaArchive(cleanRpaArchive(rpaArchive)),
    actionType: CREATE_RPA_ARCHIVE
  });

export const modifyRpaArchive = (rpaArchive) =>
  buildApiCallAction({
    api: rpaArchiveApi,
    apiFunction: (api) => api.putRpaArchive(cleanRpaArchive(rpaArchive)),
    actionType: MODIFY_RPA_ARCHIVE
  });

function cleanRpaArchive(rpaArchive) {
  if (!rpaArchive.rpaTemplate) {
    return rpaArchive;
  } else {
    return {
      ...rpaArchive,
      rpaTemplate: {
        uuid: rpaArchive.rpaTemplate.uuid,
        name: rpaArchive.rpaTemplate.name
      }
    };
  }
}

export const deleteRpaArchive = (uuid) =>
  buildApiCallAction({
    api: rpaArchiveApi,
    apiFunction: (api) => api.deleteRpaArchive(uuid),
    actionType: DELETE_RPA_ARCHIVE,
    payload: { uuid }
  });

export const createRpaArchiveRecord = (rpaArchiveUuid, rpaArchiveRecord = {}) =>
  buildApiCallAction({
    api: rpaArchiveApi,
    apiFunction: (api) => api.postRpaArchiveRecord(rpaArchiveUuid, rpaArchiveRecord),
    actionType: CREATE_RPA_ARCHIVE_RECORD,
    payload: (response) => ({ rpaArchiveUuid, ...response })
  });

export const fetchRpaArchiveRecordsPage = (rpaArchiveUuid, params) =>
  buildApiCallAction({
    api: rpaArchiveApi,
    apiFunction: (api) => api.getRpaArchiveRecords(rpaArchiveUuid, params),
    actionType: FETCH_RPA_ARCHIVE_RECORDS_PAGE,
    payload: (response) => ({ rpaArchiveUuid, ...response })
  });

export const downloadRpaArchiveRecord = (rpaArchiveRecordUuid) =>
  buildApiCallAction({
    api: rpaArchiveApi,
    apiFunction: (api) => api.downloadRpaArchiveRecord(rpaArchiveRecordUuid),
    actionType: 'downloadRpaArchiveRecord',
    payload: { done: true }
  });

export const deleteRpaArchiveRecord = (rpaArchiveUuid, rpaArchiveRecordUuid) =>
  buildApiCallAction({
    api: rpaArchiveApi,
    apiFunction: (api) => api.deleteRpaArchiveRecord(rpaArchiveRecordUuid),
    actionType: DELETE_RPA_ARCHIVE_RECORD,
    payload: {
      rpaArchiveUuid,
      uuid: rpaArchiveRecordUuid
    }
  });
