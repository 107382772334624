import React from 'react';
import { useTranslation } from 'react-i18next';

import PhysicalFieldEditModal from 'pages/systems/physicalfields/commons/PhysicalFieldEditModal';
import AlertDialog from 'components/alertdialog/AlertDialog';

const ActionsModals = ({
  selectedField,
  createModalOpen,
  setCreateModalOpen,
  onCreate,
  editModalOpen,
  setEditModalOpen,
  onEdit,
  deleteModalOpen,
  setDeleteModalOpen,
  onDelete
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Create modal */}
      <PhysicalFieldEditModal
        title={t('physicalEntities.detail.physicalFields.createPhysicalField')}
        open={createModalOpen}
        handleSubmit={(selectedField) => onCreate(selectedField)}
        handleCancel={() => setCreateModalOpen(false)}
      />

      {/* Edit modal */}
      <PhysicalFieldEditModal
        title={t('physicalEntities.detail.physicalFields.modifyPhysicalField')}
        open={editModalOpen}
        key={selectedField?.uuid}
        physicalField={selectedField}
        handleSubmit={(editedField) => onEdit(editedField)}
        handleCancel={() => setEditModalOpen(false)}
      />

      {/* Delete modal */}
      <AlertDialog
        message={
          'Are you sure you want to delete this physical field? This will delete associated dataflows and resources.'
        }
        open={deleteModalOpen}
        onOk={() => onDelete(selectedField)}
        onCancel={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default ActionsModals;
