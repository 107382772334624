import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { Toolbar, MenuItem, Grid } from '@mui/material';
import _ from 'lodash';
import Breadcrumbs from 'pages/sidebar/Navigation';

import ProcessingDisclaimersItem from './ProcessingDisclaimerItem';
import ProcessingDisclaimerCreateModal from './ProcessingDiscalimerCreateModal';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import { Typography, FormControl, InputLabel, Select } from '@mui/material';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
const defaultState = {
  createOpen: false,
  disclaimerEditor: null,
  editorKey: Math.random().toString(),
  selectedLang: undefined,
  selectedVersion: undefined,
  languageValues: [],
  versionValues: [],
  value: 1
};

const configState = (props) => ({
  languageValues:
    _(props.processingDisclaimers)
      .map((p) => p.lang)
      .uniq()
      .value() || [],
  versionValues:
    _(props.processingDisclaimers)
      .map((p) => p.version)
      .uniq()
      .value() || []
});

export default class ProcessingDisclaimersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      ...configState(props)
    };
  }

  componentDidMount() {
    this.props.fetchProcessing(this.props.processingUuid);
    this.props.fetchAllProcessingsDisclaimers(this.props.processingUuid);
  }

  componentWillReceiveProps(props) {
    this.setState(configState(props));
  }

  handleChangeLanguage = (event) => {
    this.setState({ selectedLang: event.target.value });
  };

  handleChangeVersion = (event) => {
    this.setState({ selectedVersion: event.target.value });
  };

  languageValues() {
    _(this.props.processingDislaimers)
      .map((p) => p.lang)
      .uniq()
      .value();
  }

  versionValues(lang) {
    _(this.props.processingDislaimers)
      .map((p) => p.version)
      .uniq()
      .value();
  }

  render() {
    let filters = _({
      lang: this.state.selectedLang,
      version: this.state.selectedVersion
    })
      .pickBy()
      .value();
    let selectedDisclaimers = _(this.props.processingDisclaimers)
      .filter(filters)
      .sortBy(['version', 'lang'])
      .value();
    return (
      <div>
        <Breadcrumbs
          elements={[
            { text: 'Processings', to: '/processings' },
            {
              text: this.props?.processing?.name,
              to: `/processings/${this.props?.processingUuid}`
            },
            { text: 'Disclaimers' }
          ]}
        />
        <Tracking
          info={{
            pageTitle: `Processing Disclaimers: ${this.props?.processing} && ${this.props?.processing?.name}`,
            category: EngagementCategories.PROCESSINGS,
            action: EngagementActions.LIST
          }}
        />
        <Typography variant='h4'>
          Processing Disclaimers: {this.props.processing && this.props.processing.name}
        </Typography>
        <Toolbar style={{ backgroundColor: '#e0e0d1' }}>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item>
              <FormControl variant='standard' style={{ minWidth: '85px', paddingRight: '16px' }}>
                <InputLabel htmlFor='lang-simple'>Lang</InputLabel>
                <Select
                  variant='standard'
                  value={this.state.selectedLang || ''}
                  onChange={this.handleChangeLanguage}
                  inputProps={{
                    name: 'lang',
                    id: 'lang-simple'
                  }}>
                  <MenuItem value={''}>No language filter</MenuItem>
                  {this.state.languageValues.map((lang) => (
                    <MenuItem value={lang}>{lang}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant='standard' style={{ minWidth: '85px' }}>
                <InputLabel htmlFor='version-simple'>Version</InputLabel>
                <Select
                  variant='standard'
                  value={this.state.selectedVersion || ''}
                  onChange={this.handleChangeVersion}
                  inputProps={{
                    name: 'version',
                    id: 'version-simple'
                  }}>
                  <MenuItem value={''}>No version filter</MenuItem>
                  {this.state.versionValues.map((ver) => (
                    <MenuItem value={ver}>{ver}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Authorize hasPermissions={[Permissions.PROCESSINGS_EDITOR]}>
                <Button
                  color='primary'
                  variant='contained'
                  children='Create New'
                  primary={true}
                  onClick={() =>
                    this.setState({
                      createOpen: true,
                      disclaimerEditor: null,
                      editorKey: Math.random().toString()
                    })
                  }
                />
              </Authorize>
            </Grid>
          </Grid>
        </Toolbar>

        {selectedDisclaimers.map((disclaimer) => (
          <ProcessingDisclaimersItem
            processing={this.props.processing}
            disclaimer={disclaimer}
            onDelete={() =>
              this.props.deleteProcessingDisclaimer(this.props.processing, disclaimer)
            }
            onCopy={(disclaimer) =>
              this.setState({
                createOpen: true,
                disclaimerEditor: { ...disclaimer, uuid: null },
                editorKey: Math.random().toString()
              })
            }
          />
        ))}
        <ProcessingDisclaimerCreateModal
          key={this.state.editorKey}
          title='Create Processing Disclaimer'
          open={this.state.createOpen}
          disclaimer={this.state.disclaimerEditor}
          handleCancel={() => this.setState({ createOpen: false })}
          handleSubmit={(disclaimer) => {
            this.setState({ createOpen: false });
            this.props.createProcessingDisclaimer(this.props.processingUuid, disclaimer);
          }}
        />
      </div>
    );
  }
}
