import React from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { BubbleChart } from '@mui/icons-material';
import {
  addGraphUuidFilter,
  requestGraph,
  changePerspective,
  clearGraphFilters
} from '../../../actions/GraphActions';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

const defaultPerspectiveOptions = {
  logicalPerspective: false,
  businessPerspective: false,
  loginsPerspective: false,
  physicalPerspective: false,
  dataFlowsPerspective: false,
  dataFlowsDepth: 20,
  dataFlowsDepthHierarchy: false
};

function GraphLinkButton({
  graphUuidFilter,
  perspectiveOptions,
  addGraphUuidFilter,
  requestGraph,
  changePerspective,
  clearGraphFilters,
  component,
  size,
  ...otherProps
}) {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event) {
    if (!graphUuidFilter) {
      console.warn('GraphLinkButton: No graph uuid filter defined');
      return;
    }
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function showGraph(params) {
    clearGraphFilters();
    changePerspective({ ...defaultPerspectiveOptions, ...perspectiveOptions, ...params });
    addGraphUuidFilter(graphUuidFilter);
    requestGraph();
    history.push('/graph');
  }
  return (
    <>
      <Tooltip title='Show dataflow graph'>
        {component ? (
          React.createElement(component, { ...otherProps, onClick: handleClick })
        ) : (
          <IconButton {...otherProps} size={size || 'medium'} onClick={handleClick}>
            <BubbleChart />
          </IconButton>
        )}
      </Tooltip>
      <Menu
        id='dataflow-hierarchy-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={() => showGraph({ dataFlowsDepthHierarchy: false })}>
          Only Entities
        </MenuItem>
        <MenuItem onClick={() => showGraph({ dataFlowsDepthHierarchy: true })}>
          With Systems
        </MenuItem>
      </Menu>
    </>
  );
}

const mapDispatchToProps = {
  addGraphUuidFilter,
  requestGraph,
  changePerspective,
  clearGraphFilters
};

export default connect(null, mapDispatchToProps)(GraphLinkButton);
