import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import { setContractDefinitionFilters } from 'actions/contracts/ContractsDefinitionsActions';
import { connect } from 'react-redux';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ContractDefinitionFiltersModal from './ContractDefinitionFiltersModal';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';

function ContractDefinitionsListToolbar({ hasFilters, filters, setContractDefinitionFilters }) {
  let [editor, setEditor] = useState(filters.search | '');

  useEffect(() => {
    if (filters.search !== editor) {
      setEditor(filters.search || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.search, setEditor]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let onSetFilters = useCallback(_.debounce(setContractDefinitionFilters, 100), [
    setContractDefinitionFilters
  ]);
  return (
    <>
      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Contracts Definitions</Typography>
        </Grid>
        <Grid item>
          <TextField
            value={filters.search}
            id='input-with-icon-textfield'
            placeholder='Search...'
            variant='outlined'
            size='small'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search color='disabled' />
                </InputAdornment>
              )
            }}
            onChange={(event) => {
              setEditor(event.target.value);
              onSetFilters({ ...filters.content, search: event.target.value });
            }}></TextField>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:contractdefinitions-list'}
            pageTitle={'Contracts Definitions'}
            iconSize='small'
          />
        </Grid>
        <Grid item>
          <ContractDefinitionFiltersModal />
        </Grid>
        <Grid item>
          <WebActionsButton page={TargetPages.CONTRACT_LIST_PAGE} />
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  filters: state.contracts.definitions.filters.content,
  hasFilters: state.contracts.definitions.filters.hasFilters || false
});
export default connect(mapStateToProps, {
  setContractDefinitionFilters
})(ContractDefinitionsListToolbar);
