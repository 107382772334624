import { BlindataApi } from 'api/ApiUtils';

const RISK_EVALUATION_URL = '/api/v1/data-quality/assessment/risk-evaluations';

export default class QualityAssessmetRiskEvaluationApi extends BlindataApi {
  getRiskEvaluationsMatrix(physicalEntityUuid) {
    return this.get(`${RISK_EVALUATION_URL}/physical-entities/${physicalEntityUuid}`);
  }

  getRiskEvaluationsMatrixWithComputedResults(physicalEntityUuid) {
    return this.get(`${RISK_EVALUATION_URL}/physical-entities/${physicalEntityUuid}/results`);
  }

  putRiskEvaluation(riskEvaluation) {
    let physicalEntityUuid = riskEvaluation?.physicalEntity?.uuid;
    let riskUuid = riskEvaluation?.risk?.uuid;
    return this.put(
      `${RISK_EVALUATION_URL}/physical-entities/${physicalEntityUuid}/risks/${riskUuid}`,
      riskEvaluation
    );
  }

  deleteRiskEvaluation(riskEvaluation) {
    let physicalEntityUuid = riskEvaluation?.physicalEntity?.uuid;
    let riskUuid = riskEvaluation?.risk?.uuid;
    return this.delete(
      `${RISK_EVALUATION_URL}/physical-entities/${physicalEntityUuid}/risks/${riskUuid}`
    );
  }

  exportRiskEvaluations() {
    return this.download(`${RISK_EVALUATION_URL}/*/export`, {
      includeProperties: 'false'
    });
  }
}
