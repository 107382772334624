import React, { Component } from 'react';
import TaskSearchModal from '../utils/TaskSearchModal';
import IconButton from '@mui/material/IconButton';
import Search from '@mui/icons-material/Search';

export default class ProcessingFinderButton extends Component {
  state = {
    open: false
  };

  render() {
    return (
      <span>
        <IconButton
          size='small'
          style={{ float: 'right' }}
          onClick={() => this.setState({ open: true })}>
          <Search />
        </IconButton>
        <TaskSearchModal
          open={this.state.open}
          onCancel={() => this.setState({ open: false })}
          onSubmit={(task) => this.props.history.push(`/tasks/${task.uuid}`)}
        />
      </span>
    );
  }
}
