import React from 'react';
import AddFAB from '../../../../components/fab/AddFAB';
import { connect } from 'react-redux';
import { createTemplateProcessing } from '../../../../actions/TemplatesActions';
import TemplateProcessingModal from '../commons/TemplateProcessingModal';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';

function TemplateProcessingFAB({ createTemplateProcessing }) {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      <TemplateProcessingModal
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(templateProcessing) =>
          createTemplateProcessing(templateProcessing).then((templateProcessing) => {
            if (templateProcessing) {
              setOpen(false);
              history.push(`/templates/processings/${templateProcessing.sequenceId}`);
            }
          })
        }></TemplateProcessingModal>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  createTemplateProcessing: createTemplateProcessing
};

export default compose(
  withPermissions({ hasPermissions: [Permissions.TASKS_ADMIN] }),
  connect(mapStateToProps, mapDispatchToProps)
)(TemplateProcessingFAB);
