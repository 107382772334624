import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { t } from 'i18next';
import _ from 'lodash';
import PhysicalEntitySearchModal from 'pages/systems/physical_entities/commons/PhysicalEntitySearchModal';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodesToConnectTable } from './NodesToConnectTable';
import { TargetNodesTable } from './TargetNodesTable';
import { fetchPhysicalEntity } from 'actions/PhysicalEntitiesActions';
import { createDataFlow, fetchDataFlowsPage } from 'actions/DataFlowsActions';

const PhysicalEntitiesConnectModal = ({
  title,
  open,
  onClose,
  physicalEntityUuid,
  dataFlowDirection,
  filters
}) => {
  const [targetTable, setTargetTable] = useState(null);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [connectionAtPhysicalEntityLevel, setConnectionAtPhysicalEntityLevel] = useState(false);

  const physicalEntity = useSelector((state) => state.physicalentities.content[physicalEntityUuid]);
  const dispatch = useDispatch();

  const initialState = {
    matchStrategy: 'none',
    edges:
      physicalEntity?.physicalFields
        .sort((a, b) => a?.ordinalPosition - b?.ordinalPosition)
        .map((pf) => {
          return dataFlowDirection === 'Incoming'
            ? { fromPhysicalField: null, toPhysicalField: pf }
            : { fromPhysicalField: pf, toPhysicalField: null };
        }) || []
  };

  const [connections, dispatchConnectionsAction] = useReducer(reducer, initialState);

  function reducer(state, action) {
    switch (action.type) {
      case 'sortByPositionIndex':
        return {
          ...state,
          matchStrategy: 'sortByPositionIndex',
          edges: sortByPositionIndex(physicalEntity, dataFlowDirection, targetTable)
        };
      case 'sortByColumnName':
        return {
          ...state,
          matchStrategy: 'sortByColumnName',
          edges: sortByColumnName(physicalEntity, dataFlowDirection, targetTable)
        };
      case 'addLink':
        let modifiedEdges = state.edges;
        modifiedEdges[action.payload.index] =
          dataFlowDirection === 'Incoming'
            ? {
                fromPhysicalField: action.payload.physicalField,
                toPhysicalField: modifiedEdges[action.payload.index].toPhysicalField
              }
            : {
                fromPhysicalField: modifiedEdges[action.payload.index].fromPhysicalField,
                toPhysicalField: action.payload.physicalField
              };
        return {
          ...state,
          matchStrategy: 'none',
          edges: modifiedEdges
        };
      case 'reset':
        return initialState;
      default:
        return state;
    }
  }

  useEffect(() => {
    if (!physicalEntity || !physicalEntity.physicalFields) {
      dispatch(fetchPhysicalEntity(physicalEntityUuid));
    }
  }, [dispatch, physicalEntityUuid, physicalEntity]);

  function handleDataFlowCreationAtEntityLevel() {
    const dataFlow =
      dataFlowDirection === 'Incoming'
        ? {
            name: 'Manual Connection',
            scope: `manual_connection:${targetTable.uuid}:${physicalEntity.uuid}`,
            fromSystem: targetTable.system,
            toSystem: physicalEntity.system,
            fromPhysicalEntity: targetTable,
            toPhysicalEntity: physicalEntity
          }
        : {
            name: 'Manual Connection',
            scope: `manual_connection:${physicalEntity.uuid}:${targetTable.uuid}`,
            fromSystem: physicalEntity.system,
            toSystem: targetTable.system,
            fromPhysicalEntity: physicalEntity,
            toPhysicalEntity: targetTable
          };
    dispatch(createDataFlow(dataFlow)).then(() => dispatch(fetchDataFlowsPage(0, 25, filters)));
  }

  function handleDataFlowsCreationAtFieldsLevel() {
    connections.edges.forEach((edge) => {
      if (edge.fromPhysicalField && edge.toPhysicalField) {
        const dataFlow =
          dataFlowDirection === 'Incoming'
            ? {
                name: 'Manual Connection',
                scope: `manual_connection:${targetTable.uuid}:${physicalEntity.uuid}`,
                fromSystem: targetTable.system,
                toSystem: physicalEntity.system,
                fromPhysicalEntity: targetTable,
                fromPhysicalField: edge.fromPhysicalField,
                toPhysicalEntity: physicalEntity,
                toPhysicalField: edge.toPhysicalField
              }
            : {
                name: 'Manual Connection',
                scope: `manual_connection:${physicalEntity.uuid}:${targetTable.uuid}`,
                fromSystem: physicalEntity.system,
                toSystem: targetTable.system,
                fromPhysicalEntity: physicalEntity,
                fromPhysicalField: edge.fromPhysicalField,
                toPhysicalEntity: targetTable,
                toPhysicalField: edge.toPhysicalField
              };
        dispatch(createDataFlow(dataFlow)).then(() => dispatch(fetchDataFlowsPage(0, 25, filters)));
      }
    });
  }

  return (
    <>
      <Dialog fullWidth open={open} maxWidth={'lg'} onClick={(event) => event.stopPropagation()}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Switch
                checked={connectionAtPhysicalEntityLevel}
                onChange={() =>
                  setConnectionAtPhysicalEntityLevel(!connectionAtPhysicalEntityLevel)
                }
                color='primary'
              />
            }
            label={t('physicalEntities.connectionToTargetModal.switchLabel')}
            labelPlacement='start'
          />
          <MatchingStrategySelector
            key={connections.matchStrategy}
            display={!connectionAtPhysicalEntityLevel}
            matchStrategy={connections.matchStrategy}
            dispatchConnectionsAction={dispatchConnectionsAction}
          />
          <br></br>
          <CurrentEntityAndEntityToConnect
            connectionAtPhysicalEntityLevel={connectionAtPhysicalEntityLevel}
            physicalEntity={physicalEntity}
            dataFlowDirection={dataFlowDirection}
            targetTable={targetTable}
            connections={connections}
            dispatchConnectionsAction={dispatchConnectionsAction}
            setSearchModalOpen={setSearchModalOpen}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              onClose();
              setTargetTable(null);
              dispatchConnectionsAction({ type: 'reset' });
            }}>
            {t('commons.actions.cancel')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={!targetTable}
            onClick={(event) => {
              event.stopPropagation();
              if (connectionAtPhysicalEntityLevel) {
                handleDataFlowCreationAtEntityLevel();
              } else {
                handleDataFlowsCreationAtFieldsLevel();
              }
              onClose();
              setTargetTable(null);
            }}>
            {t('commons.actions.submit')}
          </Button>
        </DialogActions>
      </Dialog>
      <PhysicalEntitySearchModal
        open={searchModalOpen}
        onSubmit={(entity) => {
          setTargetTable(entity);
          setSearchModalOpen(false);
        }}
        onCancel={() => {
          setSearchModalOpen(false);
        }}
      />
    </>
  );
};

const MatchingStrategySelector = ({ dispatchConnectionsAction, matchStrategy, display }) => {
  if (display) {
    return (
      <>
        <h4>{t('physicalEntities.connectionToTargetModal.matchStrategyTitle')}</h4>
        <FormControl variant='standard'>
          <RadioGroup
            row
            aria-labelledby='radio-buttons-match-strategy'
            defaultValue={matchStrategy}
            name='radio-buttons-group'>
            <FormControlLabel
              value='sortByPositionIndex'
              control={
                <Radio
                  color='primary'
                  onChange={() => dispatchConnectionsAction({ type: 'sortByPositionIndex' })}
                />
              }
              label={t('physicalEntities.connectionToTargetModal.ordinalPosition')}
            />
            <FormControlLabel
              value='sortByColumnName'
              control={
                <Radio
                  color='primary'
                  onChange={() => dispatchConnectionsAction({ type: 'sortByColumnName' })}
                />
              }
              label={t('physicalEntities.connectionToTargetModal.fieldsName')}
            />
          </RadioGroup>
        </FormControl>
      </>
    );
  } else {
    return <></>;
  }
};

const ConnectToTargetTableButton = ({ targetTable, setSearchModalOpen }) => {
  if (targetTable) {
    return <Typography>{targetTable.name}</Typography>;
  } else {
    return (
      <Tooltip title={t('physicalEntities.connectionToTargetModal.buttons.connectToTargetTable')}>
        <IconButton size='large' onClick={() => setSearchModalOpen(true)}>
          <LinkIcon fontSize='large' />
        </IconButton>
      </Tooltip>
    );
  }
};

const CurrentEntity = ({ connectionAtPhysicalEntityLevel, physicalEntity, currentFields }) => {
  if (connectionAtPhysicalEntityLevel) {
    return (
      <>
        <h2>{t('physicalEntities.connectionToTargetModal.currentEntity')}</h2>
        <Typography>{physicalEntity.name}</Typography>
      </>
    );
  } else {
    return (
      <>
        <h2>Current Entity Physical Fields</h2>
        <NodesToConnectTable nodes={currentFields} title='Physical Fields' />
      </>
    );
  }
};

const EntityToConnect = ({
  dataFlowDirection,
  connectionAtPhysicalEntityLevel,
  targetTable,
  fieldsToConnect,
  dispatchConnectionsAction,
  setSearchModalOpen
}) => {
  if (connectionAtPhysicalEntityLevel) {
    return (
      <>
        <h2>
          {t('physicalEntities.connectionToTargetModal.targetEntity', {
            dataFlowDirection: dataFlowDirection
          })}
        </h2>
        <ConnectToTargetTableButton
          targetTable={targetTable}
          setSearchModalOpen={setSearchModalOpen}
        />
      </>
    );
  } else {
    return (
      <>
        <h2>
          {t('physicalEntities.connectionToTargetModal.targetFields', {
            dataFlowDirection: dataFlowDirection
          })}
        </h2>
        <TargetNodesTable
          targetTable={targetTable}
          physicalFields={fieldsToConnect}
          dispatchTableAction={dispatchConnectionsAction}
          setSearchModalOpen={setSearchModalOpen}
        />
      </>
    );
  }
};

const CurrentEntityAndEntityToConnect = ({
  connectionAtPhysicalEntityLevel,
  physicalEntity,
  dataFlowDirection,
  targetTable,
  connections,
  dispatchConnectionsAction,
  setSearchModalOpen
}) => {
  if (dataFlowDirection === 'Incoming') {
    return (
      <Grid container>
        <Grid item md={6} style={{ textAlign: 'center' }}>
          <EntityToConnect
            dataFlowDirection={dataFlowDirection}
            connectionAtPhysicalEntityLevel={connectionAtPhysicalEntityLevel}
            targetTable={targetTable}
            fieldsToConnect={connections.edges.map((edge) => edge.fromPhysicalField)}
            dispatchConnectionsAction={dispatchConnectionsAction}
            setSearchModalOpen={setSearchModalOpen}
          />
        </Grid>
        <Grid
          item
          md={6}
          sx={{ borderLeft: '1px solid', borderColor: 'lightgray', textAlign: 'center' }}>
          <CurrentEntity
            connectionAtPhysicalEntityLevel={connectionAtPhysicalEntityLevel}
            currentFields={connections.edges.map((edge) => edge.toPhysicalField)}
            physicalEntity={physicalEntity}
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container>
        <Grid
          item
          md={6}
          sx={{ borderRight: '1px solid', borderColor: 'lightgray', textAlign: 'center' }}>
          <CurrentEntity
            connectionAtPhysicalEntityLevel={connectionAtPhysicalEntityLevel}
            currentFields={connections.edges.map((edge) => edge.fromPhysicalField)}
            physicalEntity={physicalEntity}
          />
        </Grid>
        <Grid item md={6} style={{ textAlign: 'center' }}>
          <EntityToConnect
            dataFlowDirection={dataFlowDirection}
            connectionAtPhysicalEntityLevel={connectionAtPhysicalEntityLevel}
            targetTable={targetTable}
            fieldsToConnect={connections.edges.map((edge) => edge.toPhysicalField)}
            dispatchConnectionsAction={dispatchConnectionsAction}
            setSearchModalOpen={setSearchModalOpen}
          />
        </Grid>
      </Grid>
    );
  }
};

function sortByColumnName(physicalEntity, dataFlowDirection, targetTable) {
  return physicalEntity.physicalFields.map((currentPf) => {
    if (dataFlowDirection === 'Incoming') {
      return {
        fromPhysicalField:
          _.findLast(
            targetTable?.physicalFields || [],
            (pf) => pf?.name.toUpperCase() === currentPf.name.toUpperCase()
          ) || null,
        toPhysicalField: currentPf
      };
    } else {
      return {
        fromPhysicalField: currentPf,
        toPhysicalField: targetTable
          ? _.findLast(
              targetTable.physicalFields,
              (pf) => pf.name.toUpperCase() === currentPf.name.toUpperCase()
            ) || null
          : null
      };
    }
  });
}

function sortByPositionIndex(physicalEntity, dataFlowDirection, targetTable) {
  return physicalEntity.physicalFields.map((currentPf) => {
    if (dataFlowDirection === 'Incoming') {
      return {
        fromPhysicalField:
          _.findLast(
            targetTable?.physicalFields || [],
            (pf) => pf?.ordinalPosition === currentPf.ordinalPosition
          ) || null,
        toPhysicalField: currentPf
      };
    } else {
      return {
        fromPhysicalField: currentPf,
        toPhysicalField:
          _.findLast(
            targetTable.physicalFields || [],
            (pf) => pf?.ordinalPosition === currentPf.ordinalPosition
          ) || null
      };
    }
  });
}

export default PhysicalEntitiesConnectModal;
