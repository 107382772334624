import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchConfiguration } from 'actions/quality/assessment/QualityAssessmentConfigurationActions';
import {
  fetchCheckEvaluationsMatrix,
  modifyCheckEvaluation,
  deleteCheckEvaluation
} from 'actions/quality/assessment/QualityAssessmentCheckEvaluationActions';

import {
  CardContent,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  Card,
  TableBody
} from '@mui/material';

import TableCell from 'components/tables/GenericTableCell';
import CheckEvaluationModal from './CheckEvaluationModal';

function QualityAssessmentCheckPanel({ qualityCheck }) {
  let dispatch = useDispatch();
  const [checkEval, setCheckEval] = useState(null);
  let checkEvaluations = useSelector(
    (state) => state.quality.assessment.checkEvaluations[qualityCheck?.uuid]
  );
  useEffect(() => {
    if (!checkEvaluations) {
      dispatch(fetchCheckEvaluationsMatrix(qualityCheck?.uuid));
    }
  }, [dispatch, qualityCheck, checkEvaluations]);
  let configuration = useSelector((state) => state.quality.assessment.configuration);
  useEffect(() => {
    if (!configuration) {
      dispatch(fetchConfiguration());
    }
  }, [dispatch, configuration]);
  if (!checkEvaluations) {
    return null;
  }
  return (
    <Card>
      <CardHeader title='Check Assessment'></CardHeader>
      <CardContent>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Risk Code</TableCell>
              <TableCell>Risk Category</TableCell>
              <TableCell>Risk Name</TableCell>
              <TableCell>Severity Reduction</TableCell>
              <TableCell>Probability Reduction</TableCell>
              <TableCell>Check evaluation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checkEvaluations.content.map((ce) => (
              <TableRow
                hover
                style={{ cursor: 'pointer' }}
                key={ce?.risk?.uuid}
                onClick={() => setCheckEval(ce)}>
                <TableCell>{ce?.risk?.code}</TableCell>
                <TableCell>{ce?.risk?.category}</TableCell>
                <TableCell>{ce?.risk?.name}</TableCell>
                <TableCell>{ce?.severityReduction?.label || '-'}</TableCell>
                <TableCell>{ce?.probabilityReduction?.label || '-'}</TableCell>
                <TableCell>{ce?.checkImpact?.label || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CheckEvaluationModal
          key={checkEval}
          open={checkEval != null}
          checkEvaluation={checkEval}
          onCancel={() => setCheckEval(null)}
          onSubmit={(checkEvaluation) => {
            setCheckEval(null);
            dispatch(modifyCheckEvaluation(checkEvaluation)).then(() =>
              dispatch(fetchCheckEvaluationsMatrix(checkEvaluation.qualityCheck.uuid))
            );
          }}
          onDelete={(checkEvaluation) => {
            setCheckEval(null);
            dispatch(deleteCheckEvaluation(checkEvaluation)).then(() =>
              dispatch(fetchCheckEvaluationsMatrix(checkEvaluation.qualityCheck.uuid))
            );
          }}></CheckEvaluationModal>
      </CardContent>
    </Card>
  );
}

export default QualityAssessmentCheckPanel;
