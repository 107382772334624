import React from 'react';
import { useReactFlow, Handle, Position } from 'reactflow';
import _ from 'lodash';

import { Paper, Collapse, Typography, IconButton } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import ErDiagramFieldNode from './ErDiagramFieldNode';

const styles = {
  headline: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#00aeca',
    borderRadius: '5px 5px 0 0',
    padding: '5px',
    textAlign: 'center'
  },
  headlineTextColor: {
    color: '#f5f5f5'
  },
  body: {
    background: 'rgba(230, 234, 240, 0.2)'
  }
}

const ErDiagramNode = ({ id, data }) => {

  const reactFlowInstance = useReactFlow();
  const handleCollapse = () => {
    const updatedNodes = reactFlowInstance.getNodes().map((n) => n.id === id ? ({ ...n, data: { ...n.data, collapsed: !n.data.collapsed } }) : n);
    reactFlowInstance.setNodes(updatedNodes);
  }

  return (
    <Paper sx={{ cursor: 'context-menu' }}>
      <div style={styles.headline}>
        <Handle
          type='target'
          position={Position.Left}
          id={`to.${data.nodeId}`}
          style={{ position: 'relative', visibility: 'hidden' }}
        />
        <Typography variant="h6" style={{ flexGrow: 1, ...styles.headlineTextColor }}>{data.name}</Typography>
        <IconButton size='small' style={styles.headlineTextColor} onClick={handleCollapse}>
          {data.collapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
        <Handle
          type='source'
          position={Position.Right}
          id={`from.${data.nodeId}`}
          style={{ position: 'relative', visibility: 'hidden' }}
        />
      </div>
      {data.collapsed &&
        data.fields.map((field) => (
          <div key={field.physicalFieldUuid}>
            <Handle
              type='target'
              position={Position.Left}
              id={`to.${field.nodeId}`}
              style={{ visibility: 'hidden' }}
            />
            <Handle
              type='source'
              position={Position.Right}
              id={`from.${field.nodeId}`}
              style={{ visibility: 'hidden' }}
            />
          </div>
        ))}
      <Collapse style={styles.body} in={!data.collapsed}>
        {_.sortBy(data.fields, ['ordinalPosition']).map((field) => <ErDiagramFieldNode key={field.physicalFieldUuid} entity={data} field={field} />)}
      </Collapse>
    </Paper>
  );
};

export default ErDiagramNode;