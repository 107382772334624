import React, { useMemo, useReducer } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AgentConnectionItemAutoComplete from 'pages/settings/agents/commons/AgentConnectionItemAutoComplete';
import TimeZonesMenuItem from 'components/timezone/TimeZonesMenuItem';
import CronExpressionTextField from 'components/CronExpressionTextField';
import QueryBucketScheduleRunButton from './QueryBucketScheduleRunButton';

const defaultEditorState = (queryBucket) => ({
  name: `${queryBucket.name} - query extractor`,
  externalIdentifier: queryBucket.uuid,
  timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  cronExpression: null,
  scheduled: false,
  type: 'QUERY_EXTRACTOR',
  jobDetails: {
    type: 'QUERY_EXTRACTOR',
    configs: {
      connectionName: null,
      extractionQuery:
        'select v.VIEW_DEFINITION as QUERY_TEXT,\n v.TABLE_SCHEMA as SCHEMA_NAME,\n v.TABLE_NAME as TABLE_NAME, v.TABLE_NAME as STATEMENT_NAME\n from INFORMATION_SCHEMA.VIEWS v',
      appendCreateViewAs: true,
      queryBucketUuid: queryBucket.uuid,
      outputOptions: {
        dryRun: false,
        targetConnectionName: null
      }
    }
  }
});

function QueryBucketScheduleModal({ schedule, queryBucket, agent, open, onSubmit, onCancel }) {
  let { t } = useTranslation();
  const defaultState = useMemo(() => defaultEditorState(queryBucket), [queryBucket]);
  const [editor, setEditor] = useReducer(
    (state, action) => ({ ...state, ...action }),
    schedule
      ? { ...schedule, queryBucket: queryBucket.name, queryBucketUuid: queryBucket.uuid }
      : defaultState
  );
  return (
    <Dialog open={open}>
      <DialogTitle>Query Extraction Schedule Modal</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          margin='dense'
          label='Schedule Name'
          value={editor.name || ''}
          onChange={(event) => setEditor({ name: event.target.value })} />
        <CronExpressionTextField
          value={editor.cronExpression || ''}
          onChange={(cronExpression) => setEditor({ cronExpression })}
        />
        <TimeZonesMenuItem
          value={editor.timezoneId}
          onChange={(timezoneId) => setEditor({ timezoneId })}
        />

        <FormControlLabel
          control={
            <Checkbox
              size='small'
              checked={editor.scheduled}
              onChange={(event) => setEditor({ ...editor, scheduled: event.target.checked })}
              name='enabled'
              color='primary'
            />
          }
          label='Job Scheduled'
        />
        <QueryBucketScheduleJobConfigsEditor
          agent={agent}
          queryBucket={queryBucket}
          configs={editor.jobDetails.configs}
          onChange={(configs) => setEditor({ jobDetails: { ...editor.jobDetails, configs } })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
        <QueryBucketScheduleRunButton jobDefinition={editor} agent={agent} onlyTest />
        <Button onClick={() => onSubmit(editor)}>{t('commons.actions.submit')}</Button>
      </DialogActions>
    </Dialog>
  );
}

function QueryBucketScheduleJobConfigsEditor({ configs, agent, onChange, queryBucket }) {
  return <>
    <Typography variant='subtitle1'>Settings</Typography>
    <AgentConnectionItemAutoComplete
      fullWidth
      margin='dense'
      value={configs?.connectionName || ''}
      onChange={(value) => onChange({ ...configs, connectionName: value })}
      agent={agent}
    />
    <TextField
      variant="standard"
      fullWidth
      margin='dense'
      multiline
      maxRows={4}
      label='Extraction Query'
      value={configs.extractionQuery || ''}
      onChange={(event) => onChange({ ...configs, extractionQuery: event.target.value })} />
    <FormControlLabel
      control={
        <Checkbox
          checked={configs.appendCreateViewAs || false}
          onChange={(event) => {
            onChange({ ...configs, appendCreateViewAs: event.target.checked });
          }}
          name='append-create-view'
          color='primary'
        />
      }
      label='Append Create View'
    />
    <OutputOptionsEditor
      {...{
        agent,
        outputOptions: configs.outputOptions,
        onChange: (outputOptions) => onChange({ ...configs, outputOptions })
      }}></OutputOptionsEditor>
  </>;
}

function OutputOptionsEditor({ outputOptions, agent, onChange }) {
  return (
    <>
      <Typography variant='subtitle1'>Output</Typography>
      <AgentConnectionItemAutoComplete
        fullWidth
        margin='dense'
        connectionTypes={['BLINDATA']}
        value={outputOptions?.targetConnectionName || ''}
        onChange={(value) => onChange({ ...outputOptions, targetConnectionName: value })}
        agent={agent}
      />
    </>
  );
}

export default QueryBucketScheduleModal;
