import { combineReducers } from 'redux';
import {
  FETCH_STEWARDSHIP_DASHBOARD_TABLE,
  FETCH_STEWARDSHIP_DASHBOARD_ROLES,
  FETCH_STEWARDSHIP_DASHBOARD_OVERALL,
  FETCH_STEWARDSHIP_DASHBOARD_USERS_TABLE
} from '../../constants/ActionTypes';

const table = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_STEWARDSHIP_DASHBOARD_TABLE:
      return action.payload;
    default:
      return state;
  }
};

const tableusers = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_STEWARDSHIP_DASHBOARD_USERS_TABLE:
      return action.payload;
    default:
      return state;
  }
};

const coverage = (state = { columns: [], rows: [] }, action) => {
  switch (action.type) {
    case FETCH_STEWARDSHIP_DASHBOARD_ROLES:
      return action.payload;
    default:
      return state;
  }
};

const overall = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STEWARDSHIP_DASHBOARD_OVERALL:
      return { ...state, [action.payload.resourceType]: action.payload };
    default:
      return state;
  }
};

export default combineReducers({ table, tableusers, coverage, overall });
