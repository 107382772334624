import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  IconButton
} from '@mui/material';
import { compose } from 'redux';
import { withLoader } from '../../../../components/loader/Loader';
import { connect } from 'react-redux';
import { fetchTeamUserSettings, modifyTeamUserSettings } from '../../../../actions/UsersActions';
import { ChipItem } from '../../../../components/chiplist/ChipList';
import UserTeamSettingsEditor from './UserTeamSettingsEditor';
import EditIcon from '@mui/icons-material/Edit';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';

function UserTeamSettingsCard({ userUuid, editable, teamSettings, modifyTeamUserSettings }) {
  const [open, setOpen] = useState(false);
  return (
    <Card>
      <CardHeader title='Default Settings'></CardHeader>
      <CardContent>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Default Team</TableCell>
              <TableCell>Read All</TableCell>
              <TableCell>Write All</TableCell>
              <TableCell>Propose All</TableCell>
              <TableCell>Approve All</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell>
              {teamSettings.defaultTeam ? (
                <ChipItem
                  label={teamSettings.defaultTeam.name}
                  to={`/settings/teams/${teamSettings.defaultTeam.code}`}
                />
              ) : (
                'Not defined'
              )}
            </TableCell>
            <TableCell>
              <Checkbox checked={teamSettings.canReadAll} disabled />
            </TableCell>
            <TableCell>
              <Checkbox checked={teamSettings.canWriteAll} disabled />
            </TableCell>
            <TableCell>
              <Checkbox checked={teamSettings.canProposeAll} disabled />
            </TableCell>
            <TableCell>
              <Checkbox checked={teamSettings.canApproveAll} disabled />
            </TableCell>
            <TableCell>
              {editable ? (
                <Authorize hasPermissions={[Permissions.USERS_EDITOR]}>
                  <IconButton onClick={() => setOpen(true)} size="large">
                    <EditIcon></EditIcon>
                  </IconButton>
                </Authorize>
              ) : null}
            </TableCell>
          </TableBody>
        </Table>
      </CardContent>
      <UserTeamSettingsEditor
        teamSettings={teamSettings}
        open={open}
        onSubmit={(teamSettings) => {
          modifyTeamUserSettings({ userUuid, teamSettings }).then((data) => data && setOpen(false));
        }}
        onCancel={() => setOpen(false)}></UserTeamSettingsEditor>
    </Card>
  );
}

function mapStateToProps(state, { userUuid }) {
  return {
    teamSettings: state.settings.users.teamSettings[userUuid]
  };
}

const mapDispatchToProps = {
  fetchTeamUserSettings,
  modifyTeamUserSettings
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchTeamUserSettings, userUuid }) => fetchTeamUserSettings({ userUuid }),
    ready: ({ teamSettings }) => teamSettings
  })
)(UserTeamSettingsCard);
