import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import jsyaml from 'js-yaml';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // if not using I18nextProvider
  .init({
    ns: ['translation', 'suggestion', 'countries'],
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    load: 'all',
    interpolation: {
      escapeValue: false // not needed for react!!
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.yml',
      parse: (data) => jsyaml.safeLoad(data)
    },

    // react i18next special options (optional)
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    }
  });

export default i18n;
