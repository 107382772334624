import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createComment } from '../../../actions/CommentsActions';
import { Grid, TextField, Button } from '@mui/material';

function CommentsAdder({
  resourceType,
  resourceName,
  resourceIdentifier,
  createComment,
  onToggleSort
}) {
  let [content, setContent] = React.useState('');
  let onCreateComment = () => {
    const comment = {
      content,
      resourceType,
      resourceIdentifier,
      commentSubject: resourceName
    };
    setContent('');
    createComment(comment);
  };
  return (
    <Grid container alignItems='flex-end'>
      <Grid item>
        <TextField
          variant="standard"
          style={{ minWidth: 300 }}
          placeholder='Ask a question or post an update'
          fullWidth
          multiline
          label='Comment'
          value={content}
          onChange={(event) => setContent(event.target.value)} />
      </Grid>
      <Grid item>
        <Button color='primary' disabled={!content} onClick={onCreateComment}>
          Post
        </Button>
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = {
  createComment
};

export default compose(connect(null, mapDispatchToProps))(CommentsAdder);
