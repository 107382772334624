import { setDataCategoriesFilters } from 'actions/DataCategoriesActions';
import { connect } from 'react-redux';
import SearchBox from 'components/search-box/SearchBox';

export default connect(
  (state) => ({
    value: state.datacategories.filters.content.search
  }),
  (dispatch) => ({
    onChange: ({ value }) => {
      dispatch(setDataCategoriesFilters({ search: value || null }));
    }
  })
)(SearchBox);
