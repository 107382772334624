import { Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { fetchQueryStatement } from 'actions/QueryStatementsActions';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Breadcrumbs from 'pages/sidebar/Navigation';
import QueryStatementDetailSection from './QueryStatementDetailSection';
import { useHistory } from 'react-router';
import useSearchParams from 'components/useSearchParams';
import DataFlowsPreviewCard from '../list/DataFlowPreviewsCard';
import QueryStatementFullLineageCard from './QueryStatementFullLineageCard';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import ResourceTypes from 'constants/ResourceTypes';
import ErrorBoundary from 'components/errors/ErrorBoundary';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const QueryStatementDetailPage = ({ queryStatement, queryStatementUuid, fetchQueryStatement }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useSearchParams();

  const selectedTab = query.get('tab') || 'queryStatementFullLineage';
  const handleChangeTab = (_event, newValue) =>
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });

  useEffect(() => {
    if (queryStatementUuid) {
      fetchQueryStatement(queryStatementUuid);
    }
  }, [queryStatementUuid, fetchQueryStatement]);

  if (!queryStatement) return <></>;

  return (
    <div>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.data_flows'), to: '/dataflows' },
          { text: t('navbar.queryparser'), to: '/dataflows/queryparser' },
          {
            text: queryStatement?.queryBucket?.name,
            to: `/dataflows/queryparser/buckets/${queryStatement?.queryBucket?.uuid}`
          },
          { text: queryStatement?.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('querystatements.datail.header', {
            queryStatementName: queryStatement?.name
          }),
          category: EngagementCategories.QUERY_PARSER,
          action: EngagementActions.LIST
        }}
      />
      <Grid container alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            {t('querystatements.datail.header', {
              queryStatementName: queryStatement?.name
            })}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`${ResourceTypes.QUERY_STATEMENT}:${queryStatement.uuid}`}
            iconSize={'small'}
            pageTitle={t('querystatements.datail.header', {
              queryStatementName: queryStatement?.name
            })}
          />
        </Grid>
        <div style={{ marginLeft: 'auto' }}>
          <WebActionsButton
            resource={queryStatement}
            page={TargetPages.QUERY_STATEMENT_DETAIL_PAGE}
          />
        </div>
      </Grid>

      <QueryStatementDetailSection
        queryStatement={queryStatement}
        onRefresh={() => fetchQueryStatement(queryStatement.uuid)}
      />

      <Tabs value={selectedTab} onChange={handleChangeTab} centered>
        <Tab value='queryStatementFullLineage' label='Full Lineage' />
        <Tab value='dataFlowsPreview' label='Data Flows Preview' />
      </Tabs>
      {selectedTab === 'dataFlowsPreview' && (
        <DataFlowsPreviewCard queryStatement={queryStatement} />
      )}
      {selectedTab === 'queryStatementFullLineage' && (
        <ErrorBoundary>
          <QueryStatementFullLineageCard queryStatement={queryStatement} />
        </ErrorBoundary>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  queryStatementUuid: props.match.params['queryStatementUuid'],
  queryStatement: state.dataflows.querystatements.content[props.match.params['queryStatementUuid']]
});

export default connect(mapStateToProps, { fetchQueryStatement })(QueryStatementDetailPage);
