import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { t } from 'i18next';
import hljsStyle from 'react-syntax-highlighter/dist/cjs/styles/hljs/idea';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import TableCell from 'components/tables/GenericTableCell';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const HttpHeaders = ({ headers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onToggleOpen = () => setIsOpen(!isOpen);
  return (
    <>
      <Grid container alignItems='center'>
        <h4>{t('webActions.executionModal.result.headers.title')}</h4>
        <IconButton size='small' onClick={onToggleOpen}>
          <ExpandMoreIcon
            sx={{
              transform: !isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: (theme) =>
                theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest
                })
            }}
          />
        </IconButton>
      </Grid>
      {isOpen ? (
        <TableContainer style={{ width: '100%' }}>
          {headers ? (
            <Table size='small'>
              <TableHead>
                <TableCell>{t('webActions.executionModal.result.headers.name')}</TableCell>
                <TableCell>{t('webActions.executionModal.result.headers.content')}</TableCell>
              </TableHead>
              <TableBody>
                {Object.keys(headers || {}).map((key) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>
                      <SyntaxHighlighter
                        style={hljsStyle}
                        customStyle={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                        {headers[key]}
                      </SyntaxHighlighter>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography>{t('webActions.executionModal.result.headers.noHeader')}</Typography>
          )}
        </TableContainer>
      ) : (
        <></>
      )}
    </>
  );
};
