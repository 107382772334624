import {
  Typography,
  TablePagination,
  TableContainer,
  FormControlLabel,
  Switch
} from '@mui/material';
import { fetchPhysicalConstraintsPageByType } from 'actions/PhysicalConstraintsActions';
import ExpandableCard from 'components/expandable-card/ExpandableCard';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ConstraintTypesWithoutReferences } from 'constants/PhysicalConstraintTypes';
import PhysicalConstraintsTable from './PhysicalConstraintsTable';

function getFetchParams(type, physicalEntityUuid, physicalFieldUuid, isReferenced) {
  let fetchParams = {
    constraintType: type,
    physicalEntityUuid: physicalEntityUuid,
    physicalFieldUuid: physicalFieldUuid,
    isReferenced: isReferenced
  };
  if (type === 'PRIMARY_KEY' || type === 'FOREIGN_KEY' || type === 'INDEX') {
    fetchParams.constraintTypes = [type];
  } else {
    fetchParams.constraintTypesNot = ['PRIMARY_KEY', 'FOREIGN_KEY', 'INDEX'];
  }
  return fetchParams;
}

const PhysicalConstraintsExpandableCard = ({
  type,
  title,
  physicalEntityUuid,
  physicalFieldUuid
}) => {
  const [open, setOpen] = useState(false);
  const [isReferenced, setIsReferenced] = useState(false);

  const { content, number, size, totalElements } =
    useSelector((state) => state.physicalconstraints.contentByType[type]) || {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      let fetchParams = getFetchParams(type, physicalEntityUuid, physicalFieldUuid, isReferenced);
      dispatch(fetchPhysicalConstraintsPageByType(fetchParams));
    }
  }, [open, dispatch, type, physicalEntityUuid, physicalFieldUuid, isReferenced]);

  const hasReferences = !ConstraintTypesWithoutReferences.includes(type);

  return (
    <ExpandableCard
      onExpandChange={setOpen}
      title={<Typography variant='subtitle1'>{title}</Typography>}>
      {hasReferences && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <FormControlLabel
            sx={{ paddingBottom: 1 }}
            control={
              <Switch
                checked={isReferenced}
                onChange={() => setIsReferenced((isReferenced) => !isReferenced)}
                color='primary'
              />
            }
            label={'Referenced'}
            labelPlacement='left'
          />
        </div>
      )}
      {content && content.length > 0 ? (
        <TableContainer>
          <PhysicalConstraintsTable
            physicalConstraints={content}
            hasReferences={hasReferences}
            isReferenced={isReferenced}
          />
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component='div'
            count={totalElements}
            page={number}
            rowsPerPage={size}
            onPageChange={(event, page) =>
              dispatch(
                fetchPhysicalConstraintsPageByType({
                  page: page,
                  size: size,
                  ...getFetchParams(type, physicalEntityUuid, physicalFieldUuid, isReferenced)
                })
              )
            }
            onRowsPerPageChange={(event) =>
              dispatch(
                fetchPhysicalConstraintsPageByType({
                  page: 0,
                  size: event.target.value,
                  ...getFetchParams(type, physicalEntityUuid, physicalFieldUuid, isReferenced)
                })
              )
            }
          />
        </TableContainer>
      ) : (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='body1'>No physical constraint defined</Typography>
        </div>
      )}
    </ExpandableCard>
  );
};

export default PhysicalConstraintsExpandableCard;
