import { combineReducers } from 'redux';
import crudReducers from 'reducers/utils';
import { EXECUTE_AGENT_WEB_ACTION_CONFIGURATION } from 'constants/ActionTypes';

const configurationExecutionResult = (state = {}, action) => {
  if (action.type === EXECUTE_AGENT_WEB_ACTION_CONFIGURATION) {
    return { ...state, [action.payload.configurationUuid]: action.payload };
  } else {
    return state;
  }
};

export default combineReducers({
  configurationExecutionResult,
  ...crudReducers({ resource: 'AGENT_WEB_ACTION_CONFIGURATION', keyProperty: 'uuid' })
});
