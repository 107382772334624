import { AddFAB } from 'components/fab';
import { t } from 'i18next';
import React, { useState } from 'react';
import PhysicalConstraintModal from './PhysicalConstraintModal';
import ActionsBuilder from 'actions/PhysicalConstraintsActions';
import { useDispatch } from 'react-redux';

const PhysicalConstraintFAB = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      {open && (
        <PhysicalConstraintModal
          open={open}
          title={t('physicalConstraints.modal.createTitle')}
          onClose={() => setOpen(false)}
          onSubmit={(pc) => dispatch(ActionsBuilder.create(pc))}
        />
      )}
    </>
  );
};

export default PhysicalConstraintFAB;
