import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  exportProbeDefinitionsCSV,
  exportProbeDefinitionsYAML
} from 'actions/quality/probes/ProbesDefinitionsActions';

import { Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';

const QualityProbesExportButton = ({
  exportProbeDefinitionsCSV,
  exportProbeDefinitionsYAML,
  projectUuid,
  tagUuid
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return <>
    <Tooltip title='Export'>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="large">
        <DownloadIcon />
      </IconButton>
    </Tooltip>
    <Menu
      id='export-menu'
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}>
      <MenuItem
        onClick={() => {
          exportProbeDefinitionsCSV({
            projectUuid: projectUuid,
            tagUuid: tagUuid
          });
          setAnchorEl(null);
        }}>
        Export CSV
      </MenuItem>
      <MenuItem
        onClick={() => {
          exportProbeDefinitionsYAML({
            projectUuid: projectUuid,
            tagUuid: tagUuid,
            includeConnections: true
          });
          setAnchorEl(null);
        }}>
        Export YAML (connections included)
      </MenuItem>
      <MenuItem
        onClick={() => {
          exportProbeDefinitionsYAML({
            projectUuid: projectUuid,
            tagUuid: tagUuid,
            includeConnections: false
          });
          setAnchorEl(null);
        }}>
        Export YAML (connections excluded)
      </MenuItem>
    </Menu>
  </>;
};

export default connect(null, { exportProbeDefinitionsCSV, exportProbeDefinitionsYAML })(
  QualityProbesExportButton
);
