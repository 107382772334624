import React from 'react';

import { Route, Switch } from 'react-router-dom';

import IssueMngtNavabar from 'pages/issueMngt/IssuMngtNavbar';
import MyWorkPage from 'pages/issueMngt/myWork/MyWorkPage';
import CampaignsListPage from 'pages/issueMngt/campaigns/list/CampaignsListPage';
import CampaignDetailPage from 'pages/issueMngt/campaigns/detail/CampaignDetailPage';
import IssuesListPage from 'pages/issueMngt/issues/list/IssuesListPage';
import IssueDetailPage from 'pages/issueMngt/issues/detail/IssueDetailPage';
import IssuesPoliciesPage from './issuesPolicies/list/IssuesPoliciesPage';

const IssueMngtRoutes = (props) => (
  <>
    <IssueMngtNavabar />
    <Switch>
      <Route exact path={`${props.match.url}/my-work`} component={MyWorkPage} />
      <Route exact path={`${props.match.url}/campaigns`} component={CampaignsListPage} />
      <Route
        exact
        path={`${props.match.url}/campaigns/:campaignUuid`}
        component={CampaignDetailPage}
      />
      <Route exact path={`${props.match.url}/issues`} component={IssuesListPage} />
      <Route exact path={`${props.match.url}/issues/:issueUuid`} component={IssueDetailPage} />
      <Route exact path={`${props.match.url}/policies`} component={IssuesPoliciesPage}></Route>
    </Switch>
  </>
);

export default IssueMngtRoutes;
