import React, { useEffect } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { connect } from 'react-redux';

import {
  fetchWatchlistPage,
  createWatchlistRecord,
  deleteWatchlistRecord
} from '../../../actions/WatchlistActions';
function WatchlistIconButtonView({
  entityType,
  entityIdentifier,
  userIdentifier,
  createWatchlistRecord,
  watchlistSubscription,
  fetchWatchlistPage,
  deleteWatchlistRecord
}) {
  useEffect(
    function () {
      fetchWatchlistPage({
        userUuid: userIdentifier,
        entityType: entityType,
        entityIdentifier: entityIdentifier
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchWatchlistPage]
  );
  if (!watchlistSubscription) {
    return (
      <Tooltip title={'Click to watch'}>
        <IconButton
          onClick={(event) => {
            createWatchlistRecord({
              entityIdentifier: entityIdentifier,
              userUuid: userIdentifier,
              entityType: entityType
            });
            event.stopPropagation();
          }}
          size="large">
          <VisibilityOffIcon />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={'Click to unwatch'}>
      <IconButton
        color='primary'
        onClick={(event) => {
          deleteWatchlistRecord(watchlistSubscription);
          event.stopPropagation();
        }}
        size="large">
        <VisibilityIcon />
      </IconButton>
    </Tooltip>
  );
}

const mapStateToProps = (state, props) => ({
  userIdentifier: state.settings.profile.uuid,
  watchlistSubscription:
    state.social.watchlist.contentByEntity[`${props.entityType}:${props.entityIdentifier}`]
});

const mapDispatchToProps = {
  deleteWatchlistRecord,
  fetchWatchlistPage,
  createWatchlistRecord
};
const WatchlistIconButton = connect(mapStateToProps, mapDispatchToProps)(WatchlistIconButtonView);
export default WatchlistIconButton;
