import React, { useState } from 'react';

import PaginatedPanel from 'components/pagination/PaginatedPanel';
import AgentsListItem from 'pages/settings/agents/list/AgentsListItem';
import EditAgentModal from 'pages/settings/agents/commons/EditAgentModal';
import DeleteAgentModal from 'pages/settings/agents/commons/DeleteAgentModal';
import PairAgentModal from 'pages/settings/agents/commons/PairAgentModal';

const AgentsList = ({
  currentPage,
  fetchAgentsPage,
  fetchAgent,
  modifyAgent,
  deleteAgent,
  pairAgent
}) => {

  const [selectedAgent, setSelectedAgent] = useState(null);
  const [modifyModalOpen, setModifyModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [pairingModalOpen, setPairingModalOpen] = useState(false);

  return (
    <>
      <PaginatedPanel
        currentPage={currentPage}
        onPageSelection={(page) => fetchAgentsPage({ page })}>
        {currentPage.content.map((agent) => (
          <AgentsListItem
            key={agent.uuid}
            agent={agent}
            handleEdit={() => {
              setSelectedAgent(agent);
              setModifyModalOpen(true);
            }}
            handleDelete={() => {
              setSelectedAgent(agent);
              setDeleteModalOpen(true);
            }}
            handlePairing={() => {
              setSelectedAgent(agent);
              setPairingModalOpen(true);
            }}
          />
        ))}
      </PaginatedPanel>

      {modifyModalOpen && (
        <EditAgentModal
          selectedAgent={selectedAgent}
          open={modifyModalOpen}
          setOpen={setModifyModalOpen}
          modifyAgent={modifyAgent}
        />
      )}

      <DeleteAgentModal
        selectedAgent={selectedAgent}
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        deleteAgent={deleteAgent}
      />

      <PairAgentModal
        selectedAgent={selectedAgent}
        open={pairingModalOpen}
        setOpen={setPairingModalOpen}
        pairAgent={pairAgent}
        fetchAgent={fetchAgent}
      />
    </>
  );
};

export default AgentsList;
