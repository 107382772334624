import { buildApiCallAction } from './ActionUtils';
import QualityResultsApi from '../api/QualityResultsApi';
import {
  FETCH_QUALITY_RESULTS_PAGE,
  CREATE_QUALITY_RESULT,
  SET_QUALITY_RESULTS_FILTERS,
  CLEAR_QUALITY_RESULTS_FILTERS,
  FETCH_QUALITY_RESULT,
  DELETE_QUALITY_RESULT,
  EXPORT_QUALITY_RESULTS,
  MODIFY_QUALITY_RESULT
} from '../constants/ActionTypes';

const api = new QualityResultsApi();

export const fetchQualityResultsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getQualityResults(params),
    actionType: FETCH_QUALITY_RESULTS_PAGE
  });

export const patchQualityResult = (result) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.patchQualityResult(result),
    actionType: MODIFY_QUALITY_RESULT
  });

export const fetchQualityResult = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getQualityResult(uuid),
    actionType: FETCH_QUALITY_RESULT
  });

export const deleteQualityResult = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteQualityResult(uuid),
    actionType: DELETE_QUALITY_RESULT
  });
export const createQualityResult = (qualityResult) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postQualityResult(qualityResult),
    actionType: CREATE_QUALITY_RESULT
  });
export const exportResults = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportQualityResults(params),
    actionType: EXPORT_QUALITY_RESULTS
  });
export const setQualityResultsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_QUALITY_RESULTS_FILTERS, payload: filters });
};
export const clearQualityResultsFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_QUALITY_RESULTS_FILTERS });
};

export const applyFilters =
  ({ page, size, qualityCheckUuid, ...newFilters }) =>
  (dispatch, getState) => {
    dispatch(setQualityResultsFilters(newFilters));
    dispatch(
      fetchQualityResultsPage({
        page,
        size,
        ...newFilters,
        qualityCheckUuid
      })
    );
  };
