import React from 'react';
import { Route, Switch } from 'react-router-dom';
import QueryBucketDetailPage from './buckets/detail/QueryBucketDetailPage';
import QueryBucketListPage from './buckets/list/QueryBucketsListPage';
import QueryStatementDetailPage from './statements/detail/QueryStatementDetailPage';

const QueryParserRoutes = (props) => {
  return (
    <>
      <Switch>
        <Route exact path={props.match.url} component={QueryBucketListPage} />
        <Route
          exact
          path={`${props.match.url}/buckets/:queryBucketUuid`}
          component={QueryBucketDetailPage}
        />
        <Route
          exact
          path={`${props.match.url}/buckets/:queryBucketUuid/statements/:queryStatementUuid`}
          component={QueryStatementDetailPage}
        />
      </Switch>
    </>
  );
};

export default QueryParserRoutes;
