import React from 'react';
import PaginatedPanel from '../../../../components/pagination/PaginatedPanel';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchTemplatesMappingsPage } from '../../../../actions/TemplatesActions';
import TeamCodeDecorator from '../../../settings/teams/common/TeamCodeDecorator';

function TemplatesMappingsList({ currentPage, onPageSelection }) {
  return (
    <PaginatedPanel autoload onPageSelection={onPageSelection} currentPage={currentPage}>
      <>
        {currentPage.content.map((item) => (
          <TemplatesMappingsListItem templateMapping={item}></TemplatesMappingsListItem>
        ))}
      </>
    </PaginatedPanel>
  );
}

function TemplatesMappingsListItem({ templateMapping }) {
  const { t } = useTranslation();
  return (
    <ExpandableCard
      title={templateMapping.name}
      subheader={templateMapping.code}
      actions={[
        <Button
          children={t('commons.actions.detail')}
          component={Link}
          to={`/templates/mappings/${templateMapping.sequenceId}`}
        />
      ]}
      decorator={
        templateMapping && (
          <TeamCodeDecorator teamCode={templateMapping.teamCode}></TeamCodeDecorator>
        )
      }></ExpandableCard>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.templates.templatesMappings.currentPage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onPageSelection: (page) => dispatch(fetchTemplatesMappingsPage({ page }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesMappingsList);
