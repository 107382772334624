import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  fetchQualityChecksTimelineByCheck,
  setQualityChecksTimelineByCheckFilters
} from 'actions/QualityDashboardsActions';
import { setQualityResultsFilters } from 'actions/QualityResultsActions';

import QualityChecksTimelineCard from 'pages/quality/kqis/quality_checks/commons/QualityChecksTimelineCard';

const QualityChecksTimelineTab = ({
  qualityCheck,
  data,
  filters,
  fetchQualityChecksTimelineByCheck,
  setQualityResultsFilters,
  setQualityChecksTimelineByCheckFilters
}) => {

  useEffect(() => {
    fetchQualityChecksTimelineByCheck({ qualityChecksUuids: [qualityCheck.uuid], ...filters });
  }, [qualityCheck, filters, fetchQualityChecksTimelineByCheck]);

  const setQualityChecksTimelineFilters = (newFilters) => {
    setQualityChecksTimelineByCheckFilters(newFilters);
    if (newFilters.startedAtGte) setQualityResultsFilters({ startedAtGte: newFilters.startedAtGte, startedAtLt: filters.startedAtLt });
    if (newFilters.startedAtLt) setQualityResultsFilters({ startedAtGte: filters.startedAtGte, startedAtLt: newFilters.startedAtLt });
  }

  return (
    <QualityChecksTimelineCard
      qualityCheckUuid={qualityCheck.uuid}
      data={data}
      filters={filters}
      setQualityChecksTimelineFilters={setQualityChecksTimelineFilters}
      chartWidth='100%'
    />
  );
}

const mapStateToProps = (state, props) => ({
  data: state.quality.qualityDashboards.qualityChecksTimelineByCheck.data,
  filters: state.quality.qualityDashboards.qualityChecksTimelineByCheck.filters
})

const mapDispatchToProps = {
  fetchQualityChecksTimelineByCheck,
  setQualityResultsFilters,
  setQualityChecksTimelineByCheckFilters
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityChecksTimelineTab);