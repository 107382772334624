import React, { useState } from 'react';

import { TextField, FormControlLabel, Switch } from '@mui/material';

const MongoConnectionProperties = ({ properties, updateProperties }) => {
  const [sslEnabled, setSslEnabled] = useState(properties?.ssl || false);

  return <>
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      required
      label={'Endpoint'}
      placeholder={'Enter the endpoint formatted as host[:port]'}
      value={properties.endpoint || ''}
      onChange={(event) => updateProperties('endpoint', event.target.value)} />
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      required
      label={'Database name'}
      placeholder={'Enter the database name'}
      value={properties.databaseName || ''}
      onChange={(event) => updateProperties('databaseName', event.target.value)} />
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      required
      label={'Username'}
      placeholder={'Enter the username'}
      value={properties.user || ''}
      onChange={(event) => updateProperties('user', event.target.value)} />
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      required
      type='password'
      label='Password'
      placeholder='Entre the password'
      value={properties.pwd || ''}
      onChange={(event) => updateProperties('pwd', event.target.value)} />

    <h4>Cluster properties</h4>
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      label={'Replica Set'}
      placeholder={'Enter the replica set name'}
      value={properties.replicaSet || ''}
      onChange={(event) => updateProperties('replicaSet', event.target.value)} />
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      label={'Read Preference'}
      placeholder={'Enter the read preference'}
      value={properties.readPreference || ''}
      onChange={(event) => updateProperties('readPreference', event.target.value)} />

    <h4>SSL properties</h4>
    <FormControlLabel
      control={
        <Switch
          name='sslSwitch'
          color='primary'
          checked={sslEnabled}
          onChange={(event) => {
            setSslEnabled(event.target.checked);
            updateProperties('ssl', event.target.checked);
          }}
        />
      }
      label='Enable SSL'
    />
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      label={'Trust Store'}
      placeholder={'Enter the trust store path'}
      value={properties.trustStore || ''}
      onChange={(event) => updateProperties('trustStore', event.target.value)} />
    <TextField
      variant="standard"
      margin='dense'
      fullWidth
      type='password'
      label='Trust Store Password'
      placeholder='Entre the trust store password'
      value={properties.trustStorePwd || ''}
      onChange={(event) => updateProperties('trustStorePwd', event.target.value)} />
  </>;
};

export default MongoConnectionProperties;
