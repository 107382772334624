export const GRAPH_STYLES = {
  nodes: {
    LOGICAL_FIELD: {
      color: '#4a9cad',
      level: 5,
      size: 3,
      label: 'name',
      icon: {
        font: 'FontAwesome',
        scale: 1.0, // size ratio of (icon / node)
        color: '#fff',
        content: '\uf12e' //fa-puzzle-piece
      }
    },
    DATA_CATEGORY: {
      color: '#4248f4',
      level: 4,
      size: 4,
      label: 'name',
      icon: {
        font: 'FontAwesome',
        scale: 1.0, // size ratio of (icon / node)
        color: '#fff',
        content: '\uf07c' //fa-folder-open-o
      }
    },
    PROCESSING: {
      color: '#ffbc38',
      level: 3,
      label: 'name',
      size: 5,
      icon: {
        font: 'FontAwesome',
        scale: 1.0, // size ratio of (icon / node)
        color: '#fff',
        content: '\uf0e3' //fa-gavel
      }
    },
    TASK: {
      color: '#ff8e38',
      level: 2,
      label: 'name',
      size: 5,
      icon: {
        font: 'FontAwesome',
        scale: 1.0, // size ratio of (icon / node)
        color: '#fff',
        content: '\uf0b1' //fa-briefcase
      }
    },
    SYSTEM_LOGIN: {
      color: '#348224',
      level: 2,
      label: 'username',
      size: 5,
      icon: {
        font: 'FontAwesome',
        scale: 1.0, // size ratio of (icon / node)
        color: '#fff',
        content: '\uf084' //fa-key
      }
    },
    SYSTEM: {
      color: '#6b6b6b',
      level: 1,
      label: 'name',
      size: 7,
      icon: {
        font: 'FontAwesome',
        scale: 1.0, // size ratio of (icon / node)
        color: '#fff',
        content: '\uf1c0' //fa-database
      }
    },
    PHYSICAL_ENTITY: {
      color: '#383838',
      level: 3,
      label: 'fullName',
      size: 4,
      icon: {
        font: 'FontAwesome',
        scale: 1.0, // size ratio of (icon / node)
        color: '#fff',
        content: '\uf0ce' //fa-table
      }
    },
    PHYSICAL_FIELD: {
      color: '#4a3c05',
      level: 4,
      size: 3,
      label: 'name',
      icon: {
        font: 'FontAwesome',
        scale: 1.0, // size ratio of (icon / node)
        color: '#fff',
        content: '\uf15b' //fa-file
      }
    },
    DATA_ACTOR: {
      color: '#FF00CC',
      level: 1,
      size: 7,
      label: 'name',
      icon: {
        font: 'FontAwesome',
        scale: 1.0, // size ratio of (icon / node)
        color: '#fff',
        content: '\uf007' //fa-user
      }
    },
    _searchedNode: {
      color: '#ec7014' //'#e60000'
    }
  },
  edges: {
    REL_DEFAULT: {
      arrows: ''
    },
    REL_DATA_FLOW: {
      dashes: true
    },
    REL_LOGICAL_RELATION: {
      dashes: [1, 10]
    }
  }
};

export const GRAPH_SUBTYPES_STYLES = {
  nodes: {
    APPLICATION: {
      SYSTEM: {
        color: '#62878c',
        icon: {
          content: '\uf108' //fa-desktop
        }
      },
      PHYSICAL_ENTITY: {
        color: '#618185',
        icon: {
          content: '\uf2d2' //fa-window-restore
        }
      },
      PHYSICAL_FIELD: {
        color: '#557478',
        icon: {
          content: '\uf129' //fa-info
        }
      }
    },
    SERVICE: {
      SYSTEM: {
        color: '#4f8271',
        icon: {
          content: '\uf0ac' //fa-globe
        }
      },
      PHYSICAL_ENTITY: {
        color: '#3d6357',
        icon: {
          content: '\uf1c9' //fa-file-code-o
        }
      },
      PHYSICAL_FIELD: {
        color: '#2c473f',
        icon: {
          content: '\uf121' //fa-code
        }
      }
    },
    DATABASE: {
      SYSTEM: {
        color: '#6b6b6b',
        icon: {
          content: '\uf1c0' //fa-database
        }
      },
      PHYSICAL_ENTITY: {
        color: '#383838',
        icon: {
          content: '\uf0ce' //fa-table
        }
      },
      PHYSICAL_FIELD: {
        color: '#4a3c05',
        icon: {
          content: '\uf15b' //fa-file
        }
      }
    },
    QUEUE: {
      SYSTEM: {
        color: '#9c1f1f',
        icon: {
          content: '\uf0e0' //fa-envelope
        }
      },
      PHYSICAL_ENTITY: {
        color: '#801818',
        icon: {
          content: '\uf01c' //fa-inbox
        }
      },
      PHYSICAL_FIELD: {
        color: '#5c1111',
        icon: {
          content: '\uf075' //fa-comment
        }
      }
    },
    ARCHIVE: {
      SYSTEM: {
        color: '#8f7409',
        icon: {
          content: '\uf233' //fa-server
        }
      },
      PHYSICAL_ENTITY: {
        color: '#6e5907',
        icon: {
          content: '\uf07c' //fa-folder-open
        }
      },
      PHYSICAL_FIELD: {
        color: '#545454',
        icon: {
          content: '\uf15b' //fa-file
        }
      }
    },
    JOB: {
      SYSTEM: {
        color: '#7a6487',
        icon: {
          content: '\uf085' //fa-cogs
        }
      },
      PHYSICAL_ENTITY: {
        color: '#63516e',
        icon: {
          content: '\uf013' //fa-cog
        }
      },
      PHYSICAL_FIELD: {
        color: '#493c52',
        icon: {
          content: '\uf07e' //fa-arrows-h
        }
      }
    }
  }
};
