import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddBox';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { DataProductPortList } from '../list/DataProductPortList';
import DataProductPortEditModal from './DataProductPortEditModal';
import AdditionalPropertiesEditor from 'components/additional_properties/AdditionalPropertiesEditor';
import ResourceTypes from 'constants/ResourceTypes';
import DataProductDomainAutoComplete from './DataProductDomainAutoComplete';
import { DataProductPortEntityTypes } from 'constants/DataProductPortEntityTypes';

const DataProductModal = ({ title, open, dataProduct, onClose, onSubmit }) => {
  const [dataProductPortModalOpen, setDataProductPortModalOpen] = useState(false);
  const [dataProductPatched, setDataProductPatched] = useState({});
  const mergedDataProduct = {
    ...dataProduct,
    ...dataProductPatched
  };
  const entityTypes = Object.keys(_.keyBy(mergedDataProduct?.ports, 'entityType'));

  const deletePortFromList = (port) =>
    setDataProductPatched({
      ...dataProductPatched,
      ports: _.differenceWith(mergedDataProduct.ports, [port], _.isEqual)
    });

  const updatePortFromList = (oldPort, newPort) =>
    setDataProductPatched({
      ...dataProductPatched,
      ports: _(mergedDataProduct.ports)
        .differenceWith([oldPort], _.isEqual)
        .unionWith([newPort], _.isEqual)
        .value()
    });

  useEffect(() => {
    if (!open) {
      setDataProductPatched({});
    }
  }, [open, setDataProductPatched]);

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DataProductDefinition
            mergedDataProduct={mergedDataProduct}
            setDataProductPatched={setDataProductPatched}
            dataProductPatched={dataProductPatched}
          />
          <h4>
            Data Product Ports
            <IconButton onClick={() => setDataProductPortModalOpen(true)}>
              <AddIcon />
            </IconButton>
          </h4>
          <Grid container spacing={2}>
            {entityTypes.map((key) => (
              <Grid item lg={12} md={12} xs={12}>
                <DataProductPortList
                  key={key}
                  title={DataProductPortEntityTypes[key] || key}
                  content={(mergedDataProduct.ports || []).filter(
                    (port) => port.entityType === key
                  )}
                  deletePortFromList={deletePortFromList}
                  updatePortFromList={updatePortFromList}
                />
              </Grid>
            ))}
          </Grid>
          <div style={{ paddingTop: '16px' }}>
            <AdditionalPropertiesEditor
              additionalProperties={mergedDataProduct.additionalProperties}
              resourceType={ResourceTypes.DATA_PRODUCT}
              onChange={(newList) =>
                setDataProductPatched({ dataProductPatched, additionalProperties: newList })
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button children={t('commons.actions.cancel')} onClick={onClose} />
          <Button
            children={t('commons.actions.submit')}
            variant='contained'
            color='primary'
            disabled={
              _.isEmpty(dataProductPatched) ||
              !(
                mergedDataProduct.name &&
                mergedDataProduct.version &&
                mergedDataProduct.domain &&
                mergedDataProduct.identifier &&
                mergedDataProduct.displayName
              )
            }
            onClick={() => {
              onSubmit(mergedDataProduct);
            }}
          />
        </DialogActions>
      </Dialog>
      {dataProductPortModalOpen && (
        <DataProductPortEditModal
          handleClose={() => setDataProductPortModalOpen(false)}
          open={dataProductPortModalOpen}
          title='Create New Data Product Port'
          onSubmit={(port) =>
            setDataProductPatched({
              ...dataProductPatched,
              ports: _.unionWith(mergedDataProduct.ports, [port], _.isEqual)
            })
          }
        />
      )}
    </>
  );
};

export default DataProductModal;

const DataProductDefinition = ({
  mergedDataProduct,
  setDataProductPatched,
  dataProductPatched
}) => (
  <>
    <h4>Data Product Definition</h4>
    <Grid container spacing={1}>
      <Grid item lg={12} xs={12}>
        <DataProductDomainAutoComplete
          value={mergedDataProduct.domain || ''}
          margin='dense'
          required={true}
          onChange={(value) =>
            setDataProductPatched({ ...dataProductPatched, domain: value?.domain })
          }
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <TextField
          variant='standard'
          fullWidth
          placeholder='Insert the name to display'
          label='Display Name'
          required
          value={mergedDataProduct.displayName || ''}
          onChange={(event) =>
            setDataProductPatched({ ...dataProductPatched, displayName: event.target.value })
          }
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <TextField
          variant='standard'
          fullWidth
          placeholder='Insert the fully qualified name'
          label='Fully Qualified Name'
          value={mergedDataProduct.name || ''}
          required
          onChange={(event) =>
            setDataProductPatched({ ...dataProductPatched, name: event.target.value })
          }
        />
      </Grid>

      <Grid item lg={12} xs={12}>
        <TextField
          variant='standard'
          fullWidth
          required
          placeholder='Insert the Data Product identifier here for exact search'
          label='Identifier'
          value={mergedDataProduct.identifier || ''}
          onChange={(event) =>
            setDataProductPatched({ ...dataProductPatched, identifier: event.target.value })
          }
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <TextField
          variant='standard'
          fullWidth
          required
          placeholder='Insert the Data Product Version'
          label='Version'
          value={mergedDataProduct.version || ''}
          onChange={(event) =>
            setDataProductPatched({ ...dataProductPatched, version: event.target.value })
          }
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <TextField
          variant='standard'
          fullWidth
          multiline
          placeholder='Enter the description'
          label='Description'
          value={mergedDataProduct.description || ''}
          onChange={(event) =>
            setDataProductPatched({ ...dataProductPatched, description: event.target.value })
          }
        />
      </Grid>
    </Grid>
  </>
);
