import QualityHighlightsApi from 'api/QualityHighlightsApi';
import { buildApiCallAction } from 'actions/ActionUtils';
import {
  FETCH_PHYSICAL_ENTITIES_WITH_QUALITY_HIGHLIGHTS,
  FETCH_PHYSICAL_FIELDS_WITH_QUALITY_HIGHLIGHTS
} from 'constants/ActionTypes';

let api = new QualityHighlightsApi();

export const fetchPhysicalEntitiesWithQualityHighlights = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) =>
      api.getPhysicalEntitiesWithQualityHighlights(params),
    actionType: FETCH_PHYSICAL_ENTITIES_WITH_QUALITY_HIGHLIGHTS
  });

export const fetchPhysicalFieldsWithQualityHighlights = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) =>
      api.getPhysicalFieldsWithQualityHighlights(params),
    actionType: FETCH_PHYSICAL_FIELDS_WITH_QUALITY_HIGHLIGHTS
  });