import { fetchAll, BlindataApi } from './ApiUtils';

export default class ProcessingsApi extends BlindataApi {
  getProcessings(page, size = 20, filters = {}) {
    return this.get(`/api/v1/processings`, { page, size, ...filters });
  }

  searchProcessings(search, { page = 0, size } = {}) {
    return this.get(`/api/v1/processings`, { search, page, size });
  }

  getProcessing(processingUuid) {
    return this.get(`/api/v1/processings/${processingUuid}`);
  }

  postProcessing(processing) {
    return this.post('/api/v1/processings', processing);
  }

  putProcessing(processing) {
    return this.put(`/api/v1/processings/${processing.uuid}`, processing);
  }

  deleteProcessing(processing) {
    return this.delete(`/api/v1/processings/${processing.uuid}`);
  }

  getAllProcessingDisclaimers(processingUuid) {
    return fetchAll(this.getProcessingsDisclaimers.bind(this, processingUuid));
  }

  getProcessingsDisclaimers(processingUuid, page) {
    return this.get(`/api/v1/processings/${processingUuid}/disclaimers`, { page });
  }

  postProcessingDisclaimer(processingUuid, disclaimer) {
    return this.post(`/api/v1/processings/${processingUuid}/disclaimers`, {
      ...disclaimer,
      processingUuid
    });
  }

  getProcessingDisclaimer(processingUuid, processingDisclaimerUuid) {
    return this.get(
      `/api/v1/processings/${processingUuid}/disclaimers/${processingDisclaimerUuid}`
    );
  }

  getLegalBasis(params) {
    return this.get('/api/v1/processings/*/legalBasis', params);
  }

  deleteProcessingDisclaimer(processingUuid, processingDisclaimerUuid) {
    return this.delete(
      `/api/v1/processings/${processingUuid}/disclaimers/${processingDisclaimerUuid}`
    );
  }

  exportProcessings(params) {
    return this.download('/api/v1/processings/*/export', {
      ...params,
      includeProperties: 'false'
    });
  }
}
