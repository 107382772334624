import { IconButton, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { extractConfiguration } from 'reducers/settings/configurations';
import configActions from '../../actions/SettingsConfigurationsActions';

export default function ExternalHelper({ resourceType }) {
  const dispatch = useDispatch();
  const configName = `helper-url-${resourceType}`;
  const configValue = useSelector(extractConfiguration(configName));
  useEffect(() => {
    if (!configValue) {
      dispatch(configActions.fetch(configName));
    }
  }, [dispatch, configName, configValue]);
  if (!configValue || !configValue.value) {
    return null;
  }
  return (
    <Tooltip title='Help'>
      <IconButton
        style={{ marginBottom: 20 }}
        size='small'
        component={'a'}
        target='_blank'
        href={configValue.value}>
        <HelpOutline style={{ height: '0.65em', width: '0.65em' }}></HelpOutline>
      </IconButton>
    </Tooltip>
  );
}
