/* eslint-disable no-useless-constructor */
import { BlindataAgentApi } from './BlindataAgentApi';

export default class AgentConnectionsApi extends BlindataAgentApi {
  constructor(agent) {
    super(agent);
  }

  getAgentConnectionsPage(params) {
    return this.get('/agent-api/v1/config/connections', params);
  }

  getAgentConnection(uuid) {
    return this.get(`/agent-api/v1/config/connections/${uuid}`);
  }

  postAgentConnection(body) {
    return this.post(`/agent-api/v1/config/connections`, body);
  }

  patchAgentConnection(uuid, body) {
    return this.patch(`/agent-api/v1/config/connections/${uuid}`, body);
  }

  deleteAgentConnection(uuid) {
    return this.delete(`/agent-api/v1/config/connections/${uuid}`);
  }

  testAgentConnection(uuid) {
    return this.get(`/agent-api/v1/config/connections/${uuid}/test`);
  }
}
