import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import Breadcrumbs from 'pages/sidebar/Navigation';
import DataCategoriesCompilationKPIsButton from 'pages/datacategories/list/DataCategoriesCompilationKPIsButton';
import DataCategoryExportButton from 'pages/datacategories/commons/DataCategoryExportButton';
import DataCategoriesFiltersButton from 'pages/datacategories/list/DataCategoriesFiltersButton';
import DataCategoriesList from 'pages/datacategories/list/DataCategoriesList';
import DataCategoryCreateFAB from './DataCategoryCreateFAB';
import DataCategoriesSearchBox from './DataCategoriesSearchBox';
import ExternalHelper from 'components/help/ExternalHelper';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

function DataCategoriesListPage({ _props }) {
  let { t } = useTranslation();

  return (
    <React.Fragment>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.logical_model'), to: '/datacategories' },
          { text: t('navbar.dataCategory') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('data_categories.header'),
          category: EngagementCategories.LOGICAL_MODEL,
          action: EngagementActions.LIST
        }}
      />

      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>
            {t('data_categories.header')}
            <ExternalHelper resourceType='datacategories'></ExternalHelper>
          </Typography>
        </Grid>
        <Grid item>
          <DataCategoriesSearchBox />
        </Grid>
        <Grid item>
          <FavoritesWidget
            iconSize={'small'}
            pageTitle={t('data_categories.header')}
            itemIdentifier={'page:datacategories-list'}
          />
        </Grid>
        <Grid item>
          <DataCategoriesCompilationKPIsButton />
        </Grid>
        <Grid item>
          <DataCategoryExportButton></DataCategoryExportButton>
        </Grid>
        <Grid item>
          <DataCategoriesFiltersButton />
        </Grid>
        <Grid item>
          <WebActionsButton page={TargetPages.DATA_CATEGORY_LIST_PAGE} />
        </Grid>
      </Grid>
      <DataCategoriesList />
      <DataCategoryCreateFAB />
    </React.Fragment>
  );
}

export default DataCategoriesListPage;
