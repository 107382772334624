import PhysicalEntitiesApi from '../api/PhysicalEntitiesApi';
import { buildApiCallAction } from './ActionUtils';
import { showMessage, showProgress, hideProgress } from './BehaviourActions';
import {
  RECEIVE_SEARCH_PHYSICAL_ENTITIES,
  FETCH_PHYSICAL_ENTITIES_PAGE,
  FETCH_PHYSICAL_ENTITY,
  CREATE_PHYSICAL_ENTITY,
  MODIFY_PHYSICAL_ENTITY,
  DELETE_PHYSICAL_ENTITY,
  SET_PHYSICAL_ENTITY_FILTER,
  CLEAR_PHYSICAL_ENTITY_FILTER,
  FETCH_PHYSICAL_ENTITY_SCHEMAS,
  FETCH_PHYSICAL_ENTITY_DATA_SET,
  EXPORT_PHYSICAL_ENTITIES
} from '../constants/ActionTypes';

let api = new PhysicalEntitiesApi();

export const fetchPhysicalEntitiesPage = (systemUuid, page, size) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getPhysicalEntitiesBySystem(systemUuid, page, size),
    actionType: FETCH_PHYSICAL_ENTITIES_PAGE,
    payload: (data) => ({ ...data, systemUuid })
  });

export const fetchPhysicalEntitiesPageWithFilters = (filters, page, size) =>
  buildApiCallAction({
    api: api,
    actionType: FETCH_PHYSICAL_ENTITIES_PAGE,
    apiFunction: (api, getState) => {
      let stateFilters = getState().physicalentities.filters.hasFilters
        ? getState().physicalentities.filters
        : {};
      return api.getPhysicalEntitiesPage(page, size, { ...stateFilters, ...filters });
    }
  });

export const fetchPhysicalEntity = (physicalEntityUuid) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getPhysicalEntity(physicalEntityUuid),
    actionType: FETCH_PHYSICAL_ENTITY,
    payload: (data) => ({ ...data, systemUuid: data.system && data.system.uuid })
  });

export const searchPhysicalEntities = ({ searchText, systemUuid }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.searchPhysicalEntities(searchText, systemUuid),
    actionType: RECEIVE_SEARCH_PHYSICAL_ENTITIES
  });

export const createPhysicalEntity = (physicalEntity, afterCreation) => (dispatch, getState) => {
  api.token = getState().authentication.access_token;
  showProgress()(dispatch, getState);
  return api
    .postPhysicalEntity(physicalEntity)
    .then((data) => {
      dispatch({ type: CREATE_PHYSICAL_ENTITY, payload: data });
      afterCreation(data);
    })
    .catch((error) => {
      console.error(error);
      showMessage(error.message)(dispatch, getState);
    })
    .then(() => hideProgress()(dispatch, getState));
};

export const modifyPhysicalEntity = (physicalEntity) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.putPhysicalEntity(physicalEntity),
    actionType: MODIFY_PHYSICAL_ENTITY
  });

export const deletePhysicalEntity = (physicalEntity) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.deletePhysicalEntity(physicalEntity),
    actionType: DELETE_PHYSICAL_ENTITY,
    payload: physicalEntity
  });

export const fetchSchemas = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getSchemas(params),
    actionType: FETCH_PHYSICAL_ENTITY_SCHEMAS
  });

export const fetchDataSet = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getDataSet(params),
    actionType: FETCH_PHYSICAL_ENTITY_DATA_SET
  });

export const fetchTableTypes = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getTableTypes(params),
    actionType: 'FETCH_PHYSICAL_ENTITY_TABLE_TYPES'
  });

export const addPhysicalField = (physicalEntity, physicalField) => (dispatch, getState) => {
  let modified = {
    ...physicalEntity,
    physicalFields: physicalEntity.physicalFields.concat([physicalField])
  };
  return modifyPhysicalEntity(modified)(dispatch, getState);
};

export const addDataCategoryToPhysicalEntity =
  (dataCategories, physicalEntity) => (dispatch, getState) => {
    let modified = {
      ...physicalEntity,
      dataCategories
    };
    return modifyPhysicalEntity(modified)(dispatch, getState);
  };

export const deleteDataCategoryFromPhysicalEntity =
  (dataCategory, physicalEntity) => (dispatch, getState) => {
    let modified = {
      ...physicalEntity,
      dataCategories: physicalEntity.dataCategories.filter((dc) => dc.uuid !== dataCategory.uuid)
    };
    return modifyPhysicalEntity(modified)(dispatch, getState);
  };

export const deletePhysicalField = (physicalEntity, physicalField) => (dispatch, getState) => {
  let modified = {
    ...physicalEntity,
    physicalFields: physicalEntity.physicalFields.filter((pf) => pf.uuid !== physicalField.uuid)
  };
  return modifyPhysicalEntity(modified)(dispatch, getState);
};

export const modifyPhysicalField = (physicalEntity, physicalField) => (dispatch, getState) => {
  let modified = {
    ...physicalEntity,
    physicalFields: physicalEntity.physicalFields.map((pf) =>
      pf.uuid === physicalField.uuid ? physicalField : pf
    )
  };
  return modifyPhysicalEntity(modified)(dispatch, getState);
};

export const setEntitiesFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_PHYSICAL_ENTITY_FILTER, payload: filters });
};

export const clearEntitiesFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_PHYSICAL_ENTITY_FILTER });
};

export const applyFilters =
  ({ page, size, ...newFilters }) =>
  (dispatch, getState) => {
    dispatch(setEntitiesFilters(newFilters));
    dispatch(fetchPhysicalEntitiesPageWithFilters(page, size));
  };
export const fetchPhysicalEntitiesByDataCategory = (page, size, dataCategoryUuid) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) =>
      api.getPhysicalEntitiesPage(page, size, {
        dataCategories: [{ uuid: dataCategoryUuid }],
        includeProperties: false
      }),
    actionType: 'FETCH_PHYSICAL_ENTITIES_BY_DATA_CATEGORY'
  });

export const exportPhysicalEntities = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.exportPhysicalEntities(params),
    actionType: EXPORT_PHYSICAL_ENTITIES
  });
