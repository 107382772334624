import React, { Component } from 'react';
import './App.css';
import { ThemeProvider as ThemeProviderV1 } from '@mui/material/styles';
import ErrorBoundary from './components/errors/ErrorBoundary';
import AppWithSideBar from './pages/sidebar/AppWithSideBar';
import { muiThemeV1 } from './pages/sidebar/ContentTheme';
import PublicRoutes from './pages/public/PublicRoutes';
import BehaviouralContainer from './pages/behavioural/BehaviouralContainer';
import Routes from './pages/Routes';
import UserAccountLoader from 'pages/sidebar/UserAccountLoader';

export default class App extends Component {
  render() {
    var content = (
      <ThemeProviderV1 theme={muiThemeV1}>
        <ErrorBoundary>
          <BehaviouralContainer>
            <Routes />
          </BehaviouralContainer>
        </ErrorBoundary>
      </ThemeProviderV1>
    );
    if (this.props.authenticated) {
      return (
        <UserAccountLoader>
          <AppWithSideBar mainContent={content} />
        </UserAccountLoader>
      );
    } else {
      return <PublicRoutes />;
    }
  }
}
