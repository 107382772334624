import React from 'react';

import { Typography } from '@mui/material';

import PaginatedPanel from 'components/pagination/PaginatedPanel';
import CampaignsListItem from 'pages/issueMngt/campaigns/list/CampaignsListItem';

const CampaignsList = ({ currentPage, handlePageChange }) => {
  return (
    <PaginatedPanel
      currentPage={currentPage}
      onPageSelection={(page) => handlePageChange(page)}
      autoload>
      {currentPage.content.length > 0 ? (
        currentPage.content.map((item) => <CampaignsListItem key={item.uuid} campaign={item} />)
      ) : (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='body1'>No campaigns found</Typography>
        </div>
      )}
    </PaginatedPanel>
  );
};

export default CampaignsList;
