import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { fetchDataProductByPortIdentifier } from 'actions/DataProductsActions';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataProductItemAutoComplete from './DataProductItemAutoComplete';

export const DataProductPortDependencyItemSelect = ({ value, onChange }) => {
  const dataProduct = useSelector((state) => state.dataproducts.dataProductByPortIdentifier[value]);
  const [patchedDataProduct, setPatchedDataProduct] = useState();
  const mergedDataProduct = patchedDataProduct || dataProduct;

  const dispatch = useDispatch();

  const mergedDataProductPort =
    _.find(mergedDataProduct?.ports, (port) => port.identifier === value) || '';

  useEffect(() => {
    if (value && !mergedDataProduct) {
      dispatch(fetchDataProductByPortIdentifier({ portIdentifier: value }));
    }
  }, [mergedDataProduct, dispatch, value]);

  return (
    <>
      <h4>Dependency</h4>
      <DataProductItemAutoComplete
        value={mergedDataProduct}
        onChange={(value) => {
          setPatchedDataProduct(value);
          onChange();
        }}
      />
      <FormControl variant='standard' fullWidth margin='dense'>
        <InputLabel>Data Product Port</InputLabel>
        <Select
          variant='standard'
          value={mergedDataProductPort}
          onChange={(event) => {
            onChange(event.target.value?.identifier);
          }}>
          {mergedDataProduct?.ports?.map((port) => (
            <MenuItem key={port.identifier} value={port}>
              {port.displayName}
            </MenuItem>
          ))}
          <MenuItem value=''>None</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
