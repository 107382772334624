import React, { useState, useEffect } from 'react';
import {
  CardHeader,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Typography,
  TablePagination
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LogicalRelationModal from './LogicalRelationModal';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation, useTranslation } from 'react-i18next';
import { fetchLogicalRelationsPage, createLogicalRelation } from 'actions/LogicalRelationsActions';
import LogicalRelationsCardTable from './LogicalRelationsCardTable';
import AuthorizeTeamStewardship from 'components/permissions/AuthorizeTeamStewardship';
import ResourceTypes from 'constants/ResourceTypes';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import Permissions from 'constants/Permissions';
import { CURRENT_PAGE_INITIAL_STATE } from 'reducers/utils';
import LogicalRelationExportButton from './LogicalRelationExportButton';
import GraphLinkButton from 'pages/graph/utils/GraphLinkButton';

const LogicalRelationsCard = ({
  fetchLogicalRelationsPage,
  createLogicalRelation,
  currentPage,
  dataCategory,
  logicalField
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    fetchLogicalRelationsPage({
      page: 0,
      viewed: null,
      ...getFetchParameters(dataCategory, logicalField)
    });
  }, [dataCategory, logicalField, fetchLogicalRelationsPage]);

  return (
    <Card>
      <CardHeader
        title={t('logicalRelations.card.title')}
        action={
          <>
            <GraphLinkButton
              size='small'
              graphUuidFilter={computeGraphFilter({ dataCategory, logicalField })}
              perspectiveOptions={{
                logicalPerspective: true,
                includeLogicalRelations: true
              }}
            />
            <AuthorizeTeamStewardship
              resourceType={
                dataCategory ? ResourceTypes.DATA_CATEGORY : ResourceTypes.LOGICAL_FIELD
              }
              resourceIdentifier={dataCategory?.uuid || logicalField?.uuid}
              hasPermissions={[Permissions.DATA_CATEGORIES_EDITOR]}
              teamCode={dataCategory?.teamCode || logicalField?.dataCategory?.teamCode}
              teamAccessLevels={[TeamAccessLevels.WRITE]}>
              <AddButton
                createLogicalRelation={createLogicalRelation}
                logicalRelation={{
                  fromDataCategory: dataCategory || null,
                  fromLogicalField: logicalField || null
                }}
              />
            </AuthorizeTeamStewardship>
            <LogicalRelationExportButton dataCategory={dataCategory} logicalField={logicalField} />
          </>
        }></CardHeader>
      <CardContent>
        {currentPage.content && currentPage.content.length > 0 ? (
          <>
            <LogicalRelationsCardTable
              logicalRelations={currentPage.content}
              dataCategory={dataCategory}
              logicalField={logicalField}
            />
            <TablePagination
              component='div'
              style={{ overflowX: 'hidden' }}
              rowsPerPageOptions={[10, 20, 50]}
              page={currentPage.number}
              rowsPerPage={currentPage.size}
              count={currentPage.totalElements}
              onRowsPerPageChange={(event) =>
                fetchLogicalRelationsPage({
                  page: currentPage.number,
                  size: event.target.value,
                  ...getFetchParameters(dataCategory, logicalField)
                })
              }
              onPageChange={(event, page) =>
                fetchLogicalRelationsPage({
                  page: page,
                  size: currentPage.size,
                  ...getFetchParameters(dataCategory, logicalField)
                })
              }
            />
          </>
        ) : (
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>
              No logical relations associated to this resource
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const getFetchParameters = (dataCategory, logicalField) => {
  return {
    aboutDataCategoryUuid: dataCategory ? dataCategory.uuid : null,
    aboutLogicalFieldUuid: logicalField ? logicalField.uuid : null
  };
};

const AddButton = ({ logicalRelation, createLogicalRelation }) => {
  const [openCreate, setOpenCreate] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t('logicalRelations.card.addButton')}>
        <IconButton size='small' onClick={() => setOpenCreate(true)}>
          <AddBoxIcon />
        </IconButton>
      </Tooltip>
      {openCreate && (
        <LogicalRelationModal
          open={openCreate}
          logicalRelation={logicalRelation}
          onClose={() => {
            setOpenCreate(false);
          }}
          onSubmit={(logicalRelation) => {
            setOpenCreate(false);
            createLogicalRelation(logicalRelation);
          }}
        />
      )}
    </>
  );
};

function computeGraphFilter({ dataCategory, logicalField }) {
  if (dataCategory) {
    return {
      entityType: 'DATA_CATEGORY',
      name: dataCategory.name || '',
      object: dataCategory
    };
  } else if (logicalField) {
    return {
      entityType: 'LOGICAL_FIELD',
      name: logicalField.name || '',
      object: logicalField
    };
  }
}

const mapStateToProps = (state, props) => {
  const currentPage = state.logicalrelations.currentPage;
  return {
    currentPage: pageMatches(props, currentPage) ? currentPage : CURRENT_PAGE_INITIAL_STATE
  };
};

function pageMatches(props, currentPage) {
  let aboutDataCategoryUuid = props.dataCategory?.uuid;
  let aboutLogicalFieldUuid = props.logicalField?.uuid;
  return (
    currentPage?.params?.aboutDataCategoryUuid === aboutDataCategoryUuid ||
    currentPage?.params?.aboutLogicalFieldUuid === aboutLogicalFieldUuid
  );
}
export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    fetchLogicalRelationsPage,
    createLogicalRelation
  })
)(LogicalRelationsCard);
