import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import IssueTypes from 'pages/issueMngt/issues/detail/IssueTypes';
import IssuesSeverities from 'pages/issueMngt/issues/detail/IssuesSeverities';
import IssueStatuses from 'pages/issueMngt/issues/detail/IssueStatuses';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid
} from '@mui/material';

import PolicyConfigurationSection from 'pages/issueMngt/issuesPolicies/detail/IssuePolicyModal/PolicyConfigurationSection';
import IssueTemplateConfigurationGeneralSection from 'pages/issueMngt/issuesPolicies/detail/IssuePolicyModal/IssueTemplateConfigurationGeneralSection';
import IssueTemplateConfigurationDetailsSection from 'pages/issueMngt/issuesPolicies/detail/IssuePolicyModal/IssueTemplateConfigurationDetailsSection';
import IssueTemplateConfigurationMoreFieldsSection from 'pages/issueMngt/issuesPolicies/detail/IssuePolicyModal/IssueTemplateConfigurationMoreFieldsSection';

const IssuePolicyModal = ({ open, title, resource, policyToEdit, handleSubmit, handleCancel }) => {
  let { t } = useTranslation();

  const steps = [
    "Configure policy's settings",
    "Configure issue's general information",
    "Configure issue's details",
    "Configure more issue's fields"
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [policy, setPolicy] = useState(
    policyToEdit || {
      active: true,
      issueTemplate: {
        issueType: IssueTypes.TASK,
        severity: IssuesSeverities.INFO,
        priorityOrder: 1000,
        issueStatus: IssueStatuses.TO_DO
      }
    }
  );
  const [policyResource, setPolicyResource] = useState(
    policyToEdit
      ? {
          resourceIdentifier: policyToEdit.resourceIdentifier,
          name: policyToEdit.resourceName,
          resourceType: policyToEdit.resourceType
        }
      : resource
      ? {
          resourceIdentifier: resource?.resourceIdentifier,
          name: resource?.resourceName,
          resourceType: resource?.resourceType
        }
      : null
  );

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit({
        ...policy,
        resourceIdentifier: policyResource?.resourceIdentifier,
        resourceName: policyResource?.name,
        resourceType: policyResource?.resourceType
      });
      handleCancel();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleChange = (change) => setPolicy({ ...policy, ...change });

  return (
    <>
      <Dialog open={open} onClose={handleCancel} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ minHeight: '525px' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <PolicyConfigurationSection
              policyName={policy.name}
              policyType={policy.policyType}
              policyContent={policy.policyContent || {}}
              policyResource={policyResource}
              isActive={policy.active}
              setPolicyResource={setPolicyResource}
              handleChange={handleChange}
            />
          )}
          {activeStep === 1 && (
            <IssueTemplateConfigurationGeneralSection
              issueName={policy.issueTemplate?.name}
              issueDescription={policy.issueTemplate?.description}
              issueType={policy.issueTemplate?.issueType}
              issueCampaign={policy.issueTemplate?.campaign || {}}
              handleChange={(issueTemplateChange) =>
                handleChange({ issueTemplate: { ...policy.issueTemplate, ...issueTemplateChange } })
              }
            />
          )}
          {activeStep === 2 && (
            <IssueTemplateConfigurationDetailsSection
              issueSeverity={policy.issueTemplate?.severity}
              issuePriorityOrder={policy.issueTemplate?.priorityOrder}
              issueStatus={policy.issueTemplate?.issueStatus}
              assignee={policy.issueTemplate?.assignee || {}}
              assigneeTeamCode={policy.issueTemplate?.assigneeTeamCode}
              issueReporter={policy.issueTemplate?.reporter || {}}
              handleChange={(issueTemplateChange) =>
                handleChange({ issueTemplate: { ...policy.issueTemplate, ...issueTemplateChange } })
              }
            />
          )}
          {activeStep === 3 && (
            <IssueTemplateConfigurationMoreFieldsSection
              issueEstimate={policy.issueTemplate?.estimate}
              resolutionTimePeriod={policy.issueTemplate?.timePeriod}
              resolutionTimeTemporalUnit={policy.issueTemplate?.temporalUnit}
              additionalProperties={policy.issueTemplate?.additionalProperties}
              handleChange={(issueTemplateChange) =>
                handleChange({ issueTemplate: { ...policy.issueTemplate, ...issueTemplateChange } })
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Button
                children={t('commons.actions.cancel')}
                onClick={() => {
                  handleCancel();
                }}
              />
            </Grid>
            <Grid item>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              <Button
                variant='contained'
                color='primary'
                disabled={!isStepValid(activeStep, policy)}
                onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

const isStepValid = (activeStep, policy) => {
  const isNumberInputEmpty = (value) => {
    return value === undefined || value === null || value === '';
  };

  const isPositiveNumber = (value, includeZero) => {
    if (isNumberInputEmpty(value)) return false;
    const numericValue = Number(value);
    return numericValue > (includeZero ? -1 : 0);
  };

  const isPositiveInteger = (value, includeZero) => {
    if (isNumberInputEmpty(value)) return false;
    const numericValue = Number(value);
    return Number.isInteger(numericValue) && numericValue > (includeZero ? -1 : 0);
  };

  const isAValidSingleSemaphorePolicy =
    policy.policyType === 'SINGLE_RESULT_SEMAPHORE' && !_.isEmpty(policy.policyContent?.semaphores);

  const isAValideRecurrentSemaphorePolicy =
    policy.policyType === 'RECURRENT_RESULT_SEMAPHORE' &&
    isPositiveInteger(policy.policyContent?.semaphoresNumber, false) &&
    !_.isEmpty(policy.policyContent?.semaphores);

  const isAValidScheduledPolicy =
    policy.policyType === 'SCHEDULED' &&
    !_.isEmpty(policy.policyContent?.cronExpression?.trim()) &&
    !_.isEmpty(policy.policyContent?.timezoneId);

  // eslint-disable-next-line default-case
  switch (activeStep) {
    case 0:
      return (
        !_.isEmpty(policy.name?.trim()) &&
        !_.isEmpty(policy.policyType) &&
        (isAValidSingleSemaphorePolicy ||
          isAValideRecurrentSemaphorePolicy ||
          isAValidScheduledPolicy)
      );
    case 1:
      return (
        !_.isEmpty(policy.issueTemplate.name?.trim()) && !_.isEmpty(policy.issueTemplate.issueType)
      );
    case 2:
      return (
        !_.isEmpty(policy.issueTemplate.severity) &&
        isPositiveInteger(policy.issueTemplate.priorityOrder, true) &&
        !_.isEmpty(policy.issueTemplate.issueStatus)
      );
    case 3:
      return (
        (_.isEmpty(policy.issueTemplate.estimate) ||
          isPositiveNumber(policy.issueTemplate.estimate, true)) &&
        ((isNumberInputEmpty(policy.issueTemplate.timePeriod) &&
          _.isEmpty(policy.issueTemplate.temporalUnit)) ||
          (isPositiveInteger(policy.issueTemplate.timePeriod) &&
            !_.isEmpty(policy.issueTemplate.temporalUnit)))
      );
  }
};

export default IssuePolicyModal;
