import { buildApiCallAction } from './ActionUtils';
import TemplatesApi from '../api/TemplatesApi';
import {
  FETCH_TEMPLATE_PROCESSINGS_PAGE,
  SEARCH_TEMPLATE_PROCESSINGS,
  FETCH_TEMPLATE_PROCESSING,
  CREATE_TEMPLATE_PROCESSING,
  MODIFY_TEMPLATE_PROCESSING,
  DELETE_TEMPLATE_PROCESSING,
  MODIFY_TEMPLATE_TASK,
  CREATE_TEMPLATE_TASK,
  FETCH_TEMPLATE_TASK,
  FETCH_TEMPLATE_TASKS_PAGE,
  SEARCH_TEMPLATE_TASKS,
  DELETE_TEMPLATE_TASK,
  CREATE_TEMPLATE_MAPPING,
  FETCH_TEMPLATE_MAPPING,
  FETCH_TEMPLATE_MAPPINGS_PAGE,
  DELETE_TEMPLATE_MAPPING,
  MODIFY_TEMPLATE_MAPPING,
  CREATE_TEMPLATE_EVENT,
  FETCH_TEMPLATE_EVENT,
  FETCH_TEMPLATE_EVENTS_PAGE,
  DELETE_TEMPLATE_EVENT,
  MODIFY_TEMPLATE_EVENT
} from '../constants/ActionTypes';

const api = new TemplatesApi();

export const fetchTemplatesProcessingsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTemplatesProcessings(params),
    actionType: FETCH_TEMPLATE_PROCESSINGS_PAGE
  });

export const searchTemplatesProcessings = ({ search }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTemplatesProcessings({ search }),
    actionType: SEARCH_TEMPLATE_PROCESSINGS
  });

export const fetchTemplateProcessing = (sequenceId) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTemplateProcessing(sequenceId),
    actionType: FETCH_TEMPLATE_PROCESSING
  });

export const createTemplateProcessing = (templateProcessing) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postTemplateProcessing(templateProcessing),
    actionType: CREATE_TEMPLATE_PROCESSING
  });

export const modifyTemplateProcessing = (templateProcessing) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putTemplateProcessing(templateProcessing),
    actionType: MODIFY_TEMPLATE_PROCESSING
  });

export const deleteTemplateProcessing = (sequenceId) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteTemplateProcessing(sequenceId),
    actionType: DELETE_TEMPLATE_PROCESSING,
    payload: { sequenceId }
  });

export const fetchTemplatesTasksPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTemplatesTasks(params),
    actionType: FETCH_TEMPLATE_TASKS_PAGE
  });

export const searchTemplatesTasks = ({ search }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTemplatesTasks({ search }),
    actionType: SEARCH_TEMPLATE_TASKS
  });

export const fetchTemplateTask = (sequenceId) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTemplateTask(sequenceId),
    actionType: FETCH_TEMPLATE_TASK
  });

export const createTemplateTask = (templateTask) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postTemplateTask(templateTask),
    actionType: CREATE_TEMPLATE_TASK
  });

export const modifyTemplateTask = (templateTask) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putTemplateTask(templateTask),
    actionType: MODIFY_TEMPLATE_TASK
  });

export const deleteTemplateTask = (sequenceId) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteTemplateTask(sequenceId),
    actionType: DELETE_TEMPLATE_TASK,
    payload: { sequenceId }
  });

export const fetchTemplatesMappingsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTemplatesMappings(params),
    actionType: FETCH_TEMPLATE_MAPPINGS_PAGE
  });

export const fetchTemplateMapping = (sequenceId) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTemplateMapping(sequenceId),
    actionType: FETCH_TEMPLATE_MAPPING
  });

export const createTemplateMapping = (templateMapping) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postTemplateMapping(templateMapping),
    actionType: CREATE_TEMPLATE_MAPPING
  });

export const modifyTemplateMapping = (templateMapping) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putTemplateMapping(templateMapping),
    actionType: MODIFY_TEMPLATE_MAPPING
  });

export const deleteTemplateMapping = (sequenceId) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteTemplateMapping(sequenceId),
    actionType: DELETE_TEMPLATE_MAPPING,
    payload: { sequenceId }
  });

export const fetchTemplatesEventsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTemplatesEvents(params),
    actionType: FETCH_TEMPLATE_EVENTS_PAGE
  });

export const fetchTemplateEvent = (sequenceId) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTemplateEvent(sequenceId),
    actionType: FETCH_TEMPLATE_EVENT
  });

export const createTemplateEvent = (templateEvent) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postTemplateEvent(templateEvent),
    actionType: CREATE_TEMPLATE_EVENT
  });

export const modifyTemplateEvent = (templateEvent) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putTemplateEvent(templateEvent),
    actionType: MODIFY_TEMPLATE_EVENT
  });

export const deleteTemplateEvent = (sequenceId) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteTemplateEvent(sequenceId),
    actionType: DELETE_TEMPLATE_EVENT,
    payload: { sequenceId }
  });
