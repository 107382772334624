import { Grid, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { t } from 'i18next';

const QueryBucketStats = ({ queryBucket }) => {
  const complete =
    100 - (queryBucket.totalStatementsInputWaiting * 100) / queryBucket.totalStatements || 0;
  return (
    <Grid
      container
      direction='column'
      style={{ paddingTop: '32px', paddingRight: '32px', width: '400px' }}>
      <Grid item>
        <LinearProgress variant='determinate' value={complete} />
      </Grid>
      <Grid item>
        <Grid container justifyContent='space-evenly'>
          <Grid item md={12}>
            <Typography variant='overline'>
              {complete.toFixed(0)}
              {t('querybuckets.stats.status')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='overline'>
              {queryBucket.totalStatements} {t('querybuckets.stats.queries')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='overline'>
              {queryBucket.totalStatementsErrors} {t('querybuckets.stats.errors')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='overline'>
              {queryBucket.totalStatementsInputWaiting} {t('querybuckets.stats.pending')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QueryBucketStats;
