import { combineReducers } from 'redux'
import {
  SHOW_PROGRESS, HIDE_PROGRESS,
  SHOW_MESSAGE,HIDE_MESSAGE,
  SHOW_SPINNER, HIDE_SPINNER
 } from '../constants/ActionTypes'

const progress = (state = {show:false, count:0, text:''}, action) => {
  switch (action.type) {
    case SHOW_PROGRESS:
      return {
        ...state,
        show: true,
        count: ++state.count,
        text: action.payload.text
      }
    case HIDE_PROGRESS:
      return {
        ...state,
        show: state.count -1 > 0,
        count: state.count > 0 ? state.count - 1 : 0,
        text:''
      }
    default:
      return state
  }
}

const spinner = (state = {show:false, count:0}, action) => {
  switch (action.type) {
    case SHOW_SPINNER:
      return {
        ...state,
        show: true,
        count: ++state.count,
      }
    case HIDE_SPINNER:
      return {
        ...state,
        show: state.count -1 > 0,
        count: state.count > 0 ? state.count - 1 : 0,
      }
    default:
      return state
  }
}

const message = (state = {show:false, text:''}, action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        show: true,
        text: action.payload.text
      }
    case HIDE_MESSAGE:
      return {
        ...state,
        show:false,
        text:''
      }
    default:
      return state
  }
}

export default combineReducers({progress,message,spinner})
