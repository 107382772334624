import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import {
  Grid,
  Button,
  Portal,
  Breadcrumbs as BreadcrumbsMUI,
  Link,
  Typography
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import NavigateBefore from '@mui/icons-material/NavigateBefore';

import _ from 'lodash';

const breadcrumbs = 'breadcrumbs';

const styles = {
  hiddenMd: (theme) => ({
    [theme.breakpoints.down('lg')]: {
      display: 'none!important'
    }
  })
};

export const NavigationActions = () => {
  const history = useHistory();

  return (
    <Grid container direction='row' alignItems='center' sx={styles.hiddenMd}>
      <Grid item>
        <Button size='small' onClick={() => history.goBack()} disabled={history.length === 0}>
          <NavigateBefore />
          Back
        </Button>
      </Grid>
      <Grid item style={{ padding: '0 0 0 1rem' }}>
        <div style={{ float: 'left', flexWrap: 'wrap' }} id={breadcrumbs} />
      </Grid>
    </Grid>
  );
};

const BreadcrumbsPortal = ({ children }) => {
  return <Portal container={document.getElementById(breadcrumbs)}>{children}</Portal>;
};

export const Breadcrumbs = ({ elements }) => {
  return (
    <BreadcrumbsPortal>
      <BreadcrumbsMUI aria-label='breadcrumb' style={{ paddingBottom: '2px' }}>
        {elements.map((element, index) =>
          element.to ? (
            <Link
              key={`breadcrumb-${index}`}
              color='textSecondary'
              variant='caption'
              children={_.truncate(element.text, { length: 40 })}
              component={RouterLink}
              to={element.to}
              underline='hover'
            />
          ) : (
            <Typography key={`breadcrumb-${index}`} variant='caption' color='textSecondary'>
              {_.truncate(element.text, { length: 40 })}
            </Typography>
          )
        )}
      </BreadcrumbsMUI>
    </BreadcrumbsPortal>
  );
};

Breadcrumbs.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired, // Text to display in the breadcrumb
      to: PropTypes.string // Path to redirect the user
    })
  )
};

export default Breadcrumbs;
