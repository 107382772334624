import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchConfiguration } from 'actions/quality/assessment/QualityAssessmentConfigurationActions';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery
} from '@mui/material';

import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import ItemSelectField from 'components/itemselector/ItemSelectField';

export default function CheckEvaluationModal({
  open,
  checkEvaluation,
  onCancel,
  onSubmit,
  onDelete
}) {
  let { t } = useTranslation();
  let dispatch = useDispatch();
  let [editor, setState] = useState(checkEvaluation || {});
  let configuration = useSelector((state) => state.quality.assessment.configuration);
  useEffect(() => {
    if (!configuration) {
      dispatch(fetchConfiguration());
    }
  }, [dispatch, configuration]);
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  if (!configuration) {
    return null;
  }
  const domainElements = configuration.domains || [];
  const severityValues = domainElements
    .filter((row) => row[0] === 'SEVERITY_REDUCTION')
    .map((row) => ({ label: row[2], value: row[1] }));
  const probabilityValues = domainElements
    .filter((row) => row[0] === 'PROBABILITY_REDUCTION')
    .map((row) => ({ label: row[2], value: row[1] }));

  return (
    <Dialog open={open} onClose={onCancel} fullWidth fullScreen={fullScreen}>
      <DialogTitle>Check Evaluation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the evaluation for risk {checkEvaluation?.risk?.name}
        </DialogContentText>
        <ItemSelectField
          fullWidth
          margin='dense'
          itemName='Severity Reduction'
          keyProperty='value'
          nameProperty='label'
          value={editor.severityReduction}
          values={severityValues}
          onChange={(severity) => setState({ ...editor, severityReduction: severity })}
        />
        <ItemSelectField
          fullWidth
          margin='dense'
          itemName='Probability Reduction'
          keyProperty='value'
          nameProperty='label'
          value={editor.probabilityReduction}
          values={probabilityValues}
          onChange={(probability) => setState({ ...editor, probabilityReduction: probability })}
        />
      </DialogContent>
      <DialogActions>
        <ConfirmButton color='secondary' onClick={() => onDelete(editor)}>
          {t('commons.actions.delete')}
        </ConfirmButton>
        <div style={{ flexGrow: 1 }} />
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={!editor.probabilityReduction || !editor.severityReduction}
          variant='contained'
          color='primary'
          onClick={() => onSubmit(editor)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
