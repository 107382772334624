import React from 'react';
import { useTranslation } from 'react-i18next';
import JsonVisualizer from 'components/syntaxhighlithter/JsonVisualizer';

import { Button, Grid, Paper } from '@mui/material';
import ExpandableCard from 'components/expandable-card/ExpandableCard';
import CardDescription from 'components/carddescription/CardDescription';

import ConfirmButton from 'components/confirmbutton/ConfirmButton';

const AgentWebConfigurationListItem = ({
  handleDelete,
  handleEdit,
  agentWebActionConfiguration
}) => {
  const { t } = useTranslation();

  return (
    <ExpandableCard
      key={agentWebActionConfiguration?.uuid}
      title={<h4>{agentWebActionConfiguration?.name}</h4>}
      subheader={` ${
        agentWebActionConfiguration?.requestTemplate?.method
          ? agentWebActionConfiguration?.requestTemplate?.method
          : 'Not defined'
      } ${
        agentWebActionConfiguration?.requestTemplate?.url
          ? agentWebActionConfiguration?.requestTemplate?.url
          : 'Not defined'
      }`}
      actions={
        <>
          <Button onClick={handleEdit}>{t('commons.actions.modify')}</Button>
          <ConfirmButton
            message={`Do you want this configuration?`}
            children={t('data_categories.actions.delete')}
            onClick={handleDelete}></ConfirmButton>
        </>
      }>
      <CardDescription>{agentWebActionConfiguration?.description}</CardDescription>
      <Grid spacing={2} container>
        {agentWebActionConfiguration?.requestTemplate && (
          <Grid item xs={12} sm={6}>
            <TemplateJsonSection
              json={agentWebActionConfiguration?.requestTemplate}
              title={'Request Template'}
            />
          </Grid>
        )}
        {agentWebActionConfiguration?.templateParams?.params && (
          <Grid item xs={12} sm={6}>
            <TemplateJsonSection
              json={agentWebActionConfiguration?.templateParams?.params}
              title={'Template Params'}
            />
          </Grid>
        )}
      </Grid>
    </ExpandableCard>
  );
};

function TemplateJsonSection({ json, title }) {
  return (
    <>
      <Grid container direction='column' wrap='nowrap'>
        <Grid item>
          <h4>{title}</h4>
        </Grid>
        <Grid>
          <Paper variant='outlined'>
            <JsonVisualizer object={json}></JsonVisualizer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default AgentWebConfigurationListItem;
