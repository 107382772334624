import React from 'react';
import { Typography, Grid } from '@mui/material';
import TemplatesTasksLists from './TemplatesTasksList';
import TemplatesTasksFAB from './TemplateTaskFAB';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
export default function TemplatesTasksListPage() {
  return (
    <>
      <Breadcrumbs elements={[{ text: 'Templates', to: '/templates' }, { text: 'Tasks' }]} />
      <Tracking
        info={{
          pageTitle: `Tasks Templates`,
          category: EngagementCategories.TEMPLATES,
          action: EngagementActions.LIST
        }}
      />
      <Grid container alignItems='center' justifyContent='flex-start'>
        <Grid item>
          <Typography variant='h4'>Tasks Templates</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:templatetasks-list'}
            pageTitle={'Tasks Templates'}
          />
        </Grid>
      </Grid>
      <TemplatesTasksLists />
      <TemplatesTasksFAB />
    </>
  );
}
