import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl
} from '@mui/material';
import { ChipItem, ChipWrapper } from 'components/chiplist/ChipList';
import AutoComplete from 'components/autocomplete/AutoComplete';
import { TargetPages } from './TargetPages';

function TargetPagesChipWrapperSelectModal({ onChange, title, pages, onDelete }) {
  const [addTargetPage, setAddTargetPage] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <ChipWrapper onRequestAdd={() => setAddTargetPage(true)}>
        {(pages || []).map((p, index) => (
          <ChipItem
            key={index}
            id={index}
            label={t(`webActions.targetPages.${p}`)}
            onRequestDelete={() => onDelete(p, index)}
          />
        ))}
      </ChipWrapper>
      <TargetPagesAutoComplete
        open={addTargetPage}
        onClose={() => setAddTargetPage(false)}
        title={title}
        onChange={onChange}
      />
    </>
  );
}

function TargetPagesAutoComplete({ open, onClose, onChange, title }) {
  const [targetPage, setTargetPage] = useState(null);
  const { t } = useTranslation();
  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormControl variant='standard' fullWidth={true}>
          <AutoComplete
            required
            fullWidth
            label='Target Page'
            dataSource={Object.values(TargetPages).sort()}
            value={targetPage || ''}
            onChange={(page) => setTargetPage(page)}
            onUpdateInput={(page) => setTargetPage(page)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            setTargetPage(null);
          }}>
          {t('commons.actions.cancel')}
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onChange(targetPage);
            setTargetPage(null);
            onClose();
          }}
          disabled={!targetPage || !Object.values(TargetPages).includes(targetPage)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default TargetPagesChipWrapperSelectModal;
