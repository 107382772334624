import React from 'react';
import { Card, CardHeader, CardContent, CardActions, Grid } from '@mui/material';

import Subheader from 'pages/systems/physical_entities/detail/PhysicalEntityDetailsCard/header/Subheader';

import GeneralSection from './GeneralSection';
import CatalogInformationSection from './CatalogInformationSection';

import CardDescription from 'components/carddescription/CardDescription';
import { RoutineStatusDecorator } from './RoutineStatusDecorator';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';

function RoutineDetailCardView({ routine, actions }) {
  return (
    <Card>
      <CardHeader
        title={routine?.schema ? `${routine?.schema}.${routine?.name}` : routine?.name}
        subheader={<Subheader system={routine?.system} type={routine?.type} />}
        action={
          actions && (
            <Authorize hasPermissions={[Permissions.QUERY_PARSER_VIEWER]}>
              <RoutineStatusDecorator
                routineAnalysisStatus={routine?.analysisStatus}
                routineErrorMessage={routine?.analysisErrorMessage}
              />
            </Authorize>
          )
        }
      />
      <CardContent>
        <CardDescription>{routine.description}</CardDescription>
        <Grid container direction='column' spacing={3}>
          <Grid item xs={12} container direction='row' spacing={3}>
            <Grid item xs={6}>
              <GeneralSection routine={routine} />
            </Grid>
            <Grid item xs={6}>
              <CatalogInformationSection routine={routine} />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>{actions}</CardActions>
    </Card>
  );
}

export default RoutineDetailCardView;
