import React from 'react';
const { Card, CardContent } = require('@mui/material');
const { default: RuleView } = require('./RuleView');

const RuleCardView = ({ rule, actions, children }) => {
  return (
    <Card>
      <CardContent>
        <RuleView rule={rule}></RuleView>
        {children}
      </CardContent>
      {actions}
    </Card>
  );
};

export default RuleCardView;
