import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ComposableTableCard, {
  addOrEditHelper
} from '../../../../components/tables/ComposableTableCard';

class PlaceholdersValuesEditor extends React.Component {
  state = {
    selectedIndex: null,
    open: false,
    name: null,
    value: null
  };

  clearState() {
    this.setState({ name: null, value: null, open: false, selectedIndex: null });
  }

  handleRowsMoved(newRows, newSelectedIndex) {
    this.setState({ selectedIndex: newSelectedIndex });
    this.props.onChange(newRows);
  }

  handleEdit() {
    let placeholderValueToEdit = this.props.placeholdersValues[this.state.selectedIndex];
    this.setState({ ...placeholderValueToEdit, open: true });
  }

  handlePut() {
    let newElement = { name: this.state.name, value: this.state.value };
    this.props.onChange(
      addOrEditHelper(this.props.placeholdersValues, newElement, this.state.selectedIndex)
    );
    this.clearState();
  }

  render() {
    return (
      <React.Fragment>
        <ComposableTableCard
          firstStrong
          title='Placeholders Values'
          selectedIndex={this.state.selectedIndex}
          rows={this.props.placeholdersValues || []}
          headers={['Name', 'Value']}
          propertiesPaths={['name', 'value']}
          onSelectionChanged={(selectedIndex) => this.setState({ selectedIndex })}
          onAdd={() => this.setState({ name: null, value: null, open: true, selectedIndex: null })}
          onEdit={() => this.handleEdit()}
          onRemove={(elem, rows, newRows) => this.props.onChange(newRows)}
          onRowsMoved={(newRows, newSelectedIndex) =>
            this.handleRowsMoved(newRows, newSelectedIndex)
          }
        />
        <Dialog open={this.state.open}>
          <DialogTitle>Add placeholder value</DialogTitle>
          <DialogContent>
            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              label='Placeholder name'
              value={this.state.name || ''}
              onChange={(event) => this.setState({ name: event.target.value })}
            />
            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              multiline
              maxRows={4}
              label='Placeholder value'
              value={this.state.value || ''}
              onChange={(event) => this.setState({ value: event.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button children='Cancel' onClick={() => this.clearState()} />
            <Button
              children='Add'
              disabled={!this.state.name || this.state.name.trim() === ''}
              variant='contained'
              color='primary'
              onClick={() => this.handlePut()}
            />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default PlaceholdersValuesEditor;
