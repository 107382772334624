import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, CardActions, Button } from '@mui/material';
import {
  fetchSingleSchedule,
  modifySchedule,
  deleteSchedule
} from 'actions/onagent/AgentScheduleActions';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';

import { fetchAgent } from 'actions/AgentsActions';
import { connect } from 'react-redux';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import JobHistoryTable from 'pages/quality/probes/projects/commons/JobHistoryTable';
import { useHistory } from 'react-router';
import AgentScheduleConfigsView from './AgentScheduleConfigsView';
import AgentGenericJobRunButton from '../../commons/AgentGenericJobRunButton';
import AgentJobDefinitionModal from '../../commons/AgentJobDefinitionModal/AgentJobDefinitionModal';

function AgentScheduleDetailPage({
  fetchAgent,
  fetchSingleSchedule,
  modifySchedule,
  deleteSchedule,
  agentUuid,
  agent,
  jobDefinitionUuid,
  jobDefinition
}) {
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    fetchAgent(agentUuid);
  }, [fetchAgent, agentUuid]);

  useEffect(() => {
    if (agent) {
      fetchSingleSchedule(agent, jobDefinitionUuid);
    }
  }, [fetchSingleSchedule, agent, agentUuid, jobDefinitionUuid]);
  let history = useHistory();

  if (!jobDefinition) {
    return null;
  }
  return (
    <React.Fragment>
      <Card>
        <CardHeader
          title={'Job Definition'}
          action={
            jobDefinition?.scheduled ? (
              <VariationTypeDecorator>{'SCHEDULED'}</VariationTypeDecorator>
            ) : (
              <VariationTypeDecorator>{'NOT SCHEDULED'}</VariationTypeDecorator>
            )
          }></CardHeader>
        <CardContent>
          <KVDefinitionList
            header='General'
            item={{
              Target: jobDefinition.name,
              Type: jobDefinition.type,
              'Cron Expression': jobDefinition.cronExpression,
              Timezone: jobDefinition.timezoneId
            }}></KVDefinitionList>
          <h4>Configurations</h4>
          <AgentScheduleConfigsView job={jobDefinition} />
        </CardContent>
        <CardActions>
          <Button onClick={() => setEdit(true)} children={'Modify'} />
          {jobDefinition.scheduled ? (
            <ConfirmButton
              message={`Do you want to unschedule this job?`}
              onClick={() => modifySchedule(agent, { ...jobDefinition, scheduled: false })}
              children={'Unschedule'}></ConfirmButton>
          ) : (
            <ConfirmButton
              message={`Do you want to schedule this job?`}
              onClick={() => modifySchedule(agent, { ...jobDefinition, scheduled: true })}
              children={'Schedule'}></ConfirmButton>
          )}
          <AgentGenericJobRunButton
            jobDefinition={jobDefinition}
            agent={agent}
            isJsonValid={isJsonString(JSON.stringify(jobDefinition?.jobDetails?.configs))}
          />
          <ConfirmButton
            message={`Do you want to delete: ${jobDefinition.name}`}
            onClick={() => {
              deleteSchedule(agent, jobDefinition);
              history.goBack();
            }}>
            Delete
          </ConfirmButton>
        </CardActions>
      </Card>
      <Card>
        <CardHeader title='Runs History'></CardHeader>
        <CardContent>
          <JobHistoryTable agent={agent} jobUuid={jobDefinition.uuid} />
        </CardContent>
      </Card>
      {edit && (
        <AgentJobDefinitionModal
          jobDefinition={jobDefinition}
          open={edit}
          onSubmit={(jobDef) => {
            modifySchedule(agent, jobDef);
            setEdit(false);
          }}
          onCancel={() => setEdit(false)}
          agent={agent}
        />
      )}
    </React.Fragment>
  );
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function mapStateToProps(state, props) {
  return {
    jobDefinitionUuid: props.match.params['jobDefinitionUuid'],
    agentUuid: props.match.params['agentUuid'],
    jobDefinition: state.settings.agents.schedule.content[props.match.params['jobDefinitionUuid']],
    agent: state.settings.agents.definitions.content[props.match.params['agentUuid']]
  };
}

export default connect(mapStateToProps, {
  fetchSingleSchedule,
  modifySchedule,
  deleteSchedule,
  fetchAgent
})(AgentScheduleDetailPage);
