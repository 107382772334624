import React, { useState } from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withLoader } from '../../../../components/loader/Loader';
import { connect } from 'react-redux';
import useSearchParams from 'components/useSearchParams';
import {
  deletePhysicalField,
  modifyPhysicalField,
  fetchPhysicalField
} from 'actions/PhysicalFieldsActions';

import Typography from '@mui/material/Typography';
import PhysicalFieldDetailsCard from './PhysicalFieldDetailsCard';
import CommentsPanel from '../../../social/comments/CommentsPanel';
import PhysicalFieldEditModal from '../commons/PhysicalFieldEditModal';
import Breadcrumbs from 'pages/sidebar/Navigation';
import DataFlowCard from 'pages/dataflows/utils/DataFlowCard';
import QualityPhysicalPanel from 'pages/quality/kqis/quality_checks/commons/QualityPhysicalPanel';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import { Grid, Tabs, Tab } from '@mui/material';

import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import ResourceTypes from 'constants/ResourceTypes';
import withAuthorize from 'components/permissions/withAuthorize';
import Permissions from 'constants/Permissions';
import ClassificationAssignmentsPanel from 'pages/classification/assignments/commons/ClassificationAssignmentsPanel';
import { Box } from '@mui/system';
import PhysicalConstraintsPanel from 'pages/systems/physical_constraints/tab/PhysicalConstraintsPanel';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const PhysicalFieldDetailPage = ({
  physicalFieldUuid,
  physicalField,
  modifyPhysicalField,
  deletePhysicalField,
  history,
  t
}) => {
  const [open, setOpen] = useState(false);
  const query = useSearchParams();

  const handleEdit = () => setOpen(true);
  const handleDelete = () => {
    deletePhysicalField(physicalField);
    history.goBack();
  };

  const handleCancel = () => setOpen(false);
  const selectedTab = query.get('tab') || 'dataflows';
  const handleChangeTab = (_event, newValue) =>
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });
  return physicalField ? (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.physical_model'), to: '/systems' },
          {
            text: physicalField.physicalEntity?.system?.name,
            to: `/systems/${physicalField.physicalEntity?.system?.uuid}`
          },
          {
            text: physicalField.physicalEntity?.name,
            to: `/physicalentities/${physicalField.physicalEntity?.uuid}`
          },
          { text: physicalField.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('physicalFields.detail.header', { physicalFieldName: physicalField.name }),
          category: EngagementCategories.PHYSICAL_MODEL,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>
            {t('physicalFields.detail.header', {
              physicalFieldName: physicalField.name
            })}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`${ResourceTypes.PHYSICAL_FIELD}:${physicalField.uuid}`}
            resourceIdentifier={physicalField.uuid}
            resourceType={ResourceTypes.PHYSICAL_FIELD}
            resourceName={physicalField.name}
            pageTitle={`Physical Field: ${physicalField.name}`}></FavoritesWidget>
        </Grid>
        <Grid item>
          <IssuesResourceWidget
            resourceType={ResourceTypes.PHYSICAL_FIELD}
            resourceIdentifier={physicalField?.uuid}
            resourceName={
              physicalField?.physicalEntity?.schema
                ? `${physicalField?.physicalEntity?.schema}.${physicalField?.physicalEntity?.name}.${physicalField?.name}`
                : `${physicalField?.physicalEntity?.name}.${physicalField?.name}`
            }
          />
        </Grid>
      </Grid>
      <PhysicalFieldDetailsCard
        physicalField={physicalField}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          variant={'scrollable'}
          scrollButtons
          allowScrollButtonsMobile>
          <Tab value='dataflows' label='Dataflows' />
          <Tab value='constraints' label='Constraints' />
          <AuthorizedTab
            hasPermissions={[Permissions.QUALITY_VIEWER]}
            value='quality'
            label='Quality Checks'
          />
          <AuthorizedTab
            hasPermissions={[Permissions.CLASSIFICATION_VIEWER]}
            value='classification'
            label='Classification'
          />
          <Tab value='comments' label='Comments' />
        </Tabs>
      </Box>
      {selectedTab === 'dataflows' && <DataFlowCard physicalFieldUuid={physicalFieldUuid} />}
      {selectedTab === 'constraints' && <PhysicalConstraintsPanel physicalField={physicalField} />}
      {selectedTab === 'quality' && (
        <QualityPhysicalPanel physicalFieldUuid={physicalFieldUuid}></QualityPhysicalPanel>
      )}
      {selectedTab === 'classification' && (
        <ClassificationAssignmentsPanel
          physicalField={physicalField}
          physicalEntity={physicalField.physicalEntity}></ClassificationAssignmentsPanel>
      )}
      {selectedTab === 'comments' && (
        <CommentsPanel
          notExpandable
          resourceName={physicalField.name}
          resourceType='PHYSICAL_FIELD'
          resourceIdentifier={physicalFieldUuid}
        />
      )}
      <PhysicalFieldEditModal
        title={'Edit: ' + physicalField.name}
        physicalField={physicalField}
        open={open}
        handleSubmit={modifyPhysicalField}
        handleCancel={handleCancel}
      />
    </>
  ) : null;
};
const AuthorizedTab = withAuthorize(Tab, 'span');

const mapStateToProps = (state, props) => ({
  physicalFieldUuid: props.match.params['physicalFieldUuid'],
  physicalField: state.physicalFields.content[props.match.params['physicalFieldUuid']]
});

const mapDispatchToProps = {
  fetchPhysicalField,
  modifyPhysicalField,
  deletePhysicalField
};

export default _.flow(
  withTranslation(),
  withLoader({
    onLoad({ physicalFieldUuid, fetchPhysicalField }) {
      fetchPhysicalField(physicalFieldUuid);
    }
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(PhysicalFieldDetailPage);
