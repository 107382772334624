import React, { useEffect, useState } from 'react';
import Permissions from '../../../constants/Permissions';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  Card,
  CardContent,
  IconButton,
  Grid,
  TablePagination,
  Typography,
  Tooltip
} from '@mui/material';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  fetchResponsibilitiesPage,
  createResponsibility,
  deleteResponsibility,
  modifyResponsibility
} from '../../../actions/StewardshipActions';
import ResponsabilityModal from './common/ResponsibilityModal';
import StewardshipExportResponsibilities from '../common/StewardshipExportResponsibilities';
import PreAuthorize from '../../../components/permissions/Authorize';
import ResponsibilitiesFilterEditor from './common/ResponsibilitiesFilterEditor';
import { Link } from 'react-router-dom';
import ConfirmIconButton from '../../../components/confirmbutton/ConfirmIconButton';
import { useTranslation } from 'react-i18next';
import UserDisplayName from '../../settings/users/commons/UserDisplayName';
import TableCell from 'components/tables/GenericTableCell';
import ExternalHelper from 'components/help/ExternalHelper';
import TeamDisplayName from 'pages/settings/teams/common/TeamDisplayName';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

function StewardshipResponsibilitiesTable({
  currentPage,
  onPageSelection,
  createResponsibility,
  deleteResponsibility,
  modifyResponsibility,
  filters,
  setFilters
}) {
  const [open, setOpen] = useState(false);
  let { t } = useTranslation();

  useEffect(() => {
    onPageSelection({ page: 0, size: 25, ...filters });
  }, [filters, onPageSelection]);

  return (
    <React.Fragment>
      <Grid container alignItems='baseline'>
        <Grid item>
          <Typography variant='h4'>
            Responsibilities
            <ExternalHelper resourceType='stewardshipResponsibilities' />
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:responsibilities-list'}
            pageTitle={t('stewardship.responsibilities.header')}
            iconSize='small'
          />
        </Grid>
        <Grid item>
          <PreAuthorize hasPermissions={[Permissions.STEWARDSHIP_EDITOR, Permissions.USERS_VIEWER]}>
            <Tooltip title='Create responsibility'>
              <IconButton
                aria-label='create responsibility'
                color='default'
                size={'small'}
                onClick={() => setOpen(true)}>
                <AddBoxIcon />
              </IconButton>
            </Tooltip>
          </PreAuthorize>
        </Grid>
        <Grid item>
          <StewardshipExportResponsibilities filters={filters}></StewardshipExportResponsibilities>
        </Grid>
        <Grid item>
          <ResponsibilitiesFilterEditor
            key={filters}
            filters={filters}
            setFilters={setFilters}></ResponsibilitiesFilterEditor>
        </Grid>
      </Grid>

      <Card>
        <CardContent style={{ overflowX: 'auto' }}>
          <Table size='small' style={{ overflowX: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell>Assignee</TableCell>
                <TableCell>Role Name</TableCell>
                <TableCell>Resource Type</TableCell>
                <TableCell>Resource Name</TableCell>
                <TableCell hiddenMd>Description</TableCell>
                <TableCell hiddenMd>Start Date</TableCell>
                <TableCell hiddenMd>End Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(currentPage.content || []).map((responsibility) => (
                <TableRow key={responsibility.sequenceId} hover>
                  <TableCell>
                    {responsibility.user ? (
                      <UserDisplayName user={responsibility.user}></UserDisplayName>
                    ) : (
                      <TeamDisplayName team={responsibility.assigneeTeam}></TeamDisplayName>
                    )}
                  </TableCell>
                  <TableCell>
                    <ChipWrapper>
                      <ChipItem
                        label={responsibility.stewardshipRole.name}
                        component={Link}
                        to={`/stewardship/roles/${responsibility.stewardshipRole.uuid}`}></ChipItem>
                    </ChipWrapper>
                  </TableCell>
                  <TableCell>{t(`resourceType.${responsibility.resourceType}`)}</TableCell>
                  <TableCell>{responsibility.resourceName}</TableCell>
                  <TableCell breakWord hiddenMd>
                    {responsibility.description}
                  </TableCell>
                  <TableCell hiddenMd>{formatDate(responsibility.startDate)}</TableCell>
                  <TableCell hiddenMd>{formatDate(responsibility.endDate)}</TableCell>
                  <TableCell>
                    <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                      <Grid item></Grid>
                      <Grid item>
                        <PreAuthorize hasPermissions={[Permissions.STEWARDSHIP_ADMIN]}>
                          <ConfirmIconButton
                            size='small'
                            message={`Do you want to permanently delete this responsibility?`}
                            onClick={() =>
                              deleteResponsibility(responsibility.sequenceId).then(() => {
                                onPageSelection(currentPage.number, currentPage.size);
                              })
                            }>
                            <DeleteIcon></DeleteIcon>
                          </ConfirmIconButton>
                        </PreAuthorize>
                      </Grid>
                      {!responsibility.endDate && (
                        <Grid item>
                          <PreAuthorize hasPermissions={[Permissions.STEWARDSHIP_EDITOR]}>
                            <ConfirmIconButton
                              size='small'
                              message='Do you want to end this responsibility?'
                              onClick={() => {
                                modifyResponsibility({ ...responsibility, endDate: Date.now() });
                              }}>
                              <CancelPresentationIcon></CancelPresentationIcon>
                            </ConfirmIconButton>
                          </PreAuthorize>
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 200]}
            component='div'
            count={currentPage.totalElements}
            rowsPerPage={currentPage.size}
            page={currentPage.number}
            onPageChange={(event, page) =>
              onPageSelection({ page: page, size: currentPage.size, ...filters })
            }
            onRowsPerPageChange={(event) => {
              onPageSelection({ page: currentPage.number, size: event.target.value, ...filters });
            }}></TablePagination>
          {open && (
            <ResponsabilityModal
              open={open}
              setOpen={setOpen}
              onSubmit={createResponsibility}></ResponsabilityModal>
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
function formatDate(timestamp) {
  var m = moment(timestamp);
  var dateCreation;
  if (!m.isValid()) dateCreation = '';
  else {
    dateCreation = m.format('DD/MM/YYYY');
  }
  return dateCreation;
}
function mapStateToProps(state) {
  return {
    currentPage: state.stewardship.responsibilities.currentPage
  };
}
function mapDispatchToProps(dispatch, { filters }) {
  return {
    onPageSelection: (props) => dispatch(fetchResponsibilitiesPage({ ...filters, ...props })),
    createResponsibility: (responsibility) => dispatch(createResponsibility(responsibility)),
    deleteResponsibility: (sequenceId) => dispatch(deleteResponsibility(sequenceId)),
    modifyResponsibility: (responsibility) => dispatch(modifyResponsibility(responsibility))
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps)(StewardshipResponsibilitiesTable)
);
