import React from 'react';
import DataActorsSearchModal from '../../../data_actors/utils/DataActorsSearchModal';
import TaskSearchModal from '../../../tasks/utils/TaskSearchModal';
import ProcessingSearchModal from '../../../processings/utils/ProcessingSearchModal';
import DataCategorySearchModal from '../../../datacategories/utils/DataCategorySearchModal';
import LogicalFieldSearchModal from '../../../datacategories/utils/LogicalFieldSearchModal';
import PhysicalEntitySearchModal from '../../../systems/physical_entities/commons/PhysicalEntitySearchModal';
import SystemsSearchModal from '../../../systems/commons/SystemsSearchModal';
import SystemLoginSearchModal from '../../../systems/logins/SystemLoginSearchModal';
import PhysicalFieldSearchModal from '../../../systems/physical_entities/commons/PhysicalFieldSearchModal';

const ResourceSearchModals = ({ filterEditor, changeGraphFilterEditor, addGraphUuidFilter }) => {
  let onSubmit = object => {
    addGraphUuidFilter({
      entityType: filterEditor.entityType,
      name: object[settings[filterEditor.entityType].name],
      object: object
    });
    changeGraphFilterEditor({ openResourceFilterEditor: false });
  };
  let onCancel = () => changeGraphFilterEditor({ openResourceFilterEditor: false });
  return (
    <>
      <DataActorsSearchModal
        open={filterEditor.openResourceFilterEditor && filterEditor.entityType === 'DATA_ACTOR'}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <TaskSearchModal
        open={filterEditor.openResourceFilterEditor && filterEditor.entityType === 'TASK'}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <ProcessingSearchModal
        open={filterEditor.openResourceFilterEditor && filterEditor.entityType === 'PROCESSING'}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <DataCategorySearchModal
        open={filterEditor.openResourceFilterEditor && filterEditor.entityType === 'DATA_CATEGORY'}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <LogicalFieldSearchModal
        open={filterEditor.openResourceFilterEditor && filterEditor.entityType === 'LOGICAL_FIELD'}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <PhysicalEntitySearchModal
        open={
          filterEditor.openResourceFilterEditor && filterEditor.entityType === 'PHYSICAL_ENTITY'
        }
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <SystemsSearchModal
        open={filterEditor.openResourceFilterEditor && filterEditor.entityType === 'SYSTEM'}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <SystemLoginSearchModal
        open={filterEditor.openResourceFilterEditor && filterEditor.entityType === 'SYSTEM_LOGIN'}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <PhysicalFieldSearchModal
        open={filterEditor.openResourceFilterEditor && filterEditor.entityType === 'PHYSICAL_FIELD'}
        onSubmit={onSubmit}
        onCancel={onCancel}></PhysicalFieldSearchModal>
    </>
  );
};

const settings = {
  DATA_ACTOR: {
    name: 'name'
  },
  SYSTEM_LOGIN: {
    name: 'username'
  },
  PHYSICAL_ENTITY: {
    name: 'name'
  },
  TASK: {
    name: 'name'
  },
  PROCESSING: {
    name: 'name'
  },
  DATA_CATEGORY: {
    name: 'name'
  },
  LOGICAL_FIELD: {
    name: 'name'
  },
  SYSTEM: {
    name: 'name'
  },
  PHYSICAL_FIELD: {
    name: 'name'
  }
};

export default ResourceSearchModals;
