import React from 'react';
import PaginatedPanel from '../../../../components/pagination/PaginatedPanel';
import ProposalListsItem from './ProposalListItem';

function ProposalsList({ currentPage, fetchProposalsPage }) {
  return (
    <PaginatedPanel
      currentPage={currentPage}
      onPageSelection={page => fetchProposalsPage({ page })}
      autoload>
      {currentPage.content.map(proposal => (
        <ProposalListsItem proposal={proposal} />
      ))}
    </PaginatedPanel>
  );
}

export default ProposalsList;
