import { useDispatch } from 'react-redux';
// import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { useTracking } from 'react-tracking';
import useTrackingInfo from 'components/engagement/useTrackingInfo';

import { ADD_ENGAGEMENT_HIT } from 'constants/ActionTypes';
// import { selectCurrentTenant } from 'reducers/settings/tenants';

const usePageView = (hitData) => {

  // const currentTenant = useSelector(selectCurrentTenant);
  const match = useRouteMatch();
  const trackingInfo = useTrackingInfo();
  const dispatch = useDispatch();

  useTracking(
    hitData,
    {
      dispatchOnMount: () => ({
        hitType: 'PAGEVIEW',
        // tenantUuid: currentTenant?.uuid,
        pagePath: match.url,
        ...trackingInfo
      }),
      dispatch: data => {
        dispatch({ type: ADD_ENGAGEMENT_HIT, payload: data });
      }
    }
  );
}

export default usePageView;