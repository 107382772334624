import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TextFieldsListEditor from '../../../components/list_editors/TextFieldsListEditor';
import { loadSuggestions } from '../../../components/suggestions/SuggestionsUtils';

const RecipientsFormSection = ({
  t,
  recipients,
  dataCategories,
  onAdd,
  onDelete
}) => (
  <TextFieldsListEditor
    headerTitle={t('processings.recipients.header')}
    addLabel={t('processings.recipients.actions.add')}
    items={recipients}
    onAdd={onAdd}
    onDelete={onDelete}
    textFields={[
      {
        hintText: t('processings.recipients.fields.recipientCategoryHint'),
        floatingLabelText: t('processings.recipients.fields.recipientCategory'),
        property: 'recipientCategory',
        suggestions: loadSuggestions(t)(
          'suggestion:recipientCategories',
          dataCategories
        ),
        enabler: true
      },
      {
        hintText: t('processings.recipients.fields.recipientDescriptionHint'),
        floatingLabelText: t(
          'processings.recipients.fields.recipientDescription'
        ),
        property: 'recipientDescription'
      },
      {
        hintText: t('processings.recipients.fields.diffusionModeHint'),
        floatingLabelText: t('processings.recipients.fields.diffusionMode'),
        suggestions: loadSuggestions(t)(
          'suggestion:diffusionModes',
          dataCategories
        ),
        property: 'diffusionMode'
      }
    ]}
  />
);

RecipientsFormSection.propTypes = {
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      recipientCategory: PropTypes.string,
      recipientDescription: PropTypes.string,
      diffusionMode: PropTypes.string
    })
  )
};

export default withTranslation()(RecipientsFormSection);
