import React, { useState } from 'react';
import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { t } from 'i18next';
import { ChipItem } from 'components/chiplist/ChipList';
import IssueIcons from 'pages/issueMngt/issues/detail/IssueIcons';
import SeverityIcons from 'pages/issueMngt/issues/detail/SeverityIcons';
import { patchIssue } from 'actions/IssuesActions';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';

export const MyIssueListItem = ({ orderedIssueStatuses, issue }) => {
  return (
    <ListItem key={issue.uuid} style={{ padding: '0px 4px 0px 4px' }}>
      <Card style={{ width: '100%' }}>
        <CardHeader
          style={{ padding: '8px' }}
          title={<Typography variant='body1'>{issue.name}</Typography>}
          action={
            <>
              <GoToDetailsButton issue={issue} />
              <Authorize hasPermissions={[Permissions.CAMPAIGNS_EDITOR]}>
                <HandleIssueStatusButton
                  orderedIssueStatuses={orderedIssueStatuses}
                  issue={issue}
                />
              </Authorize>
            </>
          }
        />
        <CardContent style={{ padding: '8px' }}>
          <Grid container spacing={1}>
            <Grid item container xs={12} justifyContent='flex-end'>
              {issue.campaign ? (
                <ChipItem
                  size='small'
                  key={issue.campaign.uuid}
                  id={issue.campaign.uuid}
                  label={issue.campaign.name}
                  to={`/issue-management/campaigns/${issue.campaign.uuid}`}
                  onClick={(event) => event.stopPropagation()}
                />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item container lg={6} spacing={1}>
              <Grid item>
                <Tooltip title={issue.issueType}>{IssueIcons[issue.issueType]}</Tooltip>
              </Grid>
              <Grid item>{SeverityIcons[issue.severity]}</Grid>
              <Grid item>
                <Badge
                  badgeContent={issue.estimate}
                  color='primary'
                  style={{ paddingLeft: '1em' }}
                />
              </Grid>
            </Grid>
            <Grid item container lg={6} justifyContent='flex-end'>
              {issue.plannedEndDate ? (
                <Grid item>
                  <Typography variant='caption'>
                    deadline: {moment(issue.plannedEndDate).format('DD MMM YYYY')}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ListItem>
  );
};

const GoToDetailsButton = ({ issue }) => {
  const history = useHistory();
  return (
    <IconButton size='small' onClick={() => history.push(`/issue-management/issues/${issue.uuid}`)}>
      <OpenInNewIcon color='primary' fontSize='small' />
    </IconButton>
  );
};

const HandleIssueStatusButton = ({ orderedIssueStatuses, issue }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  return (
    <>
      <IconButton size='small' onClick={(event) => setAnchorEl(event.currentTarget)}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {orderedIssueStatuses.map((status, index) => (
          <MenuItem
            key={index}
            value={status}
            onClick={() => {
              setAnchorEl(null);
              dispatch(patchIssue(issue.uuid, { issueStatus: status }));
            }}>
            {t(`issueMngt.issues.statuses.${status}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
