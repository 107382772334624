import { BlindataApi } from './ApiUtils';

const CUSTOM_PROPERTY_DEFINITIONS_URL = '/api/v1/settings/custom_properties/definitions';

export default class CustomPropertiesApi extends BlindataApi {
  getCustomPropertyDefinitions(params) {
    return this.get(CUSTOM_PROPERTY_DEFINITIONS_URL, params);
  }

  getCustomPropertyDefinition(uuid) {
    return this.get(`${CUSTOM_PROPERTY_DEFINITIONS_URL}/${uuid}`);
  }

  postCustomPropertyDefinition(templateProcessing) {
    return this.post(CUSTOM_PROPERTY_DEFINITIONS_URL, templateProcessing);
  }

  putCustomPropertyDefinition(templateProcessing) {
    return this.put(
      `${CUSTOM_PROPERTY_DEFINITIONS_URL}/${templateProcessing.uuid}`,
      templateProcessing
    );
  }

  deleteCustomPropertyDefinition(uuid) {
    return this.delete(`${CUSTOM_PROPERTY_DEFINITIONS_URL}/${uuid}`);
  }

  getCustomPropertyDefinitionValues(uuid, params) {
    return this.get(`${CUSTOM_PROPERTY_DEFINITIONS_URL}/${uuid}/values`, params);
  }

  postCustomPropertyDefinitionRefresh(uuid, params) {
    return this.post(`${CUSTOM_PROPERTY_DEFINITIONS_URL}/${uuid}/refresh`, params);
  }
}
