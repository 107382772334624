import {
  RECEIVE_SEARCH_PHYSICAL_ENTITIES,
  CREATE_PHYSICAL_ENTITY,
  MODIFY_PHYSICAL_ENTITY,
  DELETE_PHYSICAL_ENTITY,
  FETCH_PHYSICAL_ENTITIES_PAGE,
  FETCH_PHYSICAL_ENTITY,
  SET_PHYSICAL_ENTITY_FILTER,
  CLEAR_PHYSICAL_ENTITY_FILTER
} from '../constants/ActionTypes';
import _ from 'lodash';
import { combineReducers } from 'redux';

const filtersDefault = {
  search: null,
  hasFilters: false,
  system: undefined,
  schema: undefined,
  tableType: undefined,
  physicalFieldsCount: undefined,
  dataCategories: [],
  logicalFields: [],
  propertiesNames: [],
  propertiesValues: [],
  nullPropertiesNames: [],
  dataSet: undefined,
  hidden: false,
  view: undefined,
  startCreatedAt: undefined,
  endCreatedAt: undefined,
  startUpdatedAt: undefined,
  endUpdatedAt: undefined
};

const filters = (state = filtersDefault, action) => {
  switch (action.type) {
    case SET_PHYSICAL_ENTITY_FILTER:
      const nextState = { ...state, ...action.payload, hasFilters: undefined };
      const hasFilters = Object.values(nextState).some(
        (x) =>
          x !== undefined &&
          x !== null &&
          !(x === '' || (Array.isArray(x) && x.length === 0) || x === false)
      );
      return { ...nextState, hasFilters };
    case CLEAR_PHYSICAL_ENTITY_FILTER:
      return filtersDefault;
    default:
      return state;
  }
};

const content = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_SEARCH_PHYSICAL_ENTITIES:
      return {
        ...state,
        ..._(action.payload.content).keyBy('uuid').value()
      };
    case CREATE_PHYSICAL_ENTITY:
    case MODIFY_PHYSICAL_ENTITY:
      return {
        ...state,
        [action.payload.uuid]: action.payload
      };
    case DELETE_PHYSICAL_ENTITY:
      return {
        ...state,
        [action.payload.uuid]: undefined
      };
    case FETCH_PHYSICAL_ENTITIES_PAGE:
      return {
        ...state,
        ..._(action.payload.content).keyBy('uuid').value()
      };
    case FETCH_PHYSICAL_ENTITY:
      return {
        ...state,
        [action.payload.uuid]: action.payload
      };
    default:
      return state;
  }
};

const CURRENT_PAGE_INITIAL_STATE = {
  content: [],
  number: 0,
  totalPages: 0,
  totalElements: 0,
  size: 25
};

const pagination = (state = CURRENT_PAGE_INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_PHYSICAL_ENTITY:
    case MODIFY_PHYSICAL_ENTITY:
      return {
        ...state,
        content: _(state.content)
          .keyBy('uuid')
          .assign({ [action.payload.uuid]: action.payload })
          .map()
          .value()
      };
    case DELETE_PHYSICAL_ENTITY:
      return {
        ...state,
        content: state.content.filter((e) => e['uuid'] !== action.payload['uuid'])
      };
    case FETCH_PHYSICAL_ENTITIES_PAGE:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  content,
  pagination,
  filters
});
