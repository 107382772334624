import { combineReducers } from 'redux';
import crudReducers, { CURRENT_PAGE_INITIAL_STATE } from 'reducers/utils';
import { FETCH_QUALITY_CHECKS_PANEL_PAGE } from 'constants/ActionTypes';

function panels(state = {}, action) {
  switch (action.type) {
    case FETCH_QUALITY_CHECKS_PANEL_PAGE:
      return { ...state, [buildPanelsKey(action.payload)]: action.payload };
    default:
      return state;
  }
}

function buildPanelsKey(payload) {
  let { physicalEntityUuid, physicalFieldUuid } = payload;
  return JSON.stringify({ physicalEntityUuid, physicalFieldUuid });
}

export const selectPanel = (payload) => (state) =>
  state.quality.qualityChecks.panels[buildPanelsKey(payload)] || CURRENT_PAGE_INITIAL_STATE;

export default combineReducers({
  ...crudReducers({ resource: 'QUALITY_CHECK', keyProperty: 'uuid' }),
  panels
});
