import React from 'react';
import { Switch, Route } from 'react-router';
import TeamsListPage from './list/TeamsListPage';
import TeamDetailPage from './detail/TeamDetailPage';

const TeamsRoutes = props => (
  <Switch>
    <Route exact path={`${props.match.url}/:teamCode`} component={TeamDetailPage} />
    <Route path={`${props.match.url}`} component={TeamsListPage} />
  </Switch>
);

export default TeamsRoutes;
