import React from 'react';
import {
  MenuItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Menu
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import UsernameAvatar from '../../../components/avatars/UsernameAvatar';
import { MoreHoriz } from '@mui/icons-material';
import { createUrlItem } from 'pages/Routes';

function NotificationItem({ notification, onDelete, onMarkAsRead, onClick }) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let objectUrl = createUrlItem(notification.objectType, notification.objectIdentifier);
  return (
    <ListItem
      button
      component='a'
      alignItems='flex-start'
      dense
      disabled={!objectUrl}
      onClick={() => {
        onClick(objectUrl);
        onMarkAsRead(notification);
      }}>
      <ListItemAvatar>
        <UsernameAvatar username={notification.subjectName} randomBackgroundColor></UsernameAvatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography component='span' display='inline' variant='body2'>
              <bold>{notification.subjectName}</bold>
            </Typography>
            <Typography component='span' variant='caption'>
              {' '}
              {t(`social.notifications.items.verbs.${notification.eventType}`)}
            </Typography>
            <Typography component='span' variant='body2'>
              {' '}
              {notification.objectName}
            </Typography>
          </>
        }
        secondary={
          notification.createdAt && moment(notification.createdAt).fromNow()
        }></ListItemText>
      <ListItemSecondaryAction>
        <IconButton onClick={handleClick} size='large'>
          <MoreHoriz></MoreHoriz>
        </IconButton>
        <Menu
          id={`notification-menu-${notification.sequenceId}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <MenuItem
            onClick={() => {
              onDelete(notification);
              setAnchorEl(null);
            }}>
            Delete
          </MenuItem>
          <MenuItem
            onClick={() => {
              onMarkAsRead(notification);
              setAnchorEl(null);
            }}>
            Mark as read
          </MenuItem>
        </Menu>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default NotificationItem;
