import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';

import { createProbeConnection } from 'actions/quality/probes/ProbesConnectionsActions';

import AddFAB from 'components/fab/AddFAB';
import QualityProbesConnectionModal from 'pages/quality/probes/connections/commons/QualityProbesConnectionModal';

function QualityProbesConnectionFAB({ createProbeConnection }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AddFAB onClick={() => setOpen(true)} />
      {open && (
        <QualityProbesConnectionModal
          title='Create connection'
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={(connection) => {
            createProbeConnection(connection);
            setOpen(false);
          }}
        />
      )}
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.PROBES_EDITOR] }),
  connect(null, { createProbeConnection })
)(QualityProbesConnectionFAB);
