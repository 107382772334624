import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LogicalFieldDetailPage from './detail/LogicalFieldDetailPage';
import LogicalFieldsListPage from './list/LogicalFieldsListPage';
import DataCategoryLogicalFieldNavbar from '../datacategories/commons/DataCategoryLogicalFieldsNavbar';

const LogicalFieldsRoutes = (props) => (
  <React.Fragment>
    <DataCategoryLogicalFieldNavbar></DataCategoryLogicalFieldNavbar>
    <Switch>
      <Route exact path={props.match.url} component={LogicalFieldsListPage}></Route>
      <Route
        exact
        path={`${props.match.url}/:logicalFieldUuid`}
        component={LogicalFieldDetailPage}
      />
    </Switch>
  </React.Fragment>
);

export default LogicalFieldsRoutes;
