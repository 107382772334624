import CampaignsApi from 'api/CampaignsApi';
import IssuesApi from 'api/IssuesApi';
import issuePolicyApi from 'api/IssuesPoliciesApi';
import UniversalSearchApi from 'api/UniversalSearchApi';
import AbstractImporter from './AbstractImporter';
export default class ImporterIssues extends AbstractImporter {
  static isSupported(object) {
    return object.issue || object.campaign || object.issuePolicy;
  }

  async importObject(object, resolveUuidOnly = false) {
    if (object.issue) {
      return this.importIssue(object.issue, resolveUuidOnly);
    } else if (object.campaign) {
      return this.importCampaign(object.campaign, resolveUuidOnly);
    } else if (object.issuePolicy) {
      return this.importIssuePolicy(object.issuePolicy, resolveUuidOnly);
    } else {
      throw new Error('Object type not supported by ImporterIssue');
    }
  }

  async importIssuePolicy(issuePolicy, resolveUuidOnly = false) {
    let api = new issuePolicyApi();
    let reconciledPolicy = issuePolicy;
    if (!resolveUuidOnly) {
      let resourceTargetResolved = issuePolicy?.resourceType
        ? await this.resolveTarget(issuePolicy)
        : issuePolicy;
      reconciledPolicy = {
        ...issuePolicy,
        ...resourceTargetResolved
      };
    }
    return this.importTemplate({
      object: reconciledPolicy,
      objectName: 'name',
      objectIdentifier: 'uuid',
      api: api,
      search: api.getPolicies,
      post: api.postPolicy,
      put: api.putPolicy,
      delet: api.deletePolicy,
      resolveUuidOnly
    });
  }

  async importIssue(issue, resolveUuidOnly = false) {
    let api = new IssuesApi();
    let reconciledIssue = issue;
    if (!resolveUuidOnly) {
      let resourceTargetResolved = issue?.resourceType ? await this.resolveTarget(issue) : issue;
      reconciledIssue = {
        ...issue,
        ...resourceTargetResolved,
        assignee: issue?.assignee
          ? await super.importObject({ user: issue?.assignee }, true)
          : null,
        reporter: issue?.reporter
          ? await super.importObject({ user: issue?.reporter }, true)
          : null,
        campaign: issue?.campaign ? await this.importCampaign(issue.campaign, true) : null
      };
    }
    return this.importTemplate({
      object: reconciledIssue,
      objectName: 'name',
      objectIdentifier: 'uuid',
      api: api,
      search: api.getIssues,
      post: api.postIssue,
      put: api.putIssue,
      delet: api.deleteIssue,
      resolveUuidOnly
    });
  }

  async resolveTarget(issue) {
    let { resourceType, resourceName, resourceIdentifier } = issue;
    let api = new UniversalSearchApi();
    let result = await this.importTemplate({
      object: {
        resourceType,
        name: resourceName,
        resourceIdentifier
      },
      objectName: 'name',
      objectIdentifier: 'resourceIdentifier',
      api,
      search: (params) => api.getSearch({ ...params, resourceType }),
      resolveUuidOnly: true
    });
    return {
      resourceType,
      resourceName: result.name,
      resourceIdentifier: result.resourceIdentifier
    };
  }

  async importCampaign(campaign, resolveUuidOnly = false) {
    let api = new CampaignsApi();
    let reconciledCampaign = campaign;
    if (!resolveUuidOnly) {
      reconciledCampaign = {
        ...campaign,
        owner: campaign?.owner ? await super.importObject({ user: campaign.owner }, true) : null,
        creator: campaign?.creator
          ? await super.importObject({ user: campaign.creator }, true)
          : null
      };
    }
    return this.importTemplate({
      object: reconciledCampaign,
      objectName: 'name',
      objectIdentifier: 'uuid',
      api,
      search: api.getCampaigns,
      post: api.postCampaign,
      put: api.putCampaign,
      delet: api.deleteCampaign,
      resolveUuidOnly
    });
  }
}
