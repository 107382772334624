import { FETCH_QUALITY_ASSESSMENT_CONFIGURATION } from 'constants/ActionTypes';
import { buildApiCallAction } from 'actions/ActionUtils';
import QualityAssessmentConfigurationApi from 'api/quality/assessment/QualityAssessmentConfigurationApi';

const qualityAssessmentConfigurationApi = new QualityAssessmentConfigurationApi();

export const fetchConfiguration = () =>
  buildApiCallAction({
    api: qualityAssessmentConfigurationApi,
    apiFunction: (api) => api.getConfiguration(),
    actionType: FETCH_QUALITY_ASSESSMENT_CONFIGURATION
  });
