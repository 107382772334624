import { handleErrors, parseJson, encodeQueryData, BlindataApi } from './ApiUtils';

const PHYSICAL_FIELD_URL = '/api/v1/physical_fields';
export default class PhysicalFieldsApi extends BlindataApi {
  token = '';
  selectedTenantUuid = null;

  getPhysicalFields({ search, physicalEntityUuid, logicalFieldsUuids, page, size } = {}) {
    const filters = { search, physicalEntityUuid, logicalFieldsUuids, page, size };
    return fetch(`/api/v1/physical_fields?${encodeQueryData(filters)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson);
  }

  getPhysicalField(physicalFieldUuid) {
    return this.get(`${PHYSICAL_FIELD_URL}/${physicalFieldUuid}`);
  }

  postPhysicalField(physicalField) {
    return fetch(`/api/v1/physical_fields`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(physicalField)
    })
      .then(handleErrors)
      .then(parseJson);
  }

  putPhysicalField(physicalField) {
    return fetch(`/api/v1/physical_fields/${physicalField.uuid}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(physicalField)
    })
      .then(handleErrors)
      .then(parseJson);
  }

  deletePhysicalField(physicalField) {
    return fetch(`/api/v1/physical_fields/${physicalField.uuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.token}`,
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    }).then(handleErrors);
  }

  exportPhysicalField(params) {
    return this.download('/api/v1/physical_fields/*/export', {
      ...params,
      includeProperties: 'false'
    });
  }
}
