import React, { useState } from 'react';
import { Card, CardActions, CardContent, CardHeader, Button, Grid } from '@mui/material';
import {
  modifyQualityCheck,
  deleteQualityCheck
} from '../../../../../actions/QualityChecksActions';
import { createQualityResult } from '../../../../../actions/QualityResultsActions';
import { useHistory } from 'react-router';
import ConfirmButton from '../../../../../components/confirmbutton/ConfirmButton';
import ModifyAction from '../commons/ModifyAction';
import { connect } from 'react-redux';
import Authorize from '../../../../../components/permissions/Authorize';
import Permissions from '../../../../../constants/Permissions';
import QualityResultModal from '../commons/QualityResultModal';
import ResourceTypes from '../../../../../constants/ResourceTypes';
import CustomPropertiesView from '../../../../../components/additional_properties/CustomPropertiesView';
import WatchlistIconButton from 'pages/social/watchlist/WatchlistIconButton';
import QualityCheckPhysicalAssociationTable from '../commons/QualityCheckPhysicalAssociationTable';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import QualityProbesDefinitionAssociatedCheck from 'pages/quality/probes/definitions/common/QualityProbesDefinitionAssociatedCheck';
import CardDescription from 'components/carddescription/CardDescription';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

function zeroScore(number) {
  if (number === 0) {
    return '0';
  } else if (number === null) {
    return 'Not defined for this check';
  } else {
    return number;
  }
}
function QualityCheckDetailCard({
  qualityCheck,
  modifyQualityCheck,
  deleteQualityCheck,
  createQualityResult
}) {
  const cardStyle = {
    margin: '8px 0 8px 0',
    padding: 8
  };

  const [open, setOpen] = useState(false);
  let history = useHistory();

  return (
    <div>
      <Card style={cardStyle}>
        <CardHeader
          title={qualityCheck.name}
          subheader={qualityCheck.code}
          action={
            <Grid container direction='row' alignContent='center'>
              {!qualityCheck.enabled && (
                <Grid item>
                  <VariationTypeDecorator>{'DISABLED'}</VariationTypeDecorator>
                </Grid>
              )}
              <Grid item>
                <WatchlistIconButton
                  entityIdentifier={qualityCheck.uuid}
                  entityType={'QUALITY_CHECK'}></WatchlistIconButton>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <CardDescription>{qualityCheck.description}</CardDescription>
          <div className='dls-wrapper'>
            <KVDefinitionList
              header='Score Strategy'
              item={{
                'Score Strategy': qualityCheck.scoreStrategy,
                Highest: zeroScore(qualityCheck.scoreRightValue),
                Lowest: zeroScore(qualityCheck.scoreLeftValue),
                Expected: zeroScore(qualityCheck.scoreExpectedValue)
              }}></KVDefinitionList>
            <KVDefinitionList
              header='Semaphore Thresholds'
              item={{
                Warning: zeroScore(qualityCheck.warningThreshold),
                Success: zeroScore(qualityCheck.successThreshold)
              }}></KVDefinitionList>

            <CustomPropertiesView
              additionalProperties={qualityCheck.additionalProperties}
              resourceType={ResourceTypes.QUALITY_CHECK}
            />
          </div>
          <QualityCheckPhysicalAssociationTable qualityCheck={qualityCheck} />
          <Authorize hasPermissions={[Permissions.PROBES_VIEWER]}>
            <QualityProbesDefinitionAssociatedCheck
              checkCode={qualityCheck?.code}
              checkGroupCode={qualityCheck?.checkGroupCode}
              rootUuid={qualityCheck?.rootUuid}></QualityProbesDefinitionAssociatedCheck>
          </Authorize>
        </CardContent>
        <Authorize hasPermissions={[Permissions.QUALITY_EDITOR]}>
          <CardActions>
            <ModifyAction qualityCheck={qualityCheck} modifyQualityCheck={modifyQualityCheck} />
            <ConfirmButton
              onClick={() => {
                deleteQualityCheck(qualityCheck.uuid).then(() =>
                  history.push(`/data-quality/kqis/suites/${qualityCheck.qualitySuite.uuid}`)
                );
              }}>
              Delete
            </ConfirmButton>
            {qualityCheck.isManual && <Button onClick={() => setOpen(true)}>Add Result</Button>}
            <div style={{ marginLeft: 'auto' }}>
              <WebActionsButton
                resource={qualityCheck}
                page={TargetPages.QUALITY_CHECK_DETAIL_PAGE}
              />
            </div>
          </CardActions>
        </Authorize>
      </Card>
      {open && (
        <QualityResultModal
          qualityCheck={qualityCheck}
          open={open}
          onCancel={() => setOpen(false)}
          onSubmit={(result) =>
            createQualityResult(result).then(
              (result) => result && history.push(`/data-quality/kqis/results/${result.uuid}`)
            )
          }></QualityResultModal>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  createQualityResult,
  modifyQualityCheck,
  deleteQualityCheck
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityCheckDetailCard);
