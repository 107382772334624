import { buildApiCallAction } from './ActionUtils';
import {
  FETCH_COMMENTS_PAGE,
  FETCH_COMMENT,
  CREATE_COMMENT,
  MODIFY_COMMENT,
  DELETE_COMMENT
} from '../constants/ActionTypes';
import CommentsApi from '../api/CommentsApi';

const commentsApi = new CommentsApi();

export const fetchCommentsPage = (params) =>
  buildApiCallAction({
    api: commentsApi,
    apiFunction: (api) => api.getComments(params),
    actionType: FETCH_COMMENTS_PAGE,
    payload: (response) => ({
      ...response,
      resourceType: params.resourceType,
      resourceIdentifier: params.resourceIdentifier
    })
  });

export const fetchComment = (sequenceId) =>
  buildApiCallAction({
    api: commentsApi,
    apiFunction: (api) => api.getComment(sequenceId),
    actionType: FETCH_COMMENT
  });

export const createComment = (templateProcessing) =>
  buildApiCallAction({
    api: commentsApi,
    apiFunction: (api) => api.postComment(templateProcessing),
    actionType: CREATE_COMMENT
  });

export const modifyComment = (templateProcessing) =>
  buildApiCallAction({
    api: commentsApi,
    apiFunction: (api) => api.putComment(templateProcessing),
    actionType: MODIFY_COMMENT
  });

export const deleteComment = (sequenceId) =>
  buildApiCallAction({
    api: commentsApi,
    apiFunction: (api) => api.deleteComment(sequenceId),
    actionType: DELETE_COMMENT,
    payload: { sequenceId }
  });
