import React from 'react';
import RpaTemplateEditor from './RpaTemplateEditor';
import { connect } from 'react-redux';
import {
  editRpaTemplate,
  deleteRpaTemplate,
  createRpaTemplate,
  modifyRpaTemplate
} from '../../../actions/RpaTemplateActions';
import {
  testDownloadRpaTemplate,
  loadEditorWithTemplate,
  loadEditorWithTemplateSample
} from '../../../actions/RpaUtilsActions';

class RpaTemplateEditorPage extends React.Component {
  static EDIT_MODE = 'EDIT_MODE';
  static CREATE_MODE = 'CREATE_MODE';

  componentDidMount() {
    let { loadEditorWithTemplate, loadEditorWithTemplateSample } = this.props;
    if (this.isEditMode()) {
      loadEditorWithTemplate(this.props.match.params['rpaTemplateUuid']);
    } else {
      loadEditorWithTemplateSample();
    }
  }

  isEditMode() {
    return this.props.mode === RpaTemplateEditorPage.EDIT_MODE;
  }

  render() {
    let {
      editor,
      editRpaTemplate,
      createRpaTemplate,
      modifyRpaTemplate,
      deleteRpaTemplate,
      testDownloadRpaTemplate
    } = this.props;

    return (
      <RpaTemplateEditor
        editor={editor}
        onEdit={editRpaTemplate}
        onSave={
          this.isEditMode()
            ? modifyRpaTemplate
            : editor =>
                createRpaTemplate(editor).then(({ uuid }) =>
                  this.props.history.push(`/reports/rpa/templates/edit/${uuid}`)
                )
        }
        onDelete={
          this.isEditMode()
            ? editor =>
                deleteRpaTemplate(editor).then(() =>
                  this.props.history.push('/reports/rpa/templates/create')
                )
            : null
        }
        onTest={
          this.isEditMode()
            ? ({ uuid }, type) => testDownloadRpaTemplate({ templateUuid: uuid, type })
            : null
        }
      />
    );
  }
}

const mapDispatchToProps = {
  editRpaTemplate,
  loadEditorWithTemplate,
  loadEditorWithTemplateSample,
  createRpaTemplate,
  modifyRpaTemplate,
  deleteRpaTemplate,
  testDownloadRpaTemplate
};

const mapStateToProps = state => ({
  editor: state.reports.rpa.templates.editor
});

export default connect(mapStateToProps, mapDispatchToProps)(RpaTemplateEditorPage);
