import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AddFAB } from '../../../components/fab';
import ProcessingItem from './ProcessingsListItem';
import ProcessingEditorModal from '../form/ProcessingEditorModal';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';

export default class ProcessingsList extends Component {
  state = {
    createOpen: false,
    editOpen: false,
    processingToEdit: {},
    deleteOpen: false,
    processingToDelete: {}
  };

  handleCreate(processing) {
    this.props.createProcessing(processing).then((p) => {
      this.setState({ createOpen: false });
      if (p) this.props.history.push(`/processings/${p.uuid}`);
    });
  }

  handleEdit(processing) {
    this.props
      .modifyProcessing(processing)
      .then(() => this.setState({ editOpen: false, processingToEdit: undefined }));
  }

  handleDelete(processing) {
    this.props.deleteProcessing(processing).then(() => this.props.history.replace('/processings'));
  }

  render() {
    return (
      <div>
        {this.props.processings.map((p) => (
          <ProcessingItem
            key={p.uuid}
            processing={p}
            history={this.props.history}
            deleteProcessing={(processing) => this.handleDelete(processing)}
            modifyProcessing={(processing) =>
              this.setState({
                editOpen: true,
                processingToEdit: processing
              })
            }
          />
        ))}

        <Authorize hasAnyPermissions={[Permissions.PROCESSINGS_ADMIN, Permissions.PROPOSE]}>
          <AddFAB onClick={() => this.setState({ createOpen: true })} />
        </Authorize>

        <ProcessingEditorModal
          title='Create new Processing'
          handleCancel={() => this.setState({ createOpen: false })}
          handleSubmit={(processing) => this.handleCreate(processing)}
          open={this.state.createOpen}
        />

        <ProcessingEditorModal
          title='Modify Processing'
          processing={this.state.processingToEdit}
          handleCancel={() => this.setState({ editOpen: false })}
          handleSubmit={(processing) => this.handleEdit(processing)}
          open={this.state.editOpen}
        />
      </div>
    );
  }
}

ProcessingsList.propTypes = {
  processings: PropTypes.array.isRequired
};
