import ProbesDefinitionsApi from "api/quality/probes/ProbesDefinitionsApi";
import ProbesProjectsApi from "api/quality/probes/ProbesProjectsApi";
import AbstractImporter from "./AbstractImporter";

export default class ImporterQualityProbes extends AbstractImporter {

  static isSupported(object) {
    return object.qualityProbe || object.qualityProbesProject;
  }

  async importObject(object, resolveUuidOnly = false) {
    if (object.qualityProbe) {
      return this.importQualityProbe(object.qualityProbe, resolveUuidOnly);
    } else if (object.qualityProbesProject) {
      return this.importQualityProbesProject(object.qualityProbesProject, resolveUuidOnly);
    } else {
      throw new Error('Object type not supported by ImporterQualityProbes');
    }
  }

  async importQualityProbe(probe, resolveUuidOnly = false) {
    let api = new ProbesDefinitionsApi();
    let reconciledProbe = probe;
    if (!resolveUuidOnly) {
      reconciledProbe = {
        ...probe,
        project: await this.importQualityProbesProject(probe.project, true)
      }
    }
    return this.importTemplate({
      object: reconciledProbe,
      objectName: 'name',
      objectIdentifier: 'rootUuid',
      api: api,
      post: api.postProbeDefinition,
      put: api.putProbeDefinition,
      delet: api.logicallyDeleteProbeDefinition,
      resolveUuidOnly
    })
  }

  async importQualityProbesProject(project, resolveUuidOnly = false) {
    let api = new ProbesProjectsApi();
    return this.importTemplate({
      object: project,
      objectName: 'name',
      objectIdentifier: 'uuid',
      api,
      search: api.getProjects,
      post: api.postProject,
      put: api.putProject,
      delet: api.deleteProject,
      resolveUuidOnly
    })
  }
}