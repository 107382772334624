import React, { useEffect, useState } from 'react';
import { fetchSchedule } from 'actions/onagent/AgentScheduleActions';
import { Typography } from '@mui/material';

import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';
import { connect } from 'react-redux';
import PaginatedPanel from 'components/pagination/PaginatedPanel';
import AgentScheduleListItem from './AgentScheduleListItem';
import AgentJobDefinitionFAB from './AgentJobDefinitionFAB';

function AgentRunList({ agent, fetchSchedule, currentPage, modifySchedule, deleteSchedule }) {
  const [load, setLoad] = useState(false);
  useEffect(() => {
    !load && fetchSchedule(agent).then(() => setLoad(true));
  });

  return (
    <React.Fragment>
      {currentPage?.content?.length > 0 ? (
        <PaginatedPanel
          currentPage={currentPage}
          onPageSelection={(page) => fetchSchedule(agent, { page })}>
          {currentPage.content.map((job) => (
            <AgentScheduleListItem
              agent={agent}
              jobSchedule={job}
              onEdit={modifySchedule}
              onDelete={deleteSchedule}></AgentScheduleListItem>
          ))}
        </PaginatedPanel>
      ) : (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='subtitle2' align='center' style={{ marginTop: '20px' }}>
            No job scheduled on this agent
          </Typography>
        </div>
      )}
      <AgentJobDefinitionFAB agent={agent} />
    </React.Fragment>
  );
}

function mapStateToProps(state, props) {
  return {
    currentPage: state.settings.agents.schedule.currentPage
  };
}
export default compose(
  withPermissions({ hasPermissions: [Permissions.AGENTS_VIEWER] }),
  connect(mapStateToProps, {
    fetchSchedule
  })
)(AgentRunList);
