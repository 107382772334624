import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchSchemas } from '../../../../actions/PhysicalEntitiesActions';
import AutoComplete from '../../../../components/autocomplete/AutoComplete';

function PhysicalEntitySchemaAutoComplete({ fetchSchemas, fullWidth, margin, systemUuid, schema, onChange }) {

  const [schemas, setSchemas] = useState([]);

  useEffect(() => {
    if (systemUuid) {
      fetchSchemas({ systemUuid: systemUuid, search: '' }).then(
        (response) => response && setSchemas(response.content)
      );
    }
  }, [systemUuid, fetchSchemas, setSchemas]);

  return (
    <AutoComplete
      fullWidth={fullWidth}
      margin={margin}
      label='Schema'
      value={schema}
      disabled={!systemUuid}
      dataSource={schemas}
      onUpdateInput={onChange}
      onNewRequest={onChange}
    />
  );
}

export default connect(null, { fetchSchemas })(PhysicalEntitySchemaAutoComplete);
