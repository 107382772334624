import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import AutoComplete from 'components/autocomplete/AutoComplete';

import JdbcConnectionProperties from 'pages/settings/agents/commons/AgentConnectionModal/JdbcConnectionProperties';
import MongoConnectionProperties from 'pages/settings/agents/commons/AgentConnectionModal/MongoConnectionProperties';
import BlindataConnectionProperties from 'pages/settings/agents/commons/AgentConnectionModal/BlindataConnectionProperties';

const AgentConnectionModal = ({ title, open, connection, onSubmit, onClose }) => {
  const { t } = useTranslation();

  const [patch, setPatch] = useState({});
  const mergedState = {
    ...connection,
    ...patch,
    properties: { ...connection?.properties, ...patch.properties }
  };

  const setPatchProperties = (key, value) => {
    const cleanedValue = value === '' || value === undefined ? null : value;
    setPatch({ ...patch, properties: { ...patch.properties, [key]: cleanedValue } });
  };

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          margin='dense'
          required
          fullWidth
          label={'Name'}
          placeholder={'Enter the connection name'}
          value={mergedState.name || ''}
          onChange={(event) => setPatch({ ...patch, name: event.target.value })}
        />
        <AutoComplete
          required
          fullWidth
          label={'Type'}
          placeholder='Select the connection type'
          value={mergedState.type || ''}
          dataSource={['JDBC', 'MONGO', 'BLINDATA']}
          onUpdateInput={(type) => setPatch({ ...patch, type })}
        />
        {mergedState.type === 'JDBC' && (
          <JdbcConnectionProperties
            properties={mergedState.properties}
            updateProperties={(key, value) => setPatchProperties(key, value)}
          />
        )}
        {mergedState.type === 'MONGO' && (
          <MongoConnectionProperties
            properties={mergedState.properties}
            updateProperties={(key, value) => setPatchProperties(key, value)}
          />
        )}
        {mergedState.type === 'BLINDATA' && (
          <BlindataConnectionProperties
            properties={mergedState.properties}
            updateProperties={(key, value) => setPatchProperties(key, value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button disabled={disabled(mergedState)} onClick={() => onSubmit(patch)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const disabled = (connection) => {
  return (
    _.isEmpty(connection.name) ||
    _.isEmpty(connection.type) ||
    (connection.type.toUpperCase() === 'JDBC' && _.isEmpty(connection.properties.endpoint)) ||
    (connection.type.toUpperCase() === 'MONGO' &&
      (_.isEmpty(connection.properties.endpoint) ||
        _.isEmpty(connection.properties.databaseName) ||
        _.isEmpty(connection.properties.user) ||
        _.isEmpty(connection.properties.pwd)))
  );
};

export default AgentConnectionModal;
