import { SET_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT, CLEAR_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT, FETCH_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT } from "../../constants/ActionTypes";
import { combineReducers } from "redux";

const filtersInitialState = { 
  selectedProcessings: [], 
  targetDate: null,
  context: null,
  nullContext: false, 
  openAddProcessing: false 
}

function filters(state= filtersInitialState,action){
  switch (action.type) {
    case SET_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT:
      return {...state,...action.payload}
    case CLEAR_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT:
      return filtersInitialState;
    default:
      return state;
  }
}

function audienceCount(state=null,action){
  switch (action.type) {
    case FETCH_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT:
      return action.payload
    case SET_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT:
    case CLEAR_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT:
      return null;
    default:
      return state;
  }
}

export default combineReducers({filters,audienceCount})