import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { searchDataActors } from '../../../actions/DataActorsActions'
import ItemAutoCompleteModal from '../../../components/itemselector/ItemAutoCompleteModal'

const DataActorsSearchModal = ({ t, title, open, contentByUuid, searchDataActors, onCancel, onSubmit }) => (
  <ItemAutoCompleteModal title={title || `Search Data Actor`}
    itemName={'Data Actor'}
    open={open}
    keyProperty={'uuid'}
    nameProperty={'name'}
    values={Object.values(contentByUuid)}
    onSearch={(searchText) => searchDataActors({ searchText })}
    handleCancel={onCancel}
    handleSubmit={onSubmit} />
)

DataActorsSearchModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  contentByUuid: state.data_actors.contentByUuid
});

export default _.flow([
  withTranslation(),
  connect(mapStateToProps, { searchDataActors })
])(DataActorsSearchModal);
