import React from 'react';
import { Card, CardHeader, CardContent, TextField } from '@mui/material';
import HtmlEditor from 'components/html_editor/HtmlEditor';

const TemplateContentEditor = ({ editor, editContractTemplate }) => (
  <Card style={{ margin: '8px 0 8px 0' }}>
    <CardHeader title={editor.name || 'Template Name'}></CardHeader>
    <CardContent>
      <TextField
        variant='standard'
        fullWidth
        margin='dense'
        placeholder='Enter the name'
        label='Template Name'
        value={editor.name || ''}
        onChange={(event) => editContractTemplate({ name: event.target.value })}
      />
      <br />

      <HtmlEditor
        label='Template Content'
        defaultHtmlEnabled
        placeholder='Enter the template content. Use the switch button to enable the html editor.'
        text={editor.contentTemplate || ''}
        handleChange={(text) => editContractTemplate({ contentTemplate: text })}
      />

      <br />
    </CardContent>
  </Card>
);

export default TemplateContentEditor;
