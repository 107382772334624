import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
import ClassificationScopesModal from '../../commons/ClassificationScopesModal';
import { HelpIcon } from '../../../../components/help';
import DictionariesAutoComplete from '../../dictionaries/commons/DictionariesAutoComplete';

function RulesModal({ open, rule = {}, onClose, onSubmit, fullScreen = false }) {
  const { t } = useTranslation();
  const [ruleDataPatch, setRuleDataPatch] = React.useState({});
  const [addScopeOpen, setAddScopeOpen] = React.useState(false);
  const [enabled, setEnabled] = React.useState(false);
  const mergedRuleData = { ...rule, ...ruleDataPatch };
  return (
    <Dialog fullWidth fullScreen={fullScreen} open={open}>
      <DialogTitle>Rule</DialogTitle>
      <DialogContent>
        <h4>Rule definition</h4>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter rule name'}
          label={'Name'}
          value={mergedRuleData.name || ''}
          onChange={(event) => setRuleDataPatch({ ...ruleDataPatch, name: event.target.value })}
        />
        <br></br>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter data category name'}
          label={'Data Category Name'}
          value={mergedRuleData.dataCategoryName || ''}
          onChange={(event) =>
            setRuleDataPatch({ ...ruleDataPatch, dataCategoryName: event.target.value })
          }
        />
        <br></br>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter logical field name'}
          label={'Logical Field Name'}
          value={mergedRuleData.logicalFieldName || ''}
          onChange={(event) =>
            setRuleDataPatch({ ...ruleDataPatch, logicalFieldName: event.target.value })
          }
        />
        <br></br>
        <TextField
          variant='standard'
          fullWidth
          placeholder={'Enter description'}
          label={'Description'}
          value={mergedRuleData.description || ''}
          onChange={(event) =>
            setRuleDataPatch({ ...ruleDataPatch, description: event.target.value })
          }
        />
        <br></br>
        <FormControl variant='standard' fullWidth>
          <InputLabel>Rule Type</InputLabel>
          <Select
            variant='standard'
            native={false}
            required
            fullWidth
            defaultValue={mergedRuleData.type || ''}
            onChange={(event) => {
              setRuleDataPatch({
                ...ruleDataPatch,
                type: event.target.value,
                ruleContent: null,
                dictionary: null
              });
            }}>
            <MenuItem value={t('classification.rules.DICTIONARY_DATA.value')}>
              <ListItem dense={true}>
                <ListItemText primary={t('classification.rules.DICTIONARY_DATA.name')} />
                <ListItemIcon>
                  <HelpIcon tooltip={t('classification.rules.DICTIONARY_DATA.definition')} />
                </ListItemIcon>
              </ListItem>
            </MenuItem>
            <MenuItem value={t('classification.rules.REGEX_DATA.value')}>
              <ListItem dense={true}>
                <ListItemText primary={t('classification.rules.REGEX_DATA.name')} />
                <ListItemIcon>
                  <HelpIcon tooltip={t('classification.rules.REGEX_DATA.definition')} />
                </ListItemIcon>
              </ListItem>
            </MenuItem>
            <MenuItem value={t('classification.rules.VALUE_DATA.value')}>
              <ListItem dense={true}>
                <ListItemText primary={t('classification.rules.VALUE_DATA.name')} />
                <ListItemIcon>
                  <HelpIcon tooltip={t('classification.rules.VALUE_DATA.definition')} />
                </ListItemIcon>
              </ListItem>
            </MenuItem>
            <MenuItem value={t('classification.rules.TABLE_METADATA.value')}>
              <ListItem dense={true}>
                <ListItemText primary={t('classification.rules.TABLE_METADATA.name')} />
                <ListItemIcon>
                  <HelpIcon tooltip={t('classification.rules.TABLE_METADATA.definition')} />
                </ListItemIcon>
              </ListItem>
            </MenuItem>
            <MenuItem value={t('classification.rules.FIELD_METADATA.value')}>
              <ListItem dense={true}>
                <ListItemText primary={t('classification.rules.FIELD_METADATA.name')} />
                <ListItemIcon>
                  <HelpIcon tooltip={t('classification.rules.FIELD_METADATA.definition')} />
                </ListItemIcon>
              </ListItem>
            </MenuItem>
            <MenuItem value={t('classification.rules.TYPE_METADATA.value')}>
              <ListItem dense={true}>
                <ListItemText primary={t('classification.rules.TYPE_METADATA.name')} />
                <ListItemIcon>
                  <HelpIcon tooltip={t('classification.rules.TYPE_METADATA.definition')} />
                </ListItemIcon>
              </ListItem>
            </MenuItem>
            <MenuItem value={t('classification.rules.REGEX_FIELD_METADATA.value')}>
              <ListItem dense={true}>
                <ListItemText primary={t('classification.rules.REGEX_FIELD_METADATA.name')} />
                <ListItemIcon>
                  <HelpIcon tooltip={t('classification.rules.REGEX_FIELD_METADATA.definition')} />
                </ListItemIcon>
              </ListItem>
            </MenuItem>
            <MenuItem value={t('classification.rules.REGEX_TABLE_METADATA.value')}>
              <ListItem dense={true}>
                <ListItemText primary={t('classification.rules.REGEX_TABLE_METADATA.name')} />
                <ListItemIcon>
                  <HelpIcon tooltip={t('classification.rules.REGEX_TABLE_METADATA.definition')} />
                </ListItemIcon>
              </ListItem>
            </MenuItem>
          </Select>
        </FormControl>
        <br></br>
        {mergedRuleData.type !== t('classification.rules.DICTIONARY_DATA.value') && (
          <TextField
            variant='standard'
            required
            fullWidth
            placeholder={
              mergedRuleData.type !== undefined
                ? t(`classification.rules.${mergedRuleData.type}.example`)
                : 'Enter rule content'
            }
            label={'Rule Content'}
            value={mergedRuleData.ruleContent || ''}
            onChange={(event) =>
              setRuleDataPatch({ ...ruleDataPatch, ruleContent: event.target.value })
            }
          />
        )}
        <br></br>
        {mergedRuleData.type === t('classification.rules.DICTIONARY_DATA.value') && (
          <DictionariesAutoComplete
            value={rule.dictionary || ''}
            onChange={(dictionary) => {
              if (dictionary) setRuleDataPatch({ ...ruleDataPatch, dictionary: dictionary });
              else {
                setRuleDataPatch({ ...ruleDataPatch, dictionary: null });
              }
            }}
          />
        )}
        <br></br>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter rule weight'}
          label={'Weight'}
          value={mergedRuleData.weight || ''}
          onChange={(event) => setRuleDataPatch({ ...ruleDataPatch, weight: event.target.value })}
        />
        <br></br>
        {(mergedRuleData.type === t('classification.rules.DICTIONARY_DATA.value') ||
          mergedRuleData.type === t('classification.rules.REGEX_DATA.value') ||
          mergedRuleData.type === t('classification.rules.VALUE_DATA.value')) && (
          <TextField
            variant='standard'
            fullWidth
            placeholder={'Enter rule threshold'}
            label={'Threshold'}
            value={mergedRuleData.threshold || ''}
            onChange={(event) =>
              setRuleDataPatch({ ...ruleDataPatch, threshold: event.target.value })
            }
          />
        )}
        <br></br>
        <FormControlLabel
          control={
            <Checkbox
              checked={enabled}
              onChange={(event) => {
                setRuleDataPatch({ ...ruleDataPatch, enabled: event.target.checked });
                setEnabled(event.target.checked);
              }}
              name='enabled'
              color='primary'
            />
          }
          label='Production'
        />
        <br></br>
        <h4>Associated scopes</h4>
        <ChipWrapper onRequestAdd={() => setAddScopeOpen(true)}>
          {(mergedRuleData.scopes || []).map((p, index) => (
            <ChipItem
              key={index}
              id={index}
              label={p}
              onRequestDelete={() =>
                setRuleDataPatch({
                  ...ruleDataPatch,
                  scopes: mergedRuleData.scopes.filter((p, idx) => idx !== index)
                })
              }
            />
          ))}
        </ChipWrapper>
        <ClassificationScopesModal
          title={'Add Scope'}
          open={addScopeOpen}
          onCancel={() => setAddScopeOpen(false)}
          onSubmit={(scope) => {
            setRuleDataPatch({
              ...ruleDataPatch,
              scopes: (mergedRuleData.scopes || []).concat([scope])
            });
            setAddScopeOpen(false);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button
          color='primary'
          variant='contained'
          disabled={
            !mergedRuleData.name ||
            (!mergedRuleData.dataCategoryName && !mergedRuleData.logicalFieldName) ||
            !mergedRuleData.weight ||
            (!mergedRuleData.ruleContent && mergedRuleData.type !== 'DICTIONARY_DATA') ||
            !mergedRuleData.type
          }
          onClick={() => {
            onSubmit(mergedRuleData, ruleDataPatch);
          }}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RulesModal;
