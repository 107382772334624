import { IconButton, Tooltip } from '@mui/material';
import { Publish } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';

export default function ImportLinkButton() {
  return (
    <Tooltip title='Import' aria-label='import'>
      <IconButton size='small' component={Link} to={'/settings/import'}>
        <Publish />
      </IconButton>
    </Tooltip>
  );
}
