import SystemLoginApi from '../api/SystemLoginApi'
import {buildApiCallAction} from './ActionUtils'
import {showMessage,showProgress,hideProgress} from './BehaviourActions'
import {
  RECEIVE_SEARCH_SYSTEM_LOGINS,
  FETCH_SYSTEM_LOGINS_PAGE,
  MODIFY_SYSTEM_LOGIN,
  DELETE_SYSTEM_LOGIN,
  FETCH_GRANTS_PAGE,
  CREATE_SYSTEM_LOGIN,
  CREATE_GRANT,
  DELETE_GRANT
} from '../constants/ActionTypes'

let api = new SystemLoginApi();

export const searchSystemLogins = ({searchText,systemUuid,page,size})=>buildApiCallAction({
  api,
  apiFunction: api => api.searchSystemLogins(searchText,systemUuid,page,size),
  actionType: RECEIVE_SEARCH_SYSTEM_LOGINS
})

export const fetchSystemLoginsPageBySystem = (systemUuid,page,size)=> buildApiCallAction({
  api:api,
  apiFunction: api => api.getSystemLoginPageBySystem(systemUuid,page,size),
  actionType: FETCH_SYSTEM_LOGINS_PAGE,
  payload: data => ({...data,systemUuid})
})

export const createSystemLogin = (systemLogin) => (dispatch,getState) => {
  api.token= getState().authentication.access_token
  showProgress()(dispatch,getState);
  return api.postSystemLogin(systemLogin)
  .then(data => dispatch({type:CREATE_SYSTEM_LOGIN, payload:data}))
  .catch(error => {
    showMessage(error.message)(dispatch,getState);
  })
  .then(()=> hideProgress()(dispatch,getState))
}

export const modifySystemLogin = (systemLogin) => buildApiCallAction({
    api: api,
    apiFunction: (api) => api.putSystemLogin(systemLogin),
    actionType: MODIFY_SYSTEM_LOGIN
})

export const deleteSystemLogin = (systemLogin) => buildApiCallAction({
  api: api,
  apiFunction: (api) => api.deleteSystemLogin(systemLogin),
  actionType: DELETE_SYSTEM_LOGIN,
  payload: systemLogin
})
/*
export const fetchGrants = (systemLogin,page,size) => (dispatch,getState) =>{
  api.token= getState().authentication.access_token
  showProgress()(dispatch,getState);
  return api.getGrants(systemLogin,page)
  .then(data => dispatch({type:FETCH_GRANTS_PAGE, payload:data}))
  .catch(error => {
    showMessage(error.message)(dispatch,getState);
  })
  .then(()=> hideProgress()(dispatch,getState))
}*/

export const fetchGrants = (systemLogin,page,size)=> buildApiCallAction({
  api,
  apiFunction: api=>api.getGrants(systemLogin,page),
  actionType: FETCH_GRANTS_PAGE,
  payload: (data)=> ({systemLoginUuid:systemLogin.uuid,...data})
})

export const createGrant = (systemLogin,physicalEntity,grant) => (dispatch,getState) => {
  api.token= getState().authentication.access_token
  showProgress()(dispatch,getState);
  return api.postGrant(systemLogin,physicalEntity,grant)
  .then(data => dispatch({type:CREATE_GRANT, payload:data}))
  .catch(error => {
    showMessage(error.message)(dispatch,getState);
  })
  .then(()=> hideProgress()(dispatch,getState))
}

export const deleteGrant=(systemLogin,grant) => (dispatch,getState) => {
  api.token= getState().authentication.access_token
  showProgress()(dispatch,getState);
  return api.deleteGrant(systemLogin,grant)
  .then(data => dispatch({type:DELETE_GRANT, payload:grant}))
  .catch(error => {
    showMessage(error.message)(dispatch,getState);
  })
  .then(()=> hideProgress()(dispatch,getState))
}
