import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import QualityPhysicalPanel from 'pages/quality/kqis/quality_checks/commons/QualityPhysicalPanel';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

export function DataProductQualityPanel({ dataProduct }) {
  let ports = useMemo(
    () => dataProduct?.ports.filter((p) => p.entityType === 'outputPorts') || [],
    [dataProduct]
  );
  if (ports.length === 0) {
    return (
      <Card>
        <CardHeader title='Quality Checks' />
        <CardContent>
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>No output ports defined for this resource</Typography>
          </div>
        </CardContent>
      </Card>
    );
  }
  return (
    <>
      {ports.map((p) => (
        <QualityPhysicalPanel
          title={p.displayName || p.name}
          physicalEntityUuid={p.physicalEntities.map((pe) => pe.uuid)}
        />
      ))}
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DataProductQualityPanel);
