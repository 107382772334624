import {
  FETCH_RPA_TEMPLATE,
  DELETE_RPA_TEMPLATE,
  FETCH_RPA_TEMPLATES,
  MODIFY_RPA_TEMPLATE,
  EDIT_RPA_TEMPLATE,
  CREATE_RPA_TEMPLATE
} from '../../../constants/ActionTypes';
import { combineReducers } from 'redux';

function content(state = {}, action) {
  switch (action.type) {
    case FETCH_RPA_TEMPLATE:
    case CREATE_RPA_TEMPLATE:
      return { ...state, [action.payload.uuid]: action.payload };
    default:
      return state;
  }
}

function editor(state = {}, action) {
  switch (action.type) {
    case EDIT_RPA_TEMPLATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const currentPage = (state = { content: [], number: 0, totalPages: 0 }, action) => {
  switch (action.type) {
    case FETCH_RPA_TEMPLATES:
      return action.payload;
    case DELETE_RPA_TEMPLATE:
      return {
        ...state,
        content: state.content.filter(i => i.uuid !== action.payload.uuid)
      };
    case MODIFY_RPA_TEMPLATE:
      return {
        ...state,
        content: state.content.map(p => (p.uuid === action.payload.uuid ? action.payload : p))
      };
    default:
      return state;
  }
};

export default combineReducers({ content, currentPage, editor });
