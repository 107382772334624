import React from 'react';
import { Switch, Route } from 'react-router';
import StewardshipDashboardsPage from './StewardshipDashboardsPage';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  return (
    <Switch>
      <Route path={`${props.match.url}`} component={StewardshipDashboardsPage}></Route>
    </Switch>
  );
}
