import React from 'react';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';
import { useTranslation } from 'react-i18next';
import { ChipWrapper, ChipItem } from '../../../components/chiplist/ChipList';
import { useHistory } from 'react-router';
import { CLEAR_TASKS_FILTERS } from '../../../constants/ActionTypes';
import ResourceTypes from '../../../constants/ResourceTypes';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';

function TaskView({ task }) {
  const [t] = useTranslation();
  let history = useHistory();
  return (
    <>
      {task.description}
      <h4>{t('tasks.sections.businessMetadata')}</h4>
      <KVDefinitionList
        item={{
          [t('tasks.fields.category')]: task.category
        }}
      />

      <h4>{t('tasks.sections.lifecycleDefinition')}</h4>
      <KVDefinitionList
        item={{
          [t('tasks.fields.startDate')]: task.startDate,
          [t('tasks.fields.endDate')]: task.endDate
        }}
      />

      <CustomPropertiesView
        additionalProperties={task.additionalProperties || []}
        resourceType={ResourceTypes.TASK}
      />

      {CLEAR_TASKS_FILTERS.processings && <h4>{t('tasks.sections.associatedProcessings')}</h4>}
      <ChipWrapper>
        {(task.processings || []).map((p) => (
          <ChipItem
            key={p.uuid}
            id={p.uuid}
            label={p.name}
            tooltip={p.description}
            onClick={p.uuid ? () => history.push(`/processings/${p.uuid}`) : undefined}
          />
        ))}
      </ChipWrapper>
    </>
  );
}

export default TaskView;
