import React, { useState } from 'react';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid
} from '@mui/material';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  fetchDictionary,
  modifyDictionary,
  deleteDictionary
} from '../../../../actions/DictionariesActions';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { withLoader } from '../../../../components/loader/Loader';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import DictionariesModal from '../commons/DictionariesModal';
import DictionaryCardView from './DictionaryCardView';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

function DictionaryDetailPage({ dictionary, deleteDictionary, modifyDictionary }) {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        elements={[{ text: 'Classification', to: '/classification' }, { text: dictionary.name }]}
      />
      <Tracking
        info={{
          pageTitle: `Dictionary: ${dictionary.dictionaryName}`,
          category: EngagementCategories.CLASSIFICATION,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Dictionary: {dictionary.dictionaryName}</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`dictionary:${dictionary.dictionaryName}_${dictionary.uuid}`}
            pageTitle={`Dictionary: ${dictionary.dictionaryName}`}
          />
        </Grid>
      </Grid>
      <Card>
        <CardHeader
          title={dictionary.dictionaryName}
          subheader={dictionary.entityName}></CardHeader>
        <CardContent>{dictionary.query}</CardContent>
        <Authorize hasPermissions={[Permissions.CLASSIFICATION_EDITOR]}>
          <CardActions>
            <ModifyDictionary
              dictionary={dictionary}
              modifyDictionary={modifyDictionary}></ModifyDictionary>
            <ConfirmButton
              onClick={() => {
                deleteDictionary(dictionary).then(() =>
                  history.push('/classification/dictionaries')
                );
              }}>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </CardActions>
        </Authorize>
      </Card>
      <Typography variant='h5'>Content</Typography>
      <DictionaryCardView dictionary={dictionary}></DictionaryCardView>
    </>
  );
}

function ModifyDictionary({ dictionary, modifyDictionary }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      <DictionariesModal
        dictionary={dictionary}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(modifiedDictionary) => {
          modifyDictionary(modifiedDictionary);
          setOpen(false);
        }}></DictionariesModal>
    </>
  );
}

function mapStateToProps(state, props) {
  const identifier = props.match.params.uuid;
  return {
    dictionary: state.classification.dictionaries.content[identifier],
    uuid: props.match.params.uuid
  };
}

const mapDispatchToProps = {
  fetchDictionary,
  modifyDictionary,
  deleteDictionary
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchDictionary, uuid }) => fetchDictionary(uuid),
    ready: ({ dictionary }) => dictionary !== undefined
  })
)(DictionaryDetailPage);
