import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tooltip } from '@mui/material';
import PropertiesTableRenderer from './PropertiesTableRenderer';

const styles = {
  tooltip: {
    maxWidth: '800px'
  }
};

const PropertiesTable = ({
  headers,
  propertiesPaths,
  keyPath,
  rows,
  selectedIndex,
  selectedIndexes,
  onSelectionChanged,
  firstStrong
}) => (
  <PropertiesTableRenderer
    {...{
      headers,
      propertiesPaths,
      keyPath,
      rows,
      selectedIndex,
      selectedIndexes,
      onSelectionChanged,
      firstStrong,
      cellContentComponent: cellContentComponent(firstStrong)
    }}
  />
);

const cellContentComponent =
  (firstStrong) =>
  ({ property, propertyPathIndex }) => {
    let text = formatValueAsText(property);
    let renderedText = propertyPathIndex === 0 && firstStrong ? <strong>{text}</strong> : text;
    let tableCell = <p>{renderedText}</p>;
    var enrichedTableCell =
      property && property.length > 100 ? (
        <Tooltip sx={styles.tooltip} title={property} placement='left'>
          {tableCell}
        </Tooltip>
      ) : (
        tableCell
      );
    return enrichedTableCell;
  };

const formatValueAsText = (value) => {
  if (_.isArray(value)) {
    return value.filter((elem) => elem).join(', ');
  } else {
    return _.truncate(value || 'Not defined', {
      length: 100,
      omission: ' ...'
    });
  }
};

PropertiesTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  propertiesPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedIndex: PropTypes.number,
  selectedIndexes: PropTypes.instanceOf(Set),
  onSelectionChanged: PropTypes.func,
  firstStrong: PropTypes.bool
};

export default PropertiesTable;
