import * as React from "react";
const SvgIngress = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 80 91.5"
    width="1em"
    height="1em"
    {...props}
  >
    <path fill="#29aae2" d="m40 0 40 23v45.5l-40 23-40-23V23z" />
    <path
      fill="#79d0f2"
      d="m40 4 36 20.8v41.6L47.63 82.792l-2.585-40.704-30.956-23.117L40 4z"
    />
    <g fill="none" stroke="#fff" strokeWidth={4}>
      <path d="m40 11 30 17.3v34.6L40 80.2 10 62.9V28.3zm0 56.6 20-33.8H20z" />
      <path id="Ingress_svg__a" d="M40 11v15.5M40 44v20" />
      <use xlinkHref="#Ingress_svg__a" transform="rotate(120 40 45)" />
      <use xlinkHref="#Ingress_svg__a" transform="rotate(-120 40 45)" />
    </g>
  </svg>
);
export default SvgIngress;
