/* eslint-disable no-useless-constructor */
import { BlindataAgentApi } from './BlindataAgentApi';

export default class AgentAdminApi extends BlindataAgentApi {
  constructor(agent) {
    super(agent);
  }

  pairAgent(body) {
    return this.post('/agent-api/v1/admin/tenants', body);
  }
}
