// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { fetchAccountResultingPermissions, fetchProfile } from 'actions/LoginActions';
import { useDispatch } from 'react-redux';
import { fetchAvailableWebActions } from 'actions/settings/webactions/WebActionsActions';

function UserAccountLoader({ children }) {
  let [loaded, setLoaded] = useState(false);
  let dispatch = useDispatch();
  useEffect(() => {
    (async function fetchUserAccountInfo() {
      if (loaded) return;
      await dispatch(fetchProfile());
      await dispatch(fetchAccountResultingPermissions());
      await dispatch(fetchAvailableWebActions());
      setLoaded(true);
    })();
  }, [dispatch, setLoaded, loaded]);
  return loaded ? children : null;
}

export default UserAccountLoader;
