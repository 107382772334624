import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchIssuesPage } from 'actions/IssuesActions';
import IssuesTable from './IssuesTable';
function IssueList({ currentPage, fetchIssuesPage, filters, pagination }) {
  useEffect(
    function () {
      fetchIssuesPage({ page: 0, ...filters });
    },
    [filters, fetchIssuesPage]
  );
  const handleFetch = (page, size) => {
    fetchIssuesPage({ page, size, ...filters });
  };
  if (!currentPage) {
    return null;
  }
  return (
    <IssuesTable
      issues={currentPage.content}
      pagination={pagination}
      handleFetch={handleFetch}></IssuesTable>
  );
}
const mapStateToProps = (state) => ({
  currentPage: state.campaigns.issues.currentPage,
  filters: state.campaigns.issues.filters.content,
  pagination: {
    page: state.campaigns.issues.currentPage.number,
    size: state.campaigns.issues.currentPage.size,
    totalElements: state.campaigns.issues.currentPage.totalElements
  }
});

export default connect(mapStateToProps, { fetchIssuesPage })(IssueList);
