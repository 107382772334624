import React from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import GraphDataFlowLinkButton from 'pages/graph/utils/GraphDataFlowLinkButton';
import { useHistory } from 'react-router';
import { AccountTree } from '@mui/icons-material';
import { ConnectPhysicalEntitiesButton } from './ConnectPhysicalEntitiesButton';
import { t } from 'i18next';

export const DataFlowCardControlButtons = ({
  systemUuid,
  physicalEntityUuid,
  physicalFieldUuid,
  filters,
  incoming,
  setIncoming,
  name
}) => {
  const history = useHistory();

  function computeGraphUuidFilter() {
    if (physicalFieldUuid) {
      return {
        entityType: 'PHYSICAL_FIELD',
        name: name || '',
        object: { uuid: physicalFieldUuid }
      };
    }
    if (physicalEntityUuid) {
      return {
        entityType: 'PHYSICAL_ENTITY',
        name: name || '',
        object: { uuid: physicalEntityUuid }
      };
    }
    if (systemUuid) {
      return {
        entityType: 'SYSTEM',
        name: name || '',
        object: { uuid: systemUuid }
      };
    }
    return {};
  }

  return (
    <>
      {!systemUuid && (
        <Tooltip title='Show Flow Chart'>
          <IconButton
            sx={{ margin: 1 }}
            onClick={() =>
              history.push(
                `/dataflows/flowchart?resourceIdentifier=${
                  physicalEntityUuid || physicalFieldUuid
                }&resourceType=${
                  physicalEntityUuid ? 'PHYSICAL_ENTITY' : 'PHYSICAL_FIELD'
                }&dataFlowsDepth=50`
              )
            }
            size='large'>
            <AccountTree />
          </IconButton>
        </Tooltip>
      )}
      <GraphDataFlowLinkButton
        sx={{ margin: 1 }}
        perspectiveOptions={{
          physicalPerspective: true,
          dataFlowsPerspective: true,
          dataFlowsDepth: 5
        }}
        graphUuidFilter={computeGraphUuidFilter()}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ConnectPhysicalEntitiesButton
          filters={filters}
          dataFlowDirection={'Incoming'}
          physicalEntityUuid={physicalEntityUuid}
        />
        <Button color={incoming ? 'primary' : 'grey'} onClick={() => setIncoming(true)}>
          {t('dataflows.card.controlButtons.incoming')}
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ConnectPhysicalEntitiesButton
          filters={filters}
          dataFlowDirection={'Outcoming'}
          physicalEntityUuid={physicalEntityUuid}
        />
        <Button color={!incoming ? 'primary' : 'grey'} onClick={() => setIncoming(false)}>
          {t('dataflows.card.controlButtons.outcoming')}
        </Button>
      </div>
    </>
  );
};
