import AlertDialog from 'components/alertdialog/AlertDialog';
import React from 'react';
import RulesModal from './RulesModal';

function ClassificationRulesActionsModals({
  selectedRule,
  editModalOpen,
  setEditModalOpen,
  deleteModalOpen,
  setDeleteModalOpen,
  onEdit,
  onDelete
}) {
  return (
    <>
      {/* Edit modal */}
      <RulesModal
        rule={selectedRule}
        open={editModalOpen}
        key={selectedRule && selectedRule.uuid}
        onClose={() => setEditModalOpen(false)}
        onSubmit={(rule) => onEdit(rule)}></RulesModal>
      {/* Delete modal */}
      <AlertDialog
        message={'Do you really want to delete this rule?'}
        open={deleteModalOpen}
        onOk={() => onDelete(selectedRule)}
        onCancel={() => setDeleteModalOpen(false)}
      />
    </>
  );
}

export default ClassificationRulesActionsModals;
