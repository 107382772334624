import React from 'react';
import { Button, Card, CardHeader, CardContent, CardActions, Grid } from '@mui/material';
import { TextField } from '@mui/material';
import { connect } from 'react-redux';
import {
  clearDataSubjectReport,
  editDataSubjectReportSelection,
  fetchDataSubjectReport,
  downloadDataSubjectReport
} from '../../../actions/ReportsActions';
import PropertiesTable from '../../../components/tables/PropertiesTable';
import ChipPropertiesTable from '../../../components/tables/ChipPropertiesTable';
import { useTranslation } from 'react-i18next';

const DataSubjectReportSelection = ({
  name,
  value,
  data,
  editDataSubjectReportSelection,
  clearDataSubjectReport,
  fetchDataSubjectReport,
  downloadDataSubjectReport
}) => {
  const { t } = useTranslation();
  return (
    <Card style={{ margin: '8px 0px 8px 0px' }}>
      <CardHeader title='Data Subject Report' />
      <CardContent>
        <Grid container>
          <Grid item>
            <h4 style={{ display: 'inline', margin: '0 16px 0 0' }}>Data Subject Key</h4>
            <TextField
              variant='standard'
              style={{ margin: '4px', padding: 0 }}
              placeholder='Enter the key name'
              label='Key Name'
              value={name || ''}
              onChange={(event) => editDataSubjectReportSelection({ name: event.target.value })}
            />

            <TextField
              variant='standard'
              style={{ margin: '4px' }}
              placeholder='Enter the key value'
              label='Key Value'
              value={value || ''}
              onChange={(event) => editDataSubjectReportSelection({ value: event.target.value })}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          children={t('commons.actions.load')}
          color='primary'
          style={{ margin: '4px' }}
          onClick={() => fetchDataSubjectReport({ name, value })}
          disabled={!name || !value}></Button>

        <Button
          children={t('commons.actions.download')}
          style={{ margin: '4px' }}
          onClick={() => downloadDataSubjectReport({ name, value })}
          disabled={!name || !value}></Button>

        <Button
          children={t('commons.actions.clear')}
          style={{ margin: '4px' }}
          onClick={() => clearDataSubjectReport()}
          disabled={!name || !value}></Button>
      </CardActions>
    </Card>
  );
};

const DataSubjectReportView = ({ data, history }) => {
  if (data == null) return null;
  return (
    <Card>
      <CardHeader
        title={`Report  (${data.dataSubjectKey.name} , ${data.dataSubjectKey.value})`}></CardHeader>
      <CardContent>
        <h4>Consents</h4>
        <PropertiesTable
          firstStrong
          rows={data.consents}
          headers={['Processing', 'Context', 'Consent', 'Read']}
          propertiesPaths={[
            'processing.name',
            'data.context',
            'data.consentState',
            'data.disclaimerState'
          ]}
        />
        <h4>Processings</h4>
        <ChipPropertiesTable
          rows={data.processings}
          headers={['Processing', 'Category', 'Description']}
          propertiesPaths={['name', 'category', 'description']}
          getChipLink={(row) => `/processings/${row.uuid}`}
        />
        <h4>Data Categories</h4>
        <ChipPropertiesTable
          rows={data.dataCategories}
          headers={['Data Category', 'Clearance', 'Class', 'Description']}
          propertiesPaths={['name', 'clearance', 'dataClass', 'description']}
          getChipLink={(row) => `/datacategories/${row.uuid}`}
        />
        <h4>Systems</h4>
        <ChipPropertiesTable
          rows={data.systems.map((s) => ({
            data: s.data,
            physicalEntities: s.physicalEntities.map((pe) => pe.schema + '.' + pe.name)
          }))}
          headers={['System', 'Description', 'Physical Entities']}
          propertiesPaths={['data.name', 'data.description', 'physicalEntities']}
          getChipLink={(row) => `/systems/${row.data.uuid}`}
        />
      </CardContent>
    </Card>
  );
};

const DataSubjectReport = (props) => (
  <div>
    <DataSubjectReportSelection {...props}></DataSubjectReportSelection>
    <DataSubjectReportView data={props.data}></DataSubjectReportView>
  </div>
);

function mapStateToProps(state) {
  return {
    access_token: state.authentication.access_token,
    name: state.reports.dataSubjectReport.selection.name,
    value: state.reports.dataSubjectReport.selection.value,
    data: state.reports.dataSubjectReport.data
  };
}

export default connect(mapStateToProps, {
  clearDataSubjectReport,
  editDataSubjectReportSelection,
  fetchDataSubjectReport,
  downloadDataSubjectReport
})(DataSubjectReport);
