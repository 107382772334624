import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControlLabel,
  Switch
} from '@mui/material';

import SystemItemAutoComplete from 'pages/systems/commons/SystemItemAutoComplete';
import PhysicalEntitySchemaAutoComplete from 'pages/systems/physical_entities/commons/PhysicalEntitySchemaAutoComplete';
import PhysicalEntityTypeSelect from 'pages/systems/physical_entities/commons/PhysicalEntityTypeSelect';
import DataSetAutoComplete from 'pages/systems/physical_entities/commons/DataSetAutoComplete';
import DatePicker, { hourAdjustments } from 'components/pickers/DatePicker';
import AdditionalPropertiesEditor from 'components/additional_properties/AdditionalPropertiesEditor';
import ResourceTypes from 'constants/ResourceTypes';

const PhysicalEntityCreateModal = ({
  physicalEntity,
  title,
  fullScreen,
  open,
  handleSubmit,
  handleCancel
}) => {
  const [name, setName] = useState(physicalEntity?.name);
  const [description, setDescription] = useState(physicalEntity?.description);
  const [system, setSystem] = useState(physicalEntity?.system);
  const [schema, setSchema] = useState(physicalEntity?.schema);
  const [tableType, setTableType] = useState(physicalEntity?.tableType);
  const [dataSet, setDataSet] = useState(physicalEntity?.dataSet);
  const [creationDate, setCreationDate] = useState(physicalEntity?.creationDate);
  const [modificationDate, setModificationDate] = useState(physicalEntity?.modificationDate);
  const [hidden, setHidden] = useState(physicalEntity?.hidden);
  const [additionalProperties, setAdditionalProperties] = useState(
    physicalEntity?.additionalProperties
  );

  return (
    <Dialog fullWidth fullScreen={fullScreen} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          placeholder='Enter the entity name'
          label='Name'
          required={true}
          value={name || ''}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          placeholder='Enter the entity description'
          label='Description'
          value={description || ''}
          onChange={(event) => setDescription(event.target.value)}
        />
        <SystemItemAutoComplete
          fullWidth
          margin='dense'
          required={true}
          value={system}
          onChange={(system) => {
            setSystem(system);
            setSchema(undefined);
          }}
        />
        <PhysicalEntitySchemaAutoComplete
          fullWidth
          margin='dense'
          systemUuid={system?.uuid}
          schema={schema || ''}
          onChange={(schema) => setSchema(schema)}
        />
        <PhysicalEntityTypeSelect
          fullWidth
          margin='dense'
          type={tableType}
          onChange={(type) => setTableType(type)}
        />
        <DataSetAutoComplete
          fullWidth
          margin='dense'
          dataSet={dataSet || ''}
          onChange={(dataSet) => setDataSet(dataSet)}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              fullWidth
              margin='dense'
              label={'Created on system at'}
              hintText={'Creation Date'}
              hourAdjustment={hourAdjustments.startOfDay}
              clearable={true}
              value={creationDate || null}
              onChange={(date) => setCreationDate(date)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              fullWidth
              margin='dense'
              label={'Updated on system at'}
              hintText={'Modification Date'}
              hourAdjustment={hourAdjustments.startOfDay}
              clearable={true}
              value={modificationDate || null}
              onChange={(date) => setModificationDate(date)}
            />
          </Grid>
        </Grid>
        <FormControlLabel
          label='Hidden'
          control={
            <Switch
              color='primary'
              checked={hidden || false}
              onChange={(event, checked) => setHidden(checked)}
            />
          }
          style={{ marginTop: '16px' }}
        />
        <AdditionalPropertiesEditor
          resourceType={ResourceTypes.PHYSICAL_ENTITY}
          additionalProperties={additionalProperties}
          onChange={(additionalProperties) => setAdditionalProperties(additionalProperties)}
        />
      </DialogContent>
      <DialogActions>
        <Button children='Cancel' onClick={handleCancel} />
        <Button
          children='Submit'
          variant='contained'
          color='primary'
          disabled={!name || !system}
          onClick={() =>
            handleSubmit({
              ...physicalEntity,
              physicalFields: null, //keep the pf the same
              name,
              description,
              system,
              schema,
              tableType,
              dataSet,
              creationDate,
              modificationDate,
              hidden,
              additionalProperties
            })
          }
        />
      </DialogActions>
    </Dialog>
  );
};

PhysicalEntityCreateModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default _.flow([])(PhysicalEntityCreateModal);
