import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import BdLinkify from 'components/linkify/BdLinkify';

const styles = {
  definitionList: {
    overflow: 'auto',
    marginRight: '8px'
  },
  definitionTerms: {
    float: 'left',
    clear: 'left',
    textAlign: 'left',
    fontWeight: 400,
    color: '#00aeca'
  },
  definitionDescription: {
    display: 'block',
    float: 'left',
    clear: 'left',
    marginLeft: '40px',
    wordWrap: 'break-word'
  }
};

const DefinitionList = ({ t, header, items, labelProperty, dataProperties }) => (
  <dl style={styles.definitionList}>
    {header ? <h4>{header}</h4> : null}
    {items
      .map((i, index1) =>
        [
          <dt style={styles.definitionTerms} key={index1 + i[labelProperty]}>
            {i[labelProperty]}
          </dt>
        ].concat(
          dataProperties.map((dataProperty, index2) => (
            <dd style={styles.definitionDescription} key={index1 + '$' + index2 + i[dataProperty]}>
              <BdLinkify>
                {_.isArray(i[dataProperty])
                  ? i[dataProperty].join(', ')
                  : i[dataProperty] || t('commons.placeholders.undefined')}
              </BdLinkify>
              <br></br>
            </dd>
          ))
        )
      )
      .reduce((x, y) => x.concat(y), [])}
  </dl>
);

DefinitionList.propTypes = {
  header: PropTypes.string,
  dataProperties: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelProperty: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
};

export default withTranslation()(DefinitionList);
