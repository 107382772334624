import React, { useState } from 'react';
import {
  Button,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid
} from '@mui/material';
import Search from '@mui/icons-material/FilterList';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  clearProcessingsFilters,
  setProcessingsFilters
} from '../../../actions/ProcessingsActions';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import DataCategorySearchModal from '../../datacategories/utils/DataCategorySearchModal';
import ProcessingCategorySearchModal from './ProcessingCategorySearchModal';
import DataActorsSearchModal from '../../data_actors/utils/DataActorsSearchModal';
import AccountTeamChipEditor from '../../settings/teams/common/AccountTeamChipEditor';
import DatePicker, { hourAdjustments } from 'components/pickers/DatePicker';
import ProcessingLegalBasisAutoComplete from '../commons/ProcessingLegalBasisAutoComplete';

function ProcessingsFilters({
  filters,
  setProcessingsFilters,
  clearProcessingsFilters,
  onRefresh
}) {
  let { t } = useTranslation();
  let [open, setOpen] = useState(false);
  let [addDataCategory, setAddDataCategory] = useState(false);
  let [addProcessingCategory, setAddProcessingCategory] = useState(false);
  let [addController, setAddController] = useState(false);
  let [addProcessor, setAddProcessor] = useState(false);
  let {
    hasFilters,
    dataCategories = [],
    teamsCodes = [],
    nullTeamCode = false,
    processingsCategories = [],
    controllers = [],
    processors = [],
    endDate = null,
    lifeCycleState = null,
    legalBasis = null,
    sort
  } = filters;

  return (
    <React.Fragment>
      <IconButton
        size='small'
        color={hasFilters ? 'primary' : 'grey'}
        onClick={() => setOpen(true)}>
        <Search />
      </IconButton>
      <Dialog fullWidth modal={true} contentStyle={{ maxWidth: '600px' }} open={open}>
        <DialogTitle>Processings Filters</DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1'>{t('data_categories.header')}</Typography>
          <ChipWrapper onRequestAdd={() => setAddDataCategory(true)}>
            {dataCategories.map((dc) => (
              <ChipItem
                key={dc.uuid}
                id={dc.uuid}
                label={dc.name}
                tooltip={dc.description}
                onRequestDelete={() =>
                  setProcessingsFilters({
                    dataCategories: dataCategories.filter((d) => d.uuid !== dc.uuid)
                  })
                }
              />
            ))}
          </ChipWrapper>
          <Typography variant='subtitle1'>Processing Categories</Typography>
          <ChipWrapper onRequestAdd={() => setAddProcessingCategory(true)}>
            {processingsCategories.map((cat) => (
              <ChipItem
                key={cat}
                id={cat}
                label={cat}
                tooltip={cat}
                onRequestDelete={() =>
                  setProcessingsFilters({
                    processingsCategories: processingsCategories.filter((d) => d !== cat)
                  })
                }
              />
            ))}
          </ChipWrapper>
          <Typography variant='subtitle1'>Controllers</Typography>
          <ChipWrapper onRequestAdd={() => setAddController(true)}>
            {controllers.map((controller) => (
              <ChipItem
                key={controller}
                id={controller}
                label={controller.name}
                tooltip={controller.businessName}
                onRequestDelete={() =>
                  setProcessingsFilters({
                    controllers: controllers.filter((d) => d !== controller)
                  })
                }
              />
            ))}
          </ChipWrapper>
          <Typography variant='subtitle1'>Processors</Typography>
          <ChipWrapper onRequestAdd={() => setAddProcessor(true)}>
            {processors.map((processor) => (
              <ChipItem
                key={processor}
                id={processor}
                label={processor.name}
                tooltip={processor.businessName}
                onRequestDelete={() =>
                  setProcessingsFilters({
                    processors: processors.filter((d) => d !== processor)
                  })
                }
              />
            ))}
          </ChipWrapper>
          <AccountTeamChipEditor
            teamsCodes={teamsCodes}
            onChange={(teamsCodes) => {
              setProcessingsFilters({ teamsCodes: teamsCodes });
            }}></AccountTeamChipEditor>
          <FormControlLabel
            label='Only Public'
            control={
              <Checkbox
                checked={nullTeamCode}
                onChange={(event, checked) =>
                  setProcessingsFilters({ nullTeamCode: checked || undefined })
                }
              />
            }></FormControlLabel>
          <h4>Legal Basis</h4>
          <ProcessingLegalBasisAutoComplete
            lb={legalBasis || ''}
            onChange={(value) => setProcessingsFilters({ legalBasis: value })}
          />
          <Typography variant='subtitle1'>Life Cycle</Typography>
          <Grid container direction='row' alignContent='center' alignItems='center' fullWidth>
            <Grid item xs={5}>
              <FormControl variant='standard' fullWidth>
                <InputLabel fullWidth>State</InputLabel>
                <Select
                  variant='standard'
                  fullWidth
                  defaultValue={lifeCycleState}
                  onChange={(event) =>
                    setProcessingsFilters({ lifeCycleState: event.target.value })
                  }>
                  <MenuItem value={null}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'EXPIRED'}>Expired</MenuItem>
                  <MenuItem value={'ALIVE'}>Alive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Typography style={{ marginTop: '25%', marginLeft: '35%' }}>At</Typography>
            </Grid>
            <Grid item xs={5}>
              <DatePicker
                clearable={true}
                label={'End Date'}
                hintText={'End Date'}
                margin='dense'
                disabled={!lifeCycleState}
                hourAdjustment={hourAdjustments.endOfDay}
                value={endDate}
                onChange={(date) => setProcessingsFilters({ endDate: date })}
              />
            </Grid>
          </Grid>
          <Typography variant='subtitle1'>Order By</Typography>
          <Button
            onClick={() =>
              setProcessingsFilters({
                sort: sort.slice().reverse()
              })
            }>
            {sort.join(', ')}
            <SwapHoriz />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.clear')}
            onClick={() => {
              clearProcessingsFilters();
              setOpen(false);
              onRefresh();
            }}
          />
          <Button
            variant='outlined'
            color='primary'
            children={t('commons.actions.apply')}
            onClick={() => {
              setOpen(false);
              onRefresh();
            }}
          />
        </DialogActions>
      </Dialog>
      <ProcessingCategorySearchModal
        open={addProcessingCategory}
        onCancel={() => setAddProcessingCategory(false)}
        onSubmit={(pc) => {
          setProcessingsFilters({
            processingsCategories: processingsCategories.concat([pc])
          });
          setAddProcessingCategory(false);
        }}
      />
      <DataCategorySearchModal
        open={addDataCategory}
        onCancel={() => setAddDataCategory(false)}
        onSubmit={(dc) => {
          setProcessingsFilters({
            dataCategories: dataCategories.concat([dc])
          });
          setAddDataCategory(false);
        }}
      />
      <DataActorsSearchModal
        open={addController}
        onSubmit={(p) => {
          setProcessingsFilters({
            controllers: controllers.concat([p])
          });
          setAddController(false);
        }}
        onCancel={() => setAddController(false)}
      />
      <DataActorsSearchModal
        open={addProcessor}
        onSubmit={(p) => {
          setProcessingsFilters({
            processors: processors.concat([p])
          });
          setAddProcessor(false);
        }}
        onCancel={() => setAddProcessor(false)}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  filters: state.processings.filters
});

export default connect(mapStateToProps, { clearProcessingsFilters, setProcessingsFilters })(
  ProcessingsFilters
);
