/* eslint-disable no-useless-constructor */
import { BlindataAgentApi } from './BlindataAgentApi';
const AGENT_JOB_HISTORY_URL = '/agent-api/v1/jobs/history';

export default class AgentJobHistoryApi extends BlindataAgentApi {
  constructor(agent) {
    super(agent);
  }

  getJobHistoryPage(params) {
    return this.get(AGENT_JOB_HISTORY_URL, params);
  }

  getJobHistory(sequenceId) {
    return this.get(`${AGENT_JOB_HISTORY_URL}/${sequenceId}`);
  }
  getJobHistoryLog(sequenceId) {
    return this.get(`${AGENT_JOB_HISTORY_URL}/${sequenceId}/logs`);
  }
}
