import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AutoComplete from '../../../components/autocomplete/AutoComplete';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import DataActorItemAutoComplete from '../../data_actors/utils/DataActorItemAutoComplete';
import DataCategoriesFormSection from './DataCategoriesFormSection';
import LifeCycleFormSection from './LifeCycleFormSection';
import RecipientsFormSection from './RecipientsFormSection';
import PurposesFormSection from './PurposesFormSection';
import SecurityMeasuresFormSection from './SecurityMeasuresFormSection';
import TransferToOrganizationFormComponent from './TransferToOrganizationFormComponent';
import AdditionalPropertiesEditor from '../../../components/additional_properties/AdditionalPropertiesEditor';
import DataActorsSearchModal from '../../data_actors/utils/DataActorsSearchModal';
import AccountTeamSelector, {
  ACCESS_LEVELS
} from '../../settings/profile/utils/AccountTeamSelector';
import ProposeButton from '../../workflows/proposals/commons/ProposeButton';
import {
  ProposalResourceTypes,
  ProposalVariationTypes
} from '../../workflows/proposals/commons/ProposalConstants';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import ResourceTypes from '../../../constants/ResourceTypes';

const defaultState = {
  uuid: undefined,
  name: undefined,
  description: undefined,
  category: undefined,
  startDate: undefined,
  endDate: undefined,
  associatedDataCategories: [],
  jointControllers: [],
  purposes: [],
  recipients: [],
  securityMeasures: [],
  transferExtraEuOrganizations: [],
  additionalProperties: [],
  addDataCategoryOpen: false,
  addJointControllerOpen: false,
  excludeFromRopa: false,
  teamCode: null,
  templateCode: null
};

class ProcessingEditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = props.processing ? { ...defaultState, ...props.processing } : defaultState;
  }

  componentWillReceiveProps(props) {
    let isNewTransaction = props.open && !this.props.open;
    if (isNewTransaction) {
      if (props.processing) {
        this.setState({ ...props.processing });
      } else {
        this.setState(defaultState);
      }
    }
  }

  onSubmit() {
    let processing = {
      ...this.state
    };
    this.props.handleSubmit(processing);
  }

  render() {
    const { t, fullScreen } = this.props;

    return (
      <Dialog fullWidth fullScreen={fullScreen} open={this.props.open}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <h4>General</h4>
          <TextField
            variant='standard'
            required
            placeholder={t('processings.general.fields.nameHint')}
            label={t('processings.general.fields.name')}
            style={{ width: '50%', minWidth: '250px' }}
            value={this.state.name || ''}
            onChange={(event) => this.setState({ name: event.target.value })}
          />

          <TextField
            variant='standard'
            placeholder={t('processings.general.fields.categoryHint')}
            label={t('processings.general.fields.category')}
            style={{ width: '50%', minWidth: '250px' }}
            value={this.state.category || ''}
            onChange={(event) => this.setState({ category: event.target.value })}
          />

          <AutoComplete
            fullWidth
            placeholder={t('processings.general.fields.legalBasisHint')}
            label={t('processings.general.fields.legalBasis')}
            value={this.state.legalBasis || ''}
            dataSource={t('suggestion:processingLegalBases', { returnObjects: true }) || []}
            onUpdateInput={(newValue) => this.setState({ legalBasis: newValue })}
          />
          <br />

          <TextField
            variant='standard'
            placeholder={t('processings.general.fields.descriptionHint')}
            label={t('processings.general.fields.description')}
            multiLine={true}
            fullWidth
            value={this.state.description || ''}
            onChange={(event) => this.setState({ description: event.target.value })}
          />

          <TextField
            variant='standard'
            fullWidth
            placeholder={t('processings.general.fields.templateCodeHint')}
            label={t('processings.general.fields.templateCode')}
            value={this.state.templateCode || ''}
            onChange={(event) => this.setState({ templateCode: event.target.value })}
          />

          <DataCategoriesFormSection
            associatedDataCategories={this.state.associatedDataCategories}
            onAdd={(associatedDataCategory) =>
              this.setState({
                associatedDataCategories: this.state.associatedDataCategories.concat([
                  associatedDataCategory
                ])
              })
            }
            onRemove={(associatedDataCategory) =>
              this.setState({
                associatedDataCategories: this.state.associatedDataCategories.filter(
                  (adc) => adc.dataCategory.uuid !== associatedDataCategory.dataCategory.uuid
                )
              })
            }
          />

          <h4>Actors</h4>
          <DataActorItemAutoComplete
            fullWidth
            title='Add controller'
            style={{ width: '50%', minWidth: '200px' }}
            itemName='Controller'
            value={this.state.controller}
            onChange={(value) => this.setState({ controller: value })}
          />
          <br />
          <DataActorItemAutoComplete
            fullWidth
            title='Add representative'
            itemName='Representative'
            value={this.state.representative}
            onChange={(value) => this.setState({ representative: value })}
          />
          <br />
          <DataActorItemAutoComplete
            fullWidth
            title='Add Data Protection Officer'
            itemName='Data Protection Officer'
            value={this.state.dataProtectionOfficer}
            onChange={(value) => this.setState({ dataProtectionOfficer: value })}
          />
          <br />
          <h4>Joint Controllers</h4>
          <br></br>
          <ChipWrapper onRequestAdd={() => this.setState({ addJointControllerOpen: true })}>
            {this.state.jointControllers.map((dc) => (
              <ChipItem
                key={dc.uuid}
                id={dc.uuid}
                label={dc.name}
                onRequestDelete={() =>
                  this.setState({
                    jointControllers: this.state.jointControllers.filter((d) => d.uuid !== dc.uuid)
                  })
                }
              />
            ))}
          </ChipWrapper>

          <DataActorsSearchModal
            title='Add joint controller'
            open={this.state.addJointControllerOpen}
            onCancel={() => this.setState({ addJointControllerOpen: false })}
            onSubmit={(jointController) =>
              this.setState({
                jointControllers: this.state.jointControllers.concat([jointController]),
                addJointControllerOpen: false
              })
            }
          />

          <PurposesFormSection
            purposes={this.state.purposes}
            onAdd={(purpose) => this.setState({ purposes: this.state.purposes.concat([purpose]) })}
            onDelete={(purpose) =>
              this.setState({ purposes: this.state.purposes.filter((e) => e !== purpose) })
            }
            dataCategories={(this.state.associatedDataCategories || []).map(
              (adc) => adc.dataCategory
            )}
          />

          <RecipientsFormSection
            recipients={this.state.recipients}
            onAdd={(item) => this.setState({ recipients: this.state.recipients.concat([item]) })}
            onDelete={(item) =>
              this.setState({ recipients: this.state.recipients.filter((e) => e !== item) })
            }
            dataCategories={(this.state.associatedDataCategories || []).map(
              (adc) => adc.dataCategory
            )}
          />

          <SecurityMeasuresFormSection
            securityMeasures={this.state.securityMeasures}
            onAdd={(purpose) =>
              this.setState({ securityMeasures: this.state.securityMeasures.concat([purpose]) })
            }
            onDelete={(purpose) =>
              this.setState({
                securityMeasures: this.state.securityMeasures.filter((e) => e !== purpose)
              })
            }
          />

          <TransferToOrganizationFormComponent
            transferExtraEuOrganizations={this.state.transferExtraEuOrganizations}
            onAdd={(item) =>
              this.setState({
                transferExtraEuOrganizations: this.state.transferExtraEuOrganizations.concat([item])
              })
            }
            onDelete={(item) =>
              this.setState({
                transferExtraEuOrganizations: this.state.transferExtraEuOrganizations.filter(
                  (e) => e !== item
                )
              })
            }
          />

          <LifeCycleFormSection
            startDate={this.state.startDate && new Date(this.state.startDate)}
            endDate={this.state.endDate && new Date(this.state.endDate)}
            consentDuration={this.state.consentDuration}
            consentDurationUnit={this.state.consentDurationUnit}
            onChange={(newObj) => this.setState(newObj)}
          />

          <FormControlLabel
            style={{ paddingTop: 8 }}
            label='Exclude from ROPA'
            control={
              <Checkbox
                checked={this.state.excludeFromRopa || false}
                onChange={() => this.setState({ excludeFromRopa: !this.state.excludeFromRopa })}
                value='excludeFromRopa'
              />
            }
          />

          <AdditionalPropertiesEditor
            additionalProperties={this.state.additionalProperties}
            resourceType={ResourceTypes.PROCESSING}
            onChange={(newList) =>
              this.setState({
                additionalProperties: newList
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <AccountTeamSelector
            accessLevel={ACCESS_LEVELS.WRITE}
            accessLevel2={ACCESS_LEVELS.PROPOSE}
            style={{ float: 'left' }}
            selectedTeamCode={this.state.teamCode || ''}
            onChange={(code) => this.setState({ teamCode: code })}
          />
          <div style={{ flexGrow: 1 }} />
          <Button children={t('commons.actions.cancel')} onClick={this.props.handleCancel} />
          <ProposeButton
            disabled={
              this.state.name === undefined || this.state.associatedDataCategories.length === 0
            }
            resourceIdentifier={this.state.uuid}
            resourceName={this.state.name}
            resourceType={ProposalResourceTypes.PROCESSING}
            resourcePayload={this.state}
            variationType={
              this.state.uuid ? ProposalVariationTypes.PUT : ProposalVariationTypes.POST
            }
            onProposalChange={(proposal) => this.setState({ proposal })}
          />
          <Authorize
            hasAnyPermissions={[Permissions.PROCESSINGS_EDITOR, Permissions.PROCESSINGS_ADMIN]}>
            <Button
              children={t('commons.actions.submit')}
              variant='contained'
              color='primary'
              disabled={
                this.state.name === undefined ||
                this.state.associatedDataCategories.length === 0 ||
                this.state.proposal
              }
              onClick={() => this.onSubmit()}
            />
          </Authorize>
        </DialogActions>
      </Dialog>
    );
  }
}

ProcessingEditorModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  processing: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default _.flow([withTranslation()])(ProcessingEditorModal);
