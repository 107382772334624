import ImporterStewardship from './ImporterStewardship';
import ImporterQuality from './ImporterQuality';
import ImporterUsers from './ImporterUsers';
import ImporterLegacy from './ImporterLegacy';
import ImporterQualityAssessment from './ImporterQualityAssessment';
import ImporterQualityProbes from './ImporterQualityProbes';
import ImporterIssues from './ImporterIssues';
import ImporterClassification from './ImporterClassification';
import ImporterLogicalRelation from './ImporterLogicalRelation';
import ImporterQueryParser from './ImporterQueryParser';
import ImporterRoutine from './ImporterRoutine';
import ImporterPhysicalConstraint from './ImporterPhysicalConstraint';
import ImporterDataProduct from './ImporterDataProduct';
/**
 * This class is a strategy to import objects of different resource types.
 * New importer implementations must be added in registerImporters method.
 */
export default class Importer {
  constructor(access_token, selectedTenantUuid, configuration) {
    this.access_token = access_token;
    this.selectedTenantUuid = selectedTenantUuid;
    this.configuration = configuration;
    this.delegate = this;
    this.registeredImporters = this.registerImporters();
  }

  registerImporters() {
    return [
      ImporterLegacy,
      ImporterStewardship,
      ImporterUsers,
      ImporterQuality,
      ImporterQualityAssessment,
      ImporterQualityProbes,
      ImporterIssues,
      ImporterClassification,
      ImporterLogicalRelation,
      ImporterQueryParser,
      ImporterRoutine,
      ImporterDataProduct,
      ImporterPhysicalConstraint
    ];
  }

  async importObject(object, resolveUuidOnly = false) {
    for (const importerClass of this.registeredImporters) {
      if (importerClass.isSupported(object)) {
        return new importerClass(this).importObject(object, resolveUuidOnly);
      }
    }
    throw new Error('Unrecognized object');
  }
}
