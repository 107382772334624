import ResponsibilityChipListEditor from './ResponsibilityChipListEditor';

import { connect } from 'react-redux';
import {
  fetchRolesPage,
  createResponsibility,
  deleteResponsibility,
  modifyResponsibility,
  fetchResponsibilitiesPage
} from '../../../../actions/StewardshipActions';
import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Card,
  CardHeader,
  CardContent
} from '@mui/material';
import Permissions from '../../../../constants/Permissions';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';

function StewardshipResponsibilitiesPanel({
  resourceType,
  resourceIdentifier,
  fetchRolesPage,
  createResponsibility,
  responsibilities,
  fetchResponsibilitiesPage,
  modifyResponsibility,
  currentPage
}) {
  useEffect(
    function () {
      fetchResponsibilitiesPage({
        size: 100,
        resourceType: resourceType,
        resourceIdentifier: resourceIdentifier,
        endDateIsNull: true
      });
      fetchRolesPage({
        resourceType: resourceType
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchResponsibilitiesPage, fetchRolesPage, resourceType]
  );
  const resourceApplicableRoles = currentPage.content.filter((r) =>
    (r.resources || []).some((type) => type.resourceType === resourceType)
  );
  return (
    <Card titleTypographyProps={{}}>
      <CardHeader title={'Responsibilities'}></CardHeader>
      <CardContent>
        {resourceApplicableRoles.length > 0 ? (
          <StewardshipResponsibilitiesTable
            roles={resourceApplicableRoles}
            responsibilities={responsibilities}
            resourceType={resourceType}
            resourceIdentifier={resourceIdentifier}
            onCreate={createResponsibility}
            onModify={modifyResponsibility}
          />
        ) : (
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>No roles defined for this resource</Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

function StewardshipResponsibilitiesTable({
  roles,
  resourceType,
  resourceIdentifier,
  responsibilities,
  onCreate,
  onModify
}) {
  return (
    <Table size='small' style={{ overflowX: 'auto' }}>
      <TableBody>
        {roles.map((role) => (
          <TableRow key={role.uuid}>
            <TableCell>{role.name}</TableCell>
            <TableCell>
              <ResponsibilityChipListEditor
                role={role}
                resourceType={resourceType}
                resourceIdentifier={resourceIdentifier}
                onDelete={onModify}
                onSubmit={onCreate}
                values={responsibilities.filter(
                  (res) => res.stewardshipRole.uuid === role.uuid && !res.endDate
                )}></ResponsibilityChipListEditor>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.stewardship.roles.currentPage,
    responsibilities: state.stewardship.responsibilities.currentPage.content
  };
}
const mapDispatchToProps = {
  fetchRolesPage,
  fetchResponsibilitiesPage,
  createResponsibility,
  deleteResponsibility,
  modifyResponsibility
};

export default compose(
  withPermissions({ hasPermissions: [Permissions.STEWARDSHIP_VIEWER] }),
  connect(mapStateToProps, mapDispatchToProps)
)(StewardshipResponsibilitiesPanel);
