import { BlindataApi } from './ApiUtils';

export default class DashboardsApi extends BlindataApi {
  getConsentsEventsLineChart(params) {
    return this.get(`/api/internal/dashboards/consentsEvents/lineChart`, params);
  }

  getConsentsStatesColumnChart(params) {
    return this.get(`/api/internal/dashboards/consentsStates/columnChart`, params);
  }

  getDataSubjectsAudienceCount(params) {
    return this.get(`/api/internal/dashboards/consentsStates/audienceCount`, params);
  }

  getSystemDataSubjectCount(params) {
    return this.get(`/api/internal/dashboards/systems/dataSubjectsCount`, params);
  }

  getSystemMetadataMetrics(params) {
    return this.get(`/api/internal/dashboards/systems/metadataMetrics`, params);
  }
}
