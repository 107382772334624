import AgentAdminApi from 'api/onagent/AgentAdminApi';
import { buildApiCallAction } from 'actions/ActionUtils';
import { PAIR_AGENT } from 'constants/ActionTypes';

export const pairAgent = (agent, pairingRequest) =>
  buildApiCallAction({
    api: new AgentAdminApi(agent),
    apiFunction: (api) => api.pairAgent(pairingRequest),
    actionType: PAIR_AGENT
  });
