import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchWorskpacesPage } from 'actions/social/favorites/WorkspaceActions';
import { MenuItem, Select, Divider, ListItemText, FormControl, InputLabel } from '@mui/material';
import _ from 'lodash';

function WorkspacesSelector({
  fullWidth = true,
  workspace,
  workspaces,
  onChange,
  setCreateWorkspaceOpen,
  fetchWorskpacesPage,
  kp,
  np,
  fromMove
}) {
  const [workspacesList, setWorkspacesList] = useState(workspaces || null);

  useEffect(() => {
    if (!workspaces || workspaces.length < 1) {
      fetchWorskpacesPage().then((response) => setWorkspacesList(response.content));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchWorskpacesPage]);

  let nameProperty = np;
  let keyProperty = kp;
  let value = workspace;
  let valueKey = value ? value[keyProperty] : undefined;
  let valuesByUuid = _.reduce(
    workspacesList,
    (obj, val) => {
      obj[val[keyProperty]] = val;
      return obj;
    },
    {}
  );

  let randomId = Math.random().toString();
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={`selector-${randomId}-label`}>Workspace</InputLabel>
      <Select
        labelId={`selector-${randomId}-label`}
        id={`selector-${randomId}`}
        margin='dense'
        label='Workspace'
        value={valueKey || valuesByUuid[keyProperty]}
        onChange={(event) => {
          if (event.target.value !== '_createWorkspace') {
            onChange(valuesByUuid[event.target.value]);
            event.stopPropagation();
          } else {
            setCreateWorkspaceOpen(true);
            event.stopPropagation();
          }
        }}
        variant='outlined'>
        {(workspacesList || []).map((workspace) => (
          <MenuItem
            key={workspace[keyProperty]}
            value={workspace[keyProperty]}
            children={workspace[nameProperty]}
          />
        ))}
        <Divider></Divider>
        <MenuItem disabled={fromMove} key={'_createWorkspace'} value={'_createWorkspace'}>
          <ListItemText>Create workspace</ListItemText>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default connect(null, { fetchWorskpacesPage })(WorkspacesSelector);
