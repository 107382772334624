import React from 'react';
import AddFAB from '../../../../components/fab/AddFAB';
import { connect } from 'react-redux';
import { createTemplateTask } from '../../../../actions/TemplatesActions';
import TemplateTaskModal from '../commons/TemplateTaskModal';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';

function TemplateTaskFAB({ createTemplateTask }) {
  const [open, setOpen] = React.useState(false);
  let history = useHistory();
  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      <TemplateTaskModal
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(templateTask) =>
          createTemplateTask(templateTask).then((templateTask) => {
            if (templateTask) {
              setOpen(false);
            }
            history.push(`/templates/tasks/${templateTask.sequenceId}`);
          })
        }></TemplateTaskModal>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  createTemplateTask
};

export default compose(
  withPermissions({ hasPermissions: [Permissions.TASKS_ADMIN] }),
  connect(mapStateToProps, mapDispatchToProps)
)(TemplateTaskFAB);
