import React, { useState } from 'react';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import FilterList from '@mui/icons-material/FilterList';
import DataActorsFiltersModal from './DataActorsFiltersModal';
import { clearDataActorsFilters, setDataActorsFilters } from '../../../actions/DataActorsActions';

function DataActorsFiltersButton({
  filters,
  setDataActorsFilters,
  clearDataActorsFilters,
  hasFilters,
  onRefresh
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton
        size='small'
        color={hasFilters ? 'primary' : 'grey'}
        style={{ float: 'right' }}
        onClick={() => setOpen(true)}>
        <FilterList />
      </IconButton>
      {open && (
        <DataActorsFiltersModal
          onRefresh={onRefresh}
          filters={filters}
          open={open}
          onClear={() => {
            setOpen(false);
            clearDataActorsFilters();
            onRefresh();
          }}
          onSubmit={(filters) => {
            setOpen(false);
            setDataActorsFilters(filters);
            onRefresh();
          }}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  filters: state?.data_actors?.filters?.content,
  hasFilters: state?.data_actors?.filters?.hasFilters || false
});

export default connect(mapStateToProps, {
  clearDataActorsFilters,
  setDataActorsFilters
})(DataActorsFiltersButton);
