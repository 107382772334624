import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
import ClassificationScopesModal from '../../commons/ClassificationScopesModal';

function DicionariesModal({ open, dictionary = {}, onClose, onSubmit, fullScreen = false }) {
  const { t } = useTranslation();
  const [dictionaryDataPatch, setDictionaryDataPatch] = React.useState({});
  const mergedDictionariesData = { ...dictionary, ...dictionaryDataPatch };
  const [addScopeOpen, setAddScopeOpen] = React.useState(false);

  return (
    <Dialog fullWidth fullScreen={fullScreen} open={open}>
      <DialogTitle>Dictionaries</DialogTitle>
      <DialogContent>
        <h4>Dictionary definition</h4>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter the dictionary name'}
          label={'Dictionary name'}
          value={mergedDictionariesData.dictionaryName || ''}
          onChange={(event) =>
            setDictionaryDataPatch({ ...dictionaryDataPatch, dictionaryName: event.target.value })
          }
        />
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter the entity name'}
          label={'Entity name'}
          value={mergedDictionariesData.entityName || ''}
          onChange={(event) =>
            setDictionaryDataPatch({ ...dictionaryDataPatch, entityName: event.target.value })
          }
        />
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter the query'}
          label={'Query'}
          value={mergedDictionariesData.query || ''}
          onChange={(event) =>
            setDictionaryDataPatch({ ...dictionaryDataPatch, query: event.target.value })
          }
        />
        <br></br>
        <h4>Associated scopes</h4>
        <ChipWrapper onRequestAdd={() => setAddScopeOpen(true)}>
          {(mergedDictionariesData.scopes || []).map((p, index) => (
            <ChipItem
              key={index}
              id={index}
              label={p}
              onRequestDelete={() =>
                setDictionaryDataPatch({
                  ...dictionaryDataPatch,
                  scopes: mergedDictionariesData.scopes.filter((p, idx) => idx !== index)
                })
              }
            />
          ))}
        </ChipWrapper>
        <ClassificationScopesModal
          title={'Add a scope'}
          open={addScopeOpen}
          onCancel={() => setAddScopeOpen(false)}
          onSubmit={(scope) => {
            setDictionaryDataPatch({
              ...dictionaryDataPatch,
              scopes: (mergedDictionariesData.scopes || []).concat([scope])
            });
            setAddScopeOpen(false);
          }}
        />
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={
            !mergedDictionariesData.dictionaryName ||
            !mergedDictionariesData.entityName ||
            !mergedDictionariesData.query
          }
          onClick={() => onSubmit(mergedDictionariesData, dictionaryDataPatch)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DicionariesModal;
