import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import ExpandableCard from '../../../components/expandable-card/ExpandableCard';
import { Typography, Button, Hidden } from '@mui/material';
import { ChipWrapper, ChipItem } from '../../../components/chiplist/ChipList';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';
import ResourceTypes from '../../../constants/ResourceTypes';
import IssueResourceListItemWidget from 'pages/issueMngt/issues/commons/IssueResourceListItemWidget';
import { modifySystem, deleteSystem } from 'actions/SystemsActions';
import { setEntitiesFilters } from 'actions/PhysicalEntitiesActions';
import { setRoutinesFilters } from 'actions/RoutinesActions';
import { connect } from 'react-redux';
import SystemEditorModal from '../commons/SystemEditorModal';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import { getIconShort, getTechnologyConfig } from 'constants/SystemTechnologyTypes';
function SystemItem({
  system,
  modifySystem,
  deleteSystem,
  setEntitiesFilters,
  setRoutinesFilters
}) {
  const history = useHistory();

  let [open, setOpen] = useState(false);
  let { t } = useTranslation();
  const handleShowEntities = (sys) => {
    setEntitiesFilters({ system: sys });
    history.push('/physicalentities');
  };
  const handleShowRoutines = (sys) => {
    setRoutinesFilters({ system: sys });
    history.push('/routines');
  };
  return (
    <ExpandableCard
      title={system.name}
      avatar={getIconShort(getTechnologyConfig(system?.technology), {
        width: '2em',
        height: '2em'
      })}
      visibleChildren={<SystemSubtypeChip system={system} />}
      decorator={
        <IssueResourceListItemWidget
          resourceType={ResourceTypes.SYSTEM}
          resourceIdentifier={system.uuid}></IssueResourceListItemWidget>
      }
      actions={[
        system.uuid && (
          <Button
            key='link'
            children={t('systems.actions.detail')}
            component={Link}
            to={`systems/${system.uuid}`}
          />
        ),
        <Authorize hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
          <Button children={t('systems.actions.modify')} onClick={() => setOpen(true)} />
        </Authorize>,
        <Button
          children={t('systems.actions.openLogins')}
          component={Link}
          to={`systems/${system.uuid}/logins`}
        />,
        <Button
          children={t('systems.actions.openEntities')}
          onClick={() => handleShowEntities(system)}
        />,
        <Button
          children={t('systems.actions.openRoutines')}
          onClick={() => handleShowRoutines(system)}
        />,
        <ConfirmButton
          message={'Do you really want to delete System: ' + (system ? system.name : '') + '?'}
          children={t('data_categories.actions.delete')}
          onClick={() => deleteSystem(system)}></ConfirmButton>
      ]}>
      <Typography paragraph>{system.description}</Typography>
      <CustomPropertiesView
        resourceType={ResourceTypes.SYSTEM}
        additionalProperties={system.additionalProperties}
      />
      {open && (
        <SystemEditorModal
          title='Modify system'
          open={open}
          system={system}
          onSubmit={(sys) => {
            modifySystem(sys);
            setOpen(false);
          }}
          onClose={() => setOpen(false)}></SystemEditorModal>
      )}
    </ExpandableCard>
  );
}

function SystemSubtypeChip({ system }) {
  if (!system || !system.subtype) {
    return null;
  }
  return (
    <Hidden mdDown>
      <ChipWrapper
        justifyRigth
        style={{
          position: 'absolute',
          clear: 'both',
          float: 'right',
          display: 'flex',
          right: '60px',
          top: '18px'
        }}>
        <ChipItem id={`system-${system.uuid}-subtype-chip`} label={system.subtype} />
      </ChipWrapper>
    </Hidden>
  );
}

export default connect(null, {
  modifySystem,
  deleteSystem,
  setEntitiesFilters,
  setRoutinesFilters
})(SystemItem);
