import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createWebAction } from 'actions/settings/webactions/WebActionsActions';
import { AddFAB } from 'components/fab';
import WebActionsModal from './WebActionsModal';
function WebActionFAB({ createWebAction }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      {open && (
        <WebActionsModal
          title={'Create a Web Action'}
          open={open}
          handleCancel={() => setOpen(false)}
          handleSubmit={(webAction) => {
            createWebAction(webAction);
            setOpen(false);
          }}></WebActionsModal>
      )}
    </>
  );
}

export default connect(null, { createWebAction })(WebActionFAB);
