import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataActorEditorModal from '../commons/DataActorEditorModal';
import DataActorItem from './DataActorsListItem';
import AlertDialog from '../../../components/alertdialog/AlertDialog';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import { AddFAB } from '../../../components/fab';

export default class DataActorsList extends Component {
  state = {
    editOpen: false,
    createOpen: false,
    deleteOpen: false
  };

  handleDelete() {
    this.props
      .deleteDataActor(this.state.dataActorToDelete)
      .then(() => this.setState({ deleteOpen: false, dataActorToDelete: undefined }));
  }

  render() {
    return (
      <div>
        {this.props.dataActors.map((dp) => (
          <DataActorItem
            key={dp.uuid}
            dataActor={dp}
            deleteDataActor={(dataActor) =>
              this.setState({ deleteOpen: true, dataActorToDelete: dataActor })
            }
            modifyDataActor={(dataActor) =>
              this.setState({ editOpen: true, dataActorToEdit: dataActor })
            }
          />
        ))}

        <Authorize hasPermissions={[Permissions.DATA_ACTORS_EDITOR]}>
          <AddFAB onClick={() => this.setState({ createOpen: true })} />
        </Authorize>

        <DataActorEditorModal
          title='Create Data Actor'
          open={this.state.createOpen}
          handleSubmit={(dp) =>
            this.props
              .createDataActor(dp)
              .then((dataActor) => this.props.history.push(`/data_actors/${dataActor.uuid}`))
          }
          handleCancel={() => this.setState({ createOpen: false })}
        />

        <DataActorEditorModal
          title='Modify Data Actor'
          key={this.state.dataActorToEdit && this.state.dataActorToEdit.uuid}
          open={this.state.editOpen}
          dataActor={this.state.dataActorToEdit}
          handleSubmit={(dp) =>
            this.props
              .modifyDataActor(dp)
              .then(
                (dataActor) =>
                  dataActor && this.props.history.push(`/data_actors/${dataActor.uuid}`)
              )
          }
          handleCancel={() => this.setState({ editOpen: false })}
        />

        <AlertDialog
          open={this.state.deleteOpen}
          message={`Do you really want to delete Data Actor: ${
            this.state.dataActorToDelete && this.state.dataActorToDelete.name
          } ?`}
          onCancel={() => this.setState({ deleteOpen: false })}
          onOk={() => this.handleDelete(this.state.taskToDelete)}
        />
      </div>
    );
  }
}

DataActorsList.propTypes = {
  modifyDataActor: PropTypes.func.isRequired,
  createDataActor: PropTypes.func.isRequired,
  deleteDataActor: PropTypes.func.isRequired,
  dataActors: PropTypes.array.isRequired
};
