import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ProcessingItemAutoComplete from '../../../processings/utils/ProcessingItemAutoComplete';
import ComposableTableCard, {
  addOrEditHelper
} from '../../../../components/tables/ComposableTableCard';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const initialState = { open: false, selectedIndex: null, name: null, processing: null };

class ProcessingsPlaceholdersEditor extends React.Component {
  state = initialState;

  render() {
    let props = this.props;
    let processingSelectedValue = this.state.processing;
    return (
      <React.Fragment>
        <ComposableTableCard
          firstStrong
          title='Processing Placeholders'
          rows={this.props.processingPlaceholders}
          selectedIndex={this.state.selectedIndex}
          headers={['Name', 'Processing ', 'Description']}
          propertiesPaths={['name', 'processing.name', 'processing.description']}
          onSelectionChanged={(selectedIndex) => this.setState({ selectedIndex })}
          onAdd={() => this.setState({ ...initialState, open: true })}
          onEdit={() => {
            let processingPlaceholder = this.props.processingPlaceholders[this.state.selectedIndex];
            let name = processingPlaceholder.name;
            let processing = processingPlaceholder.processing;
            this.setState({ name, processing, open: true });
          }}
          onRemove={(elem, rows, newRows) => this.props.onChange(newRows)}
          onRowsMoved={(newRows, selectedIndex) => {
            this.setState({ selectedIndex });
            this.props.onChange(newRows);
          }}
        />

        <Dialog open={this.state.open}>
          <DialogTitle>Add Processing</DialogTitle>
          <DialogContent>
            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              label='Placeholder name'
              value={this.state.name || ''}
              onChange={(event) => this.setState({ name: (event.target.value || '').trim() })}
            />
            <ProcessingItemAutoComplete
              value={processingSelectedValue}
              onChange={(processing) => {
                this.setState({ processing: processing });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button children='Cancel' onClick={() => this.setState(initialState)} />
            <Button
              children='Add'
              variant='contained'
              color='primary'
              disabled={
                !this.state.name ||
                this.state.name.length === 0 ||
                !processingSelectedValue ||
                !processingSelectedValue.uuid
              }
              onClick={() => {
                props.onChange(
                  addOrEditHelper(
                    this.props.processingPlaceholders,
                    { name: this.state.name, processing: this.state.processing },
                    this.state.selectedIndex
                  )
                );
                this.setState(initialState);
              }}
            />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default ProcessingsPlaceholdersEditor;
