import { Grid } from '@mui/material';
import React from 'react';
import PhysicalConstraintsExportButton from '../commons/PhysicalConstraintsExportButton';
import PhysicalConstraintsFilters from '../commons/PhysicalConstraintsFilters';
import PhysicalConstraintsSearchBox from '../commons/PhysicalConstraintsSearchBox';

const PhysicalConstraintsListToolBox = ({ systemUuid }) => {
  return (
    <Grid container alignItems='center'>
      <Grid item>
        <PhysicalConstraintsSearchBox />
      </Grid>
      <Grid item>
        <PhysicalConstraintsExportButton />
      </Grid>
      <Grid item>
        <PhysicalConstraintsFilters systemUuid={systemUuid} />
      </Grid>
    </Grid>
  );
};

export default PhysicalConstraintsListToolBox;
