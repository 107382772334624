import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { ChipItem } from '../../../components/chiplist/ChipList';
import ItemSelectField from '../../../components/itemselector/ItemSelectField';
import DataCategoryItemAutoComplete from '../../datacategories/utils/DataCategoryItemAutoComplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DialogActions, DialogTitle, DialogContent, Grid } from '@mui/material';

const defaultState = {
  dataCategory: undefined,
  erasureTimeLimits: undefined,
  erasureTimeLimitsUnit: undefined,
  addDataCategoryOpen: false,
  removeModalOpen: false
};

class DataCategoriesFormSection extends Component {
  state = defaultState;

  handleAddCategorySubmit() {
    this.props.onAdd({
      dataCategory: this.state.dataCategory,
      erasureTimeLimits: this.state.erasureTimeLimits,
      erasureTimeLimitsUnit: this.state.erasureTimeLimitsUnit
    });
    this.setState({ addDataCategoryOpen: false });
  }

  handleAddCategoryCancel() {
    this.setState(defaultState);
  }

  render() {
    const { t } = this.props;

    const timeUnits = ['YEARS', 'MONTHS', 'DAYS', 'HOURS'].map((tu) => ({
      val: tu,
      name: t('commons.timeunits.values.' + tu)
    }));

    const actions = [
      <Button children='Cancel' onClick={() => this.handleAddCategoryCancel()} />,
      <Button
        children='Submit'
        variant='contained'
        color='primary'
        disabled={this.state.dataCategory === undefined}
        onClick={() => this.handleAddCategorySubmit()}
      />
    ];

    return (
      <div>
        <h4>{t('processings.data_categories.header')}*</h4>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>{t('data_categories.label')}</TableCell>
              <TableCell>{t('processings.data_categories.fields.erasureTimeLimits')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody displayRowCheckbox={false}>
            {(this.props.associatedDataCategories || []).map((adc) => (
              <TableRow key={adc.dataCategory.uuid}>
                <TableCell>
                  <ChipItem
                    key={adc.dataCategory.uuid}
                    id={adc.dataCategory.uuid}
                    label={_.truncate(adc.dataCategory.name, {
                      length: 30,
                      omission: '...'
                    })}
                    onRequestDelete={() => this.props.onRemove(adc)}
                  />
                </TableCell>
                <TableCell>{`${adc.erasureTimeLimits || ''} ${
                  adc.erasureTimeLimitsUnit || ''
                }`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Button
          children={t('processings.data_categories.actions.add')}
          color='primary'
          onClick={() => this.setState({ addDataCategoryOpen: true })}
        />

        <Dialog fullWidth open={this.state.addDataCategoryOpen} modal={true} actions={actions}>
          <DialogTitle>{t('processings.data_categories.addDataCategory')}</DialogTitle>
          <DialogContent>
            <DataCategoryItemAutoComplete
              itemName={t('data_categories.label')}
              value={this.state.dataCategory}
              onChange={(val) =>
                this.setState({
                  dataCategory: val
                })
              }
            />
            <Grid container>
              <Grid item>
                <TextField
                  variant='standard'
                  placeholder={t('processings.data_categories.fields.erasureTimeLimitsHint')}
                  label={t('processings.data_categories.fields.erasureTimeLimits')}
                  multiLine
                  value={this.state.erasureTimeLimits || ''}
                  type='number'
                  onChange={(event) =>
                    this.setState({
                      erasureTimeLimits: event.target.value.replace(/\D/g, '')
                    })
                  }
                />
              </Grid>
              <Grid item>
                <ItemSelectField
                  itemName={t('processings.data_categories.fields.erasureTimeLimitsUnit')}
                  keyProperty={'val'}
                  nameProperty={'name'}
                  value={timeUnits.find((e) => e.val === this.state.erasureTimeLimitsUnit)}
                  values={timeUnits}
                  onChange={(obj) =>
                    this.setState({
                      erasureTimeLimitsUnit: obj && obj.val
                    })
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>{actions}</DialogActions>
        </Dialog>
      </div>
    );
  }
}

DataCategoriesFormSection.propTypes = {
  associatedDataCategories: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default withTranslation()(DataCategoriesFormSection);
