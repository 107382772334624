import * as React from "react";
const SvgGreenplum = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="#80bc00"
    viewBox="0 0 64 64"
    {...props}
  >
    <path d="M47.202 15.1c-9.35-8.126-23.5-7.258-31.776 1.95S7.76 40.42 16.824 48.865c2.118 1.904 3.915 2.83 5.5 2.83 3.07 0 5.362-3.432 8.325-7.762a68.95 68.95 0 0 1 5.912-7.883 70.88 70.88 0 0 1 7.2-6.703c3.807-3.204 6.797-5.724 6.85-8.553.027-1.716-1.1-3.58-3.42-5.684zM37.3 28.716a36.1 36.1 0 0 0-4.102 3.74 38.3 38.3 0 0 0-3.351 4.558c-1.515 2.292-2.587 3.94-4.022 3.94a2.04 2.04 0 0 1-1.341-.603 11.25 11.25 0 0 1 15.041-16.717 1.96 1.96 0 0 1 .778 1.528c-.08 1.153-1.287 2.158-3.003 3.553zM32.013 0C14.34-.007.007 14.313 0 31.987S14.313 63.993 31.987 64 63.993 49.687 64 32.013A32 32 0 0 0 32.013 0zM52.2 50.152c-10.035 11.136-27.196 12.036-38.34 2-11.258-10-12.33-27.197-2.396-38.505a27.35 27.35 0 0 1 38.491-2.587c11.357 9.877 12.6 27.073 2.78 38.48z" />
  </svg>
);
export default SvgGreenplum;
