import { buildApiCallAction } from 'actions/ActionUtils';
import {
  SEARCH_PHYSICAL_FIELDS,
  FETCH_PHYSICAL_FIELDS_PAGE,
  FETCH_PHYSICAL_FIELD,
  CREATE_PHYSICAL_FIELD,
  MODIFY_PHYSICAL_FIELD,
  DELETE_PHYSICAL_FIELD,
  EXPORT_PHYSICAL_FIELDS
} from 'constants/ActionTypes';
import PhysicalFieldsApi from 'api/PhysicalFieldsApi';

const api = new PhysicalFieldsApi();

export const searchPhysicalFields = ({ search }) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getPhysicalFields({ search }),
    actionType: SEARCH_PHYSICAL_FIELDS
  });

export const fetchPhysicalFieldsPage = ({ physicalEntityUuid, logicalFieldsUuids, page, size }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) =>
      api.getPhysicalFields({ physicalEntityUuid, logicalFieldsUuids, page, size }),
    actionType: FETCH_PHYSICAL_FIELDS_PAGE
  });

export const fetchPhysicalField = (physicalFieldUuid) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getPhysicalField(physicalFieldUuid),
    actionType: FETCH_PHYSICAL_FIELD
  });

export const createPhysicalField = (physicalField) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postPhysicalField(physicalField),
    actionType: CREATE_PHYSICAL_FIELD
  });

export const modifyPhysicalField = (physicalField) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.putPhysicalField(physicalField),
    actionType: MODIFY_PHYSICAL_FIELD
  });

export const deletePhysicalField = (physicalField) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.deletePhysicalField(physicalField),
    actionType: DELETE_PHYSICAL_FIELD,
    payload: physicalField
  });

export const exportPhysicalField = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.exportPhysicalField(params),
    actionType: EXPORT_PHYSICAL_FIELDS
  });
