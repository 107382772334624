import React from 'react';
import {
  fetchDataActor,
  deleteDataActor,
  modifyDataActor
} from '../../../actions/DataActorsActions';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Grid
} from '@mui/material';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import { withLoader } from '../../../components/loader/Loader';
import ConfirmButton from '../../../components/confirmbutton/ConfirmButton';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import DataActorAssignedLogins from '../commons/DataActorAssignedLogins';
import DataActorEditorModal from '../commons/DataActorEditorModal';
import { withOpenCloseHandler } from '../../../components/openclosehandler/OpenCloseHandler';
import HistoryButtonLink from '../../history/commons/HistoryButtonLink';
import HistoryTargetTypes from '../../history/commons/HistoryTargetTypes';
import TeamCodeDecorator from '../../settings/teams/common/TeamCodeDecorator';
import { compose } from 'redux';
import ResourceTypes from '../../../constants/ResourceTypes';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';
import AuthorizeTeam from 'components/permissions/AuthorizeTeam';
import TeamAccessLevels from 'constants/TeamAccessLevels';
import TaskAssignments from 'pages/tasks/detail/TaskAssignments';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import IssuesResourceWidget from 'pages/issueMngt/issues/commons/IssuesResourceWidget';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';
function DataActorDetailComponent({ t, dataActor, deleteDataActor, modifyDataActor, history }) {
  if (dataActor) {
    return (
      <React.Fragment>
        <Breadcrumbs
          elements={[{ text: 'Data Actors', to: '/data_actors' }, { text: dataActor.name }]}
        />
        <Tracking
          info={{
            pageTitle: `Data Actor: ${dataActor.name}`,
            category: EngagementCategories.DATA_ACTOR,
            action: EngagementActions.DETAIL
          }}
        />

        <Grid container justifyContent='flex-end' alignItems='center'>
          <Grid item style={{ flexGrow: 1 }}>
            <Typography style={{ marginBottom: '8px' }} variant='h4'>
              Data Actor: {dataActor.name}
            </Typography>
          </Grid>
          <Grid item>
            <FavoritesWidget
              itemIdentifier={`${ResourceTypes.DATA_ACTOR}:${dataActor.uuid}`}
              resourceIdentifier={dataActor.uuid}
              resourceType={ResourceTypes.DATA_ACTOR}
              resourceName={dataActor.name}
              pageTitle={`Data Actor: ${dataActor.name}`}
            />
          </Grid>
          <Grid item>
            <IssuesResourceWidget
              resourceType={ResourceTypes.DATA_ACTOR}
              resourceIdentifier={dataActor?.uuid}
              resourceName={dataActor?.name}
            />
          </Grid>
        </Grid>

        <Card>
          <CardHeader
            title={dataActor.name}
            subheader={dataActor.businessName}
            action={<TeamCodeDecorator teamCode={dataActor.teamCode} />}
          />
          <CardContent>
            <div>{dataActor.notes}</div>

            <h4>Roles</h4>
            <ChipWrapper>
              {dataActor.roles.map((role) => {
                return <ChipItem key={role} id={role} label={role} />;
              })}
            </ChipWrapper>
            <span className='dls-wrapper'>
              <KVDefinitionList
                header='Description'
                item={{
                  Code: dataActor.identifierCode,
                  'First Name': dataActor.firstName,
                  'Last Name': dataActor.lastName,
                  'Business Name': dataActor.businessName,
                  'Subject Type': dataActor.subjectType,
                  'Subject Description': dataActor.subjectDescription,
                  Country: dataActor.country
                }}
              />

              <KVDefinitionList
                header='Contacts'
                item={{
                  Email: dataActor.email,
                  Phone: dataActor.phone,
                  Fax: dataActor.fax
                }}
              />

              <KVDefinitionList
                header={'Address'}
                item={{
                  State: dataActor.addressState,
                  City: dataActor.addressCity,
                  'Street Address': dataActor.addressStreet,
                  'Postal Code': dataActor.addressPostalCode,
                  'Building Number': dataActor.addressBuildingNumber
                }}
              />

              <CustomPropertiesView
                additionalProperties={dataActor.additionalProperties}
                resourceType={ResourceTypes.DATA_ACTOR}
              />
            </span>
            <Authorize hasPermissions={[Permissions.SYSTEMS_VIEWER]}>
              <DataActorAssignedLogins dataActor={dataActor} />
            </Authorize>
            <Authorize hasPermissions={[Permissions.TASKS_VIEWER]}>
              <TaskAssignments dataActor={dataActor} />
            </Authorize>
          </CardContent>
          <CardActions>
            <AuthorizeTeam
              hasAnyPermissions={[Permissions.DATA_ACTORS_EDITOR, Permissions.PROPOSE]}
              teamCode={dataActor.teamCode}
              teamAccessLevels={[TeamAccessLevels.WRITE, TeamAccessLevels.PROPOSE]}>
              <DataActorModifyButton
                dataActor={dataActor}
                onModifyDataActor={(modified) => modifyDataActor(modified)}
              />
            </AuthorizeTeam>
            <Authorize hasPermissions={[Permissions.DATA_ACTORS_EDITOR]}>
              <ConfirmButton
                children={t('commons.actions.delete')}
                message={`Do you want to delete ${dataActor.name}?`}
                onClick={() =>
                  deleteDataActor(dataActor).then(() => history.replace('/data_actors'))
                }
              />
            </Authorize>
            <HistoryButtonLink
              targetType={[HistoryTargetTypes.DATA_ACTOR, HistoryTargetTypes.TASK_ASSIGNMENT]}
              targetIdentifier={dataActor.uuid}
            />
            <div style={{ marginLeft: 'auto' }}>
              <WebActionsButton resource={dataActor} page={TargetPages.DATA_ACTOR_DETAIL_PAGE} />
            </div>
          </CardActions>
        </Card>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

const _DataActorModifyButton = ({ t, dataActor, onModifyDataActor, onToggleOpen, isOpen }) => (
  <React.Fragment>
    <Button onClick={onToggleOpen}>{t('commons.actions.modify')}</Button>

    <DataActorEditorModal
      title='Modify Data Actor'
      key={dataActor.uuid}
      open={isOpen}
      dataActor={dataActor}
      handleSubmit={(dp) => {
        onToggleOpen();
        onModifyDataActor(dp);
      }}
      handleCancel={onToggleOpen}
    />
  </React.Fragment>
);

const DataActorModifyButton = _.flow([withOpenCloseHandler, withTranslation()])(
  _DataActorModifyButton
);

const mapStateToProps = (state, props) => ({
  dataActor: state.data_actors.contentByUuid[props.match.params['dataActorUuid']],
  dataActorUuid: props.match.params['dataActorUuid']
});

export default compose(
  connect(mapStateToProps, { fetchDataActor, deleteDataActor, modifyDataActor }),
  withLoader({
    onLoad: ({ fetchDataActor, dataActorUuid }) => fetchDataActor(dataActorUuid),
    ready: ({ dataActor }) => dataActor
  }),
  withTranslation(),
  withRouter
)(DataActorDetailComponent);
