import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from '@mui/material';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import FilterList from '@mui/icons-material/FilterList';
import ProcessingsSearchModal from '../../processings/utils/ProcessingSearchModal';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  setConsentsEventsLineChartFilters,
  clearConsentsEventsLineChartFilters,
  fetchConsentsEventsLineChart
} from '../../../actions/DashboardsActions';
import DatePicker, { hourAdjustments } from '../../../components/pickers/DatePicker';
import { HelpIcon } from '../../../components/help';
import { withTranslation } from 'react-i18next';

class ConsentsEventsLineChartFilters extends Component {
  state = {
    openFilters: false,
    openSearch: false
  };

  onToggleOpenSearch = () => this.setState({ openSearch: !this.state.openSearch });

  onToggleOpenFilters = () => this.setState({ openFilters: !this.state.openFilters });

  render() {
    let {
      hasFilters,
      onRefresh,
      processings,
      endDate,
      startDate,
      setFilters,
      clearFilters,
      fullScreen,
      t
    } = this.props;
    let { openSearch, openFilters } = this.state;
    let { onToggleOpenFilters, onToggleOpenSearch } = this;
    return (
      <React.Fragment>
        <IconButton
          color={hasFilters ? 'primary' : 'grey'}
          onClick={onToggleOpenFilters}
          size='large'>
          <FilterList />
        </IconButton>
        <Dialog fullWidth fullScreen={fullScreen} open={openFilters}>
          <DialogTitle>Consents Events Timeline Filters</DialogTitle>
          <DialogContent fullWidth>
            <Typography paragraph variant='body1'>
              Processings
              <HelpIcon tooltip='Restricts the analysis to the specified processings' />
            </Typography>
            <ChipWrapper onRequestAdd={onToggleOpenSearch}>
              {processings.map((processing) => (
                <ChipItem
                  key={processing.uuid}
                  id={processing.uuid}
                  label={processing.name}
                  tooltip={processing.description}
                  onRequestDelete={() =>
                    setFilters({
                      processings: processings.filter((p) => p.uuid !== processing.uuid)
                    })
                  }
                />
              ))}
            </ChipWrapper>
            <ProcessingsSearchModal
              open={openSearch}
              onCancel={onToggleOpenSearch}
              onSubmit={(p) => {
                setFilters({ processings: processings.concat([p]) });
                onToggleOpenSearch();
              }}
            />
            <Typography paragraph variant='body1' style={{ marginTop: 8 }}>
              Time window
              <HelpIcon tooltip='Selects the consents received in the specified time window' />
            </Typography>
            <DatePicker
              label='Start date'
              clearable={false}
              value={startDate}
              hourAdjustment={hourAdjustments.startOfDay}
              onChange={(startDate) =>
                startDate && startDate.length > 0
                  ? setFilters({ startDate })
                  : setFilters({ startDate: null })
              }
            />
            <DatePicker
              label='End date'
              hourAdjustment={hourAdjustments.endOfDay}
              value={endDate}
              onChange={(endDate) =>
                endDate && endDate.length > 0
                  ? setFilters({ endDate })
                  : setFilters({ endDate: null })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              children={t('commons.actions.clear')}
              onClick={() => {
                clearFilters();
                onToggleOpenFilters();
                onRefresh();
              }}
            />
            <Button
              variant='outlined'
              color='primary'
              children={t('commons.actions.apply')}
              onClick={() => {
                onToggleOpenFilters();
                onRefresh();
              }}
            />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ConsentsEventsLineChartFilters.propTypes = {
  hasFilters: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  processings: PropTypes.array,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
  hasFilters: state.dashboards.consentsEvents.lineChartFilters.hasFilters,
  processings: state.dashboards.consentsEvents.lineChartFilters.processings,
  startDate: state.dashboards.consentsEvents.lineChartFilters.startDate,
  endDate: state.dashboards.consentsEvents.lineChartFilters.endDate
});

const mapDispatchToProps = {
  onRefresh: fetchConsentsEventsLineChart,
  setFilters: setConsentsEventsLineChartFilters,
  clearFilters: clearConsentsEventsLineChartFilters
};

export default _.flow([withTranslation(), connect(mapStateToProps, mapDispatchToProps)])(
  ConsentsEventsLineChartFilters
);
