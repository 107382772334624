import { BlindataApi } from 'api/ApiUtils';

const CHECK_EVALUATION_URL = '/api/v1/data-quality/assessment/check-evaluations';

export default class QualityAssessmentCheckEvaluationApi extends BlindataApi {
  getCheckEvaluationsMatrix(checkUuid) {
    return this.get(`${CHECK_EVALUATION_URL}/checks/${checkUuid}`);
  }

  getCheckEvaluationsByPhysicalEntityAndRisk(physicalEntityUuid, riskUuid) {
    return this.get(CHECK_EVALUATION_URL, { physicalEntityUuid, riskUuid });
  }

  putCheckEvaluation(checkEvaluation) {
    let checkUuid = checkEvaluation?.qualityCheck?.uuid;
    let riskUuid = checkEvaluation?.risk?.uuid;
    return this.put(
      `${CHECK_EVALUATION_URL}/checks/${checkUuid}/risks/${riskUuid}`,
      checkEvaluation
    );
  }

  deleteCheckEvaluation(checkEvaluation) {
    let checkUuid = checkEvaluation?.qualityCheck?.uuid;
    let riskUuid = checkEvaluation?.risk?.uuid;
    return this.delete(`${CHECK_EVALUATION_URL}/checks/${checkUuid}/risks/${riskUuid}`);
  }

  exportCheckEvaluations() {
    return this.download(`${CHECK_EVALUATION_URL}/*/export`, {
      includeProperties: 'false'
    });
  }
}
