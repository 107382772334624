import React, { useState } from 'react';
import SqlVisualizer from 'components/syntaxhighlithter/SqlVisualizer';
import { Button, Card, CardContent, CardHeader, Grid, Tooltip, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import QueryStatementModal from 'pages/dataflows/queryparser/statements/commons/QueryStatementModal';
import { useDispatch } from 'react-redux';
import { createQueryStatementWithFeedback } from 'actions/QueryStatementsActions';
import _ from 'lodash';
import { RoutineManualExtractionContextMenu } from './RoutineManualExtractionContextMenu';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import { RoutineAnalysisModal } from '../commons/RoutineAnalysisModal';
import { analyzeSystemRoutine, confirmSystemRoutine } from 'actions/RoutinesActions';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';

function RoutineBodySection({
  systemRoutineName,
  systemRoutineSchema,
  systemRoutineUuid,
  systemRoutineAnalysisStatus,
  body
}) {
  const [contextMenu, setContextMenu] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [queryStatementModalOpen, setQueryStatementModalOpen] = useState(false);
  const [routineAnalysisModalOpen, setRoutineAnalysisModalOpen] = useState(false);
  const [currentQueryStatement, setCurrentQueryStatement] = useState();

  const dispatch = useDispatch();
  const selectedText = window.getSelection().toString();

  const currentLine = (currentText, allText) => {
    const lines = _(allText).split(currentText).head().split('\n').length;
    return lines;
  };

  const handleOpenContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: window.innerWidth / 4,
            mouseY: event.clientY - 6
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  if (!body) {
    return null;
  }

  const bodyStyle = {
    border: '1px solid',
    borderColor: 'lightgrey',
    borderRadius: '0.5em',
    padding: '1em'
  };

  return (
    <Card>
      <CardHeader
        title={'Routine Body Definition'}
        subheader={
          <Authorize hasPermissions={[Permissions.QUERY_PARSER_EDITOR]}>
            <Grid container spacing={1} alignItems='center' alignContent='center'>
              <Grid item>
                <Typography>Manual Statements Selection</Typography>
              </Grid>
              <Grid item>
                <Tooltip title='Select the statement manually using the pointer and then right click on the selection'>
                  <HelpOutlineIcon fontSize='small' />
                </Tooltip>
              </Grid>
              <Grid item lg={12} xs={12}>
                <ConfirmButton
                  disabled={systemRoutineAnalysisStatus !== 'PENDING'}
                  message='Do you want to set this System Routine to Analyzed?'
                  variant='outlined'
                  color='success'
                  endIcon={<CheckIcon />}
                  onClick={() => dispatch(confirmSystemRoutine(systemRoutineUuid))}>
                  Confirm
                </ConfirmButton>
              </Grid>
            </Grid>
          </Authorize>
        }
        action={[
          <Authorize hasPermissions={[Permissions.QUERY_PARSER_EDITOR]}>
            <Button
              onClick={() => setRoutineAnalysisModalOpen(true)}
              variant='outlined'
              color='primary'
              endIcon={<AutoFixHighIcon />}>
              Analyze All
            </Button>
          </Authorize>
        ]}
      />
      <CardContent>
        {body ? (
          <div
            onMouseDown={() => setIsSelected(true)}
            onContextMenu={handleOpenContextMenu}
            style={{
              overflow: 'auto',
              cursor: isSelected ? 'auto' : 'pointer'
            }}>
            <SqlVisualizer text={body} style={bodyStyle} rowNumber={!isSelected} />
            <Authorize hasPermissions={[Permissions.QUERY_PARSER_EDITOR]}>
              <RoutineManualExtractionContextMenu
                contextMenu={contextMenu}
                selectedText={selectedText}
                onClose={handleCloseContextMenu}
                onSubmit={() => {
                  setCurrentQueryStatement((currentQueryStatement) => ({
                    ...currentQueryStatement,
                    queryText: selectedText,
                    name: `${systemRoutineName}.${currentLine(selectedText, body)}`,
                    schema: systemRoutineSchema
                  }));
                  setQueryStatementModalOpen(true);
                }}
              />
              <QueryStatementModal
                title={'Insert New Query Statement'}
                open={queryStatementModalOpen}
                queryStatement={currentQueryStatement}
                onClose={() => {
                  setCurrentQueryStatement();
                  setQueryStatementModalOpen(false);
                }}
                onSubmit={(queryStatement) => {
                  dispatch(
                    createQueryStatementWithFeedback({
                      ...queryStatement,
                      systemRoutineUuid: systemRoutineUuid
                    })
                  );
                  setCurrentQueryStatement();
                  setQueryStatementModalOpen(false);
                }}
              />
              <RoutineAnalysisModal
                open={routineAnalysisModalOpen}
                onClose={() => setRoutineAnalysisModalOpen(false)}
                onSubmit={(analysisOptions) =>
                  dispatch(analyzeSystemRoutine(systemRoutineUuid, analysisOptions))
                }
              />
            </Authorize>
          </div>
        ) : (
          <Typography
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '300px'
            }}>
            No routine body defined
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default RoutineBodySection;
