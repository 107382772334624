import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import WorkspacesSelector from 'pages/social/favorites/workspaces/commons/WorkspacesSelector';

function FavoriteItemModal({ onSubmit, open, onCancel, item, workspaces }) {
  const [editor, setEditor] = useState(item || {});
  const { t } = useTranslation();
  return (
    <Dialog onClick={(event) => event.stopPropagation()} fullWidth maxWidth='sm' open={open}>
      <DialogTitle>Edit Favorite</DialogTitle>
      <DialogContent>
        <h4>Alias</h4>
        <TextField
          fullWidth
          label='Alias'
          value={editor.alias || ''}
          onChange={(event) => setEditor({ ...editor, alias: event.target.value })}
        />
        <h4>Workspace</h4>
        <WorkspacesSelector
          workspaces={workspaces}
          fromMove
          kp='uuid'
          np='name'
          workspace={editor.workspace}
          onChange={(workspace) =>
            setEditor({ ...editor, workspace: workspace })
          }></WorkspacesSelector>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
            setEditor({});
          }}>
          {t('commons.actions.cancel')}
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onSubmit(editor);
            setEditor({});
          }}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FavoriteItemModal;
