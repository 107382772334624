import { fetchPhysicalConstraintSchemas } from 'actions/PhysicalConstraintsActions';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const PhysicalConstraintSchemaAutoComplete = ({ value, onChange, ...props }) => {
  const [schemas, setSchemas] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPhysicalConstraintSchemas({ search: '' })).then((response) =>
      setSchemas(response.content.map((schema) => ({ schema: schema })))
    );
  }, [dispatch, setSchemas]);

  return (
    <ItemAutoComplete
      itemName='Schema'
      values={schemas}
      value={{ schema: value }}
      nameProperty={'schema'}
      onSearch={(inputValue) => {
        dispatch(fetchPhysicalConstraintSchemas({ search: inputValue })).then((response) =>
          setSchemas(response?.content?.map((schema) => ({ schema: schema })))
        );
        onChange({ schema: inputValue });
      }}
      onChange={(newValue) => onChange(newValue)}
      {...props}
    />
  );
};

export default PhysicalConstraintSchemaAutoComplete;
