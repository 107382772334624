import React from 'react';
import { modifySchedule, deleteSchedule } from 'actions/onagent/AgentScheduleActions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import ExpandableCard from 'components/expandable-card/ExpandableCard';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';

function AgentScheduleListItem({ jobSchedule, agent, modifySchedule, deleteSchedule }) {
  const { t } = useTranslation();

  return (
    <ExpandableCard
      title={jobSchedule.name}
      key={jobSchedule.uuid}
      subheader={jobSchedule.type}
      decorator={
        <VariationTypeDecorator>
          {jobSchedule.scheduled ? 'Scheduled' : 'Not Scheduled'}
        </VariationTypeDecorator>
      }
      actions={
        <>
          <Button
            children={t('commons.actions.detail')}
            component={Link}
            to={`${agent.uuid}/jobs/schedules/${jobSchedule.uuid}`}
          />
          {jobSchedule.scheduled ? (
            <Authorize hasPermissions={[Permissions.AGENTS_EDITOR]}>
              <ConfirmButton
                message={`Do you want to unschedule this job?`}
                onClick={() => modifySchedule(agent, { ...jobSchedule, scheduled: false })}
                children={'Unschedule'}
              />
            </Authorize>
          ) : (
            <Authorize hasPermissions={[Permissions.AGENTS_EDITOR]}>
              <ConfirmButton
                message={`Do you want to schedule this job?`}
                onClick={() => modifySchedule(agent, { ...jobSchedule, scheduled: true })}
                children={'Schedule'}
              />
            </Authorize>
          )}
          <Authorize hasPermissions={[Permissions.AGENTS_EDITOR]}>
            <ConfirmButton
              message={`Do you want to delete: ${jobSchedule.name}`}
              onClick={() => deleteSchedule(agent, jobSchedule)}
              children={t('commons.actions.delete')}
            />
          </Authorize>
        </>
      }>
      <KVDefinitionList
        item={{
          'Cron Expression': jobSchedule.cronExpression,
          Timezone: jobSchedule.timezoneId
        }}></KVDefinitionList>
    </ExpandableCard>
  );
}

export default connect(null, { modifySchedule, deleteSchedule })(AgentScheduleListItem);
