import { BlindataApi } from './ApiUtils';

const QUALITY_CHECKS_URL = '/api/v1/data-quality/checks';

export default class QualityChecksApi extends BlindataApi {
  getQualityCheck(uuid) {
    return this.get(`${QUALITY_CHECKS_URL}/${uuid}`);
  }

  getQualityChecks(params) {
    return this.get(QUALITY_CHECKS_URL, params);
  }

  postQualityCheck(qualityCheck) {
    return this.post(QUALITY_CHECKS_URL, qualityCheck);
  }

  putQualityCheck(qualityCheck) {
    return this.put(`${QUALITY_CHECKS_URL}/${qualityCheck.uuid}`, qualityCheck);
  }

  deleteQualityCheck(uuid) {
    return this.delete(`${QUALITY_CHECKS_URL}/${uuid}`);
  }

  updateChecksOptionsEnabled(params) {
    return this.put(`${QUALITY_CHECKS_URL}/*/options/enabled`, params);
  }

  exportQualityCheck(params) {
    return this.download(`${QUALITY_CHECKS_URL}/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }

  getCheckGroupCode(params) {
    return this.get(`${QUALITY_CHECKS_URL}/*/checkGroupCode`, {
      ...params,
      includeProperties: 'false'
    });
  }
}
