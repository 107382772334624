import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DialogContent, DialogActions, DialogTitle } from '@mui/material';

const initialState = {
  uuid: undefined,
  username: undefined
};

class SystemLoginEditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = props.systemLogin ? { ...props.systemLogin } : initialState;
  }

  componentWillReceiveProps(props) {
    if (props.systemLogin) {
      this.setState({ ...props.systemLogin });
    } else {
      this.setState(initialState);
    }
  }

  render() {
    const actions = [
      <Button children='Cancel' onClick={this.props.handleCancel} />,
      <Button
        children='Submit'
        variant='contained'
        color='primary'
        disabled={this.username && 1}
        onClick={() => this.props.handleSubmit(this.state)}
      />
    ];

    return (
      <Dialog
        title={this.props.title}
        actions={actions}
        fullScreen={this.props.fullScreen}
        open={this.props.open}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <TextField
            variant='standard'
            placeholder='Enter the username'
            label='Name'
            value={this.state.username || ''}
            onChange={(event) => this.setState({ username: event.target.value })}
          />
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

SystemLoginEditorModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  username: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default _.flow([])(SystemLoginEditorModal);
