import { Card, CardContent, CardHeader, TablePagination, Typography } from '@mui/material';
import { fetchQueryStatementsPage } from 'actions/QueryStatementsActions';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import QueryStatementExportButton from 'pages/dataflows/queryparser/statements/commons/QueryStatementExportButton';
import QueryStatementsCardTable from 'pages/dataflows/queryparser/statements/list/QueryStatementsCardTable';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RoutineExtractedStatementsDeleteButton from '../commons/RoutineExtractedStatementsDeleteButton';

const RoutineExtractedStatementsSection = ({ systemRoutine }) => {
  const currentPage = useSelector((state) => state.dataflows.querystatements.currentPage);
  const dispatch = useDispatch();

  const fetchStatements = (param) => dispatch(fetchQueryStatementsPage(param));

  useEffect(() => {
    dispatch(
      fetchQueryStatementsPage({
        page: 0,
        systemRoutineUuid: systemRoutine?.uuid
      })
    );
  }, [dispatch, systemRoutine]);

  return (
    <Card>
      <CardHeader
        title={
          <>
            Extracted Statements
            <Authorize hasPermissions={[Permissions.QUERY_PARSER_EDITOR]}>
              <RoutineExtractedStatementsDeleteButton systemRoutineUuid={systemRoutine?.uuid} />
            </Authorize>
          </>
        }
        action={<QueryStatementExportButton systemRoutineUuid={systemRoutine?.uuid} />}
      />
      <CardContent>
        {currentPage.content && currentPage.content.length > 0 ? (
          <>
            <QueryStatementsCardTable
              queryStatements={currentPage.content}
              onRefresh={() =>
                fetchStatements({
                  page: currentPage.number,
                  size: currentPage.size,
                  systemRoutineUuid: systemRoutine?.uuid
                })
              }
            />
            <TablePagination
              component='div'
              style={{ overflowX: 'hidden' }}
              rowsPerPageOptions={[10, 20, 50]}
              page={currentPage.number}
              rowsPerPage={currentPage.size}
              count={currentPage.totalElements}
              onRowsPerPageChange={(event) =>
                fetchStatements({
                  page: currentPage.number,
                  size: event.target.value,
                  systemRoutineUuid: systemRoutine?.uuid
                })
              }
              onPageChange={(_event, page) =>
                fetchStatements({
                  page: page,
                  size: currentPage.size,
                  systemRoutineUuid: systemRoutine?.uuid
                })
              }
            />
          </>
        ) : (
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>No query statement has been extracted</Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default RoutineExtractedStatementsSection;
