import React from 'react';
import { Card, CardHeader, CardContent, CardActions, IconButton, Button } from '@mui/material';

import PropertiesTable from './PropertiesTable';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';

const ComposableTableCard = ({
  title,
  onAdd,
  onRemove,
  onEdit,
  onRowsMoved,
  classes,
  additionalContent,
  ...propertiesTableProps
}) => (
  <Card
    sx={{
      margin: '8px 0px 8px 0px'
    }}>
    <CardHeader title={title} action={onAdd ? <AddAction {...{ onAdd }} /> : null} />
    <CardContent>
      <PropertiesTable {...{ ...propertiesTableProps }} />
      {additionalContent}
    </CardContent>
    <CardActions>
      {onEdit ? (
        <Button disabled={cannotEdit(propertiesTableProps)} onClick={onEdit}>
          Edit
        </Button>
      ) : null}
      {onRemove ? (
        <Button
          disabled={cannotRemove(propertiesTableProps)}
          onClick={remove(propertiesTableProps, onRemove)}>
          Remove
        </Button>
      ) : null}
      {onRowsMoved ? (
        <Button
          disabled={cannotMoveUp(propertiesTableProps)}
          onClick={moveUp(propertiesTableProps, onRowsMoved)}>
          Move Up
        </Button>
      ) : null}
      {onRowsMoved ? (
        <Button
          disabled={cannotMoveDown(propertiesTableProps)}
          onClick={moveDown(propertiesTableProps, onRowsMoved)}>
          Move Down
        </Button>
      ) : null}
    </CardActions>
  </Card>
);

const cannotEdit = ({ selectedIndex }) => selectedIndex == null;

const cannotRemove = ({ selectedIndex }) => selectedIndex == null;

const cannotMoveUp = ({ selectedIndex, rows }) => selectedIndex == null || selectedIndex <= 0;

const cannotMoveDown = ({ selectedIndex, rows }) =>
  selectedIndex == null || selectedIndex >= rows.length - 1;

const moveUp =
  ({ selectedIndex, rows }, onRowsMoved) =>
  () => {
    let newSelectedIndex = selectedIndex - 1;
    onRowsMoved(newMovedRows(rows, selectedIndex, newSelectedIndex), newSelectedIndex);
  };

const remove =
  ({ selectedIndex, rows }, onRemove) =>
  () => {
    let newRows = [...rows.slice(0, selectedIndex), ...rows.slice(selectedIndex + 1)];
    onRemove(rows[selectedIndex], rows, newRows);
  };

export const addOrEditHelper = (rows, newElement, selectedIndex) => {
  let index = selectedIndex == null ? rows.length : selectedIndex;
  var newRows = [...rows];
  newRows[index] = newElement;
  return newRows;
};

const moveDown =
  ({ selectedIndex, rows }, onRowsMoved) =>
  () => {
    let newSelectedIndex = selectedIndex + 1;
    onRowsMoved(newMovedRows(rows, selectedIndex, newSelectedIndex), newSelectedIndex);
  };

function newMovedRows(array, fromIndex, toIndex) {
  var copiedArray = [...array];
  var tmp = copiedArray[toIndex];
  copiedArray[toIndex] = copiedArray[fromIndex];
  copiedArray[fromIndex] = tmp;
  return copiedArray;
}

const AddAction = ({ onAdd }) => (
  <IconButton style={{ marginLeft: 32 }} onClick={onAdd} size='large'>
    <AddCircleIcon />
  </IconButton>
);

export default ComposableTableCard;
