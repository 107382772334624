import React from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Typography,
  Checkbox
} from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import { useTranslation } from 'react-i18next';
import IssuePoliciesTableActions from './IssuePoliciesTableActions';

const IssuesPoliciesTable = ({ policies, pagination, handleFetch }) => {
  const { t } = useTranslation();

  return policies.length > 0 ? (
    <>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Resource Name</TableCell>
            <TableCell>Resource Type</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {policies.map((policy) => (
            <TableRow key={policy.uuid}>
              <TableCell>{policy.name}</TableCell>
              <TableCell>{t(`issueMngt.issuesPolicies.types.${policy.policyType}`)}</TableCell>
              <TableCell>{policy.resourceName}</TableCell>
              <TableCell>{policy.resourceType}</TableCell>
              <TableCell>
                <Checkbox size='small' disabled checked={policy.active}></Checkbox>
              </TableCell>
              <TableCell>
                <IssuePoliciesTableActions policy={policy}></IssuePoliciesTableActions>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        rowsPerPageOptions={[10, 20, 50]}
        page={pagination.page}
        rowsPerPage={pagination.size}
        count={pagination.totalElements}
        onPageChange={(event, page) => handleFetch(page, pagination.size)}
        onRowsPerPageChange={(event) => handleFetch(pagination.page, event.target.value)}
      />
    </>
  ) : (
    <div style={{ textAlign: 'center', padding: '2%' }}>
      <Typography variant='body1'>No policies</Typography>
    </div>
  );
};

IssuesPoliciesTable.propTypes = {
  policies: PropTypes.array.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    totalElements: PropTypes.number.isRequired
  }).isRequired
};

export default IssuesPoliciesTable;
