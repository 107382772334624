import React from 'react';
import PropTypes from 'prop-types';
import { CardActions } from '@mui/material';
import PhysicalFieldDetailsCard from 'pages/systems/physicalfields/detail/PhysicalFieldDetailsCard';

function PhysicalFieldCardView({ physicalField }) {
  if (physicalField) {
    return <PhysicalFieldDetailsCard physicalField={physicalField}></PhysicalFieldDetailsCard>;
  } else {
    return null;
  }
}

PhysicalFieldCardView.propTypes = {
  logicalField: PropTypes.object,
  actions: PropTypes.instanceOf(CardActions)
};

export default PhysicalFieldCardView;
