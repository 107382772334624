import { buildApiCallAction } from '../../ActionUtils';
import QualityAssessmentRiskDefinitionsApi from 'api/quality/assessment/QualityAssessmentRiskDefinitionsApi';
import {
  FETCH_RISK_DEFINITIONS_PAGE,
  FETCH_RISK_DEFINITION,
  CREATE_RISK_DEFINITION,
  MODIFY_RISK_DEFINITION,
  DELETE_RISK_DEFINITION,
  FETCH_RISK_CATEGORIES,
  EXPORT_RISK_DEFINITIONS
} from '../../../constants/ActionTypes';

const qualityAssessmentRiskDefinitionsApi = new QualityAssessmentRiskDefinitionsApi();

export const fetchRiskDefinitionsPage = (params) =>
  buildApiCallAction({
    api: qualityAssessmentRiskDefinitionsApi,
    apiFunction: (api) => api.getRiskDefinitions(params),
    actionType: FETCH_RISK_DEFINITIONS_PAGE
  });

export const fetchRiskDefinition = (uuid) =>
  buildApiCallAction({
    api: qualityAssessmentRiskDefinitionsApi,
    apiFunction: (api) => api.getRiskDefinition(uuid),
    actionType: FETCH_RISK_DEFINITION
  });

export const createRiskDefinition = (riskDefinition) =>
  buildApiCallAction({
    api: qualityAssessmentRiskDefinitionsApi,
    apiFunction: (api) => api.postRiskDefinition(riskDefinition),
    actionType: CREATE_RISK_DEFINITION
  });

export const modifyRiskDefinition = (riskDefinition) =>
  buildApiCallAction({
    api: qualityAssessmentRiskDefinitionsApi,
    apiFunction: (api) => api.putRiskDefinition(riskDefinition),
    actionType: MODIFY_RISK_DEFINITION
  });

export const deleteRiskDefinition = (riskDefinition) =>
  buildApiCallAction({
    api: qualityAssessmentRiskDefinitionsApi,
    apiFunction: (api) => api.deleteRiskDefinition(riskDefinition.uuid),
    actionType: DELETE_RISK_DEFINITION
  });

export const fetchRiskCategories = () =>
  buildApiCallAction({
    api: qualityAssessmentRiskDefinitionsApi,
    apiFunction: (api) => api.getRiskCategories(),
    actionType: FETCH_RISK_CATEGORIES
  });

export const exportRisks = (params) =>
  buildApiCallAction({
    api: qualityAssessmentRiskDefinitionsApi,
    apiFunction: (api) => api.exportRisks(params),
    actionType: EXPORT_RISK_DEFINITIONS
  });
