import * as React from "react";
const SvgMicrosoftAzureAnalysisServices = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 298 231"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="1em"
    height="1em"
    {...props}
  >
    <use xlinkHref="#MicrosoftAzureAnalysisServices_svg__a" x={0.5} y={0.5} />
    <symbol id="MicrosoftAzureAnalysisServices_svg__a" overflow="visible">
      <g stroke="none" fill="#5ab4d9">
        <path d="M114.033 62 122 64.5V0H0v93.75l60.253.25-1.494-9H9l-.035-65h105.067zM123 94V80l-8-3v8H74l1.5 9zm59.967 34L175 132.5V54h122v94H175.996l13.442-9H288V74H182.967zm-116.155 8 3.486 20H28.967v65H134v-51.156l9-5.094V230H21v-94zM82 136l3.5 20H130l14-7.805V136z" />
        <circle cx={61.5} cy={53} r={15.5} />
        <path d="m221.75 107.5-149-47 3-6.25 148 47.25zm-129 80.5 134-72-3-6.25-133 72.25zm-14.5-9-18-112 6.5-1 17.5 112z" />
        <circle cx={83} cy={189.5} r={15.5} />
        <circle cx={237.25} cy={107} r={15.5} />
      </g>
    </symbol>
  </svg>
);
export default SvgMicrosoftAzureAnalysisServices;
