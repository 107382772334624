import React from 'react';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import { Typography } from '@mui/material';
import ProfileContent from './ProfileContent';

const ProfilePage = () => (
  <div>
    <Breadcrumbs elements={[{ text: 'Settings', to: '/settings' }, { text: 'Profile' }]} />
    <Tracking
      info={{
        pageTitle: 'Profile Page',
        category: EngagementCategories.SETTINGS,
        action: EngagementActions.DETAIL
      }}
    />
    <Typography variant='h4'>Profile</Typography>
    <ProfileContent />
  </div>
);

export default ProfilePage;
