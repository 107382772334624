import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import Permissions from 'constants/Permissions';
import withPermissions from 'components/permissions/withPermissions';

import { createRiskDefinition } from 'actions/quality/assessment/QualityAssessmentRiskDefinitionsActions';

import { Tooltip, IconButton } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

import RiskDefinitionModal from 'pages/quality/assessment/risks/detail/RiskDefinitionModal';

const RiskDefinitionAddButton = ({ selectedCategory, createRiskDefinition }) => {
  const history = useHistory();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title={'Create a risk definition'}>
        <IconButton onClick={() => setOpen(true)} size='small'>
          <AddBoxIcon></AddBoxIcon>
        </IconButton>
      </Tooltip>
      {open && (
        <RiskDefinitionModal
          title='Create a Risk Definition'
          open={open}
          category={selectedCategory === 'All Categories' ? null : selectedCategory}
          handleSubmit={(riskDefinition) => {
            createRiskDefinition(riskDefinition).then(() => {
              setOpen(false);
              history.push(history.location.pathname + '?tab=' + riskDefinition.category);
            });
          }}
          handleCancel={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default compose(
  withPermissions({ hasPermissions: [Permissions.QUALITY_ASSMNT_ADMIN] }),
  connect(null, { createRiskDefinition })
)(RiskDefinitionAddButton);
