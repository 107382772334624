import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AutoComplete from '../autocomplete/AutoComplete';
import {
  List,
  ListItem,
  DialogActions,
  DialogContent,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  DialogTitle
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';

const INITIAL_STATE = {
  addItemOpen: false
};

export default class TextFieldsListEditor extends Component {
  state = INITIAL_STATE;

  render() {
    var addButtonDisabled = this.props.textFields
      .filter((tf) => tf.enabler)
      .map((tf) => this.state[tf.property])
      .some((value) => value === undefined);
    return (
      <div style={{ marginTop: '8px', ...this.props.style }}>
        <h4>{this.props.headerTitle}</h4>
        <List dense disablePadding>
          {(this.props.items || []).map((e) => (
            <ListItem key={e[this.props.textFields[0].property]} disableGutters>
              <ListItemText
                primary={this.labelText(e, this.props.textFields[0])}
                secondary={e[this.props.textFields[1].property]}
              />
              <ListItemSecondaryAction>
                <IconButton size='small' onClick={() => this.props.onDelete(e)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Button
          children={this.props.addLabel || 'Add item'}
          color='primary'
          onClick={() => this.setState({ addItemOpen: true })}
        />
        <Dialog contentStyle={{ maxWidth: '400px' }} open={this.state.addItemOpen}>
          <DialogTitle>{this.props.headerTitle}</DialogTitle>
          <DialogContent>
            {this.props.textFields.map((tf) => {
              if (tf.suggestions == null) {
                return (
                  <TextField
                    variant="standard"
                    key={tf.property}
                    placeholder={tf.hintText}
                    margin='dense'
                    label={tf.floatingLabelText}
                    fullWidth
                    value={this.state[tf.property] || ''}
                    onChange={(event) => this.setState({ [tf.property]: event.target.value })} />
                );
              } else {
                return (
                  <AutoComplete
                    key={tf.property}
                    placeholder={tf.hintText}
                    label={tf.floatingLabelText}
                    fullWidth
                    dataSource={tf.suggestions || []}
                    dataSourceConfig={tf.suggestionsConfig}
                    value={this.computeSearchTextFromSuggestions(
                      tf.suggestions,
                      this.state[tf.property]
                    )}
                    onUpdateInput={(newValue, dataSource) => {
                      let dataSourceElement = _(dataSource).find({ text: newValue });
                      let dataSourceValue = dataSourceElement && dataSourceElement.value;
                      this.setState({ [tf.property]: dataSourceValue || newValue });
                    }}
                  />
                );
              }
            })}
          </DialogContent>
          <DialogActions>
            <Button
              children='Cancel'
              primary={false}
              onClick={() => this.setState(INITIAL_STATE)}
            />
            <Button
              children='Add'
              color='primary'
              disabled={addButtonDisabled}
              onClick={() => {
                this.props.onAdd(this.state);
                this.setState(INITIAL_STATE);
              }}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  computeSearchTextFromSuggestions(suggestions, value) {
    if (!value) {
      return '';
    } else {
      let dataSourceElement = _(suggestions).find({ value: value });
      let dataSourceElementText = dataSourceElement && dataSourceElement.text;
      return dataSourceElementText || value;
    }
  }

  labelText(item, tf) {
    let suggestions = tf.suggestions;
    let value = item[tf.property];
    let text = this.computeSearchTextFromSuggestions(suggestions, value);
    return text !== value ? `${text} (${value})` : value;
  }
}

TextFieldsListEditor.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  addLabel: PropTypes.string,
  items: PropTypes.array.isRequired,
  textFields: PropTypes.arrayOf(
    PropTypes.shape({
      hintText: PropTypes.string,
      floatingLabelText: PropTypes.string,
      property: PropTypes.string.isRequired,
      enabler: PropTypes.bool,
      suggestions: PropTypes.array,
      suggestionsConfig: PropTypes.object
    })
  )
};
