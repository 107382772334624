import React from 'react';
import AddFAB from '../../../../components/fab/AddFAB';
import { connect } from 'react-redux';
import { createRole } from '../../../../actions/StewardshipActions';
import StewardshipRoleModal from '../commons/StewardshipRoleModal';
import { useHistory } from 'react-router';

function StewardshipRoleCreateFAB({ onCreate }) {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      <StewardshipRoleModal
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(stewardshipRole) =>
          onCreate(stewardshipRole).then((stewardshipRole) => {
            if (stewardshipRole) {
              setOpen(false);
              history.push(`/stewardship/roles/${stewardshipRole.uuid}`);
            }
          })
        }></StewardshipRoleModal>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  onCreate: createRole
};

export default connect(mapStateToProps, mapDispatchToProps)(StewardshipRoleCreateFAB);
