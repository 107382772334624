export const ProposalVariationTypes = {
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
  MERGE: 'MERGE'
};

export const ProposalResourceTypes = {
  DATA_CATEGORY: 'DATA_CATEGORY',
  PROCESSING: 'PROCESSING',
  TASK: 'TASK',
  DATA_ACTOR: 'DATA_ACTOR',
  TASK_ASSIGNMENT: 'TASK_ASSIGNMENT'
};

export const ProposalStatus = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
  CANCELED: 'CANCELED',
  APPROVED: 'APPROVED',
  ERROR: 'ERROR',
  REFUSED: 'REFUSED'
};
