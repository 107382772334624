import React from 'react';
import PaginatedPanel from '../../../../components/pagination/PaginatedPanel';
import { TemplatesListItem } from "./TemplatesListItem";

export const TemplatesList = ({ page, fetchContractTemplatesPage, deleteContractTemplate }) => (
<PaginatedPanel currentPage={page} onPageSelection={fetchContractTemplatesPage} autoload>
  {page.content.map(template => (
  <TemplatesListItem key={template.uuid} {...{ template, deleteContractTemplate }} />
  ))}
</PaginatedPanel>
);