import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Link as MaterialLink
} from '@mui/material';
import { t } from 'i18next';
import TableCell from 'components/tables/GenericTableCell';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import { Link } from 'react-router-dom';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import _ from 'lodash';

export const AvailableWebActionsModal = ({
  open,
  setOpen,
  availableWebActions,
  setWebAction,
  setWebActionExecutionOpen
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth='md'>
      <DialogTitle>{t('webActions.availableModal.title')}</DialogTitle>
      <DialogContent>
        <Table size='small'>
          <TableHead>
            <TableCell>{t('webActions.availableModal.name')}</TableCell>
            <TableCell>{t('webActions.availableModal.description')}</TableCell>
            <TableCell>{t('webActions.availableModal.agent')}</TableCell>
            <TableCell align='right'></TableCell>
          </TableHead>
          <TableBody>
            {availableWebActions.map((webAction) => (
              <TableRow key={webAction}>
                <TableCell>
                  <Typography>
                    <Link
                      to={`/settings/web-actions/${webAction.uuid}`}
                      style={{ textDecoration: 'none' }}
                      onClick={(event) => event.stopPropagation()}>
                      <MaterialLink underline='hover' style={{ color: 'black' }}>
                        {webAction.name || ''}
                      </MaterialLink>
                    </Link>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='description'>
                    {_.truncate(webAction?.description, { length: 50 })}
                  </Typography>
                </TableCell>
                <TableCell>
                  <VariationTypeDecorator>{webAction?.agent?.name}</VariationTypeDecorator>
                </TableCell>
                <TableCell align='right'>
                  <Button
                    variant='outlined'
                    color='primary'
                    endIcon={<PlayCircleOutlineIcon />}
                    onClick={() => {
                      setWebAction(webAction);
                      setWebActionExecutionOpen(true);
                    }}>
                    {t('webActions.availableModal.executeButton')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} children={t('commons.actions.close')} />
      </DialogActions>
    </Dialog>
  );
};
