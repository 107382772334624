import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, CardContent, CardHeader, CircularProgress } from '@mui/material';
import TableDiagram from 'components/flow/TableDiagram';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQueryStatementFullLineage } from 'actions/QueryStatementsActions';

function computeStyledGraph({ nodes, edges, queryStatement }) {
  const nodeIdGenerator = (node) =>
    `${node.systemUuid}.${node.statementUuid}.${node.schema}.${node.tableName}.${node.nodeType}.${node.alias}`;
  let styledNodes = _(nodes)
    .groupBy(nodeIdGenerator)
    .mapValues((nodes, key) => {
      return {
        id: key,
        type: 'tableNode',
        data: {
          name: nodes[0].tableName,
          schema: nodes[0].schema,
          systemUuid: nodes[0].systemUuid,
          systemName: nodes[0].systemName,
          nodeType: nodes[0].nodeType,
          notFoundInCatalog: nodes.reduce(
            (prev, current) => prev.notFoundInCatalog || current.notFoundInCatalog,
            { notFoundInCatalog: false }
          ),
          isExternalTargetTable:
            nodes[0].nodeType === 'TARGET_TABLE' && queryStatement.connectedToTarget,
          columns: nodes
            .sort((a, b) => a.positionIndex - b.positionIndex)
            .map((n) => ({ name: n.columnName }))
        }
      };
    })
    .values()
    .value();
  let nodesByID = _.keyBy(nodes, 'sequenceId');
  let styledEdges = edges.map((e) => ({
    id: e.sequenceId,
    source: nodeIdGenerator(nodesByID[e.from]),
    sourceHandle: `out.${nodesByID[e.from].columnName}`,
    target: nodeIdGenerator(nodesByID[e.to]),
    targetHandle: `in.${nodesByID[e.to].columnName}`,
    animated: true
  }));
  return { nodes: styledNodes, edges: styledEdges };
}

export default function QueryStatementFullLineageCard({ queryStatement }) {
  const dispatch = useDispatch();
  const loadedGraph = useSelector(
    (state) => state.dataflows.querystatements.fullLineage[queryStatement.uuid]
  );
  const parentRef = useRef(null);
  const [size, setSize] = useState(null);

  // useEffect will run on parentRef value assignment
  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (parentRef.current) {
      let height = parentRef.current.offsetHeight;
      let width = parentRef.current.offsetWidth;
      setSize({ height, width });
    }
  }, [parentRef, setSize]);

  useEffect(() => {
    dispatch(fetchQueryStatementFullLineage(queryStatement.uuid));
  }, [queryStatement, dispatch]);

  const styledGraph = useMemo(() => {
    return loadedGraph
      ? computeStyledGraph({
          nodes: loadedGraph.nodes,
          edges: loadedGraph.edges,
          queryStatement: queryStatement
        })
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedGraph]);

  return (
    <Card ref={parentRef}>
      <CardHeader title='Full Lineage'></CardHeader>
      {!size || !styledGraph ? (
        <CircularProgress></CircularProgress>
      ) : (
        <CardContent style={{ overflowX: 'auto', height: 800, width: size.width }} ref={parentRef}>
          <TableDiagram
            key={styledGraph.nodes}
            initialNodes={styledGraph.nodes}
            initialEdges={styledGraph.edges}></TableDiagram>
        </CardContent>
      )}
    </Card>
  );
}
