import React from 'react';
import { IconButton, TableCell } from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchAssignmentsPage } from '../../../../actions/AssignmentsActions';

function AssignmentsStateButton({
  t,
  assignment,
  patch,
  onPageSelection,
  page,
  rowsPerPage,
  filters
}) {
  const handleStatusVariation = (assignmentSequenceId, assignmentNewState) => {
    patch({ sequenceId: assignmentSequenceId, assignmentState: assignmentNewState }).then(() =>
      onPageSelection({
        page: page,
        size: rowsPerPage,
        filters: filters
      })
    );
  };

  return (
    <TableCell align='center'>
      <IconButton
        aria-label='confirmed'
        size='small'
        style={{
          color:
            assignment.assignmentState === t('classification.assignments.state.confirmed')
              ? 'green'
              : '#a2b9bc'
        }}
        onClick={() =>
          handleStatusVariation(
            assignment.sequenceId,
            t('classification.assignments.state.confirmed')
          )
        }>
        <ThumbUpAltIcon />
      </IconButton>
      <IconButton
        aria-label='pending'
        size='small'
        style={{
          color:
            assignment.assignmentState === t('classification.assignments.state.pending')
              ? '#feb236'
              : '#a2b9bc'
        }}
        onClick={() =>
          handleStatusVariation(
            assignment.sequenceId,
            t('classification.assignments.state.pending')
          )
        }>
        <HelpOutlineIcon />
      </IconButton>
      <IconButton
        aria-label='refused'
        size='small'
        disabled={false}
        style={{
          color:
            assignment.assignmentState === t('classification.assignments.state.refused')
              ? 'red'
              : '#a2b9bc'
        }}
        onClick={() =>
          handleStatusVariation(
            assignment.sequenceId,
            t('classification.assignments.state.refused')
          )
        }>
        <ThumbDownAltIcon />
      </IconButton>
    </TableCell>
  );
}

AssignmentsStateButton.propTypes = {
  t: PropTypes.func.isRequired,
  assignment: PropTypes.object.isRequired,
  patch: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    onPageSelection: (props) => dispatch(fetchAssignmentsPage(props))
  };
}

function mapStateToProps(state, props) {
  return {
    page: state.classification.assignments.currentPage.number,
    rowsPerPage: state.classification.assignments.currentPage.size,
    filters: props.filters || state.classification.assignments.filters
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AssignmentsStateButton);
