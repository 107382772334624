import {
  FETCH_QUALITY_ASSESSMENT_RESIDUAL_RISK_TABLE_DASHBOARD,
  FETCH_QUALITY_ASSESSMENT_RESIDUAL_RISK_COUNTER_DASHBOARD,
  EXPORT_RESIDUAL_RISK_TABLE
} from 'constants/ActionTypes';
import { buildApiCallAction } from 'actions/ActionUtils';
import QualityAssessmentDashboardsApi from 'api/quality/assessment/QualityAssessmentDashboardsApi';

const qualityAssessmentDashboardsApi = new QualityAssessmentDashboardsApi();

export const fetchResidualRiskCounterDashbordData = () =>
  buildApiCallAction({
    api: qualityAssessmentDashboardsApi,
    apiFunction: (api) => api.getResidualRiskCounter(),
    actionType: FETCH_QUALITY_ASSESSMENT_RESIDUAL_RISK_COUNTER_DASHBOARD
  });

export const fetchResidualRisksTableDashboardData = () =>
  buildApiCallAction({
    api: qualityAssessmentDashboardsApi,
    apiFunction: (api) => api.getResidualRiskTable(),
    actionType: FETCH_QUALITY_ASSESSMENT_RESIDUAL_RISK_TABLE_DASHBOARD
  });

export const exportResidualRisksTable = () =>
  buildApiCallAction({
    api: qualityAssessmentDashboardsApi,
    apiFunction: (api) => api.exportResidualRisksTable(),
    actionType: EXPORT_RESIDUAL_RISK_TABLE
  });