import React from 'react';
import { Table, TableHead, TableRow, TableBody, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from 'components/tables/GenericTableCell';

function TemplateParamsModalTable({ editor, setEditor }) {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Mode</TableCell>
          <TableCell>Default Value</TableCell>
          <TableCell>Mandatory</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(editor?.templateParams?.params).map((paramName) => (
          <TableRow key={paramName}>
            <TableCell>{paramName ? paramName : ''}</TableCell>
            <TableCell>{editor?.templateParams?.params[paramName]?.mode}</TableCell>
            <TableCell>{editor?.templateParams?.params[paramName]?.defaultValue}</TableCell>
            <TableCell>
              {editor?.templateParams?.params[paramName]?.mandatory
                ? editor?.templateParams?.params[paramName]?.mandatory.toString()
                : ''}
            </TableCell>
            <TableCell>
              <IconButton
                size='small'
                onClick={() =>
                  setEditor({
                    ...editor,
                    templateParams: {
                      ...editor?.templateParams,
                      params: delete editor?.templateParams?.params[paramName] && {
                        ...editor?.templateParams?.params
                      }
                    }
                  })
                }>
                <DeleteIcon></DeleteIcon>
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default TemplateParamsModalTable;
