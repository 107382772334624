import crudReducers from '../utils';
import { combineReducers } from 'redux';
import { CLEAR_DICTIONARIES_FILTER, SET_DICTIONARIES_FILTER } from '../../constants/ActionTypes';

const filterDefault = {
  hasFilter : false,
  scopes: []
}

const filter = (state=filterDefault, action)=>{
  switch(action.type){
    case SET_DICTIONARIES_FILTER:
      return{...state, ...action.payload, hasFilter: true}
    case CLEAR_DICTIONARIES_FILTER:
      return filterDefault
  default:
    return state;  
  }
}

export default combineReducers({
    ...crudReducers({ resource: 'DICTIONARY', keyProperty: 'uuid' }),
    filter
  });