import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TextFieldsListEditor from '../list_editors/TextFieldsListEditor';
import CustomPropertiesEditor from './CustomPropertiesEditor';
import _ from 'lodash';
import withCustomPropertiesDefinitons from './withCustomPropertiesDefinitons';
function AdditionalPropertiesEditor({
  additionalProperties = [],
  onChange,
  resourceType,
  resource,
  customProperties
}) {
  const { t } = useTranslation();
  const cdpByName = _.keyBy(customProperties, 'name');
  // eslint-disable-next-line no-unused-vars
  const customAdditionalProperties = additionalProperties.filter((adp) => cdpByName[adp.name]);
  const remainingAdditionalProperties = additionalProperties.filter((adp) => !cdpByName[adp.name]);
  return (
    <>
      <CustomPropertiesEditor
        {...{
          resourceType,
          resource,
          onChange: (adps) => {
            onChange(adps);
          },
          additionalProperties
        }}
      />
      <TextFieldsListEditor
        headerTitle={t('additionalProperties.headerTitle')}
        addLabel={t('additionalProperties.addPropertyLabel')}
        items={remainingAdditionalProperties}
        onAdd={(dsc) => onChange(additionalProperties.concat([dsc]))}
        onDelete={(dsc) => onChange(additionalProperties.filter((e) => e !== dsc))}
        textFields={[
          {
            hintText: t('additionalProperties.propertyNameHint'),
            floatingLabelText: t('additionalProperties.propertyNameLabel'),
            property: 'name',
            suggestions:
              resourceType &&
              t('suggestion:additionalProperties.' + resourceType, {
                returnObjects: true,
                defaultValue: []
              }),
            suggestionsConfig: { text: 'text', value: 'value' },
            enabler: true
          },
          {
            hintText: t('additionalProperties.propertyValueHint'),
            floatingLabelText: t('additionalProperties.propertyValueLabel'),
            property: 'value'
          }
        ]}
      />
    </>
  );
}

AdditionalPropertiesEditor.propTypes = {
  additionalProperties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string
    })
  ),
  onChange: PropTypes.func.isRequired
};

export default withCustomPropertiesDefinitons(AdditionalPropertiesEditor);
