import React from 'react';

import { Box, Tab, Tabs, Grid } from '@mui/material';

import AgentConnectionsTab from 'pages/settings/agents/detail/AgentConnectionsTab';
import AgentJobsHistoryTab from 'pages/settings/agents/detail/AgentJobsHistoryTab';
import AgentScheduleTab from 'pages/settings/agents/detail/AgentSchedulesTab';
import { useHistory } from 'react-router';
import useSearchParams from 'components/useSearchParams';

function AgentsTab({ agent }) {
  const query = useSearchParams();
  const history = useHistory();
  const selectedTab = 1 * query.get('tab') || 0;
  const handleChange = (_event, newValue) =>
    history.push(history.location.pathname + '?tab=' + newValue);
  return <>
    <Grid container alignItems='baseline' direction='row' justifyContent='center'>
      <Grid item>
        <Box display='flex' justifyContent='space-around' width='100%'>
          <Tabs value={selectedTab} onChange={handleChange}>
            <Tab label='Connection' />
            <Tab label='Schedules' />
            <Tab label='Runs' />
          </Tabs>
        </Box>
      </Grid>
    </Grid>

    {selectedTab === 0 && <AgentConnectionsTab agent={agent} />}
    {selectedTab === 1 && <AgentScheduleTab agent={agent} />}
    {selectedTab === 2 && <AgentJobsHistoryTab agent={agent} />}
  </>;
}

export default AgentsTab;
