import * as React from "react";
const SvgNuodb = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 179"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="m225.828 8.069-13.283.79-29.57 80.032-35.22-41.22-13.935-3.477L62.471 0l13.843 45.046.036.205-36.514-5.127 40.425 77.81 38.36 25.166L0 178.65l164.053-19.231-.043-28.359 5.613-1.644 41.353-12.088 12.105-90.103L256 48.659l-30.172-40.59"
      fill="#B5D118"
    />
  </svg>
);
export default SvgNuodb;
