import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  fetchPhysicalFieldsPage,
  createPhysicalField,
  modifyPhysicalField,
  deletePhysicalField
} from 'actions/PhysicalFieldsActions';
import { fetchDashboardTypeCounterIfAllowedLargeRequest } from 'actions/IssueMngtDashboardsActions';
import {
  IconButton,
  TablePagination,
  Typography,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Tooltip
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

import Table from 'pages/systems/physical_entities/detail/PhysicalEntityDetailsCard/PhysicalFieldsSection/Table';
import ActionsModals from 'pages/systems/physical_entities/detail/PhysicalEntityDetailsCard/PhysicalFieldsSection/ActionsModals';
import StewardshipAuthorize from 'components/permissions/StewardshipAuthorize';

import ResourceTypes from 'constants/ResourceTypes';
import Permissions from 'constants/Permissions';
import PhysicalFieldsExportButton from 'components/exports/PhysicalFieldsExportButton';

const AddAction = ({ physicalEntityUuid, handleClick }) => {
  return (
    <>
      <StewardshipAuthorize
        resourceType={ResourceTypes.PHYSICAL_ENTITY}
        resourceIdentifier={physicalEntityUuid}
        hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
        <Tooltip title={'Create field'}>
          <IconButton color='default' size={'small'} onClick={handleClick}>
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </StewardshipAuthorize>
    </>
  );
};

const PhysicalFieldsSection = ({
  physicalEntity,
  physicalFields,
  fetchPhysicalFields,
  onAddPhysicalField,
  onEditPhysicalField,
  onDeletePhysicalField,
  pageNumber,
  pageSize,
  totalElements,
  fetchDashboardTypeCounterIfAllowedLargeRequest,
  totalPages
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [selectedField, setSelectedField] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const onShowPhysicalFieldDetails = (uuid) => history.push(`/systems/physical_fields/${uuid}`);
  const onCreate = (field) => {
    onAddPhysicalField({ ...field, physicalEntity });
    setCreateModalOpen(false);
  };
  const onEdit = (field) => {
    onEditPhysicalField(field);
    setEditModalOpen(false);
  };
  const onDelete = (field) => {
    onDeletePhysicalField(field);
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    physicalEntity &&
      fetchPhysicalFields({ physicalEntityUuid: physicalEntity.uuid, page: 0, size: 100 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [physicalEntity]);

  useEffect(() => {
    physicalFields &&
      physicalFields.length > 0 &&
      fetchDashboardTypeCounterIfAllowedLargeRequest(
        physicalFields,
        25,
        ResourceTypes.PHYSICAL_FIELD
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [physicalFields]);
  return (
    <>
      <Card>
        <CardHeader
          title={t('physicalEntities.detail.physicalFields.title')}
          action={
            <Grid container alignItems='center'>
              <Grid item>
                <PhysicalFieldsExportButton
                  physicalEntity={physicalEntity}></PhysicalFieldsExportButton>
              </Grid>
              <Grid item>
                <AddAction
                  physicalEntityUuid={physicalEntity.uuid}
                  handleClick={() => setCreateModalOpen(true)}
                />
              </Grid>
            </Grid>
          }></CardHeader>
        <CardContent>
          {physicalFields.length > 0 ? (
            <Table
              fields={physicalFields?.sort((a, b) => a.name.localeCompare(b.name))}
              handleShowDetails={onShowPhysicalFieldDetails}
              handleEdit={(field) => {
                setSelectedField(field);
                setEditModalOpen(true);
              }}
              handleDelete={(field) => {
                setSelectedField(field);
                setDeleteModalOpen(true);
              }}
            />
          ) : (
            <div style={{ textAlign: 'center', padding: '2%' }}>
              <Typography variant='body1'>No physical fields defined</Typography>
            </div>
          )}
          {totalPages > 1 && (
            <TablePagination
              component='div'
              style={{ overflowX: 'hidden' }}
              rowsPerPageOptions={[100, 200]}
              rowsPerPage={pageSize}
              page={pageNumber}
              count={totalElements}
              onPageChange={(event, page) =>
                fetchPhysicalFields({
                  physicalEntityUuid: physicalEntity.uuid,
                  page,
                  size: pageSize
                })
              }
              onRowsPerPageChange={(event) =>
                fetchPhysicalFields({
                  physicalEntityUuid: physicalEntity.uuid,
                  page: pageNumber,
                  size: event.target.value
                })
              }
            />
          )}
          <ActionsModals
            selectedField={selectedField}
            createModalOpen={createModalOpen}
            setCreateModalOpen={setCreateModalOpen}
            onCreate={onCreate}
            editModalOpen={editModalOpen}
            setEditModalOpen={setEditModalOpen}
            onEdit={onEdit}
            deleteModalOpen={deleteModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            onDelete={onDelete}
          />
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  physicalFields: state.physicalFields.currentPage.content,
  pageNumber: state.physicalFields.currentPage.number,
  pageSize: state.physicalFields.currentPage.size,
  totalElements: state.physicalFields.currentPage.totalElements,
  totalPages: state.physicalFields.currentPage.totalPages
});

const mapDispatchToProps = {
  fetchPhysicalFields: fetchPhysicalFieldsPage,
  onAddPhysicalField: createPhysicalField,
  onEditPhysicalField: modifyPhysicalField,
  onDeletePhysicalField: deletePhysicalField,
  fetchDashboardTypeCounterIfAllowedLargeRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalFieldsSection);
