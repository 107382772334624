import { List } from '@mui/material';
import React from 'react';
import { MyIssueListItem } from './MyIssueListItem';

export const MyIssuesList = ({ issues, orderedIssueStatuses, height }) => {
  if (!issues) return <></>;
  return (
    <List style={{ overflowY: 'auto', maxHeight: `${height}px` }}>
      {issues.map((issue) => (
        <MyIssueListItem issue={issue} orderedIssueStatuses={orderedIssueStatuses} />
      ))}
    </List>
  );
};
