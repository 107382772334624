import React from 'react';
import PropTypes from 'prop-types';
import ContentAdd from '@mui/icons-material/Add';
import { Fab } from '@mui/material';

const styles = {
  fab: {
    position: 'fixed',
    bottom: (theme) => theme.spacing(2),
    right: (theme) => theme.spacing(2)
  }
};

const AddFAB = ({ id, onClick, classes, ...props }) => (
  <Fab id={id} size='large' color='primary' sx={styles.fab} onClick={onClick} {...props}>
    <ContentAdd />
  </Fab>
);

AddFAB.propTypes = {
  onClick: PropTypes.func
};

export default AddFAB;
