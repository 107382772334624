import {
  FETCH_TEAMS_LIST,
  FETCH_TEAM,
  CREATE_TEAM,
  DELETE_TEAM,
  MODIFY_TEAM
} from '../constants/ActionTypes';
import TeamApi from '../api/TeamsApi';
import { buildApiCallAction } from './ActionUtils';

const teamApi = new TeamApi();

export const fetchTeamListPage = ({ page, size, search }) =>
  buildApiCallAction({
    api: teamApi,
    apiFunction: (api) => api.getTeams({ page, size, search }),
    actionType: FETCH_TEAMS_LIST
  });

export const fetchTeam = (teamCode) =>
  buildApiCallAction({
    api: teamApi,
    apiFunction: (api) => api.getTeam(teamCode),
    actionType: FETCH_TEAM
  });

export const createTeam = (team) =>
  buildApiCallAction({
    api: teamApi,
    apiFunction: (api) => api.postTeam(team),
    actionType: CREATE_TEAM
  });
export const deleteTeam = (team) =>
  buildApiCallAction({
    api: teamApi,
    apiFunction: (api) => api.deleteTeam(team.code),
    actionType: DELETE_TEAM,
    payload: team
  });
export const modifyTeam = (team) =>
  buildApiCallAction({
    api: teamApi,
    apiFunction: (api) => api.putTeam(team),
    actionType: MODIFY_TEAM
  });
