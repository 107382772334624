import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ChipItem, ChipWrapper } from '../../../components/chiplist/ChipList';
import ItemSelectorModal from '../../../components/itemselector/ItemSelectorModal';
import AutoComplete from '../../../components/autocomplete/AutoComplete';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import AdditionalPropertiesEditor from '../../../components/additional_properties/AdditionalPropertiesEditor';
import { DialogContent, DialogActions, DialogTitle } from '@mui/material';
import DataActorItemAutoComplete from '../utils/DataActorItemAutoComplete';
import AccountTeamSelector, {
  ACCESS_LEVELS
} from '../../settings/profile/utils/AccountTeamSelector';
import ResourceTypes from '../../../constants/ResourceTypes';

const initialState = {
  uuid: undefined,
  name: undefined,
  firstName: undefined,
  lastName: undefined,
  notes: undefined,
  roles: [],
  refersToDataActor: undefined,
  additionalProperties: [],
  teamCode: null,
  addRole: false
};

const roleValues = [
  'DATA_PROCESSOR',
  'DATA_CONTROLLER',
  'DATA_PROTECTION_OFFICER',
  'REPRESENTATIVE',
  'ORGANIZATION',
  'DEPARTMENT',
  'PERSON'
];

class DataActorsEditorModal extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = props.dataActor ? { ...initialState, ...props.dataActor } : initialState;
    this.subjectTypeSuggestions = t('suggestion:subjectTypes', { returnObjects: true });
    this.subjectTypeDescriptionSuggestions = t('suggestion:subjectTypeDescriptions', {
      returnObjects: true
    });
    this.subjectTypeDescriptionSuggestions.all = _(this.subjectTypeDescriptionSuggestions)
      .flatMap()
      .uniqBy()
      .value();
    this.iso3166Objects = t('countries:iso3166Objects', { returnObjects: true });
  }

  componentWillReceiveProps(props) {
    if (!this.props.open && props.open) {
      if (props.dataActor) {
        this.setState({ ...props.dataActor });
      } else {
        this.setState(initialState);
      }
    }
  }

  onAddRole() {
    this.setState({ addRole: true });
  }

  countryErrorMessage() {
    let { t } = this.props;
    if (this.state.country && _(this.iso3166Objects).find({ alpha2: this.state.country })) {
      return undefined;
    } else if (this.state.country) {
      return t('commons.errors.notValid');
    }
  }

  countryTextValue() {
    var iso3166Object = _(this.iso3166Objects).find({ alpha2: this.state.country });
    if (iso3166Object) {
      return iso3166Object.country;
    } else {
      return this.state.country;
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Dialog fullScreen={this.props.fullScreen} open={this.props.open}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <h4 style={{ marginTop: '8px' }}>General</h4>
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the Blindata identifier name'
            label='Name'
            helperText={this.state.name ? undefined : t('commons.errors.required')}
            required={true}
            error={!this.state.name || this.state.name.trim() === ''}
            value={this.state.name || ''}
            onChange={(event, newValue) => this.setState({ name: event.target.value })}
          />
          <br />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter an identifier code'
            label='Code'
            value={this.state.identifierCode || ''}
            onChange={(event) => this.setState({ identifierCode: event.target.value })}
          />
          <br />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the first name'
            label='First Name'
            value={this.state.firstName || ''}
            onChange={(event, newValue) => this.setState({ firstName: event.target.value })}
          />
          <br />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the last name'
            label='Last Name'
            value={this.state.lastName || ''}
            onChange={(event, newValue) => this.setState({ lastName: event.target.value })}
          />
          <br />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the business name'
            label='Business Name'
            value={this.state.businessName || ''}
            onChange={(event, newValue) => this.setState({ businessName: event.target.value })}
          />
          <br />

          <AutoComplete
            fullWidth
            placeholder='Enter the country'
            label='Country'
            value={this.countryTextValue() || ''}
            helperText={this.countryErrorMessage()}
            margin='dense'
            dataSource={this.iso3166Objects}
            dataSourceConfig={{ text: 'country', value: 'alpha2' }}
            onUpdateInput={(searchText, dataSource) => {
              let newValue = _(dataSource).find({ country: searchText });
              this.setState({ country: (newValue && newValue.alpha2) || searchText });
            }}
          />
          <br />

          <h4>Roles*</h4>
          <ChipWrapper onRequestAdd={() => this.onAddRole()}>
            {(this.state.roles || []).map((role) => (
              <ChipItem
                key={role}
                id={role}
                label={role}
                onRequestDelete={(delRole) =>
                  this.setState({ roles: _.remove(this.state.roles || [], (r) => r === delRole) })
                }
              />
            ))}
          </ChipWrapper>
          <ItemSelectorModal
            title='Add Role'
            itemName='Role'
            open={this.state.addRole}
            keyProperty='name'
            nameProperty='name'
            values={roleValues.map((r) => {
              return { name: r };
            })}
            handleCancel={() => this.setState({ addRole: false })}
            handleSubmit={(role) =>
              this.setState({
                roles: (this.state.roles || []).concat([role.name]),
                addRole: false
              })
            }
          />

          <DataActorItemAutoComplete
            itemName='Refers to DataActor'
            value={this.state.refersToDataActor}
            onChange={(val) => this.setState({ refersToDataActor: val })}
          />

          <h4>Description</h4>
          <AutoComplete
            fullWidth
            placeholder='Enter the subject type'
            label='Subject type'
            value={this.state.subjectType || ''}
            dataSource={this.subjectTypeSuggestions}
            onUpdateInput={(newValue) => this.setState({ subjectType: newValue })}
          />
          <br />

          <AutoComplete
            fullWidth
            placeholder='Enter the subject description'
            label='Subject description'
            margin='dense'
            value={this.state.subjectDescription || ''}
            dataSource={
              this.subjectTypeDescriptionSuggestions[this.state.subjectType] ||
              this.subjectTypeDescriptionSuggestions.all ||
              []
            }
            onUpdateInput={(newValue) => this.setState({ subjectDescription: newValue })}
          />
          <br />

          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the notes'
            label='Notes'
            value={this.state.notes || ''}
            onChange={(event) => this.setState({ notes: event.target.value })}
          />
          <br />

          <h4>Address</h4>
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the state'
            label='State'
            value={this.state.addressState || ''}
            onChange={(event, newValue) => this.setState({ addressState: event.target.value })}
          />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the city'
            label='City'
            value={this.state.addressCity || ''}
            onChange={(event, newValue) => this.setState({ addressCity: event.target.value })}
          />
          <br />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the address'
            label='Address'
            value={this.state.addressStreet || ''}
            onChange={(event, newValue) => this.setState({ addressStreet: event.target.value })}
          />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the building number'
            label='Building Number'
            value={this.state.addressBuildingNumber || ''}
            onChange={(event, newValue) =>
              this.setState({ addressBuildingNumber: event.target.value })
            }
          />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the postal code'
            label='Postal Code'
            value={this.state.addressPostalCode || ''}
            onChange={(event, newValue) => this.setState({ addressPostalCode: event.target.value })}
          />
          <br />
          <h4>Contacts</h4>
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the email'
            label='Email'
            value={this.state.email || ''}
            onChange={(event, newValue) => this.setState({ email: event.target.value })}
          />
          <br />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the phone'
            label='Phone'
            value={this.state.phone || ''}
            onChange={(event) => this.setState({ phone: event.target.value })}
          />
          <br />
          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the fax'
            label='Fax'
            value={this.state.fax || ''}
            onChange={(event, newValue) => this.setState({ fax: event.target.value })}
          />
          <br />

          <AdditionalPropertiesEditor
            resourceType={ResourceTypes.DATA_ACTOR}
            additionalProperties={this.state.additionalProperties}
            onChange={(newList) =>
              this.setState({
                additionalProperties: newList
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <AccountTeamSelector
            accessLevel={ACCESS_LEVELS.WRITE}
            accessLevel2={ACCESS_LEVELS.PROPOSE}
            style={{ float: 'left' }}
            selectedTeamCode={this.state.teamCode || ''}
            onChange={(code) => this.setState({ teamCode: code })}
          />
          <div style={{ flexGrow: 1 }} />
          <Button children={t('commons.actions.cancel')} onClick={this.props.handleCancel} />
          <Button
            children={t('commons.actions.submit')}
            color='primary'
            disabled={!this.state.name || this.state.roles.length === 0}
            onClick={() => this.props.handleSubmit(this.state)}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DataActorsEditorModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  uuid: PropTypes.string,
  name: PropTypes.string,
  firstName: PropTypes.string,
  notes: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default _.flow([withTranslation()])(DataActorsEditorModal);
