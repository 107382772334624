import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid
} from '@mui/material';

import QualityProbesProjectTagsItemAutocomplete from '../../commons/QualityProbesProjectTagsItemAutocomplete';
import CronExpressionTextField from 'components/CronExpressionTextField';
import TimeZonesMenuItem from 'components/timezone/TimeZonesMenuItem';
import AgentConnectionItemAutoComplete from 'pages/settings/agents/commons/AgentConnectionItemAutoComplete';

import { convertProbesToJob } from 'pages/settings/agents/commons/ProbesJobConverter';

function ProjectScheduleModal({ title, open, project, onSubmit, onClose, schedule, agent }) {
  const { t } = useTranslation();
  const [openTagModal, setOpenTagModal] = useState(false);
  const [name, setName] = useState(schedule?.name);
  const [timeZone, setTimezone] = useState(
    schedule?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [cronExpression, setCronExpression] = useState(schedule?.cronExpression);
  const [outputConnection, setOutputConnection] = useState(
    schedule?.jobDetails?.configs?.outputOptions?.targetConnectionName
  );
  const [scheduled, setScheduled] = useState(schedule?.scheduled || false);
  const [jobDetails, setJobDetails] = useState(schedule?.jobDetails);

  const handleTargetTagUpdate = (tag) => {
    if (tag) {
      setJobDetails(convertProbesToJob(tag.probesDefinitions, tag.project.name));
      setName(`${project.name}:${tag.name}`);
    } else {
      setJobDetails(null);
      setName(null);
    }
  };

  const handleSubmit = () => {
    onSubmit(agent, {
      uuid: schedule?.uuid,
      name: name,
      externalIdentifier: project?.uuid,
      type: 'QUALITY_PROBES',
      cronExpression: cronExpression,
      timezoneId: timeZone,
      scheduled: scheduled,
      jobDetails: {
        ...jobDetails,
        configs: {
          ...jobDetails.configs,
          outputOptions: {
            dryRun: false,
            targetConnectionName: outputConnection
          }
        }
      }
    });
    onClose();
  };

  return (
    <div>
      <Dialog fullWidth open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <h4>Target</h4>
          <Grid container spacing={5} alignItems='baseline'>
            <Grid item>
              <Typography>{name ? name : 'No target selected'}</Typography>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}></Grid>
            <Grid item>
              <Button onClick={() => setOpenTagModal(true)}>Select Tag</Button>
            </Grid>
          </Grid>
          <CronExpressionTextField value={cronExpression || ''} onChange={setCronExpression} />
          <TimeZonesMenuItem
            value={timeZone}
            onChange={(tz) => setTimezone(tz)}></TimeZonesMenuItem>
          <AgentConnectionItemAutoComplete
            label='Blindata Output Connection'
            fullWidth
            margin='dense'
            connectionTypes={['BLINDATA']}
            value={outputConnection || ''}
            onChange={(value) => setOutputConnection(value)}
            agent={agent}
          />
          <FormControlLabel
            control={
              <Checkbox
                size='small'
                checked={scheduled}
                onChange={(event) => setScheduled(event.target.checked)}
                name='enabled'
                color='primary'
              />
            }
            label='Job Scheduled'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
          <Button disabled={!name || !jobDetails} onClick={handleSubmit}>
            {t('commons.actions.submit')}
          </Button>
        </DialogActions>
      </Dialog>
      {openTagModal && (
        <SelectTagModal
          open={openTagModal}
          setOpen={setOpenTagModal}
          setName={setName}
          projectUuid={project?.uuid}
          onChange={handleTargetTagUpdate}></SelectTagModal>
      )}
    </div>
  );
}

function SelectTagModal({ open, setOpen, projectUuid, onChange }) {
  const [tag, setTag] = useState(null);
  const { t } = useTranslation();

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Select a Tag</DialogTitle>
      <DialogContent>
        <QualityProbesProjectTagsItemAutocomplete
          projectUuid={projectUuid}
          onChange={(tag) => setTag(tag)}></QualityProbesProjectTagsItemAutocomplete>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setTag(null);
            setOpen(false);
          }}>
          {t('commons.actions.cancel')}
        </Button>
        <Button
          onClick={() => {
            onChange(tag);
            setOpen(false);
          }}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ProjectScheduleModal;
