import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import { Chart } from 'react-google-charts';
import {
  fetchEngagementPageViewsTimeline,
  fetchEngagementPageViewsMetrics
} from 'actions/EngagementDashboardsActions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

function EngagementPageViewsDashboards({
  fetchEngagementPageViewsTimeline,
  fetchEngagementPageViewsMetrics,
  filters,
  pageViewTimeline,
  pageViewMetrics
}) {
  const [loadedMetrics, setLoadedMetrics] = useState(false);
  const [loadedTimeline, setLoadedTimeline] = useState(false);
  const [precision, setPrecision] = useState('hour');
  let { t } = useTranslation();

  const handleSetPrecision = ({ precision }) => {
    setPrecision(precision);
    fetchEngagementPageViewsTimeline({ ...filters, precision });
  };

  useEffect(() => {
    fetchEngagementPageViewsMetrics({ ...filters }).then(() => setLoadedMetrics(true));
  }, [filters, fetchEngagementPageViewsMetrics]);

  useEffect(() => {
    fetchEngagementPageViewsTimeline({ ...filters, precision }).then(() => setLoadedTimeline(true));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, fetchEngagementPageViewsTimeline]);
  const tableRows = loadedMetrics && [
    pageViewMetrics.columns,
    ...pageViewMetrics.rows.map((r) => [r[0], r[1], r[2], r[3], r[4], r[5]])
  ];
  const timelineRows = loadedTimeline && [
    pageViewTimeline.columns,
    ...pageViewTimeline.rows.map((r) => [new Date(r[0]), r[1]])
  ];

  return (
    <Card>
      <CardHeader
        action={
          <FormControl variant="standard" style={{ marginRight: '50%' }}>
            <InputLabel id='quality-suite-results-kpi-precision-selector-label'>
              Precision
            </InputLabel>
            <Select
              variant="standard"
              labelId='quality-suite-results-kpi-precision-selector-label'
              id='quality-suite-results-kpi-precision-selector'
              value={precision}
              onChange={(event) => handleSetPrecision({ precision: event.target.value })}>
              <MenuItem value='month'>{t('commons.timeunits.values.MONTHS')}</MenuItem>
              <MenuItem value='day'>{t('commons.timeunits.values.DAYS')}</MenuItem>
              <MenuItem value='hour'>{t('commons.timeunits.values.HOURS')}</MenuItem>
            </Select>
          </FormControl>
        }
        title={'Page Views Dashboards'}></CardHeader>
      <CardContent>
        <Grid container alignItems='center' direction='column' spacing={3}>
          {timelineRows.length > 1 ? (
            <Grid item style={{ width: '100%', height: '300%' }}>
              <Chart
                chartType='ColumnChart'
                loader={<div>Loading Chart</div>}
                data={timelineRows}
                options={{
                  colors: ['#00AECA']
                }}
              />
            </Grid>
          ) : (
            <Typography variant='overline'>{t('commons.placeholders.nothingToDisplay')}</Typography>
          )}
          <Grid item style={{ width: '100%' }}>
            <Chart
              width={'100%'}
              height={'400px'}
              chartType='Table'
              loader={<div>Loading Chart</div>}
              data={tableRows}
              options={{
                showRowNumber: true,
                width: '100%',
                height: '100%'
              }}
              rootProps={{ 'data-testid': '3' }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state, props) => ({
  pageViewMetrics: state.engagement.engagementDashboards.engagementPageViewsMetrics,
  filters:
    state.engagement.engagementDashboards.engagementTimeline.engagementSessionTimeline.filters,

  pageViewTimeline:
    state.engagement.engagementDashboards.engagementTimeline.engagementPageViewTimeline.data
});

const mapDispatchToProps = {
  fetchEngagementPageViewsTimeline,
  fetchEngagementPageViewsMetrics
};

export default connect(mapStateToProps, mapDispatchToProps)(EngagementPageViewsDashboards);
