import React, { useState } from 'react';

import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material';

import SystemItemAutoComplete from 'pages/systems/commons/SystemItemAutoComplete';
import { useTranslation } from 'react-i18next';
import RoutineSchemaAutoComplete from './RoutineSchemaAutoComplete';
import RoutineCatalogAutoComplete from './RoutineCatalogAutoComplete';

function RoutineModal({ title, open, handleSubmit, handleCancel, routine, system }) {
  const mergedDataRoutine = { ...routine, system: routine ? routine.system : system };
  const [editor, setEditor] = useState(mergedDataRoutine || null);
  const { t } = useTranslation();
  return (
    <Dialog maxWidth='lg' fullWidth open={open} onClick={(event) => event.stopPropagation()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          placeholder='Enter the routine name'
          label='Name'
          required={true}
          value={editor?.name || ''}
          onChange={(event) => setEditor({ ...editor, name: event.target.value })}
        />
        <RoutineSchemaAutoComplete
          required
          margin='dense'
          schema={{ schema: editor?.schema } || null}
          onChange={(value) => setEditor({ ...editor, schema: value?.schema })}
        />
        <RoutineCatalogAutoComplete
          required
          margin='dense'
          catalog={{ catalog: editor?.catalog } || ''}
          onChange={(value) => setEditor({ ...editor, catalog: value?.catalog })}
        />
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          placeholder='Enter the routine description'
          label='Description'
          value={editor?.description || ''}
          onChange={(event) => setEditor({ ...editor, description: event.target.value })}
        />
        <SystemItemAutoComplete
          fullWidth
          margin='dense'
          required={true}
          value={editor?.system || system || null}
          onChange={(system) => {
            setEditor({ ...editor, system: system });
          }}
        />
        <FormControl fullWidth variant='standard' margin='dense'>
          <InputLabel>Routine Type</InputLabel>
          <Select
            value={editor?.type || null}
            variant='standard'
            fullWidth
            onChange={(event) => setEditor({ ...editor, type: event.target.value })}>
            <MenuItem value='PROCEDURE'>
              <ListItem dense>
                <ListItemText primary='PROCEDURE' />
              </ListItem>
            </MenuItem>
            <MenuItem value='FUNCTION'>
              <ListItem dense>
                <ListItemText primary='FUNCTION' />
              </ListItem>
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant='standard'
          margin='dense'
          id='outlined-multiline-static'
          label='Routine Definition'
          multiline
          placeholder='A SQL query text.'
          fullWidth
          value={editor?.routineDefinition || ''}
          onChange={(event) => {
            setEditor({
              ...editor,
              routineDefinition: event.target.value
            });
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.cancel')}
          onClick={() => {
            handleCancel();
            setEditor(null);
          }}
        />
        <Button
          variant='contained'
          color='primary'
          disabled={!editor?.name || !editor?.catalog || !editor.schema}
          children={t('commons.actions.submit')}
          onClick={() => {
            handleSubmit(editor);
            setEditor(null);
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

export default RoutineModal;
