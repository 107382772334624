import { Button } from '@mui/material';
import React from 'react';

function OIDCImplicitFlowButton({ setting }) {
  return (
    <Button
      style={{ margin: 4, width: 214 }}
      variant='contained'
      color='primary'
      onClick={() => {
        window.location.href = setting?.redirectUrl;
      }}>
      Sign in with {setting.provider}
    </Button>
  );
}

export default OIDCImplicitFlowButton;
