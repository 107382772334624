import React, { useState } from 'react';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  FormControlLabel,
  Switch,
  Tabs,
  Tab
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import KVDefinitionList from '../../../../components/definition-list/KVDefinitionList';
import Loader from '../../../../components/loader/Loader';
import {
  fetchContractTemplate,
  deleteContractTemplate
} from '../../../../actions/ContractTemplatesActions';
import { connect } from 'react-redux';
import Breadcrumbs from 'pages/sidebar/Navigation';

import ComposableTableCard from '../../../../components/tables/ComposableTableCard';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import { Controlled as CodeMirror } from 'react-codemirror2-react-17';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/display/fullscreen.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import ErrorBoundary from 'components/errors/ErrorBoundary';
import ReactQuill from 'react-quill';
import AttachmentsCard from '../attachments/AttachmentsCard';
import useSearchParams from 'components/useSearchParams';

const TemplateDetailPage = ({ template, deleteContractTemplate, t }) => {
  const query = useSearchParams();
  const history = useHistory();
  const selectedTab = query.get('tab') || 'content';
  const handleChangeTab = (_event, newValue) =>
    history.push(history.location.pathname + '?tab=' + newValue, { keepScroll: true });

  return (
    <React.Fragment>
      <Breadcrumbs
        elements={[
          { text: 'Contracts', to: '/contracts' },
          { text: 'Templates', to: '/contracts/templates' },
          { text: template.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: `Contract Template`,
          category: EngagementCategories.CONSENTS,
          action: EngagementActions.DETAIL
        }}
      />
      <Typography variant='h4'>
        Contract Template
        <Button
          style={{ float: 'right' }}
          children='Preview'
          onClick={() => window.alert('Not yet implemented')}
        />
      </Typography>

      <TemplateGeneralData {...{ template, deleteContractTemplate, t, history }} />

      <Tabs value={selectedTab} onChange={handleChangeTab} centered>
        <Tab value='content' label='Content'></Tab>
        <Tab value='attachments' label='Attachments'></Tab>
        <Tab value='placeholders' label='Placeholders'></Tab>
      </Tabs>
      {selectedTab === 'content' && <TemplateContent template={template} />}
      {selectedTab === 'attachments' && <AttachmentsCard templateUuid={template.uuid} />}
      {selectedTab === 'placeholders' && (
        <>
          <TemplateDetailProcessingPlaceholders template={template} />
          <TemplateDetailPlaceholders template={template} />
        </>
      )}
    </React.Fragment>
  );
};

const TemplateGeneralData = ({ template, deleteContractTemplate, t, history }) => (
  <Card style={{ margin: '8px 0 8px 0' }}>
    <CardHeader title={template.name} />
    <CardContent>
      <KVDefinitionList
        item={{
          Name: template.name,
          'Created at': template.createdAt,
          'Updated at': template.updatedAt
        }}
      />
    </CardContent>
    <CardActions>
      <Button component={Link} to={`/contracts/templates/editor/edit/${template.uuid}`}>
        {t('commons.actions.modify')}
      </Button>
      <ConfirmButton
        onClick={() => {
          deleteContractTemplate(template).then(() => history.replace('/contracts/templates'));
        }}
        children={t('commons.actions.delete')}
      />
    </CardActions>
  </Card>
);

function TemplateContent({ template }) {
  let [isHtml, setIsHtml] = useState(true);
  return (
    <Card style={{ margin: '8px 0 8px 0' }}>
      <CardHeader
        title='Template Content'
        action={
          <FormControlLabel
            style={{ paddingLeft: '12px' }}
            control={
              <Switch
                checked={isHtml}
                onChange={() => setIsHtml(!isHtml)}
                value='htmlEditor'
                color='primary'
              />
            }
            label='Html'
          />
        }
      />
      <CardContent>
        {isHtml ? (
          <CodeMirror
            style={{ heigth: 'fill-parent' }}
            value={template.contentTemplate}
            options={{
              mode: 'htmlmixed',
              lineNumbers: true
            }}
          />
        ) : (
          <ErrorBoundary>
            <ReactQuill
              value={template.contentTemplate}
              modules={{
                toolbar: [],
                clipboard: {
                  matchVisual: false
                }
              }}
              readOnly
              onChange={(text) => null}
            />
          </ErrorBoundary>
        )}
      </CardContent>
    </Card>
  );
}

const TemplateDetailProcessingPlaceholders = ({ template }) => (
  <ComposableTableCard
    firstStrong
    title='Processing Placeholders'
    headers={['Name', 'Processing', 'Processing Description']}
    propertiesPaths={['name', 'processing.name', 'processing.description']}
    rows={template.processingPlaceholders || []}
  />
);

const TemplateDetailPlaceholders = ({ template }) => (
  <ComposableTableCard
    firstStrong
    title='Placeholders'
    headers={['Name', 'Value']}
    propertiesPaths={['name', 'value']}
    rows={template.placeholders || []}
  />
);

const LoadableTemplateDetail = ({
  templateUuid,
  template,
  fetchContractTemplate,
  ...otherProps
}) => (
  <Loader onLoad={() => fetchContractTemplate(templateUuid)}>
    {template ? <TemplateDetailPage {...{ template, ...otherProps }} /> : null}
  </Loader>
);

const mapStateToProps = (state, props) => ({
  template: state.contracts.templates.content[props.match.params['templateUuid']],
  templateUuid: props.match.params['templateUuid']
});

export default connect(mapStateToProps, {
  fetchContractTemplate,
  deleteContractTemplate
})(withTranslation()(LoadableTemplateDetail));
