import React, { useState } from 'react';
import { Grid, Tooltip, IconButton } from '@mui/material';

import { AddBox } from '@mui/icons-material';
import TemplateParamsModalTable from './TemplateParamsModalTable';
import TemplateParamsModal from './TemplateParamsModal';

function TemplateParamsModalSection({ editor, setEditor }) {
  return (
    <div>
      <Grid container alignContent='center' alignItems='center'>
        <Grid item>
          <h4>Template Params</h4>
        </Grid>
        <Grid item>
          <ParamAddButton editor={editor} setEditor={setEditor} />
        </Grid>
      </Grid>
      {editor?.templateParams?.params && Object.keys(editor?.templateParams?.params).length > 0 && (
        <TemplateParamsModalTable editor={editor} setEditor={setEditor} />
      )}
    </div>
  );
}

function ParamAddButton({ editor, setEditor }) {
  const [addParam, setAddParam] = useState(false);
  return (
    <>
      <Tooltip title={'Add a header'}>
        <IconButton onClick={() => setAddParam(true)} size='small'>
          <AddBox></AddBox>
        </IconButton>
      </Tooltip>
      {addParam && (
        <TemplateParamsModal
          title='Add a template param'
          open={addParam}
          handleSubmit={(parameter) => {
            setEditor({
              ...editor,
              templateParams: {
                ...editor?.templateParams,
                params: {
                  ...editor?.templateParams?.params,
                  [parameter.name]: {
                    mode: parameter?.mode,
                    defaultValue: parameter?.defaultValue,
                    mandatory: parameter?.mandatory
                  }
                }
              }
            });
            setAddParam(false);
          }}
          handleCancel={() => setAddParam(false)}
        />
      )}
    </>
  );
}

export default TemplateParamsModalSection;
