import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import RaisedButton from '@mui/material/Button';
import './Reset.css';
import { resetPasswordStart } from '../../../actions/LoginActions';
import { showMessage } from '../../../actions/BehaviourActions';
import { connect } from 'react-redux';
import logo_oriz from '../../../images/logo_oriz_white.png';

class ResetStart extends Component {
  state = {
    username: null
  };

  handleClick(event) {
    event.preventDefault();
    this.props
      .resetPasswordStart({
        username: this.state.username
      })
      .then(() => {
        this.props.history.push('/');
      });
  }

  render() {
    return (
      <form id='login-div'>
        <img id='login-logo' src={logo_oriz} alt='Logo' />
        <TextField
          variant='standard'
          placeholder=''
          InputLabelProps={{
            shrink: true
          }}
          label='Username'
          onChange={(event) => this.setState({ username: event.target.value })}
          margin='normal'
        />
        <br />
        <RaisedButton
          variant='contained'
          color='primary'
          children='Request Password Reset'
          style={style}
          disabled={!this.state.username}
          onClick={(event) => this.handleClick(event)}></RaisedButton>
        <input type='submit' hidden onClick={(event) => this.handleClick(event)} />
      </form>
    );
  }
}

const style = {
  margin: 15
};

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps, { resetPasswordStart, showMessage })(ResetStart);
