import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import {
  fetchAgentWebActionConfigurationsPage,
  deleteAgentWebActionConfiguration,
  modifyAgentWebActionConfiguration
} from 'actions/onagent/webactions/AgentWebActionConfigurationsActions';

import { connect } from 'react-redux';
import AgentWebActionConfigurationListItem from './AgentWebActionConfigurationListItem';
import PaginatedPanel from 'components/pagination/PaginatedPanel';
import AgentWebActionConfigurationAddButton from './AgentWebActionConfigurationAddButton';
import AgentWebActionConfigurationModal from '../modal/AgentWebActionConfigurationModal';
function AgentWebActionConfigurationList({
  webAction,
  fetchAgentWebActionConfigurationsPage,
  deleteAgentWebActionConfiguration,
  modifyAgentWebActionConfiguration,
  agent,
  webActionConfigurationsPage
}) {
  const [editConf, setEditConf] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);
  useEffect(() => {
    if (agent) {
      fetchAgentWebActionConfigurationsPage(agent, { webActionUuid: webAction?.uuid });
    }
  }, [fetchAgentWebActionConfigurationsPage, agent, webAction?.uuid]);

  return (
    <div>
      {webActionConfigurationsPage?.content?.length > 0 ? (
        <PaginatedPanel
          currentPage={webActionConfigurationsPage}
          onPageSelection={(page) =>
            fetchAgentWebActionConfigurationsPage(agent, { webActionUuid: webAction?.uuid })
          }>
          {webActionConfigurationsPage?.content?.map((config) => (
            <AgentWebActionConfigurationListItem
              key={config.uuid}
              handleEdit={() => {
                setSelectedConfig(config);
                setEditConf(true);
              }}
              handleDelete={() => deleteAgentWebActionConfiguration(agent, config)}
              webAction={webAction}
              agentWebActionConfiguration={config}
            />
          ))}
        </PaginatedPanel>
      ) : (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='body1'>No configurations found for this action</Typography>
          <AgentWebActionConfigurationAddButton agent={agent} webAction={webAction} />
        </div>
      )}
      {editConf && (
        <AgentWebActionConfigurationModal
          open={editConf}
          webAction={webAction}
          title={'Edit Configuration'}
          agentWebActionConfiguration={selectedConfig}
          handleCancel={() => setEditConf(false)}
          handleSubmit={(configuration) => {
            modifyAgentWebActionConfiguration(agent, selectedConfig.uuid, configuration);
            setEditConf(false);
          }}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webActionConfigurationsPage: state.settings.agents.webActionConfigurations.currentPage
  };
}

export default connect(mapStateToProps, {
  fetchAgentWebActionConfigurationsPage,
  deleteAgentWebActionConfiguration,
  modifyAgentWebActionConfiguration
})(AgentWebActionConfigurationList);
