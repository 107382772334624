import { React, cloneElement } from 'react';
import { useHistory } from 'react-router';

import { Menu, Grid, Typography, Tooltip, IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import { ConstraintTypesIcons } from 'constants/PhysicalConstraintTypes';

const ErDiagramEdgeContextMenu = ({ edge, position, handleClose }) => {

  const history = useHistory();

  return (
    <Menu
      autoFocus={false}
      open={position !== null}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={position !== null ? { top: position.mouseY, left: position.mouseX } : undefined}
    >
      <div style={{ padding: '8px 16px 0' }}>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <ErDiagramEdgeContextMenuHeader constraintName={edge.data.name} constraintType={edge.data.type} />
          </Grid>
          <Grid item>
            <Tooltip title='Go to details'>
              <IconButton size='small' onClick={() => { handleClose(); history.push(`/physical-constraints/detail/${edge.data.constraintUuid}`); }}>
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <KVDefinitionList
          item={{
            'Schema': edge.data.schema
          }}
        />
      </div>
    </Menu >
  );
}

const ErDiagramEdgeContextMenuHeader = ({ constraintName, constraintType }) => {

  const constraintIcon = ConstraintTypesIcons[constraintType];

  return (
    <Grid container direction='column' justifyContent='flex-start'>
      <Grid item>
        <Grid container alignItems='center'>
          {constraintIcon &&
            <Grid item style={{ height: '24px', marginRight: '5px' }}>
              {cloneElement(constraintIcon, { style: { color: 'rgba(0, 0, 0, 0.87)' } })}
            </Grid>
          }
          <Grid item>
            <Typography variant='h6' sx={{ marginBottom: '2px', marginRight: '20px' }}>{constraintName}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant='overline' sx={{ lineHeight: 'unset' }}>{constraintType.replace('_', ' ')}</Typography>
      </Grid>
    </Grid>
  )
}

export default ErDiagramEdgeContextMenu;