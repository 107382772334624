import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { setProposalEditor, clearProposalEditor } from '../../../../actions/ProposalsActions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import withPermissions from '../../../../components/permissions/withPermissions';
import Permissions from '../../../../constants/Permissions';
import ProposalSearchModal from './ProposalSearchModal';
import { ProposalStatus } from './ProposalConstants';

function ProposeButton({
  disabled,
  resourceType,
  resourcePayload,
  resourceIdentifier,
  resourceName,
  variationType,
  onProposalChange = () => null,
  clearProposalEditor,
  setProposalEditor
}) {
  let { t } = useTranslation();
  const anchorRef = React.useRef(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [searchModalOpen, setSearchModalOpen] = React.useState(false);
  const [proposal, setProposal] = React.useState(null);
  let history = useHistory();
  function loadProposalEditor() {
    clearProposalEditor();
    setProposalEditor({
      ...(proposal || {}),
      newItems: [
        {
          resourceName,
          resourceType,
          resourcePayload,
          resourceIdentifier,
          variationType
        }
      ]
    });
    history.push('/workflows/proposals/editor');
  }
  return (
    <>
      <ButtonGroup variant='text' ref={anchorRef} aria-label='split button'>
        <Button onClick={loadProposalEditor} disabled={disabled}>
          {t('commons.actions.propose')}
        </Button>
        <Button
          aria-controls={menuOpen ? 'split-button-menu' : undefined}
          aria-expanded={menuOpen ? 'true' : undefined}
          aria-label='select proposal settings'
          aria-haspopup='menu'
          onClick={() => setMenuOpen(true)}>
          <ArrowDropDownIcon color={proposal != null ? 'primary' : 'inherit'} />
        </Button>
      </ButtonGroup>
      <ProposalSearchModal
        proposalStatus={ProposalStatus.DRAFT}
        open={searchModalOpen}
        handleCancel={() => setSearchModalOpen(false)}
        handleSubmit={(proposal) => {
          setSearchModalOpen(false);
          setProposal(proposal);
          onProposalChange(proposal);
        }}
      />
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                <MenuList id='split-button-menu'>
                  <MenuItem
                    key={1}
                    disabled={proposal !== null}
                    selected={proposal !== null}
                    onClick={(event) => setSearchModalOpen(true)}>
                    {(proposal !== null && proposal.name) || 'Select Proposal'}
                  </MenuItem>
                  <MenuItem
                    key={1}
                    disabled={proposal == null}
                    selected={proposal == null}
                    onClick={(event) => {
                      setProposal(null);
                      onProposalChange(null);
                      setMenuOpen(false);
                    }}>
                    New Proposal
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setProposalEditor,
  clearProposalEditor
};

export default compose(
  withPermissions({ hasPermissions: [Permissions.PROPOSE] }),
  connect(mapStateToProps, mapDispatchToProps)
)(ProposeButton);
