import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardContent, CardHeader, Card, CardActions } from '@mui/material';
import TaskView from './TaskView';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const TaskCardView = ({ task, children }) => {
  const [t] = useTranslation();
  return (
    <Card>
      <CardHeader
        title={task.name}
        subheader={t('tasks.fields.category').toLowerCase() + ' ' + task.category}
      />
      <CardContent>
        <TaskView task={task}></TaskView>
        {children}
      </CardContent>
      <CardActions>
        <div style={{ marginLeft: 'auto' }}>
          <WebActionsButton resource={task} page={TargetPages.TASK_DETAIL_PAGE} />
        </div>
      </CardActions>
    </Card>
  );
};

export default TaskCardView;
