import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import BdLinkify from 'components/linkify/BdLinkify';
function KVDefinitionList({ header, item }) {
  let { t } = useTranslation();

  return (
    <dl
      style={{
        overflow: 'auto',
        marginRight: '8px'
      }}>
      {header && <h4>{header}</h4>}
      {_(item)
        .flatMap((val, key) => [
          <dt
            style={{
              // margin: '0 4 0 0',
              // padding: '0 4 0 0',
              float: 'left',
              clear: 'left',
              width: '100px',
              textAlign: 'left',
              fontWeight: 400,
              color: '#00aeca'
            }}
            key={key}>{`${key}:`}</dt>,
          <dd
            style={{
              // margin: '0 0 4 0',
              // padding: '0 0 4 0',
              float: 'left',
              wordWrap: 'break-word'
            }}
            key={key + val}>
            <BdLinkify>
              {val !== undefined && val !== null ? val : t('commons.placeholders.undefined')}
            </BdLinkify>
          </dd>
        ])
        .value()}
    </dl>
  );
}

KVDefinitionList.propTypes = {
  header: PropTypes.string,
  item: PropTypes.object.isRequired
};

export default KVDefinitionList;
