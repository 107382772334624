import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  fetchMyIssues,
  modifyIssue,
  patchIssue,
  deleteIssue,
  setIssuesFilters
} from 'actions/IssuesActions';

import { Card, CardContent } from '@mui/material';

import IssuesTable from '../issues/list/IssuesTable';

const MyIssuesList = ({ currentPage, filters, fetchMyIssues, setIssuesFilters, pagination }) => {
  useEffect(() => {
    fetchMyIssues({
      page: 0,
      size: 10,
      ...filters
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  const handleFetch = (page, size) => {
    fetchMyIssues({ page, size, ...filters });
  };
  return (
    <div>
      <Card style={{ overflow: 'hidden' }}>
        <CardContent>
          <IssuesTable
            issues={currentPage.content}
            pagination={pagination}
            handleFetch={handleFetch}></IssuesTable>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  currentPage: state.campaigns.issues.currentPage,
  filters: state.campaigns.issues.filters.content,
  pagination: {
    page: state.campaigns.issues.currentPage.number,
    size: state.campaigns.issues.currentPage.size,
    totalElements: state.campaigns.issues.currentPage.totalElements
  }
});

const mapDispatchToProps = {
  fetchMyIssues,
  modifyIssue,
  patchIssue,
  deleteIssue,
  setIssuesFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(MyIssuesList);
