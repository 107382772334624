import React from 'react';
import AddFAB from '../../../../components/fab/AddFAB';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import DictionariesModal from '../commons/DictionariesModal';
import { createDictionary } from '../../../../actions/DictionariesActions';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';

function DictionariesFAB({ createDictionary }) {
  const [open, setOpen] = React.useState(false);
  let history = useHistory();

  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      <DictionariesModal
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(dictionary) => {
          createDictionary(dictionary).then((dictionary) => {
            if (dictionary) {
              setOpen(false);
            }
            history.push(`/classification/dictionaries/${dictionary.uuid}`);
          });
        }}></DictionariesModal>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  createDictionary
};

export default compose(
  withPermissions({ hasPermissions: [Permissions.CLASSIFICATION_EDITOR] }),
  connect(mapStateToProps, mapDispatchToProps)
)(DictionariesFAB);
