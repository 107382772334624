import React from 'react';
import { IconButton, Tooltip, Button } from '@mui/material';
import { connect } from 'react-redux';
import GetAppIcon from '@mui/icons-material/GetApp';

import { exportPhysicalField } from 'actions/PhysicalFieldsActions';

function PhysicalFieldsExportButton({ onClick, system, physicalEntity }) {
  return (
    <>
      {system && (
        <Button
          onClick={() =>
            onClick({
              systemUuid: system && system.uuid
            })
          }>
          Export Fields
        </Button>
      )}
      {physicalEntity && (
        <Tooltip title='Export CSV'>
          <IconButton
            size='small'
            color='default'
            aria-label='Download'
            onClick={() =>
              onClick({
                physicalEntityUuid: physicalEntity && physicalEntity.uuid,
                systemUuid: system && system.uuid
              })
            }>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: (filters) => dispatch(exportPhysicalField(filters))
  };
}

export default connect(null, mapDispatchToProps)(PhysicalFieldsExportButton);
