import { BlindataApi } from './ApiUtils';

const ROUTINE_URL = '/api/v1/routines';

export default class RoutinesApi extends BlindataApi {
  getRoutinesPage(params) {
    return this.get(ROUTINE_URL, params);
  }
  getRoutine(identifier) {
    return this.get(`${ROUTINE_URL}/${identifier}`);
  }
  postRoutine(routine) {
    return this.post(ROUTINE_URL, routine);
  }
  putRoutine(routine) {
    return this.put(`${ROUTINE_URL}/${routine.uuid}`, routine);
  }
  deleteRoutine(routine) {
    return this.delete(`${ROUTINE_URL}/${routine.uuid}`);
  }
  exportRoutines(params) {
    return this.download(`${ROUTINE_URL}/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }
  getSchemas(params) {
    return this.get(`${ROUTINE_URL}/*/schemas`, params);
  }
  getCatalog(params) {
    return this.get(`${ROUTINE_URL}/*/catalog`, params);
  }

  analyzeRoutine(routineIdentifier, params) {
    return this.postWithQueryParameters(
      `${ROUTINE_URL}/${routineIdentifier}/analyze`,
      null,
      params
    );
  }

  resetRoutine(routineIdentifier) {
    return this.post(`${ROUTINE_URL}/${routineIdentifier}/reset`, null);
  }

  confirmRoutine(routineIdentifier) {
    return this.post(`${ROUTINE_URL}/${routineIdentifier}/confirm`, null);
  }
}
