import React from 'react';
import { ProposalStatus } from '../commons/ProposalConstants';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { patchProposal } from '../../../../actions/ProposalsActions';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import ConfirmButtonWithComment from '../../../../components/confirmbutton/ConfirmButtonWithComment';
import Permissions from '../../../../constants/Permissions';
import withPermissions from '../../../../components/permissions/withPermissions';

function ManagementButtons({ patchProposal, proposal }) {
  if (![ProposalStatus.SUBMITTED, ProposalStatus.ACKNOWLEDGED].includes(proposal.proposalStatus)) {
    return null;
  }
  return (
    <>
      <ProposerButtons patchProposal={patchProposal} proposal={proposal} />
      <ApproverButtons patchProposal={patchProposal} proposal={proposal} />
    </>
  );
}

const ProposerButtons = withPermissions({ hasPermissions: [Permissions.PROPOSE] })(function ({
  proposal,
  patchProposal
}) {
  const { t } = useTranslation();
  return (
    <ConfirmButtonWithComment
      message='Do you want to cancel this proposal?'
      onClick={({ comment }) =>
        patchProposal({ uuid: proposal.uuid, proposalStatus: ProposalStatus.CANCELED, comment })
      }>
      {t('commons.actions.delete')}
    </ConfirmButtonWithComment>
  );
});

const ApproverButtons = withPermissions({ hasPermissions: [Permissions.APPROVE] })(function ({
  patchProposal,
  proposal
}) {
  let { uuid } = proposal;
  const { t } = useTranslation();
  return (
    <>
      <ConfirmButton
        disabled={proposal.proposalStatus !== ProposalStatus.SUBMITTED}
        message='Do you want to acknowledge this proposal?'
        onClick={() => patchProposal({ uuid, proposalStatus: ProposalStatus.ACKNOWLEDGED })}>
        Acknowledge
      </ConfirmButton>

      <ConfirmButtonWithComment
        message='Do you want to refuse this proposal?'
        color={'secondary'}
        children={t('commons.actions.refuse')}
        onClick={({ comment }) =>
          patchProposal({ uuid, proposalStatus: ProposalStatus.REFUSED, comment })
        }></ConfirmButtonWithComment>

      <ConfirmButtonWithComment
        message='Do you want to approve this proposal?'
        color={'primary'}
        children={t('commons.actions.approve')}
        onClick={({ comment }) =>
          patchProposal({ uuid, proposalStatus: ProposalStatus.APPROVED, comment })
        }></ConfirmButtonWithComment>
    </>
  );
});

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  patchProposal
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagementButtons);
