import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ActionDataCategories from '@mui/icons-material/Extension';
import ActionWork from '@mui/icons-material/Work';
import { ThumbsUpDown, Assignment, AssignmentInd, MenuOpen, PushPin } from '@mui/icons-material';
import ActionUsers from '@mui/icons-material/SupervisorAccount';
import ActionProcessings from '@mui/icons-material/Gavel';
import ActionDataSubjects from '@mui/icons-material/Face';
import ActionConsents from '@mui/icons-material/ThumbUp';
import ActionContracts from '@mui/icons-material/Code';
import ActionGraph from '@mui/icons-material/Explore';
import ActionDataSources from '@mui/icons-material/Business';
import ActionDataFlows from '@mui/icons-material/SwapCalls';
import ActionQualityKqis from '@mui/icons-material/NetworkCheck';
import ActionQualityProbes from '@mui/icons-material/SettingsInputComponent';
import ActionQualityAssessment from '@mui/icons-material/VerifiedUser';
import CategoryIcon from '@mui/icons-material/Category';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ThemeProvider } from '@mui/material/styles';
import { muiThemeV1 } from './SideBarMenuTheme';
import { Link } from 'react-router-dom';

import './SideBarMenu.css';
import UserAccountMenu from './UserAccountMenu';
import LogoHorizontal from '../../images/logo_oriz_white.png';
import Authorize from '../../components/permissions/Authorize';
import Permissions from '../../constants/Permissions';
import { IconButton, ListItemIcon, ListItemText } from '@mui/material';

function SideBarMenu({ docked, onItemSelected, onDockChange }) {
  let { t } = useTranslation();
  return (
    <ThemeProvider theme={muiThemeV1}>
      <div>
        <IconButton
          style={{ right: '4px', top: '4px', position: 'fixed' }}
          size='small'
          onClick={onDockChange}>
          {docked ? <MenuOpen /> : <PushPin />}
        </IconButton>

        <Link to='/'>
          <img id='sidebar-logo' src={LogoHorizontal} alt='Logo' />
        </Link>

        <List id='sidebar-List'>
          <UserAccountMenu onItemSelected={onItemSelected} />

          <Authorize
            hasAnyPermissions={[Permissions.DATA_CATEGORIES_VIEWER, Permissions.SYSTEMS_VIEWER]}>
            <MenuSubheader>{t('sidebar.barLabels.metadataRegistry')}</MenuSubheader>
          </Authorize>

          <Authorize hasPermissions={[Permissions.DATA_CATEGORIES_VIEWER]}>
            <MenuListItem
              text={t('sidebar.logical_model')}
              path='/datacategories'
              icon={<ActionDataCategories className='drawer-icon' />}
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasPermissions={[Permissions.SYSTEMS_VIEWER]}>
            <MenuListItem
              text={t('sidebar.physical_model')}
              path='/systems'
              icon={<ActionDataSources className='drawer-icon' />}
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasPermissions={[Permissions.SYSTEMS_VIEWER]}>
            <MenuListItem
              text={t('sidebar.data_flows')}
              path='/dataflows'
              icon={<ActionDataFlows className='drawer-icon' />}
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize
            hasAnyPermissions={[Permissions.DATA_CATEGORIES_VIEWER, Permissions.SYSTEMS_VIEWER]}>
            <MenuListItem
              text={t('sidebar.graph')}
              path='/graph'
              icon={<ActionGraph className='drawer-icon' />}
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasPermissions={[Permissions.CLASSIFICATION_VIEWER]}>
            <MenuListItem
              text={t('sidebar.classification')}
              path='/classification'
              icon={<SortByAlphaIcon className='drawer-icon' />}
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasAnyPermissions={[Permissions.QUALITY_VIEWER]}>
            <MenuSubheader>{t('sidebar.barLabels.dataQuality')}</MenuSubheader>
          </Authorize>

          <Authorize hasPermissions={[Permissions.QUALITY_VIEWER]}>
            <MenuListItem
              text={t('sidebar.kqis')}
              path='/data-quality/kqis/suites'
              icon={<ActionQualityKqis className='drawer-icon' />}
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasPermissions={[Permissions.PROBES_VIEWER]}>
            <MenuListItem
              text={t('sidebar.qualityProbes')}
              path='/data-quality/probes/projects'
              icon={<ActionQualityProbes className='drawer-icon' />}
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasPermissions={[Permissions.QUALITY_ASSMNT_VIEWER]}>
            <MenuListItem
              text={t('sidebar.qualityAssessment')}
              path='/data-quality/assessment/risks'
              icon={<ActionQualityAssessment className='drawer-icon' />}
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize
            hasAnyPermissions={[
              Permissions.DATA_ACTORS_VIEWER,
              Permissions.PROCESSINGS_VIEWER,
              Permissions.TASKS_VIEWER
            ]}>
            <MenuSubheader>{t('sidebar.barLabels.processingsRegistry')}</MenuSubheader>
          </Authorize>

          <Authorize hasPermissions={[Permissions.PROCESSINGS_VIEWER]}>
            <MenuListItem
              text={t('sidebar.processings')}
              icon={<ActionProcessings className='drawer-icon' />}
              path='/processings'
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasPermissions={[Permissions.TASKS_VIEWER]}>
            <MenuListItem
              text={t('sidebar.tasks')}
              icon={<ActionWork className='drawer-icon' />}
              path='/tasks'
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasPermissions={[Permissions.DATA_ACTORS_VIEWER]}>
            <MenuListItem
              text={t('sidebar.data_actors')}
              icon={<ActionUsers className='drawer-icon' />}
              path='/data_actors'
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasAnyPermissions={[Permissions.TASKS_VIEWER]}>
            <MenuListItem
              text={t('sidebar.templates')}
              icon={<CategoryIcon className='drawer-icon' />}
              path='/templates'
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize
            hasAnyPermissions={[
              Permissions.PROPOSE,
              Permissions.APPROVE,
              Permissions.STEWARDSHIP_VIEWER,
              Permissions.CAMPAIGNS_VIEWER
            ]}>
            <MenuSubheader>{t('sidebar.barLabels.management')}</MenuSubheader>
          </Authorize>

          <Authorize hasPermissions={[Permissions.STEWARDSHIP_VIEWER]}>
            <MenuListItem
              text={t('sidebar.stewardship')}
              path='/stewardship'
              icon={<AssignmentInd className='drawer-icon' />}
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasAnyPermissions={[Permissions.PROPOSE, Permissions.APPROVE]}>
            <MenuListItem
              text={t('sidebar.proposals')}
              icon={<ThumbsUpDown className='drawer-icon' />}
              path='/workflows/proposals'
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasPermissions={[Permissions.CAMPAIGNS_VIEWER]}>
            <MenuListItem
              text={t('sidebar.issues')}
              icon={<Assignment className='drawer-icon' />}
              path='/issue-management/my-work'
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasPermissions={[Permissions.CONSENTS_VIEWER]}>
            <MenuSubheader>{t('sidebar.barLabels.consentsNotary')}</MenuSubheader>
          </Authorize>

          <Authorize hasPermissions={[Permissions.CONSENTS_VIEWER]}>
            <MenuListItem
              text={t('sidebar.data_subjects')}
              icon={<ActionDataSubjects className='drawer-icon' />}
              path='/datasubjects'
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasPermissions={[Permissions.CONSENTS_VIEWER]}>
            <MenuListItem
              text={'Consents'}
              icon={<ActionConsents className='drawer-icon' />}
              path='/consents'
              onItemSelected={onItemSelected}
            />
          </Authorize>

          <Authorize hasPermissions={[Permissions.CONTRACTS_VIEWER]}>
            <MenuListItem
              text={t('sidebar.contracts')}
              icon={<ActionContracts className='drawer-icon' />}
              path='/contracts'
              onItemSelected={onItemSelected}
            />
          </Authorize>
        </List>
      </div>
    </ThemeProvider>
  );
}

const MenuListItem = ({ icon, path, text, onItemSelected }) => (
  <ListItem button component={Link} to={path} onClick={onItemSelected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText children={text} />
  </ListItem>
);

const MenuSubheader = ({ children }) => (
  <div style={{ paddingLeft: '12px' }}>
    <Typography variant='body1' color='secondary'>
      {children}
    </Typography>
  </div>
);

SideBarMenu.propTypes = {
  onItemSelected: PropTypes.func
};

export default SideBarMenu;
