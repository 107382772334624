import React from 'react';
import { withTranslation } from 'react-i18next';

import LogicalFieldEditModal from 'pages/logicalfields/commons/LogicalFieldEditModal';
import AlertDialog from 'components/alertdialog/AlertDialog';

const LogicalFieldsActionsModals = ({
  selectedField,
  editModalOpen,
  setEditModalOpen,
  onEdit,
  deleteModalOpen,
  setDeleteModalOpen,
  onDelete,
  t
}) => {

  return (
    <>

      {/* Edit modal */}
      <LogicalFieldEditModal
        title={`${t('commons.actions.modify')} Logical Field`}
        open={editModalOpen}
        key={selectedField?.uuid}
        logicalField={selectedField}
        handleSubmit={(field) => onEdit(field)}
        handleCancel={() => setEditModalOpen(false)}
      />

      {/* Delete modal */}
      <AlertDialog
        message={`Do you really want to delete this logical field (${selectedField?.name || '-'})?`}
        open={deleteModalOpen}
        onOk={() => onDelete(selectedField)}
        onCancel={() => setDeleteModalOpen(false)}
      />

    </>
  )
}

export default withTranslation()(LogicalFieldsActionsModals);