import { buildApiCallAction } from 'actions/ActionUtils';
import WebHooksApi from 'api/settings/webhooks/WebHooksApi';
import {
  CREATE_WEB_HOOK,
  DELETE_WEB_HOOK,
  FETCH_WEB_HOOK,
  FETCH_WEB_HOOKS_PAGE,
  MODIFY_WEB_HOOK
} from 'constants/ActionTypes';

const api = new WebHooksApi();

export const fetchWebHooksPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getWebHooks(params),
    actionType: FETCH_WEB_HOOKS_PAGE
  });

export const fetchWebHook = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getWebHook(uuid),
    actionType: FETCH_WEB_HOOK
  });
export const createWebHook = (webHook) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postWebHook(webHook),
    actionType: CREATE_WEB_HOOK
  });

export const modifyWebHook = (webHook) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putWebHook(webHook),
    actionType: MODIFY_WEB_HOOK
  });

export const deleteWebHook = (webHook) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteWebHook(webHook.uuid),
    actionType: DELETE_WEB_HOOK,
    payload: webHook
  });
