import React from 'react';
import { useTranslation } from 'react-i18next';

import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';

import { Button, Typography } from '@mui/material';
import ExpandableCard from 'components/expandable-card/ExpandableCard';
import DefinitionList from 'components/definition-list/DefinitionList';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';

function AgentConnectionsListItem({ connection, handleTest, handleEdit, handleDelete }) {
  const { t } = useTranslation();

  const connectionProperties = connection?.properties
    ? Object.entries(connection?.properties).map(([key, value]) => ({ name: key, value }))
    : [];

  return (
    <ExpandableCard
      title={connection.name}
      subheader={connection.type}
      actions={
        <>
          <Button children={t('commons.actions.test')} onClick={handleTest}></Button>
          <Authorize hasPermissions={[Permissions.AGENTS_ADMIN]}>
            <Button onClick={handleEdit}>{t('commons.actions.modify')}</Button>
          </Authorize>
          <Authorize hasPermissions={[Permissions.AGENTS_ADMIN]}>
            <ConfirmButton
              message={`Do you want to delete connection '${connection.name}'`}
              children={t('data_categories.actions.delete')}
              onClick={handleDelete}></ConfirmButton>
          </Authorize>
        </>
      }>
      <h4>Connection Properties</h4>
      {connectionProperties.length > 0 ? (
        <DefinitionList
          items={connectionProperties}
          labelProperty='name'
          dataProperties={['value']}
        />
      ) : (
        <Typography variant='overline'>{t('commons.placeholders.none')}</Typography>
      )}
    </ExpandableCard>
  );
}

export default AgentConnectionsListItem;
