import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { deleteRole, modifyRole } from '../../../../actions/StewardshipActions';
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import PermissionsEditor from '../../../settings/users/commons/PermissionsEditor';
import ResourceApplicability from '../commons/ResourceApplicability';
import StewardshipRoleModal from '../commons/StewardshipRoleModal';
import PreAuthorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';

function StewardshipRoleCard({ stewardshipRole, onDelete, onModify }) {
  let { t } = useTranslation();
  let history = useHistory();

  return (
    <Card>
      <CardHeader title={stewardshipRole.name} subheader={stewardshipRole.roleCategory} />
      <CardContent>
        <Typography variant='body1' paragraph>
          {stewardshipRole.description}
        </Typography>

        <ResourceApplicability resources={stewardshipRole.resources}></ResourceApplicability>
        <h4>Permissions</h4>
        <PermissionsEditor permissions={stewardshipRole.permissions}></PermissionsEditor>
      </CardContent>
      <PreAuthorize hasPermissions={[Permissions.STEWARDSHIP_ADMIN]}>
        <CardActions>
          <ModifyAction stewardshipRole={stewardshipRole} onModify={onModify}></ModifyAction>
          <ConfirmButton onClick={() => onDelete(stewardshipRole).then(() => history.goBack())}>
            {t('commons.actions.delete')}
          </ConfirmButton>
        </CardActions>
      </PreAuthorize>
    </Card>
  );
}

function ModifyAction({ stewardshipRole, onModify }) {
  let [open, setOpen] = React.useState(false);
  let { t } = useTranslation();
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      <StewardshipRoleModal
        open={open}
        stewardshipRole={stewardshipRole}
        onClose={() => setOpen(false)}
        onSubmit={(stewardshipRole) => {
          onModify(stewardshipRole);
          setOpen(false);
        }}></StewardshipRoleModal>
    </>
  );
}

const mapDispatchToProps = {
  onDelete: deleteRole,
  onModify: modifyRole
};

export default connect(null, mapDispatchToProps)(StewardshipRoleCard);
