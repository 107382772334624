import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { createProbeDefinition } from 'actions/quality/probes/ProbesDefinitionsActions';
import { IconButton, Tooltip } from '@mui/material';
import QualityProbesDefinitionModal from './QualityProbesDefinitionModal';

function QualityProbesDefinitionAddButton({ createProbeDefinition, project }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title={'Create Probe'}>
        <IconButton onClick={() => setOpen(true)} size='medium'>
          <AddBoxIcon></AddBoxIcon>
        </IconButton>
      </Tooltip>
      {open && (
        <QualityProbesDefinitionModal
          open={open}
          passedProject={project}
          title='Create Probe Definition'
          onClose={() => setOpen(false)}
          onSubmit={(probeDefinition) => {
            createProbeDefinition(probeDefinition);
            setOpen(false);
          }}
        />
      )}
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.PROBES_EDITOR] }),
  connect(null, { createProbeDefinition })
)(QualityProbesDefinitionAddButton);
