import React from 'react';
import { connect } from 'react-redux';
import KVDefinitionList from '../../components/definition-list/KVDefinitionList';
import { fetchProofs, fetchRecords } from '../../actions/NotaryActions';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import conv from 'convert-hex';
import { Typography, CardActions } from '@mui/material';
import ExpandableCard from '../../components/expandable-card/ExpandableCard';

const NotaryRecord = ({ record, proofs, fetchProofs }) => (
  <ExpandableCard
    style={{ margin: '16px' }}
    onExpandChange={() => {
      if (record.status === 'SUBMITTED') fetchProofs(record);
    }}
    title={record.createdAt}
    subheader={record.hash}>
    <KVDefinitionList
      item={{
        Uuid: record.uuid,
        Hash: record.hash,
        'Hash Alg': record.hashAlgorithm,
        'Hash Rep': record.hashRepresentation,
        Payload: record.payload,
        Encoding: record.encoding,
        'Record Type': record.recordType,
        Status: record.status,
        'Created At': record.createdAt,
        'Updated At': record.updatedAt
      }}
    />
    {record.aggregatedRecord ? <h3>Aggregated Record</h3> : null}
    {record.aggregatedRecord ? <NotaryRecordContainer record={record.aggregatedRecord} /> : null}
    {proofs ? <h3>Proofs</h3> : null}
    {proofs ? proofs.map((p) => <NotaryProofItem record={record} proof={p} />) : null}
  </ExpandableCard>
);

const NotaryProofItem = ({ record, proof }) => (
  <ExpandableCard
    title={proof.createdAt}
    subheader={proof.status}
    visibleChildren={
      <CardActions>
        <Button
          disabled={!proof.finalReceipt}
          children={'Download Data'}
          onClick={() => downloadData(record.payload)}
        />
        <Button
          disabled={!proof.finalReceipt}
          children={'Download OTS'}
          onClick={() => downloadOTS(proof.finalReceipt)}
        />
      </CardActions>
    }>
    <KVDefinitionList item={proof} />
  </ExpandableCard>
);

const downloadOTS = (otsString) => {
  let otsByte = conv.hexToBytes(otsString);
  let prova = conv.bytesToHex(otsByte);
  let check = prova === otsString;
  console.log(`Generating OTS: check is ${check}`);
  var ab = new ArrayBuffer(otsByte.length); //bytes is the array with the integer
  var ia = new Uint8Array(ab);
  for (var i = 0; i < otsByte.length; i++) {
    ia[i] = otsByte[i];
  }
  var blob = new Blob([ia], { type: 'application/octet-stream' });
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = 'consent.ots';
  link.download = fileName;
  link.click();
};

const downloadData = (dataString) => {
  var utf8 = unescape(encodeURIComponent(dataString));
  var arr = [];
  for (let i = 0; i < utf8.length; i++) {
    arr.push(utf8.charCodeAt(i));
  }
  var ab = new ArrayBuffer(arr.length); //bytes is the array with the integer
  var ia = new Uint8Array(ab);
  for (let i = 0; i < arr.length; i++) {
    ia[i] = arr[i];
  }
  var blob = new Blob([ia], { type: 'application/octet-stream' });
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = 'consent.dat';
  link.download = fileName;
  link.click();
};

const mapStateToProps = (state, props) => ({
  proofs: state.notary.proofsByRecordUuid[props.record.uuid]
});

const NotaryRecordContainer = connect(mapStateToProps, { fetchProofs })(NotaryRecord);

class NotaryRegistry extends React.Component {
  componentDidMount() {
    this.props.fetchRecords({
      resourceType: this.props.resourceType,
      resourceIdentifier: this.props.resourceIdentifier
    });
  }

  render() {
    return (
      <div>
        <Typography style={{ margin: '16px 0 8px 0' }} variant='h6'>
          NotaryRegistry
        </Typography>
        {this.props.records ? (
          this.props.records.map((r) => <NotaryRecordContainer key={r.uuid} record={r} />)
        ) : (
          <Paper style={{ textAlign: 'center', padding: 16 }}>Not yet notarized</Paper>
        )}
      </div>
    );
  }
}

const mapStateToPropsOfRegistry = (state, props) => ({
  records: state.notary.recordsByResourceIdentifier[props.resourceIdentifier]
});

export default connect(mapStateToPropsOfRegistry, { fetchRecords })(NotaryRegistry);
