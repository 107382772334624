import { combineReducers } from 'redux';
import crudReducers from 'reducers/utils';
import filtersReducer from 'reducers/utils/filters';
import ResourceTypes from 'constants/ResourceTypes';
import dataflowpreviews from './dataflowpreviews';
import {
  FETCH_QUERY_STATEMENT_FULL_LINEAGE,
  FETCH_QUERY_STATEMENT_ENDING_NODES,
  FETCH_DATA_FLOWS_INVOLVED_STATEMENTS
} from 'constants/ActionTypes';

const filtersDefault = {
  search: '',
  statuses: [],
  creationDateGt: null,
  creationDateLt: null
};

function fullLineage(state = {}, action) {
  switch (action.type) {
    case FETCH_QUERY_STATEMENT_FULL_LINEAGE:
      return {
        ...state,
        [action.payload.queryStatementUuid]: action.payload
      };
    default:
      return state;
  }
}

function dataflowsinvolved(state = {}, action) {
  switch (action.type) {
    case FETCH_DATA_FLOWS_INVOLVED_STATEMENTS:
      return action.payload;
    default:
      return state;
  }
}

function endingNodes(state = {}, action) {
  switch (action.type) {
    case FETCH_QUERY_STATEMENT_ENDING_NODES:
      return {
        ...state,
        [action.payload.queryStatementUuid]: action.payload.endingProjectionNodes
      };
    default:
      return state;
  }
}

export default combineReducers({
  ...crudReducers({ resource: ResourceTypes.QUERY_STATEMENT, keyProperty: 'uuid' }),
  filters: filtersReducer({
    resource: ResourceTypes.QUERY_STATEMENT,
    filtersDefault: filtersDefault
  }),
  dataflowpreviews,
  dataflowsinvolved,
  fullLineage,
  endingNodes
});
