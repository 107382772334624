import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import ResourceTypes from 'constants/ResourceTypes';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';

import QualitySuiteItemAutoComplete from 'pages/quality/kqis/quality_checks/commons/QualitySuiteItemAutoComplete';
import QualityCheckGroupCodeItemAutocomplete from 'pages/quality/kqis/quality_checks/commons/QualityCheckGroupCodeItemAutocomplete';
import AdditionalPropertiesEditor from 'components/additional_properties/AdditionalPropertiesEditor';
import QualityCheckPhysicalAssociationTable from 'pages/quality/kqis/quality_checks/commons/QualityCheckPhysicalAssociationTable';

import { HelpIcon } from 'components/help';

const QualityCheckModal = ({ open, qualitySuite, qualityCheck, onSubmit, onClose }) => {
  const { t } = useTranslation();

  const INITIAL_STATE = {
    qualitySuite,
    code: '',
    name: '',
    checkGroupCode: '',
    description: '',
    successThreshold: 100,
    warningThreshold: 95,
    enabled: qualitySuite.published,
    isManual: false,
    scoreStrategy: 'PERCENTAGE',
    scoreRightValue: '',
    scoreLeftValue: '',
    scoreExpectedValue: '',
    additionalProperties: []
  };
  const [editor, setEditor] = useState(qualityCheck || INITIAL_STATE);
  const handleChange = (change) => setEditor({ ...editor, ...change });

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>Quality Check</DialogTitle>
      <DialogContent>
        <QualitySuiteItemAutoComplete
          value={editor.qualitySuite}
          onChange={(suite) => setEditor({ ...editor, qualitySuite: suite })}
        />
        <QualityCheckDefinitionSection
          editor={editor}
          suitePublished={editor.qualitySuite?.published}
          handleChange={handleChange}
        />
        <ScoreStrategySection editor={editor} handleChange={handleChange} />
        <MoreFieldsSection editor={editor} handleChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.cancel')} onClick={onClose} />
        <Button
          children={t('commons.actions.submit')}
          variant='contained'
          color='primary'
          disabled={disabled(editor)}
          onClick={() => onSubmit(editor)}
          // onClick={() => {
          //   if (editor.scoreStrategy === 'PERCENTAGE') {
          //     onSubmit({ ...editor, scoreRightValue: '', scoreLeftValue: '', scoreExpectedValue: '' });
          //   } else if (editor.scoreStrategy === 'MINIMUM') {
          //     onSubmit({ ...editor, scoreRightValue: '' });
          //   } else if (editor.scoreStrategy === 'MAXIMUM') {
          //     onSubmit({ ...editor, scoreLeftValue: '' });
          //   } else {
          //     onSubmit(editor);
          //   }
          // }}
        />
      </DialogActions>
    </Dialog>
  );
};

const QualityCheckDefinitionSection = ({ editor, suitePublished, handleChange }) => {
  return (
    <>
      <h4>Quality Check Definition</h4>
      <TextField
        variant='standard'
        required
        fullWidth
        margin='dense'
        label={'Check Code'}
        placeholder={'Enter the quality check code'}
        value={editor.code}
        onChange={(event) => handleChange({ code: event.target.value })}
      />
      <TextField
        variant='standard'
        required
        fullWidth
        margin='dense'
        label={'Name'}
        placeholder={'Enter the quality check name'}
        value={editor.name}
        onChange={(event) => handleChange({ name: event.target.value })}
      />
      <QualityCheckGroupCodeItemAutocomplete
        checkGroupCode={editor.checkGroupCode}
        onChange={(checkGroupCode) => handleChange({ checkGroupCode })}
      />
      <TextField
        variant='standard'
        fullWidth
        margin='dense'
        multiline
        label={'Description'}
        placeholder={'Enter the quality check description'}
        value={editor.description}
        onChange={(event) => handleChange({ description: event.target.value })}
      />
      <TextField
        variant='standard'
        required
        fullWidth
        margin='dense'
        type='number'
        label={'Success Threshold'}
        placeholder={'Enter the success threshold (integer between 0 and 100)'}
        value={editor.successThreshold}
        onChange={(event) => handleChange({ successThreshold: event.target.value })}
        error={isNonValidThreshold(editor.successThreshold)}
      />
      <TextField
        variant='standard'
        required
        fullWidth
        margin='dense'
        type='number'
        label={'Warning Threshold'}
        placeholder={'Enter the warning threshold (integer between 0 and 100)'}
        value={editor.warningThreshold}
        onChange={(event) => handleChange({ warningThreshold: event.target.value })}
        error={isNonValidThreshold(editor.warningThreshold)}
      />
      <FormControlLabel
        label='Is Check Enabled'
        control={
          <Checkbox
            size='small'
            name='enabled'
            checked={editor.enabled || false}
            disabled={!suitePublished}
            onChange={(event) => handleChange({ enabled: event.target.checked })}
          />
        }
      />
      <FormControlLabel
        label='Manual Insert'
        control={
          <Checkbox
            size='small'
            name='manual insert'
            checked={editor.isManual}
            onChange={(event) => handleChange({ isManual: event.target.checked })}
          />
        }
      />
    </>
  );
};

const ScoreStrategySection = ({ editor, handleChange }) => {
  return (
    <>
      <h4>Score Strategy</h4>
      <ScoreStrategySelect editor={editor} handleChange={handleChange} />
      {(editor.scoreStrategy === 'DISTANCE' || editor.scoreStrategy === 'MAXIMUM') && (
        <TextField
          variant='standard'
          required
          fullWidth
          margin='dense'
          type='number'
          label={'Highest Acceptable Value'}
          placeholder={'Enter the highest acceptable value'}
          value={editor.scoreRightValue}
          onChange={(event) => handleChange({ scoreRightValue: event.target.value })}
        />
      )}
      {(editor.scoreStrategy === 'DISTANCE' || editor.scoreStrategy === 'MINIMUM') && (
        <TextField
          variant='standard'
          required
          fullWidth
          margin='dense'
          type='number'
          label={'Lowest Acceptable Value'}
          value={editor.scoreLeftValue}
          placeholder={'Enter the lowest acceptable value'}
          onChange={(event) => handleChange({ scoreLeftValue: event.target.value })}
        />
      )}
      {(editor.scoreStrategy === 'DISTANCE' ||
        editor.scoreStrategy === 'MAXIMUM' ||
        editor.scoreStrategy === 'MINIMUM') && (
        <TextField
          variant='standard'
          required
          fullWidth
          margin='dense'
          type='number'
          label={'Expected Value'}
          placeholder={'Enter the expected value'}
          value={editor.scoreExpectedValue}
          onChange={(event) => handleChange({ scoreExpectedValue: event.target.value })}
        />
      )}
    </>
  );
};

const ScoreStrategySelect = ({ editor, handleChange }) => {
  return (
    <FormControl variant='standard' required fullWidth margin='dense'>
      <InputLabel id='score-strategy-select-label'>Score Strategy Type</InputLabel>
      <Select
        variant='standard'
        labelId='score-strategy-select-label'
        value={editor.scoreStrategy}
        onChange={(event) => handleChange({ scoreStrategy: event.target.value })}>
        <MenuItem value={'PERCENTAGE'}>
          <ListItem dense>
            <ListItemText primary={'Percentage'} />
            <ListItemIcon>
              <HelpIcon
                tooltip={
                  'This strategy calculate the ratio between the total elements decreased by the metric and the overall total elements [i.e. (total elements - metric) / total elements]. Metric is intended to count for the number of errors over the totale elements analyzed.'
                }
              />
            </ListItemIcon>
          </ListItem>
        </MenuItem>
        <MenuItem value={'PERCENTAGE_DEVIATION'}>
          <ListItem dense>
            <ListItemText primary={'Percentage Deviation'} />
            <ListItemIcon>
              <HelpIcon
                tooltip={
                  'This strategy calculate the deviation ratio between the total elements and the metric [i.e. 1 - abs(total elements - metric) / total elements]'
                }
              />
            </ListItemIcon>
          </ListItem>
        </MenuItem>
        <MenuItem value={'MINIMUM'}>
          <ListItem dense>
            <ListItemText primary={'Minimum'} />
            <ListItemIcon>
              <HelpIcon
                tooltip={`This strategy calculates a score that expresses how much a metric is higher than a minimum cap [i.e. 1 - ((expected value - metric) / lowest acceptable value)].
                    If the metric is higher than the expected value, the score will be 100%.
                    If the metric is lower than the minimum acceptable value, the score will be 0%.
                    If the metric is between the lowest acceptable value and the expected value, the score will be a percentual representation of the distance from the expected value.`}
              />
            </ListItemIcon>
          </ListItem>
        </MenuItem>
        <MenuItem value={'MAXIMUM'}>
          <ListItem dense>
            <ListItemText primary={'Maximum'} />
            <ListItemIcon>
              <HelpIcon
                tooltip={`This strategy calculates a score that expresses how much a metric is lower than a maximum cap [i.e. 1 - ((expected value - metric) / highest acceptable value)].
                    If the metric is lower than the expected value, the score will be 100%.
                    If the metric is higher than the maximum acceptable value, the score will be 0%.
                    If the metric is between the expected value and the highest acceptable value, the score will be a percentual representation of the distance from the expected value.`}
              />
            </ListItemIcon>
          </ListItem>
        </MenuItem>
        <MenuItem value={'DISTANCE'}>
          <ListItem dense>
            <ListItemText primary={'Distance'} />
            <ListItemIcon>
              <HelpIcon
                tooltip={`This strategy calculates a score that expresses how much the metric is close to an expected value and between a fixed range.
                    If the metric is equal to the expected value, the score will be 100%.
                    If the metric is lower than the minimum acceptable value or it is higher than the maximum acceptable value, the score will be 0%.
                    If the metric is between the lower acceptable value and the highest acceptable value, the score will be a percentual representation of the distance from the expected value.`}
              />
            </ListItemIcon>
          </ListItem>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const MoreFieldsSection = ({ editor, handleChange }) => {
  return (
    <>
      <AdditionalPropertiesEditor
        additionalProperties={editor.additionalProperties}
        resourceType={ResourceTypes.QUALITY_CHECK}
        onChange={(additionalProperties) => handleChange({ additionalProperties })}
      />
      <QualityCheckPhysicalAssociationTable
        qualityCheck={editor}
        onChange={({ physicalEntities, physicalFields }) =>
          handleChange({ physicalEntities, physicalFields })
        }
      />
    </>
  );
};

const disabled = (qualityCheck) => {
  const isQualitySuiteNull = qualityCheck.uuid && !qualityCheck.qualitySuite;
  const isNameNotValid = !qualityCheck.name;
  const isCodeNotValid = !qualityCheck.code;
  const isSuccesThresholdNotValid = empty(qualityCheck.successThreshold);
  const isWarningThresholdNotValid = empty(qualityCheck.warningThreshold);
  const isMinNotValid =
    qualityCheck.scoreStrategy === 'MINIMUM' &&
    (empty(qualityCheck.scoreLeftValue) || empty(qualityCheck.scoreExpectedValue));
  const isMaxNotValid =
    qualityCheck.scoreStrategy === 'MAXIMUM' &&
    (empty(qualityCheck.scoreRightValue) || empty(qualityCheck.scoreExpectedValue));
  const isDistanceNotValid =
    qualityCheck.scoreStrategy === 'DISTANCE' &&
    (empty(qualityCheck.scoreLeftValue) ||
      empty(qualityCheck.scoreRightValue) ||
      empty(qualityCheck.scoreExpectedValue));
  return (
    isQualitySuiteNull ||
    isNameNotValid ||
    isCodeNotValid ||
    isSuccesThresholdNotValid ||
    isWarningThresholdNotValid ||
    isMinNotValid ||
    isMaxNotValid ||
    isDistanceNotValid ||
    isNonValidThreshold(qualityCheck.successThreshold) ||
    isNonValidThreshold(qualityCheck.warningThreshold)
  );
};
const empty = (value) => {
  return value === null || value === undefined || value === '';
};
const isNonValidThreshold = (threshold) => {
  if (empty(threshold)) return true;
  return !_.inRange(_.toNumber(threshold), 0, 101);
};

export default QualityCheckModal;
