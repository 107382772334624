import { buildApiCallAction } from 'actions/ActionUtils';
import ErDiagramApi from 'api/physical/ErDiagramApi';
import { FETCH_ER_DIAGRAM_DATA } from 'constants/ActionTypes';

const erDiagramApi = new ErDiagramApi();

export const fetchErDiagramData = (params) =>
  buildApiCallAction({
    api: erDiagramApi,
    apiFunction: (api) => api.getErDiagramData(params),
    actionType: FETCH_ER_DIAGRAM_DATA
  });