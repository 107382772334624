import React, { useEffect } from 'react';
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Button,
  Table,
  CardActions,
  Grid
} from '@mui/material';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import { useHistory } from 'react-router';
import { Warning } from '@mui/icons-material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import {
  fetchTag,
  deleteTag,
  patchTag,
  restoreProbesFromTag
} from 'actions/quality/probes/ProbesTagsActions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QualityProbesDefinitionsTable from '../../definitions/list/QualityProbesDefinitionsTable';
import QualityProbesTagModal from '../common/QualityProbesTagModal';
import QualityProbesExportButton from '../../projects/commons/QualityProbesExportButton';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import ProjectTestButton from '../../definitions/list/ProjectTestButton';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

function QualityProbesTagDetailPage({
  tagUuid,
  fetchTag,
  deleteTag,
  patchTag,
  tag,
  restoreProbesFromTag
}) {
  let { t } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    fetchTag(tagUuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTag]);
  if (!tag) {
    return null;
  }
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.qualityProbes'), to: '/data-quality/probes/projects' },
          { text: tag.project?.name, to: `/data-quality/probes/projects/${tag.project?.uuid}` },
          { text: tag.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('quality.probes.tags.detailPageHeader', {
            tagName: `${tag.project?.name}:${tag?.name}`
          }),
          category: EngagementCategories.DATA_QUALITY_PROBES,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container>
        <Grid item>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            {t('quality.probes.tags.detailPageHeader', {
              tagName: `${tag.project?.name}:${tag?.name}`
            })}
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <QualityProbesExportButton tagUuid={tag.uuid} projectUuid={tag.project.uuid} />
        </Grid>
      </Grid>
      <Card>
        <CardHeader title={tag.name} subheader={tag.project?.name} />
        <CardContent>
          <Typography variant='body1' paragraph>
            {tag.description}
          </Typography>
        </CardContent>
        <Authorize hasPermissions={[Permissions.PROBES_EDITOR]}>
          <CardActions>
            <ModifyButton tag={tag} patchTag={patchTag}></ModifyButton>
            <ConfirmButton
              message={
                <>
                  <Grid container direction='column' spacing={3}>
                    <Grid item>
                      <Typography variant='body1'>Do you want do delete {tag.name}?</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems='center' direction='row' spacing={1}>
                        <Grid item>
                          <Warning color='secondary'></Warning>
                        </Grid>
                        <Grid item>
                          <Typography variant='caption'>
                            This action will not delete associated schedules
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
              onClick={() => deleteTag(tagUuid).then(() => history.goBack())}>
              {t('commons.actions.delete')}
            </ConfirmButton>
            <ConfirmButton
              message={'Do you want to restore this probes?'}
              onClick={() => {
                restoreProbesFromTag(tagUuid).then(() =>
                  history.push(`/data-quality/probes/projects/${tag.project.uuid}`)
                );
              }}>
              Restore
            </ConfirmButton>
          </CardActions>
        </Authorize>
      </Card>
      <Card>
        <CardHeader
          title={'Probes'}
          action={
            <ProjectTestButton
              project={tag.project}
              projectProbes={tag?.probesDefinitions}></ProjectTestButton>
          }></CardHeader>
        <CardContent>
          <Table size='small'>
            <QualityProbesDefinitionsTable
              content={tag.probesDefinitions}></QualityProbesDefinitionsTable>
          </Table>
        </CardContent>
      </Card>
    </>
  );
}

function ModifyButton({ tag, patchTag }) {
  let [open, setOpen] = React.useState(false);
  let { t } = useTranslation();
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      <QualityProbesTagModal
        title='Modify Tag'
        tag={tag}
        project={tag?.project}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(tag) => {
          patchTag(tag);
          setOpen(false);
        }}></QualityProbesTagModal>
    </>
  );
}

function mapStateToProps(state, props) {
  return {
    tag: state.quality.qualityProbes.tags.content[props.match.params['tagUuid']],
    tagUuid: props.match.params['tagUuid']
  };
}

export default connect(mapStateToProps, { fetchTag, deleteTag, patchTag, restoreProbesFromTag })(
  QualityProbesTagDetailPage
);
