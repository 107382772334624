import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

function WebActionDetailHeader({ webAction }) {
  const { t } = useTranslation();
  return (
    <>
      <Grid container justifyContent='flex-end' alignItems='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>
            {t('webActions.detail.header', {
              name: webAction.name
            })}
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`WEB_ACTION:${webAction?.uuid}`}
            pageTitle={`Web Action: ${webAction?.name}`}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WebActionDetailHeader;
