import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AutoComplete from '../../../components/autocomplete/AutoComplete';
import { fetchTaskAssignmentsRoles } from '../../../actions/TaskAssignmentsActions';

function TaskAssignmentRoleAutoComplete({ value, onChange, fetchTaskAssignmentsRoles }) {
  let [roles, setRoles] = useState([]);
  useEffect(() => {
    fetchTaskAssignmentsRoles().then((response) => response && setRoles(response.content));
  }, [setRoles, fetchTaskAssignmentsRoles]);
  return (
    <AutoComplete
      fullWidth
      label='Role'
      value={value}
      dataSource={roles}
      onUpdateInput={onChange}
      onNewRequest={onChange}
    />
  );
}

export default connect(null, { fetchTaskAssignmentsRoles })(TaskAssignmentRoleAutoComplete);
