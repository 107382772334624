import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { Table, TableHead, TableBody, TableRow, TablePagination, Typography } from '@mui/material';
import IssueIcons from '../detail/IssueIcons';
import SeverityIcons from '../detail/SeverityIcons';
import TableCell from 'components/tables/GenericTableCell';
import { ChipItem } from 'components/chiplist/ChipList';
import IssueStatesManager from '../detail/IssueStatesManager';
import moment from 'moment';
import UserChip, { TeamChip } from 'components/chiplist/UserChip';

const IssuesTable = ({ issues, pagination, handleFetch }) => {
  const history = useHistory();

  return issues.length > 0 ? (
    <>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 30 }}>Type</TableCell>
            <TableCell style={{ flexGrow: 1 }}>Name</TableCell>
            <TableCell hiddenSm>Campaign</TableCell>
            <TableCell hiddenSm>Assignee</TableCell>
            <TableCell hiddenMd>Estimate</TableCell>
            <TableCell hiddenMd>Due Date</TableCell>
            <TableCell style={{ width: 30, marginLeft: '30px' }} align='center' hiddenMd>
              Severity
            </TableCell>
            <TableCell style={{ width: 100 }} align='center'>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {issues.map((issue) => (
            <TableRow
              hover={true}
              style={{ cursor: 'pointer' }}
              key={issue.uuid}
              onClick={() => history.push(`/issue-management/issues/${issue.uuid}`)}>
              <TableCell>{IssueIcons[issue.issueType] || '-'}</TableCell>
              <TableCell>{issue.name}</TableCell>
              <TableCell hiddenSm>
                {issue.campaign ? (
                  <ChipItem
                    size='small'
                    key={issue.campaign.uuid}
                    id={issue.campaign.uuid}
                    label={issue.campaign.name}
                    to={`/issue-management/campaigns/${issue.campaign.uuid}`}
                    onClick={(event) => event.stopPropagation()}
                  />
                ) : (
                  <div>-</div>
                )}
              </TableCell>
              <TableCell hiddenSm>{getAssignee(issue)}</TableCell>
              <TableCell hiddenMd>
                {' '}
                {issue.estimate !== null && issue.estimate !== undefined && issue.estimate !== ''
                  ? issue.estimate
                  : '-'}
              </TableCell>
              <TableCell hiddenMd>
                {issue.plannedEndDate ? moment(issue.plannedEndDate).format('DD MMM YYYY') : '-'}
              </TableCell>
              <TableCell align='center' hiddenMd>
                {SeverityIcons[issue.severity] || '-'}
              </TableCell>
              <TableCell align='center'>
                <IssueStatesManager issue={issue}></IssueStatesManager>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        rowsPerPageOptions={[10, 20, 50]}
        page={pagination.page}
        rowsPerPage={pagination.size}
        count={pagination.totalElements}
        onPageChange={(event, page) => handleFetch(page, pagination.size)}
        onRowsPerPageChange={(event) => handleFetch(pagination.page, event.target.value)}
      />
    </>
  ) : (
    <div style={{ textAlign: 'center', padding: '2%' }}>
      <Typography variant='body1'>No issues</Typography>
    </div>
  );
};

function getAssignee(issue) {
  if (issue.assignee?.displayName) {
    return <UserChip label={issue.assignee?.displayName} />;
  } else if (issue.assigneeTeamCode) {
    return <TeamChip label={issue.assigneeTeamCode} />;
  } else {
    return '-';
  }
}

IssuesTable.propTypes = {
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    totalElements: PropTypes.number.isRequired
  }).isRequired,
  issues: PropTypes.array.isRequired
};

export default IssuesTable;
