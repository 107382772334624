import React from 'react';
import { IconButton, Table, TableBody, TableHead, TableRow, Tooltip } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { t } from 'i18next';
import PhysicalFieldItemSelect from 'pages/systems/physical_entities/commons/PhysicalFieldItemSelect';
import TableCell from 'components/tables/GenericTableCell';

export const TargetNodesTable = ({
  targetTable,
  physicalFields,
  dispatchTableAction,
  setSearchModalOpen
}) => {
  if (targetTable) {
    return (
      <Table size='small' style={{ overflowX: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('querystatements.targetTableModal.targetTableFields')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {physicalFields.map((physicalField, index) => {
            return (
              <TableRow hover={false} key={index}>
                <TableCell sx={[{ height: '80px' }, { 'padding-top': 0, 'padding-bottom': 0 }]}>
                  <PhysicalFieldItemSelect
                    value={physicalField}
                    physicalEntityUuid={targetTable.uuid}
                    physicalFieldUuid={physicalField?.uuid}
                    onChange={(pf) =>
                      dispatchTableAction({
                        type: 'addLink',
                        payload: { index: index, physicalField: pf }
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  } else {
    return (
      <Tooltip title={t('querystatements.targetTableModal.tooltip.connectToTargetTable')}>
        <IconButton size='large' onClick={() => setSearchModalOpen(true)}>
          <LinkIcon fontSize='large' />
        </IconButton>
      </Tooltip>
    );
  }
};
