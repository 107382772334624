import { BlindataApi } from 'api/ApiUtils';

const CONTRACT_DEFINITIONS_URL = '/api/v1/contracts/definitions';

export default class ContractDefinitionsApi extends BlindataApi {
  getContractDefinitionsPage(params) {
    return this.get(CONTRACT_DEFINITIONS_URL, params);
  }

  getContractDefinition(uuid) {
    return this.get(`${CONTRACT_DEFINITIONS_URL}/${uuid}`);
  }

  postContractDefinition(contractDefinition) {
    return this.post(CONTRACT_DEFINITIONS_URL, contractDefinition);
  }

  putContractDefinition(contractDefinition) {
    return this.put(`${CONTRACT_DEFINITIONS_URL}/${contractDefinition.uuid}`, contractDefinition);
  }

  deleteContractDefinition(contractDefinition) {
    return this.delete(`${CONTRACT_DEFINITIONS_URL}/${contractDefinition.uuid}`);
  }

  exportContractDefinitionsCSV(params) {
    return this.download(`${CONTRACT_DEFINITIONS_URL}/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }

  getContractDefinitionCategories(params) {
    return this.get(`${CONTRACT_DEFINITIONS_URL}/*/categories`, params);
  }
}
