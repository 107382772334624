import { BlindataApi } from './ApiUtils';

export default class WatchlistApi extends BlindataApi {
  getWatchlist(params) {
    return this.get('/api/v1/social/watch-list', params);
  }

  postWatchlist(watchlistRecord) {
    return this.post('/api/v1/social/watch-list', watchlistRecord);
  }

  deleteWatchlistRecord(sequenceId) {
    return this.delete(`/api/v1/social/watch-list/${sequenceId}`);
  }
}
