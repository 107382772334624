import AgentJobRunnerApi from 'api/onagent/AgentJobRunnerApi';
import { buildApiCallAction } from 'actions/ActionUtils';
import { TEST_PROBE_ON_AGENT, RUN_PROJECT_ON_AGENT } from 'constants/ActionTypes';
import { convertProbesToJob } from 'pages/settings/agents/commons/ProbesJobConverter';

export const testProbe = (agent, probe) =>
  buildApiCallAction({
    api: new AgentJobRunnerApi(agent),
    apiFunction: (api) => api.runJob(convertProbesToJob([probe], probe.name)),
    actionType: TEST_PROBE_ON_AGENT
  });

export const runProject = (agent, projectProbes, projectName) =>
  buildApiCallAction({
    api: new AgentJobRunnerApi(agent),
    apiFunction: (api) => api.runJob(convertProbesToJob(projectProbes, projectName)),
    actionType: RUN_PROJECT_ON_AGENT
  });

export const runJob = (agent, jobDetails) =>
  buildApiCallAction({
    api: new AgentJobRunnerApi(agent),
    apiFunction: (api) => api.runJob(jobDetails),
    actionType: 'RUN_AGENT_JOB'
  });

export const runJobByUuid = (agent, jobDefinitionUuid) =>
  buildApiCallAction({
    api: new AgentJobRunnerApi(agent),
    apiFunction: (api) => api.runJobByUuid(jobDefinitionUuid),
    actionType: 'RUN_AGENT_JOB_BY_UUID'
  });
