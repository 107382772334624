import React, { useState } from 'react';
import Papa from 'papaparse';
import { Button, Card, CardHeader, CardActions, CardContent } from '@mui/material';
import PropertiesTable from '../../../../components/tables/PropertiesTable';
import KVDefinitionList from '../../../../components/definition-list/KVDefinitionList';

function ImportCsvPreview({ file, configuration, onNext, onBack }) {
  const [loaded, setLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [errors, setErrors] = useState([]);
  if (!loaded) {
    Papa.parse(file, {
      header: true,
      transformHeader: (header) => header && header.trim(),
      delimiter: configuration.delimiterGuessing ? null : configuration.delimiter,
      encoding: configuration.encoding || '',
      dynamicTyping: true,
      skipEmptyLines: 'greedy',
      complete: function (results) {
        setLoaded(true);
        setHeaders(results.meta.fields);
        setRows(results.data);
        setErrors(results.errors);
      },
      error: function (error, file) {
        setLoaded(true);
        setErrors([error]);
      }
    });
  }
  return (
    <>
      <Card>
        <CardHeader title='Parsing Summary' />
        <CardContent>
          <KVDefinitionList
            item={{
              'Total Rows': rows ? rows.length : 0,
              'Total Errors': errors ? errors.length : 0
            }}
          />
        </CardContent>
        <CardActions>
          <Button onClick={() => onBack()}>Back</Button>
          <Button
            disabled={!loaded}
            variant='outlined'
            color='primary'
            onClick={() => onNext({ headers, rows })}>
            Continue
          </Button>
        </CardActions>
      </Card>

      {errors && errors.length > 0 ? (
        <Card>
          <CardHeader title='Errors' />
          <CardContent>
            {errors.map((e) => (
              <p>{JSON.stringify(e)}</p>
            ))}
          </CardContent>
        </Card>
      ) : null}
      {rows ? (
        <Card>
          <CardHeader title='Content' />
          <CardContent>
            <PropertiesTable
              headers={headers}
              propertiesPaths={headers}
              rows={rows.slice(0, Math.min(100, rows.length))}
            />
          </CardContent>
        </Card>
      ) : null}
    </>
  );
}

export default ImportCsvPreview;
