import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Menu,
  MenuItem,
  TablePagination,
  Typography,
  TableFooter
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/display/fullscreen.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import { Add, MoreHoriz } from '@mui/icons-material';
import TableCell from 'components/tables/GenericTableCell';
import AttachmentModal from '../attachments/AttachmentModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchContractTemplateAttachmentsPage,
  createContractTemplateAttachment,
  deleteContractTemplateAttachment,
  modifyContractTemplateAttachment,
  uploadContractTemplateAttachment,
  downloadContractTemplateAttachment
} from 'actions/contracts/ContractTemplateAttachmentsActions';
import ConfirmMenuItem from 'components/confirmbutton/ConfirmMenuItem';

export default function AttachmentsCard({ templateUuid }) {
  let [open, setOpen] = useState(false);
  let dispatch = useDispatch();
  let currentPage = useSelector((state) => state.contracts.templates.attachments.currentPage);
  useEffect(() => {
    if (templateUuid) dispatch(fetchContractTemplateAttachmentsPage(templateUuid));
  }, [templateUuid, dispatch]);

  const handleSubmit = (attachment) => {
    let { file, ...metaAttachment } = attachment;
    dispatch(createContractTemplateAttachment(templateUuid, metaAttachment)).then((response) => {
      if (response) dispatch(uploadContractTemplateAttachment(templateUuid, response, { file }));
    });
    setOpen(false);
  };
  return (
    <Card style={{ margin: '8px 0 8px 0' }}>
      <CardHeader
        title='Template Attachments'
        action={
          <IconButton onClick={() => setOpen(true)} size="large">
            <Add></Add>
          </IconButton>
        }
      />
      <CardContent>
        {!currentPage.content.filter((a) => a.contractTemplateUuid === templateUuid).length ? (
          <div style={{ textAlign: 'center', padding: '2%' }}>
            <Typography variant='body1'>No attachments defined</Typography>
          </div>
        ) : (
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>File name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPage.content
                .filter((a) => a.contractTemplateUuid === templateUuid)
                .map((a) => (
                  <TableRow key={a.uuid}>
                    <TableCell>
                      <strong>{a.name}</strong>
                    </TableCell>
                    <TableCell>{a.fileName}</TableCell>
                    <TableCell>
                      <AttachmentsTableActions attachment={a} templateUuid={templateUuid} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  page={currentPage.number}
                  rowsPerPage={currentPage.size}
                  count={currentPage.totalElements}
                  onPageChange={(event, page) =>
                    dispatch(
                      fetchContractTemplateAttachmentsPage(templateUuid, {
                        page,
                        size: currentPage.size
                      })
                    )
                  }
                  onRowsPerPageChange={(event) =>
                    dispatch(
                      fetchContractTemplateAttachmentsPage(templateUuid, {
                        page: 0,
                        size: event.target.value
                      })
                    )
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
        <AttachmentModal
          open={open}
          onCancel={() => setOpen(false)}
          onSubmit={handleSubmit}></AttachmentModal>
      </CardContent>
    </Card>
  );
}

const AttachmentsTableActions = ({ attachment, templateUuid }) => {
  let { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleDownload = (event) => {
    setAnchorEl(null);
    dispatch(downloadContractTemplateAttachment(templateUuid, attachment));
    event.stopPropagation();
  };

  const handleOpenEdit = (event) => {
    setAnchorEl(null);
    setEditOpen(true);
    event.stopPropagation();
  };

  const handleModify = (attachment) => {
    let { file, ...attachmentMeta } = attachment;
    dispatch(modifyContractTemplateAttachment(templateUuid, attachmentMeta))
      .then((response) => {
        if (response)
          return dispatch(uploadContractTemplateAttachment(templateUuid, response, { file }));
      })
      .then(() => setEditOpen(false));
  };

  const handleDelete = () => {
    setAnchorEl(null);
    dispatch(deleteContractTemplateAttachment(templateUuid, attachment));
  };

  return (
    <>
      <IconButton
        size='small'
        aria-haspopup='true'
        aria-controls='physical-entities-table-actions'
        onClick={handleOpenMenu}>
        <MoreHoriz />
      </IconButton>
      <Menu
        id='physical-entities-table-actions'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleDownload}>Download</MenuItem>

        <MenuItem onClick={handleOpenEdit}>{t('commons.actions.modify')}</MenuItem>

        <ConfirmMenuItem onClick={handleDelete}>{t('commons.actions.delete')}</ConfirmMenuItem>
      </Menu>
      {editOpen && (
        <AttachmentModal
          open={editOpen}
          attachment={attachment}
          onCancel={() => setEditOpen(false)}
          onSubmit={handleModify}
        />
      )}
    </>
  );
};
