import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grow,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { t } from 'i18next';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { fetchResponsibilitiesPageByResourceType } from 'actions/StewardshipActions';
import { ChipItem, ChipWrapper } from 'components/chiplist/ChipList';
import { createUrl } from 'pages/Routes';

export const ResponsibilitiesCard = ({ height, resourceType }) => {
  const user = useSelector((state) => state.settings.profile);

  const userResponsibilitiesPage = useSelector(
    (state) => state.stewardship.responsibilities.responsibilitiesByResourceType[resourceType]
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      fetchResponsibilitiesPageByResourceType({
        resourceType: resourceType,
        page: 0,
        user: user,
        endDateIsNull: true,
        includeUserTeams: true
      })
    );
  }, [dispatch, resourceType, user]);

  return (
    <Grow in={true} mountOnEnter unmountOnExit {...{ timeout: 800 }}>
      <Card>
        <CardHeader
          title={t('homepage.dashboard.responsibilities.title')}
          subheader={`Resource type: ${resourceType}`}
          action={
            <IconButton size='small' onClick={() => history.push('/stewardship/responsibilities')}>
              <OpenInNewIcon color='primary' fontSize='small' />
            </IconButton>
          }
        />
        <CardContent>
          {userResponsibilitiesPage &&
          userResponsibilitiesPage.content &&
          userResponsibilitiesPage.content.length > 0 ? (
            <>
              <TableContainer style={{ height: `${height}px` }}>
                <Table size='small' stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('homepage.dashboard.responsibilities.table.role')}</TableCell>
                      <TableCell align='justify'>
                        {t('homepage.dashboard.responsibilities.table.resourceName')}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userResponsibilitiesPage.content.map((responsibility, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <ChipWrapper>
                            <ChipItem
                              label={responsibility.stewardshipRole.name}
                              component={Link}
                              to={`/stewardship/roles/${responsibility.stewardshipRole.uuid}`}></ChipItem>
                          </ChipWrapper>
                        </TableCell>
                        <TableCell align='justify'>{responsibility.resourceName}</TableCell>
                        <TableCell>
                          <Tooltip title='Go to the resource'>
                            <IconButton
                              size='small'
                              onClick={() =>
                                history.push(
                                  `${createUrl(responsibility.resourceType)}/${
                                    responsibility.resourceIdentifier
                                  }`
                                )
                              }>
                              <OpenInNewIcon color='primary'></OpenInNewIcon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component='div'
                count={userResponsibilitiesPage.totalElements}
                rowsPerPage={userResponsibilitiesPage.size}
                page={userResponsibilitiesPage.number}
                onPageChange={(_event, page) =>
                  dispatch(
                    fetchResponsibilitiesPageByResourceType({
                      resourceType: resourceType,
                      page: page,
                      size: userResponsibilitiesPage.size,
                      user: user
                    })
                  )
                }
                onRowsPerPageChange={(event) => {
                  dispatch(
                    fetchResponsibilitiesPageByResourceType({
                      resourceType: resourceType,
                      page: userResponsibilitiesPage.number,
                      size: event.target.value,
                      user: user
                    })
                  );
                }}
              />
            </>
          ) : (
            <div
              style={{
                textAlign: 'center',
                padding: '2%',
                height: `${height + 52}px`,
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center'
              }}>
              <Typography
                variant='body1'
                style={{
                  textAlign: 'center',
                  alignSelf: 'center'
                }}>
                {t('homepage.dashboard.responsibilities.noResponsibility')}
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>
    </Grow>
  );
};
