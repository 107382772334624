import PropTypes from 'prop-types'
import _ from 'lodash'
import {connect} from 'react-redux'
import ItemAutoComplete from '../../../components/itemselector/ItemAutoComplete';
import { fetchProcessing, searchProcessings } from '../../../actions/ProcessingsActions'
import {withLoader} from '../../../components/loader/Loader'

const mapStateToProps = (state,props)=>({
  values: Object.values(state.processings.contentByUuid),
})

const mapDispatchToProps = {
  onSearch: searchProcessings,
  fetchProcessing
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  itemName: 'Processing',
  keyProperty:'uuid',
  nameProperty:'name',
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  value: extractOriginalValue(ownProps,stateProps)
})

const extractOriginalValue = (ownProps,stateProps)=>{
  if(ownProps.value && !ownProps.value.name){
    let originalValue = _(stateProps.values).find({'uuid': ownProps.value.uuid}) 
    return originalValue || ownProps.value
  }else{
    return ownProps.value
  }
}
const ProcessingItemAutoComplete = _.flow([
  withLoader({
    onLoad: ({value, values, fetchProcessing}) => {
      if(value && value.uuid && !_(values).find({'uuid':value.uuid})){
        fetchProcessing(value.uuid);
      }
    }
  }),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
])(ItemAutoComplete)

ProcessingItemAutoComplete.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

ProcessingItemAutoComplete.displayName = 'ProcessingItemAutoComplete'

export default ProcessingItemAutoComplete