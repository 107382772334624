import React from 'react';

import { Card, CardContent } from '@mui/material';
import QualityProbesTagsList from 'pages/quality/probes/tags/list/QualityProbesTagsList';

function ProjectTagsTab({ project }) {
  return (
    <Card>
      <CardContent>
        <QualityProbesTagsList project={project} />
      </CardContent>
    </Card>
  );
}

export default ProjectTagsTab;
