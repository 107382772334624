import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import ItemAutoComplete from '../../../../components/itemselector/ItemAutoComplete';
import {
  fetchPhysicalEntity,
  searchPhysicalEntities
} from '../../../../actions/PhysicalEntitiesActions';
import { withLoader } from '../../../../components/loader/Loader';
import { compose } from 'redux';

const mapStateToProps = (state, props) => ({
  values: Object.values(state.physicalentities.content).filter(
    (pe) => !props.systemUuid || _.get(pe, 'system.uuid') === props.systemUuid
  )
});

const mapDispatchToProps = {
  searchPhysicalEntities,
  fetchPhysicalEntity
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  itemName: 'PhysicalEntity',
  keyProperty: 'uuid',
  nameProperty: 'fullName',
  subheaderProperty: 'system.name',
  onSearch: (searchText) =>
    dispatchProps.searchPhysicalEntities({
      searchText,
      systemUuid: ownProps.systemUuid
    }),
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  value: extractOriginalValue(ownProps, stateProps)
});

const extractOriginalValue = (ownProps, stateProps) => {
  if (ownProps.value && !ownProps.value.name) {
    let originalValue = _(stateProps.values).find({
      uuid: ownProps.value.uuid
    });
    return originalValue || ownProps.value;
  } else {
    return ownProps.value;
  }
};

const PhysicalEntityItemAutoComplete = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withLoader({
    onLoad: ({ value, values, fetchPhysicalEntity }) => {
      if (value && value.uuid && !_(values).find({ uuid: value.uuid })) {
        fetchPhysicalEntity(value.uuid);
      }
    }
  })
)(ItemAutoComplete);

PhysicalEntityItemAutoComplete.propTypes = {
  value: PropTypes.object,
  systemUuid: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

PhysicalEntityItemAutoComplete.displayName = 'PhysicalEntityItemAutoComplete';

export default PhysicalEntityItemAutoComplete;
