import * as React from "react";
const SvgApacheFeather = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    viewBox="0 0 2392.5 4226.6"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <path
        id="ApacheFeather_svg__b"
        fill="#BE202E"
        d="M1393.2 1934.8c-15.4 2.8-31.3 5.5-47.6 8.3-.1 0-.2.1-.3.1 8.2-1.2 16.3-2.4 24.3-3.8s15.8-2.9 23.6-4.6z"
      />
      <path
        id="ApacheFeather_svg__c"
        fill="#BE202E"
        d="M1433.6 1735.5s-.1 0-.1.1c-.1 0-.1.1-.2.1 2.6-.3 5.1-.8 7.6-1.1 10.3-1.5 20.4-3.3 30.2-5.4-12.3 2-24.8 4.2-37.5 6.3z"
      />
      <path
        id="ApacheFeather_svg__j"
        fill="#BE202E"
        d="M1369.6 1939.4c-8 1.4-16.1 2.7-24.3 3.8 8.2-1.1 16.3-2.4 24.3-3.8z"
      />
      <path
        id="ApacheFeather_svg__l"
        fill="#BE202E"
        d="M1433.2 1735.7c2.6-.3 5.1-.8 7.6-1.1-2.5.3-5 .7-7.6 1.1z"
      />
      <path
        id="ApacheFeather_svg__n"
        fill="#BE202E"
        d="M1433.5 1735.6s.1 0 .1-.1c0 0-.1 0-.1.1z"
      />
    </defs>
    <linearGradient
      id="ApacheFeather_svg__a"
      x1={-5167.096}
      x2={-4570.116}
      y1={697.555}
      y2={1395.619}
      gradientTransform="rotate(-65.001 -2052.924 -4777.854)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#f69923" />
      <stop offset={0.312} stopColor="#f79a23" />
      <stop offset={0.838} stopColor="#e97826" />
    </linearGradient>
    <path
      fill="url(#ApacheFeather_svg__a)"
      d="M1798.9 20.1C1732.6 59.2 1622.5 170 1491 330.5l120.8 228c84.8-121.3 170.9-230.4 257.8-323.6 6.7-7.4 10.2-10.9 10.2-10.9-3.4 3.6-6.8 7.3-10.2 10.9-28.1 31-113.4 130.5-242.1 328.1 123.9-6.2 314.3-31.5 469.6-58.1 46.2-258.8-45.3-377.3-45.3-377.3S1935.5-60.6 1798.9 20.1z"
    />
    <path
      fill="none"
      d="M1594.4 1320.7c.9-.2 1.8-.3 2.7-.5l-17.4 1.9c-1.1.5-2 1-3.1 1.4 6-.9 11.9-1.9 17.8-2.8zm-123.3 408.4c-9.9 2.2-20 3.9-30.2 5.4 10.2-1.5 20.3-3.3 30.2-5.4zm-838 916.1c1.3-3.4 2.6-6.8 3.8-10.2 26.6-70.2 52.9-138.4 79-204.9 29.3-74.6 58.2-146.8 86.8-216.8 30.1-73.8 59.8-145.1 89.1-214 30.7-72.3 61-141.9 90.7-208.9 24.2-54.5 48-107.3 71.5-158.4 7.8-17 15.6-33.9 23.4-50.6 15.4-33.1 30.7-65.6 45.7-97.3 13.9-29.3 27.7-57.9 41.4-86 4.5-9.4 9.1-18.6 13.6-27.9.7-1.5 1.5-3 2.2-4.5l-14.8 1.6-11.8-23.2c-1.1 2.3-2.3 4.5-3.5 6.8-21.2 42.1-42.2 84.6-63 127.5-12 24.8-24 49.7-35.9 74.7-33 69.3-65.5 139.2-97.4 209.6-32.3 71.1-63.9 142.6-94.9 214.2-30.5 70.3-60.3 140.7-89.6 210.9-29.2 70.1-57.7 140-85.6 209.4-29.1 72.5-57.4 144.3-84.8 215.3-6.2 16-12.4 32-18.5 48-22 57.3-43.4 113.8-64.3 169.6l18.6 36.7 16.6-1.8c.6-1.7 1.2-3.4 1.8-5 26.9-73.5 53.5-145.1 79.9-214.8zm800.1-909.5c.1 0 .1-.1.2-.1 0 0-.1 0-.2.1z"
    />
    <use xlinkHref="#ApacheFeather_svg__b" />
    <use xlinkHref="#ApacheFeather_svg__b" opacity={0.35} />
    <use xlinkHref="#ApacheFeather_svg__c" />
    <use xlinkHref="#ApacheFeather_svg__c" opacity={0.35} />
    <linearGradient
      id="ApacheFeather_svg__d"
      x1={-9585.342}
      x2={-5326.209}
      y1={620.505}
      y2={620.505}
      gradientTransform="rotate(-65.001 -2052.924 -4777.854)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0.323} stopColor="#9e2064" />
      <stop offset={0.63} stopColor="#c92037" />
      <stop offset={0.751} stopColor="#cd2335" />
      <stop offset={1} stopColor="#e97826" />
    </linearGradient>
    <path
      fill="url(#ApacheFeather_svg__d)"
      d="M1255.7 1147.6c36.7-68.6 73.9-135.7 111.5-201 39-67.8 78.5-133.6 118.4-197 2.3-3.7 4.7-7.5 7-11.3 39.4-62.4 79.2-122.4 119.3-179.8l-120.8-228c-9.1 11.1-18.2 22.4-27.5 33.9-34.8 43.4-71 90.1-108.1 139.6-41.8 55.8-84.8 115.4-128.5 177.9-40.3 57.8-81.2 118.3-122.1 180.9-34.8 53.3-69.8 108.2-104.5 164.5l-3.9 6.3 157.2 310.5c33.6-66.5 67.6-132.1 102-196.5z"
    />
    <linearGradient
      id="ApacheFeather_svg__e"
      x1={-9071.207}
      x2={-6533.178}
      y1={1047.69}
      y2={1047.69}
      gradientTransform="rotate(-65.001 -2052.924 -4777.854)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#282662" />
      <stop offset={0.095} stopColor="#662e8d" />
      <stop offset={0.788} stopColor="#9f2064" />
      <stop offset={0.949} stopColor="#cd2032" />
    </linearGradient>
    <path
      fill="url(#ApacheFeather_svg__e)"
      d="M539.7 2897.1c-20.8 57.2-41.7 115.4-62.7 174.9-.3.9-.6 1.7-.9 2.6-3 8.4-5.9 16.8-8.9 25.2-14.1 40.1-26.4 76.2-54.5 158.3 46.3 21.1 83.5 76.7 118.7 139.8-3.7-65.3-30.8-126.7-82.1-174.2 228.3 10.3 425-47.4 526.7-214.3 9.1-14.9 17.4-30.5 24.9-47.2-46.2 58.6-103.5 83.5-211.4 77.4-.2.1-.5.2-.7.3.2-.1.5-.2.7-.3 158.8-71.1 238.5-139.3 308.9-252.4 16.7-26.8 32.9-56.1 49.5-88.6-138.9 142.6-299.8 183.2-469.3 152.4l-127.1 13.9c-4 10.7-7.9 21.4-11.8 32.2z"
    />
    <linearGradient
      id="ApacheFeather_svg__f"
      x1={-9346.126}
      x2={-5086.994}
      y1={580.817}
      y2={580.817}
      gradientTransform="rotate(-65.001 -2052.924 -4777.854)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0.323} stopColor="#9e2064" />
      <stop offset={0.63} stopColor="#c92037" />
      <stop offset={0.751} stopColor="#cd2335" />
      <stop offset={1} stopColor="#e97826" />
    </linearGradient>
    <path
      fill="url(#ApacheFeather_svg__f)"
      d="M599 2612.4c27.5-71 55.8-142.8 84.8-215.3 27.8-69.4 56.4-139.2 85.6-209.4 29.2-70.2 59.1-140.5 89.6-210.9 31-71.6 62.7-143.1 94.9-214.2 31.9-70.3 64.4-140.3 97.4-209.6 11.9-25 23.9-49.9 35.9-74.7 20.8-42.9 41.8-85.4 63-127.5 1.1-2.3 2.3-4.5 3.5-6.8l-157.2-310.5c-2.6 4.2-5.1 8.4-7.7 12.6-36.6 59.8-73.1 121-108.9 183.5-36.2 63.1-71.7 127.4-106.4 192.6-29.3 55-57.9 110.5-85.7 166.5-5.6 11.4-11.1 22.6-16.6 33.9-34.3 70.5-65.2 138.6-93.2 204.1-31.7 74.2-59.6 145.1-84 212.3-16.1 44.2-30.7 86.9-44.1 127.9-11 35-21.5 70.1-31.4 105-23.5 82.3-43.7 164.4-60.3 246.2l158 311.9c20.9-55.8 42.3-112.3 64.3-169.6 6.1-15.9 12.3-32 18.5-48z"
    />
    <linearGradient
      id="ApacheFeather_svg__g"
      x1={-9035.503}
      x2={-6797.201}
      y1={638.441}
      y2={638.441}
      gradientTransform="rotate(-65.001 -2052.924 -4777.854)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#282662" />
      <stop offset={0.095} stopColor="#662e8d" />
      <stop offset={0.788} stopColor="#9f2064" />
      <stop offset={0.949} stopColor="#cd2032" />
    </linearGradient>
    <path
      fill="url(#ApacheFeather_svg__g)"
      d="M356.1 2529.2c-19.8 99.8-33.9 199.2-41 298-.2 3.5-.6 6.9-.8 10.4-49.3-79-181.3-156.1-181-155.4 94.5 137 166.2 273 176.9 406.5-50.6 10.4-119.9-4.6-200-34.1 83.5 76.7 146.2 97.9 170.6 103.6-76.7 4.8-156.6 57.5-237.1 118.2 117.7-48 212.8-67 280.9-51.6-108 305.8-216.3 643.4-324.6 1001.8 33.2-9.8 53-32.1 64.1-62.3 19.3-64.9 147.4-490.7 348.1-1050.4 5.7-15.9 11.5-31.9 17.3-48 1.6-4.5 3.3-9 4.9-13.4 21.2-58.7 43.2-118.6 65.9-179.7 5.2-13.9 10.4-27.8 15.6-41.8.1-.3.2-.6.3-.8l-157.8-311.8c-.7 3.5-1.6 7.1-2.3 10.8z"
    />
    <linearGradient
      id="ApacheFeather_svg__h"
      x1={-9346.126}
      x2={-5086.994}
      y1={1021.622}
      y2={1021.622}
      gradientTransform="rotate(-65.001 -2052.924 -4777.854)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0.323} stopColor="#9e2064" />
      <stop offset={0.63} stopColor="#c92037" />
      <stop offset={0.751} stopColor="#cd2335" />
      <stop offset={1} stopColor="#e97826" />
    </linearGradient>
    <path
      fill="url(#ApacheFeather_svg__h)"
      d="M1178.1 1370.3c-4.5 9.2-9 18.5-13.6 27.9-13.6 28.1-27.4 56.7-41.4 86-15.1 31.7-30.3 64.1-45.7 97.3-7.8 16.7-15.5 33.5-23.4 50.6-23.5 51.1-47.3 103.9-71.5 158.4-29.7 67-60 136.6-90.7 208.9-29.3 68.9-59 140.2-89.1 214-28.6 70-57.5 142.3-86.8 216.8-26.1 66.5-52.4 134.7-79 204.9-1.3 3.4-2.6 6.8-3.8 10.2-26.4 69.7-53 141.3-79.8 214.7-.6 1.7-1.2 3.4-1.8 5l127.1-13.9c-2.5-.5-5.1-.8-7.6-1.3 152-18.9 354-132.5 484.6-272.7 60.2-64.6 114.8-140.8 165.3-230 37.6-66.4 72.9-140 106.5-221.5 29.4-71.2 57.6-148.3 84.8-231.9-34.9 18.4-74.9 31.9-119 41.3-7.7 1.6-15.6 3.2-23.6 4.6-8 1.4-16.1 2.7-24.3 3.8.1 0 .2-.1.3-.1 141.7-54.5 231.1-159.8 296.1-288.7-37.3 25.4-97.9 58.7-170.5 74.7-9.9 2.2-20 3.9-30.2 5.4-2.6.4-5.1.8-7.6 1.1.1 0 .1-.1.2-.1 0 0 .1 0 .1-.1 49.2-20.6 90.7-43.6 126.7-70.8 7.7-5.8 15.2-11.8 22.4-18.1 11-9.5 21.4-19.5 31.4-30 6.4-6.7 12.6-13.6 18.6-20.8 14.1-16.8 27.3-34.9 39.7-54.6 3.8-6 7.5-12.1 11.2-18.4 4.7-9.1 9.2-18 13.6-26.8 19.8-39.8 35.6-75.3 48.2-106.5 6.3-15.6 11.8-30 16.5-43.4 1.9-5.3 3.7-10.5 5.4-15.5 5-15 9.1-28.3 12.3-40 4.8-17.5 7.7-31.4 9.3-41.5-4.8 3.8-10.3 7.6-16.5 11.3-42.8 25.6-116.2 48.8-175.4 59.7l116.7-12.8-116.7 12.8c-.9.2-1.8.3-2.7.5-5.9 1-11.9 1.9-17.9 2.9 1.1-.5 2-1 3.1-1.4l-399.3 43.8c-.7 1.4-1.4 2.8-2.2 4.3z"
    />
    <linearGradient
      id="ApacheFeather_svg__i"
      x1={-9610.334}
      x2={-5351.202}
      y1={999.733}
      y2={999.733}
      gradientTransform="rotate(-65.001 -2052.924 -4777.854)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0.323} stopColor="#9e2064" />
      <stop offset={0.63} stopColor="#c92037" />
      <stop offset={0.751} stopColor="#cd2335" />
      <stop offset={1} stopColor="#e97826" />
    </linearGradient>
    <path
      fill="url(#ApacheFeather_svg__i)"
      d="M1627.6 563.1c-35.5 54.5-74.3 116.4-116 186.5-2.2 3.6-4.4 7.4-6.6 11.1-36 60.7-74.3 127.3-114.5 200.3-34.8 63-71 130.6-108.6 203.3-32.8 63.3-66.7 130.5-101.5 201.6l399.3-43.8c116.3-53.5 168.3-101.9 218.8-171.9 13.4-19.3 26.9-39.5 40.3-60.4 41-64 81.2-134.5 117.2-204.6 34.7-67.7 65.3-134.8 88.8-195.3 14.9-38.5 26.9-74.3 35.2-105.7 7.3-27.7 13-54 17.4-79.1-155.5 26.5-345.9 51.9-469.8 58z"
    />
    <use xlinkHref="#ApacheFeather_svg__j" />
    <use xlinkHref="#ApacheFeather_svg__j" opacity={0.35} />
    <linearGradient
      id="ApacheFeather_svg__k"
      x1={-9346.126}
      x2={-5086.994}
      y1={1152.726}
      y2={1152.726}
      gradientTransform="rotate(-65.001 -2052.924 -4777.854)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0.323} stopColor="#9e2064" />
      <stop offset={0.63} stopColor="#c92037" />
      <stop offset={0.751} stopColor="#cd2335" />
      <stop offset={1} stopColor="#e97826" />
    </linearGradient>
    <path
      fill="url(#ApacheFeather_svg__k)"
      d="M1369.6 1939.4c-8 1.4-16.1 2.7-24.3 3.8 8.2-1.1 16.3-2.4 24.3-3.8z"
    />
    <use xlinkHref="#ApacheFeather_svg__l" />
    <use xlinkHref="#ApacheFeather_svg__l" opacity={0.35} />
    <linearGradient
      id="ApacheFeather_svg__m"
      x1={-9346.126}
      x2={-5086.994}
      y1={1137.725}
      y2={1137.725}
      gradientTransform="rotate(-65.001 -2052.924 -4777.854)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0.323} stopColor="#9e2064" />
      <stop offset={0.63} stopColor="#c92037" />
      <stop offset={0.751} stopColor="#cd2335" />
      <stop offset={1} stopColor="#e97826" />
    </linearGradient>
    <path
      fill="url(#ApacheFeather_svg__m)"
      d="M1433.2 1735.7c2.6-.3 5.1-.8 7.6-1.1-2.5.3-5 .7-7.6 1.1z"
    />
    <use xlinkHref="#ApacheFeather_svg__n" />
    <use xlinkHref="#ApacheFeather_svg__n" opacity={0.35} />
    <linearGradient
      id="ApacheFeather_svg__o"
      x1={-6953.407}
      x2={-6011.999}
      y1={1134.716}
      y2={1134.716}
      gradientTransform="rotate(-65.001 -2052.924 -4777.854)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0.323} stopColor="#9e2064" />
      <stop offset={0.63} stopColor="#c92037" />
      <stop offset={0.751} stopColor="#cd2335" />
      <stop offset={1} stopColor="#e97826" />
    </linearGradient>
    <path
      fill="url(#ApacheFeather_svg__o)"
      d="M1433.5 1735.6s.1 0 .1-.1c0 0-.1 0-.1.1z"
    />
    <path
      fill="#6D6E71"
      d="M2218.7 387v11.7h27.4v77.4h12.7v-77.4h27.6V387h-67.7zm158.3 0-30.6 62.2-30.8-62.2h-15.3v89.1h11.7v-70.7l30.3 61.3h8.1l30.3-61.3v70.7h11.7V387H2377z"
    />
  </svg>
);
export default SvgApacheFeather;
