import React from 'react';
import { Typography, Grid } from '@mui/material';
import TemplatesProcessingsLists from './TemplatesProcessingsList';
import TemplatesProcessingsFAB from './TemplatesProcessingsFAB';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
export default function TemplatesProcessingsListPage() {
  return (
    <>
      <Breadcrumbs elements={[{ text: 'Templates', to: '/templates' }, { text: 'Processings' }]} />
      <Tracking
        info={{
          pageTitle: `Processings Templates`,
          category: EngagementCategories.TEMPLATES,
          action: EngagementActions.LIST
        }}
      />
      <Grid container alignItems='center' justifyContent='flex-start'>
        <Grid item>
          <Typography variant='h4'>Processings Templates</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:templateprocessings-list'}
            pageTitle={'Processings Templates'}
          />
        </Grid>
      </Grid>
      <TemplatesProcessingsLists />
      <TemplatesProcessingsFAB />
    </>
  );
}
