import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { changePassword } from '../../../actions/LoginActions';
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from '@mui/material';

class ChangePassword extends Component {
  state = {
    open: false,
    currentPassword: undefined,
    newPassword1: undefined,
    newPassword2: undefined
  };

  newPassword2ErrorText() {
    if (!this.state.newPassword2) {
      return undefined;
    } else if (this.state.newPassword1 !== this.state.newPassword2) {
      return this.props.t('commons.errors.notValid');
    }
  }

  preSubmit() {
    const { currentPassword, newPassword1 } = this.state;
    this.props
      .changePassword({ currentPassword, newPassword: newPassword1 })
      .then(() => this.setState({ open: false }));
  }

  isStateConsistent() {
    const { currentPassword, newPassword1, newPassword2 } = this.state;
    return currentPassword && newPassword1 && newPassword2 && newPassword1 === newPassword2;
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Button children='Change Password' onClick={() => this.setState({ open: true })} />
        <Dialog open={this.state.open}>
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              type='password'
              required
              placeholder={t('Insert the current password')}
              label={t('Current Password')}
              value={this.state.currentPassword || ''}
              onChange={(event) => this.setState({ currentPassword: event.target.value })}
            />
            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              autoComplete='new-password'
              type='password'
              required
              placeholder={t('Insert the new password')}
              label={t('New Password')}
              value={this.state.newPassword1 || ''}
              onChange={(event) => this.setState({ newPassword1: event.target.value })}
            />

            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              autoComplete='new-password'
              type='password'
              required
              placeholder={t('Repeat the password')}
              label={t('Repeat New Password')}
              helperText={this.newPassword2ErrorText() || ' '}
              error={this.newPassword2ErrorText()}
              value={this.state.newPassword2 || ''}
              onChange={(event) => this.setState({ newPassword2: event.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              children={t('commons.actions.cancel')}
              primary={false}
              onClick={() => this.setState({ open: false })}
            />
            <Button
              children={t('commons.actions.submit')}
              primary={true}
              disabled={!this.isStateConsistent()}
              onClick={() => this.preSubmit()}
            />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({}), { changePassword })(withTranslation()(ChangePassword));
