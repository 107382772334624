import React from 'react';
import ToolbarActions from '../../sidebar/ToolbarActions';
import { Button } from '@mui/material';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function DataCategoryLogicalFieldsNavbar() {
  let { t } = useTranslation();
  let { pathname } = useLocation();

  const dataCategoriesUrl = `/datacategories`;
  const logicalFieldsUrl = `/logicalfields`;
  return (
    <ToolbarActions>
      <Button
        color={pathname === dataCategoriesUrl ? 'primary' : 'grey'}
        children={t('navbar.dataCategory')}
        component={Link}
        to={dataCategoriesUrl}></Button>
      <Button
        color={pathname === logicalFieldsUrl ? 'primary' : 'grey'}
        children={t('navbar.logicalFields')}
        component={Link}
        to={logicalFieldsUrl}></Button>
    </ToolbarActions>
  );
}

export default DataCategoryLogicalFieldsNavbar;
