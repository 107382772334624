import React, { useState } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Dialog
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function AliasesEditModals({ open, title, onCancel, onAdd }) {
  let [alias, setAlias] = useState('');
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          placeholder='Enter the Alias'
          label='Alias'
          onChange={(event) => setAlias(event.target.value)}
        />
        <DialogActions>
          <Button children={t('commons.actions.cancel')} onClick={onCancel}></Button>
          <Button
            children={t('commons.actions.add')}
            variant='contained'
            color='primary'
            onClick={() => {
              onAdd(alias);
              setAlias('');
            }}></Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default AliasesEditModals;
