import React, { useState } from 'react';
import { Card, CardActions, CardContent, CardHeader, Button, Typography } from '@mui/material';
import { modifyWorkspace, deleteWorkspace } from 'actions/social/favorites/WorkspaceActions';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import CardDescription from 'components/carddescription/CardDescription';
import WorkspaceModal from '../commons/WorkspaceModal';
import WorkspaceFavoriteTable from '../commons/WorkspaceFavoriteTable';
import { useTranslation } from 'react-i18next';

function WorkspaceDetailCard({
  workspace,
  modifyWorkspace,
  deleteWorkspace,
  favorites,
  fetchFavoritesPage,
  workspaceUuid,
  favoritesPagination
}) {
  const cardStyle = {
    margin: '8px 0 8px 0',
    padding: 8
  };
  const { t } = useTranslation();
  let history = useHistory();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Card style={cardStyle}>
        <CardHeader title={workspace?.name} />
        <CardContent>
          {workspace?.description && <h4>Description</h4>}
          <CardDescription>{workspace?.description}</CardDescription>

          {favorites?.length > 0 ? (
            <>
              <h4>Items</h4>
              <WorkspaceFavoriteTable
                pagination={favoritesPagination}
                workspaceUuid={workspaceUuid}
                fetchFavoritesPage={fetchFavoritesPage}
                truncateLength={60}
                favoriteItems={favorites}></WorkspaceFavoriteTable>
            </>
          ) : (
            <Typography style={{ textAlign: 'center', padding: '2%' }} variant='body1'>
              No elements inside this workspace
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button children={t('commons.actions.modify')} onClick={() => setOpen(true)}></Button>
          <ConfirmButton
            onClick={() => {
              deleteWorkspace(workspace?.uuid).then(() => history.push(''));
            }}>
            {t('commons.actions.cancel')}
          </ConfirmButton>
        </CardActions>
      </Card>
      {open && (
        <WorkspaceModal
          title='Edit Worskpace'
          workspace={workspace}
          open={open}
          onSubmit={(workspace) => {
            modifyWorkspace(workspace);
            setOpen(false);
          }}
          onCancel={() => setOpen(false)}></WorkspaceModal>
      )}
    </>
  );
}

export default connect(null, { modifyWorkspace, deleteWorkspace })(WorkspaceDetailCard);
