import React, { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { ChipWrapper, ChipItem } from '../../../components/chiplist/ChipList';
import ItemSelectorModal from '../../../components/itemselector/ItemSelectorModal';
import BusinessNameAutocompleteModal from '../commons/BusinessNameAutoCompleteModal';
import AccountTeamChipEditor from '../../settings/teams/common/AccountTeamChipEditor';

function DataActorsFilterModal({ open, onClear, onSubmit, filters }) {
  const [editor, setEditor] = useReducer((s, a) => ({ ...s, ...a }), filters);
  const [addBusinessName, setAddBusinessName] = useState(false);
  const [addRole, setAddRole] = useState(false);
  const { t } = useTranslation();
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Data Actors Filters</DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>Business Name</Typography>
        <ChipWrapper onRequestAdd={() => setAddBusinessName(true)}>
          {(editor.businessNames || []).map((bn) => (
            <ChipItem
              key={bn}
              id={bn}
              label={bn}
              tooltip={bn}
              onRequestDelete={() =>
                setEditor({
                  ...editor,
                  businessNames: editor.businessNames.filter((r) => r !== bn)
                })
              }
            />
          ))}
        </ChipWrapper>
        <BusinessNameAutocompleteModal
          open={addBusinessName}
          onSubmit={({ businessName }) => {
            setEditor({ ...editor, businessNames: editor.businessNames.concat([businessName]) });
            setAddBusinessName(false);
          }}
          onCancel={() => setAddBusinessName(false)}
        />
        <Typography variant='subtitle1'>Roles</Typography>
        <ChipWrapper onRequestAdd={() => setAddRole(true)}>
          {(editor.roles || []).map((role) => (
            <ChipItem
              key={role}
              id={role}
              label={role}
              tooltip={role}
              onRequestDelete={() => setEditor({ roles: editor.roles.filter((r) => r !== role) })}
            />
          ))}
        </ChipWrapper>
        <ItemSelectorModal
          title='Select a Role'
          itemName='Role'
          open={addRole}
          values={(roleValues || []).map((r) => ({ name: r, value: r }))}
          keyProperty={'value'}
          nameProperty={'name'}
          handleSubmit={({ value }) => {
            setEditor({ ...editor, roles: editor.roles.concat([value]) });
            setAddRole(false);
          }}
          handleCancel={() => setAddRole(false)}
        />
        <AccountTeamChipEditor
          teamsCodes={editor.teamsCodes}
          onChange={(teamsCodes) => {
            setEditor({ ...editor, teamsCodes: teamsCodes });
          }}></AccountTeamChipEditor>
        <FormControlLabel
          label='Only Public'
          control={
            <Checkbox
              checked={editor.nullTeamCode}
              onChange={(event, checked) =>
                setEditor({ ...editor, nullTeamCode: checked || undefined })
              }
            />
          }></FormControlLabel>
      </DialogContent>
      <DialogActions>
        <Button children={t('commons.actions.clear')} onClick={() => onClear()} />
        <Button
          children={t('commons.actions.apply')}
          onClick={() => onSubmit(editor)}
          variant='outlined'
          color='primary'
        />
      </DialogActions>
    </Dialog>
  );
}

const roleValues = [
  'DATA_PROCESSOR',
  'DATA_CONTROLLER',
  'DATA_PROTECTION_OFFICER',
  'REPRESENTATIVE',
  'ORGANIZATION',
  'DEPARTMENT',
  'PERSON'
];

export default DataActorsFilterModal;
