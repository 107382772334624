import React from 'react';
import PaginatedPanel from '../../../../components/pagination/PaginatedPanel';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchTemplatesTasksPage } from '../../../../actions/TemplatesActions';

function TemplatesTasksList({ currentPage, onPageSelection }) {
  return (
    <PaginatedPanel autoload onPageSelection={onPageSelection} currentPage={currentPage}>
      <>
        {currentPage.content.map((item) => (
          <TemplatesTasksListItem templateTask={item}></TemplatesTasksListItem>
        ))}
      </>
    </PaginatedPanel>
  );
}

function TemplatesTasksListItem({ templateTask }) {
  const { t } = useTranslation();
  return (
    <ExpandableCard
      title={templateTask.name}
      subheader={templateTask.code}
      actions={[
        <Button
          children={t('commons.actions.detail')}
          component={Link}
          to={`/templates/tasks/${templateTask.sequenceId}`}
        />
      ]}></ExpandableCard>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.templates.templatesTasks.currentPage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onPageSelection: (page) => dispatch(fetchTemplatesTasksPage({ page }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesTasksList);
