import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Storage } from '@mui/icons-material';
import { useHistory } from 'react-router';

export function RpaArchiveButton(props) {
  let history = useHistory();
  return (
    <Tooltip title='Archives'>
      <IconButton
        variant='contained'
        color='primary'
        onClick={() => history.push('/reports/rpa/archives')}
        size='large'>
        <Storage />
      </IconButton>
    </Tooltip>
  );
}
