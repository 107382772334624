import StewardshipApi from '../../../../api/StewardshipApi';
import UniversalSearchApi from '../../../../api/UniversalSearchApi';
import AbstractImporter from './AbstractImporter';
import ImporterUsers from './ImporterUsers';

export default class ImporterStewardship extends AbstractImporter {
  static isSupported(object) {
    return (
      object.stewardshipAssignment || object.stewardshipResponsibility || object.stewardshipRole
    );
  }

  async importObject(object, resolveUuidOnly) {
    if (object.stewardshipAssignment) {
      return this.importStewardshipAssignment(object.stewardshipAssignment, resolveUuidOnly);
    } else if (object.stewardshipResponsibility) {
      return this.importStewardshipResponsibility(
        object.stewardshipResponsibility,
        resolveUuidOnly
      );
    } else if (object.stewardshipRole) {
      return this.importStewardshipRole(object.stewardshipRole, resolveUuidOnly);
    } else {
      throw new Error('Object type not supported by ImporterStewardship');
    }
  }

  async importStewardshipAssignment(stewardshipAssignment, resolveUuidOnly = false) {
    let api = new StewardshipApi();
    let reconciledAssignment = stewardshipAssignment;
    if (!resolveUuidOnly) {
      reconciledAssignment = {
        ...reconciledAssignment,
        user: await new ImporterUsers(this).importUser(stewardshipAssignment.user, true),
        stewardshipRole: await this.importStewardshipRole(
          stewardshipAssignment.stewardshipRole,
          true
        )
      };
    }
    return await this.importTemplate({
      object: reconciledAssignment,
      api,
      post: api.createAssignment,
      delet: api.deleteAssignment,
      resolveUuidOnly
    });
  }

  async importStewardshipRole(stewardshipRole, resolveUuidOnly = false) {
    let api = new StewardshipApi();
    return this.importTemplate({
      object: stewardshipRole,
      objectName: 'name',
      objectIdentifier: 'uuid',
      api,
      search: api.getRoles,
      put: api.putRole,
      post: api.createRole,
      resolveUuidOnly,
      delet: api.deleteRole
    });
  }

  async importStewardshipResponsibility(stewardshipResponsibility, resolveUuidOnly = false) {
    let api = new StewardshipApi();
    let reconciledStewardshipResponsibility = stewardshipResponsibility;
    if (!resolveUuidOnly) {
      let resolvedTarget = await this.resolveTarget(stewardshipResponsibility);
      reconciledStewardshipResponsibility = {
        ...reconciledStewardshipResponsibility,
        user: stewardshipResponsibility.user
          ? await new ImporterUsers(this).importUser(stewardshipResponsibility.user, true)
          : null,

        stewardshipRole: await this.importStewardshipRole(
          stewardshipResponsibility.stewardshipRole,
          true
        ),
        ...resolvedTarget
      };
    }

    return await this.importTemplate({
      object: reconciledStewardshipResponsibility,
      objectIdentifier: 'sequenceId',
      api,
      post: api.createResponsibility,
      put: api.putResponsibility,
      delet: api.deleteResponsibility,
      resolveUuidOnly
    });
  }

  async resolveTarget(stewardshipResponsibility) {
    let { resourceType, resourceName, resourceIdentifier } = stewardshipResponsibility;
    let api = new UniversalSearchApi();
    let result = await this.importTemplate({
      object: {
        resourceType,
        name: resourceName,
        resourceIdentifier
      },
      objectName: 'name',
      objectIdentifier: 'resourceIdentifier',
      api,
      search: (params) => api.getSearch({ ...params, resourceType }),
      resolveUuidOnly: true
    });
    return {
      resourceType,
      resourceName: result.name,
      resourceIdentifier: result.resourceIdentifier
    };
  }
}
