import React from 'react';
import { Switch, Route } from 'react-router';
import AgentsListPage from 'pages/settings/agents/list/AgentsListPage';
import AgentDetailPage from './detail/AgentDetailPage';
import JobHistoryDetailPage from './history/JobHistoryDetailPage';
import AgentScheduleDetailPage from './schedules/detail/AgentScheduleDetailPage';

const AgentsRoutes = (props) => (
  <Switch>
    <Route exact path={`${props.match.url}`} component={AgentsListPage} />
    <Route exact path={`${props.match.url}/:agentUuid`} component={AgentDetailPage}></Route>
    <Route
      exact
      path={`${props.match.url}/:agentUuid/runs-history/:sequenceId`}
      component={JobHistoryDetailPage}></Route>
    <Route
      exact
      path={`${props.match.url}/:agentUuid/jobs/schedules/:jobDefinitionUuid`}
      component={AgentScheduleDetailPage}></Route>
  </Switch>
);

export default AgentsRoutes;
