import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import _ from 'lodash';
import { MyIssuesList } from './MyIssuesList';

export const MyIssuesStatusesColumns = ({
  orderedIssueStatuses,
  status,
  height,
  issuesByStatuses
}) => {
  return (
    <Grid item lg={12 / orderedIssueStatuses.length} md={6} sm={6} xs={6}>
      <Card
        variant='outlined'
        style={{
          height: '98%',
          backgroundColor: '#f4f5f7'
        }}>
        <CardHeader subheader={`${_.replace(status, '_', ' ')}`} style={{ paddingBottom: '4px' }} />
        <CardContent style={{ height: `${height}px`, padding: '4px', marginBottom: '8px' }}>
          <MyIssuesList
            orderedIssueStatuses={orderedIssueStatuses}
            issues={issuesByStatuses[status]}
            height={height}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};
