import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { connect } from 'react-redux';
import { exportRisks } from 'actions/quality/assessment/QualityAssessmentRiskDefinitionsActions';

function RiskExportButton({ onClick }) {
  return (
    <Tooltip title='Export CSV'>
      <IconButton color='default' aria-label='Download' onClick={() => onClick()} size='small'>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: () => dispatch(exportRisks())
  };
}

export default connect(null, mapDispatchToProps)(RiskExportButton);
