import React from 'react';
import { withTranslation } from 'react-i18next';

import KVDefinitionList from 'components/definition-list/KVDefinitionList';

function CatalogInformationSection({ routine, t }) {
  if (!routine) {
    return null;
  }
  return (
    <>
      <div className='dls-wrapper'>
        <KVDefinitionList
          header={t('routines.detail.catalog.title')}
          item={{
            ...{
              [t('routines.detail.catalog.catalog')]: routine.catalog,
              [t('routines.detail.catalog.schema')]: routine.schema,
              [t('routines.detail.catalog.type')]: routine.type
            },
            ...{
              [t('routines.detail.catalog.creation')]: routine.created
                ? new Date(routine.created).toLocaleDateString()
                : '-'
            },
            ...{
              [t('routines.detail.catalog.lastAltered')]: routine.lastAltered
                ? new Date(routine.lastAltered).toLocaleDateString()
                : '-'
            }
          }}
        />
      </div>
    </>
  );
}

export default withTranslation()(CatalogInformationSection);
