import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchPhysicalFields } from '../../../../actions/PhysicalFieldsActions';
import ItemAutoCompleteModal from '../../../../components/itemselector/ItemAutoCompleteModal';

const PhysicalFieldSearchModal = ({
  title,
  physicalFields,
  open,
  onSubmit,
  onCancel,
  searchPhysicalFields
}) => (
  <ItemAutoCompleteModal
    title={title || 'Search Physical Field'}
    itemName='Physical Field'
    open={open}
    keyProperty='uuid'
    nameProperty='name'
    categoryProperties={['_prefix']}
    labelSeparator={'.'}
    values={physicalFields}
    onSearch={search => searchPhysicalFields({ search })}
    handleCancel={onCancel}
    handleSubmit={onSubmit}
  />
);

PhysicalFieldSearchModal.propTypes = {
  title: PropTypes.string,
  physicalFields: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  searchPhysicalFields: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  physicalFields: Object.values(state.physicalFields.content).map(f => ({
    ...f,
    _prefix: `${f.physicalEntity.system.name} > ${f.physicalEntity.schema}.${f.physicalEntity.name}`
  }))
});

export default connect(mapStateToProps, { searchPhysicalFields })(PhysicalFieldSearchModal);
