import React from 'react';
import { withTranslation } from 'react-i18next';

import KVDefinitionList from '../../../../../components/definition-list/KVDefinitionList';
import CustomPropertiesView from '../../../../../components/additional_properties/CustomPropertiesView';

import ResourceTypes from '../../../../../constants/ResourceTypes';
import CardDescription from 'components/carddescription/CardDescription';
import { getSystemName } from 'constants/SystemTechnologyTypes';

const GeneralSection = ({ physicalEntity, t }) => {
  return (
    <>
      <CardDescription>{physicalEntity.description}</CardDescription>
      <div className='dls-wrapper'>
        <KVDefinitionList
          header={t('physicalEntities.detail.general.title')}
          item={{
            ...{
              [t('physicalEntities.detail.general.uuid')]: physicalEntity.uuid,
              [t('physicalEntities.detail.general.dataset')]: physicalEntity.dataSet,
              [t('physicalEntities.detail.general.system')]: getSystemName(physicalEntity?.system),
              [t('physicalEntities.detail.general.schema')]: physicalEntity.schema,
              [t('physicalEntities.detail.general.name')]: physicalEntity.name
            },
            ...(physicalEntity.tableType && {
              [t('physicalEntities.detail.general.type')]: physicalEntity.tableType
            }),
            ...(physicalEntity.creationDate && {
              [t('physicalEntities.detail.general.creationDate')]: new Date(
                physicalEntity.creationDate
              ).toLocaleDateString()
            }),
            ...(physicalEntity.modificationDate && {
              [t('physicalEntities.detail.general.modificationDate')]: new Date(
                physicalEntity.modificationDate
              ).toLocaleDateString()
            })
          }}
        />
      </div>
      <div style={{ marginTop: '1em', marginBottom: '1em', marginRight: '8px' }}>
        <CustomPropertiesView
          additionalProperties={physicalEntity.additionalProperties}
          resourceType={ResourceTypes.PHYSICAL_ENTITY}
        />
      </div>
    </>
  );
};

export default withTranslation()(GeneralSection);
