import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SystemItemAutoComplete from 'pages/systems/commons/SystemItemAutoComplete';

const QueryBucketSystemModal = ({ title, open, onClose, onSubmit }) => {
  const [system, setSystem] = useState(null);
  const { t } = useTranslation();

  return (
    <Dialog fullWidth maxWidth='xs' open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <h4>{t('querybuckets.modal.system.title')}</h4>
        <SystemItemAutoComplete value={system} onChange={(sys) => setSystem(sys)} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            setSystem(null);
          }}>
          {t('commons.actions.cancel')}
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onSubmit(system);
            setSystem(null);
          }}
          disabled={!system}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QueryBucketSystemModal;
