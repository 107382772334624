import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

import { fetchCampaign, modifyCampaign, deleteCampaign } from 'actions/CampaignActions';

import { Tooltip, Typography, Grid } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import CampaignDetailsCard from 'pages/issueMngt/campaigns/detail/CampaignDetailsCard';
import IssuesListCard from 'pages/issueMngt/campaigns/detail/IssuesListCard';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const CampaignDetailPage = ({
  campaignUuid,
  campaign,
  fetchCampaign,
  modifyCampaign,
  deleteCampaign
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchCampaign(campaignUuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignUuid]);

  return campaign ? (
    <>
      <Breadcrumbs
        elements={[
          { text: t('navbar.issueMngt'), to: '/issue-management/my-work' },
          { text: t('navbar.campaigns'), to: '/issue-management/campaigns' },
          { text: campaign?.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: `Campaign: ${campaign?.name}`,
          category: EngagementCategories.CAMPAIGNS,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography style={{ marginBottom: '8px' }} variant='h4'>
            Campaign: {campaign.name}
            {campaign.private && (
              <Tooltip title='Only Admin or Owner can add issues'>
                <LockIcon></LockIcon>
              </Tooltip>
            )}
          </Typography>
        </Grid>
        <FavoritesWidget
          itemIdentifier={`CAMPAIGN:${campaign.uuid}`}
          iconSize={'small'}
          pageTitle={`Campaign: ${campaign?.name}`}></FavoritesWidget>
        <Grid item></Grid>
      </Grid>

      <CampaignDetailsCard
        campaign={campaign}
        modifyCampaign={modifyCampaign}
        deleteCampaign={deleteCampaign}
      />
      <IssuesListCard campaign={campaign} />
    </>
  ) : null;
};

const mapStateToProps = (state, props) => ({
  campaignUuid: props.match.params['campaignUuid'],
  campaign: state.campaigns.campaigns.content[props.match.params['campaignUuid']]
});

const mapDispatchToProps = {
  fetchCampaign,
  modifyCampaign,
  deleteCampaign
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetailPage);
