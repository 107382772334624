import * as React from "react";
const SvgPresto = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <rect width={256} height={256} rx={18} />
    <path
      d="M230.118 128.531c0 7.047-5.713 12.76-12.761 12.76-7.047 0-12.76-5.713-12.76-12.76 0-7.048 5.713-12.761 12.76-12.761 7.048 0 12.761 5.713 12.761 12.761"
      fill="#669CFE"
    />
    <path
      d="M203.806 154.531c0 7.047-5.713 12.76-12.761 12.76-7.048 0-12.761-5.713-12.761-12.76 0-7.048 5.713-12.761 12.761-12.761 7.048 0 12.761 5.713 12.761 12.761"
      fill="#3FE7FE"
    />
    <path
      d="M177.451 181.072c0 7.048-5.713 12.761-12.76 12.761-7.048 0-12.761-5.713-12.761-12.761 0-7.047 5.713-12.76 12.761-12.76 7.047 0 12.76 5.713 12.76 12.76"
      fill="#FFF"
    />
    <path
      d="M202.993 102.336c0 6.597-5.348 11.944-11.944 11.944-6.597 0-11.945-5.347-11.945-11.944s5.348-11.945 11.945-11.945c6.596 0 11.944 5.348 11.944 11.945"
      fill="#669CFE"
    />
    <path
      d="M176.738 128.298c0 6.597-5.348 11.945-11.944 11.945-6.597 0-11.945-5.348-11.945-11.945s5.348-11.945 11.945-11.945c6.596 0 11.944 5.348 11.944 11.945"
      fill="#3FE7FE"
    />
    <path
      d="M150.569 154.642c0 6.597-5.347 11.945-11.944 11.945s-11.945-5.348-11.945-11.945 5.348-11.944 11.945-11.944 11.944 5.347 11.944 11.944"
      fill="#FFF"
    />
    <path
      d="M175.743 76.235c0 5.991-4.857 10.849-10.849 10.849s-10.849-4.858-10.849-10.849c0-5.992 4.857-10.849 10.849-10.849s10.849 4.857 10.849 10.849"
      fill="#669CFE"
    />
    <path
      d="M149.522 102.191c0 5.991-4.858 10.849-10.85 10.849-5.991 0-10.849-4.858-10.849-10.849 0-5.992 4.858-10.85 10.849-10.85 5.992 0 10.85 4.858 10.85 10.85"
      fill="#3FE7FE"
    />
    <path
      d="M123.253 128.618c0 5.992-4.857 10.849-10.849 10.849s-10.849-4.857-10.849-10.849c0-5.991 4.857-10.849 10.849-10.849s10.849 4.858 10.849 10.849"
      fill="#FFF"
    />
    <path
      d="M148.618 50.008c0 5.386-4.366 9.752-9.752 9.752-5.386 0-9.752-4.366-9.752-9.752 0-5.387 4.366-9.753 9.752-9.753 5.386 0 9.752 4.366 9.752 9.753"
      fill="#669CFE"
    />
    <path
      d="M122.297 76.089c0 5.386-4.366 9.753-9.752 9.753-5.386 0-9.752-4.367-9.752-9.753s4.366-9.752 9.752-9.752c5.386 0 9.752 4.366 9.752 9.752"
      fill="#3FE7FE"
    />
    <path
      d="M96.06 102.471c0 5.386-4.367 9.752-9.753 9.752s-9.752-4.366-9.752-9.752c0-5.386 4.366-9.753 9.752-9.753 5.386 0 9.753 4.367 9.753 9.753"
      fill="#FFF"
    />
    <path
      d="M95.06 154.721a8.67 8.67 0 1 1-17.338-.002 8.67 8.67 0 0 1 17.338.002m26.125 26.125a8.67 8.67 0 1 1-17.338-.002 8.67 8.67 0 0 1 17.338.002m26.083 26.083a8.669 8.669 0 0 1-8.669 8.669 8.669 8.669 0 0 1-8.669-8.669 8.669 8.669 0 0 1 8.669-8.669 8.669 8.669 0 0 1 8.669 8.669m-52.083 0a8.67 8.67 0 1 1-17.339 0 8.67 8.67 0 0 1 17.339 0m-52.584 0a8.67 8.67 0 1 1-17.337 0 8.67 8.67 0 0 1 17.337 0m26.125-26.125a8.67 8.67 0 1 1-17.337 0 8.67 8.67 0 0 1 17.337 0"
      fill="#6A9EFE"
    />
    <path
      d="M95.172 50.032a9.06 9.06 0 0 1-18.118 0 9.06 9.06 0 1 1 18.118 0"
      fill="#3FE7FE"
    />
    <path
      d="M68.799 76.538a9.06 9.06 0 1 1-18.12 0 9.06 9.06 0 0 1 18.12 0M41.174 49.663a7.31 7.31 0 1 1-14.62 0 7.31 7.31 0 0 1 14.62 0"
      fill="#FFF"
    />
  </svg>
);
export default SvgPresto;
