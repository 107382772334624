import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { t } from 'i18next';
import QueryBucketItemAutoComplete from 'pages/dataflows/queryparser/buckets/commons/QueryBucketItemAutoComplete';
import { AnalysisStrategySelect } from './AnalysisStrategySelect';

export const RoutineAnalysisModal = ({ open, onClose, onSubmit }) => {
  const [analysisOptions, setAnalysisOptions] = useState();

  return (
    <Dialog
      open={open}
      fullWidth
      onClick={(event) => event.stopPropagation()}
      onContextMenu={(event) => event.stopPropagation()}>
      <DialogTitle>Select Analysis Modality</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} direction={'column'}>
          <Grid item>
            <QueryBucketItemAutoComplete
              required
              onChange={(queryBucket) =>
                setAnalysisOptions({ ...analysisOptions, queryBucket: queryBucket })
              }
              value={analysisOptions?.queryBucket}
            />
          </Grid>
          <Grid item>
            <AnalysisStrategySelect
              value={analysisOptions?.strategy}
              onChange={(strategy) =>
                setAnalysisOptions({ ...analysisOptions, strategy: strategy })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}>
          {t('commons.actions.cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={
            !analysisOptions ||
            !analysisOptions.strategy ||
            !analysisOptions.queryBucket ||
            !analysisOptions.queryBucket.uuid
          }
          onClick={(event) => {
            event.stopPropagation();
            onClose();
            onSubmit({
              strategy: analysisOptions.strategy,
              queryBucketUuid: analysisOptions.queryBucket.uuid
            });
          }}>
          ANALYZE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
