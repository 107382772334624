import {
  FETCH_PROFILE,
  CHANGE_DEFAULT_TENANT,
  REMOVE_USER_TENANT,
  ADD_USER_TENANT
} from '../../constants/ActionTypes';
import _ from 'lodash';

const PROFILE_INITIAL_STATE = {
  uuid: null,
  username: null,
  email: null,
  applicationScope: null,
  permissions: [],
  tenant: null,
  associatedTenants: []
};

const profile = (state = PROFILE_INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PROFILE:
      return action.payload;
    case CHANGE_DEFAULT_TENANT:
      if (action.payload.userUuid !== state.uuid) return state;
      return { ...state, tenant: action.payload.tenant };
    case REMOVE_USER_TENANT:
      if (action.payload.userUuid !== state.uuid) return state;
      return {
        ...state,
        associatedTenants: state.associatedTenants.filter(
          (t) => t.uuid !== action.payload.tenant.uuid
        )
      };
    case ADD_USER_TENANT:
      if (action.payload.userUuid !== state.uuid) return state;
      return {
        ...state,
        associatedTenants: _.unionBy(state.associatedTenants, [action.payload.tenant], 'uuid')
      };
    default:
      return state;
  }
};

export const extractUserUuid = (state) => state.settings.profile.uuid;
export default profile;
