import React, { Component } from 'react';
import _ from 'lodash';
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Switch,
  FormControlLabel
} from '@mui/material';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ItemSelectField from '../../../../components/itemselector/ItemSelectField';
import PermissionsEditor from './PermissionsEditor';
import { IfApplicationScopeOwner } from 'components/permissions/withApplicationScope';

const DEFAULT_STATE = {
  username: null,
  fullName: null,
  email: null,
  applicationScope: 'NONE',
  password1: null,
  password2: null,
  permissions: []
};

const applicationScopes = {
  OWNER: { key: 'OWNER', text: 'Owner' },
  ADMIN: { key: 'ADMIN', text: 'Admin' },
  EDITOR: { key: 'EDITOR', text: 'Editor' },
  VIEWER: { key: 'VIEWER', text: 'Viewer' },
  CUSTOM: { key: 'CUSTOM', text: 'Custom' },
  NONE: { key: 'NONE', text: 'None' }
};

class UserEditorModal extends Component {
  constructor(props) {
    super();
    let user = props.user;
    if (user) {
      this.state = { ...user };
    } else {
      this.state = DEFAULT_STATE;
    }
  }

  componentWillReceiveProps(props) {
    let user = props.user;
    //it is a user to be edited and it is different from the one in current editing
    if (user?.uuid && user.uuid !== this.state.uuid) {
      this.setState({ ...user });
    }
  }

  isStateConsistent() {
    if (this.props.isPatch && !this.state.password1) {
      return (
        this.state.username && this.state.email && applicationScopes[this.state.applicationScope]
      );
    } else {
      return (
        this.state.username &&
        this.state.email &&
        applicationScopes[this.state.applicationScope] &&
        this.state.password1 &&
        this.state.password2 &&
        this.state.password1 === this.state.password2
      );
    }
  }

  password2ErrorText() {
    if (this.props.isPatch) {
      if (!this.state.password2 && this.state.password1) {
        return this.props.t('commons.errors.required');
      } else if (this.state.password1 && this.state.password1 !== this.state.password2) {
        return this.props.t('commons.errors.notValid');
      }
    } else {
      if (!this.state.password2) {
        return this.props.t('commons.errors.required');
      } else if (this.state.password1 !== this.state.password2) {
        return this.props.t('commons.errors.notValid');
      }
    }
  }

  preSubmit() {
    var user = { ...this.state };
    user.password = user.password1;
    delete user.password1;
    delete user.password2;
    this.props.handleSubmit(user);
  }

  customPermissionsFragment() {
    if (this.state.applicationScope === 'CUSTOM') {
      return (
        <PermissionsEditor
          permissions={this.state.permissions}
          onChange={(permissions) => this.setState({ permissions })}></PermissionsEditor>
      );
    }
  }

  render() {
    const { t, fullScreen } = this.props;
    return (
      <Dialog fullScreen={fullScreen} open={this.props.open}>
        <DialogTitle>{this.props.title}</DialogTitle>

        <DialogContent>
          <TextField
            variant='standard'
            disabled={this.props.isPatch}
            autoComplete='off'
            fullWidth
            margin='dense'
            placeholder={t('Insert the username')}
            label={t('Username')}
            helperText={
              this.state.username || this.props.isPatch ? null : t('commons.errors.required')
            }
            required
            value={this.state.username || ''}
            onChange={(event) => this.setState({ username: event.target.value })}
          />
          <TextField
            variant='standard'
            autoComplete='off'
            fullWidth
            margin='dense'
            placeholder={t('Insert the full name')}
            label={t('Full name')}
            value={this.state.fullName || ''}
            onChange={(event) => this.setState({ fullName: event.target.value })}></TextField>
          <TextField
            variant='standard'
            fullWidth
            autoComplete='off'
            margin='dense'
            placeholder={t('Insert the email')}
            label={t('Email')}
            helperText={
              this.state.email || this.props.isPatch ? null : t('commons.errors.required')
            }
            required
            value={this.state.email || ''}
            onChange={(event) => this.setState({ email: event.target.value })}
          />
          <TextField
            variant='standard'
            fullWidth
            autoComplete='new-password'
            margin='dense'
            type='password'
            placeholder={t('Insert the password')}
            label={t('Password')}
            helperText={
              this.state.password1 || this.props.isPatch ? null : t('commons.errors.required')
            }
            required={!this.props.isPatch}
            value={this.state.password1 || ''}
            onChange={(event) =>
              this.setState({ password1: event.target.value !== '' ? event.target.value : null })
            }
          />
          <TextField
            variant='standard'
            fullWidth
            autoComplete='new-password'
            margin='dense'
            type='password'
            placeholder={t('Repeat the password')}
            label={t('Repeat Password')}
            helperText={this.password2ErrorText()}
            required={!this.props.isPatch && !this.state.password1}
            value={this.state.password2 || ''}
            onChange={(event) =>
              this.setState({ password2: event.target.value !== '' ? event.target.value : null })
            }
          />
          {this.props.isPatch && (
            <FormControlLabel
              label='Set this user active'
              control={
                <Switch
                  color='primary'
                  checked={this.state.active || false}
                  onChange={(event, checked) => this.setState({ active: event.target.checked })}
                />
              }
              style={{ marginTop: '16px' }}
            />
          )}
          <IfApplicationScopeOwner>
            <h4>Permissions on all tenants</h4>
            <ItemSelectField
              mandatory={true}
              itemName='Application Scope'
              value={applicationScopes[this.state.applicationScope]}
              errorText={
                applicationScopes[this.state.applicationScope] || this.props.isPatch
                  ? null
                  : t('commons.errors.required')
              }
              values={_(applicationScopes).map().value()}
              keyProperty='key'
              nameProperty='text'
              onChange={(item) => {
                this.setState({ applicationScope: item && item.key });
              }}
            />
            {this.customPermissionsFragment()}
          </IfApplicationScopeOwner>
        </DialogContent>

        <DialogActions>
          <Button children={t('commons.actions.cancel')} onClick={this.props.handleCancel} />
          <Button
            children={t('commons.actions.submit')}
            color='primary'
            disabled={!this.isStateConsistent()}
            onClick={() => this.preSubmit()}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

UserEditorModal.propsTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
  title: PropTypes.string.isRequired,
  isPatch: PropTypes.bool
};

export default _.flow([withTranslation()])(UserEditorModal);
