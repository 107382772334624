import AgentsApi from 'api/AgentsApi';
import { buildApiCallAction } from 'actions/ActionUtils';
import {
  FETCH_AGENTS_PAGE,
  FETCH_AGENT,
  CREATE_AGENT,
  MODIFY_AGENT,
  DELETE_AGENT,
  SEARCH_AGENTS
} from 'constants/ActionTypes';

const api = new AgentsApi();

export const fetchAgentsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAgentsPage(params),
    actionType: FETCH_AGENTS_PAGE
  });

export const fetchAgent = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAgent(uuid),
    actionType: FETCH_AGENT
  });

export const createAgent = (agent) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postAgent(agent),
    actionType: CREATE_AGENT
  });

export const modifyAgent = (agent) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putAgent(agent),
    actionType: MODIFY_AGENT
  });

export const deleteAgent = (agent) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteAgent(agent.uuid),
    actionType: DELETE_AGENT,
    payload: agent
  });

export const searchAgents = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAgentsPage(params),
    actionType: SEARCH_AGENTS
  });
