import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import PhysicalEntitiesConnectModal from '../commons/PhysicalEntitiesConnectModal';

export const ConnectPhysicalEntitiesButton = ({
  filters,
  physicalEntityUuid,
  dataFlowDirection
}) => {
  const [open, setOpen] = useState(false);

  if (!physicalEntityUuid) return <></>;
  else {
    return (
      <Tooltip
        title={
          dataFlowDirection === 'Incoming'
            ? 'Connect an incoming table'
            : 'Connect an outcoming table'
        }>
        <>
          <IconButton size='small' onClick={() => setOpen(true)}>
            <LinkIcon fontSize='small' />
          </IconButton>
          <PhysicalEntitiesConnectModal
            open={open}
            title={
              dataFlowDirection === 'Incoming'
                ? 'Connect an incoming table'
                : 'Connect an outcoming table'
            }
            filters={filters}
            dataFlowDirection={dataFlowDirection}
            physicalEntityUuid={physicalEntityUuid}
            onClose={() => setOpen(false)}
            onSubmit={() => setOpen(false)}
          />
        </>
      </Tooltip>
    );
  }
};
