import { CircularProgress, Dialog } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';

import { WebActionConfigurationExecutionParams } from './WebActionConfigurationExecutionParams';
import { WebActionConfigurationExecutionResult } from './WebActionConfigurationExecutionResult';

const WebActionExecutionModal = ({ webAction, open, onCancel, onResultVisualized, payload }) => {
  const [spinner, setSpinner] = useState(false);
  const [configuration, setConfiguration] = useState();
  const [isNewResult, setIsNewResult] = useState(false);

  const executionResult = useSelector(
    (state) =>
      state.settings.agents.webActionConfigurations?.configurationExecutionResult[
        configuration?.uuid
      ]
  );

  const handleDialogContent = () => {
    if (spinner) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '100px'
          }}>
          <CircularProgress />
        </Box>
      );
    } else if (executionResult && isNewResult) {
      return (
        <WebActionConfigurationExecutionResult
          executionResult={executionResult}
          setIsNewResult={setIsNewResult}
          onResultVisualized={onResultVisualized}
        />
      );
    } else {
      return (
        <WebActionConfigurationExecutionParams
          onCancel={onCancel}
          setSpinner={setSpinner}
          webAction={webAction}
          payload={payload}
          configuration={configuration}
          setConfiguration={setConfiguration}
          setIsNewResult={setIsNewResult}
        />
      );
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth='md'>
      {handleDialogContent()}
    </Dialog>
  );
};

export default WebActionExecutionModal;
