import { BlindataApi } from './ApiUtils';

const DATA_FLOWS_IMPACT_ANALYSIS_URL = '/api/v1/dataflows/*/impact-analysis';

export default class DataFlowsImpactAnalysisApi extends BlindataApi {
  postImpactAnalysis(params) {
    return this.post(DATA_FLOWS_IMPACT_ANALYSIS_URL, params);
  }

  exportImpactAnalysis(params) {
    return this.download(`${DATA_FLOWS_IMPACT_ANALYSIS_URL}/*/export`, {
      ...params,
      includeProperties: 'false'
    });
  }
}
