import { buildApiCallAction } from './ActionUtils';
import CustomRolesApi from '../api/CustomRolesApi';
import {
  CREATE_CUSTOM_ROLE,
  FETCH_CUSTOM_ROLES_PAGE,
  DELETE_CUSTOM_ROLE
} from '../constants/ActionTypes';

const api = new CustomRolesApi();

export const createCustomRole = (customRole) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.createCustomRole(customRole),
    actionType: CREATE_CUSTOM_ROLE
  });

export const fetchCustomRolesPage = ({ page = 0, size = 100 } = {}) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getCustomRoles({ page, size }),
    actionType: FETCH_CUSTOM_ROLES_PAGE
  });

export const deleteCustomRole = (uuid) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.deleteCustomRole(uuid),
    actionType: DELETE_CUSTOM_ROLE,
    payload: { uuid }
  });
