import React from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'pages/sidebar/Navigation';
import StewardshipDashboardsOverall from './StewardshipDashboardsOverall';
import StewardshipDashboardsResourcesCoverage from './StewardshipDashboardsResourcesCoverage';
import StewardshipDashboardsUsersCoverage from './StewardshipDashboardsUsersCoverage';
import { Typography, Grid } from '@mui/material';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

function StewardshipDashboardsPage() {
  let { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.stewardship'), to: '/stewardship/responsibilities' },
          { text: t('stewardship.dashboards.header') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('stewardship.dashboards.header'),
          category: EngagementCategories.STEWARDSHIP,
          action: EngagementActions.DASHBOARDS
        }}
      />
      <Grid container alignItems='center' justifyContent='flex-start'>
        <Grid item>
          <Typography variant='h4'>{t('stewardship.dashboards.header')}</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:stewardship-dashboard'}
            pageTitle={t('stewardship.dashboards.header')}
          />
        </Grid>
      </Grid>
      <StewardshipDashboardsOverall></StewardshipDashboardsOverall>
      <StewardshipDashboardsResourcesCoverage></StewardshipDashboardsResourcesCoverage>
      <StewardshipDashboardsUsersCoverage></StewardshipDashboardsUsersCoverage>
    </>
  );
}

export default StewardshipDashboardsPage;
