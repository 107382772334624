import QualityAssessmetRiskEvaluationApi from 'api/quality/assessment/QualityAssessmentRiskEvaluationApi';
import {
  FETCH_PE_RISK_EVALUATION,
  MODIFY_PE_RISK_EVALUATION,
  DELETE_PE_RISK_EVALUATION,
  EXPORT_PE_RISK_EVALUATIONS
} from 'constants/ActionTypes';
import { buildApiCallAction } from 'actions/ActionUtils';

const qualityAssessmetRiskEvaluationApi = new QualityAssessmetRiskEvaluationApi();

export const fetchRiskEvaluations = (physicalEntityUuid) =>
  buildApiCallAction({
    api: qualityAssessmetRiskEvaluationApi,
    apiFunction: (api) => api.getRiskEvaluationsMatrixWithComputedResults(physicalEntityUuid),
    actionType: FETCH_PE_RISK_EVALUATION,
    payload: (response) => ({ ...response, physicalEntityUuid })
  });

export const modifyRiskEvaluation = (riskEvaluation) =>
  buildApiCallAction({
    api: qualityAssessmetRiskEvaluationApi,
    apiFunction: (api) => api.putRiskEvaluation(riskEvaluation),
    actionType: MODIFY_PE_RISK_EVALUATION
  });

export const deleteRiskEvaluation = (riskEvaluation) =>
  buildApiCallAction({
    api: qualityAssessmetRiskEvaluationApi,
    apiFunction: (api) => api.deleteRiskEvaluation(riskEvaluation),
    actionType: DELETE_PE_RISK_EVALUATION
  });

export const exportRiskEvaluations = () =>
  buildApiCallAction({
    api: qualityAssessmetRiskEvaluationApi,
    apiFunction: (api) => api.exportRiskEvaluations(),
    actionType: EXPORT_PE_RISK_EVALUATIONS
  });
