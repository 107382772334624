import { BlindataApi } from './ApiUtils';

const DATA_PRODUCT_URL = '/api/v1/dataproducts';

export default class DataProductsApi extends BlindataApi {
  getDataProducts(params) {
    return this.get(DATA_PRODUCT_URL, params);
  }
  getDataProduct(identifier) {
    return this.get(`${DATA_PRODUCT_URL}/${identifier}`);
  }
  postDataProduct(dataProduct) {
    return this.post(DATA_PRODUCT_URL, dataProduct);
  }
  putDataProduct(dataProduct) {
    return this.put(`${DATA_PRODUCT_URL}/${dataProduct.uuid}`, dataProduct);
  }
  deleteDataProduct(dataProduct) {
    return this.delete(`${DATA_PRODUCT_URL}/${dataProduct.uuid}`);
  }
  getDataProductMesh(identifier) {
    return this.get(`${DATA_PRODUCT_URL}/*/mesh`, { dataProductUuid: identifier });
  }
  exportDataProducts(params) {
    return this.download(`${DATA_PRODUCT_URL}/*/export`, params);
  }
  getDataProductDomains(params) {
    return this.get(`${DATA_PRODUCT_URL}/*/domains`, params);
  }
  getDataProductServicesTypes(params) {
    return this.get(`${DATA_PRODUCT_URL}/*/services-types`, params);
  }
}
