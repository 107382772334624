import React, { Fragment, useState } from 'react';
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Button,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import TeamSelector from '../common/TeamSelector';
import { useTranslation } from 'react-i18next';
import UsersInCurrentTenantItemAutocomplete from 'pages/settings/users/commons/UsersInCurrentTenantItemAutocomplete';

const INITIAL_STATE = {
  teamCode: null,
  user: null,
  canRead: false,
  canWrite: false,
  canApprove: false,
  canPropose: false
};

function TeamPolicyByUserModal({
  title,
  teamPolicyToEdit,
  open,
  handleSubmit,
  handleCancel,
  fixedUserUuid,
  fixedTeamCode
}) {
  let { t } = useTranslation();
  let [editor, setEditor] = useState({
    ...INITIAL_STATE,
    user: fixedUserUuid && { uuid: fixedUserUuid },
    teamCode: fixedTeamCode,
    ...teamPolicyToEdit
  });
  return (
    <Fragment>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {fixedUserUuid && (
            <TeamSelector
              value={editor.teamCode && { code: editor.teamCode }}
              onChange={({ code }) => setEditor({ ...editor, teamCode: code })}></TeamSelector>
          )}
          {fixedTeamCode && (
            <UsersInCurrentTenantItemAutocomplete
              value={editor.user}
              onChange={(user) => setEditor({ ...editor, user })}
            />
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={editor.canRead}
                onChange={(e) => setEditor({ ...editor, canRead: e.target.checked })}></Checkbox>
            }
            label='Read'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editor.canWrite}
                onChange={(e) => setEditor({ ...editor, canWrite: e.target.checked })}></Checkbox>
            }
            label='Write'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editor.canPropose}
                onChange={(e) => setEditor({ ...editor, canPropose: e.target.checked })}></Checkbox>
            }
            label='Propose'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editor.canApprove}
                onChange={(e) => setEditor({ ...editor, canApprove: e.target.checked })}></Checkbox>
            }
            label='Approve'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{t('commons.actions.cancel')}</Button>
          <Button
            onClick={() => {
              handleSubmit(editor);
            }}>
            {t('commons.actions.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
export default TeamPolicyByUserModal;
