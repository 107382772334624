import { combineReducers } from 'redux';
import {
  FETCH_DATA_FLOW_PREVIEWS_PAGE,
  CREATE_DATA_FLOW_PREVIEWS
} from '../../constants/ActionTypes';

const content = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_FLOW_PREVIEWS_PAGE:
      return action.payload.content;
    case CREATE_DATA_FLOW_PREVIEWS:
      return action.payload.content;
    default:
      return state;
  }
};

export default combineReducers({
  content
});
