import React, { useEffect, useState } from 'react';
import { toolbarActionsId } from './MaterialTitlePanel';
import { Portal } from '@mui/material';

export default function ToolbarActions({ children }) {
  let [containerElem, setContainerElem] = useState(null);
  useEffect(() => {
    setContainerElem(document.getElementById(toolbarActionsId));
  }, [setContainerElem]);
  return containerElem ? <Portal container={containerElem}>{children}</Portal> : null;
}
