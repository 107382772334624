import React from 'react';
import PropTypes from 'prop-types';
import { ChipWrapper, ChipItem } from '../../../components/chiplist/ChipList';
import KVDefinitionList from '../../../components/definition-list/KVDefinitionList';
import ResourceTypes from '../../../constants/ResourceTypes';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';
import CardDescription from 'components/carddescription/CardDescription';

function DataActorView({ dataActor, children }) {
  if (dataActor) {
    return (
      <>
        <CardDescription>{dataActor.notes}</CardDescription>

        <h4>Roles</h4>
        <ChipWrapper>
          {(dataActor.roles || []).map((role) => {
            return <ChipItem key={role} id={role} label={role} />;
          })}
        </ChipWrapper>
        <span className='dls-wrapper'>
          <KVDefinitionList
            header='Description'
            item={{
              Code: dataActor.identifierCode,
              'First Name': dataActor.firstName,
              'Last Name': dataActor.lastName,
              'Business Name': dataActor.businessName,
              'Subject Type': dataActor.subjectType,
              'Subject Description': dataActor.subjectDescription,
              Country: dataActor.country
            }}
          />

          <KVDefinitionList
            header='Contacts'
            item={{
              Email: dataActor.email,
              Phone: dataActor.phone,
              Fax: dataActor.fax
            }}
          />

          <KVDefinitionList
            header={'Address'}
            item={{
              State: dataActor.addressState,
              City: dataActor.addressCity,
              'Street Address': dataActor.addressStreet,
              'Postal Code': dataActor.addressPostalCode,
              'Building Number': dataActor.addressBuildingNumber
            }}
          />
          <CustomPropertiesView
            additionalProperties={dataActor.additionalProperties}
            resourceType={ResourceTypes.DATA_ACTOR}
          />
        </span>

        {children}
      </>
    );
  } else {
    return null;
  }
}

DataActorView.propTypes = {
  dataActor: PropTypes.object,
  children: PropTypes.object
};

export default DataActorView;
