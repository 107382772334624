import React from 'react';
import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';

const trendIconStyles = {
  trendUp: {
    color: 'green'
  },
  trendFlat: {
    color: 'black'
  },
  trendDown: {
    color: 'red'
  }
};

export default function QualityTrendingIcon({ trendIndicator }) {
  switch (trendIndicator) {
    case 'INCREASE':
      return <TrendingUp sx={trendIconStyles.trendUp} />;
    case 'DECREASE':
      return <TrendingDown sx={trendIconStyles.trendDown} />;
    case 'STABLE':
    default:
      return <TrendingFlat sx={trendIconStyles.trendFlat} />;
  }
}
