import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchHistoryRecord } from '../../../actions/HistoryActions';

import Breadcrumbs from 'pages/sidebar/Navigation';
import HistoryRecordMetadataCard from './HistoryRecordMetadataCard';
import HistoryRecordPayloadCard from './HistoryRecordPayloadCard';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';

const HistoryDetailPage = ({ recordSequenceId, record, fetchHistoryRecord }) => {
  useEffect(() => {
    fetchHistoryRecord(recordSequenceId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordSequenceId]);

  return record ? (
    <>
      <Breadcrumbs
        elements={[
          { text: 'History', to: '/history/records' },
          {
            text: `${record.targetName ? record.targetName : 'Unknown target'} [${
              record.operation
            }]`
          }
        ]}
      />
      <Tracking
        info={{
          pageTitle: `History: ${record.targetName || 'Unknown target'} [${record.operation}]`,
          category: EngagementCategories.SETTINGS,
          action: EngagementActions.LIST
        }}
      />
      <HistoryRecordMetadataCard record={record} />
      <HistoryRecordPayloadCard sequenceId={recordSequenceId} />
    </>
  ) : null;
};

const mapStateToProps = (state, props) => ({
  recordSequenceId: props.match.params['sequenceId'],
  record: state.history.content[props.match.params['sequenceId']]
});

const mapDispatchToProps = {
  fetchHistoryRecord
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryDetailPage);
