import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { setUsersFilters } from 'actions/UsersActions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function UsersSearchBox() {
  let filters = useSelector((state) => state.settings.users.filters.content || {});
  let dispatch = useDispatch();
  return (
    <TextField
      value={filters.search || ''}
      id='input-with-icon-textfield'
      placeholder='Search...'
      variant='outlined'
      size='small'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Search color='disabled' />
          </InputAdornment>
        )
      }}
      onChange={(event) => {
        dispatch(setUsersFilters({ ...filters, search: event.target.value }));
      }}></TextField>
  );
}
