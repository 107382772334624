import React, { useState } from 'react';
import { Typography, Card, CardContent, Grid, CardActions, Button } from '@mui/material';
import KVDefinitionList from '../../../../components/definition-list/KVDefinitionList';
import TeamEditorModal from '../common/TeamEditorModal';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import Authorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import CardDescription from 'components/carddescription/CardDescription';

function TeamDetailCard({ team, onDelete, onModify }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Team: {team.name}</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`TEAM:${team.name}_${team.code}`}
            pageTitle={`Team: ${team.name}`}></FavoritesWidget>
        </Grid>
      </Grid>

      <Card>
        <CardContent>
          <Typography variant='h5'>Team Info</Typography>
          <CardDescription>{team?.description}</CardDescription>
          <KVDefinitionList
            item={{
              Code: team.code,
              Name: team.name,
              Approval: team.doubleApproval ? 'DOUBLE' : 'STANDARD'
            }}></KVDefinitionList>
        </CardContent>
        <CardActions>
          <Authorize hasPermissions={[Permissions.USERS_EDITOR]}>
            <Button
              children={t('commons.actions.modify')}
              key='modify'
              onClick={() => setOpen(true)}></Button>
          </Authorize>
          <Authorize hasPermissions={[Permissions.USERS_EDITOR]}>
            <ConfirmButton
              message={`Do tou want to delete: ${team.name}?`}
              onClick={() => {
                onDelete(team);
                history.goBack();
              }}>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </Authorize>
        </CardActions>
      </Card>
      <TeamEditorModal
        key={team.code}
        team={team}
        open={open}
        handleSubmit={(team) => {
          setOpen(false);
          onModify(team);
        }}
        handleCancel={() => setOpen(false)}
      />
    </React.Fragment>
  );
}

export default TeamDetailCard;
