import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchSystems } from '../../../actions/SystemsActions';
import ItemAutoCompleteModal from '../../../components/itemselector/ItemAutoCompleteModal';

const PhysicalEntitySearchModal = ({ title, systems, open, onSubmit, onCancel, searchSystems }) => (
  <ItemAutoCompleteModal
    title={title || 'Search System'}
    itemName='System'
    open={open}
    keyProperty='uuid'
    nameProperty='name'
    labelSeparator={' > '}
    values={Object.values(systems)}
    onSearch={(searchText) => searchSystems({ search: searchText })}
    handleCancel={onCancel}
    handleSubmit={onSubmit}
  />
);

PhysicalEntitySearchModal.propTypes = {
  title: PropTypes.string,
  systems: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  searchSystems: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  systems: state.systems.content
});

export default connect(mapStateToProps, { searchSystems })(PhysicalEntitySearchModal);
