import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import _ from 'lodash';
import { DataProductPortPhysicalEntitiesList } from '../list/DataProductPortPhysicalEntitiesList';
import AdditionalPropertiesEditor from 'components/additional_properties/AdditionalPropertiesEditor';
import ResourceTypes from 'constants/ResourceTypes';

import { DataProductPortDependencyItemSelect } from './DataProductPortDependencyItemSelect';
import ItemAutoComplete from 'components/itemselector/ItemAutoComplete';
import { DataProductPortEntityTypes } from 'constants/DataProductPortEntityTypes';
import DataProductServicesTypeAutoComplete from './DataProductServicesTypeAutoComplete';

const DataProductPortEditModal = ({ open, dataProductPort, title, handleClose, onSubmit }) => {
  const [dataProductPortPatched, setDataProductPortPatched] = useState({});

  const mergedDataProductPort = {
    ...dataProductPort,
    ...dataProductPortPatched
  };

  const deletePhysicalEntityFromList = (peToRemove) =>
    setDataProductPortPatched({
      ...dataProductPortPatched,
      physicalEntities: mergedDataProductPort.physicalEntities.filter(
        (pe) => pe.uuid !== peToRemove.uuid
      )
    });

  const updatePhysicalEntitiesList = (pe) =>
    setDataProductPortPatched({
      ...dataProductPortPatched,
      physicalEntities: _.unionWith(mergedDataProductPort.physicalEntities, [pe], _.isEqual)
    });

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DataProductPortDefinition
          mergedDataProductPort={mergedDataProductPort}
          setDataProductPortPatched={setDataProductPortPatched}
          dataProductPortPatched={dataProductPortPatched}
        />
        <AdditionalPropertiesEditor
          additionalProperties={mergedDataProductPort.additionalProperties}
          resourceType={ResourceTypes.DATA_PRODUCT}
          onChange={(newList) =>
            setDataProductPortPatched({ ...dataProductPortPatched, additionalProperties: newList })
          }
        />
        <DataProductPortPhysicalEntitiesList
          dataProductPort={mergedDataProductPort}
          deletePhysicalEntityFromList={deletePhysicalEntityFromList}
          updatePhysicalEntitiesList={updatePhysicalEntitiesList}
        />
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.cancel')}
          onClick={() => {
            handleClose();
          }}
        />
        <Button
          children={t('commons.actions.submit')}
          variant='outlined'
          color='primary'
          disabled={
            _.isEmpty(mergedDataProductPort) ||
            !(
              mergedDataProductPort.name &&
              mergedDataProductPort.displayName &&
              mergedDataProductPort.version &&
              mergedDataProductPort.identifier &&
              mergedDataProductPort.entityType
            )
          }
          onClick={() => {
            onSubmit(mergedDataProductPort);
            handleClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DataProductPortEditModal;

const DataProductPortDefinition = ({
  mergedDataProductPort,
  setDataProductPortPatched,
  dataProductPortPatched
}) => {
  return (
    <>
      <h4>Data Product Port Definition</h4>
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <ItemAutoComplete
            values={_(DataProductPortEntityTypes)
              .map((value, key) => ({ name: value, entityType: key }))
              .value()}
            value={{
              name:
                DataProductPortEntityTypes[mergedDataProductPort.entityType] ||
                mergedDataProductPort.entityType,
              entityType: mergedDataProductPort.entityType
            }}
            nameProperty='name'
            itemName='Entity Type'
            required
            onChange={(value) =>
              setDataProductPortPatched({
                ...dataProductPortPatched,
                entityType: value?.entityType
              })
            }
            onSearch={(value) =>
              setDataProductPortPatched({
                ...dataProductPortPatched,
                entityType: value
              })
            }
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <TextField
            variant='standard'
            fullWidth
            multiline
            required
            placeholder='Insert the name to display'
            label='Display Name'
            value={mergedDataProductPort.displayName || ''}
            onChange={(event) =>
              setDataProductPortPatched({
                ...dataProductPortPatched,
                displayName: event.target.value
              })
            }
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <TextField
            variant='standard'
            fullWidth
            multiline
            required
            placeholder='Insert the fully qualified name'
            label='Fully Qualified Name'
            value={mergedDataProductPort.name || ''}
            onChange={(event) =>
              setDataProductPortPatched({ ...dataProductPortPatched, name: event.target.value })
            }
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <TextField
            variant='standard'
            fullWidth
            multiline
            required
            placeholder='Insert the port identifier here for exact search'
            label='Identifier'
            value={mergedDataProductPort.identifier || ''}
            onChange={(event) =>
              setDataProductPortPatched({
                ...dataProductPortPatched,
                identifier: event.target.value
              })
            }
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <TextField
            variant='standard'
            fullWidth
            multiline
            required
            placeholder='Enter the version'
            label='Version'
            value={mergedDataProductPort.version || ''}
            onChange={(event) =>
              setDataProductPortPatched({
                ...dataProductPortPatched,
                version: event.target.value
              })
            }
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <TextField
            variant='standard'
            fullWidth
            multiline
            placeholder='Enter the description'
            label='Description'
            value={mergedDataProductPort.description || ''}
            onChange={(event) =>
              setDataProductPortPatched({
                ...dataProductPortPatched,
                description: event.target.value
              })
            }
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <DataProductServicesTypeAutoComplete
            margin='dense'
            value={mergedDataProductPort.servicesType || ''}
            onChange={(value) =>
              setDataProductPortPatched({
                ...dataProductPortPatched,
                servicesType: value?.servicesType
              })
            }
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <DataProductPortDependencyItemSelect
            value={mergedDataProductPort.dependsOnIdentifier || ''}
            onChange={(identifier) => {
              setDataProductPortPatched({
                ...dataProductPortPatched,
                dependsOnIdentifier: identifier
              });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
