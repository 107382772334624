export function convertProbesToJob(projectProbes, jobName) {
  const checks = projectProbes.map((probe) => {
    return {
      code: probe.checkCode || probe.checkGroupCode,
      probe: {
        type: probe.type,
        queries: probe.queries.map((query) => {
          let transformedQuery = {
            ...query,
            ...query.queryBody,
            connectionType: query.connectionType?.toLowerCase()
          };
          delete transformedQuery.queryBody;
          return transformedQuery;
        })
      }
    }
  });
  return {
    type: 'QUALITY_PROBES',
    configs: {
      name: jobName,
      checks: checks
    }
  };
}
