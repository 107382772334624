import {
  FETCH_TASKS_PAGE,
  SEARCH_TASKS,
  CREATE_TASK,
  MODIFY_TASK,
  DELETE_TASK,
  SET_TASKS_FILTERS,
  CLEAR_TASKS_FILTERS,
  CREATE_TASK_PHYSICAL_ENTITY_ASSOCIATION,
  FETCH_TASK_PHYSICAL_ENTITY_ASSOCIATIONS,
  REMOVE_TASK_PHYSICAL_ENTITY_ASSOCIATION,
  FETCH_TASK
} from '../constants/ActionTypes';
import { combineReducers } from 'redux';
import _ from 'lodash';

const contentByUuid = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TASK:
    case CREATE_TASK:
    case MODIFY_TASK:
      return {
        ...state,
        [action.payload.uuid]: action.payload
      };
    case FETCH_TASKS_PAGE:
    case SEARCH_TASKS:
      return { ...state, ..._(action.payload.content).keyBy('uuid').value() };
    case DELETE_TASK:
      let { [action.payload.uuid]: _clearedELem, ...stateDiff } = state;
      return stateDiff;
    default:
      return state;
  }
};

const currentPage = (state = { content: [], number: 0, totalPages: 0 }, action) => {
  switch (action.type) {
    case FETCH_TASKS_PAGE:
      return action.payload;
    case DELETE_TASK:
      return { ...state, content: state.content.filter((i) => i.uuid !== action.payload.uuid) };
    case MODIFY_TASK:
      return {
        ...state,
        content: state.content.map((p) => (p.uuid === action.payload.uuid ? action.payload : p))
      };
    default:
      return state;
  }
};

const filters = (
  state = {
    hasFilters: false,
    processings: [],
    endDate: null,
    lifeCycleState: null,
    endDateOperator: null
  },
  action
) => {
  switch (action.type) {
    case SET_TASKS_FILTERS:
      return { ...state, ...action.payload, hasFilters: true };
    case CLEAR_TASKS_FILTERS:
      return {
        hasFilters: false,
        processings: [],
        endDate: null,
        lifeCycleState: null,
        endDateOperator: null
      };
    default:
      return state;
  }
};

const associatedPhysicalEntities = (state = {}, action) => {
  switch (action.type) {
    case CREATE_TASK_PHYSICAL_ENTITY_ASSOCIATION:
      return {
        ...state,
        [action.payload.taskUuid]: (state[action.payload.taskUuid] || []).concat([action.payload])
      };
    case FETCH_TASK_PHYSICAL_ENTITY_ASSOCIATIONS:
      return {
        ...state,
        ..._(action.payload.content).groupBy('taskUuid').value()
      };
    case REMOVE_TASK_PHYSICAL_ENTITY_ASSOCIATION:
      return {
        ...state,
        [action.payload.taskUuid]: _(state[action.payload.taskUuid] || [])
          .reject({ uuid: action.payload.uuid })
          .value()
      };
    default:
      return state;
  }
};

export default combineReducers({ contentByUuid, currentPage, filters, associatedPhysicalEntities });
