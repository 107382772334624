import React from 'react';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import TeamsList from './TeamsList';
import TeamCreateFab from '../common/TeamCreateFab';

const TeamsListPage = () => (
  <>
    <Breadcrumbs
      elements={[
        { text: 'Settings', to: '/settings' },
        { text: 'Teams' }
      ]}
    />
    <Tracking
      info={{
        pageTitle: 'Teams',
        category: EngagementCategories.SETTINGS,
        action: EngagementActions.LIST
      }}
    />

    <TeamsList></TeamsList>
    <TeamCreateFab></TeamCreateFab>
  </>
);

export default TeamsListPage;
