import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withLoader } from '../../../../components/loader/Loader';
import { fetchTemplateEvent } from '../../../../actions/TemplatesActions';
import Step0DataActorReconciliation from './Step0DataActorReconciliation';
import Step1TemplateEvaluation from './Step1TemplateEvaluation';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
function TemplateEventWorkflowPage({ templateEvent, history }) {
  let [stepNumber, setStepNumber] = useState(0);
  let [context, setContext] = useState({ templateEvent });

  const nextStepCallback = (number) => (newContext) => {
    if (number === 2) {
      history.push(`/templates/events`);
    }
    setStepNumber(number);
    setContext({ ...context, ...newContext });
  };

  return (
    <>
      <Breadcrumbs
        elements={[{ text: 'Templates', to: '/templates' }, { text: 'Workflow' }]}></Breadcrumbs>
      <Tracking
        info={{
          pageTitle: 'Template Workflow Page',
          category: EngagementCategories.TEMPLATES,
          action: EngagementActions.DETAIL
        }}
      />
      {stepNumber === 0 && (
        <Step0DataActorReconciliation {...{ context }} onNext={nextStepCallback(1)} />
      )}
      {stepNumber === 1 && (
        <Step1TemplateEvaluation {...{ context }} onNext={nextStepCallback(2)} />
      )}
    </>
  );
}

function mapStateToProps(state, props) {
  const templateEventSequenceId = props.match.params.sequenceId;
  return {
    templateEvent: state.templates.templatesEvents.content[templateEventSequenceId],
    templateEventSequenceId: templateEventSequenceId
  };
}

export default compose(
  connect(mapStateToProps, { fetchTemplateEvent }),
  withLoader({
    onLoad: ({ fetchTemplateEvent, templateEventSequenceId }) =>
      fetchTemplateEvent(templateEventSequenceId),
    ready: ({ templateEvent }) => templateEvent
  })
)(TemplateEventWorkflowPage);
