import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EngagementDashboardsPage from '../engagement/dashboards/EngagementDashboardsPage';

export default function EngagementDashboardsRoutes(props) {
  return (
    <Switch>
      <Route path={`${props.match.url}/dashboards`} component={EngagementDashboardsPage} />
    </Switch>
  );
}
