import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import { deleteContractTemplate } from '../../../../actions/ContractTemplatesActions';
import Authorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { useTranslation } from 'react-i18next';

export const TemplatesListItem = ({ template }) => {
  const { t } = useTranslation();
  return (
    <ExpandableCard
      title={`${template.name}`}
      subheader={`created at: ${template.createdAt}`}
      actions={[
        <Button
          children={t('commons.actions.detail')}
          component={Link}
          to={`/contracts/templates/${template.uuid}`}></Button>,
        <Authorize hasPermissions={[Permissions.CONTRACTS_EDITOR]}>
          <Button
            children={t('commons.actions.modify')}
            component={Link}
            to={`/contracts/templates/editor/edit/${template.uuid}`}></Button>
        </Authorize>,
        <Authorize hasPermissions={[Permissions.CONTRACTS_ADMIN]}>
          <ConfirmButton
            children={t('commons.actions.delete')}
            onClick={() => deleteContractTemplate(template)}
          />
        </Authorize>
      ]}
    />
  );
};
