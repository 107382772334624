import React from 'react';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import QualityCheckAddAction from './QualityCheckAddAction';
import QualityChecksTable from './QualityChecksTable';
import UpdateChecksEnabledButton from '../commons/UpdateChecksEnabledButton';
import QualityCheckExportButton from '../../quality_checks/commons/QualityCheckExportButton';
function QualityChecksCard({ qualitySuite }) {
  return (
    <Card>
      <CardHeader
        title={
          <Grid container direction='row' alignItems='center' justifyContent='flex-start'>
            <Grid item>
              <Typography variant='h6'>Checks</Typography>
            </Grid>
            {qualitySuite.published && (
              <Grid item>
                <UpdateChecksEnabledButton
                  suiteUuid={qualitySuite.uuid}></UpdateChecksEnabledButton>
              </Grid>
            )}
          </Grid>
        }
        action={
          <Grid container direction='row' alignItems='center'>
            <Grid item>
              <QualityCheckAddAction qualitySuite={qualitySuite} />
            </Grid>
            <Grid item>
              <QualityCheckExportButton
                suiteUuid={qualitySuite?.uuid}
                includeProperties={true}></QualityCheckExportButton>
            </Grid>
          </Grid>
        }></CardHeader>
      <CardContent>
        <QualityChecksTable qualitySuite={qualitySuite} />
      </CardContent>
    </Card>
  );
}

export default QualityChecksCard;
