import React from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Toolbar,
  Checkbox,
  FormControlLabel,
  Grid,
  Slider,
  Tooltip
} from '@mui/material';
import { changePerspective } from '../../../../actions/GraphActions';

const GraphPerspectivesToolbar = ({
  logicalPerspective,
  businessPerspective,
  loginsPerspective,
  physicalPerspective,
  dataFlowsPerspective,
  dataFlowsDepth,
  dataFlowsDepthHierarchy,
  includeLogicalRelations,
  changePerspective
}) => (
  <Toolbar variant='dense' style={{ alignItems: 'baseline' }}>
    <Typography variant='body1'>Perspectives:</Typography>
    <Grid container justifyContent='flex-end'>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={logicalPerspective}
              onChange={(event, checked) => changePerspective({ logicalPerspective: checked })}
            />
          }
          label='Logical'
        />
        <br></br>
        <Tooltip title='Select to show logical relations'>
          <FormControlLabel
            label={
              <Typography variant='caption' gutterBottom>
                Show Relations
              </Typography>
            }
            control={
              <Checkbox
                size='small'
                checked={includeLogicalRelations}
                onChange={(event, checked) =>
                  changePerspective({ includeLogicalRelations: checked })
                }
              />
            }
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <FormControlLabel
          label='Business'
          control={
            <Checkbox
              checked={businessPerspective}
              onChange={(event, checked) => changePerspective({ businessPerspective: checked })}
            />
          }
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          label='Logins'
          control={
            <Checkbox
              checked={loginsPerspective}
              onChange={(event, checked) => changePerspective({ loginsPerspective: checked })}
            />
          }
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          label='Physical'
          control={
            <Checkbox
              checked={physicalPerspective}
              onChange={(event, checked) =>
                changePerspective({
                  physicalPerspective: checked,
                  dataFlowsPerspective: checked && dataFlowsPerspective
                })
              }
            />
          }
        />
        <br></br>
        <Tooltip title='Select to show systems in DataFlows traversal'>
          <FormControlLabel
            label={
              <Typography variant='caption' gutterBottom>
                Show Hierarchy
              </Typography>
            }
            control={
              <Checkbox
                size='small'
                disabled={!dataFlowsPerspective}
                checked={dataFlowsDepthHierarchy}
                onChange={(event, checked) =>
                  changePerspective({ dataFlowsDepthHierarchy: checked })
                }
              />
            }
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <FormControlLabel
          label='DataFlows'
          control={
            <Checkbox
              label='DataFlows'
              checked={dataFlowsPerspective}
              onChange={(event, checked) =>
                changePerspective({
                  dataFlowsPerspective: checked,
                  physicalPerspective: physicalPerspective || checked,
                  logicalPerspective: !checked && logicalPerspective,
                  businessPerspective: !checked && businessPerspective,
                  loginsPerspective: !checked && loginsPerspective
                })
              }
            />
          }
        />
        <br></br>
        <Typography variant='caption' id='dataflows-depth-slider' gutterBottom>
          DataFlows Depth
        </Typography>
        <Slider
          disabled={!dataFlowsPerspective}
          value={dataFlowsDepth}
          defaultValue={30}
          aria-labelledby='dataflows-depth-slider'
          valueLabelDisplay='auto'
          step={1}
          marks
          min={0}
          max={10}
          onChange={(event, number) => changePerspective({ dataFlowsDepth: number })}
        />
      </Grid>
    </Grid>
  </Toolbar>
);

const mapStateToProps = (state) => ({
  logicalPerspective: state.graph.filters.logicalPerspective,
  businessPerspective: state.graph.filters.businessPerspective,
  loginsPerspective: state.graph.filters.loginsPerspective,
  physicalPerspective: state.graph.filters.physicalPerspective,
  dataFlowsPerspective: state.graph.filters.dataFlowsPerspective,
  dataFlowsDepth: state.graph.filters.dataFlowsDepth,
  dataFlowsDepthHierarchy: state.graph.filters.dataFlowsDepthHierarchy,
  includeLogicalRelations: state.graph.filters.includeLogicalRelations
});

export default connect(mapStateToProps, { changePerspective })(GraphPerspectivesToolbar);
