import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@mui/material';
import DataFlowEditor from './DataFlowEditor';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  createDataFlow,
  editDataFlow,
  clearDataFlowEditor
} from '../../../actions/DataFlowsActions';

function DataFlowEditorDialog({
  onClose,
  open,
  editor,
  editDataFlow,
  createDataFlow,
  clearDataFlowEditor
}) {
  const [t] = useTranslation();
  const onCancel = () => {
    clearDataFlowEditor();
    onClose();
  };
  const onSave = () => createDataFlow(editor).then(onClose).then(clearDataFlowEditor);

  return (
    <Dialog fullWidth style={{ padding: 8 }} open={open} onClose={onClose}>
      <DialogTitle>Create DataFlow</DialogTitle>
      <DialogContent>
        <DataFlowEditor {...{ editor, onChange: editDataFlow }} />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={onSave}>
          {t('commons.actions.submit')}
        </Button>
        <Button onClick={onCancel}>{t('commons.actions.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default connect(
  (state, ownProps) => ({
    editor: { ...(ownProps.editor || {}), ...state.dataflows.editor }
  }),
  { createDataFlow, clearDataFlowEditor, editDataFlow }
)(DataFlowEditorDialog);
