import React, { useState, useReducer } from 'react';
import _ from 'lodash';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { Button, Card, CardHeader, CardContent, CardActions, Checkbox } from '@mui/material';
import KVDefinitionList from '../../../../components/definition-list/KVDefinitionList';
import Importer from '../utils/Importer';
import { connect } from 'react-redux';
import PaginatedPanel from 'components/pagination/PaginatedPanel';
import JsonVisualizer from 'components/syntaxhighlithter/JsonVisualizer';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';

const LIST_PAGE_SIZE = 100;

function ImportJob({ objects, configuration, onBack, importObject }) {
  // eslint-disable-next-line no-unused-vars
  let [started, setStarted] = useState(false);
  let [page, setPage] = useState(0);
  let [importStates, dispatchImportState] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {}
  );
  let [disabledStates, dispatchDisabledState] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {}
  );
  let [analysisStates, dispatchAnalysisState] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {}
  );
  return (
    <>
      <Card>
        <CardHeader title='Import Summary' />
        <CardContent>
          <KVDefinitionList item={summaryStatsMap(importStates, objects)} />
        </CardContent>
        <CardActions>
          <Button disabled={started} onClick={() => onBack()}>
            Back
          </Button>
          <Button
            disabled={started}
            variant='outlined'
            color='primary'
            onClick={() => {
              setStarted(true);
              dispatchAnalysisState(_.mapValues(analysisStates, () => undefined));
              analyzeObjects(
                objects,
                dispatchAnalysisState,
                disabledStates,
                importObject,
                configuration
              ).then(() => setStarted(false));
            }}>
            Analyze
          </Button>
          <Button
            disabled={started}
            variant='contained'
            color='primary'
            onClick={() => {
              setStarted(true);
              dispatchImportState(_.mapValues(importStates, () => undefined));
              importObjects(
                objects,
                dispatchImportState,
                disabledStates,
                importObject,
                configuration
              ).then(() => setStarted(false));
            }}>
            Start
          </Button>
        </CardActions>
      </Card>

      <Card>
        <CardHeader title='Objects' />
        <CardContent>
          {objects && objects.length > 0 ? (
            <PaginatedPanel
              currentPage={{
                content: objects,
                number: page,
                totalPages: Math.floor(objects.length / LIST_PAGE_SIZE + 1)
              }}
              onPageSelection={(newPage) => setPage(newPage)}>
              {objects
                .slice(page * LIST_PAGE_SIZE, page * LIST_PAGE_SIZE + LIST_PAGE_SIZE)
                .map((o, index) => (
                  <ExpandableCard
                    key={page * LIST_PAGE_SIZE + index}
                    title={`${page * LIST_PAGE_SIZE + index}: ${
                      importStates[page * LIST_PAGE_SIZE + index] || 'PENDING'
                    }`}
                    subheader={getNameFromObject(o)}
                    decorator={
                      <>
                        <VariationTypeDecorator>
                          {analysisStates[page * LIST_PAGE_SIZE + index]}
                        </VariationTypeDecorator>
                        <Checkbox
                          checked={disabledStates[page * LIST_PAGE_SIZE + index] !== true}
                          onClick={(event) => {
                            dispatchDisabledState({
                              [page * LIST_PAGE_SIZE + index]: disabledStates[
                                page * LIST_PAGE_SIZE + index
                              ]
                                ? false
                                : true
                            });
                            event.stopPropagation();
                          }}
                        />
                      </>
                    }>
                    <JsonVisualizer object={o}></JsonVisualizer>
                  </ExpandableCard>
                ))}
            </PaginatedPanel>
          ) : (
            'No data available'
          )}
        </CardContent>
      </Card>
    </>
  );
}

function summaryStatsMap(importStates, objects) {
  return {
    Processed: `${_(importStates).reduce((acc, val) => acc + (val ? 1 : 0), 0)} / ${
      objects.length
    }`,
    Imported: _(importStates).reduce((acc, val, key) => acc + (val === 'OK'), 0),
    Errors: _(importStates).reduce(
      (acc, val, key) => acc + (val !== 'OK' && val !== 'PENDING' && val !== undefined),
      0
    )
  };
}

function importObjects(objects, dispatchImportState, disabledStates, importObject, configuration) {
  const semaphore = new Semaphore(configuration.patchMode ? 1 : 2);
  return Promise.all(
    _(objects).map(function (object, index) {
      return semaphore
        .acquire()
        .then(() => !disabledStates[index] && importObject(object, configuration))
        .then(() => (!disabledStates[index] ? 'OK' : 'IGNORED'))
        .catch((e) => {
          console.error('Import Error', e);
          return !e.response
            ? e.message
            : e.response
                .json()
                .then((data) => data.message || e.message)
                .catch(() => e.message);
        })
        .then((status) => dispatchImportState({ [index]: status }))
        .then(() => semaphore.release());
    })
  );
}

function analyzeObjects(
  objects,
  dispatchAnalysisState,
  disabledStates,
  importObject,
  configuration
) {
  const semaphore = new Semaphore(configuration.patchMode ? 1 : 10);
  return Promise.all(
    _(objects).map(function (object, index) {
      return semaphore
        .acquire()
        .then(() => importObject(object, configuration, true))
        .then((responseData) => (responseData.uuid || responseData.sequenceId ? 'UPDATE' : '?'))
        .catch((e) => 'CREATE')
        .then((status) => dispatchAnalysisState({ [index]: status }))
        .then(() => semaphore.release());
    })
  );
}

function getNameFromObject(object) {
  for (let key in object) {
    return object[key]?.name;
  }
}

export default connect(undefined, {
  importObject: (object, configuration, resolveUuidOnly) => (dispatch, getState) => {
    let access_token = getState().authentication.access_token;
    let selectedTenantUuid = _.get(getState(), 'settings.tenants.selectedTenant.uuid');
    let importer = new Importer(access_token, selectedTenantUuid, configuration);
    return importer.importObject(object, resolveUuidOnly);
  }
})(ImportJob);

function Semaphore(max) {
  var counter = 0;
  var waiting = [];

  var take = function () {
    if (waiting.length > 0 && counter < max) {
      counter++;
      let promise = waiting.shift();
      promise.resolve();
    }
  };

  this.acquire = function () {
    if (counter < max) {
      counter++;
      return new Promise((resolve) => {
        resolve();
      });
    } else {
      return new Promise((resolve, err) => {
        waiting.push({ resolve: resolve, err: err });
      });
    }
  };

  this.release = function () {
    counter--;
    take();
  };

  this.purge = function () {
    let unresolved = waiting.length;

    for (let i = 0; i < unresolved; i++) {
      waiting[i].err('Task has been purged.');
    }

    counter = 0;
    waiting = [];

    return unresolved;
  };
}
