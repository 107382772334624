import React from 'react';
import { fetchSystemDataSubjectCount } from '../../../actions/DashboardsActions';
import { downloadSystemDataSubjectsExport } from '../../../actions/ReportsActions';
import { connect } from 'react-redux';
import { Card, Typography, Button, CardContent, CardHeader, CardActions } from '@mui/material';

const styles = {
  title: {
    marginBottom: '8px'
  },
  card: {
    padding: '8px',
    margin: '8px 0 8px 0'
  }
};

const SystemDataSubjectPanel = ({
  systemUuid,
  dataSubjectsCount,
  fetchSystemDataSubjectCount,
  downloadSystemDataSubjectsExport
}) => (
  <Card sx={styles.card}>
    <CardHeader title='Data Subjects' />
    <CardContent>
      <div style={{ display: 'inline' }}>
        <Typography variant='body1'>
          Data Subjects Count:{' '}
          <Typography style={{ marginLeft: 8, display: 'inline' }} variant='h4'>
            {extractCountNumber(dataSubjectsCount)}
          </Typography>
        </Typography>
      </div>
    </CardContent>
    <CardActions>
      <Button onClick={() => fetchSystemDataSubjectCount({ systemUuid })}>
        Count Data Subjects
      </Button>
      <Button onClick={() => downloadSystemDataSubjectsExport({ systemUuid })}>
        Download Keys
      </Button>
    </CardActions>
  </Card>
);

function extractCountNumber(count) {
  if (count) {
    try {
      let {
        rows: [[countNumber]]
      } = count;
      return countNumber;
    } catch (e) {
      return 'error';
    }
  } else {
    return '?';
  }
}

const mapStateToProps = (state, props) => ({
  dataSubjectsCount: state.dashboards.systems.dataSubjectsCount[props.systemUuid]
});

const mapDispatchToProps = {
  fetchSystemDataSubjectCount,
  downloadSystemDataSubjectsExport
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemDataSubjectPanel);
