import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import AssignmentsFilters from '../commons/AssignmentsFilters';
import AssignmentsTable from './AssignmentsTable';
import AssignmentsStatusConfiguration from '../commons/AssignmentsStatusConfiguration';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import AssignmentsExportButton from '../commons/AssignmentsExportButton';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

export default function AssignmentsListPage() {
  return (
    <>
      <Breadcrumbs
        elements={[{ text: 'Classification', to: '/classification' }, { text: 'Assignments' }]}
      />
      <Tracking
        info={{
          pageTitle: `Assignments`,
          category: EngagementCategories.CLASSIFICATION,
          action: EngagementActions.LIST
        }}
      />
      <Grid container direction='row' alignItems='center'>
        <Grid item>
          <Typography variant='h4'>Assignments </Typography>
        </Grid>
        <Grid item>
          <AssignmentsStatusConfiguration />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget
            pageTitle={'Assignments'}
            iconSize={'small'}
            itemIdentifier={'page:assignments-list'}
          />
        </Grid>
        <Grid item>
          <AssignmentsExportButton />
        </Grid>
        <Grid item>
          <AssignmentsFilters />
        </Grid>
        <Grid item>
          <WebActionsButton page={TargetPages.TASK_ASSIGNMENT_LIST_PAGE} />
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <AssignmentsTable />
        </CardContent>
      </Card>
    </>
  );
}
