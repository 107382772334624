import { ViewerPermissionsByResourceTypes } from 'constants/ResourceTypes';
import _ from 'lodash';

export const hasPermissions = (requiredPermissions) => (state) => {
  let permissions = state.authentication.permissions;
  return _.every(requiredPermissions, (p) => _.includes(permissions, p));
};

export const hasAnyPermissions = (requiredPermissions) => (state) => {
  let permissions = state.authentication.permissions;
  return !_.isNil(_.find(requiredPermissions, (p) => _.includes(permissions, p)));
};

export const hasAnyPermissionsMatching = (predicate) => (state) => {
  let permissions = state.authentication.permissions;
  return permissions.filter(predicate).length >= 1;
};

export const filterViewableResourceTypes = (resourceTypes) => (state) => {
  return resourceTypes.filter((rt) =>
    hasPermissions([ViewerPermissionsByResourceTypes[rt]])(state)
  );
};

export const getCurrentUserUuid = (state) => state.settings.profile.uuid;
