import { BlindataApi } from './ApiUtils';

const RULES_URL = '/api/v1/classification/rules';

export default class RulesApi extends BlindataApi {
  getAllRules(params) {
    return this.get(RULES_URL, params);
  }

  getRule(identifier) {
    return this.get(`${RULES_URL}/${identifier}`);
  }

  postRule(rule) {
    return this.post(RULES_URL, rule);
  }

  putRule(rule) {
    return this.put(`${RULES_URL}/${rule.uuid}`, rule);
  }

  deleteRule(rule) {
    return this.delete(`${RULES_URL}/${rule.uuid}`);
  }

  exportRules(params) {
    return this.download(`${RULES_URL}/*/export`, { ...params, includeProperties: 'false' });
  }
}
