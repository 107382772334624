import React, { useState } from 'react';
import { Typography, TextField, Grid, Button, ButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controlled as CodeMirror } from 'react-codemirror2-react-17';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/display/fullscreen.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import './RpaTemplateEditor.css';
import { SwapHoriz } from '@mui/icons-material';
import Breadcrumbs from 'pages/sidebar/Navigation';

function RpaTemplateEditor({ editor = {}, onEdit, onSave, onDelete, onTest }) {
  const [t] = useTranslation();
  const [type, setType] = useState('PDF');
  const anchorRef = React.useRef(null);
  return (
    <div>
      <Breadcrumbs
        elements={[{ text: 'Processings', to: '/processings' }, { text: 'RoPA Template Editor' }]}
      />

      <Typography variant='h4'>{t('rpaTemplate.editorHeader')}</Typography>
      <TextField
        variant='standard'
        label={t('rpaTemplate.name')}
        margin='dense'
        value={editor.name || ''}
        onChange={(event) => onEdit({ name: event.target.value, unsaved: true })}
        fullWidth
      />

      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        spacing='1'
        style={{ padding: '8px 0' }}>
        <Grid item>
          <ButtonGroup variant='text' ref={anchorRef}>
            <Button disabled={!editor.uuid || editor.unsaved} onClick={() => onTest(editor, type)}>
              Test {type}
            </Button>
            <Button
              size='small'
              aria-label='toggle report type'
              aria-haspopup='menu'
              onClick={() => setType(type === 'PDF' ? 'DOC' : 'PDF')}>
              <SwapHoriz />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <Button disabled={!editor.uuid} onClick={() => onDelete(editor)}>
            {t('commons.actions.delete')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            disabled={!editor.name || !editor.unsaved}
            onClick={() => {
              onEdit({ unsaved: false });
              onSave(editor);
            }}>
            {t('commons.actions.submit')}
          </Button>
        </Grid>
      </Grid>
      <CodeMirror
        style={{ heigth: 'fill-parent' }}
        value={editor.templateContent || ''}
        onBeforeChange={(editor, data, newValue) => {
          onEdit({ templateContent: newValue, unsaved: true });
        }}
        options={{
          mode: 'htmlmixed',
          lineNumbers: true
        }}></CodeMirror>
    </div>
  );
}

export default RpaTemplateEditor;
