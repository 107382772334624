import React from 'react';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function TeamItem({ team }) {
  const { t } = useTranslation();
  return (
    <div className='card-custom-div' style={{ margin: '8px 8px 0' }}>
      <ExpandableCard
        zDepth={1}
        key={team.code}
        title={team.name}
        subheader={team.description}
        actions={[
          <Button
            children={t('commons.actions.detail')}
            key='detail'
            component={Link}
            to={`/settings/teams/${team.code}`}></Button>
        ]}
      />
    </div>
  );
}

export default TeamItem;
