import crudReducers from '../utils';
import { combineReducers } from 'redux';
import {
  CREATE_FAVORITE,
  DELETE_FAVORITE,
  FETCH_FAVORITES_PAGE,
  MODIFY_FAVORITE
} from 'constants/ActionTypes';

import _ from 'lodash';

const contentByItemIdentifier = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FAVORITES_PAGE:
      return {
        ...state,
        ..._.groupBy(action.payload.content, (fav) => fav.itemIdentifier)
      };

    case CREATE_FAVORITE:
      return {
        ...state,
        [action.payload.itemIdentifier]: (state[action.payload.itemIdentifier] || []).concat([
          action.payload
        ])
      };
    case MODIFY_FAVORITE:
      return {
        ...state,
        [action.payload.favorite.itemIdentifier]: (
          state[action.payload.favorite.itemIdentifier] || []
        )
          .filter((fav) => fav.uuid !== action.payload.favorite.uuid)
          .concat([action.payload.favorite])
      };
    case DELETE_FAVORITE:
      return {
        ...state,
        [action.payload.itemIdentifier]: (state[action.payload.itemIdentifier] || []).filter(
          (fav) => fav.itemIdentifier !== action.payload.itemIdentifier
        )
      };
    default:
      return state;
  }
};
const contentByWorkspace = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FAVORITES_PAGE:
      return {
        ...state,
        ..._.groupBy(action.payload.content, (fav) => fav.workspace.uuid)
      };

    case CREATE_FAVORITE:
      return {
        ...state,
        [action.payload.workspace.uuid]: (state[action.payload.workspace.uuid] || []).concat([
          action.payload
        ])
      };
    case MODIFY_FAVORITE:
      return {
        ...state,
        [action.payload.oldWorkspaceUuid]: (state[action.payload.oldWorkspaceUuid] || []).filter(
          (fav) => fav.uuid !== action.payload.favorite.uuid
        ),
        [action.payload.favorite.workspace.uuid]: (
          state[action.payload.favorite.workspace.uuid] || []
        )
          .map((fav) => (fav.uuid === action.payload.favorite.uuid ? action.payload.favorite : fav))
          .concat(
            action.payload.oldWorkspaceUuid !== action.payload.favorite.workspace.uuid
              ? [action.payload.favorite]
              : []
          )
      };
    case DELETE_FAVORITE:
      return {
        ...state,
        [action.payload.workspace.uuid]: (state[action.payload.workspace.uuid] || []).filter(
          (fav) => fav.uuid !== action.payload.uuid
        )
      };
    default:
      return state;
  }
};
export default combineReducers({
  contentByItemIdentifier,
  contentByWorkspace,
  ...crudReducers({ resource: 'FAVORITE', keyProperty: 'uuid' })
});
