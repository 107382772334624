import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material';
import _ from 'lodash';
import TableCell from 'components/tables/GenericTableCell';
import ActionGraph from '@mui/icons-material/Explore';
import ActionProcessing from '@mui/icons-material/Gavel';
import SystemIcon from '@mui/icons-material/StorageRounded';
import PhysicalEntityIcon from '@mui/icons-material/TableChartOutlined';
import PhysicalFieldIcon from '@mui/icons-material/NoteSharp';
import ActionUsers from '@mui/icons-material/SupervisorAccount';
import ActionWork from '@mui/icons-material/Work';
import ActionDataCategories from '@mui/icons-material/FolderOpenTwoTone';
import ActionLogicalField from '@mui/icons-material/Extension';
import ActionQualityCheck from '@mui/icons-material/NetworkCheck';
import WidgetsIcon from '@mui/icons-material/WidgetsOutlined';
import ResourceTypes from 'constants/ResourceTypes';
import FavoritesTableActions from 'pages/social/favorites/items/FavoritesTableActions';
import { useHistory } from 'react-router';

const resourceTypesIcons = {
  [ResourceTypes.DATA_CATEGORY]: <ActionDataCategories style={{ color: '#4248f4' }} />,
  [ResourceTypes.LOGICAL_FIELD]: <ActionLogicalField style={{ color: '#4a9cad' }} />,
  [ResourceTypes.SYSTEM]: <SystemIcon style={{ color: '6b6b6b' }} />,
  [ResourceTypes.PHYSICAL_ENTITY]: <PhysicalEntityIcon style={{ color: '#383838' }} />,
  [ResourceTypes.PHYSICAL_FIELD]: <PhysicalFieldIcon style={{ color: '#4a3c05' }} />,
  [ResourceTypes.PROCESSING]: <ActionProcessing style={{ color: '#ffbc38' }} />,
  [ResourceTypes.DATA_ACTOR]: <ActionUsers style={{ color: '#FF00CC' }} />,
  [ResourceTypes.TASK]: <ActionWork style={{ color: '#ff8e38' }} />,
  [ResourceTypes.QUALITY_CHECK]: <ActionQualityCheck style={{ color: '#acd079' }} />,
  [ResourceTypes.DATA_PRODUCT]: <WidgetsIcon style={{ color: '6b6b6b' }}></WidgetsIcon>
};

function WorkspaceFavoriteTable({
  favoriteItems,
  pagination,
  workspaces,
  maxHeight,
  fetchFavoritesPage,
  workspaceUuid,
  fromWidget
}) {
  const history = useHistory();
  const initialPage = 0,
    initialSize = 20;
  const [tablePagination, setTablePagination] = useState(
    pagination || {
      page: initialPage,
      size: initialSize,
      totalElements: 0
    }
  );
  const handleFetch = (page, size) => {
    fetchFavoritesPage({ workspaceUuid, page, size }).then((response) => {
      setTablePagination({
        page: response?.number || initialPage,
        size: response?.size || initialSize,
        totalElements: response?.totalElements || 0
      });
    });
  };
  return (
    <TableContainer style={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: maxHeight }}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell breakWord>Name</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {favoriteItems.map((item) => (
            <TableRow
              onClick={() => history.push(`${item.pagePath}`)}
              hover
              style={{ cursor: 'pointer' }}
              key={item.uuid}>
              <TableCell>
                {item.resourceType !== null ? (
                  resourceTypesIcons[item.resourceType]
                ) : (
                  <ActionGraph />
                )}
              </TableCell>
              <Tooltip title={item?.alias}>
                <TableCell breakWord>
                  {item?.alias.length > 25 ? (
                    <>
                      <Tooltip title={item?.alias}>
                        <p>{_.truncate(item?.alias, { length: 25 })}</p>
                      </Tooltip>
                    </>
                  ) : (
                    item?.alias
                  )}
                </TableCell>
              </Tooltip>
              <TableCell>
                <FavoritesTableActions workspaces={workspaces} item={item} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!fromWidget && (
        <TablePagination
          component='div'
          style={{ overflowX: 'hidden' }}
          rowsPerPageOptions={[10, 20, 100, 250, 500]}
          page={tablePagination.page}
          rowsPerPage={tablePagination.size}
          count={tablePagination.totalElements}
          onPageChange={(event, page) => handleFetch(page, tablePagination.size)}
          onRowsPerPageChange={(event) =>
            handleFetch(tablePagination.page, event.target.value)
          }></TablePagination>
      )}
    </TableContainer>
  );
}

export default WorkspaceFavoriteTable;
