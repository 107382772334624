import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ItemAutoComplete from '../../../components/itemselector/ItemAutoComplete';
import { DialogContent, DialogTitle, DialogActions } from '@mui/material';

class GrantEditorModal extends Component {
  state = {
    uuid: undefined,
    type: undefined,
    notes: undefined
  };

  onSubmit() {
    this.props.handleSubmit(this.props.systemLogin, this.state.physicalEntity, {
      uuid: this.state.uuid,
      type: this.state.type,
      notes: this.state.notes,
      physicalEntityUuid: this.state.physicalEntity.uuid,
      systemLoginUuid: this.props.systemLogin.uuid
    });
  }

  render() {
    const actions = [
      <Button children='Cancel' primary={false} onClick={this.props.handleCancel} />,
      <Button children='Submit' color='primary' disabled={false} onClick={() => this.onSubmit()} />
    ];

    return (
      <Dialog open={this.props.open}>
        <DialogTitle>{this.props.title}</DialogTitle>

        <DialogContent>
          <ItemAutoComplete
            itemName={'Physical Entity'}
            values={this.props.physicalEntities}
            keyProperty={'uuid'}
            nameProperty={'name'}
            labelSeparator={'.'}
            categoryProperties={['schema']}
            onChange={(physicalEntity) => this.setState({ physicalEntity: physicalEntity })}
            onSearch={(searchText) => this.props.onSearchPhysicalEntities(searchText)}
          />

          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the grant type'
            label='Type'
            onChange={(event, newValue) => this.setState({ type: event.target.value })}
          />

          <TextField
            variant='standard'
            fullWidth
            margin='dense'
            placeholder='Enter the notes'
            label='Notes'
            onChange={(event) => this.setState({ notes: event.target.value })}
          />
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

GrantEditorModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string,
  physicalEntity: PropTypes.object,
  physicalEntities: PropTypes.array.isRequired,
  systemLogin: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSearchPhysicalEntities: PropTypes.func.isRequired
};

export default _.flow([])(GrantEditorModal);
