import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createRoutine } from 'actions/RoutinesActions';
import { AddFAB } from 'components/fab';
import RoutineModal from './RoutineModal';
function RoutineFAB({ createRoutine, system }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      {open && (
        <RoutineModal
          system={system}
          setOpen={setOpen}
          title='Create Routine'
          open={open}
          handleSubmit={(routine) => {
            createRoutine(routine);
            setOpen(false);
          }}
          handleCancel={() => setOpen(false)}></RoutineModal>
      )}
    </>
  );
}

export default connect(null, { createRoutine })(RoutineFAB);
