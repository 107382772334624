import { Button, Grid, Badge } from '@mui/material';
import React from 'react';
import _ from 'lodash';
import IssueIcons from '../detail/IssueIcons';
import IssueTypes from '../detail/IssueTypes';
import { useSelector } from 'react-redux';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';

function IssueResourceListItemWidget({ resourceIdentifier, resourceType, style }) {
  let rdxData = useSelector(
    (state) =>
      state.campaigns.dashboards.issuesTypesCounters[`${resourceType}:${resourceIdentifier}`]
  );
  let data = rdxData || {};
  if (!resourceIdentifier || !resourceType) {
    return null;
  }
  return (
    <div>
      <Button style={{ ...style }} size='small' disabled>
        {_.sum(Object.values(data)) > 0 && (
          <Grid container>
            {issuesOrder.map((type) =>
              data[type] > 0 ? (
                <Grid item>
                  <BadgeIssueIcon badgeContent={data[type]} issueType={type}></BadgeIssueIcon>
                </Grid>
              ) : null
            )}
          </Grid>
        )}
      </Button>
    </div>
  );
}
const issuesOrder = [
  IssueTypes.ALERT,
  IssueTypes.WARNING,
  IssueTypes.BUG,
  IssueTypes.VULNERABILITY,
  IssueTypes.TASK,
  IssueTypes.FEATURE_REQUEST,
  IssueTypes.QUESTION
];

function BadgeIssueIcon({ badgeContent, issueType }) {
  return (
    <Badge
      badgeContent={badgeContent}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}>
      {IssueIcons[issueType]}
    </Badge>
  );
}

export default withPermissions({ hasPermissions: [Permissions.CAMPAIGNS_VIEWER] })(
  IssueResourceListItemWidget
);
