import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function QualityProbesProjectModal({ title, open, project, onSubmit, onClose }) {
  const [projectName, setProjectName] = useState((project && project.name) || undefined);
  const [projectDescription, setProjectDescription] = useState(
    (project && project.description) || undefined
  );
  const { t } = useTranslation();

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          required
          fullWidth
          placeholder={'Enter the name'}
          label={'Name'}
          value={projectName || ''}
          onChange={(event) => setProjectName(event.target.value)} />
        <TextField
          variant="standard"
          fullWidth
          placeholder={'Enter the description'}
          label={'Description'}
          value={projectDescription || ''}
          onChange={(event) => setProjectDescription(event.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={!projectName}
          onClick={() =>
            onSubmit({
              ...project,
              name: projectName,
              description: projectDescription
            })
          }>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QualityProbesProjectModal;
