import React, { useState } from 'react';
import { ChipWrapper, ChipItem } from '../../../components/chiplist/ChipList';
import DataActorsSearchModal from '../utils/DataActorsSearchModal';

export default function DataActorChipListEditor({ dataActors, onChange, onAdd, onRemove }) {
  dataActors = dataActors || [];
  const [open, setOpen] = useState(false);
  const handleDelete = (da) => () => {
    if (onRemove) {
      onRemove(da);
    }
    if (onChange) {
      onChange(
        dataActors.filter((e) => e !== da),
        dataActors
      );
    }
  };
  const handleAdd = (da) => {
    if (onAdd) {
      onAdd(da);
    }
    if (onChange) {
      onChange(dataActors.concat([da]), dataActors);
    }
    setOpen(false);
  };
  return (
    <>
      <ChipWrapper onRequestAdd={(onChange || onAdd) && (() => setOpen(true))}>
        {(dataActors || []).map((dataActor) => (
          <ChipItem
            key={dataActor.uuid}
            onRequestDelete={(onChange || onRemove) && handleDelete(dataActor)}>
            {dataActor.name}
          </ChipItem>
        ))}
      </ChipWrapper>
      <DataActorsSearchModal
        title='Add Data Actor'
        open={open}
        onCancel={() => setOpen(false)}
        onSubmit={handleAdd}></DataActorsSearchModal>
    </>
  );
}
