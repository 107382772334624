import { BlindataApi } from '../../ApiUtils';

export default class WorkspaceApi extends BlindataApi {
  getWorkspaces(params) {
    return this.get('/api/v1/social/favorites/workspaces', params);
  }

  getWorkspace(uuid) {
    return this.get(`/api/v1/social/favorites/workspaces/${uuid}`);
  }

  postWorkspace(workspace) {
    return this.post('/api/v1/social/favorites/workspaces', workspace);
  }

  putWorkspace(workspace) {
    return this.put(`/api/v1/social/favorites/workspaces/${workspace.uuid}`, workspace);
  }

  deleteWorkspace(workspace) {
    return this.delete(`/api/v1/social/favorites/workspaces/${workspace.uuid}`);
  }
}
