import React from 'react';
import ItemSelectorModal from '../../../../components/itemselector/ItemSelectorModal';
import { Button } from '@mui/material';
import Authorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';

export function ProposalItemAddButton({ proposal, resourceType, onSubmit }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Authorize hasPermissions={[Permissions.PROPOSE]}>
      <Button size='small' disabled={proposal == null} onClick={() => setOpen(true)}>
        Add from proposal
      </Button>
      <ProposalItemSelectModal
        open={open}
        proposal={proposal}
        resourceType={resourceType}
        onCancel={() => setOpen(false)}
        onSubmit={(item) => {
          onSubmit(item.resourcePayload);
          setOpen(false);
        }}
      />
    </Authorize>
  );
}

function ProposalItemSelectModal({
  proposal: { items = [] } = {},
  resourceType,
  open = false,
  item = null,
  onCancel,
  onSubmit
}) {
  const availableItems = items.filter((e) => e.resourceType === resourceType);
  return (
    <ItemSelectorModal
      title={`Select a ${resourceType}`}
      itemName={resourceType}
      open={open}
      value={item}
      values={availableItems}
      keyProperty='resourceName'
      nameProperty='resourceName'
      handleCancel={onCancel}
      handleSubmit={onSubmit}
    />
  );
}
