import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ConfigurationGeneralModalSection from './ConfigurationGeneralModalSection';
import TemplateParamsModalSection from './TemplateParamsModalSection';
import RequestTemplateSection from 'pages/settings/webactions/commons/RequestTemplateSection';
import { useSelector } from 'react-redux';

function AgentWebActionConfigurationModal({
  title,
  open,
  handleSubmit,
  handleCancel,
  agentWebActionConfiguration,
  webAction
}) {
  const { t } = useTranslation();
  const [editor, setEditor] = useReducer((s, a) => ({ ...s, ...a }), agentWebActionConfiguration);
  const user = useSelector((state) => state.settings.profile);

  return (
    <Dialog fullWidth open={open} onClick={(event) => event.stopPropagation()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ConfigurationGeneralModalSection editor={editor} setEditor={setEditor} />
        <RequestTemplateSection editor={editor} setEditor={setEditor} />
        <TemplateParamsModalSection editor={editor} setEditor={setEditor} />
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.cancel')}
          onClick={() => {
            handleCancel();
            setEditor(null);
          }}
        />
        <Button
          disabled={
            !editor?.name ||
            (editor?.requestTemplate &&
              (!editor?.requestTemplate?.url || !editor?.requestTemplate?.method))
          }
          variant='contained'
          color='primary'
          children={t('commons.actions.submit')}
          onClick={() => {
            handleSubmit({
              ...addAudit(editor, user),
              webActionUuid: webAction.uuid,
              webActionName: webAction.name
            });
            setEditor(null);
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

function addAudit(editor, user) {
  return !editor?.uuid
    ? { ...editor, createdByUserUuid: user.uuid, createdByUserDisplayName: user.displayName }
    : {
        ...editor,
        updatedByUserDisplayName: user.displayName,
        updatedByUserUuid: user.uuid
      };
}

export default AgentWebActionConfigurationModal;
