import React from 'react';
import { Paper } from '@mui/material';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Paper style={{ padding: 16, margin: '8px auto', textAlign: 'center' }}>
          {this.props.message}
        </Paper>
      );
    }
    return this.props.children;
  }
}
