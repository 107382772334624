import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TaskGrantItem from './TaskGrantItem';
import TaskGrantEditorModal from './TaskGrantEditorModal';
import { AddFAB } from '../../components/fab';
import { Typography } from '@mui/material';

export default class TaskGrantsList extends Component {
  state = {
    createOpen: false
  };

  render() {
    return (
      <div>
        <Typography variant='h4'>Task Grants</Typography>
        {this.props.taskGrants.map((tg) => (
          <TaskGrantItem taskGrant={tg} deleteTaskGrant={this.props.deleteTaskGrant} />
        ))}
        <AddFAB onClick={() => this.setState({ createOpen: true })} />
        <TaskGrantEditorModal
          title='Create TaskGrant'
          open={this.state.createOpen}
          searchSystemLogins={this.props.searchSystemLogins}
          systemLogins={this.props.systemLogins}
          tasks={this.props.tasks}
          handleSubmit={(tg) =>
            this.props.createTaskGrant(tg).then(() => this.setState({ createOpen: false }))
          }
          handleCancel={() => this.setState({ createOpen: false })}
        />
      </div>
    );
  }
}

TaskGrantsList.propTypes = {
  createTaskGrant: PropTypes.func.isRequired,
  deleteTaskGrant: PropTypes.func.isRequired,
  taskGrants: PropTypes.array.isRequired,
  systemLogins: PropTypes.array.isRequired,
  searchSystemLogins: PropTypes.func.isRequired
};
