import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import { Select, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ItemSelectField = (props) => {
  let value = props.value;
  let valueKey = value ? value[props.keyProperty] : undefined;
  let valuesByUuid = _.reduce(
    props.values,
    (obj, val) => {
      obj[val[props.keyProperty]] = val;
      return obj;
    },
    {}
  );
  let nameProperty = props.nameProperty;
  let keyProperty = props.keyProperty;
  let categoryProperties = props.categoryProperties || [];
  let randomId = Math.random().toString();
  let { t } = useTranslation();
  return (
    <form>
      <FormControl variant="standard" fullWidth margin={props.margin || 'none'}>
        <InputLabel id={`selector-${randomId}-label`}>{props.itemName}</InputLabel>
        <Select
          variant="standard"
          labelId={`selector-${randomId}-label`}
          id={`selector-${randomId}`}
          value={valueKey || ''}
          onChange={(event) => props.onChange(valuesByUuid[event.target.value])}
          displayEmpty>
          {!props.mandatory && (
            <MenuItem key={'null'} value={null} children={null}>
              <em>{t('commons.placeholders.none')}</em>
            </MenuItem>
          )}

          {props.values.map((lf) => {
            var category = '';
            if ((categoryProperties || []).length === 1) {
              category = lf[categoryProperties[0]] + ' > ';
            } else if ((categoryProperties || []).length === 2) {
              category = lf[categoryProperties[0]][categoryProperties[1]] + ' > ';
            }
            return (
              <MenuItem
                key={lf[keyProperty]}
                value={lf[keyProperty]}
                children={category + lf[nameProperty]}
              />
            );
          })}
        </Select>
        {!valueKey && <FormHelperText>{`Select ${props.itemName}`}</FormHelperText>}
      </FormControl>
    </form>
  );
};

ItemSelectField.propTypes = {
  itemName: PropTypes.string.isRequired,
  value: PropTypes.object,
  values: PropTypes.array.isRequired,
  keyProperty: PropTypes.string.isRequired,
  nameProperty: PropTypes.string.isRequired,
  categoryProperties: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  margin: PropTypes.string,
  mandatory: PropTypes.bool
};

export default ItemSelectField;
