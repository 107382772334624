import React from 'react';
import _ from 'lodash';

import { Table, TableHead, TableBody, TableRow } from '@mui/material';

import TableCell from 'components/tables/GenericTableCell';

import {
  greenSemaphoreColor,
  yellowSemaphoreColor,
  redSemaphoreColor
} from 'pages/quality/kqis/quality_checks/commons/QualitySemaphoreColors';

const DOMAINS_TYPE_IDX = 0;
const DOMAINS_VALUE_IDX = 1;
const DOMAINS_LABEL_IDX = 2;

const styles = {
  unstyledCell: {
    backgroundColor: 'unset',
    border: 'unset'
  },
  headerCell: {
    backgroundColor: 'rgb(245,245,245)',
    fontWeight: 500
  },
  subheaderCell: {
    backgroundColor: 'rgb(250,250,250)',
    fontWeight: 400,
    fontStyle: 'italic'
  },
  greenCell: {
    backgroundColor: greenSemaphoreColor,
    borderRight: '1px solid rgb(224, 224, 224)'
  },
  yellowCell: {
    backgroundColor: yellowSemaphoreColor,
    borderRight: '1px solid rgb(224, 224, 224)'
  },
  redCell: {
    backgroundColor: redSemaphoreColor,
    borderRight: '1px solid rgb(224, 224, 224)'
  }
};

const ScoringMatrix = ({
  domains,
  matrix,
  columnsDomainElementType,
  rowsDomainElementType,
  matrixEntriesDomainElementType,
  columnsHeader,
  rowsHeader,
  mirroredColors
}) => {
  const columnsValues = domains
    .filter((e) => e[DOMAINS_TYPE_IDX] === columnsDomainElementType)
    .sort((e1, e2) => e1[DOMAINS_VALUE_IDX] - e2[DOMAINS_VALUE_IDX])
    .map((e) => e[DOMAINS_LABEL_IDX]);
  const rowsValues = domains
    .filter((e) => e[DOMAINS_TYPE_IDX] === rowsDomainElementType)
    .sort((e1, e2) => e1[DOMAINS_VALUE_IDX] - e2[DOMAINS_VALUE_IDX])
    .map((e) => e[DOMAINS_LABEL_IDX]);
  const matrixEntries = domains.filter(
    (e) => e[DOMAINS_TYPE_IDX] === matrixEntriesDomainElementType
  );
  const mappedMatrix = matrix.map((e) => [
    lookupDomain(domains, columnsDomainElementType, e[0]),
    lookupDomain(domains, rowsDomainElementType, e[1]),
    lookupDomain(domains, matrixEntriesDomainElementType, e[2])
  ]);

  const columns = [...columnsValues];
  const rows = rowsValues.map((rowValue) => {
    return columnsValues.reduce(
      (acc, columnValue) => {
        const matrixValue = mappedMatrix.find(
          (e) => e[0].label === columnValue && e[1].label === rowValue
        );
        return [...acc, matrixValue[2]];
      },
      [rowValue]
    );
  });

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell sx={styles.unstyledCell} />
          <TableCell sx={styles.unstyledCell} />
          <TableCell sx={styles.headerCell} align='center' colSpan={columnsValues.length}>
            {columnsHeader}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={styles.unstyledCell} />
          <TableCell sx={styles.unstyledCell} />
          {columns.map((c) => (
            <TableCell key={c} sx={styles.subheaderCell}>
              {c}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((r, rIndex) => (
          <TableRow key={rIndex}>
            {r.map((c, cIndex) => {
              if (rIndex === 0 && cIndex === 0) {
                return (
                  <>
                    <TableCell sx={styles.headerCell} rowSpan={rows.length}>
                      {rowsHeader}
                    </TableCell>
                    <TableCell sx={styles.subheaderCell}>{c}</TableCell>
                  </>
                );
              } else if (cIndex === 0) {
                return <TableCell sx={styles.subheaderCell}>{c}</TableCell>;
              } else {
                return (
                  <TableCell
                    sx={() => {
                      let color = getCellColor(c.value, matrixEntries.length, mirroredColors);
                      switch (color) {
                        case 'green':
                          return styles.greenCell;
                        case 'yellow':
                          return styles.yellowCell;
                        case 'red':
                          return styles.redCell;
                        default:
                          return {};
                      }
                    }}>
                    {c.label}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const lookupDomain = (domains, domainType, valueToSearch) => {
  let row = _.find(
    domains,
    (domainElement) =>
      domainElement[DOMAINS_TYPE_IDX] === domainType &&
      domainElement[DOMAINS_VALUE_IDX] === valueToSearch
  );
  if (row) {
    return {
      value: row[DOMAINS_VALUE_IDX],
      label: row[DOMAINS_LABEL_IDX]
    };
  } else {
    throw new Error(`Invalid ${domainType} lookup with ${valueToSearch}`);
  }
};

const getCellColor = (value, totalDomainElements, mirrored = false) => {
  if (value < totalDomainElements / 3) {
    return !mirrored ? 'green' : 'red';
  }
  if (value > Math.round(totalDomainElements / 3) * 2) {
    return !mirrored ? 'red' : 'green';
  }
  return 'yellow';
};

export default ScoringMatrix;
