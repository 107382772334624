import { BlindataApi } from './ApiUtils';

export default class SettingsConfigurationsApi extends BlindataApi {
  getSettingsConfigurations(params) {
    return this.get('/api/v1/settings/configurations', params);
  }

  getSettingsConfiguration(name) {
    return this.get(`/api/v1/settings/configurations/${name}`);
  }

  postSettingsConfiguration(body) {
    return this.post(`/api/v1/settings/configurations/${body.name}`, body);
  }
  putSettingsConfiguration(body) {
    return this.put(`/api/v1/settings/configurations/${body.name}`, body);
  }

  deleteSettingsConfiguration(name) {
    return this.delete(`/api/v1/settings/configurations/${name}`);
  }
}
