import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteQueryBucket, modifyQueryBucket } from 'actions/QueryBucketsActions';
import { connect } from 'react-redux';
import QueryBucketModal from '../commons/QueryBucketModal';
import { useHistory } from 'react-router';
import { ChipItem } from 'components/chiplist/ChipList';
import CardDescription from 'components/carddescription/CardDescription';
import TeamCodeDecorator from 'pages/settings/teams/common/TeamCodeDecorator';
import QueryBucketStats from '../commons/QueryBucketStats';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import QueryBucketCloneButton from '../commons/QueryBucketCloneButton';
import { getIconShort, getTechnologyConfig } from 'constants/SystemTechnologyTypes';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';

const QueryBucketDetailSection = ({ queryBucket, deleteQueryBucket, modifyQueryBucket }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  if (!queryBucket) return null;
  return (
    <Card key={queryBucket.uuid} style={{ margin: '8px 0 8px 0' }}>
      <CardHeader
        title={queryBucket?.name}
        action={<TeamCodeDecorator teamCode={queryBucket?.teamCode} />}
        subheader={
          queryBucket.additionalProperties.filter((prop) => prop.name === '_dataFlowStrategy')
            .length > 0 ? (
            <>
              {`Automatic dataflow creation at ${
                queryBucket.additionalProperties
                  .filter((prop) => prop.name === '_dataFlowStrategy')
                  .map((prop) => prop.value)[0]
              } level`}
            </>
          ) : (
            <></>
          )
        }
      />
      <CardContent>
        <>
          <CardDescription>{queryBucket?.description}</CardDescription>
          <QueryBucketStats queryBucket={queryBucket} />
          <h4>{t('querybuckets.detail_section.systems')}</h4>
          {(queryBucket?.systems || []).map((system) => (
            <ChipItem
              icon={getIconShort(getTechnologyConfig(system.technology), {
                width: '2em',
                height: '2em'
              })}
              key={system.uuid}
              id={system.uuid}
              label={system.name}
              to={`/systems/${system.uuid}`}
            />
          ))}
        </>
      </CardContent>
      <CardActions>
        <Authorize hasPermissions={[Permissions.QUERY_PARSER_EDITOR]}>
          <Button onClick={() => setOpen(true)}>{t('querybuckets.actions.modify')}</Button>
          <ConfirmButton
            message={t('querybuckets.delete_confirmation', {
              queryBucketName: queryBucket.name
            })}
            children={t('querybuckets.actions.delete')}
            onClick={() => {
              deleteQueryBucket(queryBucket);
              history.goBack();
            }}
          />
          <QueryBucketCloneButton queryBucket={queryBucket} />
        </Authorize>
        {open && (
          <QueryBucketModal
            title={t('querybuckets.modify_title')}
            open={open}
            queryBucket={queryBucket}
            onClose={() => {
              setOpen(false);
            }}
            onSubmit={(queryBucket) => {
              setOpen(false);
              modifyQueryBucket(queryBucket);
            }}
          />
        )}
        <div style={{ marginLeft: 'auto' }}>
          <WebActionsButton resource={queryBucket} page={TargetPages.QUERY_BUCKET_DETAIL_PAGE} />
        </div>
      </CardActions>
    </Card>
  );
};
export default connect(null, { modifyQueryBucket, deleteQueryBucket })(QueryBucketDetailSection);
