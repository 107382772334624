import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { t } from 'i18next';
import Breadcrumbs from 'pages/sidebar/Navigation';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionsBuilder from 'actions/PhysicalConstraintsActions';
import TableCell from 'components/tables/GenericTableCell';
import { useHistory } from 'react-router';
import PhysicalConstraintFAB from '../commons/PhysicalConstraintFAB';
import PhysicalConstraintsListToolBox from './PhysicalConstraintsListToolBox';
import { ConstraintTypesIcons } from 'constants/PhysicalConstraintTypes';

const PhysicalConstraintsListPage = ({ _props }) => {
  const { content, number, size, totalElements } = useSelector(
    (state) => state.physicalconstraints.currentPage
  );

  const rawFilters = useSelector((state) => state.physicalconstraints.filters.content);

  const filters = { ...rawFilters, systemUuid: rawFilters?.system?.uuid };

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(ActionsBuilder.fetchPage({ page: 0, ...filters }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rawFilters]);

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.physical_model'), to: '/systems' },
          { text: t('sidebar.physicalConstraints') }
        ]}
      />
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Typography variant='h4'>{t('physicalConstraints.header')}</Typography>
        </Grid>
        <Grid item>
          <PhysicalConstraintsListToolBox />
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          {content && content.length > 0 ? (
            <>
              <Table style={{ overflowX: 'auto' }} size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>System</TableCell>
                    <TableCell>Schema</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content.map((pc) => (
                    <TableRow
                      sx={{ height: '36px' }}
                      hover
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/physical-constraints/detail/${pc.uuid}`)}>
                      <TableCell>{pc?.system?.name || ''}</TableCell>
                      <TableCell>{pc?.schema || ''}</TableCell>
                      <TableCell>{getConstraintNameWithIcon(pc)}</TableCell>
                      <TableCell>{pc?.type || ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component='div'
                count={totalElements}
                page={number}
                rowsPerPage={size}
                onPageChange={(event, page) =>
                  dispatch(
                    ActionsBuilder.fetchPage({
                      page: page,
                      size: size,
                      ...filters
                    })
                  )
                }
                onRowsPerPageChange={(event) =>
                  dispatch(
                    ActionsBuilder.fetchPage({
                      page: 0,
                      size: event.target.value,
                      ...filters
                    })
                  )
                }
              />
            </>
          ) : (
            <div style={{ textAlign: 'center', padding: '2%' }}>
              <Typography variant='body1'>No physical constraint found</Typography>
            </div>
          )}
        </CardContent>
      </Card>
      <PhysicalConstraintFAB />
    </>
  );
};

function getConstraintNameWithIcon(pc) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {ConstraintTypesIcons[pc.type] || <></>}
      {` `}
      <div style={{ paddingLeft: '8px' }}>{pc?.name || ''}</div>
    </div>
  );
}

export default PhysicalConstraintsListPage;
