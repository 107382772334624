import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchAssignmentsConfig,
  modifyAssignmentsConfig
} from '../../../../actions/AssignmentsConfigActions';
import { compose } from 'redux';
import { withLoader } from '../../../../components/loader/Loader';

function AssignmentConfigModal({
  open,
  onClose,
  fullScreen = false,
  assignmentsConfig,
  modifyAssignmentsConfig
}) {
  const { t } = useTranslation();
  const REFUSED_LOWER_BOUND = 0;
  const CONFIRMED_UPPER_BOUND = 1;
  const [refusedUpperBound, setRefusedUpperBound] = useState(
    assignmentsConfig.refusedInterval.upperBound
  );
  const [pendingValues, setPendingValues] = useState([
    assignmentsConfig.pendingInterval.lowerBound,
    assignmentsConfig.pendingInterval.upperBound
  ]);
  const [confirmedLowerBound, setConfirmedLowerBound] = useState(
    assignmentsConfig.confirmedInterval.lowerBound
  );
  useEffect(
    function () {
      setRefusedUpperBound(assignmentsConfig.refusedInterval.upperBound);
      setPendingValues([
        assignmentsConfig.pendingInterval.lowerBound,
        assignmentsConfig.pendingInterval.upperBound
      ]);
      setConfirmedLowerBound(assignmentsConfig.confirmedInterval.lowerBound);
    },
    [assignmentsConfig]
  );

  const handleRefusedRange = (event, newRefusedRange) => {
    if (newRefusedRange[1] > REFUSED_LOWER_BOUND) {
      setRefusedUpperBound(newRefusedRange[1]);
      setPendingValues([newRefusedRange[1], pendingValues[1]]);
    }
  };

  const handlePendingRange = (event, newPendingValues) => {
    if (newPendingValues[0] > REFUSED_LOWER_BOUND && newPendingValues[1] < CONFIRMED_UPPER_BOUND) {
      setRefusedUpperBound(newPendingValues[0]);
      setPendingValues(newPendingValues);
      setConfirmedLowerBound(newPendingValues[1]);
    }
  };

  const handleConfirmedRange = (event, newConfirmedValues) => {
    if (newConfirmedValues[0] < CONFIRMED_UPPER_BOUND) {
      setConfirmedLowerBound(newConfirmedValues[0]);
      setPendingValues([pendingValues[0], newConfirmedValues[0]]);
    }
  };

  return (
    <Dialog fullWidth fullScreen={fullScreen} open={open}>
      <DialogTitle>Assignment Configuration</DialogTitle>
      <DialogContent>
        <h4>Refused assignments bounds</h4>
        <br></br>
        <Slider
          value={[REFUSED_LOWER_BOUND, refusedUpperBound]}
          onChange={handleRefusedRange}
          valueLabelDisplay='on'
          step={0.05}
          max={CONFIRMED_UPPER_BOUND}
          min={REFUSED_LOWER_BOUND}
          marks
          aria-labelledby='refused-range-slides'
          getAriaValueText={() => [REFUSED_LOWER_BOUND, refusedUpperBound]}
        />
        <br></br>
        <h4>Pending assignments bounds</h4>
        <br></br>
        <Slider
          value={pendingValues}
          onChange={handlePendingRange}
          valueLabelDisplay='on'
          step={0.05}
          max={CONFIRMED_UPPER_BOUND}
          min={REFUSED_LOWER_BOUND}
          marks
          aria-labelledby='pending-range-slides'
          getAriaValueText={() => pendingValues}
        />
        <br></br>
        <h4>Confirmed assignments bounds</h4>
        <br></br>
        <Slider
          value={[confirmedLowerBound, CONFIRMED_UPPER_BOUND]}
          onChange={handleConfirmedRange}
          valueLabelDisplay='on'
          step={0.05}
          max={CONFIRMED_UPPER_BOUND}
          min={REFUSED_LOWER_BOUND}
          marks
          aria-labelledby='confirmed-range-slides'
          getAriaValueText={() => [confirmedLowerBound, CONFIRMED_UPPER_BOUND]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => {
            assignmentsConfig.refusedInterval.upperBound = refusedUpperBound;
            assignmentsConfig.pendingInterval.lowerBound = pendingValues[0];
            assignmentsConfig.pendingInterval.upperBound = pendingValues[1];
            assignmentsConfig.confirmedInterval.lowerBound = confirmedLowerBound;
            modifyAssignmentsConfig(assignmentsConfig);
            onClose();
          }}>
          {t('commons.actions.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    assignmentsConfig: state.classification.assignmentsconfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modifyAssignmentsConfig: (assignmentsConfig) =>
      dispatch(modifyAssignmentsConfig(assignmentsConfig)),
    fetchAssignmentsConfig: (props) => dispatch(fetchAssignmentsConfig(props))
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchAssignmentsConfig }) => fetchAssignmentsConfig()
  })
)(AssignmentConfigModal);
