import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import TableCell from 'components/tables/GenericTableCell';
import { t } from 'i18next';
import _ from 'lodash';

export const WebHookModalRequestTemplateParams = ({ editor, setEditor }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Grid container alignItems='center' paddingTop={2} paddingBottom={1}>
        <Typography>Request Template Params</Typography>
        <IconButton size='small' onClick={() => setOpen(true)}>
          <AddBoxIcon />
        </IconButton>
        {editor?.requestTemplate?.requestTemplateParams &&
          editor?.requestTemplate?.requestTemplateParams.length > 0 && (
            <Table size='small'>
              <TableHead>
                <TableCell align='left'>Parameter</TableCell>
                <TableCell align='right'></TableCell>
              </TableHead>
              <TableBody>
                {editor?.requestTemplate?.requestTemplateParams.map((templateParam) => (
                  <TableRow key={templateParam}>
                    <TableCell align='left'>{templateParam}</TableCell>
                    <TableCell align='right'>
                      <IconButton
                        size='small'
                        onClick={() =>
                          setEditor({
                            requestTemplate: {
                              ...editor?.requestTemplate,
                              requestTemplateParams:
                                editor?.requestTemplate?.requestTemplateParams?.filter(
                                  (param) => param !== templateParam
                                )
                            }
                          })
                        }>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
      </Grid>
      {open && (
        <RequestTemplateParametersModal
          open={open}
          onSubmit={(parameter) => {
            setEditor({
              requestTemplate: {
                ...editor?.requestTemplate,
                requestTemplateParams: _(
                  [].concat(editor?.requestTemplate?.requestTemplateParams || [], parameter)
                )
                  .uniq()
                  .value()
              }
            });
            setOpen(false);
          }}
          onCancel={() => setOpen(false)}
        />
      )}
    </>
  );
};

const RequestTemplateParametersModal = ({ open, onCancel, onSubmit }) => {
  const [parameter, setParameter] = useState();

  return (
    <Dialog open={open}>
      <DialogTitle>Add a request template parameter</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          fullWidth
          margin='dense'
          label='Parameter'
          placeholder='Enter the parameter'
          value={parameter || ''}
          onChange={(event) => setParameter(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={!parameter}
          variant='outlined'
          color='primary'
          onClick={() => onSubmit(parameter)}>
          {t('commons.actions.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
