import React, { useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Popover,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid
} from '@mui/material';
import WorkspacesSelector from '../workspaces/commons/WorkspacesSelector';
import WorkspaceModal from '../workspaces/commons/WorkspaceModal';
import { useTranslation } from 'react-i18next';

function FavoriteWidgetModal({
  id,
  open,
  anchorEl,
  handleClose,
  workspaces,
  handleDelete,
  onSubmit,
  createWorkspace,
  favorite,
  title
}) {
  const [favoritePatch, setFavoritePatch] = useState({});
  const [createWorkspaceOpen, setCreateWorkspaceOpen] = useState(false);

  const mergedFavorite = {
    ...favorite,
    ...favoritePatch
  };
  const { t } = useTranslation();
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={id}
      onClose={() => {
        handleClose();
        setFavoritePatch({});
      }}
      elevation={2}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={3}>
            <Typography variant='overline'>Name</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              label='Name'
              margin='dense'
              variant='outlined'
              value={mergedFavorite?.alias || ''}
              onChange={(event) =>
                setFavoritePatch({ ...favoritePatch, alias: event.target.value })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant='overline'>Workspace</Typography>
          </Grid>
          <Grid item xs={9}>
            {(favoritePatch?.workspace || mergedFavorite?.workspace) && (
              <WorkspacesSelector
                workspace={favoritePatch?.workspace || mergedFavorite?.workspace}
                workspaces={workspaces}
                kp='uuid'
                np='name'
                fullWidth
                onChange={(workspace) =>
                  setFavoritePatch({ ...favoritePatch, workspace: workspace })
                }
                setCreateWorkspaceOpen={setCreateWorkspaceOpen}></WorkspacesSelector>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.cancel')}
          onClick={handleClose}
          style={{ float: 'left' }}></Button>
        <div style={{ flexGrow: 1 }} />
        <Button children={t('commons.actions.delete')} onClick={handleDelete}>
          Delete
        </Button>
        <Button
          color='primary'
          variant='contained'
          children={t('commons.actions.submit')}
          onClick={() => {
            onSubmit(mergedFavorite);
            handleClose();
          }}></Button>
      </DialogActions>
      {createWorkspaceOpen && (
        <WorkspaceModal
          title='Create Worskpace'
          open={createWorkspaceOpen}
          onSubmit={(workspace) => {
            setCreateWorkspaceOpen(false);
            createWorkspace(workspace).then((response) =>
              onSubmit({ ...mergedFavorite, workspace: response })
            );
          }}
          onCancel={() => setCreateWorkspaceOpen(false)}
        />
      )}
    </Popover>
  );
}

export default FavoriteWidgetModal;
