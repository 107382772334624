import React, { useState } from 'react';
import RolesItemAutoComplete from '../../roles/commons/RolesItemAutoComplete';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import UsersInCurrentTenantItemAutocomplete from 'pages/settings/users/commons/UsersInCurrentTenantItemAutocomplete';
import { useTranslation } from 'react-i18next';

function StewardshipAssignmentsFilters({ open, setOpen, onSubmit, filters, setFilters }) {
  const [editor, setEditor] = useState(filters);
  let { t } = useTranslation();
  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>{'Set filters'}</DialogTitle>
      <DialogContent>
        <UsersInCurrentTenantItemAutocomplete
          value={filters.user || null}
          onChange={(user) =>
            setEditor({ ...editor, user: user && user })
          }></UsersInCurrentTenantItemAutocomplete>
        <RolesItemAutoComplete
          value={filters.stewardshipRole || null}
          onChange={(role) => {
            setEditor({ ...editor, stewardshipRole: role && role });
          }}></RolesItemAutoComplete>
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.clear')}
          onClick={() => {
            setEditor(null);
            setFilters({ user: null, stewardshipRole: null });
            onSubmit({
              userUuid: null,
              stewardshipRoleUuid: null
            });
            setOpen(false);
          }}></Button>
        <Button
          children={t('commons.actions.apply')}
          variant='outlined'
          color='primary'
          onClick={() => {
            setFilters({ user: editor?.user, stewardshipRole: editor?.stewardshipRole });
            onSubmit({
              userUuid: editor?.user?.uuid,
              stewardshipRoleUuid: editor?.stewardshipRole?.uuid
            });
            setOpen(false);
          }}></Button>
      </DialogActions>
    </Dialog>
  );
}

export default StewardshipAssignmentsFilters;
