import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { modifyQueryStatement, deleteQueryStatement } from 'actions/QueryStatementsActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ConfirmMenuItem from 'components/confirmbutton/ConfirmMenuItem';
import QueryStatementModal from '../commons/QueryStatementModal';
import { fetchQueryBucket } from 'actions/QueryBucketsActions';

const QueryStatementsCardActions = ({
  queryStatement,
  t,
  modifyQueryStatement,
  deleteQueryStatement,
  fetchQueryBucket,
  onRefresh
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  let isOpen = Boolean(anchorEl);

  return (
    <>
      <IconButton
        size='small'
        aria-haspopup='true'
        aria-controls='query-statements-card-actions'
        onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        onClick={(event) => event.stopPropagation()}
        id='query-statements-table-actions'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {isOpen && (
          <MenuItem
            onClick={(event) => {
              setOpenEdit(true);
              setAnchorEl(null);
              event.stopPropagation();
            }}>
            {t('commons.actions.modify')}
          </MenuItem>
        )}
        {isOpen && (
          <ConfirmMenuItem
            message={t('querystatements.card.actions.confirm')}
            onClick={(event) => {
              setAnchorEl(null);
              deleteQueryStatement(queryStatement)
                .then(() => fetchQueryBucket(queryStatement.queryBucket?.uuid))
                .then(onRefresh);
            }}
            onClose={() => setAnchorEl(null)}>
            {t('commons.actions.delete')}
          </ConfirmMenuItem>
        )}
      </Menu>

      <QueryStatementModal
        queryStatement={queryStatement}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        onSubmit={(queryStatement) => {
          setOpenEdit(false);
          modifyQueryStatement(queryStatement).then(() =>
            fetchQueryBucket(queryStatement.queryBucket?.uuid)
          );
        }}></QueryStatementModal>
    </>
  );
};

export default compose(
  withTranslation(),
  connect(null, { modifyQueryStatement, deleteQueryStatement, fetchQueryBucket })
)(QueryStatementsCardActions);
