import { BlindataApi } from './ApiUtils';

const TEMPLATES_PROCESSINGS_URL = '/api/v1/templates/processings';
const TEMPLATES_TASKS_URL = '/api/v1/templates/tasks';
const TEMPLATES_MAPPINGS_URL = '/api/v1/templates/mappings';
const TEMPLATES_EVENTS_URL = '/api/v1/templates/events';

export default class TemplatesApi extends BlindataApi {
  getTemplatesProcessings(params) {
    return this.get(TEMPLATES_PROCESSINGS_URL, params);
  }

  getTemplateProcessing(sequenceId) {
    return this.get(`${TEMPLATES_PROCESSINGS_URL}/${sequenceId}`);
  }

  postTemplateProcessing(templateProcessing) {
    return this.post(TEMPLATES_PROCESSINGS_URL, templateProcessing);
  }

  putTemplateProcessing(templateProcessing) {
    return this.put(
      `${TEMPLATES_PROCESSINGS_URL}/${templateProcessing.sequenceId}`,
      templateProcessing
    );
  }

  deleteTemplateProcessing(sequenceId) {
    return this.delete(`${TEMPLATES_PROCESSINGS_URL}/${sequenceId}`);
  }

  getTemplatesTasks(params) {
    return this.get(TEMPLATES_TASKS_URL, params);
  }

  getTemplateTask(sequenceId) {
    return this.get(`${TEMPLATES_TASKS_URL}/${sequenceId}`);
  }

  postTemplateTask(templateTask) {
    return this.post(TEMPLATES_TASKS_URL, templateTask);
  }

  putTemplateTask(templateTask) {
    return this.put(`${TEMPLATES_TASKS_URL}/${templateTask.sequenceId}`, templateTask);
  }

  deleteTemplateTask(sequenceId) {
    return this.delete(`${TEMPLATES_TASKS_URL}/${sequenceId}`);
  }

  getTemplatesMappings(params) {
    return this.get(TEMPLATES_MAPPINGS_URL, params);
  }

  getTemplateMapping(sequenceId) {
    return this.get(`${TEMPLATES_MAPPINGS_URL}/${sequenceId}`);
  }

  postTemplateMapping(templateTask) {
    return this.post(TEMPLATES_MAPPINGS_URL, templateTask);
  }

  putTemplateMapping(templateTask) {
    return this.put(`${TEMPLATES_MAPPINGS_URL}/${templateTask.sequenceId}`, templateTask);
  }

  deleteTemplateMapping(sequenceId) {
    return this.delete(`${TEMPLATES_MAPPINGS_URL}/${sequenceId}`);
  }

  getTemplatesEvents(params) {
    return this.get(TEMPLATES_EVENTS_URL, params);
  }

  getTemplateEvent(sequenceId) {
    return this.get(`${TEMPLATES_EVENTS_URL}/${sequenceId}`);
  }

  postTemplateEvent(templateTask) {
    return this.post(TEMPLATES_EVENTS_URL, templateTask);
  }

  putTemplateEvent(templateTask) {
    return this.put(`${TEMPLATES_EVENTS_URL}/${templateTask.sequenceId}`, templateTask);
  }

  deleteTemplateEvent(sequenceId) {
    return this.delete(`${TEMPLATES_EVENTS_URL}/${sequenceId}`);
  }
}
