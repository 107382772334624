import * as React from "react";
const SvgApacheKyuubiLong = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1890.599 571.656"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      style={{
        fill: "#999",
      }}
      transform="translate(-46.901 -557.516)"
      d="M320.183 1034.416H219.362l-23.456-93.605-42.088 32.919-15.452 60.686H46.901l82.069-322.318h91.459l-42.356 166.377 86.585-77.549H374.8l-104.588 81.791z"
    />
    <path
      style={{
        fill: "#999",
      }}
      d="M332.746 243.407h94.416l8.58 155.433 84.312-155.433h88.171L452.068 492.886c-17.794 28.452-33.457 47.671-46.98 57.65-19.117 14.081-44.577 21.12-76.366 21.12-12.896 0-32.366-1.764-58.431-5.28l8.865-62.432c11.851 4.093 25.67 6.154 41.494 6.154 10.543 0 19.688-2.427 27.44-7.268 7.746-4.84 15.475-13.506 23.193-25.972zM811.078 476.899h-83.549l9.631-37.818c-16.412 15.539-31.801 26.602-46.158 33.195-14.362 6.599-30.774 9.897-49.24 9.897-24.626 0-42.059-7.364-52.306-22.09-10.246-14.734-11.474-37.414-3.67-68.05l37.841-148.622h89.924l-32.692 128.391c-3.733 14.66-3.676 25.066.183 31.225 3.859 6.154 10.692 9.236 20.516 9.236 10.697 0 20.499-4.104 29.409-12.313 8.904-8.209 16.063-22.941 21.475-44.199l28.604-112.341h89.485zM1102.399 476.899h-83.548l9.63-37.818c-16.412 15.539-31.801 26.602-46.157 33.195-14.362 6.599-30.774 9.897-49.24 9.897-24.626 0-42.059-7.364-52.306-22.09-10.246-14.734-11.474-37.414-3.67-68.05l37.841-148.622h89.924l-32.692 128.392c-3.733 14.66-3.676 25.066.183 31.225 3.859 6.154 10.691 9.236 20.516 9.236 10.698 0 20.498-4.104 29.41-12.313 8.904-8.209 16.063-22.941 21.474-44.199l28.605-112.341h89.485zM1230.208 154.585h90.364l-28.44 111.685c11.325-9.379 23.268-16.412 35.82-21.104 12.552-4.693 25.721-7.04 39.502-7.04 28.433 0 49.354 10.229 62.763 30.671 13.403 20.448 15.241 49.806 5.503 88.058-6.497 25.505-16.469 47.962-29.918 67.387-13.443 19.42-28.895 33.931-46.368 43.533-17.463 9.595-35.364 14.396-53.682 14.396-15.686 0-29.193-3.374-40.512-10.109-8.483-5.28-16.663-15.174-24.547-29.685l-8.791 34.519h-83.764zm37.527 204.907c-5.115 20.088-5.046 34.622.205 43.647 5.257 9.007 13.677 13.517 25.254 13.517 10.698 0 20.801-4.436 30.317-13.3 9.516-8.871 16.942-23.776 22.28-44.736 4.698-18.479 4.595-32.03-.308-40.683-4.915-8.644-12.421-12.969-22.531-12.969-12.171 0-23.244 4.508-33.234 13.517-9.989 9.019-17.319 22.684-21.983 41.007"
    />
    <path
      style={{
        fill: "#999",
      }}
      transform="translate(-46.901 -557.516)"
      d="M1578.705 1034.413h-89.484l59.452-233.49h89.484z"
    />
    <path
      style={{
        fill: "#e7211a",
      }}
      d="M1890.599 170.392a109.039 109.039 0 0 1-27.001 3.38c-46.443 0-86.105-29.021-101.849-69.915l-.109-.212c-.514-1.222-.667-2.58-.799-3.984-.542-5.578 2.146-9.658 8.015-10.259a42.18 42.18 0 0 1 3.779-.169c14.585 0 27.44 7.392 35.032 18.631v-.537c-27.977-47.78-79.854-79.871-139.222-79.871-5.245 0-10.435.251-15.555.742l.491-.514a42.77 42.77 0 0 1 28.565-10.885 42.75 42.75 0 0 1 18.318 4.099l-5.343-2.888C1671.99 6.485 1646.092 0 1618.681 0c-90.815 0-164.995 71.2-169.773 160.822l.017-.013c0 14.636 11.811 26.51 26.418 26.624 29.701-4.441 53.442-28.702 61.771-37.052 12.193 9.864 27.719 15.761 44.622 15.761 1.747 0 3.476-.063 5.188-.183-23.129-6.496-41.089-28.074-41.089-53.271 0-21.777 12.662-40.598 31.02-49.491 16.092 31.522 44.405 57.152 78.907 67.381 17.839 5.2 34.747 12.078 40.889 28.455 11.428 29.639 40.187 50.674 73.86 50.674 4.989 0 9.876-.462 14.613-1.353l.462-.087c-16.354 20.369-41.465 33.406-69.619 33.406l-39.685 2.198c-31.567 0-57.163 25.592-57.163 57.165 0 21.526 11.902 40.26 29.478 50.01 16.423 8.191 33.908 8.191 52.077 8.191 64.145 0 119.939-35.751 148.549-88.41-22.799 0-42.528-12.971-51.644-30.815v-.142c43.088-2.204 79.274-30.547 93.02-69.478"
    />
    <path
      style={{
        fill: "#e7211a",
      }}
      d="M1682.823 201.459c-30.928-14.242-53.151-6.839-53.151-6.839 12.73-42.206 53.151 6.839 53.151 6.839"
    />
    <path
      style={{
        fill: "#999",
      }}
      d="m321.269 711.254-49.792 70.424h19.171l9.572-14.271h23.731l.312 14.271h17.906l-3.289-70.424zm2.007 39.909h-11.79l11.322-16.451zM432.454 711.254h-41.118l-22.672 70.513h17.415l7.336-22.822h25.08c2.921 0 5.814-1.104 8.596-3.272 2.654-2.074 4.504-4.688 5.508-7.776l6.478-20.169c1.115-3.445 1.7-5.959 1.784-7.682.106-1.968-.279-3.646-1.137-4.973-1.578-2.497-4.093-3.819-7.27-3.819zm-10.057 16.245-4.889 15.202h-18.87l4.889-15.202zM494.51 711.254l-49.792 70.424h19.171l9.572-14.271h23.731l.312 14.271h17.906l-3.289-70.424zm2.007 39.909h-11.79l11.322-16.451zM561.923 714.616c-2.654 2.074-4.504 4.688-5.502 7.776l-15.135 47.083c-1.065 3.3-.842 6.177.669 8.557v.006c1.583 2.492 4.097 3.813 7.275 3.813h44.139l5.084-15.821h-38.487l12.498-38.872h38.492l5.084-15.821h-45.516c-2.931.001-5.83 1.105-8.601 3.279zM643.212 754.162h23.53l-8.875 27.605h17.493l22.672-70.513h-17.493l-8.574 26.658h-23.53l8.574-26.658h-17.41l-22.671 70.513h17.409zM725.091 765.606l3.679-11.444h32.767l5.224-16.25h-32.767l3.35-10.413h36.53l5.223-16.245h-53.939l-22.672 70.513h54.101l5.196-16.161z"
      transform="translate(-46.901 -557.516)"
    />
  </svg>
);
export default SvgApacheKyuubiLong;
