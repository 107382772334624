import { Typography, Link as MaterialLink } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataProductByPortIdentifier } from 'actions/DataProductsActions';
import _ from 'lodash';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';

export const DataProductPortDependency = ({ portIdentifier, onClick }) => {
  const dataProduct = useSelector(
    (state) => state.dataproducts.dataProductByPortIdentifier[portIdentifier]
  );
  const dataProductPort = _.find(dataProduct?.ports, (port) => port.identifier === portIdentifier);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataProductByPortIdentifier({ portIdentifier: portIdentifier }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (portIdentifier && (!dataProduct || !dataProductPort)) {
    return (
      <>
        <h4>Dependency</h4>
        <Box
          sx={{
            p: 2,
            border: '1px solid lightGrey',
            borderRadius: 2,
            textAlign: 'center',
            overflowX: 'auto'
          }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <WarningIcon style={{ color: '#e9a227' }} />
            <Typography variant='h5'>Invalid identifier: {portIdentifier}</Typography>
          </div>
        </Box>
      </>
    );
  }

  return (
    <>
      <h4>Dependency</h4>
      <Box
        sx={{
          p: 2,
          border: '1px solid lightGrey',
          borderRadius: 2,
          textAlign: 'center',
          overflowX: 'auto'
        }}>
        <Typography variant='h5'>
          <Link
            to={`/dataproducts/detail/${dataProduct?.uuid}`}
            style={{ textDecoration: 'none' }}
            onClick={(event) => {
              onClick();
              event.stopPropagation();
            }}>
            <MaterialLink underline='hover' style={{ color: 'black' }}>
              {dataProduct?.displayName || dataProduct?.name || dataProduct?.identifier}
            </MaterialLink>
          </Link>
          {` : `}
          <Link
            to={`/dataproducts/detail/${dataProduct?.uuid}?port=${dataProductPort?.uuid}`}
            style={{ textDecoration: 'none' }}
            onClick={(event) => {
              onClick();
              event.stopPropagation();
            }}>
            <MaterialLink underline='hover' style={{ color: 'black' }}>
              {dataProductPort?.displayName || dataProductPort?.name || dataProductPort?.identifier}
            </MaterialLink>
          </Link>
        </Typography>
      </Box>
    </>
  );
};
