import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AdditionalPropertiesEditor from '../../../../components/additional_properties/AdditionalPropertiesEditor';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
import TemplateVariablesEditor from '../../commons/TemplateVariablesEditor';
import DatePicker from '../../../../components/pickers/DatePicker';
import TemplatesProcessingsSearchModal from '../../processings/commons/TemplatesProcessingsSearchModal';
import ProcessingSearchModal from '../../../processings/utils/ProcessingSearchModal';
import TemplateTaskAssignmentEditor from './TemplateTaskAssignmentEditor';
import AccountTeamSelector, {
  ACCESS_LEVELS
} from '../../../settings/profile/utils/AccountTeamSelector';
import ResourceTypes from '../../../../constants/ResourceTypes';

function TemplateTaskModal({ open, templateTask = {}, onClose, onSubmit, fullScreen = false }) {
  const { t } = useTranslation();
  const [contentPatch, setContentPatch] = React.useState({});
  const [templateDataPatch, setTemplateDataPatch] = React.useState({});
  const [addProcessingOpen, setAddProcessingOpen] = React.useState(false);
  const [addTemplateProcessingOpen, setAddTemplateProcessingOpen] = React.useState(false);

  const mergedContent = { ...(templateTask && templateTask.content), ...contentPatch };
  const mergedTemplateData = { ...templateTask, ...templateDataPatch };
  return (
    <Dialog fullWidth fullScreen={fullScreen} open={open}>
      <DialogTitle>Task Template</DialogTitle>
      <DialogContent>
        <h4>Template data</h4>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter the code'}
          label={'Code'}
          value={mergedTemplateData.code || ''}
          onChange={(event) =>
            setTemplateDataPatch({ ...templateDataPatch, code: event.target.value })
          }
        />
        <br></br>
        <TextField
          variant='standard'
          required
          fullWidth
          placeholder={'Enter the name'}
          label={'Name'}
          value={mergedTemplateData.name || ''}
          onChange={(event) =>
            setTemplateDataPatch({ ...templateDataPatch, name: event.target.value })
          }
        />
        <br></br>
        <TemplateVariablesEditor
          variables={mergedTemplateData.variables}
          type='TASK'
          onChange={(variables) => setTemplateDataPatch({ ...templateDataPatch, variables })}
        />
        <h4>Associated processings templates</h4>
        <ChipWrapper onRequestAdd={() => setAddTemplateProcessingOpen(true)}>
          {(mergedTemplateData.templatesProcessings || []).map((p, index) => (
            <ChipItem
              key={index}
              id={index}
              label={p.name}
              onRequestDelete={() => {
                setTemplateDataPatch({
                  ...templateDataPatch,
                  templatesProcessings: mergedTemplateData.templatesProcessings.filter(
                    (p, idx) => idx !== index
                  )
                });
              }}
            />
          ))}
        </ChipWrapper>

        <TemplatesProcessingsSearchModal
          title={'Add Processing Template'}
          open={addTemplateProcessingOpen}
          onCancel={() => setAddTemplateProcessingOpen(false)}
          onSubmit={(templateProcessing) => {
            setTemplateDataPatch({
              ...templateDataPatch,
              templatesProcessings: (mergedTemplateData.templatesProcessings || []).concat([
                templateProcessing
              ])
            });
            setAddTemplateProcessingOpen(false);
          }}
        />

        <TemplateTaskAssignmentEditor
          templateTask={{ ...mergedTemplateData, content: mergedContent }}
          taskAssignments={mergedTemplateData.taskAssignments || []}
          onChange={(taskAssignments) =>
            setTemplateDataPatch({
              ...templateDataPatch,
              taskAssignments
            })
          }
        />
        <h4>General:</h4>
        <h4>{t('tasks.sections.commonMetadata')}</h4>
        <TextField
          variant='standard'
          margin='normal'
          required
          placeholder={t('tasks.fields.nameHint')}
          label={t('tasks.fields.name')}
          fullWidth
          value={mergedContent.name || ''}
          onChange={(event) => setContentPatch({ ...contentPatch, name: event.target.value })}
        />

        <TextField
          variant='standard'
          margin='normal'
          placeholder={t('tasks.fields.descriptionHint')}
          label={t('tasks.fields.description')}
          multiLine={true}
          fullWidth
          value={mergedContent.description || ''}
          onChange={(event) =>
            setContentPatch({ ...contentPatch, description: event.target.value })
          }
        />
        <h4>{t('tasks.sections.associatedProcessings')}</h4>
        <ChipWrapper onRequestAdd={() => setAddProcessingOpen(true)}>
          {(mergedContent.processings || []).map((p, index) => (
            <ChipItem
              key={index}
              id={index}
              label={p.name}
              onRequestDelete={() =>
                setContentPatch({
                  ...contentPatch,
                  processings: this.state.processings.filter((p, idx) => idx !== index)
                })
              }
            />
          ))}
        </ChipWrapper>

        <ProcessingSearchModal
          title={t('tasks.addProcessingTitle')}
          open={addProcessingOpen}
          onCancel={() => setAddProcessingOpen(false)}
          onSubmit={(processing) => {
            setContentPatch({
              ...contentPatch,
              processings: (mergedContent.processings || []).concat([processing])
            });
            setAddProcessingOpen(false);
          }}
        />

        <h4>{t('tasks.sections.businessMetadata')}</h4>
        <TextField
          variant='standard'
          margin='normal'
          placeholder={t('tasks.fields.categoryHint')}
          label={t('tasks.fields.category')}
          fullWidth
          value={mergedContent.category || ''}
          onChange={(event) => setContentPatch({ ...contentPatch, category: event.target.value })}
        />

        <h4>{t('tasks.sections.lifecycleDefinition')}</h4>

        <DatePicker
          label={t('tasks.fields.startDate')}
          hintText={t('tasks.fields.startDate')}
          value={mergedContent.startDate}
          onChange={(date) => setContentPatch({ ...contentPatch, startDate: date })}
        />

        <DatePicker
          label={t('tasks.fields.endDate')}
          hintText={t('tasks.fields.endDateHint')}
          value={mergedContent.endDate}
          onChange={(date) => setContentPatch({ ...contentPatch, endDate: date })}
        />

        <AdditionalPropertiesEditor
          resourceType={ResourceTypes.TASK}
          additionalProperties={contentPatch.additionalProperties || []}
          onChange={(newList) =>
            setContentPatch({ ...contentPatch, additionalProperties: newList })
          }
        />
      </DialogContent>
      <DialogActions>
        <AccountTeamSelector
          style={{ float: 'left' }}
          accessLevel={ACCESS_LEVELS.WRITE}
          accessLevel2={ACCESS_LEVELS.PROPOSE}
          selectedTeamCode={mergedContent.teamCode || ''}
          onChange={(code) => setContentPatch({ ...contentPatch, teamCode: code })}
        />
        <div style={{ flexGrow: 1 }} />
        <Button onClick={onClose}>{t('commons.actions.cancel')}</Button>
        <Button
          disabled={!mergedTemplateData.name || !mergedTemplateData.code || !mergedContent.name}
          onClick={() => onSubmit({ ...mergedTemplateData, content: mergedContent }, contentPatch)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TemplateTaskModal;
