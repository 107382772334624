import _ from 'lodash';
import {
  ProposalResourceTypes,
  ProposalVariationTypes
} from '../../../workflows/proposals/commons/ProposalConstants';
import Mustache from 'mustache';

export default class WorkflowDataLoader {
  constructor({ fetchMappings, searchProcessings, searchTasks, fetchTaskAssignment }) {
    this.fetchMappings = fetchMappings;
    this.searchProcessings = searchProcessings;
    this.searchTasks = searchTasks;
    this.fetchTaskAssignment = fetchTaskAssignment;
  }

  async loadData({ templateEvent }) {
    const { selectedMappings } = await this.loadMappings({ templateEvent });
    const dataActor = templateEvent.dataActor;
    const newVariationItems = await this.evaluate({
      templateEvent,
      mappings: selectedMappings,
      dataActor
    });
    const reconciledVariationItems = await Promise.all(
      newVariationItems.map(this.reconcileItem.bind(this))
    );
    return reconciledVariationItems;
  }

  async loadMappings({ templateEvent }) {
    const mappingsResponse = await this.fetchMappings({ page: 0, size: 500 });
    const allMappings = mappingsResponse.content;
    const selectedMappings = templateEvent.mappingsCodes
      .map((mc) => _.find(allMappings, ['code', mc]))
      .filter((m) => m != null);
    const relatedTeamCodes = [templateEvent.teamCode]
      .concat(selectedMappings.map((sm) => sm.teamCode))
      .filter((tc) => tc);
    return {
      selectedMappings,
      mappings:
        relatedTeamCodes.length > 0
          ? allMappings.filter((m) => relatedTeamCodes.includes(m.teamCode))
          : allMappings
    };
  }

  async evaluate({ templateEvent, mappings, dataActor }) {
    let templateTasks = _(mappings)
      .flatMap((mapping) => mapping.templatesTasks)
      .uniqBy('code')
      .value();
    let templateProcessings = _(templateTasks)
      .flatMap((tt) => tt.templatesProcessings)
      .uniqBy('code')
      .value();

    let processingsItems = await Promise.all(
      templateProcessings
        .map(this.constructProcessingInstance({ dataActor, templateEvent }))
        .map((p) => ({
          resourceName: p.name,
          resourceType: ProposalResourceTypes.PROCESSING,
          resourcePayload: p,
          resourceIdentifier: p.uuid,
          variationType: ProposalVariationTypes.POST
        }))
        .map(this.reconcileItem.bind(this))
    );

    let tasksItems = await Promise.all(
      templateTasks
        .map(this.constructTaskInstance({ templateEvent, dataActor, processingsItems }))
        .map((t) => ({
          resourceName: t.name,
          resourceType: ProposalResourceTypes.TASK,
          resourcePayload: t,
          resourceIdentifier: t.uuid,
          variationType: ProposalVariationTypes.POST
        }))
        .map(this.reconcileItem.bind(this))
    );
    let tasksAssignmentsItems = await Promise.all(
      _(templateTasks)
        .flatMap(this.constructTaskAssignments({ dataActor, templateEvent, tasksItems }))
        .map((ta) => ({
          resourceName: `${ta.dataActorName} -> ${ta.taskName}`,
          resourceType: ProposalResourceTypes.TASK_ASSIGNMENT,
          resourcePayload: ta,
          resourceIdentifier: ta.uuid,
          variationType: ProposalVariationTypes.POST
        }))
        .map(this.reconcileItem.bind(this))
        .value()
    );

    let dataActorItem = {
      resourceName: dataActor.name,
      resourceType: ProposalResourceTypes.DATA_ACTOR,
      resourcePayload: dataActor,
      resourceIdentifier: dataActor.uuid,
      variationType: ProposalVariationTypes.MERGE
    };

    let transferExtraEuDataActors = _(processingsItems)
      .flatMap((p) => p.resourcePayload.transferExtraEuOrganizations)
      .map((teeu) => teeu?.organization)
      .compact()
      .map((org) => ({
        resourceName: org.name,
        resourceType: ProposalResourceTypes.DATA_ACTOR,
        resourcePayload: org,
        resourceIdentifier: org.uuid,
        variationType: ProposalVariationTypes.MERGE
      }))
      .value();

    let dataCategories = _(processingsItems)
      .flatMap((p) => p.resourcePayload.associatedDataCategories)
      .map((adc) => adc?.dataCategory)
      .compact()
      .map((dc) => ({
        resourceName: dc.name,
        resourceType: ProposalResourceTypes.DATA_CATEGORY,
        resourcePayload: dc,
        resourceIdentifier: dc.uuid,
        variationType: ProposalVariationTypes.MERGE
      }))
      .value();

    return [dataActorItem]
      .concat(dataCategories)
      .concat(transferExtraEuDataActors)
      .concat(processingsItems)
      .concat(tasksItems)
      .concat(tasksAssignmentsItems);
  }

  constructProcessingInstance = (context) => (templateProcessing) => {
    let processingInstance = templateProcessing.content;
    processingInstance.templateCode = templateProcessing.code;
    processingInstance.templateEventSequenceId = _.get(context, 'templateEvent.sequenceId');
    processingInstance = this.injectVariables(
      templateProcessing.variables,
      processingInstance,
      context
    );
    return processingInstance;
  };

  constructTaskInstance = (context) => (templateTask) => {
    let { processingsItems } = context;
    let taskInstance = templateTask.content;
    taskInstance.templateCode = templateTask.code;
    taskInstance.templateEventSequenceId = _.get(context, 'templateEvent.sequenceId');
    let templatesProcessings = templateTask.templatesProcessings || [];
    taskInstance.processings = templatesProcessings
      .map(({ code }) => _.find(processingsItems, ['resourcePayload.templateCode', code]))
      .map((pi) => pi.resourcePayload);
    taskInstance = this.injectVariables(templateTask.variables, taskInstance, context);
    return taskInstance;
  };

  constructTaskAssignments = (context) => (templateTask) => {
    let taskInstance = _(context.tasksItems).find([
      'resourcePayload.templateCode',
      templateTask.code
    ]);
    return templateTask.taskAssignments.map((templateTaskAssignment) => {
      let taskAssignmentInstance = templateTaskAssignment.content;
      taskAssignmentInstance.taskUuid = taskInstance.resourceIdentifier;
      taskAssignmentInstance.taskName = taskInstance.resourceName;
      taskAssignmentInstance = this.injectVariables(
        templateTaskAssignment.variables,
        taskAssignmentInstance,
        context
      );
      return taskAssignmentInstance;
    });
  };

  async reconcileItem(item) {
    let reconciledItem = item;
    switch (item.resourceType) {
      case ProposalResourceTypes.PROCESSING:
        const searchProcessingResponse = await this.searchProcessings(item.resourceName);
        if (
          searchProcessingResponse.content.length > 0 &&
          searchProcessingResponse.content[0].name === item.resourceName
        ) {
          reconciledItem = this.mergeItemForUpdate(item, searchProcessingResponse.content[0]);
        }
        break;
      case ProposalResourceTypes.TASK:
        const searchTaskResponse = await this.searchTasks(item.resourceName);
        if (
          searchTaskResponse.content.length > 0 &&
          searchTaskResponse.content[0].name === item.resourceName
        ) {
          reconciledItem = this.mergeItemForUpdate(item, searchTaskResponse.content[0]);
        }
        break;
      case ProposalResourceTypes.TASK_ASSIGNMENT:
        if (item.resourcePayload.taskUuid) {
          const taskAssignmentResponse = await this.fetchTaskAssignment({
            dataActor: { uuid: item.resourcePayload.dataActorUuid },
            task: { uuid: item.resourcePayload.taskUuid }
          });
          if (taskAssignmentResponse.content.length === 1) {
            reconciledItem = this.mergeItemForUpdate(item, taskAssignmentResponse.content[0]);
          }
        }
        break;
      case ProposalResourceTypes.DATA_ACTOR:
        break;
      case ProposalResourceTypes.DATA_CATEGORY:
        break;
      default:
        throw new Error(`unrecognized resource type ${item.resourceType}`);
    }
    return reconciledItem;
  }

  mergeItemForUpdate(newItem, existingItem) {
    return {
      ...newItem,
      resourceIdentifier: existingItem.uuid,
      variationType: ProposalVariationTypes.PUT,
      resourcePayload: {
        ...existingItem,
        ...newItem.resourcePayload
      }
    };
  }

  injectVariables(templateVariables, resourcePayload, context) {
    const data = { ...context.templateEvent.data, dataActor: context.dataActor };
    const escapedData = _.mapValues(data, (value) =>
      _.isString(value) ? JSON.stringify(value).slice(1, -1) : value
    );
    let jsonResourcePayload = JSON.stringify(resourcePayload);
    if (!jsonResourcePayload.includes('{{{')) {
      jsonResourcePayload = jsonResourcePayload.replace(/{{([^"]*?)}}/g, '{{{$1}}}');
    }
    const resourcePayloadRendered = Mustache.render(jsonResourcePayload, escapedData);
    let injectedResourcePayload = JSON.parse(resourcePayloadRendered);
    _(templateVariables || []).forEach((variable) => {
      const valueToAssign = _.get(data, variable.contextKey);
      const assignmentPath = variable.destination;
      if (valueToAssign) {
        _.set(injectedResourcePayload, assignmentPath, valueToAssign);
      }
    });
    return injectedResourcePayload;
  }
}
