import React from 'react';
import { Route, Switch } from 'react-router-dom';
import WebActionDetailPage from './detail/WebActionDetailPage';
import WebActionsListPage from './list/WebActionsListPage';

const WebActionsRoutes = (props) => (
  <React.Fragment>
    <Switch>
      <Route exact path={`${props.match.url}`} component={WebActionsListPage} />

      <Route exact path={`${props.match.url}/:webActionUuid`} component={WebActionDetailPage} />
    </Switch>
  </React.Fragment>
);

export default WebActionsRoutes;
