import React from 'react';
import { Switch, Route } from 'react-router';
import TemplateEventDetailPage from './detail/TemplateEventDetailPage';
import TemplatesEventsListPage from './list/TemplatesEventsListPage';
import TemplateEventWorkflowPage from './workflow/TemplateEventWorkflowPage';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  return (
    <Switch>
      <Route
        exact
        path={`${props.match.url}/:sequenceId/workflow`}
        component={TemplateEventWorkflowPage}></Route>
      <Route
        exact
        path={`${props.match.url}/:sequenceId`}
        component={TemplateEventDetailPage}></Route>
      <Route path={`${props.match.url}`} component={TemplatesEventsListPage}></Route>
    </Switch>
  );
}
