import React, { useState } from 'react';
import { useLocation } from 'react-router';

import Authorize from '../../components/permissions/Authorize';
import Permissions, { PermissionsRoles } from '../../constants/Permissions';
import { useTranslation } from 'react-i18next';

import ToolbarActions from '../sidebar/ToolbarActions';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { MoreVert } from '@mui/icons-material';

const SettingsNavbar = () => {
  const { pathname } = useLocation();
  return (
    <>
      <ToolbarActions>
        <Button
          color={pathname === '/settings/profile' ? 'primary' : 'grey'}
          children='Profile'
          component={Link}
          to={'/settings/profile'}
        />
        <Authorize hasPermissions={[Permissions.USERS_VIEWER]}>
          <Button
            color={pathname === '/settings/users' ? 'primary' : 'grey'}
            children='Users'
            component={Link}
            to={'/settings/users'}
          />
        </Authorize>
        <Authorize hasPermissions={[Permissions.USERS_VIEWER]}>
          <Button
            color={pathname === '/settings/teams' ? 'primary' : 'grey'}
            children='Teams'
            component={Link}
            to={'/settings/teams'}
          />
        </Authorize>
        <Authorize hasPermissions={[Permissions.AGENTS_VIEWER]}>
          <Button
            color={pathname === '/settings/agents' ? 'primary' : 'grey'}
            children='Agents'
            component={Link}
            to={'/settings/agents'}
          />
        </Authorize>
        <Authorize hasAnyPermissions={importPermissions}>
          <Button
            color={pathname === '/settings/import' ? 'primary' : 'grey'}
            children='Import'
            component={Link}
            to={'/settings/import'}
          />
        </Authorize>
        <MoreLinksButton />
      </ToolbarActions>
    </>
  );
};

const MoreLinksButton = () => {
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  return (
    <>
      <IconButton
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size='large'>
        <MoreVert />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        <Authorize hasPermissions={[Permissions.AGENTS_VIEWER]}>
          <MenuItem
            selected={pathname === '/settings/agents'}
            children='Agents Settings'
            component={Link}
            to={'/settings/agents'}
            onClick={() => setAnchorEl(null)}
          />
        </Authorize>
        <Authorize hasAnyPermissions={importPermissions}>
          <MenuItem
            selected={pathname === '/settings/import'}
            children='Import Csv'
            component={Link}
            to={'/settings/import'}
            onClick={() => setAnchorEl(null)}
          />
        </Authorize>
        <MenuItem
          selected={pathname === '/settings/custom_properties_definitions'}
          children='Custom Properties'
          component={Link}
          to={'/settings/custom_properties_definitions'}
          onClick={() => setAnchorEl(null)}
        />
        <Authorize hasPermissions={[Permissions.AGENTS_VIEWER]}>
          <MenuItem
            selected={pathname === '/web-actions'}
            children={t('webActions.navBar.name')}
            component={Link}
            to={'/settings/web-actions'}
            onClick={() => setAnchorEl(null)}
          />
        </Authorize>
        <Authorize hasPermissions={[Permissions.AGENTS_VIEWER]}>
          <MenuItem
            selected={pathname === '/web-hooks'}
            children={t('navbar.webHooks')}
            component={Link}
            to={'/settings/web-hooks'}
            onClick={() => setAnchorEl(null)}
          />
        </Authorize>
      </Menu>
    </>
  );
};

const importPermissions = Object.values(Permissions).filter(
  (p) => p.includes(PermissionsRoles.ADMIN) || p.includes(PermissionsRoles.EDITOR)
);

export default SettingsNavbar;
