import React from 'react';
import { Table, TableRow, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';
function HelpIconTable() {
  const { t } = useTranslation();
  return (
    <>
      <Table>
        <TableRow>
          <TableCell>{t('quality.probes.definitions.probeType.singleMetric')}</TableCell>
          <TableCell>{t('quality.probes.definitions.helpText.singleMetricHelpText')}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('quality.probes.definitions.probeType.doubleMetric')}</TableCell>
          <TableCell>{t('quality.probes.definitions.helpText.doubleMetricHelpText')}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('quality.probes.definitions.probeType.recordComparison')}</TableCell>
          <TableCell>{t('quality.probes.definitions.helpText.recordComparisonHelpText')}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('quality.probes.definitions.probeType.tabularMetrics')}</TableCell>
          <TableCell>{t('quality.probes.definitions.helpText.tabularMetricsHelpText')}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('quality.probes.definitions.probeType.copyRows')}</TableCell>
          <TableCell>{t('quality.probes.definitions.helpText.copyRowsHelpText')}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('quality.probes.definitions.probeType.exportRows')}</TableCell>
          <TableCell>{t('quality.probes.definitions.helpText.exportRowsHelpText')}</TableCell>
        </TableRow>
      </Table>
    </>
  );
}

export default HelpIconTable;
