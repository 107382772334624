import React from 'react';
import _ from 'lodash';
import { Typography } from '@mui/material';
import { selectCurrentTenant } from '../../../../reducers/settings/tenants';
import { connect } from 'react-redux';
import UserTeamSettingsCard from './UserTeamSettingsCard';
import TeamPoliciesCard from '../../teams/detail/TeamPoliciesCard';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
function UserTeamSettingsPage(props) {
  return (
    <>
      <Breadcrumbs
        elements={[
          { text: 'Settings', to: '/settings' },
          { text: 'Users', to: '/settings/users' },
          { text: props.displayName, to: `/settings/users/${props.match.params.userUuid}` },
          { text: 'Teams' }
        ]}
      />
      <Tracking
        info={{
          pageTitle: `${props?.tenantName}: ${props?.displayName} teams`,
          category: EngagementCategories.SETTINGS,
          action: EngagementActions.DETAIL
        }}
      />

      <Typography variant='h4' component='h1'>
        {props.tenantName}: {props.displayName} teams
      </Typography>
      <UserTeamSettingsCard userUuid={props.match.params.userUuid} editable />
      <TeamPoliciesCard byUserUuid={props.match.params.userUuid}></TeamPoliciesCard>
    </>
  );
}

function mapStateToProps(state, props) {
  return {
    tenantName: _.get(selectCurrentTenant(state), 'name'),
    displayName: _.get(state.settings.users.content[props.match.params.userUuid], 'displayName')
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserTeamSettingsPage);
