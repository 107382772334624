import React from 'react';
import { withTranslation } from 'react-i18next';

import { ChipWrapper, ChipItem } from '../../../../../components/chiplist/ChipList';

const LogicalFieldSection = ({ logicalFields, t }) => {
  return (
    <div>
      <h4>{t('physicalFields.detail.logicalFields.title')}</h4>
      <ChipWrapper>
        {(logicalFields || []).map((lf) => (
          <ChipItem
            key={lf.uuid}
            id={lf.uuid}
            label={lf.name}
            tooltip={lf.description}
            to={`/logicalfields/${lf.uuid}`}
          />
        ))}
      </ChipWrapper>
    </div>
  );
};

export default withTranslation()(LogicalFieldSection);
