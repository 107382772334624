import crudReducers from '../../utils';
import { combineReducers } from 'redux';
import {
  FETCH_RPA_ARCHIVE_RECORDS_PAGE,
  CREATE_RPA_ARCHIVE_RECORD,
  DELETE_RPA_ARCHIVE_RECORD
} from '../../../constants/ActionTypes';

export default combineReducers({
  ...crudReducers({ resource: 'RPA_ARCHIVE', keyProperty: 'uuid' }),
  records
});

function records(state = {}, action) {
  switch (action.type) {
    case FETCH_RPA_ARCHIVE_RECORDS_PAGE:
      return { ...state, [action.payload.rpaArchiveUuid]: action.payload };
    case CREATE_RPA_ARCHIVE_RECORD:
      let rpaArchiveUuid = action.payload.rpaArchiveUuid;
      let currentPage = state[action.payload.rpaArchiveUuid] || {};
      let previousContent = currentPage.content || [];
      return {
        ...state,
        [rpaArchiveUuid]: {
          ...(state[rpaArchiveUuid] || {}),
          content: [action.payload].concat(previousContent)
        }
      };
    case DELETE_RPA_ARCHIVE_RECORD:
      return {
        ...state,
        [action.payload.rpaArchiveUuid]: {
          ...(state[action.payload.rpaArchiveUuid] || {}),
          content: (state[action.payload.rpaArchiveUuid].content || []).filter(
            ({ uuid }) => uuid !== action.payload.uuid
          )
        }
      };
    default:
      return state;
  }
}
