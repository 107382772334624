import React from 'react';

import AlertDialog from 'components/alertdialog/AlertDialog';

const DeleteAgentModal = ({ selectedAgent, open, setOpen, deleteAgent }) => (
  <AlertDialog
    open={open}
    message={
      'Do you really want to delete ' +
      (selectedAgent ? `Agent ${selectedAgent.name}` : 'this agent') +
      '?'
    }
    onOk={() => {
      deleteAgent(selectedAgent);
      setOpen(false);
    }}
    onCancel={() => setOpen(false)}
  />
);

export default DeleteAgentModal;