import React from 'react';
import TeamItem from './TeamItem';
import PaginatedPanel from '../../../../components/pagination/PaginatedPanel';
import { fetchTeamListPage } from '../../../../actions/TeamsActions';
import { connect } from 'react-redux';
import { Typography, Grid } from '@mui/material';
import { selectCurrentTenant } from '../../../../reducers/settings/tenants';
import _ from 'lodash';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';

const TeamsList = ({ tenantName, page, fetchTeamListPage }) => (
  <>
    <Grid container>
      <Grid item>
        <Typography variant='h5'>Teams of {tenantName}</Typography>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}></Grid>
      <Grid item>
        <FavoritesWidget itemIdentifier={'page:teams-list'} pageTitle={`Teams of ${tenantName}`} />
      </Grid>
    </Grid>

    <PaginatedPanel
      currentPage={page}
      onPageSelection={(page) => fetchTeamListPage({ page })}
      autoload>
      {page.content.map((team) => (
        <TeamItem key={team.code} team={team} />
      ))}
    </PaginatedPanel>
  </>
);

function mapStateToProps(state, props) {
  return {
    page: state.settings.teams.currentPage,
    tenantName: _.get(selectCurrentTenant(state), 'name')
  };
}

const mapDispatchToProps = {
  fetchTeamListPage
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsList);
