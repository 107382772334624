import { IconButton, Menu, Tooltip } from '@mui/material';
import { Settings } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { hideSpinner, showSpinner } from '../../../../actions/BehaviourActions';
import { searchProcessings } from '../../../../actions/ProcessingsActions';
import { createImmediateProposal, createProposal } from '../../../../actions/ProposalsActions';
import { fetchDataActorTaskAssignments } from '../../../../actions/TaskAssignmentsActions';
import { searchTasks } from '../../../../actions/TasksActions';
import {
  fetchTemplatesMappingsPage,
  modifyTemplateEvent
} from '../../../../actions/TemplatesActions';
import ConfirmMenuItem from '../../../../components/confirmbutton/ConfirmMenuItem';
import { immediateAction } from '../workflow/WorkflowActions';

function TemplateEventsMassiveActions(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [running, setRunning] = useState(false);
  const { t } = useTranslation();
  function handleOpen(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  async function handleIgnoreAll() {
    setRunning(true);
    props.showSpinner();
    setAnchorEl(null);
    let templateEvents = props.currentPage.content || [];
    try {
      for (let event of templateEvents) {
        await props.modifyTemplateEvent({ ...event, eventStatus: 'DELETED' });
      }
    } finally {
      setRunning(false);
      props.hideSpinner();
      props.onCompleted();
    }
  }

  async function handleImmediateAll() {
    setRunning(true);
    props.showSpinner();
    setAnchorEl(null);
    let templateEvents = props.currentPage.content || [];
    try {
      for (let event of templateEvents) {
        await immediateAction({ ...props, templateEvent: event })();
      }
    } finally {
      setRunning(false);
      props.hideSpinner();
      props.onCompleted();
    }
  }

  return (
    <>
      <Tooltip title='Massive Actions' aria-label='massive-actions'>
        <IconButton
          size='small'
          aria-controls='massive-actions-menu'
          aria-haspopup='true'
          onClick={handleOpen}>
          <Settings></Settings>
        </IconButton>
      </Tooltip>
      <Menu
        id='massive-actions-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <ConfirmMenuItem
          disabled={running}
          message={t('templates.events.confirmImmediateAllMessage')}
          onClick={handleImmediateAll}>
          {t('templates.actions.immediateAll')}
        </ConfirmMenuItem>
        <ConfirmMenuItem
          disabled={running}
          message={t('templates.events.confirmIgnoreAllMessage')}
          onClick={handleIgnoreAll}>
          {t('templates.actions.ignoreAll')}
        </ConfirmMenuItem>
      </Menu>
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.templates.templatesEvents.currentPage
  };
}

export const mapDispatchToProps = {
  searchProcessings: searchProcessings,
  searchTasks: searchTasks,
  fetchTaskAssignment: fetchDataActorTaskAssignments,
  fetchMappings: fetchTemplatesMappingsPage,
  modifyTemplateEvent: modifyTemplateEvent,
  createProposal: createProposal,
  createImmediateProposal: createImmediateProposal,
  showSpinner: showSpinner,
  hideSpinner: hideSpinner
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateEventsMassiveActions);
