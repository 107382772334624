import PropTypes from 'prop-types';
import _ from 'lodash';
import ItemAutoComplete from '../../../components/itemselector/ItemAutoComplete';
import { connect } from 'react-redux';
import { withLoader } from '../../../components/loader/Loader';
import { searchTasks, fetchTask } from '../../../actions/TasksActions';

const mapStateToProps = (state, props) => ({
  values: Object.values(state.tasks.contentByUuid)
});

const mapDispatchToProps = {
  onSearch: searchTasks,
  fetchTask
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  itemName: 'Task',
  keyProperty: 'uuid',
  nameProperty: 'name',
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  value: extractOriginalValue(ownProps, stateProps)
});

const extractOriginalValue = (ownProps, stateProps) => {
  if (ownProps.value && !ownProps.value.name) {
    let originalValue = _(stateProps.values).find({ uuid: ownProps.value.uuid });
    return originalValue || ownProps.value;
  } else {
    return ownProps.value;
  }
};

const TaskItemAutoComplete = _.flow([
  withLoader({
    onLoad: ({ value, values, fetchTask }) => {
      if (value && value.uuid && !_(values).find({ uuid: value.uuid })) {
        fetchTask(value.uuid);
      }
    }
  }),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
])(ItemAutoComplete);

TaskItemAutoComplete.displayName = 'TaskItemAutoComplete';

TaskItemAutoComplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

export default TaskItemAutoComplete;
