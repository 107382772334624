import React, { useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';

import Permissions from 'constants/Permissions';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Button
} from '@mui/material';
import { Schedule } from '@mui/icons-material';

import Authorize from 'components/permissions/Authorize';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import CampaignModal from 'pages/issueMngt/campaigns/detail/CampaignModal';
import CompletionLineChart from 'pages/issueMngt/campaigns/detail/CompletionLineChart';
import CardDescription from 'components/carddescription/CardDescription';

const CampaignDetailsCard = ({ campaign, modifyCampaign, deleteCampaign }) => {
  return (
    <>
      <Card>
        <CardHeader
          title={campaign.name}
          subheader={
            <Grid container direction='column'>
              <Grid item>
                <Typography variant='overline'>
                  Owner: {campaign.owner?.displayName || 'Not defined'}
                </Typography>
              </Grid>
              {campaign.startDate && campaign.endDate && (
                <Grid item>
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                      <Schedule fontSize='small' />
                    </Grid>
                    <Grid item>
                      <Typography variant='overline'>
                        {moment(campaign.startDate).format('DD MMMM YYYY')} -{' '}
                        {moment(campaign.endDate).format('DD MMMM YYYY')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          }
          action={<VariationTypeDecorator>{campaign.status}</VariationTypeDecorator>}
        />
        <CardContent>
          <CardDescription>{campaign.description}</CardDescription>
          <div style={{ paddingTop: '32px' }}>
            <CompletionLineChart campaignUuid={campaign.uuid} />
          </div>
        </CardContent>
        <CardActions>
          <Authorize hasPermissions={[Permissions.CAMPAIGNS_ADMIN]}>
            <ModifyButton campaign={campaign} modifyCampaign={modifyCampaign} />
          </Authorize>
          <Authorize hasPermissions={[Permissions.CAMPAIGNS_ADMIN]}>
            <DeleteButton campaign={campaign} deleteCampaign={deleteCampaign} />
          </Authorize>
        </CardActions>
      </Card>
    </>
  );
};

const ModifyButton = ({ campaign, modifyCampaign }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Modify</Button>
      {modalOpen && (
        <CampaignModal
          title={'Edit:' + campaign.name}
          open={modalOpen}
          campaign={campaign}
          handleSubmit={modifyCampaign}
          handleCancel={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

const DeleteButton = ({ campaign, deleteCampaign }) => {
  const history = useHistory();

  return (
    <ConfirmButton
      message={`Do tou want to delete: ${campaign.name}?`}
      onClick={() => {
        deleteCampaign(campaign.uuid);
        history.push('/issue-management/campaigns');
      }}>
      Delete
    </ConfirmButton>
  );
};

export default CampaignDetailsCard;
