import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';

import { fetchSchedule } from 'actions/onagent/AgentScheduleActions';
import { compose } from 'redux';
import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';
import { connect } from 'react-redux';
import AgentItemAutocomplete from 'pages/settings/agents/commons/AgentItemAutocomplete';
import ProjectScheduleAddButton from './ProjectScheduleAddButton';
import ProjectScheduleDetailCard from './ProjectScheduleDetailCard';

function ProjectScheduleTab({ fetchSchedule, project, jobDefinition }) {
  const [selectedAgent, setSelectedAgent] = useState(null);

  return (
    <div>
      <Grid container alignItems='stretch' direction='row'>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item style={{ minWidth: '20%' }}>
          <AgentItemAutocomplete
            margin='dense'
            value={selectedAgent}
            onChange={(agent) => {
              setSelectedAgent(agent);
              agent && fetchSchedule(agent, { externalIdentifier: project.uuid });
            }}></AgentItemAutocomplete>
        </Grid>
      </Grid>
      {!selectedAgent && jobDefinition?.length < 1 && (
        <div style={{ textAlign: 'center', padding: '2%' }}>
          <Typography variant='subtitle2' align='center' style={{ marginTop: '20px' }}>
            Select an agent
          </Typography>
        </div>
      )}
      {jobDefinition?.length > 0 && selectedAgent
        ? jobDefinition.map((job) => (
            <ProjectScheduleDetailCard
              key={job.uuid}
              project={project}
              jobDefinition={job}
              agent={selectedAgent}
              setAgent={setSelectedAgent}></ProjectScheduleDetailCard>
          ))
        : selectedAgent && (
            <div style={{ textAlign: 'center', padding: '2%' }}>
              <Typography variant='body1'>No Job scheduled for this project</Typography>
              <ProjectScheduleAddButton
                project={project}
                agent={selectedAgent}></ProjectScheduleAddButton>
            </div>
          )}
    </div>
  );
}

function mapStateToProps(state, props) {
  return {
    jobDefinition: state.settings.agents.schedule.currentPage.content
  };
}
export default compose(
  withPermissions({ hasPermissions: [Permissions.AGENTS_VIEWER] }),
  connect(mapStateToProps, {
    fetchSchedule
  })
)(ProjectScheduleTab);
