import React, { useState } from 'react';
import { Button } from '@mui/material';
import QualitySuiteModal from './QualitySuiteModal';

export default function ModifyAction({ qualitySuite, modifyQualitySuite }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>Modify</Button>
      <QualitySuiteModal
        qualitySuite={qualitySuite}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(modifiedQualitySuite) => {
          modifyQualitySuite(modifiedQualitySuite);
          setOpen(false);
        }}
      />
    </>
  );
}
