import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { createProbeProject } from 'actions/quality/probes/ProbesProjectsActions';
import QualityProbesProjectModal from './QualityProbesProjectModal';
import { IconButton, Tooltip } from '@mui/material';

function QualityProbesProjectFAB({ createProbeProject }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title={'Create Project'}>
        <IconButton onClick={() => setOpen(true)} size='medium'>
          <AddBoxIcon></AddBoxIcon>
        </IconButton>
      </Tooltip>

      {open && (
        <QualityProbesProjectModal
          title='Create Project'
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={(project) => {
            createProbeProject(project);
            setOpen(false);
          }}
        />
      )}
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.PROBES_EDITOR] }),
  connect(null, { createProbeProject })
)(QualityProbesProjectFAB);
