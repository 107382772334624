import React from 'react';
import _ from 'lodash';

import { Table, TableHead, TableBody, TableRow } from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import ClassificationPanelActions from './ClassificationPanelActions';

function ClassificationRulesPanelTable({ rules, handleShowDetails, handleEdit, handleDelete }) {
  return (
    <Table size='small' style={{ overflowX: 'auto' }}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Data Category Name</TableCell>
          <TableCell>Logical Field Name</TableCell>
          <TableCell>Rule Content</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rules.map((rule) => (
          <TableRow
            hover={true}
            style={{ cursor: 'pointer' }}
            key={rule.uuid}
            onClick={(event) => handleShowDetails(rule.uuid)}>
            <TableCell>{rule.name}</TableCell>
            <TableCell>{rule.type}</TableCell>
            <TableCell>{rule.dataCategoryName || '-'}</TableCell>
            <TableCell>{rule.logicalFieldName || '-'}</TableCell>
            <TableCell>{_.truncate(rule.ruleContent, { length: 125 })}</TableCell>
            <TableCell>
              <ClassificationPanelActions
                onEdit={() => handleEdit(rule)}
                onDelete={() => handleDelete(rule)}
                onShowDetails={() => handleShowDetails(rule.uuid)}
                rule={rule}></ClassificationPanelActions>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default ClassificationRulesPanelTable;
