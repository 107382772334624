import React, { useState } from 'react';
import HistoryButtonLink from '../../../history/commons/HistoryButtonLink';
import ItemAutoComplete from '../../../../components/itemselector/ItemAutoComplete';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

function RpaDifferencesModalButton({ rpaArchiveRecords, rpaArchiveRecordUuid }) {
  const firstRopaUuid = rpaArchiveRecordUuid;
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <Button onClick={() => setOpen(true)}>Compare</Button>
      {open && (
        <RpaDifferencesModal
          open={open}
          setOpen={setOpen}
          rpaArchiveRecords={rpaArchiveRecords}
          firstRopaUuid={firstRopaUuid}></RpaDifferencesModal>
      )}
    </React.Fragment>
  );
}

function RpaDifferencesModal({ open, setOpen, rpaArchiveRecords, firstRopaUuid, value }) {
  const [searchRopas, setSearchRopas] = useState({
    firstRopaUuid: firstRopaUuid,
    secondRopaUuid: null
  });
  let { t } = useTranslation();
  return (
    <React.Fragment>
      <Dialog open={open} fullWidth={true}>
        <DialogTitle>{'Compare With'}</DialogTitle>
        <DialogContent>
          <ItemAutoComplete
            values={rpaArchiveRecords}
            value={value}
            keyProperty={'uuid'}
            nameProperty={'fileName'}
            itemName='Compare with another record'
            onChange={(value) => value && setSearchRopas({ secondRopaUuid: value.uuid })}
            onSearch={() => null}></ItemAutoComplete>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.cancel')}
            onClick={() => {
              setOpen(false);
            }}></Button>
          <HistoryButtonLink
            firstRopaUuid={firstRopaUuid}
            secondRopaUuid={searchRopas.secondRopaUuid}></HistoryButtonLink>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default RpaDifferencesModalButton;
