import _ from 'lodash';
import { combineReducers } from 'redux';
import crudReducers from 'reducers/utils';
import {
  FETCH_RISK_CATEGORIES,
  CREATE_RISK_DEFINITION,
  FETCH_PE_RISK_EVALUATION,
  FETCH_CHECK_EVALUATION,
  FETCH_QUALITY_ASSESSMENT_CONFIGURATION,
  MODIFY_RISK_DEFINITION,
  DELETE_RISK_DEFINITION,
  MODIFY_CHECK_EVALUATION,
  DELETE_CHECK_EVALUATION,
  CREATE_QUALITY_CHECK,
  MODIFY_QUALITY_CHECK,
  DELETE_QUALITY_CHECK
} from 'constants/ActionTypes';

function configuration(state = null, action) {
  switch (action.type) {
    case FETCH_QUALITY_ASSESSMENT_CONFIGURATION:
      return action.payload;
    default:
      return state;
  }
}

const riskDefinitions = combineReducers({
  ...crudReducers({ resource: 'RISK_DEFINITION', keyProperty: 'uuid' }),
  categories: (state = [], action) => {
    switch (action.type) {
      case FETCH_RISK_CATEGORIES:
        return action.payload.content;
      case CREATE_RISK_DEFINITION:
        return _.uniq([...state, action.payload.category]).sort();
      default:
        return state;
    }
  }
});

function riskEvaluations(state = {}, action) {
  switch (action.type) {
    case FETCH_PE_RISK_EVALUATION:
      return { [action.payload.physicalEntityUuid]: action.payload };
    case CREATE_RISK_DEFINITION:
    case MODIFY_RISK_DEFINITION:
    case DELETE_RISK_DEFINITION:
    case MODIFY_CHECK_EVALUATION:
    case DELETE_CHECK_EVALUATION:
    case CREATE_QUALITY_CHECK:
    case DELETE_QUALITY_CHECK:
    case MODIFY_QUALITY_CHECK:
      return {};
    default:
      return state;
  }
}

function checkEvaluations(state = {}, action) {
  switch (action.type) {
    case FETCH_CHECK_EVALUATION:
      return { [action.payload.qualityCheckUuid]: action.payload };
    case CREATE_RISK_DEFINITION:
    case MODIFY_RISK_DEFINITION:
    case DELETE_RISK_DEFINITION:
      return {};
    default:
      return state;
  }
}

export default combineReducers({ configuration, riskDefinitions, riskEvaluations, checkEvaluations });
