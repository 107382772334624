import React from 'react';
import { useSelector } from 'react-redux';

const withApplicationScope =
  ({ requiredApplicationScope }) =>
  (WrappedComponent, FallBackComponent = null) => {
    function _WithApplicationScope(props) {
      let { applicationScope } = useSelector(mapStateToProps);
      if (applicationScope === requiredApplicationScope) {
        return <WrappedComponent {...props} />;
      } else {
        return (FallBackComponent && <FallBackComponent />) || null;
      }
    }

    _WithApplicationScope.displayName = `WithApplicationScope(${getDisplayName(WrappedComponent)})`;
    return _WithApplicationScope;
  };

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const mapStateToProps = (state) => ({
  applicationScope: state.settings.profile.applicationScope
});

export const IfApplicationScopeOwner = withApplicationScope({ requiredApplicationScope: 'OWNER' })(
  React.Fragment
);
export default withApplicationScope;
