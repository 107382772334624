import React from 'react';

import { Box, Tab, Tabs, Grid } from '@mui/material';
import { useHistory } from 'react-router';
import useSearchParams from 'components/useSearchParams';
import RoutineBodySection from './RoutineBodySection';
import RoutineExtractedStatementsSection from './RoutineExtractedStatementsSection';
import withAuthorize from 'components/permissions/withAuthorize';
import Permissions from 'constants/Permissions';

function RoutineTabs({ routine }) {
  const query = useSearchParams();
  const history = useHistory();
  const selectedTab = query.get('tab') || 'definition';
  const handleChange = (_event, newValue) =>
    history.push(history.location.pathname + '?tab=' + newValue);
  return (
    <>
      <Grid container alignItems='baseline' direction='row' justifyContent='center'>
        <Grid item>
          <Box display='flex' justifyContent='space-around' width='100%'>
            <Tabs value={selectedTab} onChange={handleChange}>
              <Tab value='definition' label='Definition' />
              <AuthorizedTab
                value='sqlLineage'
                label='SQL Lineage'
                hasPermissions={[Permissions.QUERY_PARSER_VIEWER]}
              />
            </Tabs>
          </Box>
        </Grid>
      </Grid>
      {selectedTab === 'definition' && (
        <RoutineBodySection
          systemRoutineAnalysisStatus={routine?.analysisStatus}
          systemRoutineName={routine?.name}
          systemRoutineSchema={routine?.schema}
          systemRoutineUuid={routine?.uuid}
          body={routine?.routineDefinition}
        />
      )}
      {selectedTab === 'sqlLineage' && (
        <RoutineExtractedStatementsSection systemRoutine={routine} />
      )}
    </>
  );
}

const AuthorizedTab = withAuthorize(Tab, 'span');

export default RoutineTabs;
