import React from 'react';
import { List, ListItem } from '@mui/material';
import ActionDelete from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import { removeGraphUuidFilter, removeGraphPropertyFilter } from '../../../../actions/GraphActions';
import { Typography, Paper } from '@mui/material';
import GraphUuidFilter from './GraphUuidFilter';

const GraphFiltersList = ({
  uuidFilters,
  propertyFilters,
  removeGraphUuidFilter,
  removeGraphPropertyFilter
}) =>
  uuidFilters.length || propertyFilters.length ? (
    <>
      <Paper style={{ margin: '8px 0 8px 0', padding: 16 }}>
        <Typography variant='h5'>Entities to analyze</Typography>
        <List>
          {uuidFilters.map((f) => (
            <GraphUuidFilter key={f.name} filter={f} onDelete={removeGraphUuidFilter} />
          ))}
          {propertyFilters.map((f) => createPropertyFilterItem(f, removeGraphPropertyFilter))}
        </List>
      </Paper>
    </>
  ) : (
    <p style={{ textAlign: 'center', minHeight: '200px', marginTop: '100px' }}>
      No Entities Selected
    </p>
  );

const createPropertyFilterItem = (filter, removeGraphPropertyFilter) => (
  <ListItem
    key={filter.name}
    primaryText={filter.name}
    secondaryText={filter.entityType}
    rightIcon={<ActionDelete onClick={() => removeGraphPropertyFilter(filter)} />}
  />
);

const mapStateToProps = (state) => ({
  uuidFilters: state.graph.filters.uuidFilters,
  propertyFilters: state.graph.filters.propertyFilters
});

export default connect(mapStateToProps, {
  removeGraphUuidFilter,
  removeGraphPropertyFilter
})(GraphFiltersList);
