import DataFlowsApi from '../api/DataFlowsApi';
import {
  FETCH_DATA_FLOW,
  CREATE_DATA_FLOW,
  DELETE_DATA_FLOW,
  FETCH_DATA_FLOWS_PAGE,
  EDIT_DATA_FLOW_EDITOR,
  MODIFY_DATA_FLOW,
  CLEAR_DATA_FLOW_EDITOR,
  EDIT_DATA_FLOW_FILTERS,
  CLEAR_DATA_FLOW_FILTERS,
  FETCH_DATA_FLOW_SCOPES,
  EXPORT_DATA_FLOW
} from '../constants/ActionTypes';
import { buildApiCallAction } from './ActionUtils';

let api = new DataFlowsApi();

export const fetchDataFlow = (uuid) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getDataFlow(uuid),
    actionType: FETCH_DATA_FLOW
  });

export const fetchScopes = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getScopes(params),
    actionType: FETCH_DATA_FLOW_SCOPES
  });

export const fetchDataFlowsPageWithFilters = (page, size) => (dispatch, getState) => {
  const filters = getState().dataflows.filters;
  return fetchDataFlowsPage(page, size, getQueryParams(filters))(dispatch, getState);
};

export const exportDataFlowsWithFilters = () => (dispatch, getState) => {
  const filters = getState().dataflows.filters;
  return exportDataFlow(filters)(dispatch, getState);
};

export const fetchDataFlowsPage = (page, size, filters) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getDataFlows(page, size, filters),
    actionType: FETCH_DATA_FLOWS_PAGE
  });

export const createDataFlow = (dataFlow) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postDataFlow(dataFlow),
    actionType: CREATE_DATA_FLOW
  });

export const deleteDataFlow = (dataFlow) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteDataFlow(dataFlow),
    actionType: DELETE_DATA_FLOW,
    payload: dataFlow
  });
export const exportDataFlow = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportDataFlow(getQueryParams(params)),
    actionType: EXPORT_DATA_FLOW
  });

export const modifyDataFlow = (dataFlow) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putDataFlow(dataFlow),
    actionType: MODIFY_DATA_FLOW
  });

export const editDataFlow = (editor) => (dispatch, getState) => {
  return dispatch({ type: EDIT_DATA_FLOW_EDITOR, payload: editor });
};

export const clearDataFlowEditor = (editor) => (dispatch, getState) => {
  return dispatch({
    type: CLEAR_DATA_FLOW_EDITOR,
    payload: editor
  });
};

export const editDataFlowFilters = (filters) => (dispatch, getState) =>
  dispatch({
    type: EDIT_DATA_FLOW_FILTERS,
    payload: filters
  });

export const clearDataFlowFilters = () => (dispatch) => dispatch({ type: CLEAR_DATA_FLOW_FILTERS });

function getQueryParams(filters) {
  if (!filters) {
    return null;
  }
  const extractUuid = (elem) => elem.uuid;
  return {
    fromSystems: filters.fromSystems.map(extractUuid),
    fromPhysicalEntities: filters.fromPhysicalEntities.map(extractUuid),
    nullFromPhysicalEntities: filters.nullFromPhysicalEntities,
    fromPhysicalEntitySchemas: filters.fromPhysicalEntitySchemas,
    toPhysicalEntitySchemas: filters.toPhysicalEntitySchemas,
    fromPhysicalFields: filters.fromPhysicalFields.map(extractUuid),
    toSystems: filters.toSystems.map(extractUuid),
    toPhysicalEntities: filters.toPhysicalEntities.map(extractUuid),
    nullToPhysicalEntities: filters.nullToPhysicalEntities,
    toPhysicalFields: filters.toPhysicalFields.map(extractUuid),
    scopes: filters.scopes,
    aboutLogicalFields: filters.aboutLogicalFields.map(extractUuid),
    aboutDataCategories: filters.aboutDataCategories.map(extractUuid)
  };
}
