import React from 'react';
import SettingsUsersPage from './list/SettingsUsersPage';
import UserDetailPage from './detail/UserDetailPage';
import { Route, Switch } from 'react-router-dom';
import UserTeamSettingsPage from './teamsettings/UserTeamSettingsPage';

const UsersRoutes = props => (
  <React.Fragment>
    <Switch>
      <Route exact path={`${props.match.url}`} component={SettingsUsersPage} />

      <Route exact path={`${props.match.url}/:userUuid`} component={UserDetailPage} />
      <Route
        exact
        path={`${props.match.url}/:userUuid/teams-settings`}
        component={UserTeamSettingsPage}
      />
    </Switch>
  </React.Fragment>
);

export default UsersRoutes;
