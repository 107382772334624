import ContractsApi from '../api/ContractsApi';
import { buildApiCallAction } from './ActionUtils';
import {
  FETCH_CONTRACT_TEMPLATE_PAGE,
  FETCH_CONTRACT_TEMPLATE,
  CREATE_CONTRACT_TEMPLATE,
  MODIFY_CONTRACT_TEMPLATE,
  DELETE_CONTRACT_TEMPLATE,
  EDIT_CONTRACT_TEMPLATE_EDITOR,
  CLEAR_CONTRACT_TEMPLATE_EDITOR
} from '../constants/ActionTypes';

let api = new ContractsApi();

export const fetchContractTemplatesPage = (page, size) => buildApiCallAction({
  api: api,
  apiFunction: api => api.getContractTemplates(page, size),
  actionType: FETCH_CONTRACT_TEMPLATE_PAGE
});

export const fetchContractTemplate = (uuid) => buildApiCallAction({
  api: api,
  apiFunction: api => api.getContractTemplate(uuid),
  actionType: FETCH_CONTRACT_TEMPLATE
});

export const createContractTemplate = (contractTemplate) => buildApiCallAction({
  api: api,
  apiFunction: api => api.postContractTemplate(contractTemplate),
  actionType: CREATE_CONTRACT_TEMPLATE
});

export const deleteContractTemplate = (contractTemplate) => buildApiCallAction({
  api: api,
  apiFunction: api => api.deleteContractTemplate(contractTemplate),
  actionType: DELETE_CONTRACT_TEMPLATE,
  payload: contractTemplate
});

export const modifyContractTemplate = (contractTemplate) => buildApiCallAction({
  api: api,
  apiFunction: api => api.putContractTemplate(contractTemplate),
  actionType: MODIFY_CONTRACT_TEMPLATE
});

export const editContractTemplate = (item) => (dispatch, getState) => {
  dispatch({
    type: EDIT_CONTRACT_TEMPLATE_EDITOR,
    payload: item
  })
}

export const clearContractTemplateEditor = () => (dispatch, getState) => {
  dispatch({
    type: CLEAR_CONTRACT_TEMPLATE_EDITOR
  })
}

export const loadContractTemplateEditor = (uuid) => buildApiCallAction({
  api: api,
  apiFunction: api => api.getContractTemplate(uuid),
  actionType: EDIT_CONTRACT_TEMPLATE_EDITOR
})
