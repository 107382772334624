import React from 'react';
const { Card, CardContent } = require('@mui/material');
const { default: DictionaryView } = require('./DictionaryView');

const DictionaryCardView = ({ dictionary, actions, children }) => {
  return (
    <Card>
      <CardContent>
        <DictionaryView dictionary={dictionary}></DictionaryView>
        {children}
      </CardContent>
      {actions}
    </Card>
  );
};

export default DictionaryCardView;
