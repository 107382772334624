import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChipItem, ChipWrapper } from 'components/chiplist/ChipList';
import QueryBucketSystemModal from './QueryBucketSystemModal';
import _ from 'lodash';
import AccountTeamSelector, {
  ACCESS_LEVELS
} from 'pages/settings/profile/utils/AccountTeamSelector';
import TableCell from 'components/tables/GenericTableCell';
import { Add, ArrowDownward, ArrowUpward, DeleteOutlined } from '@mui/icons-material';
import SystemItemAutoComplete from 'pages/systems/commons/SystemItemAutoComplete';
import { AnalysisStrategySelect } from 'pages/systems/routines/commons/AnalysisStrategySelect';
import { getIconShort, getTechnologyConfig } from 'constants/SystemTechnologyTypes';

const QueryBucketModal = ({ title, open, onClose, onSubmit, queryBucket }) => {
  const [queryBucketDataPatch, setQueryBucketDataPatch] = useState({});
  const [openSystemModal, setOpenSystemModal] = useState(false);
  const { t } = useTranslation();

  const additionalValues = ['ENTITY', 'FIELD', 'NONE'];
  const mergedQueryBucketData = _.assign({}, queryBucket, queryBucketDataPatch);

  return (
    <Dialog fullWidth maxWidth='md' open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <h4>{t('querybuckets.modal.dialog.content')}</h4>
        <TextField
          variant='standard'
          fullWidth
          placeholder={t('querybuckets.modal.fields.nameHint')}
          label={t('querybuckets.modal.fields.name')}
          value={mergedQueryBucketData.name || ''}
          onChange={(event) =>
            setQueryBucketDataPatch({ ...queryBucketDataPatch, name: event.target.value })
          }
        />
        <br></br>
        <TextField
          variant='standard'
          fullWidth
          placeholder={t('querybuckets.modal.fields.descriptionHint')}
          label={t('querybuckets.modal.fields.description')}
          value={mergedQueryBucketData.description || ''}
          onChange={(event) =>
            setQueryBucketDataPatch({ ...queryBucketDataPatch, description: event.target.value })
          }
        />
        <br></br>
        <Typography style={{ paddingTop: '1rem', paddingBottom: '0.5rem' }}>Systems:</Typography>
        <ChipWrapper onRequestAdd={() => setOpenSystemModal(true)}>
          {mergedQueryBucketData.systems && mergedQueryBucketData.systems.length > 0 ? (
            mergedQueryBucketData.systems.map((sys) => (
              <ChipItem
                key={sys.uuid}
                id={sys.uuid}
                label={sys.name}
                icon={getIconShort(getTechnologyConfig(sys?.technology), {
                  width: '2em',
                  height: '2em'
                })}
                tooltip={sys?.description}
                onRequestDelete={() => {
                  setQueryBucketDataPatch({
                    ...queryBucketDataPatch,
                    systems: mergedQueryBucketData.systems.filter((s) => s.uuid !== sys.uuid)
                  });
                }}
              />
            ))
          ) : (
            <></>
          )}
        </ChipWrapper>
        <h4>Automatic Dataflow Creation</h4>
        <FormControl variant='standard' fullWidth margin='dense'>
          <InputLabel>Strategy</InputLabel>
          <Select
            variant='standard'
            defaultValue={'NONE'}
            value={
              mergedQueryBucketData?.additionalProperties?.find(
                (prop) => prop.name === '_dataFlowStrategy'
              )?.value || 'NONE'
            }
            onChange={(event) => {
              setQueryBucketDataPatch({
                ...queryBucketDataPatch,
                additionalProperties: (mergedQueryBucketData?.additionalProperties || [])
                  .filter((old) => old.name !== '_dataFlowStrategy')
                  .concat([{ name: '_dataFlowStrategy', value: event.target.value }])
              });
            }}>
            {additionalValues.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <QueryBucketSystemModal
          open={openSystemModal}
          onClose={() => setOpenSystemModal(false)}
          onSubmit={(sys) => {
            setOpenSystemModal(false);
            setQueryBucketDataPatch({
              ...queryBucketDataPatch,
              systems: _.unionBy(mergedQueryBucketData.systems, [sys], 'uuid')
            });
          }}
        />
        <PreProcessingSettingsEditor
          additionalProperties={mergedQueryBucketData.additionalProperties || []}
          onChange={(additionalProperties) => {
            setQueryBucketDataPatch({
              ...queryBucketDataPatch,
              additionalProperties: additionalProperties
            });
          }}
        />
        <h4 style={{ marginBottom: '0px' }}>System Routines analysis settings</h4>
        <SystemItemAutoComplete
          value={mergedQueryBucketData?.systemRoutinesSystem}
          onChange={(system) =>
            setQueryBucketDataPatch({ ...queryBucketDataPatch, systemRoutinesSystem: system })
          }
        />
        <AnalysisStrategySelect
          value={mergedQueryBucketData?.systemRoutinesAnalysisStrategy}
          onChange={(strategy) =>
            setQueryBucketDataPatch({
              ...queryBucketDataPatch,
              systemRoutinesAnalysisStrategy: strategy
            })
          }
        />
        <FormControlLabel
          checked={
            mergedQueryBucketData?.additionalProperties?.find(
              (prop) => prop.name === '_disableTemporaryTables'
            )?.value === 'true'
          }
          onChange={(event, checked) =>
            setQueryBucketDataPatch({
              ...queryBucketDataPatch,
              additionalProperties: (mergedQueryBucketData?.additionalProperties || [])
                .filter((old) => old.name !== '_disableTemporaryTables')
                .concat([{ name: '_disableTemporaryTables', value: `${checked}` }])
            })
          }
          sx={{ marginTop: 1 }}
          control={<Checkbox />}
          label='Disable system routines temporary tables'
        />
      </DialogContent>
      <DialogActions>
        <AccountTeamSelector
          accessLevel={ACCESS_LEVELS.WRITE}
          selectedTeamCode={mergedQueryBucketData.description || ''}
          onChange={(code) => setQueryBucketDataPatch({ ...queryBucketDataPatch, teamCode: code })}
        />
        <div style={{ flexGrow: 1 }}></div>
        <Button
          onClick={() => {
            onClose();
            setQueryBucketDataPatch({});
          }}>
          {t('commons.actions.cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            onSubmit(mergedQueryBucketData);
            setQueryBucketDataPatch({});
          }}
          disabled={!mergedQueryBucketData.name || _.isEqual(queryBucket, mergedQueryBucketData)}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const REPLACEMENT_REGEX_PROPERTY_NAME = '_replaceRegex';

function PreProcessingSettingsEditor({ additionalProperties, onChange }) {
  let { t } = useTranslation();
  let [addOpen, setAddOpen] = useState(false);
  let [editor, setEditor] = useState({ regex: null, replacement: null });
  const replacementSettings = additionalProperties.filter(
    (ap) => ap.name === REPLACEMENT_REGEX_PROPERTY_NAME
  );
  return (
    <>
      <Toolbar style={{ padding: 0 }}>
        <h4 style={{ flexGrow: 1 }}>Query Pre-Processing Settings</h4>
        <IconButton size='small' onClick={() => setAddOpen(true)}>
          <Add></Add>
        </IconButton>
      </Toolbar>
      {replacementSettings.length === 0 && (
        <Typography variant='body2' align='center' style={{ textAlign: 'center', padding: 4 }}>
          No query pre-processing settings defined for this bucket.
        </Typography>
      )}

      {replacementSettings.length > 0 && (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox' />
              <TableCell>Regex</TableCell>
              <TableCell>Replacement</TableCell>
              <TableCell style={{ width: 100 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {replacementSettings.map((ap, apIndex) => (
              <TableRow>
                <TableCell padding='checkbox'>
                  <IconButton
                    onClick={() => onChange(additionalProperties.filter((old) => old !== ap))}
                    size='large'>
                    <DeleteOutlined />
                  </IconButton>
                </TableCell>
                <TableCell>{ap.value.split('::::')[0]}</TableCell>
                <TableCell>{ap.value.split('::::')?.[1] || ''}</TableCell>
                <TableCell>
                  <IconButton
                    size='small'
                    disabled={apIndex === 0}
                    onClick={() =>
                      onChange(
                        additionalProperties
                          .filter((ap) => ap.name !== REPLACEMENT_REGEX_PROPERTY_NAME)
                          .concat(arraymove(replacementSettings, apIndex, apIndex - 1))
                      )
                    }>
                    <ArrowUpward />
                  </IconButton>
                  <IconButton
                    size='small'
                    disabled={apIndex === replacementSettings.length - 1}
                    onClick={() =>
                      onChange(
                        additionalProperties
                          .filter((ap) => ap.name !== REPLACEMENT_REGEX_PROPERTY_NAME)
                          .concat(arraymove(replacementSettings, apIndex, apIndex + 1))
                      )
                    }>
                    <ArrowDownward />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <Dialog open={addOpen}>
        <DialogTitle>Add a pre-processing setting</DialogTitle>
        <DialogContent>
          <TextField
            variant='standard'
            fullWidth
            label='Regex'
            value={editor.regex}
            onChange={(event) => setEditor({ ...editor, regex: event.target.value })}
          />
          <TextField
            variant='standard'
            fullWidth
            label='Replacement'
            value={editor.replacement}
            onChange={(event) => setEditor({ ...editor, replacement: event.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddOpen(false)}>{t('commons.actions.cancel')}</Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              onChange(
                additionalProperties.concat([
                  {
                    name: REPLACEMENT_REGEX_PROPERTY_NAME,
                    value: `${editor.regex}::::${editor.replacement || ''}`
                  }
                ])
              );
              setAddOpen(false);
              setEditor({});
            }}>
            {t('commons.actions.add')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function arraymove(arr, fromIndex, toIndex) {
  const newArr = [...arr];
  newArr.splice(toIndex, 0, newArr.splice(fromIndex, 1)[0]);
  return newArr;
}

export default QueryBucketModal;
