import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';

function _DatePicker({
  label,
  value,
  onChange = () => null,
  clearable = true,
  format = 'D/MM/YYYY',
  hourAdjustment = hourAdjustments.startOfDay,
  fullWidth = true,
  margin = 'normal',
  disabled,
  minDate,
  maxDate,
  variant = 'standard'
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        minDate={minDate && Date.parse(minDate)}
        maxDate={maxDate && Date.parse(maxDate)}
        fullWidth={fullWidth}
        margin={margin}
        label={label}
        variant={variant}
        disabled={disabled}
        value={typeof value === 'undefined' ? null : value}
        format={format}
        clearable={clearable}
        onChange={(date) => {
          let adjustment = hourAdjustmentsFuns[hourAdjustment];
          if (adjustment) adjustment(date);
          onChange(date && date.toISOString());
        }}
        renderInput={(params) => <TextField {...{ ...params, variant, margin, fullWidth }} />}
      />
    </LocalizationProvider>
  );
}

export const hourAdjustments = {
  startOfDay: 'startOfDay',
  endOfDay: 'endOfDay'
};

const hourAdjustmentsFuns = {
  startOfDay: (date) => (date ? date.startOf('day') : null),
  endOfDay: (date) => (date ? date.endOf('day') : null)
};

_DatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  format: PropTypes.string,
  hourAdjustment: PropTypes.oneOf(Object.values(hourAdjustments)),
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string
};

export default _DatePicker;
