import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { List, ListItem, Grid, Typography } from '@mui/material';
import { fetchProbeDefinitionsPage } from 'actions/quality/probes/ProbesDefinitionsActions';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';
import Pagination from 'components/pagination/Pagination';
import { useHistory } from 'react-router-dom';

const QualityProbesDefinitionVersionsSection = ({
  currentProbeDefinition,
  currentPage,
  fetchProbeDefinitionsPage
}) => {
  const history = useHistory();

  const fetchProbeVersions = (page) => {
    fetchProbeDefinitionsPage({
      rootUuid: currentProbeDefinition.rootUuid,
      lastVersion: false,
      sort: 'createdAt,desc',
      page: page,
      size: 10
    });
  };

  useEffect(() => {
    fetchProbeVersions(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProbeDefinition]);
  return (
    <Grid container direction='column' style={{ height: '100%' }}>
      <Grid item>
        <List>
          {currentPage.content.map((version, index) => (
            <ListItem
              key={index}
              button
              selected={version.sequenceId === currentProbeDefinition.sequenceId}
              onClick={() => {
                history.push(
                  `/data-quality/probes/definitions/${version.rootUuid}/versions/${version.sequenceId}`
                );
              }}>
              <div>
                <Grid container direction='row' alignItems='center' spacing={1}>
                  <Grid item>
                    <Typography variant='body1'>{`Version #${version.version}`}</Typography>
                  </Grid>
                  {version.lastVersion && (
                    <Grid item>
                      <VariationTypeDecorator>Current</VariationTypeDecorator>
                    </Grid>
                  )}
                </Grid>
                <Typography variant='body2' color='textSecondary'>
                  {moment(version.createdAt).format('DD MMMM YYYY, HH:mm:ss')}
                </Typography>
              </div>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item style={{ flexGrow: 1 }} />
      {currentPage && currentPage.totalPages > 1 ? (
        <Grid item>
          <Pagination
            total={currentPage.totalPages}
            current={currentPage.number + 1}
            display={3}
            onChange={(number) => fetchProbeVersions(number - 1)}
            styleRoot={{ margin: '8px 0 8px 0', display: 'flex', justifyContent: 'space-around' }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapStateToProps = (state, props) => ({
  currentPage: state.quality.qualityProbes.definitions.currentPage
});

export default connect(mapStateToProps, { fetchProbeDefinitionsPage })(
  QualityProbesDefinitionVersionsSection
);
