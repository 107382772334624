import { buildApiCallAction } from './ActionUtils';
import IssueMngtDashboardsApi from 'api/IssueMngtDashboardsApi';
import { FETCH_CAMPAIGN_TEMPORAL, FETCH_ISSUE_TYPE_COUNTERS } from 'constants/ActionTypes';
import { hasPermissions } from 'components/permissions/permissiosSelectors';
import Permissions from 'constants/Permissions';
import _ from 'lodash';

const api = new IssueMngtDashboardsApi();

export const fetchTemporal = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTemporal(uuid),
    actionType: FETCH_CAMPAIGN_TEMPORAL,
    payload: (response) => ({ ...response, campaignUuid: uuid })
  });

export const fetchDashboardTypeCounterIfAllowed =
  ({ resourceType, resourceIdentifier }) =>
  (dispatch, getState) => {
    let isCampaignViewer = hasPermissions([Permissions.CAMPAIGNS_VIEWER])(getState());
    return isCampaignViewer
      ? buildApiCallAction({
          api,
          apiFunction: (api) => api.getIssuesTypesCounter({ resourceType, resourceIdentifier }),
          actionType: FETCH_ISSUE_TYPE_COUNTERS,
          payload: (data) => ({ ...data, resourceType })
        })(dispatch, getState)
      : Promise.resolve();
  };

export const fetchDashboardTypeCounterIfAllowedLargeRequest =
  (originalArray, sizeSplitArray, resourceType) => (dispatch, getState) => {
    var splittedArray = _.chunk(originalArray, sizeSplitArray);
    _.forEach(splittedArray, function (entitiesArray) {
      return fetchDashboardTypeCounterIfAllowed({
        resourceIdentifier: entitiesArray.map((pe) => pe.uuid),
        resourceType: resourceType
      })(dispatch, getState);
    });
  };
