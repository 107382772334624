import { React, cloneElement } from 'react';
import { useHistory } from 'react-router';

import { Menu, Grid, Typography, Tooltip, IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import ResourceTypes from 'constants/ResourceTypes';
import { ResourceTypesIcons } from 'constants/ResourceTypesIcons';

const ErDiagramFieldNodeContextMenu = ({ entity, field, position, handleClose }) => {

  const history = useHistory();

  return (
    <Menu
      autoFocus={false}
      open={position !== null}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={position !== null ? { top: position.mouseY, left: position.mouseX } : undefined}
    >
      <div style={{ padding: '8px 16px 0' }}>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <ErDiagramFieldNodeContextMenuHeader physicalFieldName={field.name} />
          </Grid>
          <Grid item>
            <Tooltip title='Go to details'>
              <IconButton size='small' onClick={() => { handleClose(); history.push(`/systems/physical_fields/${field.physicalFieldUuid}`); }}>
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <KVDefinitionList
          item={{
            'Entity': entity.name,
            'Schema': entity.schema,
            'System': entity.systemName
          }}
        />
      </div>
    </Menu>
  );
};

const ErDiagramFieldNodeContextMenuHeader = ({ physicalFieldName }) => {

  return (
    <Grid container direction='column' justifyContent='flex-start'>
      <Grid item>
        <Grid container alignItems='center'>
          <Grid item style={{ height: '24px' }}>
            {cloneElement(ResourceTypesIcons[ResourceTypes.PHYSICAL_FIELD], { style: { color: 'rgba(0, 0, 0, 0.87)' } })}
          </Grid>
          <Grid item>
            <Typography variant='h6' sx={{ marginLeft: '5px', marginBottom: '2px', marginRight: '20px' }}>{physicalFieldName}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant='overline' sx={{ lineHeight: 'unset' }}>Physical Field</Typography>
      </Grid>
    </Grid>
  )
}

export default ErDiagramFieldNodeContextMenu;