import { buildApiCallAction } from 'actions/ActionUtils';
import ContractDefinitionsApi from 'api/contracts/ContractDefinitionsApi';
import {
  FETCH_CONTRACT_DEFINITIONS_PAGE,
  FETCH_CONTRACT_DEFINITION,
  CREATE_CONTRACT_DEFINITION,
  MODIFY_CONTRACT_DEFINITION,
  DELETE_CONTRACT_DEFINITION,
  SEARCH_CONTRACT_DEFINITIONS,
  EXPORT_CONTRACT_DEFINITIONS,
  SET_CONTRACT_DEFINITIONS_FILTERS,
  CLEAR_CONTRACT_DEFINITIONS_FILTERS,
  FETCH_CONTRACT_DEFINITION_CATEGORIES
} from 'constants/ActionTypes';

const api = new ContractDefinitionsApi();

export const searchContractDefinition = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getContractDefinitionsPage(params),
    actionType: SEARCH_CONTRACT_DEFINITIONS
  });

export const setContractDefinitionFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_CONTRACT_DEFINITIONS_FILTERS, payload: filters });
};

export const clearContractDefinitionFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_CONTRACT_DEFINITIONS_FILTERS });
};
export const fetchContractDefinition = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getContractDefinition(uuid),
    actionType: FETCH_CONTRACT_DEFINITION
  });

export const fetchContractDefinitionsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getContractDefinitionsPage(validateFilters(params)),
    actionType: FETCH_CONTRACT_DEFINITIONS_PAGE
  });

export const createContractDefinition = (contractDefinition) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postContractDefinition(contractDefinition),
    actionType: CREATE_CONTRACT_DEFINITION
  });

export const modifyContractDefinition = (contractDefinition) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putContractDefinition(contractDefinition),
    actionType: MODIFY_CONTRACT_DEFINITION
  });

export const deleteContractDefinition = (contractDefinition) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteContractDefinition(contractDefinition),
    actionType: DELETE_CONTRACT_DEFINITION,
    payload: contractDefinition
  });

export const exportContractDefinitions = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportContractDefinitions(params),
    actionType: EXPORT_CONTRACT_DEFINITIONS
  });

export const fetchContractDefinitionCategories = (params) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.getContractDefinitionCategories(params),
    actionType: FETCH_CONTRACT_DEFINITION_CATEGORIES
  });

function validateFilters(filters) {
  return {
    processingUuids: filters.content.processingUuids?.map((proc) => proc.uuid),
    search: filters.content?.search,
    category: filters.content?.category,
    propertiesNames: filters.content?.propertiesNames,
    propertiesValues: filters.content?.propertiesValues,
    nullPropertiesNames: filters.content?.nullPropertiesNames,
    page: filters.page,
    size: filters.size
  };
}
