import React, { useState } from 'react';
import { connect } from 'react-redux';

import { createCampaign } from 'actions/CampaignActions';

import AddFAB from 'components/fab/AddFAB';
import CampaignModal from 'pages/issueMngt/campaigns/detail/CampaignModal';

const CampaignFAB = ({ createCampaign }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      {open && (
        <CampaignModal
          open={open}
          handleSubmit={createCampaign}
          handleCancel={() => setOpen(false)}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  createCampaign
};

export default connect(null, mapDispatchToProps)(CampaignFAB);
