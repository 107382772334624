import { BlindataApi } from 'api/ApiUtils';

const PROBES_TAGS_URL = '/api/v1/data-quality/probes/tags';

export default class ProbesProjectsApi extends BlindataApi {
  getTag(uuid) {
    return this.get(`${PROBES_TAGS_URL}/${uuid}`);
  }

  getTagsPage(params) {
    return this.get(PROBES_TAGS_URL, params);
  }

  postTag(tag) {
    return this.post(PROBES_TAGS_URL, tag);
  }

  restoreProbesFromTag(tagUuid) {
    return this.put(`${PROBES_TAGS_URL}/${tagUuid}/restore`);
  }

  deleteTag(uuid) {
    return this.delete(`${PROBES_TAGS_URL}/${uuid}`);
  }

  patchTag(tag) {
    return this.patch(`${PROBES_TAGS_URL}/${tag.uuid}`, tag);
  }
}
