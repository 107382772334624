import React, { useState } from 'react';
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { WebHookMatchRuleModal } from './WebHookMatchRuleModal';
import { useDispatch } from 'react-redux';
import { modifyWebHook } from 'actions/settings/webhooks/WebHooksActions';
import _ from 'lodash';
import ExpandableCard from 'components/expandable-card/ExpandableCard';
import { t } from 'i18next';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import KVDefinitionList from 'components/definition-list/KVDefinitionList';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import { Decorator } from 'components/decorator/Decorator';

const WebHookMatchRulesPanel = ({ webHook }) => {
  const dispatch = useDispatch();
  return (
    <>
      <Grid container direction='column'>
        <Grid container item justifyContent='flex-end'>
          <Authorize hasPermissions={[Permissions.AGENTS_EDITOR]}>
            <AddMatchRuleButton webHook={webHook} />
          </Authorize>
        </Grid>
        {webHook?.matchRules && webHook?.matchRules.length > 0 ? (
          webHook.matchRules.map((rule) => (
            <ExpandableCard
              key={rule.name}
              title={rule.name}
              decorator={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Decorator text={rule?.eventType || 'ALL EVENTs'} />
                  {rule?.resourceType}
                </div>
              }
              subheader={<Typography variant='description'>{rule.description}</Typography>}
              actions={
                <>
                  <Authorize hasPermissions={[Permissions.AGENTS_EDITOR]}>
                    <ModifyMatchRuleButton matchRule={rule} webHook={webHook} />
                  </Authorize>
                  <Authorize hasPermissions={[Permissions.AGENTS_EDITOR]}>
                    <ConfirmButton
                      onClick={() =>
                        dispatch(
                          modifyWebHook({
                            ...webHook,
                            matchRules: webHook?.matchRules?.filter((el) => el.uuid !== rule.uuid)
                          })
                        )
                      }>
                      {t('commons.actions.delete')}
                    </ConfirmButton>
                  </Authorize>
                </>
              }>
              <KVDefinitionList header='Match Attributes' item={rule.matchAttributes} />
              <KVDefinitionList header='Parameter Mapping' item={rule.requestTemplateParamsMap} />
            </ExpandableCard>
          ))
        ) : (
          <Grid container item justifyContent='center'>
            <Typography variant='body1' sx={{ textAlign: 'center', padding: 5 }}>
              {t('webHooks.matchRules.noRule')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default WebHookMatchRulesPanel;

const AddMatchRuleButton = ({ webHook }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      <Tooltip title={t('webHooks.matchRules.modal.title', { action: 'Add' })}>
        <IconButton onClick={() => setOpen(true)}>
          <AddBoxIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <WebHookMatchRuleModal
          open={open}
          title={t('webHooks.matchRules.modal.title', { action: 'Add' })}
          onCancel={() => setOpen(false)}
          requestTemplateParams={webHook?.requestTemplate?.requestTemplateParams}
          onSubmit={(matchRule) => {
            dispatch(
              modifyWebHook({
                ...webHook,
                matchRules: _([].concat(webHook?.matchRules, matchRule)).compact().uniq().value()
              })
            );
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

const ModifyMatchRuleButton = ({ webHook, matchRule }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      {open && (
        <WebHookMatchRuleModal
          open={open}
          title={t('webHooks.matchRules.modal.title', { action: 'Modify' })}
          matchRule={matchRule}
          onCancel={() => setOpen(false)}
          requestTemplateParams={webHook?.requestTemplate?.requestTemplateParams}
          onSubmit={(matchRule) => {
            dispatch(
              modifyWebHook({
                ...webHook,
                matchRules: [
                  ...webHook?.matchRules?.filter((rule) => rule.uuid !== matchRule.uuid),
                  matchRule
                ]
              })
            );
            setOpen(false);
          }}
        />
      )}
    </>
  );
};
