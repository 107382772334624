import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class Loader extends React.Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return this.props.children;
  }
}

export const withLoader =
  ({ onLoad, dependencies = [], ready = () => true }) =>
  (WrappedComponent) => {
    return class extends React.Component {
      componentDidMount() {
        onLoad(this.props);
      }

      componentDidUpdate(prevProps) {
        if (
          dependencies?.length > 0 &&
          !_.every(dependencies, (d) => prevProps[d] === this.props[d])
        ) {
          onLoad(this.props);
        }
      }

      render() {
        if ((_.isFunction(ready) && ready(this.props)) || (!_.isFunction(ready) && ready)) {
          return <WrappedComponent {...this.props} />;
        } else {
          return null;
        }
      }
    };
  };

Loader.propTypes = {
  onLoad: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};
