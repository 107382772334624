import {
  FETCH_DICTIONARYS_PAGE,
  FETCH_DICTIONARY,
  CREATE_DICTIONARY,
  DELETE_DICTIONARY,
  MODIFY_DICTIONARY,
  SET_DICTIONARIES_FILTER,
  CLEAR_DICTIONARIES_FILTER,
  EXPORT_CLASSIFICATION_DICTIONARIES
} from '../constants/ActionTypes';
import DictionaryApi from '../api/DictionaryApi';
import { buildApiCallAction } from './ActionUtils';

const dictionaryApi = new DictionaryApi();

export const fetchDictionaryListPage = (params) =>
  buildApiCallAction({
    api: dictionaryApi,
    apiFunction: (api) => api.getAllDictionaries(params),
    actionType: FETCH_DICTIONARYS_PAGE
  });

export const fetchDictionary = (dictionaryUuid) =>
  buildApiCallAction({
    api: dictionaryApi,
    apiFunction: (api) => api.getDictionary(dictionaryUuid),
    actionType: FETCH_DICTIONARY
  });

export const createDictionary = (dictionary) =>
  buildApiCallAction({
    api: dictionaryApi,
    apiFunction: (api) => api.postDictionary(dictionary),
    actionType: CREATE_DICTIONARY
  });

export const deleteDictionary = (dictionary) =>
  buildApiCallAction({
    api: dictionaryApi,
    apiFunction: (api) => api.deleteDictionary(dictionary),
    actionType: DELETE_DICTIONARY,
    payload: dictionary
  });

export const modifyDictionary = (dictionary) =>
  buildApiCallAction({
    api: dictionaryApi,
    apiFunction: (api) => api.putDictionary(dictionary),
    actionType: MODIFY_DICTIONARY
  });

export const setScopesFilter = (filter) => (dispatch) => {
  dispatch({ type: SET_DICTIONARIES_FILTER, payload: filter });
};

export const clearScopesFilter = (filter) => (dispatch) => {
  dispatch({ type: CLEAR_DICTIONARIES_FILTER });
};

export const exportClassificationDictionaries = (params) =>
  buildApiCallAction({
    api: dictionaryApi,
    apiFunction: (api) => api.exportClassificationDictionaries(params),
    actionType: EXPORT_CLASSIFICATION_DICTIONARIES
  });
