import React from 'react';
import { Button } from '@mui/material';
import WorkflowDataLoader from './WorkflowDataLoader';
import { connect } from 'react-redux';
import { searchProcessings } from '../../../../actions/ProcessingsActions';
import { searchTasks } from '../../../../actions/TasksActions';
import { fetchDataActorTaskAssignments } from '../../../../actions/TaskAssignmentsActions';
import {
  fetchTemplatesMappingsPage,
  modifyTemplateEvent
} from '../../../../actions/TemplatesActions';
import { createProposal, createImmediateProposal } from '../../../../actions/ProposalsActions';
import { ProposalStatus } from '../../../workflows/proposals/commons/ProposalConstants';
import withPermissions from '../../../../components/permissions/withPermissions';
import { compose } from 'redux';
import Permissions from '../../../../constants/Permissions';

export function immediateAction({
  templateEvent,
  searchProcessings,
  searchTasks,
  fetchTaskAssignment,
  fetchMappings,
  modifyTemplateEvent,
  createImmediateProposal
}) {
  return async function () {
    let loader = new WorkflowDataLoader({
      searchProcessings,
      searchTasks,
      fetchTaskAssignment,
      fetchMappings
    });
    let items = await loader.loadData({ templateEvent });
    let response = await createImmediateProposal({
      items
    });
    if (response) {
      return await modifyTemplateEvent({ ...templateEvent, eventStatus: 'COMPLETED' });
    } else {
      return null;
    }
  };
}

function _ImmediateButton(props) {
  const onClick = immediateAction(props);
  return <Button onClick={onClick}>Immediate</Button>;
}

function _ProposeButton({
  templateEvent,
  searchProcessings,
  searchTasks,
  fetchTaskAssignment,
  fetchMappings,
  modifyTemplateEvent,
  createProposal
}) {
  async function create() {
    let loader = new WorkflowDataLoader({
      searchProcessings,
      searchTasks,
      fetchTaskAssignment,
      fetchMappings
    });
    let items = await loader.loadData({ templateEvent });
    let response = await createProposal({
      name: templateEvent.name,
      proposalStatus: ProposalStatus.SUBMITTED,
      items
    });
    if (response) {
      return await modifyTemplateEvent({ ...templateEvent, eventStatus: 'COMPLETED' });
    } else {
      return null;
    }
  }
  return <Button onClick={create}>PROPOSE</Button>;
}

export const mapDispatchToProps = {
  searchProcessings: searchProcessings,
  searchTasks: searchTasks,
  fetchTaskAssignment: fetchDataActorTaskAssignments,
  fetchMappings: fetchTemplatesMappingsPage,
  modifyTemplateEvent,
  createProposal,
  createImmediateProposal
};

export const ImmediateButton = compose(
  withPermissions({
    hasPermissions: [
      Permissions.PROCESSINGS_EDITOR,
      Permissions.TASKS_EDITOR,
      Permissions.DATA_ACTORS_EDITOR
    ]
  }),
  connect(null, mapDispatchToProps)
)(_ImmediateButton);

export const ProposeButton = compose(
  withPermissions({ hasPermissions: [Permissions.PROPOSE] }),
  connect(null, mapDispatchToProps)
)(_ProposeButton);
