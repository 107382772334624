import React from 'react';
import { Typography, Switch, FormControlLabel, Grid } from '@mui/material';
import ProposalsList from './ProposalsList';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  fetchProposalsPageWithFilters,
  setProposalsFilters,
  clearProposalsFilters
} from '../../../../actions/ProposalsActions';
import ProposalStatusSwitcher from './ProposalStatusSwitcher';
import { ProposalStatus } from '../commons/ProposalConstants';
import Authorize from '../../../../components/permissions/Authorize';
import Permissions from '../../../../constants/Permissions';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
function ProposalsListPage({ currentPage, filters, fetchProposalsPage, setProposalsFilters }) {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs elements={[{ text: t('sidebar.proposals'), to: '/workflows/proposals' }]} />
      <Tracking
        info={{
          pageTitle: 'Proposals Page',
          category: EngagementCategories.PROPOSALS,
          action: EngagementActions.LIST
        }}
      />
      <Grid container alignItems='center' justifyContent='flex-start'>
        <Grid item>
          <Typography variant='h4'>
            {filters.approverPerspective ? 'Incoming' : 'My'} Proposals
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <FavoritesWidget itemIdentifier={'page:proposals-list'} pageTitle={'Proposals Page'} />
        </Grid>
        <Grid item>
          <Authorize hasPermissions={[Permissions.APPROVE]}>
            <FormControlLabel
              style={{ float: 'right' }}
              control={
                <Switch
                  color='primary'
                  checked={filters.approverPerspective || false}
                  onChange={(event, checked) => {
                    setProposalsFilters({
                      approverPerspective: checked,
                      proposalStates: [ProposalStatus.SUBMITTED, ProposalStatus.ACKNOWLEDGED]
                    });
                    fetchProposalsPage();
                  }}></Switch>
              }
              label='Incoming'
            />
          </Authorize>
        </Grid>
      </Grid>

      <ProposalStatusSwitcher
        filters={filters}
        onChange={(filters) => {
          setProposalsFilters(filters);
          fetchProposalsPage({ page: 0 });
        }}></ProposalStatusSwitcher>
      <ProposalsList currentPage={currentPage} fetchProposalsPage={fetchProposalsPage} />
    </>
  );
}

const mapStateToProps = (state) => ({
  currentPage: state.workflows.proposals.currentPage,
  filters: state.workflows.proposals.filters
});

const mapDispatchToProps = {
  fetchProposalsPage: fetchProposalsPageWithFilters,
  setProposalsFilters,
  clearProposalsFilters
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProposalsListPage);
