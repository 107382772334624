import React, { useCallback, useEffect, useState } from 'react';
import { setQueryBucketsFilters } from 'actions/QueryBucketsActions';
import _ from 'lodash';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { connect } from 'react-redux';

const QueryBucketsSearchBox = ({ search, setQueryBucketsFilters }) => {
  let [editor, setEditor] = useState(search || '');

  useEffect(() => {
    if (search !== editor) {
      setEditor(search || '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setEditor]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let onSetFilters = useCallback(_.debounce(setQueryBucketsFilters, 300), [setQueryBucketsFilters]);

  return (
    <TextField
      value={editor}
      id='input-with-icon-textfield'
      placeholder='Search...'
      variant='outlined'
      size='small'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='disabled' />
          </InputAdornment>
        )
      }}
      onChange={(event) => {
        setEditor(event.target.value);
        onSetFilters({ search: event.target.value || null });
      }}
    />
  );
};

const mapStateToProps = (state, { search }) => ({
  search: state.dataflows.querybuckets.filters.content.search
});
export default connect(mapStateToProps, { setQueryBucketsFilters })(QueryBucketsSearchBox);
