import React, { useState } from 'react';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid
} from '@mui/material';
import TaskCardView from '../../../tasks/utils/TaskCardView';
import { connect } from 'react-redux';
import {
  fetchTemplateTask,
  modifyTemplateTask,
  deleteTemplateTask
} from '../../../../actions/TemplatesActions';
import { compose } from 'redux';
import { withLoader } from '../../../../components/loader/Loader';
import TemplateVariablesEditor from '../../commons/TemplateVariablesEditor';
import { ChipItem, ChipWrapper } from '../../../../components/chiplist/ChipList';
import ConfirmButton from '../../../../components/confirmbutton/ConfirmButton';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import TemplateTaskModal from '../commons/TemplateTaskModal';
import TemplateTaskAssignmentEditor from '../commons/TemplateTaskAssignmentEditor';
import { TemplatesTasksUpdateDialog } from '../../commons/TemplatesUpdateDialog';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
function TemplateTaskDetailPage({ templateTask, deleteTemplateTask, modifyTemplateTask }) {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        elements={[{ text: 'Templates', to: '/templates' }, { text: templateTask.name }]}
      />
      <Tracking
        info={{
          pageTitle: `Task Template: ${templateTask.name}`,
          category: EngagementCategories.TEMPLATES,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>Task Template: {templateTask.name}</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`PROCESSING_TEMPLATE:${templateTask?.sequenceId}`}
            pageTitle={`Processing Template ${templateTask?.name}`}
          />
        </Grid>
      </Grid>
      <Card>
        <CardHeader title={templateTask.name} subheader={templateTask.code}></CardHeader>
        <CardContent>
          <TemplateVariablesEditor type='TASK' variables={templateTask.variables} />
          <h4>Associated Processings Templates</h4>
          <ChipWrapper>
            {(templateTask.templatesProcessings || []).map((p, index) => (
              <ChipItem
                key={index}
                id={index}
                label={p.name}
                to={`/templates/processings/${p.sequenceId}`}
              />
            ))}
          </ChipWrapper>
          <TemplateTaskAssignmentEditor
            taskAssignments={templateTask.taskAssignments}
            templateTask={templateTask}></TemplateTaskAssignmentEditor>
        </CardContent>
        <Authorize hasPermissions={[Permissions.TASKS_ADMIN]}>
          <CardActions>
            <ModifyAction
              templateTask={templateTask}
              modifyTemplateTask={modifyTemplateTask}></ModifyAction>
            <ConfirmButton
              onClick={() => {
                deleteTemplateTask(templateTask.sequenceId).then(() =>
                  history.push('/templates/tasks')
                );
              }}>
              {t('commons.actions.delete')}
            </ConfirmButton>
          </CardActions>
        </Authorize>
      </Card>
      <Typography variant='h5'>Content</Typography>
      <TaskCardView task={templateTask.content}></TaskCardView>
    </>
  );
}

function ModifyAction({ templateTask, modifyTemplateTask }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [contentPatch, setContentPatch] = useState(null);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      {open && (
        <TemplateTaskModal
          templateTask={templateTask}
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={(modifiedTemplateTask, contentPatch) => {
            modifyTemplateTask(modifiedTemplateTask);
            setOpen(false);
            setContentPatch(contentPatch);
          }}></TemplateTaskModal>
      )}
      {contentPatch && (
        <TemplatesTasksUpdateDialog
          open={contentPatch != null}
          templateCode={templateTask.code}
          contentPatch={contentPatch}
          onDismiss={() => setContentPatch(null)}
        />
      )}
    </>
  );
}

function mapStateToProps(state, props) {
  const sequenceId = props.match.params.sequenceId;
  return {
    templateTask: state.templates.templatesTasks.content[sequenceId],
    sequenceId: props.match.params.sequenceId
  };
}

const mapDispatchToProps = {
  fetchTemplateTask,
  modifyTemplateTask,
  deleteTemplateTask
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader({
    onLoad: ({ fetchTemplateTask, sequenceId }) => fetchTemplateTask(sequenceId),
    ready: ({ templateTask }) => templateTask !== undefined
  })
)(TemplateTaskDetailPage);
