import React from 'react';
import PaginatedPanel from '../../../../components/pagination/PaginatedPanel';
import ExpandableCard from '../../../../components/expandable-card/ExpandableCard';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchRpaArchivesPage } from '../../../../actions/RpaArchiveActions';

function RpaArchivesList({ currentPage, onPageSelection }) {
  return (
    <PaginatedPanel autoload onPageSelection={onPageSelection} currentPage={currentPage}>
      <>
        {currentPage.content.map((item) => (
          <RpaArchivesListItem key={item.uuid} rpaArchive={item}></RpaArchivesListItem>
        ))}
      </>
    </PaginatedPanel>
  );
}

function RpaArchivesListItem({ rpaArchive }) {
  const { t } = useTranslation();
  return (
    <ExpandableCard
      title={rpaArchive.name}
      subheader={rpaArchive.code}
      actions={[
        <Button
          children={t('commons.actions.detail')}
          component={Link}
          to={`/reports/rpa/archives/${rpaArchive.uuid}`}
        />
      ]}></ExpandableCard>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.reports.rpa.archives.currentPage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onPageSelection: (page) => dispatch(fetchRpaArchivesPage({ page }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RpaArchivesList);
