import { createWebHook } from 'actions/settings/webhooks/WebHooksActions';
import { AddFAB } from 'components/fab';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import WebHookModal from './WebHookModal';
import { useHistory } from 'react-router';

const WebHookFAB = ({ _props }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <AddFAB onClick={() => setOpen(true)}></AddFAB>
      {open && (
        <WebHookModal
          title={'Create a Web Hook'}
          open={open}
          onCancel={() => setOpen(false)}
          onSubmit={(webHook) => {
            dispatch(createWebHook(webHook)).then(
              (res) => res?.uuid && history.push(`/settings/web-hooks/${res.uuid}`)
            );
            setOpen(false);
          }}></WebHookModal>
      )}
    </>
  );
};

export default WebHookFAB;
