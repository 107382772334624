import { BlindataApi } from './ApiUtils';
import _ from 'lodash';

export default class RpaUtilsApi extends BlindataApi {
  testDownloadRpa(params) {
    let { templateUuid, type } = params;
    var f = document.createElement('form');
    f.method = 'POST';
    f.action = `/api/v1/reports/rpa/utils/testDownload`;
    f.target = '_blank';
    var element1 = document.createElement('INPUT');
    element1.name = 'access_token';
    element1.value = this.token;
    element1.type = 'hidden';
    f.appendChild(element1);

    if (this.selectedTenantUuid) {
      var element2 = document.createElement('INPUT');
      element2.name = 'x_bd_tenant';
      element2.value = this.selectedTenantUuid;
      element2.type = 'hidden';
      f.appendChild(element2);
    }
    //and some more input elements here
    _({ type, templateUuid })
      .pickBy(_.negate(_.isEmpty))
      .forEach((value, key) => {
        let e = document.createElement('INPUT');
        e.name = key;
        e.value = value;
        e.type = 'hidden';
        f.appendChild(e);
      });
    //and dont forget to add a submit button
    document.body.appendChild(f);
    f.submit();
    return new Promise((resolve) => resolve());
  }

  downloadRpa(filters) {
    return this.download('/api/v1/reports/rpa/utils/download', filters);
  }

  fetchTemplateSample() {
    return this.get('/api/v1/reports/rpa/utils/templateSample');
  }
}
