import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ItemAutoCompleteModal from '../../../../components/itemselector/ItemAutoCompleteModal';
import { searchTemplatesProcessings } from '../../../../actions/TemplatesActions';

function TemplatesProcessingsSearchModal({
  title,
  open,
  content,
  searchTemplatesProcessings,
  onCancel,
  onSubmit
}) {
  return (
    <ItemAutoCompleteModal
      title={title || `Search Processing Template`}
      itemName={'Processing Template'}
      open={open}
      keyProperty={'sequenceId'}
      nameProperty={'name'}
      values={Object.values(content)}
      onSearch={(searchText) => searchTemplatesProcessings({ search: searchText })}
      handleCancel={onCancel}
      handleSubmit={onSubmit}
    />
  );
}

TemplatesProcessingsSearchModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  content: state.templates.templatesProcessings.content
});

export default connect(mapStateToProps, { searchTemplatesProcessings })(
  TemplatesProcessingsSearchModal
);
