import LogicalRelationsApi from 'api/LogicalRelationsApi';
import AbstractImporter from './AbstractImporter';

export default class ImporterLogicalRelation extends AbstractImporter {
  static isSupported(object) {
    return object.logicalRelation;
  }

  async importObject(object, resolveUuidOnly = false) {
    if (object.logicalRelation) {
      return this.importLogicalRelation(object.logicalRelation, resolveUuidOnly);
    } else {
      throw new Error('Object type not supported by ImporterLogicalRelation');
    }
  }

  async importLogicalRelation(logicalRelation, resolveUuidOnly = false) {
    let api = new LogicalRelationsApi();

    let reconciledLogicalRelation = logicalRelation;
    if (!resolveUuidOnly) {
      reconciledLogicalRelation = {
        ...logicalRelation,
        fromDataCategory: logicalRelation.fromDataCategory
          ? await super.importObject({ dataCategory: logicalRelation.fromDataCategory }, true)
          : null,
        fromLogicalField: logicalRelation.fromLogicalField
          ? await super.importObject({ logicalField: logicalRelation.fromLogicalField }, true)
          : null,
        toDataCategory: logicalRelation.toDataCategory
          ? await super.importObject({ dataCategory: logicalRelation.toDataCategory }, true)
          : null,
        toLogicalField: logicalRelation.toLogicalField
          ? await super.importObject({ logicalField: logicalRelation.toLogicalField }, true)
          : null
      };
    }
    return this.importTemplate({
      object: reconciledLogicalRelation,
      objectName: null,
      objectIdentifier: 'uuid',
      api: api,
      post: api.postLogicalRelation,
      put: api.putLogicalRelation,
      delet: api.deleteLogicalRelation,
      resolveUuidOnly
    });
  }
}
