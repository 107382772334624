import React from 'react';

import { Table, TableHead, TableBody, TableRow } from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import { ResourceTypesIcons } from 'constants/ResourceTypesIcons';
import ResourceTypes from 'constants/ResourceTypes';
import ConfirmIconButton from 'components/confirmbutton/ConfirmIconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { getIconShort, getTechnologyConfig } from 'constants/SystemTechnologyTypes';
import _ from 'lodash';

function AssetsTable({ editor, setEditor }) {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell hiddenSm>Description</TableCell>
          <TableCell style={{ textAlign: 'end' }}>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(editor?.dataCategories || []).map((dc) => (
          <TableRow key={dc.uuid}>
            <TableCell>
              {
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {ResourceTypesIcons[ResourceTypes.DATA_CATEGORY]}
                  <div style={{ padding: '12px' }}>{dc?.name}</div>
                </div>
              }
            </TableCell>
            <TableCell breakWord hiddenSm>
              {_.truncate(dc?.description, { length: 60 }) || '-'}
            </TableCell>
            <TableCell style={{ textAlign: 'end' }}>
              <ConfirmIconButton
                size='small'
                aria-label='delete'
                onClick={() =>
                  setEditor({
                    ...editor,
                    dataCategories: editor.dataCategories.filter(
                      (category) => category.uuid !== dc.uuid
                    )
                  })
                }>
                <DeleteIcon />
              </ConfirmIconButton>
            </TableCell>
          </TableRow>
        ))}
        {(editor?.logicalFields || []).map((lf) => (
          <TableRow key={lf.uuid}>
            <TableCell>
              {
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {ResourceTypesIcons[ResourceTypes.LOGICAL_FIELD]}
                  <div style={{ padding: '12px' }}>
                    {lf?.dataCategory?.name}.{lf.name}
                  </div>
                </div>
              }
            </TableCell>
            <TableCell breakWord hiddenSm>
              {_.truncate(lf?.description, { length: 60 }) || '-'}
            </TableCell>
            <TableCell style={{ textAlign: 'end' }}>
              <ConfirmIconButton
                size='small'
                aria-label='delete'
                onClick={() =>
                  setEditor({
                    ...editor,
                    logicalFields: editor.logicalFields.filter((field) => field.uuid !== lf.uuid)
                  })
                }>
                <DeleteIcon />
              </ConfirmIconButton>
            </TableCell>
          </TableRow>
        ))}
        {(editor?.physicalEntities || []).map((pe) => (
          <TableRow key={pe.uuid}>
            <TableCell>
              {
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {ResourceTypesIcons[ResourceTypes.PHYSICAL_ENTITY]}
                  {getIconShort(getTechnologyConfig(pe.system?.technology), {
                    width: '1.5em',
                    height: '1.5em'
                  })}
                  <div style={{ display: 'flex', alignItems: 'center', padding: '12px' }}>
                    {pe?.schema}.{pe?.name}
                  </div>
                </div>
              }
            </TableCell>
            <TableCell hiddenSm>{_.truncate(pe?.description, { length: 60 }) || '-'}</TableCell>
            <TableCell style={{ textAlign: 'end' }}>
              <ConfirmIconButton
                size='small'
                aria-label='delete'
                onClick={() =>
                  setEditor({
                    ...editor,
                    physicalEntities: editor.physicalEntities.filter(
                      (entity) => entity.uuid !== pe.uuid
                    )
                  })
                }>
                <DeleteIcon />
              </ConfirmIconButton>
            </TableCell>
          </TableRow>
        ))}
        {(editor?.physicalFields || []).map((pf) => (
          <TableRow key={pf?.uuid}>
            <TableCell>
              {
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {ResourceTypesIcons[ResourceTypes.PHYSICAL_FIELD]}
                  {getIconShort(getTechnologyConfig(pf?.physicalEntity?.system?.technology), {
                    width: '1.5em',
                    height: '1.5em'
                  })}
                  <div style={{ padding: '12px' }}>
                    {pf?.physicalEntity?.schema}.{pf?.physicalEntity?.name}.{pf?.name}
                  </div>
                </div>
              }
            </TableCell>
            <TableCell>{_.truncate(pf?.description, { length: 60 }) || '-'}</TableCell>
            <TableCell style={{ textAlign: 'end' }}>
              <ConfirmIconButton
                size='small'
                aria-label='delete'
                onClick={() =>
                  setEditor({
                    ...editor,
                    physicalFields: editor.physicalFields.filter((field) => field.uuid !== pf.uuid)
                  })
                }>
                <DeleteIcon />
              </ConfirmIconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default AssetsTable;
