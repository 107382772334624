import { buildApiCallAction } from './ActionUtils';
import UsersApi from '../api/UsersApi';
import {
  FETCH_USERS_PAGE,
  FETCH_USER,
  SET_USERS_FILTERS,
  CREATE_USER,
  PATCH_USER,
  DELETE_USER,
  SEARCH_USER,
  FETCH_USER_TENANTS,
  ADD_USER_TENANT,
  REMOVE_USER_TENANT,
  FETCH_DEFAULT_TENANT,
  CHANGE_DEFAULT_TENANT,
  FETCH_USER_TENANT_PERMISSIONS,
  MODIFY_USER_TENANT_PERMISSIONS,
  FETCH_TEAM_USER_SETTINGS,
  MODIFY_TEAM_USER_SETTINGS,
  FETCH_USERS_IN_CURRENT_TENANT,
  CLEAR_USERS_FILTERS
} from '../constants/ActionTypes';

const api = new UsersApi();

export const fetchUsersPage = (page, size, filters = {}) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getUsers({ page, size, ...filters }),
    actionType: FETCH_USERS_PAGE
  });

export const searchUsers = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getUsers(params),
    actionType: SEARCH_USER
  });

export const fetchUsers = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getUsers(params),
    actionType: FETCH_USERS_PAGE
  });

export const fetchUser = (userUuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getUser(userUuid),
    actionType: FETCH_USER
  });

export const setUsersFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: SET_USERS_FILTERS, payload: filters });
};

export const clearUsersFilters = (filters) => (dispatch, getState) => {
  dispatch({ type: CLEAR_USERS_FILTERS, payload: filters });
};

export const createUser = (user) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postUser(user),
    actionType: CREATE_USER
  });

export const patchUser = (userPatches) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.patchUser(userPatches),
    actionType: PATCH_USER
  });

export const deleteUser = (user) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteUser(user),
    payload: user,
    actionType: DELETE_USER
  });

export const fetchUserTenants = (userUuid, params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getUserTenants(userUuid, params),
    actionType: FETCH_USER_TENANTS,
    payload: (data) => ({ userUuid, ...data })
  });

export const addUserToTenant = (userUuid, tenant) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putUserToTenant(userUuid, tenant.uuid),
    actionType: ADD_USER_TENANT,
    payload: (data) => ({ userUuid, tenant })
  });

export const removeUserFromTenant = (userUuid, tenant) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteUserFromTenant(userUuid, tenant.uuid),
    actionType: REMOVE_USER_TENANT,
    payload: (data) => ({ userUuid, tenant, tenantUuid: tenant.uuid })
  });

export const fetchUserDefaultTenant = (userUuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getUserDefaultTenant(userUuid),
    actionType: FETCH_DEFAULT_TENANT,
    payload: (data) => ({ userUuid, tenant: data })
  });

export const changeUserDefaultTenant = (userUuid, tenant) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putUserDefaultTenant(userUuid, tenant.uuid),
    actionType: CHANGE_DEFAULT_TENANT,
    payload: (data) => ({ userUuid, tenant })
  });

export const fetchTenantPermissions = ({ userUuid, tenantUuid }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTenantPermissions({ userUuid, tenantUuid }),
    actionType: FETCH_USER_TENANT_PERMISSIONS
  });

export const modifyTenantPermissions = ({ userUuid, tenantUuid, permissions }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putTenantPermissions({ userUuid, tenantUuid, permissions }),
    actionType: MODIFY_USER_TENANT_PERMISSIONS
  });

export const fetchTeamUserSettings = ({ userUuid, teamSettings }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTeamSettings(userUuid),
    actionType: FETCH_TEAM_USER_SETTINGS
  });

export const modifyTeamUserSettings = ({ userUuid, teamSettings }) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putTeamSettings(userUuid, teamSettings),
    actionType: MODIFY_TEAM_USER_SETTINGS
  });

export const fetchUsersInCurrentTenant = (search) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getUsersInCurrentTenant(search),
    actionType: FETCH_USERS_IN_CURRENT_TENANT
  });
