import React from 'react';
import { withTranslation } from 'react-i18next';

import KVDefinitionList from '../../../../../components/definition-list/KVDefinitionList';
import CustomPropertiesView from '../../../../../components/additional_properties/CustomPropertiesView';

import ResourceTypes from '../../../../../constants/ResourceTypes';
import CardDescription from 'components/carddescription/CardDescription';
import { getSystemName } from 'constants/SystemTechnologyTypes';

const GeneralSection = ({ physicalField, t }) => {
  return (
    <>
      <CardDescription>{physicalField.description}</CardDescription>
      <div className='dls-wrapper'>
        <KVDefinitionList
          header={t('physicalFields.detail.general.title')}
          item={{
            ...{
              [t('physicalFields.detail.general.uuid')]: physicalField.uuid,
              [t('physicalFields.detail.general.name')]: physicalField.name,
              [t('physicalFields.detail.general.type')]: physicalField.type,
              [t('physicalFields.detail.general.position')]: physicalField.ordinalPosition,
              [t('physicalFields.detail.general.system')]: getSystemName(
                physicalField.physicalEntity.system
              ),
              [t('physicalFields.detail.general.physicalEntity')]: physicalField.physicalEntity.name
            },
            ...(physicalField.creationDate && {
              [t('physicalFields.detail.general.creationDate')]: new Date(
                physicalField.creationDate
              ).toLocaleDateString()
            }),
            ...(physicalField.modificationDate && {
              [t('physicalFields.detail.general.modificationDate')]: new Date(
                physicalField.modificationDate
              ).toLocaleDateString()
            })
          }}
        />
        <div style={{ marginTop: '1em', marginBottom: '1em', marginRight: '8px' }}>
          <CustomPropertiesView
            additionalProperties={physicalField.additionalProperties}
            resourceType={ResourceTypes.PHYSICAL_FIELD}
          />
        </div>
      </div>
    </>
  );
};

export default withTranslation()(GeneralSection);
