import QueryStatementsApi from 'api/QueryStatementsApi';
import QueryBucketsApi from 'api/QueryBucketsAPI';
import AbstractImporter from './AbstractImporter';

export default class ImporterQueryParser extends AbstractImporter {
  static isSupported(object) {
    return object.queryStatement || object.queryBucket;
  }

  async importObject(object, resolveUuidOnly = false) {
    if (object.queryStatement) {
      return this.importQueryStatement(object.queryStatement, resolveUuidOnly);
    } else if (object.queryBucket) {
      return this.importQueryBucket(object.queryBucket, resolveUuidOnly);
    }
    throw new Error('Object type not supported by ImporterQueryParser');
  }

  async importQueryStatement(queryStatement, resolveUuidOnly = false) {
    let api = new QueryStatementsApi();

    let reconciledQueryStatement = queryStatement;
    if (!resolveUuidOnly) {
      reconciledQueryStatement = {
        ...queryStatement,
        queryBucket: await super.importObject({ queryBucket: queryStatement.queryBucket }, true)
      };
    }
    return this.importTemplate({
      object: reconciledQueryStatement,
      objectName: null,
      objectIdentifier: 'uuid',
      api: api,
      post: api.postQueryStatement,
      put: api.putQueryStatement,
      delet: api.deleteQueryStatement,
      resolveUuidOnly
    });
  }

  async importQueryBucket(queryBucket, resolveUuidOnly = false) {
    let api = new QueryBucketsApi();
    let reconciledQueryBucket = queryBucket;

    if (!resolveUuidOnly) {
      reconciledQueryBucket = {
        ...queryBucket,
        systems: queryBucket.systems
          ? Promise.all(
              queryBucket.systems.map((sys) => {
                return super.importObject({ system: sys });
              })
            )
          : null
      };
    }

    return this.importTemplate({
      object: reconciledQueryBucket,
      objectName: 'name',
      objectIdentifier: 'uuid',
      api,
      post: api.postQueryBucket,
      put: api.putQueryBucket,
      delet: api.deleteQueryBucket,
      search: (params) => api.getAllQueryBuckets({ ...params }),
      resolveUuidOnly
    });
  }
}
