import React from 'react';
import ConfirmButton from '../../../components/confirmbutton/ConfirmButton';
import { deleteSystem, modifySystem } from '../../../actions/SystemsActions';
import { setEntitiesFilters } from 'actions/PhysicalEntitiesActions';
import { setRoutinesFilters } from 'actions/RoutinesActions';
import { setPhysicalConstraintsFilters } from 'actions/PhysicalConstraintsActions';
import { connect } from 'react-redux';
import { CardHeader, Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import Authorize from '../../../components/permissions/Authorize';
import Permissions from '../../../constants/Permissions';
import SystemEditorModal from '../commons/SystemEditorModal';
import OpenCloseHandler from '../../../components/openclosehandler/OpenCloseHandler';
import ResourceTypes from '../../../constants/ResourceTypes';
import CustomPropertiesView from '../../../components/additional_properties/CustomPropertiesView';
import HistoryButtonLink from '../../history/commons/HistoryButtonLink';
import HistoryTargetTypes from '../../history/commons/HistoryTargetTypes';
import { compose } from 'redux';
import CardDescription from 'components/carddescription/CardDescription';
import { getIconShort, getTechnologyConfig } from 'constants/SystemTechnologyTypes';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';
const styles = {
  title: {
    marginBottom: '8px'
  },
  card: {
    padding: '8px',
    margin: '8px 0 8px 0'
  }
};

function SystemDetailCard({
  system,
  deleteSystem,
  modifySystem,
  setEntitiesFilters,
  setRoutinesFilters,
  setPhysicalConstraintsFilters,
  history,

  t
}) {
  const handleShowEntities = () => {
    setEntitiesFilters({ system });
    history.push('/physicalentities');
  };

  const handleShowRoutines = () => {
    setRoutinesFilters({ system: system });
    history.push('/routines');
  };

  const handleShowConstraints = () => {
    setPhysicalConstraintsFilters({ system: system });
    history.push('/physical-constraints');
  };

  return system ? (
    <Card sx={styles.card}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingLeft: '16px'
        }}>
        {getIconShort(getTechnologyConfig(system?.technology), { width: '3em', height: '3em' })}
        <CardHeader title={system.name} subheader={system.subtype} />
      </div>
      <CardContent>
        <h4>Description</h4>
        <CardDescription>{system.description}</CardDescription>
        <CustomPropertiesView
          resourceType={ResourceTypes.SYSTEM}
          additionalProperties={system.additionalProperties}
        />
      </CardContent>
      <CardActions>
        <Authorize hasPermissions={[Permissions.SYSTEMS_EDITOR]}>
          <ModifySystemButton {...{ modifySystem, system, t }} />
        </Authorize>
        <Authorize hasPermissions={[Permissions.SYSTEMS_ADMIN]}>
          <ConfirmButton
            children={t('systems.actions.delete')}
            message={`Delete system ${system.name}?`}
            onClick={() => deleteSystem(system).then(() => history.push('/systems'))}
          />
        </Authorize>
        <Button component={Link} to={`/systems/${system?.uuid}/logins`}>
          {t('systems.actions.openLogins')}
        </Button>
        <Button onClick={handleShowEntities}>{t('systems.actions.openEntities')}</Button>
        <Button children={t('systems.actions.openRoutines')} onClick={handleShowRoutines}></Button>
        <Button
          children={t('systems.actions.openPhysicalConstraints')}
          onClick={handleShowConstraints}
        />
        <HistoryButtonLink
          targetType={HistoryTargetTypes.SYSTEM}
          targetIdentifier={system?.uuid}></HistoryButtonLink>
        <div style={{ marginLeft: 'auto' }}>
          <WebActionsButton resource={system} page={TargetPages.SYSTEM_DETAIL_PAGE} />
        </div>
      </CardActions>
    </Card>
  ) : null;
}

const ModifySystemButton = ({ system, modifySystem, t }) => (
  <OpenCloseHandler>
    {({ isOpen, onToggleOpen }) => (
      <React.Fragment>
        <Button onClick={onToggleOpen}>{t('systems.actions.modify')}</Button>
        {isOpen ? (
          <SystemEditorModal
            title={`Modify system: ${system.name}`}
            open={isOpen}
            system={system}
            onSubmit={(sys) => {
              onToggleOpen();
              modifySystem(sys);
            }}
            onClose={onToggleOpen}
          />
        ) : null}
      </React.Fragment>
    )}
  </OpenCloseHandler>
);
function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  modifySystem,
  deleteSystem,
  setEntitiesFilters,
  setRoutinesFilters,
  setPhysicalConstraintsFilters
};
export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SystemDetailCard);
