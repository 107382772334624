import Tracking from 'components/engagement/Tracking';
import EngagementActions from 'constants/EngagementActions';
import EngagementCategories from 'constants/EngagementCategories';
import React from 'react';
import WebHooksList from './WebHooksList';
import WebHookFAB from './WebHookFAB';
import Breadcrumbs from 'pages/sidebar/Navigation';
import { t } from 'i18next';

const WebHooksListPage = ({ _props }) => {
  return (
    <>
      <Breadcrumbs
        elements={[{ text: t('navbar.settings'), to: '/settings' }, { text: t('navbar.webHooks') }]}
      />
      <Tracking
        info={{
          pageTitle: 'Web Hooks',
          category: EngagementCategories.SETTINGS,
          action: EngagementActions.LIST
        }}
      />
      <WebHooksList />
      <WebHookFAB />
    </>
  );
};

export default WebHooksListPage;
