import { BlindataApi } from 'api/ApiUtils';

export default class QualityHighlightsApi extends BlindataApi {

  getPhysicalEntitiesWithQualityHighlights(params) {
    return this.get(`/api/v1/data-quality/highlights/physical-entities`, params);
  }

  getPhysicalFieldsWithQualityHighlights(params) {
    return this.get(`/api/v1/data-quality/highlights/physical-fields`, params);
  }
}