import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import ToolbarActions from '../sidebar/ToolbarActions';

const ContractsNavbar = ({ location: { pathname } }) => (
  <>
    <ToolbarActions>
      <Button
        color={pathname === '/contracts/definitions' ? 'primary' : 'grey'}
        children='Definitions'
        component={Link}
        to='/contracts/definitions'
      />

      <Button
        color={pathname === '/contracts/configurations' ? 'primary' : 'grey'}
        children='Configurations'
        component={Link}
        to='/contracts/configurations'
      />
      <Button
        color={pathname === '/contracts/templates' ? 'primary' : 'grey'}
        children='Templates'
        component={Link}
        to='/contracts/templates'
      />
    </ToolbarActions>
  </>
);

export default withRouter(ContractsNavbar);
