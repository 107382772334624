import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { searchLogicalFields } from '../../../actions/LogicalFieldsActions';
import ItemAutoCompleteModal from '../../../components/itemselector/ItemAutoCompleteModal';

const LogicalFieldSearchModal = ({
  title,
  searchLogicalFields,
  logicalFields,
  open,
  onCancel,
  onSubmit,
  t
}) => (
  <ItemAutoCompleteModal
    title={title || t('logicalFields.searchTitle')}
    itemName={t('logicalFields.label')}
    open={open}
    keyProperty={'uuid'}
    nameProperty={'name'}
    categoryProperties={['dataCategory', 'name']}
    labelSeparator=': '
    values={Object.values(logicalFields)}
    onSearch={(searchText) => searchLogicalFields({ searchText })}
    handleCancel={onCancel}
    handleSubmit={onSubmit}
  />
);

const mapStateToProps = (state, props) => ({
  logicalFields: state.logicalfields.content
});

LogicalFieldSearchModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default connect(mapStateToProps, { searchLogicalFields })(
  withTranslation()(LogicalFieldSearchModal)
);
