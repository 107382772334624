import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  fetchProbeDefinition,
  modifyProbeDefinition,
  deleteLogicalProbeDefinition,
  fetchProbeDefinitionByRoot
} from 'actions/quality/probes/ProbesDefinitionsActions';

import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Button,
  Divider
} from '@mui/material';

import Breadcrumbs from 'pages/sidebar/Navigation';
import ConfirmButton from 'components/confirmbutton/ConfirmButton';
import QualityProbesDefinitionModal from 'pages/quality/probes/definitions/common/QualityProbesDefinitionModal';
import QualityProbesDefinitionDetailSection from 'pages/quality/probes/definitions/detail/QualityProbesDefinitionDetailSection';
import QualityProbesDefinitionQuerySection from 'pages/quality/probes/definitions/detail/QualityProbesDefinitionQuerySection';
import QualityProbesDefinitionVersionsSection from 'pages/quality/probes/definitions/detail/QualityProbesDefinitionVersionsSection';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import VariationTypeDecorator from 'pages/workflows/proposals/commons/VariationTypeDecorator';

import ProbeTestButton from 'pages/quality/probes/definitions/common/ProbeTestButton';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import WebActionsButton from 'pages/settings/webactions/commons/WebActionsButton';
import TargetPages from 'pages/settings/webactions/commons/TargetPages';
const QualityProbesDefinitionDetailPage = ({
  probeRootUuid,
  versionSequenceId,
  probe,
  associatedCheck,
  fetchProbeDefinitionByRoot,
  fetchProbeDefinition,
  modifyProbeDefinition,
  deleteProbeDefinition
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (versionSequenceId) {
      fetchProbeDefinition(probeRootUuid, versionSequenceId);
    } else {
      fetchProbeDefinitionByRoot(probeRootUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [probeRootUuid, versionSequenceId]);

  return probe ? (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.qualityProbes'), to: '/data-quality/probes/projects' },
          { text: probe.project?.name, to: `/data-quality/probes/projects/${probe.project?.uuid}` },
          { text: probe.name }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('quality.probes.definitions.detailPageHeader', {
            probeName: probe.name
          }),
          category: EngagementCategories.DATA_QUALITY_PROBES,
          action: EngagementActions.DETAIL
        }}
      />
      <Grid container direction='row' alignContent='center'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>
            <Typography style={{ marginBottom: '8px' }} variant='h4'>
              {t('quality.probes.definitions.detailPageHeader', {
                probeName: probe.name
              })}
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={`PROBE:${probe.uuid}`}
            pageTitle={t('quality.probes.definitions.detailPageHeader', {
              probeName: probe.name
            })}
          />
        </Grid>
      </Grid>

      <Card>
        <CardHeader
          action={<VariationTypeDecorator>{`Version #${probe.version}`}</VariationTypeDecorator>}
          title={probe.name}
          subheader={
            <div>
              <div>{probe.type}</div>
              <div>Created by: {probe.userDisplayName}</div>
            </div>
          }
        />

        <CardContent>
          <Grid container direction='column' spacing={3}>
            <Grid item xs={12}>
              <QualityProbesDefinitionDetailSection probe={probe} />
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item xs={12} container direction='row' spacing={3}>
              <Grid item xs style={{ overflow: 'auto' }}>
                <QualityProbesDefinitionQuerySection probe={probe} />
              </Grid>
              <Grid item>
                <Divider orientation='vertical' />
              </Grid>
              <Grid item xs={4}>
                <QualityProbesDefinitionVersionsSection currentProbeDefinition={probe} />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <ProbeTestButton probe={probe}></ProbeTestButton>
          {probe.lastVersion && (
            <Authorize hasPermissions={[Permissions.PROBES_EDITOR]}>
              <>
                <ModifyButton
                  project={probe.project}
                  probe={probe}
                  associatedCheck={associatedCheck}
                  modifyProbeDefinition={modifyProbeDefinition}
                />
                <DeleteButton probe={probe} deleteProbeDefinition={deleteProbeDefinition} />
              </>
            </Authorize>
          )}
          <div style={{ marginLeft: 'auto' }}>
            <WebActionsButton
              resource={probe}
              page={TargetPages.QUALITY_PROBE_DEFINITION_DETAIL_PAGE}
            />
          </div>
        </CardActions>
      </Card>
    </>
  ) : null;
};

const ModifyButton = ({ probe, associatedCheck, modifyProbeDefinition }) => {
  let { t } = useTranslation();
  const history = useHistory();

  let [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>{t('commons.actions.modify')}</Button>
      {open && (
        <QualityProbesDefinitionModal
          title='Modify Probe Definition'
          open={open}
          onClose={() => setOpen(false)}
          probeDefinition={probe}
          associatedCheck={associatedCheck}
          onSubmit={(probeDefinition) => {
            modifyProbeDefinition(probeDefinition).then((probeDefinition) => {
              history.push(
                `/data-quality/probes/definitions/${probeDefinition.rootUuid}/versions/${probeDefinition.sequenceId}`
              );
            });
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

const DeleteButton = ({ probe, deleteProbeDefinition }) => {
  let { t } = useTranslation();
  const history = useHistory();

  return (
    <ConfirmButton
      onClick={() => {
        deleteProbeDefinition(probe).then(() => history.push('/data-quality/probes/projects'));
      }}>
      {t('commons.actions.delete')}
    </ConfirmButton>
  );
};

const mapStateToProps = (state, props) => ({
  probeRootUuid: props.match.params['probeRootUuid'],
  versionSequenceId: props.match.params['sequenceId'],
  probe: state.quality.qualityProbes.definitions.currentProbeDefinition,
  associatedCheck: state.quality.qualityChecks.currentPage.content[0]
});

const mapDispatchToProps = {
  fetchProbeDefinition,
  fetchProbeDefinitionByRoot,
  modifyProbeDefinition,
  deleteProbeDefinition: deleteLogicalProbeDefinition
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityProbesDefinitionDetailPage);
