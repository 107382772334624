import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { connect } from 'react-redux';
import { exportTaskAssignments } from 'actions/TaskAssignmentsActions';

function TaskAssignmentsExportButton({
  exportTaskAssignments,
  size,
  taskUuid,
  processingUuid,
  dataActorUuid,
  tooltipTitle
}) {
  return (
    <Tooltip title={tooltipTitle || 'Export CSV'}>
      <IconButton
        size='small'
        onClick={() => exportTaskAssignments({ size, taskUuid, processingUuid, dataActorUuid })}>
        <GetApp />
      </IconButton>
    </Tooltip>
  );
}

export default connect(null, { exportTaskAssignments })(TaskAssignmentsExportButton);
