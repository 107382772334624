import React, { useEffect } from 'react';
import PaginatedPanel from 'components/pagination/PaginatedPanel';
import { fetchQueryBucketsPage } from 'actions/QueryBucketsActions';
import QueryBucketsListItem from './QueryBucketsListItem';
import { connect } from 'react-redux';

const QueryBucketList = ({ filters, currentPage, fetchQueryBucketsPage }) => {
  useEffect(() => {
    fetchQueryBucketsPage({ page: 0, ...filters }).then(
      (response) => response && response.content.length > 0
    );
  }, [filters, fetchQueryBucketsPage]);
  return (
    <PaginatedPanel
      currentPage={currentPage}
      onPageSelection={(page) => fetchQueryBucketsPage({ page, ...filters })}>
      {currentPage.content.map((queryBucket) => (
        <QueryBucketsListItem key={queryBucket.uuid} queryBucket={queryBucket} />
      ))}
    </PaginatedPanel>
  );
};

const mapStateToProps = (state) => ({
  currentPage: state.dataflows.querybuckets.currentPage,
  filters: state.dataflows.querybuckets.filters.content
});

export default connect(mapStateToProps, {
  fetchQueryBucketsPage
})(QueryBucketList);
