import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { ChipItem, ChipWrapper } from 'components/chiplist/ChipList';
import Permissions from 'constants/Permissions';

function PermissionChipWrapperSelectModal({ onChange, title, permissions, onDelete }) {
  const [addPermission, setAddPermission] = useState(false);
  return (
    <>
      <ChipWrapper onRequestAdd={() => setAddPermission(true)}>
        {(permissions || []).map((p, index) => (
          <ChipItem key={index} id={index} label={p} onRequestDelete={() => onDelete(p, index)} />
        ))}
      </ChipWrapper>
      <PermissionTypeSelectDialog
        open={addPermission}
        onClose={() => setAddPermission(false)}
        title={title}
        onChange={onChange}
      />
    </>
  );
}

function PermissionTypeSelectDialog({ open, onClose, onChange, title }) {
  const [permission, setPermission] = useState(null);
  const { t } = useTranslation();
  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormControl variant='standard' fullWidth={true}>
          <InputLabel id='demo-simple-select-label'>Required Permission</InputLabel>
          <Select
            defaultValue={null}
            variant='standard'
            value={permission}
            onChange={(event) => {
              setPermission(event.target.value);
            }}>
            {Object.values(Permissions)
              .sort()
              .map((p) => (
                <MenuItem value={p}>{p}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            setPermission(null);
          }}>
          {t('commons.actions.cancel')}
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onChange(permission);
            setPermission(null);
            onClose();
          }}
          disabled={!permission}>
          {t('commons.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default PermissionChipWrapperSelectModal;
