import { BlindataApi } from './ApiUtils';

const NOTIFICATION_SETTINGS_URL = '/api/v1/social/notifications-settings';

export default class NotificationsSettingsApi extends BlindataApi {
  getUserNotificationsSettings() {
    return this.get(`${NOTIFICATION_SETTINGS_URL}/me`);
  }
  putUserNotificationsSettings(notificationsSettings) {
    return this.put(`${NOTIFICATION_SETTINGS_URL}/me`, notificationsSettings);
  }
}
