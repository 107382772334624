import React from 'react';
import { Table, TableHead, TableBody, TableRow, Tooltip } from '@mui/material';
import TableCell from 'components/tables/GenericTableCell';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import QueryStatementsCardActions from './QueryStatementsCardActions';
import { useHistory } from 'react-router';
import QueryStatementStatusDecorator from '../commons/QueryStatementStatusDecorator';
import { formatDate } from 'pages/history/commons/HistoryRecordTable';

const QueryStatementsCardTable = ({ queryStatements, onRefresh }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Table size='small' style={{ overflowX: 'auto' }}>
      <TableHead>
        <TableRow>
          <TableCell hiddenSm align='left'>
            {t('querystatements.table.schema')}
          </TableCell>
          <TableCell align='left'>{t('querystatements.table.name')}</TableCell>
          <TableCell align='left'>{t('querystatements.table.elaborationStatus')}</TableCell>
          <TableCell hiddenMd hiddenSm align='left'>
            {t('querystatements.table.text')}
          </TableCell>
          <TableCell hiddenMd hiddenSm align='center'>
            {t('querystatements.table.type')}
          </TableCell>
          <TableCell hiddenMd hiddenSm align='right'>
            {t('querystatements.table.creationDate')}
          </TableCell>
          <TableCell align='right' style={{ width: 100 }}>
            {t('querystatements.table.actions')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {queryStatements.map((queryStatement) => (
          <TableRow
            onClick={() =>
              history.push(
                `/dataflows/queryparser/buckets/${queryStatement.queryBucket.uuid}/statements/${queryStatement.uuid}`
              )
            }
            hover={true}
            style={{ cursor: 'pointer' }}
            key={queryStatement.uuid}>
            <TableCell hiddenSm align='left'>
              {queryStatement.schema || ''}
            </TableCell>
            <TableCell align='left'>{queryStatement.name}</TableCell>
            <TableCell align='left'>
              <QueryStatementStatusDecorator queryStatement={queryStatement} />
            </TableCell>
            <TableCell hiddenMd hiddenSm align='left' style={{ flexGrow: 1 }}>
              <Tooltip title={queryStatement.queryText} placement='bottom'>
                <p>{_.truncate(queryStatement.queryText, { length: 90, omission: '...' })}</p>
              </Tooltip>
            </TableCell>
            <TableCell hiddenMd hiddenSm align='center'>
              {queryStatement.queryType || ''}
            </TableCell>
            <TableCell hiddenMd hiddenSm align='right'>
              {formatDate(queryStatement.createdAt)}
            </TableCell>
            <TableCell align='right'>
              <QueryStatementsCardActions queryStatement={queryStatement} onRefresh={onRefresh} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default QueryStatementsCardTable;
