import { Card, CardContent, CardHeader, CircularProgress } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MarkerType } from 'reactflow';
import 'reactflow/dist/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataProductMesh } from 'actions/DataProductsActions';
import _ from 'lodash';
import { DataProductDependenciesGraph } from './DataProductDependenciesGraph';

const computeStyledGraph = ({ nodes, edges }) => {
  const styledNodes = nodes.map((node) => {
    return {
      id: node.identifier,
      data: _.clone(node),
      type: 'custom'
    };
  });

  const styledEdges = edges.map((e) => ({
    id: `${e.fromPort}->${e.toPort}`,
    source: e.fromProduct,
    target: e.toProduct,
    sourceHandle: `from.${e.fromPort}`,
    targetHandle: `to.${e.toPort}`,
    type: 'default',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 15,
      height: 15
    },
    style: {
      strokeWidth: 1
    }
  }));
  return { nodes: styledNodes, edges: styledEdges };
};

const DataProductDependenciesPanel = ({ dataProduct }) => {
  const dispatch = useDispatch();
  const mesh = useSelector((state) => state.dataproducts.mesh[dataProduct.uuid]);

  const parentRef = useRef(null);
  const [size, setSize] = useState(null);

  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (parentRef.current) {
      let height = parentRef.current.offsetHeight;
      let width = parentRef.current.offsetWidth;
      setSize({ height, width });
    }
  }, [parentRef, setSize]);

  useEffect(() => {
    dispatch(fetchDataProductMesh(dataProduct.uuid));
  }, [dataProduct, dispatch]);

  const styledGraph = useMemo(() => {
    return mesh ? computeStyledGraph({ nodes: mesh.nodes, edges: mesh.edges }) : null;
  }, [mesh]);

  return (
    <Card ref={parentRef}>
      <CardHeader title='Data Product Dependencies' />
      {!size || !styledGraph ? (
        <CircularProgress></CircularProgress>
      ) : (
        <CardContent style={{ overflowX: 'auto', height: 800, width: size.width }} ref={parentRef}>
          <DataProductDependenciesGraph
            key={dataProduct.uuid}
            initialNodes={styledGraph.nodes}
            initialEdges={styledGraph.edges}></DataProductDependenciesGraph>
        </CardContent>
      )}
    </Card>
  );
};

export default DataProductDependenciesPanel;
