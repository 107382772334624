import {
  FETCH_TASK_GRANTS,
  CREATE_TASK_GRANT,
  DELETE_TASK_GRANT
} from '../constants/ActionTypes';

const taskgrants = (state = {content:[],loaded:false}, action) => {
  switch (action.type) {
    case FETCH_TASK_GRANTS:
      return {
        ...state,
        loaded:true,
        content: action.payload.content
      }
    case CREATE_TASK_GRANT:
      return {
        ...state,
        content: state.content.concat([action.payload])
      }
    case DELETE_TASK_GRANT:
      return {
        ...state,
        content: state.content.filter(tg=>tg.uuid!==action.payload.uuid)
      }
    default:
      return state
  }
}

export default taskgrants;
