import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setQualityDashboardsOverallFilters } from 'actions/QualityDashboardsActions';
import { Typography, Grid, Button } from '@mui/material';
import Breadcrumbs from 'pages/sidebar/Navigation';
import DatePicker, { hourAdjustments } from 'components/pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import QualityChecksOverallCountsCard from 'pages/quality/kqis/dashboards/QualityChecksOverallCountsCard';
import QualityChecksTimelineOverallCard from 'pages/quality/kqis/dashboards/QualityChecksTimelineOverallCard';
import QualityChecksStatsCard from 'pages/quality/kqis/dashboards/QualityChecksStatsCard';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import FavoritesWidget from 'pages/social/favorites/widget/FavoritesWidget';
import QualityResultsExportButton from '../quality_checks/commons/QualityResultExportButton';
function DataQualityDasboardsPage({ filters, setQualityDashboardsOverallFilters }) {
  let { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.kqis'), to: '/data-quality/kqis/suites' },
          { text: t('navbar.dashboard') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('quality.dashboard.header'),
          category: EngagementCategories.DATA_QUALITY,
          action: EngagementActions.DASHBOARDS
        }}
      />
      <Grid container spacing={1} alignItems='self-end'>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant='h4'>{t('quality.dashboard.header')}</Typography>
        </Grid>
        <Grid item>
          <FavoritesWidget
            itemIdentifier={'page:dataquality-dashboard'}
            pageTitle={t('quality.dashboard.header')}
          />
        </Grid>
        <Grid item>
          <QualityResultsExportButton size='medium' fromDashboard />
        </Grid>
        <Grid item>
          <OverallFilters filters={filters} setFilters={setQualityDashboardsOverallFilters} />
        </Grid>
      </Grid>
      <QualityChecksOverallCountsCard />
      <QualityChecksTimelineOverallCard />
      <QualityChecksStatsCard />
    </>
  );
}
const OverallFilters = ({ filters, setFilters }) => {
  const { t } = useTranslation();

  const [editor, setEditor] = useState(filters);

  return (
    <Grid container spacing={1} alignItems='flex-end'>
      <Grid item xs={12} sm='auto'>
        <DatePicker
          clearable={true}
          label={'From'}
          hintText={'Start Date'}
          hourAdjustment={hourAdjustments.startOfDay}
          value={editor.startedAtGte}
          maxDate={editor?.startedAtLt}
          onChange={(date) => setEditor({ ...editor, startedAtGte: date })}
        />
      </Grid>
      <Grid item xs={12} sm='auto'>
        <DatePicker
          clearable={true}
          label={'To'}
          hintText={'Start Date'}
          hourAdjustment={hourAdjustments.endOfDay}
          value={editor.startedAtLt}
          minDate={editor?.startedAtGte}
          onChange={(date) => setEditor({ ...editor, startedAtLt: date })}
        />
      </Grid>
      <Grid item xs={12} sm='auto'>
        <Button color='primary' variant='outlined' onClick={() => setFilters(editor)}>
          {t('commons.actions.apply')}
        </Button>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state, props) => ({
  filters: state.quality.qualityDashboards.overall.filters.content
});

export default connect(mapStateToProps, { setQualityDashboardsOverallFilters })(
  DataQualityDasboardsPage
);
