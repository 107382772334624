import { buildApiCallAction } from './ActionUtils';
import LogicalRelationsApi from '../api/LogicalRelationsApi';
import {
  FETCH_LOGICAL_RELATIONS_PAGE,
  FETCH_LOGICAL_RELATION,
  CREATE_LOGICAL_RELATION,
  MODIFY_LOGICAL_RELATION,
  DELETE_LOGICAL_RELATION,
  EXPORT_LOGICAL_RELATIONS
} from '../constants/ActionTypes';

const api = new LogicalRelationsApi();

export const fetchLogicalRelationsPage = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => {
      return api.getAllLogicalRelations(params);
    },
    actionType: FETCH_LOGICAL_RELATIONS_PAGE,
    payload: (response) => ({ ...response, params })
  });

export const fetchLogicalRelation = (uuid) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getLogicalRelation(uuid),
    actionType: FETCH_LOGICAL_RELATION
  });
export const createLogicalRelation = (logicalRelation) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.postLogicalRelation(logicalRelation),
    actionType: CREATE_LOGICAL_RELATION
  });

export const modifyLogicalRelation = (logicalRelation) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putLogicalRelation(logicalRelation),
    actionType: MODIFY_LOGICAL_RELATION
  });

export const deleteLogicalRelation = (logicalRelation) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.deleteLogicalRelation(logicalRelation),
    actionType: DELETE_LOGICAL_RELATION,
    payload: logicalRelation
  });

export const fetchLogicalRelationType = (search) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getLogicalRelationTypes(search),
    actionType: 'FETCH_LOGICAL_RELATIONS_TYPE'
  });

export const exportLogicalRelations = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.exportLogicalRelations(params),
    actionType: EXPORT_LOGICAL_RELATIONS
  });
