import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableHead,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Tooltip
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import StewardshipAssignmentsModal from './common/StewardshipAssignmentsModal';
import React, { useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';

import {
  fetchRole,
  fetchAssignmentsPage,
  createAssignment,
  deleteAssignment
} from '../../../actions/StewardshipActions';
import { connect } from 'react-redux';
import Authorize from '../../../components/permissions/Authorize';
import ConfirmIconButton from '../../../components/confirmbutton/ConfirmIconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Permissions from '../../../constants/Permissions';
import StewardshipAssignmentsFilters from './common/StewardshipAssignmentsFilters';
import StewardshipAssingmentExport from './common/StewardshipAssignmentExport';
import UserDisplayName from '../../settings/users/commons/UserDisplayName';

function StewardshipAssignmentsCard({
  user,
  stewardshipRoleUuid,
  stewardshipRole,
  onPageSelection,
  currentPage,
  onFetchStewardshipRole,
  createAssignment,
  deleteAssignment
}) {
  useEffect(() => {
    onPageSelection({
      page: 0,
      size: currentPage.size,
      stewardshipRoleUuid: stewardshipRoleUuid
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
  }, [onPageSelection, stewardshipRoleUuid, user, filters]);

  useEffect(() => {
    stewardshipRoleUuid &&
      onFetchStewardshipRole(stewardshipRoleUuid).then(
        setFilters({ stewardshipRole: stewardshipRole })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFetchStewardshipRole, stewardshipRoleUuid]);

  const [filters, setFilters] = useState({
    user: null,
    stewardshipRole: stewardshipRole && stewardshipRole
  });
  const [open, setOpen] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  return (
    <Card>
      <CardHeader
        title={'Assignments'}
        action={
          <Grid container>
            <Grid item>
              <Authorize hasPermissions={[Permissions.STEWARDSHIP_ADMIN]}>
                <Tooltip title='Create assignment'>
                  <IconButton
                    aria-label='create assignment'
                    color='default'
                    size={'small'}
                    onClick={() => setOpen(true)}>
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
              </Authorize>
            </Grid>
            <Grid item>
              <Tooltip title='Filters'>
                <IconButton
                  color={filters?.user || filters?.stewardshipRole ? 'primary' : 'grey'}
                  size='small'
                  onClick={() => setOpenFilters(true)}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Grid item>
                <StewardshipAssingmentExport
                  filters={filters}
                  onClick={() => null}></StewardshipAssingmentExport>
              </Grid>
            </Grid>
          </Grid>
        }></CardHeader>
      <CardContent style={{ overflowX: 'auto' }}>
        <Table size='small' style={{ overflowX: 'auto' }}>
          <TableHead>
            <TableRow selected='false'>
              <TableCell>User</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(currentPage.content || []).map((assignment) => (
              <TableRow key={assignment.sequenceId}>
                <TableCell>{<UserDisplayName user={assignment.user}></UserDisplayName>}</TableCell>
                <TableCell>{assignment.stewardshipRole.name}</TableCell>
                <TableCell>{formatDate(assignment.createdAt)}</TableCell>
                <TableCell>
                  <Authorize hasPermissions={[Permissions.STEWARDSHIP_ADMIN]}>
                    <ConfirmIconButton
                      size='small'
                      message={`Do you want to delete this assignment?`}
                      onClick={() => {
                        deleteAssignment(assignment.sequenceId);
                      }}>
                      <DeleteIcon></DeleteIcon>
                    </ConfirmIconButton>
                  </Authorize>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component='div'
          style={{ overflowX: 'hidden' }}
          count={currentPage.totalElements}
          rowsPerPage={currentPage.size}
          page={currentPage.number}
          onPageChange={(event, page) =>
            onPageSelection({
              page,
              size: currentPage.size,
              stewardshipRoleUuid: filters?.stewardshipRole?.uuid,
              userUuid: filters?.user?.uuid
            })
          }
          onRowsPerPageChange={(event) => {
            onPageSelection({
              page: currentPage.number,
              size: event.target.value,
              stewardshipRoleUuid: filters?.stewardshipRole.uuid,
              userUuid: filters?.user?.uuid
            });
          }}></TablePagination>
      </CardContent>
      <StewardshipAssignmentsFilters
        open={openFilters}
        setOpen={setOpenFilters}
        setFilters={setFilters}
        filters={filters}
        onSubmit={onPageSelection}></StewardshipAssignmentsFilters>
      <StewardshipAssignmentsModal
        open={open}
        role={stewardshipRole}
        setOpen={setOpen}
        onSubmit={createAssignment}
        title={'Add Assignment'}></StewardshipAssignmentsModal>
    </Card>
  );
}

function formatDate(timestamp) {
  if (timestamp) {
    let date = new Date(timestamp);
    return date.toLocaleDateString();
  } else {
    return null;
  }
}

function mapStateToProps(state, props) {
  return {
    currentPage: state.stewardship.assignments.currentPage,
    stewardshipRole: state.stewardship.roles.content[props.stewardshipRoleUuid],
    user: state.settings.profile
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onFetchStewardshipRole: (stewardshipRoleUuid) => dispatch(fetchRole(stewardshipRoleUuid)),
    onPageSelection: (props) => dispatch(fetchAssignmentsPage({ ...props })),
    createAssignment: (assignment) => dispatch(createAssignment(assignment)),
    deleteAssignment: (assignment) => dispatch(deleteAssignment(assignment))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StewardshipAssignmentsCard);
