import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  fetchPhysicalEntitiesPageWithFilters,
  createPhysicalEntity,
  modifyPhysicalEntity,
  deletePhysicalEntity
} from 'actions/PhysicalEntitiesActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Card, CardContent } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';

import { fetchDashboardTypeCounterIfAllowedLargeRequest } from 'actions/IssueMngtDashboardsActions';

import Breadcrumbs from 'pages/sidebar/Navigation';
import PhysicalEntitiesTable from 'pages/systems/physical_entities/list/PhysicalEntitiesTable';
import PhysicalEntitiesActionsModals from 'pages/systems/physical_entities/list/PhysicalEntitiesActionsModals';
import PhysicalEntitiesListToolbox from './PhysicalEntitiesListToolbox';
import { AddFAB } from 'components/fab';
import Authorize from 'components/permissions/Authorize';
import Permissions from 'constants/Permissions';
import ExternalHelper from 'components/help/ExternalHelper';

import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import ResourceTypes from 'constants/ResourceTypes';
import useRoutePagination from 'components/pagination/useRoutePagination';

const PhysicalEntitiesListPage = ({
  pagination,
  filters,
  onPageSelection,
  onCreatePhysicalEntity,
  onModifyPhysicalEntity,
  onDeletePhysicalEntity,
  t
}) => {
  const history = useHistory();

  const [selectedEntity, setSelectedEntity] = useState(null);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleSearch = (entity) => history.push(`${window.location.pathname}/${entity.uuid}`);
  const handleCreate = (entity) => {
    onCreatePhysicalEntity(entity);
    setCreateModalOpen(false);
  };
  const handleEdit = (entity) => {
    onModifyPhysicalEntity(entity);
    setEditModalOpen(false);
  };
  const handleDelete = (entity) => {
    onDeletePhysicalEntity(entity);
    setDeleteModalOpen(false);
  };

  let { changePagination } = useRoutePagination({
    currentPage: pagination,
    onPageSelection,
    dependencies: [filters]
  });

  return (
    <>
      <Breadcrumbs
        elements={[
          { text: t('sidebar.physical_model'), to: '/systems' },
          { text: t('navbar.physicalEntities') }
        ]}
      />
      <Tracking
        info={{
          pageTitle: t('physicalEntities.header'),
          category: EngagementCategories.PHYSICAL_MODEL,
          action: EngagementActions.LIST
        }}
      />

      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Typography variant='h4'>
            {t('physicalEntities.header')}
            <ExternalHelper resourceType='physicalentities'></ExternalHelper>
          </Typography>
        </Grid>
        <Grid item>
          <PhysicalEntitiesListToolbox />
        </Grid>
      </Grid>
      <Card style={{ overflowX: 'auto' }}>
        <CardContent>
          <PhysicalEntitiesTable
            physicalEntities={pagination.content}
            handleShowDetails={(uuid) => history.push(`/physicalentities/${uuid}`)}
            handleEdit={(entity) => {
              setSelectedEntity(entity);
              setEditModalOpen(true);
            }}
            handleDelete={(entity) => {
              setSelectedEntity(entity);
              setDeleteModalOpen(true);
            }}
          />
          <TablePagination
            component='div'
            style={{ overflowX: 'hidden' }}
            rowsPerPageOptions={[25, 50, 100, 200]}
            rowsPerPage={pagination.size}
            page={pagination.number}
            count={pagination.totalElements}
            onPageChange={(event, page) => changePagination({ page })}
            onRowsPerPageChange={(event) => changePagination({ size: event.target.value })}
          />
        </CardContent>
      </Card>
      <Authorize hasPermissions={[Permissions.SYSTEMS_ADMIN]}>
        <AddFAB onClick={() => setCreateModalOpen(true)} />
      </Authorize>
      <PhysicalEntitiesActionsModals
        selectedPhysicalEntity={selectedEntity}
        searchModalOpen={searchModalOpen}
        setSearchModalOpen={setSearchModalOpen}
        onSearch={handleSearch}
        appliedFilters={filters}
        createModalOpen={createModalOpen}
        setCreateModalOpen={setCreateModalOpen}
        onCreate={handleCreate}
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        onEdit={handleEdit}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        onDelete={handleDelete}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  pagination: state.physicalentities.pagination,
  filters: state.physicalentities.filters
});

const mapDispatchToProps = (dispatch, { history }) => ({
  onPageSelection: (page, size) =>
    dispatch(fetchPhysicalEntitiesPageWithFilters({ includeProperties: false }, page, size)).then(
      (response) => {
        response &&
          response.content.length > 0 &&
          dispatch(
            fetchDashboardTypeCounterIfAllowedLargeRequest(
              response.content,
              25,
              ResourceTypes.PHYSICAL_ENTITY
            )
          );
      }
    ),
  onCreatePhysicalEntity: (entity) =>
    dispatch(
      createPhysicalEntity(entity, (data) => history.push(`/physicalentities/${data.uuid}`))
    ),
  onModifyPhysicalEntity: (entity) => dispatch(modifyPhysicalEntity(entity)),
  onDeletePhysicalEntity: (entity) => dispatch(deletePhysicalEntity(entity))
});

export default _.flow(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PhysicalEntitiesListPage);
