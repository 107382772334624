import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PhysicalConstraintModal from './PhysicalConstraintModal';
import { useDispatch } from 'react-redux';
import ActionBuilder from 'actions/PhysicalConstraintsActions';

export const AddPhysicalConstraintButton = ({
  system,
  targetPhysicalEntity,
  targetPhysicalField
}) => {
  const [open, setOpen] = useState(false);

  const physicalConstraint = {
    ...(system && { system: system }),
    ...(targetPhysicalEntity && {
      physicalEntitiesConstraints: [{ targetPhysicalEntity: targetPhysicalEntity }]
    }),
    ...(targetPhysicalField && {
      physicalFieldsConstraints: [{ targetPhysicalField: targetPhysicalField }]
    })
  };

  const dispatch = useDispatch();

  return (
    <>
      <IconButton onClick={() => setOpen(true)} size='small'>
        <AddBoxIcon />
      </IconButton>
      <PhysicalConstraintModal
        title='Create Physical Constraint'
        open={open}
        onClose={() => setOpen(false)}
        physicalConstraint={physicalConstraint}
        onSubmit={(physicalConstraint) => {
          dispatch(ActionBuilder.create(physicalConstraint));
        }}
      />
    </>
  );
};
