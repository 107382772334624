import { TextField } from '@mui/material';
import React from 'react';
function QualityProbesDefinitionModalJDBCParameters({ query, onChange }) {
  return <>
    <TextField
      variant="standard"
      fullWidth
      margin='dense'
      placeholder={
        'The name of the query result field that you want to take as the total number of elements or metric. If not specified, the first field will be considered.'
      }
      label={'Result Field Name'}
      value={query.queryBody?.resultFieldName || ''}
      onChange={(event) => {
        onChange({
          ...query,
          queryBody: { ...query.queryBody, resultFieldName: event.target.value || null }
        });
      }}></TextField>

    <TextField
      variant="standard"
      required
      margin='dense'
      id='outlined-multiline-static'
      label='Query'
      multiline
      rows={4}
      placeholder='A SQL query text.'
      fullWidth
      value={query.queryBody?.text || ''}
      onChange={(event) => {
        onChange({ ...query, queryBody: { ...query.queryBody, text: event.target.value } });
      }}></TextField>
  </>;
}

export default QualityProbesDefinitionModalJDBCParameters;
