import React, { useState, useEffect, useReducer } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@mui/material';
import DatePicker, { hourAdjustments } from '../../../components/pickers/DatePicker';
import UsersInCurrentTenantItemAutocomplete from 'pages/settings/users/commons/UsersInCurrentTenantItemAutocomplete';
import SearchItemAutocomplete from '../../settings/search/SearchItemAutocomplete';
import HistoryTargetTypes from '../commons/HistoryTargetTypes';
import HistoryOperationTypes from '../commons/HistoryOperationTypes';
import { setHistoryFilters, clearHistoryFilters } from '../../../actions/HistoryActions';
import { connect } from 'react-redux';

function HistoryFiltersModal({
  filters,
  setHistoryFilters,
  clearHistoryFilters,
  hasFilters,
  pageSize,
  currentProfile
}) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <Tooltip title='Filters'>
        <IconButton
          color={hasFilters ? 'primary' : 'grey'}
          size='small'
          onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <HistoryModal
          currentProfile={currentProfile}
          open={open}
          setOpen={setOpen}
          filters={filters}
          setHistoryFilters={setHistoryFilters}
          onClear={clearHistoryFilters}
          pageSize={pageSize}></HistoryModal>
      )}
    </React.Fragment>
  );
}

function HistoryModal({ open, filters, onClear, setHistoryFilters, setOpen }) {
  const [filtersEditor, setFiltersEditor] = useReducer((s, a) => ({ ...s, ...a }), {});
  useEffect(() => {
    setFiltersEditor({
      user: filters.user,
      operation: filters.operation,
      targetType: filters.targetType,
      startDate: filters.startDate,
      endDate: filters.endDate,
      resource: filters.resource
    });
  }, [filters]);

  let { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogTitle>{'Set filters'}</DialogTitle>
        <DialogContent>
          <UsersInCurrentTenantItemAutocomplete
            value={filtersEditor.user || null}
            onChange={(user) => setFiltersEditor({ user })}></UsersInCurrentTenantItemAutocomplete>
          <DatePicker
            clearable={true}
            label={'Start Date'}
            hintText={'Start Date'}
            hourAdjustment={hourAdjustments.startOfDay}
            value={filtersEditor.startDate}
            onChange={(date) => setFiltersEditor({ startDate: date })}
          />

          <DatePicker
            clearable={true}
            label={'End Date'}
            hintText={'End Date'}
            hourAdjustment={hourAdjustments.endOfDay}
            value={filtersEditor.endDate}
            onChange={(date) => setFiltersEditor({ endDate: date })}
          />

          <FormControl variant='standard' fullWidth>
            <InputLabel id='select-target-type-label'>Target Type</InputLabel>
            <Select
              variant='standard'
              labelId='select-target-type-label'
              id='select-target-type'
              value={filtersEditor.targetType || ''}
              onChange={(event) => setFiltersEditor({ targetType: event.target.value || null })}>
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {Object.values(HistoryTargetTypes).map((targetType) => (
                <MenuItem key={targetType} value={targetType}>
                  {targetType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant='standard' fullWidth>
            <InputLabel id='select-operation-type-label'>Operation Type</InputLabel>
            <Select
              variant='standard'
              labelId='select-operation-type-label'
              id='select-operation-type'
              value={filtersEditor.operation || ''}
              onChange={(event) => setFiltersEditor({ operation: event.target.value || null })}>
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {Object.values(HistoryOperationTypes).map((operation) => (
                <MenuItem key={operation} value={operation}>
                  {operation}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <SearchItemAutocomplete
            resourceTypes={
              filtersEditor.targetType
                ? [filtersEditor.targetType]
                : Object.values(HistoryTargetTypes)
            }
            onChange={(resource) => setFiltersEditor({ resource })}></SearchItemAutocomplete>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('commons.actions.clear')}
            onClick={() => {
              onClear();
              setOpen(false);
            }}
          />
          <Button
            children={t('commons.actions.apply')}
            variant='outlined'
            color='primary'
            onClick={() => {
              setHistoryFilters(filtersEditor);
              setOpen(false);
            }}></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
function mapStateToProps(state, props) {
  return {
    filters: state.history.filters.content,
    hasFilters: state.history.filters.hasFilters
  };
}

export default connect(mapStateToProps, {
  setHistoryFilters,
  clearHistoryFilters
})(HistoryFiltersModal);
