import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { deleteComment, modifyComment } from '../../../actions/CommentsActions';
import { connect } from 'react-redux';
import ConfirmMenuItem from '../../../components/confirmbutton/ConfirmMenuItem';
import CommentEditorModal from './CommentEditorModal';

function CommentsActionsMenu({ profile, comment, deleteComment, modifyComment }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editOpen, setEditOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    setEditOpen(true);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    deleteComment(comment.sequenceId);
  };
  if (profile.uuid !== comment.authorUuid) {
    return null;
  }
  return (
    <>
      <IconButton edge='end' size='small' aria-label='delete' onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Menu
        id='comments-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleEdit}>Edit Comment</MenuItem>
        <ConfirmMenuItem onClick={handleDelete}>Delete Comment</ConfirmMenuItem>
      </Menu>
      <CommentEditorModal
        comment={comment}
        open={editOpen}
        onCancel={() => setEditOpen(false)}
        onSubmit={(comment) => {
          setEditOpen(false);
          modifyComment(comment);
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  profile: state.settings.profile
});

const mapDispatchToProps = {
  deleteComment,
  modifyComment
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsActionsMenu);
