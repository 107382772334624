import React from 'react';
import _ from 'lodash';
import AdditionalPropertiesItem from './AdditionalPropertiesItem';
import { useTranslation } from 'react-i18next';
import DefinitionList from '../definition-list/DefinitionList';
import withCustomProperties from './withCustomPropertiesDefinitons';

function CustomPropertiesView({ resourceType, customProperties, additionalProperties = [] }) {
  additionalProperties = additionalProperties || [];
  const cdpByName = _.keyBy(customProperties, 'name');
  const customAdditionalProperties = additionalProperties.filter((adp) => cdpByName[adp.name]);
  const remainingAdditionalProperties = additionalProperties.filter((adp) => !cdpByName[adp.name]);

  return (
    <>
      <CustomPropertiesViewRenderer
        {...{
          resourceType,
          customProperties,
          additionalProperties: customAdditionalProperties
        }}
      />
      <AdditionalPropertiesItem
        additionalProperties={remainingAdditionalProperties}
        resourceType={resourceType}
      />
    </>
  );
}

//TODO adjust additional properties resource type in translations

function CustomPropertiesViewRenderer({ customProperties, additionalProperties }) {
  const { t } = useTranslation();

  if (!customProperties || customProperties.length <= 0 || !additionalProperties) {
    return null;
  }
  const additionalPropertiesByName = _.groupBy(additionalProperties, 'name');

  const items = customProperties.map((cdp) => ({
    label: cdp.label,
    value: _(additionalPropertiesByName)
      .get(cdp.name, [])
      .map((ap) => {
        if (cdp.type === 'COMPUTED') {
          try {
            return _.round(ap.value, 2);
          } catch {
            return ap.value;
          }
        } else {
          return ap.value;
        }
      })
  }));

  return (
    <div>
      <h4>{t('Custom Properties')}</h4>
      <DefinitionList items={items} labelProperty='label' dataProperties={['value']} />
    </div>
  );
}

export default withCustomProperties(CustomPropertiesView);
