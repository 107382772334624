import React from 'react'
import PropTypes from 'prop-types'
import './Spinner.css'

const Spinner = ({ show }) => {
  if (show) {
    return (
      <div className="sk-folding-cube">
        <div className="sk-cube1 sk-cube"></div>
        <div className="sk-cube2 sk-cube"></div>
        <div className="sk-cube4 sk-cube"></div>
        <div className="sk-cube3 sk-cube"></div>
      </div>
    )
  } else { 
    return null 
  }
}

Spinner.propTypes = {
  show: PropTypes.bool.isRequired
}
export default Spinner;
