import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withPermissions from 'components/permissions/withPermissions';
import Permissions from 'constants/Permissions';

import { createSchedule } from 'actions/onagent/AgentScheduleActions';

import { Button } from '@mui/material';

import CrawlingModal from './CrawlingModal';

function CrawlingAddButton({ agent, system, createSchedule }) {
  let [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>Add crawling</Button>
      <CrawlingModal
        open={open}
        agent={agent}
        system={system}
        onCancel={() => setOpen(false)}
        onSubmit={(schedule) => {
          setOpen(false);
          createSchedule(agent, schedule);
        }}
      />
    </>
  );
}

export default compose(
  withPermissions({ hasPermissions: [Permissions.SYSTEMS_EDITOR, Permissions.AGENTS_EDITOR] }),
  connect(null, { createSchedule })
)(CrawlingAddButton);
