import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createAgentWebActionConfiguration } from 'actions/onagent/webactions/AgentWebActionConfigurationsActions';
import AgentWebActionConfigurationModal from '../modal/AgentWebActionConfigurationModal';
import { Button } from '@mui/material';

function AgentWebActionConfigurationAddButton({
  agent,
  createAgentWebActionConfiguration,
  webAction
}) {
  let [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>Add Configurations</Button>
      <AgentWebActionConfigurationModal
        open={open}
        webAction={webAction}
        title={`Configuration of action: ${webAction?.name}`}
        handleCancel={() => setOpen(false)}
        handleSubmit={(configuration) => {
          createAgentWebActionConfiguration(agent, configuration);
          setOpen(false);
        }}
      />
    </>
  );
}

export default compose(connect(null, { createAgentWebActionConfiguration }))(
  AgentWebActionConfigurationAddButton
);
