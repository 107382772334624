import React from 'react';

import { Grid, TextField, IconButton, Tooltip } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

function QualityProbesDefinitionModalMongoParameters({ query, onChange }) {
  return (
    <>
      <TextField
        variant='standard'
        required
        fullWidth
        margin='dense'
        placeholder={'The collection to query.'}
        label={'Collection'}
        value={query.queryBody?.collection || ''}
        onChange={(event) => {
          onChange({
            ...query,
            queryBody: { ...query.queryBody, collection: event.target.value }
          });
        }}></TextField>
      <TextField
        variant='standard'
        fullWidth
        margin='dense'
        placeholder={
          'The name of the query result field that you want to take as the total number of elements or metric. If not specified, the first field will be considered.'
        }
        label={'Result Field Name'}
        value={query.queryBody?.resultFieldName || ''}
        onChange={(event) => {
          onChange({
            ...query,
            queryBody: { ...query.queryBody, resultFieldName: event.target.value || null }
          });
        }}></TextField>
      <TextField
        variant='standard'
        fullWidth
        margin='dense'
        placeholder={'A default value to use in case of queries returning no document.'}
        label={'Default Value'}
        value={query.queryBody?.defaultValue || ''}
        onChange={(event) => {
          onChange({
            ...query,
            queryBody: { ...query.queryBody, defaultValue: event.target.value }
          });
        }}></TextField>
      <PipelineInputs query={query} onChange={onChange} />
    </>
  );
}

const PipelineInputs = ({ query, onChange }) => {
  const pipeline = query.queryBody?.pipeline || [''];

  const handleStageAdd = () => {
    onChange({
      ...query,
      queryBody: {
        ...query.queryBody,
        pipeline: query.queryBody?.pipeline ? [...query.queryBody.pipeline, ''] : ['']
      }
    });
  };

  const handleStageChange = (updatedStage, index) => {
    const newPipeline = [...pipeline];
    newPipeline.splice(index, 1, updatedStage);
    onChange({
      ...query,
      queryBody: { ...query.queryBody, pipeline: newPipeline }
    });
  };

  const handleStageDelete = (index) => {
    const newPipeline = [...pipeline];
    newPipeline.splice(index, 1);
    onChange({
      ...query,
      queryBody: { ...query.queryBody, pipeline: newPipeline }
    });
  };

  return (
    <>
      <Grid container direction='row' justifyContent='space-between'>
        <Grid item>
          <h5>Pipeline *</h5>
        </Grid>
        <Grid item>
          <Tooltip title={'Create stage'}>
            <IconButton onClick={handleStageAdd} size='medium'>
              <AddBoxIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {pipeline.map((stage, index) => (
        <Grid container direction='row' alignItems='flex-end' key={`pipeline-stage-${index}`}>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              variant='standard'
              fullWidth
              margin='dense'
              multiline
              id={`pipeline-stage-text-field-${index}`}
              label={`Stage ${index + 1}`}
              placeholder={`A MongoDB pipeline stage.`}
              value={stage}
              onChange={(event) => handleStageChange(event.target.value, index)}
            />
          </Grid>
          <Grid item>
            <Tooltip title={'Remove the stage'}>
              <IconButton onClick={() => handleStageDelete(index)} size='medium'>
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default QualityProbesDefinitionModalMongoParameters;
