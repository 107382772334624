import { buildApiCallAction } from './ActionUtils';
import {
  CREATE_WATCHLIST_RECORD,
  FETCH_WATCHLIST_PAGE,
  DELETE_WATCHLIST_RECORD
} from '../constants/ActionTypes';

import WatchlistApi from '../api/WatchlistApi';

const watchlistApi = new WatchlistApi();

export const fetchWatchlistPage = (params) =>
  buildApiCallAction({
    api: watchlistApi,
    apiFunction: (api) => api.getWatchlist(params),
    actionType: FETCH_WATCHLIST_PAGE,
    payload: (response) => ({
      ...response,
      entityType: params.entityType,
      entityIdentifier: params.entityIdentifier
    })
  });
export const createWatchlistRecord = (watchlistSubscription) =>
  buildApiCallAction({
    api: watchlistApi,
    apiFunction: (api) => api.postWatchlist(watchlistSubscription),
    actionType: CREATE_WATCHLIST_RECORD
  });
export const deleteWatchlistRecord = (watchlistRecord) =>
  buildApiCallAction({
    api: watchlistApi,
    apiFunction: (api) => api.deleteWatchlistRecord(watchlistRecord.sequenceId),
    actionType: DELETE_WATCHLIST_RECORD,
    payload: watchlistRecord
  });
