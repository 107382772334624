import { Grid, IconButton, Typography } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import React from 'react';
import NotificationsTabbedPanel from './NotificationsTabbedPanel';
import Breadcrumbs from 'pages/sidebar/Navigation';
import Tracking from 'components/engagement/Tracking';
import EngagementCategories from 'constants/EngagementCategories';
import EngagementActions from 'constants/EngagementActions';
import { useHistory } from 'react-router';

function NotificationsPage(props) {
  const history = useHistory();
  return (
    <>
      <Breadcrumbs elements={[{ text: 'Settings', to: '/settings' }, { text: 'Notification' }]} />
      <Tracking
        info={{
          pageTitle: `Notifications`,
          category: EngagementCategories.SETTINGS,
          action: EngagementActions.LIST
        }}
      />
      <Grid container alignItems='center'>
        <Grid item>
          <Typography variant='h4'>Notifications</Typography>
        </Grid>
        <Grid item>
          <IconButton size='small' onClick={() => history.push('/settings/profile')}>
            <SettingsOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>

      <NotificationsTabbedPanel></NotificationsTabbedPanel>
    </>
  );
}

export default NotificationsPage;
