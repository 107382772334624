import { InputAdornment, TextField } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { setPhysicalConstraintsFilters } from 'actions/PhysicalConstraintsActions';

const PhysicalConstraintsSearchBox = () => {
  const search = useSelector((state) => state.physicalconstraints.filters.search);
  const [editor, setEditor] = useState(search || '');

  const dispatch = useDispatch();

  const setFilters = (filters) => dispatch(setPhysicalConstraintsFilters(filters));

  useEffect(() => {
    if (search !== editor) {
      setEditor(search || '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setEditor]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let onSetFilters = useCallback(_.debounce(setFilters, 300), [setFilters]);

  return (
    <TextField
      value={editor}
      id='input-with-icon-textfield'
      placeholder='Search...'
      variant='outlined'
      size='small'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='disabled' />
          </InputAdornment>
        )
      }}
      onChange={(event) => {
        setEditor(event.target.value);
        onSetFilters({ search: event.target.value || null });
      }}
    />
  );
};

export default PhysicalConstraintsSearchBox;
