import { FETCH_ASSIGNMENTS_CONFIG, MODIFY_ASSIGNMENTS_CONFIG } from '../../constants/ActionTypes';

export default function assignmentsconfig(state = DEFAULT_ASSIGNMENTS_CONFIG, action) {
  switch (action.type) {
    case FETCH_ASSIGNMENTS_CONFIG:
    case MODIFY_ASSIGNMENTS_CONFIG:
      return action.payload;
    default:
      return state;
  }
}

const DEFAULT_ASSIGNMENTS_CONFIG = {
  confirmedInterval: {
    lowerBound: 0.8,
    upperBound: 1
  },
  pendingInterval: {
    lowerBound: 0.4,
    upperBound: 0.8
  },
  refusedInterval: {
    lowerBound: 0,
    upperBound: 0.4
  }
};
