import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchProbeDefinitionsPage } from 'actions/quality/probes/ProbesDefinitionsActions';

import { Grid, Table, TablePagination, Typography } from '@mui/material';

import QualityProbesDefinitionAddButton from '../common/QualityProbesDefinitionAddButton';
import QualityProbesDefinitionsTable from './QualityProbesDefinitionsTable';
import ProjectTestButton from './ProjectTestButton';

function QualityProbesDefinitionsList({ currentPage, project, fetchProbeDefinitionsPage }) {
  useEffect(() => {
    if (project) fetchProbeDefinitionsPage({ projectUuid: project.uuid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  if (!project) {
    return (
      <div style={{ textAlign: 'center', padding: '2%', marginTop: '48px' }}>
        <Typography variant='body1'>Select a project to show its probes</Typography>
      </div>
    );
  }

  return <>
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {currentPage.content.length > 0 && (
        <ProjectTestButton
          project={project}
          projectProbes={currentPage.content}></ProjectTestButton>
      )}
      <QualityProbesDefinitionAddButton project={project} />
    </div>
    {currentPage.content.length > 0 ? (
      <div style={{ height: '100%', overflowY: 'auto' }}>
        <Grid container direction='column' style={{ minHeight: '100%' }}>
          <Grid item>
            <Table size='small'>
              <QualityProbesDefinitionsTable
                content={currentPage.content}></QualityProbesDefinitionsTable>
            </Table>
          </Grid>
          <Grid item style={{ flexGrow: 1 }} />
          <Grid item>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 500, 1000]}
              component='div'
              style={{ overflowX: 'hidden' }}
              count={currentPage.totalElements}
              rowsPerPage={currentPage.size}
              page={currentPage.number}
              onPageChange={(event, page) =>
                fetchProbeDefinitionsPage({
                  page: page,
                  size: currentPage.size,
                  projectUuid: project.uuid
                })
              }
              onRowsPerPageChange={(event) =>
                fetchProbeDefinitionsPage({
                  page: currentPage.number,
                  size: event.target.value,
                  projectUuid: project.uuid
                })
              }
            />
          </Grid>
        </Grid>
      </div>
    ) : (
      <div style={{ textAlign: 'center', padding: '2%' }}>
        <Typography variant='body1'>No probes defined for this project </Typography>
      </div>
    )}
  </>;
}

const mapStateToProps = (state) => ({
  currentPage: state.quality.qualityProbes.definitions.currentPage
});

const mapDispatchToProps = {
  fetchProbeDefinitionsPage
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityProbesDefinitionsList);
